import { StyleSheet } from "react-native";

import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.WHITE,
    alignItems: "center",
    paddingHorizontal: Spacing.S16,
    flex: 1,
  },

  imageContainer: {
    height: 100,
    width: 100,
    marginBottom: Spacing.S24,
  },

  title: {
    marginBottom: Spacing.S16,
    textAlign: "center",
  },
  content: {
    textAlign: "center",
  },
});
