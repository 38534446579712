import { StyleSheet } from "react-native";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexBasis: 0,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  contentContainer: {
    paddingBottom: Spacing.S32,
  },
  mapButton: {
    paddingHorizontal: Spacing.S16,
    paddingTop: Spacing.S12,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  sectionContent: {
    marginHorizontal: Spacing.S16,
    gap: Spacing.S8,
  },
});
