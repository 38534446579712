import { StyleSheet } from "react-native";

import { Color, ColorStyle } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    aspectRatio: 1,
    height: "auto",
    overflow: "hidden",
  },

  containerForInitials: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  containerForIcons: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },

  indicatorDot: {
    backgroundColor: Color.WHITE,
  },
  indicatorContainer: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
  },
  indicatorIcon: {
    position: "absolute",
  },
});
