import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";

import { alphaHex } from "@kraaft/helper-functions";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { Firebase } from "@kraaft/shared/core/services/firebase";
import {
  useNavigationService,
  useRouteService,
} from "@kraaft/shared/core/services/navigation";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useEnsureOnline } from "@kraaft/shared/core/utils/useNetwork";
import { Button, Color, Icon, NonAccessibleText, Preloader } from "@kraaft/ui";

import { styles } from "./enterPassword.styles";

const EnterPassword = () => {
  const { t } = useTranslation();
  const navigationService = useNavigationService();
  const routeService = useRouteService<"EnterPassword">();

  const [password, setPassword] = useState("");
  const [isPasswordHidden, setPasswordHidden] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isWorking, setWorking] = useState(false);

  const { email, exists } = routeService.getParams();

  const handleNext = useEnsureOnline(async () => {
    if (!password) {
      return;
    }
    setWorking(true);

    try {
      if (exists) {
        await Firebase.auth().signInWithEmailAndPassword(email, password);
      } else {
        await Firebase.auth().createUserWithEmailAndPassword(email, password);
      }
      if (exists) {
        trackEvent({
          eventName: "User Sign In Success",
        });
      }
    } catch (err) {
      trackEvent({
        eventName: exists ? "User Sign In Failure" : "User Sign Up Failure",
      });

      if (err.code === "auth/weak-password") {
        setErrorMessage(t("weakPassword"));
        setError(true);
      } else if (err.code === "auth/wrong-password") {
        setErrorMessage(t("wrongPassword"));
        setError(true);
      } else {
        console.warn(err);
        setError(true);
      }
      setWorking(false);
    }
  }, [exists, email, password, t]);

  const handlePasswordChange = useCallback((text: string) => {
    setPassword(text);
    setError(false);
  }, []);

  const togglePassword = useCallback(() => {
    setPasswordHidden((oldState) => !oldState);
  }, []);

  const handleForgotPasswordPress = useCallback(() => {
    void Firebase.auth().sendPasswordResetEmail(email);

    navigationService.navigate("ForgotPasswordEmailSent", {
      email,
      mode: "user",
    });
  }, [email, navigationService]);

  return (
    <View style={styles.container}>
      {isWorking && (
        <Preloader absoluteFill backgroundColor={alphaHex(Color.WHITE, 0.6)} />
      )}
      <View style={styles.titleContainer}>
        <NonAccessibleText
          weight="bold"
          size="H1"
          color="FONT_HIGH_EMPHASIS"
          style={styles.title}
        >
          {exists ? t("enterYourPasswordTitle") : t("createYourPasswordTitle")}
        </NonAccessibleText>
        <NonAccessibleText size="BODY" color="FONT_HIGH_EMPHASIS">
          {exists
            ? t("enterYourPasswordContent", { email })
            : t("createYourPasswordContent")}
        </NonAccessibleText>
      </View>
      <View>
        <KInput
          testID="ide2e-password"
          disableAccessibility
          label={t("enterYourPasswordLabel")}
          value={password}
          onChangeText={handlePasswordChange}
          error={error}
          autoFocus
          keyboardType="default"
          secureTextEntry={isPasswordHidden}
          autoComplete="password"
          textContentType="password"
          returnKeyType="go"
          onSubmitEditing={handleNext}
          nativeID="ide2e-password"
          autoCapitalize="none"
        />
        <TouchableOpacity
          accessibilityLabel={isPasswordHidden ? t("show") : t("hide")}
          style={styles.togglePasswordButton}
          onPress={togglePassword}
        >
          <Icon
            name={isPasswordHidden ? "eye-off" : "eye"}
            color="FONT_LOW_EMPHASIS"
            size="MEDIUM"
          />
        </TouchableOpacity>
      </View>
      {error && (
        <View style={styles.errorContainer}>
          <NonAccessibleText size="SMALL" color="ACTION_DESCTRUCTIVE">
            {errorMessage || t("internalError")}
          </NonAccessibleText>
        </View>
      )}
      <View style={styles.navigateSpacer} />
      <View style={styles.navigateButtonContainer}>
        {exists && (
          <NonAccessibleText
            weight="light"
            size="BODY"
            color="FONT_LOW_EMPHASIS"
            onPress={handleForgotPasswordPress}
          >
            {t("enterYourPasswordForgotten")}
          </NonAccessibleText>
        )}
      </View>
      <View style={styles.buttonSpacer} />
      <Button
        id="ide2e-password-submit"
        disableAccessibility
        onPress={handleNext}
        disabled={!password}
        text={exists ? t("enterYourPasswordButton") : t("next")}
        style={styles.button}
      />
    </View>
  );
};

export { EnterPassword };
