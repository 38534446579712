import { useCallback, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";

import type { CollapsibleHorizontalProps } from "@kraaft/shared/components/animated/collapsibleHorizonal/types";

export const ANIMATION_DURATION = 250;

const CollapsibleHorizontal = ({
  open,
  children,
}: CollapsibleHorizontalProps) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const observer = useRef<ResizeObserver | null>(null);
  const classes = useStyles();

  const applyWidth = useCallback((isOpen: boolean) => {
    if (containerRef.current && rootRef.current) {
      if (!isOpen) {
        rootRef.current.style.width = "0px";
      } else {
        rootRef.current.style.width = `${containerRef.current.clientWidth}px`;
      }
    }
  }, []);

  const onRootRef = useCallback(
    (node: HTMLDivElement) => {
      rootRef.current = node;
      observer.current = new ResizeObserver(() => applyWidth(open));
      if (containerRef.current) {
        observer.current.observe(containerRef.current);
      }
      applyWidth(open);
    },
    [applyWidth, open],
  );

  useEffect(() => {
    if (open) {
      containerRef.current?.classList.remove(classes.contentDisabled);
    } else {
      const timeout = setTimeout(() => {
        containerRef.current?.classList.add(classes.contentDisabled);
      }, ANIMATION_DURATION);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [classes.contentDisabled, open]);

  useEffect(() => {
    applyWidth(open);
  }, [applyWidth, open, children]);

  return (
    <div ref={onRootRef} className={classes.root}>
      <div ref={containerRef} className={classes.children}>
        {children}
      </div>
    </div>
  );
};

export const useStyles = makeStyles({
  root: {
    transition: `all ${ANIMATION_DURATION}ms`,
    overflow: "hidden",
    position: "relative",
  },
  children: {
    position: "absolute",
  },

  contentDisabled: {
    "& *": {
      visibility: "hidden",
    },
  },
});

export { CollapsibleHorizontal };
