import { createSelector } from "@reduxjs/toolkit";
import { memoize, orderBy } from "lodash";

import { ReportTemplateOptimisticSelector } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.optimistic";
import { PoolSchemaReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { RootState } from "@kraaft/shared/core/store";

export const selectState = ({ reportTemplate: { data } }: RootState) => data;

export const selectReportTemplates = createSelector(
  selectState,
  (state) => state.templates,
);

const EMPTY_ARRAY: PoolSchemaReportTemplate[] = [];

export const selectTemplatesBySchemaIdOptimictic = memoize(
  (schemaId: string | undefined) =>
    createSelector(
      ReportTemplateOptimisticSelector.selectAllBuilt,
      (reportTemplates) =>
        schemaId !== undefined
          ? Object.values(reportTemplates).filter(
              (t) => t.schemaId === schemaId,
            )
          : EMPTY_ARRAY,
    ),
);

export const selectTemplatesBySchemaId = memoize(
  (schemaId: string | undefined) =>
    createSelector(selectReportTemplates, (reportTemplates) =>
      schemaId !== undefined
        ? Object.values(reportTemplates).filter((t) => t.schemaId === schemaId)
        : EMPTY_ARRAY,
    ),
);

export const selectOrderedTemplatesBySchemaId = memoize(
  (schemaId: string | undefined) =>
    createSelector(selectTemplatesBySchemaId(schemaId), (reportTemplates) =>
      orderBy(reportTemplates, (reportTemplate) => reportTemplate.index),
    ),
);

export const selectOrderedEnabledTemplatesBySchemaId = memoize(
  (schemaId: string | undefined) =>
    createSelector(
      selectOrderedTemplatesBySchemaId(schemaId),
      (reportTemplates) =>
        reportTemplates.filter((reportTemplate) => reportTemplate.enabled),
    ),
);

export const selectTemplate = memoize((reportTemplateId: string | undefined) =>
  createSelector(selectReportTemplates, (reportTemplates) =>
    reportTemplateId !== undefined
      ? reportTemplates[reportTemplateId]
      : undefined,
  ),
);
