import { createAction } from "@reduxjs/toolkit";

import { WithLoaderPayload } from "@kraaft/shared/core/modules/loaders/loaderUtils";

interface ConnectPoolToExternalPayload extends WithLoaderPayload {
  poolId: string;
  sharepointUrl: string;
}

export const connectPoolToExternal = createAction<ConnectPoolToExternalPayload>(
  "@poolAdmin/connectToExternal",
);

export const disconnectPoolToExternal = createAction<{ poolId: string }>(
  "@poolAdmin/disconnectToExternal",
);

interface ToggleInboundEmailPayload extends WithLoaderPayload {
  poolId: string;
  enabled: boolean;
  inboundEmailId?: string;
}

export const toggleInboundEmail = createAction<ToggleInboundEmailPayload>(
  "@poolAdmin/toggleInboundEmail",
);

interface ToggleDeliveryNoteConfig {
  poolId: string;
  enabled: boolean;
}
export const toggleDeliveryNoteConfig = createAction<ToggleDeliveryNoteConfig>(
  "@poolAdmin/toggleDeliveryNoteConfig",
);
