import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { FileSenderBucketState } from "@kraaft/shared/core/modules/fileBucket/fileBucketState";
import { RootState } from "@kraaft/shared/core/store";

const emptyList = { list: [] };

export const selectFilesInSenderBucket = memoize((roomId: string) =>
  createSelector(
    (state: RootState) => state.fileBucket,
    (fileBucket: FileSenderBucketState) => {
      return fileBucket.fileSenderBucketList?.[roomId] ?? emptyList; // TODO one layer shallower ?
    },
  ),
);

export const selectFilesCount = memoize((roomId: string) =>
  createSelector(
    (state: RootState) => state.fileBucket,
    (fileBucket: FileSenderBucketState) => {
      return fileBucket.fileSenderBucketList?.[roomId]?.list?.length ?? 0;
    },
  ),
);
