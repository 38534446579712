import { StyleSheet } from "react-native";

import { Color, ColorStyle, PixelSize, Radius, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.WHITE,
    borderRadius: Radius.SMALL,
    borderColor: ColorStyle.BACKGROUND_STANDARD,
    borderWidth: 1,
    paddingVertical: Spacing.S12,
    paddingHorizontal: Spacing.S16,
    flexDirection: "row",
    alignItems: "center",
  },
  containerWithShadow: {
    elevation: 10,
    shadowColor: Color.BLACK,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 4,
    shadowOpacity: 0.25,
  },
  pressedContainer: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderColor: Color.GREY_FRENCH,
    transform: [{ scale: 0.975 }],
  },
  iconContainer: {
    marginRight: Spacing.S8,
  },
  imageContainer: {
    marginRight: Spacing.S8,
    width: PixelSize.S32,
    height: PixelSize.S32,
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginRight: Spacing.S4,
  },
  title: {
    marginBottom: Spacing.S4,
  },
});
