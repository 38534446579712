import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { useBooleanState, useMeshContext } from "@kraaft/helper-hooks";
import { AttachmentListContext } from "@kraaft/shared/components/attachmentList/attachmentListUtils";
import { DraggableMessageType } from "@kraaft/shared/components/draggableMessage/types";
import { FilesList } from "@kraaft/shared/components/filesList";
import {
  AttachmentsEditorProps,
  ModularDetailsAttachmentContext,
} from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/attachmentsEditor.props";
import { ImportDocumentMethodSheetContent } from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/importDocumentMethodSheet/importDocumentMethodSheetContent";
import { EMPTY_LOCKED_RECORD_PLACEHOLDER } from "@kraaft/shared/components/modular/details/utils";
import { fileHelper } from "@kraaft/shared/core/modules/file/fileHelper";
import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { ModularDisplayExtendedRequirements } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { ModularDisplayRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { filterAttachments } from "@kraaft/shared/core/utils";
import {
  ActionSheetHost,
  Button,
  ButtonHandle,
  ColorStyle,
  Droppable,
  FileDropperPickerHandle,
  LabelledActionCard,
  Spacing,
} from "@kraaft/ui";

interface ListAttachmentsEditorProps {
  value: AttachmentsEditorProps["value"];
  column: AttachmentsEditorProps["column"];
  context: ModularDetailsAttachmentContext;
  id?: string;
  disabled?: boolean;
  isLocked?: boolean;
  recordType: ModularDisplayExtendedRequirements["recordType"];
}

const ListAttachmentsEditor = ({
  value,
  column,
  id,
  context,
  disabled,
  isLocked,
  recordType,
}: ListAttachmentsEditorProps) => {
  const { t } = useTranslation();
  const filePickerRef = useRef<FileDropperPickerHandle>(null);

  const {
    isLoadingColumn,
    roomTitle,
    folderTitle = "",
    openImportDocumentFromConversationModal,
    openImportImagesFromConversationModal,
  } = context;

  const items = useMemo(() => value ?? [], [value]);

  const { getPoolUsers } = useMeshContext(ModularDisplayRequirementsContext);

  const { onDrop, onDropFiles, onPickDocuments } = context;
  const listContext = useMemo<AttachmentListContext>(
    () => ({
      folderTitle,
      roomTitle,
      users: getPoolUsers() ?? {},
    }),
    [folderTitle, getPoolUsers, roomTitle],
  );
  const isLoading = isLoadingColumn(column);

  const handleAttachmentClick = useCallback(
    (attachment: Attachment) => {
      switch (attachment.type) {
        case "image": {
          const pictureIndex = filterAttachments(items, ["image"]).findIndex(
            (pictureAttachment) => pictureAttachment.id === attachment.id,
          );
          context.openCarousel(column, pictureIndex);
          break;
        }

        default:
          break;
      }
    },
    [column, context, items],
  );

  const handleDelete = useCallback(
    (attachment: Attachment) => {
      context.onDelete(column, attachment);
    },
    [column, context],
  );

  const importDocumentButtonRef = useRef<ButtonHandle>(null);

  const [
    isImportImageMethodSheetOpen,
    openImportImageMethodSheet,
    closeImportImageMethodSheet,
  ] = useBooleanState(false);

  const handleOpenPicker = useCallback(() => {
    filePickerRef.current?.openFilePicker();
  }, []);

  const onAttachDocumentFromConversation = useCallback(() => {
    return openImportDocumentFromConversationModal?.(column);
  }, [openImportDocumentFromConversationModal, column]);

  const onAttachPhotoFromConversation = useCallback(() => {
    return openImportImagesFromConversationModal?.(column);
  }, [openImportImagesFromConversationModal, column]);

  const handleDroppedFiles = useCallback(
    (files: File[]) => {
      onDropFiles(column)(files.map((file) => fileHelper.fromWebFile(file)));
    },
    [column, onDropFiles],
  );

  return (
    <Droppable
      onDrop={onDrop(column)}
      onDropFiles={handleDroppedFiles}
      dropAcceptSystemFile={!isLocked}
      dropAcceptType={
        !isLocked
          ? [
              DraggableMessageType.MessageDocument,
              DraggableMessageType.MessageVideo,
              DraggableMessageType.MessageImage,
            ]
          : []
      }
      dropIndicatorText={t("depositHere")}
    >
      <View style={styles.container}>
        {items.length > 0 && (
          <FilesList
            attachments={items}
            isLoading={isLoading}
            context={listContext}
            allowDeletion={!isLocked}
            onDelete={handleDelete}
            onAttachmentClick={handleAttachmentClick}
          />
        )}
        {isLocked && items.length === 0 && (
          <LabelledActionCard
            color={ColorStyle.FONT_LOW_EMPHASIS}
            accessibilityLabel={t("modularity.select")}
            label={EMPTY_LOCKED_RECORD_PLACEHOLDER}
            iconName={KSchemaUtils.getIcon(column)}
            disabled
            hideChevron
          />
        )}
        {!isLocked && (
          <>
            <Button
              id={id}
              ref={importDocumentButtonRef}
              icon="file-plus-01"
              text={t("modularity.add")}
              onPress={
                recordType === "formPreview"
                  ? handleOpenPicker
                  : openImportImageMethodSheet
              }
              disabled={disabled}
              variant="QUATERNARY"
              alignContent="left"
            />
            <ActionSheetHost
              anchor={importDocumentButtonRef}
              open={isImportImageMethodSheetOpen}
              onClose={closeImportImageMethodSheet}
            >
              <ImportDocumentMethodSheetContent
                onClose={closeImportImageMethodSheet}
                handleImageFromConversation={onAttachPhotoFromConversation}
                handleDocumentFromConversation={
                  onAttachDocumentFromConversation
                }
                onPickDocument={onPickDocuments(column)}
              />
            </ActionSheetHost>
          </>
        )}
      </View>
    </Droppable>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: Spacing.S8,
  },
});

export { ListAttachmentsEditor };
