import React from "react";
import { TouchableOpacity } from "react-native";

import { TrackableViewProps } from "@kraaft/shared/core/types";
import { Icon, IconName } from "@kraaft/ui";

import { styles } from "./sectionHeaderIconButton.styles";

export type SectionHeaderIconButtonProps = TrackableViewProps & {
  icon: IconName;
  iconColor?: string;
  onPress?: () => void;
};

export const SectionHeaderIconButton = React.forwardRef<
  TouchableOpacity,
  SectionHeaderIconButtonProps
>(({ accessibilityLabel, icon, iconColor, onPress }, ref) => {
  return (
    <TouchableOpacity
      accessibilityLabel={accessibilityLabel}
      ref={ref}
      style={styles.actionIconContainer}
      onPress={onPress}
    >
      <Icon
        name={icon}
        style={styles.actionIcon}
        color={iconColor ?? "FONT_LOW_EMPHASIS"}
        size="MEDIUM"
      />
    </TouchableOpacity>
  );
});
