import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { RoomSchemaVisibilitySelection } from "@kraaft/shared/components/roomSchemaVisibility/roomSchemaVisibilitySelection";
import { useEventDataGetterForToggleRoomSchema } from "@kraaft/shared/components/roomSchemaVisibility/useEventDataGetterForToggleRoomSchema";
import {
  RoomSchemaVisibilityOptimisticActions,
  RoomSchemaVisibilityOptimisticOperations,
} from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.optimistic";
import { RoomSchemaVisibility } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.state";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { TrackRoomSchemaVisibilityToggleSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

type EditSchemaVisibilityForRoomProps = {
  roomId: string;
  poolId: string;
  roomSchemaVisibility: RoomSchemaVisibility;
  source: TrackRoomSchemaVisibilityToggleSource;
  showModularFolderCount?: boolean;
};

const ConnectedEditSchemaVisibilityForRoom = ({
  roomId,
  poolId,
  roomSchemaVisibility,
  source,
  showModularFolderCount,
}: EditSchemaVisibilityForRoomProps) => {
  const dispatch = useDispatch();

  const getEventDataForSchemaId = useEventDataGetterForToggleRoomSchema({
    roomId,
    source,
  });

  const handleShowSchema = useCallback(
    (schemaId: string) => {
      trackEvent({
        eventName: "Enabled Room Schema",
        ...getEventDataForSchemaId(schemaId),
      });
      dispatch(
        RoomSchemaVisibilityOptimisticActions.addOperation(
          RoomSchemaVisibilityOptimisticOperations.show.create({
            targetId: roomId,
            schemaId: schemaId,
          }),
        ),
      );
    },
    [dispatch, getEventDataForSchemaId, roomId],
  );

  const handleHideSchema = useCallback(
    (schemaId: string) => {
      trackEvent({
        eventName: "Disabled Room Schema",
        ...getEventDataForSchemaId(schemaId),
      });
      dispatch(
        RoomSchemaVisibilityOptimisticActions.addOperation(
          RoomSchemaVisibilityOptimisticOperations.hide.create({
            targetId: roomId,
            schemaId: schemaId,
          }),
        ),
      );
    },
    [dispatch, getEventDataForSchemaId, roomId],
  );

  return (
    <RoomSchemaVisibilitySelection
      poolId={poolId}
      roomId={roomId}
      selectedSchemaIds={roomSchemaVisibility.whitelist}
      onShowSchema={handleShowSchema}
      onHideSchema={handleHideSchema}
      showModularFolderCount={showModularFolderCount}
    />
  );
};

export { ConnectedEditSchemaVisibilityForRoom };
