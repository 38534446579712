import { makeStyles } from "@mui/styles";

export const ANIMATION_DURATION = 200;

interface MakeStylesProps {
  zIndex?: number;
  visible?: boolean;
  hideRatherThanUnmount?: boolean;
  fitContent?: boolean;
}

export const useStyles = makeStyles({
  container: ({
    zIndex,
    visible,
    hideRatherThanUnmount,
    fitContent,
  }: MakeStylesProps) => ({
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    width: fitContent ? "fit-content" : undefined,
    zIndex: zIndex ?? 0,
    overflow: "hidden",
    pointerEvents: visible ? "auto" : "none",
    opacity: hideRatherThanUnmount ? (visible ? 1 : 0) : 1,
  }),
  "@global": {
    ".slideIn-enter-active": {
      transition: `transform ${ANIMATION_DURATION}ms ease-out`,
      transitionDelay: ".1s" /* 100ms delay to avoid visual glitches */,
    },
    ".slideIn-exit-active": {
      transition: `transform ${ANIMATION_DURATION}ms ease-in`,
    },

    ".slideIn-up-enter": {
      transform: "translateY(100%)",
    },
    ".slideIn-up-enter-active": {
      transform: "translateY(0%)",
    },
    ".slideIn-up-exit": {
      transform: "translateY(0%)",
    },
    ".slideIn-up-exit-active": {
      transform: "translateY(100%)",
    },

    ".slideIn-bottom-enter": {
      transform: "translateY(-100%)",
    },
    ".slideIn-bottom-enter-active": {
      transform: "translateY(0%)",
    },
    ".slideIn-bottom-exit": {
      transform: "translateY(0%)",
    },
    ".slideIn-bottom-exit-active": {
      transform: "translateY(-100%)",
    },

    ".slideIn-left-enter": {
      transform: "translateX(100%)",
    },
    ".slideIn-left-enter-active": {
      transform: "translateX(0%)",
    },
    ".slideIn-left-exit": {
      transform: "translateX(0%)",
    },
    ".slideIn-left-exit-active": {
      transform: "translateX(100%)",
    },

    ".slideIn-right-enter": {
      transform: "translateX(-100%)",
    },
    ".slideIn-right-enter-active": {
      transform: "translateX(0%)",
    },
    ".slideIn-right-exit": {
      transform: "translateX(0%)",
    },
    ".slideIn-right-exit-active": {
      transform: "translateX(-100%)",
    },
  },
});
