import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { StandaloneMetadataCondition } from "@kraaft/shared/core/generated/__generated/conditionTypes";
import { getStandaloneMetadataCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditions";
import { selectAllRooms } from "@kraaft/shared/core/modules/room/roomSelectors";
import { FilterOperator } from "@kraaft/web/src/components/filterMenu/filterMenuTypes";

import { useStyles } from "./exportFilterItem.styles";

interface Props {
  index: number;
  condition: StandaloneMetadataCondition;
  operator: FilterOperator;
}

const ExportFilterStandaloneMetadataItem = (props: Props) => {
  const { condition, index, operator } = props;
  const { t } = useTranslation();
  const rooms = useSelector(selectAllRooms);
  const classes = useStyles();

  const builtinCondition = getStandaloneMetadataCondition(
    "folder",
    condition.predicate,
  );
  const operand = t("filterTicket");

  if (!builtinCondition) {
    return null;
  }

  return (
    <div className={classes.container}>
      <span className={classes.operatorContainer}>
        <span className={classes.text}>
          {index === 0
            ? t("filterWhere")
            : t(operator === "and" ? "andFilter" : "orFilter")}
        </span>
      </span>

      <span className={classes.categoryContainer}>
        <span className={clsx(classes.text, classes.textHighlight)}>
          {operand}
        </span>
      </span>

      <span className={classes.filterContainer}>
        <span className={classes.text}>
          {t(builtinCondition.translationKey as any)}
        </span>
      </span>
    </div>
  );
};

export { ExportFilterStandaloneMetadataItem };
