import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { AiDirectorySection } from "@kraaft/shared/core/modules/aiDirectory/components/aiDirectorySection/aiDirectorySection";
import {
  formLoadingId,
  subscribeToForms,
  unsubscribeFromForms,
} from "@kraaft/shared/core/modules/form/formActions";
import { selectLoading } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { selectCurrentPoolLocation } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Preloader } from "@kraaft/ui";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { DaxiumSection } from "@kraaft/web/src/views/settings/manageIntegrations/daxiumSection/daxiumSection";
import { DeliveryNoteSection } from "@kraaft/web/src/views/settings/manageIntegrations/deliveryNoteSection/deliveryNoteSection";
import { InboundEmailSection } from "@kraaft/web/src/views/settings/manageIntegrations/inboundEmailSection/inboundEmailSection";

import { KizeoSection } from "./kizeoSection";
import { SharePointSection } from "./sharePointSection";

import { useSettingsStyles } from "../settings.styles";

const ManageIntegrations = () => {
  const dispatch = useDispatch();
  const settingsClasses = useSettingsStyles();

  const isLoading = useSelector(selectLoading(formLoadingId));
  const poolLocation = useSelector(selectCurrentPoolLocation);
  const { t } = useTranslation();
  const poolId = poolLocation?.poolId;

  useTrackPage("SettingsIntegrations");

  useEffect(() => {
    if (poolId) {
      dispatch(subscribeToForms({ poolId }));
      return () => {
        dispatch(unsubscribeFromForms({ poolId }));
      };
    }
  }, [dispatch, poolId]);

  return (
    <div className={settingsClasses.pageContainer}>
      <PageHeader
        title={t("settingsIntegrations")}
        titleElementId="settings-integrations-title"
      />
      {isLoading ? (
        <Preloader absoluteFill transparent />
      ) : (
        <>
          <KizeoSection />
          <SharePointSection />
          <InboundEmailSection />
          <DeliveryNoteSection />
          <AiDirectorySection />
          <DaxiumSection />
        </>
      )}
    </div>
  );
};

export { ManageIntegrations };
