import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useDispatch } from "react-redux";

import { LottieManager } from "@kraaft/shared/components/lottieManager";
import { animationDurations } from "@kraaft/shared/constants/animations";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { Text } from "@kraaft/ui";

import CreatingWorksiteLottie from "./assets/truck.json";

import { styles } from "./loaderCreatingPool.styles";

const LoaderCreatingPool = ({ hasInvited }: { hasInvited: boolean }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [text, setText] = useState(
    hasInvited
      ? t("onboarding.sendingInvitations")
      : t("onboarding.loadingCreatePool"),
  );

  useEffect(() => {
    const timeout1 = setTimeout(
      () => setText(t("onboarding.loadingCreatePool")),
      animationDurations.WAIT_FOR_CREATE_POOL / 2,
    );

    const timeout2 = setTimeout(
      () => dispatch(UserActions.acknowledgePoolOnboarding()),
      animationDurations.WAIT_FOR_CREATE_POOL,
    );

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [dispatch, t]);

  return (
    <View style={styles.container}>
      <LottieManager
        source={CreatingWorksiteLottie}
        style={styles.lottieContainer}
        loop
        autoPlay
      />
      <Text
        color="FONT_HIGH_EMPHASIS"
        size="H1"
        weight="bold"
        style={styles.text}
      >
        {text}
      </Text>
    </View>
  );
};

export { LoaderCreatingPool };
