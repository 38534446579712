import { makeStyles } from "@mui/styles";

import { opacify } from "@kraaft/shared/core/utils/colorsUtils";
import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  item: {
    padding: Spacing.S8,
  },
  warning: {
    fontSize: FontSize.MEDIUM,
    color: ColorStyle.ACTION_DESCTRUCTIVE,
    paddingLeft: Spacing.S16,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: 700,
    height: "100%",
    margin: "auto",
  },
  dropdowns: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    flexGrow: 1,
    padding: Spacing.S16,
  },
  title: {
    flexDirection: "row",
    paddingTop: 12,
    paddingBottom: Spacing.S8,
  },
  subtitle: {
    fontSize: FontSize.MEDIUM,
  },
  mockCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexGrow: 1,
    padding: Spacing.S16,
  },
  container: {
    padding: 12,
    width: "100%",
  },
  dialogContentContainer: {
    flex: 1,
  },
  dropdownContainer: {
    marginTop: Spacing.S8,
    width: "100%",
    height: 35,
  },
  mockCardContainer: {
    width: 350,
  },
  dropShadow: {
    boxShadow: "0px 5px 14px rgba(0, 0, 0, 0.12)",
  },
  buttons: {
    marginTop: 64,
    textAlign: "center",
    "& > button:last-of-type": {
      marginLeft: 40,
    },
  },
  helperTextContainer: {
    backgroundColor: opacify(Color.BLUE_AZURE, 0.077),
    borderRadius: 15,
    margin: Spacing.S8,
    padding: Spacing.S16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: `2px solid ${opacify(Color.BLUE_AZURE, 0.618)}`, // Fibonacci Color
  },
  helperTextWrapper: {
    display: "flex",
    flexDirection: "column",
    marginLeft: Spacing.S16,
  },
  helperTextTitle: {
    fontSize: FontSize.BODY,
    fontWeight: 500,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    marginBottom: Spacing.S8,
  },
  helperText: {
    fontSize: FontSize.BODY,
    fontWeight: 100,
    lineHeight: 1.5,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    whiteSpace: "pre-wrap",
    marginLeft: Spacing.S4,
  },
});
