import { RoomReportFiltersJson } from "@kraaft/shared/core/services/api";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export function trackGenerateConversationReport(
  roomId: string,
  isExternal: boolean,
  filters: RoomReportFiltersJson,
  roomMemberCount: number,
  selectedTemplateId?: string,
  selectedTemplateName?: string,
) {
  trackEvent({
    eventName: "Generate Conversation Report",
    room_id: roomId,
    is_shared: isExternal,
    content: filters.checkboxIds
      ? "whole_conversation"
      : filters?.dateRange
        ? "by_dates"
        : "by_records",
    memmber_count: roomMemberCount,
    include_documents: filters.includeDocuments,
    include_messages: filters.includeMessages,
    template_id: selectedTemplateId ?? "default",
    template_name: selectedTemplateName ?? "default",
    output_type: "direct_download",
  });
}
