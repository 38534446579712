import { ReactNode } from "react";
import clsx from "clsx";

import { useStyles } from "./detailedActionCardHoverWrapper.styles";

interface Props {
  children: ReactNode;
  active: boolean;
}

const DetailedActionCardHoverWrapper = ({ active, children }: Props) => {
  const classes = useStyles();

  return (
    <div className={clsx({ [classes.hoveredContainer]: active })}>
      {children}
    </div>
  );
};

export { DetailedActionCardHoverWrapper };
