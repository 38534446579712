import { put, select, takeEvery } from "typed-redux-saga/macro";

import {
  showError,
  showInfo,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { selectDirectory } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { setLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { fillDirectoryWithMessage } from "@kraaft/shared/core/modules/message/attach/attachMessageActions";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export function* fillDirectoryFromMessageDropSaga() {
  yield* takeEvery(fillDirectoryWithMessage, fillDirectoryWithMessageSaga);
}

function* fillDirectoryWithMessageSaga(
  action: ReturnType<typeof fillDirectoryWithMessage>,
) {
  const { loaderId, roomId, directoryId, messageId } = action.payload;

  const directory = yield* select(selectDirectory(directoryId));

  trackEvent({
    eventName: "Attach To Directory",
    room_id: roomId,
    element_count: 1,
    from: "message drag&drop",
    directory_id: directoryId,
    directory_name: directory?.name ?? "",
    media_extension: "unknown",
  });

  try {
    yield Api.addDirectoryFilesFromMessages({
      roomId,
      directoryId,
      messageIds: [messageId],
    });

    yield* put(setLoader({ id: loaderId, status: LoaderStatus.SUCCESS }));
    yield* put(showSuccess({ title: i18n.t("fillDirectorySuccess") }));
  } catch (error) {
    if (HttpError.isHttpErrorWithCode(error, "AllFilesAlreadyExist")) {
      yield* put(
        showInfo({
          title: i18n.t("attachInDirectoryAlert.allFilesAlreadyExist"),
        }),
      );
    } else if (HttpError.isHttpErrorWithCode(error, "NoSupportedFiles")) {
      yield* put(
        showError({ title: i18n.t("attachInDirectoryAlert.noSupportedFiles") }),
      );
    } else {
      yield* put(showError({ title: i18n.t("attachInDirectoryAlert.error") }));
    }
    yield* put(
      setLoader({ id: loaderId, status: LoaderStatus.FAILURE, error }),
    );
  }
}
