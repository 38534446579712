import { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { MapButtonContainer } from "@kraaft/shared/components/mapButton/container";
import { modularFolderHasGeolocation } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { selectSchemaHasGeolocationColumn } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { Spacing } from "@kraaft/ui";

interface Props {
  roomId: string;
  schemaId: string;
  modularFolders: Record<string, ModularFolder>;
}

export const ModularFoldersMapButton = ({
  roomId,
  schemaId,
  modularFolders,
}: Props) => {
  const hasGeolocationColumn = useSelector(
    selectSchemaHasGeolocationColumn(schemaId),
  );

  const showButton = useMemo(
    () =>
      hasGeolocationColumn ||
      Object.values(modularFolders).some(modularFolderHasGeolocation),
    [hasGeolocationColumn, modularFolders],
  );

  return showButton ? (
    <View style={styles.container}>
      <MapButtonContainer
        type="modularFolders"
        context={{ location: "ModularFolders", roomId, schemaId }}
      />
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: Spacing.S8,
  },
});
