import { StyleSheet, View } from "react-native";

import { EmptyState } from "@kraaft/shared/components/emptyState";
import { Color } from "@kraaft/ui";

export const EmptyModularFolderHistory = () => {
  return (
    <View style={styles.container}>
      <EmptyState type="modularFolderHistory" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Color.WHITE,
  },
});
