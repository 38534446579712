import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Tooltip } from "@kraaft/shared/components/tooltip";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { useSignoutAlert } from "@kraaft/shared/core/modules/user/useSignoutAlert";
import { ButtonElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/buttonElement";

const LogoutElement = () => {
  const { t } = useTranslation();

  const openSignoutAlert = useSignoutAlert();
  const username = useSelector(selectCurrentUser)?.username;

  return (
    <Tooltip
      title={`${t("loggedInAs")} ${username}`}
      placement="top-start"
      enterDelay={2000}
    >
      <div>
        <ButtonElement
          id="navbar-logout"
          icon="log-out-02"
          text={t("menuDisconnect")}
          onPress={openSignoutAlert}
        />
      </div>
    </Tooltip>
  );
};

export { LogoutElement };
