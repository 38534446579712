import React from "react";

import { CheckboxGuardComposition } from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuardComposition";
import { CheckboxGuardSheet } from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuardSheet";
import { SectionLockInfo } from "@kraaft/shared/core/modules/schema/lockInfo.utils";

export type CheckboxGuardStrategy =
  | "cantUnlockNotAllowed"
  | "cantUnlockParentLocked"
  | "cantLockParentLocked"
  | "checkConfirmation"
  | "uncheckConfirmation"
  | "lockConfirmation"
  | "unlockConfirmation"
  | "cantCheckParentLocked"
  | "cantUncheckParentLocked";

interface CheckboxGuardProps {
  strategy: CheckboxGuardStrategy | undefined;
  sectionLockInfo: SectionLockInfo;
  onConfirm: () => void;
  onClose: () => void;
  isInRoom?: boolean;
  columnName: string;
}

export const _CheckboxGuard = ({
  strategy,
  sectionLockInfo,
  onConfirm,
  onClose,
  isInRoom = false,
  columnName,
}: CheckboxGuardProps) => {
  return (
    <CheckboxGuardSheet.Host open={!!strategy} onClose={onClose} size="SMALL">
      <CheckboxGuardComposition
        strategy={strategy}
        sectionLockInfo={sectionLockInfo}
        onConfirm={onConfirm}
        onClose={onClose}
        isInRoom={isInRoom}
        columnName={columnName}
      />
    </CheckboxGuardSheet.Host>
  );
};

export const CheckboxGuard = React.memo(_CheckboxGuard);
