import { useTranslation } from "react-i18next";

import { useDeleteSignature } from "@kraaft/shared/components/signature/signatureViewer/useDeleteSignature";
import { Button } from "@kraaft/ui";

interface SignatureDeleteButtonProps {
  onDelete: () => void;
}

const SignatureDeleteButton = ({ onDelete }: SignatureDeleteButtonProps) => {
  const { t } = useTranslation();
  const { askDelete, dialog } = useDeleteSignature(onDelete);

  return (
    <>
      <Button
        variant="SECONDARY"
        accessibilityLabel={t("signature.viewer.erase")}
        text={t("signature.viewer.erase")}
        destructive
        onPress={askDelete}
        icon="trash-03"
      />
      {dialog}
    </>
  );
};

export { SignatureDeleteButton };
