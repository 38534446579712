import { StyleSheet } from "react-native";

import { ensureValidStyleSheet } from "@kraaft/shared/core/utils";
import { Spacing } from "@kraaft/ui";

export const actionGroupStyleObject = {
  buttonsContainerVertical: {
    flexDirection: "column",
    gap: Spacing.S8,
  },
  buttonsContainerHorizontal: {
    flexDirection: "row-reverse",
    gap: Spacing.S16,
  },
  buttonsContainerVerticalReversed: {
    flexDirection: "column-reverse",
    gap: Spacing.S8,
  },

  buttonsContainerNoPadding: {
    padding: Spacing.NONE,
  },
};

export const styles = StyleSheet.create(
  ensureValidStyleSheet(actionGroupStyleObject),
);
