import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useMeshContext } from "@kraaft/helper-hooks";
import { RoomFiltersContext } from "@kraaft/shared/components/filters/roomFilters.context";
import { RoomFiltersScrollableList } from "@kraaft/shared/components/filters/roomFiltersScrollableList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import {
  selectAllPoolUsers,
  selectCurrentUserId,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { UserPoolInfos } from "@kraaft/shared/core/modules/user/userState";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { type TFunction } from "@kraaft/shared/core/services/i18next";
import { compareStrings, getEmptyArray } from "@kraaft/shared/core/utils";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";

function getFriendlyUserLabelFromUserPools({
  userId,
  username,
  currentUserId,
  currentPoolId,
  userPools,
  t,
}: {
  userId: string;
  username: string;
  currentUserId: string | undefined;
  currentPoolId: string;
  userPools: Record<string, UserPoolInfos>;
  t: TFunction;
}) {
  const currentPoolInfo = userPools[currentPoolId];

  if (currentPoolInfo === undefined) {
    return username;
  }

  if (userId === currentUserId) {
    return `${username} (${t("you")})`;
  }

  if (currentPoolInfo.role === UserPoolRole.EXTERNAL) {
    return `${username} (${t("externalShort")})`;
  }

  return username;
}

interface RoomFiltersResponsibleProps {
  onClose: () => void;
  onBack: () => void;
}

export const RoomFiltersResponsible = ({
  onClose,
  onBack,
}: RoomFiltersResponsibleProps) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");

  const {
    poolId,
    responsibleId,
    setResponsibleId,
    resetResponsibleId,
    isDefaultResponsibleId,
  } = useMeshContext(RoomFiltersContext);
  const currentUserId = useSelector(selectCurrentUserId);
  const poolUsers = useSelector(selectAllPoolUsers(poolId));

  const handleSelect = useCallback(
    ([newResponsibleId]: string[]) => {
      if (newResponsibleId !== undefined) {
        setResponsibleId(newResponsibleId);
      }
    },
    [setResponsibleId],
  );

  const userList = useMemo(() => Object.entries(poolUsers), [poolUsers]);

  const items = useMemo(
    () =>
      userList
        .filter(([, { username }]) =>
          normalizeTextForSearch(username).includes(
            normalizeTextForSearch(searchText),
          ),
        )
        .map<KSelectableListItem>(([userId, { username, pools }]) => ({
          identifier: userId,
          label:
            getFriendlyUserLabelFromUserPools({
              userId,
              username,
              userPools: pools,
              currentPoolId: poolId,
              currentUserId,
              t,
            }) ?? username,
          icon: "user-01",
        }))
        .sort((a, b) => compareStrings(a.label, b.label)),
    [currentUserId, poolId, searchText, t, userList],
  );

  const selected = useMemo(() => {
    if (responsibleId !== undefined) {
      return [responsibleId];
    }
    return getEmptyArray<string>();
  }, [responsibleId]);

  return (
    <RoomFiltersScrollableList
      headerTitle={t("roomFilters.responsible")}
      items={items}
      selected={selected}
      setSelected={handleSelect}
      isMultiple={false}
      onFiltered={onClose}
      onErase={resetResponsibleId}
      canErase={!isDefaultResponsibleId}
      onBack={onBack}
      withSearchBar
      searchText={searchText}
      setSearchText={setSearchText}
      totalItemCount={userList.length}
    />
  );
};
