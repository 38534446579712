import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentContextOwnerOrSuperadmin } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { isUserSuperadmin } from "@kraaft/shared/core/modules/user/userUtils";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { SettingsCompany } from "@kraaft/web/src/views/settings/managePool/settingsCompany";
import { SettingsCurrency } from "@kraaft/web/src/views/settings/managePool/settingsCurrency";
import { SettingsLanguage } from "@kraaft/web/src/views/settings/managePool/settingsLanguage";
import { SettingsName } from "@kraaft/web/src/views/settings/managePool/settingsName";
import { SettingsSSO } from "@kraaft/web/src/views/settings/managePool/settingsSSO";
import { SettingsTimeZone } from "@kraaft/web/src/views/settings/managePool/settingsTimeZone/settingsTimeZone";

import { SettingsLogo } from "./settingsLogo";

import { useSettingsStyles } from "../settings.styles";

const ManagePool = () => {
  const { t } = useTranslation();
  const settingsClasses = useSettingsStyles();

  const pool = useSelector(selectCurrentPool);
  const isPoolOwnerOrSuperadmin = useSelector(
    selectCurrentContextOwnerOrSuperadmin,
  );
  const isSuperadmin = isUserSuperadmin(useSelector(selectCurrentUser));

  useTrackPage("SettingsPool");

  return (
    <div className={settingsClasses.pageContainer}>
      <PageHeader
        titleElementId="settings-workspace-title"
        title={t("settingsPool")}
      />

      <SettingsName isSuperadmin={isSuperadmin} pool={pool} />

      <SettingsLogo
        isPoolOwnerOrSuperadmin={isPoolOwnerOrSuperadmin}
        pool={pool}
        poolId={pool?.id}
      />

      <SettingsLanguage
        isPoolOwnerOrSuperadmin={isPoolOwnerOrSuperadmin}
        pool={pool}
        poolId={pool?.id}
      />

      {isPoolOwnerOrSuperadmin && <SettingsCurrency pool={pool} />}
      {isPoolOwnerOrSuperadmin && <SettingsTimeZone pool={pool} />}
      {isSuperadmin && pool && <SettingsCompany pool={pool} />}
      {isSuperadmin && pool && <SettingsSSO pool={pool} />}
    </div>
  );
};

export { ManagePool };
