import { useScrollbarWidth as useScrollbarWidthBase } from "react-use";

export let SCROLLBAR_WIDTH = 0;

export function useInitScrollbarWidth() {
  const width = useScrollbarWidthBase();

  SCROLLBAR_WIDTH = width ?? 0;

  return SCROLLBAR_WIDTH;
}
