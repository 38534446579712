import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { ModularRecordProperties } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

function getDuplicateTitle(
  schema: KSchema | undefined,
  properties: ModularRecordProperties | undefined,
) {
  if (!schema || schema.collection !== "folder") {
    return "";
  }
  const titleColumn = schema
    ? KSchemaUtils.getColumnByKey(
        schema.rootSection,
        KSchemaRemarkableColumns.TITLE,
      )
    : undefined;
  if (!titleColumn) {
    return "";
  }
  if (KSchemaUtils.isColumnAutomated(titleColumn)) {
    return `${schema.name} #${schema.autoIncrement + 1}`;
  }
  return i18n.t("modularFolderCopy", {
    title:
      typeof properties?.title?.value === "string"
        ? properties?.title?.value
        : "",
  });
}

export const duplicateOperation = Operation.createOne()
  .payload<{
    modularFolderId: string;
  }>({
    replaceId(payload, oldId, newId) {
      if (payload.modularFolderId === oldId) {
        payload.modularFolderId = newId;
      }
    },
    gatherExternalIds(payload) {
      return [payload.modularFolderId];
    },
  })
  .dependsOn<{
    getModularFolder(id: string): ModularFolder | undefined;
    getSchema(id: string): KSchema | undefined;
  }>()
  .augment((payload, { getModularFolder, getSchema }) => {
    const modularFolder = getModularFolder(payload.modularFolderId);
    if (!modularFolder) {
      return {};
    }
    const schema = getSchema(modularFolder.schemaId);
    return {
      poolId: modularFolder.poolId,
      roomId: modularFolder.roomId,
      schemaId: modularFolder.schemaId,
      automatedTitle: getDuplicateTitle(schema, modularFolder.properties),
      properties: modularFolder.properties,
    };
  })
  .expected((payload) => {
    const modularFolder: ModularFolder = {
      poolId: payload.poolId ?? "",
      roomId: payload.roomId ?? "",
      schemaId: payload.schemaId ?? "",
      geolocation: undefined,
      incrementalId: -1,
      index: -1,
      updatedAt: new Date(),
      properties: {
        ...payload.properties,
        title: {
          value: payload.automatedTitle ?? "",
          columnType: KColumnType.shortText,
        },
      },
      id: payload.id,
      pendingOptimisticOperations: 1,
    };
    return modularFolder;
  })
  .mutate((payload, task) =>
    Api.duplicateModularFolder({
      requestId: task.id,
      folderId: payload.modularFolderId,
    }),
  );
