import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import times from "lodash/times";

import { isNative } from "@kraaft/helper-functions";
import { DraggableMessage } from "@kraaft/shared/components/draggableMessage";
import { DraggableMessageType } from "@kraaft/shared/components/draggableMessage/types";
import { getMaxWidthOfMessage } from "@kraaft/shared/components/message/messageSize";
import { TextBubble } from "@kraaft/shared/components/message/messageText/textBubble";
import { MessageWithHeader } from "@kraaft/shared/components/message/messageWithHeader";
import { getUsernameFromUserId } from "@kraaft/shared/components/textInputWithMentions/useMentionAwareTextState";
import { InputPartitionHelper } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { AudioPlayerStatus } from "@kraaft/shared/core/modules/audio/player/audioPlayer";
import { audioPlayer } from "@kraaft/shared/core/modules/audio/player/audioPlayer.provider";
import { useLocalPathFromFilePath } from "@kraaft/shared/core/modules/file/fileAllocator/useLocalPathFromFilePath";
import { MessageTypes } from "@kraaft/shared/core/modules/message";
import { messageHasReactions } from "@kraaft/shared/core/modules/message/messageUtils";
import { Color, ColorStyle, Icon, IconName } from "@kraaft/ui";

import {
  formatDraggableText,
  isReplyOrForward,
  MessageRendererProps,
} from "../messageUtils";

import { styles as baseStyles } from "../message.styles";
import { styles } from "./messageAudio.styles";

type Props = MessageRendererProps<MessageTypes.AudioMessage> & {
  sizerWidth: number;
};

const useColors = ({
  isMessageReplyOrForward,
  isReply,
}: {
  isMessageReplyOrForward: boolean;
  isReply: boolean;
}) => {
  const colorVariant = isMessageReplyOrForward
    ? "reply-in-bubble"
    : isReply
      ? "reply"
      : "dark";
  const backWavesColor =
    colorVariant === "reply" || colorVariant === "reply-in-bubble"
      ? Color.GREY_TAUPE
      : Color.BLUE_VISTA;
  const wavesColor =
    colorVariant === "reply" || colorVariant === "reply-in-bubble"
      ? Color.GREY_JET
      : Color.WHITE;
  const waveBackgroundColor =
    colorVariant === "reply-in-bubble"
      ? Color.WHITE
      : colorVariant === "reply"
        ? ColorStyle.BACKGROUND_STANDARD
        : Color.BLUE_SAVOY;
  return { backWavesColor, wavesColor, waveBackgroundColor };
};

const getWaveIconFromIndex = (index: number) =>
  index % 2 === 0 ? "recording-02" : "recording-01";

const MessageAudio_ = (props: Props) => {
  const { roomId, message, answer, onLongPress, sizerWidth } = props;
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState<AudioPlayerStatus>(
    AudioPlayerStatus.PAUSED,
  );

  const { isReply, attachment } = message;

  const isMessageReplyOrForward = isReplyOrForward(message);

  const localPath = useLocalPathFromFilePath(attachment.original.downloadUrl);

  const toggleAudio = useCallback(async () => {
    if (localPath) {
      audioPlayer.toggle(localPath, setProgress, setStatus);
    }
  }, [localPath]);

  useEffect(() => {
    if (localPath) {
      return () => {
        audioPlayer.cleanup(localPath).catch(console.error);
      };
    }
  }, [localPath]);

  const icon: IconName =
    status === AudioPlayerStatus.PAUSED ? "play-fill" : "pause";

  const containerStyle = useMemo(
    () => [
      baseStyles.messageBox,
      isReply ? styles.containerReply : styles.containerDefault,
      { maxWidth: getMaxWidthOfMessage(isNative(), sizerWidth) },
    ],
    [isReply, sizerWidth],
  );

  const playerContainerStyle = useMemo(
    () => [
      styles.playerContainer,
      isMessageReplyOrForward && styles.playerContainerWithinBox,
      isReply ? styles.playerContainerReply : styles.playerContainerDefault,
    ],
    [isMessageReplyOrForward, isReply],
  );

  const wavesContainerStyle = useMemo(
    () => [
      styles.wavesContainer,
      isReply ? styles.wavesContainerReply : styles.wavesContainerDefault,
    ],
    [isReply],
  );

  const { backWavesColor, wavesColor, waveBackgroundColor } = useColors({
    isMessageReplyOrForward,
    isReply,
  });

  const progressStyle = useMemo(
    () => [styles.progress, { width: `${progress || 100}%` as const }],
    [progress],
  );

  const handleLongPress = useCallback(() => {
    onLongPress?.(message);
  }, [message, onLongPress]);

  return (
    <View style={containerStyle}>
      <MessageWithHeader
        roomId={roomId}
        message={message}
        answerToMessage={answer}
        onLongPressPreview={handleLongPress}
        withBubbleStyle={false}
        fitContent
      >
        <View style={playerContainerStyle}>
          <View
            style={[
              wavesContainerStyle,
              { backgroundColor: waveBackgroundColor },
            ]}
          >
            <View style={[styles.waves, styles.wavesOpacity]}>
              {times(5, (index) => (
                <Icon
                  key={index}
                  name={getWaveIconFromIndex(index)}
                  size="MEDIUM"
                  color={backWavesColor}
                />
              ))}
            </View>
            <View style={progressStyle}>
              <View style={styles.waves}>
                {times(5, (index) => (
                  <Icon
                    key={index}
                    name={getWaveIconFromIndex(index)}
                    size="MEDIUM"
                    color={wavesColor}
                  />
                ))}
              </View>
            </View>
          </View>

          <TouchableOpacity
            accessibilityLabel={t("audioRecord")}
            onPress={toggleAudio}
          >
            <View
              style={[styles.button, { backgroundColor: waveBackgroundColor }]}
            >
              <Icon name={icon} size="MINI" color={wavesColor} />
            </View>
          </TouchableOpacity>
        </View>
      </MessageWithHeader>

      {InputPartitionHelper.isNotEmpty(attachment.transcription) ? (
        <View style={containerStyle}>
          <TextBubble
            isReply={isReply}
            text={attachment.transcription}
            headerText={t("transcription")}
            modifiedText={
              attachment.transcriptionModifiedAt ? t("edited") : undefined
            }
            style={baseStyles.messageSpacer}
            hasReactions={messageHasReactions(message)}
            isFontSizeLargeBody
            draggableElement={
              <DraggableMessage
                messageId={message.id}
                messageType={DraggableMessageType.MessageText}
                renderText={() =>
                  formatDraggableText(
                    message.createdAt,
                    attachment.transcription
                      ? InputPartitionHelper.inputPartitionsToText(
                          attachment.transcription,
                          getUsernameFromUserId,
                        )
                      : "",
                  )
                }
                style={StyleSheet.absoluteFillObject}
              />
            }
          />
        </View>
      ) : null}
    </View>
  );
};

export const MessageAudio = React.memo(MessageAudio_);
