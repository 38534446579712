import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import compact from "lodash/compact";

import { OverlayMenu } from "@kraaft/shared/components/overlayMenu";
import { compareStrings } from "@kraaft/shared/core/utils/index.web";
import {
  FilteredList,
  FilteredListItem,
  ForwardedFilteredListProp,
} from "@kraaft/web/src/components/dropdown/filteredList";
import { DropdownVariant } from "@kraaft/web/src/components/dropdown/kDropdownButton/kDropdownButton";

import { KDropdownButton } from "./kDropdownButton";

import { useStyles } from "./kDropdown.styles";

export interface KDropdownProps<T extends string = string>
  extends ForwardedFilteredListProp {
  id?: string;
  items: FilteredListItem<T>[];
  selectedItemIds?: T[];
  allowMultiple?: boolean;
  alwaysOpen?: boolean;

  onSelectionChange?: (newValue: T[] | undefined) => void;
  variant?: DropdownVariant;
  onClose?: () => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  testID?: string;
}

const KDropdown = <T extends string>(props: KDropdownProps<T>) => {
  const {
    items,
    selectedItemIds = [],
    allowMultiple = false,
    alwaysOpen = false,
    variant = "light",
    onSelectionChange,
    onClose,
    label,
    placeholder,
    disabled = false,
    testID,
    ...filterListProps
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const anchorRef = useRef(null);

  const [overlayOpen, setOverlayOpen] = useState(false);

  const selectedItems = useMemo(() => {
    return compact(
      selectedItemIds.map((selectedItemId) =>
        items.find((_item) => _item.value === selectedItemId),
      ),
    );
  }, [items, selectedItemIds]);

  const valuesContent = useMemo(() => {
    if (label !== undefined) {
      return <span className={classes.value}>{label}</span>;
    }
    if (selectedItems.length === 0) {
      return <span className={classes.valueEmpty}>{placeholder}</span>;
    }
    return [...selectedItems]
      .sort((a, b) => compareStrings(a.label, b.label))
      .map((item, index) => {
        const isNotLast = index < selectedItems.length - 1;

        return (
          <span
            key={item.value}
            className={classes.value}
            style={isNotLast ? { marginRight: 4 } : undefined}
          >
            {item.label}
            {isNotLast ? "," : ""}
          </span>
        );
      });
  }, [classes.value, classes.valueEmpty, label, placeholder, selectedItems]);

  const handleOpen = useCallback(() => {
    setOverlayOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOverlayOpen(false);
    onClose?.();
  }, [onClose]);

  const handleItemClick = useCallback(
    (item: FilteredListItem<T> | undefined) => {
      const computeMultiple = (selectedIds: T[], selectedId?: T) => {
        if (selectedId === undefined) {
          return selectedIds;
        }
        const index = selectedIds.indexOf(selectedId);

        if (index >= 0) {
          const newArray = [...selectedIds];
          newArray.splice(index, 1);
          return newArray;
        }
        return [...selectedIds, selectedId];
      };
      const newValue =
        item === undefined
          ? undefined
          : allowMultiple
            ? computeMultiple(selectedItemIds, item.value)
            : [item.value];

      onSelectionChange?.(newValue);

      if (!allowMultiple) {
        handleClose();
      }
    },
    [allowMultiple, selectedItemIds, onSelectionChange, handleClose],
  );

  return (
    <>
      <KDropdownButton
        testID={testID}
        ref={anchorRef}
        content={valuesContent}
        onClick={handleOpen}
        disabled={disabled}
        variant={variant}
      />
      <OverlayMenu
        visible={alwaysOpen || overlayOpen}
        onClose={handleClose}
        anchorRef={anchorRef}
        isDropdown={filterListProps.fullWidth}
      >
        {items.length === 0 ? (
          <span className={classes.emptyList}>{t("emptyList")}</span>
        ) : (
          <FilteredList
            testID={`${testID}-items`}
            items={items}
            onItemClick={handleItemClick}
            withMultiSelect={allowMultiple}
            selectedItemIds={selectedItemIds}
            {...filterListProps}
          />
        )}
      </OverlayMenu>
    </>
  );
};

export { KDropdown };
