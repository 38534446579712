import React from "react";
import { View } from "react-native";

import { KSelectionListItemDetailed } from "@kraaft/shared/components/kSelectionList/kSelectionListItemDetailed";
import { Color } from "@kraaft/ui";

export interface MemberItemProps {
  userId: string;
  username: string;
  details?: string;
}

const MemberItem = React.forwardRef<View, MemberItemProps>(
  ({ userId, username, details }, forwardedRef) => (
    <KSelectionListItemDetailed
      ref={forwardedRef}
      noPadding
      item={{
        identifier: userId,
        label: username,
        icon: "user-01",
        value: {
          details: details,
          avatarProps: {
            type: "icon",
            name: "user-01",
            size: "small",
            backgroundColor: Color.TRANSPARENT,
          },
        },
      }}
    />
  ),
);

export { MemberItem };
