import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";

import { useBasicCellStyles } from "./basicCell.styles";

export const BasicCell = ({
  value,
  isCellSelected,
}: IBasicCellProps<
  KColumnType.shortText | KColumnType.automatedAutoIncrement
>) => {
  const classes = useBasicCellStyles({ isCellSelected });

  return <span className={classes.cellCursor}>{value}</span>;
};
