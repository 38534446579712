import {
  ReactNode,
  RefObject,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { Animated, StyleProp, ViewStyle } from "react-native";

import { HapticService } from "@kraaft/shared/core/services/haptic";
import { Color } from "@kraaft/ui";

export interface AttentionSeekerHandle {
  seekAttention(): void;
}

interface AttentionSeeker {
  children: ReactNode;
  handle: RefObject<AttentionSeekerHandle>;
  style?: StyleProp<ViewStyle>;
}

export const AttentionSeeker = ({
  children,
  handle,
  style,
}: AttentionSeeker) => {
  const animation = useRef(new Animated.Value(0)).current;

  const seekAttention = useCallback(() => {
    HapticService.renderFeedback("info-light").catch(console.error);
    Animated.sequence([
      Animated.timing(animation, {
        useNativeDriver: false,
        toValue: 1,
        duration: 400,
      }),
      Animated.delay(500),
      Animated.timing(animation, {
        useNativeDriver: false,
        toValue: 0,
        duration: 400,
      }),
    ]).start();
  }, [animation]);

  const backgroundColor = useMemo(
    () =>
      animation.interpolate({
        inputRange: [0, 1],
        outputRange: [`${Color.BLUE_LAVENDER}00`, `${Color.BLUE_LAVENDER}FF`],
      }),
    [animation],
  );

  useImperativeHandle(handle, () => ({
    seekAttention,
  }));

  return (
    <Animated.View style={[{ backgroundColor: backgroundColor }, style]}>
      {children}
    </Animated.View>
  );
};
