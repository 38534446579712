import clsx from "clsx";

import { Button } from "@kraaft/ui";
import { KPopupProps } from "@kraaft/web/src/components/kPopup/kPopup.types";
import { useStyles } from "@kraaft/web/src/components/kPopup/kPopupContained.styles";

const KPopupContained = ({
  maxWidth,
  fullHeight,
  onClose,
  children,
}: KPopupProps) => {
  const classes = useStyles({ maxWidth });

  return (
    <div
      className={clsx(classes.container, {
        [classes.fullHeightContainer]: fullHeight,
      })}
    >
      <div className={classes.crossContainer}>
        <Button
          icon="x-close"
          onPress={onClose}
          size="SMALL"
          variant="TERTIARY"
        />
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export { KPopupContained };
