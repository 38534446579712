import { useMemo } from "react";
import { GestureResponderEvent, Pressable, View } from "react-native";

import { styles } from "@kraaft/shared/components/roundSelectButton/roundSelectButton.styles";
import { TrackableViewProps } from "@kraaft/shared/core/types";

export type RoundSelectButtonProps = TrackableViewProps & {
  selected?: boolean;
  onPress: (event: GestureResponderEvent) => void;
  children: React.ReactNode;
  nativeId?: string;
};

const RoundSelectButton = ({
  accessibilityLabel,
  children,
  onPress,
  selected,
  nativeId,
}: RoundSelectButtonProps) => {
  const finalStyles = useMemo(() => {
    return [styles.root, selected && styles.selected];
  }, [selected]);

  return (
    <View style={finalStyles} nativeID={nativeId}>
      <Pressable accessibilityLabel={accessibilityLabel} onPress={onPress}>
        <View style={styles.child}>{children}</View>
      </Pressable>
    </View>
  );
};

export { RoundSelectButton };
