import { createAction } from "@reduxjs/toolkit";

import { Form } from "@kraaft/shared/core/modules/form/formState";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { createLoaderMeta } from "@kraaft/shared/core/modules/loaders/loaderUtils";

export const formLoadingId = "forms";

export const subscribeToForms = createAction(
  "@forms/SUBSCRIBE_TO_FORMS_REQUEST",
  (payload: { poolId: string }) => ({
    payload,
    meta: createLoaderMeta({ id: formLoadingId, status: LoaderStatus.LOADING }),
  }),
);

export const subscribeToFormsFailure = createAction(
  "@forms/SUBSCRIBE_TO_FORMS_FAILURE",
  (payload: { poolId: string; error: Error }) => ({
    payload,
    meta: createLoaderMeta({
      id: formLoadingId,
      status: LoaderStatus.FAILURE,
      error: payload.error,
    }),
  }),
);

export const subscribeToFormsSnapshot = createAction(
  "@forms/SUBSCRIBE_TO_FORMS_SNAPSHOT",
  (payload: { poolId: string; forms: Form[] }) => ({
    payload,
    meta: createLoaderMeta({ id: formLoadingId, status: LoaderStatus.SUCCESS }),
  }),
);

export const unsubscribeFromForms = createAction<{ poolId: string }>(
  "@forms/UNSUBSCRIBE_FROM_FORMS",
);

export const disconnectKizeoForms = createAction<{ poolId: string }>(
  "@forms/DISCONNECT_KIZEO_FORMS",
);
