import { analytics } from "@kraaft/shared/core/services/analytics";
import { JsonMap } from "@kraaft/shared/core/services/segmentAnalytics";
import { TrackingEventName } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

import { TrackingEvent } from "./trackingEvent.types";

// Use this within Sagas only (prefer useTrackEventHelper otherwise)
export const trackEvent = <T extends TrackingEventName>(
  event: TrackingEvent<T>,
) => {
  const { eventName, ...data } = event;

  analytics.track(eventName, data as unknown as JsonMap).catch(console.error);
};
