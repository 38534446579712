import { useCallback } from "react";

import { FilePath } from "@kraaft/shared/core/modules/file/file";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { CarouselIconButton } from "@kraaft/ui";

interface Props {
  filename: string;
  path: FilePath;
}

export const DownloadToolbar = (props: Props) => {
  const { filename, path } = props;

  const handleDownload = useCallback(() => {
    fileSaver.download(path, filename).catch(console.error);
  }, [filename, path]);

  return (
    <CarouselIconButton
      icon="download-01"
      onPress={handleDownload}
      accessibilityLabel="download"
    />
  );
};
