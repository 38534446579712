import { useCallback, useMemo, useRef } from "react";
import { View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { PillWrapper } from "@kraaft/shared/components/fileCard/pillWrapper";
import {
  ActionSheetItem,
  LegacyActionSheet,
} from "@kraaft/shared/components/legacyActionSheet";
import { Pill } from "@kraaft/shared/components/pill";
import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { ColorStyle } from "@kraaft/ui";

import { styles } from "./moreDirectories.styles";

interface MoreDirectoriesProps {
  roomId: string;
  directories: Directory[];
}

const MoreDirectories = ({ roomId, directories }: MoreDirectoriesProps) => {
  const ref = useRef(null);
  const navigationService = useNavigationService();
  const [open, setOpen, setClosed] = useBooleanState();

  const goToDirectory = useCallback(
    (directoryId: string) => {
      if (isNative()) {
        return;
      }
      navigationService.navigate("RoomDirectory", {
        directoryId,
        roomId,
      });
    },
    [navigationService, roomId],
  );

  const moreDirectories = useMemo<ActionSheetItem[]>(
    () =>
      directories.slice(1).map((directory) => ({
        label: directory.name,
        onPress: () => goToDirectory(directory.id),
        icon: "folder",
      })),
    [directories, goToDirectory],
  );

  const firstDirectory = directories[0];

  const goToFirstDirectory = useCallback(() => {
    if (firstDirectory) {
      goToDirectory(firstDirectory.id);
    }
  }, [firstDirectory, goToDirectory]);

  if (!firstDirectory) {
    return null;
  }

  return (
    <>
      <View style={styles.pill}>
        <PillWrapper>
          <Pill
            onPress={
              !isNative() && firstDirectory ? goToFirstDirectory : undefined
            }
            content={firstDirectory.name}
            icon="folder"
            size="small"
            color={ColorStyle.BACKGROUND_STANDARD}
          />
        </PillWrapper>
      </View>
      {directories.length > 1 && (
        <>
          <View ref={ref} style={styles.morePill}>
            <PillWrapper>
              <Pill
                onPress={setOpen}
                content={`+${directories.length}`}
                icon="folder"
                size="small"
                color={ColorStyle.BACKGROUND_STANDARD}
              />
            </PillWrapper>
          </View>
          <LegacyActionSheet
            onClose={setClosed}
            open={open}
            options={moreDirectories}
            anchorRef={ref}
          />
        </>
      )}
    </>
  );
};

export { MoreDirectories };
