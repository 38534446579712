import { useCallback, useState } from "react";
import { Table, TableBody, TableContainer } from "@mui/material";
import clsx from "clsx";

import { Color, Preloader } from "@kraaft/ui";

import { KTableHeader } from "./kTableHeader";
import { KTableRow } from "./kTableRow";
import { KTableColumn, KTableConfig, Order } from "./kTableTypes";
import { getSorting, stableSort } from "./kTableUtils";

import {
  kTableStyles,
  makeKTableCellStyle,
  makeKTableRowStyle,
  makeKTableStyle,
} from "./ktable.styles";

type KTableProps<T> = {
  data: Array<T>;
  extractKey: (item: T) => string;
  config: KTableConfig<T>;
  loading?: boolean;
  preventScroll?: boolean;
  tableContainerClassName?: string;
};

const TABLE_LOADER = (
  <Preloader
    backgroundColor={Color.TRANSPARENT}
    size="small"
    style={kTableStyles.loader}
  />
);

const KTable = <T,>({
  data,
  extractKey,
  config,
  config: {
    columns = [],
    defaultOrder = Order.NONE,
    defaultOrderBy,
    autoLayout,
    enableHover = false,
  },
  loading,
  preventScroll,
  tableContainerClassName,
}: KTableProps<T>) => {
  const [selectedRow, setSelectedRow] = useState<{ [key: number]: boolean }>(
    {},
  );
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = useState<string>(
    defaultOrderBy ? defaultOrderBy : columns[0]?.id ?? "",
  );
  const classes = makeKTableStyle();
  const tableRowClasses = makeKTableRowStyle({
    hover: config?.style?.hover,
    selected: config?.style?.selected,
  });
  const tableCellClasses = makeKTableCellStyle({
    hover: config?.style?.hover,
  });

  const handleRequestSort = (property: string) => {
    const loopOrder = (value: number, nbValues: number) => {
      const nextValue = (value + 1) % nbValues;
      return nextValue === nbValues - 1 ? nextValue - nbValues : nextValue;
    };

    const shouldUpdateOrder = orderBy === property || order === Order.NONE;

    if (shouldUpdateOrder) {
      setOrder(loopOrder(order, 3));
    }
    setOrderBy(property);
  };

  const handleRowSelected = useCallback(
    (rowIndex: number) => (state: boolean) =>
      setSelectedRow((oldSelectedRow) => ({
        ...oldSelectedRow,
        [rowIndex]: state,
      })),
    [setSelectedRow],
  );

  const findColumnWithID = <V,>(id: string): KTableColumn<T, V> | undefined =>
    (columns as KTableColumn<T, V>[]).find((item) => item.id === id);

  const renderRows = () =>
    stableSort(data, getSorting(order, findColumnWithID(orderBy))).map(
      (item, rowIndex) => {
        const isRowSelected = selectedRow[rowIndex] === true;

        const rowKey = extractKey(item);

        return (
          <KTableRow
            key={rowKey}
            {...{
              rowKey,
              handleRowSelected,
              rowIndex,
              config,
              item,
              isRowSelected,
              enableHover,
              tableRowClasses,
              columns,
              tableCellClasses,
            }}
          />
        );
      },
    );

  if (columns.length === 0) {
    return null;
  }

  return (
    <TableContainer
      className={clsx(classes.tableContainer, tableContainerClassName, {
        [classes.scroll]: !preventScroll,
      })}
    >
      <Table
        id={config.id}
        size="small"
        style={{ tableLayout: autoLayout ? "fixed" : "auto" }}
        stickyHeader
      >
        <KTableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      {loading && TABLE_LOADER}
    </TableContainer>
  );
};

export { KTable };
