import { StyleSheet } from "react-native";

import { Color } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: Color.WHITE,
  },
});
