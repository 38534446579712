import { Dictionary } from "ts-essentials";

import { Pool } from "./pool";

export enum PoolRoleType {
  AT_LEAST_STANDARD = "internal",
  EXTERNAL = "external",
}

export type PoolLocation = { roleType: PoolRoleType; poolId: string };

export interface UserUnreadPools {
  updatedAt: Date;
  userId: string;
  unreadPools: {
    [poolId: string]: number;
  };
}

export interface PoolState {
  startPoolId: string | undefined;
  currentLocation: PoolLocation | undefined;
  locations: PoolLocation[] | undefined;
  pools: Dictionary<Pool>;
  fetchedOnce: boolean;
  unreadPools: UserUnreadPools["unreadPools"];
}
