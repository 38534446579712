import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KSchemaIcon } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { SchemaActions } from "@kraaft/shared/core/modules/schema/schema.actions";
import { SchemaNameInput } from "@kraaft/web/src/components/schemaBuilder/tabs/editMetadata/schemaNameInput";

interface AddPoolSchemaDialogProps {
  onClose: () => void;
}

export const AskSchemaNameAndIconDialogContent = ({
  onClose,
}: AddPoolSchemaDialogProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [icon, setIcon] = useState<KSchemaIcon>("unchecked");
  const dispatch = useDispatch();

  const disabled = name.length === 0;
  const onConfirm = useCallback(() => {
    dispatch(SchemaActions.createSchema({ name, icon }));
    onClose();
  }, [dispatch, icon, name, onClose]);

  const handleEnterPressed = useCallback(() => {
    if (disabled) {
      return;
    }
    onConfirm();
  }, [disabled, onConfirm]);

  const actions = useMemo<FooterActions>(
    () => [
      {
        testID: "ide2e-create-schema-confirm",
        text: t("schemasView.addDialogFromScratch.create"),
        onPress: onConfirm,
        disabled,
      },
      { text: t("cancel"), onPress: onClose },
    ],
    [disabled, onClose, onConfirm, t],
  );

  return (
    <KDialogContent
      title={t("schemasView.addDialogFromScratch.title")}
      actions={actions}
      content={
        <SchemaNameInput
          name={name}
          icon={icon}
          onNameChange={setName}
          onIconChange={setIcon}
          onSubmit={handleEnterPressed}
        />
      }
    />
  );
};
