import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { copyTextToClipboard } from "@kraaft/shared/core/utils/clipboard";
import { Color, ColorStyle, Icon, Spacing, Text } from "@kraaft/ui";

const CopyButtonComponent = ({ isCopied }: { isCopied: boolean }) => {
  const { t } = useTranslation();
  return (
    <View style={styles.copyButtonRow}>
      <Text
        style={[!isCopied && { opacity: 0 }, styles.copyTextStyle]}
        size="BODY"
      >
        {t("copied")}
      </Text>
      <Icon
        name={isCopied ? "check-done-01" : "copy-03"}
        color={isCopied ? Color.GREEN_LAGOON : ColorStyle.FONT_LOW_EMPHASIS}
        size="MEDIUM"
      />
    </View>
  );
};

export function useContactInfoCopyIcon(infos: {
  phone: string | undefined;
  email: string | undefined;
}) {
  const [copiedInfo, setCopiedInfo] = useState<"none" | "phone" | "email">();

  const renderPhoneCopyIcon = useMemo(() => {
    return <CopyButtonComponent isCopied={copiedInfo === "phone"} />;
  }, [copiedInfo]);

  const renderEmailCopyIcon = useMemo(() => {
    return <CopyButtonComponent isCopied={copiedInfo === "email"} />;
  }, [copiedInfo]);

  const triggerPhoneCopy = useCallback(() => {
    if (infos.phone) {
      copyTextToClipboard(infos.phone);
    }
    setCopiedInfo("phone");
  }, [infos.phone]);

  const triggerEmailCopy = useCallback(() => {
    if (infos.email) {
      copyTextToClipboard(infos.email);
    }
    setCopiedInfo("email");
  }, [infos.email]);

  return {
    renderPhoneCopyIcon,
    renderEmailCopyIcon,
    triggerPhoneCopy,
    triggerEmailCopy,
  };
}

const styles = StyleSheet.create({
  copyButtonRow: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: Spacing.S4,
    marginLeft: Spacing.S8,
  },
  copyTextStyle: {
    color: Color.GREEN_LAGOON,
  },
});
