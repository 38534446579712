import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { CheckboxColumnEditor } from "@kraaft/web/src/components/modularTable/components/table/columnHeader/columnEditor/columnEditors/checkboxColumnEditor";
import { booleanRecordComparator } from "@kraaft/web/src/components/modularTable/components/table/recordComparatorUtils";
import { booleanValueFromClipboardText } from "@kraaft/web/src/components/modularTable/components/table/valueFromClipboardTextUtils";
import { IFieldConfig } from "@kraaft/web/src/components/modularTable/components/types";

import { withBasicCell } from "../_withTableCell/withBasicCell";
import { CheckboxCell } from "./basicCell";

export const checkboxConfig: IFieldConfig<KColumnType.checkbox> = {
  type: KColumnType.checkbox,
  TableCell: withBasicCell(CheckboxCell),
  TableEditor: null,
  comparator: booleanRecordComparator,
  columnEditor: CheckboxColumnEditor,
  getValueFromClipboardText: booleanValueFromClipboardText,
};
