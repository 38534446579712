import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    "& > div": {
      fontWeight: 500,
    },
    "& > :first-child": {
      marginRight: Spacing.S16,
    },
  },
});
