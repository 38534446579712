import { useTranslation } from "react-i18next";
import { Linking } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { trackGenerateConversationReport } from "@kraaft/shared/components/report/trackingUtils";
import {
  showError,
  showInfo,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { setLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { selectLoading } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import {
  selectRoomIsExternal,
  selectRoomMembersCount,
} from "@kraaft/shared/core/modules/room/roomSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { RoomReportFiltersJson } from "@kraaft/shared/core/services/api/api";
import { useEnsureOnline } from "@kraaft/shared/core/utils/useNetwork";

import { getReportTemplateName } from "../../core/modules/reportTemplate/reportTemplate.utils";

export function useDownloadRoomReportCallback(
  roomId: string,
  filters: RoomReportFiltersJson,
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const downloadLoadingId = `downloadingRoomReport-${roomId}`;
  const isDownloadingRoomReport = useSelector(selectLoading(downloadLoadingId));

  const roomMemberCount = useSelector(selectRoomMembersCount(roomId));
  const isExternal = useSelector(selectRoomIsExternal(roomId));

  const downloadRoomReport = useEnsureOnline(
    async (reportTemplate: ReportTemplate) => {
      trackGenerateConversationReport(
        roomId,
        isExternal,
        filters,
        roomMemberCount,
        reportTemplate.id,
        getReportTemplateName(reportTemplate),
      );

      const timeout = setTimeout(() => {
        dispatch(
          showInfo({
            title: t("downloadReportInfoTitle"),
            message: t("downloadReportInfo"),
          }),
        );
      }, 1000);

      dispatch(
        setLoader({ id: downloadLoadingId, status: LoaderStatus.LOADING }),
      );
      try {
        const { downloadUrl } = await Api.generateRoomReport({
          roomId,
          filters,
          templateId: reportTemplate.id,
        });

        if (isNative()) {
          await Linking.openURL(downloadUrl);
        } else {
          await fileSaver.download(downloadUrl);
        }

        clearTimeout(timeout);
        dispatch(showSuccess({ title: t("downloadReportSuccessTitle") }));
      } catch (e) {
        console.error(e);
        clearTimeout(timeout);
        dispatch(
          showError({
            title: t("downloadReportErrorTitle"),
            message: t("downloadReportError"),
          }),
        );
      } finally {
        dispatch(
          setLoader({ id: downloadLoadingId, status: LoaderStatus.SUCCESS }),
        );
      }
    },
    [
      dispatch,
      downloadLoadingId,
      filters,
      isExternal,
      roomId,
      roomMemberCount,
      t,
    ],
  );

  return { downloadRoomReport, isDownloadingRoomReport };
}
