import { makeStyles } from "@mui/styles";

import { Color, Radius, Spacing } from "@kraaft/ui";

const COLUMN_WIDTH = 316;

export const useStyles = makeStyles({
  columnContainer: {
    display: "flex",
  },
  columnContentWrapper: {
    width: COLUMN_WIDTH,
  },
  columnContent: {
    margin: Spacing.S8,
  },
  columnContentDragOver: {
    borderRadius: Radius.LARGE,

    boxShadow: `inset 0 0 0 4px ${Color.BLUE_SAVOY}`,
  },
});
