import { takeEvery } from "typed-redux-saga/macro";

import { intercom } from "@kraaft/shared/core/modules/intercom/intercom.provider";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

export function* initializeUserSaga() {
  yield* takeEvery(
    UserActions.userConnectedToFirebase,
    onUserConnectedToFirebase,
  );
}

function* onUserConnectedToFirebase({
  payload: { userAuth },
}: ReturnType<typeof UserActions.userConnectedToFirebase>) {
  intercom
    .initializeForUser({
      userId: userAuth.uid,
      email: userAuth.email ?? undefined,
      phone: userAuth.phoneNumber ?? undefined,
    })
    .catch(console.error);
}
