import { useMemo } from "react";
import { Text, View } from "react-native";

import { getMarkerTintColor } from "@kraaft/shared/components/mapController/markers/marker/marker.utils";
import { Color, ColorStyle } from "@kraaft/ui";

import { styles } from "./simpleClusterContent.styles";

interface SimpleClusterContentProps {
  isHovered: boolean | undefined;
  markerCount: number;
}
const SimpleClusterContent = ({
  isHovered,
  markerCount,
}: SimpleClusterContentProps) => {
  const textStyle = useMemo(
    () => [
      styles.count,
      {
        color: getMarkerTintColor(
          { isHovered },
          Color.WHITE,
          ColorStyle.FONT_LOW_EMPHASIS,
        ),
      },
    ],
    [isHovered],
  );

  return (
    <View style={styles.countContainer}>
      <Text style={textStyle}>{markerCount}</Text>
    </View>
  );
};

export { SimpleClusterContent };
