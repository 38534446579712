import { StyleSheet } from "react-native";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  avatarContainer: {
    marginRight: Spacing.S8,
  },
  leftContainer: {
    flexShrink: 1,
    flexDirection: "row",
    alignItems: "center",

    paddingHorizontal: Spacing.S4,
    paddingVertical: Spacing.S8,
  },
  title: {
    fontFamily: "Apercu-Medium",
    fontSize: FontSize.BODY,

    flex: 1,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    marginRight: Spacing.S8,
  },
  dateContainer: {
    paddingHorizontal: Spacing.S4,
  },
  date: {
    fontFamily: "Apercu",
    fontSize: FontSize.SMALL,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
});
