import { useMemo } from "react";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KFolderSchema,
  KRoomSchema,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";

export function useRoomTableSchemaForCreation(schema: KRoomSchema) {
  const schemaWithoutAutomatedColumns = useMemo(
    () =>
      KSchemaUtils.withoutSpecificColumns(schema, (c) => {
        if (KSchemaUtils.isColumnAutomated(c)) {
          return true;
        }
        if (
          c.type === KColumnType.selectMultiple ||
          c.type === KColumnType.selectSingle
        ) {
          return Object.keys(c.options).length === 0;
        }
        return false;
      }),
    [schema],
  );
  return schemaWithoutAutomatedColumns;
}

export function useFolderTableSchemaForCreation<
  S extends KFolderSchema | undefined,
>(schema: S): S {
  const schemaWithoutAutomatedColumns = useMemo(
    () =>
      schema &&
      KSchemaUtils.withoutSpecificColumns(schema, (c) =>
        KSchemaUtils.isColumnAutomated(c),
      ),
    [schema],
  );
  return schemaWithoutAutomatedColumns as S;
}
