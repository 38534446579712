import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { updatePoolMember } from "@kraaft/shared/core/modules/poolMember/poolMemberActions";
import { Api } from "@kraaft/shared/core/services/api/api";

import { isOtherJob, UserJob } from "./userJob";

export function useUserJob() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const setUserJob = useCallback(async (job: string) => {
    setLoading(true);
    try {
      await Api.setUserJob(job);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);

  const formatJob = useCallback(
    (job: UserJob) => {
      if (isOtherJob(job)) {
        return job.split("other:")[1] ?? "";
      }
      return t(`userJob.jobs.${job}`);
    },
    [t],
  );

  const superadminSetUserJob = useCallback(
    async (userId: string, job: string) => {
      setLoading(true);
      try {
        dispatch(
          updatePoolMember({
            userId,
            update: {
              job: isOtherJob(job) ? `other:${job}` : (job as UserJob),
            },
          }),
        );
        await Api.superadminSetUserJob(userId, job);
      } catch (e) {
        dispatch(
          showError({
            title: t("errorTitle"),
            message: t("userJob.couldNotUpdate"),
          }),
        );
        console.error(e);
      }
      setLoading(false);
    },
    [dispatch, t],
  );

  return { setUserJob, loading, superadminSetUserJob, formatJob };
}
