import { createAction } from "@reduxjs/toolkit";

import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import {
  actionUpdateLoaderMetaFailure,
  actionUpdateLoaderMetaSuccess,
  createLoaderMeta,
  WithLoaderPayload,
} from "@kraaft/shared/core/modules/loaders/loaderUtils";
import { TrackTypeAttachSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

interface CreateFolderFromSelectionPayload extends WithLoaderPayload {
  roomId: string;
  schemaId: string;
}

export const createFolderFromSelection = createAction(
  "@message/CREATE_FOLDER_FROM_SELECTION",
  (payload: CreateFolderFromSelectionPayload) => ({
    payload,
    meta: createLoaderMeta({
      id: payload.loaderId,
      status: LoaderStatus.LOADING,
    }),
  }),
);

export const createFolderFromSelectionSuccess = createAction(
  "@message/CREATE_FOLDER_FROM_SELECTION_SUCCESS",
  actionUpdateLoaderMetaSuccess,
);

export const createFolderFromSelectionFailure = createAction(
  "@message/CREATE_FOLDER_FROM_SELECTION_FAILURE",
  actionUpdateLoaderMetaFailure,
);

interface AttachToFolderFromSelectionPayload extends WithLoaderPayload {
  roomId: string;
  schemaId: string;
  folderId: string;
  columnKey?: string;
  /** tracking props */
  folderTitle: string;
  isRoomExternal: boolean;
}

export const attachToFolderFromSelection = createAction(
  "@message/ATTACH_TO_FOLDER_FROM_SELECTION",
  (payload: AttachToFolderFromSelectionPayload) => ({
    payload,
    meta: createLoaderMeta({
      id: payload.loaderId,
      status: LoaderStatus.LOADING,
    }),
  }),
);

export const attachToFolderFromSelectionSuccess = createAction(
  "@message/ATTACH_TO_FOLDER_FROM_SELECTION_SUCCESS",
  actionUpdateLoaderMetaSuccess,
);

export const attachToFolderFromSelectionFailure = createAction(
  "@message/ATTACH_TO_FOLDER_FROM_SELECTION_FAILURE",
  actionUpdateLoaderMetaFailure,
);

interface AttachInDirectoryFromSelectionPayload extends WithLoaderPayload {
  roomId: string;
  directoryId: string;
}

export const attachInDirectoryFromSelection = createAction(
  "@message/ATTACH_IN_DIRECTORY_FROM_SELECTION",
  (payload: AttachInDirectoryFromSelectionPayload) => ({
    payload,
    meta: createLoaderMeta({
      id: payload.loaderId,
      status: LoaderStatus.LOADING,
    }),
  }),
);

export const attachInDirectoryFromSelectionSuccess = createAction(
  "@message/ATTACH_IN_DIRECTORY_FROM_SELECTION_SUCCESS",
  actionUpdateLoaderMetaSuccess,
);

export const attachInDirectoryFromSelectionFailure = createAction(
  "@message/ATTACH_IN_DIRECTORY_FROM_SELECTION_FAILURE",
  actionUpdateLoaderMetaFailure,
);

interface UnflagMessagePayload {
  roomId: string;
  messageId: string;
}

export const unflagMessage = createAction(
  "@message/UNFLAG_MESSAGE",
  (payload: UnflagMessagePayload) => ({ payload }),
);

interface FillDirectoryFromSelectionPayload extends WithLoaderPayload {
  roomId: string;
  directoryId: string;
}

export const fillDirectoryFromSelection = createAction(
  "@message/FILL_DIRECTORY_FROM_SELECTION",
  (payload: FillDirectoryFromSelectionPayload) => ({
    payload,
    meta: createLoaderMeta({
      id: payload.loaderId,
      status: LoaderStatus.LOADING,
    }),
  }),
);

interface FillDirectoryWithMessagePayload extends WithLoaderPayload {
  roomId: string;
  directoryId: string;
  messageId: string;
}

export const fillDirectoryWithMessage = createAction(
  "@message/FILL_DIRECTORY_WITH_MESSAGE",
  (payload: FillDirectoryWithMessagePayload) => ({
    payload,
    meta: createLoaderMeta({
      id: payload.loaderId,
      status: LoaderStatus.LOADING,
    }),
  }),
);

interface FillDirectoryWithFilePayload extends WithLoaderPayload {
  roomId: string;
  directoryId: string;
  files: ModernFile<LocalPath>[];
  source: TrackTypeAttachSource;
}

export const fillDirectoryWithFile = createAction(
  "@message/FILL_DIRECTORY_WITH_FILE",
  (payload: FillDirectoryWithFilePayload) => ({
    payload,
    meta: createLoaderMeta({
      id: payload.loaderId,
      status: LoaderStatus.LOADING,
    }),
  }),
);

export interface RemoveMessagesFromDirectoryPayload extends WithLoaderPayload {
  roomId: string;
  directoryId: string;
  messageIds: string[];
}

export const removeMessagesFromDirectory = createAction(
  "@message/REMOVE_MESSAGES_FROM_DIRECTORY",
  (payload: RemoveMessagesFromDirectoryPayload) => ({
    payload,
    meta: createLoaderMeta({
      id: payload.loaderId,
      status: LoaderStatus.LOADING,
    }),
  }),
);

export const removeMessagesFromDirectorySuccess = createAction(
  "@message/REMOVE_MESSAGES_FROM_DIRECTORY_SUCCESS",
  actionUpdateLoaderMetaSuccess,
);

export const removeMessagesFromDirectoryFailure = createAction(
  "@message/REMOVE_MESSAGES_FROM_DIRECTORY_FAILURE",
  actionUpdateLoaderMetaFailure,
);
