import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectUserRoles } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import {
  selectRoom,
  selectRoomMemberIds,
} from "@kraaft/shared/core/modules/room/selectors";
import { selectUsernames } from "@kraaft/shared/core/modules/user/userSelectors";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { compareStrings } from "@kraaft/shared/core/utils/utils";

type UseRoomMembersParams = {
  roomId: Room["id"];
};

export type Member = {
  id: string;
  name?: string;
  role?: string;
};

type UseRoomMembersResult = Member[];

export const useRoomMembers = ({
  roomId,
}: UseRoomMembersParams): UseRoomMembersResult => {
  const { t } = useTranslation();

  const room = useSelector(selectRoom(roomId));
  const memberIds = useSelector(selectRoomMemberIds(roomId));

  const usernames = useSelector(selectUsernames(memberIds));
  const roles = useSelector(selectUserRoles(memberIds, room?.poolId));
  const responsibleId = getExistingRoomRecordField(
    room?.record.properties,
    "responsible",
  )?.[0];

  const getMemberRole = useCallback(
    (userId: string, role?: UserPoolRole) => {
      if (role === UserPoolRole.EXTERNAL) {
        return t("external");
      }

      if (responsibleId === userId) {
        return t("responsible");
      }

      return;
    },
    [responsibleId, t],
  );

  const members = useMemo(
    () =>
      memberIds
        .map((userId, index) => ({
          id: userId,
          name: usernames[index],
          role: getMemberRole(userId, roles[index]),
        }))
        .sort((a, b) =>
          a.name && b.name ? compareStrings(a.name, b.name) : 0,
        ),
    [memberIds, usernames, getMemberRole, roles],
  );

  return members;
};
