import { useMemo } from "react";
import { Fade, PopperProps, Tooltip } from "@mui/material";
import clsx from "clsx";

import { BaseMarkerProps } from "@kraaft/shared/components/geolocation/baseMarker/baseMarker.types";
import { defaultBaseMarkerColors } from "@kraaft/shared/components/geolocation/baseMarker/baseMarkerConfig";
import { Color } from "@kraaft/ui";

import { ReactComponent as MarkerSvg } from "../assets/marker.svg";

import { useStyles } from "./baseMarker.styles";

const BaseMarker = (props: BaseMarkerProps) => {
  const {
    children,
    baseMarkerColors: givenBaseMarkerColors,
    hoverMarkerColors: givenHoverMarkerColors,
    selectMarkerColors: givenSelectMarkerColors,
    isHovered,
    isSelected,
    tooltipComponent,
  } = props;
  const baseMarkerColors = {
    ...defaultBaseMarkerColors.base,
    ...givenBaseMarkerColors,
  };
  const hoverMarkerColors = {
    ...defaultBaseMarkerColors.hover,
    ...givenHoverMarkerColors,
  };
  const selectMarkerColors = {
    ...defaultBaseMarkerColors.select,
    ...givenSelectMarkerColors,
  };

  const currentColors = isSelected
    ? selectMarkerColors
    : isHovered
      ? hoverMarkerColors
      : baseMarkerColors;

  const classes = useStyles({ ...currentColors });

  const popperProps = useMemo<Partial<PopperProps>>(
    () => ({
      disablePortal: true,
      popperOptions: {
        positionFixed: true,
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              enabled: true,
              boundariesElement: document.getElementById("map-area"),
            },
          },
        ],
      },
    }),
    [],
  );

  return (
    <Tooltip
      id="ide2e-basemarker-tooltip"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      title={tooltipComponent || ""}
      placement="top"
      disableFocusListener
      PopperProps={popperProps}
      slotProps={{ tooltip: { sx: { backgroundColor: Color.TRANSPARENT } } }}
      classes={{ tooltip: classes.tooltip }}
    >
      <div>
        <div
          className={clsx(
            classes.markerContainer,
            (isHovered || isSelected) && classes.markerContainerHovered,
          )}
        >
          <div className={classes.content}>{children}</div>
          <MarkerSvg className={classes.marker} />
        </div>
      </div>
    </Tooltip>
  );
};

export { BaseMarker };
