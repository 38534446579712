import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { Api } from "@kraaft/shared/core/services/api/api";

import {
  isOtherAcquisitionSource,
  PoolAcquisitionSource,
} from "./poolAcquisitionSource";

export const usePoolAcquisitionSource = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const poolId = useSelector(selectCurrentPoolId);

  const [isLoading, setLoading] = useState(false);

  const setPoolAcquisitionSource = useCallback(
    async (acquisitionSource: string) => {
      setLoading(true);

      try {
        if (!poolId) {
          throw new Error("Unable to resolve current poolId");
        }

        await Api.setPoolAcquisitionSource(poolId, acquisitionSource);
      } catch (e) {
        dispatch(
          showError({
            title: t("errorTitle"),
            message: t("poolAcquisitionSource.couldNotUpdate"),
          }),
        );
        console.error(e);
      }

      setLoading(false);
    },
    [poolId, dispatch, t],
  );

  const formatAcquisitionSource = useCallback(
    (acquisitionSource: PoolAcquisitionSource) => {
      if (isOtherAcquisitionSource(acquisitionSource)) {
        return acquisitionSource.split("other:")[1] ?? "";
      }
      return t(`poolAcquisitionSource.choices.${acquisitionSource}`);
    },
    [t],
  );

  return {
    isLoading,
    formatAcquisitionSource,
    setPoolAcquisitionSource,
  };
};
