import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { MediaSelectionLocation } from "@kraaft/shared/components/galleries/types";
import {
  deselectMessage,
  setMessageSelectionProperties,
  toggleSelectMessages,
} from "@kraaft/shared/core/modules/message/messageActions";
import {
  selectMessageSelectionCount,
  selectMessageSelectionSource,
} from "@kraaft/shared/core/modules/message/messageSelectors";
import { MessageSelectionSource } from "@kraaft/shared/core/modules/message/messageState";
import { selectMiniMediasCount } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import {
  MiniMedia,
  MiniMediaOfType,
} from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { geolocationContextHover } from "@kraaft/shared/core/modules/room/roomActions";
import { miniImageHasGeolocation } from "@kraaft/shared/core/modules/room/roomUtils";
import { type ResourceKey } from "@kraaft/shared/core/services/i18next";
import { IconName } from "@kraaft/ui";

const selectionSourceFromMediaSelectionLocation: Record<
  MediaSelectionLocation,
  MessageSelectionSource
> = {
  image: "photoGallery",
  document: "documentGallery",
  directory: "directoryList",
};

const headerTitleFromState: Record<
  MediaSelectionLocation,
  {
    normal: ResourceKey;
    selecting: ResourceKey;
    filtered: ResourceKey;
  }
> = {
  image: {
    normal: "photoGalleryHeader",
    selecting: "imagesSelected",
    filtered: "photoGalleryHeaderFiltered",
  },
  document: {
    normal: "documentGalleryHeader",
    selecting: "documentsSelected",
    filtered: "documentGalleryHeader",
  },
  directory: {
    normal: "documentGalleryHeader",
    selecting: "documentsSelected",
    filtered: "documentGalleryHeader",
  },
};

// Record<
//  MediaSelectionLocation,
//  readonly MiniMedia['type'][]
// >
const mediaSelectionLocationToMediaType = {
  image: ["image"],
  document: ["document"],
  directory: ["image", "video", "document"],
} as const;

interface useGalleryContextProps<T extends MediaSelectionLocation> {
  medias: MiniMediaOfType<
    (typeof mediaSelectionLocationToMediaType)[T][number]
  >[];
  roomId: string;
  type: T;
}

export const useMediaSelectionContext = <T extends MediaSelectionLocation>(
  props: useGalleryContextProps<T>,
) => {
  const { roomId, medias, type } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const typeSelectionSource = selectionSourceFromMediaSelectionLocation[type];
  const mediaType = mediaSelectionLocationToMediaType[type];

  const selectionCount = useSelector(
    selectMessageSelectionCount(roomId, typeSelectionSource),
  );
  const selectionSource = useSelector(selectMessageSelectionSource(roomId));
  const allowSelection =
    selectionSource === undefined || selectionSource === typeSelectionSource;
  const isSelecting = selectionCount > 0;
  const totalMediaCount = useSelector(
    selectMiniMediasCount(roomId, [...mediaType]),
  );

  const handleHover = useCallback(
    (media: MiniMedia, hovered: boolean) => {
      if (media.type === "image") {
        if (allowSelection && miniImageHasGeolocation(media)) {
          dispatch(
            geolocationContextHover({
              id: media.id,
              hovered,
            }),
          );
        }
      }
    },
    [allowSelection, dispatch],
  );

  const handleSelect = useCallback(
    (messageIds: string[], newStatus: boolean) => {
      dispatch(
        toggleSelectMessages({
          roomId,
          selectionType: "notDefined",
          selectionSource: typeSelectionSource,
          messageIds,
          selectState: newStatus,
        }),
      );
    },
    [dispatch, roomId, typeSelectionSource],
  );

  const mediaAreFiltered =
    totalMediaCount !== undefined && totalMediaCount !== medias.length;

  const handleCloseFlow = useCallback(() => {
    dispatch(
      setMessageSelectionProperties({
        roomId,
        selectionType: "notDefined",
        status: undefined,
      }),
    );
  }, [dispatch, roomId]);

  const handleClearSelection = useCallback(() => {
    dispatch(deselectMessage({ roomId, all: true }));
  }, [dispatch, roomId]);

  const titleKeys = headerTitleFromState[type];

  const title = useMemo(
    () =>
      allowSelection && isSelecting
        ? t(titleKeys.selecting, {
            count: selectionCount,
          })
        : mediaAreFiltered
          ? t(titleKeys.filtered, {
              count: medias.length,
              total: totalMediaCount,
            })
          : t(titleKeys.normal),
    [
      allowSelection,
      isSelecting,
      mediaAreFiltered,
      medias.length,
      selectionCount,
      t,
      titleKeys.filtered,
      titleKeys.normal,
      titleKeys.selecting,
      totalMediaCount,
    ],
  );

  const headerCloseIcon: IconName = !isSelecting ? "chevron-left" : "x-close";

  return {
    allowSelection,
    handleHover,
    handleSelect,
    handleCloseFlow,
    handleClearSelection,
    title: title as string,
    selectionCount,
    headerCloseIcon,
    isSelecting,
  };
};
