import { useTranslation } from "react-i18next";

import { DeleteConfirmationDialogContent } from "@kraaft/shared/components/deleteConfirmationDialogContent";

interface Props {
  columnName: string;
  onDelete: () => void;
  onClose: () => void;
}

const ConfirmationStep = (props: Props) => {
  const { onClose, columnName, onDelete } = props;
  const { t } = useTranslation();

  const expectedValue = columnName.trim() || t("deleteColumn.emptyExpected");

  return (
    <DeleteConfirmationDialogContent
      onDelete={onDelete}
      onClose={onClose}
      title={t("deleteColumn.title")}
      subtitle={t("deleteColumn.subtitle", { columnName, expectedValue })}
      inputLabel={t("deleteColumn.inputLabel")}
      expectedValue={expectedValue}
      deleteButton={t("deleteColumn.delete")}
      context="deleteSchemaColumn"
    />
  );
};

export { ConfirmationStep };
