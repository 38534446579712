import { Saga, Task } from "redux-saga";
import { cancel, fork } from "typed-redux-saga/macro";

import { OfflineMessageType } from "@kraaft/shared/core/modules/message/offline/offlineMessageState";

export function* startWithBackgroundEngine(saga: Saga) {
  const task: Task = yield* fork(saga);

  return task;
}

export function* ensureBackgroundServiceRunning() {}

export function* stopBackgroundEngine(task?: Task) {
  if (task) {
    yield* cancel(task);
  }
}

export async function updateBackgroundEngineNotification(_roomId: string) {}

export async function updateProgressBar(
  _progressBar: { value: number; max: number },
  _messageType: OfflineMessageType,
) {
  return;
}
