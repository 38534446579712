import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Droppable } from "@kraaft/shared/components/droppable";
import { constants } from "@kraaft/shared/constants/constants";
import { useFileInput } from "@kraaft/web/src/core/utils/useFileInput";

import { useAddFilesToRoom } from "./dropzoneUtils";

import { styles } from "./dropzone.styles";

interface Props {
  roomId: string;
  children?: ReactNode;
  clickable?: boolean;
  noDrag?: boolean;
}

/**
 * The Dropzone component is used:
 * - either as an area where user can drop files from his computer
 * - or a clickable area which opens the file browser of the computer
 * @param props
 * @constructor
 */
const Dropzone = (props: Props) => {
  const { clickable, roomId, children } = props;
  const { t } = useTranslation();
  const onDrop = useAddFilesToRoom(roomId);

  const { getInputProps, open } = useFileInput({
    onAccepted: onDrop,
    multiple: true,
    maxSize: constants.FILE_SIZE_LIMIT,
  });

  return (
    <Droppable onDropFiles={onDrop} acceptType="*" acceptSystemFile>
      {useCallback(
        ({ isActive }) => {
          return (
            <div onClick={() => clickable && open()} style={styles.dragzone}>
              {children}
              {clickable && <input {...getInputProps()} />}
              {!isActive ? (
                <div />
              ) : (
                <span style={styles.textContainer}>
                  <p style={styles.text}>{t("dropFileHere")}</p>
                </span>
              )}
            </div>
          );
        },
        [t, clickable, getInputProps, open, children],
      )}
    </Droppable>
  );
};

export { Dropzone };
