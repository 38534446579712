import { StyleSheet } from "react-native";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    flexBasis: 0,
    flexDirection: "column",
    paddingRight: Spacing.S16,
  },
  sectionListContent: {
    paddingBottom: Spacing.S8,
  },
  item: {
    marginBottom: Spacing.S8,
  },
  header: {
    paddingRight: Spacing.S8,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
});
