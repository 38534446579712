import {
  KeyboardEventHandler,
  memo,
  MouseEventHandler,
  useCallback,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import isEqual from "fast-deep-equal";

import { useMeshContext } from "@kraaft/helper-hooks";
import { MODULAR_LABEL_LIMIT } from "@kraaft/shared/constants/constants";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaSection } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularColumnsContext } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { ColorPicker } from "@kraaft/web/src/components/colorPicker/colorPicker";
import { ContextMenuButton } from "@kraaft/web/src/components/contextMenuButton";
import {
  EditableText,
  EditableTextHandle,
} from "@kraaft/web/src/components/editableText/editableText";
import { SectionIcon } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementIcon/sectionIcon";
import { ElementCondition } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementCondition/elementCondition";
import {
  ElementsEditorSectionBackdrop,
  SpaceBehavior,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditorSectionBackdrop/elementsEditorSectionBackdrop";
import { useEditorItems } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditorSectionItem/useEditorItems";
import { useSectionOpenContextMenu } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditorSectionItem/useSectionOpenContextMenu";
import { SchemaBuilderContext } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

import { useStyles } from "./elementsEditorSectionItem.styles";

const NAME_ID = "column-name";

export interface ElementsEditorSectionItemProps {
  section: KSchemaSection;
  columnsContext: ModularColumnsContext;
  hideParentSection: boolean;
  availableColumnTypes: KColumnType[];
  forceInstantDeletion?: boolean;
}

// eslint-disable-next-line complexity
const ElementsEditorSectionItem_ = ({
  section,
  forceInstantDeletion = false,
  columnsContext,
  hideParentSection,
  availableColumnTypes,
}: ElementsEditorSectionItemProps) => {
  const classes = useStyles({ color: section.color });
  const {
    currentElementEditionKey,
    setCurrentElementEditionKey,
    currentElementCreation,
    insertElement,
    renameElement,
    deleteElement,
    currentHoveredKey,
    currentDraggedKey,
    resetCreation,
    editSection,
  } = useMeshContext(SchemaBuilderContext);

  const onClick = useCallback(
    (key: string) => {
      setCurrentElementEditionKey(key);
    },
    [setCurrentElementEditionKey],
  );

  const internOnInsertElement = useCallback(
    (_: string, name: string) => {
      if (!currentElementCreation || name.length === 0) {
        resetCreation();
        return;
      }
      if (currentElementCreation?.type === "section") {
        currentElementCreation.section.name = name;
        insertElement(
          currentElementCreation.section,
          currentElementCreation.placement,
        );
      } else if (currentElementCreation?.type === "column") {
        currentElementCreation.column.name = name;
        insertElement(
          currentElementCreation.column,
          currentElementCreation.placement,
        );
      }
    },
    [currentElementCreation, insertElement, resetCreation],
  );

  const editors = useEditorItems(
    section,
    columnsContext,
    availableColumnTypes,
    internOnInsertElement,
  );

  const internEditSectionColor = useCallbackRealtime(
    ([_key], color: string) => {
      editSection(_key, {
        color,
      });
    },
    [editSection],
    [section.key],
  );

  const { t } = useTranslation();
  const inputRef = useRef<EditableTextHandle>(null);
  const [tempName, setTempName] = useState(section.name);
  const autofocus = useRef(false);

  const startName = useRef(section.name);
  startName.current = section.name;

  const internOnDelete = useCallback(() => {
    deleteElement(section.key);
  }, [deleteElement, section.key]);

  const hasChildren = Object.keys(section.elements).length > 0;
  const isCreatingThisSection =
    currentElementCreation?.type === "section" &&
    currentElementCreation.section.key === section.key;

  const internOnChangeName = useCallback(() => {
    if (tempName.length === 0 && !isCreatingThisSection) {
      setTempName(startName.current);
      return;
    }
    renameElement(section.key, tempName, hasChildren);
  }, [
    tempName,
    isCreatingThisSection,
    renameElement,
    section.key,
    hasChildren,
  ]);

  const blur = useCallback(() => {
    if (isCreatingThisSection) {
      internOnInsertElement("", tempName);
    } else {
      internOnChangeName();
    }

    inputRef.current?.blur();
  }, [
    internOnChangeName,
    internOnInsertElement,
    isCreatingThisSection,
    tempName,
  ]);

  const handleKey = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter" || event.key === "Escape") {
        inputRef.current?.blur();
      }
    },
    [],
  );

  const internOnClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();
      autofocus.current =
        !hasChildren || (event.target as HTMLDivElement).id === NAME_ID;
      onClick?.(section.key);
    },
    [hasChildren, onClick, section.key],
  );

  const openContextMenu = useSectionOpenContextMenu({
    onDelete: internOnDelete,
    section,
    forceInstantDeletion,
  });

  if (hideParentSection) {
    return <div className={classes.baseEditors}>{editors}</div>;
  }

  const isHoverInThisSection = section.elements[currentHoveredKey?.key ?? ""];
  const isDraggedFromThisSection = section.elements[currentDraggedKey ?? ""];

  let spaceBehavior: SpaceBehavior = "normal";
  if (isHoverInThisSection && !isDraggedFromThisSection) {
    spaceBehavior = "more";
  } else if (isDraggedFromThisSection && !isHoverInThisSection) {
    spaceBehavior = "less";
  }

  return (
    <div
      id="column-editor"
      onClick={internOnClick}
      role="button"
      className={classes.root}
    >
      <div id="element-outline" className={classes.outline} />
      <div className={classes.main}>
        <div className={classes.infos}>
          <div className={classes.left}>
            <SectionIcon color={section.color} />
            <EditableText
              id={NAME_ID}
              editing={currentElementEditionKey === section.key}
              ref={inputRef}
              inputLimit={MODULAR_LABEL_LIMIT.SECTION_LABEL}
              overLimitHelperMessage={t("formBuilder.overLimitSectionLabel", {
                count: MODULAR_LABEL_LIMIT.SECTION_LABEL,
              })}
              textInputProps={{
                className: classes.input,
                placeholder: t("formBuilder.schema.columnEditorPlaceholder"),
                onChangeText: setTempName,
                onKeyDown: handleKey,
                onBlur: blur,
                value: tempName,
                hasError:
                  startName.current.length > 0 && section.name.length === 0,
                disabledAutoComplete: true,
                autoFocus: autofocus.current || isCreatingThisSection,
              }}
            />
          </div>
          <div className={classes.right}>
            <ColorPicker
              value={section.color}
              onChange={internEditSectionColor}
            />
            <ContextMenuButton onPress={openContextMenu} />
          </div>
        </div>
        {section.condition && (
          <ElementCondition condition={section.condition} />
        )}
      </div>
      {editors.length > 0 && (
        <div className={classes.editorContainer}>
          <ElementsEditorSectionBackdrop spaceBehavior={spaceBehavior}>
            {editors}
          </ElementsEditorSectionBackdrop>
        </div>
      )}
    </div>
  );
};

export const ElementsEditorSectionItem = memo(
  ElementsEditorSectionItem_,
  isEqual,
) as typeof ElementsEditorSectionItem_;
