import { eventChannel } from "redux-saga";
import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import {
  selectChildDirectoryCount,
  selectDirectory,
} from "@kraaft/shared/core/modules/directory/directorySelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";
import { setDefaultDirectoryTreeForPool } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminReducers";

import { directoryUtils } from "./utils/utils.provider";
import { DirectoryActions, DirectoryStateActions } from "./directoryActions";

export function* directorySagas() {
  yield* takeEvery(
    DirectoryActions.setDefaultTreeForPool,
    setDefaultTreeForPoolSaga,
  );
  yield* takeEvery(DirectoryActions.add, newDirectorySaga);
  yield* takeEvery(DirectoryActions.remove, removeDirectorySaga);
  yield* takeEvery(DirectoryActions.rename, renameDirectorySaga);
  yield* takeEvery(DirectoryActions.move, moveDirectorySaga);
  yield* takeEvery(DirectoryActions.downloadDirectory, downloadDirectorySaga);

  yield takeCountedDeep(
    DirectoryActions.subscribe,
    DirectoryActions.unsubscribe,
    subscribeToRoomDirectoriesSaga,
    function* (unsubscribe?: () => void) {
      unsubscribe?.();
    },
    (action) => action.payload.roomId,
  );
}

function* setDefaultTreeForPoolSaga({
  payload,
}: ReturnType<(typeof DirectoryActions)["setDefaultTreeForPool"]>) {
  try {
    yield* put(setDefaultDirectoryTreeForPool(payload));
    yield* call(Api.setDefaultDirectoryTree, payload);
    yield* put(showSuccess({ title: i18n.t("directory.successfullySaved") }));
  } catch (e) {
    yield* put(showError({ title: i18n.t("directory.errorSaving") }));
  }
}

function* subscribeToRoomDirectoriesSaga(
  registerUnsubscribe: (unsubscribe: () => void) => void,
  { payload }: ReturnType<(typeof DirectoryActions)["subscribe"]>,
) {
  const { roomId } = payload;

  const channel = yield* call(() =>
    eventChannel<Directory[]>((emit) =>
      Firestore.subscribeToRoomDirectories(roomId, emit),
    ),
  );

  yield* takeEvery(channel, function* (directories) {
    yield* put(DirectoryStateActions.setForRoom({ roomId, directories }));
  });

  registerUnsubscribe(() => channel.close());
}

function* removeDirectorySaga({
  payload: { roomId, directoryId },
}: ReturnType<(typeof DirectoryActions)["remove"]>) {
  const directory = yield* select(selectDirectory(directoryId));
  if (!directory) {
    return;
  }

  yield* put(DirectoryStateActions.delete({ directoryId }));

  try {
    yield* call(Api.removeDirectory, { roomId, directoryId });
  } catch (e) {
    yield* put(DirectoryStateActions.add({ directory }));
    yield* put(showError({ title: i18n.t("directory.cannotRemove") }));
  }
}

function* renameDirectorySaga({
  payload: { roomId, directoryId, newDirectoryName },
}: ReturnType<(typeof DirectoryActions)["rename"]>) {
  const directory = yield* select(selectDirectory(directoryId));
  if (!directory) {
    return;
  }

  const trimmedName = newDirectoryName.trim();

  yield* put(
    DirectoryStateActions.rename({
      directoryId,
      newName: trimmedName,
    }),
  );

  try {
    yield* call(Api.renameDirectory, {
      roomId,
      directoryId,
      newDirectoryName: trimmedName,
    });
  } catch (e) {
    yield* put(
      DirectoryStateActions.rename({ directoryId, newName: directory.name }),
    );
    yield* put(showError({ title: i18n.t("directory.cannotRename") }));
  }
}

function* moveDirectorySaga({
  payload: {
    roomId,
    directoryId,
    newParentDirectoryId,
    afterSiblingDirectoryId,
  },
}: ReturnType<(typeof DirectoryActions)["move"]>) {
  try {
    yield* call(Api.moveDirectory, {
      roomId,
      directoryId,
      newParentId: newParentDirectoryId,
      afterSiblingId: afterSiblingDirectoryId,
    });
  } catch (e) {
    yield* put(showError({ title: i18n.t("directory.cannotMove") }));
  }
}

function* newDirectorySaga({
  payload: { name, roomId, parentId },
  meta,
}: ReturnType<(typeof DirectoryActions)["add"]>) {
  const index = yield* select(selectChildDirectoryCount(roomId, parentId));

  const parent = yield* select(selectDirectory(parentId));

  const optimisticId = name;
  const optimistic: Directory = {
    id: optimisticId,
    roomId,
    name,
    parentId,
    index,
    files: [],
    depth: parent?.depth ?? 0 + 1,
    deepestChildDepth: 0,
    optimistic: true,
  };

  yield* put(DirectoryStateActions.add({ directory: optimistic }));

  try {
    yield* call(Api.addDirectory, { name: name.trim(), roomId, parentId });
    yield* put(DirectoryActions.addSuccess(meta));
  } catch (e) {
    console.log("error in newDirectorySaga: ", e);
    yield* put(DirectoryStateActions.delete({ directoryId: optimisticId }));
    yield* put(DirectoryActions.addFailure(e, meta));

    if (
      HttpError.isHttpErrorWithCode(e, "DirectoryTreeDepthMustNotExceedLimit")
    ) {
      yield* put(showError({ title: i18n.t("directory.errorDepthExceeded") }));
    } else {
      yield* put(showError({ title: i18n.t("directory.cannotAdd") }));
    }
  }
}

function* downloadDirectorySaga(
  action: ReturnType<typeof DirectoryActions.downloadDirectory>,
) {
  const { roomId, directoryId } = action.payload;
  const { meta } = action;

  try {
    const { downloadUrl, filename } = yield* call(Api.downloadDirectory, {
      roomId,
      directoryId,
    });
    yield* call(directoryUtils.downloadDirectory, { downloadUrl, filename });
  } catch (error) {
    yield* put(
      showError({
        title: i18n.t("directory.couldNotDownloadDirectory"),
      }),
    );
    yield* put(DirectoryActions.downloadDirectorySelectionFailure(error, meta));
  }
  yield* put(DirectoryActions.downloadDirectorySelectionSuccess(meta));
}
