import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";

import { storage } from "@kraaft/shared/core/modules/storage/storage.provider";

export type LastCredentialMethod = "phone" | "email";

export type LoginStateType = {
  lastLoginInfos:
    | {
        hint: string;
        method: LastCredentialMethod | "other";
      }
    | undefined;
};

export const initialState: LoginStateType = {
  lastLoginInfos: undefined,
};

export type LocalStateType = Record<
  (typeof LoginSlice)["name"],
  LoginStateType
>;

export const selectLastLoginInfos = (state: LocalStateType) =>
  state.login.lastLoginInfos;

export const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    tryLoginHint(
      mutableState,
      _: PayloadAction<LoginStateType["lastLoginInfos"]>,
    ) {
      return mutableState;
    },
    saveLastCredentialHint(
      mutableState,
      { payload }: PayloadAction<LoginStateType["lastLoginInfos"]>,
    ) {
      mutableState.lastLoginInfos = payload;
    },
  },
});

export const persistedLoginReducer = persistReducer(
  {
    storage,
    key: LoginSlice.name,
    version: 0,
  },
  LoginSlice.reducer,
);
