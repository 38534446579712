import moment from "moment";

import { mapValues, orderBy, uniq } from "lodash";
import { Dictionary } from "ts-essentials";
import { call, put, select } from "typed-redux-saga/macro";

import { AlertDialog } from "@kraaft/shared/components/alertDialog";
import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { MessageTypes } from "@kraaft/shared/core/modules/message";
import { selectors as userSel } from "@kraaft/shared/core/modules/user";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { RootState } from "@kraaft/shared/core/store";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

import * as actions from "./messageActions";
import * as Types from "./messageState";
import { FileMessage } from "./messageState";

const EDIT_OR_DELETE_LIMIT_DAYS = 1;

const ONE_MB = 1024 * 1024 * 1024;
const fileSizeLimit = 50 * ONE_MB;
const fileSizeLimitMb = fileSizeLimit / ONE_MB;

interface CheckFileSize {
  isBelowMaxSize: boolean;
  message?: string;
}

export function checkFileSize(file: ModernFile<LocalPath>): CheckFileSize {
  if (file.size && file.size > fileSizeLimit) {
    const message =
      file.contentType === "video"
        ? i18n.t("fileServiceVideoTooBigBody", {
            size: fileSizeLimitMb,
          })
        : i18n.t("fileServiceFileTooBigBody", {
            filename: file.filename,
            size: fileSizeLimitMb,
          });

    return { isBelowMaxSize: false, message };
  }

  return { isBelowMaxSize: true };
}

export function setMessageIsReply(
  messages: Dictionary<Types.Message>,
  currentUserId: string,
): Dictionary<Types.Message> {
  return mapValues(messages, (message) => {
    if ("isReply" in message) {
      message.isReply = message.senderId !== currentUserId;
    }
    return message;
  });
}

export function getAnsweredIds(messages: Dictionary<MessageTypes.Message>): {
  answeredIds: string[];
} {
  const answeredIds: string[] = [];

  mapValues(messages, (message) => {
    if (
      !isLogMessage(message) &&
      message.answerTo &&
      !answeredIds.includes(message?.answerTo) &&
      !messages[message.answerTo]
    ) {
      answeredIds.push(message.answerTo);
    }
  });

  return { answeredIds };
}

export function* findUnfetchedMessages({
  roomId,
  messageIds,
}: {
  roomId: string;
  messageIds: string[];
}) {
  yield* put(actions.fetchUnknownMessages({ messageIds, roomId }));
}

export function* findAndFetchUnknownUsers(userIds: string[]) {
  yield* call(waitFor, ({ user }: RootState) => !user.isLoadingPoolUsers);
  const users = yield* select(userSel.selectUsers);
  const invalidIds = ["", "0", "superadmin"];
  const unknownUserIds = uniq(userIds).filter(
    (id) => users[id] === undefined && !invalidIds.includes(id),
  );
  for (const userId of unknownUserIds) {
    yield* put(UserActions.loadUser(userId));
  }
}

// Conditionals

export function isTextMessage(
  message: Types.Message | undefined,
): message is Types.TextMessage {
  return message !== undefined && message.type === "text";
}

export function isImageMessage(
  message: Types.Message | undefined,
): message is Types.ImageMessage {
  return message !== undefined && message.type === "image";
}

export function isAudioMessage(
  message: Types.Message | undefined,
): message is Types.AudioMessage {
  return message !== undefined && message.type === "audio";
}

export function isVideoMessage(
  message: Types.Message | undefined,
): message is Types.VideoMessage {
  return message !== undefined && message.type === "video";
}

export function isDocumentMessage(
  message: Types.Message | undefined,
): message is Types.DocumentMessage {
  return message !== undefined && message.type === "document";
}

export function isGeolocationMessage(
  message: Types.Message | undefined,
): message is Types.GeolocationMessage {
  return message !== undefined && message.type === "geolocation";
}

export function isLogMessage(
  message: Types.Message | undefined,
): message is Types.LogMessage {
  return message !== undefined && message.type === "log";
}

export function isReply(message: Types.Message | undefined): boolean {
  return (
    message !== undefined && "isReply" in message && message.isReply === true
  );
}

export function isDeleted(message: Types.Message | null | undefined): boolean {
  return (
    !message || (!isLogMessage(message) && message.deletedAt !== undefined)
  );
}

export function hasAttachment(message: Types.Message): message is FileMessage {
  return (
    isImageMessage(message) ||
    isVideoMessage(message) ||
    isDocumentMessage(message) ||
    isAudioMessage(message)
  );
}

export const hasGeolocatedAttachment = (
  message: Types.Message,
): message is Types.ImageMessage | Types.VideoMessage =>
  isImageMessage(message) || isVideoMessage(message);

export function isPersisted(
  message: Types.Message | null | undefined,
): boolean {
  if (!message) {
    return false;
  }

  if (isLogMessage(message)) {
    return true;
  }

  return message.sendingStatus === "persisted";
}

export function isMessageAgeOkToBeEdited(message: Types.MessageWithText) {
  if (
    moment().isAfter(
      moment(message.createdAt).add(EDIT_OR_DELETE_LIMIT_DAYS, "days"),
    )
  ) {
    return false;
  }

  return true;
}

export const canShowRemoveMessage = (
  message: Types.Message,
  isSuperadmin: boolean,
  isAccountOwner: boolean,
) => {
  return !isReply(message) || isSuperadmin || isAccountOwner;
};

export function openMessageEditingAgeLimitationAlert() {
  AlertDialog.alert(
    i18n.t("messageCannotBeEditedAlertTitle"),
    i18n.t("messageCannotBeEditedAlertContent"),
    [
      {
        text: i18n.t("cancel"),
        style: "cancel",
      },
    ],
  );
}

export function canRemoveMessage(
  message: Types.Message,
  isSuperadmin: boolean,
  isAccountOwner: boolean,
) {
  if (!canShowRemoveMessage(message, isSuperadmin, isAccountOwner)) {
    return false;
  }

  const can =
    isSuperadmin ||
    isAccountOwner ||
    !moment().isAfter(
      moment(message.createdAt).add(EDIT_OR_DELETE_LIMIT_DAYS, "days"),
    );

  if (!can) {
    AlertDialog.alert(
      i18n.t("messageCannotBeRemovedAlertTitle"),
      i18n.t("messageCannotBeRemovedAlertContent"),
      [
        {
          text: i18n.t("cancel"),
          style: "cancel",
        },
      ],
    );
  }

  return can;
}

export function isMessageAwaitingSend(message: Types.Message) {
  return (
    !isLogMessage(message) &&
    ["sending", "error"].includes(message.sendingStatus)
  );
}

export const orderMessages = <T extends Types.Message>(
  messages: T[],
  reverseOrder = false,
) => {
  return orderBy(
    messages,
    [
      (message) => (isMessageAwaitingSend(message) ? 0 : 1),
      (message) => message.createdAt,
      (message) => message.id,
    ],
    reverseOrder ? ["desc", "asc", "asc"] : ["asc", "desc", "desc"],
  );
};

export function isUserMessage(msg: Types.Message): msg is Types.UserMessage {
  return Types.UserMessageTypesSet.has(msg.type);
}

export function messageHasReactions(msg: Types.UserMessage) {
  return Object.keys(msg.reactions).length > 0;
}

export function messageHasText(
  message: Types.Message,
): message is Types.MessageWithText {
  return (
    isTextMessage(message) ||
    isImageMessage(message) ||
    isVideoMessage(message) ||
    isAudioMessage(message)
  );
}

export function getMessageInputPartitions(message: Types.MessageWithText) {
  switch (message.type) {
    case "text":
      return message.text;
    case "image":
    case "video":
      return message.attachment.caption;
    case "audio":
      return message.attachment.transcription;
  }
}

export function setMessageInputPartitions(
  message: Types.MessageWithText,
  text: InputPartition[],
) {
  switch (message.type) {
    case "text":
      message.text = text;
      break;
    case "image":
    case "video":
      message.attachment.caption = text;
      break;
    case "audio":
      message.attachment.transcription = text;
      break;
  }
}

export function getIdFromMessage(message: Types.Message) {
  return message.id;
}

export function getKeyFromMessage(message: Types.Message) {
  return isUserMessage(message)
    ? message.optimisticId || message.id
    : message.id;
}

export function sortMessagesFn(
  a: MessageTypes.Message,
  b: MessageTypes.Message,
) {
  const timeDiff = a.createdAt.getTime() - b.createdAt.getTime();
  if (timeDiff !== 0) {
    return timeDiff;
  }
  return a.id < b.id ? -1 : a === b ? 0 : 1;
}

export function sortMessages(
  messages: Record<string, Types.Message>,
  reversed?: boolean,
) {
  return Object.values(messages).sort(
    (a, b) => sortMessagesFn(a, b) * (reversed ? -1 : 1),
  );
}
