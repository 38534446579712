import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { isEmpty } from "lodash/fp";

import {
  Members,
  MembersProps,
} from "@kraaft/shared/components/members/members";
import { SearchBar } from "@kraaft/shared/components/searchBar";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";
import { useKeyboardState } from "@kraaft/shared/core/utils/useKeyboardState";

import { styles } from "./membersWithSearch.styles";

export type MembersWithSearchProps = Omit<
  MembersProps,
  "contentContainerStyle" | "isScrollDisabled"
>;

export const MembersWithSearch = ({
  items,
  ...props
}: MembersWithSearchProps) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [isKeyboardOpen] = useKeyboardState();

  const filteredItems = useMemo(
    () =>
      items?.filter((item) =>
        normalizeTextForSearch(item.name).includes(
          normalizeTextForSearch(search),
        ),
      ),
    [items, search],
  );

  return (
    <View style={styles.container}>
      <View style={styles.search}>
        <SearchBar
          placeholder={t("search")}
          value={search}
          isStatic
          withPlaceholderIcon
          withCancelButton={isKeyboardOpen}
          onChange={setSearch}
        />
      </View>
      <Members
        items={filteredItems}
        contentContainerStyle={styles.content}
        isScrollDisabled={isEmpty(filteredItems)}
        {...props}
      />
    </View>
  );
};
