import { StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  background: {
    backgroundColor: Color.WHITE,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: Spacing.S16,
    paddingTop: Spacing.S16,
    paddingBottom: isNative() ? Spacing.S8 : Spacing.S16,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  inputContainer: {
    flex: 1,
    marginRight: Spacing.S8,
  },
});
