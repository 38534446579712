import { useMemo } from "react";
import { Accept, DropzoneOptions, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { type ResourceKey } from "@kraaft/shared/core/services/i18next";
import { formatError } from "@kraaft/shared/core/utils";
import { Button, Icon, Text } from "@kraaft/ui";
import { ErrorType } from "@kraaft/web/src/core/types/dropzoneFile";

import { getFileDropzoneErrorMessage } from "./fileDropzone.utils";

import { styles, useStyles } from "./fileDropzone.styles";

interface FileResultAccepted {
  isValid: true;
  file: File;
}

interface FileResultRejected {
  isValid: false;
  file: File;
  errors: ErrorType[];
}

export type FileResult = FileResultAccepted | FileResultRejected;

interface FileDropzoneProps {
  accept: Accept;
  maxSize: number;
  invalidFileErrorKey?: ResourceKey;
  fileResult: FileResult | undefined;
  setFileResult: (newFileResult: FileResult) => void;
  extraErrorsMessages?: string[];
}

const FileDropzone = (props: FileDropzoneProps) => {
  const {
    accept,
    maxSize,
    invalidFileErrorKey,
    fileResult,
    setFileResult,
    extraErrorsMessages,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const hasFile = fileResult !== undefined;

  const dropzoneOptions = useMemo<DropzoneOptions>(
    () => ({
      onDrop: ([acceptedFile], [rejectedFile]) => {
        if (acceptedFile !== undefined) {
          setFileResult({ isValid: true, file: acceptedFile });
        } else if (rejectedFile !== undefined) {
          setFileResult({
            isValid: false,
            file: rejectedFile.file,
            errors: rejectedFile.errors.map(
              (error) => formatError(error.code) as ErrorType,
            ),
          });
        }
      },
      accept,
      multiple: false,
      noClick: true,
      maxSize,
    }),
    [accept, maxSize, setFileResult],
  );
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    open: openFilePicker,
  } = useDropzone(dropzoneOptions);

  const potentialErrorsMessages = useMemo(() => {
    if (!hasFile || (fileResult.isValid && !extraErrorsMessages?.length)) {
      return;
    }

    const filename = fileResult.file.name;
    const errorsMessages: string[] = [];

    if (!fileResult.isValid) {
      for (const error of fileResult.errors) {
        errorsMessages.push(
          t(getFileDropzoneErrorMessage(invalidFileErrorKey, error), {
            fileType: fileResult.file.type,
          }),
        );
      }
    }
    if (extraErrorsMessages !== undefined) {
      errorsMessages.push(...extraErrorsMessages);
    }

    return (
      <>
        <div className={classes.errorLabel}>
          <Text size="SMALL" color="ACTION_DESCTRUCTIVE">
            {t("importFileError", { file: filename })}
          </Text>
        </div>
        {errorsMessages.map((message) => (
          <div key={message} className={classes.errorBullet}>
            <Text key={message} size="SMALL" color="ACTION_DESCTRUCTIVE">
              {`- ${message}`}
            </Text>
          </div>
        ))}
      </>
    );
  }, [
    hasFile,
    fileResult,
    extraErrorsMessages,
    classes.errorLabel,
    classes.errorBullet,
    t,
    invalidFileErrorKey,
  ]);

  return (
    <>
      <div
        {...getRootProps()}
        className={clsx(classes.dropzone, {
          [classes.borderAccept]: isDragAccept,
        })}
      >
        <input {...getInputProps()} />

        <div className={classes.dropzonContent}>
          {hasFile ? (
            <div className={classes.filenameContainer}>
              <Icon
                name={fileResult.isValid ? "check-circle" : "x-circle"}
                color={
                  fileResult.isValid ? "GREEN_LAGOON" : "ACTION_DESCTRUCTIVE"
                }
                size="SMALL"
              />
              <Text
                weight="bold"
                size="BODY"
                color="FONT_HIGH_EMPHASIS"
                style={styles.centeredText}
              >
                {fileResult.file.name}
              </Text>
            </div>
          ) : (
            <Text
              size="BODY"
              color="FONT_HIGH_EMPHASIS"
              style={styles.centeredText}
            >
              {t("dropzone.dragAndDrop")}
            </Text>
          )}
          <Button
            style={styles.buttonContainer}
            accessibilityLabel={hasFile ? t("replace") : t("loadFile")}
            text={hasFile ? t("replace") : t("loadFile")}
            icon="upload-cloud-01"
            variant="QUATERNARY"
            onPress={openFilePicker}
          />
        </div>
      </div>

      {potentialErrorsMessages}
    </>
  );
};

export { FileDropzone };
