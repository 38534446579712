import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { showInfo } from "@kraaft/shared/core/modules/alert/alertActions";
import { clearLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { selectLoader } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { excelExportLoadingId } from "@kraaft/shared/core/modules/loaders/loaderUtils";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { ModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Button, Sheet, Spacing, Text } from "@kraaft/ui";

import { ExportFilterCoupleItem } from "./exportFilterCoupleItem";
import { ExportFilterMetadataItem } from "./exportFilterMetadataItem";
import { ExportFilterStandaloneItem } from "./exportFilterStandaloneItem";
import { ExportFilterStandaloneMetadataItem } from "./exportFilterStandaloneMetadataItem";

import { makeDialogStyle } from "./exportDialog.styles";
import { useStyles as makeExportItemStyles } from "./exportFilterItem.styles";

interface ExportPopupProps {
  schema: KSchema | undefined;
  onClose: () => void;
  filtersList: CompositeCondition | undefined;
  numberOfRecords: number;
  isExportingRooms?: boolean;
}

export const ExportPopup = Sheet({ web: "popup" })
  .create(
    ({ Paper, Header, Content, Footer }) =>
      ({
        schema,
        onClose,
        filtersList,
        numberOfRecords,
        isExportingRooms,
      }: ExportPopupProps) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const loader = useSelector(selectLoader(excelExportLoadingId));
        const classes = makeDialogStyle();
        const itemClasses = makeExportItemStyles();
        const isLoading = loader?.status === LoaderStatus.LOADING;

        useEffect(() => {
          if (loader?.status === LoaderStatus.SUCCESS) {
            dispatch(clearLoader(excelExportLoadingId));
            onClose();
          }
        }, [dispatch, loader?.status, onClose]);

        const schemaColumns = useMemo(
          () =>
            schema ? KSchemaUtils.flattenColumnsDict(schema.rootSection) : {},
          [schema],
        );

        const filters = (
          baseCondition: CompositeCondition | undefined,
          depth = 0,
        ): React.ReactNode[] | null => {
          return (
            baseCondition?.conditions.flatMap((condition, index) => {
              const key = `${condition.type}-${index}`;

              switch (condition.type) {
                case "couple-record": {
                  return (
                    <div key={key}>
                      <ExportFilterCoupleItem
                        condition={condition}
                        index={index}
                        operator={baseCondition?.operator}
                        columns={schemaColumns}
                      />
                    </div>
                  );
                }
                case "standalone-record": {
                  return (
                    <div key={key}>
                      <ExportFilterStandaloneItem
                        condition={condition}
                        index={index}
                        operator={baseCondition?.operator}
                        columns={schemaColumns}
                      />
                    </div>
                  );
                }
                case "metadata": {
                  return (
                    <div key={key}>
                      <ExportFilterMetadataItem
                        condition={condition}
                        index={index}
                        operator={baseCondition?.operator}
                      />
                    </div>
                  );
                }
                case "standalone-metadata": {
                  return (
                    <div key={key}>
                      <ExportFilterStandaloneMetadataItem
                        condition={condition}
                        index={index}
                        operator={baseCondition?.operator}
                      />
                    </div>
                  );
                }
                case "composite": {
                  if (condition.conditions.length > 0) {
                    return [
                      depth > 0 ? (
                        <div
                          className={itemClasses.text}
                          key={key}
                          style={{ marginLeft: (depth - 1) * Spacing.S16 }}
                        >
                          {baseCondition.operator === "and"
                            ? t("andFilter")
                            : t("orFilter")}
                        </div>
                      ) : null,
                      <div
                        style={{
                          marginLeft: depth * Spacing.S16,
                        }}
                        key={key}
                      >
                        {filters(condition, depth + 1)}
                      </div>,
                    ];
                  }
                  return null;
                }
                default:
                  return null;
              }
            }) ?? []
          );
        };

        const handleExportPress = () => {
          if (schema) {
            if (isExportingRooms) {
              trackEvent({
                eventName: "Export Conversation Excel",
                filter_count: filtersList ? filtersList.conditions.length : 0,
              });
            }
            dispatch(
              showInfo({
                title: t("downloadReportInfoTitle"),
                message: t("downloadReportInfo"),
              }),
            );
            dispatch(ModularFolderActions.exportToExcel(schema, filtersList));
            onClose();
          }
        };

        const isFiltered = useMemo(() => {
          if (!filtersList) {
            return false;
          }

          if (filtersList.conditions.length === 0) {
            return false;
          }

          return !(
            filtersList.conditions.length === 1 &&
            filtersList.conditions[0]?.type === "composite" &&
            filtersList.conditions[0]?.conditions.length === 0
          );
        }, [filtersList]);

        return (
          <Paper>
            <Header onClose={onClose}>{t("excelExport")}</Header>
            <Content>
              <Text>
                {t(isFiltered ? "exportFilter" : "exportAllFilter", {
                  filterNumber: numberOfRecords,
                })}
              </Text>
              <div className={classes.explanation}>{filters(filtersList)}</div>
            </Content>
            <Footer>
              <Button
                loading={isLoading}
                variant="PRIMARY"
                accessibilityLabel={t("export")}
                text={t("export")}
                onPress={handleExportPress}
              />
            </Footer>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "SMALL" });
