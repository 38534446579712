import { Fade, Modal } from "@mui/material";

import { useStyles } from "@kraaft/shared/components/kPopup/kPopup.styles";
import { KPopupProps } from "@kraaft/web/src/components/kPopup/kPopup.types";
import { KPopupContained } from "@kraaft/web/src/components/kPopup/kPopupContained";
import { KPopupFullscreen } from "@kraaft/web/src/components/kPopup/kPopupFullscreen";

const KPopup = (props: KPopupProps) => {
  const { id, onClose, open, disableEnforceFocus, maxWidth, fullScreen } =
    props;
  const classes = useStyles({ maxWidth });

  return (
    <Modal
      id={id}
      BackdropProps={{ classes: { root: classes.overlay } }}
      open={open}
      onClose={onClose}
      hideBackdrop={fullScreen}
      disableEnforceFocus={disableEnforceFocus}
    >
      <Fade appear in={open} timeout={300}>
        <div className={classes.modalContainer}>
          {!fullScreen ? (
            <KPopupContained {...props} />
          ) : (
            <KPopupFullscreen {...props} />
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export { KPopup };
