import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import compact from "lodash/compact";

import { MessageActionFlowProps } from "@kraaft/shared/components/galleries/messageActionFlow/messageActionFlowProps";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";
import { DirectoryActions } from "@kraaft/shared/core/modules/directory/directoryActions";
import { selectIsInSelectionFlow } from "@kraaft/shared/core/modules/message/messageSelectors";
import { nullId } from "@kraaft/shared/core/utils";
import {
  ChooseDirectoryStep,
  ChooseDirectoryStepParams,
} from "@kraaft/web/src/components/multiStepDialog/customs/attachMessageSelectionSteps/chooseDirectoryStep";
import {
  ChooseModularFolderStep,
  ChooseModularFolderStepParams,
} from "@kraaft/web/src/components/multiStepDialog/customs/attachMessageSelectionSteps/chooseModularFolderStep";
import {
  ChooseRootStep,
  ChooseRootStepParams,
} from "@kraaft/web/src/components/multiStepDialog/customs/attachMessageSelectionSteps/chooseRootStep";
import { MultiStepDialog } from "@kraaft/web/src/components/multiStepDialog/multiStepDialog";
import { ensureStepType } from "@kraaft/web/src/components/multiStepDialog/multiStepDialogUtils";

const AttachMessageSelectionSteps = (props: MessageActionFlowProps) => {
  const { onClose, roomId, onSuccess } = props;

  const dispatch = useDispatch();

  const open = useSelector(
    selectIsInSelectionFlow({
      roomId,
      selectionSource: undefined,
      selectionType: "attach",
    }),
  );

  useEffect(() => {
    dispatch(DirectoryActions.subscribe({ roomId }));
    return () => {
      dispatch(DirectoryActions.unsubscribe({ roomId }));
    };
  }, [roomId, dispatch]);

  const [selectedSchemaId, setSelectedSchemaId] = useState<string | undefined>(
    undefined,
  );

  const [selectedDirectoryId, setSelectedDirectoryId] = useState<
    string | undefined
  >(undefined);

  const generateSteps = useMemo(() => {
    const steps = compact([
      ensureStepType<ChooseRootStepParams>({
        dialog: ChooseRootStep,
        dialogFrameProps: { fullHeight: true, size: KDialogSize.MEDIUM },
        params: { roomId, setSelectedSchemaId, setSelectedDirectoryId },
      }),
      ensureStepType<ChooseModularFolderStepParams>({
        dialog: ChooseModularFolderStep,
        dialogFrameProps: { fullHeight: true, size: KDialogSize.MEDIUM },
        params: { roomId, schemaId: selectedSchemaId, onSuccess },
      }),
      ensureStepType<ChooseDirectoryStepParams>({
        dialog: ChooseDirectoryStep,
        dialogFrameProps: { fullHeight: true, size: KDialogSize.MEDIUM },
        params: {
          roomId,
          setSelectedDirectoryId,
          directoryId: selectedDirectoryId ?? nullId,
          onSuccess,
        },
      }),
    ]);

    return steps;
  }, [onSuccess, roomId, selectedDirectoryId, selectedSchemaId]);

  return (
    <MultiStepDialog open={open} onClose={onClose} steps={generateSteps} />
  );
};

export { AttachMessageSelectionSteps };
