import { useCallback } from "react";

import { SimpleClusterTooltip } from "@kraaft/shared/components/mapController/markers/marker/cluster/simpleClusterTooltip/simpleClusterTooltip";
import { RoomCardTooltip } from "@kraaft/shared/components/mapController/markers/roomMarker/roomCardTooltip";
import { ROOM_CARD_WIDTH } from "@kraaft/shared/components/mapController/markers/roomMarker/roomCardTooltip/roomCardTooltip.styles";
import { MarkerGeoJSON } from "@kraaft/shared/components/mapController/types";
import { RoomCardWithGeolocation } from "@kraaft/shared/core/modules/roomCard/roomCard.state";

interface RoomClusterTooltipProps {
  markerCount: number;
  markers: MarkerGeoJSON<RoomCardWithGeolocation>[];
  onPress?: (roomCard: RoomCardWithGeolocation) => void;
}

const RoomClusterTooltip = ({
  markerCount,
  markers,
  onPress,
}: RoomClusterTooltipProps) => {
  const renderItem = useCallback(
    (roomCard: RoomCardWithGeolocation) => (
      <RoomCardTooltip
        key={roomCard.roomId}
        roomCard={roomCard}
        onPress={() => onPress?.(roomCard)}
      />
    ),
    [onPress],
  );

  return (
    <SimpleClusterTooltip
      markerCount={markerCount}
      markers={markers}
      renderItem={renderItem}
      contentWidth={ROOM_CARD_WIDTH}
    />
  );
};

export { RoomClusterTooltip };
