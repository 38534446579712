export function getSourceType(url: string): string {
  const regex = /\.([^.]*?)(?=\?|#|$)/i;
  const match: RegExpMatchArray | null = url.match(regex);
  if (match !== null && match[1] !== undefined) {
    const extension = match[1].toLowerCase();
    if (["ogg", "webm"].includes(extension)) {
      return `video/${extension}`;
    }
  }
  return "video/mp4";
}
