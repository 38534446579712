import { View } from "react-native";

import { ColorStyle, Icon } from "@kraaft/ui";

import { styles } from "./privateConversation.styles";

interface Props {
  iconSize: number;
}

const PrivateConversation = ({ iconSize: size }: Props) => {
  return (
    <View style={styles.lockIconContainer}>
      <Icon
        name="lock-03-fill"
        size={size}
        color={ColorStyle.FONT_HIGH_EMPHASIS}
      />
    </View>
  );
};

export { PrivateConversation };
