import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: FontSize.BODY,
    fontWeight: "normal",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  spacer: {
    marginBottom: 22,
  },
  noMargin: {
    margin: 0,
    marginBottom: 3,
  },
});
