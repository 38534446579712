import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    position: "relative",
    padding: Spacing.S16,
    boxSizing: "border-box",
    minHeight: "100%",
  },
  counter: {
    display: "flex",
    justifyContent: "end",
  },
});
