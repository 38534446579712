import { useTranslation } from "react-i18next";

import { useDialog } from "@kraaft/shared/components/kDialog/context";
import { Button } from "@kraaft/ui";
import { AddSchemaDialogContent } from "@kraaft/web/src/views/schemasView/addSchema/addSchemaDialogContent";

export const AddSchemaButton = () => {
  const { t } = useTranslation();
  const { openDialog, closeDialog } = useDialog();

  const openSchemaDialog = () => {
    openDialog(<AddSchemaDialogContent onClose={closeDialog} />);
  };

  return (
    <Button
      text={t("schemasView.newSchemaButton")}
      onPress={openSchemaDialog}
    />
  );
};
