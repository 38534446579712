import { ModernFile } from "@kraaft/shared/core/modules/file/file";
import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { getAttachmentTypeFromMimeType, uuid } from "@kraaft/shared/core/utils";

export function modernFileToAttachment(
  file: ModernFile,
  userId: string,
): Attachment {
  const mimeType = file.contentType;
  const type = getAttachmentTypeFromMimeType(mimeType);

  if (type === "image") {
    return {
      type: "image",
      createdAt: new Date(),
      original: {
        downloadUrl: file.path,
        filename: file.filename,
      },
      size: undefined,
      id: uuid(),
      senderUserId: userId,
    };
  }
  if (type === "audio") {
    return {
      type: "audio",
      createdAt: new Date(),
      id: uuid(),
      original: {
        downloadUrl: file.path,
        filename: file.filename,
      },
      senderUserId: userId,
    };
  }
  if (type === "video") {
    return {
      type: "video",
      createdAt: new Date(),
      original: {
        downloadUrl: file.path,
        filename: file.filename,
      },
      id: uuid(),
      senderUserId: userId,
      converted: {},
    };
  }
  return {
    type: "document",
    createdAt: new Date(),
    id: uuid(),
    original: {
      downloadUrl: file.path,
      filename: file.filename,
    },
    senderUserId: userId,
  };
}
