import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { RoomActions } from "@kraaft/shared/core/modules/room/roomActions";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

const useSetRoomEmoji = (roomId: string) => {
  const dispatch = useDispatch();

  return useCallback(
    (emoji: string) => {
      trackEvent({
        eventName: "Set Room Emoji",
        room_id: roomId,
        emoji,
      });
      dispatch(RoomActions.changeRoomEmoji({ roomId, emoji }));
    },
    [dispatch, roomId],
  );
};

export { useSetRoomEmoji };
