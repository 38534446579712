import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  main: {
    flexGrow: 1,
    paddingVertical: Spacing.S16,
    height: "100%",
  },
  messagesWrapper: {
    flexShrink: 1,
    flexGrow: 1,
    position: "relative",
  },
  activityIndicator: {
    paddingVertical: 10,
  },
});
