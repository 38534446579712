import { useCallback, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { KSelectionListToggle } from "@kraaft/shared/components/kSelectionListToggle/kSelectionListToggle";
import { SafeAreaView } from "@kraaft/shared/components/safeAreaView";
import { selectModularFoldersBySchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectPoolFolderSchemas } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Icon, Spacing, Text } from "@kraaft/ui";

type SchemaListWithTogglesProps = {
  poolId: string;
  roomId: string;
  selectedSchemaIds: Set<string>;
  onShowSchema: (schemaId: string) => void;
  onHideSchema: (schemaId: string) => void;
  id?: string;
  showModularFolderCount?: boolean;
};

interface SchemaInfo {
  recordCount: number;
}

const SchemaListWithToggles = ({
  poolId,
  roomId,
  selectedSchemaIds,
  onShowSchema,
  onHideSchema,
  id,
  showModularFolderCount,
}: SchemaListWithTogglesProps) => {
  const schemas = useSelector(selectPoolFolderSchemas(poolId));
  const { folders: modularFoldersBySchemaId } = useSelector(
    selectModularFoldersBySchemaId(roomId),
  );

  const items = useMemo<KSelectableListItem<string, SchemaInfo>[]>(
    () =>
      schemas.map((s) => {
        const recordCount = modularFoldersBySchemaId[s.id]?.length ?? 0;

        return {
          label: s.name,
          identifier: s.id,
          icon: KSchemaUtils.getSchemaIconName(s.icon),
          value: { recordCount },
        };
      }),
    [modularFoldersBySchemaId, schemas],
  );

  const selected = useMemo(
    () => schemas.filter((s) => selectedSchemaIds.has(s.id)).map((s) => s.id),
    [schemas, selectedSchemaIds],
  );

  const onSelect = useCallback(
    (
      _: unknown,
      targetItems: KSelectableListItem<string, SchemaInfo>[],
      value: boolean,
    ) => {
      for (const item of targetItems) {
        if (value) {
          onShowSchema(item.identifier);
        } else {
          onHideSchema(item.identifier);
        }
      }
    },
    [onHideSchema, onShowSchema],
  );

  const renderItem = useCallback(
    (item: KSelectableListItem<string, SchemaInfo>) => {
      return (
        <View style={styles.itemContainer}>
          {item.icon && <Icon size="MEDIUM" name={item.icon} />}
          <Text style={styles.textLabel} size="BODY" numberOfLines={1}>
            {item.label}
          </Text>
          {showModularFolderCount && (
            <Text size="BODY" color="FONT_LOW_EMPHASIS">
              ({item.value?.recordCount ?? 0})
            </Text>
          )}
        </View>
      );
    },
    [showModularFolderCount],
  );

  return (
    <>
      <KSelectionListToggle
        id={id}
        items={items}
        selected={selected}
        setSelected={onSelect}
        renderItem={renderItem}
      />
      <SafeAreaView edges={["bottom"]} />
    </>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    gap: Spacing.S8,
    paddingLeft: Spacing.S16,
    paddingRight: Spacing.S8,
  },
  textLabel: {
    flexShrink: 1,
  },
});

export { SchemaListWithToggles };
