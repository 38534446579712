import { makeStyles } from "@mui/styles";

import { FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    gap: Spacing.S12,
    fontSize: FontSize.MEDIUM,
  },
});
