import { useMemo, useState } from "react";

import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectionListUncontrolledProps } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";

const KSelectionListUncontrolled = <T extends string, U>({
  defaultValue,
  ...otherProps
}: KSelectionListUncontrolledProps<T, U>) => {
  const preselected = useMemo(() => {
    if (defaultValue) {
      return Array.isArray(defaultValue) ? defaultValue : [defaultValue];
    }
    return [];
  }, [defaultValue]);

  const [selected, setSelected] = useState(preselected);

  return (
    <KSelectionList
      selected={selected}
      setSelected={setSelected}
      {...otherProps}
    />
  );
};

export { KSelectionListUncontrolled };
