import { AnyAction } from "redux";
import { eventChannel } from "redux-saga";
import { call, put, select, take, takeEvery } from "typed-redux-saga/macro";

import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import {
  PoolActions,
  subscribeToPool,
  unsubscribeFromPool,
} from "@kraaft/shared/core/modules/pool/poolActions";
import { selectOnePool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { Firestore } from "@kraaft/shared/core/services/firestore";

function* checkFreemiumStatusSaga(pool: Pool) {
  const previousPool = yield* select(selectOnePool(pool.id));

  if (previousPool && previousPool.isFreemium !== pool.isFreemium) {
    return {
      isFreemium: pool.isFreemium,
    };
  }
  return false;
}

function* receivePool(payload: { pool: Pool }) {
  const hasFreemiumStatusChanged = yield* checkFreemiumStatusSaga(payload.pool);
  yield* put(PoolActions.receivePools({ pools: [payload.pool] }));

  if (hasFreemiumStatusChanged) {
    yield* put(
      PoolActions.freemiumStatusUpdated({
        isFreemium: hasFreemiumStatusChanged.isFreemium,
      }),
    );
  }
}

function createPoolChannel(poolId: string) {
  return eventChannel<{ pool: Pool }>((emit) =>
    Firestore.subscribeToPool(poolId, (pool) => emit({ pool })),
  );
}

function isUnsubscribeFromPool(poolId: string) {
  return (action: AnyAction) =>
    unsubscribeFromPool.match(action) && action.payload.poolId === poolId;
}

export function* subscribeToPoolSaga({
  payload,
}: ReturnType<typeof subscribeToPool>) {
  const { poolId } = payload;
  const channel = yield* call(createPoolChannel, poolId);
  yield* takeEvery(channel, receivePool);

  yield* take([
    UserActions.userDisconnectedFromFirebase,
    isUnsubscribeFromPool(poolId),
  ]);

  channel.close();
}
