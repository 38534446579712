import { useCallback } from "react";

import { SimpleClusterTooltip } from "@kraaft/shared/components/mapController/markers/marker/cluster/simpleClusterTooltip/simpleClusterTooltip";
import { FolderCardTooltip } from "@kraaft/shared/components/mapController/markers/modularFolderMarker/folderCardTooltip";
import { FOLDER_CARD_WIDTH } from "@kraaft/shared/components/mapController/markers/modularFolderMarker/folderCardTooltip/folderCardTooltip.styles";
import { MarkerGeoJSON } from "@kraaft/shared/components/mapController/types";
import { ModularFolderWithGeolocation } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";

interface ModularFolderClusterTooltipProps {
  markerCount: number;
  markers: MarkerGeoJSON<ModularFolderWithGeolocation>[];
  onPress?: (modularFolder: ModularFolderWithGeolocation) => void;
}

const ModularFolderClusterTooltip = ({
  markerCount,
  markers,
  onPress,
}: ModularFolderClusterTooltipProps) => {
  const renderItem = useCallback(
    (modularFolder: ModularFolderWithGeolocation) => (
      <FolderCardTooltip
        key={modularFolder.id}
        schemaId={modularFolder.schemaId}
        modularFolderId={modularFolder.id}
        onPress={() => onPress?.(modularFolder)}
      />
    ),
    [onPress],
  );

  return (
    <SimpleClusterTooltip
      markerCount={markerCount}
      markers={markers}
      renderItem={renderItem}
      contentWidth={FOLDER_CARD_WIDTH}
    />
  );
};

export { ModularFolderClusterTooltip };
