import { useCallback, useMemo } from "react";
import { StrictOmit } from "ts-essentials";

import {
  KDropdown,
  KDropdownProps,
} from "@kraaft/web/src/components/dropdown/kDropdown";
import { useAvailableCurrencies } from "@kraaft/web/src/views/settings/managePool/settingsCurrency/useAvailableCurrencies";

interface Props
  extends StrictOmit<
    KDropdownProps,
    "items" | "onSelectionChange" | "selectedItemIds" | "withSearchBar"
  > {
  currency: string;
  onChange(currency: string): void;
}

const CurrencyDropdown = ({ currency, onChange, ...dropdownProps }: Props) => {
  const selected = useMemo(() => [currency], [currency]);
  const currencies = useAvailableCurrencies();

  const onSelectionChange = useCallback(
    (value: string[] | undefined) => {
      if (value?.[0]) {
        onChange(value[0]);
      }
    },
    [onChange],
  );

  return (
    <KDropdown
      {...dropdownProps}
      items={currencies}
      selectedItemIds={selected}
      onSelectionChange={onSelectionChange}
      withSearchBar
    />
  );
};

export { CurrencyDropdown };
