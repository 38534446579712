import { RoomNameLayout } from "@kraaft/shared/components/createRoom/roomName/roomName.layout";

interface RoomNameProps {
  poolId: string;
  labelIds: string[] | undefined;
  onRoomCreated: (roomId: string) => void;
}

const RoomName = (props: RoomNameProps) => {
  const { poolId, labelIds, onRoomCreated } = props;

  return (
    <RoomNameLayout
      poolId={poolId}
      labelIds={labelIds}
      onRoomCreated={onRoomCreated}
    />
  );
};

export { RoomName };
