import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import {
  ActionFooter,
  FooterActions,
} from "@kraaft/shared/components/actionFooter/actionFooter";
import { DocumentGallery } from "@kraaft/shared/components/galleries/documentGallery";
import { MiniDocument } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";
import { Color } from "@kraaft/ui";

import { styles } from "./documentGalleryPicker.styles";

interface Props {
  roomId: string;
  medias: MiniDocument[];
  onSubmit: () => void;
  isLoading: boolean;
}

const DocumentGalleryPicker = (props: Props) => {
  const { roomId, medias, onSubmit, isLoading } = props;

  const { t } = useTranslation();

  const { selectionCount } = useMediaSelectionContext({
    medias,
    roomId,
    type: "document",
  });

  const actions: FooterActions = [
    {
      accessibilityLabel: t("add"),
      text: t("add"),
      onPress: onSubmit,
      loading: isLoading,
      disabled: selectionCount === 0,
    },
  ];

  return (
    <View style={styles.container}>
      <View style={styles.galleryContainer}>
        <DocumentGallery
          roomId={roomId}
          medias={medias}
          mode="selection"
          backgroundColor={Color.WHITE}
        />
      </View>
      <ActionFooter
        backgroundColor={Color.WHITE}
        actions={actions}
        direction={isNative() ? "vertical" : "horizontal"}
      />
    </View>
  );
};

export { DocumentGalleryPicker };
