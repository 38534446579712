import { createAction } from "@reduxjs/toolkit";

import { Company } from "@kraaft/shared/core/modules/company/company.state";

export const CompanyStateActions = {
  set: createAction<Record<string, Company>>("@company/state/set"),
};

export const CompanyActions = {
  subscribe: createAction("@company/subscribe"),
  subscribeTo: createAction<{ companyId: string }>("@company/subscribeTo"),
  unsubscribe: createAction("@company/unsubscribe"),
  unsubscribeFrom: createAction<{ companyId: string }>(
    "@company/unsubscribeFrom",
  ),
};
