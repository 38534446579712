import { useSelector } from "react-redux";

import { NotificationPreferences } from "@kraaft/shared/components/notificationPreferences/notificationPreferences";
import { usePoolNotificationFilter } from "@kraaft/shared/core/modules/userPool/hooks/userPoolNotificationFilter.hooks";
import { selectUserPoolNotificationFilter } from "@kraaft/shared/core/modules/userPool/userPool.selectors";
import { PoolNotificationFilterSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

interface RoomNotificationPreferencesProps {
  poolId: string;
  source: PoolNotificationFilterSource;
  noPadding?: boolean;
}

export const PoolNotificationPreferences = ({
  poolId,
  source,
  noPadding,
}: RoomNotificationPreferencesProps) => {
  const poolOption = useSelector(selectUserPoolNotificationFilter(poolId));

  const { changeNotificationFilter } = usePoolNotificationFilter({
    poolId,
    source,
  });

  if (!changeNotificationFilter) {
    return null;
  }

  return (
    <NotificationPreferences
      type="pool"
      poolNotificationFilter={poolOption}
      onChange={changeNotificationFilter}
      noPadding={noPadding}
    />
  );
};
