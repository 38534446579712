import { StyleSheet } from "react-native";

import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    backgroundColor: Color.WHITE,
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
  },
  button: {
    margin: Spacing.S16,
  },
});
