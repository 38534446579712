import { useCallback } from "react";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KFormatterProps } from "@kraaft/web/src/components/modularTable/components/types";

import { ExpandCellWrapper } from "./expandCellWrapper";

export const withExpandButton =
  (
    FormatterComponent: React.ComponentType<KFormatterProps<KColumnType>>,
    onExpand: (row: ModularRecord) => void,
  ) =>
  (props: KFormatterProps<KColumnType>) => {
    const handleExpandClick = useCallback(() => {
      onExpand(props.row as ModularRecord);
    }, [props.row]);

    return (
      <ExpandCellWrapper onExpandClick={handleExpandClick}>
        <FormatterComponent {...props} />
      </ExpandCellWrapper>
    );
  };
