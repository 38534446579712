import { createReducer } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

import { SchemaViewState } from "@kraaft/shared/core/modules/schemaView/schemaViewState";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import {
  createEntryKeyForSchemaViews,
  RecordWorkspace,
} from "@kraaft/web/src/core/modules/memory/memoryUtils";

import * as actions from "./schemaViewActions";

const initialState: SchemaViewState = {
  schemaViews: {},
  unsavedSchemaViews: {},
};

function getUnsavedKey(
  workspace: RecordWorkspace,
  schemaId: string,
  schemaViewId?: string,
) {
  return schemaViewId ?? createEntryKeyForSchemaViews(workspace, schemaId);
}

function ensureTemporaryExists(
  state: SchemaViewState,
  workspace: RecordWorkspace,
  schemaId: string,
  schemaViewId?: string,
) {
  const entryKey = getUnsavedKey(workspace, schemaId, schemaViewId);

  let entry = state.unsavedSchemaViews[entryKey];
  if (!entry && schemaViewId) {
    const existing = state.schemaViews[schemaViewId];
    if (existing) {
      entry = {
        ...cloneDeep(existing),
        dirty: false,
      };
    }
  }
  if (!entry) {
    entry = {
      name: "",
      dirty: false,
      filters: {
        type: "composite",
        operator: "and",
        conditions: [],
      },
      formats: {
        kanban: {
          displayedProperties: [],
          groupedByProperty: undefined,
        },
      },
      schemaId,
    };
  }
  state.unsavedSchemaViews[entryKey] = entry;
  return entry;
}

export const schemaViewReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);

  addCase(actions.receiveSchemaViews, (state, { payload }) => {
    state.schemaViews = {};
    for (const schemaView of payload) {
      state.schemaViews[schemaView.id] = schemaView;
    }
  });

  addCase(
    actions.receiveTemporarySchemaViewsFromMemory,
    (state, { payload }) => {
      state.unsavedSchemaViews = payload.temporarySchemaViews;
    },
  );

  addCase(actions.editSchemaView, (state, { payload }) => {
    const key = getUnsavedKey(
      payload.workspace,
      payload.schemaId,
      payload.schemaViewId,
    );

    let edited = ensureTemporaryExists(
      state,
      payload.workspace,
      payload.schemaId,
      payload.schemaViewId,
    );

    edited = {
      ...edited,
      ...payload.schemaView,
      dirty: true,
    };

    state.unsavedSchemaViews[key] = edited;
  });

  addCase(actions.editSchemaViewKanbanFormat, (state, { payload }) => {
    const entry = ensureTemporaryExists(
      state,
      payload.workspace,
      payload.schemaId,
      payload.schemaViewId,
    );

    entry.dirty = true;

    entry.formats.kanban = {
      ...entry.formats.kanban,
      ...payload.editions,
    };
  });

  addCase(actions.clearTemporarySchemaView, (state, { payload }) => {
    const entryKey = getUnsavedKey(
      payload.workspace,
      payload.schemaId,
      payload.schemaViewId,
    );

    delete state.unsavedSchemaViews[entryKey];
  });

  // Adds optimistic to saving existing schemaViews, and preventing a
  // blink on save
  addCase(actions.saveEditedSchemaView, (state, { payload }) => {
    if (!payload.schemaViewId) {
      return;
    }
    const existing = state.unsavedSchemaViews[payload.schemaViewId];

    if (!existing) {
      return;
    }
    state.schemaViews[payload.schemaViewId] = {
      ...existing,
      id: payload.schemaViewId,
    };
  });

  addCase(actions.saveEditedSchemaViewFailure, (state, { payload }) => {
    state.schemaViews[payload.schemaView.id] = payload.schemaView;
  });

  addCase(actions.editSchemaViewName, (state, { payload }) => {
    const existing = state.schemaViews[payload.schemaViewId];
    if (existing) {
      existing.name = payload.name;
    }
    const existingTemporary = state.unsavedSchemaViews[payload.schemaViewId];
    if (existingTemporary) {
      existingTemporary.name = payload.name;
    }
  });
});
