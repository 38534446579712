import { StyleSheet } from "react-native";

import { Color, PixelSize, Radius } from "@kraaft/ui";

export const styles = StyleSheet.create({
  containerBase: {
    borderRadius: Radius.SMALL,
    alignItems: "center",
    justifyContent: "center",
  },
  containerLarge: {
    height: PixelSize.S32,
    width: PixelSize.S32,
  },
  containerSmall: {
    height: PixelSize.S24,
    width: PixelSize.S24,
  },
  textLarge: {
    lineHeight: 20,
  },
  textSmall: {
    lineHeight: 15,
  },
  circleBase: {
    position: "absolute",
    top: -4,
    right: -4,
    backgroundColor: Color.RED_TOMATO,
    borderWidth: 2,
    borderColor: Color.WHITE,
  },
  circleLarge: {
    height: 13,
    width: 13,
    borderRadius: 6.5,
  },
  circleSmall: {
    height: 11,
    width: 11,
    borderRadius: 5.5,
  },
});
