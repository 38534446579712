import { difference } from "lodash";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  ModularRecordProperty,
  ModularRecordValueOfType,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";

function filterDifferenceAddedRemoved(
  added: string[] | undefined,
  removed: string[] | undefined,
) {
  return {
    filteredAdded: removed !== undefined ? difference(added, removed) : added,
    filteredRemoved: added !== undefined ? difference(removed, added) : removed,
  };
}

type SimplifiedValues<T extends KColumnType = KColumnType> = {
  valueType: T;
  addedValue: ModularRecordValueOfType<T>;
  removedValue: ModularRecordValueOfType<T>;
};

export function getSimplifiedValuesFromModularRecordProperties(
  oldValue: ModularRecordProperty | undefined,
  newValue: ModularRecordProperty | undefined,
): SimplifiedValues | null {
  if (oldValue === undefined) {
    if (newValue !== undefined) {
      return {
        valueType: newValue.columnType,
        addedValue: newValue.value,
        removedValue: undefined,
      };
    }
    return null;
  }

  if (newValue === undefined) {
    return {
      valueType: oldValue.columnType,
      addedValue: undefined,
      removedValue: oldValue.value,
    };
  }

  if (
    oldValue.columnType === KColumnType.selectMultiple &&
    newValue.columnType === KColumnType.selectMultiple
  ) {
    const { filteredAdded, filteredRemoved } = filterDifferenceAddedRemoved(
      newValue.value,
      oldValue.value,
    );

    return {
      valueType: newValue.columnType,
      addedValue: filteredAdded,
      removedValue: filteredRemoved,
    };
  }

  if (
    oldValue.columnType === KColumnType.attachment &&
    newValue.columnType === KColumnType.attachment
  ) {
    const { filteredAdded, filteredRemoved } = filterDifferenceAddedRemoved(
      newValue.value?.map((attachment) => attachment.id),
      oldValue.value?.map((attachment) => attachment.id),
    );

    return {
      valueType: newValue.columnType,
      addedValue: newValue.value?.filter((attachment) =>
        filteredAdded?.includes(attachment.id),
      ),
      removedValue: oldValue.value?.filter((attachment) =>
        filteredRemoved?.includes(attachment.id),
      ),
    };
  }

  if (oldValue.columnType !== newValue.columnType) {
    return null;
  }

  return {
    valueType: oldValue.columnType,
    addedValue: newValue.value,
    removedValue: oldValue.value,
  };
}
