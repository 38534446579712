import { DirectoryUtils } from "@kraaft/shared/core/modules/directory/utils/utils.types";
import { RemotePath } from "@kraaft/shared/core/modules/file/file";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";

export class WebDirectoryUtils implements DirectoryUtils {
  async downloadDirectory({
    downloadUrl,
    filename,
  }: { downloadUrl: RemotePath; filename: string }): Promise<void> {
    await fileSaver.download(downloadUrl, filename);
  }
}
