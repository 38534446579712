import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";

export function getValidUsers(records: ModularRecord[]): ModularRecord[] {
  return records.filter(
    (r) =>
      r.properties.firstName &&
      r.properties.lastName &&
      (r.properties.email || r.properties.phoneNumber),
  );
}
