import { useMemo } from "react";
import { ImageBackground, ImageSourcePropType, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { type ResourceKey } from "@kraaft/shared/core/services/i18next";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { Text } from "@kraaft/ui";

import emptyStateDocuments from "../../assets/images/emptyStateDocuments.png";
import emptyStateFolders from "../../assets/images/emptyStateFolders.png";
import emptyStateMembers from "../../assets/images/emptyStateMembers.png";
import emptyStateModularFolders from "../../assets/images/emptyStateModularFolders.png";
import emptyStatePhotos from "../../assets/images/emptyStatePhotos.png";
import emptyStateRoomList from "../../assets/images/emptyStateRoomList.png";
import emptyStateSearch from "../../assets/images/emptyStateSearch.png";
import { styles } from "./styles";

const PLATFORM = isNative() ? "native" : "web";

interface EmptyStateManifest {
  title: ResourceKey;
  subtitle: ResourceKey | undefined;
  image: any;
  width: number;
  height: number;
  textOffset: number;
}

const CONFIG = {
  documents: {
    title: `emptyState.documents.${PLATFORM}.title`,
    subtitle: `emptyState.documents.${PLATFORM}.subtitle`,
    image: emptyStateDocuments,
    width: 200,
    height: 217,
    textOffset: -45,
  },
  folders: {
    title: `emptyState.folders.${PLATFORM}.title`,
    subtitle: `emptyState.folders.${PLATFORM}.subtitle`,
    image: emptyStateFolders,
    width: 162,
    height: 200,
    textOffset: -36,
  },
  members: {
    title: `emptyState.members.${PLATFORM}.title`,
    subtitle: `emptyState.members.${PLATFORM}.subtitle`,
    image: emptyStateMembers,
    width: 220,
    height: 194,
    textOffset: -43,
  },
  modularFolderHistory: {
    title: "emptyState.modularFolderHistory.title",
    subtitle: undefined,
    image: emptyStateModularFolders,
    width: 184,
    height: 189,
    textOffset: 0,
  },
  modularFolders: {
    title: `emptyState.modularFolders.${PLATFORM}.title`,
    subtitle: `emptyState.modularFolders.${PLATFORM}.subtitle`,
    image: emptyStateModularFolders,
    width: 184,
    height: 189,
    textOffset: 0,
  },
  photos: {
    title: `emptyState.photos.${PLATFORM}.title`,
    subtitle: `emptyState.photos.${PLATFORM}.subtitle`,
    image: emptyStatePhotos,
    width: 184,
    height: 189,
    textOffset: -33,
  },
  roomList: {
    title: `emptyState.roomList.${PLATFORM}.title`,
    subtitle: `emptyState.roomList.${PLATFORM}.subtitle`,
    image: emptyStateRoomList,
    width: 195,
    height: 204,
    textOffset: -33,
  },
  search: {
    title: "searchMessages.empty",
    image: emptyStateSearch,
    subtitle: undefined,
    width: 300,
    height: 200,
    textOffset: -33,
  },
} as const satisfies Record<string, EmptyStateManifest>;

export type EmptyStateProps = {
  type: keyof typeof CONFIG;
};

const EmptyState = ({ type }: EmptyStateProps) => {
  const { title, subtitle, width, height, image, textOffset } = CONFIG[type];

  const imageStyle = useMemo(() => ({ height, width }), [height, width]);
  const textContainerStyle = useMemo(
    () => [styles.textContainer, { marginTop: textOffset }],
    [textOffset],
  );

  return (
    <View style={styles.container}>
      <ImageBackground
        source={image as ImageSourcePropType}
        resizeMode="contain"
        style={imageStyle}
      />
      <View style={textContainerStyle}>
        <Text weight="bold" size="BODY" style={styles.title}>
          <Trans i18nKey={title} />
        </Text>
        <Text color="FONT_LOW_EMPHASIS" size="MEDIUM" style={styles.subtitle}>
          <Trans i18nKey={subtitle} />
        </Text>
      </View>
    </View>
  );
};

export { EmptyState };
