import { DummyOfflineFeature } from "@kraaft/shared/core/modules/dummy/dummy.offline";
import { ModularFolderOfflineFeature } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { getTaskManagerFromOfflineFeature } from "@kraaft/shared/core/utils/optimistic/newOptimistic/createOfflineFeature";

export function getRegisteredTaskManagers() {
  return getTaskManagerFromOfflineFeature([
    DummyOfflineFeature,
    ModularFolderOfflineFeature,
  ]);
}
