import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  KSchema,
  KSchemaIcon,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { AutoNumberingMode } from "@kraaft/shared/core/modules/schema/schema.actions";
import {
  SchemaOptimisticActions,
  SchemaOptimisticOperations,
} from "@kraaft/shared/core/modules/schema/schema.optimistic";
import { getColumnDefinitionFromAutonumberingMode } from "@kraaft/shared/core/modules/schema/schema.utils";
import { EditMetadata } from "@kraaft/web/src/components/schemaBuilder/tabs/editMetadata/editMetadata";

interface EditPoolSchemaMetadataProps {
  schema: KSchema;
}

const EditPoolSchemaMetadata = ({ schema }: EditPoolSchemaMetadataProps) => {
  const dispatch = useDispatch();

  const handleChangeName = useCallback(
    (name: string) => {
      dispatch(
        SchemaOptimisticActions.addOperation(
          SchemaOptimisticOperations.rename.create({
            targetId: schema.id,
            name,
          }),
        ),
      );
    },
    [dispatch, schema.id],
  );

  const handleChangeIcon = useCallback(
    (icon: KSchemaIcon) => {
      dispatch(
        SchemaOptimisticActions.addOperation(
          SchemaOptimisticOperations.editMetadata.create({
            targetId: schema.id,
            icon,
          }),
        ),
      );
    },
    [dispatch, schema.id],
  );

  const handleChangeAutonumberMode = useCallback(
    (autoNumberingMode: AutoNumberingMode) => {
      dispatch(
        SchemaOptimisticActions.addOperation(
          SchemaOptimisticOperations.editColumnDefinition.create({
            targetId: schema.id,
            columnKey: "title",
            definition:
              getColumnDefinitionFromAutonumberingMode(autoNumberingMode),
          }),
        ),
      );
    },
    [dispatch, schema.id],
  );

  const handleChangeHighlightedCheckbox = useCallback(
    (columnKey: string | undefined) => {
      dispatch(
        SchemaOptimisticActions.addOperation(
          SchemaOptimisticOperations.setHighlightedCheckbox.create({
            targetId: schema.id,
            columnKey,
          }),
        ),
      );
    },
    [dispatch, schema.id],
  );

  return (
    <EditMetadata
      schema={schema}
      onChangeName={handleChangeName}
      onChangeIcon={handleChangeIcon}
      onChangeAutonumberMode={handleChangeAutonumberMode}
      onChangeHighlightedCheckbox={handleChangeHighlightedCheckbox}
    />
  );
};
export { EditPoolSchemaMetadata };
