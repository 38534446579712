import { makeStyles } from "@mui/styles";

import { Color, PixelSize, Radius, Spacing } from "@kraaft/ui";

const CLOSE_BUTTON_OFFSET = PixelSize.S32 + PixelSize.S4;

interface MakeStylesProps {
  maxWidth: React.CSSProperties["maxWidth"];
}

export const useStyles = makeStyles({
  container: {
    backgroundColor: Color.WHITE,
    position: "relative",
    borderRadius: Radius.SMALL,
    margin: `${Spacing.S32}px ${Spacing.S32 + CLOSE_BUTTON_OFFSET}px`,
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.4)",
    maxHeight: "85vh",
    width: "calc(100% - 64px)",

    maxWidth: ({ maxWidth }: MakeStylesProps) => maxWidth,
  },
  fullHeightContainer: {
    height: "100%",
  },
  crossContainer: {
    position: "absolute",
    right: -CLOSE_BUTTON_OFFSET,
    top: -CLOSE_BUTTON_OFFSET,
    "& > button": {
      backgroundColor: Color.WHITE,
    },
  },
  content: {
    maxHeight: "inherit",
    height: "100%",
    overflow: "hidden",
    borderRadius: Radius.SMALL,
  },
});
