export type PermissionResult =
  | "unavailable"
  | "blocked"
  | "denied"
  | "granted"
  | "limited";

export type PermissionName =
  | "notification"
  | "camera"
  | "location"
  | "photo_library"
  | "audio_recording"
  | "read_contacts";

export abstract class Permissions {
  abstract statusOf(type: PermissionName): Promise<PermissionResult>;
  abstract request(type: PermissionName): Promise<PermissionResult>;
  abstract handleEnableBlockedPermission(type: PermissionName): Promise<void>;

  static permissionOrder: PermissionResult[] = [
    "unavailable",
    "blocked",
    "denied",
    "limited",
    "granted",
  ];

  static reduceMultiplePermissionStatus(statuses: PermissionResult[]) {
    const defaultValue = this.permissionOrder[this.permissionOrder.length - 1];

    if (defaultValue === undefined) {
      return "unavailable";
    }

    return Object.values(statuses).reduce((acc, curr) => {
      if (
        this.permissionOrder.indexOf(curr) < this.permissionOrder.indexOf(acc)
      ) {
        return curr;
      }

      return acc;
    }, defaultValue);
  }

  static convertStatusToBoolean(
    permissionStatus: PermissionResult,
    allowLimited = true,
  ) {
    return (
      permissionStatus === "granted" ||
      (allowLimited && permissionStatus === "limited")
    );
  }

  private permissionRequestedListeners: Array<() => void> = [];

  addOnPermissionRequestListener(listener: () => void) {
    this.permissionRequestedListeners.push(listener);
    return () => {
      const index = this.permissionRequestedListeners.indexOf(listener);
      if (index < 0) {
        return;
      }
      this.permissionRequestedListeners.splice(index, 1);
    };
  }

  protected requestedPermissions() {
    for (const listener of this.permissionRequestedListeners) {
      listener();
    }
  }
}
