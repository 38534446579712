import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useArchive } from "@kraaft/shared/core/modules/room/hooks/roomArchive.hooks";
import {
  subscribeToRoomUsers,
  unsubscribeFromRoomUsers,
} from "@kraaft/shared/core/modules/room/roomActions";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { Button } from "@kraaft/ui";

interface Props {
  room: Room;
  setDisplayed?: () => void;
}

function useRoomUsersSubscription(roomId: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(subscribeToRoomUsers({ roomId }));
    return () => {
      dispatch(unsubscribeFromRoomUsers({ roomId }));
    };
  }, [dispatch, roomId]);
}

const ArchiveSection = ({ room: { id: roomId }, setDisplayed }: Props) => {
  const { t } = useTranslation();

  const { archive, unarchive, loading, isArchived } = useArchive(
    roomId,
    "room_info_tab",
  );

  const hasRights = (isArchived && unarchive) || (!isArchived && archive);

  useEffect(() => {
    if (hasRights) {
      setDisplayed?.();
    }
  }, [setDisplayed, hasRights]);

  useRoomUsersSubscription(roomId);

  const handlePress = useCallback(() => {
    if (isArchived && unarchive) {
      return unarchive();
    }

    if (!isArchived && archive) {
      return archive();
    }
  }, [isArchived, archive, unarchive]);

  if (!hasRights) {
    return null;
  }

  const text = t(isArchived ? "unarchive" : "archive");

  return (
    <Button
      variant="QUATERNARY"
      size="SMALL"
      alignContent="left"
      disabled={loading}
      id="room-details-archive-room"
      accessibilityLabel={text}
      text={text}
      icon={loading ? "dots-horizontal" : isArchived ? "unarchive" : "archive"}
      onPress={handlePress}
    />
  );
};

export { ArchiveSection };
