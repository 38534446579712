import {
  // eslint-disable-next-line no-restricted-imports
  Trans as BaseTrans,
  TransProps as BaseTransProps,
} from "react-i18next";

import {
  ImprovedI18n,
  ResourceKey,
  TFunction,
} from "@kraaft/shared/core/services/i18next";

type MinimumBaseProps = Omit<
  BaseTransProps<
    never, // We want the trans props without worrying about key typing
    never, // We want the trans props without worrying about namespace typing
    never, // We want the trans props without worrying about TKPrefix typing
    unknown // We actually care about the component typing
  >,
  "t" | "i18n" | "i18nKey"
>;

type TransProps<K extends ResourceKey> = MinimumBaseProps & {
  i18n?: ImprovedI18n;
  i18nKey?: K | K[];
  t?: TFunction;
};

/**
 * Same as react-i18next's {@link BaseTrans} but with our custom ResourceKeys for better performances.
 *
 * @see {@link BaseTrans}
 * @example
 * // Using Trans component to provide translations for a given key
 * <Trans id="example.key" />
 */
export const Trans = <K extends ResourceKey>(props: TransProps<K>) => {
  return <BaseTrans {...(props as BaseTransProps<any>)} />;
};
