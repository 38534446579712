import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { SkinToneVariant } from "@kraaft/shared/components/emojiSelector/emoji.types";
import { getSkinTone } from "@kraaft/shared/components/emojiSelector/emoji.utils";
import {
  SKIN_TONE_ELEMENT_WIDTH,
  SkinToneElement,
} from "@kraaft/shared/components/emojiSelector/skinToneSelector/skinToneElement";

const SKIN_TONE_ORDER = [
  "default",
  "light",
  "medium-light",
  "medium",
  "medium-dark",
  "dark",
] satisfies SkinToneVariant[];

type SkinToneSelectorCollapsibleContentProps = {
  selectedVariant: SkinToneVariant;
  onSelect: (skinToneVariant: SkinToneVariant) => void;
};

const SkinToneSelectorCollapsibleContent = React.memo(
  ({ selectedVariant, onSelect }: SkinToneSelectorCollapsibleContentProps) => {
    const orderedSkinTones = useMemo(() => {
      return SKIN_TONE_ORDER.filter((element) => element !== selectedVariant);
    }, [selectedVariant]);

    const skinTonesButtons = useMemo(
      () =>
        orderedSkinTones.map((skinToneVariant) => (
          <SkinToneElement
            key={skinToneVariant}
            skinTone={getSkinTone(skinToneVariant)}
            onPress={() => onSelect(skinToneVariant)}
          />
        )),
      [onSelect, orderedSkinTones],
    );

    return (
      <View
        style={[
          styles.container,
          /**
           * we need the width to be specified to prevent
           * CollapsibleHorizontal's onlayout from triggering
           */
          { width: orderedSkinTones.length * SKIN_TONE_ELEMENT_WIDTH },
        ]}
      >
        {skinTonesButtons}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
});

export { SkinToneSelectorCollapsibleContent };
