import { StyleSheet } from "react-native";

import { Color } from "@kraaft/ui";

export const styles = StyleSheet.create({
  tooltipContent: {
    backgroundColor: Color.WHITE,
    borderRadius: 6,
    paddingHorizontal: 8,
    flexDirection: "row",
    alignItems: "center",
  },
});
