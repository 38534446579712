import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  ActionSheetItem,
  LegacyActionSheet,
} from "@kraaft/shared/components/legacyActionSheet";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { selectOrderedEnabledTemplatesBySchemaId } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.selectors";
import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import {
  DownloadReportFormat,
  getReportTemplateIcon,
} from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.utils";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";

import { getReportTemplateName } from "../../core/modules/reportTemplate/reportTemplate.utils";

interface UseReportTemplateSelectionProps {
  schemaId: string | undefined;
  allowPDF: boolean;
  callback: (
    reportTemplate: ReportTemplate,
    format: DownloadReportFormat,
  ) => void;
  anchorRef: React.RefObject<Element>;
  portalHostname?: string | undefined;
  withAnchorWidth?: boolean;
}

export function useReportTemplateSelection(
  props: UseReportTemplateSelectionProps,
) {
  const {
    schemaId,
    allowPDF,
    callback,
    anchorRef,
    portalHostname,
    withAnchorWidth,
  } = props;
  const { t } = useTranslation();

  const [isReportTemplateOpen, openReportTemplate, closeReportTemplate] =
    useBooleanState();
  const [isFormatOpen, openFormat, closeFormat] = useBooleanState();

  const dispatch = useDispatch();

  const reportTemplates = useSelector(
    selectOrderedEnabledTemplatesBySchemaId(schemaId),
  );

  const [selectedReportTemplate, setSelectedReportTemplate] =
    useState<ReportTemplate | null>(null);

  const handleTemplateSelected = useCallback(
    (template: ReportTemplate) => {
      setSelectedReportTemplate(template);
      closeReportTemplate();
      if (allowPDF) {
        openFormat();
      } else {
        callback(template, template.format);
      }
    },
    [allowPDF, callback, closeReportTemplate, openFormat],
  );

  const handleReportTemplateOpen = useCallback(async () => {
    if (reportTemplates.length === 0) {
      dispatch(
        showError({
          title: "",
          message: t("reportTemplate.noTemplateAvailable"),
        }),
      );
    } else if (reportTemplates.length === 1 && reportTemplates[0]) {
      handleTemplateSelected(reportTemplates[0]);
    } else {
      openReportTemplate();
    }
  }, [
    reportTemplates,
    dispatch,
    t,
    handleTemplateSelected,
    openReportTemplate,
  ]);

  const reportTemplateOptions = useMemo(
    () =>
      reportTemplates.map<ActionSheetItem>((reportTemplate) => ({
        icon: getReportTemplateIcon(reportTemplate.format),
        label: getReportTemplateName(reportTemplate),
        onPress: () => {
          handleTemplateSelected(reportTemplate);
        },
      })),
    [reportTemplates, handleTemplateSelected],
  );

  const formatOptions = useMemo<ActionSheetItem[]>(
    () => [
      selectedReportTemplate?.format === "xlsx"
        ? {
            label: "EXCEL",
            icon: "excel-icon",
            onPress: () => {
              if (selectedReportTemplate) {
                void callback(selectedReportTemplate, "xlsx");
              }
            },
          }
        : {
            label: "WORD",
            icon: "word-icon",
            onPress: () => {
              if (selectedReportTemplate) {
                void callback(selectedReportTemplate, "docx");
              }
            },
          },

      {
        label: "PDF",
        icon: "pdf-icon",
        onPress: () => {
          if (selectedReportTemplate) {
            void callback(selectedReportTemplate, "pdf");
          }
        },
      },
    ],
    [selectedReportTemplate, callback],
  );

  const ReportTemplateSelection = useMemo(() => {
    return (
      <>
        <LegacyActionSheet
          options={reportTemplateOptions}
          title={t("reportTemplate.useTemplate")}
          open={isReportTemplateOpen}
          onClose={closeReportTemplate}
          portalHostname={portalHostname}
          anchorRef={anchorRef}
          withAnchorWidth={withAnchorWidth}
        />
        <LegacyActionSheet
          title={t("reportTemplate.useFormat")}
          open={isFormatOpen}
          onClose={closeFormat}
          portalHostname={portalHostname}
          anchorRef={anchorRef}
          options={formatOptions}
          withAnchorWidth={withAnchorWidth}
        />
      </>
    );
  }, [
    reportTemplateOptions,
    t,
    isReportTemplateOpen,
    closeReportTemplate,
    portalHostname,
    anchorRef,
    withAnchorWidth,
    isFormatOpen,
    closeFormat,
    formatOptions,
  ]);

  return {
    openReportTemplateSelection: handleReportTemplateOpen,
    ReportTemplateSelection,
  };
}
