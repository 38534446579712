import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  NativeSyntheticEvent,
  Pressable,
  SafeAreaView,
  TextInput,
  TextInputKeyPressEventData,
  View,
} from "react-native";
import {
  GooglePlaceData,
  GooglePlaceDetail,
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
} from "react-native-google-places-autocomplete";

import { isNative } from "@kraaft/helper-functions";
import {
  checkForCoordinates,
  decorateText,
} from "@kraaft/shared/components/placesAutocomplete/placesAutocompleteUtils";
import { PoweredByWithContainer } from "@kraaft/shared/components/placeSelection/poweredByWithContainer";
import {
  getAppEngineApiBaseUrl,
  getGoogleGeocodeApiKey,
} from "@kraaft/shared/constants/environment/environment.utils";
import { GeoLocation } from "@kraaft/shared/core/types";
import { dismissNativeKeyboard } from "@kraaft/shared/core/utils/platformUtils";
import {
  ColorStyle,
  DISABLE_AUTOCOMPLETE_PROPS,
  Icon,
  Spacing,
} from "@kraaft/ui";

import { SEARCH_BAR_HEIGHT, styles } from "./placeSearchBar.styles";

interface Props {
  extraPadding?: number;
  setLocation: (location: GeoLocation | undefined) => void;
  nativeID?: string;
}

const PlaceSearchBar = ({
  extraPadding = 0,
  setLocation,
  nativeID = "search-bar-location",
}: Props) => {
  const { t } = useTranslation();

  const ref = useRef<GooglePlacesAutocompleteRef>(null);
  const inputRef = useRef<TextInput>(null);

  const [placesAutocompleteText, setPlacesAutocompleteText] = useState("");

  const isListVisible = !!placesAutocompleteText;

  const pressableStyle = useMemo(() => {
    return [
      styles.PAOverlayContainer,
      { height: isListVisible ? undefined : SEARCH_BAR_HEIGHT },
    ];
  }, [isListVisible]);

  const googlePlacesAutocompleteQuery = useMemo(
    () => ({
      key: getGoogleGeocodeApiKey(),
      language: t("language"),
    }),
    [t],
  );

  const handlePlacesAutocompleteFail = useCallback(() => {
    console.log("GooglePlacesAutocomplete::onFail :");
  }, []);

  const handleRowPressed = useCallback(
    (data: GooglePlaceData, details: GooglePlaceDetail | null) => {
      if (details) {
        const { lat: latitude, lng: longitude } = details.geometry.location;

        const result: GeoLocation = {
          coords: {
            latitude,
            longitude,
          },
          address: {
            description: data.description,
            googlePlaceId: data.place_id,
          },
        };

        setLocation(result);
      }
      ref.current?.setAddressText("");
    },
    [setLocation],
  );

  const onBlurInput = useCallback(() => {
    const onBlur = () => {
      ref.current?.setAddressText("");
    };
    if (isNative()) {
      onBlur();
    } else {
      setTimeout(onBlur, 200); // wait for the onPress event to be triggered before hiding the list
    }
  }, []);

  const onSubmit = useCallback(async () => {
    const coordinates = checkForCoordinates(placesAutocompleteText);
    if (coordinates) {
      setLocation({ coords: coordinates });
    } else {
      const place = await ref.current?.selectRow(0);
      if (place) {
        handleRowPressed(place.data, place.details ?? null);
      }
    }
  }, [handleRowPressed, placesAutocompleteText, setLocation]);

  const onKeyPress = useCallback(
    async (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (e.nativeEvent.key === "Enter") {
        await onSubmit();
      } else if (e.nativeEvent.key === "Escape") {
        inputRef.current?.blur();
      }
    },
    [onSubmit],
  );

  function renderRow(
    { description, matched_substrings }: GooglePlaceData,
    index: number,
  ) {
    return decorateText(
      index,
      description,
      matched_substrings,
      styles.description,
      {
        fontFamily: "Apercu-Medium",
      },
    );
  }

  const poweredBy = useCallback(() => <PoweredByWithContainer />, []);

  return (
    <>
      <View style={styles.spaceSaver} />
      <Pressable
        accessibilityLabel=""
        onPress={dismissNativeKeyboard}
        style={pressableStyle}
      >
        <SafeAreaView style={styles.PAOverlaySafeContainer}>
          <GooglePlacesAutocomplete
            ref={ref}
            placeholder={t("searchAddress")}
            disableScroll
            keyboardShouldPersistTaps="always"
            fetchDetails
            isRowScrollable={isNative()}
            listViewDisplayed={true}
            onPress={handleRowPressed}
            onFail={handlePlacesAutocompleteFail}
            query={googlePlacesAutocompleteQuery}
            renderRow={renderRow}
            renderPoweredComponent={poweredBy}
            requestUrl={{
              useOnPlatform: "all",
              url: `${getAppEngineApiBaseUrl()}/maps-proxy`,
            }}
            textInputProps={{
              nativeID,
              InputComp: SearchInput,
              clearButtonMode: "never",
              placeholderTextColor: ColorStyle.FONT_LOW_EMPHASIS,
              value: placesAutocompleteText,
              onChangeText: setPlacesAutocompleteText,
              returnKeyType: "done",
              onBlur: onBlurInput,
              onKeyPress,
              onSubmitEditing: onSubmit,
              ref: inputRef,
              ...DISABLE_AUTOCOMPLETE_PROPS,
            }}
            styles={{
              textInputContainer: {
                ...styles.PATextInputContainer,
                ...(isListVisible ? styles.PATextInputContainerFocused : {}),
                paddingLeft: Spacing.S8 + extraPadding,
                paddingRight: extraPadding,
              },
              textInput: styles.PATextInput,
              separator: styles.PASeparator,
              row: styles.PARow,
            }}
          />
        </SafeAreaView>
      </Pressable>
    </>
  );
};

const SearchInput = forwardRef(
  (props: TextInput["props"], ref: ForwardedRef<TextInput>) => {
    const inputStyle = useMemo(() => {
      const hPadding = Spacing.S8;
      const iconSize = 24;

      return [
        props.style,
        {
          marginLeft: -iconSize - hPadding,
          paddingLeft: iconSize + 2 * hPadding,
        },
      ];
    }, [props.style]);

    return (
      <>
        <Icon
          name="search-md"
          color="FONT_LOW_EMPHASIS"
          size="MEDIUM"
          style={styles.searchIcon}
        />
        <TextInput {...props} style={inputStyle} ref={ref} />
      </>
    );
  },
);

export { PlaceSearchBar };
