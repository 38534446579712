import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getRoleTranslation } from "@kraaft/shared/core/modules/poolMember/poolMemberUtils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { useDefaultModularColumnContext } from "@kraaft/shared/core/modules/schema/modularTypes/useDefaultModularColumnContext";
import { KSchemaColors } from "@kraaft/shared/core/modules/schema/schema.colors";
import {
  selectSchemaLockLookup,
  selectSchemaOrderedColumns,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";

export function useImportUsersSchema() {
  const { t } = useTranslation();

  const schema = useMemo<KSchema>(
    () =>
      ({
        poolId: "",
        autoIncrement: 0,
        collection: "folder",
        color: "",
        description: "",
        icon: "bar-chart",
        id: "",
        index: 0,
        name: "",
        type: "custom",
        updatedAt: new Date(),
        highlightedCheckbox: undefined,
        defaultVisibility: "visible",
        rootSection: {
          color: KSchemaColors.DefaultSectionColor,
          elementType: "section",
          index: 0,
          key: KSchemaUtils.rootSectionKey,
          reportKey: KSchemaUtils.rootSectionKey,
          name: KSchemaUtils.rootSectionKey,
          elements: {
            firstName: {
              elementType: "column",
              index: 0,
              key: "firstName",
              reportKey: "firstName",
              name: t("inviteOrImportCollaborators.schema.firstName"),
              type: KColumnType.shortText,
            },
            lastName: {
              elementType: "column",
              index: 0,
              key: "lastName",
              reportKey: "lastName",
              name: t("inviteOrImportCollaborators.schema.lastName"),
              type: KColumnType.shortText,
            },
            email: {
              elementType: "column",
              index: 0,
              key: "email",
              reportKey: "email",
              name: t("inviteOrImportCollaborators.schema.email"),
              type: KColumnType.shortText,
            },
            phoneNumber: {
              elementType: "column",
              index: 0,
              key: "phoneNumber",
              reportKey: "phoneNumber",
              name: t("inviteOrImportCollaborators.schema.phoneNumber"),
              type: KColumnType.shortText,
            },
            role: {
              elementType: "column",
              index: 0,
              key: "role",
              reportKey: "role",
              name: t("inviteOrImportCollaborators.schema.role"),
              type: KColumnType.selectSingle,
              allowColor: false,
              preventEmpty: true,
              options: {
                owner: {
                  index: 0,
                  label: getRoleTranslation(t, UserPoolRole.OWNER),
                },
                admin: {
                  index: 1,
                  label: getRoleTranslation(t, UserPoolRole.ADMIN),
                },
                standard: {
                  index: 2,
                  label: getRoleTranslation(t, UserPoolRole.STANDARD),
                },
                external: {
                  index: 3,
                  label: getRoleTranslation(t, UserPoolRole.EXTERNAL),
                },
              },
            },
          },
        },
      }) as const,
    [t],
  );

  const schemaLockLookup = useSelector(selectSchemaLockLookup(schema.id));
  const orderedColumns = useSelector(selectSchemaOrderedColumns(schema.id));

  return {
    schema,
    columnsContext: useDefaultModularColumnContext(
      schemaLockLookup,
      orderedColumns,
    ),
  };
}
