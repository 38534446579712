import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { KRoomSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Button } from "@kraaft/ui";
import { ConversationFormatViewer } from "@kraaft/web/src/components/conversationViewer/conversationFormatViewer";
import { ExportPopup } from "@kraaft/web/src/components/exportPopup";
import { SaveSchemaViewButton } from "@kraaft/web/src/components/modularFoldersViewer/saveSchemaViewButton";
import { useSchemaView } from "@kraaft/web/src/components/modularFoldersViewer/useSchemaView";
import { useSchemaViewActionSheet } from "@kraaft/web/src/components/modularFoldersViewer/useSchemaViewActionSheet";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { VisualisationSelector } from "@kraaft/web/src/components/visualisationSelector/visualisationSelector";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";
import { queries } from "@kraaft/web/src/views/app/appRouter/routes";

import { useConversationTableRoomFilter } from "./useConversationTableRoomFilter";
import { useSubscribeToRoomsWithSchema } from "./useSubscribeToRoomsWithSchema";

import { useStyles } from "./conversationViewer.styles";

interface ConnectedConversationViewerProps {
  roomSchema: KRoomSchema;
  schemaViewId?: string;
}

const ConnectedConversationViewer = ({
  roomSchema,
  schemaViewId,
}: ConnectedConversationViewerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const query = useQueryString();
  const format = query.get(queries.format);
  const rooms = useSubscribeToRoomsWithSchema(roomSchema);
  const schemaView = useSchemaView(
    RecordWorkspace.ALL_ROOMS,
    roomSchema.id,
    schemaViewId,
  );

  const { filterButton, filteredRooms } = useConversationTableRoomFilter(
    roomSchema,
    rooms,
    schemaViewId,
  );

  const exportButtonRef = useRef<HTMLButtonElement | null>(null);

  const { schemaViewActionSheet, schemaViewActionsPopupElements } =
    useSchemaViewActionSheet(
      RecordWorkspace.ALL_ROOMS,
      roomSchema.id,
      schemaView,
    );

  const { open: openExportPopup, element: exportPopupElement } =
    ExportPopup.use({
      schema: roomSchema,
      filtersList: schemaView?.filters,
      numberOfRecords: filteredRooms.length,
    });

  const headerButtons = useMemo(
    () => (
      <>
        <SaveSchemaViewButton
          workspace={RecordWorkspace.ALL_ROOMS}
          schemaView={schemaView}
        />
        <Button
          id="database-export-button"
          accessibilityLabel={t("excelExport")}
          text={t("excelExport")}
          onPress={openExportPopup}
          ref={exportButtonRef}
          variant="PRIMARY"
        />
        {schemaViewActionSheet}
      </>
    ),
    [openExportPopup, schemaView, schemaViewActionSheet, t],
  );

  const pageName = schemaView.id ? schemaView.name : t("allTickets");
  const pageSpecification = schemaView.id ? t("room") : undefined;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.headerContainer}>
          <PageHeader
            title={pageName}
            titleSpecification={pageSpecification}
            right={headerButtons}
          />
          <div className={classes.databaseSubHeader}>
            <VisualisationSelector containerId="database-format" />
            <div className={classes.filterButton}>{filterButton}</div>
          </div>
        </div>

        <div className={classes.viewerContainer}>
          <ConversationFormatViewer
            format={format}
            schemaId={roomSchema.id}
            rooms={filteredRooms}
            schemaViewId={schemaView.id}
          />
        </div>
      </div>

      {exportPopupElement}
      {schemaViewActionsPopupElements}
    </>
  );
};

export { ConnectedConversationViewer };
