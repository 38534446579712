import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useQueryString() {
  const location = useLocation();
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  return query;
}
