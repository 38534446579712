import { makeStyles } from "@mui/styles";

import { Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles(() => ({
  carousel: {
    borderRadius: Radius.SMALL,
    overflow: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    flex: 1,
    display: "flex",
  },
  carouselHidden: {
    opacity: 0,
    pointerEvents: "none",
  },
  carouselContainer: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "35%",
    maxWidth: 450,
    margin: Spacing.S16,
  },
  carouselAspectRatio: {
    position: "relative",
    height: 0,
    paddingBottom: "66.66%", // 3:2 Aspect Ratio
  },
  carouselContent: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
}));
