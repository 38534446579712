import { createReducer } from "@reduxjs/toolkit";

import { DummyStateActions } from "@kraaft/shared/core/modules/dummy/dummy.bundle";

import { Dummy } from "./dummy";

export interface DummyState {
  dummies: Record<string, Dummy>;
}

const initialState: DummyState = {
  dummies: {},
};

export const dummyReducer = createReducer<DummyState>(
  initialState,
  ({ addCase }) => {
    addCase(DummyStateActions.set, (state, { payload }) => {
      state.dummies = payload.aggregates;
    });
  },
);
