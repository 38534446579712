import { useRef } from "react";

import {
  BaseDirectoryTreeProps,
  DirectoryTree,
} from "@kraaft/web/src/components/directoryTree/directoryTree";
import { DirectoryTreeStructure } from "@kraaft/web/src/components/directoryTree/directoryTree.types";
import { normalizeSerializedDirectories } from "@kraaft/web/src/components/directoryTree/directoryTree.utils";
import { SerializedDirectory } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminState";

interface Props extends BaseDirectoryTreeProps {
  serializedDirectories: SerializedDirectory[];
}

const DefaultDirectoryTree = (props: Props) => {
  const { serializedDirectories, onUpdate } = props;

  // We don't want to update the content on render
  const directoryTreeStructure = useRef<DirectoryTreeStructure>(
    normalizeSerializedDirectories(serializedDirectories),
  ).current;

  return (
    <DirectoryTree
      directoryTreeStructure={directoryTreeStructure}
      onUpdate={onUpdate}
    />
  );
};

export { DefaultDirectoryTree };
