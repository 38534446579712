import { createAction } from "@reduxjs/toolkit";

import {
  TemporaryWorkflow,
  Workflow,
} from "@kraaft/shared/core/modules/workflows/types";

export const WorkflowActions = {
  create: createAction<{ congrats: boolean }>("@workflow/save"),
  duplicate: createAction<{
    workflowId: string;
    loaderId: string;
  }>("@workflow/duplicate"),

  setEditing: createAction<{ workflowId: string }>("@workflow/setEditing"),
};

export const WorkflowStateActions = {
  set: createAction<{ data: Workflow[] }>("@workflow/state/set"),

  setCreating: createAction<{
    poolId?: string;
    baseProps: Partial<Workflow>;
  }>("@workflow/state/setCreating"),

  setEditingOrCreating: createAction<TemporaryWorkflow>(
    "@workflow/state/setEditingOrCreating",
  ),
  updateEditing: createAction<Partial<TemporaryWorkflow>>(
    "@workflow/state/updateEditing",
  ),
};
