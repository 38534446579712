import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  BrowserPath,
  ModernFile,
  RemotePath,
} from "@kraaft/shared/core/modules/file/file";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { ReportTemplateActions } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.actions";
import {
  ReportTemplateOptimisticActions,
  ReportTemplateOptimisticOperations,
} from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.optimistic";
import { selectTemplatesBySchemaIdOptimictic } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.selectors";
import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Api } from "@kraaft/shared/core/services/api/api";
import { AddReportTemplate } from "@kraaft/web/src/components/kDialog/customs/addReportTemplateDialog/addReportTemplateDialog";
import { OrderedListRows } from "@kraaft/web/src/components/orderableList/orderableList.types";
import { EditReportTemplates } from "@kraaft/web/src/components/schemaBuilder/tabs/editReportTemplates/editReportTemplates";

interface EditPoolSchemaReportTemplatesProps {
  poolSchema: KSchema;
}

const EditPoolSchemaReportTemplates = ({
  poolSchema,
}: EditPoolSchemaReportTemplatesProps) => {
  const dispatch = useDispatch();

  const templates = useSelector(
    selectTemplatesBySchemaIdOptimictic(poolSchema.id),
  );

  const handleAdd = useCallback(
    (params: Parameters<AddReportTemplate>[0]) => {
      dispatch(
        ReportTemplateActions.addReportTemplate({
          poolId: poolSchema.poolId,
          schemaId: poolSchema.id,
          file: params.file,
          name: params.name,
          forceAdd: params.forceAdd,
        }),
      );
    },
    [dispatch, poolSchema.id, poolSchema.poolId],
  );

  const handleReorder = useCallback(
    (ordered: OrderedListRows<ReportTemplate>) => {
      dispatch(
        ReportTemplateOptimisticActions.addOperation(
          ReportTemplateOptimisticOperations.reorder.create({
            editions: ordered,
            poolId: poolSchema.poolId,
            targetIds: Object.keys(ordered),
          }),
        ),
      );
    },
    [dispatch, poolSchema.poolId],
  );

  const handleToggle = useCallback(
    (reportTemplateId: string, enabled: boolean) => {
      dispatch(
        ReportTemplateOptimisticActions.addOperation(
          ReportTemplateOptimisticOperations.edit.create({
            targetId: reportTemplateId,
            editions: {
              enabled: enabled,
            },
          }),
        ),
      );
    },
    [dispatch],
  );

  const handleRename = useCallback(
    (reportTemplateId: string, name: string) => {
      dispatch(
        ReportTemplateOptimisticActions.addOperation(
          ReportTemplateOptimisticOperations.edit.create({
            targetId: reportTemplateId,
            editions: {
              name: name,
            },
          }),
        ),
      );
    },
    [dispatch],
  );

  const handleReplaceFile = useCallback(
    (reportTemplateId: string, file: ModernFile<BrowserPath>) => {
      dispatch(
        ReportTemplateActions.editReportTemplateFile({
          reportTemplateId,
          file,
          forceAdd: false,
        }),
      );
    },
    [dispatch],
  );

  const handleRemove = useCallback(
    (reportTemplateId: string) => {
      dispatch(
        ReportTemplateOptimisticActions.addOperation(
          ReportTemplateOptimisticOperations.delete.create({
            targetId: reportTemplateId,
          }),
        ),
      );
    },
    [dispatch],
  );

  const handleDownload = useCallback(async () => {
    let response: { downloadUrl: RemotePath; filename: string } | undefined;
    if (poolSchema.collection === "folder") {
      response = await Api.generateDefaultFolderTemplate({
        schemaId: poolSchema.id,
      });
    }
    if (poolSchema.collection === "room") {
      response = await Api.generateDefaultRoomTemplate({
        schemaId: poolSchema.id,
      });
    }

    if (response?.downloadUrl) {
      await fileSaver.download(response.downloadUrl, response.filename);
    }
  }, [poolSchema.collection, poolSchema.id]);

  return (
    <EditReportTemplates
      schema={poolSchema}
      onAdd={handleAdd}
      onReorder={handleReorder}
      reportTemplates={templates}
      onToggle={handleToggle}
      onRename={handleRename}
      onReplaceFile={handleReplaceFile}
      onRemove={handleRemove}
      onDownload={handleDownload}
    />
  );
};
export { EditPoolSchemaReportTemplates };
