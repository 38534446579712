import { useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";

import { CarouselIconButton, ColorStyle, FontSize, Spacing } from "@kraaft/ui";
import { stopPropagation } from "@kraaft/web/src/core/utils";

interface Props {
  setRotationValue(value: number): void;
}

export const RotateToolbar = ({ setRotationValue }: Props) => {
  const classes = useStyles();

  const [imageRotation, setImageRotation] = useState(0);

  const handleRotateClick = useCallback(() => {
    setImageRotation((oldRotation) => {
      const newValue = (oldRotation - 90) % 3600;

      setRotationValue?.(newValue);
      return newValue;
    });
  }, [setRotationValue]);

  return (
    <div className={classes.mediaToolbarContainer} onClick={stopPropagation}>
      <span className={classes.imageRotationContainer}>
        <CarouselIconButton
          onPress={handleRotateClick}
          icon="refresh-ccw-01"
          accessibilityLabel="rotate"
        />
        <span className={classes.imageRotation}>{`${
          -imageRotation % 360
        }°`}</span>
      </span>
    </div>
  );
};

export const useStyles = makeStyles({
  mediaToolbarContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  imageRotationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  imageRotation: {
    marginLeft: Spacing.S8,

    color: ColorStyle.FONT_LOW_EMPHASIS,
    fontSize: FontSize.SMALL,
    width: 20,
    textAlign: "center",
    userSelect: "none",
  },
});
