import moment from "moment";

import { useCallback, useRef } from "react";

import { isNative } from "@kraaft/helper-functions";
import { useBooleanState } from "@kraaft/helper-hooks";
import { ActionCard } from "@kraaft/shared/components/actionCard";
import { DateTimePicker } from "@kraaft/shared/components/dateTimePicker";
import { ColorStyle } from "@kraaft/ui";

interface Props {
  label: string;
  value: Date | undefined;
  onChange(value: Date | undefined): void;
}

const DateInput = ({ label, value, onChange }: Props) => {
  const dateRef = useRef(null);

  const [datepickerVisible, openDatePicker, closeDatePicker] =
    useBooleanState();

  const dateString = value ? moment(value).format("L") : "";

  const handleChange = useCallback(
    (date: Date | undefined) => {
      closeDatePicker();
      setTimeout(() => {
        onChange(date);
      }, 0); // prevent android datepicker reopen when validating date
    },
    [closeDatePicker, onChange],
  );

  return (
    <>
      <ActionCard
        text={label}
        iconName="calendar-date"
        description={dateString}
        descriptionColor={ColorStyle.FONT_LOW_EMPHASIS}
        action={openDatePicker}
        showBracket
        backgroundColor={ColorStyle.BACKGROUND_STANDARD}
      />
      {!isNative() && <div ref={dateRef} />}
      <DateTimePicker
        visible={datepickerVisible}
        date={value ?? new Date()}
        onClose={close}
        onChange={handleChange}
        anchorRef={dateRef}
      />
    </>
  );
};

export { DateInput };
