import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  containerReply: {
    alignItems: "flex-start",
  },
  containerDefault: {
    alignItems: "flex-end",
  },
  draggable: {
    display: "flex",
    justifyContent: "center",
  },
});
