import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Api } from "@kraaft/shared/core/services/api";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

export const deleteOperation = Operation.deleteOne<ModularFolder>().mutate(
  async (payload, task) => {
    await Api.deleteModularFolder({
      requestId: task.id,
      folderId: payload.id,
    });
  },
);
