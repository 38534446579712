import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  scrollableFlatList: {
    flexGrow: 1,
    flexBasis: 0,
  },
  content: {
    flexGrow: 1,
  },
  separator: {
    height: Spacing.S4,
    width: "100%",
  },
  emptyState: {
    paddingHorizontal: Spacing.S16,
    paddingVertical: Spacing.S8,
    alignItems: "center",
  },
});
