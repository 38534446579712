import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity } from "react-native";

import { Icon } from "@kraaft/ui";

import { styles } from "./retryButton.styles";

interface Props {
  handleRetry: () => void;
}

const RetryButton = (props: Props) => {
  const { handleRetry } = props;
  const { t } = useTranslation();

  const title = t("retrySending");

  return (
    <TouchableOpacity
      accessibilityLabel={title}
      onPress={handleRetry}
      style={styles.container}
    >
      <Text style={styles.retryText}>{title}</Text>
      <Icon size="SMALL" name="refresh-ccw-01" color="ACTION_DESCTRUCTIVE" />
    </TouchableOpacity>
  );
};

export { RetryButton };
