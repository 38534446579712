import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { compact } from "lodash";

import { IS_NOT_ARCHIVED_PREDICATE } from "@kraaft/shared/core/generated/__generated/builtinsConditions";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import {
  KSchemaColumn,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Button, Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { FilterItemProps } from "@kraaft/web/src/components/filterMenu/filterItem";
import { FilterMenuContent } from "@kraaft/web/src/components/filterMenu/filterMenuContent";
import { getTwoPartsCompositeCondition } from "@kraaft/web/src/components/filterMenu/filterUtils";
import {
  DEFAULT_FILTER,
  useFilterState,
} from "@kraaft/web/src/components/filterMenu/useFilterState";
import { Toggle } from "@kraaft/web/src/components/toggle";

import { useStyles } from "./filterMenu.styles";

interface FilterMenuProps {
  columns: { [key: string]: { path: KSchemaSection[]; column: KSchemaColumn } };
  filtersList: CompositeCondition | undefined;
  onApplyFilter: (filter: CompositeCondition) => void;
  disabledColumnTypes?: FilterItemProps["disabledColumnTypes"];
  showIncludeArchivedRoomsShortcut?: boolean;
}

const FilterMenu = (props: FilterMenuProps): JSX.Element => {
  const {
    columns,
    onApplyFilter,
    filtersList,
    disabledColumnTypes,
    showIncludeArchivedRoomsShortcut,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [filters, setFilters] = useFilterState(
    getTwoPartsCompositeCondition(filtersList ?? DEFAULT_FILTER)[0],
  );

  const [hideArchived, setHideArchived] = useState(() => {
    const [_, topLevelCondition] = getTwoPartsCompositeCondition(
      filtersList ?? DEFAULT_FILTER,
    );
    return !!topLevelCondition?.conditions.some(
      (e) =>
        e.type === "standalone-metadata" &&
        e.predicate === IS_NOT_ARCHIVED_PREDICATE,
    );
  });

  const internOnApplyFilter = useCallback(() => {
    onApplyFilter({
      type: "composite",
      operator: "and",
      conditions: compact([
        filters,
        hideArchived
          ? {
              type: "standalone-metadata",
              predicate: IS_NOT_ARCHIVED_PREDICATE,
            }
          : undefined,
      ]),
    });
  }, [filters, hideArchived, onApplyFilter]);

  return (
    <div className={classes.container}>
      <span className={classes.filterTitle}>{t("filterTitle")}</span>

      <FilterMenuContent
        columns={columns}
        filters={filters}
        setFilters={setFilters}
        disabledColumnTypes={disabledColumnTypes}
      />

      {showIncludeArchivedRoomsShortcut && (
        <Box row items="center" mt="S16">
          <Toggle value={hideArchived} setValue={setHideArchived} />
          <Text>{t("hideArchivedRooms")}</Text>
        </Box>
      )}

      <div className={classes.apply}>
        <Button
          onPress={internOnApplyFilter}
          accessibilityLabel={t("filter")}
          text={t("filter")}
          icon="check"
        />
      </div>
    </div>
  );
};

export { FilterMenu };
