import { useSelector } from "react-redux";

import { EntryLayout } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayout";
import { ModularFolderLocked } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.state";
import { selectUsername } from "@kraaft/shared/core/modules/user/userSelectors";

interface LockedEntryProps {
  entry: ModularFolderLocked;
}

export const LockedEntry = ({ entry }: LockedEntryProps) => {
  const initiatorName = useSelector(selectUsername(entry.by));

  return (
    <EntryLayout
      date={entry.at}
      iconName="lock-01"
      iconColor="YELLOW_EARTH"
      i18nKey="history.entryLabel.locked"
      i18nValues={{ initiatorName }}
    />
  );
};
