import { createReducer } from "@reduxjs/toolkit";
import { createMigrate, persistReducer } from "redux-persist";
import {
  MigrationManifest,
  PersistConfig,
  PersistedState,
} from "redux-persist/es/types";

import { storage } from "@kraaft/shared/core/modules/storage/storage.provider";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { updateSchemaViewStore } from "@kraaft/web/src/core/modules/memory/memoryActions";
import { MemoryState } from "@kraaft/web/src/core/modules/memory/memoryState";

const initialState: MemoryState = {
  temporarySchemaViews: {},
};

const memoryReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => {
    return initialState;
  });

  addCase(updateSchemaViewStore, (state, { payload }) => {
    state.temporarySchemaViews = payload.temporarySchemaViews;
  });
});

export const memoryVersion = 2;

export const memoryMigrations: MigrationManifest = {
  1: () => initialState as unknown as PersistedState, // https://github.com/rt2zz/redux-persist/pull/1170
  2: () => initialState as unknown as PersistedState,
};

const memoryPersistConfig: PersistConfig<MemoryState> = {
  key: "memory",
  storage,
  version: memoryVersion,
  migrate: createMigrate(memoryMigrations),
};
const persistedReducer = persistReducer(memoryPersistConfig, memoryReducers);

export { persistedReducer as memoryReducers };
