import React, { useEffect } from "react";
import { ScrollView } from "react-native";
import { useDispatch } from "react-redux";

import { RoomReportSection } from "@kraaft/shared/components/conversationDetails/roomReportSection";
import { WorksiteFolderContent } from "@kraaft/shared/components/conversationDetails/worksiteFolder/worksiteFolderContent";
import { DirectoryActions } from "@kraaft/shared/core/modules/directory/directoryActions";
import { MiniMediaActions } from "@kraaft/shared/core/modules/miniMedia/miniMedia.actions";
import { useSubscribeToModularFolders } from "@kraaft/shared/core/modules/modularFolder/useSubscribeToModularFolders";
import { useIsRoomFreemium } from "@kraaft/shared/core/modules/room/useIsRoomFreemium";
import { ConversationDetailsPanel } from "@kraaft/shared/core/services/navigation/navigationParams";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";

import { styles } from "./worksiteFolder.styles";

interface WorksiteFolderProps {
  roomId: string;
  portalHostname?: string;
  openedPanel: ConversationDetailsPanel | undefined;
  setOpenedPanel: (
    panelIdentifier: ConversationDetailsPanel | undefined,
  ) => void;
}

const WorksiteFolder_ = (props: WorksiteFolderProps) => {
  const { roomId, portalHostname, openedPanel, setOpenedPanel } = props;
  const dispatch = useDispatch();

  const limitForFreemium = useIsRoomFreemium(roomId);

  useTrackPage("WorksiteFolder", { identifier: roomId });
  useSubscribeToModularFolders(roomId);

  useEffect(() => {
    dispatch(DirectoryActions.subscribe({ roomId }));
    dispatch(
      MiniMediaActions.subscribeToMiniMedias({ roomId, limitForFreemium }),
    );

    return () => {
      dispatch(DirectoryActions.unsubscribe({ roomId }));
      dispatch(
        MiniMediaActions.unsubscribeFromMiniMedias({
          roomId,
          limitForFreemium,
        }),
      );
    };
  }, [dispatch, limitForFreemium, roomId]);

  return (
    <>
      <ScrollView
        style={styles.container}
        scrollIndicatorInsets={{ right: -0.1 }}
        contentContainerStyle={styles.contentContainer}
        contentInsetAdjustmentBehavior="automatic"
        keyboardShouldPersistTaps="handled" // needed for AddDirectoryDialog on native
      >
        <WorksiteFolderContent roomId={roomId} />
      </ScrollView>
      <RoomReportSection
        roomId={roomId}
        openedPanel={openedPanel}
        setOpenedPanel={setOpenedPanel}
        portalHostname={portalHostname}
      />
    </>
  );
};

export const WorksiteFolder = React.memo(WorksiteFolder_);
