import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList/kSelectionList";
import { poolAcquisitionSources } from "@kraaft/shared/components/onboarding/askPoolAcquisitionSource/poolAcquisitionSource";
import { usePoolAcquisitionSource } from "@kraaft/shared/components/onboarding/askPoolAcquisitionSource/usePoolAcquisitionSource";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackEventOnce } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button, Text } from "@kraaft/ui";

import { styles } from "./askPoolAcquisitionSource.styles";

const OTHER_IDENTIFIER = "other";

const AskPoolAcquisitionSource = () => {
  const { t } = useTranslation();

  const { setPoolAcquisitionSource, isLoading } = usePoolAcquisitionSource();

  useTrackEventOnce({
    eventName: "Onboarding Need To Select Acquisition Source",
  });

  const [selected, setSelected] = useState<string>("");
  const [other, setOther] = useState("");

  const handleSelect = useCallback((newSelection: string[]) => {
    const [newSelected] = newSelection;

    if (!newSelected) {
      return;
    }

    setSelected(newSelected);
  }, []);

  const items = useMemo(
    () => [
      ...poolAcquisitionSources.map((acquisitionSource) => ({
        label: t(`poolAcquisitionSource.choices.${acquisitionSource}`),
        identifier: acquisitionSource,
      })),
      {
        label: t("poolAcquisitionSource.choices.other"),
        identifier: OTHER_IDENTIFIER,
      },
    ],
    [t],
  );

  const next = useCallback(() => {
    const acquisitionSource = selected === OTHER_IDENTIFIER ? other : selected;

    setPoolAcquisitionSource(acquisitionSource).catch(console.error);

    trackEvent({
      eventName: "Onboarding Selected Acquisition Source",
      acquisitionSource,
    });
  }, [other, selected, setPoolAcquisitionSource]);

  return (
    <View style={styles.root}>
      <Text weight="bold" size="H1" style={styles.title}>
        {t("poolAcquisitionSource.howDidYouKnowUs")}
      </Text>

      <ScrollView style={styles.scrollView}>
        <KSelectionList
          selected={[selected]}
          setSelected={handleSelect}
          items={items}
          noPadding
          disableScroll
        />
      </ScrollView>
      {selected === OTHER_IDENTIFIER && (
        <KInput
          label={t("poolAcquisitionSource.fillYourAcquisitionSource")}
          value={other}
          onChangeText={setOther}
        />
      )}
      <View style={styles.spacer} />
      <Button
        loading={isLoading}
        disabled={!selected}
        style={styles.nextButton}
        accessibilityLabel={t("next")}
        text={t("next")}
        onPress={next}
      />
    </View>
  );
};

export { AskPoolAcquisitionSource };
