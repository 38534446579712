import { useEffect } from "react";
import { View } from "react-native";
import { useDispatch } from "react-redux";

import { CloseMapButton } from "@kraaft/shared/components/mapController/closeMapButton";
import {
  geolocationContextHover,
  geolocationContextSelect,
} from "@kraaft/shared/core/modules/room/roomActions";
import { Color, PortalHost, Preloader } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";

import { MapControllerLayoutProps } from "./mapControllerLayout.props";

import { styles } from "./mapController.style";

export const MapControllerLayout = ({
  children,
  withCloseButton,
  topLeftButton,
  rightPanel,
  isLoading,
  mapControllerPortalHostname,
}: MapControllerLayoutProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      geolocationContextHover({
        hovered: false,
      }),
    );
    dispatch(
      geolocationContextSelect({
        selected: false,
      }),
    );
  }, [dispatch]);

  return (
    <View style={styles.mapControllerWeb}>
      <View style={styles.mapContainer}>
        {children}
        <View pointerEvents="box-none" style={styles.overlayContainer}>
          <View pointerEvents="box-none" style={styles.headerContainer}>
            <View pointerEvents="box-none">{topLeftButton}</View>
            {isLoading && (
              <Preloader
                color={Color.WHITE}
                style={styles.loader}
                size="small"
              />
            )}
            <Box items="flex-end">
              {withCloseButton && (
                <Box mb="S8">
                  <CloseMapButton />
                </Box>
              )}
              {mapControllerPortalHostname ? (
                <PortalHost hostname={mapControllerPortalHostname} />
              ) : null}
            </Box>
          </View>
        </View>
      </View>
      {rightPanel && (
        <View style={styles.mapDetailsContainer}>{rightPanel}</View>
      )}
    </View>
  );
};
