import { Icon } from "@kraaft/ui";

import { useStyles } from "./videoPlayer.styles";

const PlayButton = () => {
  const classes = useStyles();

  return (
    <div className={classes.button}>
      <Icon name="play-fill" size="MEDIUM" color="FONT_LOW_EMPHASIS" />
    </div>
  );
};

export { PlayButton };
