import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { KPopupProps } from "@kraaft/web/src/components/kPopup/kPopup.types";
import {
  CROSS_SIZE,
  useStyles,
} from "@kraaft/web/src/components/kPopup/kPopupFullscreen.styles";

const KPopupFullscreen = ({ maxWidth, onClose, children }: KPopupProps) => {
  const { t } = useTranslation();

  const classes = useStyles({ maxWidth });

  const headerIconButtonStyles = useMemo<React.CSSProperties>(
    () => ({
      position: "absolute",
      top: 24 - CROSS_SIZE / 2,
      right: CROSS_SIZE / 2,
      zIndex: 1,
    }),
    [],
  );

  return (
    <div className={classes.container}>
      <HeaderIconButton
        style={headerIconButtonStyles}
        accessibilityLabel={t("close")}
        icon="x-close"
        onPress={onClose}
        size="SMALL"
      />
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export { KPopupFullscreen };
