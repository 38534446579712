import React from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet } from "react-native";

import { Skeleton } from "@kraaft/shared/components/skeleton";
import {
  Color,
  ColorStyle,
  FontSize,
  Icon,
  IconName,
  Spacing,
  Text,
} from "@kraaft/ui";

type UserInfoRowProps = {
  onPress: () => void;
  iconName: IconName;
  value: string | undefined;
  renderCopyButton: React.ReactNode;
  isLoading?: boolean;
};
export const UserInfoRow = ({
  renderCopyButton,
  value,
  onPress,
  iconName,
  isLoading,
}: UserInfoRowProps) => {
  const color = value ? Color.BLACK : ColorStyle.FONT_LOW_EMPHASIS;
  const { t } = useTranslation();
  return (
    <Pressable
      style={styles.infoItemRow}
      onPress={onPress}
      disabled={!value}
      accessibilityLabel={value}
    >
      <Icon size="MEDIUM" name={iconName} color={color} />

      {isLoading ? (
        <Skeleton height={FontSize.BODY} width="80%" isText />
      ) : (
        <Text
          style={styles.infoItemText}
          color={color}
          size="BODY"
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {value || t("userContactInfo.emptyInformation")}
        </Text>
      )}

      {!!value && renderCopyButton}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  infoItemRow: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: Spacing.S8,
    flexGrow: 1,
  },
  infoItemText: {
    flexGrow: 1,
    flexShrink: 1,
  },
});
