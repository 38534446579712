import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useMeshContext } from "@kraaft/helper-hooks";
import { RoomFiltersContext } from "@kraaft/shared/components/filters/roomFilters.context";
import { RoomFiltersScrollableList } from "@kraaft/shared/components/filters/roomFiltersScrollableList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { selectRoomSchemaStatusColumn } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { getEmptyArray } from "@kraaft/shared/core/utils";

interface RoomFiltersStatusProps {
  onClose: () => void;
  onBack: () => void;
}

export const RoomFiltersStatus = ({
  onClose,
  onBack,
}: RoomFiltersStatusProps) => {
  const { t } = useTranslation();

  const { poolId, statusId, setStatusId, resetStatusId, isDefaultStatusId } =
    useMeshContext(RoomFiltersContext);
  const statusColumn = useSelector(selectRoomSchemaStatusColumn(poolId));

  const handleSelect = useCallback(
    ([newStatus]: string[]) => {
      if (newStatus !== undefined) {
        setStatusId(newStatus);
      }
    },
    [setStatusId],
  );

  const items = useMemo<KSelectableListItem[]>(() => {
    if (statusColumn === undefined) {
      return getEmptyArray<KSelectableListItem>();
    }

    return KSchemaUtils.orderOptions(statusColumn).map(([labelId, label]) => ({
      label: label.label,
      identifier: labelId,
    }));
  }, [statusColumn]);

  const selected = useMemo(() => {
    if (statusId !== undefined) {
      return [statusId];
    }
    return getEmptyArray<string>();
  }, [statusId]);

  return (
    <RoomFiltersScrollableList
      headerTitle={t("roomFilters.status")}
      items={items}
      selected={selected}
      setSelected={handleSelect}
      isMultiple={false}
      onFiltered={onClose}
      onErase={resetStatusId}
      canErase={!isDefaultStatusId}
      onBack={onBack}
    />
  );
};
