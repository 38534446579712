import { useEffect } from "react";
import { ConnectDropTarget, useDrop } from "react-dnd";

import { useMeshContext } from "@kraaft/helper-hooks";
import {
  DraggableDirectoryTreeItem,
  FOLDER_ITEM_TYPE,
  HoveredDirectoryTreeInfo,
} from "@kraaft/web/src/components/directoryTree/directoryTree.types";
import { DirectoryTreeContext } from "@kraaft/web/src/components/directoryTree/directoryTreeContext";

interface UseDirectoryDropProps {
  info: HoveredDirectoryTreeInfo;
  checkItemCanDrop: (item: DraggableDirectoryTreeItem) => boolean;
}

const useDirectoryTreeRowDrop = (
  props: UseDirectoryDropProps,
): [{ isOver: boolean; canDrop: boolean }, ConnectDropTarget] => {
  const { info, checkItemCanDrop } = props;
  const { moveDirectory, setHoveredDirectoryTreeInfo } =
    useMeshContext(DirectoryTreeContext);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: FOLDER_ITEM_TYPE,
    drop: (item: DraggableDirectoryTreeItem) => {
      moveDirectory(item.directory, info);
    },
    canDrop: checkItemCanDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    const triggerHovered = canDrop && isOver;

    if (triggerHovered) {
      setHoveredDirectoryTreeInfo(info);
    } else if (isOver && !canDrop) {
      setHoveredDirectoryTreeInfo(undefined);
    }
  }, [canDrop, isOver, setHoveredDirectoryTreeInfo, info]);

  return [{ isOver, canDrop }, drop];
};

export { useDirectoryTreeRowDrop };
