import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { noop } from "ts-essentials";

import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { ModularDetailsAttachmentContext } from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/attachmentsEditor.props";
import { ModularDetailsEditorContext } from "@kraaft/shared/components/modular/details/editors/types";
import { useModularDetails } from "@kraaft/shared/components/modular/details/useModularDetails";
import { ModularTableValueUpdate } from "@kraaft/shared/components/modular/details/utils";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchema,
  KSchemaColumn,
  KSchemaColumnLiteralValue,
  KSchemaColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { useDefaultModularColumnContext } from "@kraaft/shared/core/modules/schema/modularTypes/useDefaultModularColumnContext";
import {
  selectSchemaLockLookup,
  selectSchemaOrderedColumns,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { PortalHost } from "@kraaft/ui";
import { modernFileToAttachment } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/formPreview/formPreview.utils";

import { useStyles } from "./formPreview.styles";

const MOBILE_PORTAL_HOSTNAME = "MOBILE_PORTAL";

interface FormPreviewProps {
  currentUserId: string;
  schema: KSchema;
}

export const FormPreview = ({ currentUserId, schema }: FormPreviewProps) => {
  const styles = useStyles();

  const [record, setRecord] = useState<ModularRecord>(() => {
    const _record: ModularRecord = {
      id: "",
      schemaId: schema.id,
      properties: {},
    };
    ModularRecordUtils.fillAutomatedValues(
      schema,
      _record.properties,
      currentUserId,
    );
    return _record;
  });

  useEffect(() => {
    setRecord((oldRecord) => {
      ModularRecordUtils.fillAutomatedValues(
        schema,
        oldRecord.properties,
        currentUserId,
      );
      return { ...oldRecord };
    });
  }, [currentUserId, schema]);

  const onUpdate = useCallback(
    (update: ModularTableValueUpdate) => {
      setRecord((oldRecord) => {
        oldRecord.properties = {
          ...oldRecord.properties,
          [update.column.key]: {
            columnType: update.column.type,
            value: update.value,
            updatedBy: currentUserId,
            updatedAt: new Date(),
          } as KSchemaColumnValue,
        };
        return { ...oldRecord };
      });
    },
    [currentUserId],
  );

  const onAddFile = useCallback(
    (
      column: KSchemaColumn<KColumnType.attachment> | undefined,
      files: ModernFile<LocalPath>[],
    ) => {
      if (!column) {
        return;
      }

      function addFileToColumn(
        columnValue: NonNullable<
          KSchemaColumnLiteralValue<KColumnType.attachment>
        >,
        file: ModernFile<LocalPath>,
      ) {
        columnValue.push(modernFileToAttachment(file, currentUserId));
      }

      setRecord((oldRecord) => {
        const existingColumn = [
          ...((oldRecord.properties[
            column.key
          ] as KSchemaColumnLiteralValue<KColumnType.attachment>) ?? []),
        ];
        for (const file of files) {
          addFileToColumn(existingColumn, file);
        }
        oldRecord.properties[column.key] = {
          columnType: KColumnType.attachment,
          value: existingColumn,
        };
        return { ...oldRecord };
      });
    },
    [currentUserId],
  );

  const onDeleteAttachment = useCallback(
    (
      column: KSchemaColumn<KColumnType.attachment> | undefined,
      attachment: Attachment,
    ) => {
      if (!column) {
        return;
      }
      setRecord((oldRecord) => {
        const property = oldRecord.properties[column.key] as
          | KSchemaColumnLiteralValue<KColumnType.attachment>
          | undefined;
        if (!property) {
          return oldRecord;
        }
        oldRecord.properties[column.key] = {
          columnType: KColumnType.attachment,
          value: [...property.filter((att) => att.id !== attachment.id)],
        };
        return { ...oldRecord };
      });
    },
    [],
  );

  const noopnoop = useCallback(() => noop, []);

  const attachmentContext = useMemo<ModularDetailsAttachmentContext>(
    () => ({
      folderTitle: "",
      isLoadingColumn: () => false,
      onDelete: onDeleteAttachment,
      onDrop: noopnoop,
      onDropFiles: (column) => (files) => onAddFile(column, files),
      onPickDocuments: noopnoop,
      onPickImages: noopnoop,
      openCamera: noopnoop,
      openCarousel: noop,
      roomTitle: "room",
      openImportDocumentFromConversationModal: undefined,
      openImportImagesFromConversationModal: undefined,
    }),
    [noopnoop, onAddFile, onDeleteAttachment],
  );

  const schemaLockLookup = useSelector(selectSchemaLockLookup(schema.id));
  const orderedColumns = useSelector(selectSchemaOrderedColumns(schema.id));

  const defaultColumnsContext = useDefaultModularColumnContext(
    schemaLockLookup,
    orderedColumns,
  );

  const editorContext = useMemo<ModularDetailsEditorContext>(
    () => ({
      portalHostname: MOBILE_PORTAL_HOSTNAME,
    }),
    [],
  );

  const { ModularDetailsContextProvider, modularDetailsElements, topElement } =
    useModularDetails({
      id: record.id,
      recordProperties: record.properties,
      rootSection: schema.rootSection,
      updateRecord: onUpdate,
      updateRecordSignature: noop,
      editorContext,
      columnsContext: defaultColumnsContext,
      attachmentContext,
      offsetIndices: 1,
    });

  const scrollView = useMemo(
    () => (
      <div className={styles.content}>
        {topElement}
        {modularDetailsElements}
      </div>
    ),
    [modularDetailsElements, styles.content, topElement],
  );

  return (
    <div>
      <div className={styles.phone}>
        <img src="/phone.png" className={styles.phoneOverlay} alt="phone" />
        <div className={styles.phoneContent}>
          <div className={styles.relativeContent}>
            <DefaultLayoutHeader headerTitle={schema.name} />
            <ModularDetailsContextProvider>
              {scrollView}
            </ModularDetailsContextProvider>
          </div>
          <div className={styles.absoluteContent}>
            <PortalHost hostname={MOBILE_PORTAL_HOSTNAME} />
          </div>
        </div>
      </div>
    </div>
  );
};
