import { useCallback } from "react";

import { CreateWorkspace } from "@kraaft/shared/components/createWorkspace";
import { KraaftLogo } from "@kraaft/shared/components/kraaftLogo";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { Box } from "@kraaft/web/src/components/box";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";
import { SplitScreenBackButton } from "@kraaft/web/src/components/splitScreenWrapper/splitScreenBackButton";

const CreateWorkspaceWeb = () => {
  const navigationService = useNavigationService();

  const handleBackPress = useCallback(() => {
    navigationService.goBack();
  }, [navigationService]);

  return (
    <SplitScreenWrapper headerLeft={<KraaftLogo />}>
      <Box mb="S16">
        <SplitScreenBackButton onClick={handleBackPress} />
      </Box>
      <CreateWorkspace />
    </SplitScreenWrapper>
  );
};

export { CreateWorkspaceWeb };
