import { makeStyles } from "@mui/styles";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  header: {
    padding: `${Spacing.S24}px ${Spacing.S32}px`,
    paddingBottom: Spacing.NONE,
    borderBottom: `solid 1px ${ColorStyle.SEPARATOR}`,
  },
  textContainer: {
    marginBottom: Spacing.S16,
  },
  tabs: {
    display: "flex",
    marginTop: Spacing.S4,
    marginBottom: Spacing.S8,
  },
});
