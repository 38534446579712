import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import {
  InfoPanel,
  InfoPanelImageType,
} from "@kraaft/shared/components/infoPanel";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  subscribeToPoolRooms,
  unsubscribeFromPoolRooms,
} from "@kraaft/shared/core/modules/room/roomActions";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { ConversationViewer } from "@kraaft/web/src/components/conversationViewer";
import { ModularFoldersViewer } from "@kraaft/web/src/components/modularFoldersViewer";
import { SchemaViewViewer } from "@kraaft/web/src/components/modularFoldersViewer/schemaViewViewer";
import { Sidebar } from "@kraaft/web/src/components/sidebar";
import { routes } from "@kraaft/web/src/views/app/appRouter/routes";
import { ExpandableGlobalViews } from "@kraaft/web/src/views/database/expandableGlobalViews";
import { ExpandableRoomViews } from "@kraaft/web/src/views/database/expandableRoomViews";
import { ExpandableSchemaViews } from "@kraaft/web/src/views/database/expandableSchemaViews";
import { useDatabaseExpandableState } from "@kraaft/web/src/views/database/useDatabaseExpandableState";
import { useSaveAndReturnPreviousLocation } from "@kraaft/web/src/views/database/useSaveAndReturnPreviousLocation";
import { ModularFolderStateActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";

import { useStyles } from "./database.styles";

const Database = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const poolId = useSelector(selectCurrentPoolId);
  const classes = useStyles();

  const previousSavedPath = useSaveAndReturnPreviousLocation();

  useEffect(() => {
    if (poolId) {
      dispatch(
        subscribeToPoolRooms({
          poolId,
        }),
      );
      return () => {
        dispatch(
          unsubscribeFromPoolRooms({
            poolId,
          }),
        );
      };
    }
  }, [dispatch, poolId]);

  useEffect(() => {
    return () => {
      dispatch(ModularFolderStateActions.cleanModularFolderList());
    };
  }, [dispatch]);

  useTrackPage("Databases");

  const {
    schemaViewsExpanded,
    roomViewsExpanded,
    globalViewsExpanded,
    handleExpand,
  } = useDatabaseExpandableState();

  return (
    <div className={classes.page}>
      <Sidebar>
        <DefaultLayoutHeader
          headerTitle={t("database")}
          id="database-views-title"
        />
        <div className={classes.sidebarContent}>
          <div className={classes.expandable}>
            <ExpandableSchemaViews
              poolId={poolId}
              expanded={schemaViewsExpanded}
              setExpanded={handleExpand("schemaView")}
            />
          </div>
          <div className={classes.expandable}>
            <ExpandableRoomViews
              poolId={poolId}
              expanded={roomViewsExpanded}
              setExpanded={handleExpand("room")}
            />
          </div>
          <div className={classes.expandable}>
            <ExpandableGlobalViews
              poolId={poolId}
              expanded={globalViewsExpanded}
              setExpanded={handleExpand("global")}
            />
          </div>
        </div>
      </Sidebar>

      <div className={classes.main}>
        <Switch>
          <Route path={routes.Database} exact={true}>
            <InfoPanel
              title={t("databaseDefaultTitle")}
              message={t("databaseDefaultContent")}
              imageType={InfoPanelImageType.DATABASE}
            />
            <Redirect to={previousSavedPath ?? routes.ConversationTable} />
          </Route>

          <Route path={routes.ConversationTable}>
            <ConversationViewer />
          </Route>

          <Route path={routes.ModularFoldersTableForRoom}>
            <ModularFoldersViewer />
          </Route>

          <Route path={routes.ModularFoldersTable}>
            <ModularFoldersViewer />
          </Route>

          <Route path={routes.SchemaView}>
            <SchemaViewViewer />
          </Route>

          <Redirect to={routes.Database} />
        </Switch>
      </div>
    </div>
  );
};

export { Database };
