import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View, ViewStyle } from "react-native";

import { constants } from "@kraaft/shared/constants/constants";
import { intercom } from "@kraaft/shared/core/modules/intercom/intercom.provider";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Button, Text } from "@kraaft/ui";

import { styles } from "./messageLimitReached.styles";

type Location = "conversation" | "photo_gallery" | "documents";

const getStyleForLocation = (location: Location): ViewStyle => {
  switch (location) {
    case "conversation":
      return styles.containerForConversation;
    case "photo_gallery":
      return styles.containerForPhotoGallery;
    case "documents":
      return styles.containerForDocuments;
  }
};

interface Props {
  from: Location;
}

const MessageLimitReached = (props: Props) => {
  const { from } = props;
  const { t } = useTranslation();

  const handleContactUsPress = useCallback(() => {
    trackEvent({
      eventName: "Click On Freemium Contact Us",
      from,
    });
    intercom.openChatWithMessage(
      t("freemium.messageLimitReached.contactUsInitialMessage"),
    );
  }, [from, t]);

  const containerStyle = useMemo(
    () => [styles.container, getStyleForLocation(from)],
    [from],
  );

  return (
    <View style={containerStyle}>
      <Text weight="medium" style={styles.title}>
        {t(`freemium.messageLimitReached.${from}.title`, {
          numberOfDays: constants.FREEMIUM_CONTENT_LIMIT_DAYS,
        })}
      </Text>
      <Text style={styles.subtitle}>
        {t(`freemium.messageLimitReached.${from}.subtitle`)}
      </Text>
      <Button
        accessibilityLabel={t("freemium.messageLimitReached.contactUs")}
        text={t("freemium.messageLimitReached.contactUs")}
        onPress={handleContactUsPress}
      />
    </View>
  );
};

export { MessageLimitReached };
