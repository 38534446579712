import { useCallback } from "react";

import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";

import { SchemaLibraryCard } from "./schemaLibraryCard";

interface SchemaLibraryThumbnailProps {
  librarySchema: LibrarySchema;
  onSelectSchema: (librarySchemaId: string) => void;
}

const SchemaLibraryThumbnail = ({
  librarySchema,
  onSelectSchema,
}: SchemaLibraryThumbnailProps) => {
  const handlePress = useCallback(() => {
    onSelectSchema(librarySchema.id);
  }, [librarySchema.id, onSelectSchema]);

  return (
    <SchemaLibraryCard
      onPress={handlePress}
      title={librarySchema.schema.name}
      coverPictureUrl={librarySchema.presentation.coverPictureUrl ?? undefined}
      icon={KSchemaUtils.getSchemaIconName(librarySchema.schema.icon)}
      stealthy={!librarySchema.enabled}
    />
  );
};

export { SchemaLibraryThumbnail };
