import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { DistributiveStrictOmit } from "@kraaft/shared/core/typeUtils";
import {
  DateTimePicker as UIDateTimePicker,
  DateTimePickerProps,
} from "@kraaft/ui";

export const DateTimePicker = (
  props: DistributiveStrictOmit<DateTimePickerProps, "translate" | "locale">,
) => {
  const { t, i18n } = useTranslation();

  const translate = useCallback<NonNullable<DateTimePickerProps["translate"]>>(
    (key) => t(`dateTimePicker.${key}`),
    [t],
  );

  return (
    <UIDateTimePicker {...props} translate={translate} locale={i18n.language} />
  );
};
