import { useCallback, useRef, useState } from "react";

import {
  FillDirectoryPickerHandle,
  FillFirectoryFlowProps,
} from "@kraaft/shared/components/directory/fillDirectoryFlowProps";
import { FillDirectoryDocumentGalleryPicker } from "@kraaft/shared/components/directory/fillDirectoryFromDocumentGalleryFlow/fillDirectoryDocumentGalleryPicker";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";

export const FillDirectoryFromDocumentGalleryFlow = (
  props: FillFirectoryFlowProps,
) => {
  const { directory, open, onClose } = props;

  const [title, setTitle] = useState("");
  const pickerRef = useRef<FillDirectoryPickerHandle>(null);

  const handleClose = useCallback(() => {
    pickerRef.current?.clearSelection();
    onClose();
  }, [onClose]);

  return (
    <KDialog
      fullHeight
      size={KDialogSize.MEDIUM}
      title={title}
      open={open}
      onClose={handleClose}
      content={
        <FillDirectoryDocumentGalleryPicker
          ref={pickerRef}
          directory={directory}
          onClose={onClose}
          onTitleChanged={setTitle}
        />
      }
    />
  );
};
