import { formatDateCell } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularDisplayRequirements } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { formatAddress } from "@kraaft/shared/core/utils";
import { formatNumber } from "@kraaft/shared/core/utils/useNumberInput";
import { Cell } from "@kraaft/web/src/components/modularTable/components/types";

export const simpleClipboardTextFromCell = (
  cell:
    | Cell<KColumnType.shortText>
    | Cell<KColumnType.automatedAutoIncrement>
    | Cell<KColumnType.longText>
    | Cell<KColumnType.roomName>,
) => {
  const { value } = cell;
  if (value) {
    return value;
  }
  return "";
};

export const numberClipboardTextFromCell = (cell: Cell<KColumnType.number>) => {
  const { value } = cell;
  if (value) {
    return String(value);
  }
  return "";
};

export const currencyClipboardTextFromCell = (
  cell: Cell<KColumnType.currency>,
  displayContext: ModularDisplayRequirements,
) => {
  const { value } = cell;
  if (value) {
    return formatNumber(value, displayContext.currencyCode, true);
  }
  return "";
};

export const dateClipboardTextFromCell = (
  cell: Cell<KColumnType.date> | Cell<KColumnType.automatedCreatedAt>,
) => {
  const { value } = cell;
  if (value) {
    return formatDateCell(value, cell.column.displayTime) ?? "";
  }
  return "";
};

export const geolocationClipboardTextFromCell = (
  cell: Cell<KColumnType.geolocation>,
) => {
  const { value } = cell;
  if (value) {
    return formatAddress(value, false);
  }
  return "";
};

export const selectClipboardTextFromCell = (
  cell: Cell<KColumnType.selectSingle> | Cell<KColumnType.selectMultiple>,
) => {
  const { value, column } = cell;
  if (value) {
    return value.map((item) => column.options[item]?.label).join(", ");
  }
  return "";
};

export const userClipboardTextFromCell = (
  cell:
    | Cell<KColumnType.user>
    | Cell<KColumnType.roomMembers>
    | Cell<KColumnType.automatedCreatedBy>,
  displayContext: ModularDisplayRequirements,
) => {
  const { value } = cell;
  const allUsers = displayContext.getOrLoadUsers(value ?? []);
  if (value) {
    return value.map((item) => allUsers[item]?.username).join(", ");
  }
  return "";
};
