import { assert } from "ts-essentials";
import { put, select, takeEvery } from "typed-redux-saga/macro";

import { addOfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageActions";
import { TextOfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageState";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { uuid } from "@kraaft/shared/core/utils";

import * as actions from "./sendMessageActions";

export function* sendTextMessageSagas() {
  yield* takeEvery(actions.sendTextMessage, sendTextMessageSaga);
}

function* sendTextMessageSaga({
  payload: { text, roomId, requestId, answerTo },
}: ReturnType<typeof actions.sendTextMessage>) {
  const currentUserId = yield* select(selectCurrentUserId);
  assert(currentUserId, "no currentUserId");

  const offlineMessage: TextOfflineMessage = {
    senderId: currentUserId,
    roomId,
    requestId,
    writtenAt: new Date().getTime(),
    optimisticId: uuid(),
    type: "text",
    text,
    answerTo,
  };

  yield* put(addOfflineMessage(offlineMessage));
}
