import { spawn } from "typed-redux-saga/macro";

import { initializeUserSaga } from "@kraaft/shared/core/modules/intercom/redux/sagas/initializeUser.saga";
import { resetIntercomSaga } from "@kraaft/shared/core/modules/intercom/redux/sagas/resetIntercom.saga";
import { updateUserSaga } from "@kraaft/shared/core/modules/intercom/redux/sagas/updateUser.saga";

export function* intercomSaga() {
  yield* spawn(updateUserSaga);
  yield* spawn(resetIntercomSaga);
  yield* spawn(initializeUserSaga);
}
