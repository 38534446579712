export const poolAcquisitionSources = [
  "meta",
  "wordOfMouth",
  "google",
  "linkedin",
] as const;

export type PoolAcquisitionSource =
  | (typeof poolAcquisitionSources)[number]
  | `other:${string}`;

export function isOtherAcquisitionSource(
  acquisitionSource: string,
): acquisitionSource is `other:${string}` {
  return acquisitionSource.startsWith("other:");
}
