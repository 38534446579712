import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  countContainer: {
    marginTop: 10,
  },
  count: {
    fontFamily: "Apercu-Medium",
    fontSize: 14,
  },
});
