import { useMeshContext } from "@kraaft/helper-hooks";
import { DirectoryTreeContext } from "@kraaft/web/src/components/directoryTree/directoryTreeContext";

interface DirectoryTreeRowGroupProps {
  directoryId: string;
  newDirectoryName: string;
}

export const useValidateDirectoryName = (props: DirectoryTreeRowGroupProps) => {
  const { directoryId, newDirectoryName } = props;

  const {
    directoryTreeStructure: { directories },
  } = useMeshContext(DirectoryTreeContext);

  const currentDirectory = directories[directoryId];

  if (currentDirectory === undefined) {
    return false;
  }

  if (newDirectoryName.length === 0) {
    return false;
  }

  return !Object.values(directories).some((directory) => {
    if (
      directory.id === directoryId ||
      directory.parentDirectoryId !== currentDirectory.parentDirectoryId
    ) {
      return false;
    }

    return directory.name === newDirectoryName;
  });
};
