import { createAction } from "@reduxjs/toolkit";

export const PermissionRequestStrategyActions = {
  numberOfSessionsChanged: createAction(
    "@permissionRequestStrategy/numberOfSessionsChanged",
  ),
  didInvite: createAction("@permissionRequestStrategy/didInvite"),
};

export const PermissionRequestStrategyStateActions = {
  setCurrentSessionUserId: createAction<string>(
    "@permissionRequestStrategy/state/setCurrentSessionUserId",
  ),
  incrementNumberOfSessions: createAction(
    "@permissionRequestStrategy/state/incrementNumberOfSessions",
  ),
  incrementNumberOfSentInvites: createAction(
    "@permissionRequestStrategy/state/incrementNumberOfSentInvites",
  ),
};
