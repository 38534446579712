import { createAction } from "@reduxjs/toolkit";

import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import {
  actionUpdateLoaderMetaFailure,
  actionUpdateLoaderMetaSuccess,
  createLoaderMeta,
} from "@kraaft/shared/core/modules/loaders/loaderUtils";
import {
  generateAddReportTemplateLoaderId,
  generateEditReportTemplateFileLoaderId,
} from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.actions.utils";

import { PoolSchemaReportTemplate } from "./reportTemplate.state";

export interface ReportTemplateUpdatableValues {
  name?: string;
  enabled?: boolean;
}

export const ReportTemplateActions = {
  subscribe: createAction<{ poolId: string }>("@reportTemplate/SUBSCRIBE"),
  unsubscribe: createAction<{ poolId: string }>("@reportTemplate/UNSUBSCRIBE"),

  addReportTemplate: createAction(
    "@reportTemplate/addReportTemplate",
    (payload: {
      poolId: string;
      schemaId: string;
      name: string;
      file: ModernFile<LocalPath>;
      forceAdd: boolean;
    }) => ({
      payload,
      meta: createLoaderMeta({
        id: generateAddReportTemplateLoaderId(payload.schemaId),
        status: LoaderStatus.LOADING,
      }),
    }),
  ),
  addReportTemplateSuccess: createAction(
    "@reportTemplate/addTemplateSuccess",
    actionUpdateLoaderMetaSuccess,
  ),
  addReportTemplateFailure: createAction(
    "@reportTemplate/addTemplateFailure",
    actionUpdateLoaderMetaFailure,
  ),

  editReportTemplateFile: createAction(
    "@reportTemplate/editReportTemplateFile",
    (payload: {
      reportTemplateId: string;
      file: ModernFile<LocalPath>;
      forceAdd: boolean;
    }) => ({
      payload,
      meta: createLoaderMeta({
        id: generateEditReportTemplateFileLoaderId(payload.reportTemplateId),
        status: LoaderStatus.LOADING,
      }),
    }),
  ),
  editReportTemplateFileSuccess: createAction(
    "@reportTemplate/editReportTemplateFileSuccess",
    actionUpdateLoaderMetaSuccess,
  ),
  editReportTemplateFileFailure: createAction(
    "@reportTemplate/editReportTemplateFileFailure",
    actionUpdateLoaderMetaFailure,
  ),
};

export const ReportTemplateStateActions = {
  set: createAction<{ data: PoolSchemaReportTemplate[] }>(
    "@reportTemplate/state/set",
  ),
};
