import { createAction } from "@reduxjs/toolkit";
import { Dictionary } from "ts-essentials";

import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { GeoLocation } from "@kraaft/shared/core/types";

import * as MessageTypes from "./messageState";

export const addUnknownMessages = createAction(
  "@message/ADD_UNKNOWN_MESSAGES",
  (payload: {
    initialMessageIds: string[];
    messages: Dictionary<MessageTypes.Message>;
    roomId: string;
  }) => ({
    payload,
  }),
);

export const fetchUnknownMessages = createAction(
  "@message/FETCH_UNKNOWN_MESSAGES",
  (payload: { messageIds: string[]; roomId: string }) => ({ payload }),
);

export const fetchingUnknownMessages = createAction(
  "@message/FETCHING_UNKNOWN_MESSAGES",
  (payload: { messageIds: string[]; roomId: string }) => ({ payload }),
);

interface ToggleSelectMessagesPayload {
  roomId: string;
  selectionType: MessageTypes.MessageSelectionType;
  selectionSource: MessageTypes.MessageSelectionSource;
  messageIds: string[];
  selectState: boolean;
}

export const toggleSelectMessages = createAction(
  "@message/SELECT_MESSAGES",
  (payload: ToggleSelectMessagesPayload) => ({ payload }),
);

interface DeselectMessagePayload {
  roomId: string;
  messageId?: string;
  all?: boolean;
}

export const deselectMessage = createAction(
  "@message/DESELECT_MESSAGE",
  (payload: DeselectMessagePayload) => ({ payload }),
);

type SetMessageSelectionPropertiesPayload = {
  roomId: string;
  selectionSource?: MessageTypes.MessageSelectionSource;
} & MessageTypes.MessageSelectionProperties;

export const setMessageSelectionProperties = createAction(
  "@message/SET_MESSAGE_SELECTION_PROPERTIES",
  (payload: SetMessageSelectionPropertiesPayload) => ({ payload }),
);

interface OpenForwardMessageSelectionFlowPayload {
  roomId: string;
}

export const openForwardMessageSelectionFlow =
  createAction<OpenForwardMessageSelectionFlowPayload>(
    "@message/OPEN_FORWARD_MESSAGE_SELECTION_FLOW",
  );

interface ForwardMessageSelectionPayload {
  roomId: string;
  destRoomId: string;
  preserveCaptions: boolean;
}

export const forwardMessageSelection =
  createAction<ForwardMessageSelectionPayload>(
    "@message/FORWARD_MESSAGE_SELECTION",
  );

export const setReplyingSourceMessage = createAction(
  "@message/SET_REPLYING_SOURCE_MESSAGE",
  (roomId: string, messageId: string) => ({ payload: { roomId, messageId } }),
);

export const unsetReplyingSourceMessage = createAction(
  "@message/UNSET_REPLYING_SOURCE_MESSAGE",
  (roomId: string) => ({ payload: roomId }),
);

interface ReactToMessagePayload {
  roomId: string;
  messageId: string;
  emoji: string;
}

export const reactToMessage = createAction<ReactToMessagePayload>(
  "@message/REACT_TO_MESSAGE",
);

interface ReactToMessageOptimisticPayload {
  roomId: string;
  messageId: string;
  emoji: string;
  userId: string;
}

interface ReactToMessageFailedPayload {
  roomId: string;
  messageId: string;
  // undefined means that there was no reaction before
  lastEmoji: string | undefined;
  userId: string;
}

export const reactToMessageFailed = createAction<ReactToMessageFailedPayload>(
  "@message/REACT_TO_MESSAGE_FAILED",
);

export type UpdateMessageAttachmentGeolocationPayload = {
  roomId: string;
  messageId: string;
  geolocation: GeoLocation | undefined;
};

export const MessageActions = {
  updateAttachmentGeolocation:
    createAction<UpdateMessageAttachmentGeolocationPayload>(
      "@message/UPDATE_ATTACHMENT_GEOLOCATION",
    ),
  editMessageTextRequest: createAction<{
    roomId: string;
    messageId: string;
    text: InputPartition[];
    messageType: "text" | "image" | "video" | "audio";
  }>("@message/EDIT_MESSAGE_TEXT_REQUEST"),
};

export const MessageStateActions = {
  clearRoomMessages: createAction<{ roomId: string }>(
    "@message/CLEAR_ROOM_MESSAGES",
  ),
  setAttachmentGeolocation: createAction<{
    roomId: string;
    messageId: string;
    geolocation: GeoLocation | undefined;
  }>("@message/state/SET_ATTACHMENT_GEOLOCATION"),
  isLoading: createAction<{ roomId: string; loading: boolean }>(
    "@message/state/LOADING",
  ),
  editMessageText: createAction<{
    roomId: string;
    messageId: string;
    text: InputPartition[];
  }>("@message/state/EDIT_MESSAGE_TEXT"),
  reactToMessageOptimistic: createAction<ReactToMessageOptimisticPayload>(
    "@message/state/REACT_TO_MESSAGE_OPTIMISTIC",
  ),
};
