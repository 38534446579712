import React from "react";

import { LocalPath } from "@kraaft/shared/core/modules/file/file";
import { getFilename } from "@kraaft/shared/core/utils";
import { PlayButton } from "@kraaft/web/src/components/videoPlayer/playButton";
import { useVideoPlayerContext } from "@kraaft/web/src/components/videoPlayer/useVideoPlayerContext";

import { getSourceType } from "../videoPlayerUtils";

import { useStyles } from "./messageVideoPlayer.styles";

interface Props {
  fileUrl: LocalPath | undefined;
  mode?: "modal" | "simple";
  onLongPress?: () => void;
  playOnClick?: boolean;
  isStatic?: boolean;
}

const MessageVideoPlayer_ = ({ fileUrl, playOnClick, isStatic }: Props) => {
  const classes = useStyles();

  const { videoRef, onVideoClick, shouldShowControls, isPlaying } =
    useVideoPlayerContext({
      playOnClick,
      isStatic,
    });

  const filename = fileUrl ? getFilename(fileUrl) : "video";
  const sourceType = getSourceType(filename);

  return (
    <div className={classes.container}>
      <div className={classes.videoContainer}>
        {!isPlaying && <PlayButton />}
        <video
          ref={videoRef}
          className={classes.video}
          onClick={onVideoClick}
          controls={!isStatic && shouldShowControls}
        >
          <source src={fileUrl} type={sourceType} />
        </video>
      </div>
    </div>
  );
};

export const MessageVideoPlayer = React.memo(MessageVideoPlayer_);
