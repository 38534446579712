import { makeStyles } from "@mui/styles";

import { styleObject } from "@kraaft/shared/styles/panel.styles";
import { Color, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  page: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  topBar: {
    backgroundColor: Color.WHITE,
    paddingLeft: Spacing.S8,
    paddingRight: Spacing.S8,
    height: 49,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    ...styleObject.borderBottom,
  },
  tabs: {
    flexGrow: 1,
    paddingRight: Spacing.S8,
    paddingLeft: Spacing.S8,
    marginTop: Spacing.S8,
    marginBottom: Spacing.S8,
  },
  closeDrawer: {
    paddingLeft: Spacing.S8,
  },
});
