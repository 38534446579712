import { AnyAction } from "redux";
import { eventChannel } from "redux-saga";
import { call, put, spawn, take, takeEvery } from "typed-redux-saga/macro";

import {
  disconnectKizeoForms,
  subscribeToForms,
  subscribeToFormsSnapshot,
  unsubscribeFromForms,
} from "@kraaft/shared/core/modules/form/formActions";
import { Form } from "@kraaft/shared/core/modules/form/formState";
import { kizeoFormSagas } from "@kraaft/shared/core/modules/form/kizeoFormSagas";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { Api } from "@kraaft/shared/core/services/api";
import { Firestore } from "@kraaft/shared/core/services/firestore";

export function* formSagas() {
  yield* spawn(kizeoFormSagas);

  yield* takeEvery(subscribeToForms, subscribeToFormsSaga);
  yield* takeEvery(disconnectKizeoForms, disconnectKizeoFormsSaga);
}

type FormSnapshotEvent = { poolId: string; forms: Form[] };

function* subscribeToFormsSaga({
  payload,
}: ReturnType<typeof subscribeToForms>) {
  const { poolId } = payload;

  const channel = yield* call(createFormsChannel, poolId);
  yield* takeEvery(channel, receiveForms);

  yield* take([
    UserActions.userDisconnectedFromFirebase,
    isUnsubscribeFromForms(poolId),
  ]);

  channel.close();
}

function* receiveForms(payload: FormSnapshotEvent) {
  yield* put(subscribeToFormsSnapshot(payload));
}

function createFormsChannel(poolId: string) {
  return eventChannel<FormSnapshotEvent>((emit) => {
    const unsubscribe = Firestore.subscribeToKizeoForms(poolId, (forms) =>
      emit({ poolId, forms }),
    );

    return unsubscribe;
  });
}

function isUnsubscribeFromForms(poolId: string) {
  return (action: AnyAction) =>
    unsubscribeFromForms.match(action) && action.payload.poolId === poolId;
}

function* disconnectKizeoFormsSaga(
  action: ReturnType<typeof disconnectKizeoForms>,
) {
  const { poolId } = action.payload;

  try {
    yield* put(unsubscribeFromForms({ poolId }));
    yield* call(Api.disconnectKizeoForms, { poolId });
  } catch (error) {
    console.log("disconnectKizeoForms ::", error);
  }
}
