function extractEmailDomain(email: string) {
  const domain = email.split("@")[1];
  return domain;
}

function isKraaftEmail(email: string) {
  const domain = extractEmailDomain(email);
  return domain === "kraaft.co";
}

export function isKraaftEmployeeEmail(email: string) {
  return isKraaftEmail(email);
}
