import { StyleProp, ViewStyle } from "react-native";

import { PixelSize } from "@kraaft/ui";

import { AvatarProps } from "./avatar.types";

import { styles } from "./avatar.styles";

export const AVATAR_CONTAINER_LARGE = PixelSize.S48;
export const AVATAR_CONTAINER_MEDIUM = PixelSize.S40;
export const AVATAR_CONTAINER_SMALL = PixelSize.S32;

export const getStylesForSizeAndType = (size: AvatarProps["size"]) => {
  const containerStyle: StyleProp<ViewStyle> = [styles.container];
  const indicatorDotStyle: StyleProp<ViewStyle> = [styles.indicatorDot];
  const topIndicatorContainerStyle: StyleProp<ViewStyle> = [
    styles.indicatorContainer,
  ];
  const bottomIndicatorContainerStyle: StyleProp<ViewStyle> = [
    styles.indicatorContainer,
  ];

  const containerSize =
    size === "large"
      ? AVATAR_CONTAINER_LARGE
      : size === "medium"
        ? AVATAR_CONTAINER_MEDIUM
        : // size === 'small'
          AVATAR_CONTAINER_SMALL;
  const indicatorIconSize = containerSize / 3;

  containerStyle.push({
    width: containerSize,
    borderRadius: containerSize / 2,
  });

  indicatorDotStyle.push({
    height: containerSize / 2,
    width: containerSize / 2,
    borderRadius: containerSize / 4,
  });

  topIndicatorContainerStyle.push({
    top: 0,
    right: -containerSize / 4,
  });
  bottomIndicatorContainerStyle.push({
    bottom: 0,
    right: -containerSize / 4,
  });

  return {
    containerStyle,
    indicatorDotStyle,
    topIndicatorContainerStyle,
    bottomIndicatorContainerStyle,
    indicatorIconSize,
  };
};
