import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { showInfo } from "@kraaft/shared/core/modules/alert/alertActions";
import { selectIsInternetReachable } from "@kraaft/shared/core/modules/app/appSelector";
import { RootState } from "@kraaft/shared/core/store";
import { AnyUnexplained } from "@kraaft/shared/core/types";

export function useNetwork() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isOnline = useSelector(selectIsInternetReachable);

  const ensureOnline = useCallback(
    function ensureOnline<T extends (...args: unknown[]) => void>(
      action: T,
      message?: { title: string; message?: string },
    ): (...args: Parameters<T>) => void {
      if (isOnline) {
        return action;
      }
      return () => {
        dispatch(
          showInfo({
            title: message ? message.title : t("offlineDefaultTitle"),
            message: message ? message.message : t("offlineDefaultMessage"),
          }),
        );
      };
    },
    [dispatch, isOnline, t],
  );

  return { ensureOnline, isOnline };
}

export function useEnsureOnline<T extends (...args: AnyUnexplained) => void>(
  action: T,
  deps: unknown[],
  messageTitle?: string,
  messageBody?: string,
): T {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isOnline = useSelector(
    (state: RootState) => state.app.network.isInternetReachable,
  );

  const ensureOnline = useCallback(
    (...args: AnyUnexplained) => {
      if (isOnline) {
        return action(...args);
      }
      dispatch(
        showInfo({
          title: messageTitle || t("offlineDefaultTitle"),
          message: messageBody || t("offlineDefaultMessage"),
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isOnline, messageBody, messageTitle, t, ...deps],
  );

  return ensureOnline as T;
}
