import { useMemo } from "react";
import clsx from "clsx";

import { ActionFooter } from "@kraaft/shared/components/actionFooter/actionFooter";
import { useStyles } from "@kraaft/shared/components/kDialog/kDialogContent/kDialogContent.styles";
import { Button } from "@kraaft/ui";
import { KDialogTitle } from "@kraaft/web/src/components/kDialog/kDialogTitle";

import { KDialogProps } from "./KDialogContent.types";

const KDialogContent = (props: KDialogProps) => {
  const { content, leftActions, actions } = props;

  const showLeftActions = leftActions !== undefined && leftActions.length > 0;
  const showActions = actions !== undefined && actions.length > 0;
  const showBottomBar = showLeftActions || showActions;

  const classes = useStyles({
    fullScreen: props.fullScreen,
    showBottomBar,
  });

  const renderedLeftActions = useMemo(
    () =>
      leftActions?.map((leftAction) => (
        <Button key={leftAction.text} variant="SECONDARY" {...leftAction} />
      )),
    [leftActions],
  );

  return (
    <>
      <KDialogTitle {...props} />
      {content && <div className={classes.content}>{content}</div>}
      {showBottomBar && (
        <div
          className={clsx(classes.actions, content && classes.actionsPadding)}
        >
          {renderedLeftActions}
          <div className={classes.actionSpacer} />
          {actions && (
            <ActionFooter
              actions={actions}
              direction="horizontal"
              noPadding={!props.fullScreen}
              backgroundColor="transparent"
            />
          )}
        </div>
      )}
    </>
  );
};

export { KDialogContent };
