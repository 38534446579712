import { MessageTypes } from "@kraaft/shared/core/modules/message";
import {
  GroupedEvent,
  LogMessage,
  LogMessageEvent,
  LogMessageOfType,
  Message,
} from "@kraaft/shared/core/modules/message/messageState";

const GROUP_BY_MINUTES = 15;
function groupFolderLogs<
  T extends "folderCompleted" | "folderCreated" | "folderUnchecked",
>(current: GroupedEvent<T>, message: MessageTypes.LogMessageOfType<T>) {
  const [lastMessage] = current.messages;
  const firstMessage = current.messages[current.messages.length - 1];
  if (!firstMessage || !lastMessage) {
    return true;
  }

  const isConcerningSameSchema =
    firstMessage.event.schemaId === message.event.schemaId;
  const isSentBySameUser = firstMessage.senderId === message.senderId;
  const timeSinceLastMessage =
    message.createdAt.getTime() - lastMessage.createdAt.getTime();
  const lastIsSentLessThanThreshold =
    timeSinceLastMessage < GROUP_BY_MINUTES * 60 * 1000;

  // Allow grouping if messages are sent by the same user, with the same schema id
  // and last log is less than 15 minutes ago
  return (
    isSentBySameUser && isConcerningSameSchema && lastIsSentLessThanThreshold
  );
}

const groupSpecifiers: {
  [k in LogMessageEvent["type"]]: (
    currentGrouping: GroupedEvent<k>,
    message: LogMessageOfType<k>,
  ) => boolean;
} = {
  folderCreated: groupFolderLogs,
  folderCompleted: () => false,
  folderUnchecked: () => false,
  internalMemberJoinedPool: () => false,
  workflowMessage: () => false,
};

function shouldKeepGrouping(
  context: LogMessageOfType<"group">,
  message: LogMessage,
) {
  const [firstGroupedMessage] = context.event.messages;
  if (
    firstGroupedMessage &&
    firstGroupedMessage.event.type !== message.event.type
  ) {
    return false;
  }
  if (!firstGroupedMessage) {
    return true;
  }
  const specifier = groupSpecifiers[context.event.groupedBy];
  if (!specifier) {
    return false;
  }
  return specifier(context.event as any, message as any);
}

function createGroupMessage(
  id: string,
  createdAt: Date,
  updatedAt: Date,
  senderId: string,
  groupedBy: LogMessageEvent["type"],
): LogMessageOfType<"group"> {
  return {
    type: "log",
    id,
    createdAt,
    updatedAt,
    senderId,
    event: {
      type: "group",
      groupedBy,
      messages: [],
    },
  };
}

export function groupSimilarLogs(messages: Message[]) {
  const groupedMessages: Message[] = [];
  let currentGroupingContext: LogMessageOfType<"group"> | undefined;

  function flushGroup(context: LogMessageOfType<"group">) {
    if (context.event.messages.length === 1) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      groupedMessages.unshift(context.event.messages[0]!);
    } else {
      groupedMessages.unshift(context);
    }
    currentGroupingContext = undefined;
  }

  // We want to group them starting from the earliest message
  for (let i = 0; i < messages.length; i += 1) {
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    const message = messages[i]!;
    if (message.type !== "log" || message.event.type === "group") {
      if (currentGroupingContext) {
        flushGroup(currentGroupingContext);
      }
      groupedMessages.unshift(message);
      continue;
    }
    if (
      currentGroupingContext &&
      !shouldKeepGrouping(currentGroupingContext, message)
    ) {
      flushGroup(currentGroupingContext);
    }
    if (!currentGroupingContext) {
      currentGroupingContext = createGroupMessage(
        message.id,
        message.createdAt,
        message.updatedAt,
        message.senderId,
        message.event.type,
      );
    }
    currentGroupingContext.event.messages.push(message as any);
  }

  if (currentGroupingContext) {
    flushGroup(currentGroupingContext);
  }

  return groupedMessages;
}
