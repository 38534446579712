import { StyleSheet } from "react-native";

import { styleObject } from "@kraaft/shared/styles/panel.styles";
import { Color, ColorStyle, PixelSize } from "@kraaft/ui";

export const styles = StyleSheet.create({
  main: {
    ...styleObject.borderTop,
  },
  header: {
    flexDirection: "row",
    marginTop: 7.5,
    paddingHorizontal: 12,
    justifyContent: "space-between", // TODO : slight vertical alignment adjustment
  },
  title: {
    color: ColorStyle.ACTION_CTA,
  },
  dismissIcon: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  senderContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingVertical: 10,
  },
  sendButton: {
    backgroundColor: Color.BLUE_SAVOY,
    borderRadius: 20,
    width: PixelSize.S36,
    height: PixelSize.S36,
    marginHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  sendLoader: {
    marginHorizontal: 10,
    padding: 5,
  },
  scrollContainer: {
    width: 0, // needed for scrollView to be scrollable when we have a lot of items
  },
});
