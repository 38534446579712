import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  panelsContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: Spacing.S24,
  },

  sectionTitle: {
    fontSize: FontSize.H1,
    marginBottom: Spacing.S16,
  },

  poolNameInput: {
    border: `1px solid ${ColorStyle.SEPARATOR}`,
  },

  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: Spacing.S40,
  },

  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: Spacing.S8,
  },
});
