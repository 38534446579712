import { makeStyles } from "@mui/styles";

export const makeDialogStyle = makeStyles({
  dialogContentContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  scrollbarOffset: {
    paddingRight: 12,
  },
});
