import { StyleSheet } from "react-native";

import { ColorStyle } from "@kraaft/ui";

export const styles = StyleSheet.create({
  panel: {
    flexGrow: 1,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
});
