import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useMeshContext } from "@kraaft/helper-hooks";
import { getFriendlyUserLabel } from "@kraaft/shared/components/modular/details/editors/userEditor/getFriendlyUserLabel";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularDisplayRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { compareStrings } from "@kraaft/shared/core/utils";
import { KDropdownButton } from "@kraaft/web/src/components/dropdown/kDropdownButton";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";

import { useStyles } from "./basicCell.styles";

export const UserCell = React.forwardRef<
  HTMLDivElement,
  IBasicCellProps<
    KColumnType.user | KColumnType.roomMembers | KColumnType.automatedCreatedBy
  >
>(({ value: userFieldValue, column }, ref) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { poolId, getOrLoadUsers, getPoolUsers } = useMeshContext(
    ModularDisplayRequirementsContext,
  );

  const valuesContent = useMemo(() => {
    const values = userFieldValue ?? [];

    const allUsers = {
      ...getOrLoadUsers(values),
      ...getPoolUsers(),
    };

    return values
      .map((value) => ({
        value,
        valueFormatted: getFriendlyUserLabel({
          userId: value,
          users: allUsers,
          t,
          poolId,
        }),
      }))
      .sort((a, b) => compareStrings(a.valueFormatted, b.valueFormatted))
      .map(({ value, valueFormatted }, index) => {
        const isNotLast = index < values.length - 1;

        return (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${value}`}
            className={classes.value}
            style={isNotLast ? { marginRight: 4 } : undefined}
          >
            {valueFormatted}
            {isNotLast ? "," : ""}
          </span>
        );
      });
  }, [classes.value, getOrLoadUsers, getPoolUsers, poolId, t, userFieldValue]);

  return (
    <div className={classes.container}>
      {column.editable ? (
        <KDropdownButton ref={ref} content={valuesContent} />
      ) : (
        <div className={classes.notEditableContainer}>
          <span className={classes.notEditableContent}>{valuesContent}</span>
        </div>
      )}
    </div>
  );
});
