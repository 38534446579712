import { useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { PoolSchemaBuilder } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder";
import { PoolSchemaBuilderTabs } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderTabs";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";
import { queries } from "@kraaft/web/src/views/app/appRouter/routes";

const RoomSchemaBuilder = () => {
  const poolId = useSelector(selectCurrentPoolId);
  const schema = useSelector(selectRoomSchema(poolId));
  const route =
    (useQueryString().get(queries.tab) as PoolSchemaBuilderTabs | undefined) ??
    PoolSchemaBuilderTabs.schema;

  if (!schema) {
    return null;
  }

  return <PoolSchemaBuilder schema={schema} tab={route} hidePreview />;
};

export { RoomSchemaBuilder };
