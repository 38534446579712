import {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { copyTextToClipboard } from "@kraaft/shared/core/utils/clipboard";
import { Text, TextProps } from "@kraaft/ui";

import { useStyles } from "./copiableText.styles";

interface CopyToClipboardProps extends TextProps {
  children: ReactNode;
  textToCopy: string;
}

const CopiableText = ({ textToCopy, size, ...other }: CopyToClipboardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const timeout = useRef<number | null>(null);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => () => {
      if (timeout.current !== null) {
        clearTimeout(timeout.current);
      }
    },
    [],
  );

  const onCopy = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();
      copyTextToClipboard(textToCopy);
      setCopied(true);
      if (timeout.current !== null) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => setCopied(false), 1250);
    },
    [textToCopy],
  );

  return (
    <div onClick={onCopy} ref={divRef} className={classes.root}>
      {copied ? (
        <Text size={size} color="GREEN_LAGOON">
          {t("textCopied")}
        </Text>
      ) : (
        <Text size={size} {...other} />
      )}
    </div>
  );
};

export { CopiableText };
