import { useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { SelectRoomTaskList } from "@kraaft/shared/components/createRoom/roomDetails/selectRoomTaskList";
import { SearchHeader } from "@kraaft/shared/components/searchHeader";

import { styles } from "./newRoomTaskList.styles";

interface Props {
  schemaTemplateIds: string[];
  onSubmit?: (selectedIds: string[]) => void;
  onClose: () => void;
}

const NewRoomTaskList = (props: Props) => {
  const { schemaTemplateIds, onSubmit, onClose } = props;
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");

  const handleSubmit = (selectedIds: string[]) => {
    onSubmit?.(selectedIds);
    onClose();
  };

  return (
    <View style={styles.container}>
      <SearchHeader
        headerTitle={t("tasksList")}
        onGoBackPress={onClose}
        onFilterSet={setFilter}
        placeholder={t("search")}
      />
      <SelectRoomTaskList
        filter={filter}
        onSubmit={handleSubmit}
        preselected={schemaTemplateIds}
      />
    </View>
  );
};

export { NewRoomTaskList };
