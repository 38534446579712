import { ReactNode } from "react";
import { Pressable, View } from "react-native";

import SuccessLottie from "@kraaft/shared/assets/success.json";
import { LottieManager } from "@kraaft/shared/components/lottieManager";
import { dismissNativeKeyboard } from "@kraaft/shared/core/utils/platformUtils";

import { styles } from "./congratulations.styles";

export const CongratsContainer = ({ children }: { children: ReactNode }) => (
  <Pressable
    accessibilityLabel=""
    style={styles.container}
    onPress={dismissNativeKeyboard}
  >
    <View style={styles.imageContainer}>
      <LottieManager
        source={SuccessLottie}
        style={styles.lottieContainer}
        initialSegment={[0, 53]}
        loop={false}
      />
    </View>
    {children}
  </Pressable>
);
