import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Mention } from "@kraaft/shared/core/framework/mentionnableText/mention";
import { MentionAwareText } from "@kraaft/shared/core/framework/mentionnableText/mentionAwareText";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";

export function usePluginMentions(
  mentionAwareText: MentionAwareText,
  availableMentions: Mention[],
  focusInputAtIndex: (index: number) => void,
) {
  const { t } = useTranslation();
  const [filteredMentions, setFilteredMentions] = useState<
    Mention[] | undefined
  >(undefined);

  mentionAwareText.hideMentions = () => setFilteredMentions(undefined);
  mentionAwareText.showMentions = (filter) => {
    const normalizedFilter = normalizeTextForSearch(filter);
    setFilteredMentions(
      availableMentions.filter((mention) => {
        if (mention.type === "everyone") {
          return (
            t("mentions.atEveryone").includes(normalizedFilter) ||
            "everyone".includes(normalizedFilter)
          );
        }
        return normalizeTextForSearch(
          mentionAwareText.getUsernameFromUserId(mention.id),
        ).includes(normalizedFilter);
      }),
    );
  };
  mentionAwareText.placeCursor = focusInputAtIndex;

  const insertMention = useCallback(
    (mention: Mention) => {
      mentionAwareText.insertMentionAtIndex(
        mention,
        mentionAwareText.getCursor(),
      );
    },
    [mentionAwareText],
  );

  return { insertMention, filteredMentions };
}
