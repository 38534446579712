import { makeStyles } from "@mui/styles";

import { Color } from "@kraaft/ui";

export const makeWrapperStyle = makeStyles(() => ({
  hoverable: {
    backgroundColor: Color.WHITE,
    "&:hover": {
      backgroundColor: Color.BLUE_LAVENDER,
    },
  },
}));
