import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { useRoomAccess } from "@kraaft/shared/core/modules/room/hooks/roomAccess.hooks";
import {
  subscribeToRoomUsers,
  unsubscribeFromRoomUsers,
} from "@kraaft/shared/core/modules/room/roomActions";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { Button } from "@kraaft/ui";

interface Props {
  room: Room;
  setDisplayed?: () => void;
}

const JoinLeaveSection = (props: Props) => {
  const {
    room: { id: roomId, poolId },
    setDisplayed,
  } = props;
  const navigationService = useNavigationService();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLeaveConfirm = useCallback(() => {
    if (isNative()) {
      navigationService.navigate("Home");
    }
  }, [navigationService]);

  const handleJoinConfirm = useCallback(() => {
    navigationService.navigate("ConversationInfo", {
      roomId,
    });
  }, [navigationService, roomId]);

  const { leaveRoom, joinRoom } = useRoomAccess({
    poolId,
    roomId,
    source: "room_info_tab",
    onLeaveConfirm: handleLeaveConfirm,
    onJoinConfirm: handleJoinConfirm,
  });

  useEffect(() => {
    if (!!leaveRoom || !!joinRoom) {
      setDisplayed?.();
    }
  }, [setDisplayed, leaveRoom, joinRoom]);

  useEffect(() => {
    dispatch(subscribeToRoomUsers({ roomId }));

    return () => {
      dispatch(unsubscribeFromRoomUsers({ roomId }));
    };
  }, [dispatch, roomId]);

  if (!leaveRoom && !joinRoom) {
    return null;
  }

  return (
    <>
      {!!leaveRoom && (
        <Button
          variant="QUATERNARY"
          destructive
          size="SMALL"
          alignContent="left"
          id="room-details-leave-room"
          accessibilityLabel={t("leaveConversation")}
          text={t("leaveConversation")}
          icon="log-out-02"
          onPress={leaveRoom}
        />
      )}

      {!!joinRoom && (
        <Button
          variant="QUATERNARY"
          size="SMALL"
          alignContent="left"
          id="room-details-join-room"
          accessibilityLabel={t("enterConversation")}
          text={t("enterConversation")}
          icon="log-in-02"
          onPress={joinRoom}
        />
      )}
    </>
  );
};

export { JoinLeaveSection };
