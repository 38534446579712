import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  addButtonContainer: {
    marginTop: Spacing.S8,
  },
});
