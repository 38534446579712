import { put, select, spawn, take, takeEvery } from "typed-redux-saga/macro";

import { selectNavigationSwitch } from "@kraaft/shared/core/modules/app/appSelector";
import {
  PoolStateActions,
  startOnPool,
} from "@kraaft/shared/core/modules/pool/poolActions";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { navigationService } from "@kraaft/shared/core/services/navigation/provider";
import { history } from "@kraaft/web/src/core/services/history";
import { queries } from "@kraaft/web/src/views/app/appRouter/routes";

export function* routerSagas() {
  yield* spawn(extractParamsFromStartLocation);

  yield* takeEvery(PoolStateActions.setPoolLocation, changePoolSaga);
}

function* extractParamsFromStartLocation() {
  const action = yield* take([
    UserActions.userConnectedToFirebase,
    UserActions.userDisconnectedFromFirebase,
  ]);

  if (UserActions.userConnectedToFirebase.match(action)) {
    const params = new URLSearchParams(history.location.search);

    const poolId = params.get(queries.poolId);

    if (poolId) {
      yield* put(startOnPool({ poolId }));
    }
  }
}

function* changePoolSaga({
  payload,
}: ReturnType<typeof PoolStateActions.setPoolLocation>) {
  yield* updateQueryPoolId(payload.poolId);
}

function* updateQueryPoolId(value: string) {
  const step = yield* select(selectNavigationSwitch);

  // updating params on other screens can cause crashes
  if (step === "ok") {
    const historyParams = new URLSearchParams(history.location.search);
    const historyPoolId = historyParams.get(queries.poolId);

    if (historyPoolId !== value) {
      navigationService.navigateAndPreserveSearch({
        pathname: history.location.pathname,
        search: new URLSearchParams({
          ...navigationService.getResetQuery(),
          [queries.poolId]: value ?? "",
        }),
      });
    }
  }
}
