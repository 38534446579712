import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";
import { VERTICAL_NAVBAR_WIDTH } from "@kraaft/web/src/components/layout/verticalNavigationBar/verticalNavigation.utils";

type BaseNavigationElementProps = {
  children: React.ReactNode;
};

const BaseNavigationElement = ({ children }: BaseNavigationElementProps) => {
  const classes = useStyles();

  return <div className={classes.wrapper}>{children}</div>;
};

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    minWidth: VERTICAL_NAVBAR_WIDTH - Spacing.S8 * 2,
  },
});

export { BaseNavigationElement };
