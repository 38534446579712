import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { createLoaderMeta } from "@kraaft/shared/core/modules/loaders/loaderUtils";

export const kizeoFormLoaderId = "kizeoForm";

export type KizeoForm = {
  name: string;
  kizeo: { formId: string };
  /** isConfigured is undefined when the configuration is not received yet, true or false once the configuration is received */
  isConfigured?: boolean;
};

export const kizeoFormAdapter = createEntityAdapter<KizeoForm, string>({
  selectId: (entity) => entity.kizeo.formId,
});

const slice = createSlice({
  name: "kizeoForm",
  initialState: kizeoFormAdapter.getInitialState(),
  reducers: {
    loadKizeoForms: {
      reducer: () => kizeoFormAdapter.getInitialState(),
      prepare: ({ poolId }: { poolId: string }) => ({
        payload: { poolId },
        meta: createLoaderMeta({
          id: kizeoFormLoaderId,
          status: LoaderStatus.LOADING,
        }),
      }),
    },
    kizeoFormsLoaded: {
      reducer: kizeoFormAdapter.setAll,
      prepare: (forms: KizeoForm[]) => ({
        payload: forms,
        meta: createLoaderMeta({
          id: kizeoFormLoaderId,
          status: LoaderStatus.SUCCESS,
        }),
      }),
    },
    kizeoFormsLoadingFailure: {
      reducer: () => {},
      prepare: (error: Error) => ({
        payload: {},
        meta: createLoaderMeta({
          id: kizeoFormLoaderId,
          status: LoaderStatus.FAILURE,
          error,
        }),
      }),
    },
    stopLoadingKizeoForms: {
      reducer: () => {},
      prepare: () => ({
        payload: {},
        meta: createLoaderMeta({
          id: kizeoFormLoaderId,
          status: LoaderStatus.SUCCESS,
        }),
      }),
    },

    kizeoConfigurationsLoaded: (
      state,
      { payload }: PayloadAction<{ formId: string; isConfigured: boolean }[]>,
    ) => {
      kizeoFormAdapter.updateMany(
        state,
        payload.map((item) => ({
          id: item.formId,
          changes: { isConfigured: item.isConfigured },
        })),
      );
    },
  },
});

export const {
  loadKizeoForms,
  kizeoFormsLoaded,
  kizeoFormsLoadingFailure,
  kizeoConfigurationsLoaded,
  stopLoadingKizeoForms,
} = slice.actions;

export const kizeoFormReducers = slice.reducer;
