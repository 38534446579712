import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  screen: {
    flexGrow: 1,
  },
  safeAreaView: {
    flexGrow: 1,
    flexBasis: 0,
  },
  button: {
    margin: Spacing.S16,
  },
});
