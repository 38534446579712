import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { noop } from "ts-essentials";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { selectOnePool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { getInviteLinkForTarget } from "@kraaft/shared/core/utils/useInviteLinkMessageConfigGenerator/useInviteLinkMessageConfigGenerator.utils";
import { Button, Color, Preloader, Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { useCopyButton } from "@kraaft/web/src/components/copyButton/copyButton";

const InviteCollaboratorsAtPoolCreation = (props: {
  poolId: string;
  onInvited?: (ids?: string[]) => void;
}) => {
  const { poolId } = props;
  const { t } = useTranslation();
  const navigationService = useNavigationService();

  const pool = useSelector(selectOnePool(poolId));

  const goToNextPage = useCallback(() => {
    navigationService.replace("LoaderCreatingPool", {
      hasInvited: false,
    });
  }, [navigationService]);

  const [link, setLink] = useState("");

  useEffect(() => {
    const updateLink = async () => {
      setLink("");
      setLink(
        await getInviteLinkForTarget({
          type: "pool",
          poolId,
        }),
      );
    };

    updateLink().catch(console.error);
  }, [poolId]);

  const { copyToClipboard, icon: rightCopyIcon } = useCopyButton({
    value: link,
  });

  return (
    <Box>
      <Box mb="S12">
        <Text weight="bold" size="H1">
          {t("inviteCollaboratorsAtPoolCreation.title")}
        </Text>
      </Box>
      <Box mb="S16">
        <Text size="BODY">
          {t("inviteCollaboratorsAtPoolCreation.subtitleWeb")}
        </Text>
      </Box>
      <Box mb="S8">
        <KInput
          label={t("shareInviteCollaborators.linkLabel", {
            poolName: pool?.name,
          })}
          value={link}
          onChangeText={noop}
          editable={false}
          onFocus={copyToClipboard}
          rightIcon={rightCopyIcon}
          selectTextOnFocus
        />
        {!link && <Preloader absoluteFill backgroundColor={Color.WHITE} />}
      </Box>
      <Box mb="S40" items="flex-start">
        <Button
          variant="QUATERNARY"
          accessibilityLabel={t("inviteCollaboratorsAtPoolCreation.copy")}
          text={t("inviteCollaboratorsAtPoolCreation.copy")}
          onPress={copyToClipboard}
          icon="copy-06"
        />
      </Box>
      <Button
        variant="PRIMARY"
        accessibilityLabel={t("next")}
        text={t("next")}
        onPress={goToNextPage}
      />
    </Box>
  );
};

export { InviteCollaboratorsAtPoolCreation };
