import { useMemo } from "react";
import { View, ViewStyle } from "react-native";

import { Text } from "@kraaft/ui";

import { styles } from "./droppableContainer.styles";

interface Props {
  isActive: boolean;
  text?: string;
  style?: ViewStyle;
}

const DroppableContainer = (props: Props) => {
  const { isActive, text, style } = props;

  const containerStyle = useMemo(
    () => [
      styles.container,
      Boolean(text) && styles.containerWithText,
      isActive && styles.activeBorder,
      style,
    ],
    [isActive, style, text],
  );

  return (
    <View style={containerStyle} pointerEvents="none">
      <Text size="BODY" color="FONT_LOW_EMPHASIS" weight="bold">
        {text}
      </Text>
    </View>
  );
};

export { DroppableContainer };
