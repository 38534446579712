import { createAction } from "@reduxjs/toolkit";

import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import {
  actionUpdateLoaderMetaFailure,
  actionUpdateLoaderMetaSuccess,
  createLoaderMeta,
  excelExportLoadingId,
} from "@kraaft/shared/core/modules/loaders/loaderUtils";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import {
  CarouselWithModularFolder,
  ModularFolderRoomLoadStatus,
} from "@kraaft/shared/core/modules/modularFolder/modularFolderState";
import { ModularFolderVisibility } from "@kraaft/shared/core/modules/modularFolder/types";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";

// Modular Folder Carousel

type OpenModularFolderCarouselPayload = {
  roomId: string;
  initialIndex?: number;
} & CarouselWithModularFolder;

export const ModularFolderActions = {
  subscribeForPool: createAction<{ poolId: string }>(
    "@modularFolder/SUBSCRIBE_FOR_POOL",
  ),
  unsubscribeForPool: createAction<{ poolId: string }>(
    "@modularFolder/UNSUBSCRIBE_FOR_POOL",
  ),
  subscribeForRoom: createAction<{ roomId: string }>(
    "@modularFolder/SUBSCRIBE_FOR_ROOM",
  ),
  unsubscribeForRoom: createAction<{ roomId: string }>(
    "@modularFolder/UNSUBSCRIBE_FOR_ROOM",
  ),

  exportToExcel: createAction(
    "@modularFolder/EXPORT_TO_EXCEL",
    (schema: KSchema, filterList: CompositeCondition | undefined) => ({
      payload: { schema, filterList },
      meta: createLoaderMeta({
        id: excelExportLoadingId,
        status: LoaderStatus.LOADING,
      }),
    }),
  ),
};

export const ModularFolderStateActions = {
  updateRoomLoadStatus: createAction<{
    roomId: string;
    status: ModularFolderRoomLoadStatus;
  }>("@modularFolder/state/UPDATE_ROOM_LOAD_STATUS"),

  openCarousel: createAction(
    "@modularFolder/state/OPEN_CAROUSEL",
    (payload: OpenModularFolderCarouselPayload) => ({
      payload: { ...payload, initialIndex: payload.initialIndex ?? 0 },
    }),
  ),
  closeCarousel: createAction("@modularFolder/state/CLOSE_CAROUSEL"),

  exportToExcelSuccess: createAction(
    "@modularFolder/state/EXPORT_TO_EXCEL_SUCCESS",
    actionUpdateLoaderMetaSuccess,
  ),

  exportToExcelFailure: createAction(
    "@modularFolder/state/EXPORT_TO_EXCEL_FAILURE",
    actionUpdateLoaderMetaFailure,
  ),

  cleanModularFolderList: createAction(
    "@modularFolder/CLEAN_MODULAR_FOLDER_LIST",
  ),

  setIdsForVisibility: createAction<{
    visibility: ModularFolderVisibility;
    ids: string[];
  }>("@modularFolder/state/SET_IDS_FOR_VISIBILITY"),
};
