import { useRef } from "react";

import { RoomFiltersValues } from "@kraaft/shared/components/filters/roomFilters.context";
import { RoomFiltersSheet } from "@kraaft/shared/components/filters/roomFilters.sheet";
import { RoomFiltersSheetComposition } from "@kraaft/shared/components/filters/roomFilters.sheetComposition";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { getActiveFiltersCountAndText } from "@kraaft/shared/core/modules/filter/filterUtils";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";

interface Props {
  poolId: string | undefined;
  defaultRoomFilters: RoomFiltersValues;
  filters: RoomFiltersValues;
  setFilters: (newFilters: RoomFiltersValues) => void;
}

export const RoomSelectionFilterButton = ({
  poolId,
  defaultRoomFilters,
  filters,
  setFilters,
}: Props) => {
  const buttonRef = useRef(null);
  const [isOpen, openRoomFiltersSheet, closeRoomFiltersSheet] =
    useBooleanState(false);

  const { activeFiltersCount, activeFiltersCountAsText } =
    getActiveFiltersCountAndText(filters);

  return (
    <>
      <HeaderIconButton
        ref={buttonRef}
        icon="filter-funnel-03"
        accessibilityLabel={activeFiltersCountAsText}
        text={activeFiltersCountAsText}
        onPress={openRoomFiltersSheet}
        variant="TERTIARY"
        size="SMALL"
        selected={activeFiltersCount > 0}
        condensed
      />

      {poolId !== undefined ? (
        <RoomFiltersSheet.Host
          open={isOpen}
          onClose={closeRoomFiltersSheet}
          anchor={buttonRef}
          placement="bottom-start"
        >
          <RoomFiltersSheetComposition
            poolId={poolId}
            onFilter={setFilters}
            initialRoomFilters={filters}
            defaultRoomFilters={defaultRoomFilters}
            onClose={closeRoomFiltersSheet}
          />
        </RoomFiltersSheet.Host>
      ) : null}
    </>
  );
};
