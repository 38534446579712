import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { KDialog } from "@kraaft/shared/components/kDialog";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { SearchableMultiselect } from "@kraaft/shared/components/searchableMultiselect";
import {
  DaxiumLinkDetails,
  useDaxiumLinkDetails,
} from "@kraaft/shared/core/modules/daxium/daxium.hooks";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { firestore } from "@kraaft/shared/core/services/firestore/sdk";
import {
  useApi,
  useApiError,
  useApiQuery,
} from "@kraaft/shared/core/utils/useApi.hooks";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button, Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { KTable } from "@kraaft/web/src/components/kTable";
import { IntegrationSection } from "@kraaft/web/src/views/settings/manageIntegrations/integrationSection";

const SuperadminDaxiumSection = (props: {
  link?: DaxiumLinkDetails;
}) => {
  const poolId = useSelector(selectCurrentPool)?.id;
  const list = useDaxiumEndpointList();
  const { t } = useTranslation();

  const [link, { error: linkError }] = useApi(
    useCallback(
      (endpointId: string) =>
        Api.call("linkPoolToDaxium", { poolId, endpointId }),
      [poolId],
    ),
  );
  useApiError(linkError);

  const [unlink, { error: unlinkError }] = useApi(
    useCallback(() => Api.call("unlinkPoolFromDaxium", { poolId }), [poolId]),
  );
  useApiError(unlinkError);

  const actions = useMemo(() => {
    if (props.link) {
      return [
        {
          label: t("integrations.daxium.unlink"),
          onPress: unlink,
          icon: "link-broken-01",
        } satisfies ActionSheetItem,
      ];
    }
    return list.map(
      ({ id }) =>
        ({
          label: `${t("integrations.daxium.link")} ${id}`,
          onPress: () => link(id),
          icon: "link-03",
        }) satisfies ActionSheetItem,
    );
  }, [props.link, t, list, link, unlink]);

  const details = props.link && <DaxiumForms link={props.link} />;

  return (
    <IntegrationSection
      title={t("integrations.daxium.title")}
      actions={actions}
      details={details}
    />
  );
};

const UserDaxiumSection = (props: { link?: DaxiumLinkDetails }) => {
  const { t } = useTranslation();
  const details = props.link && <DaxiumForms link={props.link} />;

  return (
    <IntegrationSection
      title={t("integrations.daxium.title")}
      details={details}
    />
  );
};

export const DaxiumSection = () => {
  const poolId = useSelector(selectCurrentPool)?.id;
  const link = useDaxiumLinkDetails(poolId);
  const isSuperAdmin = useSelector(selectCurrentUserIsSuperadmin);

  if (isSuperAdmin) {
    return <SuperadminDaxiumSection link={link} />;
  }

  if (link) {
    return <UserDaxiumSection link={link} />;
  }

  return null;
};

function useDaxiumEndpointList() {
  type DaxiumEndpoint = { id: string };
  const [list, setList] = useState<DaxiumEndpoint[]>([]);

  useEffect(() => {
    return firestore()
      .collection("daxiumEndpointList-1n")
      .onSnapshot((snapshot) => {
        setList(snapshot.docs.map((doc) => doc.data() as DaxiumEndpoint));
      });
  }, [setList]);

  return list;
}

function useDaxiumAvailableForms(poolId?: string) {
  type DaxiumExternalForm = { id: string; name: string; bound: boolean };

  const query = useApiQuery(
    useCallback(
      () =>
        Api.call<{ data: DaxiumExternalForm[] }>("getAvailableDaxiumForms", {
          poolId,
        }),
      [poolId],
    ),
  );

  return {
    ...query,
    result: query.result?.data ?? [],
  };
}

export const AvailableDaxiumForms = ({ link }: { link: DaxiumLinkDetails }) => {
  const { t } = useTranslation();
  const poolId = useSelector(selectCurrentPool)?.id;
  const { result: list, loading } = useDaxiumAvailableForms(poolId);

  const [enableForm, { error: enableError }] = useApi(
    useCallback(
      (formId: string) => Api.call("enableDaxiumForm", { poolId, formId }),
      [poolId],
    ),
  );
  useApiError(enableError);

  const [disableForm, { error: disableError }] = useApi(
    useCallback(
      (formId: string) => Api.call("disableDaxiumForm", { poolId, formId }),
      [poolId],
    ),
  );
  useApiError(disableError);

  const items = useMemo(
    () =>
      list.map((i) => ({
        identifier: i.id,
        label: i.name,
        disabled: !i.bound,
      })),
    [list],
  );

  const selected = useMemo(() => link.forms.map((f) => f.id), [link]);

  return (
    <Box grow gap="S24">
      <Text>{t("integrations.daxium.manageFormsDescription")}</Text>
      <SearchableMultiselect
        items={items}
        selected={selected}
        onCheck={enableForm}
        onUncheck={disableForm}
      />
      {loading && <Text>{t("integrations.daxium.manageFormsLoading")}</Text>}
    </Box>
  );
};

export const DaxiumForms = ({ link }: { link: DaxiumLinkDetails }) => {
  const { t } = useTranslation();
  const [isOpen, open, close] = useBooleanState(false);

  const [disableForm, { error: disableError }] = useApi(
    useCallback(
      (formId: string) =>
        Api.call("disableDaxiumForm", { poolId: link.id, formId }),
      [link.id],
    ),
  );
  useApiError(disableError);

  return (
    <Box grow>
      <div>
        <Button
          icon="plus"
          variant="SECONDARY"
          size="SMALL"
          text={t("integrations.daxium.manageForms")}
          onPress={open}
        />
      </div>
      <KDialog
        open={isOpen}
        onClose={close}
        title={t("integrations.daxium.manageForms")}
        content={<AvailableDaxiumForms link={link} />}
      />
      <KTable
        config={{
          columns: [
            {
              id: "name",
              label: t("integrations.daxium.columnForm"),
              width: 200,
              component: (item) => <Text>{item.name}</Text>,
            },
            {
              id: "actions",
              label: t("integrations.daxium.columnActions"),
              width: 1,
              component: (item) => (
                <Button
                  variant="SECONDARY"
                  destructive
                  size="SMALL"
                  icon="trash-01"
                  onPress={() => disableForm(item.id)}
                />
              ),
            },
          ],
        }}
        data={link.forms}
        extractKey={(item) => item.id}
      />
    </Box>
  );
};
