import { FilePath } from "@kraaft/shared/core/modules/file/file";
import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import { LibrarySchemaReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import {
  KFolderSchema,
  KSchemaIcon,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";

export interface LibrarySchemaState {
  fetched: boolean;
  librarySchemas: {
    [librarySchemaId: string]: LibrarySchema;
  };
  companyLibrarySchemas: {
    [librarySchemaId: string]: LibrarySchema;
  };
  createdColumnKey: { librarySchemaId: string; columnKey: string } | undefined;
}

export interface LibrarySchemaPresentation {
  description: string;
  coverPictureUrl: FilePath | null;
  video:
    | {
        type: "youtube";
        url: string;
        id: string;
      }
    | undefined;
  sampleReport: {
    filename: string;
    downloadUrl: FilePath;
  } | null;
}

export const LibrarySchemaLanguage = {
  "fr-FR": "fr-FR",
  "en-GB": "en-GB",
  "en-US": "en-US",
  "it-IT": "it-IT",
  "de-DE": "de-DE",
  "es-ES": "es-ES",
} as const satisfies { [key in PoolLanguage]: key };

export type LibrarySchemaLanguage =
  (typeof LibrarySchemaLanguage)[keyof typeof LibrarySchemaLanguage];

export interface LibrarySchema {
  id: string;
  schema: KFolderSchema;
  enabled: boolean;
  name: string;
  icon: KSchemaIcon;
  index: number;
  presentation: LibrarySchemaPresentation;
  language: LibrarySchemaLanguage;
  reportTemplates: LibrarySchemaReportTemplate[];
  updatedAt: Date;
  companyId: string | undefined;
}
