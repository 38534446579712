import { ReactNode, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMeshContextSetup } from "@kraaft/helper-hooks";
import {
  LibrarySchemaOptimisticActions,
  LibrarySchemaOptimisticOperations,
} from "@kraaft/shared/core/modules/librarySchema/librarySchema.optimistic";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import {
  KSchemaColumnDefinition,
  KSchemaElement,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.optimistic";
import { selectCreatedColumn } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { useEffectDifference } from "@kraaft/shared/core/utils/useEffectDifference";
import { getFieldProp } from "@kraaft/web/src/components/modularTable/components/fields";
import {
  getDefaultColumnValue,
  getDefaultSectionValue,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/columnUtils";
import { FormBuilderElement } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";
import { ElementCreation } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditor";
import {
  SchemaBuilderContext,
  SchemaBuilderContextProps,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

interface Props {
  librarySchema: LibrarySchema;
  children: ReactNode;
}

export const LibrarySchemaBuilderContextProvider = ({
  librarySchema,
  children,
}: Props) => {
  const dispatch = useDispatch();

  const { schema } = librarySchema;

  const lockOnEnd = useRef(false);
  const [currentDraggedKey, setCurrentDraggedKey] = useState<
    string | undefined
  >(undefined);
  const [currentHoveredKey, setCurrentHoveredKey] = useState<
    HoverState | undefined
  >(undefined);

  const [currentElementEditionKey, setCurrentElementEditionKey] = useState<
    string | undefined
  >(undefined);
  const [currentElementCreation, setCurrentElementCreation] = useState<
    ElementCreation | undefined
  >(undefined);
  const justCreatedColumn = useSelector(selectCreatedColumn(librarySchema.id));

  const blurEverything = useCallback(() => {
    setCurrentElementEditionKey(undefined);
    setCurrentElementCreation(undefined);
  }, []);

  const insertElement = useCallbackRealtime(
    (
      [_schema, _currentElementCreation],
      element: KSchemaElement,
      placement: HoverState,
    ) => {
      if (!_currentElementCreation || element.name.length === 0) {
        return;
      }
      if (element.elementType === "section") {
        dispatch(
          LibrarySchemaOptimisticActions.addOperation(
            LibrarySchemaOptimisticOperations["schema.addSection"].create({
              targetId: _schema.id,
              section: element,
              placement,
            }),
          ),
        );
      }
      if (element.elementType === "column") {
        dispatch(
          LibrarySchemaOptimisticActions.addOperation(
            LibrarySchemaOptimisticOperations["schema.addColumn"].create({
              targetId: _schema.id,
              column: element,
              placement,
              inSection: _currentElementCreation.inSection,
            }),
          ),
        );
      }
      blurEverything();
    },
    [blurEverything, dispatch],
    [schema, currentElementCreation],
  );

  const addElement = useCallbackRealtime(
    (
      [_schema],
      item: FormBuilderElement,
      placement?: HoverState,
      inElement?: string,
    ) => {
      const columns = Object.values(_schema.rootSection.elements).sort(
        KSchemaUtils.orderByIndex,
      );
      const columnKey = placement?.key ?? columns[columns.length - 1]?.key;
      if (!columnKey) {
        return;
      }
      if (item.type === "section") {
        const section = getDefaultSectionValue("");
        setCurrentElementEditionKey(section.key);
        setCurrentElementCreation({
          type: "section",
          placement: placement ?? { key: columnKey, placement: "after" },
          section,
          inSection: inElement,
        });
      } else {
        const column = getDefaultColumnValue("", item.columnType);
        setCurrentElementEditionKey(column.key);
        setCurrentElementCreation({
          type: "column",
          placement: placement ?? { key: columnKey, placement: "after" },
          column,
          inSection: inElement,
        });
      }
    },
    [],
    [schema],
  );

  const editColumnDefinition = useCallbackRealtime(
    ([_schema], columnKey: string, newDefinition: KSchemaColumnDefinition) => {
      dispatch(
        LibrarySchemaOptimisticActions.addOperation(
          LibrarySchemaOptimisticOperations[
            "schema.editColumnDefinition"
          ].create({
            targetId: _schema.id,
            columnKey,
            definition: newDefinition,
          }),
        ),
      );
    },
    [dispatch],
    [schema],
  );

  const renameElement = useCallbackRealtime(
    ([schemaRef], columnKey: string, name: string, hasEditor: boolean) => {
      const element = KSchemaUtils.findElement(
        schemaRef.rootSection,
        columnKey,
      );
      if (element?.name !== name) {
        if (element?.elementType === "column") {
          dispatch(
            LibrarySchemaOptimisticActions.addOperation(
              LibrarySchemaOptimisticOperations["schema.renameColumn"].create({
                targetId: schema.id,
                columnKey,
                name,
              }),
            ),
          );
        } else if (element?.elementType === "section") {
          dispatch(
            LibrarySchemaOptimisticActions.addOperation(
              LibrarySchemaOptimisticOperations["schema.editSection"].create({
                targetId: schema.id,
                key: element.key,
                edits: {
                  name,
                },
              }),
            ),
          );
        }
      }
      if (!hasEditor) {
        setCurrentElementEditionKey(undefined);
      }
    },
    [dispatch, schema.id],
    [schema],
  );

  const reorderElement = useCallback(
    (
      hoverState: HoverState,
      target: string,
      tryToMoveInsideElement: string | undefined,
    ) => {
      if (target === hoverState.key) {
        return;
      }
      dispatch(
        LibrarySchemaOptimisticActions.addOperation(
          LibrarySchemaOptimisticOperations["schema.reorderElement"].create({
            targetId: schema.id,
            placement: hoverState,
            target,
            tryToMoveInsideElement:
              tryToMoveInsideElement === hoverState.key ? true : false,
          }),
        ),
      );
    },
    [dispatch, schema.id],
  );

  const deleteElement = useCallback(
    (columnKey: string) => {
      dispatch(
        LibrarySchemaOptimisticActions.addOperation(
          LibrarySchemaOptimisticOperations["schema.deleteColumn"].create({
            targetId: schema.id,
            columnKey,
          }),
        ),
      );
    },
    [dispatch, schema.id],
  );

  const createdColumnType = justCreatedColumn
    ? KSchemaUtils.findColumn(schema.rootSection, justCreatedColumn)?.type
    : undefined;
  const columnCreationRef = useRef(currentElementCreation);
  columnCreationRef.current = currentElementCreation;
  useEffectDifference(
    ([, oldJustCreatedColumn, oldType]) => {
      if (
        columnCreationRef.current ||
        !justCreatedColumn ||
        (oldJustCreatedColumn === justCreatedColumn &&
          oldType === createdColumnType)
      ) {
        return;
      }
      if (
        createdColumnType &&
        getFieldProp("columnEditor", createdColumnType)
      ) {
        setCurrentElementEditionKey(justCreatedColumn);
      }
    },
    [currentElementCreation, justCreatedColumn, createdColumnType],
  );

  const resetDrag = useCallback(() => {
    setCurrentHoveredKey(undefined);
    setCurrentDraggedKey(undefined);
  }, []);

  const resetCreation = useCallback(() => {
    setCurrentElementCreation(undefined);
    setCurrentElementEditionKey(undefined);
  }, []);

  const editSection = useCallback(
    (
      key: string,
      edits: {
        color?: string;
      },
    ) => {
      dispatch(
        LibrarySchemaOptimisticActions.addOperation(
          LibrarySchemaOptimisticOperations["schema.editSection"].create({
            targetId: schema.id,
            key,
            edits: {
              color: edits.color,
            },
          }),
        ),
      );
    },
    [dispatch, schema.id],
  );

  const editCondition = useCallback(
    (key: string, condition: CompositeCondition | undefined) => {
      dispatch(
        LibrarySchemaOptimisticActions.addOperation(
          LibrarySchemaOptimisticOperations["schema.editCondition"].create({
            targetId: schema.id,
            key: key,
            condition,
          }),
        ),
      );
    },
    [dispatch, schema.id],
  );

  const duplicate = useCallback(
    (key: string) => {
      dispatch(
        LibrarySchemaOptimisticActions.addOperation(
          LibrarySchemaOptimisticOperations["schema.duplicateElement"].create({
            key,
            targetId: schema.id,
          }),
        ),
      );
    },
    [dispatch, schema.id],
  );

  const contextValue = useMeshContextSetup<SchemaBuilderContextProps>({
    insertElement,
    addElement,
    editColumnDefinition,
    renameElement,
    reorderElement,
    deleteElement,
    blurEverything,
    currentElementCreation,
    currentElementEditionKey,
    setCurrentElementCreation,
    setCurrentElementEditionKey,
    currentHoveredKey,
    setCurrentHoveredKey,
    currentDraggedKey,
    setCurrentDraggedKey,
    resetDrag,
    resetCreation,
    lockOnEnd,
    editSection,
    editCondition,
    duplicate,
    schema,
    isColumnDeletionInstant: true,
    hidePreview: false,
  });

  return (
    <SchemaBuilderContext.Provider value={contextValue}>
      {children}
    </SchemaBuilderContext.Provider>
  );
};
