import { put, takeEvery } from "typed-redux-saga/macro";

import { MemoryStateActions } from "@kraaft/shared/core/modules/memory/memoryActions";
import * as actions from "@kraaft/shared/core/modules/user/userActions";

export function* blockUserSaga() {
  yield* takeEvery(actions.UserActions.blockUser, everyBlockUserSaga);
}

function* everyBlockUserSaga(
  action: ReturnType<typeof actions.UserActions.blockUser>,
) {
  yield* put(
    actions.UserStateActions.addBlockedUsers({
      userIds: [action.payload.userId],
    }),
  );
  yield* put(
    MemoryStateActions.addBlockedUser({
      userId: action.payload.userId,
    }),
  );
}
