import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ActionFooter,
  FooterActions,
} from "@kraaft/shared/components/actionFooter/actionFooter";
import { HideWhenKeyboard } from "@kraaft/shared/components/hideWhenKeyboard";
import { ReportGenerator } from "@kraaft/shared/components/report";
import { SlidePanel } from "@kraaft/shared/components/slidePanel";
import { ConversationDetailsPanel } from "@kraaft/shared/core/services/navigation/navigationParams";
import { ColorStyle } from "@kraaft/ui";

interface Props {
  roomId: string;
  openedPanel: ConversationDetailsPanel | undefined;
  setOpenedPanel: (
    panelIdentifier: ConversationDetailsPanel | undefined,
  ) => void;
  portalHostname?: string;
}

const RoomReportSection = (props: Props) => {
  const { roomId, openedPanel, setOpenedPanel, portalHostname } = props;
  const { t } = useTranslation();

  const panelIdentifier = ConversationDetailsPanel.report;

  const handlePress = useCallback(() => {
    setOpenedPanel(panelIdentifier);
  }, [panelIdentifier, setOpenedPanel]);

  const closePanel = useCallback(() => {
    setOpenedPanel(undefined);
  }, [setOpenedPanel]);

  const actions: FooterActions = useMemo(
    () => [
      {
        id: "room-export-button",
        accessibilityLabel: t("generateTheReport"),
        text: t("generateTheReport"),
        onPress: handlePress,
      },
    ],
    [handlePress, t],
  );

  return (
    <>
      <HideWhenKeyboard>
        <ActionFooter
          actions={actions}
          backgroundColor={ColorStyle.BACKGROUND_LIGHT}
        />
      </HideWhenKeyboard>
      <SlidePanel
        name="RoomReport"
        portalHostname={portalHostname}
        title={t("generateReportTitle")}
        open={openedPanel === panelIdentifier}
        closeIcon="x-close"
        closeIconId="close-report-generator"
        onClose={closePanel}
        direction="up"
      >
        <ReportGenerator
          roomId={roomId}
          close={closePanel}
          portalHostname={portalHostname}
        />
      </SlidePanel>
    </>
  );
};

export { RoomReportSection };
