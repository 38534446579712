import { StyleSheet, View } from "react-native";

import { ColorStyle, Icon, Spacing } from "@kraaft/ui";

interface Props {
  isSelected: boolean | "partial";
}

export const ModularFolderChecker = ({ isSelected }: Props) => {
  const color =
    isSelected === false ? ColorStyle.FONT_LOW_EMPHASIS : ColorStyle.ACTION_CTA;
  const iconName =
    isSelected === "partial"
      ? "checkbox-partial-fill"
      : isSelected
        ? "checkbox-on-fill"
        : "checkbox-off";

  return (
    <View style={styles.leftIconButton}>
      <Icon name={iconName} size="LARGE" color={color} />
    </View>
  );
};

const styles = StyleSheet.create({
  leftIconButton: {
    paddingHorizontal: Spacing.S8,
    justifyContent: "center",
    alignItems: "center",
  },
});
