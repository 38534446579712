import {
  Dispatch,
  memo,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { HoverState } from "@kraaft/shared/core/modules/schema/schema.optimistic";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { useNextFrameValue } from "@kraaft/shared/core/utils/useNextFrameValue";
import { DraggableLineDecoration } from "@kraaft/web/src/components/legacyDraggableLineDecoration";
import {
  ColumnDropItem,
  FormBuilderDropTypes,
  InsertElementDropItem,
  isItemSection,
  ReorderColumnDropItem,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";

import { useStyles } from "./elementDrag.styles";

const ITEM_HEIGHT = 56;

export interface ElementDragProps {
  parentKey: string;
  elementKey: string;
  isSection: boolean;
  children: ReactNode;
  preventDrag?: boolean;
  setCurrentHoverKey: Dispatch<SetStateAction<HoverState | undefined>>;
  transition?: "top" | "bottom";
  onDrag: (key: string) => void;
  onEnd: (
    item: ReorderColumnDropItem | undefined,
    droppedOn: string | undefined,
  ) => void;
  isAboveThreshold: number;
  highlightHover: boolean;
  lockOnEnd: MutableRefObject<boolean>;
}

const ElementDrag_ = ({
  children,
  elementKey,
  preventDrag,
  setCurrentHoverKey,
  transition,
  onDrag,
  onEnd,
  isAboveThreshold,
  highlightHover,
  lockOnEnd,
  parentKey,
  isSection,
}: ElementDragProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [{ isDragging, isHover }, drop] = useDrop({
    accept: [FormBuilderDropTypes.REORDER, FormBuilderDropTypes.INSERT],
    // eslint-disable-next-line complexity
    hover: (item: ReorderColumnDropItem | InsertElementDropItem, monitor) => {
      if (!monitor.isOver({ shallow: true })) {
        return;
      }
      const dragSource = monitor.getClientOffset();
      const itemDraggedY = dragSource?.y ?? 0;

      const thisRect = containerRef.current?.getBoundingClientRect();
      const thisHeight = thisRect?.height ?? 0;
      const thisY = thisRect?.y ?? 0;

      const computedThisAboveDragged =
        thisY + thisHeight * isAboveThreshold < itemDraggedY;
      let newPlacement: HoverState | undefined;
      if (computedThisAboveDragged) {
        newPlacement = {
          key: elementKey,
          placement: "after",
        };
      } else if (!preventDrag) {
        newPlacement = {
          key: elementKey,
          placement: "before",
        };
      }
      if (parentKey !== KSchemaUtils.rootSectionKey && isItemSection(item)) {
        return;
      }
      setCurrentHoverKey((oldValue) => {
        if (
          oldValue?.key !== newPlacement?.key ||
          oldValue?.placement !== newPlacement?.placement
        ) {
          return newPlacement;
        }
        return oldValue;
      });
    },
    canDrop: (item: ColumnDropItem) => isItemSection(item) !== isSection,
    drop: (item) => {
      lockOnEnd.current = true;
      if (item) {
        onEnd(item as ReorderColumnDropItem, elementKey);
      }
    },
    collect: (monitor) => ({
      isDragging: Object.values(FormBuilderDropTypes).includes(
        monitor.getItem()?.type,
      ),
      isHover: monitor.isOver() && monitor.canDrop(),
    }),
  });

  const [{ isBeingDragged }, drag, preview] = useDrag({
    canDrag: !preventDrag,
    type: FormBuilderDropTypes.REORDER,
    end: (item) => {
      if (!lockOnEnd.current) {
        onEnd(item, undefined);
      }
      lockOnEnd.current = false;
    },
    item: () => {
      setTimeout(() => onDrag(elementKey), 0);
      return {
        type: FormBuilderDropTypes.REORDER,
        elementKey,
        parentKey,
        elementType: isSection ? "section" : "column",
      } as ReorderColumnDropItem;
    },
    collect: (monitor) => {
      const item = monitor.getItem();
      return {
        isBeingDragged:
          item?.type === FormBuilderDropTypes.REORDER &&
          item?.elementKey === elementKey,
      };
    },
  });

  const isBeingDraggedNextFrame = useNextFrameValue(isBeingDragged);

  const styles = useStyles({
    isBeingDragged,
    isBeingDraggedNextFrame,
    isDragging,
    transition,
    isHover: isHover && highlightHover,
  });

  useLayoutEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, [preview]);

  drop(containerRef);

  const contentWrapperStyles = useMemo(
    () => ({
      height: isBeingDraggedNextFrame ? ITEM_HEIGHT : undefined,
    }),
    [isBeingDraggedNextFrame],
  );

  return (
    <DraggableLineDecoration
      handleRef={drag}
      ref={containerRef}
      className={styles.root}
      preventDrag={preventDrag}
    >
      <div style={contentWrapperStyles}>{children}</div>
    </DraggableLineDecoration>
  );
};

export const ElementDrag = memo(ElementDrag_) as typeof ElementDrag_;
