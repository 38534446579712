import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  content: {
    flex: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(100vh - 300px)", // @HACK, limit the size of content, otherwise it is scrollable
    minHeight: 200,
    maxWidth: "100%",
    overflow: "auto",
    gap: Spacing.S8,
  },
  intro: {
    whiteSpace: "pre-line",
    marginBottom: Spacing.S16,
  },

  errorText: {
    fontSize: FontSize.SMALL,
    color: ColorStyle.ACTION_DESCTRUCTIVE,
    maxHeight: 100,
    overflowY: "auto",
    flexShrink: 0,
    marginTop: Spacing.S8,
  },
});

export const inlineStyles = {
  list: {
    flexGrow: 1,
  },
};
