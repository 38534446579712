import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  value: {
    fontSize: FontSize.MEDIUM,
    textOverflow: "ellipsis",
  },
  valueEmpty: {
    fontSize: FontSize.MEDIUM,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  emptyList: {
    display: "flex",
    flex: 1,
    fontFamily: "Apercu",
    fontWeight: 500,
    fontSize: FontSize.MEDIUM,
    color: ColorStyle.FONT_LOW_EMPHASIS,
    textAlign: "center",
    padding: Spacing.S8,
  },
});
