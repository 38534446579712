import { View } from "react-native";

import { Tooltip } from "@kraaft/shared/components/tooltip";
import { Icon, IconName } from "@kraaft/ui";

import { styles } from "../modularPill.styles";

const WithIconContent = ({
  children,
  iconName,
  iconColor,
  tooltipTitle = "",
}: {
  children: React.ReactNode;
  iconName?: IconName;
  iconColor?: string;
  tooltipTitle?: string;
}) => (
  <View style={styles.withIconContainer}>
    {iconName ? (
      <Tooltip title={tooltipTitle} placement="left" arrow>
        <View style={styles.withIconContainerIcon}>
          <Icon
            name={iconName}
            size="MINI"
            color={iconColor ?? "FONT_HIGH_EMPHASIS"}
          />
        </View>
      </Tooltip>
    ) : null}
    <View style={styles.withIconContainerContent}>{children}</View>
  </View>
);

export { WithIconContent };
