import { useSelector } from "react-redux";

import { ModularFolderDetails } from "@kraaft/shared/components/modularFolders/modularFolderDetails";
import { selectModularFolderSchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ModularFolderUseMapNavigationReturnType } from "@kraaft/shared/core/utils/useMapNavigation/types";
interface ModularFolderMenuProps {
  mapNavigation: ModularFolderUseMapNavigationReturnType;
  portalHostname: string;
}

export const ModularFolderMenu = ({
  mapNavigation,
  portalHostname,
}: ModularFolderMenuProps) => {
  const { roomId, modularFolderId } = mapNavigation;

  const schemaId = useSelector(selectModularFolderSchemaId(modularFolderId));

  return (
    <ModularFolderDetails
      roomId={roomId}
      schemaId={schemaId ?? ""}
      modularFolderId={modularFolderId}
      portalHostname={portalHostname}
    />
  );
};
