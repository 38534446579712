import { StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { styleObject } from "@kraaft/shared/styles/panel.styles";
import {
  Color,
  ColorStyle,
  FontSize,
  PixelSize,
  Radius,
  Spacing,
} from "@kraaft/ui";

export const CENTER_HEADER_HEIGHT = 50;
export const MARKER_HEIGHT = 50;
export const MARKER_WIDTH = 37;

export const styles = StyleSheet.create({
  safeAreaHeader: {
    backgroundColor: Color.WHITE,
  },
  mapContainer: {
    flexGrow: 1,
    backgroundColor: Color.WHITE,
  },
  map: {
    flexGrow: 1,
  },
  footerContainer: {
    width: "100%",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  addressContainer: {
    paddingHorizontal: Spacing.S16,
    paddingTop: Spacing.S16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: PixelSize.S40 + Spacing.S16, // S40 text height + P16 paddingTop
  },
  addressLabel: {
    fontSize: FontSize.BODY,
    fontWeight: "500",
    color: Color.BLACK,
    flexShrink: 1,
  },
  buttonContainer: {
    paddingVertical: Spacing.S16,
    paddingHorizontal: Spacing.S16,
  },
  absoluteOverlay: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
  MarkerContainer: {
    zIndex: 2,
  },

  PAOverlayContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Color.WHITE,
    zIndex: 0,
  },
  PAOverlaySafeContainer: {
    backgroundColor: Color.WHITE,
    flex: 1,
  },
  PATextInputContainer: {
    height: isNative() ? 54 : 49,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingHorizontal: Spacing.S16,
    ...styleObject.borderBottom,
  },
  PATextInput: {
    fontFamily: "Apercu",
    fontSize: FontSize.BODY,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: Radius.SMALL,
    marginBottom: 0,
    height: PixelSize.S32,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  PARightButtonStyle: {
    position: "absolute",
    right: PixelSize.S16,
  },
  PASeparator: {
    backgroundColor: ColorStyle.SEPARATOR,
    width: "85%",
    right: "-10%",
  },
  PADescription: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  footerButtonsContainer: {
    gap: 8,
  },
});
