import React, { useEffect, useMemo, useRef, useState } from "react";
import { InputBase } from "@mui/material";
import isEqual from "fast-deep-equal";

import { DateTimePicker } from "@kraaft/shared/components/dateTimePicker";
import { formatDateCell } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KEditorProps } from "@kraaft/web/src/components/modularTable/components/types";

import { useStyles } from "./dateEditor.styles";

const DateEditor_ = (props: KEditorProps<KColumnType.date>) => {
  const classes = useStyles();
  const { column, row, onRowChange, onClose } = props;

  const defaultValue = row.properties[column.key]?.value;

  const ref = useRef<HTMLElement>(null);

  const onChange = (value: Date | undefined) => {
    setDate(value);
    onRowChange(
      {
        ...row,
        properties: {
          ...row.properties,
          [column.key]: {
            columnType: column.type,
            value,
          } as KSchemaColumnValue,
        },
      },
      true,
    );
  };

  useEffect(() => {
    if (ref.current) {
      setOpen(true);
    }
  }, [ref]);

  const [isOpen, setOpen] = useState(false);
  const [date, setDate] = useState<Date | undefined>(defaultValue);

  const pickerValue = useMemo(() => date || new Date(), [date]);
  const inputValue = formatDateCell(date, column.displayTime);

  return (
    <>
      <InputBase
        value={inputValue}
        fullWidth
        contentEditable={false}
        inputProps={{
          ref,
        }}
        classes={{ root: classes.root, input: classes.input }}
        disabled
        className={classes.root}
      />
      <DateTimePicker
        displayTime={column.displayTime}
        visible={isOpen}
        date={pickerValue}
        onClose={onClose}
        onChange={onChange}
        anchorRef={ref}
      />
    </>
  );
};

export const DateEditor = React.memo(DateEditor_, isEqual);
