import { KeyboardEventHandler, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ColumnHeaderEditorProps } from "@kraaft/web/src/components/modularTable/components/types";
import { UnlabeledTextInput } from "@kraaft/web/src/components/unlabeledTextInput";

import { useStyles } from "./numberColumnEditor.styles";

const NumberColumnEditor = (
  props: ColumnHeaderEditorProps<KColumnType.number>,
) => {
  const { columnDefinition, setColumnDefinition } = props;

  const { t } = useTranslation();
  const styles = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const changeText = useCallback(
    (text: string) => {
      setColumnDefinition({
        ...columnDefinition,
        type: KColumnType.number,
        symbol: text,
      });
    },
    [columnDefinition, setColumnDefinition],
  );

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter") {
        inputRef.current?.blur();
      }
    },
    [],
  );

  return (
    <div className={styles.container}>
      <UnlabeledTextInput
        ref={inputRef}
        id="create-number-unity"
        onChangeText={changeText}
        placeholder={t("unity")}
        defaultValue={columnDefinition?.symbol}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export { NumberColumnEditor };
