import { matchPath } from "react-router-dom";
import { History } from "history";
import qs from "qs";

import {
  ScreenKey,
  ScreenParams,
} from "@kraaft/shared/core/services/navigation/navigationParams";
import { KeysOfUnion } from "@kraaft/shared/core/typeUtils";
import { LibrarySchemaBuilderTabs } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder/librarySchemaBuilderTabs";
import { PoolSchemaBuilderTabs } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderTabs";
import { getCurrentRoute } from "@kraaft/web/src/core/services/navigation/getCurrentRoute";
import {
  geoDetailQuery,
  queries,
  routes,
  viewModeQuery,
} from "@kraaft/web/src/views/app/appRouter/routes";

class RouteService<BaseRouteName extends ScreenKey> {
  constructor(
    private readonly navigation: History<ScreenParams<BaseRouteName>>,
  ) {}

  getParams() {
    return (this.navigation.location.state ??
      {}) as ScreenParams<BaseRouteName>;
  }

  getParam<ParamKey extends KeysOfUnion<ScreenParams<BaseRouteName>>>(
    param: ParamKey,
  ): ScreenParams<BaseRouteName>[ParamKey];
  getParam<ParamKey extends KeysOfUnion<ScreenParams<BaseRouteName>>>(
    param: ParamKey,
    fallback: NonNullable<ScreenParams<BaseRouteName>[ParamKey]>,
  ): NonNullable<ScreenParams<BaseRouteName>[ParamKey]>;
  getParam<ParamKey extends KeysOfUnion<ScreenParams<BaseRouteName>>>(
    param: ParamKey,
    fallback?: NonNullable<ScreenParams<BaseRouteName>[ParamKey]>,
  ) {
    const value = this.getParams()[param];
    if (fallback !== undefined) {
      return value ?? fallback;
    }
    return value;
  }

  getQueryString(): ReturnType<typeof qs.parse> {
    return qs.parse(this.navigation.location.search.slice(1));
  }

  isViewingConversationFolder(): boolean {
    return getCurrentRoute(this.navigation) === "ConversationFolder";
  }

  isViewingConversationInfo(): boolean {
    return getCurrentRoute(this.navigation) === "ConversationInfo";
  }

  getEditSchemaCurrentTab(): PoolSchemaBuilderTabs {
    return (
      (this.getQueryString()[queries.tab] as
        | PoolSchemaBuilderTabs
        | undefined) ?? PoolSchemaBuilderTabs.schema
    );
  }

  getEditLibrarySchemaCurrentTab(): LibrarySchemaBuilderTabs {
    return (
      (this.getQueryString()[queries.tab] as
        | LibrarySchemaBuilderTabs
        | undefined) ?? LibrarySchemaBuilderTabs.schema
    );
  }

  isDrawerOpen(): boolean {
    return (
      this.isViewingConversationFolder() || this.isViewingConversationInfo()
    );
  }

  isMapOpen(): boolean {
    return (
      this.getQueryString()[queries.viewMode] === viewModeQuery.geolocation
    );
  }

  isRoomsMapOpen(): boolean {
    return (
      this.isMapOpen() &&
      this.getQueryString()[queries.geoDetail] === geoDetailQuery.rooms
    );
  }

  getCurrentRoomId() {
    const matchingRoom = matchPath(
      this.navigation.location.pathname,
      routes.Room,
    );
    if (!matchingRoom) {
      return undefined;
    }
    return (matchingRoom.params as ScreenParams<"Conversation">).roomId;
  }
}

export { RouteService };
