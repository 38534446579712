import { useMemo } from "react";
import clsx from "clsx";

import { ModularPill } from "@kraaft/shared/components/modular/pill";
import { ExtraContextForPillRenderer } from "@kraaft/shared/components/modular/pill/utils/types";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordWithIdAndTitle } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { filterNullableChildren } from "@kraaft/shared/core/utils/filterNullableChildren";

import { useStyles } from "./kanbanCard.styles";

export interface KanbanCardPillsProps {
  schema: KSchema;
  modularRecord: ModularRecordWithIdAndTitle;
  displayProperties: string[];
  poolMembers: Record<string, User>;
  currencyCode: string;
}

const KanbanCardPills = (props: KanbanCardPillsProps) => {
  const {
    schema,
    modularRecord,
    displayProperties,
    poolMembers,
    currencyCode,
  } = props;
  const classes = useStyles();

  const extraContext = useMemo<ExtraContextForPillRenderer>(
    () => ({ poolMembers, currencyCode }),
    [currencyCode, poolMembers],
  );

  const pillsWithoutNullableContent = filterNullableChildren(
    ...displayProperties.map((columnKey) => {
      if (!schema) {
        return null;
      }
      const schemaColumn = KSchemaUtils.findColumn(
        schema.rootSection,
        columnKey,
      );
      const property = modularRecord?.properties[columnKey];

      if (schemaColumn && property !== undefined) {
        return (
          <ModularPill
            schemaColumn={schemaColumn}
            modularRecordProperty={property}
            extraContext={extraContext}
          />
        );
      }
      return null;
    }),
  );

  const pills = pillsWithoutNullableContent?.map((component, index) => {
    const isNotLast = index < pillsWithoutNullableContent.length - 1;

    return (
      <span
        // We need to update when order change because of isNotLast
        // eslint-disable-next-line react/no-array-index-key
        // biome-ignore lint :
        key={index}
        className={clsx(
          classes.pillContainer,
          isNotLast && classes.pillVerticalSpacer,
        )}
      >
        {component}
      </span>
    );
  });

  return <div className={classes.pillsContainer}>{pills}</div>;
};

export { KanbanCardPills };
