import { LoaderCreatingPool } from "@kraaft/shared/components/onboarding/loaderCreatingPool";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";

const LoaderCreatingPoolWeb = () => {
  return (
    <SplitScreenWrapper>
      <LoaderCreatingPool hasInvited={false} />
    </SplitScreenWrapper>
  );
};

export { LoaderCreatingPoolWeb };
