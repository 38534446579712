import { CurrentUser, User } from "@kraaft/shared/core/modules/user/userState";

export function buildUserAcronym(
  responsibleUser: User | CurrentUser | null | undefined,
) {
  if (!responsibleUser) {
    return null;
  }

  let firstName = responsibleUser.firstName;
  let lastName = responsibleUser.lastName;

  if (!firstName && !lastName) {
    if (!responsibleUser.username) {
      return null;
    }

    const regex = /([a-zA-Z]+)(?:[\s|_]+([a-zA-Z]+))?/;

    const matches = responsibleUser.username.match(regex);

    if (!matches) {
      return null;
    }

    firstName = matches[1];
    lastName = matches[2];
  }

  if (!lastName) {
    if (!firstName) {
      return null;
    }
    return firstName.substring(0, 3).toUpperCase();
  }

  if (!firstName) {
    return null;
  }

  return `${firstName[0]}${lastName.substring(0, 2)}`.toUpperCase();
}

export function buildPoolAcronym(poolName: string) {
  const regex = /([a-zA-Z0-9]+)(?:[\s_-]+([a-zA-Z0-9]+))?/;

  const matches = poolName.match(regex);

  if (!matches || !matches[1]) {
    return "";
  }

  const firstName = matches[1];
  const lastName = matches[2];

  if (!lastName) {
    return (firstName[0] ?? "").toUpperCase();
  }

  return `${firstName[0]}${lastName[0]}`.toUpperCase();
}
