import { useMeshContext } from "@kraaft/helper-hooks";
import { FormPreview } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/formPreview/formPreview";
import { SchemaBuilderContext } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

interface ContextAwareFormPreviewProps {
  currentUserId: string;
}

export const ContextAwareFormPreview = ({
  currentUserId,
}: ContextAwareFormPreviewProps) => {
  const { schema } = useMeshContext(SchemaBuilderContext);

  return <FormPreview currentUserId={currentUserId} schema={schema} />;
};
