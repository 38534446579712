import { createReducer } from "@reduxjs/toolkit";
import { orderBy } from "lodash";

import { CarouselStateActions } from "@kraaft/shared/core/modules/carousel/carousel.actions";
import { MiniMediaState } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";

import { MiniMediaStateActions } from "./miniMedia.actions";

const initialState: MiniMediaState = {
  medias: {},
  carousel: {
    show: false,
  },
  search: "",
};

export const miniMediaReducers = createReducer(initialState, (builder) => {
  const { addCase } = builder;

  addCase(MiniMediaStateActions.set, (state, { payload }) => {
    state.medias[payload.roomId] = {
      list: orderBy(payload.medias, (m) => m.createdAt.getTime(), "desc"),
      isLimitedResult: payload.isLimitedResult,
    };
  });

  addCase(MiniMediaStateActions.setSearchText, (state, { payload }) => {
    state.search = payload.search;
  });

  addCase(MiniMediaStateActions.openMiniMediaCarousel, (state, { payload }) => {
    state.carousel = {
      show: true,
      roomId: payload.roomId,
      messageId: payload.messageId,
      mediaIds: payload.mediaIds,
    };
  });

  addCase(MiniMediaStateActions.closeMiniMediaCarousel, (state) => {
    state.carousel = {
      show: false,
    };
  });

  addCase(CarouselStateActions.close, (state) => {
    state.carousel = {
      show: false,
    };
  });
});
