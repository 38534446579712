import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import compact from "lodash/compact";

import { isNative } from "@kraaft/helper-functions";
import {
  ActionFooter,
  FooterActions,
} from "@kraaft/shared/components/actionFooter/actionFooter";
import { FillDirectoryFromDocumentGalleryFlow } from "@kraaft/shared/components/directory/fillDirectoryFromDocumentGalleryFlow";
import { FillDirectoryFromPhotoGalleryFlow } from "@kraaft/shared/components/directory/fillDirectoryFromPhotoGalleryFlow";
import {
  ActionSheetItem,
  LegacyActionSheet,
} from "@kraaft/shared/components/legacyActionSheet";
import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import { conditionalEntry } from "@kraaft/shared/core/utils";
import { ColorStyle } from "@kraaft/ui";

const DirectoryContentAddFooter = ({
  onAddDirectory,
  onOpenFilePicker,
  onTakePhoto,
  directory,
}: {
  onAddDirectory: () => void;
  onOpenFilePicker: () => void;
  onTakePhoto: () => void;
  directory: Directory;
}) => {
  const { t } = useTranslation();

  const addButtonRef = useRef(null);

  const actions = useMemo<FooterActions>(
    () => [
      {
        accessibilityLabel: t("add"),
        text: t("add"),
        onPress: () => {
          setIsSheetOpen(true);
        },
        ref: addButtonRef,
      },
    ],
    [t],
  );

  const [dialog, setDialog] = useState<
    "none" | "photoGallery" | "documentGallery"
  >();
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const sheetOptions: ActionSheetItem[] = useMemo(
    () =>
      compact([
        conditionalEntry(
          {
            label: t("directoryActions.takePhoto"),
            onPress: onTakePhoto,
            icon: "camera-plus",
          },
          isNative(),
        ),
        {
          label: t("directoryActions.addFromPhotoGallery"),
          onPress: () => {
            setDialog("photoGallery");
          },
          icon: "image-01",
        },
        {
          label: t("directoryActions.addFromDocumentGallery"),
          onPress: () => {
            setDialog("documentGallery");
          },
          icon: "file-06",
        },
        {
          label: t("directoryActions.addFromComputer"),
          onPress: onOpenFilePicker,
          icon: "upload-01",
        },
        {
          label: t("directoryActions.new"),
          onPress: onAddDirectory,
          icon: "folder-plus",
        },
      ]),
    [onAddDirectory, onOpenFilePicker, onTakePhoto, t],
  );

  const closeDialogs = useCallback(() => setDialog("none"), []);

  const closeActionSheet = useCallback(() => setIsSheetOpen(false), []);

  return (
    <View style={styles.footerContainer}>
      <ActionFooter
        actions={actions}
        backgroundColor={ColorStyle.BACKGROUND_LIGHT}
      />
      <LegacyActionSheet
        anchorRef={addButtonRef}
        open={isSheetOpen}
        options={sheetOptions}
        onClose={closeActionSheet}
      />
      <FillDirectoryFromPhotoGalleryFlow
        directory={directory}
        open={dialog === "photoGallery"}
        onClose={closeDialogs}
      />
      <FillDirectoryFromDocumentGalleryFlow
        directory={directory}
        open={dialog === "documentGallery"}
        onClose={closeDialogs}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  footerContainer: {
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
  },
});

export { DirectoryContentAddFooter };
