import { createContext, useCallback, useMemo, useState } from "react";

import { AnimationState as BaseAnimationState } from "@kraaft/shared/core/types";

type AnimationState = "undetermined" | BaseAnimationState;

// Hook
type AnimationStateChangeHandler = (state: BaseAnimationState) => void;

type AnimationCallbackProps = {
  onEntering: () => void;
  onEntered: () => void;
  onExiting: () => void;
  onExited: () => void;
};

interface UseAnimationContextProps {
  defaultAnimationState: BaseAnimationState;
}

const useAnimationContext = ({
  defaultAnimationState,
}: UseAnimationContextProps): [
  AnimationContextProps,
  AnimationCallbackProps,
  AnimationStateChangeHandler,
] => {
  const [animationState, setAnimationState] = useState<AnimationState>(
    defaultAnimationState,
  );

  const animationContextProps: AnimationContextProps = useMemo(
    () => ({ animationState }),
    [animationState],
  );

  const animationStateChangeHandler: AnimationStateChangeHandler = useCallback(
    (state) => setAnimationState(state),
    [],
  );

  const animationCallbackProps = useMemo(
    () => ({
      onEntering: () => {
        animationStateChangeHandler("entering");
      },

      onEntered: () => {
        animationStateChangeHandler("entered");
      },

      onExiting: () => {
        animationStateChangeHandler("exiting");
      },

      onExited: () => {
        animationStateChangeHandler("exited");
      },
    }),
    [animationStateChangeHandler],
  );

  return [
    animationContextProps,
    animationCallbackProps,
    animationStateChangeHandler,
  ];
};

// Context
interface AnimationContextProps {
  animationState: AnimationState;
}

const AnimationContext = createContext<AnimationContextProps>({
  animationState: "undetermined",
});

export { AnimationContext, useAnimationContext };
