import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  board: {
    position: "relative",
    flexGrow: 1,
    flexBasis: 0,
    backgroundColor: Color.WHITE,

    overflow: "auto",
  },
  boardContainer: {
    display: "inline-flex",
    flexDirection: "row",
    minHeight: "100%",
  },

  footerSeparator: {
    marginTop: Spacing.S8,
    height: 2,
    backgroundImage: `linear-gradient(to right, ${Color.GREY_FRENCH} 60%, #00000000 0%)`,
    backgroundSize: "25px 2px",
    backgroundRepeat: "repeat-x",
  },
  footerContainer: {
    position: "sticky",
    left: 0,

    display: "flex",
    flexDirection: "column",
  },
  footerLabel: {
    margin: Spacing.S16,

    textAlign: "center",
    fontSize: FontSize.MEDIUM,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  columnContent: {
    display: "flex",
    flexDirection: "column",
    padding: Spacing.S8,
  },
  cardSpacer: {
    marginBottom: Spacing.S8,
  },
});
