import { GeoLocation } from "@kraaft/shared/core/types";
import { GoogleGeocoding } from "@kraaft/shared/core/utils/google.geocoding";
import { LocationIQGeocoding } from "@kraaft/shared/core/utils/locationIQ.geocoding";

export class Geocoding {
  static getGeolocationFromAddress(address: string) {
    return GoogleGeocoding.getGeolocationFromAddress(address);
  }

  static getGeolocationFromCoordinates(
    coords: {
      latitude: number;
      longitude: number;
    },
    language: string,
  ): Promise<GeoLocation> {
    return LocationIQGeocoding.getGeolocationFromCoordinates(coords, language);
  }
}
