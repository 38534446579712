import { useTranslation } from "react-i18next";

import { Text } from "@kraaft/ui";

import { useStyles } from "../poolSchemaBuilderHeaderName.styles";

const PoolSchemaBuilderHeaderRoomName = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Text size="H1">{t("formBuilder.roomSchemaHeader")}</Text>
    </div>
  );
};

export { PoolSchemaBuilderHeaderRoomName };
