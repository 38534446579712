import { useCallback, useEffect, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { SearchConversationContextProvider } from "@kraaft/shared/components/conversation/searchConversationContext";
import { SearchPanel } from "@kraaft/shared/components/conversation/searchPanel";
import { AttachMessageSelectionFlow } from "@kraaft/shared/components/galleries/messageActionFlow/attachFlow";
import { ForwardMessageSelectionFlow } from "@kraaft/shared/components/galleries/messageActionFlow/forwardFlow";
import {
  MessageListContext,
  useMessageListContext,
} from "@kraaft/shared/components/replyPreview/replyHooks";
import { setMessageSelectionProperties } from "@kraaft/shared/core/modules/message/messageActions";
import { selectMessageSelectionCount } from "@kraaft/shared/core/modules/message/messageSelectors";
import { focusRoom } from "@kraaft/shared/core/modules/room/roomActions";
import { useSubmitSelection } from "@kraaft/shared/core/utils/useSubmitSelection";
import { PortalHost } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { ConversationFooter } from "@kraaft/web/src/views/messenger/conversationFooter";
import { ConversationsDisplayer } from "@kraaft/web/src/views/messenger/conversationsDisplayer/conversationsDisplayer";
import { ConversationHeader } from "@kraaft/web/src/views/messenger/header/conversationHeader";

interface Props {
  roomId: string;
  isDetailsDrawerOpen: boolean;
  onCloseSelection: () => void;
  onDetailsIconClick: () => void;
}

export const ConversationPanel = ({
  roomId,
  isDetailsDrawerOpen,
  onCloseSelection,
  onDetailsIconClick,
}: Props) => {
  const dispatch = useDispatch();
  const selectionCount = useSelector(
    selectMessageSelectionCount(roomId, "conversation"),
  );
  const hasSelection = selectionCount > 0;

  const handleSubmitSelection = useSubmitSelection(roomId, "conversation");

  useEffect(() => {
    dispatch(focusRoom({ roomId }));
  }, [dispatch, roomId]);

  const handleAttachSelectionClose = useCallback(() => {
    dispatch(
      setMessageSelectionProperties({
        roomId,
        selectionType: "attach",
        status: undefined,
      }),
    );
  }, [dispatch, roomId]);

  const handleForwardSelectionClose = useCallback(() => {
    dispatch(
      setMessageSelectionProperties({
        roomId,
        selectionType: "forward",
        status: undefined,
      }),
    );
  }, [dispatch, roomId]);

  const content = useMemo(
    () => (
      <>
        <Box grow overflow="hidden">
          <ConversationHeader
            roomId={roomId}
            drawerOpen={isDetailsDrawerOpen}
            onDetailsClick={onDetailsIconClick}
          />
          <ConversationsDisplayer roomId={roomId} />
          <ConversationFooter
            roomId={roomId}
            hasSelection={hasSelection}
            selectionCount={selectionCount}
            onSubmitSelection={handleSubmitSelection}
            onCloseSelection={onCloseSelection}
          />
          <View style={StyleSheet.absoluteFill} pointerEvents="box-none">
            <PortalHost hostname="CONVERSATION" />
          </View>
        </Box>
        <AttachMessageSelectionFlow
          onClose={handleAttachSelectionClose}
          roomId={roomId}
        />
        <ForwardMessageSelectionFlow
          onClose={handleForwardSelectionClose}
          roomId={roomId}
        />
        <SearchPanel roomId={roomId} portalHostname="CONVERSATION" />
      </>
    ),
    [
      handleAttachSelectionClose,
      handleForwardSelectionClose,
      handleSubmitSelection,
      hasSelection,
      isDetailsDrawerOpen,
      onCloseSelection,
      onDetailsIconClick,
      roomId,
      selectionCount,
    ],
  );

  return (
    <SearchConversationContextProvider>
      <MessageListContext.Provider value={useMessageListContext()}>
        {content}
      </MessageListContext.Provider>
    </SearchConversationContextProvider>
  );
};
