import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { getFactoryElement } from "@kraaft/shared/core/utils/factoryUtils";
import {
  selectMultipleConfig,
  selectSingleConfig,
} from "@kraaft/web/src/components/modularTable/components/fields/select";
import { signatureConfig } from "@kraaft/web/src/components/modularTable/components/fields/signature";
import { IFieldConfig } from "@kraaft/web/src/components/modularTable/components/types";

import { attachmentConfig } from "./attachment";
import { checkboxConfig } from "./checkbox";
import { currencyConfig } from "./currency";
import { automatedCreatedAtConfig, dateConfig } from "./date";
import { geolocationConfig } from "./geolocation";
import { longTextConfig } from "./longText";
import { numberConfig } from "./number";
import { roomNameConfig } from "./roomName";
import { automatedAutoIcrementConfig, shortTextConfig } from "./shortText";
import {
  automatedCreatedByConfig,
  roomMembersConfig,
  userConfig,
} from "./user";

const fieldDict: { [T in KColumnType]: IFieldConfig<T> | null } = {
  [KColumnType.attachment]: attachmentConfig,
  [KColumnType.checkbox]: checkboxConfig,
  [KColumnType.currency]: currencyConfig,
  [KColumnType.date]: dateConfig,
  [KColumnType.geolocation]: geolocationConfig,
  [KColumnType.longText]: longTextConfig,
  [KColumnType.number]: numberConfig,
  [KColumnType.shortText]: shortTextConfig,
  [KColumnType.automatedAutoIncrement]: automatedAutoIcrementConfig,
  [KColumnType.user]: userConfig,
  [KColumnType.roomMembers]: roomMembersConfig,
  [KColumnType.roomName]: roomNameConfig,
  [KColumnType.selectMultiple]: selectMultipleConfig,
  [KColumnType.selectSingle]: selectSingleConfig,
  [KColumnType.automatedCreatedAt]: automatedCreatedAtConfig,
  [KColumnType.automatedCreatedBy]: automatedCreatedByConfig,
  [KColumnType.signature]: signatureConfig,
  [KColumnType.join]: null,
};

/**
 * Returns specific property of field config
 * @param fieldType
 */
export const getFieldProp = <
  T extends KColumnType,
  P extends keyof IFieldConfig<T>,
>(
  prop: P,
  fieldType: T,
) => {
  const field: IFieldConfig<T> = getFactoryElement(fieldDict, fieldType);
  return field?.[prop];
};

/**
 * Returns `true` if it receives an existing fieldType
 * @param fieldType
 */
export const isFieldType = <T extends KColumnType>(fieldType: T) => {
  return fieldType in fieldDict;
};
