import { nanoid } from "@kraaft/helper-functions";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { ImageAttachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { createAttachmentFromModernFile } from "@kraaft/shared/core/modules/modularFolder/modularFolderUtils";
import { incrementPendingOperations } from "@kraaft/shared/core/modules/modularFolder/operations/modularFolder.offline.tools";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Api } from "@kraaft/shared/core/services/api";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

export const addSignatureOperation = Operation.editOne<ModularFolder>()
  .payload<{
    columnKey: string;
    file: ModernFile<LocalPath>;
  }>()
  .dependsOn<{ getCurrentUserId(): string }>()
  .augment((payload, { getCurrentUserId }) => ({
    signatureId: nanoid(),
    userId: getCurrentUserId(),
    at: new Date().toISOString(),
  }))
  .expected((modularFolder, payload) => {
    incrementPendingOperations(modularFolder);
    const attachment = createAttachmentFromModernFile(
      payload.signatureId ?? "",
      payload.userId ?? "",
      payload.file,
    ) as ImageAttachment;
    modularFolder.properties[payload.columnKey] = {
      columnType: KColumnType.signature,
      value: attachment,
      updatedAt: payload.at ? new Date(payload.at) : new Date(),
      updatedBy: payload.userId ?? undefined,
    };
  })
  .mutate((payload) =>
    Api.addSignatureToModularFolder({
      folderId: payload.id,
      columnKey: payload.columnKey,
      file: payload.file,
    }),
  );
