import { put, select } from "typed-redux-saga/macro";

import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";

export function* addCreatedRoomLocationSaga(
  action: ReturnType<typeof roomActions.RoomActions.addCreatedRoomLocation>,
) {
  const { roomId, geolocation } = action.payload;
  const room = yield* select(selectRoom(roomId));
  if (room === undefined) {
    return;
  }
  const roomSchema = yield* select(selectRoomSchema(room.poolId));
  if (roomSchema === undefined) {
    return;
  }

  const firstGeolocationColumn = KSchemaUtils.getFirstColumnOfType(
    roomSchema,
    KColumnType.geolocation,
  );
  if (firstGeolocationColumn === undefined) {
    console.log("Schema has no geolocation column");
    return;
  }

  const updatedProperties = KSchemaConversion.toRawProperties({
    [firstGeolocationColumn.key]: {
      columnType: firstGeolocationColumn.type,
      value: geolocation,
    },
  });

  yield* put(
    roomActions.updateRoomRecord({ roomId, update: updatedProperties }),
  );
}
