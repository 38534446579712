import { StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { styleObject } from "@kraaft/shared/styles/panel.styles";
import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
  },
  scrollviewContainer: {
    ...(isNative() ? { flex: 1 } : { height: 0 }), // This should be `flex: 1` but scroll does not work on web
  },
  innerSectionContent: {
    gap: Spacing.S8,
  },
  text: {
    fontFamily: "Apercu",
    fontWeight: "400",
    fontSize: FontSize.BODY,
  },
  textDescription: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  textCentered: {
    textAlign: "center",
  },
  separator: {
    marginLeft: 44,
    ...styleObject.borderBottom,
  },
  selectionList: {
    marginBottom: 12,
    backgroundColor: Color.WHITE,
  },
  email: {
    backgroundColor: Color.WHITE,
    height: 55,
  },
  bottomButtonContainer: {
    backgroundColor: Color.WHITE,
    padding: Spacing.S16,
  },
  safeAreaView: {
    backgroundColor: Color.WHITE,
  },
  dateContainer: {
    display: "flex",
    minHeight: 50,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dateLeft: { flex: 1, height: "100%", marginRight: 5 },
  dateRight: { flex: 1, marginLeft: 5 },
  selection: { paddingVertical: Spacing.S16 },
  spaceBelow: { paddingBottom: Spacing.S16 },

  spacer: { flex: 1 },
});
