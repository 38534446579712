import { nanoid } from "@kraaft/helper-functions";
import {
  LocalPath,
  ModernFile,
  ModernFileHelper,
} from "@kraaft/shared/core/modules/file/file";
import { FileUploadHelper } from "@kraaft/shared/core/modules/file/fileUploadHelper";
import { createAttachmentFromModernFile } from "@kraaft/shared/core/modules/modularFolder/modularFolderUtils";
import {
  getModularFolderAttachmentValue,
  incrementPendingOperations,
} from "@kraaft/shared/core/modules/modularFolder/operations/modularFolder.offline.tools";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Api } from "@kraaft/shared/core/services/api";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

export const addAttachmentOperation = Operation.editOne<ModularFolder>()
  .payload<{
    roomId: string;
    columnKey: string | undefined;
    files: ModernFile<LocalPath>[];
  }>()
  .creates((payload) => payload.files.length)
  .dependsOn<{ getCurrentUserId(): string }>()
  .augment((payload, { getCurrentUserId }) => ({
    userId: getCurrentUserId(),
  }))
  .expected((modularFolder, payload) => {
    incrementPendingOperations(modularFolder);
    const propertyValue = getModularFolderAttachmentValue(
      modularFolder,
      payload.columnKey,
    );
    propertyValue?.push(
      ...payload.files.map((file, index) =>
        createAttachmentFromModernFile(
          payload.allocatedIds?.[index] ?? nanoid(),
          payload.userId ?? "",
          file,
        ),
      ),
    );
    return modularFolder;
  })
  .mutate(async (payload, task) => {
    const result = await FileUploadHelper.uploadThreeSteps(payload.files, {
      createUploadPaths(files) {
        console.log("Creating upload paths", files);
        return Api.createFolderUploadPaths({
          roomId: payload.roomId,
          filenames: files.map((file) => file.filename),
        });
      },
      async onceUploaded(files) {
        const data = await Api.addModularFolderAttachments({
          requestId: task.id,
          attachments: files.map(({ file, storagePath }) => {
            const fileCoordinates = ModernFileHelper.getCoordinates(file);
            const fileCaption = ModernFileHelper.getText(file);
            return {
              filename: file.filename,
              storagePath,
              text: fileCaption,
              coords: fileCoordinates,
            };
          }),
          columnKey: payload.columnKey,
          folderId: payload.id,
        });
        return data;
      },
    });
    return result;
  });
