import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { LegacyContextMenuButton } from "@kraaft/web/src/components/contextMenuButton";

interface SimplifiedTableContextMenuProps {
  onDelete: () => void;
  record: ModularRecord;
  lockingColumns: string[];
}

export const SimplifiedTableContextMenu = ({
  onDelete,
  record,
  lockingColumns,
}: SimplifiedTableContextMenuProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const hasAtLeastOneLockedColumn = useMemo(
    () =>
      ModularRecordUtils.hasAtLeastOneLockedColumn(
        record.properties,
        lockingColumns,
      ),
    [lockingColumns, record.properties],
  );

  if (hasAtLeastOneLockedColumn) {
    return null;
  }

  const actions: ActionSheetItem[] = [
    {
      label: t("delete"),
      style: "destructive",
      onPress: onDelete,
    },
  ];

  return (
    <div className={styles.contextMenuButtonContainer}>
      <LegacyContextMenuButton actions={actions} />
    </div>
  );
};

const useStyles = makeStyles({
  contextMenuButtonContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
});
