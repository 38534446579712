import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ActionCard } from "@kraaft/shared/components/actionCard";
import { selectMiniMediasCount } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";

interface DocumentGalleryActionCardProps {
  roomId: string;
}

export const DocumentGalleryActionCard = ({
  roomId,
}: DocumentGalleryActionCardProps) => {
  const { t } = useTranslation();
  const navigationService = useNavigationService();

  const totalMediaCount = useSelector(
    selectMiniMediasCount(roomId, ["document"]),
  );

  const handleOpen = useCallback(() => {
    navigationService.navigate("DocumentGallery", { roomId });
  }, [navigationService, roomId]);

  return (
    <ActionCard
      iconName="file-04"
      text={t("worksiteFolder.allDocuments")}
      description={`${totalMediaCount}`}
      action={handleOpen}
      showBracket
    />
  );
};
