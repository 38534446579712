import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import SuccessLottie from "@kraaft/shared/assets/success.json";
import { DetailedActionCard } from "@kraaft/shared/components/detailedActionCard";
import { LottieManager } from "@kraaft/shared/components/lottieManager";
import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { intercom } from "@kraaft/shared/core/modules/intercom/intercom.provider";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Color, ColorStyle, Text } from "@kraaft/ui";

import { styles } from "./welcomeOnKraaft.styles";

const WelcomeOnKraaft = () => {
  const { t } = useTranslation();
  const navigationService = useNavigationService();

  const handleJoinWorkspacePress = useCallback(() => {
    trackEvent({
      eventName: "Onboarding Clicked Uses Kraaft",
    });
    intercom
      .openArticle(getEnvironment().INTERCOM.ARTICLES.JOIN_A_WORKSPACE)
      .catch(console.error);
  }, []);

  const handleCreateWorkspacePress = useCallback(() => {
    trackEvent({
      eventName: "Onboarding Clicked First Kraaft User",
    });
    navigationService.navigate("CreateWorkspace");
  }, [navigationService]);

  return (
    <View style={styles.container}>
      <View style={styles.welcomeTitleContainer}>
        <LottieManager
          source={SuccessLottie}
          style={styles.lottieContainer}
          initialSegment={[0, 53]}
          loop={false}
        />
        <Text weight="bold" size="H1" style={styles.welcomeTitle}>
          {t("welcomeOnKraaft.title")}
        </Text>
        <Text size="BODY" color="FONT_LOW_EMPHASIS">
          {t("welcomeOnKraaft.almostThere")}
        </Text>
      </View>
      <View style={styles.cardsContainer}>
        <View style={styles.cardSpacer}>
          <DetailedActionCard
            icon="user-check-02"
            iconColor={Color.YELLOW_KRAAFT}
            title={t("welcomeOnKraaft.createWorkspace.title")}
            subtitle={t("welcomeOnKraaft.createWorkspace.subtitle")}
            onPress={handleCreateWorkspacePress}
          />
        </View>
        <DetailedActionCard
          icon="users-right"
          iconColor={ColorStyle.ACTION_CTA}
          title={t("welcomeOnKraaft.joinWorkspace.title")}
          subtitle={t("welcomeOnKraaft.joinWorkspace.subtitle")}
          onPress={handleJoinWorkspacePress}
        />
      </View>
    </View>
  );
};

export { WelcomeOnKraaft };
