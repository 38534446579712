import { createReducer } from "@reduxjs/toolkit";

import {
  UserActions,
  UserStateActions,
} from "@kraaft/shared/core/modules/user/userActions";
import {
  OnboardingState,
  UserState,
} from "@kraaft/shared/core/modules/user/userState";

const DEFAULT_ONBOARDING_STATE: OnboardingState = {
  shouldShowConversationOnboardingButton: false,
  shouldShowPoolOnboarding: false,
};

const initialState: UserState = {
  authState: "loading",
  isLoadingPoolUsers: false,
  poolUsers: {},
  users: {},
  blockedUserIds: {},
  onboarding: undefined,
  mayShowHomeEmptyState: false,
};

export const userReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userConnectedToFirebase, (state, { payload }) => {
    state.authState = "signedIn";
    state.userAuth = payload.userAuth;
  });

  addCase(UserActions.userDisconnectedFromFirebase, () => {
    return { ...initialState, authState: "signedOut" };
  });

  addCase(
    UserStateActions.setLoadingPoolUsers,
    (state, { payload: { isLoading } }) => {
      state.isLoadingPoolUsers = isLoading;
    },
  );

  addCase(
    UserStateActions.poolUsersUpdated,
    (state, { payload: { poolId, users } }) => {
      state.isLoadingPoolUsers = false;
      state.poolUsers[poolId] = users;
      state.users = { ...state.users, ...users };
    },
  );

  addCase(UserStateActions.addUnknownUsers, (state, { payload }) => {
    for (const [id, value] of Object.entries(payload)) {
      state.users[id] = value;
    }
  });

  addCase(UserStateActions.loggedUserReceived, (state, { payload }) => {
    state.currentUser = payload;
  });

  addCase(
    UserStateActions.setCurrentUserOnboardingState,
    (state, { payload }) => {
      if (payload !== null) {
        state.onboarding = {
          fetched: true,
          state: payload,
        };
      } else {
        state.onboarding = {
          fetched: false,
          state: DEFAULT_ONBOARDING_STATE,
        };
      }
    },
  );

  addCase(
    UserStateActions.updateCurrentUserOnboardingState,
    (state, { payload }) => {
      if (state.onboarding) {
        state.onboarding.state = {
          ...state.onboarding.state,
          ...payload,
        };
      } else {
        state.onboarding = {
          fetched: false,
          state: { ...DEFAULT_ONBOARDING_STATE, ...payload },
        };
      }
    },
  );

  addCase(UserStateActions.addBlockedUsers, (state, { payload }) => {
    for (const userId of payload.userIds) {
      state.blockedUserIds[userId] = true;
    }
  });

  addCase(UserStateActions.setMayShowHomeEmptyState, (state, { payload }) => {
    state.mayShowHomeEmptyState = payload;
  });
});
