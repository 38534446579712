import { makeStyles } from "@mui/styles";

import { ColorStyle } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  },

  videoContainer: {
    position: "relative",
    height: "100%",
    maxHeight: "100%",
  },

  video: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    maxHeight: "100%",
    width: "100%",
    height: "100%",
    cursor: "pointer",
    objectFit: "cover",
    zIndex: 1,
  },
});
