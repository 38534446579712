import { EventChannel, eventChannel } from "redux-saga";
import { put, take, takeEvery } from "typed-redux-saga/macro";

import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  SchemaActions,
  SchemaStateActions,
} from "@kraaft/shared/core/modules/schema/schema.actions";
import { SchemaDelaySnapshot } from "@kraaft/shared/core/modules/schema/schema.optimistic";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

import { UserActions } from "../../user/userActions";

export function* subscribeToSchemasSaga() {
  yield takeCountedDeep(
    SchemaActions.subscribe,
    SchemaActions.unsubscribe,
    subscribeToSchemas,
    unsubscribeFromSchemas,
    (action) => action.payload.poolId,
  );

  let currentPoolId: string | undefined;
  while (true) {
    const action = yield* take([
      PoolStateActions.setPoolLocation,
      UserActions.userDisconnectedFromFirebase,
    ]);
    if (PoolStateActions.setPoolLocation.match(action)) {
      if (currentPoolId) {
        yield* put(SchemaActions.unsubscribe({ poolId: currentPoolId }));
      }
      currentPoolId = action.payload.poolId;
      yield* put(SchemaActions.subscribe({ poolId: currentPoolId }));
    } else if (currentPoolId) {
      yield* put(SchemaActions.unsubscribe({ poolId: currentPoolId }));
    }
  }
}

type Meta = EventChannel<{ poolId: string; data: KSchema[] }> | undefined;

function createChannel(poolId: string) {
  return eventChannel<{ poolId: string; data: KSchema[] }>((emit) => {
    return Firestore.subscribeToSchemas(poolId, (data) =>
      emit({ poolId, data }),
    );
  });
}

function* receiveSchemas(payload: { poolId: string; data: KSchema[] }) {
  yield* SchemaDelaySnapshot(SchemaStateActions.set, payload);
  yield* put(
    SchemaStateActions.setSchemasLoadedForPoolId({ poolId: payload.poolId }),
  );
}

function* subscribeToSchemas(
  registerMeta: (meta: Meta) => void,
  action: ReturnType<typeof SchemaActions.subscribe>,
) {
  const channel = createChannel(action.payload.poolId);
  registerMeta(channel);
  yield* takeEvery(channel, receiveSchemas);
}

function* unsubscribeFromSchemas(meta: Meta) {
  meta?.close();
}
