import { useCallback, useRef } from "react";
import { Pressable } from "react-native";

import { useBooleanState } from "@kraaft/helper-hooks";
import {
  MemberItem,
  MemberItemProps,
} from "@kraaft/shared/components/members/memberItem";
import { UserContactInfoSheet } from "@kraaft/shared/components/userContactInfoSheet/userContactInfoSheet";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

interface PressableMemberItemProps extends MemberItemProps {}

export const PressableMemberItem = (props: PressableMemberItemProps) => {
  const itemRef = useRef(null);
  const [isUserContactInfoOpen, openUserContactInfo, closeUserContactInfo] =
    useBooleanState(false);

  const handleOnPress = useCallback(() => {
    trackEvent({
      eventName: "Click On User Name",
      from: "members_page",
    });
    openUserContactInfo();
  }, [openUserContactInfo]);

  return (
    <Pressable onPress={handleOnPress} accessibilityLabel={props.username}>
      <MemberItem {...props} ref={itemRef} />
      <UserContactInfoSheet
        anchor={itemRef}
        open={isUserContactInfoOpen}
        onClose={closeUserContactInfo}
        userId={props.userId}
      />
    </Pressable>
  );
};
