import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, PixelSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  page: {
    position: "relative",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    display: "flex",
    flexDirection: "row",
    height: "100%",
    overflow: "hidden",
  },
  sidebarContent: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    padding: "16px 16px 0px 16px",
    overflowY: "auto",
  },
  expandable: {
    marginBottom: Spacing.S16,
  },
  main: {
    flex: 1,
    overflow: "hidden",
    backgroundColor: Color.WHITE,
    position: "relative",
  },
  dropdown: {
    height: PixelSize.S36,
  },
  conversationNavMenu: {
    marginTop: Spacing.S16,
  },
});
