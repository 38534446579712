import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DateTimePicker } from "@kraaft/shared/components/dateTimePicker";
import { formatDateCell } from "@kraaft/shared/components/modular/details/utils";
import {
  Condition,
  CoupleRecordCondition,
} from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Icon } from "@kraaft/ui";

import { useStyles } from "./filterOptions.styles";

interface DateFilterOptionsProps {
  condition: CoupleRecordCondition;
  setCondition: (condition: Condition) => void;
  column: KSchemaColumn;
  valueColumnType: KColumnType.date | KColumnType.automatedCreatedAt;
}

const DateFilterOptions = (props: DateFilterOptionsProps): JSX.Element => {
  const { condition, setCondition, valueColumnType } = props;
  const [dateVisible, setDateVisible] = useState(false);
  const dateRef = useRef(null);
  const classes = useStyles();

  const handleDateChange = useCallback(
    (date: Date) => {
      const _condition: Condition = {
        ...condition,
        value: {
          value: date,
          columnType: valueColumnType,
        },
      };
      setCondition(_condition);
    },
    [condition, setCondition, valueColumnType],
  );

  useEffect(() => {
    if (condition.value.value === undefined) {
      handleDateChange(new Date());
    }
  }, [condition.value.value, handleDateChange]);

  const dateValue = useMemo(() => {
    const defaultDate = new Date();

    if (
      condition.value.columnType === KColumnType.date ||
      condition.value.columnType === KColumnType.automatedCreatedAt
    ) {
      return condition.value.value ?? defaultDate;
    }
    return defaultDate;
  }, [condition]);

  const openDatePicker = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      setDateVisible(true);
    },
    [setDateVisible],
  );

  const closeDatePicker = useCallback(() => {
    setDateVisible(false);
  }, [setDateVisible]);

  const formatted = formatDateCell(dateValue, false);

  return (
    <>
      <div className={classes.container} ref={dateRef} onClick={openDatePicker}>
        <span className={classes.text}>{formatted}</span>
        <Icon name="calendar" size="MEDIUM" color="FONT_HIGH_EMPHASIS" />
      </div>
      <DateTimePicker
        forceValue
        visible={dateVisible}
        date={dateValue}
        onClose={closeDatePicker}
        onChange={handleDateChange}
        anchorRef={dateRef}
      />
    </>
  );
};

export { DateFilterOptions };
