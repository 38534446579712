import { RoomFiltersValues } from "@kraaft/shared/components/filters/roomFilters.context";

const sumBooleans = (...args: boolean[]) =>
  args.reduce((acc, cur) => {
    return acc + (cur ? 1 : 0);
  }, 0);

const getActiveFiltersCount = (filters: RoomFiltersValues) =>
  sumBooleans(
    filters.visibility !== "member",
    filters.statusId !== undefined,
    filters.responsibleId !== undefined,
    filters.showArchived,
  ) + filters.labelIds.length;

export const getActiveFiltersCountAndText = (filters: RoomFiltersValues) => {
  const activeFiltersCount = getActiveFiltersCount(filters);

  return {
    activeFiltersCount,
    activeFiltersCountAsText:
      activeFiltersCount > 0 ? `(${activeFiltersCount})` : "",
  };
};
