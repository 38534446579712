import chunk from "lodash/chunk";
import { fork, put, take, takeLatest } from "typed-redux-saga/macro";

import {
  kizeoConfigurationsLoaded,
  kizeoFormsLoaded,
  kizeoFormsLoadingFailure,
  loadKizeoForms,
  stopLoadingKizeoForms,
} from "@kraaft/shared/core/modules/form/kizeoFormReducers";
import { Api } from "@kraaft/shared/core/services/api";
import { ResolvePromise } from "@kraaft/shared/core/typeUtils";

export function* kizeoFormSagas() {
  yield* takeLatest(loadKizeoForms, loadKizeoFormsSaga);
}

const CHUNK_SIZE = 100;

function* loadKizeoFormsSaga(action: ReturnType<typeof loadKizeoForms>) {
  const {
    payload: { poolId },
  } = action;

  const task = yield* fork(() => loadFormsAndConfigurations(poolId));

  yield* take(stopLoadingKizeoForms);
  task.cancel();
}

function* loadFormsAndConfigurations(poolId: string) {
  try {
    const response: ResolvePromise<typeof Api.loadKizeoForms> =
      yield Api.loadKizeoForms({ poolId });

    if (response.errorMessage !== undefined) {
      yield* put(kizeoFormsLoadingFailure(new Error(response.errorMessage)));
    } else {
      yield* put(kizeoFormsLoaded(response.forms));

      for (const forms of chunk(response.forms, CHUNK_SIZE)) {
        const configResponse: ResolvePromise<
          typeof Api.loadKizeoFormConfigurations
        > = yield Api.loadKizeoFormConfigurations({
          poolId,
          formIds: forms.map((form) => form.kizeo.formId),
        });
        if (configResponse.errorMessage === undefined) {
          yield* put(kizeoConfigurationsLoaded(configResponse.configurations));
        }
      }
    }
  } catch (e) {
    yield* put(kizeoFormsLoadingFailure(e));
  }
}
