import { useMeshContext } from "@kraaft/helper-hooks";
import {
  KSchemaColumn,
  KSchemaColumnDefinition,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { getFieldProp } from "@kraaft/web/src/components/modularTable/components/fields";
import { SchemaBuilderContext } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

interface Props {
  column: KSchemaColumn;
  onChangeDefinition: (newDefinition: KSchemaColumnDefinition) => void;
  forceInstantDeletion: boolean;
}

export const AnyColumnEditor = ({
  column,
  onChangeDefinition,
  forceInstantDeletion,
}: Props) => {
  const ColumnEditor = getFieldProp("columnEditor", column.type);
  const { isColumnDeletionInstant } = useMeshContext(SchemaBuilderContext);

  return ColumnEditor ? (
    <ColumnEditor
      columnDefinition={column}
      setColumnDefinition={onChangeDefinition}
      isColumnDeletionInstant={forceInstantDeletion || isColumnDeletionInstant}
    />
  ) : null;
};
