import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "flex-start",
  },
  dragIcon: {
    marginRight: Spacing.S8,
    cursor: "grab",
    marginTop: Spacing.S16,
  },
  content: {
    width: "100%",
  },
});
