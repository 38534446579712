import { useCallback, useEffect, useState } from "react";
import { SafeAreaView, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { CreateNewChecklistTemplateDialog } from "@kraaft/shared/components/createNewChecklistTemplateDialog";
import { SelectChecklistTemplate } from "@kraaft/shared/components/selectChecklistTemplate/selectChecklistTemplate";
import { KeyboardAvoidingView, PortalHost } from "@kraaft/ui";

import { styles } from "./selectRoomShared.styles";

interface SelectRoomTaskListProps {
  filter: string;
  preselected: string[];
  onSubmit: (id: string[]) => void;
}

export const SelectRoomTaskList = ({
  onSubmit,
  preselected,
  filter,
}: SelectRoomTaskListProps) => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [templates, setTemplates] = useState<string[]>(preselected ?? []);

  const onSelectTemplates = () => {
    onSubmit(templates);
  };

  useEffect(() => {
    setTemplates(preselected);
  }, [preselected]);

  const closeCreateDialog = useCallback(() => setCreateDialogOpen(false), []);
  const openCreateDialog = useCallback(() => setCreateDialogOpen(true), []);

  return (
    <KeyboardAvoidingView style={styles.screen}>
      <SafeAreaView style={styles.safeAreaView}>
        <View style={styles.listContainer}>
          <View style={styles.list}>
            <SelectChecklistTemplate
              actionButtonsPortalHost="buttons"
              onCreateNewList={isNative() ? undefined : openCreateDialog}
              filter={filter}
              multiple={true}
              selected={templates}
              onChange={(newTemplates) =>
                setTemplates(newTemplates.map((t) => t.id))
              }
              onSelectTemplates={onSelectTemplates}
            />
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <PortalHost hostname="buttons" />
        </View>
        <CreateNewChecklistTemplateDialog
          onClose={closeCreateDialog}
          onCreate={closeCreateDialog}
          open={createDialogOpen}
        />
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
};
