import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  copiedLabelContainer: {
    display: "flex",
    alignItems: "center",
    paddingRight: 12,
    cursor: "pointer",
  },
  copiedLabelIcon: {
    marginLeft: 6,
  },
});
