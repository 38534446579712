import { useTranslation } from "react-i18next";

import { useRoomReportFilters } from "@kraaft/shared/components/report/useRoomReportFilters";
import { ToggleWithLabel } from "@kraaft/shared/components/toggle/toggleWithLabel";

interface TogglesProps {
  includeDocuments: boolean;
  includeMessages: boolean;
  updateFilters: ReturnType<typeof useRoomReportFilters>["updateFilters"];
}

const Toggles = (props: TogglesProps) => {
  const { includeDocuments, includeMessages, updateFilters } = props;
  const { t } = useTranslation();

  return (
    <>
      <ToggleWithLabel
        noPadding
        small
        label={t("generateReportIncludeDocuments")}
        value={includeDocuments}
        id="ide2e-reportGenerator-toggle-includedocuments"
        setValue={updateFilters("includeDocuments")}
      />
      <ToggleWithLabel
        noPadding
        small
        label={t("generateReportIncludeConversation")}
        value={includeMessages}
        id="ide2e-reportGenerator-toggle-includemessages"
        setValue={updateFilters("includeMessages")}
      />
    </>
  );
};

export { Toggles };
