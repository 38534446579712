import { StyleSheet } from "react-native";

import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.WHITE,
  },
  inner: {
    paddingHorizontal: Spacing.S16,
    flex: 1,
    justifyContent: "center",
  },
  imageContainer: {
    marginBottom: Spacing.S24,
    alignItems: "center",
  },
  image: {
    height: 64,
    width: 64,
  },
  text: {
    fontFamily: "Apercu",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.BODY,
    textAlign: "center",
  },
  last: {
    marginVertical: Spacing.S24,
  },
});

export { styles };
