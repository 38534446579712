import React, { useCallback, useMemo } from "react";
import { StyleSheet } from "react-native";
import { noop } from "ts-essentials";

import { AutoLink } from "@kraaft/shared/components/autolink";
import { CustomMatcher } from "@kraaft/shared/components/autolink/types";
import {
  TextComponentProps,
  TextProcessorProps,
} from "@kraaft/shared/components/textProcessor/textProcessor.types";
import { getTextProcessorRegex } from "@kraaft/shared/components/textProcessor/textProcessor.utils";
import { Color, Text } from "@kraaft/ui";

const TextProcessor_ = <T,>(props: TextProcessorProps<T>) => {
  const {
    children: text,
    textStyle,
    textComponentProps,
    highlight,
    enableLinks,
    enableMail,
    enablePhone,
    linkStyle,
    onLongPress,
    highlightColor = Color.YELLOW_KRAAFT,
  } = props;

  const matchers = useMemo(
    () =>
      getTextProcessorRegex(highlight).map<CustomMatcher>((spec) => ({
        pattern: new RegExp(spec.regex.source, "g"),
        style: [
          textStyle,
          styles.highlight,
          { backgroundColor: highlightColor, pointerEvents: "none" },
        ],
        onPress: noop,
      })),
    [highlight, highlightColor, textStyle],
  );

  const TextComponent = (props.textComponent ??
    Text) as React.ComponentType<TextComponentProps>;

  const renderText = useCallback(
    (t: string) => (
      <TextComponent {...textComponentProps} style={textStyle}>
        {t}
      </TextComponent>
    ),
    [TextComponent, textComponentProps, textStyle],
  );

  if (!text) {
    return null;
  }

  return (
    <AutoLink
      enableLinks={enableLinks}
      enableMail={enableMail}
      enablePhone={enablePhone}
      renderText={renderText}
      matchers={matchers}
      linkStyle={[textStyle, linkStyle]}
      textStyle={textStyle}
      onLongPress={onLongPress}
    >
      {text}
    </AutoLink>
  );
};

export const TextProcessor = React.memo(
  TextProcessor_,
) as typeof TextProcessor_;

const styles = StyleSheet.create({
  highlight: {
    backgroundColor: Color.YELLOW_KRAAFT,
  },
  textEmoji: {
    // Needed on android, otherwise emojis are faded
    // https://github.com/arronhunt/react-native-emoji-selector/issues/5
    color: "#000000",
  },
});
