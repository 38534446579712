import { StyleSheet } from "react-native";

import { PreviewSquare } from "@kraaft/shared/components/imagesPreview/previewSquare";
import { Color, ColorStyle, IconSize, Preloader } from "@kraaft/ui";

const LoadingPreviewSquare = () => {
  return (
    <PreviewSquare style={styles.container}>
      <Preloader color={Color.BLACK} size={IconSize.MEDIUM} />
    </PreviewSquare>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
});

export { LoadingPreviewSquare };
