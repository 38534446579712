import { makeStyles } from "@mui/styles";

import {
  Color,
  ColorStyle,
  FontSize,
  PixelSize,
  Radius,
  Spacing,
} from "@kraaft/ui";

interface MakeStylesProps {
  hasError: boolean;
}

const useStyles = makeStyles({
  inputContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "0px 8px",
    boxSizing: "border-box",
    gap: Spacing.S8,
    height: PixelSize.S32,
    borderRadius: Radius.SMALL,
    overflow: "hidden",
    "&:focus-within": {
      border: ({ hasError }: MakeStylesProps) =>
        `1px solid ${
          hasError ? ColorStyle.ACTION_DESCTRUCTIVE : ColorStyle.ACTION_CTA
        }`,
    },
    backgroundColor: Color.WHITE,
  },
  input: {
    flexGrow: 1,
    border: "none",
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontFamily: "Apercu",
    "&::placeholder": {
      color: ColorStyle.FONT_LOW_EMPHASIS,
    },
  },
  loaderContainer: {
    padding: 2 /** visually align loader to clear icon */,
  },
  iconContainer: {
    cursor: "pointer",
  },
});

export { useStyles };
