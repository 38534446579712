import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 8,
    padding: "12px 8px",
    width: 300,
    boxSizing: "border-box",

    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.12)",
    backgroundColor: Color.WHITE,
    "&:hover": {
      backgroundColor: ColorStyle.ACTION_HOVERED,
    },
  },
  bubbleContainer: {
    marginRight: Spacing.S16,
  },
  infosContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  cardTitle: {
    fontSize: FontSize.TITLE,
    lineHeight: "17px",
    fontWeight: "bold",
    marginBottom: Spacing.S8,
    wordBreak: "break-word",
  },
  pillsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  pillContainer: {
    alignSelf: "flex-start",
  },
  pillVerticalSpacer: {
    marginBottom: Spacing.S8,
  },
});
