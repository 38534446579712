import { createAction } from "@reduxjs/toolkit";

import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { createLoaderMeta } from "@kraaft/shared/core/modules/loaders/loaderUtils";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";

import { PoolMember, PoolMembers } from "./poolMemberState";

export const loadPoolMembers = createAction(
  "@poolMember/loadPoolMembers",
  (payload: { poolId: string; superadminPanel: boolean }) => ({ payload }),
);

export const populatePoolMembers = createAction(
  "@poolMember/populatePoolMembers",
  (payload: { members: PoolMembers }) => ({ payload }),
);

export const addMemberToPool = createAction(
  "@poolMember/addMemberToPool",
  (payload: { poolId: string; userId: string; loadingId: string }) => ({
    payload,
    meta: createLoaderMeta({
      id: payload.loadingId,
      status: LoaderStatus.LOADING,
    }),
  }),
);

export const removeMemberFromPool = createAction(
  "@poolMember/removeMemberFromPool",
  (payload: { poolId: string; userId: string; superadminPanel: boolean }) => ({
    payload,
  }),
);

export const removeMemberFromPoolSuccess = createAction(
  "@poolMember/removeMemberFromPoolSuccess",
  (payload: { userId: string }) => ({ payload }),
);

export const editPoolMember = createAction(
  "@poolMember/editPoolMember",
  (payload: {
    userId: string;
    poolId: string;
    update: { role: UserPoolRole };
  }) => ({
    payload,
  }),
);

export const updatePoolMember = createAction(
  "@poolMember/updatePoolMember",
  (payload: { userId: string; update: Partial<PoolMember> }) => ({ payload }),
);

export type DisconnectMicrosoftStoragePayload = {
  poolId: string;
  loaderId?: string;
};
export const disconnectMicrosoftStorage =
  createAction<DisconnectMicrosoftStoragePayload>(
    "@poolMember/disconnectMicrosoftStorage",
  );
