import { memo, useCallback, useMemo, useState } from "react";
import { LayoutChangeEvent, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { DraggableMessage } from "@kraaft/shared/components/draggableMessage";
import { DraggableMessageType } from "@kraaft/shared/components/draggableMessage/types";
import { MessageCaption } from "@kraaft/shared/components/message/messageCaption";
import { MediaOpener } from "@kraaft/shared/components/message/messageImage/mediaOpener";
import { getResizedDimensions } from "@kraaft/shared/components/message/messageImage/messageImageUtils";
import {
  MessageRendererProps,
  useMessageStyles,
} from "@kraaft/shared/components/message/messageUtils";
import { MessageWithHeader } from "@kraaft/shared/components/message/messageWithHeader";
import { MessageVideoPlayer } from "@kraaft/shared/components/videoPlayer";
import { useLocalPathFromFilePath } from "@kraaft/shared/core/modules/file/fileAllocator/useLocalPathFromFilePath";
import { MessageTypes } from "@kraaft/shared/core/modules/message";
import { messageHasReactions } from "@kraaft/shared/core/modules/message/messageUtils";
import { Size } from "@kraaft/shared/core/types";
import { getVideoMedia } from "@kraaft/shared/core/utils";
import { Preloader } from "@kraaft/ui";

import { styles as baseStyles } from "../message.styles";
import { styles } from "./messageVideo.styles";

interface Props extends MessageRendererProps<MessageTypes.VideoMessage> {
  sizerWidth: number;
}

const MessageVideo_ = ({
  roomId,
  message,
  answer,
  onLongPress,
  sizerWidth,
}: Props) => {
  const { isReply, attachment } = message;
  const videoMedia = getVideoMedia(attachment);
  const downloadUrl = videoMedia.downloadUrl;

  const [layoutSize, setLayoutSize] = useState<Size>({ height: 0, width: 0 });
  const localPath = useLocalPathFromFilePath(downloadUrl);

  const isLoading = localPath === undefined;

  const hasReactions = messageHasReactions(message);
  const { textColor } = useMessageStyles({ isReply, hasReactions });

  const onLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    setLayoutSize((oldSize) => {
      if (
        nativeEvent.layout.height === oldSize.height ||
        nativeEvent.layout.width === oldSize.width
      ) {
        return oldSize;
      }
      return {
        height: nativeEvent.layout.height,
        width: nativeEvent.layout.width,
      };
    });
  }, []);

  const containerStyle = useMemo(
    () => [
      baseStyles.messageBox,
      isReply ? styles.containerReply : styles.containerDefault,
      {
        opacity: layoutSize.width > 0 ? 1 : 0,
      },
    ],
    [isReply, layoutSize.width],
  );

  const videoRect = useMemo(
    () => getResizedDimensions(layoutSize, sizerWidth, isNative()),
    [layoutSize, sizerWidth],
  );

  const handleLongPress = useCallback(() => {
    onLongPress?.(message);
  }, [message, onLongPress]);

  return (
    <View style={containerStyle}>
      <MessageWithHeader
        roomId={roomId}
        message={message}
        answerToMessage={answer}
        onLongPressPreview={handleLongPress}
        fitContent
        removeMesageBackground={!isLoading}
      >
        <MediaOpener onLongPress={handleLongPress} messageId={message.id}>
          <DraggableMessage
            messageId={message.id}
            messageType={DraggableMessageType.MessageVideo}
          >
            <View
              onLayout={onLayout}
              style={[
                styles.videoContainer,
                videoRect.height !== 1 && videoRect,
              ]}
            >
              {isLoading ? (
                <View style={styles.loadingContainer}>
                  <Preloader size={28} color={textColor} />
                </View>
              ) : (
                <MessageVideoPlayer
                  fileUrl={localPath}
                  onLongPress={handleLongPress}
                  isStatic
                />
              )}
            </View>
          </DraggableMessage>
        </MediaOpener>
        <MessageCaption
          message={message}
          width={videoRect.width}
          fixNonDeterministicSizeVideo
        />
      </MessageWithHeader>
    </View>
  );
};

export const MessageVideo = memo(MessageVideo_);
