import { makeStyles } from "@mui/styles";

import {
  CarouselIconButton,
  ColorStyle,
  FontSize,
  PixelSize,
  Spacing,
} from "@kraaft/ui";
import { stopPropagation } from "@kraaft/web/src/core/utils";

interface Props {
  onZoomIn: () => void;
  onZoomOut: () => void;
  onResetZoomScale: () => void;
  zoomScale: number;
}

export const ZoomToolbar = (props: Props) => {
  const { onZoomIn, onZoomOut, onResetZoomScale, zoomScale } = props;
  const classes = useStyles();

  return (
    <div className={classes.mediaToolbarContainer} onClick={stopPropagation}>
      <CarouselIconButton
        icon="zoom-out"
        onPress={onZoomOut}
        accessibilityLabel="hey"
      />
      <span className={classes.zoomScale}>{`${Math.round(
        zoomScale * 100,
      )} %`}</span>
      <CarouselIconButton
        icon="zoom-in"
        onPress={onZoomIn}
        accessibilityLabel="hey"
      />
      <CarouselIconButton
        icon="minimize-01"
        onPress={onResetZoomScale}
        accessibilityLabel="hey"
      />
    </div>
  );
};

export const useStyles = makeStyles({
  mediaToolbarContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: Spacing.S16,
  },
  zoomScale: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
    fontSize: FontSize.SMALL,
    width: PixelSize.S40,
    textAlign: "center",
    userSelect: "none",
  },
});

export const makeMediaToolbarButtonStyles = makeStyles({
  mediaToolbarButton: {
    cursor: "pointer",
    userSelect: "none",
  },
});
