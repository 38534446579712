import { StyleSheet } from "react-native";

import { ColorStyle, FontSize, Radius } from "@kraaft/ui";

export const styles = StyleSheet.create({
  main: {
    borderStyle: "dashed",
    width: 80,
    height: 80,
    borderRadius: Radius.LARGE,
    borderWidth: 1,
    borderColor: ColorStyle.FONT_LOW_EMPHASIS,
    marginHorizontal: 6,
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  name: {
    fontSize: FontSize.MINI,
    color: ColorStyle.FONT_LOW_EMPHASIS,
    letterSpacing: -0.09,
    textAlign: "center",
  },
});
