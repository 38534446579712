import { useRef } from "react";
import { StyleSheet, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { useHover } from "@kraaft/helper-hooks";
import { Color, ColorStyle, FontSize, Text } from "@kraaft/ui";

interface ReactionPillProps {
  emoji: string;
  count: number;
  highlighted?: boolean;
}

export const ReactionPill = ({
  emoji,
  count,
  highlighted,
}: ReactionPillProps) => {
  const ref = useRef<View>(null);
  const hovered = useHover(ref);

  return (
    <View
      ref={ref}
      style={[
        styles.emojiContainer,
        highlighted && styles.highlightedEmojiContainer,
        hovered && !highlighted && styles.hoveredEmojiContainer,
      ]}
      nativeID="reactions-pill-text"
      accessibilityLabel={emoji}
    >
      <Text
        selectable={false}
        key={emoji}
        style={[styles.emoji, highlighted && styles.numberReacted]}
      >
        {emoji} {count}
      </Text>
    </View>
  );
};

export const styles = StyleSheet.create({
  emojiContainer: {
    flexDirection: "row",
    alignItems: "center",

    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: Color.TRANSPARENT,

    // Visually align Emojis on web
    paddingVertical: isNative() ? 1 : 2,
    paddingHorizontal: 6,
    overflow: "visible",
  },
  highlightedEmojiContainer: {
    borderColor: Color.BLUE_KRAAFT,
    backgroundColor: ColorStyle.ACTION_SELECTED,
  },
  hoveredEmojiContainer: {
    borderColor: ColorStyle.SEPARATOR,
  },
  emoji: {
    fontSize: FontSize.SMALL,
    lineHeight: 20,

    // Visually align Emojis on web
    marginBottom: isNative() ? undefined : -2,
    marginRight: isNative() ? undefined : 2,

    // Needed on android, otherwise emojis are faded
    // https://github.com/arronhunt/react-native-emoji-selector/issues/5
    color: "#000000",
  },
  numberReacted: {
    color: ColorStyle.ACTION_CTA,
    fontWeight: "bold",
  },
});
