import { StyleSheet } from "react-native";

import { ensureValidStyleSheet } from "@kraaft/shared/core/utils";
import { styleObject } from "@kraaft/shared/styles/panel.styles";
import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export const nativeStylesObject = {
  content: {
    flexGrow: 1,
    flexBasis: 0,
    backgroundColor: Color.WHITE,
    height: "100%",
    paddingHorizontal: Spacing.S16,
  },
  footer: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    ...styleObject.borderTop,
  },
  safeAreaView: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
};

export const styles = StyleSheet.create(
  ensureValidStyleSheet(nativeStylesObject),
);
