import moment from "moment";

import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaColumnLiteralValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordProperties } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { ColumnValidatorType } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { type ResourceKey } from "@kraaft/shared/core/services/i18next";

export type BasicColumnUpdater<T extends KColumnType> = (
  columnValue: KSchemaColumnLiteralValue<T>,
) => void;
export type ColumnUpdater = {
  [KColumnType.attachment]: () => void;
  [KColumnType.checkbox]: BasicColumnUpdater<KColumnType.checkbox>;
  [KColumnType.currency]: BasicColumnUpdater<KColumnType.currency>;
  [KColumnType.date]: BasicColumnUpdater<KColumnType.date>;
  [KColumnType.geolocation]: BasicColumnUpdater<KColumnType.geolocation>;
  [KColumnType.longText]: BasicColumnUpdater<KColumnType.longText>;
  [KColumnType.number]: BasicColumnUpdater<KColumnType.number>;
  [KColumnType.selectSingle]: BasicColumnUpdater<KColumnType.selectSingle>;
  [KColumnType.selectMultiple]: BasicColumnUpdater<KColumnType.selectMultiple>;
  [KColumnType.shortText]: BasicColumnUpdater<KColumnType.shortText>;
  [KColumnType.signature]: (file: ModernFile<LocalPath> | undefined) => void;
  [KColumnType.user]: BasicColumnUpdater<KColumnType.user>;
  [KColumnType.roomMembers]: BasicColumnUpdater<KColumnType.roomMembers>;
};
export type UpdateableModularColumnType = keyof ColumnUpdater;

export type ColumnUpdaterForType<T extends UpdateableModularColumnType> =
  T extends UpdateableModularColumnType ? ColumnUpdater[T] : never;

export interface ModularTableValueUpdate<T extends KColumnType = KColumnType> {
  id: string;
  column: KSchemaColumn<T>;
  rowIndex?: number;
  value: KSchemaColumnLiteralValue<T>;
}

export function modularColumnIsUpdateable(
  column: KSchemaColumn,
): column is KSchemaColumn<UpdateableModularColumnType> {
  return !KSchemaUtils.isReadOnlyColumn(column);
}

export const EMPTY_LOCKED_RECORD_PLACEHOLDER = "-";

export const validateValue = (
  row: ModularRecordProperties,
  value: KSchemaColumnLiteralValue,
  validator?: ColumnValidatorType,
): [boolean, ResourceKey?] => {
  if (validator) {
    const isValid = validator.check(row, value);

    return [isValid, validator.errorKey];
  }
  return [true];
};

export const formatDateCell = (
  value: Date | undefined,
  displayTime: boolean,
) => {
  if (value !== undefined) {
    return moment(value).format(displayTime ? "L LT" : "L");
  }
  return undefined;
};
