import { View } from "react-native";

import { DocumentGallery } from "@kraaft/shared/components/galleries/documentGallery";
import { DocumentGalleryProps } from "@kraaft/shared/components/galleries/documentGallery/documentGallery";
import { DocumentGallerySelectionFooter } from "@kraaft/shared/components/galleries/documentGallery/documentGalleryWithSelection/documentGallerySelectionFooter";
import { AttachMessageSelectionFlow } from "@kraaft/shared/components/galleries/messageActionFlow/attachFlow";
import { ForwardMessageSelectionFlow } from "@kraaft/shared/components/galleries/messageActionFlow/forwardFlow";
import { styles } from "@kraaft/shared/components/galleries/photoGallery/photoGalleryWithSelection/photoGalleryWithSelection.styles";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";
import { ColorStyle, Preloader } from "@kraaft/ui";

interface Props extends Pick<DocumentGalleryProps, "medias"> {
  roomId: string;
}

const DocumentGalleryWithSelection = ({ roomId, medias }: Props) => {
  const { handleCloseFlow } = useMediaSelectionContext({
    medias,
    roomId,
    type: "document",
  });

  return (
    <>
      <View style={styles.content}>
        {!medias && (
          <Preloader
            absoluteFill
            backgroundColor={ColorStyle.BACKGROUND_LIGHT}
          />
        )}
        {medias && (
          <DocumentGallery
            roomId={roomId}
            medias={medias}
            backgroundColor={ColorStyle.BACKGROUND_LIGHT}
          />
        )}
      </View>
      <DocumentGallerySelectionFooter roomId={roomId} />
      <AttachMessageSelectionFlow onClose={handleCloseFlow} roomId={roomId} />
      <ForwardMessageSelectionFlow onClose={handleCloseFlow} roomId={roomId} />
    </>
  );
};

export { DocumentGalleryWithSelection };
