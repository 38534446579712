import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectOrderedModularFoldersWithSchemaIdAndVisibility } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { ModularFolderVisibilityType } from "@kraaft/shared/core/modules/modularFolder/types";
import {
  subscribeToPoolRooms,
  unsubscribeFromPoolRooms,
} from "@kraaft/shared/core/modules/room/roomActions";
import {
  KSchema,
  KSchemaColumn,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Preloader } from "@kraaft/ui";
import { useFilterFolders } from "@kraaft/web/src/components/filterMenu/filterUtils";
import { KanbanLayout } from "@kraaft/web/src/components/kanbanLayout";
import { useSchemaView } from "@kraaft/web/src/components/modularFoldersViewer/useSchemaView";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

interface Props {
  schema: KSchema;
  schemaViewId?: string;
  onRecordClick: (recordId: string) => void;
  onRecordDrop: (
    recordId: string,
    column: KSchemaColumn,
    optionKey: string | undefined,
  ) => void;
}

const ModularFoldersKanbanViewForSchema = ({
  schema,
  schemaViewId,
  onRecordClick,
  onRecordDrop,
}: Props) => {
  const dispatch = useDispatch();

  const { isLoaded: foldersFetched, folders } = useSelector(
    selectOrderedModularFoldersWithSchemaIdAndVisibility({
      visibilities: [
        { type: ModularFolderVisibilityType.User },
        { type: ModularFolderVisibilityType.Pool, poolId: schema.poolId },
      ],
      schemaId: schema.id,
    }),
  );

  const schemaView = useSchemaView(
    RecordWorkspace.ALL_ROOMS,
    schema.id,
    schemaViewId,
  );

  const filteredFolders = useFilterFolders(
    schema.rootSection,
    folders,
    schemaView?.filters,
  );

  useEffect(() => {
    dispatch(ModularFolderActions.subscribeForPool({ poolId: schema.poolId }));
    dispatch(
      subscribeToPoolRooms({
        poolId: schema.poolId,
      }),
    );
    return () => {
      dispatch(
        ModularFolderActions.unsubscribeForPool({ poolId: schema.poolId }),
      );
      dispatch(
        unsubscribeFromPoolRooms({
          poolId: schema.poolId,
        }),
      );
    };
  }, [dispatch, schema]);

  return foldersFetched ? (
    <KanbanLayout
      workspace={RecordWorkspace.ALL_ROOMS}
      schema={schema}
      modularRecords={filteredFolders}
      onRecordClick={onRecordClick}
      onRecordDrop={onRecordDrop}
      schemaViewId={schemaViewId}
    />
  ) : (
    <Preloader absoluteFill transparent />
  );
};

export { ModularFoldersKanbanViewForSchema };
