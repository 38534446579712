import { useCallback } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";

import { RecentAwareEmojiSelector } from "@kraaft/shared/components/emojiSelector/recentAwareEmojiSelector";
import { selectCurrentPoolLanguage } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { Sheet } from "@kraaft/ui";

interface AllEmojisSheetProps {
  onEmojiSelected(emoji: string): void;
  height: number;
  width: number;
}

export const AllEmojisSheet = Sheet({
  native: "bottom",
  web: "anchored",
}).create<AllEmojisSheetProps>(
  ({ Paper }) =>
    ({ onEmojiSelected, onClose, height, width }) => {
      const language = useSelector(selectCurrentPoolLanguage);

      const handleEmojiSelected = useCallback(
        (emoji: string) => {
          onEmojiSelected(emoji);
          onClose();
        },
        [onClose, onEmojiSelected],
      );

      return (
        <Paper noPadding>
          <View style={{ height }}>
            <RecentAwareEmojiSelector
              recentEmojiSpace="message_reaction"
              poolLanguage={language}
              onEmojiSelected={handleEmojiSelected}
              width={width}
            />
          </View>
        </Paper>
      );
    },
);
