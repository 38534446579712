import { assert } from "ts-essentials";
import { put, select } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api";

export function* changeRoomUsers(
  action: ReturnType<typeof roomActions.changeRoomUsersRequest>,
) {
  const { payload, meta } = action;
  try {
    const { roomId, addedUserIds, removedUserIds } = payload;
    const currentUserId = yield* select(selectCurrentUserId);
    assert(currentUserId, "no currentUserId");

    yield* put(roomActions.RoomStateActions.updateRoomMembers(payload));

    yield Api.changeRoomUsers({
      roomId,
      addedUserIds,
      removedUserIds,
    });

    if (addedUserIds?.includes(currentUserId)) {
      yield* put(roomActions.RoomStateActions.roomJoined({ roomId }));
    }

    if (removedUserIds?.includes(currentUserId)) {
      yield* put(roomActions.RoomStateActions.roomLeft({ roomId }));
    }
  } catch (err) {
    console.log("changeRoomUsers", err);
    yield* put(
      showError({
        title: "Error adding users to the room",
        message: "Error adding users to the room",
      }),
    );
    yield* put(roomActions.changeRoomUsersFailure(err, meta));
  }
}
