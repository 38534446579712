import { StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
  },
  contentContainer: {
    paddingTop: Spacing.S8,
    paddingBottom: isNative() ? Spacing.S16 : 0,
  },
  row: {
    flexDirection: "row",
    padding: Spacing.S12,
    gap: Spacing.S8,
  },
  sectionTitleRow: {
    flexDirection: "row",
    paddingHorizontal: Spacing.S12,
    paddingVertical: Spacing.S8,
    gap: Spacing.S8,
    backgroundColor: Color.WHITE,
  },
  imageRow: {
    alignItems: "flex-start",
    justifyContent: "center",
  },
  textRow: {
    justifyContent: "center",
    flexShrink: 1,
  },
  emptyTextContainer: {
    padding: Spacing.S12,
    alignItems: "center",
    flex: 1,
  },
  sectionFooter: {
    paddingBottom: Spacing.S16,
  },
});
