import { RefCallback, useCallback, useEffect, useRef } from "react";
import { View } from "react-native";
import { VirtualElement } from "@floating-ui/react";
import compact from "lodash/compact";

import { isNative } from "@kraaft/helper-functions";
import { addContextMenuListener } from "@kraaft/ui";

type UseSelectionContextMenuProps = {
  selection: Record<string, true>;
  onOpen?: (virtualElement: VirtualElement) => void;
};

export function useSelectionContextMenu({
  selection,
  onOpen,
}: UseSelectionContextMenuProps) {
  const allImageRef = useRef<Map<string, HTMLElement | null>>(new Map());
  const selectionContextMenuSubscription = useRef<Array<() => void>>();

  const registerRef = useCallback(
    (id: string): RefCallback<HTMLElement | View> => {
      // It is convenient to accept an RN View ref, but we want to deal with HTMLElement anyway
      return (ref) => {
        if (!isNative() && ref instanceof HTMLElement) {
          allImageRef.current.set(id, ref);
        }
      };
    },
    [],
  );

  useEffect(() => {
    if (!selection || !onOpen || isNative()) {
      return;
    }

    // We can be more efficient here by not unsubscribe listeners that will be re-subscribe
    // unsubscribe all
    if (selectionContextMenuSubscription.current !== undefined) {
      for (const unsubscribe of selectionContextMenuSubscription.current) {
        unsubscribe();
      }
    }

    const allSelectedMessageIds = Object.keys(selection);
    // resubscribe selection
    selectionContextMenuSubscription.current = compact(
      allSelectedMessageIds.map((id) => {
        const imageRef = allImageRef.current.get(id);
        if (!imageRef) {
          return undefined;
        }
        return addContextMenuListener({ current: imageRef }, onOpen);
      }),
    );

    return () => {};
  }, [onOpen, selection]);

  return { registerRef };
}
