import {
  CompactTextPillContent,
  TextPillContent,
} from "@kraaft/shared/components/modular/pill/utils";
import { PillRenderer } from "@kraaft/shared/components/modular/pill/utils/types";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { Color, ColorStyle } from "@kraaft/ui";

export const CheckboxCompactedPillRenderer: PillRenderer<KColumnType.checkbox> =
  ({ schemaColumn, recordProperty }) => {
    const isChecked = recordProperty?.value;

    return (
      <CompactTextPillContent
        iconName={
          isChecked ? "round-checkbox-on-fill" : "round-checkbox-unchecked"
        }
        iconColor={
          isChecked ? Color.GREEN_LAGOON : ColorStyle.FONT_LOW_EMPHASIS
        }
      >
        {schemaColumn.name}
      </CompactTextPillContent>
    );
  };

export const CheckboxPillRenderer: PillRenderer<KColumnType.checkbox> = ({
  schemaColumn,
  recordProperty,
}) => {
  const isChecked = recordProperty?.value;

  return (
    <TextPillContent
      iconName={
        isChecked ? "round-checkbox-on-fill" : "round-checkbox-unchecked"
      }
      iconColor={isChecked ? Color.GREEN_LAGOON : ColorStyle.FONT_LOW_EMPHASIS}
      numberOfLines={1}
      tooltipTitle={schemaColumn.name}
    >
      {schemaColumn.name}
    </TextPillContent>
  );
};
