/** @example
 * FactoryAsTuples<{ A: 'foo'; B: 'bar' }>
 * // becomes ["A", "foo"] | ["B", "bar"]
 */
export type FactoryAsTuples<F, K extends keyof F = keyof F> = K extends K
  ? [K, F[K]]
  : never;

/** @example
 * FactoryElementWithType<{ A: 'foo'; B: 'bar' }>
 * // becomes "foo" | "bar"
 */
export type FactoryElementWithType<F, K extends keyof F = keyof F> = Extract<
  FactoryAsTuples<F>,
  [K, any]
>[1];

export const getFactoryElement = <F, T extends keyof F = keyof F>(
  factory: F,
  type: T,
): FactoryElementWithType<F, T> => factory[type];
