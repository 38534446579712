import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import { httpsCallableUnprefixed } from "@kraaft/shared/core/services/firebase/firebaseUtils";

export type PoolSuggestion = {
  id: string;
  name: string;
  language: PoolLanguage;
  isFreemium: boolean;
};

const SuperadminApi = {
  importRoomsSuperAdmin: async (payload: {
    poolId: string;
    excelBase64: string | ArrayBuffer;
    force?: boolean;
  }): Promise<{
    isSuccessful: boolean;
    warnings: string[];
  }> => {
    if (payload.force === undefined) {
      payload.force = false;
    }
    const result = await httpsCallableUnprefixed("superadmin-importRooms")(
      payload,
    );
    return result.data;
  },

  getPoolMembers: async (payload: { poolId: string }) => {
    return await httpsCallableUnprefixed("superadmin-getPoolMembers")(payload);
  },

  importUsers: async (payload: {
    poolId: string;
    excelBase64: string | ArrayBuffer;
  }): Promise<{
    warnings: string[] | undefined;
  }> => {
    const result = await httpsCallableUnprefixed("superadmin-importUsers")(
      payload,
    );
    return result.data;
  },

  addMemberToPool: async (payload: {
    userId: string;
    poolId: string;
  }): Promise<void> => {
    return (
      await httpsCallableUnprefixed("superadmin-addMemberToPool")(payload)
    ).data;
  },

  migrateUserRooms: async (payload: {
    fromUserId: string;
    toUserId: string;
  }): Promise<void> => {
    return (
      await httpsCallableUnprefixed("superadmin-migrateUserRooms")(payload)
    ).data;
  },

  createPool: async (payload: {
    name: string;
    language: PoolLanguage;
    timeZone: string;
  }): Promise<string> => {
    const result = await httpsCallableUnprefixed("superadmin-createPool")(
      payload,
    );
    return result.data;
  },

  deleteMessage: async (payload: { messageId: string }) => {
    return await httpsCallableUnprefixed(
      "superadmin-definitivelyRemoveMessage",
    )(payload);
  },

  updatePoolFreemiumState: async (payload: {
    poolId: string;
    newState: boolean;
  }) => {
    return await httpsCallableUnprefixed("superadmin-updatePoolFreemiumState")(
      payload,
    );
  },

  searchPoolName: async (payload: {
    search: string;
  }): Promise<PoolSuggestion[]> =>
    (await httpsCallableUnprefixed("superadmin-searchPoolName")(payload)).data,
};

Object.freeze(SuperadminApi);
export { SuperadminApi };
