export class NetworkError extends Error {
  public readonly path: string;
  public readonly elapsedDurationMs: number | undefined;

  constructor({
    path,
    message,
    cause,
    elapsedDurationMs,
  }: {
    path: string;
    message?: string;
    cause?: Error;
    elapsedDurationMs?: number;
  }) {
    super(message, { cause });
    this.path = path;
    this.elapsedDurationMs = elapsedDurationMs;
  }
}
