import moment from "moment";

import {
  CompactTextPillContent,
  TextPillContent,
} from "@kraaft/shared/components/modular/pill/utils";
import {
  PillRenderer,
  PillsDisplayOptions,
} from "@kraaft/shared/components/modular/pill/utils/types";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Color } from "@kraaft/ui";

function startOfDay(date: Date) {
  const result = new Date(date);
  result.setHours(0, 0, 0, 0);
  return result;
}

const useTextColor = (
  date: Date | undefined,
  displayOptions: PillsDisplayOptions | undefined,
) => {
  if (!date || !displayOptions?.coloredPastDate) {
    return undefined;
  }
  if (startOfDay(new Date()).getTime() > startOfDay(date).getTime()) {
    return Color.ORANGE_BASIC;
  }
  return undefined;
};

export const DateCompactedPillRenderer: PillRenderer<KColumnType.date> = ({
  recordProperty,
  schemaColumn,
  displayOptions,
}) => {
  const textColor = useTextColor(recordProperty?.value, displayOptions);

  if (!recordProperty?.value) {
    return null;
  }

  const dateAsString = moment(recordProperty.value).format("L");

  return (
    <CompactTextPillContent
      iconName={KSchemaUtils.getIcon(schemaColumn)}
      textColor={textColor}
    >
      {dateAsString}
    </CompactTextPillContent>
  );
};

// displayOptions are not implemented
export const DatePillRenderer: PillRenderer<KColumnType.date> = ({
  schemaColumn,
  recordProperty,
}) => {
  if (!recordProperty?.value) {
    return null;
  }

  const dateAsString = moment(recordProperty.value).format("L");

  return (
    <TextPillContent
      iconName={KSchemaUtils.getIcon(schemaColumn)}
      numberOfLines={1}
      tooltipTitle={schemaColumn.name}
    >
      {dateAsString}
    </TextPillContent>
  );
};
