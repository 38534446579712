import { makeStyles } from "@mui/styles";

import { Color, PixelSize } from "@kraaft/ui";

export const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "12px 6px",
  },
  vertical: {
    flexDirection: "column",
    padding: "6px 12px",
  },
  color: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    marginTop: 1,
    marginLeft: 1,
    border: "none",
    cursor: "pointer",
    height: PixelSize.S24,
    width: PixelSize.S24,
    borderRadius: "50%",
    transition: "all 250ms",
  },
  margin: {
    margin: 12,
  },
  border: {
    boxShadow: `0px 0px 0px 1px ${Color.GREY_FRENCH} inset`,
  },
});
