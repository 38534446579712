import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { SectionLockInfo } from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { ModularRecordProperties } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { type ResourceKey } from "@kraaft/shared/core/services/i18next";

export type ColumnValidatorType = {
  check: (
    row: ModularRecordProperties,
    cell: ModularRecordProperties[string]["value"],
  ) => boolean;
  errorKey: ResourceKey;
};

export interface ModularDisplayRequirements {
  poolId: string;
  currentUserId: string;

  getOrLoadUsers(userId: string[]): Record<string, User>;
  getPoolUsers(): Record<string, User>;

  currencyCode: string;
}

export interface ModularDisplayExtendedRequirements {
  getRoomFromRecordId: (id: string) => Room | undefined;
  getRoomNameFromRecordId: (id: string) => string | undefined;

  noCheckboxConfirmation?: boolean;

  recordType: "room" | "modularFolder" | "formPreview" | "standalone";
}

export interface ModularColumnContext {
  editable?: (
    recordProperties: ModularRecordProperties,
    // This could be generic, but makes the whole context generic across the app.
    // For the moment, the only usage of this parameter is to know whether a title is editable
    // In an EveryoneRoom, title is not editable
    recordId: string,
  ) => boolean;
  getSectionLockInfo?(
    recordProperties: ModularRecordProperties,
  ): SectionLockInfo;
  nonReorderable?: boolean;
  autoFocus?: boolean;
}

export interface ModularColumnsContext {
  columns: {
    [columnKey: string]: ModularColumnContext;
  };
  getSectionLockInfoFromSectionKey?(
    sectionKey: string,
    recordProperties: ModularRecordProperties,
  ): SectionLockInfo;
}

export const EMPTY_COLUMN_CONTEXT = {};
