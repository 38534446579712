import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { openPreview } from "@kraaft/shared/core/modules/preview/previewActions";
import {
  KSchema,
  KSchemaColumn,
  KSchemaColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFoldersKanbanViewForRoom } from "@kraaft/web/src/components/modularFoldersViewer/kanban/modularFoldersKanbanViewForRoom";
import { ModularFoldersKanbanViewForSchema } from "@kraaft/web/src/components/modularFoldersViewer/kanban/modularFoldersKanbanViewForSchema";

interface Props {
  roomId?: string;
  schema: KSchema;
  schemaViewId?: string;
}

const ModularFoldersKanbanView = ({ schema, roomId, schemaViewId }: Props) => {
  const dispatch = useDispatch();

  const handleRecordClick = useCallback(
    (recordId: string) => {
      dispatch(
        openPreview({
          type: "modularFolder",
          folderId: recordId,
        }),
      );
    },
    [dispatch],
  );

  const handleRecordDrop = useCallback(
    (
      recordId: string,
      column: KSchemaColumn,
      optionKey: string | undefined,
    ) => {
      dispatch(
        OfflineModularFolderActions.edit({
          editions: [
            {
              id: recordId,
              properties: [
                [
                  column.key,
                  {
                    columnType: column.type,
                    value: [optionKey],
                  } as KSchemaColumnValue<typeof column.type>,
                ],
              ],
            },
          ],
        }),
      );
    },
    [dispatch],
  );

  if (roomId) {
    return (
      <ModularFoldersKanbanViewForRoom
        schema={schema}
        roomId={roomId}
        onRecordClick={handleRecordClick}
        onRecordDrop={handleRecordDrop}
        schemaViewId={schemaViewId}
      />
    );
  }
  return (
    <ModularFoldersKanbanViewForSchema
      schema={schema}
      onRecordClick={handleRecordClick}
      onRecordDrop={handleRecordDrop}
      schemaViewId={schemaViewId}
    />
  );
};

export { ModularFoldersKanbanView };
