import { useCallback, useMemo } from "react";
import { useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchema,
  KSchemaColumn,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordWithIdAndTitle } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { UserMap } from "@kraaft/shared/core/modules/user/userState";
import { DraggableKanbanCardContainer } from "@kraaft/web/src/components/kanban/board/draggableKanbanCardContainer";
import {
  getKanbanColumnsFromSchemaColumn,
  groupModularRecordsByColumnValue,
  KANBAN_ITEM,
} from "@kraaft/web/src/components/kanban/board/kanbanBoardUtils";
import { KanbanColumn } from "@kraaft/web/src/components/kanban/board/kanbanColumn";
import { KanbanDragLayer } from "@kraaft/web/src/components/kanban/board/kanbanDragLayer";
import { KanbanCard } from "@kraaft/web/src/components/kanban/card";

import { useStyles } from "./kanbanBoard.styles";

const KANBAN_MAX_RECORDS_PER_COLUMN = 75;

interface Props {
  schema: KSchema;
  modularRecords: ModularRecordWithIdAndTitle[];
  onRecordClick?: (recordId: string) => void;
  onRecordDrop: (
    recordId: string,
    column: KSchemaColumn,
    optionKey: string | undefined,
  ) => void;
  groupedByColumn: KSchemaColumn<KColumnType.selectSingle | KColumnType.user>;
  displayProperties: string[];
  poolMembers: UserMap;
  currencyCode: string;
}

const KanbanBoard = (props: Props) => {
  const {
    schema,
    modularRecords,
    onRecordClick,
    onRecordDrop,
    groupedByColumn,
    displayProperties,
    poolMembers,
    currencyCode,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [, drop] = useDrop({ accept: KANBAN_ITEM });

  const modularRecordsGrouped = groupModularRecordsByColumnValue(
    modularRecords,
    groupedByColumn,
    poolMembers,
  );

  const kanbanColumns = getKanbanColumnsFromSchemaColumn(
    groupedByColumn,
    modularRecordsGrouped.withValue,
    poolMembers,
  );

  const hasColumnBeenTruncated = useMemo(
    () =>
      modularRecordsGrouped.withoutValue.length >
        KANBAN_MAX_RECORDS_PER_COLUMN ||
      Object.values(modularRecordsGrouped).some(
        (records) => records.length > KANBAN_MAX_RECORDS_PER_COLUMN,
      ),
    [modularRecordsGrouped],
  );

  const getItemsForColumn = useCallback(
    (key: string | undefined) =>
      key !== undefined
        ? modularRecordsGrouped.withValue[key]
        : modularRecordsGrouped.withoutValue,
    [modularRecordsGrouped.withValue, modularRecordsGrouped.withoutValue],
  );

  const renderKanbanCardsForColumn = useCallback(
    (items: ModularRecordWithIdAndTitle[] | undefined) => {
      return items ? (
        <div className={classes.columnContent}>
          {items
            .slice(0, KANBAN_MAX_RECORDS_PER_COLUMN)
            .map((modularRecord, index) => {
              const isNotLast = index < items.length - 1;

              return (
                <div
                  key={modularRecord.id}
                  className={clsx(isNotLast && classes.cardSpacer)}
                >
                  <DraggableKanbanCardContainer modularRecord={modularRecord}>
                    <KanbanCard
                      currencyCode={currencyCode}
                      schema={schema}
                      modularRecord={modularRecord}
                      poolMembers={poolMembers}
                      displayProperties={displayProperties}
                      onRecordClick={onRecordClick}
                    />
                  </DraggableKanbanCardContainer>
                </div>
              );
            })}
        </div>
      ) : null;
    },
    [
      currencyCode,
      classes.cardSpacer,
      classes.columnContent,
      displayProperties,
      onRecordClick,
      poolMembers,
      schema,
    ],
  );

  return (
    <>
      <div ref={drop} className={classes.board}>
        <div className={classes.boardContainer}>
          {kanbanColumns.map((kanbanColumnData) => {
            const key = kanbanColumnData.columnType
              ? kanbanColumnData.optionKey
              : "noValue";

            const items = getItemsForColumn(kanbanColumnData.optionKey);

            return (
              <KanbanColumn
                key={key}
                column={groupedByColumn}
                kanbanColumnData={kanbanColumnData}
                itemCount={items?.length ?? 0}
                onItemDrop={onRecordDrop}
              >
                {renderKanbanCardsForColumn(items)}
              </KanbanColumn>
            );
          })}
        </div>
        {hasColumnBeenTruncated ? (
          <div className={classes.footerContainer}>
            <span className={classes.footerSeparator} />
            <span className={classes.footerLabel}>
              {t("kanban.columnTruncated", {
                max: KANBAN_MAX_RECORDS_PER_COLUMN,
              })}
            </span>
          </div>
        ) : null}
      </div>
      <KanbanDragLayer
        currencyCode={currencyCode}
        schema={schema}
        poolMembers={poolMembers}
        displayProperties={displayProperties}
      />
    </>
  );
};

export { KanbanBoard };
