import { createAction } from "@reduxjs/toolkit";

import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import {
  SchemaView,
  TemporarySchemaView,
} from "@kraaft/shared/core/modules/schemaView/schemaViewState";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

interface AddSchemaViewPayload {
  name: string;
  schemaId: string;
  filters: CompositeCondition;
}

export const addSchemaView = createAction<AddSchemaViewPayload>(
  "@schemaView/ADD_SCHEMA_VIEW",
);

export const deleteSchemaView = createAction<{ id: string }>(
  "@schemaView/DELETE_SCHEMA_VIEW",
);

export const receiveSchemaViews = createAction<SchemaView[]>(
  "@schemaView/RECEIVE_SCHEMA_VIEWS",
);

export const receiveTemporarySchemaViewsFromMemory = createAction<{
  temporarySchemaViews: { [id: string]: TemporarySchemaView };
}>("@schemaView/RECEIVE_TEMPORARY_SCHEMA_VIEWS");

interface TemporarySchemaViewOrUnsaved {
  workspace: RecordWorkspace;
  schemaId: string;
  schemaViewId: string | undefined;
}

export const editSchemaView = createAction<
  TemporarySchemaViewOrUnsaved & {
    schemaView: Partial<TemporarySchemaView>;
  }
>("@schemaView/EDIT_SCHEMA_VIEW");

export const editSchemaViewName = createAction<{
  schemaViewId: string;
  name: string;
}>("@schemaView/EDIT_SCHEMA_VIEW_NAME");

export const saveEditedSchemaView = createAction<TemporarySchemaViewOrUnsaved>(
  "@schemaView/SAVE_EDITED_SCHEMA_VIEW",
);

export const saveEditedSchemaViewFailure = createAction<{
  schemaView: SchemaView;
}>("@schemaView/SAVE_EDITED_SCHEMA_VIEW_FAILURE");

export const clearTemporarySchemaView =
  createAction<TemporarySchemaViewOrUnsaved>(
    "@schemaView/CLEAR_EDITED_SCHEMA_VIEW",
  );

export const editSchemaViewKanbanFormat = createAction<
  TemporarySchemaViewOrUnsaved & {
    editions: Partial<SchemaView["formats"]["kanban"]>;
  }
>("@schemaView/EDIT_KANBAN_FORMAT");
