import clsx from "clsx";

import { useStyles } from "./skeleton.styles";

interface SkeletonProps {
  height: React.CSSProperties["height"];
  width: React.CSSProperties["width"];
  borderRadius?: number;
  isText?: boolean;
}

const Skeleton = (props: SkeletonProps) => {
  const { height, width, borderRadius, isText } = props;

  const classes = useStyles({ height, width, borderRadius });

  return (
    <div
      className={clsx(
        classes.container,
        classes.withAnimation,
        isText && classes.textSkeleton,
      )}
    />
  );
};

export { Skeleton };
