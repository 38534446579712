import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { LimitedInput } from "@kraaft/shared/components/createWorkspace/limitedInput";
import { useValidateWorkspaceName } from "@kraaft/shared/components/createWorkspace/useValidateWorkspaceName";
import { useIsWorkspaceNameTaken } from "@kraaft/shared/core/modules/pool/hooks/useIsWorkspaceNameTaken.hook";
import { Text } from "@kraaft/ui";

const useWorkspaceNameInput = (
  name: string,
  setName: (newName: string) => void,
  submit: () => void,
  defaultName?: string,
) => {
  const { t } = useTranslation();

  const validationErrors = useValidateWorkspaceName(name);

  const { isTaken, loading, error } = useIsWorkspaceNameTaken(
    name,
    defaultName,
    !validationErrors,
  );

  return [
    <>
      <LimitedInput
        onChange={setName}
        hasError={name !== "" && (isTaken || !!validationErrors)}
        onSubmitEditing={submit}
        defaultValue={defaultName}
      />

      {name !== "" ? (
        error ? (
          <Text size="SMALL" color="ACTION_DESCTRUCTIVE">
            {error}
          </Text>
        ) : validationErrors ? (
          <>
            {validationErrors.map((e) => (
              <Text key={e} size="SMALL" color="ACTION_DESCTRUCTIVE">
                {e}
              </Text>
            ))}
          </>
        ) : loading ? (
          <Text size="SMALL" color="FONT_LOW_EMPHASIS">
            {t("newWorkspace.checkingAvailability")}
          </Text>
        ) : isTaken ? (
          <Text size="SMALL" color="ACTION_DESCTRUCTIVE">
            {t("newWorkspace.nameTaken")}
          </Text>
        ) : (
          <Text size="SMALL" color="GREEN_LAGOON">
            {t("newWorkspace.nameAvailable")}
          </Text>
        )
      ) : (
        // eslint-disable-next-line no-inline-styles/no-inline-styles
        <View style={{ height: 15 }} />
      )}
    </>,
    {
      isLoading: loading,
      isTaken: isTaken,
      isError: Boolean(error) || Boolean(validationErrors),
    },
  ] as const;
};

export { useWorkspaceNameInput };
