import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectMapOverlaysByPoolIdOptimictic } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.selectors";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button } from "@kraaft/ui";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { AddMapOverlayDialog } from "@kraaft/web/src/views/settings/manageMapOverlays/addMapOverlayDialog";
import { MapOverlayItem } from "@kraaft/web/src/views/settings/manageMapOverlays/mapOverlayItem/mapOverlayItem";

import { useSettingsStyles } from "../settings.styles";
import { useStyles } from "./manageMapOverlays.styles";

interface ManageMapOverlaysProps {
  poolId: string | undefined;
}

const ManageMapOverlays = (props: ManageMapOverlaysProps) => {
  const { poolId } = props;

  const { t } = useTranslation();
  const settingsClasses = useSettingsStyles();
  const classes = useStyles();

  const [
    isAddMapOverlayDialogOpen,
    openAddMapOverlayDialog,
    closeAddMapOverlayDialog,
  ] = useBooleanState();

  const mapOverlays = useSelector(selectMapOverlaysByPoolIdOptimictic(poolId));

  const overlays = useMemo(
    () =>
      mapOverlays.map((mapOverlay) => (
        <div key={mapOverlay.id} className={classes.rowContainer}>
          <MapOverlayItem {...mapOverlay} />
        </div>
      )),
    [classes.rowContainer, mapOverlays],
  );

  return (
    <>
      <div className={settingsClasses.pageContainer}>
        <PageHeader title={t("importMapOverlay.settingsTitle")} />
        {overlays}
        <div className={classes.footerContainer}>
          <Button
            accessibilityLabel={t("importMapOverlay.buttonLabel")}
            text={t("importMapOverlay.buttonLabel")}
            onPress={openAddMapOverlayDialog}
          />
        </div>
      </div>
      {poolId ? (
        <AddMapOverlayDialog
          poolId={poolId}
          open={isAddMapOverlayDialogOpen}
          onClose={closeAddMapOverlayDialog}
        />
      ) : null}
    </>
  );
};

export { ManageMapOverlays };
