import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { useMeshContext } from "@kraaft/helper-hooks";
import { useEmojiSelectorWidth } from "@kraaft/shared/components/avatarEmojiPicker/useEmojiSelectorWidth";
import { RecentAwareEmojiSelector } from "@kraaft/shared/components/emojiSelector/recentAwareEmojiSelector";
import { RecentEmojiSpace } from "@kraaft/shared/core/modules/emoji/emoji.state";
import { selectCurrentPoolLanguage } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { AnimationContext } from "@kraaft/ui";

import { EmojiSelectorSheet } from "./emojiSelector.sheet";

type EmojiSelectorContentProps = {
  onClose: () => void;
  onEmojiSelected: (emoji: string) => void;
  recentEmojiSpace: RecentEmojiSpace;
};

export const EmojiSelectorSheetContent = React.memo(
  ({
    onClose,
    onEmojiSelected,
    recentEmojiSpace,
  }: EmojiSelectorContentProps) => {
    const { t } = useTranslation();

    const emojiSelectorWidth = useEmojiSelectorWidth();

    const { animationState } = useMeshContext(AnimationContext);

    const isAnimating =
      animationState !== "undetermined" && animationState !== "entered";
    const poolLanguage = useSelector(selectCurrentPoolLanguage);

    const handleEmojiSelected = useCallback(
      async (emoji: string) => {
        onEmojiSelected(emoji);
        onClose();
      },
      [onClose, onEmojiSelected],
    );

    return (
      <EmojiSelectorSheet.Paper noPadding noGap noSafeAreaBottom width={400}>
        {isNative() && (
          <EmojiSelectorSheet.Header onClose={onClose}>
            {t("pickAnEmoji")}
          </EmojiSelectorSheet.Header>
        )}
        <EmojiSelectorSheet.GrowingContent height={400} noPadding>
          <RecentAwareEmojiSelector
            width={emojiSelectorWidth}
            poolLanguage={poolLanguage}
            onEmojiSelected={handleEmojiSelected}
            isAnimating={isAnimating}
            recentEmojiSpace={recentEmojiSpace}
          />
        </EmojiSelectorSheet.GrowingContent>
      </EmojiSelectorSheet.Paper>
    );
  },
);
