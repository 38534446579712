import { ConnectDropTarget } from "react-dnd";
import clsx from "clsx";

import { useMeshContext } from "@kraaft/helper-hooks";
import { DirectoryTreeContext } from "@kraaft/web/src/components/directoryTree/directoryTreeContext";
import { DirectoryTreeIndentation } from "@kraaft/web/src/components/directoryTree/directoryTreeIndentation";

import { useStyles } from "./directoryDropper.styles";

interface DirectoryDropperProps {
  level: number;
  dropRef: ConnectDropTarget;
  isVisible?: boolean;
  isActive?: boolean;
}

const DirectoryDropper = (props: DirectoryDropperProps) => {
  const { level, dropRef, isVisible, isActive } = props;
  const { isDraggingDirectory } = useMeshContext(DirectoryTreeContext);

  const classes = useStyles();
  const shouldHide = !isDraggingDirectory || !isVisible;

  return (
    <div
      ref={dropRef}
      className={clsx(classes.interDirectoryContainer, {
        [classes.interDirectoryHidden]: shouldHide,
      })}
    >
      {!shouldHide && (
        <>
          <DirectoryTreeIndentation level={level} />
          <div
            className={clsx(classes.interDirectoryLine, {
              [classes.interDirectoryLineActive]: isActive,
            })}
          />
        </>
      )}
    </div>
  );
};

export { DirectoryDropper };
