import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDataGrid, {
  CalculatedColumn,
  Column,
  DataGridHandle,
  RowsChangeData,
  SortColumn,
} from "react-data-grid";
import clsx from "clsx";
import isEqual from "fast-deep-equal";
import _compact from "lodash/compact";
import { assert } from "ts-essentials";

import { useMeshContext } from "@kraaft/helper-hooks";
import { ModularTableValueUpdate } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import {
  ModularDisplayExtendedRequirementsContext,
  ModularDisplayExtendedRequirementsProvider,
  ModularDisplayExtendedRequirementsProviderProps,
} from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import {
  ModularDisplayRequirementsContext,
  ModularDisplayRequirementsProvider,
} from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { uuid } from "@kraaft/shared/core/utils";
import { betterMemo } from "@kraaft/shared/core/utils/betterMemo";
import { PixelSize, PortalHost } from "@kraaft/ui";
import {
  getFieldProp,
  isFieldType,
} from "@kraaft/web/src/components/modularTable/components/fields";
import { ColumnHeader } from "@kraaft/web/src/components/modularTable/components/table/columnHeader";
import { SimpleColumnHeader } from "@kraaft/web/src/components/modularTable/components/table/columnHeader/simpleColumnHeader";
import { CopyPasteTableManager } from "@kraaft/web/src/components/modularTable/components/table/copyPasteTableManager";
import {
  ModularTableContext,
  ModularTableContextProps,
} from "@kraaft/web/src/components/modularTable/components/table/modularTableContext";
import {
  shouldCloseEditor,
  stabilizeIdx,
} from "@kraaft/web/src/components/modularTable/components/table/tableUtils";
import { withEditionListener } from "@kraaft/web/src/components/modularTable/components/table/withEditionListener";
import { withExpandButton } from "@kraaft/web/src/components/modularTable/components/table/withExpandButton";
import { ModularTableProps } from "@kraaft/web/src/components/modularTable/schema";

import { FROZEN_INDICATOR_WIDTH, useStyles } from "./table.styles";
import "react-data-grid/lib/styles.css";
import "./table.css";

const MIN_COLUMN_WIDTH = 150;
const SCROLLBAR_HEIGHT = 12;

export interface TableHandle {
  resetTableSize: () => void;
}

export interface Position {
  idx: number;
  rowIdx: number;
}

const rowKeyGetter = (row: ModularRecord) => row.id;

const TableComponent = <T extends ModularRecord>(
  props: ModularTableProps<T> & {
    fwdRef?: React.Ref<TableHandle>;
    canCreateRow?: boolean;
  },
) => {
  const {
    fwdRef,
    tableState,
    tableActions,
    attachmentContext,
    tableClassName,
    allowEdit = true,
    disablePreview = false,
    editableHeader = false,
    fullSize = true,
    canCreateRow,
    disableSort,
    tableContext,
    maxRows,
  } = props;

  const dataGridRef = useRef<DataGridHandle>(null);
  const tableIdentifier = useRef(uuid()).current;

  const [refresher, setRefresher] = useState(false);
  const [frozenIndicatorWidth, setFrozenIndicatorWidth] = useState(0);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [rows, _setRows] = useState<T[]>([]);
  const position = useRef<Position>();
  const previousPosition = useRef<Position>();
  const [isEditing, setEditing] = useState(false);
  const classes = useStyles();

  const handleResetTableSize = useCallback(() => {
    setRefresher((oldRefresher) => !oldRefresher);
  }, []);

  const orderedColumns = useMemo(() => {
    const ordered = KSchemaUtils.orderedColumns(
      tableState.schema.rootSection,
    ).filter((col) => isFieldType(col.type));
    // Have the first column be roomName
    const conversationNameIndex = ordered.findIndex(
      (o) => o.type === KColumnType.roomName,
    );
    if (conversationNameIndex === -1) {
      return ordered;
    }
    const [conversationName] = ordered.splice(conversationNameIndex, 1);
    if (!conversationName) {
      return ordered;
    }
    return [conversationName, ...ordered];
  }, [tableState.schema]);

  useImperativeHandle(
    fwdRef,
    () => ({
      resetTableSize: handleResetTableSize,
    }),
    [handleResetTableSize],
  );

  const displayContext = useMeshContext(ModularDisplayRequirementsContext);
  const extendedDisplayContext = useMeshContext(
    ModularDisplayExtendedRequirementsContext,
  );

  const getSortedRows = useCallback(
    (rawRows: T[]): T[] => {
      if (sortColumns.length === 0) {
        return tableState.rows;
      }

      const sortedRows = [...rawRows];
      sortedRows.sort((a, b) => {
        for (const sort of sortColumns) {
          const column = KSchemaUtils.findColumn(
            tableState.schema.rootSection,
            sort.columnKey,
          );
          if (column) {
            const comparator = getFieldProp("comparator", column.type)?.(
              column,
              displayContext,
              extendedDisplayContext,
              sort.direction,
            );

            const compResult = comparator?.(a, b) ?? 0;
            if (compResult !== 0) {
              return sort.direction === "ASC" ? compResult : -compResult;
            }
          }
        }
        return 0;
      });

      return sortedRows.map(stabilizeIdx);
    },
    [
      displayContext,
      extendedDisplayContext,
      sortColumns,
      tableState.rows,
      tableState.schema.rootSection,
    ],
  );

  const setRows = useCallback(
    (_rows: T[]) => _setRows(getSortedRows(_rows)),
    [getSortedRows],
  );

  const handleTableScroll = useCallback((event: Event) => {
    if (event.target && event.target instanceof Element) {
      setFrozenIndicatorWidth(
        Math.min(event.target.scrollLeft, FROZEN_INDICATOR_WIDTH),
      );
    }
  }, []);

  useEffect(() => {
    const tableElement = dataGridRef.current?.element;

    tableElement?.addEventListener("scroll", handleTableScroll);

    return () => {
      tableElement?.removeEventListener("scroll", handleTableScroll);
    };
  }, [handleTableScroll]);

  useEffect(() => {
    setRows(tableState.rows);
  }, [setRows, tableState.rows]);

  const handleColumnsReorder = useCallback(
    (sourceKey: string, targetKey: string) => {
      const sourceColumnIndex = orderedColumns.findIndex(
        (c) => c.key === sourceKey,
      );
      const targetColumnIndex = orderedColumns.findIndex(
        (c) => c.key === targetKey,
      );

      tableActions.column?.onReorder?.(
        sourceKey,
        sourceColumnIndex,
        targetKey,
        targetColumnIndex,
      );
    },
    [orderedColumns, tableActions.column],
  );

  const updateRows = useCallback(
    (newRows: T[], updates: ModularTableValueUpdate[]) => {
      const clonedNewRows = [...newRows];
      setRows(clonedNewRows);
      tableActions.rows?.onUpdate?.(clonedNewRows);
      tableActions.cell?.onUpdate?.(updates);
    },
    [setRows, tableActions.cell, tableActions.rows],
  );

  const handleCellKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Backspace" || event.key === "Delete") {
        event.preventDefault();

        if (position.current) {
          const row = rows[position.current.rowIdx];
          const column = orderedColumns[position.current.idx];
          assert(row && column, "handleCellKeyDown :: row or column missing");

          const columnContext = tableContext.columns[column.key];
          if (
            columnContext?.editable &&
            !columnContext.editable(row.properties, row.id)
          ) {
            return;
          }

          const columnKey = column.key;
          const property = row.properties[columnKey];

          if (property !== undefined) {
            const update: ModularTableValueUpdate = {
              column,
              id: row.id,
              rowIndex: position.current.rowIdx,
              value: undefined,
            };

            updateRows(rows, [update]);
          }
        }
      }
    },
    [orderedColumns, position, rows, updateRows, tableContext.columns],
  );

  const columns = useMemo(() => {
    const _columns = orderedColumns
      .filter((column) => getFieldProp("TableCell", column.type))
      .map((column, index) => {
        const path = KSchemaUtils.findColumnWithContext(
          tableState.schema.rootSection,
          column.key,
        );
        const columnContext = tableContext.columns[column.key];
        const isNotLastColumn = index < orderedColumns.length - 1;

        const editorComponent = getFieldProp("TableEditor", column.type);

        const editor = editorComponent
          ? withEditionListener(editorComponent)
          : undefined;

        const formatterComponent = getFieldProp("TableCell", column.type);

        const formatter =
          !disablePreview && columnContext?.onExpand
            ? withExpandButton(formatterComponent, columnContext?.onExpand)
            : formatterComponent;

        // Workaround to expand last column to max width
        const finalColumnMinWidth = isNotLastColumn ? MIN_COLUMN_WIDTH : "auto";

        return {
          editable:
            allowEdit && editor ? columnContext?.editable || true : false,
          preventEdition: !allowEdit,
          resizable: true,
          sortable: !disableSort,
          frozen: columnContext?.layout?.frozen,
          headerRenderer: editableHeader ? ColumnHeader : SimpleColumnHeader,
          formatter,
          editor,
          editorOptions: {
            onCellKeyDown: handleCellKeyDown,
          },
          section: path?.parentSection,
          ...column,
          columnContext,
          rowContext: tableContext.records,
          tableIdentifier,
          onReorder: handleColumnsReorder,
          onDelete: tableActions.column?.onDelete,
          width: finalColumnMinWidth,
          // minWidth: MIN_COLUMN_WIDTH,
        };
      });

    return [
      ..._columns,
      ...(tableContext.additionalColumns || []),
    ] as unknown as Column<T>[];
  }, [
    allowEdit,
    disablePreview,
    disableSort,
    editableHeader,
    handleCellKeyDown,
    handleColumnsReorder,
    orderedColumns,
    tableActions.column?.onDelete,
    tableContext.additionalColumns,
    tableContext.columns,
    tableContext.records,
    tableIdentifier,
    tableState.schema.rootSection,
  ]);

  const onRowsChange = (newRows: T[], data: RowsChangeData<T>) => {
    const updates = _compact(
      data.indexes.map((index) => {
        const row = newRows[index];
        const key = data.column.key;
        const column = data.column as unknown as KSchemaColumn;
        return (
          row && {
            id: row.id,
            column,
            value: row.properties[key]?.value,
          }
        );
      }),
    );
    updateRows(newRows, updates);
  };

  const handleSelectedCellChange = useCallback((newPosition: Position) => {
    previousPosition.current = position.current;
    position.current = newPosition;
  }, []);

  const handleRowClick = useCallback(
    (row: T, column: CalculatedColumn<T, unknown>) => {
      const index = rows.findIndex((r) => r.id === row.id);
      if (index < 0) {
        return;
      }
      const isCellAlreadySelected =
        previousPosition.current &&
        previousPosition.current.rowIdx === index &&
        previousPosition.current.idx === column.idx;

      dataGridRef.current?.selectCell(
        { rowIdx: index, idx: column.idx },
        isCellAlreadySelected,
      );

      handleSelectedCellChange({ idx: column.idx, rowIdx: index });
    },
    [handleSelectedCellChange, rows],
  );

  const onEnterChangeCell = useCallback(() => {
    if (position.current) {
      dataGridRef?.current?.selectCell({
        idx: position.current.idx,
        rowIdx: position.current.rowIdx + 1,
      });
    }
  }, [position]);

  const modularTableContext = useMemo(
    () =>
      ({
        onFilesUpload: attachmentContext?.onFilesUpload,
        isCellLoading: attachmentContext?.isCellLoading,
        onAttachmentClick: attachmentContext?.onAttachmentClick,
        onEnterChangeCell,
        setEditing,
      }) as ModularTableContextProps,
    [
      onEnterChangeCell,
      attachmentContext?.isCellLoading,
      attachmentContext?.onAttachmentClick,
      attachmentContext?.onFilesUpload,
    ],
  );

  const rowHeight = PixelSize.S40;

  return (
    <div className={classes.container}>
      <ModularTableContext.Provider value={modularTableContext}>
        <CopyPasteTableManager
          canCreateRow={canCreateRow}
          enableCopy
          enablePaste={allowEdit}
          rows={rows}
          updateRows={updateRows}
          position={position}
          columns={columns}
          isEditing={isEditing}
          maxRows={maxRows}
          tableContext={tableContext}
        >
          <ReactDataGrid<T>
            key={`table-refresher(${refresher})`}
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            // we are required to set the height ourself for auto-height
            // https://github.com/adazzle/react-data-grid/issues/2252
            style={
              fullSize
                ? {}
                : {
                    height:
                      rows.length === 0
                        ? 0
                        : (rows.length + 1) * rowHeight + SCROLLBAR_HEIGHT,
                  }
            }
            className={clsx(
              "rdg-light",
              "rdg-table",
              tableClassName,
              fullSize && classes.fullSize,
              classes.frozenIndicator,
              frozenIndicatorWidth > 0 && classes.displayFrozenIndicator,
            )}
            rowHeight={rowHeight}
            headerRowHeight={36}
            summaryRowHeight={0}
            onRowClick={handleRowClick}
            rowKeyGetter={rowKeyGetter}
            sortColumns={!disableSort ? sortColumns : undefined}
            onSortColumnsChange={!disableSort ? setSortColumns : undefined}
            onRowsChange={onRowsChange}
            onSelectedCellChange={handleSelectedCellChange}
            shouldCloseEditor={shouldCloseEditor}
            enableVirtualization
            onPaste={(event) => {
              return event.targetRow; // Basically we cancel the onPaste from the library
            }}
          />
        </CopyPasteTableManager>
        <PortalHost hostname={`tableDragLayer_${tableIdentifier}`} />
        <PortalHost hostname={`tableDropLayer_${tableIdentifier}`} />
      </ModularTableContext.Provider>
    </div>
  );
};

export const Table = betterMemo(
  <T extends ModularRecord>(
    props: ModularTableProps<T> & {
      fwdRef?: React.Ref<TableHandle>;
      canCreateRow?: boolean;
    } & ModularDisplayExtendedRequirementsProviderProps,
  ) => {
    return (
      <ModularDisplayRequirementsProvider>
        <ModularDisplayExtendedRequirementsProvider
          recordType={props.recordType}
          noCheckboxConfirmation={props.noCheckboxConfirmation}
        >
          <TableComponent
            tableState={props.tableState}
            tableActions={props.tableActions}
            attachmentContext={props.attachmentContext}
            tableContext={props.tableContext}
            tableClassName={props.tableClassName}
            allowEdit={props.allowEdit}
            disablePreview={props.disablePreview}
            editableHeader={props.editableHeader}
            fullSize={props.fullSize}
            disableSort={props.disableSort}
            maxRows={props.maxRows}
            canCreateRow={props.canCreateRow}
          />
        </ModularDisplayExtendedRequirementsProvider>
      </ModularDisplayRequirementsProvider>
    );
  },
  isEqual,
);
