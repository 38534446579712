import { put, takeEvery } from "typed-redux-saga/macro";

import {
  RoomCardActions,
  RoomCardStateActions,
} from "@kraaft/shared/core/modules/roomCard/roomCard.actions";
import { BatchActions } from "@kraaft/shared/core/store/batchingReducer";

function* updateSearchText(
  action: ReturnType<typeof RoomCardActions.updateSearchText>,
) {
  yield* put(
    BatchActions({
      actions: [
        RoomCardStateActions.setHasError({
          filterId: action.payload.filterId,
          hasError: false,
        }),
        RoomCardStateActions.setSearchText({
          filterId: action.payload.filterId,
          searchText: action.payload.value,
        }),
        RoomCardStateActions.resetLoaded({ filterId: action.payload.filterId }),
      ],
    }),
  );
}

export function* updateSearchTextSaga() {
  yield* takeEvery(RoomCardActions.updateSearchText, updateSearchText);
}
