import { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import isEqual from "fast-deep-equal";

import { Hoverable } from "@kraaft/shared/components/hoverable";
import { HoverableRenderProps } from "@kraaft/shared/components/hoverable/types";
import { RoomCardContainer } from "@kraaft/shared/components/roomCard/roomCardContainer";
import { RoomCardWrapperProps } from "@kraaft/shared/components/roomCard/roomCardWrapper/types";
import { selectGeolocationIsHovered } from "@kraaft/shared/core/modules/room/selectors";
import { useRouteService } from "@kraaft/web/src/core/services/navigation/useNavigationService";

import { makeWrapperStyle } from "./roomCardWrapper.styles";

const RoomCardWrapper_ = ({
  roomCard,
  disabled,
  onLongPress,
  onPress,
  onHover,
  description,
  pillColumns,
}: RoomCardWrapperProps) => {
  const classes = makeWrapperStyle();

  const handleHoverIn = useCallback(() => {
    onHover?.(roomCard, true);
  }, [onHover, roomCard]);

  const handleHoverOut = useCallback(() => {
    onHover?.(roomCard, false);
  }, [onHover, roomCard]);

  const routeService = useRouteService();
  const navigationRoomId = routeService.getCurrentRoomId();
  const isMapHovered = useSelector(selectGeolocationIsHovered(roomCard.roomId));
  const isShowingMap = routeService.isRoomsMapOpen();
  const isActive =
    (isShowingMap && isMapHovered) || navigationRoomId === roomCard.roomId;

  const renderContent = useCallback(
    ({ hoverProps, isHovered }: HoverableRenderProps): JSX.Element => (
      <div id="ide2e-roomCard" className={classes.hoverable} {...hoverProps}>
        <RoomCardContainer
          roomCard={roomCard}
          isHovered={isHovered}
          disabled={disabled}
          onLongPress={onLongPress}
          onPress={onPress}
          isActive={isActive}
          description={description}
          pillColumns={pillColumns}
        />
      </div>
    ),
    [
      classes.hoverable,
      roomCard,
      disabled,
      onLongPress,
      onPress,
      isActive,
      description,
      pillColumns,
    ],
  );

  return (
    <Hoverable onHoverIn={handleHoverIn} onHoverOut={handleHoverOut}>
      {renderContent}
    </Hoverable>
  );
};

export const RoomCardWrapper = memo(RoomCardWrapper_, isEqual);
