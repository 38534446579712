import React, { useCallback } from "react";
import clsx from "clsx";

import { Icon, Preloader } from "@kraaft/ui";

import { useStyles } from "./unlabeledTextInput.styles";

export interface UnlabeledTextInputProps
  extends React.HTMLProps<HTMLInputElement> {
  placeholder: string;
  onChangeText: (text: string) => void;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  disabledAutoComplete?: boolean;
  isLoading?: boolean;
  enableClearIcon?: boolean;
}

/**
 * @deprecated you can use the shared version instead
 */
const UnlabeledTextInput = React.forwardRef<
  HTMLInputElement,
  UnlabeledTextInputProps
>(
  (
    {
      defaultValue,
      disabled,
      onBlur,
      onChangeText,
      onFocus,
      placeholder,
      value,
      onKeyPress,
      hasError,
      disabledAutoComplete,
      isLoading,
      enableClearIcon,
      className,
      ...other
    },
    ref,
  ): JSX.Element => {
    const classes = useStyles({ hasError: Boolean(hasError) });

    const onChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        other.onChange?.(event);
        onChangeText(event.target.value);
      },
      [onChangeText, other],
    );

    const handleClearText = useCallback(() => {
      onChange({
        target: { value: "" },
      } as React.ChangeEvent<HTMLInputElement>);
    }, [onChange]);

    return (
      <div className={clsx(classes.inputContainer, className)}>
        <input
          {...other}
          ref={ref}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          className={classes.input}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          defaultValue={defaultValue}
          onKeyUp={onKeyPress}
          {...(disabledAutoComplete
            ? {
                autoComplete: "off",
                "data-lpignore": true,
                "data-form-type": "other",
              }
            : {})}
        />
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <Preloader size="small" />
          </div>
        ) : enableClearIcon && value ? (
          <div className={classes.iconContainer} onClick={handleClearText}>
            <Icon name="x-circle" color="FONT_LOW_EMPHASIS" size="MEDIUM" />
          </div>
        ) : null}
      </div>
    );
  },
);

export { UnlabeledTextInput };
