import { matchPath } from "react-router-dom";
import { History } from "history";
import orderBy from "lodash/orderBy";

import { routes } from "@kraaft/web/src/views/app/appRouter/routes";

export type Route = keyof typeof routes;

const orderedRoutes: [Route, string][] = orderBy(
  Object.entries(routes),
  ([_, path]) => path.length,
  "desc",
) as [Route, string][];

export function getCurrentRoute(navigation: History): Route | undefined {
  for (const [route, path] of orderedRoutes) {
    if (matchPath(navigation.location.pathname, path)) {
      return route;
    }
  }
  return undefined;
}
