import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { useMeshContext } from "@kraaft/helper-hooks";
import { SearchConversationContext } from "@kraaft/shared/components/conversation/searchConversationContext";
import { QuickAccessButton } from "@kraaft/shared/components/conversationDetails/quickAccessSection/quickAccessButton";
import type { Room } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { selectRoomMembersCount } from "@kraaft/shared/core/modules/room/selectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Color, Spacing } from "@kraaft/ui";
import { useOpenItinerary } from "@kraaft/web/src/components/geolocationOpener/useItinerary";

export interface QuickAccessSectionProps {
  room: Room;
}

export const QuickAccessSection = ({ room }: QuickAccessSectionProps) => {
  const { t } = useTranslation();

  const { setSearching } = useMeshContext(SearchConversationContext);
  const navigationService = useNavigationService();

  const membersCount = useSelector(selectRoomMembersCount(room.id));

  const coords =
    room &&
    getExistingRoomRecordField(room.record.properties, "geolocation")?.coords;

  const { openItinerary } = useOpenItinerary({
    coords: coords,
    context: { roomId: room.id, source: "conversation" },
  });

  const handleOnSearch = useCallback(() => {
    trackEvent({
      eventName: "Click on Search",
      room_id: room.id,
      from: "information_panel",
    });
    setSearching(true);
  }, [room, setSearching]);

  const handleOpenItinerary = useCallback(async () => {
    await openItinerary();
  }, [openItinerary]);

  const handleMemberActionPressed = useCallback(() => {
    trackEvent({
      eventName: "View Room Members",
      room_id: room.id,
      room_name: getExistingRoomRecordField(
        room.record.properties,
        "title",
        "",
      ),
      source: "room_info_sheet",
    });
    navigationService.navigate("ConversationSearchMember", {
      roomId: room.id,
    });
  }, [room, navigationService]);

  return (
    <View style={styles.row}>
      <QuickAccessButton
        text={t("conversationInformationSheet.conversationMembers", {
          membersCount,
        })}
        iconName="users-01"
        onPress={handleMemberActionPressed}
      />

      {!!coords && (
        <QuickAccessButton
          text={t("itinerary")}
          iconName="navigation-pointer-01"
          onPress={handleOpenItinerary}
        />
      )}

      <QuickAccessButton
        text={t("searchSimple")}
        iconName="search-md"
        onPress={handleOnSearch}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    backgroundColor: Color.WHITE,
    padding: Spacing.S16,
    justifyContent: "space-around",
  },
});
