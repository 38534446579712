import { t } from "i18next";
import { call, put, select } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { deleteSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { selectSchemaViews } from "@kraaft/shared/core/modules/schemaView/schemaViewSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { navigationService } from "@kraaft/shared/core/services/navigation/provider";

export function* deleteSchemaViewSaga(
  action: ReturnType<typeof deleteSchemaView>,
) {
  const schemaViews = yield* select(selectSchemaViews);

  try {
    const { payload } = action;

    yield* call(Api.deleteSchemaView, {
      id: payload.id,
    });
    const firstSchemaView = schemaViews.find((s) => s.id !== payload.id);
    if (firstSchemaView) {
      navigationService.navigate("SchemaView", {
        schemaViewId: firstSchemaView.id,
      });
    } else {
      navigationService.navigate("Database");
    }
  } catch (e) {
    yield* put(showError({ title: t("errorServer") }));
  }
}
