import { Platform, StyleSheet } from "react-native";

import { authScreensTokens } from "@kraaft/shared/components/auth/authScreenTokens";
import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.WHITE,
    flex: 1,
  },

  titleContainer: {
    marginBottom: authScreensTokens.marginBelowTitlesContainer,
  },

  loginHintPillStyle: {
    marginBottom: Platform.select({ web: Spacing.S16, native: Spacing.S8 }),
  },

  errorContainer: {
    marginVertical: Spacing.S8,
  },

  navigateSpacer: {
    minHeight: Spacing.S12,
    flexBasis: Spacing.S32,
    flexShrink: 1,
  },

  navigateButtonContainer: {
    justifyContent: "center",
    alignItems: "center",
  },

  buttonSpacer: {
    minHeight: Spacing.S12,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: Spacing.S40,
  },

  button: {
    marginBottom: Spacing.S12,
  },
});
