import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize, PixelSize } from "@kraaft/ui";

const useStyles = makeStyles({
  headerContainer: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
    fontSize: FontSize.SMALL,
    fontWeight: 400,
    display: "flex",
    height: PixelSize.S36,
    width: "100%",
    "&:hover $iconContainer": {
      opacity: 1,
    },
  },
  sortedHeaderContainer: {
    fontSize: 13,
    fontWeight: 600,
  },
  resizeHandle: {
    alignSelf: "center",
    marginRight: -2,

    width: 1,
    height: "60%",
    borderRadius: 1,
    backgroundColor: ColorStyle.SEPARATOR,
  },
  textContainer: {
    flexGrow: 1,
    flexBasis: 0,
    flexShrink: 1,
    minWidth: 0,

    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",

    fontSize: FontSize.MEDIUM,

    cursor: "pointer",
  },
  text: {
    display: "flex",
    overflow: "hidden",
  },
  prefix: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  headerIcon: {
    marginRight: 4,
  },
  iconContainer: {
    padding: "0 5px",
    opacity: 0,
  },
});

export { useStyles };
