import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import { globalPermissionRequester } from "@kraaft/shared/components/permissionRequester/globalPermissionRequester";
import { selectAllStoreRehydrated } from "@kraaft/shared/core/modules/memory/memorySelectors";
import { updateSentMessagesCount } from "@kraaft/shared/core/modules/message/send/sendMessageActions";
import {
  PermissionRequestStrategyActions,
  PermissionRequestStrategyStateActions,
} from "@kraaft/shared/core/modules/permissionRequestStrategy/permissionRequestStrategy.actions";
import {
  selectCurrentSessionUserId,
  selectNumberOfSentInvites,
  selectNumberOfSentMessages,
  selectNumberOfSession,
} from "@kraaft/shared/core/modules/permissionRequestStrategy/permissionRequestStrategy.selectors";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

export function* permissionRequestStrategySagas() {
  yield* takeEvery(UserActions.userConnectedToFirebase, detectSessionSaga);

  yield* takeEvery(
    PermissionRequestStrategyActions.numberOfSessionsChanged,
    didStartSessionSaga,
  );
  yield* takeEvery(PermissionRequestStrategyActions.didInvite, didInviteSaga);
  yield* takeEvery(updateSentMessagesCount, didSendMessageSaga);
}

function* detectSessionSaga({
  payload,
}: ReturnType<typeof UserActions.userConnectedToFirebase>) {
  yield* waitFor(selectAllStoreRehydrated);
  const currentSessionUserId = yield* select(selectCurrentSessionUserId);

  if (currentSessionUserId === undefined) {
    yield* put(
      PermissionRequestStrategyStateActions.setCurrentSessionUserId(
        payload.userAuth.uid,
      ),
    );
    yield* put(
      PermissionRequestStrategyStateActions.incrementNumberOfSessions(),
    );
    yield* put(PermissionRequestStrategyActions.numberOfSessionsChanged());
  }
}

function* didStartSessionSaga() {
  yield* waitFor(selectAllStoreRehydrated);
  const numberOfSession = yield* select(selectNumberOfSession);

  const askAtSessionCount = 2;
  if (numberOfSession === askAtSessionCount) {
    yield* call(() =>
      globalPermissionRequester.request(
        "notification",
        "second-session-started",
      ),
    );
  }
}

function* didInviteSaga() {
  yield* waitFor(selectAllStoreRehydrated);
  yield* put(
    PermissionRequestStrategyStateActions.incrementNumberOfSentInvites(),
  );
  const numberOfSentInvites = yield* select(selectNumberOfSentInvites);

  const askAtSentInviteCount = 1;
  if (numberOfSentInvites === askAtSentInviteCount) {
    yield* call(() =>
      globalPermissionRequester.request("notification", "invited-someone"),
    );
  }
}

function* didSendMessageSaga() {
  yield* waitFor(selectAllStoreRehydrated);
  const numberOfSentMessages = yield* select(selectNumberOfSentMessages);

  const askAtSentMessageCount = 1;
  if (numberOfSentMessages === askAtSentMessageCount) {
    yield* call(() =>
      globalPermissionRequester.request("notification", "sent-first-message"),
    );
  }
}
