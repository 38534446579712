import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "react-native";

import { FilePath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { Icon } from "@kraaft/ui";

import { getIcon } from "./shareFilePreviewUtils";

import { styles } from "./shareFilePreview.styles";

interface Props {
  file: ModernFile<FilePath>;
  onClickRemoveFile: () => void;
}

const ShareFilePreview = ({ onClickRemoveFile, file }: Props) => {
  const { t } = useTranslation();

  const icon = getIcon(file.contentType);

  return (
    <View style={styles.main}>
      <Icon name={icon} size="MEDIUM" style={styles.icon} />
      <Text numberOfLines={2} style={styles.name}>
        {file.filename}
      </Text>
      <TouchableOpacity
        accessibilityLabel={t("remove")}
        style={styles.dismissContainer}
        onPress={onClickRemoveFile}
      >
        <Icon name="x-sm" size={10} style={styles.dismissIcon} />
      </TouchableOpacity>
    </View>
  );
};

export { ShareFilePreview };
