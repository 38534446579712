import React, { useRef } from "react";
import { TableCell, TableRow } from "@mui/material";
import isEqual from "fast-deep-equal";
import identity from "lodash/identity";

import { KTableColumn, KTableConfig, KTableContext } from "../kTableTypes";

interface Props<T> {
  rowKey: string;
  handleRowSelected: (rowIndex: number) => (state: boolean) => void;
  rowIndex: number;
  config: KTableConfig<T>;
  item: T;
  isRowSelected: boolean;
  enableHover: boolean;
  tableRowClasses: Record<"hover" | "selected" | "root", string>;
  columns: KTableColumn<T>[];
  tableCellClasses: Record<"root", string>;
}

const KTableRow_ = <T,>({
  rowKey,
  handleRowSelected,
  rowIndex,
  config,
  item,
  isRowSelected,
  enableHover,
  tableRowClasses,
  columns,
  tableCellClasses,
}: Props<T>) => {
  const rowRef = useRef<HTMLTableRowElement>(null);

  const context: KTableContext = {
    selectRow: handleRowSelected(rowIndex),
    rowRef,
  };

  const handleClick = () => !!config.onRowClicked && config.onRowClicked(item);

  const handleMouseEnter = () => !!config.onRowEnter && config.onRowEnter(item);

  const handleMouseLeave = () =>
    !!config?.onRowLeave && config.onRowLeave(item);

  return (
    <TableRow
      data-testid="ide2e-table-row"
      selected={isRowSelected}
      hover={enableHover}
      classes={tableRowClasses}
      style={{ ...config.style?.rows }}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={rowRef}
    >
      {columns.map((column, colIndex) => {
        const colKey = column.id || `${rowKey}-${colIndex}`;
        const getValue = column.value || identity;
        const renderComponent = (): React.ReactNode =>
          column.component
            ? column.component(getValue(item, rowIndex), context)
            : (getValue(item, rowIndex) as React.ReactNode);
        const colStyle: React.CSSProperties = {
          whiteSpace: "nowrap",
          ...config.style?.columns,
          ...column.style?.column,
          width: column.width || undefined,
        };

        return (
          <TableCell
            key={colKey}
            padding="none"
            style={colStyle}
            classes={tableCellClasses}
          >
            {renderComponent()}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export const KTableRow = React.memo(KTableRow_, isEqual) as typeof KTableRow_;
