import { GooglePlaceData } from "react-native-google-places-autocomplete";
import memoize from "lodash/memoize";

import { i18n } from "@kraaft/shared/core/services/i18next";
import { Address, GeoCoordinates } from "@kraaft/shared/core/types";
import { getDeviceTimeZone } from "@kraaft/shared/core/utils";
import { Geocoding } from "@kraaft/shared/core/utils/geocoding";

export async function getAddressWithOptionalData(
  coords: GeoCoordinates,
  data: GooglePlaceData | undefined,
): Promise<Address | undefined> {
  if (data) {
    return {
      description: data.description,
      googlePlaceId: data.place_id,
    };
  }

  const geolocation = await Geocoding.getGeolocationFromCoordinates(
    coords,
    i18n.language,
  );
  return geolocation?.address;
}

export const getDeviceTimeZoneGeoLocation = memoize(async () => {
  const timeZoneCity = getDeviceTimeZone().split("/")[1]?.replace("_", " ");
  if (timeZoneCity) {
    return Geocoding.getGeolocationFromAddress(timeZoneCity);
  }
  return;
});

export function areGeoCoordinatesDifferent(
  coords1: GeoCoordinates | undefined,
  coords2: GeoCoordinates | undefined,
) {
  const checkDecimalSize = 6;

  if (!coords1?.latitude && !coords2?.latitude) {
    return false;
  }

  if (!coords1?.latitude || !coords2?.latitude) {
    return true;
  }

  return (
    coords1.latitude.toFixed(checkDecimalSize) !==
      coords2.latitude.toFixed(checkDecimalSize) &&
    coords1.longitude.toFixed(checkDecimalSize) !==
      coords2.longitude.toFixed(checkDecimalSize)
  );
}
