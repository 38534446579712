import { compact } from "lodash";

import {
  Directory as RoomDirectory,
  DIRECTORY_MAX_DEPTH,
  ROOT_DIRECTORY_ID,
} from "@kraaft/shared/core/modules/directory/directory";
import { uuid } from "@kraaft/shared/core/utils";
import {
  Directory,
  DirectoryTreeStructure,
  HoveredBetweenDirectoryInfoPlacement,
  HoveredDirectoryTreeInfo,
  NormalizedDirectories,
} from "@kraaft/web/src/components/directoryTree/directoryTree.types";
import { SerializedDirectory } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminState";

const updateDeepestChildDepthForTree = (
  directories: Record<string, Directory>,
) => {
  for (const directory of Object.values(directories)) {
    directory.deepestChildDepth = 0;
  }

  const directoryTreeLeaves = getDirectoyTreeLeaves(directories);
  for (const directoryLeaf of directoryTreeLeaves) {
    updateDeepestChildDepthForAncestors(directories, directoryLeaf);
  }
};

const updateDeepestChildDepthForAncestors = (
  directories: Record<string, Directory>,
  directory: Directory,
) => {
  if (directory.parentDirectoryId === undefined) {
    return;
  }
  let parentDirectory = directories[directory.parentDirectoryId];
  let relativeDepth = 1;

  let counter = 0;
  while (
    parentDirectory !== undefined &&
    relativeDepth > parentDirectory.deepestChildDepth &&
    counter <= DIRECTORY_MAX_DEPTH
  ) {
    parentDirectory.deepestChildDepth = Math.max(
      relativeDepth,
      parentDirectory.deepestChildDepth,
    );
    if (parentDirectory.parentDirectoryId === undefined) {
      break;
    }

    parentDirectory = directories[parentDirectory.parentDirectoryId];

    relativeDepth += 1;
    counter += 1;
  }
};

const updateDepthForChildren = (
  directories: Record<string, Directory>,
  directoryId: string,
  depth = 0,
) => {
  const directory = directories[directoryId];

  if (directory === undefined) {
    return;
  }

  directory.depth = depth;
  if (directory.childDirectoryIds.length > 0) {
    for (const childDirectoryId of directory.childDirectoryIds) {
      updateDepthForChildren(directories, childDirectoryId, depth + 1);
    }
  }
};

const getDirectoyTreeLeaves = (
  directories: Record<string, Directory>,
): Directory[] =>
  Object.values(directories).filter(
    (directory) => directory.childDirectoryIds.length === 0,
  );

const extractDirectoryData = (
  serializedDirectory: SerializedDirectory,
  parentDirectoryId: string | undefined,
  depth: number,
  accumulator: Record<string, Directory> = {},
): typeof accumulator => {
  const directory: Directory = {
    id: serializedDirectory.id,
    name: serializedDirectory.name,
    childDirectoryIds: serializedDirectory.directories?.map((d) => d.id) ?? [],
    parentDirectoryId,
    deepestChildDepth: 0,
    depth,
  };

  accumulator[serializedDirectory.id] = directory;

  if (serializedDirectory.directories === undefined) {
    if (parentDirectoryId) {
      updateDeepestChildDepthForAncestors(accumulator, directory);
    }

    return accumulator;
  }

  return serializedDirectory.directories
    .map((childStoreDirectory) =>
      extractDirectoryData(
        childStoreDirectory,
        serializedDirectory.id,
        depth + 1,
        accumulator,
      ),
    )
    .reduce((acc, currentStoreDirectory) => {
      for (const [key, value] of Object.entries(currentStoreDirectory)) {
        acc[key] = value;
      }

      return acc;
    }, accumulator);
};

export const normalizeSerializedDirectories = (
  serializedDirectories: SerializedDirectory[],
): DirectoryTreeStructure => {
  const directoryTreeStructure: DirectoryTreeStructure = {
    rootDirectoryIds: [],
    directories: {},
  };

  for (const storeDirectory of serializedDirectories) {
    directoryTreeStructure.rootDirectoryIds.push(storeDirectory.id);
    directoryTreeStructure.directories = {
      ...directoryTreeStructure.directories,
      ...extractDirectoryData(storeDirectory, undefined, 0),
    };
  }

  return directoryTreeStructure;
};

const extendArrayIfNeeded = (array: string[], index: number) => {
  if (array.length < index) {
    return [...array, ..."missing_id".repeat(index - array.length)];
  }
  return array;
};

export const normalizeRoomDirectories = (
  roomDirectories: RoomDirectory[],
): DirectoryTreeStructure => {
  let rootDirectoryIds: DirectoryTreeStructure["rootDirectoryIds"] = [];
  const directories: DirectoryTreeStructure["directories"] = {};

  for (const directory of roomDirectories) {
    directories[directory.id] = {
      id: directory.id,
      name: directory.name,
      childDirectoryIds: [],
      parentDirectoryId:
        directory.parentId !== ROOT_DIRECTORY_ID
          ? directory.parentId
          : undefined,
      depth: directory.depth,
      deepestChildDepth: directory.deepestChildDepth,
    };
  }

  for (const directory of roomDirectories) {
    if (directory.parentId !== ROOT_DIRECTORY_ID) {
      const parentFolder = directories[directory.parentId];

      if (parentFolder !== undefined) {
        parentFolder.childDirectoryIds = extendArrayIfNeeded(
          parentFolder.childDirectoryIds,
          directory.index,
        );

        parentFolder.childDirectoryIds[directory.index] = directory.id;
      } else {
        // Never happens
      }
    } else {
      rootDirectoryIds = extendArrayIfNeeded(rootDirectoryIds, directory.index);

      rootDirectoryIds[directory.index] = directory.id;
    }
  }

  return { rootDirectoryIds, directories };
};

export const hashSerializeDirectories = (
  serializedDirectories: SerializedDirectory[],
  namePrefix = "",
  pathList: string[] = [],
): string[] => {
  for (const serializedDirectory of serializedDirectories) {
    const hashPath = `${namePrefix}/${serializedDirectory.name}`;
    if (serializedDirectory.directories) {
      hashSerializeDirectories(
        serializedDirectory.directories,
        hashPath,
        pathList,
      );
    }
    pathList.push(hashPath);
  }

  return pathList;
};

const serializeChildDirectory = (
  directory: Directory,
  directories: NormalizedDirectories,
): SerializedDirectory => {
  if (directory.childDirectoryIds.length > 0) {
    return {
      id: directory.id,
      name: directory.name,
      directories: compact(
        directory.childDirectoryIds.map((childDirectoryId) => {
          const childDirectory = directories[childDirectoryId];

          if (childDirectory) {
            return serializeChildDirectory(childDirectory, directories);
          }
          return undefined;
        }),
      ),
    };
  }
  return {
    id: directory.id,
    name: directory.name,
    directories: undefined,
  };
};

export const serializeDirectories = (
  directoryTreeStructure: DirectoryTreeStructure,
): SerializedDirectory[] => {
  const serializedDirectories: SerializedDirectory[] = [];

  for (const directoryId of directoryTreeStructure.rootDirectoryIds) {
    const directory = directoryTreeStructure.directories[directoryId];

    if (directory !== undefined) {
      serializedDirectories.push(
        serializeChildDirectory(directory, directoryTreeStructure.directories),
      );
    }
  }

  return serializedDirectories;
};

function removeDirectoryIdFromArray(array: string[], directoryId: string) {
  array.splice(array.indexOf(directoryId), 1);
}

function insertDirectoryIdInArray(
  array: string[],
  directoryId: string,
  siblingDirectoryId: string,
  placement: HoveredBetweenDirectoryInfoPlacement,
) {
  const newDirectoryIndex = array.indexOf(siblingDirectoryId);

  if (newDirectoryIndex < 0) {
    return;
  }

  array.splice(
    placement === "before" ? newDirectoryIndex : newDirectoryIndex + 1,
    0,
    directoryId,
  );
}

function getDirectoryFromMap(
  directoryMap: DirectoryTreeStructure["directories"],
  directoryId: string,
) {
  const foundDirectory = directoryMap[directoryId];

  if (!foundDirectory) {
    throw new Error(
      `getDirectoryFromMap :: directory ${directoryId} not found`,
    );
  }

  return foundDirectory;
}

export const optimisticMoveDirectory = (
  directoryTreeStructure: DirectoryTreeStructure,
  directory: Directory,
  info: HoveredDirectoryTreeInfo,
): DirectoryTreeStructure => {
  try {
    const foundDirectory = getDirectoryFromMap(
      directoryTreeStructure.directories,
      directory.id,
    );

    if (foundDirectory.parentDirectoryId === undefined) {
      removeDirectoryIdFromArray(
        directoryTreeStructure.rootDirectoryIds,
        directory.id,
      );
    } else {
      const parentDirectory = getDirectoryFromMap(
        directoryTreeStructure.directories,
        foundDirectory.parentDirectoryId,
      );

      removeDirectoryIdFromArray(
        parentDirectory.childDirectoryIds,
        directory.id,
      );
    }

    foundDirectory.parentDirectoryId = info.parentDirectoryId;

    if (info.type === "between") {
      if (info.parentDirectoryId === undefined) {
        insertDirectoryIdInArray(
          directoryTreeStructure.rootDirectoryIds,
          directory.id,
          info.siblingDirectoryId,
          info.placement,
        );
      } else {
        const parentDirectory = getDirectoryFromMap(
          directoryTreeStructure.directories,
          info.parentDirectoryId,
        );

        insertDirectoryIdInArray(
          parentDirectory.childDirectoryIds,
          directory.id,
          info.siblingDirectoryId,
          info.placement,
        );
        updateDepthForChildren(
          directoryTreeStructure.directories,
          directory.id,
          parentDirectory.depth + 1,
        );
      }
    } else {
      const parentDirectory = getDirectoryFromMap(
        directoryTreeStructure.directories,
        info.parentDirectoryId,
      );

      parentDirectory.childDirectoryIds.push(directory.id);
      updateDepthForChildren(
        directoryTreeStructure.directories,
        directory.id,
        parentDirectory.depth + 1,
      );
    }

    updateDeepestChildDepthForTree(directoryTreeStructure.directories);

    return { ...directoryTreeStructure };
  } catch (e) {
    console.error(e);
    return directoryTreeStructure;
  }
};

export const optimisticRenameDirectory = (
  directoryTreeStructure: DirectoryTreeStructure,
  directoryId: string,
  newName: string,
) => {
  try {
    const directory = getDirectoryFromMap(
      directoryTreeStructure.directories,
      directoryId,
    );

    if (directory.isTemporary) {
      directory.isTemporary = false;
    }

    directory.name = newName;

    return { ...directoryTreeStructure };
  } catch (e) {
    console.error(e);
    return directoryTreeStructure;
  }
};

export const optimisticCreateTemporaryDirectory = (
  directoryTreeStructure: DirectoryTreeStructure,
  parentDirectoryId: string | undefined,
) => {
  try {
    const newDirectoryId = uuid();
    const newDirectory: Directory = {
      id: newDirectoryId,
      name: "",
      parentDirectoryId,
      childDirectoryIds: [],
      deepestChildDepth: 0,
      depth: 0,

      isTemporary: true,
    };
    directoryTreeStructure.directories[newDirectoryId] = newDirectory;

    if (parentDirectoryId) {
      const parentDirectory = getDirectoryFromMap(
        directoryTreeStructure.directories,
        parentDirectoryId,
      );

      if (parentDirectory.depth + 1 > DIRECTORY_MAX_DEPTH) {
        return directoryTreeStructure;
      }

      newDirectory.depth = parentDirectory.depth + 1;

      updateDeepestChildDepthForAncestors(
        directoryTreeStructure.directories,
        newDirectory,
      );

      if (parentDirectory.childDirectoryIds) {
        parentDirectory.childDirectoryIds.push(newDirectoryId);
      }
    } else {
      directoryTreeStructure.rootDirectoryIds.push(newDirectoryId);
    }

    return { ...directoryTreeStructure };
  } catch (e) {
    console.error(e);
    return directoryTreeStructure;
  }
};

const recursivelyDeleteSubDirectory = (
  directories: NormalizedDirectories,
  directoryIds: string[],
) => {
  for (const directoryId of directoryIds) {
    const directory = getDirectoryFromMap(directories, directoryId);
    if (directory.childDirectoryIds) {
      recursivelyDeleteSubDirectory(directories, directory.childDirectoryIds);
      delete directories[directoryId];
    }
  }
};

export const optimisticRemoveDirectory = (
  directoryTreeStructure: DirectoryTreeStructure,
  directoryId: string,
) => {
  try {
    const rootDirectoryIndex =
      directoryTreeStructure.rootDirectoryIds.indexOf(directoryId);

    if (rootDirectoryIndex >= 0) {
      directoryTreeStructure.rootDirectoryIds.splice(rootDirectoryIndex, 1);
    }

    recursivelyDeleteSubDirectory(directoryTreeStructure.directories, [
      directoryId,
    ]);

    return { ...directoryTreeStructure };
  } catch (e) {
    console.error(e);
    return directoryTreeStructure;
  }
};
