import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useMeshContext } from "@kraaft/helper-hooks";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { KSchemaElement } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { SchemaBuilderContext } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

export function useDuplicateActionItems(element: KSchemaElement) {
  const { t } = useTranslation();

  const { duplicate } = useMeshContext(SchemaBuilderContext);

  const internDuplicate = useCallback(() => {
    duplicate(element.key);
  }, [duplicate, element.key]);

  return useMemo<ActionSheetItem[]>(
    () => [
      {
        label: t("formBuilder.schema.duplicate"),
        onPress: internDuplicate,
      },
    ],
    [internDuplicate, t],
  );
}
