import { useKeyPressEvent } from "react-use";

import { RoomDetailsLayout } from "@kraaft/shared/components/createRoom/roomDetails/roomDetails.layout";

export const ADD_CONVERSATION_DETAILS_PORTAL_HOSTNAME =
  "add-conversation-details";

interface RoomDetailsProps {
  poolId: string;
  roomId: string;
  onClose: () => void;
}

const RoomDetails = (props: RoomDetailsProps) => {
  const { poolId, roomId, onClose } = props;

  useKeyPressEvent("Enter", onClose);

  return (
    <RoomDetailsLayout
      poolId={poolId}
      roomId={roomId}
      onSubmit={onClose}
      portalHostname={ADD_CONVERSATION_DETAILS_PORTAL_HOSTNAME}
    />
  );
};

export { RoomDetails };
