import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  View,
} from "react-native";

import { KInput, KInputHandle } from "@kraaft/shared/components/input/KInput";
import { Api } from "@kraaft/shared/core/services/api";
import { Firebase } from "@kraaft/shared/core/services/firebase";
import { onlyDigits } from "@kraaft/shared/core/utils";
import { Button, NonAccessibleText, Preloader } from "@kraaft/ui";

import { styles } from "./enterUsername.styles";

const useOnPressEnter = (fn: () => void) =>
  useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (e.nativeEvent.key === "Enter") {
        e.preventDefault();
        fn();
      }
    },
    [fn],
  );

const EnterUsername = () => {
  const { t } = useTranslation();
  const inputLastNameRef = useRef<KInputHandle>(null);

  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [working, setWorking] = useState(false);

  const handleNext = async () => {
    try {
      if (
        onlyDigits(firstName) ||
        onlyDigits(lastName) ||
        !firstName ||
        !lastName
      ) {
        throw Error(t("lastStepDigitError"));
      }

      setWorking(true);

      const firebaseAuthUser = Firebase.auth().currentUser;

      if (!firebaseAuthUser) {
        throw new Error("Cannot get current authenticated user");
      }

      await Api.createUser({
        firstName: firstName,
        lastName: lastName,
      });
    } catch (err) {
      setError(true);
      setErrorMessage(err.message);
    } finally {
      setWorking(false);
    }
  };

  const changeFirstName = (text: string) => {
    setFirstname(text);
    setError(false);
    setErrorMessage("");
  };

  const changeLastName = (text: string) => {
    setLastname(text);
    setError(false);
    setErrorMessage("");
  };

  const submitFirstName = () => {
    inputLastNameRef.current?.focus();
  };

  return (
    <View style={styles.container}>
      {working && (
        <Preloader absoluteFill backgroundColor="rgba(255, 255, 255, 0.5)" />
      )}
      <NonAccessibleText weight="bold" size="H1" style={styles.title}>
        {t("lastStepTitle")}
      </NonAccessibleText>
      <View style={styles.firstNameInputContainer}>
        <KInput
          disableAccessibility
          disableAutocomplete
          label={t("lastStepLabelFirstName")}
          value={firstName}
          onChangeText={changeFirstName}
          error={error}
          autoFocus
          keyboardType="default"
          clearButtonMode="while-editing"
          returnKeyType="next"
          onKeyPress={useOnPressEnter(submitFirstName)} // use onKeyPress because on Android Chrome, onSubmitEditing is not called
          onSubmitEditing={submitFirstName}
          nativeID="ide2e-lastStep-firstName"
        />
      </View>
      <KInput
        disableAccessibility
        disableAutocomplete
        label={t("lastStepLabelLastName")}
        value={lastName}
        onChangeText={changeLastName}
        error={error}
        keyboardType="default"
        returnKeyType="go"
        clearButtonMode="while-editing"
        onSubmitEditing={handleNext}
        onKeyPress={useOnPressEnter(handleNext)} // use onKeyPress because on Android Chrome, onSubmitEditing is not called
        ref={inputLastNameRef}
        nativeID="ide2e-lastStep-lastName"
      />
      {error && (
        <View style={styles.errorContainer}>
          <NonAccessibleText size="SMALL" color="ACTION_DESCTRUCTIVE">
            {errorMessage ?? t("lastStepUnknownError")}
          </NonAccessibleText>
        </View>
      )}
      <View style={styles.spacer} />
      <Button
        id="ide2e-lastStep-submit"
        disableAccessibility
        onPress={handleNext}
        disabled={!firstName || !lastName}
        text={t("lastStepButton")}
      />
    </View>
  );
};

export { EnterUsername };
