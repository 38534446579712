import { Dictionary } from "ts-essentials";

import { Color, Icon, IconName } from "@kraaft/ui";

interface Props {
  onClick: () => void;
  icon?: IconName;
}

const SplitScreenBackButton = (props: Props) => {
  return (
    <button onClick={props.onClick} style={styles.button}>
      <Icon name={props.icon ?? "chevron-left"} size="MEDIUM" color="BLACK" />
    </button>
  );
};

const styles: Dictionary<React.CSSProperties> = {
  button: {
    backgroundColor: Color.TRANSPARENT,
    border: 0,
    cursor: "pointer",

    padding: "8px 12px",
    margin: "-8px -12px",
  },
};

export { SplitScreenBackButton };
