import React, { useImperativeHandle, useRef } from "react";
import { noop } from "ts-essentials";

import { KInput, KInputHandle } from "@kraaft/shared/components/input/KInput";
import { useCopyButton } from "@kraaft/web/src/components/copyButton/copyButton";

interface InviteLinkInputProps {
  label: string;
  link: string;
}

export interface InviteLinkInputHandle extends KInputHandle {
  copyToClipboard: () => void;
}

export const InviteLinkInput = React.forwardRef<
  InviteLinkInputHandle,
  InviteLinkInputProps
>(({ label, link }, forwardedRef) => {
  const { copyToClipboard, icon: rightCopyIcon } = useCopyButton({
    value: link,
  });

  const kInputRef = useRef<KInputHandle>(null);

  useImperativeHandle(forwardedRef, () => ({
    copyToClipboard: copyToClipboard,
    blur: () => kInputRef.current?.blur(),
    focus: () => kInputRef.current?.focus(),
  }));

  return (
    <KInput
      ref={kInputRef}
      label={label}
      value={link}
      onChangeText={noop}
      editable={false}
      onFocus={copyToClipboard}
      rightIcon={rightCopyIcon}
      selectTextOnFocus
    />
  );
});
