import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LayoutChangeEvent,
  SectionListData,
  SectionListRenderItemInfo,
  Text,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { VirtualElement } from "@floating-ui/react";
import { flatten, isEmpty } from "lodash/fp";

import { isNative } from "@kraaft/helper-functions";
import { SelectionState } from "@kraaft/shared/components/checkbox/checkbox";
import { SectionListWithEmptyState } from "@kraaft/shared/components/emptyState/lists/sectionList";
import { MessageLimitReached } from "@kraaft/shared/components/freemium/messageLimitReached";
import { MonthHeader } from "@kraaft/shared/components/galleries/monthHeader";
import { PhotoListItem } from "@kraaft/shared/components/galleries/photoGallery/photoListItem";
import { useSelectionContextMenu } from "@kraaft/shared/components/galleries/photoGallery/useSelectionContextMenu";
import {
  MiniImageLine,
  PhotoGalleryLineContraints,
  useSortedGallery,
} from "@kraaft/shared/components/galleries/photoGallery/useSortedGallery";
import { MapButtonContainer } from "@kraaft/shared/components/mapButton/container";
import { selectIsLoading } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import {
  getDownloadMediaLoadingId,
  getShareMediaLoadingId,
} from "@kraaft/shared/core/modules/media/media.actions";
import { deselectMessage } from "@kraaft/shared/core/modules/message/messageActions";
import {
  selectMessageSelection,
  selectMessageSelectionSource,
} from "@kraaft/shared/core/modules/message/messageSelectors";
import {
  MiniMediaActions,
  MiniMediaStateActions,
} from "@kraaft/shared/core/modules/miniMedia/miniMedia.actions";
import { selectMiniMediasIsLimitedResultForType } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { MiniImage } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { useIsRoomFreemium } from "@kraaft/shared/core/modules/room/useIsRoomFreemium";
import { AnyUnexplained } from "@kraaft/shared/core/types";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";
import { useAndroidBackHandler } from "@kraaft/shared/core/utils/useAndroidBackHandler";
import { SCROLLBAR_WIDTH } from "@kraaft/shared/core/utils/useInitScrollbarWidth";
import { useKeyPressed } from "@kraaft/shared/core/utils/useKeyPressed";
import { useSafeAreaInsets } from "@kraaft/shared/core/utils/useSafeAreaInsets";
import { Spacing } from "@kraaft/ui";

import { styles } from "./photoGallery.styles";

export interface PhotoGalleryProps {
  roomId: string;
  imageHeights: PhotoGalleryLineContraints;
  medias: MiniImage[];
  mode?: "default" | "selection";
  backgroundColor: string;
  areMediaFiltered?: boolean;
  showMapButton?: boolean;
  onRightClick?: (virtualElement: VirtualElement) => void;
}

const PhotoGallery_ = ({
  roomId,
  medias: mediasToDisplay,
  imageHeights,
  mode = "default",
  backgroundColor,
  areMediaFiltered,
  showMapButton = false,
  onRightClick,
}: PhotoGalleryProps) => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(0);

  const { bottom: bottomInset } = useSafeAreaInsets();

  const limitForFreemium = useIsRoomFreemium(roomId);

  const isLimitedResult = useSelector(
    selectMiniMediasIsLimitedResultForType(roomId, "image"),
  );

  const availableWidth = width - SCROLLBAR_WIDTH - Spacing.S16; // P16 is the padding right of the list

  const { sortedMonths: months, sortedMedias: medias } = useSortedGallery(
    mediasToDisplay,
    imageHeights,
    availableWidth,
  );
  const { selection } = useSelector(
    selectMessageSelection(roomId, "photoGallery"),
  );
  const selectionSource = useSelector(selectMessageSelectionSource(roomId));
  const shouldDisplayCheckbox =
    mode === "selection" ? true : !isEmpty(selection);

  const downloadBulkMediaLoadingId = getDownloadMediaLoadingId(roomId);
  const shareBulkMediaLoadingId = getShareMediaLoadingId(roomId);
  const isDownloading = useSelector(
    selectIsLoading(downloadBulkMediaLoadingId),
  );
  const isSharing = useSelector(selectIsLoading(shareBulkMediaLoadingId));
  const isDisabled = isDownloading || isSharing;

  const { t } = useTranslation();

  const { allowSelection, handleHover, handleSelect } =
    useMediaSelectionContext({ roomId, medias, type: "image" });

  useEffect(() => {
    dispatch(
      MiniMediaActions.subscribeToMiniMedias({ roomId, limitForFreemium }),
    );

    return () => {
      dispatch(
        MiniMediaActions.unsubscribeFromMiniMedias({
          roomId,
          limitForFreemium,
        }),
      );
    };
  }, [dispatch, limitForFreemium, roomId]);

  const handleSingleSelect = useCallback(
    (messageId: string, newStatus: boolean) => {
      handleSelect([messageId], newStatus);
    },
    [handleSelect],
  );

  const onPhotoPress = useCallback(
    (messageId: string) => {
      if (mode === "default") {
        dispatch(
          MiniMediaStateActions.openMiniMediaCarousel({
            roomId,
            messageId,
            mediaIds: medias.map((m) => m.messageId),
          }),
        );
      } else {
        handleSingleSelect(messageId, true);
      }
    },
    [dispatch, handleSingleSelect, medias, mode, roomId],
  );

  const { registerRef } = useSelectionContextMenu({
    onOpen: onRightClick,
    selection,
  });

  const renderItem = useCallback(
    ({ item: line }: SectionListRenderItemInfo<MiniImageLine>) => {
      return (
        <View style={styles.images}>
          {line.images.map((image) => (
            <View
              key={image.id}
              style={styles.wrapper}
              ref={registerRef(image.messageId)}
            >
              <PhotoListItem
                onPress={onPhotoPress}
                shouldDisplayCheckbox={shouldDisplayCheckbox}
                imageHeight={line.lineHeight}
                image={image}
                allowSelection={allowSelection}
                onSelect={handleSingleSelect}
                onHover={handleHover}
                selected={allowSelection && Boolean(selection[image.messageId])}
                disabled={isDisabled}
              />
            </View>
          ))}
        </View>
      );
    },
    [
      registerRef,
      onPhotoPress,
      shouldDisplayCheckbox,
      allowSelection,
      handleSingleSelect,
      handleHover,
      selection,
      isDisabled,
    ],
  );

  const handleViewWidth = useCallback((event: LayoutChangeEvent) => {
    setWidth(event.nativeEvent.layout.width);
  }, []);

  const getSelectionState = useCallback(
    (monthId: string): SelectionState => {
      const month = months.find((m) => m.id === monthId);
      if (!month) {
        return "none";
      }
      let state: SelectionState = "all";
      let count = 0;
      for (const line of month.data) {
        for (const image of line.images) {
          if (!selection[image.messageId]) {
            state = "some";
          } else {
            count += 1;
          }
        }
      }
      if (count === 0) {
        return "none";
      }
      return state;
    },
    [months, selection],
  );

  const onSelectWholeMonth = useCallback(
    (monthId: string) => {
      const selected = getSelectionState(monthId);
      const month = months.find((m) => m.id === monthId);

      if (!month) {
        return;
      }

      const idsToSelect = month.data.map((imageLine) =>
        imageLine.images.map((image) => image.messageId),
      );
      handleSelect(flatten(idsToSelect), selected !== "all");
    },
    [getSelectionState, handleSelect, months],
  );

  const headerStyle = useMemo(
    () => [styles.header, { backgroundColor }],
    [backgroundColor],
  );

  // Section list does not understand the section type
  const renderHeader = useCallback(
    ({ section }: { section: SectionListData<MiniImageLine> }) => (
      <View style={headerStyle}>
        <MonthHeader
          selectionSource={selectionSource}
          wantedSelectionSource="photoGallery"
          section={section as AnyUnexplained}
          onSelectMonth={onSelectWholeMonth}
          selectionState={getSelectionState((section as AnyUnexplained).id)}
          checkboxSide={isNative() ? "right" : "left"}
        />
      </View>
    ),
    [getSelectionState, headerStyle, onSelectWholeMonth, selectionSource],
  );

  const deselectAll = useCallback(() => {
    dispatch(deselectMessage({ roomId, all: true }));
  }, [dispatch, roomId]);

  useKeyPressed("Escape", deselectAll);
  useAndroidBackHandler(
    useCallback(() => {
      if (Object.keys(selection).length === 0) {
        return false;
      }
      deselectAll();
      return true;
    }, [deselectAll, selection]),
  );

  const sectionListHeader = useMemo(
    () =>
      mode === "default" &&
      (areMediaFiltered || isLimitedResult || medias.length > 0) ? (
        <>
          {showMapButton && (
            <View style={styles.mapButton}>
              <MapButtonContainer
                type="photos"
                context={{ location: "PhotoGallery", roomId }}
              />
            </View>
          )}
          {areMediaFiltered && (
            <View style={styles.helperTextContainer}>
              <Text style={styles.helperText}>{t("filteredMapMedias")}</Text>
            </View>
          )}
        </>
      ) : null,
    [
      t,
      mode,
      showMapButton,
      areMediaFiltered,
      isLimitedResult,
      medias.length,
      roomId,
    ],
  );

  const sectionListFooter = useMemo(() => {
    if (isLimitedResult) {
      return <MessageLimitReached from="photo_gallery" />;
    }

    return null;
  }, [isLimitedResult]);

  const keyExtractor = useCallback(
    (line: MiniImageLine) => line.images.map((image) => image.id).join(","),
    [],
  );

  const disableVirtualization = !isNative();

  const contentContainerStyle = useMemo(
    () => [styles.sectionListContent, { paddingBottom: bottomInset }],
    [bottomInset],
  );

  return (
    <View style={styles.root}>
      <SectionListWithEmptyState
        type="photos"
        style={styles.sectionList}
        contentContainerStyle={contentContainerStyle}
        keyExtractor={keyExtractor}
        sections={months}
        disableVirtualization={disableVirtualization}
        isEmptyStateEnabled={
          /* the empty state is triggered if the width isn't set, and it may stay as an overlay on android */
          !areMediaFiltered && !isLimitedResult && width > 0
        }
        scrollEnabled={medias.length > 0}
        stickySectionHeadersEnabled
        renderItem={renderItem}
        renderSectionHeader={renderHeader}
        onLayout={handleViewWidth}
        ListFooterComponent={sectionListFooter}
        ListHeaderComponent={sectionListHeader}
        windowSize={11}
      />
    </View>
  );
};

export const PhotoGallery = React.memo(PhotoGallery_) as typeof PhotoGallery_;
