import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { ModularSchemaList } from "@kraaft/shared/components/actionCardList/modularSchemaList";
import { DetailsSection } from "@kraaft/shared/components/conversationDetails/detailsSection";
import { SectionHeader } from "@kraaft/shared/components/conversationDetails/sectionHeader";
import { SchemaListSectionHeaderActions } from "@kraaft/shared/components/conversationDetails/worksiteFolder/schemaListSection/schemaListSectionHeaderActions";
import { PromotionCard } from "@kraaft/shared/components/promotionCard";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";

import { styles } from "../section.styles";

interface Props {
  roomId: string;
}

const SchemaListSection = (props: Props) => {
  const { roomId } = props;

  const { t } = useTranslation();

  const navigationService = useNavigationService();

  const goToModularFolders = (schemaId: string) => {
    navigationService.navigate("ModularFolders", {
      roomId,
      schemaId: schemaId,
    });
  };

  return (
    <DetailsSection>
      <SectionHeader
        title={t("worksiteFolder.formsSectionTitle")}
        titleElementId="room-schema-section"
      >
        <SchemaListSectionHeaderActions roomId={roomId} />
      </SectionHeader>
      <View style={styles.sectionContent}>
        <ModularSchemaList roomId={roomId} onPress={goToModularFolders} />
      </View>
      <PromotionCard
        variant="library"
        source="room_info_tab"
        withBorderRadius
      />
    </DetailsSection>
  );
};

export { SchemaListSection };
