export const animationDurations = {
  ACTION_SHEET: 150,
  TAB_BAR_HIDING: 100,
  WAIT_FOR_CREATE_POOL: 2000,
  ADD_ROOM_MORPH: 150,
  COLLAPSIBLE: 150,
  ROTATE_WITH_DEVICE: 175,
  CAMERA_APPEAR_FADE_DELAY: 200,
  CAMERA_FORMAT_MORPH: 200,
  CAMERA_FORMAT_FADE_DELAY: 500,

  CAMERA_FADE_DELAY: 800,
  CAMERA_FADE_DURATION: 500,
  CAMERA_BORDER_DELAY: 300,
  CAMERA_BORDER_DURATION: 300,
  CAMERA_SNAP_DURATION: 100,

  CAMERA_PREVIEW_CAROUSEL: 300,
  CAMERA_BUTTON_MORPH: 200,
  QUICK_SCALE: 175,

  ANCHORED_SHEET: 150,
  POPUP_SHEET: 150,
};
