import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import {
  ReactionDescription,
  ReactionsDescription,
} from "@kraaft/shared/components/message/reactions/reactionPills";
import { ReactionCountPill } from "@kraaft/shared/components/message/reactions/reactionSheet/reactionCountPill";
import { ReactionLine } from "@kraaft/shared/components/message/reactions/reactionSheet/reactionLine";
import { Sheet, Spacing, Text } from "@kraaft/ui";

interface ReactionsDetailsSheetProps {
  reactions: ReactionsDescription;
}

function orderReactionDescriptionsEntriesByCount(
  [, a]: [index: string, reaction: ReactionDescription],
  [, b]: [index: string, reaction: ReactionDescription],
) {
  return b.count - a.count;
}

export const ReactionsDetailsSheet = Sheet({
  native: "bottom",
  web: "anchored",
}).create<ReactionsDetailsSheetProps>(
  ({ Paper, Content, Header }) =>
    ({ onClose, reactions }) => {
      const [filter, setFilter] = useState<string | undefined>(undefined);
      const { t } = useTranslation();

      const orderedReactions = useMemo(
        () =>
          Object.entries(reactions).sort(
            orderReactionDescriptionsEntriesByCount,
          ),
        [reactions],
      );

      const totalReactionCount = useMemo(
        () =>
          Object.values(reactions).reduce((acc, curr) => acc + curr.count, 0),
        [reactions],
      );

      const filtered = useMemo(
        () =>
          filter
            ? reactions[filter]?.names.map((name) => ({
                name,
                emoji: filter,
              })) ?? []
            : Object.entries(reactions).reduce<
                Array<{ name: string; emoji: string }>
              >((acc, [emoji, { names }]) => {
                acc.push(...names.map((name) => ({ name, emoji })));
                return acc;
              }, []),
        [filter, reactions],
      );

      return (
        <Paper>
          <Header onClose={onClose}>
            <Text>{t("reactions.title")}</Text>
          </Header>
          <Content noPadding>
            <View style={styles.root}>
              <ScrollView
                horizontal
                style={styles.scrollview}
                contentContainerStyle={styles.scrollviewContent}
                showsHorizontalScrollIndicator={false}
              >
                <Pressable
                  onPress={() => setFilter(undefined)}
                  accessibilityLabel={t("reactions.seeAll")}
                >
                  <ReactionCountPill
                    emoji={t("reactions.all")}
                    count={totalReactionCount}
                    selected={filter === undefined}
                  />
                </Pressable>
                {orderedReactions.map(([emoji, { count }]) => (
                  <Pressable
                    key={emoji}
                    onPress={() => setFilter(emoji)}
                    accessibilityLabel={t("reactions.filterBy", {
                      emoji: filter,
                    })}
                  >
                    <ReactionCountPill
                      emoji={emoji}
                      count={count}
                      selected={filter === emoji}
                    />
                  </Pressable>
                ))}
              </ScrollView>
              <ScrollView
                showsVerticalScrollIndicator={false}
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={styles.reactionContent}
              >
                {filtered?.map(({ name, emoji }) => (
                  <ReactionLine key={name} name={name} emoji={emoji} />
                ))}
              </ScrollView>
            </View>
          </Content>
        </Paper>
      );
    },
);

const styles = StyleSheet.create({
  root: {
    maxHeight: 400,
  },
  scrollview: {
    marginBottom: Spacing.S8,
    flexShrink: 0,
  },
  scrollviewContent: {
    gap: Spacing.S8,
    paddingHorizontal: Spacing.S16,
  },
  reactionContent: {
    paddingHorizontal: Spacing.S16,
  },
});
