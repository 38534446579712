import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { formatAddress } from "@kraaft/shared/core/utils";
import { Preloader } from "@kraaft/ui";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";
import { useBasicCellStyle } from "@kraaft/web/src/components/modularTable/components/fields/geolocation/basicCell.styles";

export const GeolocationCell = ({
  value,
  isLoading,
}: IBasicCellProps<KColumnType.geolocation>) => {
  const styles = useBasicCellStyle();

  // Handle LoadingState for onPaste (async)
  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Preloader size="small" />
      </div>
    );
  }

  const displayedValue = formatAddress(value, false);

  return <div className={styles.basicCell}>{displayedValue}</div>;
};
