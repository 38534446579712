import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CompanyActions } from "@kraaft/shared/core/modules/company/company.actions";
import { selectCompany } from "@kraaft/shared/core/modules/company/company.selectors";

export function useCompany(companyId: string | undefined) {
  const dispatch = useDispatch();
  const companies = useSelector(selectCompany(companyId));

  useEffect(() => {
    if (!companyId) {
      return;
    }

    dispatch(CompanyActions.subscribeTo({ companyId }));
    return () => {
      dispatch(CompanyActions.unsubscribeFrom({ companyId }));
    };
  }, [companyId, dispatch]);

  return companies;
}
