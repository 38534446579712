import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Keyboard, Platform, Pressable, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { assert } from "ts-essentials";

import { alphaHex } from "@kraaft/helper-functions";
import { FirebaseCaptchaVerifier } from "@kraaft/shared/components/auth/firebaseCaptchaVerifier";
import { FirebaseCaptchaVerifierHandle } from "@kraaft/shared/components/auth/firebaseCaptchaVerifierHandle";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { showSuccess } from "@kraaft/shared/core/modules/alert/alertActions";
import { selectAuthState } from "@kraaft/shared/core/modules/user/userSelectors";
import { Firebase } from "@kraaft/shared/core/services/firebase";
import {
  useNavigationService,
  useRouteService,
} from "@kraaft/shared/core/services/navigation";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Button, Color, NonAccessibleText, Preloader } from "@kraaft/ui";

import { styles } from "./confirmationCode.styles";

const DELAY_WAITING_AUTH_EVENT_MS = 5000;

const ConfirmationCode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigationService = useNavigationService();
  const routeService = useRouteService<"ConfirmationCode">();
  const captchaRef = useRef<FirebaseCaptchaVerifierHandle | null>(null);

  const authState = useSelector(selectAuthState);
  const [code, setCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isWorking, setWorking] = useState(false);
  const [didConfirm, setDidConfirm] = useState(false);

  const { fullNumber, exists } = routeService.getParams();

  const resend = async () => {
    try {
      trackEvent({ eventName: "Resend OTP Code" });
      await Firebase.signInWithPhoneNumber({
        phoneNumber: fullNumber,
        applicationVerifier: captchaRef.current?.getApplicationVerifier(),
      });
      setTimeout(() => {
        setWorking(false);
      }, DELAY_WAITING_AUTH_EVENT_MS); // wait to receive authState change event
    } catch (exception) {
      console.error("Resend error", exception);
      setWorking(false);
    }
  };

  const handleResend = async () => {
    setWorking(true);
    await captchaRef.current?.verify();
    await resend();
  };

  const handleNext = async () => {
    await confirmCode(code);
  };

  const confirmCode = async (newCode: string) => {
    setDidConfirm(true);
    setError(null);
    try {
      if (isWorking) {
        return;
      }
      setWorking(true);
      const confirmation = navigationService.getConfirmation();
      assert(confirmation, "no confirmation object");
      await confirmation.confirm(newCode);

      if (exists) {
        trackEvent({
          eventName: "User Sign In Success",
        });
      }
      setTimeout(() => {
        setWorking(false);
      }, DELAY_WAITING_AUTH_EVENT_MS); // wait to receive authState change event
    } catch (err) {
      const errorMessage =
        err.code === "auth/invalid-verification-code"
          ? t("confirmationCodeError")
          : err.message;

      trackEvent({
        eventName: exists ? "User Sign In Failure" : "User Sign Up Failure",
      });

      setError(errorMessage);
      setCode("");
      setWorking(false);
    }
  };

  useEffect(() => {
    if (authState === "signedIn" && !didConfirm) {
      dispatch(showSuccess({ title: "", message: t("automaticConfirmation") })); // message is long so we don't want it bold
    }
  }, [authState, didConfirm, dispatch, t]);

  return (
    <>
      <Pressable
        accessibilityLabel=""
        style={styles.container}
        onPress={Keyboard.dismiss}
      >
        {isWorking && (
          <Preloader
            absoluteFill
            backgroundColor={alphaHex(Color.WHITE, 0.6)}
          />
        )}
        <View style={styles.titleContainer}>
          <NonAccessibleText weight="bold" size="H1" style={styles.title}>
            {t("confirmationCodeTitle")}
          </NonAccessibleText>
          <NonAccessibleText size="BODY" color="FONT_LOW_EMPHASIS">
            {t("confirmationCodeContent", { fullNumber })}
          </NonAccessibleText>
        </View>
        <KInput
          nativeID="ide2e-code"
          disableAccessibility
          label={t("confirmationCodeLabel")}
          value={code}
          onChangeText={setCode}
          error={error !== null}
          keyboardType={Platform.select({
            ios: "number-pad",
            android: "number-pad",
            web: "numeric",
          })}
          autoFocus
          returnKeyType="next"
          clearButtonMode="while-editing"
          onSubmitEditing={handleNext}
        />
        {error && (
          <View style={styles.errorContainer}>
            <NonAccessibleText size="SMALL" color="ACTION_DESCTRUCTIVE">
              {error}
            </NonAccessibleText>
          </View>
        )}
        <View style={styles.navigateSpacer} />
        <View style={styles.resendButtonContainer}>
          <NonAccessibleText
            weight="light"
            size="BODY"
            color="FONT_LOW_EMPHASIS"
            onPress={handleResend}
          >
            {t("confirmationCodeButton")}
          </NonAccessibleText>
        </View>
        <View style={styles.buttonSpacer} />
        <Button
          id="ide2e-next"
          disableAccessibility
          onPress={handleNext}
          text={t("next")}
        />
      </Pressable>
      <FirebaseCaptchaVerifier ref={captchaRef} />
    </>
  );
};

export { ConfirmationCode };
