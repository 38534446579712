import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectOrderedModularFoldersWithRoomIdAndSchemaId,
  selectOrderedModularFoldersWithSchemaIdAndVisibility,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { ModularFolderVisibilityType } from "@kraaft/shared/core/modules/modularFolder/types";
import {
  subscribeToPoolRooms,
  subscribeToRoom,
  unsubscribeFromPoolRooms,
  unsubscribeFromRoom,
} from "@kraaft/shared/core/modules/room/roomActions";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { useFilterFolders } from "@kraaft/web/src/components/filterMenu/filterUtils";
import { useSchemaView } from "@kraaft/web/src/components/modularFoldersViewer/useSchemaView";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

export const useFilteredModularFolders = ({
  schema,
  schemaViewId,
}: {
  schema: KSchema;
  schemaViewId?: string;
}) => {
  const dispatch = useDispatch();
  const { isLoaded, folders } = useSelector(
    selectOrderedModularFoldersWithSchemaIdAndVisibility({
      visibilities: [
        { type: ModularFolderVisibilityType.User },
        { type: ModularFolderVisibilityType.Pool, poolId: schema.poolId },
      ],
      schemaId: schema.id,
    }),
  );

  const schemaView = useSchemaView(
    RecordWorkspace.ALL_ROOMS,
    schema.id,
    schemaViewId,
  );

  const filteredFolders = useFilterFolders(
    schema.rootSection,
    folders,
    schemaView?.filters,
  );

  useEffect(() => {
    dispatch(ModularFolderActions.subscribeForPool({ poolId: schema.poolId }));
    dispatch(
      subscribeToPoolRooms({
        poolId: schema.poolId,
      }),
    );
    return () => {
      dispatch(
        ModularFolderActions.unsubscribeForPool({ poolId: schema.poolId }),
      );
      dispatch(
        unsubscribeFromPoolRooms({
          poolId: schema.poolId,
        }),
      );
    };
  }, [dispatch, schema]);

  return {
    isLoaded,
    filteredFolders,
  };
};

export const useFilteredModularFoldersForRoom = ({
  roomId,
  schema,
  schemaViewId,
}: {
  roomId: string;
  schema: KSchema;
  schemaViewId?: string;
}) => {
  const dispatch = useDispatch();
  const { isLoaded, folders } = useSelector(
    selectOrderedModularFoldersWithRoomIdAndSchemaId({
      roomId,
      schemaId: schema.id,
    }),
  );

  const schemaView = useSchemaView(
    RecordWorkspace.IN_ROOM,
    schema.id,
    schemaViewId,
  );
  const filteredFolders = useFilterFolders(
    schema.rootSection,
    folders,
    schemaView?.filters,
  );

  useEffect(() => {
    dispatch(ModularFolderActions.subscribeForRoom({ roomId }));
    dispatch(subscribeToRoom({ roomId }));
    return () => {
      dispatch(ModularFolderActions.unsubscribeForRoom({ roomId }));
      dispatch(unsubscribeFromRoom({ roomId }));
    };
  }, [dispatch, roomId]);

  return {
    isLoaded,
    filteredFolders,
  };
};
