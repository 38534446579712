import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  buttonContainer: {
    paddingTop: Spacing.S12,
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  buttonContainerMobile: {
    paddingTop: Spacing.NONE,
    paddingHorizontal: Spacing.S16,
    flexDirection: "column",
    justifyContent: "center",
  },
  secondaryButtonContainerMobile: {
    paddingBottom: Spacing.S12,
  },
  primaryButtonWeb: {
    paddingLeft: Spacing.S12,
  },
  primaryButtonMobile: {},
  buttonMobile: {},
});
