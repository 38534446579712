import { DeepRequired } from "ts-essentials";

import { GeolocatedElement } from "@kraaft/shared/components/geolocation/types";
import {
  MiniImage,
  MiniImageWithGeolocation,
  MiniMedia,
} from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { onlyMiniMediaOfType } from "@kraaft/shared/core/modules/miniMedia/miniMedia.utils";
import {
  BaseRoomModularRecordProperties,
  Room,
  RoomModularRecord,
  RoomNotificationFilter,
  RoomUserHistory,
} from "@kraaft/shared/core/modules/room/roomState";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KRoomSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { PoolNotificationFilter } from "@kraaft/shared/core/modules/userPool/userPool.state";
import { type TFunction } from "@kraaft/shared/core/services/i18next";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { TranslationKey } from "@kraaft/shared/core/types";

export function getSubscribeToRoomLoaderId(roomId: string | undefined) {
  return `subscribeToRoomLoaderId-${roomId}`;
}

export function roomArchivedStatus(room: Room, roomHistory?: RoomUserHistory) {
  return roomHistory
    ? Boolean(roomHistory.isArchived)
    : Boolean(room.isArchivedForAll);
}

export function getSharepointErrorMessage(err: Error) {
  const errorMessageMap = {
    unauthorized: "sharepointErrorUnauthorized",
    "invalid url": "sharepointErrorInvalidUrl",
    unknown: "internalError",
  } as const;
  const message: string = err.message;
  const translationKey =
    message in errorMessageMap
      ? errorMessageMap[message as keyof typeof errorMessageMap]
      : "internalError";
  return i18n.t(translationKey);
}

export function getExistingRoomRecordField<
  F extends keyof BaseRoomModularRecordProperties,
>(
  roomProperties: RoomModularRecord["properties"] | undefined,
  field: F,
): DeepRequired<BaseRoomModularRecordProperties>[F]["value"] | undefined;
export function getExistingRoomRecordField<
  F extends keyof BaseRoomModularRecordProperties,
>(
  roomProperties: RoomModularRecord["properties"] | undefined,
  field: F,
  defaultValue: DeepRequired<BaseRoomModularRecordProperties>[F]["value"],
): DeepRequired<BaseRoomModularRecordProperties>[F]["value"];
export function getExistingRoomRecordField<
  F extends keyof BaseRoomModularRecordProperties,
>(
  roomProperties: RoomModularRecord["properties"] | undefined,
  field: F,
  defaultValue?: DeepRequired<BaseRoomModularRecordProperties>[F]["value"],
): DeepRequired<BaseRoomModularRecordProperties>[F]["value"] | undefined {
  if (!roomProperties) {
    return undefined;
  }
  const value = roomProperties[field]?.value as
    | DeepRequired<BaseRoomModularRecordProperties>[F]["value"]
    | undefined;

  return value !== undefined ? value : defaultValue;
}

export function ensureAccessToRoomSchemaColumns(
  schema: KRoomSchema | undefined | null,
): schema is KRoomSchema {
  return schema?.collection === "room";
}

const defaultRoomStatus = "IN_PROGRESS";
export function getTemporaryRoomDefaultStatus(roomSchema: KRoomSchema) {
  if (ensureAccessToRoomSchemaColumns(roomSchema)) {
    const statusColumn = KSchemaUtils.findColumn(
      roomSchema.rootSection,
      "status",
      KColumnType.selectSingle,
    );

    if (!statusColumn) {
      return "";
    }

    if (statusColumn?.options[defaultRoomStatus] !== undefined) {
      return defaultRoomStatus;
    }

    return KSchemaUtils.orderOptions(statusColumn)[0]?.[0];
  }
}

export function miniImageHasGeolocation(
  media: MiniImage,
): media is MiniImageWithGeolocation {
  return media.geolocation !== undefined && media.geolocation !== null;
}

export function getGeolocatedMiniMedias(medias: MiniMedia[]) {
  return onlyMiniMediaOfType(medias, ["image"]).filter((media) =>
    miniImageHasGeolocation(media),
  ) as MiniImageWithGeolocation[];
}

export function formatGeolocatedMiniMedias(
  medias: MiniImageWithGeolocation[],
): GeolocatedElement<MiniImageWithGeolocation>[] {
  return medias.map((media) => ({
    ref: media,
    id: media.id,
    geolocation: media.geolocation,
  }));
}

export function getRoomTrackingType(room: Room) {
  return room.isClonedFromDemo ? "clonedFromDemo" : "default";
}

export function getNotificationDescription(
  t: TFunction,
  roomSettings: RoomNotificationFilter,
  poolSettings: PoolNotificationFilter,
) {
  const translations = {
    all: "notifications.briefOptions.all",
    only_mentions: "notifications.briefOptions.onlyMentions",
  } satisfies Record<PoolNotificationFilter, TranslationKey>;

  const effectiveSetting =
    roomSettings === "inherit" ? poolSettings : roomSettings;

  return t(translations[effectiveSetting]);
}

const poolMutedArray: PoolNotificationFilter[] = ["only_mentions"];
const roomMutedArray: RoomNotificationFilter[] = ["only_mentions"];

function isPoolNotificationFilterMuted(settings: PoolNotificationFilter) {
  return poolMutedArray.includes(settings);
}

export function isConversationMuted(
  poolSettings: PoolNotificationFilter,
  roomSettings: RoomNotificationFilter,
) {
  if (roomSettings === "inherit") {
    return isPoolNotificationFilterMuted(poolSettings);
  }

  return roomMutedArray.includes(roomSettings);
}
