import moment from "moment";

import { useMemo } from "react";
import { capitalize } from "lodash";

import { MARGIN_BETWEEN_IMAGES } from "@kraaft/shared/components/galleries/photoGallery/photoGallery.styles";
import {
  MiniImage,
  MiniMedia,
} from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { ImageSize } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { compareStrings } from "@kraaft/shared/core/utils";

export type PhotoGalleryLineContraints = {
  defaultHeight: number;
  maxHeight: number;
  minImageCountPerLine: number;
};

export const DEFAULT_PHOTO_GALLERY_CONSTRAINTS: PhotoGalleryLineContraints = {
  defaultHeight: 125,
  maxHeight: 210,
  minImageCountPerLine: 2,
};

export interface MiniImageLine {
  images: MiniImage[];
  lineHeight: number;
}

function getTotalMarginWidth(imageCount: number) {
  return imageCount * MARGIN_BETWEEN_IMAGES;
}

const MAX_VERTICAL_RATIO = 1.5;
const MAX_HORIZONTAL_RATIO = 1.5;

export function getGalleryImageSize(size: ImageSize | undefined) {
  let height = size?.height ?? 1;
  let width = size?.width ?? 1;

  if (height / width > MAX_VERTICAL_RATIO) {
    const newHeight = width * MAX_VERTICAL_RATIO;
    height = newHeight;
  }
  if (width / height > MAX_HORIZONTAL_RATIO) {
    const newWidth = height * MAX_HORIZONTAL_RATIO;
    width = newWidth;
  }

  return { width, height };
}

function getWidth(image: MiniImage["preview"], lineHeight: number) {
  const { width, height } = getGalleryImageSize(image.size);

  const scaleRatio = lineHeight / height;

  const scaledDownImageWidth = width * scaleRatio;

  return scaledDownImageWidth;
}

// How many pixels from the right border will make the last row not try to expand to
// touch the right border
const DISTANCE_WHEN_LAST_LINE_DONT_FILL_WIDTH = 20;

function adaptLineHeight(
  line: MiniImageLine,
  availableWidth: number,
  heights: PhotoGalleryLineContraints,
  isLastLine: boolean,
) {
  const width = line.images.reduce((acc, curr) => {
    if (!curr.preview) {
      return acc;
    }
    return acc + getWidth(curr.preview, heights.defaultHeight);
  }, 0);
  const availableWidthWithMargins =
    availableWidth - getTotalMarginWidth(line.images.length - 1);

  const fitWidthMultiplier = 1 / (width / availableWidthWithMargins);
  // Ensures the multiplier keeps the final height between heights.default and heights.max
  const clampedFitWidthMultiplier =
    Math.min(heights.defaultHeight * fitWidthMultiplier, heights.maxHeight) /
    heights.defaultHeight;

  if (
    isLastLine &&
    Math.abs(availableWidthWithMargins - clampedFitWidthMultiplier * width) >
      DISTANCE_WHEN_LAST_LINE_DONT_FILL_WIDTH
  ) {
    line.lineHeight = heights.defaultHeight;
  } else {
    line.lineHeight = heights.defaultHeight * clampedFitWidthMultiplier;
  }
}

function splitImagesIntoLines(
  images: MiniImage[],
  requiredHeights: PhotoGalleryLineContraints,
  availableWidth: number,
) {
  const array: MiniImageLine[] = [];

  // Current width is the sum of the width of the pictures, without margin
  let currentWidth = 0;
  let currentLine: MiniImageLine = {
    lineHeight: requiredHeights.defaultHeight,
    images: [],
  };
  for (let i = 0; i < images.length; i += 1) {
    const element = images[i];
    if (!element) {
      // Impossible
      break;
    }
    const image = element.preview;
    if (!image) {
      break;
    }

    const scaledDownImageWidth = getWidth(image, requiredHeights.defaultHeight);
    const availableWidthWithMargins =
      availableWidth - getTotalMarginWidth(currentLine.images.length + 1);
    currentWidth += scaledDownImageWidth;

    if (currentWidth > availableWidthWithMargins) {
      if (currentLine.images.length < requiredHeights.minImageCountPerLine) {
        const lineHeight =
          requiredHeights.defaultHeight *
          (availableWidthWithMargins / currentWidth);
        currentLine.lineHeight = lineHeight;
        currentLine.images.push(element);
      } else {
        currentWidth = scaledDownImageWidth;
        array.push(currentLine);
        currentLine = {
          lineHeight: requiredHeights.defaultHeight,
          images: [element],
        };
      }
    } else {
      currentLine.images.push(element);
    }
  }

  array.push(currentLine);
  array.forEach((line, index) =>
    adaptLineHeight(
      line,
      availableWidth,
      requiredHeights,
      index === array.length - 1,
    ),
  );

  return array;
}

export function sortMediaByMonth<T extends MiniMedia>(medias: T[]) {
  return medias.reduce<{
    [monthAndYear: string]: T[];
  }>((acc, curr) => {
    const month2Digits = curr.createdAt.getMonth().toString().padStart(2, "0");
    const monthKey = `${curr.createdAt.getFullYear()}-${month2Digits}`;
    const month = acc[monthKey] || [];
    let insertBefore = 0;
    for (insertBefore = 0; insertBefore < month.length; insertBefore += 1) {
      const element = month[insertBefore];
      if (!element) {
        break;
      }
      if (curr.createdAt.getTime() > element.createdAt.getTime()) {
        break;
      }
    }
    month.splice(insertBefore, 0, curr);
    acc[monthKey] = month;
    return acc;
  }, {});
}

export function useSortedGallery(
  medias: MiniImage[],
  requiredHeights: PhotoGalleryLineContraints,
  width: number,
) {
  const { sortedMonths, sortedMedias } = useMemo(() => {
    if (width <= 0) {
      return { sortedMonths: [], sortedMedias: [] };
    }

    const months = sortMediaByMonth(medias);

    const _sortedMonths = Object.entries(months)
      .sort(([a], [b]) => compareStrings(b, a))
      .map(([monthKey, images]) => ({
        id: monthKey,
        title: capitalize(moment(images[0]?.createdAt).format("MMMM YYYY")),
        data: splitImagesIntoLines(images, requiredHeights, width),
      }));

    const _sortedMedias = _sortedMonths
      .map((month) => month.data.map((line) => line.images))
      .flat(2);

    return { sortedMonths: _sortedMonths, sortedMedias: _sortedMedias };
  }, [medias, requiredHeights, width]);

  return { sortedMonths, sortedMedias };
}
