import { PropsWithChildren } from "react";
import { FlatList, ScrollView } from "react-native";
import {
  KeyboardAwareFlatListProps,
  KeyboardAwareScrollViewProps,
} from "react-native-keyboard-aware-scroll-view";

import { KeyboardAwareScrollViewContextNoopProvider } from "@kraaft/shared/components/keyboardAware/keyboardAwareScrollViewContext";

export const KeyboardAwareScrollView = (
  props: PropsWithChildren<KeyboardAwareScrollViewProps> & {
    additionalHeight?: number;
  },
) => (
  <KeyboardAwareScrollViewContextNoopProvider>
    <ScrollView {...props} />
  </KeyboardAwareScrollViewContextNoopProvider>
);

export const KeyboardAndHeaderAwareScrollView = (
  props: PropsWithChildren<KeyboardAwareScrollViewProps>,
) => (
  <KeyboardAwareScrollViewContextNoopProvider>
    <ScrollView {...props} />
  </KeyboardAwareScrollViewContextNoopProvider>
);

export const KeyboardAwareFlatList = <ItemT,>(
  props: PropsWithChildren<KeyboardAwareFlatListProps<ItemT>>,
) => (
  <KeyboardAwareScrollViewContextNoopProvider>
    <FlatList {...props} />
  </KeyboardAwareScrollViewContextNoopProvider>
);
