import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  marker: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50",
    left: "50",
    transform: "translate(-50%, -100%)",
  },
});
