import { useCallback, useState } from "react";
import { LayoutChangeEvent, LayoutRectangle } from "react-native";

export function useLayoutMeasurer() {
  const [layoutRectangle, setLayoutRectangle] = useState<LayoutRectangle>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setLayoutRectangle(event.nativeEvent.layout);
  }, []);

  return [layoutRectangle, onLayout] as const;
}

export function useWidthMeasurer(defaultValue = 0) {
  const [layoutWidth, setLayoutWidth] = useState(defaultValue);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setLayoutWidth(event.nativeEvent.layout.width);
  }, []);

  return [layoutWidth, onLayout] as const;
}
