import {
  ImageAttachment,
  VideoAttachment,
} from "@kraaft/shared/core/modules/folder/attachmentTypes";
import {
  MiniMedia,
  MiniMediaOfType,
} from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";

export function onlyMiniMediaOfType<T extends MiniMedia["type"]>(
  medias: MiniMedia[],
  types: T[],
): MiniMediaOfType<T>[] {
  return medias.filter((e) =>
    types.includes(e.type as T),
  ) as MiniMediaOfType<T>[];
}

export function isMiniMedia(
  item: ImageAttachment | VideoAttachment | MiniMedia,
): item is MiniMedia {
  return "isMiniMedia" in item;
}
