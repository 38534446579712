import { createReducer } from "@reduxjs/toolkit";

import { CarouselStateActions } from "@kraaft/shared/core/modules/carousel/carousel.actions";
import { OfflineModularFolderStateActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { groupModularFolderDictByRoomId } from "@kraaft/shared/core/modules/modularFolder/modularFolderUtils";
import { ModularFolderVisibilityType } from "@kraaft/shared/core/modules/modularFolder/types";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { mergeInPlace } from "@kraaft/shared/core/store/mergeInPlace";

import * as actions from "./modularFolderActions";
import { ModularFolderStateActions } from "./modularFolderActions";
import { ModularFolderState } from "./modularFolderState";

const initialState: ModularFolderState = {
  foldersByRoomId: {},
  modularFolders: {},
  roomLoadStatus: {},
  folderByVisibility: {
    userVisible: [],
    roomVisible: {},
    poolVisible: {},
  },
  carousel: undefined,
};

const modularFolderReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);

  addCase(OfflineModularFolderStateActions.set, (state, { payload }) => {
    const mutableNewState = groupModularFolderDictByRoomId(payload.aggregates);

    for (const [roomId, modularFolders] of Object.entries(mutableNewState)) {
      state.foldersByRoomId[roomId] ??= {};
      const existingRoomModularFolders = state.foldersByRoomId[roomId];

      for (const oldId of Object.keys(existingRoomModularFolders)) {
        if (!(oldId in modularFolders)) {
          delete existingRoomModularFolders[oldId];
          delete state.modularFolders[oldId];
        }
      }

      for (const modularFolder of Object.values(modularFolders)) {
        existingRoomModularFolders[modularFolder.id] = true;
        state.modularFolders[modularFolder.id] = mergeInPlace(
          state.modularFolders[modularFolder.id],
          modularFolder,
        );
      }
    }
  });

  addCase(ModularFolderStateActions.cleanModularFolderList, (state) => {
    state.foldersByRoomId = {};
    state.modularFolders = {};
  });

  addCase(
    ModularFolderStateActions.setIdsForVisibility,
    (state, { payload }) => {
      switch (payload.visibility.type) {
        case ModularFolderVisibilityType.User: {
          state.folderByVisibility.userVisible = payload.ids;
          break;
        }
        case ModularFolderVisibilityType.Room: {
          if (payload.visibility.roomId) {
            state.folderByVisibility.roomVisible[payload.visibility.roomId] =
              payload.ids;
          }
          break;
        }
        case ModularFolderVisibilityType.Pool: {
          if (payload.visibility.poolId) {
            state.folderByVisibility.poolVisible[payload.visibility.poolId] =
              payload.ids;
          }
          break;
        }
      }
    },
  );

  addCase(ModularFolderStateActions.openCarousel, (state, { payload }) => {
    state.carousel = {
      ...payload,
      show: true,
    };
  });

  addCase(ModularFolderStateActions.closeCarousel, (state) => {
    state.carousel = {
      show: false,
    };
  });

  addCase(CarouselStateActions.close, (state) => {
    state.carousel = {
      show: false,
    };
  });

  addCase(
    actions.ModularFolderStateActions.updateRoomLoadStatus,
    (state, { payload: { roomId, status } }) => {
      state.roomLoadStatus[roomId] = status;
    },
  );
});

export { modularFolderReducers };
