import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SectionList, View } from "react-native";
import type {
  SectionListData,
  SectionListRenderItemInfo,
} from "react-native/Libraries/Lists/SectionList";
import { useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { styles } from "@kraaft/shared/components/readAcknowledgement/readAcknowledgement.styles";
import { selectMessageInRoom } from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import { RoomMember } from "@kraaft/shared/core/modules/room/roomState";
import { selectRoomMembersAndReadStatus } from "@kraaft/shared/core/modules/room/selectors";
import {
  selectCurrentUserId,
  selectUsers,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { Color, ColorStyle, Icon, Text } from "@kraaft/ui";

interface ReadAcknowledgementProps {
  roomId: string;
  messageId: string;
}

interface SectionData {
  title: "read" | "receipt";
  data: RoomMember[];
}

function keyExtractor(roomMember: RoomMember) {
  return roomMember.userId;
}

export const ReadAcknowledgement = ({
  roomId,
  messageId,
}: ReadAcknowledgementProps) => {
  const { t } = useTranslation();
  const roomMembers = useSelector(selectRoomMembersAndReadStatus(roomId));
  const currentUserId = useSelector(selectCurrentUserId);
  const users = useSelector(selectUsers);
  const message = useSelector(selectMessageInRoom(roomId, messageId));

  const roomMembersWithoutCurrentUser = useMemo(() => {
    return roomMembers.filter(
      (roomMember) => roomMember.userId !== currentUserId,
    );
  }, [roomMembers, currentUserId]);

  const readAndReceiptMembers = useMemo<SectionData[]>(() => {
    if (!message) {
      return [];
    }

    const readMembers = roomMembersWithoutCurrentUser.filter((roomMember) => {
      return (
        roomMember.lastReadMessageCreatedAt &&
        roomMember.lastReadMessageCreatedAt >= message.createdAt
      );
    });

    const receiptMembers = roomMembersWithoutCurrentUser.filter(
      (roomMember) => !readMembers.includes(roomMember),
    );

    if (receiptMembers.length === 0 && readMembers.length > 0) {
      return [{ title: "read", data: readMembers }];
    }

    return [
      {
        title: "read",
        data: readMembers,
      },
      {
        title: "receipt",
        data: receiptMembers,
      },
    ];
  }, [roomMembersWithoutCurrentUser, message]);

  const renderSectionHeader = useCallback(
    ({ section }: { section: SectionListData<RoomMember, SectionData> }) => (
      <View style={styles.sectionTitleRow}>
        <View style={styles.imageRow}>
          <Icon
            name="double-check"
            color={
              section.title === "read"
                ? Color.BLUE_KRAAFT
                : ColorStyle.FONT_LOW_EMPHASIS
            }
          />
        </View>
        <View style={styles.textRow}>
          <Text size="BODY" weight="medium" color="BLUE_KRAAFT">
            {`${
              section.title === "read"
                ? t("readAcknowledgement.ReadBy")
                : t("readAcknowledgement.SentTo")
            } (${section.data.length})`}
          </Text>
        </View>
      </View>
    ),
    [t],
  );

  const renderItem = useCallback(
    ({ item }: SectionListRenderItemInfo<RoomMember, SectionData>) => (
      <View style={styles.row}>
        <View style={styles.imageRow}>
          <Icon name="user-01" size={24} />
        </View>
        <View style={styles.textRow}>
          <Text size="BODY">{getUsername(users[item.userId])}</Text>
        </View>
      </View>
    ),
    [users],
  );

  const renderSectionFooter = useCallback(
    ({ section }: { section: SectionListData<RoomMember, SectionData> }) => {
      if (section.data.length > 0) {
        if (section.title === "read" && !isNative()) {
          return null;
        }

        return <View style={styles.sectionFooter} />;
      }
      return (
        <View style={styles.sectionFooter}>
          <View style={styles.emptyTextContainer}>
            <Text size="BODY" color="FONT_LOW_EMPHASIS">
              {section.title === "read"
                ? t("readAcknowledgement.nobodyHasRead")
                : t("readAcknowledgement.notYetSent")}
            </Text>
          </View>
        </View>
      );
    },
    [t],
  );

  if (!message) {
    return null;
  }

  return (
    <SectionList
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      sections={readAndReceiptMembers}
      keyExtractor={keyExtractor}
      renderSectionHeader={renderSectionHeader}
      renderItem={renderItem}
      renderSectionFooter={renderSectionFooter}
      stickySectionHeadersEnabled
    />
  );
};
