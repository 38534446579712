import { ComponentType, useCallback, useRef } from "react";

import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import {
  TrackData,
  TrackingEvent,
} from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

export function withOnPressTracking<
  P extends { onPress?: ((...args: any[]) => void) | null },
>(Component: ComponentType<P>) {
  return <T extends keyof TrackData>({
    onPress,
    getTrackingEvent,
    ...other
  }: P & {
    getTrackingEvent: () => TrackingEvent<T>;
  }) => {
    const getTrackingEventRef = useRef(getTrackingEvent);
    getTrackingEventRef.current = getTrackingEvent;

    const handlePress = useCallback(
      (...args: any) => {
        onPress?.(...args);
        trackEvent(getTrackingEventRef.current());
      },
      [onPress],
    );

    return <Component {...(other as any as P)} onPress={handlePress} />;
  };
}
