import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    height: "100%",
    padding: `${Spacing.S4}px ${Spacing.NONE}px`,
    flex: 1,
  },
}));
