import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { Button, Color, Text } from "@kraaft/ui";

interface DoesNotExistProps {
  roomId: string;
}

export const DoesNotExist = ({ roomId }: DoesNotExistProps) => {
  const { t } = useTranslation();
  const navigationService = useNavigationService();

  const onBack = () => {
    if (isNative()) {
      navigationService.goBack();
    } else {
      navigationService.navigate("ConversationFolder", {
        roomId,
      });
    }
  };

  return (
    <View style={styles.root}>
      <Text size="BODY" style={styles.text}>
        {t("recordDoesNotExistOrNoConnection")}
      </Text>
      <Button variant="QUATERNARY" text={t("back")} onPress={onBack} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Color.WHITE,
  },
  text: {
    textAlign: "center",
    marginBottom: 8,
  },
});
