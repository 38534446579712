import memoize from "lodash/fp/memoize";
import { createSelector } from "reselect";

import { FeatureFlagKey } from "@kraaft/shared/core/modules/featureFlags/featureFlags.state";
import { RootState } from "@kraaft/shared/core/store";

const selectFeatureFlagsState = (state: RootState) => state.featureFlag;

export const selectFeatureFlags = createSelector(
  selectFeatureFlagsState,
  (state) => state.items,
);
export const selectFeatureFlag = memoize(<T extends FeatureFlagKey>(key: T) =>
  createSelector(selectFeatureFlagsState, (state) => state.items[key]),
);
