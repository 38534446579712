import {
  ActionCreatorWithPayload,
  createAction,
  createReducer,
  createSelector,
} from "@reduxjs/toolkit";
import { memoize } from "lodash";
import { Saga, SagaMiddleware } from "redux-saga";

import {
  BaseAggregate,
  DependenciesFromDeclaredOperations,
  OfflineFeature,
  OptimisticOperation,
  UserDeclaredOperations,
} from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/optimistic.types";
import { createReduxBundle } from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle";
import {
  ReduxState,
  ReduxStateActions,
  UserProvidedSelectors,
} from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle.types";
import { Task } from "@kraaft/shared/core/utils/optimistic/newOptimistic/taskStore/task";

export interface OptimisticState {
  features: Record<string, { operations: OptimisticOperation[] }>;
  results: Record<string, any>;
  idCorrespondance: Record<string, string>;
}

const REDUCER_NAME = "__offline-feature-reducer" as const;

export const GlobalOfflineReduxBundle = {
  actions: {
    deleteOperations: createAction<{
      feature: string;
      operationIndexes: number[];
    }>("offline-feature/delete"),
    seedOperations: createAction<{
      feature: string;
      operations: OptimisticOperation[];
    }>("offline-feature/seed-operations"),
    addOperation: createAction<{
      feature: string;
      operation: OptimisticOperation;
    }>("offline-feature/add-operation"),
    removeDependentOperations: createAction<{
      feature: string;
      dependencies: string[];
    }>("offline-feature/remove-dependent-operations"),
    setOperationMutateResult: createAction<{
      feature: string;
      id: string;
      result: any;
    }>("offline-feature/set-operation-mutate-result"),
    replaceOperationTargetId: createAction<{
      feature: string;
      ids: string[];
      by: string[];
    }>("offline-feature/replace-operation-target-id"),
  },
  featureOperations: {} as Record<string, UserDeclaredOperations>,
  sagas: [] as Saga[],
  store: {} as any,
  boot(store: any, sagaMiddleware: SagaMiddleware) {
    this.store = store;
    for (const saga of this.sagas) {
      sagaMiddleware.run(saga);
    }
  },
  registerFeatureOperations(
    feature: string,
    operations: UserDeclaredOperations,
  ) {
    this.featureOperations[feature] = operations;
  },
  selectIdCorrespondance(state: any) {
    return (state[REDUCER_NAME] as OptimisticState).idCorrespondance;
  },
  selectCorrespondingId: memoize((id: string) => {
    return createSelector(
      (state) => (state[REDUCER_NAME] as OptimisticState).idCorrespondance,
      (correspondance) => correspondance[id] ?? id,
    );
  }),
  selectMutateResults(state: any) {
    return (state[REDUCER_NAME] as OptimisticState).results;
  },
  createSelectorForFeature: memoize((feature: string) =>
    createSelector(
      (state: any) => (state[REDUCER_NAME] as OptimisticState).features,
      (features) =>
        features[feature] ?? {
          operations: [],
        },
    ),
  ),
};

export function InitReduxOfflineFeature({
  errorAction,
  resetAction,
}: {
  errorAction: ActionCreatorWithPayload<{
    feature: string;
    task: Task;
    error: any;
  }>;
  resetAction: ActionCreatorWithPayload<any>;
}) {
  function getFeature(state: OptimisticState, feature: string) {
    const existing = state.features[feature];
    if (existing) {
      return existing;
    }
    const created: OptimisticState["features"][string] = { operations: [] };
    state.features[feature] = created;
    return created;
  }

  function deleteOperations(
    state: OptimisticState,
    payload: {
      feature: string;
      operationIndexes: number[];
    },
  ) {
    const feature = getFeature(state, payload.feature);

    for (const index of [...payload.operationIndexes].reverse()) {
      const operation = feature.operations[index];
      feature.operations.splice(index, 1);
      if (operation) {
        delete state.results[operation.task.id];
      }
    }
  }

  const reducer = createReducer<OptimisticState>(
    { features: {}, idCorrespondance: {}, results: {} },
    ({ addCase, addMatcher }) => {
      addCase(
        GlobalOfflineReduxBundle.actions.deleteOperations,
        (state, { payload }) => {
          deleteOperations(state, payload);
        },
      );

      addCase(
        GlobalOfflineReduxBundle.actions.seedOperations,
        (state, { payload }) => {
          state.features[payload.feature] = { operations: payload.operations };
        },
      );

      addCase(
        GlobalOfflineReduxBundle.actions.addOperation,
        (state, { payload }) => {
          const feature = getFeature(state, payload.feature);

          feature.operations.push(payload.operation);
        },
      );

      addCase(
        GlobalOfflineReduxBundle.actions.setOperationMutateResult,
        (state, { payload }) => {
          state.results[payload.id] = payload.result;
        },
      );

      addCase(
        GlobalOfflineReduxBundle.actions.removeDependentOperations,
        (state, { payload }) => {
          const feature = getFeature(state, payload.feature);

          feature.operations = feature.operations.filter(
            (operation) =>
              !operation.task.dependencies.some((dependency) =>
                payload.dependencies.includes(dependency),
              ),
          );
        },
      );

      addCase(
        GlobalOfflineReduxBundle.actions.replaceOperationTargetId,
        (state, { payload }) => {
          const feature = getFeature(state, payload.feature);

          for (const operation of feature.operations) {
            const declaredOperation =
              GlobalOfflineReduxBundle.featureOperations[payload.feature]?.[
                operation.task.name
              ];
            if (!declaredOperation) {
              continue;
            }
            for (let i = 0; i < payload.ids.length; i += 1) {
              const oldId = payload.ids[i];
              const newId = payload.by[i];

              if (!oldId || !newId) {
                continue;
              }

              state.idCorrespondance[oldId] = newId;

              declaredOperation.replaceId(operation.task.payload, oldId, newId);
            }
            if (declaredOperation.type === "custom") {
              operation.task.dependencies = declaredOperation.gatherIds(
                operation.task.payload,
              );
            }
          }
        },
      );

      addCase(resetAction, (state) => {
        state.features = {};
      });

      addMatcher(
        (action): action is ReturnType<ReduxStateActions<any>["set"]> =>
          action.type.startsWith("OfflineSetter/"),
        (state, { payload }) => {
          deleteOperations(state, payload);
        },
      );
    },
  );

  function Create<
    Aggregate extends BaseAggregate,
    DeclaredOperations extends UserDeclaredOperations,
  >(
    feature: OfflineFeature<Aggregate, DeclaredOperations>,
    userProvidedSelectors: UserProvidedSelectors<Aggregate>,
    ...args: DependenciesFromDeclaredOperations<DeclaredOperations> extends never
      ? []
      : [
          extra: (
            getState: () => ReduxState,
            selectAggregate: (
              id: string,
            ) => (state: ReduxState) => Aggregate | undefined,
          ) => DependenciesFromDeclaredOperations<DeclaredOperations>,
        ]
  ) {
    GlobalOfflineReduxBundle.registerFeatureOperations(
      feature.name,
      feature.userDeclaredOperations,
    );
    const { Actions, Selectors, StateActions, internal } = createReduxBundle<
      Aggregate,
      DeclaredOperations
    >(feature.name, feature.userDeclaredOperations, feature.taskManager)(
      userProvidedSelectors,
      errorAction,
      resetAction,
      args[0],
    );

    GlobalOfflineReduxBundle.sagas.push(internal.saga);

    return { Actions, Selectors, StateActions };
  }

  return { reducerName: REDUCER_NAME, reducer, Create };
}
