import { ReactNode, useEffect, useRef } from "react";

import { useMeshContext } from "@kraaft/helper-hooks";
import {
  AttentionSeeker,
  AttentionSeekerHandle,
} from "@kraaft/shared/components/attentionSeeker";
import { MessageListContext } from "@kraaft/shared/components/replyPreview/replyHooks";

interface MessageAttentionSeekerProps {
  messageId: string;
  children: ReactNode;
}

export const MessageAttentionSeeker = ({
  messageId,
  children,
}: MessageAttentionSeekerProps) => {
  const isHighlighted = useMeshContext(
    MessageListContext,
    ({ highlightedMessage }) => highlightedMessage === messageId,
  );

  const seekerRef = useRef<AttentionSeekerHandle>(null);

  useEffect(() => {
    if (isHighlighted) {
      seekerRef.current?.seekAttention();
    }
  }, [isHighlighted]);

  return <AttentionSeeker handle={seekerRef}>{children}</AttentionSeeker>;
};
