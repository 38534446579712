import { makeStyles } from "@mui/styles";

import { Radius } from "@kraaft/ui";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    height: 35,
    width: "100%",
    borderRadius: Radius.SMALL,
  },
}));
