import { createBasicSubscription } from "@kraaft/shared/core/modules/common/subscription";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { poolAdminSetOne } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminReducers";

export const poolAdminSubscription = createBasicSubscription({
  name: "poolAdmin",
  subscribe: Firestore.subscribeToPoolAdmin,
  onReceived: (pool) => poolAdminSetOne(pool),
  identifier: (action) => action.payload,
});
