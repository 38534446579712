import { ReactNode, useLayoutEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import {
  FormBuilderDropTypes,
  FormBuilderElement,
  InsertElementDropItem,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";

interface ElementPickerItemDragProps {
  children: ReactNode;
  element: FormBuilderElement;
  onDragStart: () => void;
}

const ElementPickerItemDrag = ({
  children,
  element,
  onDragStart,
}: ElementPickerItemDragProps) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: FormBuilderDropTypes.INSERT,
    item: () => {
      onDragStart();
      return (
        element.type === "section"
          ? {
              type: FormBuilderDropTypes.INSERT,
              insertType: "section",
            }
          : {
              type: FormBuilderDropTypes.INSERT,
              columnType: element.columnType,
              insertType: "column",
            }
      ) as InsertElementDropItem;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useLayoutEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, [preview]);

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {children}
    </div>
  );
};

export { ElementPickerItemDrag };
