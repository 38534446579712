import { StyleSheet } from "react-native";

import {
  Color,
  ColorStyle,
  FontSize,
  PixelSize,
  Radius,
  Spacing,
} from "@kraaft/ui";

export const styles = StyleSheet.create({
  infoContainer: {
    flexGrow: 1,
    flexShrink: 1,
    borderLeftWidth: 2,
    marginVertical: 8,
    marginHorizontal: 8,
  },
  previewContainer: {
    borderRadius: 12,
    flexDirection: "row",
    alignItems: "center",
  },

  previewContainerPreview: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  previewContainerMessageSelf: {
    borderTopRightRadius: 2,
    backgroundColor: ColorStyle.ACTION_CTA,
  },
  previewContainerMessageReply: {
    borderTopLeftRadius: 2,
    backgroundColor: Color.WHITE,
  },

  previewCancelButton: {
    marginRight: Spacing.S8,
    flexShrink: 0,
  },

  previewHeader: {
    minHeight: PixelSize.S16,
  },
  messageSenderName: {
    fontSize: FontSize.SMALL,
    fontWeight: "700",
    marginBottom: 4,
    marginHorizontal: 8,
  },
  messagePreview: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: "row",
    display: "flex",
    minHeight: PixelSize.S16,
    paddingHorizontal: Spacing.S4,
  },
  messageTitlePreview: {
    flex: 1,
    fontWeight: "bold",
  },
  iconMessage: {
    fontSize: 10,
    marginHorizontal: 2,
    color: ColorStyle.FONT_LOW_EMPHASIS,
    textAlign: "center",
  },
  fileContainer: {
    width: 55,
    height: 55,
    marginRight: 10,
    marginVertical: 5,
    borderRadius: Radius.LARGE,
    backgroundColor: Color.WHITE,
  },
  iconContainer: {
    width: 55,
    height: 55,
    marginRight: 10,
    marginVertical: 5,
    borderRadius: Radius.LARGE,
    backgroundColor: Color.WHITE,
    justifyContent: "center",
    alignItems: "center",
    padding: 6,
  },
  icon: {
    alignSelf: "center",
  },
  imagePreview: {
    width: "100%",
    height: "100%",
    borderRadius: 12,
  },
  audioTranscriptionContainer: {
    flexShrink: 1,
    flexGrow: 1,
    flexDirection: "row",
  },
  audioTranscriptionTextContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "100%",
  },
});
