import { useCallback } from "react";
import { useSelector } from "react-redux";

import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { selectPoolFolderSchemasDict } from "@kraaft/shared/core/modules/schema/schema.selectors";
import {
  TrackData,
  TrackRoomSchemaVisibilityToggleSource,
} from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

export const useEventDataGetterForToggleRoomSchema = ({
  roomId,
  source,
}: { roomId: string; source: TrackRoomSchemaVisibilityToggleSource }): ((
  schemaId: string,
) => TrackData["Enabled Room Schema"] | TrackData["Disabled Room Schema"]) => {
  const room = useSelector(selectRoom(roomId));
  const schemas = useSelector(selectPoolFolderSchemasDict(room?.poolId));

  return useCallback(
    (schemaId: string) => {
      const schema = schemas[schemaId];
      const roomTitle = getExistingRoomRecordField(
        room?.record.properties,
        "title",
        "",
      );

      return {
        room_id: roomId,
        room_name: roomTitle,
        schema_id: schemaId,
        schema_name: schema?.name ?? "",
        source,
      };
    },
    [room, roomId, schemas, source],
  );
};
