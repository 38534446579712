import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { simpleClipboardTextFromCell } from "@kraaft/web/src/components/modularTable/components/table/clipboardTextFromCellUtils";
import { roomNameRecordComparator } from "@kraaft/web/src/components/modularTable/components/table/recordComparatorUtils";
import { IFieldConfig } from "@kraaft/web/src/components/modularTable/components/types";

import { withBasicCell } from "../_withTableCell/withBasicCell";
import { RoomNameCell } from "./basicCell";

export const roomNameConfig: IFieldConfig<KColumnType.roomName> = {
  type: KColumnType.roomName,
  TableCell: withBasicCell(RoomNameCell),
  TableEditor: null,
  comparator: roomNameRecordComparator,
  getClipboardTextFromCell: simpleClipboardTextFromCell,
};
