import React from "react";

import { withOnPressTracking } from "@kraaft/shared/components/tracking/withOnPressTracking";
import { Button, ButtonHandle, ButtonProps } from "@kraaft/ui";

export type HeaderIconButtonProps = ButtonProps & {
  id?: string;
  selected?: boolean;
  testID?: string;
};

export const HeaderIconButton = React.forwardRef<
  ButtonHandle,
  HeaderIconButtonProps
>(({ id, selected, style, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      id={id}
      variant="TERTIARY"
      size="SMALL"
      selected={selected}
      style={style}
      {...props}
    />
  );
});

export const TrackedHeaderIconButton = withOnPressTracking(HeaderIconButton);
