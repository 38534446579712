import { ReactNode, useContext, useMemo, useRef } from "react";

import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame";
import { BaseDialogFrameProps } from "@kraaft/shared/components/kDialog/kDialogProps";
import {
  createModalContext,
  withModalFrame,
} from "@kraaft/shared/components/modalContext";
import { uuid } from "@kraaft/shared/core/utils";

const DialogContext = createModalContext<BaseDialogFrameProps>("Dialog");

export const DialogProvider = withModalFrame(KDialogFrame, DialogContext);

export const useDialog = () => {
  const { open, close } = useContext(DialogContext);
  const key = useRef(uuid());

  return useMemo(
    () => ({
      openDialog: (element: ReactNode, options?: BaseDialogFrameProps) => {
        open(key.current, element, options ?? {});
      },
      closeDialog: () => close(key.current),
    }),
    [open, close],
  );
};
