import { useMemo } from "react";

import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import { getFallbackLanguage } from "@kraaft/shared/core/services/i18next/i18nextUtils";
import { languageDetector } from "@kraaft/shared/core/services/i18next/languageDetector/languageDetectorService";

export function useDeviceLanguage(): PoolLanguage {
  return useMemo(() => {
    const detected = languageDetector.detect();
    if (Array.isArray(detected)) {
      return getFallbackLanguage(detected[0]);
    }
    return getFallbackLanguage(detected);
  }, []);
}
