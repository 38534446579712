import { makeStyles } from "@mui/styles";

import { SearchBar, SearchBarProps } from "./searchBar";

const SearchbarPreventPlaceholderOverflow = (props: SearchBarProps) => {
  const classes = useStyles({ isStatic: !!props.isStatic });

  return (
    <div className={classes.wrapper}>
      <SearchBar {...props} />
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    width: ({ isStatic }: { isStatic: boolean }) =>
      isStatic ? "100%" : undefined,
    "& input#searchbar": {
      width: 0,
      "&::placeholder": {
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
});

export { SearchbarPreventPlaceholderOverflow as SearchBar };
