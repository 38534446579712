import { useSelector } from "react-redux";

import { DraggableMessageType } from "@kraaft/shared/components/draggableMessage/types";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { ModularRecordProperties } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { selectSchemaColumnsOfType } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Color, ColorStyle, ColorValue, IconName } from "@kraaft/ui";

type ColoredIcon = { icon: IconName; color: ColorValue };

export const getModularFolderIconWithColor = (
  schema: KFolderSchema | undefined,
  modularFolder: ModularFolder | undefined,
): ColoredIcon => {
  if (!schema || !modularFolder) {
    return {
      icon: "question-marker",
      color: ColorStyle.FONT_LOW_EMPHASIS,
    };
  }
  return (
    getHighlighedCheckboxIconWithColor(
      schema.highlightedCheckbox,
      modularFolder.properties,
    ) ?? {
      icon: KSchemaUtils.getSchemaIconName(schema.icon),
      color: ColorStyle.FONT_LOW_EMPHASIS,
    }
  );
};

export const getModularFolderIcon = (
  schema: KFolderSchema | undefined,
  modularFolder: ModularFolder,
): IconName => {
  return getModularFolderIconWithColor(schema, modularFolder).icon;
};

export const getHighlighedCheckboxIconWithColor = (
  highlightedCheckboxColumnKey: string | undefined,
  recordProperties: ModularRecordProperties,
): ColoredIcon | undefined => {
  if (!highlightedCheckboxColumnKey) {
    return undefined;
  }
  const completed = ModularRecordUtils.findColumn(
    recordProperties,
    highlightedCheckboxColumnKey,
    KColumnType.checkbox,
  )?.value;
  if (completed) {
    return {
      icon: "round-checkbox-on-fill",
      color: Color.BLUE_KRAAFT,
    };
  }
  return {
    icon: "round-checkbox-unchecked",
    color: ColorStyle.FONT_LOW_EMPHASIS,
  };
};

export function useModularFolderDropAcceptType(
  schemaId: string,
): DraggableMessageType[] {
  const attachmentColumns = useSelector(
    selectSchemaColumnsOfType(schemaId, KColumnType.attachment),
  );

  const dropAcceptType: Set<DraggableMessageType> = new Set();

  if (attachmentColumns.some((column) => column.mode === "gallery")) {
    dropAcceptType.add(DraggableMessageType.MessageImage);
    dropAcceptType.add(DraggableMessageType.MessageVideo);
  }

  if (attachmentColumns.some((column) => column.mode === "list")) {
    dropAcceptType.add(DraggableMessageType.MessageImage);
    dropAcceptType.add(DraggableMessageType.MessageVideo);
    dropAcceptType.add(DraggableMessageType.MessageDocument);
  }

  return Array.from(dropAcceptType);
}
