import { useDispatch, useSelector } from "react-redux";

import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { selectRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

type StatusType =
  | "has-no-messages"
  | "has-unread-messages"
  | "has-read-messages";

export function useRoomUnread(roomId: string) {
  const dispatch = useDispatch();

  const roomCard = useSelector(selectRoomCard(roomId));

  const hasUnread =
    roomCard && roomCard.type === "member" ? roomCard.unread : false;
  const status: StatusType = !roomCard?.lastMessageInfo
    ? "has-no-messages"
    : hasUnread
      ? "has-unread-messages"
      : "has-read-messages";

  const markAsUnread = useCallbackRealtime(
    async ([_roomId, _hasUnread]) => {
      trackEvent({
        eventName: "Update Conversation Read Status",
        room_id: _roomId,
        marked_as: "unread",
      });

      dispatch(
        roomActions.markRoomUnread({
          roomId: _roomId,
          currentValue: Boolean(_hasUnread),
        }),
      );
    },
    [dispatch],
    [roomId, hasUnread],
  );

  const markAsRead = useCallbackRealtime(
    async ([_roomId, _hasUnread]) => {
      trackEvent({
        eventName: "Update Conversation Read Status",
        room_id: _roomId,
        marked_as: "read",
      });

      dispatch(
        roomActions.markRoomRead({
          roomId: _roomId,
          currentValue: Boolean(_hasUnread),
        }),
      );
    },
    [dispatch],
    [roomId, hasUnread],
  );

  return { markAsUnread, markAsRead, status };
}
