import { StyleSheet } from "react-native";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  content: {
    padding: Spacing.S16,
    flexGrow: 1,
  },
});
