import { StyleSheet, View } from "react-native";

import { capitalize } from "@kraaft/helper-functions";
import { Pill } from "@kraaft/shared/components/pill";
import { Tooltip } from "@kraaft/shared/components/tooltip";
import { dateFormatter } from "@kraaft/shared/core/services/dateFormatter/dateFormatter.provider";
import { type ResourceKey } from "@kraaft/shared/core/services/i18next";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import {
  ColorStyle,
  ColorValue,
  Icon,
  IconName,
  IconSize,
  Spacing,
  Text,
} from "@kraaft/ui";

export interface EntryLayoutProps {
  date: Date;
  iconName: IconName;
  iconColor: ColorValue;
  i18nKey: ResourceKey;
  i18nValues: Record<string, unknown>;
  errorMessage?: string;
}

export const EntryLayout = ({
  date,
  iconName,
  iconColor,
  i18nKey,
  i18nValues,
  errorMessage,
}: EntryLayoutProps) => {
  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Tooltip
          title={capitalize(dateFormatter.format(date))}
          placement="top-start"
        >
          <View>
            <Pill
              icon="calendar"
              content={capitalize(
                dateFormatter.getRelativeLabel(date, new Date()),
              )}
              size="small"
            />
          </View>
        </Tooltip>
        {errorMessage ? (
          <Tooltip title={errorMessage} placement="left">
            <View>
              <Icon name="alert-circle" color="RED_TOMATO" />
            </View>
          </Tooltip>
        ) : null}
      </View>
      <View style={styles.contentContainer}>
        <Icon name={iconName} color={iconColor} size={IconSize.MEDIUM} />
        <Text size="BODY" style={styles.text}>
          <Trans
            i18nKey={i18nKey}
            values={i18nValues}
            components={{
              bold: <Text size="BODY" weight="bold" />,
            }}
          />
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
    padding: Spacing.S16,
    gap: Spacing.S8,
  },
  activeContainer: {
    backgroundColor: ColorStyle.ACTION_SELECTED,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  contentContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S12,
  },
  text: {
    flexShrink: 1,
  },
});
