import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import {
  ActionFooter,
  FooterActions,
} from "@kraaft/shared/components/actionFooter/actionFooter";
import {
  PhotoGallery,
  PhotoGalleryProps,
} from "@kraaft/shared/components/galleries/photoGallery/photoGallery";
import { DEFAULT_PHOTO_GALLERY_CONSTRAINTS } from "@kraaft/shared/components/galleries/photoGallery/useSortedGallery";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";
import { Color, ColorStyle } from "@kraaft/ui";

import { styles } from "./photoGalleryPicker.styles";

interface Props extends Pick<PhotoGalleryProps, "medias"> {
  roomId: string;
  onSubmit: () => void;
  isLoading?: boolean;
}

const PhotoGalleryPicker = (props: Props) => {
  const { roomId, medias, onSubmit, isLoading } = props;
  const { t } = useTranslation();

  const { selectionCount } = useMediaSelectionContext({
    type: "image",
    roomId,
    medias,
  });

  const actions: FooterActions = [
    {
      accessibilityLabel: t("add"),
      text: t("add"),
      onPress: onSubmit,
      loading: isLoading,
      disabled: selectionCount === 0,
    },
  ];

  return (
    <View style={styles.container}>
      <View style={styles.galleryContainer}>
        <PhotoGallery
          roomId={roomId}
          medias={medias}
          imageHeights={DEFAULT_PHOTO_GALLERY_CONSTRAINTS}
          mode="selection"
          backgroundColor={Color.WHITE}
        />
      </View>
      <ActionFooter
        actions={actions}
        direction={isNative() ? "vertical" : "horizontal"}
        backgroundColor={ColorStyle.BACKGROUND_LIGHT}
      />
    </View>
  );
};

export { PhotoGalleryPicker };
