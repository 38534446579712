import { StyleSheet } from "react-native";

import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  listWrapper: {
    backgroundColor: Color.WHITE,
    paddingLeft: Spacing.S8,
    height: "100%",
  },
});
