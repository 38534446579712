import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { PoolAdmin, SerializedDirectory } from "./poolAdminState";

export const poolAdminAdapter = createEntityAdapter<PoolAdmin, string>({
  selectId: (entity) => entity.poolId,
});

const slice = createSlice({
  name: "poolAdmin",
  initialState: poolAdminAdapter.getInitialState(),
  reducers: {
    poolAdminSetOne: poolAdminAdapter.setOne,
    setDefaultDirectoryTreeForPool: (
      state,
      {
        payload,
      }: PayloadAction<{
        poolId: string;
        tree: SerializedDirectory[];
      }>,
    ) => {
      let poolAdminState = state.entities[payload.poolId];

      if (!poolAdminState) {
        poolAdminState = {
          poolId: payload.poolId,
        };
        state.entities[payload.poolId] = poolAdminState;
      }

      poolAdminState.defaultDirectoryTree = payload.tree;
    },
  },
});

export const poolAdminReducers = slice.reducer;
export const { poolAdminSetOne, setDefaultDirectoryTreeForPool } =
  slice.actions;
