import { StyleSheet } from "react-native";

import { actionGroupStyleObject } from "@kraaft/shared/components/actionGroup/actionGroup.styles";
import { ensureValidStyleSheet } from "@kraaft/shared/core/utils";
import { ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  ...ensureValidStyleSheet(actionGroupStyleObject),
  buttonsContainer: {
    padding: Spacing.S16,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
});
