import { select } from "typed-redux-saga/macro";

import { DatadogConfigFlag } from "@kraaft/shared/core/modules/featureFlags/featureFlags.default";
import { selectFeatureFlag } from "@kraaft/shared/core/modules/featureFlags/featureFlags.selectors";
import { selectCurrentUserAuth } from "@kraaft/shared/core/modules/user/userSelectors";
import { DatadogService } from "@kraaft/shared/core/services/datadog";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

export function* datadogSagas() {
  const datadogConfig: DatadogConfigFlag = yield waitFor(
    selectFeatureFlag("DATADOG_CONFIG"),
  );

  yield DatadogService.init(datadogConfig);

  const currentUser = yield* select(selectCurrentUserAuth);
  yield DatadogService.setUser(currentUser ? { id: currentUser?.uid } : null);
}
