import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ImageStyle } from "expo-image";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { SignatureViewer } from "@kraaft/shared/components/signature/signatureViewer";
import { useDeleteSignature } from "@kraaft/shared/components/signature/signatureViewer/useDeleteSignature";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Image, morphClassNameAsStyle } from "@kraaft/ui";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";

import { useStyles } from "./basicCell.styles";

export const SignatureCell = React.forwardRef<
  HTMLDivElement,
  IBasicCellProps<KColumnType.signature>
>(({ value, column, row, sectionLockInfo }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const styles = useStyles();
  const [open, setOpen, setClose] = useBooleanState();

  const deleteSignature = useCallback(() => {
    dispatch(
      OfflineModularFolderActions.edit({
        editions: [
          {
            id: row.id,
            properties: [
              [
                column.key,
                {
                  columnType: KColumnType.signature,
                  value: undefined,
                },
              ],
            ],
          },
        ],
      }),
    );
  }, [column, dispatch, row.id]);

  const { dialog: deleteDialog, askDelete } =
    useDeleteSignature(deleteSignature);

  const actions = useMemo<FooterActions>(
    () => [
      {
        accessibilityLabel: t("signature.viewer.erase"),
        text: t("signature.viewer.erase"),
        onPress: askDelete,
        icon: "trash-03",
        destructive: true,
      },
    ],
    [askDelete, t],
  );

  const imageStyle = useMemo<ImageStyle>(() => {
    return morphClassNameAsStyle(styles.image) as ImageStyle;
  }, [styles.image]);

  return (
    <>
      <div className={styles.root} ref={ref}>
        {value && (
          <button type="button" onClick={setOpen} className="no-button">
            <Image
              style={imageStyle}
              preferredSize="thumbnail"
              source={value.original.downloadUrl}
              alt="signature"
            />
          </button>
        )}
      </div>
      {value && (
        <KDialog
          title={t("signature.word")}
          open={open}
          onClose={setClose}
          actions={!sectionLockInfo.isLocked ? actions : undefined}
          content={
            <SignatureViewer
              signatureSize={300}
              attachment={value}
              onDelete={askDelete}
              hideDeleteButton
            />
          }
        />
      )}
      {deleteDialog}
    </>
  );
});
