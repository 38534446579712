import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { MapTrackingInfo } from "@kraaft/shared/components/mapController/types";
import { useAlert } from "@kraaft/shared/core/modules/alert/useAlert";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export function useMapScreenshoter(
  screenshoterId: string,
  trackingInfo: MapTrackingInfo,
) {
  const { t } = useTranslation();
  const [isScreenshoting, setScreenshoting] = useState(false);
  const { alertError } = useAlert();

  const downloadScreenshot = useCallback(async () => {
    trackEvent({ eventName: "Download Map", ...trackingInfo });

    const mapElement = document.getElementById(screenshoterId);

    if (!mapElement) {
      return;
    }

    setScreenshoting(true);
    try {
      const html2canvas = await import("html2canvas");

      const canvas = await html2canvas.default(mapElement, {
        useCORS: true,
        ignoreElements: (element: Element) => {
          if (element.tagName.toLowerCase() === "div") {
            if (element.className.includes("gmnoprint")) {
              return true;
            }
            if (element.className.includes("gm-style-cc")) {
              return true;
            }
          }

          return false;
        },
      });
      const image = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const imageBrowserPath = fileAllocator.parseRemotePath(image);
      await fileSaver.download(
        imageBrowserPath,
        `${t("mapScreenshot.filename")}.png`,
      );
    } catch (e) {
      console.log("error screenshoting", e);
      alertError(e, "useMapScreenshoter");
    }
    setScreenshoting(false);
  }, [alertError, screenshoterId, t, trackingInfo]);

  return { downloadScreenshot, isScreenshoting };
}
