import { makeStyles } from "@mui/styles";

import { LogHolder } from "@kraaft/shared/components/message/messageLog/holders/logHolder.props";
import { Radius, Spacing } from "@kraaft/ui";

export const GradientLogHolder = ({ children }: LogHolder) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

const useStyles = makeStyles({
  root: {
    borderRadius: Radius.LARGE,
    padding: Spacing.S8,
    textAlign: "center",
    background: "linear-gradient(90deg, #4761D6 0%, #EA536E 100%)",
  },
});
