import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { markerSize } from "@kraaft/shared/components/geolocation/baseMarker/baseMarkerConfig";
import { ClusterConfig } from "@kraaft/shared/components/mapController/markers/marker/cluster/cluster.types";
import { getMarkerCoordinates } from "@kraaft/shared/components/mapController/markers/markerUtils";
import { RoomCardListForMap } from "@kraaft/shared/components/mapController/markers/roomMarker/roomCardListForMap";
import { RoomCluster } from "@kraaft/shared/components/mapController/markers/roomMarker/roomCluster";
import { MarkerGeoJSON } from "@kraaft/shared/components/mapController/types";
import { geolocationContextActions } from "@kraaft/shared/core/modules/room/roomActions";
import { RoomCardWithGeolocation } from "@kraaft/shared/core/modules/roomCard/roomCard.state";

interface Props {
  onTooltipPress: (roomId: string) => void;
}

const useRoomClusterConfig = ({
  onTooltipPress,
}: Props): {
  clusterConfig: ClusterConfig<RoomCardWithGeolocation>;
  roomCardListPanel?: React.ReactNode;
} => {
  const dispatch = useDispatch();
  const [selectedClusterMarkers, setSelectedClusterMarkers] = useState<
    MarkerGeoJSON<RoomCardWithGeolocation>[]
  >([]);

  const handleTooltipPress = useCallback(
    (roomCard: RoomCardWithGeolocation) => {
      onTooltipPress(roomCard.roomId);
    },
    [onTooltipPress],
  );

  const handleClose = useCallback(() => {
    setSelectedClusterMarkers([]);
  }, []);

  const roomCardListPanel = useMemo(
    () => (
      <RoomCardListForMap
        markers={selectedClusterMarkers}
        open={selectedClusterMarkers.length > 0}
        onClose={handleClose}
        onRowPress={handleTooltipPress}
      />
    ),
    [selectedClusterMarkers, handleClose, handleTooltipPress],
  );

  const clusterConfig = useMemo<ClusterConfig<RoomCardWithGeolocation>>(
    () => ({
      ClusterRenderer: RoomCluster,
      size: markerSize,
      tracksViewChanges: false,
      onPress: (markers, isMaxZoom) => {
        if (isMaxZoom) {
          if (isNative()) {
            setSelectedClusterMarkers(markers);
          }
        } else {
          const boundsList = markers.map((marker) =>
            getMarkerCoordinates(marker),
          );

          dispatch(
            geolocationContextActions({
              actions: [{ type: "zoomOnBounds", boundsList }],
            }),
          );
        }
      },
      onTooltipPress: (roomCard) => {
        onTooltipPress(roomCard.roomId);
      },
    }),
    [dispatch, onTooltipPress],
  );

  return { clusterConfig, roomCardListPanel };
};

export { useRoomClusterConfig };
