import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  titleContainer: {
    padding: Spacing.S16,
  },
  title: {
    fontSize: FontSize.TITLE,
    fontWeight: "bold",
    marginRight: Spacing.S8,
  },
  subTitle: {
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },

  addButton: {
    margin: "0 16px",
    minHeight: 160,
    minWidth: 160,
    maxHeight: 240,
    maxWidth: 320,

    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    alignItems: "center",

    border: "none",
    borderRadius: 10,
    backgroundColor: ColorStyle.ACTION_SELECTED,

    transition: "opacity 0.2s",
  },
  addButtonWithHover: {
    cursor: "pointer",

    "&:hover": {
      backgroundColor: ColorStyle.ACTION_CTA,
      transform: "scale(1.025)",
      transition: "transform 200ms ease, background-color 200ms ease",
    },
  },

  image: {
    maxHeight: "200px",
    minWidth: "200px",
    maxWidth: "300px",
    padding: "10px",

    borderRadius: 15,
    objectFit: "cover",
  },
});
