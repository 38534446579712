import { View } from "react-native";
import { useSelector } from "react-redux";

import { ConnectedEditSchemaVisibilityForRoom } from "@kraaft/shared/components/roomSchemaVisibility/editSchemaVisibilityForRoom.connected";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { RoomSchemaVisibilityOptimisticSelector } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.optimistic";
import { TrackRoomSchemaVisibilityToggleSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";
import { Preloader } from "@kraaft/ui";

import { styles } from "./editSchemaVisibilityForRoom.styles";

type EditSchemaVisibilityForRoomProps = {
  roomId: string;
  source: TrackRoomSchemaVisibilityToggleSource;
  showModularFolderCount?: boolean;
};

const EditSchemaVisibilityForRoom = ({
  roomId,
  source,
  showModularFolderCount,
}: EditSchemaVisibilityForRoomProps) => {
  const room = useSelector(selectRoom(roomId));
  const roomSchemaVisibility = useSelector(
    RoomSchemaVisibilityOptimisticSelector.selectBuilt(roomId),
  );

  if (room === undefined || roomSchemaVisibility === undefined) {
    return (
      <View style={styles.container}>
        <Preloader absoluteFill transparent />
      </View>
    );
  }

  return (
    <ConnectedEditSchemaVisibilityForRoom
      poolId={room.poolId}
      roomId={roomId}
      roomSchemaVisibility={roomSchemaVisibility}
      source={source}
      showModularFolderCount={showModularFolderCount}
    />
  );
};

export { EditSchemaVisibilityForRoom };
