import {
  MembersPanelContent,
  MembersPanelContentProps,
} from "@kraaft/shared/components/membersPanelContent";
import { SlidePanel } from "@kraaft/shared/components/slidePanel";
import { Room } from "@kraaft/shared/core/modules/room/roomState";

type Props = Pick<
  MembersPanelContentProps,
  "isEditEnabled" | "onClose" | "onEdit"
> & {
  roomId: Room["id"];
  portalHostname?: string;
  isVisible?: boolean;
};

const MembersPanel = ({
  roomId,
  portalHostname,
  isVisible,
  ...props
}: Props) => {
  const { onClose } = props;

  return (
    <SlidePanel
      name="RoomMembers"
      portalHostname={portalHostname}
      open={isVisible}
      onClose={onClose}
    >
      <MembersPanelContent roomId={roomId} {...props} />
    </SlidePanel>
  );
};

export { MembersPanel };
