import { keyBy } from "lodash";
import { EventChannel, eventChannel } from "redux-saga";
import { call, debounce, put, select } from "typed-redux-saga/macro";

import {
  MessageDataActions,
  MessageDataStateActions,
} from "@kraaft/shared/core/modules/message/messageData/messageData.actions";
import {
  selectMessageDataForRoom,
  selectMessageInRoom,
  selectMessageLinkedList,
} from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import { fetchAnsweredMessages } from "@kraaft/shared/core/modules/message/messageData/sagas/manuallyLoadMessages";
import { Message } from "@kraaft/shared/core/modules/message/messageState";
import { sortMessages } from "@kraaft/shared/core/modules/message/messageUtils";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { FirestoreTypes } from "@kraaft/shared/core/services/firestore/sdk";
import { takeCountedDeep, waitFor } from "@kraaft/shared/core/utils/sagas";
import {
  CursorState,
  LinkedList,
  LinkedListElement,
} from "@kraaft/shared/core/utils/useBidirectional/createLinkedLists";
import { LinkedListHelpers } from "@kraaft/shared/core/utils/useBidirectional/linkedList";

type Meta =
  | EventChannel<{
      messages: Record<string, Message>;
      docs: FirestoreTypes.QueryDocumentSnapshot[];
      isFirstPayload: boolean;
    }>
  | undefined;

export function* subscribeToMessagesSaga() {
  yield takeCountedDeep(
    MessageDataActions.subscribe,
    MessageDataActions.unsubscribe,
    subscribeToMessages,
    unsubscribeFromMessages,
    (a) => a.payload.roomId,
  );
}

async function createChannel(
  userId: string,
  roomId: string,
  startAfterMessage: Message | undefined,
) {
  return eventChannel<{
    messages: Record<string, Message>;
    docs: FirestoreTypes.QueryDocumentSnapshot[];
    isFirstPayload: boolean;
    startAfterMessage: Message | undefined;
  }>((emit) => {
    const unsubscribe = Firestore.subscribeToMessages(
      userId,
      roomId,
      startAfterMessage?.createdAt,
      (messages, docs, isFirstPayload) =>
        emit({
          messages: messages.messages,
          docs,
          isFirstPayload,
          startAfterMessage: startAfterMessage,
        }),
    );
    return unsubscribe;
  });
}

function appendNewMessagesToLinkedList(
  linkedList: LinkedList,
  messages: Record<string, Message>,
  startAfterMessage: Message | undefined,
) {
  const startAfterId = startAfterMessage?.id;

  const startAfterLink =
    startAfterId !== undefined ? linkedList[startAfterId] : undefined;

  let lastLink = startAfterLink && { ...startAfterLink };
  const builtLinkedList: LinkedList = lastLink
    ? { [lastLink.itemId]: lastLink }
    : {};

  for (const message of sortMessages(messages)) {
    // ignore old messages
    if (
      startAfterMessage &&
      message.createdAt.getTime() <= startAfterMessage.createdAt.getTime()
    ) {
      continue;
    }

    const link: LinkedListElement = {
      earlierId:
        lastLink?.itemId ??
        (startAfterMessage ? CursorState.UNKNOWN : CursorState.FINISHED),
      itemId: message.id,
      laterId: CursorState.FINISHED,
    };
    if (lastLink) {
      lastLink.laterId = link.itemId;
    }
    lastLink = link;
    builtLinkedList[link.itemId] = link;
  }
  return builtLinkedList;
}

function* subscribeToMessages(
  registerMeta: (meta: Meta) => void,
  { payload: { roomId } }: ReturnType<(typeof MessageDataActions)["subscribe"]>,
) {
  const currentUserId = yield* select(selectCurrentUserId);

  if (!currentUserId) {
    return;
  }

  const linkedList = yield* waitFor((state) => {
    const roomLinkedList = state.messageData.linkedLists[roomId];
    if (!roomLinkedList) {
      return undefined;
    }
    return roomLinkedList;
  });

  const lastKnownMessageId = LinkedListHelpers.getLatest(
    linkedList,
    CursorState.FINISHED,
  );

  const lastKnownMessage = yield* select(
    selectMessageInRoom(roomId, lastKnownMessageId?.itemId ?? ""),
  );

  const lastMessage = lastKnownMessage
    ? lastKnownMessage
    : !LinkedListHelpers.isEmpty(linkedList)
      ? yield* call(Firestore.fetchRoomLastMessage, roomId, currentUserId)
      : undefined;

  const channel = yield* call(
    createChannel,
    currentUserId,
    roomId,
    lastMessage,
  );
  registerMeta(channel);

  yield* debounce(
    300,
    channel,
    function* ({ messages, docs, isFirstPayload, startAfterMessage }) {
      const answeredMessages = yield* call(
        fetchAnsweredMessages,
        currentUserId,
        roomId,
        messages,
        false,
      );

      yield* put(
        MessageDataStateActions.addMessages({
          roomId,
          messages: { ...messages, ...answeredMessages },
          messageDocs: keyBy(docs, (d) => d.id),
          shouldActAsNewMessage: !isFirstPayload,
        }),
      );

      const currentLinkedList = yield* select(selectMessageLinkedList(roomId));

      const roomMessages = yield* select(selectMessageDataForRoom(roomId));
      const lastLink = LinkedListHelpers.getLatest(
        currentLinkedList,
        CursorState.FINISHED,
      );
      const lastLinkMessage = roomMessages[lastLink?.itemId ?? ""];
      const isRoomEmpty = LinkedListHelpers.isEmpty(currentLinkedList);

      if (!lastLinkMessage && !isRoomEmpty) {
        return;
      }

      const builtLinkedList = appendNewMessagesToLinkedList(
        currentLinkedList,
        messages,
        startAfterMessage,
      );

      yield* put(
        MessageDataStateActions.addLinkedList({
          roomId,
          linkedList: builtLinkedList,
        }),
      );
    },
  );
}

function* unsubscribeFromMessages(meta: Meta) {
  meta?.close();
}
