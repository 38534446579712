import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { ColorStyle, Icon } from "@kraaft/ui";

import { CurrentStepProps } from "../../../multiStepDialogTypes";

import { useStyles } from "./doneStep.styles";

type DoneStepProps = CurrentStepProps;

const DoneStep = (props: DoneStepProps) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const title = t("connectKizeoCongratsTitle");

  const content = (
    <div className={classes.dialogContentContainer}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Icon name="check" size={70} color={ColorStyle.ACTION_CTA} />
        </Grid>
        <Grid item>
          <Typography align="center" classes={{ root: classes.text }}>
            {t("connectKizeoCongratsContent")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );

  return <KDialogContent {...{ onClose, title, content }} />;
};

export { DoneStep };
