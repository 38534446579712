import { useRef } from "react";

import { UserMessage } from "@kraaft/shared/core/modules/message/messageState";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button, Sheet } from "@kraaft/ui";

import { ReactButtonSheetContent } from "./reactButton.sheet.content";

interface Props {
  onReact: (emoji: string) => void;
  message: UserMessage;
}

const ReactToMessageSheet = Sheet({ web: "anchored" });

export const ReactButton = ({ onReact, message }: Props) => {
  const ref = useRef(null);
  const [isOpen, openSheet, closeSheet] = useBooleanState();

  return (
    <>
      <ReactToMessageSheet.Host anchor={ref} open={isOpen} onClose={closeSheet}>
        <ReactButtonSheetContent
          onReact={onReact}
          onClose={closeSheet}
          message={message}
        />
      </ReactToMessageSheet.Host>
      <Button
        id="message-react-button"
        icon="face-smile"
        onPress={openSheet}
        size="SMALL"
        variant="TERTIARY"
        ref={ref}
      />
    </>
  );
};
