import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { KInput, KInputHandle } from "@kraaft/shared/components/input/KInput";
import { Toggle } from "@kraaft/shared/components/toggle";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { useLoader } from "@kraaft/shared/core/utils/useLoader";
import { Button, Text } from "@kraaft/ui";
import { useCopyButton } from "@kraaft/web/src/components/copyButton/copyButton";
import { toggleInboundEmail } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminActions";
import { selectPoolAdmin } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminSelectors";
import { PoolInboundEmail } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminState";
import { usePoolAdminSubscription } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminUtils";

import { IntegrationSection } from "../integrationSection";

import { useStyles } from "./inboundEmailSection.styles";

const InboundToggle = ({
  inbound,
  toggleInbound,
}: {
  inbound: PoolInboundEmail & { id: string };
  toggleInbound: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const inputRef = useRef<KInputHandle>(null);

  const { copyToClipboard, icon: rightCopyIcon } = useCopyButton({
    value: inbound?.email,
  });

  return (
    <div className={classes.inboundContainer}>
      <Toggle value={!!inbound?.isActive} setValue={toggleInbound} />
      <div className={classes.inboundInput}>
        <KInput
          ref={inputRef}
          value={inbound.email}
          onChangeText={() => {}}
          label={t("inboundEmailIntegration.label")}
          onFocus={copyToClipboard}
          rightIcon={rightCopyIcon}
          disabled
          selectTextOnFocus
        />
      </div>
    </div>
  );
};

const InboundEmailSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pool = useSelector(selectCurrentPool);
  const classes = useStyles();
  const poolId = pool?.id;

  usePoolAdminSubscription(poolId);
  const poolAdmin = useSelector(selectPoolAdmin(poolId));

  const { loaderId, loading } = useLoader({
    prefix: "InboundEmailSectionLoader",
  });

  const inbounds = useMemo(() => {
    return (
      poolAdmin?.inboundEmails &&
      Object.entries(poolAdmin.inboundEmails).map(([key, value]) => ({
        id: key,
        ...value,
      }))
    );
  }, [poolAdmin]);

  const hasInbounds = inbounds && inbounds.length > 0;

  const toggleInbound = useCallback(
    (enabled: boolean, inboundEmailId?: string) => {
      if (poolId) {
        dispatch(
          toggleInboundEmail({
            poolId,
            enabled,
            inboundEmailId,
            loaderId,
          }),
        );
      }
    },
    [poolId, dispatch, loaderId],
  );

  const activateInbound = useCallback(() => {
    toggleInbound(true);
  }, [toggleInbound]);

  return (
    <IntegrationSection
      actions={[]}
      details={
        <div className={classes.sectionContainer}>
          <div className={classes.description}>
            <Text color="FONT_LOW_EMPHASIS">
              {t("inboundEmailIntegration.description")}
            </Text>
          </div>
          {hasInbounds && inbounds ? (
            inbounds.map((inbound) => (
              <InboundToggle
                key={inbound.id}
                inbound={inbound}
                toggleInbound={(value: boolean) =>
                  toggleInbound(value, inbound.id)
                }
              />
            ))
          ) : (
            <div>
              <Button
                loading={loading}
                onPress={activateInbound}
                accessibilityLabel={t("inboundEmailIntegration.add")}
                text={t("inboundEmailIntegration.add")}
              />
            </div>
          )}
        </div>
      }
      title={t("inboundEmailIntegration.title")}
    />
  );
};

export { InboundEmailSection };
