import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { Spacing, Text } from "@kraaft/ui";

export const AutoSaveInfoText = () => {
  const { t } = useTranslation();

  return (
    <View style={style.container}>
      <Text color="FONT_LOW_EMPHASIS" style={style.infoText}>
        {t("modularFolderAutoSaveInfoText")}
      </Text>
    </View>
  );
};

const style = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    padding: Spacing.S16,
  },
  infoText: {
    textAlign: "center",
  },
});
