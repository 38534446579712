import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

import { EmojiPicker } from "@kraaft/shared/components/message/emojiPicker";
import { UserMessage } from "@kraaft/shared/core/modules/message/messageState";
import { selectCurrentUserAuth } from "@kraaft/shared/core/modules/user/userSelectors";
import { Color, Spacing } from "@kraaft/ui";

interface Props {
  onReact: (emoji: string) => void;
  onClose: () => void;
  message: UserMessage;
}

export const ReactButtonSheetContent = ({
  onReact,
  onClose,
  message,
}: Props) => {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUserAuth);

  const myReaction = message.reactions[currentUser?.uid ?? ""];

  const handleEmojiPicked = (emoji: string) => {
    onReact(emoji);
    onClose();
  };

  return (
    <div className={classes.root}>
      <EmojiPicker
        onEmojiPicked={handleEmojiPicked}
        selectedEmoji={myReaction?.emoji}
      />
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    borderRadius: (48 + 8 * 2) / 2,
    backgroundColor: Color.WHITE,
    padding: Spacing.S8,
    boxShadow: "0px 4px 14px 0px #0000001F",
  },
});
