export const poolCompanyIndustries = ["publicWorks", "construction"] as const;

export type PoolCompanyIndustry =
  | (typeof poolCompanyIndustries)[number]
  | `other:${string}`;

export function isOtherCompanyIndustry(
  companyIndustry: string,
): companyIndustry is `other:${string}` {
  return companyIndustry.startsWith("other:");
}
