import { StyleSheet } from "react-native";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  actionIconContainer: {
    padding: Spacing.S4,
  },
  actionIcon: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
});
