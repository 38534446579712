import { useCallback, useMemo } from "react";
import { ListRenderItemInfo } from "react-native";
import { flatten } from "lodash/fp";

import { DroppableDirectoryListCard } from "@kraaft/shared/components/actionCardList/directoryList/droppableDirectoryListCard";
import { useDirectoryListContext } from "@kraaft/shared/components/actionCardList/directoryList/useDirectoryListContext";
import { FlatListWithEmptyState } from "@kraaft/shared/components/emptyState/lists/flatList";
import { FileCard } from "@kraaft/shared/components/fileCard";
import { DetailGroupSeparator } from "@kraaft/shared/components/groupButton/detailGroupSeparator";

import {
  VirtualizedDirectoryListItem,
  VirtualizedDirectoryListProps,
} from "./types";

const VirtualizedDirectoryList = ({
  roomId,
  directoryId,
  directories,
  files = [],
  disableIncompatible,
  onPressDirectory,
  onSelect,
  newDirectoryElement,
  ...props
}: VirtualizedDirectoryListProps) => {
  const items = useMemo<VirtualizedDirectoryListItem[]>(
    () =>
      flatten([
        directories.map<VirtualizedDirectoryListItem>((data) => ({
          displayType: "directory",
          data,
        })),
        files.map<VirtualizedDirectoryListItem>((data) => ({
          displayType: "file",
          data,
        })),
      ]),
    [directories, files],
  );

  const indexForNewDirectoryElement =
    directories.length > 0 ? directories.length - 1 : 0;
  const { isFileCardDisabled, isSelecting } = useDirectoryListContext({
    roomId,
    medias: files,
  });

  const extractKey = useCallback((item: VirtualizedDirectoryListItem) => {
    return item.data.id;
  }, []);

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<VirtualizedDirectoryListItem>) => {
      const shouldDisplayNewDirectoryElement =
        index === indexForNewDirectoryElement;

      return (
        <>
          {item.displayType === "directory" && (
            <DroppableDirectoryListCard
              key={item.data.id}
              directory={item.data}
              disabled={Boolean(disableIncompatible)}
              onPress={onPressDirectory}
              roomId={roomId}
            />
          )}
          {item.displayType === "file" && (
            <FileCard
              key={item.data.id}
              forceSelection={isSelecting}
              roomId={roomId}
              directoryId={directoryId}
              hideDirectories
              selectionSource="directoryList"
              media={item.data}
              disabled={isFileCardDisabled}
              onSelect={onSelect}
              trackingSource="directory"
            />
          )}
          {shouldDisplayNewDirectoryElement ? newDirectoryElement : null}
        </>
      );
    },
    [
      directoryId,
      disableIncompatible,
      indexForNewDirectoryElement,
      isFileCardDisabled,
      isSelecting,
      newDirectoryElement,
      onPressDirectory,
      onSelect,
      roomId,
    ],
  );

  return (
    <>
      <FlatListWithEmptyState
        {...props}
        type="folders"
        data={items}
        renderItem={renderItem}
        keyExtractor={extractKey}
        keyboardShouldPersistTaps="handled"
        ItemSeparatorComponent={DetailGroupSeparator}
      />
      {items.length === 0 ? newDirectoryElement : null}
    </>
  );
};

export { VirtualizedDirectoryList };
