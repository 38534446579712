import React from "react";
import { StyleSheet, View } from "react-native";

import { SearchResult } from "@kraaft/shared/components/conversation/searchConversationContext";
import { MessageAudioMatch } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageAudioMatch";
import { MessageDocumentMatch } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageDocumentMatch";
import { MessageGeolocationMatch } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageGeolocationMatch";
import { MessageImageMatch } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageImageMatch";
import { MessageMatchProps } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageMatchProps";
import { MessageSearchResultHoverable } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageSearchResultWrapper";
import { MessageTextMatch } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageTextMatch";
import { MessageVideoMatch } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageVideoMatch";
import { MessageSender } from "@kraaft/shared/components/message/messageSender/messageSender";
import { formatDate } from "@kraaft/shared/components/message/messageUtils";
import {
  MessageOfType,
  UserMessage,
} from "@kraaft/shared/core/modules/message/messageState";
import {
  FactoryElementWithType,
  getFactoryElement,
} from "@kraaft/shared/core/utils/factoryUtils";
import { ColorStyle, Radius, Spacing, Text } from "@kraaft/ui";

const messageMatchRendererFactory: {
  [T in UserMessage["type"]]: React.ComponentType<
    MessageMatchProps<MessageOfType<T>>
  >;
} = {
  text: MessageTextMatch,
  image: MessageImageMatch,
  video: MessageVideoMatch,
  document: MessageDocumentMatch,
  audio: MessageAudioMatch,
  geolocation: MessageGeolocationMatch,
};

export function getMessageMatchRenderer<T extends UserMessage>(
  message: T,
  searchResult: SearchResult,
) {
  const MessageMatchRenderer = getFactoryElement(
    messageMatchRendererFactory,
    message.type,
  ) as FactoryElementWithType<typeof messageMatchRendererFactory, T["type"]>;

  return <MessageMatchRenderer message={message} searchResult={searchResult} />;
}

type MessageSearchResultProps = {
  poolId: string;
  message: UserMessage;
  searchResult: SearchResult;
};

export const MessageSearchResult = ({
  poolId,
  message,
  searchResult,
}: MessageSearchResultProps) => {
  return (
    <MessageSearchResultHoverable>
      <View style={styles.container}>
        <View style={styles.header}>
          <MessageSender message={message} poolId={poolId} />
          <Text
            color="FONT_LOW_EMPHASIS"
            size="SMALL"
            style={styles.date}
            numberOfLines={1}
          >
            {formatDate(message.createdAt)}
          </Text>
        </View>
        {getMessageMatchRenderer(message, searchResult)}
      </View>
    </MessageSearchResultHoverable>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Spacing.S8,
    paddingVertical: Spacing.S8,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: Radius.SMALL,
  },
  header: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  date: {
    flexShrink: 0,
  },
});
