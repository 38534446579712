import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderLeft: "1px solid",
    borderLeftColor: ColorStyle.SEPARATOR,
    backgroundColor: Color.WHITE,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    width: 600,
  },
  content: {
    padding: Spacing.S16,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflowY: "hidden",
  },
  scrollableContent: {
    overflowY: "auto",
    flexGrow: 1,
  },
  editIcon: {
    display: "flex",
    alignItems: "center",
    color: ColorStyle.FONT_LOW_EMPHASIS,
    paddingRight: "12px",
  },
  triggers: {
    marginTop: Spacing.S24,
  },
  trigger: {
    display: "flex",
    alignItems: "center",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    paddingLeft: "8px",
  },
  typeDropdown: {
    width: "200px",
    margin: "0px 16px",
  },
  section: {
    marginBottom: Spacing.S32,
  },
  sectionTitle: {
    display: "block",
    marginBottom: Spacing.S8,
    color: ColorStyle.ACTION_CTA,
  },
  conditionsList: {
    marginBottom: Spacing.S32,
  },
  save: {
    paddingTop: Spacing.S16,
    width: "100%",
  },
  drodownContainer: {
    marginBottom: Spacing.S16,
  },
});
