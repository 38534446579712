import { useCallback, useRef } from "react";

import { permissions } from "@kraaft/shared/core/services/permission/permissions.provider";
import {
  PermissionName,
  PermissionResult,
} from "@kraaft/shared/core/services/permission/permissions.types";
import { useAppFocused } from "@kraaft/shared/core/utils/useAppFocused";
import { useRender } from "@kraaft/shared/core/utils/useRender";

export const usePermissionStatusOf = (
  type: PermissionName,
): PermissionResult => {
  // better to use this instead of having useState<Record>()
  const render = useRender();
  const permissionResultRecord = useRef<Record<string, PermissionResult>>({});

  const getStatus = useCallback(async () => {
    const status = await permissions.statusOf(type);
    const didStatusChanged = status !== permissionResultRecord.current[type];

    permissionResultRecord.current[type] = status;

    if (didStatusChanged) {
      render();
    }
  }, [render, type]);

  useAppFocused(getStatus);

  return permissionResultRecord.current[type] ?? "unavailable";
};
