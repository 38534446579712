import { CheckboxGuardStrategy } from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuard";

export function getAppropriateCheckboxGuardStrategy(
  isCheckboxLockingSection: boolean,
  value: boolean,
  isCurrentUserAuthorizedToUnlock: boolean,
  oldestLockedSectionKey: string | undefined,
  sectionKey: string,
): CheckboxGuardStrategy {
  if (isCheckboxLockingSection) {
    if (!value) {
      if (oldestLockedSectionKey && sectionKey !== oldestLockedSectionKey) {
        return "cantLockParentLocked";
      }

      return "lockConfirmation";
    }

    if (!isCurrentUserAuthorizedToUnlock) {
      return "cantUnlockNotAllowed";
    }

    if (oldestLockedSectionKey && sectionKey !== oldestLockedSectionKey) {
      return "cantUnlockParentLocked";
    }

    return "unlockConfirmation";
  }

  if (!value) {
    return "checkConfirmation";
  }

  return "uncheckConfirmation";
}
