import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DirectoryList } from "@kraaft/shared/components/actionCardList/directoryList";
import { ModularSchemaListForSelection } from "@kraaft/shared/components/actionCardList/modularSchemaList/modularSchemaListForSelection";
import {
  BannerType,
  RoomBanner,
} from "@kraaft/shared/components/conversation/header/roomBanner";
import { AddDirectoryFlow } from "@kraaft/shared/components/directory/addDirectoryFlow";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KDialogConfig } from "@kraaft/shared/components/kDialog/kDialogProps";
import { ROOT_DIRECTORY_ID } from "@kraaft/shared/core/modules/directory/directory";
import { selectRootDirectories } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { selectMessageSelectionAsMessageList } from "@kraaft/shared/core/modules/message/messageSelectors";
import {
  doAllMessageAttachmentTypesMatchDirectory,
  getAllMessagesAttachmentTypes,
} from "@kraaft/shared/core/modules/messageAttachment/messageAttachmentUtils";
import { nullId } from "@kraaft/shared/core/utils";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { useLoader } from "@kraaft/shared/core/utils/useLoader";
import { Button, PortalHost } from "@kraaft/ui";

import { CurrentStepProps } from "../../../multiStepDialogTypes";

import { useStyles } from "./chooseRootStep.styles";

const PORTAL_HOSTNAME = "chooseRootDialog";

export interface ChooseRootStepParams {
  roomId: string;
  setSelectedSchemaId: (schemaId: string) => void;
  setSelectedDirectoryId: (directoryId: string) => void;
}

type ChooseRootStepProps = CurrentStepProps<ChooseRootStepParams>;

const ChooseRootStep = (props: ChooseRootStepProps) => {
  const {
    roomId,
    setStep,
    onClose,
    setSelectedSchemaId,
    setSelectedDirectoryId,
  } = props;
  const [showBanner, setShowBanner] = useState(false);
  const { t } = useTranslation();

  const classes = useStyles();

  const rootDirectories = useSelector(selectRootDirectories(roomId));

  const [isAddDirectoryFlowOpen, openAddDirectoryFlow, closeAddDirectoryFlow] =
    useBooleanState();

  const { loaderId, loading } = useLoader({
    prefix: "addRootDirectoryFromAttachFlow",
  });

  const selectedMessages = useSelector(
    selectMessageSelectionAsMessageList(roomId, undefined),
  );
  const selectedMessagesAttachmentTypes =
    getAllMessagesAttachmentTypes(selectedMessages);

  const areDirectoryCardsDisabled = !doAllMessageAttachmentTypesMatchDirectory(
    selectedMessagesAttachmentTypes,
  );

  const handleSchemaPress = useCallback(
    (schemaId: string) => {
      setSelectedSchemaId(schemaId);
      setStep({ index: 1 });
    },
    [setSelectedSchemaId, setStep],
  );

  const handleDirectoryPress = useCallback(
    (directoryId: string) => {
      setSelectedDirectoryId(directoryId);
      setStep({ index: 2 });
    },
    [setSelectedDirectoryId, setStep],
  );

  const content = (
    <div className={classes.container}>
      <div className={classes.listContainer}>
        {showBanner && (
          <div className={classes.bannerContainer}>
            <RoomBanner
              bannerType={BannerType.INFO}
              bannerText={t("schemasIncompatibleWeb")}
              floating
            />
          </div>
        )}
        <div className={classes.listSectionContainer}>
          <div className={classes.title}>{t("schemas")}</div>
          <ModularSchemaListForSelection
            roomId={roomId}
            setOnDisabled={() => setShowBanner(true)}
            onPress={handleSchemaPress}
          />
        </div>
        <div className={classes.listSectionContainer}>
          <div className={classes.title}>{t("directories")}</div>
          <DirectoryList
            roomId={roomId}
            directoryId={ROOT_DIRECTORY_ID}
            disableIncompatible={areDirectoryCardsDisabled}
            directories={rootDirectories}
            onPressDirectory={handleDirectoryPress}
            newDirectoryElement={
              <AddDirectoryFlow
                open={isAddDirectoryFlowOpen}
                onClose={closeAddDirectoryFlow}
                roomId={roomId}
                parentId={nullId}
                loaderId={loaderId}
                loading={loading}
              />
            }
          />
          {!isAddDirectoryFlowOpen && !areDirectoryCardsDisabled && (
            <div className={classes.addDirectoryButton}>
              <Button
                accessibilityLabel={t("directoryActions.new")}
                text={t("directoryActions.new")}
                icon="plus"
                variant="QUATERNARY"
                onPress={openAddDirectoryFlow}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const leftActions = useMemo<KDialogConfig["leftActions"]>(
    () => [
      {
        accessibilityLabel: t("cancel"),
        text: t("cancel"),
        variant: "SECONDARY",
        onPress: onClose,
      },
    ],
    [onClose, t],
  );

  return (
    <>
      <KDialogContent
        title={t("attachTo")}
        content={content}
        leftActions={leftActions}
        fullHeight
      />
      <PortalHost hostname={PORTAL_HOSTNAME} />
    </>
  );
};

export { ChooseRootStep };
