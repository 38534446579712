import { call, put, takeEvery } from "typed-redux-saga/macro";

import {
  UserActions,
  UserStateActions,
} from "@kraaft/shared/core/modules/user/userActions";
import { Api } from "@kraaft/shared/core/services/api/api";

export function* acknowledgePoolOnboardingSaga() {
  yield* takeEvery(UserActions.acknowledgePoolOnboarding, function* () {
    yield* put(
      UserStateActions.updateCurrentUserOnboardingState({
        shouldShowPoolOnboarding: false,
      }),
    );
    try {
      yield* call(Api.acknowledgePoolOnboarding);
    } catch (e) {
      console.error("error calling acknowledgePoolOnboarding", e);
    }
  });
}
