import { useMemo } from "react";
import { useSelector } from "react-redux";
import { pickBy } from "lodash";

import { selectModularFoldersWithRoomIdAndSchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";

export const useFilteredModularFolders = (
  roomId: string,
  schemaId: string,
  searchText: string | undefined,
) => {
  const { folders: unfilteredFolders } = useSelector(
    selectModularFoldersWithRoomIdAndSchemaId({
      roomId,
      schemaId,
    }),
  );

  const filteredFolders = useMemo(
    () =>
      searchText
        ? pickBy(unfilteredFolders, (folder) =>
            normalizeTextForSearch(
              ModularRecordUtils.getRecordField(
                folder,
                KSchemaRemarkableColumns.TITLE,
                [KColumnType.shortText, KColumnType.automatedAutoIncrement],
                "",
              ),
            ).includes(normalizeTextForSearch(searchText)),
          )
        : unfilteredFolders,
    [searchText, unfilteredFolders],
  );

  return filteredFolders;
};
