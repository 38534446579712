import { useMemo } from "react";
import { View } from "react-native";
import { StrictOmit } from "ts-essentials";

import { Button, ButtonProps } from "@kraaft/ui";

import { styles } from "./actionFooter.styles";

export type PrimaryFooterAction = ButtonProps & {
  variant?: "PRIMARY";
};

type DistributeOmitVariant<T extends ButtonProps> = T extends any
  ? StrictOmit<T, "variant">
  : never;

export type SecondaryFooterAction = DistributeOmitVariant<ButtonProps>;

/** Footer actions are displayed in reverse order of importance (most important action at the end) */
export type FooterActions =
  | [PrimaryFooterAction, ...Array<SecondaryFooterAction>]
  | [];

interface Props {
  actions?: FooterActions;
  direction?: "vertical" | "horizontal" | "vertical-reversed";
  noPadding?: boolean;
  backgroundColor?: string;
  children?:
    | React.ReactElement<ButtonProps>[]
    | React.ReactElement<ButtonProps>;
}

const ActionFooter = ({
  actions,
  direction = "vertical",
  noPadding,
  backgroundColor,
  children,
}: Props) => {
  const containerStyle = useMemo(
    () => [
      styles.buttonsContainer,
      direction === "vertical" && styles.buttonsContainerVertical,
      direction === "horizontal" && styles.buttonsContainerHorizontal,
      direction === "vertical-reversed" &&
        styles.buttonsContainerVerticalReversed,
      noPadding && styles.buttonsContainerNoPadding,
      { backgroundColor },
    ],
    [backgroundColor, direction, noPadding],
  );

  return (
    <View style={containerStyle}>
      {actions?.map((action, idx) => {
        const isFirstAction = idx === 0;
        const key = `${action.text}-${idx}`;

        return isFirstAction ? (
          <Button key={key} id={`ide2e-dialog-button-${idx}`} {...action} />
        ) : (
          <Button
            key={key}
            id={`ide2e-dialog-button-${idx}`}
            {...action}
            variant="SECONDARY"
          />
        );
      })}
      {children}
    </View>
  );
};

export { ActionFooter };
