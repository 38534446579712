import { useRef } from "react";

import { BrowserPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { fileHelper } from "@kraaft/shared/core/modules/file/fileHelper";
import { checkInputFileSize } from "@kraaft/web/src/core/utils/useCheckFile";

interface FileInputOptions {
  onAccepted: (files: ModernFile<BrowserPath>[]) => void;
  accept?: string | string[];
  maxSize?: number;
  multiple?: boolean;
}

interface FileError {
  message: string;
  code: string;
}

interface FileRejection {
  file: File;
  errors: FileError[];
}

export function useFileInput({
  onAccepted,
  accept,
  maxSize,
  multiple = true,
}: FileInputOptions) {
  const input = useRef<HTMLInputElement>(null);

  let fileRejections: FileRejection[] = [];

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target?.files && Array.from(e.target?.files);

    const result = checkInputFileSize({ files, accept, maxSize });

    if (result.status) {
      onAccepted(
        result.acceptedFiles.map((file) => fileHelper.fromWebFile(file)),
      );
    }

    ({ fileRejections } = result);

    // reset input
    if (input.current) {
      input.current.value = "";
    }
  }

  function getInputProps() {
    return {
      accept: Array.isArray(accept) ? accept.join(",") : accept,
      ref: input,
      type: "file",
      onChange,
      hidden: true,
      multiple,
    };
  }

  function open() {
    input.current?.click();
  }

  return { fileRejections, getInputProps, open };
}
