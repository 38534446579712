import { useEffect } from "react";
import { useDispatch } from "react-redux";
import identity from "lodash/identity";

import { AppStateActions } from "@kraaft/shared/core/modules/app/appActions";
import { UtmParams } from "@kraaft/shared/core/modules/app/appState";

export function useStoreUtmParams() {
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmParams: UtmParams = {
      utm_source: searchParams.get("utm_source") ?? undefined,
      utm_campaign: searchParams.get("utm_campaign") ?? undefined,
      utm_medium: searchParams.get("utm_medium") ?? undefined,
      utm_term: searchParams.get("utm_term") ?? undefined,
      utm_content: searchParams.get("utm_content") ?? undefined,
    };
    if (Object.values(utmParams).filter(identity).length > 0) {
      dispatch(AppStateActions.setUtmParams(utmParams));
    }
  }, [dispatch]);
}
