import { useDispatch } from "react-redux";
import { DeepPartial, Dictionary } from "ts-essentials";

import { OpenCheckboxGuard } from "@kraaft/shared/components/modularFolderList/useCheckboxGuard";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { getReorderDetails, ReorderPlacement } from "@kraaft/ui";

interface UseModularFolderReorder {
  roomId: string;
  currentUserId?: string;
  folders: Dictionary<ModularFolder>;
  highlightedCheckboxColumnKey?: string;
  openCheckboxGuard: OpenCheckboxGuard;
  alterRecord: (key: string, value: DeepPartial<ModularFolder>) => void;
  revertRecordChanges: (key: string) => void;
}

export const useModularFolderReorder = ({
  roomId,
  currentUserId,
  folders,
  highlightedCheckboxColumnKey,
  openCheckboxGuard,
  alterRecord,
  revertRecordChanges,
}: UseModularFolderReorder) => {
  const dispatch = useDispatch();

  return useCallbackRealtime(
    (
      [_folders],
      modularFolderId: string,
      placement: ReorderPlacement,
      targetKey: string,
      newCheckboxValue?: boolean,
    ) => {
      if (!folders) {
        return;
      }

      const { afterId, reorderedIds } = getReorderDetails(
        _folders,
        modularFolderId,
        placement,
        targetKey,
      );

      if (
        newCheckboxValue !== undefined &&
        highlightedCheckboxColumnKey !== undefined
      ) {
        function resetChanges() {
          for (const reorderedModularFolderId of Object.keys(reorderedIds)) {
            revertRecordChanges(reorderedModularFolderId);
          }
        }

        for (const [reorderedModularFolderId, newIndex] of Object.entries(
          reorderedIds,
        )) {
          if (reorderedModularFolderId === modularFolderId) {
            alterRecord(reorderedModularFolderId, {
              index: newIndex,
              properties: {
                [highlightedCheckboxColumnKey]: {
                  columnType: KColumnType.checkbox,
                  value: newCheckboxValue,
                  updatedAt: new Date(),
                  updatedBy: currentUserId,
                },
              },
            });
          } else {
            alterRecord(reorderedModularFolderId, {
              index: newIndex,
            });
          }
        }
        openCheckboxGuard(modularFolderId, {
          onConfirm: () => {
            resetChanges();
            dispatch(
              OfflineModularFolderActions.edit({
                editions: [
                  {
                    id: modularFolderId,
                    properties: [
                      [
                        highlightedCheckboxColumnKey,
                        {
                          columnType: KColumnType.checkbox,
                          value: newCheckboxValue,
                          updatedAt: new Date(),
                          updatedBy: currentUserId,
                        },
                      ],
                    ],
                  },
                ],
              }),
            );

            dispatch(
              OfflineModularFolderActions.reorder({
                roomId,
                modularFolderId,
                afterModularFolderId: afterId,
                reorderedIds,
              }),
            );
          },
          onCancel: () => {
            resetChanges();
          },
        });
      } else {
        dispatch(
          OfflineModularFolderActions.reorder({
            roomId,
            modularFolderId,
            afterModularFolderId: afterId,
            reorderedIds,
          }),
        );
      }
    },
    [
      folders,
      highlightedCheckboxColumnKey,
      revertRecordChanges,
      openCheckboxGuard,
      alterRecord,
      currentUserId,
      dispatch,
      roomId,
    ],
    [folders],
  );
};
