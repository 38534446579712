import { filterInPlace } from "@kraaft/helper-functions";

/**
 * mergeInPlace replaces the value of a by the value of b
 * except it keeps the reference of the value of a if the value of b is equal
 * it is useful in reducers to avoid unnecessary renders due to reference change of equal values
 *
 * @example
 * merge({ nested: { value: 1 } }, { nested: { value: 1 } }) // will return a without modifying any value or reference
 * merge({ nested: { value: 1 } }, { nested: { value: 2 } }) // will return a with nested.value being 2, but no reference change
 */
// eslint-disable-next-line complexity
export function mergeInPlace(a: any, b: any) {
  if (a === b) {
    return a;
  }
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime() ? a : b;
  }
  if ((b === undefined || b === null) && b !== a) {
    return b;
  }
  if (typeof a === "object") {
    const allKeys = new Set([...Object.keys(a), ...Object.keys(b)]);
    for (const key of allKeys) {
      if (!(key in b)) {
        delete a[key];
        continue;
      }
      if (!(key in a)) {
        a[key] = b[key];
        continue;
      }
      a[key] = mergeInPlace(a[key], b[key]);
    }
    // Fixing sparse array
    if (Array.isArray(a)) {
      filterInPlace(a, Boolean);
    }
    return a;
  }
  return b;
}
