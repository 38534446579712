import {
  FileAllocator,
  fileAllocator,
} from "@kraaft/shared/core/modules/file/fileAllocator";
import { getFileType } from "@kraaft/shared/core/utils/utils";

import { FilePath, LocalPath, ModernFile, ModernFileHelper } from "../file";
import { FileHelper } from "./fileHelper";

export class WebFileHelper extends FileHelper {
  async addFileToFormData(file: ModernFile<LocalPath>, formData: FormData) {
    const data = await FileAllocator.resolveLocalPathToBlob(file.path);
    formData.append(file.filename, new File([data], file.filename));
  }

  fromWebFile(file: File): ModernFile<LocalPath> {
    const path = fileAllocator.fromWebFile(file);
    return ModernFileHelper.any(getFileType(file.name), file.name, path, {
      size: file.size,
    });
  }

  convertToBase64(filepath: FilePath) {
    return new Promise<string | ArrayBuffer | null>(async (resolve, reject) => {
      const reader = new FileReader();
      const localPath = await fileAllocator.temporaryIfNeeded(filepath);
      reader.readAsDataURL(
        await FileAllocator.resolveLocalPathToBlob(localPath),
      );
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
