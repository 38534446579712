import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  emptyContent: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export { styles };
