import { DifferenceBackgroundProps } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceBackground";
import { GalleryAttachmentDifference } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/renderers/atttachmentDifference/galleryAttachmentDifference";
import { ListAttachmentDifference } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/renderers/atttachmentDifference/listAttachmentDifference";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordValueOfType } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";

interface AttachmentDifferenceProps extends DifferenceBackgroundProps {
  value: ModularRecordValueOfType<KColumnType.attachment>;
  schemaColumn: KSchemaColumn<KColumnType.attachment> | undefined;
}

export const AttachmentDifference = ({
  value,
  schemaColumn,
  method,
}: AttachmentDifferenceProps) => {
  if (value === undefined) {
    return null;
  }
  if (schemaColumn?.mode === "gallery") {
    return <GalleryAttachmentDifference method={method} attachments={value} />;
  }
  return <ListAttachmentDifference method={method} attachments={value} />;
};
