import { useCallback, useMemo } from "react";

import {
  useNavigationService,
  useRouteService,
} from "@kraaft/shared/core/services/navigation";
import { ConversationDetailsPanel } from "@kraaft/shared/core/services/navigation/navigationParams";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";
import { queries } from "@kraaft/web/src/views/app/appRouter/routes";

interface Props {
  roomId: string;
}

const useConversationDetailsPortalQuery = (
  props: Props,
): [
  ConversationDetailsPanel | undefined,
  (panelIdentifier: ConversationDetailsPanel | undefined) => void,
] => {
  const { roomId } = props;

  const navigationService = useNavigationService();
  const routeService = useRouteService();
  const query = useQueryString();

  const openedPanel = useMemo(() => {
    const details = query.get(queries.detailScreen) as ConversationDetailsPanel;

    if (details && Object.values(ConversationDetailsPanel).includes(details)) {
      return details;
    }
    return undefined;
  }, [query]);

  const setOpenedPanel = useCallback(
    (panelIdentifier: ConversationDetailsPanel | undefined) => {
      navigationService.navigate(
        routeService.isViewingConversationFolder()
          ? "ConversationFolder"
          : "ConversationInfo",
        {
          roomId,
          openedPanel: panelIdentifier,
        },
      );
    },
    [navigationService, roomId, routeService],
  );

  return [openedPanel, setOpenedPanel];
};

export { useConversationDetailsPortalQuery };
