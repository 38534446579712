import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItemInfo, View } from "react-native";

import { SimpleRoomCard } from "@kraaft/shared/components/mapController/markers/modularFolderMarker/simpleRoomCardForMaps";
import { MarkerGeoJSON } from "@kraaft/shared/components/mapController/types";
import { SlidePanel } from "@kraaft/shared/components/slidePanel";
import {
  AnyRoomCard,
  RoomCardWithGeolocation,
} from "@kraaft/shared/core/modules/roomCard/roomCard.state";

import { styles } from "./roomCardListForMap.styles";

interface Props {
  open: boolean;
  onClose: () => void;
  onRowPress: (roomCard: RoomCardWithGeolocation) => void;
  markers: MarkerGeoJSON<RoomCardWithGeolocation>[];
}

const listSeparator = () => <View style={styles.separator} />;

const RoomCardListForMap = (props: Props) => {
  const { open, onClose, markers, onRowPress } = props;
  const { t } = useTranslation();

  const roomCards = useMemo(
    () => markers.map((marker) => marker.properties.marker.element.ref),
    [markers],
  );

  const extractKey = useCallback((item: AnyRoomCard) => {
    return item.roomId;
  }, []);

  const renderModularFolderItem = useCallback(
    ({ item }: ListRenderItemInfo<RoomCardWithGeolocation>) => {
      function handlePress() {
        onRowPress(item);
        onClose();
      }

      return <SimpleRoomCard roomCard={item} onPress={handlePress} />;
    },

    [onClose, onRowPress],
  );

  return (
    <SlidePanel
      name="MapRoomCards"
      open={open}
      onClose={onClose}
      closeIcon="chevron-down"
      title={t("roomsWithCount", { count: roomCards.length })}
      direction="up"
    >
      <View style={styles.container}>
        <FlatList
          keyExtractor={extractKey}
          data={roomCards}
          renderItem={renderModularFolderItem}
          ItemSeparatorComponent={listSeparator}
          contentContainerStyle={styles.contentContainer}
        />
      </View>
    </SlidePanel>
  );
};

export { RoomCardListForMap };
