import { call, put, select } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { ModularRecordProperties } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* updateRoomSaga(
  action: ReturnType<typeof roomActions.updateRoomRecord>,
) {
  const room = yield* select(selectRoom(action.payload.roomId));
  const oldProperties = room?.record.properties;

  try {
    const name = action.payload.update.title?.value;

    if (name === "") {
      yield* put(
        showError({
          title: i18n.t("ticketTitleChangeError"),
          message: i18n.t("ticketTitleChangeEmptyError"),
        }),
      );
      return;
    }

    yield* call(Api.editRoomRecord, {
      roomId: action.payload.roomId,
      properties: KSchemaConversion.toRawProperties(
        action.payload.update as ModularRecordProperties,
      ),
    });
  } catch (error) {
    if (error) {
      yield* put(showError({ title: i18n.t("errorServer") }));
    }
    if (oldProperties) {
      yield* put(
        roomActions.updateRoomRecordFailure(error, {
          roomId: action.payload.roomId,
          rollback: oldProperties,
        }),
      );
    }
  }
}
