import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import isEqual from "fast-deep-equal";

import { createMeshContext, useMeshContextSetup } from "@kraaft/helper-hooks";
import { Visibility } from "@kraaft/shared/core/modules/filter/filterState";

export interface RoomFiltersValues {
  visibility: Visibility;
  statusId: string | undefined;
  labelIds: string[];
  responsibleId: string | undefined;
  showArchived: boolean;
}

interface RoomFiltersContext {
  poolId: string;
  onFilter: (newFilters: RoomFiltersValues) => void;
  onReset: () => void;

  visibility: Visibility;
  setVisibility: (newVisibility: Visibility) => void;
  resetVisibility: () => void;
  isDefaultVisibility: boolean;

  statusId: string | undefined;
  setStatusId: (newStatusId: string | undefined) => void;
  resetStatusId: () => void;
  isDefaultStatusId: boolean;

  labelIds: string[];
  setLabelIds: (newLabelIds: string[]) => void;
  resetLabelIds: () => void;
  isDefaultLabelIds: boolean;

  responsibleId: string | undefined;
  setResponsibleId: (newResponsibleId: string | undefined) => void;
  resetResponsibleId: () => void;
  isDefaultResponsibleId: boolean;

  showArchived: boolean;
  setShowArchived: (newShowArchived: boolean) => void;
  resetShowArchived: () => void;
  isDefaultShowArchived: boolean;

  isDefaultAllValues: boolean;
}
export const RoomFiltersContext = createMeshContext<RoomFiltersContext>();

function useFilterValue<T>(
  initialValue: T,
  defaultValue: T,
  equalityFn?: (right: T, left: T) => boolean,
) {
  const [value, setValue] = useState<T>(initialValue);

  const resetValue = useCallback(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const isDefaultValue = equalityFn
    ? equalityFn(value, defaultValue)
    : value === defaultValue;

  return [value, setValue, resetValue, isDefaultValue] as const;
}

interface RoomFiltersContextProviderProps {
  poolId: string;
  initialRoomFilters: RoomFiltersValues;
  defaultRoomFilters: RoomFiltersValues;
  onFilter: (newFilters: RoomFiltersValues) => void;
}

export const RoomFiltersContextProvider = ({
  children,
  poolId,
  onFilter,
  initialRoomFilters,
  defaultRoomFilters,
}: PropsWithChildren<RoomFiltersContextProviderProps>) => {
  const [visibility, setVisibility, resetVisibility, isDefaultVisibility] =
    useFilterValue<Visibility>(
      initialRoomFilters.visibility,
      defaultRoomFilters.visibility,
    );
  const [statusId, setStatusId, resetStatusId, isDefaultStatusId] =
    useFilterValue<string | undefined>(
      initialRoomFilters.statusId,
      defaultRoomFilters.statusId,
    );
  const [labelIds, setLabelIds, resetLabelIds, isDefaultLabelIds] =
    useFilterValue<string[]>(
      initialRoomFilters.labelIds,
      defaultRoomFilters.labelIds,
      isEqual,
    );
  const [
    responsibleId,
    setResponsibleId,
    resetResponsibleId,
    isDefaultResponsibleId,
  ] = useFilterValue<string | undefined>(
    initialRoomFilters.responsibleId,
    defaultRoomFilters.responsibleId,
  );
  const [
    showArchived,
    setShowArchived,
    resetShowArchived,
    isDefaultShowArchived,
  ] = useFilterValue<boolean>(
    initialRoomFilters.showArchived,
    defaultRoomFilters.showArchived,
  );

  useEffect(() => {
    setVisibility(initialRoomFilters.visibility);
    setStatusId(initialRoomFilters.statusId);
    setLabelIds(initialRoomFilters.labelIds);
    setResponsibleId(initialRoomFilters.responsibleId);
    setShowArchived(initialRoomFilters.showArchived);
  }, [
    initialRoomFilters,
    setLabelIds,
    setResponsibleId,
    setShowArchived,
    setStatusId,
    setVisibility,
  ]);

  const handleReset = useCallback(() => {
    resetVisibility();
    resetStatusId();
    resetLabelIds();
    resetResponsibleId();
    resetShowArchived();
    onFilter(defaultRoomFilters);
  }, [
    defaultRoomFilters,
    onFilter,
    resetLabelIds,
    resetResponsibleId,
    resetShowArchived,
    resetStatusId,
    resetVisibility,
  ]);

  const isDefaultAllValues =
    isDefaultVisibility &&
    isDefaultStatusId &&
    isDefaultLabelIds &&
    isDefaultResponsibleId &&
    isDefaultShowArchived;

  const contextValue = useMeshContextSetup<RoomFiltersContext>({
    poolId,
    onFilter,
    onReset: handleReset,
    visibility,
    setVisibility,
    isDefaultVisibility,
    resetVisibility,
    statusId,
    setStatusId,
    isDefaultStatusId,
    resetStatusId,
    labelIds,
    setLabelIds,
    isDefaultLabelIds,
    resetLabelIds,
    responsibleId,
    setResponsibleId,
    isDefaultResponsibleId,
    resetResponsibleId,
    showArchived,
    setShowArchived,
    isDefaultShowArchived,
    resetShowArchived,
    isDefaultAllValues,
  });

  return (
    <RoomFiltersContext.Provider value={contextValue}>
      {children}
    </RoomFiltersContext.Provider>
  );
};
