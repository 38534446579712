import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
    "&:hover $iconContainer": {
      display: "flex",
    },
  },
  content: {
    flexShrink: 1,
    overflow: "hidden",
  },
  iconContainer: {
    right: Spacing.S4,
    display: "none",
    "&:active": {
      opacity: 0.5,
    },
  },
});
