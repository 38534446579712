import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { AlertDialogState } from "@kraaft/shared/components/alertDialog/alertDialog.types";
import {
  Button,
  ButtonType,
  ColorStyle,
  FontSize,
  Sheet,
  Text,
} from "@kraaft/ui";
import type { PopupSize } from "@kraaft/ui/src/layout/sheet/popupSheet/popupSheet.host";

interface AlertDialogSheetProps {
  state: AlertDialogState | undefined;
}

export const AlertDialogSheet = Sheet({
  web: "popup",
})
  .create<AlertDialogSheetProps>(
    ({ Header, Content, Footer, Paper }) =>
      ({ onClose, state }) => {
        const content = state?.message ? (
          <Text style={styles.simpleContent}>{state?.message}</Text>
        ) : null;

        const actions = useMemo(
          () =>
            [...(state?.buttons ?? [])]
              .reverse()
              .map((button, index, array) => {
                const { id, text, style, onPress } = button;
                const handlePress = () => {
                  onPress?.();
                  onClose();
                };

                const notReversedIndex = array.length - 1 - index;

                const autoFocus = style !== "cancel";
                let variant: ButtonType =
                  notReversedIndex === 0 ? "PRIMARY" : "SECONDARY";

                if (style !== "cancel") {
                  variant = "PRIMARY";
                }

                const fallbackId =
                  id ?? `ide2e-dialog-button-${notReversedIndex}`;

                return (
                  <Button
                    key={fallbackId}
                    id={fallbackId}
                    text={text}
                    onPress={handlePress}
                    variant={variant}
                    destructive={style === "destructive"}
                    autoFocus={autoFocus}
                  />
                );
              }),
          [onClose, state?.buttons],
        );

        if (!state) {
          return null;
        }

        return (
          <Paper>
            <Header onClose={onClose}>{state.title}</Header>
            <Content>{content}</Content>
            <Footer>{actions}</Footer>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "SMALL" as PopupSize });

const styles = StyleSheet.create({
  simpleContent: {
    fontSize: FontSize.MEDIUM,
    fontWeight: "normal",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
});
