import { call, takeEvery } from "typed-redux-saga/macro";

import { notifications } from "@kraaft/shared/core/modules/notifications/notifications.provider";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

export function* unregisterOnLogoutSaga() {
  yield* takeEvery(
    UserActions.userDisconnectedFromFirebase,
    handleUserDisconnected,
  );
}

function* handleUserDisconnected() {
  yield* call(() => notifications.logout());
}
