import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList/kSelectionList";
import { userJobs } from "@kraaft/shared/components/onboarding/askUserJob/userJob";
import { useUserJob } from "@kraaft/shared/components/onboarding/askUserJob/useUserJob";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackEventOnce } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button, Text } from "@kraaft/ui";

import { styles } from "./askUserJob.styles";

const OTHER_IDENTIFIER = "other";

const AskUserJob = () => {
  const { t } = useTranslation();
  const { setUserJob, loading } = useUserJob();

  useTrackEventOnce({
    eventName: "Onboarding Need To Select Job",
  });

  const [selected, setSelected] = useState<string>("");
  const [other, setOther] = useState("");

  const handleSelect = useCallback((newSelection: string[]) => {
    const [newSelected] = newSelection;
    if (!newSelected) {
      return;
    }
    setSelected(newSelected);
  }, []);

  const items = useMemo(
    () => [
      ...userJobs.map((job) => ({
        label: t(`userJob.jobs.${job}`),
        identifier: job,
      })),
      { label: t("userJob.jobs.other"), identifier: OTHER_IDENTIFIER },
    ],
    [t],
  );

  const next = useCallback(() => {
    const finalJob = selected === OTHER_IDENTIFIER ? other : selected;
    setUserJob(finalJob).catch(console.error);
    trackEvent({
      eventName: "Onboarding Selected Job",
      job: finalJob,
    });
  }, [other, selected, setUserJob]);

  return (
    <View style={styles.root}>
      <Text weight="bold" size="H1" style={styles.jobText}>
        {t("userJob.whatIsYourJob")}
      </Text>
      <ScrollView style={styles.scrollView}>
        <KSelectionList
          selected={[selected]}
          setSelected={handleSelect}
          items={items}
          noPadding
          disableScroll
        />
      </ScrollView>
      {selected === OTHER_IDENTIFIER && (
        <KInput
          label={t("userJob.fillYourJob")}
          value={other}
          onChangeText={setOther}
        />
      )}
      <View style={styles.spacer} />
      <Button
        loading={loading}
        disabled={!selected || (selected === OTHER_IDENTIFIER && !other)}
        style={styles.nextButton}
        accessibilityLabel={t("next")}
        text={t("next")}
        onPress={next}
      />
    </View>
  );
};

export { AskUserJob };
