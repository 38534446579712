import { useMemo } from "react";

import { useMeshContext } from "@kraaft/helper-hooks";
import {
  DifferenceBackground,
  DifferenceBackgroundProps,
} from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceBackground";
import { DifferenceText } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceText";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularRecordValueOfType } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { ModularDisplayRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { numberFormatter } from "@kraaft/shared/core/services/numberFormatter";

interface CurrencyDifferenceProps extends DifferenceBackgroundProps {
  value: ModularRecordValueOfType<KColumnType.currency>;
}

export const CurrencyDifference = ({
  value,
  method,
}: CurrencyDifferenceProps) => {
  const currencyCode = useMeshContext(
    ModularDisplayRequirementsContext,
    (context) => context.currencyCode,
  );

  const label = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    const formattedCurrency = numberFormatter.formatCurrency(
      value,
      currencyCode,
    );

    return formattedCurrency;
  }, [currencyCode, value]);

  return label !== undefined ? (
    <DifferenceBackground method={method}>
      <DifferenceText>{label}</DifferenceText>
    </DifferenceBackground>
  ) : null;
};
