import { takeEvery } from "typed-redux-saga/macro";

import { intercom } from "@kraaft/shared/core/modules/intercom/intercom.provider";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

export function* resetIntercomSaga() {
  yield* takeEvery(
    UserActions.userDisconnectedFromFirebase,
    onUserDisconnectedFromFirebase,
  );
}

function* onUserDisconnectedFromFirebase() {
  intercom.resetSession().catch(console.error);
}
