import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { selectUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import { User } from "@kraaft/shared/core/modules/user/userState";

type GetUserOrLoadThemFunction = (userIds: string[]) => Record<string, User>;

export const useGetOrLoadUsers = (): GetUserOrLoadThemFunction => {
  const allUsers = useSelector(selectUsers);
  const dispatch = useDispatch();

  const getOrLoadUsers = useCallback(
    (userIds: string[]) => {
      const foundUsers = {} as Record<string, User>;
      const missingUserIds = [];

      for (const userId of userIds) {
        const user = allUsers[userId];
        if (user !== undefined) {
          foundUsers[userId] = user;
        } else {
          missingUserIds.push(userId);
        }
      }

      for (const missingUserId of missingUserIds) {
        dispatch(UserActions.loadUser(missingUserId));
      }

      return foundUsers;
    },
    [allUsers, dispatch],
  );

  return getOrLoadUsers;
};
