import {
  DifferenceBackground,
  DifferenceBackgroundProps,
} from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceBackground";
import { DifferenceText } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceText";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularRecordValueOfType } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";

interface TextDifferenceProps extends DifferenceBackgroundProps {
  value:
    | ModularRecordValueOfType<KColumnType.shortText>
    | ModularRecordValueOfType<KColumnType.longText>;
}

export const TextDifference = ({ value, method }: TextDifferenceProps) => {
  return value !== undefined ? (
    <DifferenceBackground method={method}>
      <DifferenceText>{value}</DifferenceText>
    </DifferenceBackground>
  ) : null;
};
