import { ConditionLibraryForType } from "@kraaft/shared/core/generated/__generated/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";

export const checkboxCondition: ConditionLibraryForType<KColumnType.checkbox> =
  {
    checkbox: {
      equals: {
        translationKey: "is",
        check: (lhs, rhs) => (lhs.value ?? false) === (rhs.value ?? false),
      },
    },
    user: {
      isCheckedByAnyOf: {
        translationKey: "isCheckedByAnyOf",
        typeParams: { allowMultiple: true },
        check: (lhs, rhs) => {
          const checkedByUserId = lhs.updatedBy;
          const isChecked = lhs.value ?? false;
          const rhsValue = rhs.value;

          if (
            !isChecked ||
            checkedByUserId === undefined ||
            rhsValue === undefined
          ) {
            return false;
          }

          return rhsValue.includes(checkedByUserId);
        },
      },
      isCheckedByNoneOf: {
        translationKey: "isCheckedByNoneOf",
        typeParams: { allowMultiple: true },
        check: (lhs, rhs) => {
          const checkedByUserId = lhs.updatedBy;
          const isChecked = lhs.value ?? false;
          const rhsValue = rhs.value;

          if (
            !isChecked ||
            checkedByUserId === undefined ||
            rhsValue === undefined
          ) {
            return false;
          }

          return !rhsValue.includes(checkedByUserId);
        },
      },
    },
  };
