import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { openPreview } from "@kraaft/shared/core/modules/preview/previewActions";
import { updateRoomRecord } from "@kraaft/shared/core/modules/room/roomActions";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import {
  KSchemaColumn,
  KSchemaColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { selectSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KanbanLayout } from "@kraaft/web/src/components/kanbanLayout";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

interface ConversationTableProps {
  schemaId: string;
  rooms: Room[];
  schemaViewId?: string;
}

const ConversationKanbanView = ({
  schemaId,
  rooms,
  schemaViewId,
}: ConversationTableProps) => {
  const dispatch = useDispatch();
  const modularRecords = rooms.map((room) => room.record);

  const schema = useSelector(selectSchema(schemaId));

  const handleRecordClick = useCallback(
    (roomId: string) => {
      dispatch(
        openPreview({
          type: "room",
          roomId,
        }),
      );
    },
    [dispatch],
  );

  const handleRecordDrop = useCallback(
    (roomId: string, column: KSchemaColumn, optionKey: string | undefined) => {
      dispatch(
        updateRoomRecord({
          roomId,

          update: {
            [column.key]: {
              columnType: column.type,
              value: [optionKey],
            } as KSchemaColumnValue<typeof column.type>,
          },
        }),
      );
    },
    [dispatch],
  );

  if (!schema) {
    return null;
  }

  return (
    <KanbanLayout
      workspace={RecordWorkspace.ALL_ROOMS}
      schema={schema}
      modularRecords={modularRecords}
      onRecordClick={handleRecordClick}
      onRecordDrop={handleRecordDrop}
      schemaViewId={schemaViewId}
    />
  );
};

export { ConversationKanbanView };
