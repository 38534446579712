import React from "react";
import { View } from "react-native";

import { styles } from "@kraaft/shared/components/stepper/stepper.styles";
import { StepperStepProps } from "@kraaft/shared/components/stepper/stepperStep.props";

export const Step = React.memo(({ withSpacer, active }: StepperStepProps) => {
  return (
    <View style={[styles.step, withSpacer && styles.stepSpacer]}>
      {active && <View style={styles.stepActive} />}
    </View>
  );
});
