import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";

import { ModularFoldersMapViewForRoom } from "./modularFoldersMapViewForRoom";
import { ModularFoldersMapViewForSchema } from "./modularFoldersMapViewForSchema";

interface Props {
  schema: KFolderSchema;
  roomId?: string;
  schemaViewId?: string;
}

const ModularFoldersMapView = ({ schema, roomId, schemaViewId }: Props) => {
  if (roomId) {
    return (
      <ModularFoldersMapViewForRoom
        schema={schema}
        roomId={roomId}
        schemaViewId={schemaViewId}
      />
    );
  }
  return (
    <ModularFoldersMapViewForSchema
      schema={schema}
      schemaViewId={schemaViewId}
    />
  );
};

export { ModularFoldersMapView };
