import { useTranslation } from "react-i18next";

import { CheckboxGuardSheet } from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuardSheet";
import { Button, Text } from "@kraaft/ui";

interface CheckUncheckConfirmationProps {
  onClose: () => void;
  onConfirm: () => void;
  variant: "checkConfirmation" | "uncheckConfirmation";
  columnName: string;
}

export const CheckUncheckConfirmation = ({
  onClose,
  onConfirm,
  variant,
  columnName,
}: CheckUncheckConfirmationProps) => {
  const { t } = useTranslation();

  const title = t(variant === "checkConfirmation" ? "check" : "uncheck");
  const content = t(
    variant === "checkConfirmation"
      ? "confirmTaskValidation"
      : "confirmTaskUnvalidation",
    { columnName },
  );

  return (
    <CheckboxGuardSheet.Paper>
      <CheckboxGuardSheet.Header onClose={onClose}>
        {title}
      </CheckboxGuardSheet.Header>
      <CheckboxGuardSheet.Content>
        <Text size="BODY">{content}</Text>
      </CheckboxGuardSheet.Content>
      <CheckboxGuardSheet.Footer>
        <Button
          text={t("cancel")}
          variant="QUATERNARY"
          onPress={onClose}
          id="ide2e-popup-cancel"
        />
        <Button text={t("yes")} onPress={onConfirm} id="ide2e-popup-confirm" />
      </CheckboxGuardSheet.Footer>
    </CheckboxGuardSheet.Paper>
  );
};
