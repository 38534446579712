import { AnyAction } from "redux";
import { PersistConfig, PersistState } from "redux-persist";
import { StrictOmit } from "ts-essentials";

import { InputPartitionHelper } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { LocalPath } from "@kraaft/shared/core/modules/file/file";
import {
  AudioOfflineMessage,
  DocumentOfflineMessage,
  ForwardedOfflineMessage,
  GeolocationOfflineMessage,
  ImageOfflineMessage,
  OfflineMessageState,
  TextOfflineMessage,
  VideoOfflineMessage,
} from "@kraaft/shared/core/modules/message/offline/offlineMessageState";
import { storage } from "@kraaft/shared/core/modules/storage/storage.provider";
import {
  createAsyncMigrate,
  getAsyncStorageMigration,
} from "@kraaft/shared/core/store/storeMigrations";

interface V0TextOfflineMessage extends StrictOmit<TextOfflineMessage, "text"> {
  type: "text";
  text: string;
}

interface V0ImageOfflineMessage
  extends StrictOmit<ImageOfflineMessage, "caption"> {
  caption: string;
}

interface V0VideoOfflineMessage
  extends StrictOmit<VideoOfflineMessage, "caption"> {
  caption: string;
}

type V0OfflineMessage =
  | V0TextOfflineMessage
  | AudioOfflineMessage
  | GeolocationOfflineMessage
  | V0ImageOfflineMessage
  | V0VideoOfflineMessage
  | DocumentOfflineMessage
  | ForwardedOfflineMessage;

interface V0OfflineMessageState {
  queue: V0OfflineMessage[];
  backgroundSessionSentCount: number;
  lastActions: {
    date: Date;
    action: AnyAction;
  }[];
}

interface V1ImageOfflineMessage
  extends StrictOmit<ImageOfflineMessage, "file"> {
  file: {
    filename: string;
    size?: number;
    path: string;
  };
}

interface V1VideoOfflineMessage
  extends StrictOmit<VideoOfflineMessage, "file"> {
  file: {
    filename: string;
    size?: number;
    path: string;
  };
}

interface V1AudioOfflineMessage
  extends StrictOmit<AudioOfflineMessage, "file"> {
  file: {
    filename: string;
    size?: number;
    path: string;
  };
}

interface V1DocumentOfflineMessage
  extends StrictOmit<DocumentOfflineMessage, "file"> {
  file: {
    filename: string;
    size?: number;
    path: string;
  };
}

type V1OfflineMessage =
  | TextOfflineMessage
  | V1AudioOfflineMessage
  | GeolocationOfflineMessage
  | V1ImageOfflineMessage
  | V1VideoOfflineMessage
  | V1DocumentOfflineMessage
  | ForwardedOfflineMessage;

interface V1OfflineMessageState {
  queue: V1OfflineMessage[];
  backgroundSessionSentCount: number;
  lastActions: {
    date: Date;
    action: AnyAction;
  }[];
}

type PersistedStateOfType<T> = {
  _persist: PersistState;
} & T;

async function migrateFrom<From, To>(
  state: Record<string, unknown> | undefined,
  migrate: (state: From) => To,
) {
  if (!state) {
    return;
  }
  return (await migrate(state as From)) as Record<string, unknown>;
}

export const offlineMessagePersistConfig: PersistConfig<OfflineMessageState> = {
  key: "offlineMessage",
  storage: storage,
  version: 2,
  migrate: createAsyncMigrate({
    0: async (state) => {
      return await getAsyncStorageMigration("offlineMessage", 0)(state);
    },
    1: async (state) => {
      if (!state) {
        return;
      }
      const typedState =
        state as any as PersistedStateOfType<V0OfflineMessageState>;
      for (const message of typedState.queue) {
        if (message.type === "text") {
          (message as any as TextOfflineMessage).text = [
            InputPartitionHelper.textToInputPartition(message.text),
          ];
        }
        if (message.type === "image" || message.type === "video") {
          if (message.caption === undefined) {
            continue;
          }
          (message.caption as any as ImageOfflineMessage).caption = [
            InputPartitionHelper.textToInputPartition(message.caption),
          ];
        }
      }
      typedState.queue = typedState.queue.filter(
        (message) => message.type !== "forwarded",
      );
      return state;
    },
    2: async (state) => {
      return await migrateFrom<
        PersistedStateOfType<V1OfflineMessageState>,
        PersistedStateOfType<OfflineMessageState>
      >(state, (from) => {
        return {
          _persist: from._persist,
          backgroundSessionSentCount: from.backgroundSessionSentCount,
          lastActions: from.lastActions,
          queue: from.queue.map((item) => {
            if (
              item.type !== "audio" &&
              item.type !== "image" &&
              item.type !== "video" &&
              item.type !== "document"
            ) {
              return item;
            }
            return {
              ...item,
              file: {
                contentType: "document",
                path: item.file.path as LocalPath,
                filename: item.file.filename,
                size: undefined,
              },
            };
          }),
        };
      });
    },
  }),
};
