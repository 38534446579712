import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    zIndex: 1,
    display: "flex",
    height: "100%",
  },
});
