import { StyleSheet, View } from "react-native";

import { Image, PixelSize } from "@kraaft/ui";

import EmptyStateSignature from "./assets/empty_state_signature.png";

const EmptySignature = () => {
  return (
    <View style={styles.container}>
      <Image source={EmptyStateSignature} style={styles.image} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: PixelSize.S32,
    aspectRatio: 108 / 27 /** image width divided by image height */,
  },
});

export { EmptySignature };
