import { Action } from "redux";
import createSagaMiddleware, { stdChannel } from "redux-saga";

import { DatadogService } from "@kraaft/shared/core/services/datadog";
import { BatchActions } from "@kraaft/shared/core/store/batchingReducer";

const channel = stdChannel<Action<any>>();
const rawPut = channel.put;
channel.put = (action: Action<any>) => {
  if (BatchActions.match(action)) {
    action.payload.actions.forEach(rawPut);
    return;
  }
  rawPut(action);
};

export const sagaMiddleware = createSagaMiddleware({
  channel,
  onError(error, errorInfo) {
    console.error("Error in saga:", error, errorInfo);
    DatadogService.logError({
      error,
      context: {
        saga: errorInfo.sagaStack,
      },
    }).catch(console.error);
  },
});
