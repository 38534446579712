import { memoize } from "lodash";
import { createSelector } from "reselect";

import { DEFAULT_ROOM_FILTERS } from "@kraaft/shared/core/modules/filter/filterReducers";
import { getActiveFiltersCountAndText } from "@kraaft/shared/core/modules/filter/filterUtils";
import { RootState } from "@kraaft/shared/core/store";

export const selectFilterState = ({ filter }: RootState) => filter;

export const selectPoolFilterState =
  (poolId: string | undefined) =>
  ({ filter }: RootState) => {
    const roomFilters = poolId ? filter[poolId] : undefined;

    return roomFilters ?? DEFAULT_ROOM_FILTERS;
  };

export const selectRoomFilterLabelIds = memoize((poolId: string | undefined) =>
  createSelector(selectPoolFilterState(poolId), (filters) => filters.labelIds),
);

export const selectRoomFilterArchived = memoize((poolId: string | undefined) =>
  createSelector(
    selectPoolFilterState(poolId),
    (filters) => filters.showArchived,
  ),
);

export const selectActiveFiltersCountAndText = memoize(
  (poolId: string | undefined) =>
    createSelector(selectPoolFilterState(poolId), (filters) =>
      getActiveFiltersCountAndText(filters),
    ),
);
