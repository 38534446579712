import { call, put } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* disconnectRoomToExternalSaga(
  action: ReturnType<typeof roomActions.disconnectRoomToExternal>,
) {
  const {
    payload: { roomId, microsoftStorage: sharepoint },
  } = action;

  if (sharepoint) {
    try {
      yield* call(Api.stopSynchronizeRoomWithSharepoint, {
        roomId,
      });
    } catch (err) {
      yield* put(
        showError({
          title: i18n.t("sharepointErrorTitle"),
          message: i18n.t("unknown"),
        }),
      );
    }
  }
}
