import { StyleSheet } from "react-native";

import { Color, ColorStyle, FontSize, PixelSize, Radius } from "@kraaft/ui";

export const styles = StyleSheet.create({
  main: {
    borderStyle: "solid",
    width: 80,
    height: 80,
    borderRadius: Radius.LARGE,
    borderWidth: 1,
    borderColor: Color.GREY_FRENCH,
    marginHorizontal: 6,
  },
  icon: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    paddingTop: 21,
    paddingHorizontal: 28,
  },
  name: {
    fontSize: FontSize.SMALL,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    paddingHorizontal: 6,
    letterSpacing: -0.09,
    textAlign: "center",
  },
  dismissContainer: {
    width: PixelSize.S16,
    height: PixelSize.S16,
    borderRadius: 10,
    position: "absolute",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 56,
    marginRight: 8,
    marginTop: 8,
  },
  dismissIcon: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
});
