import { useCallback, useRef } from "react";

export function useScrollPromise() {
  const resolvePromise = useRef<((...args: any[]) => void) | undefined>(
    undefined,
  );

  const resolve = useCallback(() => {
    if (resolvePromise.current) {
      resolvePromise.current();
    }
    resolvePromise.current = undefined;
  }, []);

  const assign = useCallback(
    (fn: (...args: any[]) => void) => {
      resolve();
      resolvePromise.current = fn;
    },
    [resolve],
  );

  return { resolve, assign };
}
