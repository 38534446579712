import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { editSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { DirtyableSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewState";
import { stringContainsCharacters } from "@kraaft/shared/core/utils";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

import { useStyles } from "./saveSchemaViewDialog.styles";

interface SaveSchemaViewDialogProps {
  workspace: RecordWorkspace;
  schemaView: DirtyableSchemaView;
  dialogOpen: boolean;
  setDialogClosed: () => void;
  onSave: () => void;
}

const SaveSchemaViewDialog = (props: SaveSchemaViewDialogProps) => {
  const classes = useStyles();
  const { workspace, schemaView, dialogOpen, setDialogClosed, onSave } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const setName = useCallback(
    (newName: string) => {
      dispatch(
        editSchemaView({
          schemaId: schemaView.schemaId,
          schemaViewId: schemaView.id,
          workspace,
          schemaView: {
            name: newName,
          },
        }),
      );
    },
    [dispatch, schemaView.id, schemaView.schemaId, workspace],
  );

  const isSaveDisabled = !stringContainsCharacters(schemaView.name);

  const dialogButtons = useMemo<FooterActions>(
    () => [
      {
        accessibilityLabel: t("save"),
        text: t("save"),
        onPress: onSave,
        variant: "PRIMARY",
        disabled: isSaveDisabled,
      },
      {
        accessibilityLabel: t("cancel"),
        text: t("cancel"),
        onPress: setDialogClosed,
      },
    ],
    [onSave, isSaveDisabled, setDialogClosed, t],
  );

  return (
    <KDialog
      open={dialogOpen}
      onClose={setDialogClosed}
      title={t("saveSchemaView")}
      content={
        <div className={classes.content}>
          <div className={classes.text}>{t("newSchemaViewWillBeCreated")}</div>
          <div>
            <KInput
              label={t("schemaViewName")}
              value={schemaView.name}
              onChangeText={setName}
            />
          </div>
        </div>
      }
      actions={dialogButtons}
    />
  );
};

export { SaveSchemaViewDialog };
