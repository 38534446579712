import { useCallback } from "react";
import { TouchableOpacity, View } from "react-native";

import { Hoverable } from "@kraaft/shared/components/hoverable";
import { HoverableRenderProps } from "@kraaft/shared/components/hoverable/types";
import { ColorStyle, Icon, IconName, Text } from "@kraaft/ui";

import { styles } from "./menu.styles";

export interface MenuItemProps {
  id: string;
  containerId?: string;
  icon: IconName;
  label: string;
  isSelected?: boolean;
  onPress?: (id: string) => void;
  isExternallyHovered?: boolean;
  onHover?: (id: string, isHovered: boolean) => void;
}

const MenuItem = ({
  id,
  containerId,
  onPress,
  icon,
  label,
  isSelected,
  isExternallyHovered,
  onHover,
}: MenuItemProps) => {
  const handleMouseEnter =
    onHover &&
    (() => {
      onHover(id, true);
    });

  const handleMouseLeave =
    onHover &&
    (() => {
      onHover(id, false);
    });

  const renderHoverableContent = useCallback(
    ({ hoverProps, isHovered }: HoverableRenderProps) => {
      const hasHoveredStyle = isHovered || isExternallyHovered;
      const color =
        isSelected || hasHoveredStyle
          ? ColorStyle.ACTION_CTA
          : ColorStyle.FONT_HIGH_EMPHASIS;
      const textStyle =
        isSelected || hasHoveredStyle ? styles.menuItemTextSelected : undefined;
      const menuStyle = hasHoveredStyle
        ? styles.menuItemHovered
        : isSelected
          ? styles.menuItemSelected
          : undefined;

      const handlePress = () => {
        onPress?.(id);
      };

      return (
        <TouchableOpacity accessibilityLabel={label} onPress={handlePress}>
          <View
            {...hoverProps}
            style={[styles.menuItem, menuStyle]}
            nativeID={containerId}
          >
            <Icon name={icon} color={color} size="MEDIUM" />
            <Text
              style={[styles.menuItemText, textStyle, { color }]}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {label}
            </Text>
          </View>
        </TouchableOpacity>
      );
    },
    [isExternallyHovered, isSelected, containerId, icon, label, onPress, id],
  );

  return (
    <Hoverable onHoverIn={handleMouseEnter} onHoverOut={handleMouseLeave}>
      {renderHoverableContent}
    </Hoverable>
  );
};

export { MenuItem };
