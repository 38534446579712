import { makeStyles } from "@mui/styles";

import { PixelSize, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    borderRadius: Radius.SMALL,
    height: PixelSize.S24,
    width: PixelSize.S24,
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: Spacing.S8,
  },
});
