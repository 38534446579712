import { useSelector } from "react-redux";

import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { TrackingEvent } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";
import { InvitationTargetParams } from "@kraaft/shared/core/utils/useInviteLinkMessageConfigGenerator/useInviteLinkMessageConfigGenerator.types";

export function useInviteCollaboratorsTrackingEvent(
  method: TrackingEvent<"Invite Members">["method"],
  inviteParams: InvitationTargetParams,
): TrackingEvent<"Invite Members"> {
  const room = useSelector(
    selectRoom(
      inviteParams.type === "room" || inviteParams.type === "room-external"
        ? inviteParams.roomId
        : "",
    ),
  );

  switch (inviteParams.type) {
    case "pool": {
      return {
        eventName: "Invite Members",
        method,
        target: "pool_invitation",
        pool_id: inviteParams.poolId,
        source: inviteParams.source,
      };
    }
    case "room": {
      return {
        eventName: "Invite Members",
        method,
        target: "room_invitation",
        pool_id: room?.poolId ?? "unknown",
        source: inviteParams.source,
        room_id: inviteParams.roomId,
      };
    }
    case "room-external": {
      return {
        eventName: "Invite Members",
        method,
        target: "room_external_invitation",
        pool_id: room?.poolId ?? "unknown",
        room_id: inviteParams.roomId,
        source: inviteParams.source,
      };
    }
  }
}
