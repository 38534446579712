import { Color, ColorStyle, Radius } from "@kraaft/ui";

const styles: WebStyleSheet = {
  dragzone: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    boxSizing: "border-box",
    backgroundColor: Color.WHITE,
  },
  textContainer: {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "content-box",

    backgroundColor: Color.WHITE,
    borderStyle: "solid",
    borderRadius: Radius.SMALL,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderWidth: 5,
    borderColor: ColorStyle.ACTION_CTA,
  },
  textVerticalContainer: {
    flexDirection: "column",
  },
  text: {
    fontSize: 30,
    color: ColorStyle.FONT_LOW_EMPHASIS,
    letterSpacing: -0.21,
  },
};

export { styles };
