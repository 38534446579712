import { useMemo } from "react";

import { useRevertibleFolderUpdate } from "@kraaft/shared/components/modularFolderList/useRevertibleRecordUpdate";
import { getModularFolderHighlighedCheckboxValue } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";

export function useOrderableModularFolderList(
  baseModularFolders: Record<string, ModularFolder>,
  highlightedCheckboxColumnKey: string | undefined,
) {
  const [alteredFolders, alterFolder, revertFolderChanges] =
    useRevertibleFolderUpdate(baseModularFolders);

  const { unchecked, checked } = useMemo<{
    unchecked: Record<string, ModularFolder>;
    checked: Record<string, ModularFolder>;
  }>(() => {
    const _unchecked: Record<string, ModularFolder> = {};
    const _checked: Record<string, ModularFolder> = {};

    for (const [folderId, folder] of Object.entries(alteredFolders)) {
      const completed = Boolean(
        getModularFolderHighlighedCheckboxValue(
          highlightedCheckboxColumnKey,
          folder,
        )?.value,
      );
      if (completed) {
        _checked[folderId] = folder;
      } else {
        _unchecked[folderId] = folder;
      }
    }
    return { unchecked: _unchecked, checked: _checked };
  }, [alteredFolders, highlightedCheckboxColumnKey]);

  return {
    alteredFolders,
    alterFolder,
    revertFolderChanges,
    unchecked,
    checked,
  };
}
