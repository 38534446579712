import {
  CompactTextPillContent,
  TextPillContent,
} from "@kraaft/shared/components/modular/pill/utils";
import { PillRenderer } from "@kraaft/shared/components/modular/pill/utils/types";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { formatNumber } from "@kraaft/shared/core/utils/useNumberInput";

export const NumberCompactedPillRenderer: PillRenderer<KColumnType.number> = ({
  schemaColumn,
  recordProperty,
}) => {
  if (!recordProperty?.value) {
    return null;
  }

  const currencyAsString = formatNumber(
    recordProperty.value,
    schemaColumn.symbol,
  );
  return (
    <CompactTextPillContent iconName={KSchemaUtils.getIcon(schemaColumn)}>
      {currencyAsString}
    </CompactTextPillContent>
  );
};

export const NumberPillRenderer: PillRenderer<KColumnType.number> = ({
  schemaColumn,
  recordProperty,
}) => {
  if (!recordProperty?.value) {
    return null;
  }

  const numberAsString = formatNumber(
    recordProperty.value,
    schemaColumn.symbol,
  );

  return (
    <TextPillContent
      iconName={KSchemaUtils.getIcon(schemaColumn)}
      numberOfLines={1}
      tooltipTitle={schemaColumn.name}
    >
      {numberAsString}
    </TextPillContent>
  );
};
