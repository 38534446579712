import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  content: {
    width: "100%",
  },
  input: {
    width: "100%",
  },
  dropdown: {
    marginBottom: Spacing.S16,
  },
  addButton: {
    width: "100%",
    marginBottom: Spacing.S8,
  },
});
