import { Platform } from "react-native";
import { chunk, compact, flatten, mapValues, uniqBy } from "lodash";
import { Dictionary } from "ts-essentials";

import { isNative } from "@kraaft/helper-functions";
import {
  API_VERBOSE,
  FIRESTORE_VERBOSE,
} from "@kraaft/shared/constants/global";
import { Company } from "@kraaft/shared/core/modules/company/company.state";
import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import { Dummy } from "@kraaft/shared/core/modules/dummy/dummy";
import { Visibility } from "@kraaft/shared/core/modules/filter/filterState";
import { Form } from "@kraaft/shared/core/modules/form/formState";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { MapOverlay } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.state";
import { MessageTypes } from "@kraaft/shared/core/modules/message";
import {
  MiniDocument,
  MiniImage,
  MiniMedia,
  MiniVideo,
} from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { UserUnreadPools } from "@kraaft/shared/core/modules/pool/poolState";
import { PoolSchemaReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import * as RoomTypes from "@kraaft/shared/core/modules/room/roomState";
import { RoomSchemaVisibility } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.state";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { WithId } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import { SchemaTemplate } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";
import { SchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewState";
import {
  CurrentUser,
  OnboardingState,
  User,
  UserMap,
} from "@kraaft/shared/core/modules/user/userState";
import { UserPool } from "@kraaft/shared/core/modules/userPool/userPool.state";
import { Workflow } from "@kraaft/shared/core/modules/workflows/types";
import { errorReporting } from "@kraaft/shared/core/services/errorReporting";
import { FirestoreUtils } from "@kraaft/shared/core/services/firestore/firestore.utils";
import * as Types from "@kraaft/shared/core/services/firestore/firestoreTypes";
import {
  auth,
  firestore,
  FirestoreTypes,
} from "@kraaft/shared/core/services/firestore/sdk";
import { AnyUnexplained } from "@kraaft/shared/core/types";
import { nullId } from "@kraaft/shared/core/utils/utils";
import { PoolAdmin } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminState";

import * as utils from "./firestoreUtils";

// on the web, add this Hash to simulate a Firewall blocking long Firestore requests
const __SIMULATE_FIREWALL__ = window?.location?.hash === "#SIMULATE_FIREWALL";
let currentUserSubscriptionIndex = 0;

export function onSnapshotQuery<T extends FirestoreTypes.DocumentData>(
  message: string,
  query: FirestoreTypes.Query<T>,
  onNext: (snapshot: FirestoreTypes.QuerySnapshot<T>) => void,
  onError?: (error: FirestoreTypes.FirestoreError) => void,
  shouldUpdateOnMetadataUpdate?: boolean,
) {
  if (FIRESTORE_VERBOSE) {
    console.log("subscribing to", message);
  }

  const unsubscribe = query.onSnapshot(
    { includeMetadataChanges: shouldUpdateOnMetadataUpdate ?? false },
    (snapshot) => {
      if (snapshot) {
        if (FIRESTORE_VERBOSE) {
          console.log(
            `snapshot received for ${message}: ${snapshot.size} docs cache=${snapshot.metadata.fromCache}`,
          );
        }
        onNext(snapshot);
      } else {
        // If no error callback is specified, Firestore will send a null snapshot
        if (FIRESTORE_VERBOSE) {
          console.log(`snapshot received for ${message}: ${snapshot}`);
        }
      }
    },
    (error) => {
      console.warn(`snapshot error for ${message}`, error);
      onError?.(error);
    },
  );

  return () => {
    if (FIRESTORE_VERBOSE) {
      console.log("unsubscribing from", message);
    }
    unsubscribe();
  };
}

interface FirestoreFlattenedDirectory {
  roomId: string;
  name: string;
  parentDirectoryId: string;
  index: number;
  files: Types.FirestoreMiniMedia[];
  depth: number;
  deepestChildDepth: number;
}

class DirectoryCollection {
  get collection() {
    return firestore().collection(
      "flattenedDirectoryTree-1n",
    ) as FirestoreTypes.CollectionReference<FirestoreFlattenedDirectory>;
  }

  private normalizeMiniImage(mini: Types.FirestoreMiniImage): MiniImage {
    return {
      id: mini.messageId,
      type: "image",
      messageId: mini.messageId,
      name: mini.name,
      isMiniMedia: true,
      roomId: mini.roomId,
      geolocation: mini.geolocation,
      createdAt: utils.parseDate(mini.createdAt),
      updatedAt: utils.parseDate(mini.updatedAt),
      preview: mini.preview,
    };
  }

  private normalizeMiniVideo(mini: Types.FirestoreMiniVideo): MiniVideo {
    return {
      id: mini.messageId,
      type: "video",
      messageId: mini.messageId,
      name: mini.name,
      isMiniMedia: true,
      roomId: mini.roomId,
      createdAt: utils.parseDate(mini.createdAt),
      updatedAt: utils.parseDate(mini.updatedAt),
    };
  }

  private normalizeMiniDocument(
    mini: Types.FirestoreMiniDocument,
  ): MiniDocument {
    return {
      id: mini.messageId,
      type: "document",
      messageId: mini.messageId,
      name: mini.name,
      isMiniMedia: true,
      roomId: mini.roomId,
      createdAt: utils.parseDate(mini.createdAt),
      updatedAt: utils.parseDate(mini.updatedAt),
      downloadUrl: mini.downloadUrl,
    };
  }

  private normalize(
    directoryId: string,
    stored: FirestoreFlattenedDirectory,
  ): Directory {
    return {
      id: directoryId,
      name: stored.name,
      index: stored.index,
      parentId: stored.parentDirectoryId,
      roomId: stored.roomId,
      files: stored.files.map((file) => {
        const { type } = file;
        if (type === "document") {
          return this.normalizeMiniDocument(file);
        }
        if (type === "image") {
          return this.normalizeMiniImage(file);
        }
        if (type === "video") {
          return this.normalizeMiniVideo(file);
        }
        throw new Error(
          `Could not interpret firestore collection minimedia of type ${
            type as string
          }`,
        );
      }),
      depth: stored.depth,
      deepestChildDepth: stored.deepestChildDepth,
    };
  }

  subscribeToRoomDirectories(
    roomId: string,
    onChange: (directories: Directory[]) => void,
  ) {
    const query = this.collection.where("roomId", "==", roomId);
    return onSnapshotQuery("roomDirectories", query, (snapshot) => {
      const directories = snapshot.docs.map((doc) => {
        return this.normalize(doc.id, doc.data());
      });

      onChange(directories);
    });
  }
}

function onSnapshotDoc<T extends FirestoreTypes.DocumentData>(
  message: string,
  query: FirestoreTypes.DocumentReference<T>,
  onNext: (snapshot: FirestoreTypes.DocumentSnapshot<T>) => void,
  onError?: (error: FirestoreTypes.FirestoreError) => void,
) {
  if (FIRESTORE_VERBOSE) {
    console.log("subscribing to", message);
  }

  const unsubscribe = query.onSnapshot(
    (snapshot) => {
      if (FIRESTORE_VERBOSE) {
        console.log(`snapshot received for ${message}`);
      }
      onNext(snapshot);
    },
    (error) => {
      console.warn(`snapshot error for ${message}`, error);
      onError?.(error);
    },
  );

  return () => {
    if (FIRESTORE_VERBOSE) {
      console.log("unsubscribing from", message);
    }
    unsubscribe();
  };
}

function isSnapshotLikelyBuggy(snapshot: FirestoreTypes.QuerySnapshot) {
  return !isNative() && snapshot.size === 1 && snapshot.metadata.fromCache;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debounceBuggySnapshots<T extends (...args: any[]) => void>(
  callback: T,
) {
  let timer = 0;

  return (snapshot: FirestoreTypes.QuerySnapshot, ...args: Parameters<T>) => {
    if (isSnapshotLikelyBuggy(snapshot)) {
      timer = setTimeout(() => callback(...args), 2000);
    } else {
      if (timer !== null) {
        clearTimeout(timer);
      }
      callback(...args);
    }
  };
}

const directoryCollection = new DirectoryCollection();

const Firestore = {
  subscribeToRoomDirectories:
    directoryCollection.subscribeToRoomDirectories.bind(directoryCollection),
  getRooms: async (roomIds: string[]) => {
    return compact(
      await Promise.all(
        roomIds.map(async (roomId) => {
          try {
            const roomDocument = await firestore()
              .collection("rooms")
              .doc(roomId)
              .get();

            const roomData = roomDocument.data() as
              | Types.FirestoreRoom
              | undefined;

            if (!roomData) {
              return undefined;
            }

            const roomWithoutRecord = utils.normalizeFirestoreRoom(
              roomDocument.id,
              roomData,
            );
            const room: RoomTypes.Room = {
              ...roomWithoutRecord,
              record: {
                ...(KSchemaConversion.toRecord(
                  roomData.record,
                ) as RoomTypes.RoomModularRecord),
                type: roomWithoutRecord.type,
              },
            };

            return room;
          } catch (e) {
            return undefined;
          }
        }),
      ),
    );
  },

  subscribeToRooms: (
    userId: string,
    poolId: string,
    filters:
      | {
          statusFilter: Visibility;
        }
      | undefined,
    limit: number | undefined,
    callback: (tickets: WithId<Types.FirestoreRoom>[]) => void,
  ): (() => void) => {
    const query = createRoomQuery(userId, poolId, filters, limit);

    const debouncedCallback = debounceBuggySnapshots(callback);

    const unsubscribe = onSnapshotQuery(
      `subscribeToRooms userId=${userId} poolId=${poolId} filters=${JSON.stringify(
        filters,
      )} limit=${limit}`,
      query,
      (snapshot) => {
        // TODO better typing
        const rooms = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as Types.FirestoreRoom),
        }));

        debouncedCallback(snapshot, rooms);
      },
    );
    return unsubscribe;
  },

  subscribeToUserRooms: (
    userId: string,
    poolId: string,
    filters:
      | {
          isArchived: boolean;
        }
      | undefined,
    limit: number | undefined,
    callback: (rooms: RoomTypes.UserRoom[]) => void,
  ): (() => void) => {
    const query = createUserRoomQuery(userId, poolId, limit);

    const debouncedCallback = debounceBuggySnapshots(callback);

    return onSnapshotQuery(
      `subscribeToUserRooms userId=${userId} poolId=${poolId} filters=${filters} limit=${limit}`,
      query,
      (snapshot: FirestoreTypes.QuerySnapshot) => {
        const userRooms = snapshot.docs.map((doc) => {
          const data = doc.data() as Types.FirestoreUserRoom;
          return utils.normalizeFirestoreUserRoom(data);
        });

        debouncedCallback(snapshot, userRooms);
      },
    );
  },

  subscribeToRoomMembers: (
    roomId: string,
    callback: (userRooms: RoomTypes.RoomMember[]) => void,
  ): (() => void) => {
    const query = firestore()
      .collection("userRooms")
      .where("roomId", "==", roomId);

    const debouncedCallback = debounceBuggySnapshots(callback);
    return onSnapshotQuery(
      "subscribeToRoomMembers",
      query,
      (snapshot: FirestoreTypes.QuerySnapshot) => {
        const userRooms = snapshot.docs.map((doc) => {
          const data = doc.data() as Types.FirestoreUserRoom;
          return utils.normalizeRoomMember(data);
        });
        debouncedCallback(snapshot, userRooms);
      },
    );
  },

  subscribeToRoom: (
    roomId: string,
    callback: (room: WithId<Types.FirestoreRoom>) => void,
    onError: (error: Error) => void,
  ): (() => void) => {
    return onSnapshotDoc(
      "subscribeToRoom",
      firestore().collection("rooms").doc(roomId),
      (snapshot) => {
        const data = snapshot.data() as Types.FirestoreRoom;
        if (data) {
          callback({ id: snapshot.id, ...data });
        }
      },
      onError,
    );
  },

  subscribeToUserRoom: (
    { userId, roomId }: { userId: string; roomId: string },
    callback: (result: RoomTypes.RoomUserHistory | undefined) => void,
    onError: (error: Error) => void,
  ): (() => void) => {
    const query = firestore()
      .collection("userRooms")
      .where("userId", "==", userId)
      .where("roomId", "==", roomId);

    return onSnapshotQuery(
      "subscribeToUserRoom",
      query,
      (snapshot) => {
        const result = snapshot.docs.map((doc) => {
          const data = doc.data() as Types.FirestoreUserRoom;
          return utils.normalizeFirestoreUserRoom(data);
        });
        callback(result[0]);
      },
      onError,
    );
  },

  subscribeToMessages: (
    userId: string | undefined,
    roomId: string,
    updateFrom: Date | undefined,
    callback: (
      messages: ReturnType<typeof utils.normalizeMessages>,
      docs: FirestoreTypes.QueryDocumentSnapshot[],
      firstPayload: boolean,
    ) => void,
  ): (() => void) => {
    let query = firestore()
      .collection("messages-projection-2n")
      .where("roomId", "==", roomId)
      .orderBy("updatedAt", "desc");

    if (updateFrom) {
      query = query.where("updatedAt", ">", updateFrom);
    }

    const isFirstPayload = { first: true };

    return onSnapshotQuery(
      "subscribeToMessages",
      query,
      (snapshot: FirestoreTypes.QuerySnapshot) => {
        if (snapshot.size > 0) {
          const messages = utils.normalizeMessages(snapshot.docs, userId);

          callback(messages, snapshot.docs, isFirstPayload.first);
        }
        isFirstPayload.first = false;
      },
    );
  },

  subscribeToUserPoolInfo: (
    poolId: string,
    callback: (users: Record<string, User>) => void,
  ): (() => void) => {
    const query = firestore()
      .collection("users")
      .where(new firestore.FieldPath("inPools", poolId), ">=", {});
    return onSnapshotQuery(
      "subscribeToUserPool",
      query,
      async (snapshot: FirestoreTypes.QuerySnapshot) => {
        const userPool = utils.normalizeUsers(snapshot.docs);
        callback(userPool);
      },
    );
  },

  subscribeToCurrentUser: (
    callback: (user: CurrentUser, isBlockedByFirewall: boolean) => void,
  ): (() => void) => {
    const user = auth().currentUser;

    if (!user) {
      throw new Error("no current user");
    }

    const simulateBlocking =
      __SIMULATE_FIREWALL__ && currentUserSubscriptionIndex++ === 0;

    const doc = firestore().collection("users").doc(user.uid);
    return onSnapshotDoc(
      "subscribeToCurrentUser",
      doc,
      (snapshot: FirestoreTypes.DocumentSnapshot) => {
        const data = snapshot.data() as Types.FirestoreUser | undefined;

        let normalizedUser: CurrentUser = {
          id: user.uid,
          username: data?.username,
          firstName: data?.firstName,
          lastName: data?.lastName,
          pools: data && mapValues(data.inPools, utils.normalizeUserPoolInfo),
          superRole: data?.superRole,
          debug: data?.debug,
          pranked: data?.pranked,
          job:
            data?.job && utils.isValidUserJob(data.job) ? data.job : undefined,
          createdAt: utils.parseDate(data?.createdAt),
        };

        // Bouygues: detect firewall on web
        let isBlockedByFirewall =
          !snapshot.exists &&
          snapshot.metadata.fromCache &&
          Platform.OS === "web";

        // for tests
        if (simulateBlocking) {
          console.warn("Simulating Firewall blocking");
          normalizedUser = { id: user.uid };
          isBlockedByFirewall = true;
        }

        callback(normalizedUser, isBlockedByFirewall);
      },
    );
  },

  subscribeToUserOnboardingState: (
    userId: string,
    callback: (onboardingStep: OnboardingState | undefined) => void,
  ): (() => void) => {
    const doc = firestore().collection("onboarding").doc(userId);

    return onSnapshotDoc("subscribeToUserOnboardingState", doc, (snapshot) => {
      const data = snapshot?.data() as
        | Types.FirestoreUserOnboarding
        | undefined;

      callback(data && utils.normalizeUserOnboardingState(data));
    });
  },

  subscribeToPool: (poolId: string, callback: (pool: Pool) => void) => {
    const doc = firestore().collection("pools").doc(poolId);

    return onSnapshotDoc("subscribeToPool", doc, (snapshot) => {
      if (!snapshot) {
        return;
      }
      const data = snapshot.data() as Types.FirestorePool;
      callback(utils.normalizePool(snapshot.id, data));
    });
  },

  subscribeToPoolAdmin: (
    poolId: string,
    callback: (pool: PoolAdmin) => void,
  ): (() => void) => {
    const doc = firestore()
      .collection("pools")
      .doc(poolId)
      .collection("poolPrivate")
      .doc("admin");

    return onSnapshotDoc("subscribeToPoolAdmin", doc, (snapshot) => {
      const data = snapshot.data() as Types.FirestorePoolAdmin | undefined;
      if (data) {
        callback(utils.normalizePoolAdmin(poolId, data));
      } else {
        callback({ poolId });
      }
    });
  },

  getPool: async (poolId: string): Promise<Pool | undefined> => {
    try {
      const snapshot = await firestore().collection("pools").doc(poolId).get();
      const data = snapshot.data() as Types.FirestorePool;

      return data && utils.normalizePool(snapshot.id, data);
    } catch (e) {
      errorReporting.reportError(e, `get pool doc poolId==${poolId}`);
      return undefined;
    }
  },

  getPools: async (poolIds: string[]): Promise<Pool[]> => {
    const chunks = chunk(poolIds, 10);
    return flatten(
      await Promise.all(
        chunks.map(async (idsChunk) => {
          const pools = await firestore()
            .collection("pools")
            .where(firestore.FieldPath.documentId(), "in", idsChunk)
            .get();
          return pools.docs.map((doc) =>
            utils.normalizePool(doc.id, doc.data() as Types.FirestorePool),
          );
        }),
      ),
    );
  },

  getPoolByName: async (name: string): Promise<Pool | undefined> => {
    try {
      const snapshot = await firestore()
        .collection("pools")
        .where("name", "==", name)
        .get();
      const doc = snapshot.docs[0];

      return (
        doc && utils.normalizePool(doc.id, doc.data() as Types.FirestorePool)
      );
    } catch (e) {
      errorReporting.reportError(e, `get pool doc poolName==${name}`);
      return undefined;
    }
  },

  getUnknownUsers: async (userIds: string[]): Promise<UserMap> => {
    try {
      const query = firestore()
        .collection("users")
        .where(firestore.FieldPath.documentId(), "in", userIds);

      const snapshot: FirestoreTypes.QuerySnapshot = await query.get();

      const users = utils.normalizeUsers(snapshot.docs);

      return users;
    } catch (e) {
      errorReporting.reportError(e, `get users where userIds==${userIds}`);
      return {};
    }
  },

  getUnknownMessages: async (
    messageIds: string[],
    roomId: string,
    userId: string | undefined,
    fromCache: boolean,
  ): Promise<Dictionary<MessageTypes.Message, string>> => {
    try {
      const query = firestore()
        .collection("messages-projection-2n")
        .where("roomId", "==", roomId)
        .where(firestore.FieldPath.documentId(), "in", messageIds);

      const snapshot: FirestoreTypes.QuerySnapshot = await query.get({
        source: fromCache ? "cache" : "default",
      });

      const answers = utils.normalizeMessages(snapshot.docs, userId);

      return answers.messages;
    } catch (e) {
      errorReporting.reportError(
        e,
        `getUnknownMessages messageIds==${messageIds}`,
      );
      return {};
    }
  },

  subscribeToKizeoForms: (
    poolId: string,
    callback: (forms: Form[]) => void,
  ): (() => void) => {
    const query = firestore()
      .collection("forms")
      .where("poolId", "==", poolId)
      .where("type", "==", "kizeo");
    const unsubscribe = onSnapshotQuery(
      "subscribeToForms",
      query,
      (snapshot) => {
        const forms = utils.normalizeFirestoreForms(
          snapshot.docs as FirestoreTypes.QueryDocumentSnapshot<Types.FirestoreFillableForm>[],
        );
        callback(forms);
      },
    );
    return unsubscribe;
  },

  subscribeToTemplates: (
    poolId: string,
    callback: (templates: PoolSchemaReportTemplate[]) => void,
  ): (() => void) => {
    const query: FirestoreTypes.Query = firestore()
      .collection("reportTemplates-projection-36")
      .where("poolId", "==", poolId);
    const unsubscribe = onSnapshotQuery(
      "subscribeToTemplates",
      query,
      (snapshot) => {
        const templates = utils.normalizeTemplates(snapshot.docs);
        callback(templates);
      },
    );
    return unsubscribe;
  },

  subscribeToSchemas: (
    poolId: string,
    callback: (schemas: KSchema[]) => void,
  ): (() => void) => {
    const query: FirestoreTypes.Query = firestore()
      .collection("schemas-projection-48")
      .where("poolId", "==", poolId);
    const unsubscribe = onSnapshotQuery(
      "subscribeToSchemas",
      query,
      (snapshot) => {
        const schemas = utils.normalizeSchemas(snapshot.docs);
        callback(schemas);
      },
    );
    return unsubscribe;
  },

  subscribeToSchemaTemplates: (
    poolId: string,
    callback: (templates: SchemaTemplate[]) => void,
  ): (() => void) => {
    const query: FirestoreTypes.Query = firestore()
      .collection("schemaTemplates")
      .where("poolId", "==", poolId);
    const unsubscribe = onSnapshotQuery(
      "subscribeToSchemaTemplates",
      query,
      (snapshot) => {
        const templates = utils.normalizeSchemaTemplates(snapshot.docs);
        callback(templates);
      },
    );
    return unsubscribe;
  },

  subscribeToRoomModularFolders: (
    roomId: string,
    callback: (modularFolders: {
      modularFolders: WithId<Types.FirestoreModularFolder>[];
      source: "cache" | "network";
    }) => void,
  ): (() => void) => {
    const query: FirestoreTypes.Query = firestore()
      .collection("modularFolders-projection-2n")
      .where("roomId", "==", roomId);
    const unsubscribe = onSnapshotQuery(
      "subscribeToRoomModularFolders",
      query,
      (snapshot) => {
        callback({
          modularFolders: snapshot.docs.map((d) => {
            const data = d.data();
            return {
              id: d.id,
              title: data.properties.title.value,
              ...d.data(),
            } as WithId<Types.FirestoreModularFolder>;
          }),
          source: snapshot.metadata.fromCache ? "cache" : "network",
        });
      },
      undefined,
      // Firestore will return fromCache if cache is equal to network
      // We need to know if this is from offline cache or network
      // To be sure if a modular folder was deleted or is just not in cache
      true,
    );
    return unsubscribe;
  },

  subscribeToUserVisibleModularFolders: (
    poolId: string,
    userId: string,
    callback: (modularFolders: WithId<Types.FirestoreModularFolder>[]) => void,
  ): (() => void) => {
    const query: FirestoreTypes.Query = firestore()
      .collection("modularFolders-projection-2n")
      .where("poolId", "==", poolId)
      .where("userIds", "array-contains", userId);

    const unsubscribe = onSnapshotQuery(
      "subscribeToUserVisibleModularFolders",
      query,
      (snapshot) => {
        callback(
          snapshot.docs.map(
            (d) =>
              ({
                id: d.id,
                ...d.data(),
              }) as WithId<Types.FirestoreModularFolder>,
          ),
        );
      },
    );
    return unsubscribe;
  },

  subscribeToPoolVisibleModularFolders: (
    poolId: string,
    visibilities: RoomTypes.RoomVisibility[],
    callback: (modularFolders: WithId<Types.FirestoreModularFolder>[]) => void,
  ): (() => void) => {
    const query: FirestoreTypes.Query = firestore()
      .collection("modularFolders-projection-2n")
      .where("poolId", "==", poolId)
      .where("visibility", "in", visibilities);

    const unsubscribe = onSnapshotQuery(
      "subscribeToPoolVisibleModularFolders",
      query,
      (snapshot) => {
        callback(
          snapshot.docs.map(
            (d) =>
              ({
                id: d.id,
                ...d.data(),
              }) as WithId<Types.FirestoreModularFolder>,
          ),
        );
      },
    );
    return unsubscribe;
  },

  subscribeToWorkflows: (
    poolId: string,
    callback: (templates: Workflow[]) => void,
  ): (() => void) => {
    const query: FirestoreTypes.Query = firestore()
      .collection("workflows")
      .where("poolId", "==", poolId);

    const unsubscribe = onSnapshotQuery(
      "subscribeToWorkflows",
      query,
      (snapshot) => {
        const workflows = utils.normalizeWorkflows(snapshot.docs);
        callback(workflows);
      },
    );
    return unsubscribe;
  },

  subscribeToUserUnreadPools: (
    userId: string,
    callback: (userUnreadPools: UserUnreadPools[]) => void,
  ): (() => void) => {
    const query: FirestoreTypes.Query = firestore()
      .collection("userUnreadPools")
      .where("userId", "==", userId);

    const unsubscribe = onSnapshotQuery(
      "subscribeToUserUnreadPools",
      query,
      (snapshot) => {
        const userUnreads = utils.normalizeUserUnreadPools(snapshot.docs);
        callback(userUnreads);
      },
    );
    return unsubscribe;
  },

  subscribeToSchemaViews: (
    poolId: string,
    callback: (schemas: SchemaView[]) => void,
  ): (() => void) => {
    const query: FirestoreTypes.Query = firestore()
      .collection("schemaViews")
      .where("poolId", "==", poolId);
    const unsubscribe = onSnapshotQuery(
      "subscribeToSchemaViews",
      query,
      (snapshot) => {
        const schemas = utils.normalizeSchemaViews(snapshot.docs);
        callback(schemas);
      },
    );
    return unsubscribe;
  },

  subscribeToMiniMedias: (
    roomId: string,
    limitDate: Date | undefined,
    callback: (medias: MiniMedia[]) => void,
  ) => {
    let query: FirestoreTypes.Query = firestore()
      .collection("miniMediasProjection-1n")
      .where("roomId", "==", roomId);

    if (limitDate) {
      query = query.where("createdAt", ">=", limitDate);
    }

    const unsubscribe = onSnapshotQuery(
      "subscribeToMiniMedias",
      query,
      async (snapshot) => {
        if (API_VERBOSE) {
          console.log(
            `subscribeToMiniMedias :: received ${
              snapshot.size
            } docs for roomId:${roomId} with {limitDate: ${limitDate?.toISOString()}`,
          );
        }
        // Hotfix for this bug
        // https://linear.app/kraaft/issue/KRA-4043/we-seem-to-generate-multiple-mini-medias-for-the-same-message
        const docs =
          snapshot.docs as FirestoreTypes.QueryDocumentSnapshot<Types.FirestoreMiniMedia>[];
        const uniqDocs = uniqBy(docs, (doc) => doc.data().messageId);
        const medias = utils.normalizeMiniMedias(uniqDocs);

        callback(medias);
      },
    );
    return unsubscribe;
  },

  hasMoreMiniMediasOfType: async (
    roomId: string,
    type: MiniMedia["type"],
    limitDate: Date,
  ) => {
    try {
      const query: FirestoreTypes.Query = firestore()
        .collection("miniMediasProjection-1n")
        .where("roomId", "==", roomId)
        .where("type", "==", type)
        .where("createdAt", "<", limitDate)
        .limit(1);

      const snapshot: FirestoreTypes.QuerySnapshot = await query.get();

      return snapshot.size > 0;
    } catch (error) {
      errorReporting.reportError(
        error,
        `checking has miniMedias before date(${limitDate.toISOString()})`,
      );
      return false;
    }
  },

  subscribeToUserPool: (
    poolId: string,
    userId: string,
    callback: (userPool: UserPool) => void,
  ): (() => void) => {
    const query = firestore()
      .collection("userPoolsProjection")
      .where("userId", "==", userId)
      .where("poolId", "==", poolId);

    return onSnapshotQuery("subscribeToUserPool", query, (snapshot) => {
      const doc = snapshot.docs[0];
      if (doc) {
        const userPool = utils.normalizeUserPool(doc);
        callback(userPool);
      }
    });
  },
  subscribeToIdentityProviders: (
    callback: (
      providers: {
        id: string;
        name: string;
        skippable: boolean;
        offlineAccess: string | undefined;
        helpText: string | undefined;
        domains: string[];
        subConfigs:
          | {
              id: string;
              name: string;
              skippable: boolean;
              helpText: string | undefined;
              domains: string[];
            }[]
          | undefined;
      }[],
    ) => void,
  ) => {
    const query = firestore().collection("identityProviders");
    return onSnapshotQuery("getIdentityProviders", query, (snapshot) => {
      callback(snapshot.docs.map((d) => d.data()) as AnyUnexplained);
    });
  },

  subscribeToMapOverlays: (
    poolId: string,
    callback: (mapOverlays: MapOverlay[]) => void,
  ): (() => void) => {
    const query: FirestoreTypes.Query = firestore()
      .collection("mapOverlays-projection-38")
      .where("poolId", "==", poolId);
    const unsubscribe = onSnapshotQuery(
      "subscribeToMapOverlays",
      query,
      (snapshot) => {
        const mapOverlays = utils.normalizeMapOverlays(snapshot.docs);
        callback(mapOverlays);
      },
    );
    return unsubscribe;
  },

  subscribeToSuperadminLibrarySchemas: (
    callback: (librarySchemas: LibrarySchema[]) => void,
  ): (() => void) => {
    const query =
      FirestoreUtils.getFirestoreCollection<Types.FirestoreLibrarySchema>(
        "librarySchemas-projection-3n",
      );
    const unsubscribe = onSnapshotQuery(
      "subscribeToSuperadminLibrarySchemas",
      query,
      (snapshot) => {
        const librarySchemas = utils.normalizeLibrarySchema(snapshot.docs);
        callback(librarySchemas);
      },
    );
    return unsubscribe;
  },

  subscribeToPublicLibrarySchemas: (
    callback: (librarySchemas: LibrarySchema[]) => void,
  ): (() => void) => {
    const query =
      FirestoreUtils.getFirestoreCollection<Types.FirestoreLibrarySchema>(
        "librarySchemas-projection-3n",
      ).where("companyId", "==", nullId);
    const unsubscribe = onSnapshotQuery(
      "subscribeToLibrarySchemas",
      query,
      (snapshot) => {
        const librarySchemas = utils.normalizeLibrarySchema(snapshot.docs);
        callback(librarySchemas);
      },
    );
    return unsubscribe;
  },

  subscribeToCompanyLibrarySchemas: (
    companyId: string,
    callback: (librarySchemas: LibrarySchema[]) => void,
  ): (() => void) => {
    const query =
      FirestoreUtils.getFirestoreCollection<Types.FirestoreLibrarySchema>(
        "librarySchemas-projection-3n",
      ).where("companyId", "==", companyId);
    const unsubscribe = onSnapshotQuery(
      "subscribeToCompanyLibrarySchemas",
      query,
      (snapshot) => {
        const librarySchemas = utils.normalizeLibrarySchema(snapshot.docs);
        callback(librarySchemas);
      },
    );
    return unsubscribe;
  },

  subscribeToRoomSchemaVisibility: (
    roomId: string,
    callback: (roomSchemaVisibility: RoomSchemaVisibility) => void,
  ): (() => void) => {
    const doc = firestore()
      .collection("roomSchemaVisibilities-projection-41")
      .doc(roomId);

    return onSnapshotDoc(
      `subscribeToRoomSchemaVisibility ${roomId}`,
      doc,
      (snapshot) => {
        const data = snapshot.data() as
          | Types.FirestoreRoomSchemaVisibility
          | undefined;

        if (data) {
          callback(utils.normalizeRoomSchemaVisibility(roomId, data));
        }
      },
    );
  },

  async fetchRoomLastMessage(roomId: string, userId: string) {
    const { docs } = await firestore()
      .collection("messages-projection-2n")
      .where("roomId", "==", roomId)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();
    const [last] = docs;
    if (!last) {
      return undefined;
    }
    return utils.normalizeMessage(
      last.id,
      last.data() as Types.FirestoreMessage,
      userId,
      undefined,
    ).message;
  },

  async fetchRoomLastMessages(
    roomId: string,
    pageSize: number,
    userId: string,
  ) {
    const { docs } = await firestore()
      .collection("messages-projection-2n")
      .where("roomId", "==", roomId)
      .orderBy("createdAt", "desc")
      .limit(pageSize)
      .get();
    return {
      allDocs: docs,
      messages: utils.normalizeMessages(docs, userId).messages,
    };
  },

  async fetchRoomFirstMessages(
    roomId: string,
    pageSize: number,
    userId: string,
  ) {
    const { docs } = await firestore()
      .collection("messages-projection-2n")
      .where("roomId", "==", roomId)
      .orderBy("createdAt", "asc")
      .limit(pageSize)
      .get();
    return {
      allDocs: docs,
      messages: utils.normalizeMessages(docs, userId).messages,
    };
  },

  async fetchMessage(messageId: string, userId: string) {
    try {
      const doc = await firestore()
        .collection("messages-projection-2n")
        .doc(messageId)
        .get();

      if (!doc.exists || !doc.data()) {
        return undefined;
      }
      const item = utils.normalizeMessage(
        doc.id,
        doc.data() as any,
        userId,
        undefined,
      ).message;
      return {
        message: item,
        snapshot: doc as FirestoreTypes.QueryDocumentSnapshot,
      };
    } catch (e) {
      console.error("fetchMessage error:", e);
      return undefined;
    }
  },

  async fetchMessagesBefore(
    roomId: string,
    beforeDoc: FirestoreTypes.DocumentSnapshot,
    pageSize: number,
    userId: string,
  ) {
    const { docs } = await firestore()
      .collection("messages-projection-2n")
      .where("roomId", "==", roomId)
      .orderBy("createdAt", "desc")
      .startAfter(beforeDoc)
      .limit(pageSize)
      .get();
    return {
      allDocs: docs,
      messages: utils.normalizeMessages(docs, userId).messages,
    };
  },

  async fetchMessageAfter(
    roomId: string,
    afterDoc: FirestoreTypes.DocumentSnapshot,
    pageSize: number,
    userId: string,
  ) {
    const { docs } = await firestore()
      .collection("messages-projection-2n")
      .where("roomId", "==", roomId)
      .orderBy("createdAt", "asc")
      .startAfter(afterDoc)
      .limit(pageSize)
      .get();
    return {
      allDocs: docs,
      messages: utils.normalizeMessages(docs, userId).messages,
    };
  },

  subscribeToCompany: (
    companyId: string,
    callback: (company: Company) => void,
  ): (() => void) => {
    const query = FirestoreUtils.getFirestoreCollection<Types.FirestoreCompany>(
      "companies-projection-1n",
    ).doc(companyId);
    const unsubscribe = onSnapshotDoc(
      "subscribeToCompany",
      query,
      (snapshot) => {
        const data = snapshot.data();
        if (!data) {
          return;
        }
        const [normalizedCompany] = utils.normalizeCompanies([
          { ...data, id: snapshot.id },
        ]);
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        callback(normalizedCompany!);
      },
    );
    return unsubscribe;
  },

  subscribeToCompanies: (
    callback: (companies: Company[]) => void,
  ): (() => void) => {
    const query = FirestoreUtils.getFirestoreCollection<Types.FirestoreCompany>(
      "companies-projection-1n",
    );
    const unsubscribe = onSnapshotQuery(
      "subscribeToCompanies",
      query,
      (snapshot) => {
        const companies = utils.normalizeCompanies(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
        );
        callback(companies);
      },
    );
    return unsubscribe;
  },

  subscribeToDummies: (callback: (dummies: Dummy[]) => void) => {
    const query =
      FirestoreUtils.getFirestoreCollection<Types.FirestoreDummy>("dummy");
    const unsubscribe = onSnapshotQuery("dummy", query, (snapshot) => {
      const dummies = utils.normalizeDummies(
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
      );
      callback(dummies);
    });
    return unsubscribe;
  },
};

function createUserRoomQuery(
  userId: string,
  poolId: string,
  limit: number | undefined,
) {
  let query = firestore()
    .collection("userRooms")
    .where("userId", "==", userId)
    .where("poolId", "==", poolId)
    .orderBy("lastEventAt", "desc")
    .orderBy("roomId", "desc");

  query = query.where("isArchived", "in", [true, false]);

  if (limit !== undefined) {
    query = query.limit(limit);
  }
  return query;
}

function createRoomQuery(
  userId: string,
  poolId: string,
  filters: { statusFilter: Visibility } | undefined,
  limit: number | undefined,
) {
  const collection = firestore().collection("rooms");

  let query = collection
    .where("poolId", "==", poolId)
    .orderBy("lastEventAt", "desc")
    .orderBy(firestore.FieldPath.documentId(), "desc");

  let visibleFor: string[];
  if (filters?.statusFilter === "member") {
    visibleFor = [userId];
  } else if (filters?.statusFilter === "superadmin") {
    visibleFor = ["private", "pool", "administrator"];
  } else {
    visibleFor = [userId, "pool", "administrator"];
  }
  query = query.where("visibleFor", "array-contains-any", visibleFor);

  if (limit !== undefined) {
    query = query.limit(limit);
  }
  return query;
}

Object.freeze(Firestore);
export { Firestore };
