import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import compact from "lodash/compact";

import { hasTitleProperty } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { selectOrderedModularFoldersWithRoomIdAndSchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordEditionPayload } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { selectSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { withDependencyWrapper } from "@kraaft/shared/core/utils/withDepedencyWrapper";
import { Button } from "@kraaft/ui";
import { EditModularFoldersPopupSheet } from "@kraaft/web/src/components/editModularFoldersPopup/editModularFoldersPopup.sheet";
import { MAX_TABLE_HEIGHT_IN_DIALOG } from "@kraaft/web/src/components/modularTable/components/table/tableUtils";
import { SimplifiedTableEditions } from "@kraaft/web/src/components/simplifiedTable/simplifiedTableEditions";
import { SimplifiedTableEditions as SimplifiedTableEditionsType } from "@kraaft/web/src/components/simplifiedTable/simplifiedTableTypes";
import {
  getAdditionsOfEditions,
  getModificationsOfEditions,
} from "@kraaft/web/src/components/simplifiedTable/simplifiedTableUtils";
import { useRouteService } from "@kraaft/web/src/core/services/navigation/useNavigationService";

interface EditModularFoldersPopupCompositionProps {
  onClose: () => void;
  schemaId: string;
}

const EditModularFoldersPopupComposition_ = ({
  schemaId,
  schema,
  onClose,
}: EditModularFoldersPopupCompositionProps & { schema: KSchema }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const routeService = useRouteService<"Conversation">();
  const roomId = routeService.getParam("roomId");
  const { folders } = useSelector(
    selectOrderedModularFoldersWithRoomIdAndSchemaId({
      roomId,
      schemaId,
    }),
  );

  const [changes, setChanges] = useState<
    Record<string, SimplifiedTableEditionsType>
  >({});

  const apply = useCallback(async () => {
    const editions = getModificationsOfEditions(changes);
    const additions = getAdditionsOfEditions(changes);

    if (Object.keys(editions).length > 0) {
      const data = Object.entries(editions).reduce<{
        updated: Array<{
          id: string;
          properties: ModularRecordEditionPayload[];
        }>;
        deleted: Array<string>;
      }>(
        (acc, [key, value]) => {
          if (value.deleted) {
            acc.deleted.push(key);
            return acc;
          }
          acc.updated.push({ id: key, properties: value.editions });
          return acc;
        },
        { updated: [], deleted: [] },
      );
      dispatch(OfflineModularFolderActions.edit({ editions: data.updated }));
      for (const deleted of data.deleted) {
        dispatch(OfflineModularFolderActions.delete({ id: deleted }));
      }
    }
    if (Object.keys(additions).length > 0) {
      const data = compact(
        Object.values(additions).map((add) => {
          if (hasTitleProperty(add.editions)) {
            return add.editions;
          }
          return undefined;
        }),
      );

      dispatch(
        OfflineModularFolderActions.create({
          roomId,
          schemaId,
          properties: data,
        }),
      );
    }

    onClose();
  }, [changes, dispatch, onClose, roomId, schemaId]);

  const finalSchema = useMemo(() => {
    return KSchemaUtils.withoutColumnsOfType(schema, [
      KColumnType.attachment,
      KColumnType.roomMembers,
      KColumnType.roomName,
    ]);
  }, [schema]);

  return (
    <EditModularFoldersPopupSheet.Paper>
      <EditModularFoldersPopupSheet.Header onClose={onClose}>
        {t("editModularFolders.title", { schemaName: schema.name })}
      </EditModularFoldersPopupSheet.Header>
      <EditModularFoldersPopupSheet.Content>
        {schema ? (
          <SimplifiedTableEditions
            schema={finalSchema}
            records={folders}
            editions={changes}
            onEditionsChange={setChanges}
            canDeleteRecord
            forcedHeight={MAX_TABLE_HEIGHT_IN_DIALOG}
            recordType="modularFolder"
            noCheckboxConfirmation
          />
        ) : null}
      </EditModularFoldersPopupSheet.Content>
      <EditModularFoldersPopupSheet.Footer>
        <Button text={t("cancel")} variant="QUATERNARY" onPress={onClose} />
        <Button text={t("saveEdits")} onPress={apply} />
      </EditModularFoldersPopupSheet.Footer>
    </EditModularFoldersPopupSheet.Paper>
  );
};

export const EditModularFoldersPopupComposition = withDependencyWrapper(
  EditModularFoldersPopupComposition_,
  ({ schemaId }) => {
    const schema = useSelector(selectSchema(schemaId));

    if (!schema) {
      return undefined;
    }

    return { schema };
  },
);
