import { Platform } from "react-native";

import { Offset, Size } from "@kraaft/shared/core/types";

// Platform specific zoom ranges
// (iOS) Apple Maps [1 -> 18]
// (Android) Google Maps [3 -> 20]
// (Web) Google Maps [0 -> 21] (but we use 2 to prevent zooming out too far)
export const MIN_MAP_ZOOM = Platform.select({ ios: 1, android: 3 }) ?? 2;
export const MAX_MAP_ZOOM = Platform.select({ ios: 18, android: 20 }) ?? 21;

/**
 * We need this because some places can't be zoomed to {@link MAX_MAP_ZOOM}
 * The alternative approach to ensure that a zoom level is actually the maximum one for a corresponding location
 * (lat/long) would be to call Google Zoom Service.
 *
 * Since "most roadmap imagery is available from zoom levels 0 to 18".
 * We set this value to 18.
 * https://developers.google.com/maps/documentation/javascript/maxzoom#MaxZoom
 */
export const MAP_PROBABLE_MAX_ZOOM_LIMIT = 18;

export const getMarkerTintColor = (
  { isHovered, isSelected }: { isHovered?: boolean; isSelected?: boolean },
  tintColor: React.CSSProperties["color"],
  defaultColor: React.CSSProperties["color"],
) => (isHovered || isSelected ? tintColor : defaultColor);

export const getNativeMarkerOffsetFromSize = (
  size: Size,
  offset: Offset = { left: 0, top: 0 },
) => ({
  centerOffset: { x: -offset.left, y: -size.height / 2 - offset.top },
  calloutOffset: { x: 0, y: -10 },
});
