import { LegacyActionSheetProps } from "@kraaft/shared/components/legacyActionSheet";
import { ActionSheetMenu } from "@kraaft/web/src/components/actionSheetMenu";
import { PopupMenu } from "@kraaft/web/src/components/popupMenu";

/** @deprecated use @kraaft/shared/components/actionSheet instead */
const LegacyActionSheet = (props: LegacyActionSheetProps) => {
  const { anchorRef, open, onClose, withAnchorWidth, ...menuProps } = props;

  return (
    <PopupMenu
      anchorRef={anchorRef}
      open={open}
      onClose={onClose}
      withAnchorWidth={withAnchorWidth}
    >
      <ActionSheetMenu open={open} onClose={onClose} {...menuProps} />
    </PopupMenu>
  );
};

export { LegacyActionSheet };
