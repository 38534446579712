import { ReactNode, useMemo } from "react";
import { View } from "react-native";

import { Text } from "@kraaft/ui";

import { styles } from "./signatureDisplayer.styles";

interface SignatureDisplayerProps {
  children: ReactNode;
  footer: ReactNode;
  title?: string;
  comment?: string;
  signatureSize?: number;
}

const SignatureDisplayer = ({
  children,
  footer,
  title,
  comment,
  signatureSize,
}: SignatureDisplayerProps) => {
  const drawingStyles = useMemo(
    () => [styles.drawing, { height: signatureSize, width: signatureSize }],
    [signatureSize],
  );

  return (
    <View style={styles.root}>
      <View style={styles.signature}>
        <Text color="FONT_LOW_EMPHASIS">{title}</Text>
        <View style={drawingStyles}>{children}</View>
        <Text style={styles.comment}>{comment}</Text>
      </View>
      <View style={styles.footer}>{footer}</View>
    </View>
  );
};

export { SignatureDisplayer };
