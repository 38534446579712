import { cloneDeep } from "lodash";

import { CLONE_DEEP_VERBOSE } from "@kraaft/shared/constants/global";

export function traceCloneDeep(objectType: string, toClone: any) {
  if (CLONE_DEEP_VERBOSE) {
    console.log(`traceCloneDeep: ${objectType}`);
  }
  return cloneDeep(toClone);
}
