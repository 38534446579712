import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { useBooleanState } from "@kraaft/helper-hooks";
import { ContextMenuButton } from "@kraaft/shared/components/contextMenuButton";
import { Toggle } from "@kraaft/shared/components/toggle";
import { Workflow } from "@kraaft/shared/core/modules/workflows/types";
import {
  WorkflowOptimisticActions,
  WorkflowOptimisticOperations,
} from "@kraaft/shared/core/modules/workflows/workflow.optimistic";
import { ReactNodeRef } from "@kraaft/shared/core/types";
import { ActionSheetHost } from "@kraaft/ui";
import { useStyles } from "@kraaft/web/src/views/settings/workflows/workflowLine.styles";

import { WorkflowContextMenuSheetContent } from "./workflowContextMenuSheetContent";

interface WorkflowLineProps {
  workflow: Workflow;
  selected: boolean;
  onSelect: () => void;
}

export const WorkflowLine = ({
  workflow,
  selected,
  onSelect,
}: WorkflowLineProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const contextMenuButtonRef = useRef<ReactNodeRef | null>(null);
  const [isContextMenuOpen, openContextMenu, closeContextMenu] =
    useBooleanState();

  const handleOpenContextMenu = useCallback(
    (ref: ReactNodeRef) => {
      contextMenuButtonRef.current = ref;
      openContextMenu();
    },
    [openContextMenu],
  );

  const setWorkflowEnabled = useCallback(
    (value: boolean) => {
      dispatch(
        WorkflowOptimisticActions.addOperation(
          WorkflowOptimisticOperations.update.create({
            targetId: workflow.id,
            updatedWorkflow: {
              ...workflow,
              enabled: value,
            },
          }),
        ),
      );
    },
    [dispatch, workflow],
  );

  return (
    <div
      onClick={onSelect}
      className={clsx(classes.root, selected && classes.selected)}
    >
      <span className={classes.name}>{workflow.name}</span>
      <div className={classes.actionsContainer}>
        <div className={classes.switch}>
          <Toggle value={workflow.enabled} setValue={setWorkflowEnabled} />
          <span className={classes.toggleText}>
            {workflow.enabled ? t("enabled") : t("disabled")}
          </span>
        </div>
        <ContextMenuButton onPress={handleOpenContextMenu} />
        {contextMenuButtonRef.current ? (
          <ActionSheetHost
            open={isContextMenuOpen}
            onClose={closeContextMenu}
            anchor={contextMenuButtonRef.current}
          >
            <WorkflowContextMenuSheetContent
              workflowId={workflow.id}
              closeSheet={closeContextMenu}
            />
          </ActionSheetHost>
        ) : null}
      </div>
    </div>
  );
};
