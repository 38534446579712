import { call, put, spawn, takeEvery } from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { MapOverlayOptimisticSaga } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.optimistic";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

import { subscribeToMapOverlaysSaga } from "./sagas/subscribeToMapOverlays";
import * as actions from "./mapOverlay.actions";

export function* mapOverlaySagas() {
  yield* spawn(subscribeToMapOverlaysSaga);
  yield* spawn(MapOverlayOptimisticSaga);

  yield* takeEvery(actions.MapOverlayActions.addMapOverlay, addMapOverlaySaga);
}

function* addMapOverlaySaga(
  action: ReturnType<typeof actions.MapOverlayActions.addMapOverlay>,
) {
  const { payload, meta } = action;
  const { poolId, name, file } = payload;

  try {
    yield* call(Api.addMapOverlay, {
      poolId,
      name,
      file,
    });

    yield* put(showSuccess({ title: i18n.t("importMapOverlay.addSuccess") }));
    yield* put(actions.MapOverlayActions.addMapOverlaySuccess(meta));
  } catch (error) {
    yield* put(showError({ title: i18n.t("importMapOverlay.addFailure") }));

    yield* put(actions.MapOverlayActions.addMapOverlayFailure(error, meta));
  }
}
