import { useTranslation } from "react-i18next";

import { DetailedActionCard } from "@kraaft/shared/components/detailedActionCard";
import { useDialog } from "@kraaft/shared/components/kDialog/context";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { ColorStyle } from "@kraaft/ui";
import { AskSchemaNameAndIconDialogContent } from "@kraaft/web/src/views/schemasView/addSchemaFromScratch/askSchemaNameAndIconDialogContent";

import reportImage from "./assets/001-sign-up.png";

export const AddSchemaFromScratchActionCard = () => {
  const { t } = useTranslation();

  const { openDialog, closeDialog } = useDialog();

  const handlePress = () => {
    trackEvent({
      eventName: "Click On Add Schema",
      source: "pool_schema_list",
    });
    openDialog(<AskSchemaNameAndIconDialogContent onClose={closeDialog} />);
  };

  return (
    <DetailedActionCard
      title={t("schemasView.addDialog.fromScratch.title")}
      subtitle={t("schemasView.addDialog.fromScratch.subtitle")}
      titleColor={ColorStyle.ACTION_CTA}
      image={reportImage}
      onPress={handlePress}
    />
  );
};
