import { makeStyles } from "@mui/styles";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  page: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    paddingTop: Spacing.S24,
    paddingLeft: Spacing.S32,
    paddingRight: Spacing.S32,
    borderBottomWidth: 1,
    borderBottomColor: ColorStyle.SEPARATOR,
    borderBottomStyle: "solid",
  },
  gridContainer: {
    flexGrow: 1,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflowY: "auto",

    paddingTop: Spacing.S24,
    paddingLeft: Spacing.S32,
    paddingRight: Spacing.S32,
  },
});
