import { StyleSheet } from "react-native";

import { Color, ColorStyle, FontSize } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 24,
  },
  title: {
    flex: 1,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.BODY,
    lineHeight: 21,
    paddingRight: 10,
  },
  date: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
    fontSize: FontSize.MEDIUM,
  },
  unread: {
    color: Color.BLUE_SAVOY,
  },
  contextMenu: {
    // padding of the context menu
    margin: -3,
  },
});
