import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ListRenderItemInfo, Pressable, Text, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { Collapsible } from "@kraaft/shared/components/animated/collapsible";
import { FlatList } from "@kraaft/shared/components/kSelectionList/flatListComponent";
import { KSelectionListChecker } from "@kraaft/shared/components/kSelectionList/kSelectionListChecker";
import { KSelectionListItemBasic } from "@kraaft/shared/components/kSelectionList/kSelectionListItemBasic";
import {
  LoadingFooter,
  LoadMoreErrorFooter,
} from "@kraaft/shared/components/roomList/roomListFooters";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";

import { KSelectionListCell } from "./kSelectionListCell";
import {
  KSelectableListItem,
  KSelectionListItemExtendedProps,
  KSelectionListProps,
} from "./kSelectionListProps";

import { styles } from "./kSelectionList.styles";

const textFilter = <T extends string, U>(filter: string) => {
  const normalizedFilter = normalizeTextForSearch(filter);
  return (item: KSelectableListItem<T, U>) =>
    normalizeTextForSearch(item.label).includes(normalizedFilter);
};

const keyExtractor: (
  item: KSelectionListItemExtendedProps<string, unknown>,
  index: number,
) => string = (item) => item.identifier;

const KSelectionList = <T extends string, U>({
  items,
  onSelect,
  selected: selectedArray,
  setSelected,
  disabled = false,
  showChecker = true,
  id,
  minSelection,
  isMultiple,
  leftIcon,
  renderItem: overrideRenderItem,
  renderChecker: overrideRenderChecker,
  selectAll,
  style,
  labelFilter,
  keyboardShouldPersistTaps,
  disableScroll,
  isLoadingMore,
  onEndReached,
  hasError,
  keyboardDismissMode,
  noPadding,
  footer,
}: KSelectionListProps<T, U>) => {
  const { t } = useTranslation();

  const selectedRef = useRef(new Set<T>());
  selectedRef.current = useMemo(() => {
    return new Set(selectedArray);
  }, [selectedArray]);

  const selected = selectedRef.current;

  const { areAllSelected, areNoneSelected, renderedItems } = useMemo(() => {
    const renderedItems_ = labelFilter
      ? items.filter(textFilter(labelFilter))
      : items;

    const areAllSelected_ = renderedItems_.every((item) => {
      return selected.has(item.identifier) || item.disabled;
    });
    const areNoneSelected_ = !renderedItems_.some((item) => {
      return selected.has(item.identifier) && !item.disabled;
    });

    return {
      areAllSelected: areAllSelected_,
      areNoneSelected: areNoneSelected_,
      renderedItems: renderedItems_,
    };
  }, [items, labelFilter, selected]);

  const onPressHandler = useCallback(
    (item: KSelectableListItem<T, U>) => {
      let updatedSelection = new Set(selectedRef.current);
      const newValue = !selectedRef.current.has(item.identifier);
      if (newValue) {
        if (isMultiple) {
          updatedSelection.add(item.identifier);
        } else {
          updatedSelection = new Set([item.identifier]);
        }
      } else if (!minSelection || updatedSelection.size > minSelection) {
        updatedSelection.delete(item.identifier);
      }

      const updatedSelectionArray = [...updatedSelection];

      setSelected(updatedSelectionArray, [item], newValue);
      onSelect?.(updatedSelectionArray);
    },
    [isMultiple, minSelection, onSelect, setSelected],
  );

  const handleSelectAllPress = useCallback(() => {
    const updatedSelection = new Set(selectedRef.current);
    if (areNoneSelected) {
      for (const renderedItem of renderedItems) {
        if (!renderedItem.disabled) {
          updatedSelection.add(renderedItem.identifier);
        }
      }
    } else {
      for (const renderedItem of renderedItems) {
        if (!renderedItem.disabled) {
          updatedSelection.delete(renderedItem.identifier);
        }
      }
    }
    const updatedSelectionArray = [...updatedSelection];
    setSelected(updatedSelectionArray, renderedItems, areNoneSelected);
    onSelect?.(updatedSelectionArray);
  }, [areNoneSelected, onSelect, renderedItems, setSelected]);

  const baseRenderItem = useCallback(
    (item: KSelectableListItem<T, U>) => (
      <KSelectionListItemBasic
        item={item}
        leftIcon={leftIcon}
        noPadding={noPadding}
      />
    ),
    [leftIcon, noPadding],
  );

  const renderItem = overrideRenderItem ?? baseRenderItem;
  const RenderChecker = overrideRenderChecker ?? KSelectionListChecker<T, U>;

  const renderRow = useCallback(
    (item: KSelectableListItem<T, U>, key?: string) => {
      const isSelected = selected.has(item.identifier);

      return (
        <View key={key} style={styles.rowBackground}>
          <KSelectionListCell
            item={item}
            renderItem={renderItem}
            renderChecker={RenderChecker}
            isSelected={isSelected}
            onPressHandler={onPressHandler}
            isMultiple={isMultiple}
            disabled={disabled}
            showChecker={showChecker}
            noPadding={noPadding}
          />
        </View>
      );
    },
    [
      disabled,
      isMultiple,
      noPadding,
      onPressHandler,
      RenderChecker,
      renderItem,
      selected,
      showChecker,
    ],
  );

  const renderFlatListItem = useCallback(
    ({ item }: ListRenderItemInfo<KSelectableListItem<T, U>>) =>
      renderRow(item),
    [renderRow],
  );

  const renderSimpleListItem = useCallback(
    (item: KSelectableListItem<T, U>, index: number) =>
      renderRow(item, keyExtractor(item, index)),
    [renderRow],
  );

  const renderHeader = useMemo(() => {
    const title = t("selectAll");

    return (
      <Pressable
        accessibilityLabel={title}
        disabled={disabled}
        key="all"
        style={[
          styles.rowContainer,
          styles.rowBackground,
          !noPadding && styles.rowContainerPadding,
        ]}
        onPress={handleSelectAllPress}
      >
        <View style={[styles.item, !noPadding && styles.itemPadding]}>
          <Text numberOfLines={1} style={[styles.text, styles.textSelectAll]}>
            {t("selectAll")}
          </Text>
        </View>
        <RenderChecker
          onPress={handleSelectAllPress}
          type="all"
          selection={
            areAllSelected ? "all" : areNoneSelected ? "none" : "partial"
          }
        />
      </Pressable>
    );
  }, [
    RenderChecker,
    areAllSelected,
    areNoneSelected,
    disabled,
    handleSelectAllPress,
    noPadding,
    t,
  ]);

  const listStyle = useMemo(
    () => [
      styles.container,
      disableScroll ? undefined : styles.flatListContainer,
      style,
    ],
    [disableScroll, style],
  );

  const listHeaderComponent = useMemo(() => {
    if (isNative()) {
      return (
        <Collapsible open={Boolean(selectAll) && Boolean(isMultiple)}>
          {renderHeader}
        </Collapsible>
      );
    }
    if (selectAll && isMultiple) {
      return renderHeader;
    }

    return undefined;
  }, [isMultiple, renderHeader, selectAll]);

  const listFooterComponent = useMemo(() => {
    const hasFooterDelimiter = Boolean(footer);
    const hasFooter = hasFooterDelimiter || footer || isLoadingMore || hasError;

    return hasFooter ? (
      <>
        {hasFooterDelimiter && <View style={styles.footerDelimiter} />}
        {footer ?? null}
        {isLoadingMore && <LoadingFooter />}
        {hasError && <LoadMoreErrorFooter />}
      </>
    ) : null;
  }, [footer, hasError, isLoadingMore]);

  const flatListRef = useRef<FlatList<KSelectableListItem<T, U>>>(null);

  return (
    <>
      {renderedItems.length > 0 ? (
        disableScroll ? (
          <View style={listStyle}>
            {selectAll && isMultiple && renderHeader}
            {renderedItems.map(renderSimpleListItem)}
          </View>
        ) : (
          <FlatList
            ref={flatListRef}
            style={listStyle}
            nativeID={id}
            testID="ide2e-k-selection-list"
            data={renderedItems}
            ListHeaderComponent={listHeaderComponent}
            keyExtractor={keyExtractor}
            renderItem={renderFlatListItem}
            keyboardShouldPersistTaps={keyboardShouldPersistTaps}
            ListFooterComponent={listFooterComponent}
            onEndReached={onEndReached}
            keyboardDismissMode={keyboardDismissMode}
          />
        )
      ) : (
        <View style={listStyle} />
      )}
    </>
  );
};

export { KSelectionList };
