import { View } from "react-native";

import { SignInEmail } from "@kraaft/shared/components/auth/signInEmail";
import { DevQuickSignIn } from "@kraaft/shared/components/dev/devQuickSignin";
import { KraaftLogo } from "@kraaft/shared/components/kraaftLogo";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";

import { styles } from "./signIn.styles";

const SignInEmailWeb = () => {
  return (
    <SplitScreenWrapper>
      <View style={styles.logoContainer}>
        <KraaftLogo />
      </View>
      <SignInEmail />
      <DevQuickSignIn />
    </SplitScreenWrapper>
  );
};

export { SignInEmailWeb };
