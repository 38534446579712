import { makeStyles } from "@mui/styles";

export const ANIMATION_DURATION = 250;

export const useStyles = makeStyles({
  root: {
    transition: `all ${ANIMATION_DURATION}ms`,
    overflow: "hidden",
    position: "relative",
  },
  children: {
    position: "absolute",
    width: "100%",
  },

  contentDisabled: {
    "& *": {
      visibility: "hidden",
    },
  },
});
