import { useCallback, useContext } from "react";

import { MessageTextEditorContext } from "@kraaft/shared/components/messageTextEditor/messageTextEditorContext";
import { MessageWithText } from "@kraaft/shared/core/modules/message/messageState";

export function useEditedMessage() {
  const { selectedMessage } = useContext(MessageTextEditorContext);
  return selectedMessage;
}

export function useStartEditingMessageText(): (
  message: MessageWithText,
) => void {
  return useContext(MessageTextEditorContext).setSelectedMessage;
}

export function useStopEditingMessageText() {
  const { setSelectedMessage } = useContext(MessageTextEditorContext);
  return useCallback(() => setSelectedMessage(undefined), [setSelectedMessage]);
}
