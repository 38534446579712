import { FileMessageType } from "@kraaft/shared/core/modules/message/messageState";
import { IconName } from "@kraaft/ui";

export function getIcon(fileType: FileMessageType): IconName {
  switch (fileType) {
    case "audio":
    case "video":
      return "play";
    default:
      return "file-04";
  }
}
