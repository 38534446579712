import { put } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { AnyUnexplained } from "@kraaft/shared/core/types";

export function* removeMessage(
  action: ReturnType<typeof roomActions.removeMessageRequest>,
) {
  const { payload } = action;
  try {
    const { messageId } = payload;

    const response: { data: AnyUnexplained } = yield Api.removeMessage({
      messageId,
    });

    if (!response.data.success) {
      throw new Error(response.data.message);
    }
  } catch (err) {
    console.log("removeMessage", err);
    yield* put(showError({ title: i18n.t("errorRemovingMessage") }));
    yield* put(roomActions.removeMessageFailure(payload));
  }
}
