import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaElement } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";

export const FormBuilderDropTypes = {
  REORDER: "reorderColumn",
  INSERT: "insertElement",
} as const;

export type ReorderColumnDropItem = {
  type: typeof FormBuilderDropTypes.REORDER;
  elementKey: string;
  parentKey: string;
  elementType: KSchemaElement["elementType"];
};

export type InsertElementDropItem =
  | {
      type: typeof FormBuilderDropTypes.INSERT;
      insertType: "column";
      columnType: KColumnType;
    }
  | {
      type: typeof FormBuilderDropTypes.INSERT;
      insertType: "section";
    };

export interface FormBuilderColumnElement {
  type: "column";
  columnType: KColumnType;
}

export interface FormBuilderSectionElement {
  type: "section";
}

export type FormBuilderElement =
  | FormBuilderColumnElement
  | FormBuilderSectionElement;

export type ColumnDropItem = ReorderColumnDropItem | InsertElementDropItem;

export function isItemSection(item: ColumnDropItem) {
  return (
    (item.type === FormBuilderDropTypes.INSERT &&
      item.insertType === "section") ||
    (item.type === FormBuilderDropTypes.REORDER &&
      item.elementType === "section")
  );
}
