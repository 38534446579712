import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { SchemaActions } from "@kraaft/shared/core/modules/schema/schema.actions";
import { selectPoolFolderSchemas } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { OrderableList } from "@kraaft/web/src/components/orderableList/orderableList";
import { OrderedListRows } from "@kraaft/web/src/components/orderableList/orderableList.types";
import { prepareForOrderableList } from "@kraaft/web/src/components/orderableList/orderableList.utils";
import { OrderablePoolSchemaItem } from "@kraaft/web/src/views/schemasView/poolSchemaList/orderablePoolSchemaItem";

import { useStyles } from "./poolSchemaList.styles";

const PoolSchemaList = () => {
  const poolId = useSelector(selectCurrentPoolId);
  const schemas = useSelector(selectPoolFolderSchemas(poolId));
  const dispatch = useDispatch();

  const classes = useStyles();

  const updateSchemas = useCallback(
    (newSchemas: OrderedListRows<KFolderSchema>) => {
      dispatch(
        SchemaActions.reorderSchemas(
          Object.entries(newSchemas).map(([schemaId, schema]) => ({
            schemaId,
            index: schema.index,
          })),
        ),
      );
    },
    [dispatch],
  );

  const schemasAsObject = useMemo(
    () =>
      prepareForOrderableList(Object.values(schemas), (schema) => ({
        key: schema.id,
        index: schema.index,
        data: schema,
      })),
    [schemas],
  );

  return (
    <div className={classes.container}>
      <div className={classes.listContainer}>
        <OrderableList
          testId="ide2e-schemas-sortable-list"
          rows={schemasAsObject}
          updateRows={updateSchemas}
          RowRenderer={OrderablePoolSchemaItem}
          rowContainerClassName={classes.rowContainer}
          withHandle
        />
      </div>
    </div>
  );
};

export { PoolSchemaList };
