import { useEffect } from "react";

export function useKeyPressed(key: string, onKeyPressed: () => void) {
  useEffect(() => {
    const handlePress = (ev: KeyboardEvent) => {
      if (ev.key === key) {
        onKeyPressed();
      }
    };
    document.addEventListener("keydown", handlePress);
    return () => document.removeEventListener("keydown", handlePress);
  }, [key, onKeyPressed]);
}
