import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setMessageSelectionProperties } from "@kraaft/shared/core/modules/message/messageActions";
import { selectMessageSelectionType } from "@kraaft/shared/core/modules/message/messageSelectors";
import { MessageSelectionSource } from "@kraaft/shared/core/modules/message/messageState";

export const useSubmitSelection = (
  roomId: string,
  source: MessageSelectionSource,
) => {
  const dispatch = useDispatch();

  const selectionType = useSelector(selectMessageSelectionType(roomId, source));

  return useCallback(() => {
    if (roomId) {
      if (selectionType === "attach") {
        dispatch(
          setMessageSelectionProperties({
            roomId,
            selectionType: "attach",
            status: "inDialogFlow",
          }),
        );
      } else if (selectionType === "forward") {
        dispatch(
          setMessageSelectionProperties({
            roomId,
            selectionType: "forward",
            status: "inDialogFlow",
          }),
        );
      }
    }
  }, [dispatch, selectionType, roomId]);
};
