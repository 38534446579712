import { StyleSheet } from "react-native";

import { ColorStyle } from "@kraaft/ui";

export const styleObject = {
  headerLayout: {
    flexDirection: "row" as const,
    alignItems: "center" as const,
    justifyContent: "center" as const,
  },
  borderBottom: {
    borderBottomColor: ColorStyle.SEPARATOR,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomStyle: "solid" as const,
  },
  borderTop: {
    borderTopColor: ColorStyle.SEPARATOR,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopStyle: "solid" as const,
  },
  borderRight: {
    borderRightColor: ColorStyle.SEPARATOR,
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightStyle: "solid" as const,
  },
  borderLeft: {
    borderLeftColor: ColorStyle.SEPARATOR,
    borderLeftWidth: StyleSheet.hairlineWidth,
    borderLeftStyle: "solid" as const,
  },
};
