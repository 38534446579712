import { call, put, select } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { fileUpload } from "@kraaft/shared/core/modules/file/fileUploader";
import { LibrarySchemaStateActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import {
  LibrarySchema,
  LibrarySchemaLanguage,
} from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { setLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { generateAddReportTemplateLoaderId } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.actions.utils";
import {
  KSchemaColumn,
  KSchemaColumnDefinition,
  KSchemaIcon,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { AutoNumberingMode } from "@kraaft/shared/core/modules/schema/schema.actions";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.optimistic";
import {
  getContainerKeyAndIndexForReorder,
  optimisticDuplicateSchemaElement,
  optimisticSchemaAddColumn,
  optimisticSchemaAddSection,
  optimisticSchemaDeleteColumn,
  optimisticSchemaEditColumnDefinition,
  optimisticSchemaEditMetadata,
  optimisticSchemaEditSection,
  optimisticSchemaRename,
  optimisticSchemaRenameColumn,
  legacyOptimisticSchemaReorderElement,
} from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import {
  getIndexFromPlacement,
  KSchemaUtils,
} from "@kraaft/shared/core/modules/schema/schema.utils";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { RootState } from "@kraaft/shared/core/store";
import {
  createOperation,
  createOperationOnMultiple,
  createOptimisticReduxBundle,
} from "@kraaft/shared/core/utils/optimistic/createReduxBundle";

const selectOptimisticState = ({ librarySchema: { optimistic } }: RootState) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optimistic as any;

const selectLibrarySchemas = ({ librarySchema: { data } }: RootState) => ({
  ...data.librarySchemas,
  ...data.companyLibrarySchemas,
});

export const {
  actions: LibrarySchemaOptimisticActions,
  delaySnapshot: LibrarySchemaDelaySnapshot,
  operations: LibrarySchemaOptimisticOperations,
  reducer: LibrarySchemaOptimisticReducer,
  saga: LibrarySchemaOptimisticSaga,
  selectors: LibrarySchemaOptimisticSelector,
} = createOptimisticReduxBundle(
  "LibrarySchema",
  [
    createOperation<LibrarySchema, "edit", { update: { enabled?: boolean } }>({
      type: "edit",
      optimistic: (data, { payload }) => {
        if (payload.update.enabled !== undefined) {
          data.enabled = payload.update.enabled;
        }

        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.editLibrarySchema, {
          librarySchemaId: payload.targetId,
          update: payload.update,
        });

        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "setCompany",
      { companyId: string | undefined }
    >({
      type: "setCompany",
      optimistic(data, { payload }) {
        data.companyId = payload.companyId;
        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.setLibrarySchemaCompany, {
          librarySchemaId: payload.targetId,
          companyId: payload.companyId,
        });

        return updatedAt;
      },
    }),
    createOperationOnMultiple<
      LibrarySchema,
      "reorder",
      {
        language: LibrarySchemaLanguage;
        orderedIds: string[];
      }
    >({
      type: "reorder",
      optimistic: (data, { payload }) => {
        const newIndex = payload.orderedIds.indexOf(data.id);

        if (newIndex !== undefined) {
          data.index = newIndex;
        }

        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.reorderLibrarySchemas, payload);

        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "editPresentationDescription",
      { description: string }
    >({
      type: "editPresentationDescription",
      optimistic: (data, { payload }) => {
        data.presentation.description = payload.description;
        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(
          Api.setLibrarySchemaPresentationDescription,
          {
            librarySchemaId: payload.targetId,
            description: payload.description,
          },
        );

        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "editPresentationVideo",
      { videoUrl?: string }
    >({
      type: "editPresentationVideo",
      optimistic: (data, { payload }) => {
        data.presentation.video = payload.videoUrl
          ? {
              type: "youtube",
              url: payload.videoUrl,
              id: "optimistic",
            }
          : undefined;
        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(
          Api.setLibrarySchemaPresentationVideo,
          {
            librarySchemaId: payload.targetId,
            videoUrl: payload.videoUrl,
          },
        );

        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "editPresentationCoverPicture",
      { coverPictureFile: ModernFile<LocalPath> | null }
    >({
      type: "editPresentationCoverPicture",
      optimistic: (data, { payload }) => {
        if (payload.coverPictureFile !== null) {
          data.presentation.coverPictureUrl = payload.coverPictureFile.path;
        } else {
          data.presentation.coverPictureUrl = null;
        }

        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(
          Api.editLibrarySchemaPresentationCoverPicture,
          {
            librarySchemaId: payload.targetId,
            coverPictureFile: payload.coverPictureFile,
          },
        );

        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "editPresentationSampleReport",
      { sampleReportFile: ModernFile<LocalPath> | null }
    >({
      type: "editPresentationSampleReport",
      optimistic: (data, { payload }) => {
        if (payload.sampleReportFile !== null) {
          data.presentation.sampleReport = {
            filename: payload.sampleReportFile.filename,
            downloadUrl: payload.sampleReportFile.path,
          };
        } else {
          data.presentation.sampleReport = null;
        }

        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(
          Api.editLibrarySchemaPresentationSampleReport,
          {
            librarySchemaId: payload.targetId,
            sampleReportFile: payload.sampleReportFile,
          },
        );

        return updatedAt;
      },
    }),
    createOperation<LibrarySchema, "delete", unknown>({
      type: "delete",
      optimistic: () => {
        return null;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.deleteLibrarySchema, {
          librarySchemaId: payload.targetId,
        });

        return updatedAt;
      },
    }),

    // Schema
    createOperation<LibrarySchema, "schema.rename", { name: string }>({
      type: "schema.rename",
      optimistic: (data, { payload }) => {
        optimisticSchemaRename(data.schema, payload);
        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.editLibrarySchemaMetadata, {
          librarySchemaId: payload.targetId,
          update: {
            name: payload.name,
          },
        });
        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "schema.renameColumn",
      { columnKey: string; name: string }
    >({
      type: "schema.renameColumn",
      optimistic: (data, { payload }) => {
        optimisticSchemaRenameColumn(data.schema, payload);
        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.editLibrarySchemaColumn, {
          librarySchemaId: payload.targetId,
          columnKey: payload.columnKey,
          update: {
            name: payload.name,
          },
        });
        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "schema.reorderElement",
      { target: string; placement: HoverState; tryToMoveInsideElement: boolean }
    >({
      type: "schema.reorderElement",
      optimistic: (data, { payload }) => {
        legacyOptimisticSchemaReorderElement(data.schema, payload);

        return data;
      },
      call: function* ({ payload }, { selectBuilt }) {
        const librarySchema = yield* select(selectBuilt(payload.targetId));
        if (!librarySchema) {
          return null;
        }
        const params = getContainerKeyAndIndexForReorder(
          librarySchema.schema,
          payload,
        );
        if (!params) {
          return null;
        }
        const { updatedAt } = yield* call(Api.moveLibrarySchemaElement, {
          librarySchemaId: payload.targetId,
          key: payload.target,
          ...params,
        });
        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "schema.editSection",
      {
        key: string;
        edits: {
          name?: string;
          color?: string;
        };
      }
    >({
      type: "schema.editSection",
      optimistic: (data, { payload }) => {
        optimisticSchemaEditSection(data.schema, payload);
        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.editLibrarySchemaSection, {
          librarySchemaId: payload.targetId,
          sectionKey: payload.key,
          update: payload.edits,
        });
        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "schema.editMetadata",
      {
        name?: string;
        icon?: KSchemaIcon;
        autonumbering?: AutoNumberingMode;
      }
    >({
      type: "schema.editMetadata",
      optimistic: (data, { payload }) => {
        optimisticSchemaEditMetadata(data.schema, payload);
        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.editLibrarySchemaMetadata, {
          librarySchemaId: payload.targetId,
          update: { name: payload.name, icon: payload.icon },
        });
        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "schema.addSection",
      { section: KSchemaSection; placement: HoverState }
    >({
      type: "schema.addSection",
      optimistic: (data, { payload }) => {
        optimisticSchemaAddSection(data.schema, payload);
        return data;
      },
      call: function* ({ payload }, { selectBuilt }) {
        const librarySchema = yield* select(selectBuilt(payload.targetId));
        if (!librarySchema) {
          return null;
        }
        const { schema } = librarySchema;
        const newIndex = getIndexFromPlacement(schema, payload.placement);

        if (newIndex === undefined) {
          return null;
        }

        try {
          const { key, updatedAt } = yield* call(Api.addLibrarySchemaSection, {
            librarySchemaId: librarySchema.id,
            index:
              newIndex + (payload.placement.placement === "before" ? -1 : 0),
            name: payload.section.name,
            parentSectionKey: KSchemaUtils.rootSectionKey,
          });
          yield* put(
            LibrarySchemaStateActions.setCreatedKey({
              librarySchemaId: librarySchema.id,
              columnKey: key,
            }),
          );
          return updatedAt;
        } catch (e) {
          if (HttpError.isHttpErrorWithCode(e, "MaxColumnCountExceededError")) {
            yield* put(
              showError({
                title: i18n.t(
                  "formBuilder.schema.error.maxColumnCountExceeded",
                ),
              }),
            );
          }
          throw e;
        }
      },
    }),
    createOperation<
      LibrarySchema,
      "schema.addColumn",
      { column: KSchemaColumn; placement: HoverState; inSection?: string }
    >({
      type: "schema.addColumn",
      optimistic: (data, { payload }) => {
        optimisticSchemaAddColumn(data.schema, payload);
        return data;
      },
      call: function* ({ payload }, { selectBuilt }) {
        const librarySchema = yield* select(selectBuilt(payload.targetId));

        if (!librarySchema) {
          return null;
        }
        const { schema } = librarySchema;
        const newIndex = getIndexFromPlacement(schema, payload.placement);
        const context = KSchemaUtils.findWithContext(
          schema.rootSection,
          payload.placement.key,
        );

        if (newIndex === undefined || !context) {
          return null;
        }

        try {
          const { columnKey, updatedAt } = yield* call(
            Api.addLibrarySchemaColumn,
            {
              librarySchemaId: librarySchema.id,
              name: payload.column.name,
              definition: payload.column,
              index:
                newIndex + (payload.placement.placement === "before" ? -1 : 0),
              parentSectionKey: payload.inSection ?? context.parentSection.key,
            },
          );

          yield* put(
            LibrarySchemaStateActions.setCreatedKey({
              librarySchemaId: librarySchema.id,
              columnKey: columnKey,
            }),
          );
          return updatedAt;
        } catch (e) {
          if (HttpError.isHttpErrorWithCode(e, "MaxColumnCountExceededError")) {
            yield* put(
              showError({
                title: i18n.t(
                  "formBuilder.schema.error.maxColumnCountExceeded",
                ),
              }),
            );
          }
          throw e;
        }
      },
    }),
    createOperation<
      LibrarySchema,
      "schema.editColumnDefinition",
      { columnKey: string; definition: KSchemaColumnDefinition }
    >({
      type: "schema.editColumnDefinition",
      optimistic: (data, { payload }) => {
        optimisticSchemaEditColumnDefinition(data.schema, payload);
        return data;
      },
      call: {
        type: "latest",
        debounce: 1000,
        identify: (operation) => operation.payload.columnKey,
        callDelayed: function* ({ payload }) {
          const { updatedAt } = yield* call(Api.editLibrarySchemaColumn, {
            librarySchemaId: payload.targetId,
            columnKey: payload.columnKey,
            update: {
              definition: payload.definition,
            },
          });
          return updatedAt;
        },
      },
    }),
    createOperation<
      LibrarySchema,
      "schema.deleteColumn",
      { columnKey: string }
    >({
      type: "schema.deleteColumn",
      optimistic: (data, { payload }) => {
        optimisticSchemaDeleteColumn(data.schema, payload);
        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.deleteLibrarySchemaColumn, {
          librarySchemaId: payload.targetId,
          columnKey: payload.columnKey,
        });
        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "schema.editCondition",
      { key: string; condition: CompositeCondition | undefined }
    >({
      type: "schema.editCondition",
      optimistic: (data, { payload }) => {
        const element = KSchemaUtils.findElement(
          data.schema.rootSection,
          payload.key,
        );
        if (!element) {
          return data;
        }
        element.condition = payload.condition;
        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(
          Api.editLibrarySchemaElementCondition,
          {
            schemaId: payload.targetId,
            key: payload.key,
            condition: payload.condition,
          },
        );
        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "reportTemplates.add",
      {
        name: string;
        librarySchemaId: string;
        file: ModernFile<LocalPath>;
        forceAdd: boolean;
      }
    >({
      type: "reportTemplates.add",
      optimistic: (data, { payload }) => {
        data.reportTemplates = data.reportTemplates || [];
        data.reportTemplates.push({
          id: "temp",
          enabled: true,
          variant: "custom",
          name: payload.name,
          index: data.reportTemplates.length,
          updatedAt: new Date(),
          format: payload.file.filename.endsWith(".docx") ? "docx" : "xlsx",
          file: {
            filename: payload.name,
            downloadUrl: payload.file.path,
          },
        });
        return data;
      },
      call: function* ({ payload }) {
        const { librarySchemaId, name, file, forceAdd } = payload;

        const loaderId = generateAddReportTemplateLoaderId(librarySchemaId);

        yield* put(
          setLoader({
            id: loaderId,
            status: LoaderStatus.LOADING,
          }),
        );

        const storagePathPayload = {
          librarySchemaId,
          filename: file.filename,
        };
        const uploadPath = yield* call(
          Api.generateLibrarySchemaReportTemplateUploadPath,
          storagePathPayload,
        );

        yield* call(() =>
          fileUpload.upload({
            file,
            storagePath: uploadPath.storagePath,
            uploadUrl: uploadPath.uploadUrl,
          }),
        );

        try {
          const { updatedAt } = yield* call(
            Api.addLibrarySchemaReportTemplate,
            {
              librarySchemaId,
              name,
              storagePath: uploadPath.storagePath,
              forceAdd,
            },
          );
          yield* put(
            setLoader({
              id: loaderId,
              status: LoaderStatus.SUCCESS,
            }),
          );
          return updatedAt;
        } catch (e) {
          yield* put(
            setLoader({
              id: loaderId,
              status: LoaderStatus.FAILURE,
              error: e,
            }),
          );
          throw e;
        }
      },
    }),
    createOperation<
      LibrarySchema,
      "reportTemplates.reorder",
      {
        librarySchemaId: string;
        orderedIds: string[];
      }
    >({
      type: "reportTemplates.reorder",
      optimistic: (data, { payload }) => {
        const { orderedIds } = payload;
        console.log(
          "before",
          data.reportTemplates.map((t) => ("name" in t ? t.name : "default")),
        );
        data.reportTemplates.sort((a, b) => {
          const aIndex = orderedIds.indexOf(a.id);
          const bIndex = orderedIds.indexOf(b.id);
          return bIndex - aIndex;
        });
        console.log(
          "after",
          data.reportTemplates.map((t) => ("name" in t ? t.name : "default")),
        );
        return data;
      },
      call: function* ({ payload }) {
        const { librarySchemaId, orderedIds } = payload;

        const { updatedAt } = yield* call(
          Api.reorderLibrarySchemaReportTemplates,
          {
            librarySchemaId,
            reportTemplateIds: orderedIds,
          },
        );

        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "reportTemplates.toggle",
      {
        librarySchemaId: string;
        reportTemplateId: string;
        enabled: boolean;
      }
    >({
      type: "reportTemplates.toggle",
      optimistic: (data, { payload }) => {
        const { reportTemplateId, enabled } = payload;
        const report = data.reportTemplates.find(
          (r) => r.id === reportTemplateId,
        );

        if (report && enabled !== undefined) {
          report.enabled = enabled;
        }

        return data;
      },
      call: function* ({ payload }) {
        const { librarySchemaId, reportTemplateId, enabled } = payload;

        const { updatedAt } = yield* call(
          Api.toggleLibrarySchemaReportTemplate,
          {
            librarySchemaId,
            reportTemplateId,
            enabled,
          },
        );

        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "reportTemplates.rename",
      {
        librarySchemaId: string;
        reportTemplateId: string;
        name: string;
      }
    >({
      type: "reportTemplates.rename",
      optimistic: (data, { payload }) => {
        const { reportTemplateId } = payload;
        const report = data.reportTemplates.find(
          (r) => r.id === reportTemplateId,
        );

        if (
          report &&
          report.variant === "custom" &&
          payload.name !== undefined
        ) {
          report.name = payload.name;
        }

        return data;
      },
      call: function* ({ payload }) {
        const { librarySchemaId, reportTemplateId, name } = payload;

        const { updatedAt } = yield* call(
          Api.renameLibrarySchemaReportTemplate,
          {
            librarySchemaId,
            reportTemplateId,
            name,
          },
        );

        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "reportTemplates.replaceFile",
      {
        librarySchemaId: string;
        reportTemplateId: string;
        file: ModernFile<LocalPath>;
      }
    >({
      type: "reportTemplates.replaceFile",
      optimistic: (data, { payload }) => {
        const { reportTemplateId } = payload;
        const report = data.reportTemplates.find(
          (r) => r.id === reportTemplateId,
        );

        if (report && report.variant === "custom") {
          report.file = {
            filename: payload.file.filename,
            downloadUrl: payload.file.path,
          };
        }

        return data;
      },
      call: function* ({ payload }) {
        const { librarySchemaId, reportTemplateId, file } = payload;

        const storagePathPayload = {
          librarySchemaId,
          filename: file.filename,
        };
        const uploadPath = yield* call(
          Api.generateLibrarySchemaReportTemplateUploadPath,
          storagePathPayload,
        );

        yield* call(() =>
          fileUpload.upload({
            file,
            ...uploadPath,
          }),
        );

        const { updatedAt } = yield* call(
          Api.replaceFileLibrarySchemaReportTemplate,
          {
            librarySchemaId,
            reportTemplateId,
            storagePath: uploadPath.storagePath,
          },
        );

        return updatedAt;
      },
    }),
    createOperation<
      LibrarySchema,
      "reportTemplates.remove",
      {
        librarySchemaId: string;
        reportTemplateId: string;
      }
    >({
      type: "reportTemplates.remove",
      optimistic: (data, { payload }) => {
        const { reportTemplateId } = payload;

        data.reportTemplates = data.reportTemplates.filter(
          (r) => r.id !== reportTemplateId,
        );

        return data;
      },
      call: function* ({ payload }) {
        const { librarySchemaId, reportTemplateId } = payload;

        const { updatedAt } = yield* call(
          Api.removeLibrarySchemaReportTemplate,
          {
            librarySchemaId,
            reportTemplateId,
          },
        );

        return updatedAt;
      },
    }),

    createOperation<
      LibrarySchema,
      "schema.setHighlightedCheckbox",
      { columnKey: string | undefined }
    >({
      type: "schema.setHighlightedCheckbox",
      optimistic: (schema, { payload }) => {
        schema.schema.highlightedCheckbox = payload.columnKey;
        return schema;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(
          Api.setLibrarySchemaHighlightedCheckbox,
          {
            schemaId: payload.targetId,
            columnKey: payload.columnKey,
          },
        );

        return updatedAt;
      },
    }),
    createOperation<LibrarySchema, "schema.duplicateElement", { key: string }>({
      type: "schema.duplicateElement",
      optimistic: (data, { payload }) => {
        optimisticDuplicateSchemaElement(data.schema, payload);
        return data;
      },
      call: function* ({ payload }) {
        try {
          const { updatedAt } = yield* call(Api.duplicateLibrarySchemaElement, {
            schemaId: payload.targetId,
            key: payload.key,
          });
          return updatedAt;
        } catch (e) {
          if (HttpError.isHttpErrorWithCode(e, "MaxColumnCountExceededError")) {
            yield* put(
              showError({
                title: i18n.t(
                  "formBuilder.schema.error.maxColumnCountExceeded",
                ),
              }),
            );
          }
          throw e;
        }
      },
    }),
  ],
  (schema) => schema.id,
  LibrarySchemaStateActions.set,
  selectOptimisticState,
  selectLibrarySchemas,
);
