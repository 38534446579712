import { useMemo } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";

import { buildPoolAcronym } from "@kraaft/shared/components/conversation/header/conversationTitleUtils";
import { selectPoolAndColor } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { opacify } from "@kraaft/shared/core/utils/colorsUtils";
import { Color, FontSize, Icon, IconSize, Text } from "@kraaft/ui";

import { styles } from "./poolLogo.styles";

type Props = {
  id?: string;
  poolId: string;
  variant?: "large" | "small";
  withExternalVariant?: boolean;
  showNotificationDot?: boolean;
};

export const PoolLogo = ({
  withExternalVariant,
  poolId,
  showNotificationDot,
  id,
  variant = "large",
}: Props) => {
  const { pool, color } = useSelector(selectPoolAndColor(poolId));
  const poolAcronym = useMemo(
    () => pool?.name && buildPoolAcronym(pool.name),
    [pool?.name],
  );

  const containerStyle = useMemo(
    () => [
      styles.containerBase,
      variant === "small" ? styles.containerSmall : styles.containerLarge,
      { backgroundColor: color },
    ],
    [variant, color],
  );

  const textStyle = useMemo(
    () => [variant === "small" ? styles.textSmall : styles.textLarge],
    [variant],
  );

  const circleStyle = useMemo(
    () => [
      styles.circleBase,
      variant === "small" ? styles.circleSmall : styles.circleLarge,
    ],
    [variant],
  );

  const iconSize = variant === "small" ? IconSize.SMALL : IconSize.MEDIUM;
  const fontSize = variant === "small" ? FontSize.SMALL : FontSize.BODY;

  return (
    <View nativeID={id} style={containerStyle}>
      {withExternalVariant ? (
        <Icon
          testID="ide2e-external-pool-logo"
          name="shake-hands"
          size={iconSize}
          color={opacify(Color.ORANGE_BASIC, 0.15)}
        />
      ) : (
        <Text weight="medium" color="WHITE" size={fontSize} style={textStyle}>
          {poolAcronym}
        </Text>
      )}
      {showNotificationDot && <View style={circleStyle} />}
    </View>
  );
};
