import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
    marginBottom: Spacing.S8,
  },
  part: {
    flex: 1,
  },
  operator: {
    width: 80,
  },
  operatorText: {
    display: "block",
    marginLeft: Spacing.S8,
  },
});
