import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import {
  MessageTypes,
  utils as messageUtils,
} from "@kraaft/shared/core/modules/message";
import { canRemoveMessage } from "@kraaft/shared/core/modules/message/messageUtils";
import { deleteOptimisticMessage } from "@kraaft/shared/core/modules/message/send/sendMessageActions";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentUserAccountOwner } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import {
  removeMessageRequest,
  superadminDeleteMessageRequest,
} from "@kraaft/shared/core/modules/room/roomActions";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { isUserSuperadmin } from "@kraaft/shared/core/modules/user/userUtils";

interface UseRemoveMessageProps {
  roomId: string;
}

export const useRemoveMessage = ({ roomId }: UseRemoveMessageProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSuperadmin = isUserSuperadmin(useSelector(selectCurrentUser));
  const poolId = useSelector(selectCurrentPoolId);
  const isAccountOwner = useSelector(selectCurrentUserAccountOwner(poolId));

  const removeMessage = useCallback(
    (message: MessageTypes.Message) => {
      if (!messageUtils.isPersisted(message)) {
        dispatch(deleteOptimisticMessage({ roomId, optimisticId: message.id }));
      } else if (canRemoveMessage(message, isSuperadmin, isAccountOwner)) {
        return newConfirmationAlert({
          title: t("removeMessageAlertTitle"),
          message: t("removeMessageAlertContent"),
          confirmText: t("delete"),
          onConfirm: () =>
            dispatch(
              removeMessageRequest({
                messageId: message.id,
              }),
            ),
        });
      }
    },
    [t, dispatch, roomId, isSuperadmin, isAccountOwner],
  );

  const removeMessageSuperadmin = useCallback(
    (message: MessageTypes.Message) =>
      newConfirmationAlert({
        title: t("removeMessageAlertTitle"),
        message: t("removeMessageSuperadminAlertContent"),
        confirmText: t("delete"),
        onConfirm: () =>
          dispatch(
            superadminDeleteMessageRequest({
              messageId: message.id,
            }),
          ),
      }),
    [t, dispatch],
  );

  return { removeMessage, removeMessageSuperadmin };
};
