import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    paddingTop: Spacing.S24,
    paddingLeft: Spacing.S32,
    paddingRight: Spacing.S32,
    maxWidth: 700,
  },
  section: {
    marginBottom: Spacing.S32,
    "& > :first-child": {
      display: "block",
      marginBottom: Spacing.S16,
    },
  },
  rowContainer: {
    padding: `${Spacing.S12 / 2}px 0`,
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: Spacing.S16,
  },
});
