import moment from "moment";

import isEmpty from "lodash/isEmpty";

import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";

export const normalizeText = normalizeTextForSearch;

export const valueIsEmpty = (value: unknown) => isEmpty(value);

export const dateIsEqual = (lhs: Date | undefined, rhs: Date | undefined) =>
  lhs === undefined ? false : moment(lhs).isSame(rhs, "date");

export const dateIsBefore = (lhs: Date | undefined, rhs: Date | undefined) =>
  lhs === undefined ? false : moment(lhs).isBefore(rhs, "date");

export const dateIsAfter = (lhs: Date | undefined, rhs: Date | undefined) =>
  lhs === undefined ? false : moment(lhs).isAfter(rhs, "date");

export const dateIsEqualOrBefore = (
  lhs: Date | undefined,
  rhs: Date | undefined,
) => (lhs === undefined ? false : moment(lhs).isSameOrBefore(rhs, "date"));

export const dateIsEqualOrAfter = (
  lhs: Date | undefined,
  rhs: Date | undefined,
) => (lhs === undefined ? false : moment(lhs).isSameOrAfter(rhs, "date"));
