import { useMemo } from "react";

import { ModularFolderMarker } from "@kraaft/shared/components/mapController/markers/modularFolderMarker/modularFolderMarker";
import { MapMarker } from "@kraaft/shared/components/mapController/types";
import {
  formatGeolocatedModularFolders,
  getGeolocatedModularFolders,
} from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import {
  ModularFolder,
  ModularFolderWithGeolocation,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";

interface UseModularFoldersMarkersProps {
  modularFolders: ModularFolder[];
  onMarkerAction: (modularFolderId: string, schemaId?: string) => void;
}

export const useModularFoldersMarkers = ({
  modularFolders,
  onMarkerAction,
}: UseModularFoldersMarkersProps) => {
  const modularFoldersWithCoords = useMemo(() => {
    const geolocatedModularFolders =
      getGeolocatedModularFolders(modularFolders);

    return formatGeolocatedModularFolders(geolocatedModularFolders);
  }, [modularFolders]);

  const markers = useMemo(
    () =>
      modularFoldersWithCoords.map<
        MapMarker<ModularFolderWithGeolocation, "folder">
      >((geolocatedElement) => ({
        key: geolocatedElement.id,
        element: geolocatedElement,
        clusterAffiliation: "folder",
        renderType: "small",

        Marker: (
          <ModularFolderMarker
            onMarkerAction={onMarkerAction}
            geolocatedElement={geolocatedElement}
          />
        ),
      })),
    [modularFoldersWithCoords, onMarkerAction],
  );

  return markers;
};
