import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize } from "@kraaft/ui";

export const useStyles = makeStyles({
  text: {
    fontSize: FontSize.MEDIUM,
    lineHeight: "17 / 14",
    fontWeight: "normal",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    whiteSpace: "pre-line",
    marginBottom: 20,
  },
});
