import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { CompanyDropdown } from "@kraaft/shared/core/modules/company/components/companyDropdown";
import { ManageCompaniesSheet } from "@kraaft/shared/core/modules/company/components/manageCompanies.sheet";
import { Button, Spacing, Text } from "@kraaft/ui";

interface EditLibrarySchemaCompanyProps {
  companyId: string | undefined;
  onChangeCompany: (companyId: string | undefined) => void;
}

export const EditLibrarySchemaCompany = ({
  companyId,
  onChangeCompany,
}: EditLibrarySchemaCompanyProps) => {
  const { t } = useTranslation();
  const { open, element } = ManageCompaniesSheet.use({});

  return (
    <>
      <div>
        <Text size="H2" weight="bold" style={styles.textSpacer}>
          {t("support.company")}
        </Text>
        <View style={styles.filter}>
          <CompanyDropdown
            placeholder={t("unassigned")}
            onChange={onChangeCompany}
            value={companyId}
          />
          <Button
            style={styles.manage}
            text={t("support.manageCompanies")}
            onPress={open}
            size="SMALL"
            variant="SECONDARY"
          />
        </View>
      </div>
      {element}
    </>
  );
};

const styles = StyleSheet.create({
  textSpacer: {
    display: "flex",
    marginBottom: Spacing.S16,
  },
  filter: {
    maxWidth: 400,
    flexDirection: "row",
    gap: Spacing.S8,
  },
  manage: {
    flexShrink: 0,
  },
});
