import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { setPoolByName } from "@kraaft/shared/core/modules/pool/poolActions";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button } from "@kraaft/ui";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { useNavigationService } from "@kraaft/web/src/core/services/navigation/useNavigationService";
import { PoolSuggestions } from "@kraaft/web/src/views/settings/backOffice/poolSuggestions";
import { CreatePoolDialog } from "@kraaft/web/src/views/settings/createPoolDialog";
import { useSettingsStyles } from "@kraaft/web/src/views/settings/settings.styles";

import { useStyles } from "./backOffice.styles";

const BackOffice = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigationService = useNavigationService();
  const settingsClasses = useSettingsStyles();
  const classes = useStyles();

  const [isCreatePoolDialogOpen, openCreatePoolDialog, closeCreatePoolDialog] =
    useBooleanState(false);

  const headerActions = useMemo(
    () => (
      <Button
        key="superadminCreatePool"
        text={t("superadminCreatePool")}
        onPress={openCreatePoolDialog}
        variant="PRIMARY"
      />
    ),
    [openCreatePoolDialog, t],
  );

  const goToPool = useCallback(
    (poolName: string) => {
      dispatch(setPoolByName({ poolName }));
      navigationService.navigate("Home");
    },
    [dispatch, navigationService],
  );

  return (
    <div className={settingsClasses.pageContainer}>
      <PageHeader title={t("settingsSuperadmin")} right={headerActions} />
      <div className={classes.panelsContainer}>
        <div className={classes.sectionContainer}>
          <div className={classes.sectionTitle}>{t("support.managePool")}</div>
          <PoolSuggestions
            onPoolSelected={goToPool}
            alwaysRenderSuggestions
            focusInputOnSuggestionClick
          />
        </div>
      </div>
      <CreatePoolDialog
        open={isCreatePoolDialogOpen}
        onClose={closeCreatePoolDialog}
      />
    </div>
  );
};

export { BackOffice };
