import { isNative } from "@kraaft/helper-functions";
import { SectionSpacer } from "@kraaft/shared/components/conversationDetails/sectionSpacer";
import { DocumentSection } from "@kraaft/shared/components/conversationDetails/worksiteFolder/documentSection";
import { PhotoSection } from "@kraaft/shared/components/conversationDetails/worksiteFolder/photoSection";
import { SchemaListSection } from "@kraaft/shared/components/conversationDetails/worksiteFolder/schemaListSection/schemaListSection";

type WorksiteFolderContentProps = {
  roomId: string;
};

const WorksiteFolderContent = ({ roomId }: WorksiteFolderContentProps) => {
  return (
    <>
      {!isNative() ? <PhotoSection roomId={roomId} /> : null}

      <SectionSpacer />

      <DocumentSection roomId={roomId} />

      <SectionSpacer />

      <SchemaListSection roomId={roomId} />
    </>
  );
};

export { WorksiteFolderContent };
