import { StyleProp, TextStyle } from "react-native";

import { SearchResult } from "@kraaft/shared/components/conversation/searchConversationContext";
import { PartitionTextView } from "@kraaft/shared/components/textInputWithMentions/partitionTextView";
import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { Text } from "@kraaft/ui";

interface SearchTextResultProps {
  result: SearchResult;
  text: InputPartition[] | undefined;
  style?: StyleProp<TextStyle>;
}

export const SearchTextResult = ({
  result,
  text,
  style,
}: SearchTextResultProps) => {
  if (!text) {
    return null;
  }

  return (
    <Text style={style}>
      <PartitionTextView partitions={text} black highlight={result.matches} />
    </Text>
  );
};
