import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, PixelSize, Radius, Spacing } from "@kraaft/ui";

import type { DropdownVariant } from "./kDropdownButton";

interface MakeStylesProps {
  variant: DropdownVariant;
}

export const useStyles = makeStyles({
  cellContainer: {
    display: "flex",
    height: PixelSize.S32,
    width: "100%",
    overflow: "hidden",
  },
  container: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: ({ variant }: MakeStylesProps) =>
      variant === "dark" ? ColorStyle.BACKGROUND_STANDARD : Color.WHITE,
    padding: "4px 8px",
    borderRadius: Radius.SMALL,
    "&:hover": {
      backgroundColor: ColorStyle.ACTION_HOVERED,
    },
  },
  disabledContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    padding: "0 8px",
    borderRadius: Radius.SMALL,
  },
  containerSelected: {
    backgroundColor: Color.BLUE_COOL,
    "&:hover": {
      backgroundColor: Color.BLUE_COOL,
    },
  },
  valueContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    width: 0,
  },
  textContainer: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    minHeight: 1,
  },
  icon: {
    flex: 1,
    marginLeft: Spacing.S8,
  },
});
