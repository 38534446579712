import { useRef } from "react";
import { useSelector } from "react-redux";

import {
  selectRoom,
  selectRoomUserHistory,
} from "@kraaft/shared/core/modules/room/selectors";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { usePrevious } from "@kraaft/shared/core/utils/hooks";

export function useLastReadMessageReady(roomId: string, visible: boolean) {
  const room = useSelector(selectRoom(roomId));
  const currentUserId = useSelector(selectCurrentUserId);
  const userRoom = useSelector(selectRoomUserHistory(room?.id ?? ""));
  const lastReadMessageId = useRef<string | undefined>(
    userRoom?.lastReadMessageId,
  );
  const lastVisible = usePrevious(visible);
  const triggerNewUnreadBanner = visible && !lastVisible;

  if (userRoom && (!lastReadMessageId.current || triggerNewUnreadBanner)) {
    lastReadMessageId.current = userRoom.lastReadMessageId;
  }

  const ready =
    room &&
    currentUserId &&
    ((currentUserId in room.members && userRoom) ||
      !(currentUserId in room.members));

  return { ready, lastReadMessageId: lastReadMessageId.current };
}
