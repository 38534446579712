import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { useValidateNewDirectoryName } from "@kraaft/shared/components/directory/useDirectoryValidation";
import { KInput, KInputHandle } from "@kraaft/shared/components/input/KInput";
import { dismissNativeKeyboard } from "@kraaft/shared/core/utils/platformUtils";
import { Button, Sheet } from "@kraaft/ui";

export interface DirectoryNameDialogProps {
  open: boolean;
  roomId: string;
  parentId: string;
  currentName?: string;
  initialValue: string;
  onValueChange: (value: string) => void;
  loading: boolean;
  onApply: () => void;
  validateActionLabel: string;
}

export const DirectoryNameSheet = Sheet({
  native: "bottom",
  web: "popup",
})
  .create<DirectoryNameDialogProps>(
    ({ Paper, Header, Content, Footer }) =>
      ({
        roomId,
        parentId,
        initialValue,
        onValueChange,
        loading,
        validateActionLabel,
        onApply,
        onClose,
      }) => {
        const inputRef = useRef<KInputHandle>(null);
        const { t } = useTranslation();

        const [value, setValue] = useState(initialValue);

        useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        const newDirectoryNameIsValid = useValidateNewDirectoryName({
          roomId,
          parentId,
          newName: value,
          currentName: initialValue,
        });

        const disabled = !(
          value &&
          value.length > 0 &&
          newDirectoryNameIsValid
        );

        const shouldInputDisplayInvalidName =
          (value?.length ?? 0) > 0 && !newDirectoryNameIsValid;

        useEffect(() => {
          setTimeout(
            () => inputRef.current?.focus(),
            Platform.select({ default: 0, android: 100 }),
          );
        }, []);

        const internOnClose = useCallback(() => {
          dismissNativeKeyboard();
          setValue(initialValue);
          onClose();
        }, [initialValue, onClose]);

        const handleEnterPressed = useCallback(() => {
          if (disabled) {
            return;
          }
          onApply();
        }, [disabled, onApply]);

        const handleValueChange = useCallback(
          (text: string) => {
            setValue(text);
            onValueChange(text);
          },
          [onValueChange],
        );

        return (
          <Paper>
            <Header onClose={onClose}>{t("directoryActions.new")}</Header>
            <Content>
              <View style={styles.dialogContentContainer}>
                <KInput
                  label={t("directoryActions.directoryTitle")}
                  value={value}
                  onChangeText={handleValueChange}
                  ref={inputRef}
                  error={shouldInputDisplayInvalidName}
                  helperText={
                    shouldInputDisplayInvalidName
                      ? t("directory.alreadyExists", {
                          directoryName: value,
                        })
                      : undefined
                  }
                  onSubmitEditing={handleEnterPressed}
                />
              </View>
            </Content>
            <Footer>
              <Button
                accessibilityLabel={t("cancel")}
                text={t("cancel")}
                onPress={internOnClose}
                variant="SECONDARY"
              />
              <Button
                accessibilityLabel={validateActionLabel}
                text={validateActionLabel}
                onPress={onApply}
                variant="PRIMARY"
                disabled={disabled}
                loading={loading}
              />
            </Footer>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "SMALL" });

const styles = StyleSheet.create({
  dialogContentContainer: {
    flex: 1,
  },
});
