import { createReducer } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { kizeoFormReducers } from "@kraaft/shared/core/modules/form/kizeoFormReducers";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import * as actions from "./formActions";
import { FormState } from "./formState";

const initialState: FormState = {};

const formReducers = createReducer(initialState, ({ addCase }) => {
  addCase(actions.subscribeToForms, (state, { payload }) => {
    delete state[payload.poolId];
  });

  addCase(actions.subscribeToFormsSnapshot, (state, { payload }) => {
    state[payload.poolId] = payload.forms;
  });

  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);
});

const combined = combineReducers({
  forms: formReducers,
  candidateForms: kizeoFormReducers,
});
export { combined as formReducers };
