import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useInviteCollaboratorsTrackingEvent } from "@kraaft/shared/components/inviteCollaborators/useInviteCollaboratorsTrackingEvent";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { useTrackEventOnceConditionnally } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import {
  PoolInvitationTargetParams,
  RoomInvitationTargetParams,
} from "@kraaft/shared/core/utils/useInviteLinkMessageConfigGenerator/useInviteLinkMessageConfigGenerator.types";
import { getInviteLinkForTarget } from "@kraaft/shared/core/utils/useInviteLinkMessageConfigGenerator/useInviteLinkMessageConfigGenerator.utils";
import { InviteDialogLayout } from "@kraaft/web/src/components/kDialog/customs/inviteDialogLayout/inviteDialogLayout";

type InviteCollaboratorDialogProps = {
  open: boolean;
  onClose: () => void;
  pool: Pool;
  // Providing room will invite to pool and room
  room?: Room;
  invitationTargetParams:
    | PoolInvitationTargetParams
    | RoomInvitationTargetParams;
};

const InviteCollaboratorDialog = ({
  open,
  onClose,
  pool,
  room,
  invitationTargetParams,
}: InviteCollaboratorDialogProps) => {
  const { t } = useTranslation();

  const trackingEvent = useInviteCollaboratorsTrackingEvent(
    "copy_link",
    invitationTargetParams,
  );

  useTrackEventOnceConditionnally(trackingEvent, open);

  const [link, setLink] = useState<string | null>(null);

  useEffect(() => {
    async function getLink() {
      const linkResult = await (room
        ? getInviteLinkForTarget({ type: "room", roomId: room.id })
        : getInviteLinkForTarget({ type: "pool", poolId: pool.id }));

      setLink(linkResult);
    }

    if (open) {
      getLink().catch(console.error);
    }
  }, [open, pool.id, room]);

  return (
    <InviteDialogLayout
      open={open}
      onClose={onClose}
      title={t("shareInviteCollaborators.title")}
      text={t("shareInviteCollaborators.content", {
        poolName: pool.name,
      })}
      link={link}
      inputLabel={t("shareInviteCollaborators.linkLabel", {
        poolName: pool.name,
      })}
    />
  );
};

export { InviteCollaboratorDialog };
