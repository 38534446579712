import React, { useCallback, useMemo } from "react";
import { TouchableOpacity, View } from "react-native";

import { Hoverable } from "@kraaft/shared/components/hoverable";
import { HoverableRenderProps } from "@kraaft/shared/components/hoverable/types";
import { Color, ColorStyle, Icon, IconName, Text } from "@kraaft/ui";

import { styles } from "./actionCard.styles";

interface Props {
  id?: string;
  disabled?: boolean;
  text: string;
  iconName?: IconName;
  description?: string;
  descriptionColor?: string;
  content?: React.ReactNode;
  showBracket?: boolean;
  color?: string;
  forceNoColor?: boolean;
  action?: () => void;
  onLongPress?: () => void;
  testID?: string;
  greyedWhenDisabled?: boolean;
  backgroundColor?: string;
}

const ActionCard_ = (props: Props) => {
  const {
    id,
    disabled,
    text,
    iconName,
    description,
    content,
    showBracket,
    color,
    forceNoColor,
    action,
    descriptionColor,
    testID,
    greyedWhenDisabled,
    onLongPress,
    backgroundColor = Color.WHITE,
  } = props;

  const textColor =
    disabled && greyedWhenDisabled
      ? ColorStyle.FONT_LOW_EMPHASIS
      : color || ColorStyle.FONT_HIGH_EMPHASIS;
  const textStyle = useMemo(
    () => [styles.titleText, { color: textColor }],
    [textColor],
  );
  const _descriptionColor = descriptionColor
    ? descriptionColor
    : ColorStyle.FONT_LOW_EMPHASIS;

  const innerContent = useMemo(
    () => (
      <>
        <View style={styles.content}>
          <View style={styles.title}>
            {iconName ? (
              <Icon
                name={iconName}
                size="MEDIUM"
                color={forceNoColor ? undefined : textColor}
              />
            ) : null}
            <Text style={textStyle} numberOfLines={1}>
              {text}
            </Text>
          </View>

          <View style={styles.description}>
            {content || (
              <Text
                style={[styles.descriptionText, { color: _descriptionColor }]}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {description}
              </Text>
            )}
          </View>
        </View>
        {showBracket && (
          <View style={styles.bracket}>
            <Icon
              name="chevron-right"
              size="MEDIUM"
              color="FONT_LOW_EMPHASIS"
            />
          </View>
        )}
      </>
    ),
    [
      iconName,
      forceNoColor,
      textColor,
      textStyle,
      text,
      content,
      _descriptionColor,
      description,
      showBracket,
    ],
  );

  const renderContent = useCallback(
    ({ hoverProps, isHovered }: HoverableRenderProps): JSX.Element => (
      <TouchableOpacity
        accessibilityLabel={text}
        style={styles.touchableItem}
        onLongPress={onLongPress}
        disabled={disabled}
        onPress={action}
        testID={testID}
      >
        <View
          nativeID={id}
          testID="ide2e-action-card"
          {...hoverProps}
          style={[
            styles.group,
            { backgroundColor },
            !disabled && isHovered && { backgroundColor: Color.BLUE_LAVENDER },
          ]}
        >
          {innerContent}
        </View>
      </TouchableOpacity>
    ),
    [
      disabled,
      action,
      testID,
      id,
      backgroundColor,
      onLongPress,
      text,
      innerContent,
    ],
  );

  return <Hoverable>{renderContent}</Hoverable>;
};

export const ActionCard = React.memo(ActionCard_);
