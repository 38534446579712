import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { compact } from "lodash";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  selectPoolFolderSchemas,
  selectRoomSchema,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { compareStrings } from "@kraaft/shared/core/utils";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { useHandleChangeSingleSelectHelper } from "@kraaft/web/src/components/dropdown/kDropdownUtils";

interface WorkflowSchemaSelectorProps {
  schemaId: string | undefined;
  setSchema: (schemaId: string) => void;
}

export const WorkflowSchemaSelector = ({
  schemaId,
  setSchema,
}: WorkflowSchemaSelectorProps) => {
  const poolId = useSelector(selectCurrentPoolId) || "";
  const roomSchema = useSelector(selectRoomSchema(poolId));
  const folderSchemas = useSelector(selectPoolFolderSchemas(poolId));
  const { t } = useTranslation();

  const schemas = useMemo(
    () => compact([roomSchema, ...folderSchemas]),
    [folderSchemas, roomSchema],
  );

  const handleSelectionChange = useHandleChangeSingleSelectHelper(
    (newSchemaId: string | undefined) => {
      if (newSchemaId) {
        setSchema(newSchemaId);
      }
    },
    [setSchema],
  );

  const schemaItems = useMemo<FilteredListItem[]>(() => {
    const result: FilteredListItem[] = schemas
      .sort((a, b) => compareStrings(a.name, b.name))
      .map((schema) => ({
        label: schema.name,
        value: schema.id,
        icon: KSchemaUtils.getSchemaIconName(schema.icon),
      }));
    return result;
  }, [schemas]);

  useEffect(() => {
    const firstSet = schemaItems[0]?.value;

    if (!schemaId && firstSet) {
      setSchema(firstSet);
    }
    // We need to tell the parent to set the default schema at first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <KDropdown
      items={schemaItems}
      selectedItemIds={schemaId ? [schemaId] : undefined}
      onSelectionChange={handleSelectionChange}
      variant="dark"
      fullWidth
      placeholder={t("workflow.pickDataSource")}
    />
  );
};
