import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";

import { ModularFoldersTableViewForRoom } from "./modularFoldersTableViewForRoom";
import { ModularFoldersTableViewForSchema } from "./modularFoldersTableViewForSchema";

interface Props {
  schema: KFolderSchema;
  roomId?: string;
  schemaViewId?: string;
}

const ModularFoldersTableView = ({ schema, roomId, schemaViewId }: Props) => {
  if (roomId) {
    return (
      <ModularFoldersTableViewForRoom
        schema={schema}
        roomId={roomId}
        schemaViewId={schemaViewId}
      />
    );
  }
  return (
    <ModularFoldersTableViewForSchema
      schema={schema}
      schemaViewId={schemaViewId}
    />
  );
};

export { ModularFoldersTableView };
