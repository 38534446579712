import { useCallback, useEffect, useRef, useState } from "react";

// Timeout returns types are different between React-Native/Browser and Node.
type Timeout = ReturnType<typeof setTimeout>;

export function useDebounceFunction<T extends unknown[]>(
  fn: (...params: T) => void,
  delay = 500,
) {
  const timeout = useRef<Timeout>();

  return useCallback(
    (...params: T) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => fn(...params), delay);
    },
    [delay, fn],
  );
}

export function useDebounce<T>(value: T, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler: Timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
