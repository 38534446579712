import { useTranslation } from "react-i18next";

import { CheckboxGuardSheet } from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuardSheet";
import { Text } from "@kraaft/ui";

interface CantLockUnlockParentLockedProps {
  onClose: () => void;
  sectionName: string;
  modularDetailTitle: string;
  variant: "cantUnlockParentLocked" | "cantLockParentLocked";
  isInRoom: boolean;
}

export const CantLockUnlockParentLocked = ({
  onClose,
  sectionName,
  modularDetailTitle,
  variant,
  isInRoom,
}: CantLockUnlockParentLockedProps) => {
  const { t } = useTranslation();

  const title = t(
    variant === "cantUnlockParentLocked"
      ? "modularRecords.unlockAnswers"
      : "modularRecords.lockAnswers",
  );

  const text = t(
    `modularRecords.checkboxGuard.${isInRoom ? "inRoom" : "inFolders"}.${
      variant === "cantUnlockParentLocked"
        ? "cantUnlockSectionParentLocked"
        : "cantLockSectionParentLocked"
    }`,
    {
      sectionName,
      modularDetailTitle,
    },
  );

  return (
    <CheckboxGuardSheet.Paper>
      <CheckboxGuardSheet.Header onClose={onClose}>
        {title}
      </CheckboxGuardSheet.Header>
      <CheckboxGuardSheet.Content>
        <Text size="BODY">{text}</Text>
      </CheckboxGuardSheet.Content>
    </CheckboxGuardSheet.Paper>
  );
};
