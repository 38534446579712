import type firebase from "firebase/compat/app";

import { isNative } from "@kraaft/helper-functions";
import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";

export type User = { id: string; email?: string };

interface SSOProvider {
  id: string;
  offlineAccess: boolean;
}

export abstract class FirebaseSDK {
  constructor(private readonly auth: typeof firebase.auth) {
    this.init();
    if (getEnvironment().FIREBASE.ENABLE_AUTH_TESTING) {
      this.enableAuthTesting();
    }
  }

  abstract init(): void;
  abstract enableAuthTesting(): void;

  getCurrentUser() {
    return this.auth().currentUser;
  }

  onIdTokenChanged(fn: (token: null | { providerId: string }) => void) {
    return this.auth().onIdTokenChanged(async (user) => {
      const result = await user?.getIdTokenResult();
      result?.signInProvider;
      if (result?.signInProvider) {
        return fn({ providerId: result.signInProvider });
      }
      return fn(null);
    });
  }

  getLoginMethods(email: string) {
    return this.auth().fetchSignInMethodsForEmail(email);
  }

  onAuthStateChanged(fn: (user: User | null) => void) {
    return this.auth().onAuthStateChanged((user) => {
      if (user) {
        return fn({
          id: user.uid,
          email: user.email || undefined,
        });
      }
      return fn(null);
    });
  }

  async triggerSSOLinking(providerInfo: SSOProvider) {
    const user = this.auth().currentUser;

    if (!user) {
      return;
    }

    const provider = new this.auth.OAuthProvider(providerInfo.id);
    if (providerInfo.offlineAccess) {
      provider.addScope("offline_access");
    }

    provider.setCustomParameters(this.buildOauthCustomParameters(user.email));

    const creds = await user.linkWithPopup(provider);
    if (creds.credential) {
      await user.reauthenticateWithCredential(creds.credential);
    }
  }

  async triggerSSOLogin(providerInfo: SSOProvider, suggestedEmail?: string) {
    const provider = new this.auth.OAuthProvider(providerInfo.id);
    if (providerInfo.offlineAccess) {
      provider.addScope("offline_access");
    }

    provider.setCustomParameters(
      this.buildOauthCustomParameters(suggestedEmail),
    );

    await this.auth().signInWithPopup(provider);
  }

  private buildOauthCustomParameters(email: string | null | undefined) {
    return isNative()
      ? {
          login_hint: email,
          prompt: "select_account", // let the user change his account
        }
      : {
          login_hint: email,
        };
  }
}
