import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { FileMessage } from "@kraaft/shared/core/modules/message/messageState";
import { Api } from "@kraaft/shared/core/services/api";
import { getFilenameFromStoragePath } from "@kraaft/shared/core/utils";

export async function downloadMultiple(
  roomId: string,
  messageIds: string[],
  type: FileMessage["type"] | undefined,
) {
  const { downloadUrl, storagePath } = await Api.downloadFileMessages({
    roomId,
    messageIds,
    type,
  });
  await fileSaver.download(
    downloadUrl,
    getFilenameFromStoragePath(storagePath),
  );
}
