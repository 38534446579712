export enum LoaderStatus {
  LOADING = "loading",
  SUCCESS = "success",
  FAILURE = "failure",
}

interface LoaderBase {
  id: string;
}

interface LoaderSimple extends LoaderBase {
  status: LoaderStatus.LOADING | LoaderStatus.SUCCESS;
}

export interface LoaderFailure extends LoaderBase {
  status: LoaderStatus.FAILURE;
  error: Error;
}

export type LoaderConfig = LoaderSimple | LoaderFailure;

export type Loader = LoaderConfig & {
  loadingCounter?: number;
};

export interface LoaderMeta {
  loader: LoaderConfig;
}
