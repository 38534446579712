import { StyleSheet } from "react-native";

export const CHECKBOX_PADDING = 4;

export const styles = StyleSheet.create({
  check: {
    position: "absolute",
    bottom: CHECKBOX_PADDING,
    left: CHECKBOX_PADDING,
  },
});
