import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { DifferenceBackground } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceBackground";
import { DifferenceMethod } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/types";
import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { Spacing, Text } from "@kraaft/ui";

interface ListAttachmentDifferenceProps {
  method: DifferenceMethod;
  attachments: Attachment[];
}

export const ListAttachmentDifference = ({
  method,
  attachments,
}: ListAttachmentDifferenceProps) => {
  const content = useMemo(() => {
    return attachments.map((attachment) => {
      return (
        <Text key={attachment.id} size="BODY">
          {attachment.original.filename}
        </Text>
      );
    });
  }, [attachments]);

  return content !== undefined ? (
    <DifferenceBackground method={method}>
      <View style={styles.container}>{content}</View>
    </DifferenceBackground>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: Spacing.S4,
  },
});
