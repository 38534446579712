import { useTranslation } from "react-i18next";

import { Button } from "@kraaft/ui";

import { useStyles } from "./expandCellWrapper.styles";

interface Props {
  children: React.ReactNode;
  onExpandClick: () => void;
}

const ExpandCellWrapper = ({ children, onExpandClick }: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>{children}</div>
      <div className={classes.iconContainer}>
        <Button
          accessibilityLabel={t("expand")}
          icon="maximize-01"
          onPress={onExpandClick}
          variant="TERTIARY"
          size="SMALL"
        />
      </div>
    </div>
  );
};

export { ExpandCellWrapper };
