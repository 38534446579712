import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { isNative, nanoid, opacify } from "@kraaft/helper-functions";
import {
  useModularFoldersSelection,
  useOnChangeModularFoldersSelectionType,
} from "@kraaft/shared/components/modularFolders/modularFoldersSelection.context";
import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import {
  DownloadReportFormat,
  getReportTemplateName,
} from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.utils";
import { selectSchemaName } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { Api } from "@kraaft/shared/core/services/api";
import { useAuth } from "@kraaft/shared/core/services/auth/useAuth";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { isEmailValid } from "@kraaft/shared/core/utils/utils";
import {
  Button,
  Color,
  Radius,
  Sheet,
  Spacing,
  Text,
  TextInput,
} from "@kraaft/ui";

interface Props {
  roomId: string;
  schemaId: string;
  reportTemplate: ReportTemplate;
  format: DownloadReportFormat;
}

export const ModularFolderBulkExportSheet = Sheet({
  web: "popup",
  native: "bottom",
}).create<Props>(
  ({ Content, Footer, Header, Paper }) =>
    ({ roomId, schemaId, reportTemplate, format, onClose }) => {
      const { t } = useTranslation();
      const dispatch = useDispatch();

      const schemaName = useSelector(selectSchemaName(schemaId));

      const auth = useAuth();
      const [email, setEmail] = useState(auth?.email || "");

      const [isGenerating, setGenerating] = useState(false);
      const selectedIds = useModularFoldersSelection();
      const onChangeSelectionType = useOnChangeModularFoldersSelectionType();

      const generateReport = async () => {
        setGenerating(true);
        try {
          trackEvent({
            eventName: "Generate Bulk Folder Report",
            schema_id: schemaId,
            schema_name: schemaName ?? "",
            template_id: reportTemplate.id,
            template_name: getReportTemplateName(reportTemplate),
            folder_count: selectedIds.size,
            format,
          });
          await Api.bulkExportModularFoldersInRoom({
            requestId: nanoid(),
            destinationEmail: email,
            modularFolderIds: [...selectedIds],
            roomId,
            schemaId: schemaId,
            templateId: reportTemplate.id,
            asPdf: format === "pdf",
          });

          onChangeSelectionType("none");
          dispatch(
            showSuccess({
              title: t("generateReportSendSuccessTitle"),
              message: t("generateReportSendSuccess"),
            }),
          );
          onClose();
        } catch (e) {
          dispatch(showError({ title: t("errorServer"), message: e.message }));
        }
        setGenerating(false);
      };

      return (
        <Paper>
          <Header onClose={onClose}>
            {t("modularFolderBulkExport.sheet.title")}
          </Header>
          <Content>
            <View style={styles.content}>
              <Text size="BODY">
                {t("modularFolderBulkExport.sheet.content")}
              </Text>
              <TextInput
                placeholder={t("modularFolderBulkExport.sheet.emailLabel")}
                accessibilityLabel={t(
                  "modularFolderBulkExport.sheet.emailLabel",
                )}
                value={email}
                onChangeText={setEmail}
              />
              <View style={styles.warningBox}>
                <Text size="BODY">
                  {t("modularFolderBulkExport.sheet.contentAfter")}
                </Text>
              </View>
            </View>
          </Content>
          <Footer>
            <Button
              text={t("validate")}
              onPress={generateReport}
              disabled={!isEmailValid(email)}
              loading={isGenerating}
              style={isNative() ? { width: "100%" } : undefined} // ideally this case should be handled by the Footer component in the design system
            />
          </Footer>
        </Paper>
      );
    },
);

const styles = StyleSheet.create({
  content: {
    gap: Spacing.S8,
  },
  warningBox: {
    borderColor: Color.ORANGE_BASIC,
    borderWidth: 1,
    borderRadius: Radius.SMALL,
    backgroundColor: opacify(Color.ORANGE_BASIC, 0.1),
    padding: Spacing.S12,
  },
});
