import { StyleSheet } from "react-native";

import { styleObject } from "@kraaft/shared/styles/panel.styles";
import { ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  footerMessageSelected: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 16,
    paddingVertical: Spacing.S16,
    paddingHorizontal: Spacing.S24,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    ...styleObject.borderTop,
  },
});
