import { createAction } from "@reduxjs/toolkit";

import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import {
  actionUpdateLoaderMetaFailure,
  actionUpdateLoaderMetaSuccess,
  createLoaderMeta,
} from "@kraaft/shared/core/modules/loaders/loaderUtils";
import { generateAddMapOverlayLoaderId } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.actions.utils";

import { MapOverlay } from "./mapOverlay.state";

export const ADD_MAP_OVERLAY_LOADER_ID = "add-map-overlay-loader-id";

export interface MapOverlayUpdatableValues {
  name?: string;
}

export const MapOverlayActions = {
  subscribe: createAction<{ poolId: string }>("@mapOverlay/subscribe"),
  unsubscribe: createAction<{ poolId: string }>("@mapOverlay/unsubscribe"),

  addMapOverlay: createAction(
    "@mapOverlay/addMapOverlay",
    (payload: { poolId: string; name: string; file: File }) => ({
      payload,
      meta: createLoaderMeta({
        id: generateAddMapOverlayLoaderId(payload.poolId),
        status: LoaderStatus.LOADING,
      }),
    }),
  ),
  addMapOverlaySuccess: createAction(
    "@mapOverlay/addMapOverlaySuccess",
    actionUpdateLoaderMetaSuccess,
  ),
  addMapOverlayFailure: createAction(
    "@mapOverlay/addMapOverlayFailure",
    actionUpdateLoaderMetaFailure,
  ),
};

export const MapOverlayStateActions = {
  set: createAction<{ data: MapOverlay[] }>("@mapOverlay/state/set"),

  toggleMapOverlay: createAction<{ poolId: string; overlayId: string }>(
    "@mapOverlay/state/toggleMapOverlay",
  ),
  setEnabledMapOverlays: createAction<{
    poolId: string;
    enabledOverlayIds: string[];
  }>("@mapOverlay/state/setEnabledMapOverlays"),
};
