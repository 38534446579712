import { createReducer } from "@reduxjs/toolkit";

import { ModularFolderHistoryState } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.state";
import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import { ModularFolderHistoryStateActions } from "./modularFolderHistory.actions";

const initialState: ModularFolderHistoryState = {
  histories: {},
};

export const modularFolderHistoryReducers = createReducer(
  initialState,
  ({ addCase }) => {
    addCase(ModularFolderHistoryStateActions.set, (state, { payload }) => {
      state.histories[payload.modularFolderId] = {
        data: payload.modularFolderHistoryEntries,
        schemaElementWithPaths: payload.schemaElementWithPaths,
        isLoading: false,
        hasFetched: true,
      };
    });

    addCase(
      ModularFolderHistoryStateActions.setLoading,
      (state, { payload }) => {
        const history = state.histories[payload.modularFolderId];

        if (history !== undefined) {
          history.isLoading = true;
        } else {
          state.histories[payload.modularFolderId] = {
            data: [],
            schemaElementWithPaths: {},
            isLoading: true,
            hasFetched: false,
          };
        }
      },
    );

    addCase(UserActions.userDisconnectedFromFirebase, () => initialState);
    addCase(PoolStateActions.setPoolLocation, () => initialState);
  },
);
