import { useCallback, useRef } from "react";
import clsx from "clsx";

import {
  folderSchemaIcons,
  KSchemaIcon,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Icon } from "@kraaft/ui";
import { PopupMenu } from "@kraaft/web/src/components/popupMenu";

import { useStyles } from "./iconChooser.styles";

interface IconChooserProps {
  className?: string;
  value: KSchemaIcon;
  onChange: (newValue: KSchemaIcon) => void;
  testIdConfirmIconButton?: string;
  disabled?: boolean;
}

export const IconChooser = ({
  value,
  onChange,
  className,
  disabled,
}: IconChooserProps) => {
  const styles = useStyles();
  const [isOpen, open, close] = useBooleanState();
  const rootRef = useRef(null);

  const internOnChange = useCallback(
    (icon: KSchemaIcon) => {
      onChange(icon);
      close();
    },
    [close, onChange],
  );

  return (
    <>
      <button
        disabled={disabled}
        ref={rootRef}
        className={clsx("no-button", styles.root, className)}
        onClick={open}
      >
        <Icon
          size="LARGE"
          name={KSchemaUtils.getSchemaIconName(value)}
          className={styles.icon}
        />
      </button>
      <PopupMenu open={isOpen} onClose={close} anchorRef={rootRef}>
        <div>
          <div className={styles.icons}>
            {folderSchemaIcons.map((icon, index) => (
              <div
                key={icon}
                onClick={() => internOnChange(icon)}
                className={styles.basePopupIcon}
              >
                <Icon
                  key={icon}
                  className={styles.iconItem}
                  name={KSchemaUtils.schemaIconsMap[icon]}
                  size="MEDIUM"
                  testID={`ide2e-choose-icon-${index}`}
                />
              </div>
            ))}
          </div>
        </div>
      </PopupMenu>
    </>
  );
};
