import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ListRenderItem, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { useMeshContext } from "@kraaft/helper-hooks";
import {
  DisplayableSearchResult,
  SearchConversationContext,
  SearchResult,
} from "@kraaft/shared/components/conversation/searchConversationContext";
import { MessageSearchResult } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageSearchResult";
import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { FlatListWithEmptyState } from "@kraaft/shared/components/emptyState/lists/flatList";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { MessageListContext } from "@kraaft/shared/components/replyPreview/replyHooks";
import { SearchBar } from "@kraaft/shared/components/searchBar/searchBar";
import { TrackedPressable } from "@kraaft/shared/components/tracking/trackOnPressComponents";
import { selectRoom } from "@kraaft/shared/core/modules/room/roomSelectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { alpha } from "@kraaft/shared/core/utils/colorsUtils";
import { wait } from "@kraaft/shared/core/utils/promiseUtils";
import { withDependencyWrapper } from "@kraaft/shared/core/utils/withDepedencyWrapper";
import {
  Color,
  ColorStyle,
  KeyboardAvoidingView,
  PaddedSafeAreaView,
  PixelSize,
  Preloader,
  Spacing,
  Text,
} from "@kraaft/ui";

interface SearchPanelProps {
  roomId: string;
  /** injected */
  poolId: string;
}

function displayableSearchResultKeyExtractor(
  searchResult: DisplayableSearchResult,
) {
  return searchResult.message.id;
}

const Separator = () => <View style={styles.separator} />;

const MAX_SEARCH_COUNT = 100;

const SearchScreen_ = ({ roomId, poolId }: SearchPanelProps) => {
  const [goingToMessage, setGoingToMessage] = useState(false);
  const navigationService = useNavigationService();
  const { t } = useTranslation();
  const {
    setResult,
    query,
    setQuery,
    results,
    executeSearch,
    fetching,
    reset,
    setSearching,
    hasSearched,
  } = useMeshContext(SearchConversationContext);
  const { messageListHandle, highlightMessage } =
    useMeshContext(MessageListContext);

  const handleClose = useCallback(
    (shouldReset: boolean) => {
      setSearching(false);
      if (shouldReset) {
        reset(false);
      }
    },
    [reset, setSearching],
  );

  const handleClickOnMessage = useCallback(
    async (clickedResult: SearchResult) => {
      if (isNative()) {
        navigationService.navigate("ConversationMessenger", {
          roomId,
        });
      }
      setGoingToMessage(true);
      await messageListHandle.current?.scrollToMessage(clickedResult.id);
      setResult(clickedResult);
      handleClose(false);
      await wait(500);
      highlightMessage(clickedResult.id);
      setGoingToMessage(false);
    },
    [
      handleClose,
      highlightMessage,
      messageListHandle,
      navigationService,
      roomId,
      setResult,
    ],
  );

  const renderSearchResult = useCallback<
    ListRenderItem<DisplayableSearchResult>
  >(
    ({ item }) => {
      return (
        <TrackedPressable
          getTrackingEvent={() => ({
            eventName: "Click on Search Result",
            room_id: roomId,
          })}
          accessibilityLabel="search result"
          onPress={() => handleClickOnMessage(item.searchResult)}
        >
          <MessageSearchResult
            poolId={poolId}
            message={item.message}
            searchResult={item.searchResult}
          />
        </TrackedPressable>
      );
    },
    [handleClickOnMessage, poolId, roomId],
  );

  return (
    <PaddedSafeAreaView style={styles.root} bottomPadding={Spacing.S16}>
      <DefaultLayoutHeader
        disableSafeAreaBehavior
        header={
          <View style={styles.grow}>
            <SearchBar
              autoFocus={results.length === 0}
              onChange={setQuery}
              value={query}
              placeholder={t("search")}
              isStatic
              onSubmit={executeSearch}
              onClearSearch={() => reset(false)}
              clearSearchShouldFocus
            />
          </View>
        }
        headerLeft={
          <HeaderSide>
            <HeaderIconButton
              accessibilityLabel={t("close")}
              icon="chevron-left"
              onPress={() => handleClose(false)}
            />
          </HeaderSide>
        }
        headerOverflow
      />
      <KeyboardAvoidingView
        style={styles.kav}
        keyboardVerticalOffset={-Spacing.S16}
      >
        {fetching ? (
          <Preloader absoluteFill backgroundColor="transparent" />
        ) : (
          <>
            {hasSearched ? (
              <Text
                size="BODY"
                color="FONT_HIGH_EMPHASIS"
                style={styles.resultCount}
              >
                {results.length === MAX_SEARCH_COUNT
                  ? t("searchMessages.refine", { count: results.length })
                  : t("searchMessages.results", { count: results.length })}
              </Text>
            ) : null}
            <FlatListWithEmptyState
              style={styles.list}
              data={results}
              keyboardShouldPersistTaps="handled"
              keyboardDismissMode="on-drag"
              keyExtractor={displayableSearchResultKeyExtractor}
              renderItem={renderSearchResult}
              contentContainerStyle={styles.contentContainer}
              ItemSeparatorComponent={Separator}
              isEmptyStateEnabled={!hasSearched}
              type="search"
            />
          </>
        )}
        {goingToMessage ? (
          <Preloader absoluteFill backgroundColor={alpha(Color.BLACK, 0.2)} />
        ) : null}
      </KeyboardAvoidingView>
    </PaddedSafeAreaView>
  );
};

export const SearchScreen = React.memo(
  withDependencyWrapper(SearchScreen_, ({ roomId }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const room = useSelector(selectRoom(roomId));

    if (room === undefined) {
      return undefined;
    }

    return { poolId: room.poolId };
  }),
);

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    flexBasis: 0,
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
  },
  kav: {
    flexGrow: 1,
    flexShrink: 1,
  },
  list: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  contentContainer: {
    paddingBottom: Spacing.S12,
    paddingHorizontal: Spacing.S12,
  },
  separator: {
    height: PixelSize.S8,
  },
  resultCount: {
    marginVertical: Spacing.S12,
    marginLeft: Spacing.S12,
  },
});
