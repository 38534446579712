import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import { TFunction } from "@kraaft/shared/core/services/i18next";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";

import connected from "./assets/connected.png";

interface Props {
  i18nPrefix: "msStorage.success" | "msStorage.failure";
}

export const SharepointResult = ({ i18nPrefix }: Props) => {
  const lng = useQueryString().get("lng") ?? "en-GB";

  const { t } = useTranslation(undefined, { lng });

  const classes = useStyles();

  useEffect(() => {
    document.title = t(`${i18nPrefix}.title`);
    setTimeout(window.close, 5000);
  }, [i18nPrefix, t]);

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.panel}>
          <div className={classes.h1}>{t(`${i18nPrefix}.header`)}</div>
          <div className={classes.h3}>{t(`${i18nPrefix}.body`)}</div>
        </div>
        <div className={classes.panel}>
          <img src={connected} width="100%" />
        </div>
      </div>
      <div className={classes.backup}>
        <Trans
          i18nKey="msStorage.footer"
          t={t as unknown as TFunction}
          components={{
            close: <a href="#" onClick={window.close} />,
          }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
  row: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    alignContent: "flex-start",
    maxWidth: "80%",
  },
  panel: {
    flex: "0 1 400px",
    minWidth: 280,
  },
  backup: {
    padding: 20,
    fontSize: FontSize.MEDIUM,
    color: ColorStyle.FONT_LOW_EMPHASIS,
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    maxWidth: "60%",
    textAlign: "center",
  },
  h1: {
    fontSize: 40,
    fontWeight: 700,
    color: Color.BLUE_SAVOY,
    margin: 0,
    marginBottom: Spacing.S16,
  },
  h3: {
    fontSize: 25,
    fontWeight: 700,
  },
});
