import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ImportDocumentMethodSheetProps } from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/importDocumentMethodSheet/importDocumentMethodSheet";
import { ActionSheetContent, ActionSheetItem } from "@kraaft/ui";

type ImportImageSheetContentProps = ImportDocumentMethodSheetProps;

export const ImportDocumentMethodSheetContent = ({
  handleDocumentFromConversation,
  handleImageFromConversation,
  onClose,
  onPickDocument,
}: ImportImageSheetContentProps) => {
  const { t } = useTranslation();

  const handleDocumentFromConversationAndClose = useCallback(() => {
    onClose();
    handleDocumentFromConversation();
  }, [handleDocumentFromConversation, onClose]);

  const handleImageFromConversationAndClose = useCallback(() => {
    onClose();
    handleImageFromConversation();
  }, [handleImageFromConversation, onClose]);

  const handleImageFromComputerAndClose = useCallback(() => {
    onClose();
    onPickDocument();
  }, [onClose, onPickDocument]);

  return (
    <ActionSheetContent>
      <ActionSheetItem
        icon="file-06"
        onClick={handleDocumentFromConversationAndClose}
        label={t("directoryActions.addFromDocumentGallery")}
      />
      <ActionSheetItem
        icon="image-03"
        label={t("directoryActions.addFromPhotoGallery")}
        onClick={handleImageFromConversationAndClose}
      />
      <ActionSheetItem
        icon="share-01"
        onClick={handleImageFromComputerAndClose}
        label={t("directoryActions.addFromComputer")}
      />
    </ActionSheetContent>
  );
};
