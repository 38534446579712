import { StyleSheet } from "react-native";

import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  screen: {
    flexGrow: 1,
    backgroundColor: Color.WHITE,
  },

  safeAreaView: {
    flexGrow: 1,
    backgroundColor: Color.WHITE,
    flexBasis: 0,
  },

  container: {
    flex: 1,
    backgroundColor: Color.WHITE,
  },
  listContainer: {
    flex: 1,
  },
  emptyList: {
    flex: 1,
    fontFamily: "Apercu",
    fontWeight: "500",
    fontSize: 16,
    color: ColorStyle.FONT_LOW_EMPHASIS,
    textAlign: "center",
    marginVertical: Spacing.S16,
  },
  buttonContainer: {
    padding: Spacing.S16,
    justifyContent: "flex-end",
  },
});
