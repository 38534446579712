import { TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";

import { PrivateConversation } from "@kraaft/shared/components/privateConversation/privateConversation";
import { isRoomPrivate } from "@kraaft/shared/components/roomCard/roomCardUtils";
import { selectRoom } from "@kraaft/shared/core/modules/room/roomSelectors";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { IconSize, Text } from "@kraaft/ui";

import { styles } from "./conversationTitle.styles";

interface Props {
  roomId: string;
  onPress?: () => void;
}

const ConversationTitle = (props: Props) => {
  const { roomId, onPress } = props;

  const room = useSelector(selectRoom(roomId));

  if (room === undefined) {
    return null;
  }

  const isPrivate = isRoomPrivate(room);
  const title = getExistingRoomRecordField(room.record.properties, "title", "");

  return (
    <TouchableOpacity
      accessibilityLabel={title}
      style={styles.container}
      onPress={onPress}
      disabled={!onPress}
      activeOpacity={onPress ? undefined : 1}
    >
      {isPrivate && <PrivateConversation iconSize={IconSize.MEDIUM} />}
      <View style={styles.roomInfo}>
        <Text
          weight="medium"
          numberOfLines={1}
          ellipsizeMode="tail"
          style={styles.title}
        >
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export { ConversationTitle };
