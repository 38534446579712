import { KraaftLogo } from "@kraaft/shared/components/kraaftLogo";
import { AskUserMail } from "@kraaft/shared/components/onboarding/askUserMail";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";

const AskUserMailWeb = () => {
  return (
    <SplitScreenWrapper headerLeft={<KraaftLogo />}>
      <AskUserMail />
    </SplitScreenWrapper>
  );
};

export { AskUserMailWeb };
