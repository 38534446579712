import { makeStyles } from "@mui/styles";

import { Color, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    padding: Spacing.S12,
    backgroundColor: Color.WHITE,
    borderRadius: Radius.SMALL,
  },
});
