import { useTranslation } from "react-i18next";

import { isNative } from "@kraaft/helper-functions";
import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { RoomSearchHeader } from "@kraaft/shared/components/headerHomeContainer/roomSearchHeader";

type Props = {
  headerLeftTop?: React.ReactNode;
  headerRightTop?: React.ReactNode;
  headerRight?: React.ReactNode;
};

const HeaderHomeContainer = (props: Props) => {
  const { headerLeftTop, headerRightTop, headerRight } = props;
  const { t } = useTranslation();

  return (
    <>
      {isNative() && (
        <DefaultLayoutHeader
          noDivider
          noFixedHeight
          headerTitle={t("menuHome")}
          headerLeft={headerLeftTop}
          headerRight={headerRightTop}
        />
      )}
      <RoomSearchHeader headerRight={headerRight} />
    </>
  );
};

export { HeaderHomeContainer };
