import { LocalPath } from "@kraaft/shared/core/modules/file/file";
import { ImageSize } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { Size } from "@kraaft/shared/core/types";

export type ImageQuality = "hd" | "standard";

export const imageQualityDimension = {
  standard: {
    height: 1600,
    width: 1600,
  },
  hd: {
    width: 4032,
    height: 4032,
  },
} satisfies { [x in ImageQuality]: ImageSize };

export abstract class ImageHelper {
  /**
   * Gets the size of an image given a local path.
   */
  abstract size(path: LocalPath): Promise<Size>;
  /**
   * Resizes an image to a new LocalPath. After that, both path exists,
   * the original image, and the resized one.
   */
  abstract resize(path: LocalPath, quality: ImageQuality): Promise<LocalPath>;

  /**
   * Get the {@link ImageQuality} of an image
   */
  abstract getQuality(path: LocalPath): Promise<ImageQuality>;

  getQualityFromDimension(dimensions: {
    width: number;
    height: number;
  }): ImageQuality {
    if (
      dimensions.width > imageQualityDimension.standard.width ||
      dimensions.height > imageQualityDimension.standard.height
    ) {
      return "hd";
    }
    return "standard";
  }
  getDimensionForQuality(quality: ImageQuality) {
    return imageQualityDimension[quality];
  }
}
