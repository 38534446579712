import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  content: {
    width: "100%",
  },
  text: {
    marginBottom: Spacing.S24,
  },
});
