import React, { useCallback, useMemo, useState } from "react";

import { HoverableProps } from "@kraaft/shared/components/hoverable/types";

const Hoverable_ = ({ children, onHoverIn, onHoverOut }: HoverableProps) => {
  const [isHovered, setHovered] = useState<boolean>(false);

  const handleMouseEnter = useCallback(() => {
    onHoverIn?.();
    setHovered(true);
  }, [setHovered, onHoverIn]);

  const handleMouseLeave = useCallback(() => {
    onHoverOut?.();
    setHovered(false);
  }, [setHovered, onHoverOut]);

  const hoverProps = useMemo(
    () => ({
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    }),
    [handleMouseEnter, handleMouseLeave],
  );

  return children({
    ...hoverProps,
    hoverProps,
    isHovered,
  });
};

export const Hoverable = React.memo(Hoverable_);
