import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";
import { simpleClipboardTextFromCell } from "@kraaft/web/src/components/modularTable/components/table/clipboardTextFromCellUtils";
import { TextEditor } from "@kraaft/web/src/components/modularTable/components/table/editors/textEditor";
import { stringRecordComparator } from "@kraaft/web/src/components/modularTable/components/table/recordComparatorUtils";
import { simpleValueFromClipboardText } from "@kraaft/web/src/components/modularTable/components/table/valueFromClipboardTextUtils";
import { IFieldConfig } from "@kraaft/web/src/components/modularTable/components/types";

import { BasicCell } from "../_basicCell/basicCell";
import { withBasicCell } from "../_withTableCell/withBasicCell";
export const shortTextConfig: IFieldConfig<KColumnType.shortText> = {
  type: KColumnType.shortText,
  TableCell: withBasicCell(
    BasicCell as React.FC<IBasicCellProps<KColumnType.shortText>>,
  ),
  TableEditor: TextEditor,
  comparator: stringRecordComparator,
  getClipboardTextFromCell: simpleClipboardTextFromCell,
  getValueFromClipboardText: simpleValueFromClipboardText,
};

export const automatedAutoIcrementConfig: IFieldConfig<KColumnType.automatedAutoIncrement> =
  {
    type: KColumnType.automatedAutoIncrement,
    TableCell: withBasicCell(
      BasicCell as React.FC<
        IBasicCellProps<KColumnType.automatedAutoIncrement>
      >,
    ),
    TableEditor: TextEditor,
    comparator: stringRecordComparator,
    getClipboardTextFromCell: simpleClipboardTextFromCell,
    getValueFromClipboardText: simpleValueFromClipboardText,
  };
