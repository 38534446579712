import { diffChars } from "diff";

import { Span } from "@kraaft/shared/core/framework/markedText/span";

export type Difference = {
  length: number;
  value: string;
  added: boolean | undefined;
  removed: boolean | undefined;
};

/**
 *
 * the idea here is that we want to isolate the part where we need to call `diffChars` function
 * before and after `selectionHint` characters are not supposed to changed that's why this is the limit
 * basically we iterate from the start of both strings until they are not matching anymore
 * and after that we do the same from the end.
 *
 * @example
 * findSliceIndexes("Hello World", "Harr World", new Span(1, 5)) // selection is "ello"
 *
 * // "H"     "ello"       " World"
 * //   ^                  ^
 * //   startIndex         originalEndIndex
 *
 * // "H"     "arr"        " World"
 * //   ^                  ^
 * //   startIndex         incomingEndIndex
 */
function findSliceIndexes(
  originalText: string,
  incomingText: string,
  selectionHint: Span,
) {
  let possibleStartIndex = 0;
  let originalEndIndex = originalText.length;
  let incomingEndIndex = incomingText.length;

  while (
    possibleStartIndex < selectionHint.start &&
    possibleStartIndex < originalText.length &&
    originalText[possibleStartIndex] === incomingText[possibleStartIndex]
  ) {
    possibleStartIndex += 1;
  }

  while (
    originalEndIndex > selectionHint.end &&
    originalText[originalEndIndex - 1] === incomingText[incomingEndIndex - 1]
  ) {
    originalEndIndex -= 1;
    incomingEndIndex -= 1;
  }

  const realStartIndex = Math.min(possibleStartIndex, incomingEndIndex);

  return { startIndex: realStartIndex, originalEndIndex, incomingEndIndex };
}

/**
 *
 * returns a list of segments that highlights the differences between two strings,
 * we specify `selectionHint` to better identify where is the new text in case of similar strings.
 *
 * @example
 * 01: diffText("Hello", "Hello World", new Span(5, 5)) // selection is after the "o" of "Hello"
 * // [{ length: 5, value: "Hello", added: false, removed: false },
 * //  { length: 6, value: " World", added: true, removed: false }]
 *
 * 02: diffText("Hello", "HelloHello", new Span(5, 5)) // selection is after the "o" of "Hello"
 * // [{ length: 5, value: "Hello", added: false, removed: false },
 * //  { length: 5, value: "Hello", added: true, removed: false }]
 *
 * 03: diffText("Hello", "HelloHello", new Span(0, 0)) // selection is before the "H" of "Hello"
 * // [{ length: 5, value: "Hello", added: true, removed: false },
 * //  { length: 5, value: "Hello", added: false, removed: false }]
 *
 * @note
 * the different result between 02 and 03 is due to the `selectionHint`
 */
export function diffText(
  originalText: string,
  incomingText: string,
  selectionHint: Span,
): Difference[] {
  const { startIndex, originalEndIndex, incomingEndIndex } = findSliceIndexes(
    originalText,
    incomingText,
    selectionHint,
  );

  const differences: Difference[] = [];

  if (startIndex > 0) {
    differences.push({
      length: startIndex,
      added: false,
      removed: false,
      value: originalText.slice(0, startIndex),
    });
  }

  const innerOriginalText = originalText.slice(startIndex, originalEndIndex);
  const innerIncomingText = incomingText.slice(startIndex, incomingEndIndex);

  if (innerOriginalText || innerIncomingText) {
    differences.push(
      ...diffChars(innerOriginalText, innerIncomingText).map((change) => ({
        length: change.count ?? 0,
        added: change.added,
        removed: change.removed,
        value: change.value,
      })),
    );
  }

  if (
    originalEndIndex < originalText.length ||
    incomingEndIndex < incomingText.length
  ) {
    differences.push({
      length: originalText.length - originalEndIndex,
      added: false,
      removed: false,
      value: originalText.slice(originalEndIndex),
    });
  }

  return differences;
}
