import {
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { GooglePlaceData } from "react-native-google-places-autocomplete";
import isEqual from "fast-deep-equal";
import GoogleMapReact from "google-map-react";

import { OpacitySkeleton } from "@kraaft/shared/components/opacitySkeleton";
import { ReactComponent as MapSvg } from "@kraaft/shared/components/placeSelection/assets/mapSkeleton.svg";
import { ReactComponent as MarkerSvg } from "@kraaft/shared/components/placeSelection/assets/markerSkeleton.svg";
import { CenterMarker } from "@kraaft/shared/components/placeSelection/centerMarker";
import {
  MapDisplayerHandle,
  MapDisplayerProps,
} from "@kraaft/shared/components/placeSelection/mapDisplayer/mapDisplayerProps";
import {
  MARKER_HEIGHT,
  MARKER_WIDTH,
} from "@kraaft/shared/components/placeSelection/placeSelection.styles";
import {
  getAddressWithOptionalData,
  getDeviceTimeZoneGeoLocation,
} from "@kraaft/shared/components/placeSelection/placeSelectionUtils";
import { FRANCE_COORDINATES } from "@kraaft/shared/constants/constants";
import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { i18n } from "@kraaft/shared/core/services/i18next/i18next";
import { GeoCoordinates, GeoLocation } from "@kraaft/shared/core/types";
import { usePrevious } from "@kraaft/shared/core/utils/hooks";
import { MapLayout } from "@kraaft/web/src/components/geolocation/types";

import { useStyles } from "./mapDisplayer.styles";

const getCurrentPosition = () => {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};

const DEFAULT_ZOOM = 17;
const DEFAULT_TIMEZONE_ZOOM = 6;

const MapDisplayer_ = forwardRef(
  (
    { setLocation, location, centerMarker, isEditing }: MapDisplayerProps,
    ref: ForwardedRef<MapDisplayerHandle>,
  ) => {
    const classes = useStyles();
    const [language, region] = i18n.language.split("-");

    useImperativeHandle(ref, () => ({
      focusOnLocation: (value) => {
        if (value) {
          lockPin();
          setCurrentLayout(
            (layout) =>
              layout && {
                ...layout,
                center: {
                  lat: value.coords.latitude,
                  lng: value.coords.longitude,
                },
                zoom: Math.max(layout.zoom, DEFAULT_ZOOM),
              },
          );
        }
      },
    }));

    const [defaultMapLayout, setDefaultMapLayout] = useState<
      MapLayout | undefined
    >(
      location && {
        center: {
          lat: location?.coords.latitude,
          lng: location?.coords.longitude,
        },
        zoom: DEFAULT_ZOOM,
      },
    );

    const hasDefaultMapLayoutRef = useRef(false);
    hasDefaultMapLayoutRef.current = defaultMapLayout !== undefined;

    const [currentLayout, setCurrentLayout] = useState<MapLayout | undefined>();

    const lockedPin = useRef(false);
    const lockPin = useCallback(() => {
      lockedPin.current = true;
      setTimeout(() => {
        lockedPin.current = false;
      }, 1000);
    }, []);

    const containerRef = useRef<HTMLDivElement>(null);

    const setAddressFromGeoCoordinates = useCallback(
      async (newGeoCoordinates: GeoCoordinates, data?: GooglePlaceData) => {
        const newGeolocation: GeoLocation = {
          coords: {
            latitude: newGeoCoordinates.latitude,
            longitude: newGeoCoordinates.longitude,
          },
        };

        newGeolocation.address = await getAddressWithOptionalData(
          newGeoCoordinates,
          data,
        );

        setLocation(newGeolocation);

        lockPin();
      },
      [lockPin, setLocation],
    );

    useEffect(() => {
      if (!hasDefaultMapLayoutRef.current) {
        setDefaultMapLayout(
          location && {
            center: {
              lat: location?.coords.latitude,
              lng: location?.coords.longitude,
            },
            zoom: DEFAULT_ZOOM,
          },
        );
      }
    }, [location]);

    useEffect(() => {
      async function setDevicePosition() {
        try {
          const currentPosition = await getCurrentPosition();
          const deviceGeoCoordinates: GeoCoordinates = {
            latitude: currentPosition.coords.latitude,
            longitude: currentPosition.coords.longitude,
          };
          if (!hasDefaultMapLayoutRef.current) {
            await setAddressFromGeoCoordinates(deviceGeoCoordinates);
            setDefaultMapLayout({
              center: {
                lat: currentPosition.coords.latitude,
                lng: currentPosition.coords.longitude,
              },
              zoom: DEFAULT_ZOOM,
            });
          }
        } catch (error) {
          const timeZoneGeoLocation = await getDeviceTimeZoneGeoLocation();
          if (timeZoneGeoLocation) {
            if (!hasDefaultMapLayoutRef.current) {
              setDefaultMapLayout({
                center: {
                  lat: timeZoneGeoLocation.coords.latitude,
                  lng: timeZoneGeoLocation.coords.longitude,
                },
                zoom: DEFAULT_TIMEZONE_ZOOM,
              });
              return;
            }
          }

          // fallback
          if (!hasDefaultMapLayoutRef.current) {
            setDefaultMapLayout({
              center: {
                lat: FRANCE_COORDINATES.latitude,
                lng: FRANCE_COORDINATES.longitude,
              },
              zoom: DEFAULT_TIMEZONE_ZOOM,
            });
          }
        }
      }
      if (!defaultMapLayout) {
        setDevicePosition().catch(console.error);
      }
    }, [defaultMapLayout, setAddressFromGeoCoordinates, setLocation]);

    const onMapChange = useCallback(
      async (value: GoogleMapReact.ChangeEventValue) => {
        setCurrentLayout({ zoom: value.zoom, center: value.center });
        if (isEditing && !lockedPin.current) {
          const newGeoCoordinates = {
            latitude: value.center.lat,
            longitude: value.center.lng,
          };
          await setAddressFromGeoCoordinates(newGeoCoordinates);
        }
      },
      [isEditing, setAddressFromGeoCoordinates],
    );

    const wasEditing = usePrevious(isEditing);
    const previousLocation = usePrevious(location);

    useEffect(() => {
      // changing edition mode -> go back to initial region
      if (wasEditing !== isEditing) {
        if (location) {
          lockPin();
          setCurrentLayout(
            (layout) =>
              layout && {
                ...layout,
                center: {
                  lat: location.coords.latitude,
                  lng: location.coords.longitude,
                },
              },
          );
        }
      }
    }, [
      location,
      isEditing,
      wasEditing,
      region,
      lockPin,
      previousLocation?.coords,
      currentLayout,
    ]);

    return (
      <>
        {defaultMapLayout ? (
          <div className={classes.mapContainer} ref={containerRef}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: getEnvironment().GOOGLE.WEB_ONLY_MAPS_KEY,
                language,
                region,
              }}
              onChange={onMapChange}
              debounced={true}
              yesIWantToUseGoogleMapApiInternals
              hoverDistance={1}
              defaultCenter={defaultMapLayout?.center}
              defaultZoom={defaultMapLayout?.zoom}
              center={currentLayout?.center}
              zoom={currentLayout?.zoom}
              options={{
                zoomControl: true,
                fullscreenControl: false,
                mapTypeId: "hybrid",
              }}
            >
              {location && !isEditing && (
                <LocationMarker
                  lat={location?.coords.latitude}
                  lng={location?.coords.longitude}
                />
              )}
            </GoogleMapReact>
            {isEditing && (
              <div className={classes.editingMarker}>
                {centerMarker ?? <CenterMarker color="GREY" />}
              </div>
            )}
          </div>
        ) : (
          <OpacitySkeleton grow>
            <MapSvg width="100%" height="100%" preserveAspectRatio="none" />
            <div className={classes.editingMarker}>
              <MarkerSvg width={MARKER_WIDTH} height={MARKER_HEIGHT} />
            </div>
          </OpacitySkeleton>
        )}
      </>
    );
  },
);

const LocationMarker = (_props: GoogleMapReact.Coords) => {
  const classes = useStyles();
  return (
    <div className={classes.locationMarker}>
      <CenterMarker />
    </div>
  );
};

export const MapDisplayer = memo(MapDisplayer_, isEqual);
