import { StyleSheet } from "react-native";
import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

const SLIDE_ANIMATION_TIME = ".15s";
const COLLAPSE_ANIMATION_TIME = ".15s";

export const useStyles = makeStyles({
  absoluteContainer: {
    position: "absolute",
    bottom: 24,
    right: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  addRoomButton: {
    zIndex: 0,
    display: "flex",
    alignItems: "center",
    height: 60,

    transition: "transform .1s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  importRoomButton: {
    position: "absolute",
    display: "flex",
    alignItems: "center",

    right: 0,
    top: 0,
    bottom: 0,
  },
});

type SlideMakeStylesStylesProps = {
  shouldOffsetImportButton: boolean;
};

export const useSlideAnimationStyle = makeStyles({
  slide: ({ shouldOffsetImportButton }: SlideMakeStylesStylesProps) => ({
    "& > *": {
      transform: `translateX(-${shouldOffsetImportButton ? Spacing.S8 : 0}px)`,
      transition: `transform ${SLIDE_ANIMATION_TIME}`,
    },
  }),
  "@global": {
    ".slide-enter": {
      transform: "translateY(0%)",
    },
    ".slide-enter-active": {
      transform: "translateY(-100%)",
      transition: `transform ${SLIDE_ANIMATION_TIME} ease-out`,
    },
    ".slide-enter-done": {
      transform: "translateY(-100%)",
    },
    ".slide-exit": {
      transform: "translateY(-100%)",
    },
    ".slide-exit-active": {
      transform: "translateY(0%)",
      transition: `transform ${SLIDE_ANIMATION_TIME} ease-in`,
    },
  },
});

interface RoundButtonMakeStylesProps {
  buttonColor: string;
  labelWidth: number;
}

export const useRoundButtonStyles = makeStyles({
  roundButton: ({ buttonColor }: RoundButtonMakeStylesProps) => ({
    padding: Spacing.S12,
    borderRadius: 48,
    backgroundColor: buttonColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, .12)",
  }),

  collapsibleTextContainer: {
    overflow: "hidden",
    transition: `width ${COLLAPSE_ANIMATION_TIME}, opacity ${COLLAPSE_ANIMATION_TIME}`,
    boxSizing: "border-box",
  },

  "@global": {
    ".collapse-enter": {
      width: "0px",
      opacity: 0,
    },
    ".collapse-enter-active": ({ labelWidth }: RoundButtonMakeStylesProps) => ({
      width: labelWidth + Spacing.S16,
      opacity: 1,
      transition: `width ${COLLAPSE_ANIMATION_TIME} ease-out, opacity ${COLLAPSE_ANIMATION_TIME}`,
    }),
    ".collapse-enter-done": ({ labelWidth }: RoundButtonMakeStylesProps) => ({
      width: labelWidth + Spacing.S16,
      opacity: 1,
    }),
    ".collapse-exit": ({ labelWidth }: RoundButtonMakeStylesProps) => ({
      width: labelWidth + Spacing.S16,
      opacity: 1,
    }),
    ".collapse-exit-active": {
      width: "0px !important",
      opacity: "0 !important",
      transition: `width ${COLLAPSE_ANIMATION_TIME} ease-in, opacity ${COLLAPSE_ANIMATION_TIME}`,
    },
    ".collapse-exit-done": {
      width: "0px !important",
      opacity: "0 !important",
    },
  },
});

export const rnStyles = StyleSheet.create({
  text: {
    paddingHorizontal: Spacing.S8,
  },
});
