import { RoomFilters } from "@kraaft/shared/core/modules/filter/filterState";
import { PoolLocation } from "@kraaft/shared/core/modules/pool/poolState";
import { SubscribeToRoomsPayload } from "@kraaft/shared/core/modules/room/roomActions";

export type RoomFilterIdParams = SubscribeToRoomsPayload | { poolId: string };

export interface RoomFiltersWithPool extends RoomFilters {
  location: PoolLocation | undefined;
}
export const computeRoomPaginationFilterId = (payload: RoomFilterIdParams) => {
  const filterId =
    "filters" in payload ? `, ${JSON.stringify(payload.filters)}` : "";
  return `pages-poolId=${payload.poolId}${filterId}`;
};

export const computeRoomSubscriptionFilterId = (
  payload: RoomFilterIdParams,
) => {
  const filterId =
    "filters" in payload
      ? `, visibility=${payload.filters.visibility}, statusId=${
          payload.filters.statusId
        }, labelIds=${payload.filters.labelIds.join(",")}, responsibleId=${
          payload.filters.responsibleId
        }, showArchived=${payload.filters.showArchived}`
      : "";
  return `sub-poolId=${payload.poolId}${filterId}`;
};

export function computeFilterIds(filters: RoomFiltersWithPool) {
  let subscriptionFilterId = "";
  let paginationFilterId = "";
  if (filters.location?.poolId) {
    subscriptionFilterId = computeRoomSubscriptionFilterId({
      poolId: filters.location.poolId,
      filters,
    });
    paginationFilterId = computeRoomPaginationFilterId({
      poolId: filters.location.poolId,
      filters,
    });
  }
  return { subscriptionFilterId, paginationFilterId };
}
