import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { DirectoryContent } from "@kraaft/shared/components/directory/directoryContent";
import { EditDirectoryMenu } from "@kraaft/shared/components/directory/editDirectoryMenu";
import { DragNavigator } from "@kraaft/shared/components/dragNavigator";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { SearchHeader } from "@kraaft/shared/components/searchHeader";
import { selectDirectory } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { deselectMessage } from "@kraaft/shared/core/modules/message/messageActions";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { nullId } from "@kraaft/shared/core/utils";
import { DetailsRoute } from "@kraaft/web/src/components/detailsRoute";
import { detailsQuery } from "@kraaft/web/src/views/app/appRouter/routes";

import { useStyles } from "./directoryRoute.styles";

type Props = {
  roomId: string | undefined;
  directoryId: string | undefined;
  onBackButtonPress: () => void;
};

export const DirectoryRoute = ({
  directoryId,
  roomId,
  onBackButtonPress,
}: Props) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigationService = useNavigationService();
  const directory = useSelector(selectDirectory(directoryId || nullId));
  const [filter, setFilter] = useState("");

  const internOnBackPress = useCallback(() => {
    if (!directory || !roomId) {
      return;
    }
    if (directory.parentId === nullId) {
      onBackButtonPress();
    } else {
      navigationService.navigate("RoomDirectory", {
        roomId,
        directoryId: directory.parentId,
      });
    }
  }, [directory, navigationService, onBackButtonPress, roomId]);

  const onGoBack = useCallback(() => {
    if (!roomId) {
      return;
    }
    dispatch(deselectMessage({ roomId, all: true }));
    navigationService.goBack();
  }, [dispatch, navigationService, roomId]);

  const handleDragNavigatorBackTriggered = useCallback(() => {
    navigationService.goBack();
  }, [navigationService]);

  if (!directory || !roomId) {
    return null;
  }

  return (
    <DetailsRoute
      headerId="ide2e-directory-header"
      path={detailsQuery.directory}
      onBackButtonPress={internOnBackPress}
      hideHeader
    >
      <div className={styles.root}>
        <SearchHeader
          id={directoryId}
          leftIcon="chevron-left"
          centerHeader
          onFilterSet={setFilter}
          headerTitle={directory.name}
          placeholder={t("search")}
          onGoBackPress={onGoBack}
          headerRight={
            <HeaderSide>
              <EditDirectoryMenu roomId={roomId} directory={directory} />
            </HeaderSide>
          }
          headerLeft={
            <HeaderSide>
              <DragNavigator
                withHoverEffect
                onTriggered={handleDragNavigatorBackTriggered}
              >
                <HeaderIconButton
                  accessibilityLabel={t("close")}
                  icon="chevron-left"
                  onPress={onGoBack}
                />
              </DragNavigator>
            </HeaderSide>
          }
        />
        <DirectoryContent directory={directory} filter={filter} />
      </div>
    </DetailsRoute>
  );
};
