import { UserRoleRank } from "@kraaft/shared/core/modules/pool/poolUtil";
import { PoolMember } from "@kraaft/shared/core/modules/poolMember/poolMemberState";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";

export const comparePoolMembers = (a: PoolMember, b: PoolMember): number => {
  return (
    UserRoleRank[b.role || UserPoolRole.STANDARD] -
    UserRoleRank[a.role || UserPoolRole.STANDARD]
  );
};
