import { spawn } from "typed-redux-saga/macro";

import { setFiltersForSupportSaga } from "@kraaft/shared/core/modules/filter/sagas/setFiltersForSupport";

import { reconciliateFiltersSaga } from "./sagas/reconciliateFilters";

export function* filterSagas() {
  yield* spawn(reconciliateFiltersSaga);
  yield* spawn(setFiltersForSupportSaga);
}
