import { ForwardedRef } from "react";
import { Text, View } from "react-native";

import { Avatar, AvatarProps } from "@kraaft/shared/components/avatar";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { betterForwardRef } from "@kraaft/shared/core/utils/betterForwardRef";

import { styles } from "../kSelectionList.styles";

export interface DetailedItemValue {
  avatarProps: AvatarProps;
  details?: string;
}

type KSelectionListItemDetailedProps<
  T extends string,
  U extends DetailedItemValue,
> = {
  item: KSelectableListItem<T, U>;
  noPadding?: boolean;
};
const KSelectionListItemDetailed = betterForwardRef(
  <T extends string, U extends DetailedItemValue>(
    { item, noPadding }: KSelectionListItemDetailedProps<T, U>,
    forwardedRef: ForwardedRef<View>,
  ) => {
    if (item.value === undefined) {
      return null;
    }

    return (
      <View
        style={[styles.item, !noPadding && styles.itemPadding]}
        ref={forwardedRef}
      >
        <View style={styles.avatarContainer}>
          <Avatar {...item.value.avatarProps} />
        </View>
        <View style={styles.textContainer}>
          <Text numberOfLines={1} style={styles.text}>
            {item.label}
          </Text>
          {item.value.details ? (
            <Text numberOfLines={1} style={styles.details}>
              {item.value.details}
            </Text>
          ) : null}
        </View>
      </View>
    );
  },
);

export { KSelectionListItemDetailed };
