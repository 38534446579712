import { memo, useMemo } from "react";
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { orderBy } from "lodash";

import { PillTooltip } from "@kraaft/shared/components/message/reactions/pillTooltip";
import { ReactionPill } from "@kraaft/shared/components/message/reactions/reactionPill";
import { Spacing } from "@kraaft/ui";

export interface ReactionDescription {
  names: string[];
  count: number;
  firstReactedAt: number;
}

export interface ReactionsDescription {
  [emoji: string]: ReactionDescription;
}

export interface ReactionPillsProps {
  style?: ViewStyle;
  reactions: ReactionsDescription;
  userReaction: string | undefined;
  onReactionPress(emoji: string, userReaction: boolean): void;
  onLongPress?(event: GestureResponderEvent): void;
  isReply: boolean;
}

const ReactionPills_ = ({
  style,
  reactions,
  userReaction,
  onReactionPress,
  onLongPress,
  isReply,
}: ReactionPillsProps) => {
  const orderedReactions = useMemo(
    () =>
      orderBy(
        Object.entries(reactions),
        [([, { firstReactedAt }]) => firstReactedAt, ([emoji]) => emoji],
        isReply ? "asc" : "desc",
      ),
    [isReply, reactions],
  );

  if (Object.keys(reactions).length === 0) {
    return null;
  }

  return (
    <View
      style={[
        styles.root,
        isReply ? styles.containerIsReply : styles.containerSelf,
        style,
      ]}
    >
      {orderedReactions.map(([emoji, { count, names }]) => {
        const isUserReaction = emoji === userReaction;

        return (
          <PillTooltip key={emoji} emoji={emoji} names={names}>
            <Pressable
              onPress={() => onReactionPress(emoji, isUserReaction)}
              onLongPress={onLongPress}
              nativeID="reactions-pill-text"
              accessibilityLabel={emoji}
            >
              <ReactionPill
                emoji={emoji}
                count={count}
                highlighted={isUserReaction}
              />
            </Pressable>
          </PillTooltip>
        );
      })}
    </View>
  );
};

export const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: Spacing.S2,
  },
  containerSelf: {
    justifyContent: "flex-end",
  },
  containerIsReply: {
    justifyContent: "flex-start",
  },
});

export const ReactionPills = memo(ReactionPills_);
