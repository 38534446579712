import { PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { CornerWithIcon } from "@kraaft/shared/components/cornerWithIcon";
import { useGeolocatedMediaSheet } from "@kraaft/shared/components/geolocatedMediaDialog/useGeolocatedMediaSheet";
import { getMediaGeolocation } from "@kraaft/shared/components/mediaViewer/utils";
import { imageHelper } from "@kraaft/shared/core/modules/file/imageHelper";
import { MessageTypes } from "@kraaft/shared/core/modules/message";
import { MessageActions } from "@kraaft/shared/core/modules/message/messageActions";
import { isPersisted } from "@kraaft/shared/core/modules/message/messageUtils";
import { useNavigationService } from "@kraaft/shared/core/services/navigation/index";
import { GeoLocation } from "@kraaft/shared/core/types";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Portal } from "@kraaft/ui";

type CornerWithIconProps = {
  message: MessageTypes.ImageMessage | MessageTypes.VideoMessage;
  roomId: string;
};

export const MessageIcon = ({
  children,
  message,
  roomId,
}: PropsWithChildren<CornerWithIconProps>) => {
  const { id: messageId, attachment, type: messageType } = message;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigationService = useNavigationService();

  const coords = useMemo(
    () => getMediaGeolocation(attachment)?.coords,
    [attachment],
  );
  const hasCoords = Boolean(coords);

  const dialogTooltip = t(
    messageType === "image"
      ? "nonGeolocatedMediaDialog.titleForImage"
      : "nonGeolocatedMediaDialog.titleForVideo",
  );

  const mapTooltip = t("openMap");

  const openImageMessageMap = useCallback(() => {
    navigationService.openRoomMapOnPhotoGallery({ roomId, coords });
    trackEvent({
      eventName: "Click On Message Map Button",
      room_id: roomId,
      message_id: messageId,
      message_type: messageType,
    });
  }, [navigationService, roomId, coords, messageId, messageType]);

  const handleMediaLocationChange = useCallback(
    (geolocation: GeoLocation | null | undefined) => {
      dispatch(
        MessageActions.updateAttachmentGeolocation({
          roomId,
          messageId,
          geolocation: geolocation ?? undefined,
        }),
      );
    },
    [dispatch, roomId, messageId],
  );

  const { openEditor, GeolocatedMediaDialog } = useGeolocatedMediaSheet({
    media: attachment,
    roomId,
    source: "conversation",
    onLocationChange: handleMediaLocationChange,
  });

  const messageAttachementSize =
    message.type === "image" && message.attachment.size;

  const childrenWithHdLogo = useMemo(() => {
    if (
      messageAttachementSize &&
      imageHelper.getQualityFromDimension(messageAttachementSize) === "hd"
    ) {
      return (
        <CornerWithIcon
          accessibilityLabel={t("imageHD")}
          icon="hd-on-2"
          tooltipText={t("imageHD")}
          direction="bottomLeft"
        >
          {children}
        </CornerWithIcon>
      );
    }
    return children;
  }, [children, messageAttachementSize, t]);

  if (!isPersisted(message)) {
    return childrenWithHdLogo;
  }

  const isLocationEditable = messageType === "image";

  if (!hasCoords) {
    return (
      <>
        <CornerWithIcon
          accessibilityLabel={t("openMap")}
          icon="question-marker"
          onIconPress={openEditor}
          tooltipText={dialogTooltip}
          direction="bottomRight"
        >
          {childrenWithHdLogo}
        </CornerWithIcon>
        <Portal>{GeolocatedMediaDialog}</Portal>
      </>
    );
  }

  if (!isLocationEditable) {
    return childrenWithHdLogo;
  }

  return (
    <CornerWithIcon
      accessibilityLabel={t("openMap")}
      icon="map-04"
      onIconPress={openImageMessageMap}
      tooltipText={mapTooltip}
      direction="bottomRight"
    >
      {childrenWithHdLogo}
    </CornerWithIcon>
  );
};
