import { Dictionary } from "ts-essentials";

import { ErrorInfo } from "@kraaft/shared/core/modules/common";
import * as MessageTypes from "@kraaft/shared/core/modules/message/messageState";
import { GeolocationColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/geolocation";
import {
  SelectMultipleColumnValue,
  SelectSingleColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/select";
import { ShortTextColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/shortText";
import { UserColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/user";
import {
  ExistingColumnValue,
  ModularRecord,
  ModularRecordProperties,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { LongTextColumnValue } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { GeoCoordinates } from "@kraaft/shared/core/types";

export interface RoomState {
  lastCreatedRoom: string | undefined;
  /** indexed by poolId */
  roomMemberLists: Dictionary<RoomMember[]>; // @TODO: move in a dedicated reducer
  carousel:
    | { show: false }
    | {
        show: true;
        roomId: string;
        checkboxId: string;
        initialIndex: number | undefined;
      }
    | undefined;
  roomCarousel:
    | { show: false }
    | {
        type: "room" | "photoGallery";
        show: true;
        messageId: string;
      }
    | undefined;
  imagePreview:
    | { show: false }
    | {
        show: true;
        uri: string;
        message: MessageTypes.ImageMessage;
      }
    | undefined;
  geolocation: GeolocationContext;
  map: {
    [roomId: string]: {
      displayMode?: RoomMapDisplayMode; // used on native
      schemaId?: string;
      isMinZoom?: boolean;
      visibleMarkerIds?: string[];
    };
  };
  photoGalleries: {
    [roomId: string]: MessageTypes.ImageMessage[];
  };
}

export type RoomMapDisplayMode = "media" | "folder";
export const DEFAULT_ROOM_MAP_DISPLAY_MODE: RoomMapDisplayMode = "media";

interface GeolocationActionCenterOn {
  type: "centerOn";
  coords: GeoCoordinates;
}

interface GeolocationActionZoomOnMarkers {
  type: "zoomOnBounds";
  boundsList: GeoCoordinates[];
}

interface GeolocationActionZoomOut {
  type: "zoomOnMapBounds";
}

export type GeolocationAction =
  | GeolocationActionCenterOn
  | GeolocationActionZoomOnMarkers
  | GeolocationActionZoomOut;

export interface GeoLocationHover {
  id: string | undefined;
}

export interface GeoLocationSelect {
  id: string | undefined;
}

export interface GeolocationContext {
  hover?: GeoLocationHover;
  select?: GeoLocationSelect;
  actions?: GeolocationAction[];
}

export interface Label {
  id: string;
  name: string;
}

export type UserRoom = RoomUserHistory;

export enum RoomType {
  DEFAULT = "default",
  EVERYONE = "everyone",
}

interface RoomBase {
  id: string;
  createdAt: Date;
  creatorUserId: string;
  visibility: RoomVisibility | undefined;
  poolId: string;
  lastEventAt: Date;
  lastMessage?: {
    id: string;
    senderId: string;
    createdAt: Date;
    type: MessageTypes.MessageType;
    text?: string;
  };
  isArchivedForAll: boolean | undefined;
  updatedAt: Date;
  optimisticallyUpdatedAt?: Date;
  external?: {
    microsoftStorage?: {
      url: string;
      synchronizedAt?: Date;
      error?: ErrorInfo;
    };
  };
  members: RoomMembers;
  record: RoomModularRecord;
  emoji: string;

  type: RoomType;
  isClonedFromDemo?: boolean;
}

export interface DefaultRoom extends RoomBase {
  type: RoomType.DEFAULT;
}

export interface EveryoneRoom extends RoomBase {
  type: RoomType.EVERYONE;
}

export type Room = DefaultRoom | EveryoneRoom;

export type RoomWithGeolocation = Room & {
  record: RoomModularRecordWithGeolocation;
};

export type RoomVisibility = "pool" | "administrator" | "private";

export type RoomMembers = Record<string, Record<string, never>>;

export type BaseRoomModularRecordProperties = {
  title: ExistingColumnValue<ShortTextColumnValue>;
  description?: LongTextColumnValue;
  geolocation?: GeolocationColumnValue;
  status?: SelectSingleColumnValue;
  labels?: SelectMultipleColumnValue;
  responsible?: UserColumnValue;
};

export type RoomModularRecord = ModularRecord & {
  type: RoomType;
  properties: BaseRoomModularRecordProperties & ModularRecordProperties;
};

type RoomModularRecordWithGeolocation = RoomModularRecord & {
  properties: Omit<RoomModularRecord["properties"], "geolocation"> & {
    geolocation: ExistingColumnValue<GeolocationColumnValue>;
  };
};

export type RoomModularRecordProperties =
  RoomModularRecord["properties"]["geolocation"];

export type RoomNotificationFilter = "inherit" | "all" | "only_mentions";

export interface RoomUserHistory {
  id: string;
  isArchived: boolean;
  lastReadMessageCreatedAt?: Date;
  lastReadMessageId: string | undefined;
  lastEventAt: Date;
  readAt?: Date;
  notificationFilter?: RoomNotificationFilter;
  isMarkedUnread?: boolean;
}

export interface RoomMember {
  userId: string;
  lastReadMessageCreatedAt?: Date;
  lastReadMessageId?: string | undefined;
  readAt?: Date;
  roomId: string;
}
