import React, { useCallback, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import clsx from "clsx";

import { useHover } from "@kraaft/helper-hooks";
import { RoomListContext } from "@kraaft/shared/components/roomList/roomListContext";
import { Color, ColorStyle, IconSize } from "@kraaft/ui";
import { IconScaler } from "@kraaft/web/src/components/addRoomButton/iconScaler/iconScaler";
import { ImportRoomDialog } from "@kraaft/web/src/components/importRoomDialog";

import { AddRoomButton } from "./addRoomButton";
import { RoundButton } from "./roundButton";

import {
  useSlideAnimationStyle,
  useStyles,
} from "./addOrImportRoomButton.styles";

interface Props {
  onPressCreate: () => void;
}

const AddOrImportRoomButton_ = ({ onPressCreate }: Props) => {
  const { t } = useTranslation();

  const boxRef = useRef<HTMLDivElement>(null);

  const isTextHidden = useContext(RoomListContext).isScrollingDown;
  const isHovering = useHover(boxRef);

  const classes = useStyles({ shouldOffsetImportButton: isTextHidden });
  const slideAnimationClasses = useSlideAnimationStyle({
    shouldOffsetImportButton: isTextHidden,
  });

  const [importDialogOpen, setImportDialogOpen] = useState(false);

  const handleOpenImportDialog = useCallback(() => {
    setImportDialogOpen(true);
  }, []);

  const handleCloseImportDialog = useCallback(() => {
    setImportDialogOpen(false);
  }, []);

  return (
    <>
      <div ref={boxRef} className={classes.absoluteContainer}>
        <CSSTransition in={isHovering} classNames="slide" timeout={150}>
          <div
            className={clsx(
              classes.importRoomButton,
              slideAnimationClasses.slide,
            )}
          >
            <RoundButton
              id="rooms-import-rooms"
              icon="upload-cloud-01"
              iconColor={ColorStyle.ACTION_CTA}
              iconSize={IconSize.MEDIUM}
              buttonColor={Color.BLUE_COOL}
              tooltipText={t("importRoomsTooltip")}
              onClick={handleOpenImportDialog}
            />
          </div>
        </CSSTransition>

        <IconScaler isEntered={isTextHidden} className={classes.addRoomButton}>
          <AddRoomButton isTextHidden={isTextHidden} onPress={onPressCreate} />
        </IconScaler>
      </div>

      <ImportRoomDialog
        open={importDialogOpen}
        onClose={handleCloseImportDialog}
      />
    </>
  );
};

export const AddOrImportRoomButton = React.memo(AddOrImportRoomButton_);
