import { Fragment } from "react";

import { BetweenDirectoryDropper } from "@kraaft/web/src/components/directoryTree/directoryDropper/betweenDirectoryDropper";
import { DirectoryTreeRowGroup } from "@kraaft/web/src/components/directoryTree/directoryTreeRowGroup";

interface DirectoryTreeChildDirectoriesProps {
  parentDirectoryId: string | undefined;
  directoryIds: string[];
  parentLevel: number;
  isGroupBeingDragged?: boolean;
}

const DirectoryTreeChildDirectories = (
  props: DirectoryTreeChildDirectoriesProps,
) => {
  const { parentDirectoryId, directoryIds, parentLevel, isGroupBeingDragged } =
    props;

  return (
    <>
      {directoryIds.map((id, index) => {
        const childLevel = parentLevel + 1;

        return (
          <Fragment key={id}>
            <DirectoryTreeRowGroup
              directoryId={id}
              level={childLevel}
              isFirstChildDirectory={index === 0}
              isGroupBeingDragged={isGroupBeingDragged}
            />
            <BetweenDirectoryDropper
              level={childLevel}
              parentDirectoryId={parentDirectoryId}
              siblingDirectoryId={id}
              placement="after"
              followingDirectoryId={directoryIds[index + 1]}
              isGroupBeingDragged={isGroupBeingDragged}
            />
          </Fragment>
        );
      })}
    </>
  );
};

export { DirectoryTreeChildDirectories };
