import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useMeshContext } from "@kraaft/helper-hooks";
import { RoomFiltersContext } from "@kraaft/shared/components/filters/roomFilters.context";
import { selectRoomSchemaStatusColumn } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { getSelectColumnOption } from "@kraaft/shared/core/modules/schema/schema.utils";

export function useStatusSummary() {
  const { poolId, statusId } = useMeshContext(RoomFiltersContext);
  const statusColumn = useSelector(selectRoomSchemaStatusColumn(poolId));

  const statusLabel = useMemo(() => {
    if (statusColumn === undefined || statusId === undefined) {
      return undefined;
    }
    const option = getSelectColumnOption(statusColumn, statusId);

    return option?.label;
  }, [statusColumn, statusId]);

  return statusLabel;
}
