import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useBooleanState } from "@kraaft/helper-hooks";
import { ActionSheetHostWithContextMenuButton } from "@kraaft/shared/components/actionSheetHostWithContextMenuButton";
import { clearTemporarySchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { DirtyableSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewState";
import { ActionSheetContent, ActionSheetItem } from "@kraaft/ui";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

import { DeleteSchemaViewPopup } from "./saveSchemaViewButton/deleteSchemaViewPopup";
import { EditSchemaViewNamePopup } from "./saveSchemaViewButton/editSchemaViewNamePopup";

export function useSchemaViewActionSheet(
  workspace: RecordWorkspace,
  schemaId: string,
  schemaView: DirtyableSchemaView,
) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isActionSheetOpen, openActionSheet, closeActionSheet] =
    useBooleanState();

  const resetChanges = useCallback(() => {
    dispatch(
      clearTemporarySchemaView({
        schemaId,
        schemaViewId: schemaView.id,
        workspace,
      }),
    );
  }, [dispatch, schemaId, schemaView, workspace]);

  const {
    open: openEditSchemaViewNamePopup,
    element: editSchemaViewNamePopupElement,
  } = EditSchemaViewNamePopup.use({
    schemaView: schemaView,
  });

  const { open: openDeleteSchemaViewPopup, element: schemaViewPopupElement } =
    DeleteSchemaViewPopup.use({
      schemaView: schemaView,
    });

  const {
    schemaViewActionSheetItems: schemaViewActionSheet,
    schemaViewActionsPopupElements,
  } = useMemo(() => {
    const popupElements: React.ReactNode[] = [];
    const actionSheetItems: React.ReactNode[] = [];

    if (schemaView.dirty) {
      actionSheetItems.push(
        <ActionSheetItem
          label={
            schemaView.id
              ? t("deleteExistingSchemaViewChanges")
              : t("deleteSchemaViewChanges")
          }
          onClick={resetChanges}
        />,
      );
    }

    if (schemaView.id) {
      actionSheetItems.push(
        <ActionSheetItem
          label={t("renameView")}
          onClick={() => {
            closeActionSheet();
            openEditSchemaViewNamePopup();
          }}
        />,
        <ActionSheetItem
          label={t("deleteView")}
          onClick={() => {
            closeActionSheet();
            openDeleteSchemaViewPopup();
          }}
          destructive
        />,
      );

      popupElements.push(
        editSchemaViewNamePopupElement,
        schemaViewPopupElement,
      );
    }

    return {
      schemaViewActionsPopupElements: popupElements,
      schemaViewActionSheetItems:
        actionSheetItems.length > 0 ? (
          <ActionSheetHostWithContextMenuButton
            isOpen={isActionSheetOpen}
            onClose={closeActionSheet}
            handleOpen={openActionSheet}
          >
            <ActionSheetContent>{actionSheetItems}</ActionSheetContent>
          </ActionSheetHostWithContextMenuButton>
        ) : null,
    };
  }, [
    closeActionSheet,
    editSchemaViewNamePopupElement,
    isActionSheetOpen,
    openActionSheet,
    openDeleteSchemaViewPopup,
    openEditSchemaViewNamePopup,
    resetChanges,
    schemaView.dirty,
    schemaView.id,
    schemaViewPopupElement,
    t,
  ]);

  return {
    schemaViewActionSheet,
    schemaViewActionsPopupElements,
  };
}
