import { Channel, channel, END, TakeableChannel } from "redux-saga";

export function combineLatestChannels<T1, T2>(
  channel1: TakeableChannel<T1>,
  channel2: TakeableChannel<T2>,
): Channel<[T1, T2]>;
export function combineLatestChannels<T1, T2, T3>(
  channel1: TakeableChannel<T1>,
  channel2: TakeableChannel<T2>,
  channel3: TakeableChannel<T3>,
): Channel<[T1, T2, T3]>;
export function combineLatestChannels(...channels: TakeableChannel<unknown>[]) {
  const fired = new Set<TakeableChannel<unknown>>();
  let isEnded = false;
  let results: unknown[] = [];

  const resultChannel = channel();

  channels.forEach((sourceChannel, index) => {
    const callback = (message: unknown): void => {
      if (!isEnded) {
        if (message === END) {
          isEnded = true;
          resultChannel.put(END);
        } else if (message instanceof Error) {
          resultChannel.put(message);
        } else {
          fired.add(sourceChannel);
          results = [...results];
          results[index] = message;
          if (fired.size === channels.length) {
            resultChannel.put(results);
          }
        }
        sourceChannel.take(callback);
      }
    };
    sourceChannel.take(callback);
  });

  return resultChannel;
}
