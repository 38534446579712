import { ReactNode } from "react";
import { ViewStyle } from "react-native";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { ButtonProps } from "@kraaft/ui";

export enum KDialogSize {
  MEDIUM = 960,
  SMALL = 646,
}
export interface BaseDialogFrameProps {
  fullHeight?: boolean;
  fullScreen?: boolean;
  size?: KDialogSize;
}

export interface KDialogFrameProps extends BaseDialogFrameProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactChild;
}

export type KDialogAction = ButtonProps & { text: string };

export interface KDialogConfig extends BaseDialogFrameProps {
  title: ReactNode;
  subtitle?: ReactNode;
  content: JSX.Element | null;
  leftActions?: KDialogAction[];
  actions?: FooterActions;
}

export interface KDialogProps extends KDialogConfig {
  open: boolean;
  onClose: () => void;
  containerStyle?: ViewStyle;
}
