import { useState } from "react";

import { AlertDialogSheet } from "@kraaft/shared/components/alertDialog/alertDialog.sheet";
import {
  AlertDialogState,
  IAlertDialog,
} from "@kraaft/shared/components/alertDialog/alertDialog.types";
import { useEffectRealtime } from "@kraaft/shared/core/utils/hooks";

let instance: {
  open: (state: AlertDialogState) => void;
  close: () => void;
} | null;

export const AlertDialogView = () => {
  const [currentlyOpen, setCurrentlyOpen] = useState<
    AlertDialogState | undefined
  >(undefined);

  const { open, close, element } = AlertDialogSheet.use({
    state: currentlyOpen,
    size: currentlyOpen?.size,
  });

  useEffectRealtime(
    ([_currentlyOpen]) => {
      instance = {
        open: (state: AlertDialogState) => {
          setCurrentlyOpen(state);
          open();
        },
        close: () => {
          _currentlyOpen?.buttons
            ?.find((button) => button.style === "cancel")
            ?.onPress?.();
          close();
        },
      };
    },
    [close, open],
    [currentlyOpen],
  );

  return element;
};

const Alert: IAlertDialog = {
  alert(title, message, buttons, size) {
    instance?.open({
      title,
      message,
      buttons,
      size,
    });
  },
  close() {
    instance?.close();
  },
};

export { Alert as AlertDialog };
