import { useMemo } from "react";
import { useSelector } from "react-redux";

import { ModularFolderCardPillsContainer } from "@kraaft/shared/components/roomCard/modularFolderCardPills";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularRecordProperties } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { selectSchemaHighlightedColumnKey } from "@kraaft/shared/core/modules/schema/schema.selectors";

import { CompletedDescriptionContainer } from "./completedDescription";

interface ModularFolderCardDescriptionProps {
  schemaId: string;
  recordProperties: ModularRecordProperties;
}

export const ModularFolderCardDescription = ({
  schemaId,
  recordProperties,
}: ModularFolderCardDescriptionProps) => {
  const schemaHighlightedColumnKey = useSelector(
    selectSchemaHighlightedColumnKey(schemaId),
  );

  const completionProperty = useMemo(
    () =>
      schemaHighlightedColumnKey
        ? ModularRecordUtils.findColumn(
            recordProperties,
            schemaHighlightedColumnKey,
            KColumnType.checkbox,
          )
        : undefined,
    [recordProperties, schemaHighlightedColumnKey],
  );
  const isCompleted = completionProperty?.value;

  if (isCompleted) {
    const { updatedAt, updatedBy } = completionProperty;

    return (
      <CompletedDescriptionContainer
        completedAt={updatedAt}
        completedBy={updatedBy}
      />
    );
  }
  return (
    <ModularFolderCardPillsContainer
      schemaId={schemaId}
      recordProperties={recordProperties}
    />
  );
};
