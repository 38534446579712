import { useCallback } from "react";

import {
  Droppable,
  DroppableChildrenRenderer,
  DroppableItem,
} from "@kraaft/shared/components/droppable";
import { ModularFolderCardContent } from "@kraaft/shared/components/modularFolderCard/modularFolderCardContent";
import { ModularFolderCardProps } from "@kraaft/shared/components/modularFolderCard/types";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { useToggle } from "@kraaft/shared/core/utils/hooks";

const ModularFolderCard = (props: ModularFolderCardProps) => {
  const {
    id,
    enableInteractions,
    enableDrop,
    onDrop,
    onDropFiles,
    dropAcceptType,
    dropSystemFile,
  } = props;

  const [expanded, toggleExpanded] = useToggle(false);

  const handleDrop = useCallback(
    (item: DroppableItem) => {
      onDrop?.(item);
      toggleExpanded(true);
    },
    [onDrop, toggleExpanded],
  );

  const handleDropFiles = useCallback(
    (files: ModernFile<LocalPath>[]) => {
      onDropFiles?.(files);
      toggleExpanded(true);
    },
    [onDropFiles, toggleExpanded],
  );

  const renderDroppableContent = useCallback<DroppableChildrenRenderer>(
    ({ isActive, canDrop }) => {
      return (
        <ModularFolderCardContent
          {...props}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
          enableInteractions={enableInteractions}
          isDropActive={isActive}
          canDrop={canDrop}
        />
      );
    },
    [enableInteractions, expanded, props, toggleExpanded],
  );

  if (enableDrop) {
    return (
      <Droppable
        id={id}
        onDrop={handleDrop}
        onDropFiles={handleDropFiles}
        acceptType={dropAcceptType}
        acceptSystemFile={dropSystemFile}
      >
        {renderDroppableContent}
      </Droppable>
    );
  }
  if (enableInteractions) {
    return (
      <ModularFolderCardContent
        {...props}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        enableInteractions={enableInteractions}
      />
    );
  }
  return (
    <ModularFolderCardContent
      {...props}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
    />
  );
};

export { ModularFolderCard };
