import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { useHasLibrarySchemas } from "@kraaft/shared/core/modules/librarySchema/librarySchema.hooks";
import { Spacing } from "@kraaft/ui";
import { AddSchemaFromLibraryActionCard } from "@kraaft/web/src/views/schemasView/addSchema/addSchemaFromLibraryActionCard";
import { AddSchemaFromPaperActionCard } from "@kraaft/web/src/views/schemasView/addSchema/addSchemaFromPaperActionCard";
import { AddSchemaFromScratchActionCard } from "@kraaft/web/src/views/schemasView/addSchema/addSchemaFromScratchActionCard";

interface Props {
  onClose: () => void;
}

export const AddSchemaDialogContent = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const hasLibrarySchemas = useHasLibrarySchemas();

  return (
    <KDialogContent
      title={t("schemasView.addDialog.title")}
      content={
        <div className={classes.cardsContainer}>
          <AddSchemaFromPaperActionCard />
          {hasLibrarySchemas && (
            <AddSchemaFromLibraryActionCard onClose={onClose} />
          )}
          <AddSchemaFromScratchActionCard />
        </div>
      }
    />
  );
};

const useStyles = makeStyles({
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: Spacing.S16,
    width: "100%",
  },
});
