import { StyleSheet } from "react-native";

import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    flexShrink: 0,
  },
  text: {
    marginTop: Spacing.S32,
    marginBottom: Spacing.S12,
    marginLeft: Spacing.S16,
  },
  default: {
    marginBottom: Spacing.S12,
    backgroundColor: Color.WHITE,
  },
  list: {
    backgroundColor: Color.WHITE,
  },
  permissionWrapperContainer: {
    flex: 1,
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
    paddingHorizontal: Spacing.S16,
    paddingBottom: Spacing.S16,
  },
});
