import { useMemo } from "react";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ReactNodeRef } from "@kraaft/shared/core/types";
import { ActionSheetMenu } from "@kraaft/web/src/components/actionSheetMenu";
import { usePopupMenu } from "@kraaft/web/src/components/popupMenu/popupMenuProvider";
import { useDuplicateActionItems } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementActions/useDuplicateActionItems";
import { useAddElementCondition } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementCondition/useAddElementCondition";
import { useConditionActionItems } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementCondition/useConditionActionItems";

import { useDeleteElementActionItems } from "../elementActions/useDeleteElementActionItems";

interface HookProps {
  onDelete: (key: string) => void;
  column: KSchemaColumn;
  forceInstantDeletion: boolean;
}

export function useColumnOpenContextMenu({
  onDelete,
  column,
  forceInstantDeletion,
}: HookProps) {
  const { open, close } = usePopupMenu();

  return (anchor: ReactNodeRef) => {
    open(
      <ContextMenu
        onDelete={onDelete}
        column={column}
        forceInstantDeletion={forceInstantDeletion}
        onClose={close}
      />,
      { anchorRef: anchor },
    );
  };
}

type Props = HookProps & {
  onClose: () => void;
};

const ContextMenu = (props: Props) => {
  const { onDelete, column, forceInstantDeletion, onClose } = props;

  const deleteActions = useDeleteElementActionItems(
    onDelete,
    column,
    forceInstantDeletion,
  );

  const duplicateActions = useDuplicateActionItems(column);

  const openCondition = useAddElementCondition(column);
  const conditionActions = useConditionActionItems(column, openCondition);

  const actions = useMemo<ActionSheetItem[]>(
    () => [...duplicateActions, ...conditionActions, ...deleteActions],
    [duplicateActions, conditionActions, deleteActions],
  );

  return <ActionSheetMenu options={actions} onClose={onClose} open={true} />;
};
