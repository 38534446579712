import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { useMeshContext } from "@kraaft/helper-hooks";
import { DetailsEditorBox } from "@kraaft/shared/components/modular/details/editors/detailsEditorBox";
import { EditorProps } from "@kraaft/shared/components/modular/details/editors/types";
import { Pill } from "@kraaft/shared/components/pill";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularDisplayRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { Spacing } from "@kraaft/ui";

import { getFriendlyUserLabel } from "./userEditor/getFriendlyUserLabel";

type Props = EditorProps<KColumnType.automatedCreatedBy>;

const AutomatedCreatedByEditor = (props: Props) => {
  const {
    editor: { label, value, sectionLockInfo },
    column,
  } = props;
  const { t } = useTranslation();

  const { currentUserId, getOrLoadUsers, getPoolUsers, poolId } =
    useMeshContext(ModularDisplayRequirementsContext);

  const valueLabel = useMemo(() => {
    const userId = value?.[0];
    if (userId === undefined) {
      return undefined;
    }

    return getFriendlyUserLabel({
      userId,
      currentUserId,
      users: { ...getOrLoadUsers([userId]), ...getPoolUsers() },
      t,
      poolId,
    });
  }, [currentUserId, getPoolUsers, getOrLoadUsers, poolId, t, value]);

  return (
    <DetailsEditorBox
      isLocked={sectionLockInfo.isLocked}
      label={label}
      columnKey={column.key}
    >
      <View style={styles.pillsContainer}>
        <Pill content={valueLabel ?? ""} icon="user-01" size="medium" />
      </View>
    </DetailsEditorBox>
  );
};

const styles = StyleSheet.create({
  pillsContainer: {
    flexDirection: "row",
    paddingHorizontal: Spacing.S4,
    paddingVertical: Spacing.S8,
  },
});

export { AutomatedCreatedByEditor };
