import { createAction } from "@reduxjs/toolkit";

interface CreateTaskTemplatePayload {
  name: string;
}

export const createTaskTemplate = createAction(
  "@taskTemplate/CREATE_TASK_TEMPLATE",
  (payload: CreateTaskTemplatePayload) => ({ payload }),
);

interface EditTaskTemplatePayload {
  taskNames?: string[];
  name?: string;
}

export const editTaskTemplate = createAction(
  "@taskTemplate/EDIT_TASK_TEMPLATE",
  (payload: EditTaskTemplatePayload) => ({ payload }),
);

interface EditTaskTemplateTaskNamePayload {
  index: number;
  taskName: string;
}

export const editTaskTemplateTaskName = createAction(
  "@taskTemplate/EDIT_TASK_TEMPLATE_TASK_NAME",
  (payload: EditTaskTemplateTaskNamePayload) => ({ payload }),
);

export const editTaskTemplateAddNewTask = createAction(
  "@taskTemplate/EDIT_ADD_NEW_TASK",
  () => ({ payload: undefined }),
);
