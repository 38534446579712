import { Fragment, useMemo } from "react";

import { DisplayMoreButton } from "@kraaft/shared/components/actionCardList/directoryList/displayMoreButton";
import { DroppableDirectoryListCard } from "@kraaft/shared/components/actionCardList/directoryList/droppableDirectoryListCard";
import { useDirectoryListContext } from "@kraaft/shared/components/actionCardList/directoryList/useDirectoryListContext";
import { Collapsible } from "@kraaft/shared/components/animated/collapsible";
import { FileCard } from "@kraaft/shared/components/fileCard";
import { DetailGroup } from "@kraaft/shared/components/groupButton/detailGroup";
import { filterNullableChildren } from "@kraaft/shared/core/utils/filterNullableChildren";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";

import { VirtualizedDirectoryListProps } from "./types";

const DirectoryList = (props: VirtualizedDirectoryListProps) => {
  const {
    roomId,
    directoryId,
    directories,
    files = [],
    disableIncompatible,
    style,
    onPressDirectory,
    onSelect,
    newDirectoryElement = null,
    cropListSize,
  } = props;

  const [isOpen, open, close] = useBooleanState(false);

  const [directoriesToDisplay, displayMoreDirectories] = useMemo(() => {
    if (cropListSize === undefined) {
      return [directories, null] as const;
    }

    const firstDirectories = directories.slice(0, cropListSize);
    const otherDirectories = directories.slice(cropListSize);
    const hasMore = firstDirectories.length < directories.length;

    if (!hasMore) {
      return [directories, null] as const;
    }

    return [
      firstDirectories,
      <Fragment key="fragment">
        <Collapsible open={isOpen}>
          <DetailGroup>
            {otherDirectories.map((directory) => (
              <DroppableDirectoryListCard
                directory={directory}
                key={directory.id}
                disabled={Boolean(disableIncompatible)}
                onPress={onPressDirectory}
                roomId={roomId}
              />
            ))}
          </DetailGroup>
        </Collapsible>
        <DisplayMoreButton
          onPress={isOpen ? close : open}
          isOn={isOpen}
          moreCount={otherDirectories.length}
        />
      </Fragment>,
    ] as const;
  }, [
    cropListSize,
    directories,
    isOpen,
    close,
    open,
    disableIncompatible,
    onPressDirectory,
    roomId,
  ]);

  const { isFileCardDisabled, isSelecting } = useDirectoryListContext({
    roomId,
    medias: files,
  });

  return (
    <DetailGroup style={style}>
      {[
        ...directoriesToDisplay.map((directory) => (
          <DroppableDirectoryListCard
            directory={directory}
            key={directory.id}
            disabled={Boolean(disableIncompatible)}
            onPress={onPressDirectory}
            roomId={roomId}
          />
        )),
        displayMoreDirectories,
        filterNullableChildren(newDirectoryElement),
        ...files.map((file) => (
          <FileCard
            forceSelection={isSelecting}
            roomId={roomId}
            directoryId={directoryId}
            hideDirectories
            selectionSource="directoryList"
            media={file}
            key={file.id}
            disabled={isFileCardDisabled}
            onSelect={onSelect}
            trackingSource="directory"
          />
        )),
      ].filter(Boolean)}
    </DetailGroup>
  );
};

export { DirectoryList };
