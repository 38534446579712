import { StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import {
  Color,
  ColorStyle,
  FontSize,
  morphClassNameAsStyle,
  Spacing,
} from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  flatListContainer: {
    flex: 1,
    overflow: "hidden",
  },
  rowBackground: {
    backgroundColor: Color.WHITE,
  },
  rowContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    height: 48,
  },
  rowContainerPadding: {
    paddingRight: Spacing.S16,
  },
  forcePointerEvents: !isNative()
    ? morphClassNameAsStyle("force-pointer-events")
    : {},
  itemContainer: {
    flex: 1,
  },
  item: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    height: 40,
  },
  itemPadding: {
    paddingLeft: Spacing.S16,
  },
  textContainer: {
    flex: 1,
    flexDirection: "column",
  },
  text: {
    fontFamily: "Apercu",
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  details: {
    fontFamily: "Apercu",
    fontSize: FontSize.MEDIUM,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  textSelectAll: {
    fontFamily: "Apercu-Bold",
  },
  iconContainerLeft: {
    marginRight: Spacing.S8,
  },
  avatarContainer: {
    justifyContent: "center",
    marginRight: Spacing.S12,
  },
  skeletonText: {
    flex: 1,
  },
  footerDelimiter: {
    marginHorizontal: Spacing.S16,
    backgroundColor: Color.WHITE,
    borderBottomColor: ColorStyle.SEPARATOR,
    borderBottomWidth: 1,
    height: Spacing.S12,
  },
});
