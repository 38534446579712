import { Text, View } from "react-native";

import { Toggle } from "@kraaft/shared/components/toggle";
import { ToggleProps } from "@kraaft/shared/components/toggle/toggleProps";

import { styles } from "./toggleWithLabel.styles";

interface ToggleWithLabelProps extends ToggleProps {
  label: string;
  small?: boolean;
  noPadding?: boolean;
}

const ToggleWithLabel = (props: ToggleWithLabelProps) => {
  const { label, small, noPadding, ...otherProps } = props;

  return (
    <View style={styles.toggle}>
      <Text style={[styles.text, small && styles.smallText]}>{label}</Text>
      <Toggle {...otherProps} />
    </View>
  );
};

export { ToggleWithLabel };
