import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@kraaft/shared/core/store";

const selectMemoryState = (state: RootState) => state.memory;

export const selectMemoryTemporarySchemaViews = createSelector(
  selectMemoryState,
  (state) => state.platform.temporarySchemaViews,
);
