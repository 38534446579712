import { useCallback, useMemo, useRef } from "react";

import { ModularTableValueUpdate } from "@kraaft/shared/components/modular/details/utils";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { HeaderButton } from "@kraaft/web/src/components/modularFoldersTable/headerButton";
import { Table } from "@kraaft/web/src/components/modularTable/components/table";
import { TableHandle } from "@kraaft/web/src/components/modularTable/components/table/table";
import { ADDITIONAL_COLUMNS } from "@kraaft/web/src/components/modularTable/components/table/tableUtils";
import { ModularTableProps } from "@kraaft/web/src/components/modularTable/schema";
import {
  TableColumnsContext,
  TableContext,
} from "@kraaft/web/src/components/modularTable/tableContext";

import {
  MODULAR_ACTIONS_CELL_PADDING,
  MODULAR_ACTIONS_CELL_WIDTH,
} from "./modularFoldersTable";

import { useStyles } from "./modularFoldersTable.styles";

export const EditableSchemaTable = <T extends ModularRecord>({
  schema,
  rows,
  onCellUpdate,
  attachmentContext,
  actionCellFormatter,
  columnContext,
  enablePropertyEdit = false,
  disableRecordEdit = false,
  disablePreview = false,
}: {
  schema: KSchema;
  rows: T[];
  onCellUpdate: (updates: ModularTableValueUpdate[]) => void;
  attachmentContext?: ModularTableProps<T>["attachmentContext"];
  actionCellFormatter?: (item: { row: ModularRecord }) => JSX.Element;
  enablePropertyEdit?: boolean;
  disableRecordEdit?: boolean;
  disablePreview?: boolean;
  columnContext?: TableColumnsContext;
}) => {
  const classes = useStyles();

  const containerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<TableHandle>(null);

  const tableActions: ModularTableProps<T>["tableActions"] = useMemo(
    () => ({
      cell: { onUpdate: onCellUpdate },
    }),
    [onCellUpdate],
  );

  const additionalColumnsWidth = useMemo(
    () => MODULAR_ACTIONS_CELL_WIDTH + MODULAR_ACTIONS_CELL_PADDING * 2,
    [],
  );

  const handleExpandTableWidth = useCallback(() => {
    const availableWidth = containerRef.current?.clientWidth;

    if (availableWidth) {
      if (tableRef.current?.resetTableSize) {
        setTimeout(tableRef.current.resetTableSize);
      }
    }
  }, []);

  const expandButton = useMemo(
    () => (
      <HeaderButton iconName="expand-h" callback={handleExpandTableWidth} />
    ),
    [handleExpandTableWidth],
  );

  const tableState = useMemo<ModularTableProps<T>["tableState"]>(
    () => ({
      schema,
      rows,
    }),
    [rows, schema],
  );

  const tableContext = useMemo(() => {
    const context: TableContext = {
      columns: columnContext ?? {},
      records: {},
      additionalColumns: [
        {
          name: "",
          key: ADDITIONAL_COLUMNS.actionCell,
          width: additionalColumnsWidth,
          maxWidth: additionalColumnsWidth,
          resizable: false,
          formatter: actionCellFormatter,
          // works weirdly
          headerRenderer: () => (
            <div className={classes.headerAdditionalColumns}>
              {expandButton}
            </div>
          ),
        },
      ],
    };

    return context;
  }, [
    actionCellFormatter,
    additionalColumnsWidth,
    classes.headerAdditionalColumns,
    columnContext,
    expandButton,
  ]);

  return (
    <div ref={containerRef} className={classes.container}>
      <Table
        fwdRef={tableRef}
        editableHeader={enablePropertyEdit}
        tableState={tableState}
        tableActions={tableActions}
        attachmentContext={attachmentContext}
        tableContext={tableContext}
        allowEdit={!disableRecordEdit}
        disablePreview={disablePreview}
        recordType="room"
        noCheckboxConfirmation={false}
      />
    </div>
  );
};
