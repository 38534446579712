import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: Spacing.S8,
    flexGrow: 1,
  },
});
