import { makeStyles } from "@mui/styles";

import { FontSize } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    height: "100%",
    width: "100%",
    padding: "4px 0",
    flex: 1,
  },
  value: {
    fontSize: FontSize.MEDIUM,
  },
  notEditableContainer: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    padding: "0 8px",
  },
  notEditableContent: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    minHeight: 1,
  },
});
