import { spawn } from "typed-redux-saga/macro";

import { taskTemplateSaga } from "./taskTemplate/taskTemplateSaga";
import { schemaTemplateReducers } from "./schemaTemplateReducer";
import { schemaTemplateSaga as baseSchemaTemplateSaga } from "./schemaTemplateSagas";

export function* schemaTemplateSaga() {
  yield* spawn(baseSchemaTemplateSaga);
  yield* spawn(taskTemplateSaga);
}

export { schemaTemplateReducers };
