import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, ViewStyle } from "react-native";

import { Icon, Text } from "@kraaft/ui";

import { styles } from "./collapseLine.styles";

interface CollapseLineProps {
  onPress: (newValue: boolean) => void;
  title: string;
  open: boolean;
  style?: ViewStyle;
}

const CollapseLine = ({ onPress, title, open, style }: CollapseLineProps) => {
  const { t } = useTranslation();

  const finalStyle = useMemo(() => [styles.root, style], [style]);

  const handlePress = useCallback(() => {
    onPress(!open);
  }, [onPress, open]);

  const accessibilityLabel = open ? t("close") : t("open");

  return (
    <Pressable
      accessibilityLabel={accessibilityLabel}
      style={finalStyle}
      onPress={handlePress}
    >
      <Icon color="ACTION_CTA" name={open ? "chevron-up" : "chevron-down"} />
      <Text style={styles.title}>{title}</Text>
    </Pressable>
  );
};

export { CollapseLine };
