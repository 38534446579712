import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  sectionContainer: {
    paddingLeft: Spacing.S16,
    paddingRight: Spacing.S16,
  },
  toggleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S16,
  },
  inboundContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 500,
  },
  description: {
    marginBottom: Spacing.S16,
  },
  inboundInput: {
    flex: 1,
  },
});
