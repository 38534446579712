import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame/kDialogFrame";

import { DeleteOptionDialogContent } from "./deleteOptionDialogContent";

interface DialogProps {
  open: boolean;
  onClose: () => void;
  option: string;
  onDelete: () => void;
}
export const DeleteOptionDialog = (props: DialogProps) => {
  const { open, onClose, option, onDelete } = props;

  return (
    <KDialogFrame open={open} onClose={onClose}>
      <DeleteOptionDialogContent
        option={option}
        onDelete={onDelete}
        onClose={onClose}
      />
    </KDialogFrame>
  );
};
