import { View } from "react-native";

import { PoolLogo } from "@kraaft/shared/components/poolLogo";
import { useExternalAwarePoolName } from "@kraaft/shared/components/poolsMenu/poolsMenu.utils";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { PoolLocation } from "@kraaft/shared/core/modules/pool/poolState";
import { Text } from "@kraaft/ui";

import { useExternalPoolVariant } from "./useExternalPoolVariant";

import { styles } from "./poolsMenuItem.styles";

interface Props {
  location: PoolLocation;
  pool?: Pool;
  notifCount: number;
  noPadding?: boolean;
}

export const PoolsMenuItem = ({
  location,
  pool,
  notifCount,
  noPadding,
}: Props) => {
  const withExternalVariant = useExternalPoolVariant(location.poolId);
  const poolLogoName = useExternalAwarePoolName(pool, withExternalVariant);

  return (
    <View style={[styles.container, !noPadding && styles.paddedContainer]}>
      <PoolLogo
        poolId={location.poolId}
        withExternalVariant={withExternalVariant}
        variant="small"
      />
      <Text style={styles.label} numberOfLines={1}>
        {poolLogoName}
      </Text>
      {notifCount !== 0 && <Text style={styles.notif}>{notifCount}</Text>}
    </View>
  );
};
