import { CSSTransition } from "react-transition-group";

import {
  AnimationContext,
  useAnimationContext,
} from "@kraaft/web/src/core/services/animationContext/animationContext";

import { ANIMATION_DURATION, useStyles } from "./fadeIn.styles";

interface FadeInProps {
  children: React.ReactNode;
  visible: boolean;
}

export const FadeIn = ({ children, visible }: FadeInProps) => {
  const classes = useStyles({
    visible,
  });

  const [animationContextValue, animationCallbackProps] = useAnimationContext({
    defaultAnimationState: visible ? "entered" : "exited",
  });

  return (
    <AnimationContext.Provider value={animationContextValue}>
      <CSSTransition
        in={visible}
        classNames={{
          enter: "fadeIn-enter",
          enterActive: "fadeIn-enter-active",
          exit: "fadeIn-exit",
          exitActive: "fadeIn-exit-active",
        }}
        timeout={ANIMATION_DURATION}
        {...animationCallbackProps}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.container}>{children}</div>
      </CSSTransition>
    </AnimationContext.Provider>
  );
};
