import { createReducer } from "@reduxjs/toolkit";

import {
  addUnknownMessages,
  MessageStateActions,
  reactToMessageFailed,
} from "@kraaft/shared/core/modules/message/messageActions";
import { MessageDataStateActions } from "@kraaft/shared/core/modules/message/messageData/messageData.actions";
import { MessageDataState } from "@kraaft/shared/core/modules/message/messageData/messageData.state";
import {
  hasGeolocatedAttachment,
  isLogMessage,
  isPersisted,
  isUserMessage,
  messageHasText,
  setMessageInputPartitions,
} from "@kraaft/shared/core/modules/message/messageUtils";
import {
  deleteOptimisticMessage,
  errorSendingMessage,
  retrySendingMessage,
  successSendingMessage,
} from "@kraaft/shared/core/modules/message/send/sendMessageActions";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { isDev } from "@kraaft/shared/core/utils";
import { LinkedListHelpers } from "@kraaft/shared/core/utils/useBidirectional/linkedList";

const initialState: MessageDataState = {
  messages: {},
  linkedLists: {},
  messageDocs: {},
  fetchingUnknownMessages: {},
  messageIO: {},
};

export const messageDataReducer = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);

  addCase(
    MessageDataStateActions.addLinkedList,
    (state, { payload: { roomId, linkedList } }) => {
      const roomLinkedList = LinkedListHelpers.insert(
        state.linkedLists[roomId] ?? {},
        linkedList,
      );
      if (isDev) {
        LinkedListHelpers.checkSanity(roomLinkedList);
        LinkedListHelpers.checkBrokenLinks(roomLinkedList);
      }
      state.linkedLists[roomId] = roomLinkedList;
    },
  );

  addCase(
    MessageDataStateActions.addMessages,
    // eslint-disable-next-line complexity
    (
      state,
      { payload: { roomId, messages, messageDocs, shouldActAsNewMessage } },
    ) => {
      // Update room messages
      const roomMessages = state.messages[roomId] ?? {};

      for (const value of Object.values(messages)) {
        const doc = messageDocs[value.id];
        if (doc) {
          state.messageDocs[value.id] = doc;
        }
        roomMessages[value.id] = value;
        if (isPersisted(value) && isUserMessage(value) && value.optimisticId) {
          delete roomMessages[value.optimisticId];
        }
      }

      state.messages[roomId] = roomMessages;

      // Update message IO
      const messageIO = state.messageIO[roomId] ?? {
        sent: 0,
        received: 0,
      };

      const isUserSent = Object.values(messages).every(
        (message) =>
          isUserMessage(message) && message.sendingStatus !== "persisted",
      );

      const messageCount = Object.keys(messages).length;
      if (isUserSent) {
        messageIO.sent += messageCount;
      }
      if (shouldActAsNewMessage && !isUserSent) {
        messageIO.received += messageCount;
      }
      state.messageIO[roomId] = messageIO;
    },
  );

  addCase(
    addUnknownMessages,
    (state, { payload: { messages, roomId, initialMessageIds } }) => {
      for (const messageId of initialMessageIds) {
        delete state.fetchingUnknownMessages[messageId];
      }
      const room = state.messages[roomId] ?? {};
      Object.assign(room, messages);
      state.messages[roomId] = room;
    },
  );

  addCase(
    errorSendingMessage,
    (state, { payload: { roomId, optimisticId } }) => {
      const optimisticMessage = state.messages[roomId]?.[optimisticId];
      if (optimisticMessage && !isLogMessage(optimisticMessage)) {
        optimisticMessage.sendingStatus = "error";
      }
    },
  );

  addCase(
    retrySendingMessage,
    (state, { payload: { roomId, optimisticId } }) => {
      const optimisticMessage = state.messages[roomId]?.[optimisticId];
      if (optimisticMessage && !isLogMessage(optimisticMessage)) {
        optimisticMessage.sendingStatus = "sending";
      }
    },
  );

  addCase(deleteOptimisticMessage, (state, { payload }) => {
    const { roomId, optimisticId } = payload;

    delete state.messages[roomId]?.[optimisticId];
  });

  addCase(successSendingMessage, (state, { payload }) => {
    const { roomId, optimisticId } = payload;
    const message = state.messages[roomId]?.[optimisticId];
    if (
      message &&
      !isLogMessage(message) &&
      message.sendingStatus === "sending"
    ) {
      message.sendingStatus = "optimistic";
      message.createdAt = new Date();
    }
  });

  addCase(reactToMessageFailed, (state, { payload }) => {
    const message = state.messages[payload.roomId]?.[payload.messageId];
    if (!message) {
      return;
    }
    if (!isUserMessage(message)) {
      return;
    }
    if (payload.lastEmoji) {
      message.reactions[payload.userId] = {
        reactedAt: new Date(),
        emoji: payload.lastEmoji,
      };
    } else {
      delete message.reactions[payload.userId];
    }
  });

  addCase(
    MessageStateActions.setAttachmentGeolocation,
    (state, { payload: { roomId, messageId, geolocation } }) => {
      const message = state.messages[roomId]?.[messageId];

      if (!message) {
        return;
      }

      const attachment = hasGeolocatedAttachment(message)
        ? message.attachment
        : undefined;

      if (!attachment) {
        return;
      }

      if (geolocation) {
        attachment.geolocation = geolocation;
      } else {
        attachment.geolocation = undefined;
      }
    },
  );

  addCase(MessageStateActions.editMessageText, (state, { payload }) => {
    const { roomId, messageId, text } = payload;
    const message = state.messages[roomId]?.[messageId];
    if (message && messageHasText(message)) {
      setMessageInputPartitions(message, text);
    }
  });

  addCase(
    MessageStateActions.reactToMessageOptimistic,
    (state, { payload }) => {
      const message = state.messages[payload.roomId]?.[payload.messageId];
      if (!message) {
        return;
      }
      if (!isUserMessage(message)) {
        return;
      }
      const existingReaction = message.reactions[payload.userId];
      if (existingReaction && existingReaction.emoji === payload.emoji) {
        delete message.reactions[payload.userId];
      } else {
        message.reactions[payload.userId] = {
          reactedAt: new Date(),
          emoji: payload.emoji,
        };
      }
    },
  );
});
