import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { compact } from "lodash";

import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import {
  ActionSheetItem,
  LegacyActionSheet,
} from "@kraaft/shared/components/legacyActionSheet";
import { ModularFoldersMenuButtonProps } from "@kraaft/shared/components/modularFolders/modularFoldersMenuButton/modularFoldersMenuButton.props";
import { useOnChangeModularFoldersSelectionType } from "@kraaft/shared/components/modularFolders/modularFoldersSelection.context";
import { selectSchemaType } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { EditModularFoldersPopup } from "@kraaft/web/src/components/editModularFoldersPopup/editModularFoldersPopup";
import { ImportChecklistTemplate } from "@kraaft/web/src/components/importChecklistTemplateDialog/importChecklistTemplateDialog";
import { ImportModularFoldersSheet } from "@kraaft/web/src/components/importModularFoldersSheet/importModularFolders.sheet";

export const ModularFoldersMenuButton = ({
  roomId,
  schemaId,
}: ModularFoldersMenuButtonProps) => {
  const menuRef = useRef(null);
  const { t } = useTranslation();
  const [isChecklistMenuOpen, openChecklistMenu, closeChecklistMenu] =
    useBooleanState();
  const [isTemplateDialogOpen, openTemplateDialog, closeTemplateDialog] =
    useBooleanState();
  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useBooleanState();

  const onChangeSelectionType = useOnChangeModularFoldersSelectionType();

  const schemaType = useSelector(selectSchemaType(schemaId));

  const {
    element: importModularFoldersSheet,
    open: openImportModularFoldersSheet,
  } = ImportModularFoldersSheet.use({
    roomId,
    schemaId,
  });

  const actions = useMemo<ActionSheetItem[]>(
    () =>
      compact([
        {
          label: t("modularFoldersMenu.bulkExport"),
          icon: "check-done-01",
          onPress: () => onChangeSelectionType("download"),
        },
        {
          label: t("modularFoldersMenu.bulkEdit"),
          icon: "edit-05",
          onPress: openEditDialog,
        },
        {
          label: t("modularFoldersMenu.importFromTable"),
          icon: "log-in-02",
          onPress: openImportModularFoldersSheet,
        },
        schemaType === "checkbox" && {
          label: t("modularFoldersMenu.importFromTemplates"),
          icon: "list",
          onPress: openTemplateDialog,
        },
      ]),
    [
      onChangeSelectionType,
      openEditDialog,
      openImportModularFoldersSheet,
      openTemplateDialog,
      schemaType,
      t,
    ],
  );

  return (
    <>
      <div ref={menuRef}>
        <HeaderIconButton
          id="ide2e-tasks-menu"
          accessibilityLabel={t("menu")}
          icon="dots-vertical"
          onPress={openChecklistMenu}
        />
        {menuRef && (
          <LegacyActionSheet
            anchorRef={menuRef}
            options={actions}
            open={isChecklistMenuOpen}
            onClose={closeChecklistMenu}
          />
        )}
      </div>
      {importModularFoldersSheet}
      <ImportChecklistTemplate
        onClose={closeTemplateDialog}
        open={isTemplateDialogOpen}
        roomId={roomId}
      />
      <EditModularFoldersPopup
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        schemaId={schemaId}
      />
    </>
  );
};
