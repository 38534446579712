import { makeStyles } from "@mui/styles";

const ANIMATION_PAGE_DURATION = 500;

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flexGrow: 1,
    flexShrink: 1,
    width: "100%",
    overflow: "hidden",
  },

  transitionContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
  },

  absoluteContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },

  withSlideInAnimation: {
    animation: "$slideIn",
    animationDuration: `${ANIMATION_PAGE_DURATION}ms`,
    animationFillMode: "forwards",
    animationTimingFunction: "ease",
  },

  withSlideOutAnimation: {
    animation: "$slideOut",
    animationDuration: `${ANIMATION_PAGE_DURATION}ms`,
    animationFillMode: "forwards",
    animationTimingFunction: "ease",
  },

  "@keyframes slideIn": {
    "0%": {
      transform: "translateX(100%)",
      opacity: 0,
    },
    "100%": {
      transform: "translateX(0%)",
      opacity: 1,
    },
  },

  "@keyframes slideOut": {
    "0%": {
      transform: "translateX(0%)",
      opacity: 1,
    },
    "100%": {
      transform: "translateX(-100%)",
      opacity: 0,
    },
  },
});
