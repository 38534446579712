import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  startPolling,
  stopPolling,
} from "@kraaft/shared/core/modules/polling/pollingActions";
import { serializePollingDescriptor } from "@kraaft/shared/core/modules/polling/pollingHelper";
import { selectPollingData } from "@kraaft/shared/core/modules/polling/pollingSelectors";
import {
  PollableFunctions,
  PollingDescriptor,
  PoolingData,
} from "@kraaft/shared/core/modules/polling/pollingState";

export const usePolling = <T extends keyof PollableFunctions>({
  delay,
  ...descriptor
}: PollingDescriptor<T> & { delay: number }): PoolingData => {
  const dispatch = useDispatch();
  const key = serializePollingDescriptor(descriptor);
  const data = useSelector(selectPollingData(key));

  useEffect(() => {
    if (!delay) {
      return;
    }

    const {
      payload: { subscriberId },
    } = dispatch(startPolling({ ...descriptor, delay }));

    return () => {
      dispatch(stopPolling({ ...descriptor, subscriberId }));
    };
    // optimization since descriptor change only when key change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, delay]);

  return data || null;
};
