import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import { setPoolById } from "@kraaft/shared/core/modules/pool/poolActions";
import { SuperadminApi } from "@kraaft/shared/core/services/api/superadminApi";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { LanguageDropdown } from "@kraaft/web/src/views/settings/managePool/settingsLanguage/languageDropdown";
import { TimeZoneDropdown } from "@kraaft/web/src/views/settings/managePool/settingsTimeZone/timeZoneDropdown";

import { useStyles } from "./dialog.styles";

const CreatePoolDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [language, setLanguage] = useState<PoolLanguage>("fr-FR");
  const [timeZone, setTimeZone] = useState("Europe/Paris");
  const [name, setName] = useState<string>("");
  const [warning, setWarning] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const title = t("superadminCreatePool");

  const content = (
    <div className={classes.dialogContentContainer}>
      <div className={classes.item}>
        <KInput
          label={t("superadminCreatePoolName")}
          value={name}
          onChangeText={setName}
        />
      </div>
      <div className={classes.item}>
        <div className={classes.warning}>{warning}</div>
      </div>
      <div className={classes.item}>
        <div className={classes.title}>{t("superadminCreatePoolLanguage")}</div>
        <LanguageDropdown
          language={language}
          onChange={setLanguage}
          variant="dark"
        />
      </div>
      <div className={classes.item}>
        <div className={classes.title}>{t("adminTimeZone.title")}</div>
        <TimeZoneDropdown
          timeZone={timeZone}
          onChange={setTimeZone}
          variant="dark"
        />
      </div>
    </div>
  );

  const onConfirm = async () => {
    setIsLoading(true);
    try {
      trackEvent({
        eventName: "Create Workspace",
        from: "admin",
        pool_name: name,
        timeZone,
      });

      const poolId = await SuperadminApi.createPool({
        name,
        language,
        timeZone,
      });

      onClose();
      dispatch(setPoolById({ poolId }));
    } catch (error) {
      if (error.message === "poolNameAlreadyTaken") {
        setWarning(t("superadminPoolNameTaken"));
      } else {
        dispatch(
          showError({ title: t("internalError"), message: error.message }),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setWarning("");
  }, [name]);

  const actions: FooterActions = [
    {
      accessibilityLabel: t("confirm"),
      text: t("confirm"),
      onPress: onConfirm,
      loading: isLoading,
      variant: "PRIMARY",
    },
    {
      accessibilityLabel: t("cancel"),
      text: t("cancel"),
      onPress: onClose,
    },
  ];

  return (
    <KDialog
      open={open}
      onClose={onClose}
      title={title}
      content={content}
      actions={actions}
    />
  );
};

export { CreatePoolDialog };
