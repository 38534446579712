import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    height: "100%",
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    backgroundColor: Color.WHITE,
  },
  header: {
    padding: `${Spacing.S24}px ${Spacing.S32}px 0px ${Spacing.S32}px `,
  },

  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S16,
  },
  schemaNameWithIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
  },

  divider: {
    height: 1,
    width: "100%",
    border: "none",
    backgroundColor: ColorStyle.SEPARATOR,
  },

  content: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "center",
    marginTop: Spacing.S32,
  },

  leftContainer: {
    display: "flex",
    flexDirection: "column",

    width: 600,
    marginRight: Spacing.S40,
  },

  schemaNameTitle: {
    fontSize: 42,
    fontWeight: "bold",

    background: `linear-gradient(97.17deg, ${ColorStyle.ACTION_CTA} 3.15%, ${Color.BLUE_AZURE} 73.42%), ${Color.GREY_JET}`,
    backgroundClip: "text",
    textFillColor: "transparent",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },

  schemaDescription: {
    marginTop: Spacing.S24,
  },

  actionsContainer: {
    display: "flex",
    marginTop: Spacing.S24,
    gap: Spacing.S16,
  },

  videoContainer: {
    marginTop: Spacing.S32,
  },

  videoFrame: {
    aspectRatio: "16/9",
    width: "100%",
    height: "100%",
  },

  rightContainer: {
    marginLeft: Spacing.S16,
  },

  textSpacer: {
    marginBottom: Spacing.S16,
  },
});
