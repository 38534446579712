import { createReducer } from "@reduxjs/toolkit";

import * as actions from "./appActions";
import { AppState } from "./appState";

const initialState: AppState = {
  isActive: true,
  navigationSwitch: "loading",
  network: {
    isInternetReachable: true,
    networkType: "non-cellular",
  },
  inviteState: { status: "unknown" },
  isHandlingDeepLink: false,
};

// Reducers

const appReducers = createReducer(initialState, ({ addCase }) => {
  addCase(actions.changeAppState, (state, { payload }) => {
    state.isActive = payload;
  });

  addCase(actions.updateExtractEmail, (state, { payload }) => {
    state.extractEmail = payload;
  });

  addCase(actions.updateNetworkState, (state, { payload }) => {
    state.network = payload;
  });

  addCase(actions.AppStateActions.setNavigationSwitch, (state, { payload }) => {
    state.navigationSwitch = payload;
  });

  addCase(actions.updateInviteState, (state, { payload }) => {
    state.inviteState = payload;
  });

  addCase(
    actions.AppStateActions.setIsHandlingDeepLink,
    (state, { payload }) => {
      state.isHandlingDeepLink = payload.isHandlingDeepLink;
    },
  );

  addCase(actions.AppStateActions.setUtmParams, (state, { payload }) => {
    state.utmParams = payload;
  });

  addCase(
    actions.AppStateActions.setDynamicLinkForDebug,
    (state, { payload }) => {
      state.dynamicLinkForDebug = payload;
    },
  );

  addCase(
    actions.AppStateActions.showConversationOnboarding,
    (state, { payload }) => {
      state.showConversationOnboarding = payload;
    },
  );
});

export { appReducers };
