import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  autoUpdate,
  offset,
  safePolygon,
  useFloating,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { noop } from "ts-essentials";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { SUPPORT_URL } from "@kraaft/shared/constants/global";
import { intercom } from "@kraaft/shared/core/modules/intercom/intercom.provider";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Color, Radius, Spacing } from "@kraaft/ui";
import { ActionSheetMenu } from "@kraaft/web/src/components/actionSheetMenu";
import { ButtonElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/buttonElement";
import { useProlongateHoverDetection } from "@kraaft/web/src/core/utils/hover.styles";

const HelpCenterElement = () => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setOpen,
    placement: "right-end",
    middleware: [offset(Spacing.S12)],
    whileElementsMounted: autoUpdate,
  });
  const hover = useHover(context, {
    handleClose: safePolygon(),
    restMs: 100,
    delay: { open: 100 },
  });
  const classes = useStyles();
  const prolongateHoverDetectionClassname = useProlongateHoverDetection({
    size: Spacing.S12,
    position: "left",
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const goToHelpCenter = useCallback(() => {
    trackEvent({
      eventName: "Click On Help Center Button",
      from_page: "burger menu",
    });
    window.open(SUPPORT_URL, "_blank");
  }, []);

  const openChatWithSupport = useCallback(() => {
    trackEvent({
      eventName: "Click On Contact Support Button",
      from_page: "burger menu",
    });
    intercom.openChat();
  }, []);

  const onClose = useCallback(() => setOpen(false), []);

  const actions = useMemo<ActionSheetItem[]>(
    () => [
      {
        testID: "help-help-center",
        label: t("menuHelpCenter"),
        style: "default",
        onPress: goToHelpCenter,
      },
      {
        testID: "help-contact-support",
        label: t("menuContactSupport"),
        style: "default",
        onPress: openChatWithSupport,
      },
    ],
    [goToHelpCenter, openChatWithSupport, t],
  );

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <ButtonElement
          id="nav-help"
          icon="help-circle"
          text={t("menuHelp")}
          onPress={noop}
          withChevron
        />
      </div>
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className={clsx(
            classes.menuContainer,
            prolongateHoverDetectionClassname,
          )}
        >
          <ActionSheetMenu options={actions} open={isOpen} onClose={onClose} />
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles({
  menuContainer: {
    minWidth: 150,
    borderRadius: Radius.SMALL,
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
    backgroundColor: Color.WHITE,
  },
});

export { HelpCenterElement };
