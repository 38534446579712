import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Icon } from "@kraaft/ui";
import { KanbanColumnHeaderData } from "@kraaft/web/src/components/kanban/board/kanbanBoardTypes";
import { getLabelForColumn } from "@kraaft/web/src/components/kanban/board/kanbanBoardUtils";

import { useStyles } from "./kanbanColumnHeader.styles";

interface Props {
  column:
    | KSchemaColumn<KColumnType.selectSingle>
    | KSchemaColumn<KColumnType.selectMultiple>
    | KSchemaColumn<KColumnType.user>;
  kanbanColumnData: KanbanColumnHeaderData;
  itemCount: number;
  shouldDisplayBorderOver?: boolean;
}

const KanbanColumnHeader = (props: Props) => {
  const { column, kanbanColumnData, itemCount, shouldDisplayBorderOver } =
    props;
  const classes = useStyles();

  return (
    <span className={classes.columnHeader}>
      <span className={classes.columnHeaderContent}>
        <Icon
          name={
            kanbanColumnData.columnType !== undefined
              ? KSchemaUtils.getIcon(column)
              : "layers-three-01"
          }
          size="SMALL"
        />
        <span className={classes.columnHeaderLabel}>
          {getLabelForColumn(kanbanColumnData, column)}
        </span>
        <span className={classes.columnHeaderItemCount}>{itemCount}</span>
      </span>
      {shouldDisplayBorderOver && <span className={classes.withBorderOver} />}
    </span>
  );
};

export { KanbanColumnHeader };
