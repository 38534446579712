import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { EMOJI_WIDTH } from "@kraaft/shared/components/emojiSelector/emoji.utils";
import { Color } from "@kraaft/ui";

import { emojiSelectorStyles } from "./emojiSelector";

interface Props {
  gap: number;
  numColumns: number;
}

export const EmojiListSkeleton = ({ gap, numColumns }: Props) => {
  const { rows, columns } = useMemo(
    () => ({
      rows: Array(10).fill(null),
      columns: Array(numColumns).fill(null),
    }),
    [numColumns],
  );

  return (
    <View>
      {rows.map((_row, rowIndex) => (
        <View
          key={rowIndex.toString()}
          style={[emojiSelectorStyles.columnWrapperStyle, { gap }]}
        >
          {columns.map((_col, colIndex) => (
            <View key={colIndex.toString()} style={styles.item} />
          ))}
        </View>
      ))}
    </View>
  );
};

const MARGIN = 5;

const styles = StyleSheet.create({
  item: {
    flexGrow: 1,
    height: EMOJI_WIDTH - 2 * MARGIN,
    backgroundColor: Color.GREY_ANTIFLASH,
    borderRadius: EMOJI_WIDTH,
    margin: MARGIN,
  },
});
