import { useCallback, useEffect, useMemo, useState } from "react";
import { throttle } from "lodash";
import { noop } from "ts-essentials";

import { createMeshContext } from "@kraaft/helper-hooks";
import { ROOT_DIRECTORY_ID } from "@kraaft/shared/core/modules/directory/directory";
import {
  Directory,
  DirectoryTreeStructure,
  HoveredDirectoryTreeInfo,
} from "@kraaft/web/src/components/directoryTree/directoryTree.types";
import {
  optimisticCreateTemporaryDirectory,
  optimisticMoveDirectory,
  optimisticRemoveDirectory,
  optimisticRenameDirectory,
} from "@kraaft/web/src/components/directoryTree/directoryTree.utils";

function mergeDirectoryTreeStructures(
  lhs: DirectoryTreeStructure,
  rhs: DirectoryTreeStructure,
) {
  const newDirectoryTreeStructure: DirectoryTreeStructure = {
    directories: { ...rhs.directories },
    rootDirectoryIds: [...rhs.rootDirectoryIds],
  };

  const temporaryDirectories = Object.values(lhs.directories).reduce<
    Directory[]
  >((directories, directory) => {
    if (directory.isTemporary) {
      directories.push(directory);
    }
    return directories;
  }, []);

  for (const temporaryDirectory of temporaryDirectories) {
    if (temporaryDirectory.parentDirectoryId === undefined) {
      newDirectoryTreeStructure.directories[temporaryDirectory.id] =
        temporaryDirectory;
      newDirectoryTreeStructure.rootDirectoryIds.push(temporaryDirectory.id);
      continue;
    }
    const parentDirectory =
      newDirectoryTreeStructure.directories[
        temporaryDirectory.parentDirectoryId
      ];
    if (parentDirectory !== undefined) {
      newDirectoryTreeStructure.directories[temporaryDirectory.id] =
        temporaryDirectory;
      parentDirectory.childDirectoryIds.push(temporaryDirectory.id);
    }
  }

  return newDirectoryTreeStructure;
}

interface DirectoryTreeContextValues {
  directoryTreeStructure: DirectoryTreeStructure;
  isDraggingDirectory: boolean;
  setDraggingDirectoryState: (newState: boolean) => void;

  hoveredDirectoryTreeInfo: HoveredDirectoryTreeInfo | undefined;
  setHoveredDirectoryTreeInfo: (
    newState: HoveredDirectoryTreeInfo | undefined,
  ) => void;

  moveDirectory: (directory: Directory, info: HoveredDirectoryTreeInfo) => void;
  renameDirectory: (directoryId: string, newName: string) => void;
  removeDirectory: (directoryId: string) => void;

  createTemporaryDirectory: (parentDirectoryId: string | undefined) => void;
  persistTemporaryDirectory: (
    directoryId: string,
    name: string,
    parentDirectoryId: string | undefined,
  ) => void;
  removeTemporaryDirectory: (directoryId: string) => void;
}

const contextDefaultValue: DirectoryTreeContextValues = {
  directoryTreeStructure: { rootDirectoryIds: [], directories: {} },

  isDraggingDirectory: false,
  setDraggingDirectoryState: noop,

  hoveredDirectoryTreeInfo: undefined,
  setHoveredDirectoryTreeInfo: noop,

  moveDirectory: noop,
  renameDirectory: noop,
  removeDirectory: noop,
  createTemporaryDirectory: noop,
  persistTemporaryDirectory: noop,
  removeTemporaryDirectory: noop,
};

export const DirectoryTreeContext =
  createMeshContext<DirectoryTreeContextValues>();

interface UseDirectoryTreeContextProviderValueProps {
  directoryTreeStructure: DirectoryTreeStructure;
  onUpdate?: (directories: DirectoryTreeStructure) => void;
  onAddDirectory?: (name: string, parentDirectoryId?: string) => void;
  onMoveDirectory?: (
    directoryId: string,
    newParentDirectoryId: string,
    afterSiblingDirectoryId?: string,
  ) => void;
  onRenameDirectory?: (directoryId: string, newDirectoryName: string) => void;
  onRemoveDirectory?: (directoryId: string) => Promise<boolean> | boolean;
}

export const useDirectoryTreeContextProviderValue = (
  props: UseDirectoryTreeContextProviderValueProps,
) => {
  const {
    directoryTreeStructure: defaultDirectoryTreeStructure,
    onUpdate,
    onAddDirectory,
    onMoveDirectory,
    onRenameDirectory,
    onRemoveDirectory,
  } = props;

  const [isDraggingDirectory, setDraggingDirectoryState] = useState<
    DirectoryTreeContextValues["isDraggingDirectory"]
  >(contextDefaultValue.isDraggingDirectory);
  const [hoveredDirectoryTreeInfo, setHoveredDirectoryTreeInfo] = useState<
    DirectoryTreeContextValues["hoveredDirectoryTreeInfo"]
  >(contextDefaultValue.hoveredDirectoryTreeInfo);

  const setHoveredDirectoryTreeInfoDebounced = throttle(
    setHoveredDirectoryTreeInfo,
    250,
  );

  const [directoryTreeStructure, setDirectoryTreeStructure] =
    useState<DirectoryTreeStructure>(defaultDirectoryTreeStructure);

  useEffect(() => {
    setDirectoryTreeStructure((oldDirectoryTreeStructure) =>
      mergeDirectoryTreeStructures(
        oldDirectoryTreeStructure,
        defaultDirectoryTreeStructure,
      ),
    );
  }, [defaultDirectoryTreeStructure]);

  useEffect(() => {
    onUpdate?.(directoryTreeStructure);
  }, [directoryTreeStructure, onUpdate]);

  const moveDirectory = useCallback(
    (directory: Directory, info: HoveredDirectoryTreeInfo) => {
      setDirectoryTreeStructure((oldDirectoryTreeStructure) =>
        optimisticMoveDirectory(oldDirectoryTreeStructure, directory, info),
      );

      if (info.type === "between") {
        const afterSiblingDirectoryId =
          info.placement === "after" ? info.siblingDirectoryId : undefined;
        onMoveDirectory?.(
          directory.id,
          info.parentDirectoryId ?? ROOT_DIRECTORY_ID,
          afterSiblingDirectoryId,
        );
      } else {
        onMoveDirectory?.(directory.id, info.parentDirectoryId);
      }
    },
    [onMoveDirectory],
  );

  const renameDirectory = useCallback(
    (directoryId: string, newName: string) => {
      setDirectoryTreeStructure((oldDirectoryTreeStructure) =>
        optimisticRenameDirectory(
          oldDirectoryTreeStructure,
          directoryId,
          newName,
        ),
      );

      onRenameDirectory?.(directoryId, newName);
    },
    [onRenameDirectory],
  );

  const removeDirectory = useCallback(
    async (directoryId: string) => {
      const shouldRemoveOptimistically = onRemoveDirectory
        ? await onRemoveDirectory(directoryId)
        : true;

      if (shouldRemoveOptimistically === true) {
        setDirectoryTreeStructure((oldDirectoryTreeStructure) =>
          optimisticRemoveDirectory(oldDirectoryTreeStructure, directoryId),
        );
      }
    },
    [onRemoveDirectory],
  );

  const createTemporaryDirectory = useCallback(
    (parentDirectoryId: string | undefined) => {
      setDirectoryTreeStructure((oldDirectoryTreeStructure) =>
        optimisticCreateTemporaryDirectory(
          oldDirectoryTreeStructure,
          parentDirectoryId,
        ),
      );
    },
    [],
  );

  const removeTemporaryDirectory = useCallback(async (directoryId: string) => {
    setDirectoryTreeStructure((oldDirectoryTreeStructure) =>
      optimisticRemoveDirectory(oldDirectoryTreeStructure, directoryId),
    );
  }, []);

  const persistTemporaryDirectory = useCallback(
    (
      directoryId: string,
      name: string,
      parentDirectoryId: string | undefined,
    ) => {
      setDirectoryTreeStructure((oldDirectoryTreeStructure) =>
        optimisticRenameDirectory(oldDirectoryTreeStructure, directoryId, name),
      );

      onAddDirectory?.(name, parentDirectoryId);
    },
    [onAddDirectory],
  );

  const contextValue = useMemo<DirectoryTreeContextValues>(
    () => ({
      directoryTreeStructure,
      isDraggingDirectory,
      setDraggingDirectoryState,
      hoveredDirectoryTreeInfo,
      setHoveredDirectoryTreeInfo: setHoveredDirectoryTreeInfoDebounced,
      moveDirectory,
      renameDirectory,
      removeDirectory,
      createTemporaryDirectory,
      persistTemporaryDirectory,
      removeTemporaryDirectory,
    }),
    [
      directoryTreeStructure,
      isDraggingDirectory,
      hoveredDirectoryTreeInfo,
      setHoveredDirectoryTreeInfoDebounced,
      moveDirectory,
      renameDirectory,
      removeDirectory,
      createTemporaryDirectory,
      persistTemporaryDirectory,
      removeTemporaryDirectory,
    ],
  );

  return contextValue;
};
