import { useTranslation } from "react-i18next";
import { View } from "react-native";

import {
  ActionGroup,
  GroupActions,
} from "@kraaft/shared/components/actionGroup/actionGroup";
import { HideWhenKeyboard } from "@kraaft/shared/components/hideWhenKeyboard";
import { SearchBar } from "@kraaft/shared/components/searchBar";
import { AvatarList } from "@kraaft/shared/components/userSelection/avatarList";
import { AvatarContent } from "@kraaft/shared/components/userSelection/avatarList/avatarList.types";
import { useKeyboardState } from "@kraaft/shared/core/utils/useKeyboardState";

import { styles } from "./userList.styles";

interface Props {
  selectedAvatars: AvatarContent[];
  list: React.ReactNode;
  deselectUser?: (user: AvatarContent) => void;
  allowSearch?: boolean;
  allowEdition?: boolean;
  showAvatarList?: boolean;
  frozenUserIds?: Set<string>;
  filter?: string;
  setFilter?: (filter: string) => void;
  actions?: GroupActions;
  currentUserId: string | undefined;
}

const UserList = (props: Props) => {
  const {
    selectedAvatars,
    list,
    deselectUser,
    allowSearch = false,
    allowEdition = false,
    showAvatarList = false,
    frozenUserIds = new Set([]),
    currentUserId,
    filter = "",
    setFilter = () => {},
    actions = [],
  } = props;

  const { t } = useTranslation();
  const [isKeyboardOpen] = useKeyboardState();

  return (
    <>
      {allowSearch && (
        <View style={styles.searchBar}>
          <SearchBar
            isStatic
            withPlaceholderIcon
            withCancelButton={isKeyboardOpen}
            value={filter}
            onChange={setFilter}
            placeholder={t("search")}
          />
        </View>
      )}
      {showAvatarList && (
        <HideWhenKeyboard>
          <AvatarList
            items={selectedAvatars}
            frozenUserIds={frozenUserIds}
            onPress={allowEdition ? deselectUser : undefined}
            displayUserThumbnailOrInitials
            currentUserId={currentUserId}
          />
        </HideWhenKeyboard>
      )}
      {actions.length > 0 && (
        <HideWhenKeyboard>
          <View style={styles.actions}>
            <ActionGroup actions={actions} />
          </View>
        </HideWhenKeyboard>
      )}
      {(allowSearch || showAvatarList || actions.length > 0) && (
        <View style={styles.containerBorder} />
      )}
      <View style={styles.list}>{list}</View>
    </>
  );
};

export { UserList };
