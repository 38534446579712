import { eventChannel } from "redux-saga";
import { put, select, spawn, take, takeEvery } from "typed-redux-saga/macro";

import { LAUNCH_DARKLY_VERBOSE } from "@kraaft/shared/constants/global";
import { FeatureFlagsStateActions } from "@kraaft/shared/core/modules/featureFlags/featureFlags.actions";
import { selectFeatureFlags } from "@kraaft/shared/core/modules/featureFlags/featureFlags.selectors";
import { FeatureFlags } from "@kraaft/shared/core/modules/featureFlags/featureFlags.state";
import { allStoresRehydrated } from "@kraaft/shared/core/modules/memory/memoryActions";
import { analytics } from "@kraaft/shared/core/services/analytics";
import { LaunchDarklyService } from "@kraaft/shared/core/services/launchDarkly";

export function* featureFlagSagas() {
  yield* takeEvery(allStoresRehydrated, rehydratedSaga);
  yield* spawn(subscribeSaga);
}

function* rehydratedSaga() {
  const featureFlags = yield* select(selectFeatureFlags);
  analytics.setTrackContextFeatureFlags(featureFlags);
}

function* subscribeSaga() {
  const channel = createChannel();

  const initialFlags = yield* take(channel);

  if (LAUNCH_DARKLY_VERBOSE) {
    console.log("Initial feature flags received:", initialFlags);
  }
  yield* put(FeatureFlagsStateActions.set(initialFlags));
  analytics.setTrackContextFeatureFlags(initialFlags);

  yield* takeEvery(channel, receiveFeatureFlags);
}

function createChannel() {
  return eventChannel<FeatureFlags>((emit) => {
    async function init() {
      await LaunchDarklyService.init();
      await LaunchDarklyService.subscribe(emit);
    }

    init().catch(console.error);

    return () => {
      LaunchDarklyService.unsubscribe().catch(console.error);
    };
  });
}

function* receiveFeatureFlags(partials: FeatureFlags) {
  if (LAUNCH_DARKLY_VERBOSE) {
    console.log("Feature flags received:", partials);
  }
  yield* put(FeatureFlagsStateActions.setPartial(partials));

  const featureFlags = yield* select(selectFeatureFlags);
  analytics.setTrackContextFeatureFlags(featureFlags);
}
