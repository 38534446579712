import { StyleSheet } from "react-native";

import { MiniImage } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { Image } from "@kraaft/ui";

interface MiniMediaTemporaryCarouselImage {
  miniMedia: MiniImage;
}

export const MiniMediaTemporaryCarouselImage = ({
  miniMedia,
}: MiniMediaTemporaryCarouselImage) => {
  return (
    <Image
      source={miniMedia.preview.downloadUrl}
      preferredSize="medium"
      style={styles.image}
      contentFit="contain"
    />
  );
};

const styles = StyleSheet.create({
  image: {
    flexGrow: 1,
  },
});
