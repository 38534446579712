import capitalize from "lodash/capitalize";

import { KizeoFormExport } from "@kraaft/shared/core/modules/form/formState";
import { i18n } from "@kraaft/shared/core/services/i18next";

export const KizeoDefaultExport: KizeoFormExport = { type: "defaultPdf" };

export function getKizeoExportName(format: KizeoFormExport) {
  if (format.type === "defaultPdf") {
    return i18n.t("kizeoDefaultPdf");
  }
  const docType = capitalize(format.pdf ? "pdf" : format.docType);
  return `${format.name} (${docType})`;
}

const DISPLAYED_NAMES = 2;
export function getKizeoExportNames(exports: KizeoFormExport[] | undefined) {
  let newExports = exports || [KizeoDefaultExport];

  let ellipsis = "";
  if (newExports.length > DISPLAYED_NAMES) {
    ellipsis = ` (+${newExports.length - DISPLAYED_NAMES})`;
    newExports = newExports.slice(0, DISPLAYED_NAMES);
  }
  return newExports.map(getKizeoExportName).join(", ") + ellipsis;
}
