import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fromPairs } from "lodash";

import {
  getAttachmentWithGPS,
  getIndexedAttachmentWithGPS,
} from "@kraaft/shared/components/carousel/simpleCarousel/geolocationCarousel/utils";
import { GeolocatedElement } from "@kraaft/shared/components/geolocation/types";
import {
  Attachment,
  AttachmentType,
  AttachmentWithGPS,
  GEOLOCATED_ATTACHMENT_TYPES,
  IndexedAttachmentWithGPS,
} from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KFolderSchema,
  KSchemaColumnLiteralValue,
  KSchemaColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  ModularFolder,
  ModularFolderWithGeolocation,
  ModularRecordPropertiesWithTitle,
  SimplifiedModularFolder,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import {
  ModularRecord,
  ModularRecordEditionPayload,
  ModularRecordProperties,
  ModularRecordWithTitle,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { filterAttachments } from "@kraaft/shared/core/utils";

export const hasTitleProperty = (
  properties: ModularRecordProperties | undefined,
): properties is ModularRecordPropertiesWithTitle =>
  Boolean(properties?.title?.value);

export const hasModularRecordTitle = (
  record: ModularRecord | undefined,
): record is ModularRecordWithTitle => hasTitleProperty(record?.properties);

export const getModularRecordTitle = (
  record: ModularRecordWithTitle | undefined,
): string => {
  return record ? record.properties.title.value : "";
};

export function getModularFolderGeolocation(
  modularFolder: ModularFolderWithGeolocation,
) {
  return modularFolder.geolocation;
}

export const getModularFolderHighlighedCheckboxValue = (
  highlightedCheckboxColumnKey: string | undefined,
  modularFolder: ModularFolder,
) => {
  if (!highlightedCheckboxColumnKey) {
    return undefined;
  }
  return ModularRecordUtils.findColumn(
    modularFolder.properties,
    highlightedCheckboxColumnKey,
    KColumnType.checkbox,
  );
};

export const getModularFolderAttachmentColumn = <T extends AttachmentType>(
  modularFolder: SimplifiedModularFolder,
  columnKey: string | undefined,
  types: readonly T[],
): Extract<Attachment, { type: T }>[] => {
  if (!columnKey) {
    return getModularFolderAttachments(modularFolder, types);
  }
  const property = modularFolder.properties[columnKey] as
    | KSchemaColumnValue<KColumnType.attachment>
    | undefined;

  if (property?.columnType === KColumnType.attachment) {
    return filterAttachments(property.value, types);
  }

  return [];
};

export const getModularFolderAttachments = <T extends AttachmentType>(
  record: SimplifiedModularFolder,
  types: readonly T[],
): Extract<Attachment, { type: T }>[] => {
  const attachments: Extract<Attachment, { type: T }>[] = [];

  for (const property of Object.values(record.properties)) {
    if (property.columnType === KColumnType.attachment) {
      const value =
        property.value as KSchemaColumnLiteralValue<KColumnType.attachment>;
      if (value) {
        attachments.push(...filterAttachments(value, types));
      }
    }
  }

  return attachments;
};

export function modularFolderHasGeolocation(
  modularFolder: ModularFolder,
): modularFolder is ModularFolderWithGeolocation {
  return (
    modularFolder.geolocation !== undefined &&
    modularFolder.geolocation !== null
  );
}

export function getGeolocatedModularFolders(modularFolders: ModularFolder[]) {
  return modularFolders.filter((modularFolder) =>
    modularFolderHasGeolocation(modularFolder),
  ) as ModularFolderWithGeolocation[];
}

export function formatGeolocatedModularFolders(
  modularFolders: ModularFolderWithGeolocation[],
): GeolocatedElement<ModularFolderWithGeolocation>[] {
  return modularFolders.map((modularFolder) => ({
    ref: modularFolder,
    id: modularFolder.id,
    geolocation: getModularFolderGeolocation(modularFolder),
  }));
}

export function getGeolocatedModularFolderAttachments(
  modularFolder: ModularFolder,
): AttachmentWithGPS[] {
  const attachments = getModularFolderAttachments(
    modularFolder,
    GEOLOCATED_ATTACHMENT_TYPES,
  );

  return getAttachmentWithGPS(attachments);
}

export function getIndexedGeolocatedModularFolderCarouselItems(
  modularFolder: ModularFolder,
) {
  const attachments = getModularFolderAttachments(
    modularFolder,
    GEOLOCATED_ATTACHMENT_TYPES,
  );
  return getIndexedAttachmentWithGPS(attachments);
}

export function formatGeolocatedModularFolderAttachments(
  attachments: IndexedAttachmentWithGPS[],
): GeolocatedElement<IndexedAttachmentWithGPS>[] {
  return attachments.map((attachment) => ({
    ref: attachment,
    id: attachment.id,
    geolocation: attachment.geolocation,
  }));
}

export function useModularFolderTitle(schemaName: string | undefined) {
  const { t } = useTranslation();

  if (!schemaName) {
    return "";
  }

  return t("modularFolderInformations", { schemaName });
}

export function getCompletedCheckboxRatio(
  modularFolders: ModularFolder[],
  highlightedCheckboxColumnKey: string | undefined,
): string {
  let completedCounter = 0;
  if (highlightedCheckboxColumnKey) {
    for (const checkbox of modularFolders) {
      if (checkbox.properties[highlightedCheckboxColumnKey]?.value) {
        completedCounter++;
      }
    }
  }

  return `${completedCounter}/${modularFolders.length}`;
}

export function getModularFolderSummary(
  highlightedCheckboxColumnKey: string | undefined,
  modularFolders: ModularFolder[],
) {
  if (highlightedCheckboxColumnKey) {
    return getCompletedCheckboxRatio(
      modularFolders,
      highlightedCheckboxColumnKey,
    );
  }
  return modularFolders.length.toString();
}

export function useModularFolderUpdate(modularFolderId: string) {
  const dispatch = useDispatch();

  return useCallback(
    (updatedValues: ModularRecordEditionPayload[]) => {
      if (modularFolderId) {
        dispatch(
          OfflineModularFolderActions.edit({
            editions: [
              {
                id: modularFolderId,
                properties: updatedValues,
              },
            ],
          }),
        );
      }
    },
    [dispatch, modularFolderId],
  );
}

export function applyRecordUpdate(
  properties: ModularRecord["properties"],
  update: Partial<ModularRecord["properties"]>,
) {
  Object.assign(properties, update);
}

// Used to send the lightest payload we can
export function cleanRecordPropertiesForNetwork(
  properties: ModularRecordProperties[],
): ModularRecordProperties[] {
  return properties.map((property) =>
    fromPairs(
      Object.entries(property).filter(
        ([_, value]) => value.value !== undefined,
      ),
    ),
  );
}

export function optimisticallyCreateIncrementTitle(schema: KFolderSchema) {
  const titleColumn = KSchemaUtils.findColumn(
    schema.rootSection,
    KSchemaRemarkableColumns.TITLE,
  );
  if (
    !titleColumn ||
    (titleColumn.type !== KColumnType.shortText &&
      titleColumn.type !== KColumnType.automatedAutoIncrement)
  ) {
    return "";
  }
  if (!KSchemaUtils.isColumnAutomated(titleColumn)) {
    return `${schema.name} #${schema.autoIncrement + 1}`;
  }
  return `${titleColumn.prefix}${schema.autoIncrement + 1}`;
}
