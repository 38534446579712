import { StyleSheet } from "react-native";

import { Color } from "@kraaft/ui";

export const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: Color.WHITE,
    borderRadius: 10,
    justifyContent: "center",
  },
});
