import { View } from "react-native";

import { getPillForColumn } from "@kraaft/shared/components/modular/pill/modularPillFactory";
import {
  ExtraContextForPillRenderer,
  isValidSchemaColumnForPills,
  PillsDisplayOptions,
} from "@kraaft/shared/components/modular/pill/utils/types";
import {
  KSchemaColumn,
  KSchemaColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordProperty } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { filterNullableChildren } from "@kraaft/shared/core/utils/filterNullableChildren";

import { styles } from "./modularPill.styles";

interface Props {
  compactRenderer?: boolean;
  withSpacer?: boolean;

  modularRecordProperty: ModularRecordProperty | undefined;
  schemaColumn: KSchemaColumn;
  extraContext: ExtraContextForPillRenderer;
  displayOptions?: PillsDisplayOptions | undefined;
}

const ModularPill = (props: Props) => {
  const {
    compactRenderer = false,
    withSpacer,
    modularRecordProperty,
    schemaColumn,
    extraContext,
    displayOptions,
  } = props;

  const maybeChildren = filterNullableChildren(
    isValidSchemaColumnForPills(schemaColumn) &&
      (modularRecordProperty === undefined ||
        modularRecordProperty.columnType === schemaColumn.type)
      ? getPillForColumn(compactRenderer, {
          schemaColumn: schemaColumn as KSchemaColumn<
            (typeof schemaColumn)["type"]
          >,
          property: modularRecordProperty as
            | KSchemaColumnValue<(typeof schemaColumn)["type"]>
            | undefined,
          extraContext,
          displayOptions,
        })
      : null,
  );

  return maybeChildren ? (
    <View style={withSpacer && styles.withSpacer}>{maybeChildren}</View>
  ) : null;
};

export { ModularPill };
