import { ChangeEvent, MouseEvent, useCallback } from "react";
import { Switch as MaterialSwitch } from "@mui/material";

import { ToggleProps } from "@kraaft/shared/components/toggle/toggleProps";

const SX = {
  marginLeft: "-6px", // change marginLeft and marginRight to align the switch with other components
  marginRight: "-4px",
};

const Toggle = ({ setValue, value, id }: ToggleProps) => {
  const onWebSwitchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newValue: boolean) => {
      event.stopPropagation();
      setValue(newValue);
    },
    [setValue],
  );

  const stopPropagation = useCallback(
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      event.stopPropagation();
    },
    [],
  );

  return (
    <MaterialSwitch
      role="checkbox"
      aria-checked={value}
      id={id}
      checked={value}
      onChange={onWebSwitchChange}
      onClick={stopPropagation}
      centerRipple={false}
      disableRipple
      disableTouchRipple
      disableFocusRipple
      sx={SX}
    />
  );
};

export { Toggle };
