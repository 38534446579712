import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { ModularFolderDetails } from "@kraaft/shared/components/modularFolders/modularFolderDetails";
import { ModularFolderDetailsMenu } from "@kraaft/shared/components/modularFolders/modularFolderDetails/modularFolderDetailsMenu";
import { useModularFolderStatus } from "@kraaft/shared/components/modularFolders/modularFolderDetails/useModularFolderStatus";
import { useModularFolderTitle } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { selectSchemaName } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { DetailsRoute } from "@kraaft/web/src/components/detailsRoute";
import { detailsQuery } from "@kraaft/web/src/views/app/appRouter/routes";

export const ModularFolderRoute = ({
  roomId,
  schemaId,
  folderId,
  onBackButtonPress,
  hideBackButton,
}: {
  roomId: string;
  schemaId: string;
  folderId: string;
  onBackButtonPress: () => void;
  hideBackButton: boolean;
}) => {
  const { t } = useTranslation();
  const schemaName = useSelector(selectSchemaName(schemaId));
  const { considerNoConnection, isDeleted } = useModularFolderStatus(
    roomId,
    schemaId,
    folderId,
  );
  const notAccessible = considerNoConnection || isDeleted;
  const accessibleTitle = useModularFolderTitle(schemaName);
  const title = notAccessible
    ? t("modularFolderNotAccessible")
    : accessibleTitle;

  return (
    <DetailsRoute
      headerId="ide2e-modularFolder-header"
      contentId="ide2e-modularFolder-content"
      path={[detailsQuery.modularFolder, detailsQuery.modularFolderHistory]}
      title={title}
      hideBackButton={hideBackButton}
      onBackButtonPress={onBackButtonPress}
      headerRight={
        <HeaderSide>
          <ModularFolderDetailsMenu
            roomId={roomId}
            schemaId={schemaId}
            modularFolderId={folderId}
          />
        </HeaderSide>
      }
    >
      <ModularFolderDetails
        roomId={roomId}
        schemaId={schemaId}
        modularFolderId={folderId}
        portalHostname="detailsDrawer"
      />
    </DetailsRoute>
  );
};
