import { makeStyles } from "@mui/styles";

import { ColorStyle, Spacing } from "@kraaft/ui";

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headerContentContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: Spacing.S16,
  },
  headerRightIcons: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: Spacing.S8,
  },
  listContainer: {
    flexGrow: 1,
    minHeight: 0,
  },
});

export const inlineStyles: WebStyleSheet = {
  input: {
    flexGrow: 1,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    padding: "8px 16px",
  },
};

export { useStyles };
