import { StyleSheet } from "react-native";

import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: Color.WHITE,
  },
  galleryContainer: {
    flexGrow: 1,
    paddingLeft: Spacing.S16,
  },
});
