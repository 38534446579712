import { ReactNode, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { kebabCase } from "lodash/fp";

import { Direction, FadeIn, SlideIn } from "@kraaft/shared/components/animated";
import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { DatadogService } from "@kraaft/shared/core/services/datadog";
import { ViewParams } from "@kraaft/shared/core/services/datadog/sdk/types";
import { useAndroidBackHandler } from "@kraaft/shared/core/utils/useAndroidBackHandler";
import { IconName, withPortal } from "@kraaft/ui";

import { styles } from "./slidePanel.styles";

export interface SlidePanelProps {
  children: ReactNode;
  name: string;
  portalHostname?: string;
  title?: string;
  open?: boolean;
  onClose?: () => void;
  direction?: Direction;
  disablePanelAnimation?: boolean;
  zIndex?: number;
  closeIcon?: IconName;
  headerRight?: ReactNode;
  closeIconId?: string;
  withDimmedBackground?: boolean;
}

const SlidePanel_ = ({
  children,
  name,
  title,
  open = false,
  onClose,
  direction,
  disablePanelAnimation,
  zIndex,
  closeIcon = "chevron-left",
  headerRight,
  closeIconId,
  withDimmedBackground,
}: SlidePanelProps) => {
  const { t } = useTranslation();

  useAndroidBackHandler(
    useCallback(() => {
      if (open && onClose) {
        onClose();
        return true;
      }
    }, [onClose, open]),
  );

  useEffect(() => {
    const viewParams: ViewParams = { name, key: kebabCase(name) };

    if (open) {
      DatadogService.startView(viewParams).catch(console.error);

      return () => {
        DatadogService.stopView(viewParams).catch(console.error);
      };
    }
  }, [name, open]);

  return (
    <>
      {withDimmedBackground && (
        <FadeIn visible={open}>
          <View style={styles.dimmedBackground} />
        </FadeIn>
      )}
      <SlideIn
        visible={open}
        direction={direction}
        disableAnimation={disablePanelAnimation}
        zIndex={zIndex}
        fitContent={withDimmedBackground}
      >
        {title ? (
          <DefaultLayoutHeader
            headerTitle={title}
            headerLeft={
              <HeaderSide>
                <HeaderIconButton
                  accessibilityLabel={t("close")}
                  icon={closeIcon}
                  onPress={onClose}
                  id={closeIconId}
                />
              </HeaderSide>
            }
            headerRight={headerRight}
          />
        ) : null}
        <View style={styles.content}>{children}</View>
      </SlideIn>
    </>
  );
};

export const SlidePanel = withPortal(SlidePanel_);
