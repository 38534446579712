import { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";

import { Text } from "@kraaft/ui";

type SectionHeaderProps = {
  title: string;
  titleElementId?: string;
};

export const SectionHeader = ({
  title,
  children,
  titleElementId,
}: PropsWithChildren<SectionHeaderProps>) => {
  return (
    <View style={styles.container} nativeID={titleElementId}>
      <Text
        size="TITLE"
        color="FONT_HIGH_EMPHASIS"
        style={styles.sectionTitle}
        weight="medium"
      >
        {title}
      </Text>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  sectionTitle: {
    flexGrow: 1,
  },
});
