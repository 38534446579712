import { RefObject } from "react";
import { StyleSheet } from "react-native";

import { SelectingReplyPreview } from "@kraaft/shared/components/replyPreview/selectingReplyPreview";
import { Spacing } from "@kraaft/ui";
import {
  RoomActionBar,
  RoomActionBarHandle,
} from "@kraaft/web/src/views/messenger/conversation/roomActionBar";

interface ConversationActionBarsProps {
  roomId: string;
  hasSelection: boolean;
  actionBarRef: RefObject<RoomActionBarHandle>;
}

export const ConversationActionBars = ({
  roomId,
  hasSelection,
  actionBarRef,
}: ConversationActionBarsProps) => {
  return (
    <>
      <SelectingReplyPreview roomId={roomId} style={styles.replyPreview} />
      {!hasSelection ? (
        <RoomActionBar
          key={`${roomId}-actionBar`}
          roomId={roomId}
          ref={actionBarRef}
        />
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  replyPreview: {
    margin: Spacing.S8,
    marginBottom: Spacing.NONE,
  },
});
