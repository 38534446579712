import { useSelector } from "react-redux";

import { AvatarEmojiPicker } from "@kraaft/shared/components/avatarEmojiPicker";
import {
  getRoomCardType,
  isRoomPrivate,
} from "@kraaft/shared/components/roomCard/roomCardUtils";
import { useSetRoomEmoji } from "@kraaft/shared/components/roomCard/useSetRoomEmoji";
import { selectRoomIsExternal } from "@kraaft/shared/core/modules/room/roomSelectors";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";

type PressableRoomAvatarProps = {
  roomCard: AnyRoomCard;
};

export const PressableRoomAvatar = ({ roomCard }: PressableRoomAvatarProps) => {
  const setRoomEmoji = useSetRoomEmoji(roomCard.roomId);

  const isExternal = useSelector(selectRoomIsExternal(roomCard.roomId));
  const isPrivate = isRoomPrivate(roomCard);
  const roomCardType = getRoomCardType(isExternal, roomCard);

  return (
    <AvatarEmojiPicker
      emoji={roomCard.emoji}
      setEmoji={setRoomEmoji}
      isPrivate={isPrivate}
      roomCardType={roomCardType}
      recentEmojiSpace="room_emoji"
    />
  );
};
