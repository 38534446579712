import { StyleSheet } from "react-native";

import { Color, ColorStyle } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.WHITE,
    borderWidth: 1,
    borderColor: ColorStyle.FONT_LOW_EMPHASIS,
  },
  text: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  answerStyle: {
    width: 280,
  },
});
