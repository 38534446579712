import { StyleSheet } from "react-native";

import { PixelSize } from "@kraaft/ui";

export const FOLDER_CARD_WIDTH = 300;

export const styles = StyleSheet.create({
  card: {
    minHeight: PixelSize.S56,
    width: FOLDER_CARD_WIDTH,
    justifyContent: "center",
  },
});
