import { SafeAreaView, StyleSheet, View } from "react-native";

import { PermissionRequesterContent } from "@kraaft/shared/components/permissionRequester/permissionRequesterContent";
import { PermissionRequesterFooter } from "@kraaft/shared/components/permissionRequester/permissionRequesterFooter";
import {
  PermissionName,
  PermissionResult,
} from "@kraaft/shared/core/services/permission/permissions.types";
import { Spacing } from "@kraaft/ui";

interface PermissionRequesterProps {
  type: PermissionName;
  onRequestPromise?: (promise: Promise<PermissionResult>) => void;
}

export const PermissionRequester = ({
  type,
  onRequestPromise,
}: PermissionRequesterProps) => {
  return (
    <>
      <View style={styles.container}>
        <PermissionRequesterContent type={type} />
        <PermissionRequesterFooter
          type={type}
          onRequestPromise={onRequestPromise}
        />
      </View>
      <SafeAreaView />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    gap: Spacing.S16,
  },
  centeredText: {
    textAlign: "center",
  },
});
