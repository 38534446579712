import { StyleSheet } from "react-native";

import { ensureValidStyleSheet } from "@kraaft/shared/core/utils";
import { Color, ColorStyle, PixelSize, Spacing } from "@kraaft/ui";

export const nativeStylesObject = ensureValidStyleSheet({
  screen: {
    flexGrow: 1,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  safeAreaView: {
    flexGrow: 1,
    backgroundColor: Color.WHITE,
    flexBasis: 0,
  },
  buttonContainer: {
    margin: Spacing.S16,
    minHeight: PixelSize.S40,
  },
  listContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  list: {
    flexBasis: 0,
    flexGrow: 1,
    backgroundColor: Color.WHITE,
  },
  invite: {
    marginTop: Spacing.S16,
  },
});

export const styles = StyleSheet.create(nativeStylesObject);
