import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useMeshContext } from "@kraaft/helper-hooks";
import { SearchConversationContext } from "@kraaft/shared/components/conversation/searchConversationContext";
import { SearchScreen } from "@kraaft/shared/components/conversation/searchPanel/searchScreen";
import { SlidePanel } from "@kraaft/shared/components/slidePanel";

interface SearchPanelProps {
  portalHostname: string;
  roomId: string;
}

export const SearchPanel = ({ roomId, portalHostname }: SearchPanelProps) => {
  const { t } = useTranslation();
  const { setRoomId, searching } = useMeshContext(SearchConversationContext);

  useEffect(() => {
    setRoomId(roomId);
  }, [roomId, setRoomId]);

  return (
    <SlidePanel
      open={searching}
      name={t("search")}
      portalHostname={portalHostname}
    >
      <SearchScreen roomId={roomId} />
    </SlidePanel>
  );
};
