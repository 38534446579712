import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, FontSize, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  tableAndContactSupportContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: Spacing.S16,
  },
  tableWrapper: {
    flexGrow: 1,
    flexBasis: 0,
    display: "flex",
    flexDirection: "column",
  },
  contactSupportContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    padding: Spacing.S16,
    borderRadius: Radius.SMALL,
    gap: Spacing.S16,
    minWidth: 305,
  },
  contactSupportLeft: {
    display: "flex",
    gap: Spacing.S16,
    alignItems: "center",
    flexWrap: "wrap",
  },
  contactSupportText: {
    display: "flex",
    flexDirection: "column",
    gap: Spacing.S8,
  },
  table: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: FontSize.SMALL,
    borderTop: `1px solid ${Color.GREY_FRENCH}`,
    borderBottom: `1px solid ${Color.GREY_FRENCH}`,
    paddingTop: Spacing.S8,
    paddingBottom: Spacing.S8,
  },
  kTable: {
    flex: 1,
    flexBasis: 0,
  },
  cell: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    padding: Spacing.S4,
  },
}));
