import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { ModularDetailsAttachmentContext } from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/attachmentsEditor.props";
import { useModularDetailConversationAttachementContext } from "@kraaft/shared/components/modular/details/useModularDetailConversationAttachementContext";
import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { selectLoaderState } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { getModularFolderLoadingId } from "@kraaft/shared/core/modules/loaders/loaderUtils";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { selectModularFolderTitle } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ModularFolderStateActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { selectRoomTitle } from "@kraaft/shared/core/modules/room/roomSelectors";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { getModularTranslationKey } from "@kraaft/shared/core/utils";
import { useModularDrop } from "@kraaft/shared/core/utils/useModularDrop";

export const useModularDetailsAttachmentContext = (
  roomId: string,
  schemaId: string,
  modularFolderId: string,
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const roomTitle = useSelector(selectRoomTitle(roomId));
  const loaderState = useSelector(selectLoaderState);
  const modularFolderTitle = useSelector(
    selectModularFolderTitle(modularFolderId),
  );

  const { onDrop, onDropFiles, onPickDocuments, onPickImages, openCamera } =
    useModularDrop({
      modularFolderId,
      roomId,
      schemaId,
    });

  const openCarousel = useCallback(
    (column: KSchemaColumn, imageIndex: number) => {
      dispatch(
        ModularFolderStateActions.openCarousel({
          roomId,
          folderId: modularFolderId,
          column,
          initialIndex: imageIndex,
        }),
      );
    },
    [dispatch, roomId, modularFolderId],
  );

  const isLoadingColumn = useCallback(
    (column: KSchemaColumn) => {
      const loadingId = getModularFolderLoadingId(modularFolderId, column.key);
      return (loaderState[loadingId]?.loadingCounter || 0) > 0;
    },
    [loaderState, modularFolderId],
  );

  const onDelete = useCallback(
    (column: KSchemaColumn, attachment: Attachment) => {
      const message = modularFolderTitle
        ? getModularTranslationKey(
            "confirmDeleteMessageWithFolderTitle",
            column,
          )
        : getModularTranslationKey("confirmDeleteMessage", column);

      newConfirmationAlert({
        title: t("confirmDeleteDocumentTitle"),
        message: t(message, { folderTitle: modularFolderTitle }),
        confirmText: t("delete"),
        onConfirm: () => {
          dispatch(
            OfflineModularFolderActions.deleteAttachment({
              id: modularFolderId,
              attachmentId: attachment.id,
              columnKey: column.key,
            }),
          );
        },
      });
    },
    [modularFolderTitle, t, dispatch, modularFolderId],
  );

  const {
    renderAttachToFolderModals: renderAttachImageToFolderModal,
    openImportFromConversationModal: openImportImagesFromConversationModal,
  } = useModularDetailConversationAttachementContext({
    roomId,
    schemaId,
    modularFolderId,
    type: "image",
  });

  const {
    renderAttachToFolderModals: renderAttachDocumentToFolderModal,
    openImportFromConversationModal: openImportDocumentFromConversationModal,
  } = useModularDetailConversationAttachementContext({
    modularFolderId,
    roomId,
    schemaId,
    type: "document",
  });

  const attachmentContext = useMemo<ModularDetailsAttachmentContext>(
    () => ({
      onDrop,
      onDropFiles,
      onPickDocuments,
      onPickImages,
      openCamera,
      openCarousel,
      isLoadingColumn,
      folderTitle: modularFolderTitle,
      roomTitle: roomTitle ?? "",
      onDelete,
      openImportImagesFromConversationModal,
      openImportDocumentFromConversationModal,
    }),
    [
      openCarousel,
      isLoadingColumn,
      modularFolderTitle,
      onDrop,
      onDropFiles,
      onPickDocuments,
      onPickImages,
      openCamera,
      roomTitle,
      onDelete,
      openImportImagesFromConversationModal,
      openImportDocumentFromConversationModal,
    ],
  );

  return {
    attachmentContext,
    renderAttachImageToFolderModal,
    renderAttachDocumentToFolderModal,
  };
};
