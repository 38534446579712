import { View } from "react-native";

import { useMessageReceiptStatus } from "@kraaft/shared/components/message/useMessageReceiptStatus";
import { MessageTypes } from "@kraaft/shared/core/modules/message";
import { Color, ColorStyle, Icon, Text } from "@kraaft/ui";

import { styles } from "./message.styles";

export const MessageReceiptStatus = ({
  roomId,
  message,
}: {
  roomId: string;
  message: MessageTypes.Message;
}) => {
  const { receipt, receiptText } = useMessageReceiptStatus({ roomId, message });

  const readByNone = receipt === MessageTypes.ReceiptStatus.NONE;
  const readBySome = receipt === MessageTypes.ReceiptStatus.SOME;
  const receiptColor = readByNone
    ? ColorStyle.FONT_LOW_EMPHASIS
    : readBySome
      ? ColorStyle.FONT_HIGH_EMPHASIS
      : Color.BLUE_SAVOY;

  return (
    <View style={styles.receiptWrap} nativeID="message-receipt">
      <View style={styles.receiptIcon}>
        <Icon size="SMALL" name="double-check" color={receiptColor} />
      </View>
      <Text style={styles.receiptText}>{receiptText}</Text>
    </View>
  );
};
