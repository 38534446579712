import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import {
  markerOffset,
  markerSize,
} from "@kraaft/shared/components/geolocation/baseMediaMarker/baseMediaMarkerConfig";
import { ClusterConfig } from "@kraaft/shared/components/mapController/markers/marker/cluster/cluster.types";
import { getMarkerCoordinates } from "@kraaft/shared/components/mapController/markers/markerUtils";
import { MiniMediaCluster } from "@kraaft/shared/components/mapController/markers/miniMediaMarker/miniMediaCluster";
import { PhotoGalleryPanelForMap } from "@kraaft/shared/components/mapController/markers/miniMediaMarker/photoGalleryForMap";
import { MarkerGeoJSON } from "@kraaft/shared/components/mapController/types";
import { MiniMediaStateActions } from "@kraaft/shared/core/modules/miniMedia/miniMedia.actions";
import { MiniImageWithGeolocation } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { geolocationContextActions } from "@kraaft/shared/core/modules/room/roomActions";

interface Props {
  roomId: string;
}

const useMiniMediaClusterConfig = (
  props: Props,
): {
  clusterConfig: ClusterConfig<MiniImageWithGeolocation>;
  photoGalleryPanel?: React.ReactNode;
} => {
  const { roomId } = props;
  const dispatch = useDispatch();
  const [selectedClusterMarkers, setSelectedClusterMarkers] = useState<
    MarkerGeoJSON<MiniImageWithGeolocation>[]
  >([]);

  const handleClose = useCallback(() => {
    setSelectedClusterMarkers([]);
  }, []);

  const photoGalleryPanel = useMemo(
    () => (
      <PhotoGalleryPanelForMap
        roomId={roomId}
        markers={selectedClusterMarkers}
        open={selectedClusterMarkers.length > 0}
        onClose={handleClose}
      />
    ),
    [roomId, selectedClusterMarkers, handleClose],
  );

  const clusterConfig = useMemo<ClusterConfig<MiniImageWithGeolocation>>(
    () => ({
      ClusterRenderer: MiniMediaCluster,
      tracksViewChanges: true,
      size: markerSize,
      offset: markerOffset,
      onPress: (markers, isMaxZoom) => {
        if (!isMaxZoom) {
          if (isNative()) {
            setSelectedClusterMarkers(markers);
          } else {
            const boundsList = markers.map((marker) =>
              getMarkerCoordinates(marker),
            );

            dispatch(
              geolocationContextActions({
                actions: [{ type: "zoomOnBounds", boundsList }],
              }),
            );
          }
        } else {
          const firstMedia = markers[0]?.properties.marker.element.ref;

          if (firstMedia === undefined) {
            return;
          }

          dispatch(
            MiniMediaStateActions.openMiniMediaCarousel({
              roomId: firstMedia.roomId,
              messageId: firstMedia.messageId,
              mediaIds: markers.map(
                (marker) => marker.properties.marker.element.ref.messageId,
              ),
            }),
          );
        }
      },
    }),
    [dispatch],
  );

  return { clusterConfig, photoGalleryPanel };
};

export { useMiniMediaClusterConfig };
