import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";

import { CompactActionGroup } from "@kraaft/shared/components/compactActionGroup";
import { ArchiveSection } from "@kraaft/shared/components/conversationDetails/detailsAdvancedSections/detailsAdvancedSections/archiveSection";
import { DeleteSection } from "@kraaft/shared/components/conversationDetails/detailsAdvancedSections/detailsAdvancedSections/deleteSection";
import { JoinLeaveSection } from "@kraaft/shared/components/conversationDetails/detailsAdvancedSections/detailsAdvancedSections/joinLeaveSection";
import { PinningSection } from "@kraaft/shared/components/conversationDetails/detailsAdvancedSections/detailsAdvancedSections/pinningSection";
import { SharepointSection } from "@kraaft/shared/components/conversationDetails/detailsAdvancedSections/detailsAdvancedSections/sharepointSection";
import { VisibilitySwitch } from "@kraaft/shared/components/conversationDetails/detailsAdvancedSections/detailsAdvancedSections/visibilitySwitch";
import { NotificationSection } from "@kraaft/shared/components/conversationDetails/detailsAdvancedSections/notificationSection";
import { DetailsSection } from "@kraaft/shared/components/conversationDetails/detailsSection";
import { SectionHeader } from "@kraaft/shared/components/conversationDetails/sectionHeader";
import { SectionSpacer } from "@kraaft/shared/components/conversationDetails/sectionSpacer";
import {
  subscribeToRoomUsers,
  unsubscribeFromRoomUsers,
} from "@kraaft/shared/core/modules/room/roomActions";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { ConversationDetailsPanel } from "@kraaft/shared/core/services/navigation/navigationParams";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Spacing } from "@kraaft/ui";

interface Props {
  room: Room;
  portalHostname?: string;
  goToUrl: (url: string) => void;
  openedPanel: ConversationDetailsPanel | undefined;
  setOpenedPanel: (
    panelIdentifier: ConversationDetailsPanel | undefined,
  ) => void;
  onPanelClose: () => void;
}

const DetailsAdvancedSections = ({
  room,
  portalHostname,
  goToUrl,
  openedPanel,
  setOpenedPanel,
  onPanelClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isAdvancedVisible, setAdvancedVisible] = useBooleanState(false);
  const [isOtherVisible, setOtherVisible] = useBooleanState(false);

  useEffect(() => {
    dispatch(subscribeToRoomUsers({ roomId: room.id }));

    return () => {
      dispatch(unsubscribeFromRoomUsers({ roomId: room.id }));
    };
  }, [dispatch, room.id]);

  return (
    <>
      <View style={isAdvancedVisible ? undefined : styles.hidden}>
        <DetailsSection>
          <SectionHeader title={t("roomAdvancedSectionTitle")} />
          <View style={styles.contentContainer}>
            <SharepointSection
              room={room}
              goToUrl={goToUrl}
              openedPanel={openedPanel}
              setOpenedPanel={setOpenedPanel}
              portalHostname={portalHostname}
              setDisplayed={setAdvancedVisible}
              onPanelClose={onPanelClose}
            />

            <NotificationSection
              poolId={room.poolId}
              roomId={room.id}
              portalHostname={portalHostname}
              setDisplayed={setAdvancedVisible}
            />
          </View>
        </DetailsSection>
      </View>

      {isAdvancedVisible && isOtherVisible && <SectionSpacer />}

      <View style={isOtherVisible ? undefined : styles.hidden}>
        <DetailsSection>
          <CompactActionGroup>
            <VisibilitySwitch room={room} setDisplayed={setOtherVisible} />

            <PinningSection roomId={room.id} setDisplayed={setOtherVisible} />

            <ArchiveSection room={room} setDisplayed={setOtherVisible} />

            <JoinLeaveSection room={room} setDisplayed={setOtherVisible} />

            <DeleteSection room={room} setDisplayed={setOtherVisible} />
          </CompactActionGroup>
        </DetailsSection>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  hidden: {
    display: "none",
  },
  contentContainer: {
    gap: Spacing.S8,
  },
});

export { DetailsAdvancedSections };
