import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { selectDirectory } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { FileUploadHelper } from "@kraaft/shared/core/modules/file/fileUploadHelper";
import { setLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { fillDirectoryWithFile } from "@kraaft/shared/core/modules/message/attach/attachMessageActions";
import { convertFilesToAttachments } from "@kraaft/shared/core/modules/modularFolder/modularFolderSagaUtils";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { getExtension } from "@kraaft/shared/core/utils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export function* fillDirectoryFromFileDropSaga() {
  yield* takeEvery(fillDirectoryWithFile, fillDirectoryWithFileSaga);
}

function* fillDirectoryWithFileSaga(
  action: ReturnType<typeof fillDirectoryWithFile>,
) {
  const { loaderId, roomId, directoryId, files, source } = action.payload;

  const directory = yield* select(selectDirectory(directoryId));

  const firstFilename = files[0]?.filename;

  trackEvent({
    eventName: "Attach To Directory",
    room_id: roomId,
    element_count: files.length,
    from: source,
    directory_id: directoryId,
    directory_name: directory?.name ?? "",
    media_extension: firstFilename ? getExtension(firstFilename) : "",
  });

  try {
    yield* call(() =>
      FileUploadHelper.uploadThreeSteps(files, {
        createUploadPaths(f) {
          return Api.createFolderUploadPaths({
            roomId,
            filenames: f.map((file) => file.filename),
          });
        },
        async onceUploaded(processedFiles) {
          const apiPayload = {
            roomId,
            directoryId,
            files: convertFilesToAttachments(processedFiles),
          };
          await Api.addDirectoryFiles(apiPayload);
        },
      }),
    );

    yield* put(setLoader({ id: loaderId, status: LoaderStatus.SUCCESS }));
    yield* put(
      showSuccess({
        title: i18n.t("fillDirectorySuccess", { count: files.length }),
      }),
    );
  } catch (error) {
    yield* put(showError({ title: i18n.t("attachInDirectoryAlert.error") }));
    yield* put(
      setLoader({ id: loaderId, status: LoaderStatus.FAILURE, error }),
    );
  }
}
