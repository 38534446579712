import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  toggle: {
    width: "max-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  subheader: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.TITLE,
  },
  warning: {
    color: ColorStyle.ACTION_DESCTRUCTIVE,
  },
});
