import { StyleSheet } from "react-native";

import { ensureValidStyleSheet } from "@kraaft/shared/core/utils";
import { Spacing } from "@kraaft/ui";

export const nativeStylesObject = ensureValidStyleSheet({
  frameContainer: {
    flexDirection: "column",
    padding: Spacing.S24,
    boxSizing: "border-box",

    maxHeight: "100%",
  },
});

export const styles = StyleSheet.create(nativeStylesObject);
