import { reducer as toastr } from "react-redux-toastr";
import { combineReducers } from "redux";

import { librarySchemaReducers } from "@kraaft/shared/core/modules/librarySchema";
import { pollingReducers } from "@kraaft/shared/core/modules/polling";
import { previewReducers } from "@kraaft/shared/core/modules/preview";
import { schemaViewReducers } from "@kraaft/shared/core/modules/schemaView";
import { workflowReducers } from "@kraaft/shared/core/modules/workflows";
import { poolAdminReducers } from "@kraaft/web/src/core/modules/poolAdmin";

import { commonReducers } from "./commonReducers";

const WEB_reducers = combineReducers({
  toastr,
  poolAdmin: poolAdminReducers,
  polling: pollingReducers,
  workflow: workflowReducers,
  preview: previewReducers,
  schemaView: schemaViewReducers,
  librarySchema: librarySchemaReducers,
  ...commonReducers,
});

export type RootState = ReturnType<typeof WEB_reducers>;

export { WEB_reducers as platformReducers };
