import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  itemGap: {
    marginBottom: Spacing.S8,
  },
  baseThumbnail: {
    width: 20,
    height: 28,
    marginRight: Spacing.S8,
    borderRadius: 2,
  },
  documentThumbnail: {
    justifyContent: "center",
    alignItems: "center",
  },
});
