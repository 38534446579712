import React, { useEffect, useState } from "react";

import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import {
  BaseDialogFrameProps,
  KDialogFrame,
} from "@kraaft/shared/components/kDialog/kDialogFrame";

import { CurrentStep, DialogStepBase } from "./multiStepDialogTypes";

interface MultiStepDialogProps<Step> extends BaseDialogFrameProps {
  open: boolean;
  onClose: () => void;
  steps: Step[];
}

const MultiStepDialog = <Step extends DialogStepBase>({
  open = true,
  onClose,
  steps,
  ...dialogFrameProps
}: MultiStepDialogProps<Step>) => {
  const [step, setStep] = useState<CurrentStep>({ index: 0 });

  useEffect(() => {
    if (open) {
      setStep({ index: 0 });
    }
  }, [open]);

  const currentStep = steps?.[step.index];

  if (currentStep === undefined) {
    return null;
  }

  const dialogContent =
    typeof currentStep.dialog === "function" ? (
      React.createElement(currentStep.dialog, {
        ...(currentStep?.params ?? {}),
        setStep,
        step,
        onClose,
      })
    ) : (
      <KDialogContent {...{ onClose, ...currentStep.dialog }} />
    );

  return (
    <KDialogFrame
      {...{
        open,
        onClose,
        ...dialogFrameProps,
        ...currentStep.dialogFrameProps,
      }}
    >
      {dialogContent}
    </KDialogFrame>
  );
};

export { MultiStepDialog };
