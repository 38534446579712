import { useCallback, useMemo, useRef } from "react";
import clsx from "clsx";

import { Checkbox } from "@kraaft/shared/components/checkbox";
import { MonthHeaderProps } from "@kraaft/shared/components/galleries/monthHeader/monthHeaderProps";

import { CHECKBOX_SIZE, useStyles } from "./monthHeader.styles";

const MonthHeader = ({
  selectionSource,
  wantedSelectionSource,
  section,
  onSelectMonth,
  selectionState,
  checkboxSide,
}: MonthHeaderProps) => {
  const styles = useStyles({
    forceOpen: selectionState !== "none",
  });
  const ref = useRef<HTMLDivElement>(null);
  const handlePressMonth = useCallback(() => {
    onSelectMonth(section.id);
  }, [onSelectMonth, section.id]);

  const renderedTitle = useMemo(
    () => <span className={styles.month}>{section.title}</span>,
    [section.title, styles.month],
  );

  if (![undefined, wantedSelectionSource].includes(selectionSource)) {
    return <div className={styles.root}>{renderedTitle}</div>;
  }

  const checkbox = (
    <Checkbox
      size={CHECKBOX_SIZE}
      onPress={handlePressMonth}
      checked={selectionState === "all"}
      semiChecked={selectionState === "some"}
      greyedNotSelected
    />
  );

  return (
    <div className={styles.root}>
      <div
        ref={ref}
        className={clsx({
          [styles.wrapper]: true,
          [styles.wrapperLeft]: checkboxSide === "left",
          [styles.wrapperRight]: checkboxSide === "right",
        })}
      >
        {checkbox}
        {renderedTitle}
      </div>
    </div>
  );
};

export { MonthHeader };
