import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { MapButton, MapButtonProps } from "@kraaft/shared/components/mapButton";
import { useMapRedirection } from "@kraaft/shared/components/mapButton/hooks/useMapRedirection";
import { selectModularFolderTitle } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectRoomTitle } from "@kraaft/shared/core/modules/room/roomSelectors";
import { selectSchemaName } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { MapContext } from "@kraaft/shared/core/services/navigation/navigationParams";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { TrackingEvent } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

export type ToggleMapButtonProps = Omit<MapButtonProps, "onPress"> & {
  context: MapContext;
};

interface GetEventProps {
  roomTitle?: string;
  schemaName?: string;
  modularFolderTitle?: string;
}

export function getEvent(
  context: MapContext,
  { roomTitle, modularFolderTitle, schemaName }: GetEventProps,
): TrackingEvent {
  switch (context.location) {
    case "Home":
      return {
        eventName: "Click On Conversation List Map Button",
      };
    case "Conversation":
      return {
        eventName: "Click On Conversation Map Button",
        room_id: context.roomId,
        room_name: roomTitle,
        source: "folder",
      };
    case "PhotoGallery":
      return {
        eventName: "Click On Conversation Map Button",
        room_id: context.roomId,
        room_name: roomTitle,
        source: "photo_gallery",
      };
    case "ModularFolders":
      return {
        eventName: "Click On Conversation Map Button",
        room_id: context.roomId,
        room_name: roomTitle,
        source: "record_list",
        schema_id: context.schemaId,
        schema_name: schemaName ?? "",
      };
    case "ModularFolderDetails":
      return {
        eventName: "Click On Record Map Button",
        room_id: context.roomId,
        room_name: roomTitle,
        schema_id: context.schemaId,
        schema_name: schemaName ?? "",
        record_id: context.folderId,
        record_name: modularFolderTitle,
      };
  }
}

const MapButtonContainer = ({ context, ...props }: ToggleMapButtonProps) => {
  const { isMapOpened, toggleMap } = useMapRedirection({ context });

  const roomTitle = useSelector(
    selectRoomTitle("roomId" in context ? context.roomId : ""),
  );

  const schemaName = useSelector(
    selectSchemaName("schemaId" in context ? context.schemaId ?? "" : ""),
  );

  const modularFolderTitle = useSelector(
    selectModularFolderTitle("folderId" in context ? context.folderId : ""),
  );

  const event = useMemo(
    () => getEvent(context, { roomTitle, schemaName, modularFolderTitle }),
    [context, roomTitle, schemaName, modularFolderTitle],
  );

  const handlePress = useCallback(() => {
    if (event) {
      trackEvent(event);
    }

    toggleMap();
  }, [event, toggleMap]);

  return (
    <MapButton isSelected={isMapOpened} onPress={handlePress} {...props} />
  );
};

export { MapButtonContainer };
