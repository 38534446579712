import { makeStyles } from "@mui/styles";

import { Color, Radius } from "@kraaft/ui";
import { getTranslateFromTransition } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditor.utils";

interface Props {
  isBeingDragged: boolean;
  isBeingDraggedNextFrame: boolean;
  isDragging: boolean;
  transition: "top" | "bottom" | undefined;
  isHover: boolean;
}

export const useStyles = makeStyles({
  root: ({
    isBeingDragged,
    isBeingDraggedNextFrame,
    isDragging,
    transition,
    isHover,
  }: Props) => ({
    pointerEvents: isBeingDraggedNextFrame ? "none" : undefined,
    opacity: isBeingDragged ? 0 : 1,
    transition: isDragging ? "transform 150ms" : undefined,
    transform: `${
      isDragging && transition && !isBeingDragged
        ? getTranslateFromTransition(transition)
        : ""
    }`,
    "& div#element-outline": {
      borderRadius: isHover ? Radius.SMALL : undefined,
      boxShadow: isHover ? `0 0 0 4px ${Color.BLUE_SAVOY}` : undefined,
    },
  }),
});
