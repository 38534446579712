import { StyleSheet } from "react-native";

import { opacify } from "@kraaft/helper-functions";
import { ColorStyle, PixelSize } from "@kraaft/ui";

const styles = StyleSheet.create({
  button: {
    backgroundColor: ColorStyle.ACTION_CTA,
    borderRadius: 20,
    width: PixelSize.S36,
    height: PixelSize.S36,
    marginLeft: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  largeButton: {
    width: 44,
    height: 44,
    borderRadius: 22,
  },
  disabledButton: {
    backgroundColor: opacify(ColorStyle.ACTION_CTA, 0.6),
  },
});

export { styles };
