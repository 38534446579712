import { useMemo } from "react";

import { SchemaLockLookup } from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularColumnsContext } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";

export function useDefaultModularColumnContext(
  schemaLockLookup: SchemaLockLookup,
  orderedColumns: KSchemaColumn[],
): ModularColumnsContext {
  return useMemo(() => {
    return {
      getSectionLockInfoFromSectionKey(sectionKey, recordProperties) {
        return ModularRecordUtils.getSectionLockInfoWithSchemaLockLookup(
          schemaLockLookup,
          recordProperties,
          sectionKey,
        );
      },
      columns: orderedColumns.reduce<ModularColumnsContext["columns"]>(
        (acc, curr) => {
          acc[curr.key] = {
            getSectionLockInfo(recordProperties) {
              return ModularRecordUtils.getSectionLockInfoWithSchemaLockLookup(
                schemaLockLookup,
                recordProperties,
                curr.key,
              );
            },
            editable(recordProperties) {
              if (KSchemaUtils.isReadOnlyColumn(curr)) {
                return false;
              }

              return !ModularRecordUtils.getSectionLockInfoWithSchemaLockLookup(
                schemaLockLookup,
                recordProperties,
                curr.key,
              ).isLocked;
            },
          };

          return acc;
        },
        {},
      ),
    };
  }, [schemaLockLookup, orderedColumns]);
}
