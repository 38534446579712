import {
  CompactTextPillContent,
  TextPillContent,
} from "@kraaft/shared/components/modular/pill/utils";
import { PillRenderer } from "@kraaft/shared/components/modular/pill/utils/types";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { formatAddress } from "@kraaft/shared/core/utils";

export const GeolocationCompactedPillRenderer: PillRenderer<
  KColumnType.geolocation
> = ({ recordProperty, schemaColumn }) => {
  if (!recordProperty?.value) {
    return null;
  }

  const address = formatAddress(recordProperty.value);

  return (
    <CompactTextPillContent iconName={KSchemaUtils.getIcon(schemaColumn)}>
      {address}
    </CompactTextPillContent>
  );
};

export const GeolocationPillRenderer: PillRenderer<KColumnType.geolocation> = ({
  schemaColumn,
  recordProperty,
}) => {
  if (!recordProperty?.value) {
    return null;
  }

  const address = formatAddress(recordProperty.value);

  return (
    <TextPillContent
      iconName={KSchemaUtils.getIcon(schemaColumn)}
      tooltipTitle={schemaColumn.name}
    >
      {address}
    </TextPillContent>
  );
};
