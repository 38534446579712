import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import { useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { AlertDialog } from "@kraaft/shared/components/alertDialog";
import {
  AlertButtons,
  PrimaryAlertButton,
  SecondaryAlertButton,
} from "@kraaft/shared/components/alertDialog/alertDialog.types";
import { RoomArchiveStateActions } from "@kraaft/shared/core/modules/room/roomActions";
import {
  selectIsRoomArchivedForAll,
  selectIsRoomArchivedForUser,
  selectIsUserMemberOfRoom,
} from "@kraaft/shared/core/modules/room/selectors/roomOrRoomCardSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { ArchiveSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";
import { useOptimisticApi } from "@kraaft/shared/core/utils/useApi.hooks";
import { useEnsureOnline } from "@kraaft/shared/core/utils/useNetwork";

function useIsArchived(roomId: string) {
  const isArchivedForUser = useSelector(selectIsRoomArchivedForUser(roomId));
  const isArchivedForAll = useSelector(selectIsRoomArchivedForAll(roomId));

  return useMemo(
    () => ({
      isArchived: Boolean(isArchivedForUser || isArchivedForAll),
      isArchivedForAll: Boolean(isArchivedForAll),
    }),
    [isArchivedForAll, isArchivedForUser],
  );
}

function useArchiveForMe(roomId: string, source: ArchiveSource) {
  return useOptimisticApi(
    useCallback(() => {
      trackEvent({
        eventName: "Archive Conversation",
        room_id: roomId,
        source,
        type: "for_me",
      });
      return Api.setArchive({ roomId, isArchived: true });
    }, [roomId, source]),
    useMemo(
      () => ({
        success: () => RoomArchiveStateActions.setArchived({ roomId }),
        rollback: () => RoomArchiveStateActions.setUnarchived({ roomId }),
      }),
      [roomId],
    ),
  );
}

function useUnarchiveForMe(roomId: string, source: ArchiveSource) {
  return useOptimisticApi(
    useCallback(() => {
      trackEvent({
        eventName: "Unarchive Conversation",
        room_id: roomId,
        source,
        type: "for_me",
      });
      return Api.setArchive({ roomId, isArchived: false });
    }, [roomId, source]),
    useMemo(
      () => ({
        success: () => RoomArchiveStateActions.setUnarchived({ roomId }),
        rollback: () => RoomArchiveStateActions.setArchived({ roomId }),
      }),
      [roomId],
    ),
  );
}

function useArchiveForAll(roomId: string, source: ArchiveSource) {
  return useOptimisticApi(
    useCallback(() => {
      trackEvent({
        eventName: "Archive Conversation",
        room_id: roomId,
        source,
        type: "for_all",
      });
      return Api.archiveRoom({ roomId, isArchivedForAll: true });
    }, [roomId, source]),
    useMemo(
      () => ({
        success: () => RoomArchiveStateActions.setArchived({ roomId }),
        rollback: () => RoomArchiveStateActions.setUnarchived({ roomId }),
      }),
      [roomId],
    ),
  );
}

function useUnarchiveForAll(roomId: string, source: ArchiveSource) {
  return useOptimisticApi(
    useCallback(() => {
      trackEvent({
        eventName: "Unarchive Conversation",
        room_id: roomId,
        source,
        type: "for_all",
      });
      return Api.archiveRoom({ roomId, isArchivedForAll: false });
    }, [roomId, source]),
    useMemo(
      () => ({
        success: () => RoomArchiveStateActions.setUnarchived({ roomId }),
        rollback: () => RoomArchiveStateActions.setArchived({ roomId }),
      }),
      [roomId],
    ),
  );
}

function useArchiveDialog(archiveForAll: () => void, archiveForMe: () => void) {
  const { t } = useTranslation();

  return useCallback(() => {
    const alertTitle = t("toggleArchiveAlertTitle");
    const cancelButton: SecondaryAlertButton = {
      text: t("cancel"),
      style: "cancel",
    };
    const archiveButton: PrimaryAlertButton = {
      text: t(isNative() ? "archiveButton" : "archiveMeButton"),
      style: "default",
      onPress: archiveForMe,
    };
    const archiveEverybodyButton: PrimaryAlertButton = {
      text: t("archiveEveryoneButton"),
      style: "default",
      onPress: archiveForAll,
    };

    const buttons: AlertButtons = [archiveButton, cancelButton];
    let alertMessage: string;
    if (Platform.OS === "web") {
      buttons.unshift(archiveEverybodyButton);
      alertMessage =
        t("toggleArchiveAlertMessage") +
        t("toggleArchiveAlertMessageEverybody");
    } else {
      alertMessage = t("toggleArchiveAlertMessage");
    }
    AlertDialog.alert(alertTitle, alertMessage, buttons, "MEDIUM");
  }, [archiveForMe, archiveForAll, t]);
}

export function useArchive(roomId: string, source: ArchiveSource) {
  const isCurrentUserMemberOfRoom = useSelector(
    selectIsUserMemberOfRoom(roomId),
  );

  const { isArchived, isArchivedForAll } = useIsArchived(roomId);

  const [archiveForMe, archiveForMeOptions] = useArchiveForMe(roomId, source);
  const [unarchiveForMe, unarchiveForMeOptions] = useUnarchiveForMe(
    roomId,
    source,
  );
  const [archiveForAll, archiveForAllOptions] = useArchiveForAll(
    roomId,
    source,
  );
  const [unarchiveForAll, unarchiveForAllOptions] = useUnarchiveForAll(
    roomId,
    source,
  );

  const showModal = useArchiveDialog(archiveForAll, archiveForMe);

  const loading =
    archiveForMeOptions.loading ||
    unarchiveForMeOptions.loading ||
    archiveForAllOptions.loading ||
    unarchiveForAllOptions.loading;

  const archive = useEnsureOnline(() => {
    if (!loading && !isArchived) {
      showModal();
    }
  }, [loading, isArchived, showModal]);

  const unarchive = useEnsureOnline(async () => {
    if (!loading && isArchived) {
      if (isArchivedForAll) {
        await unarchiveForAll();
      } else {
        await unarchiveForMe();
      }
    }
  }, [isArchivedForAll, unarchiveForAll, unarchiveForMe, loading, isArchived]);

  return {
    isArchived,
    loading,
    archive: isCurrentUserMemberOfRoom ? archive : null,
    unarchive: isCurrentUserMemberOfRoom ? unarchive : null,
  };
}
