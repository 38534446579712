import { useEffect, useRef } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { BidirectionalMessageList } from "@kraaft/shared/components/bidirectionalMessageList";
import {
  BannerType,
  RoomBanner,
} from "@kraaft/shared/components/conversation/header/roomBanner";
import { MessageTextEditorProvider } from "@kraaft/shared/components/messageTextEditor";
import { MessageDataActions } from "@kraaft/shared/core/modules/message/messageData/messageData.actions";
import {
  selectMessageSelection,
  selectReplyingSourceMessage,
} from "@kraaft/shared/core/modules/message/messageSelectors";
import { isIE } from "@kraaft/shared/core/utils";
import { useContextShowExternalBanner } from "@kraaft/shared/core/utils/useContextSelectors/useContextSelectors";
import { CustomDragLayer } from "@kraaft/web/src/components/draggableMessage/customDragLayer";
import { ConversationActionBars } from "@kraaft/web/src/views/messenger/conversation/conversationActionBars";

import { Dropzone } from "./dropzone";
import { RoomActionBarHandle } from "./roomActionBar";

import { styles } from "./conversation.styles";
interface Props {
  roomId: string;
  visible: boolean;
  sizerWidth: number;
  autofocusMessageInput?: boolean;
}

const Conversation = (props: Props) => {
  const { roomId, visible, autofocusMessageInput, sizerWidth } = props;
  const dispatch = useDispatch();
  const actionBarRef = useRef<RoomActionBarHandle>(null);

  const messageSelection = useSelector(
    selectMessageSelection(roomId, "conversation"),
  );

  const replyingSourceMessage = useSelector(
    selectReplyingSourceMessage(roomId),
  );
  const hasSelection = Object.keys(messageSelection.selection).length > 0;

  const showExternalBanner = useContextShowExternalBanner(roomId);

  useEffect(() => {
    if (autofocusMessageInput) {
      actionBarRef.current?.focus();
    }
  }, [autofocusMessageInput]);

  // focus text input when replying
  useEffect(() => {
    if (replyingSourceMessage?.id) {
      actionBarRef.current?.focus();
    }
  }, [replyingSourceMessage?.id]);

  useEffect(() => {
    if (visible) {
      actionBarRef.current?.focus();
      dispatch(MessageDataActions.subscribe({ roomId }));

      return () => {
        dispatch(MessageDataActions.unsubscribe({ roomId }));
      };
    }
  }, [dispatch, roomId, visible]);

  function renderCustomDragLayer() {
    if (isIE()) {
      return null;
    }
    return <CustomDragLayer />;
  }

  return (
    <MessageTextEditorProvider>
      <Dropzone roomId={roomId}>
        <View style={styles.messagesWrapper} testID="ide2e-message-list">
          <BidirectionalMessageList
            roomId={roomId}
            enableSelection={hasSelection}
            sizerWidth={sizerWidth}
            messageSelection={messageSelection}
            visible={visible}
          />
        </View>
        {showExternalBanner && <RoomBanner bannerType={BannerType.EXTERNAL} />}
        <ConversationActionBars
          roomId={roomId}
          actionBarRef={actionBarRef}
          hasSelection={hasSelection}
        />
      </Dropzone>
      {renderCustomDragLayer()}
    </MessageTextEditorProvider>
  );
};

export { Conversation };
