import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { FilePath } from "@kraaft/shared/core/modules/file/file";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { VideoAttachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { utils as messageUtils } from "@kraaft/shared/core/modules/message";
import { Message } from "@kraaft/shared/core/modules/message/messageState";
import { TFunction } from "@kraaft/shared/core/services/i18next";
import { AppDispatch } from "@kraaft/shared/core/store";
import { getVideoMedia } from "@kraaft/shared/core/utils";

export function useVideoDownload(message: Message | null) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useCallback(async () => {
    if (!message || !messageUtils.isVideoMessage(message)) {
      // No error raised since message is not meant to be downloaded
      return;
    }
    const videoMedia = getVideoMedia(message.attachment);

    await downloadVideo({
      filename: videoMedia.filename,
      downloadUrl: videoMedia.downloadUrl,
      dispatch,
      t,
    });
  }, [dispatch, message, t]);
}

export function useVideoAttachmentDownload(attachment: VideoAttachment) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const videoMedia = getVideoMedia(attachment);

  return useCallback(
    () =>
      downloadVideo({
        dispatch,
        t,
        filename: videoMedia.filename,
        downloadUrl: videoMedia.downloadUrl,
      }),
    [dispatch, videoMedia, t],
  );
}

async function downloadVideo({
  filename,
  downloadUrl,
  dispatch,
  t,
}: {
  filename: string;
  downloadUrl: FilePath | undefined;
  dispatch: AppDispatch;
  t: TFunction;
}) {
  if (downloadUrl) {
    try {
      await fileSaver.download(downloadUrl, filename || "video");
    } catch (e) {
      console.error(downloadUrl, e);
      dispatch(showError({ title: t("couldNotDownloadVideo") }));
    }
  }
}
