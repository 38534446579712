import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    position: "relative",
    height: "100%",
    flexGrow: 1,

    margin: "0 -8px",
  },
  textAreaContainer: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  textArea: {
    resize: "none",
    flexGrow: 1,
    fontFamily: "Apercu, sans-serif",
    fontSize: "var(--font-size)",
    border: "none",
    boxShadow: "inset 0 0 0 2px var(--rdg-selection-color)",
    margin: 0,
    padding: `11px ${Spacing.S8}px`, // Fine tuning in order for textarea to be pixel perfect
  },
});
