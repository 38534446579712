import { EventChannel, eventChannel } from "redux-saga";
import {
  call,
  put,
  select,
  spawn,
  take,
  takeEvery,
  takeLeading,
} from "typed-redux-saga/macro";

import { forceFirestoreLongPolling } from "@kraaft/shared/core/modules/app/appActions";
import { selectNavigationSwitch } from "@kraaft/shared/core/modules/app/appSelector";
import {
  UserActions,
  UserStateActions,
} from "@kraaft/shared/core/modules/user/userActions";
import {
  selectCurrentUserAuth,
  selectOnboardingFetched,
} from "@kraaft/shared/core/modules/user/userSelectors";
import {
  CurrentUser,
  OnboardingState,
} from "@kraaft/shared/core/modules/user/userState";
import { analytics } from "@kraaft/shared/core/services/analytics";
import { firebaseAnalytics } from "@kraaft/shared/core/services/analytics/firebaseAnalytics";
import { Api } from "@kraaft/shared/core/services/api";
import { DatadogService } from "@kraaft/shared/core/services/datadog";
import { errorReporting } from "@kraaft/shared/core/services/errorReporting";
import { Firebase } from "@kraaft/shared/core/services/firebase";
import {
  crashlytics,
  FirebaseTypes,
} from "@kraaft/shared/core/services/firebase/sdk";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { LaunchDarklyService } from "@kraaft/shared/core/services/launchDarkly";
import { PersistentLogger } from "@kraaft/shared/core/utils/logger/persistentLogger";

export function* authSagas() {
  yield* spawn(firebaseAuthListenerSaga);
  yield* takeLeading(
    UserActions.userConnectedToFirebase,
    subscribeToCurrentUserSaga,
  );
  yield* takeLeading(UserActions.disconnectUserCommand, disconnectFirebaseSaga);
  yield* takeEvery(UserStateActions.loggedUserReceived, updateLaunchDarklySaga);
}

function* firebaseAuthListenerSaga() {
  function createFirebaseAuthChannel() {
    return eventChannel<{ user: FirebaseTypes.User | null }>((emit) => {
      const unsubscribe = Firebase.auth().onAuthStateChanged((user) =>
        emit({ user }),
      );

      return unsubscribe;
    });
  }

  function* receiveFirebaseUser({ user }: { user: FirebaseTypes.User | null }) {
    if (user) {
      const { uid, email, phoneNumber } = user;
      const userAuth = {
        uid,
        email,
        phoneNumber,
      };

      yield* put(UserActions.userConnectedToFirebase({ userAuth }));
    } else {
      yield* put(UserActions.userDisconnectedFromFirebase());
    }
  }

  const authChannel = yield* call(createFirebaseAuthChannel);

  yield* takeEvery(authChannel, receiveFirebaseUser);
}

function* disconnectFirebaseSaga() {
  try {
    yield Firebase.auth().signOut();
    yield DatadogService.setUser(null);
    yield LaunchDarklyService.setUser(null);
  } catch (e) {
    // pass
  }
}

function* subscribeToCurrentUserSaga(
  action: ReturnType<typeof UserActions.userConnectedToFirebase>,
) {
  const { userAuth } = action.payload;

  try {
    yield firebaseAnalytics.setUserProperties({
      user_uid: userAuth.uid,
    });

    yield analytics.identify(userAuth.uid);
    analytics.updatePermissions().catch(console.error);
    Api.identifyUser().catch(console.error);
    yield DatadogService.setUser({
      id: userAuth.uid,
    });
    yield LaunchDarklyService.setUser({
      user: {
        id: userAuth.uid,
      },
      auth: userAuth,
    });
    yield crashlytics().setUserId(userAuth.uid);
    errorReporting.setUserId(userAuth.uid);
  } catch (e) {
    console.error("Cannot init analytics", userAuth.uid);
  }

  try {
    const currentUserChannel = yield* call(createCurrentUserChannel);
    const currentUserOnboardingStateChannel =
      createCurrentUserOnboardingStateChannel(userAuth.uid);

    // Handle Firestore's input.
    yield* takeEvery(currentUserChannel, receiveCurrentUser);
    yield* takeEvery(
      currentUserOnboardingStateChannel,
      receiveCurrentUserOnboardingState,
    );

    // Unsubscribe on request.
    yield* take([
      UserActions.userDisconnectedFromFirebase,
      forceFirestoreLongPolling,
    ]);

    currentUserChannel.close();
    currentUserOnboardingStateChannel.close();
  } catch (error) {
    console.log("subscribeToCurrentUser error", error);
  }
}

function* receiveCurrentUser(action: {
  payload: { user: CurrentUser; isBlockedByFirewall: boolean };
}) {
  if (action.payload.isBlockedByFirewall) {
    yield* put(forceFirestoreLongPolling());
    return;
  }

  const user = action.payload.user;

  PersistentLogger.setPersistenceEnabled(user.debug ?? false).catch(
    console.error,
  );

  yield* put(UserStateActions.loggedUserReceived(user));
}

function* updateLaunchDarklySaga({
  payload,
}: ReturnType<typeof UserStateActions.loggedUserReceived>) {
  // update launchdarkly user
  const auth = yield* select(selectCurrentUserAuth);
  if (auth) {
    yield LaunchDarklyService.setUser({
      user: payload,
      auth,
    });
  }
}

function* receiveCurrentUserOnboardingState(
  onboardingState: OnboardingState | false,
) {
  const navigationSwitch = yield* select(selectNavigationSwitch);
  const isOnboardingFetched = yield* select(selectOnboardingFetched);

  if (
    navigationSwitch !== "onboarding-invite-to-pool" ||
    !isOnboardingFetched
  ) {
    yield* put(
      UserStateActions.setCurrentUserOnboardingState(
        onboardingState === false ? null : onboardingState,
      ),
    );
  }
}

function createCurrentUserChannel() {
  return eventChannel((emit) => {
    const unsubscribe = Firestore.subscribeToCurrentUser(
      (user, isBlockedByFirewall) =>
        emit({ payload: { user, isBlockedByFirewall } }),
    );

    return () => {
      unsubscribe();
    };
  });
}

function createCurrentUserOnboardingStateChannel(
  userId: string,
): EventChannel<OnboardingState | false> {
  // channel cannot emit null or undefined
  return eventChannel((emit) =>
    Firestore.subscribeToUserOnboardingState(userId, (state) =>
      emit(state || false),
    ),
  );
}
