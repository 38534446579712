import { ReactNode, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useMeshContextSetup } from "@kraaft/helper-hooks";
import { showInfo } from "@kraaft/shared/core/modules/alert/alertActions";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { selectOrderedEnabledTemplatesBySchemaId } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.selectors";
import {
  KSchema,
  KSchemaColumnDefinition,
  KSchemaElement,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  HoverState,
  SchemaOptimisticActions,
  SchemaOptimisticOperations,
} from "@kraaft/shared/core/modules/schema/schema.optimistic";
import { selectCreatedColumn } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { useEffectDifference } from "@kraaft/shared/core/utils/useEffectDifference";
import { getFieldProp } from "@kraaft/web/src/components/modularTable/components/fields";
import {
  getDefaultColumnValue,
  getDefaultSectionValue,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/columnUtils";
import { FormBuilderElement } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";
import { ElementCreation } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditor";
import {
  SchemaBuilderContext,
  SchemaBuilderContextProps,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

interface Props {
  schema: KSchema;
  isColumnDeletionInstant: boolean;
  hidePreview?: boolean;
  children: ReactNode;
}

export const PoolSchemaBuilderContextProvider = ({
  schema,
  children,
  isColumnDeletionInstant,
  hidePreview,
}: Props) => {
  const dispatch = useDispatch();

  const lockOnEnd = useRef(false);

  const { t } = useTranslation();

  const [currentDraggedKey, setCurrentDraggedKey] = useState<
    string | undefined
  >(undefined);
  const [currentHoveredKey, setCurrentHoveredKey] = useState<
    HoverState | undefined
  >(undefined);

  const [currentElementEditionKey, setCurrentElementEditionKey] = useState<
    string | undefined
  >(undefined);
  const [currentElementCreation, setCurrentElementCreation] = useState<
    ElementCreation | undefined
  >(undefined);

  const justCreatedColumn = useSelector(selectCreatedColumn(schema.id));

  const exportTemplates = useSelector(
    selectOrderedEnabledTemplatesBySchemaId(schema.id),
  );

  const blurEverything = useCallback(() => {
    setCurrentElementEditionKey(undefined);
    setCurrentElementCreation(undefined);
  }, [setCurrentElementCreation, setCurrentElementEditionKey]);

  const [
    insertColumnReminderHasBeenShowed,
    setInsertColumnReminderHasBeenShowed,
  ] = useState(false);

  const showInsertColumnReminderIfNeeded = useCallback(() => {
    if (!insertColumnReminderHasBeenShowed && exportTemplates.length > 1) {
      dispatch(
        showInfo({
          title: t("formBuilder.insertColumnReminder.title"),
          message: t("formBuilder.insertColumnReminder.message"),
          isPersistent: true,
        }),
      );

      setInsertColumnReminderHasBeenShowed(true);
    }
  }, [dispatch, exportTemplates.length, insertColumnReminderHasBeenShowed, t]);

  const insertElement = useCallbackRealtime(
    (
      [_schema, _currentElementCreation],
      element: KSchemaElement,
      placement: HoverState,
    ) => {
      if (!_currentElementCreation || element.name.length === 0) {
        return;
      }
      if (element.elementType === "section") {
        dispatch(
          SchemaOptimisticActions.addOperation(
            SchemaOptimisticOperations.addSection.create({
              targetId: _schema.id,
              section: element,
              placement,
            }),
          ),
        );
      }
      if (element.elementType === "column") {
        dispatch(
          SchemaOptimisticActions.addOperation(
            SchemaOptimisticOperations.addColumn.create({
              targetId: _schema.id,
              column: element,
              placement,
              inSection: _currentElementCreation.inSection,
            }),
          ),
        );

        showInsertColumnReminderIfNeeded();
      }
      blurEverything();
    },
    [blurEverything, dispatch, showInsertColumnReminderIfNeeded],
    [schema, currentElementCreation],
  );

  const addElement = useCallbackRealtime(
    (
      [_schema],
      item: FormBuilderElement,
      placement?: HoverState,
      inElement?: string,
    ) => {
      const columns = Object.values(_schema.rootSection.elements).sort(
        KSchemaUtils.orderByIndex,
      );
      const columnKey = placement?.key ?? columns[columns.length - 1]?.key;
      if (!columnKey) {
        return;
      }
      if (item.type === "section") {
        const section = getDefaultSectionValue("");
        setCurrentElementEditionKey(section.key);
        setCurrentElementCreation({
          type: "section",
          placement: placement ?? { key: columnKey, placement: "after" },
          section,
          inSection: inElement,
        });
      } else {
        const column = getDefaultColumnValue("", item.columnType);
        setCurrentElementEditionKey(column.key);
        setCurrentElementCreation({
          type: "column",
          placement: placement ?? { key: columnKey, placement: "after" },
          column,
          inSection: inElement,
        });
      }
    },
    [setCurrentElementCreation, setCurrentElementEditionKey],
    [schema],
  );

  const editColumnDefinition = useCallbackRealtime(
    ([_schema], columnKey: string, newDefinition: KSchemaColumnDefinition) => {
      dispatch(
        SchemaOptimisticActions.addOperation(
          SchemaOptimisticOperations.editColumnDefinition.create({
            targetId: _schema.id,
            columnKey,
            definition: newDefinition,
          }),
        ),
      );
    },
    [dispatch],
    [schema],
  );

  const renameElement = useCallbackRealtime(
    ([schemaRef], columnKey: string, name: string, hasEditor: boolean) => {
      const element = KSchemaUtils.findElement(
        schemaRef.rootSection,
        columnKey,
      );
      if (element?.name !== name) {
        if (element?.elementType === "column") {
          dispatch(
            SchemaOptimisticActions.addOperation(
              SchemaOptimisticOperations.renameColumn.create({
                targetId: schema.id,
                columnKey,
                name,
              }),
            ),
          );
        } else if (element?.elementType === "section") {
          dispatch(
            SchemaOptimisticActions.addOperation(
              SchemaOptimisticOperations.editSection.create({
                targetId: schema.id,
                key: element.key,
                edits: {
                  name,
                },
              }),
            ),
          );
        }
      }
      if (!hasEditor) {
        setCurrentElementEditionKey(undefined);
      }
    },
    [dispatch, schema.id, setCurrentElementEditionKey],
    [schema],
  );

  const reorderElement = useCallback(
    (
      hoverState: HoverState,
      target: string,
      tryToMoveInsideElement: string | undefined,
    ) => {
      let payload: {
        targetId: string;
        targetKey: string;
        afterKey?: string;
        sectionKey: string;
      };
      const container = tryToMoveInsideElement
        ? KSchemaUtils.findElement(schema.rootSection, tryToMoveInsideElement)
        : KSchemaUtils.findWithContext(schema.rootSection, hoverState.key)
            ?.parentSection;

      if (container?.elementType !== "section") {
        return;
      }

      if (
        tryToMoveInsideElement &&
        KSchemaUtils.findElement(container, hoverState.key)
      ) {
        payload = {
          targetId: schema.id,
          targetKey: target,
          sectionKey: container.key,
        };
      }

      if (hoverState.placement === "after") {
        payload = {
          targetId: schema.id,
          targetKey: target,
          sectionKey: container.key,
          afterKey: hoverState.key,
        };
      } else {
        const orderedElements = KSchemaUtils.orderedSectionElements(container);

        const relativeElementIndex = orderedElements.findIndex(
          (element) => element.key === hoverState.key,
        );

        payload = {
          targetId: schema.id,
          targetKey: target,
          sectionKey: container.key,
          afterKey: orderedElements[relativeElementIndex - 1]?.key,
        };
      }

      dispatch(
        SchemaOptimisticActions.addOperation(
          SchemaOptimisticOperations.reorderElement.create(payload),
        ),
      );
    },
    [dispatch, schema],
  );

  const deleteElement = useCallback(
    (columnKey: string) => {
      dispatch(
        SchemaOptimisticActions.addOperation(
          SchemaOptimisticOperations.deleteColumn.create({
            targetId: schema.id,
            columnKey,
          }),
        ),
      );
    },
    [dispatch, schema.id],
  );

  const createdColumnType = justCreatedColumn
    ? KSchemaUtils.findColumn(schema.rootSection, justCreatedColumn)?.type
    : undefined;
  const columnCreationRef = useRef(currentElementCreation);
  columnCreationRef.current = currentElementCreation;

  useEffectDifference(
    ([, oldJustCreatedColumn, oldType]) => {
      if (
        !justCreatedColumn ||
        (oldJustCreatedColumn === justCreatedColumn &&
          oldType === createdColumnType)
      ) {
        return;
      }
      if (columnCreationRef.current) {
        setCurrentElementCreation((value) => {
          if (!value) {
            return;
          }
          const element = KSchemaUtils.findElement(
            schema.rootSection,
            value.placement.key,
          );
          if (element) {
            return value;
          }
          return {
            ...value,
            placement: {
              ...value.placement,
              key: justCreatedColumn,
            },
          };
        });
        return;
      }
      if (
        createdColumnType &&
        getFieldProp("columnEditor", createdColumnType)
      ) {
        setCurrentElementEditionKey(justCreatedColumn);
      }
    },
    [
      currentElementCreation,
      justCreatedColumn,
      createdColumnType,
      setCurrentElementEditionKey,
      schema.rootSection,
    ],
  );

  const resetDrag = useCallback(() => {
    setCurrentHoveredKey(undefined);
    setCurrentDraggedKey(undefined);
  }, [setCurrentDraggedKey, setCurrentHoveredKey]);

  const resetCreation = useCallback(() => {
    setCurrentElementCreation(undefined);
    setCurrentElementEditionKey(undefined);
  }, [setCurrentElementCreation, setCurrentElementEditionKey]);

  const editSection = useCallback(
    (
      key: string,
      edits: {
        color?: string;
      },
    ) => {
      dispatch(
        SchemaOptimisticActions.addOperation(
          SchemaOptimisticOperations.editSection.create({
            targetId: schema.id,
            key,
            edits: {
              color: edits.color,
            },
          }),
        ),
      );
    },
    [dispatch, schema.id],
  );

  const editCondition = useCallback(
    (key: string, condition: CompositeCondition | undefined) => {
      dispatch(
        SchemaOptimisticActions.addOperation(
          SchemaOptimisticOperations.editCondition.create({
            targetId: schema.id,
            key: key,
            condition,
          }),
        ),
      );
    },
    [dispatch, schema.id],
  );

  const duplicate = useCallback(
    (key: string) => {
      dispatch(
        SchemaOptimisticActions.addOperation(
          SchemaOptimisticOperations.duplicateElement.create({
            key,
            targetId: schema.id,
          }),
        ),
      );

      showInsertColumnReminderIfNeeded();
    },
    [dispatch, schema.id, showInsertColumnReminderIfNeeded],
  );

  const contextValue = useMeshContextSetup<SchemaBuilderContextProps>({
    insertElement,
    addElement,
    editColumnDefinition,
    renameElement,
    reorderElement,
    deleteElement,
    blurEverything,
    currentElementCreation,
    setCurrentElementCreation,
    currentElementEditionKey,
    setCurrentElementEditionKey,
    currentHoveredKey,
    setCurrentHoveredKey,
    currentDraggedKey,
    setCurrentDraggedKey,
    resetDrag,
    resetCreation,
    lockOnEnd,
    editSection,
    editCondition,
    duplicate,
    schema,
    isColumnDeletionInstant,
    hidePreview: hidePreview ?? false,
  });

  return (
    <SchemaBuilderContext.Provider value={contextValue}>
      {children}
    </SchemaBuilderContext.Provider>
  );
};
