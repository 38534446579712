import { keyBy } from "lodash";
import { EventChannel, eventChannel } from "redux-saga";
import { put, takeEvery } from "typed-redux-saga/macro";

import {
  CompanyActions,
  CompanyStateActions,
} from "@kraaft/shared/core/modules/company/company.actions";
import { Company } from "@kraaft/shared/core/modules/company/company.state";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { takeCounted } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToCompaniesSaga() {
  yield takeCounted(
    CompanyActions.subscribe,
    CompanyActions.unsubscribe,
    subscribe,
    unsubscribe,
  );
}

function* receiveCompanies(companies: Company[]) {
  yield* put(
    CompanyStateActions.set(keyBy(companies, (company) => company.id)),
  );
}

function* subscribe(register: (channel: EventChannel<Company[]>) => void) {
  const channel = eventChannel(Firestore.subscribeToCompanies);
  register(channel);

  yield* takeEvery(channel, receiveCompanies);
}

function* unsubscribe(channel: EventChannel<Company[]> | undefined) {
  channel?.close();
}
