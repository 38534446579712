import { useTranslation } from "react-i18next";

import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame/kDialogFrame";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button } from "@kraaft/ui";

import { DeleteRoomDialogContent } from "./deleteRoomDialogContent";

export const DeleteRoomButton = (props: { room: Room }) => {
  const { room } = props;
  const [isDialogOpen, openDialog, closeDialog] = useBooleanState();
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="QUATERNARY"
        destructive
        size="SMALL"
        alignContent="left"
        id="room-details-delete-room"
        accessibilityLabel={t("deleteRoom.card")}
        text={t("deleteRoom.card")}
        icon="trash-03"
        onPress={openDialog}
      />
      <KDialogFrame open={isDialogOpen} onClose={closeDialog}>
        <DeleteRoomDialogContent room={room} onClose={closeDialog} />
      </KDialogFrame>
    </>
  );
};
