import { StyleSheet, View } from "react-native";

import { ColorStyle, PixelSize, Radius, Spacing, Text } from "@kraaft/ui";

interface ReactionCountPillProps {
  emoji: string;
  count: number;
  selected: boolean;
}

export const ReactionCountPill = ({
  emoji,
  count,
  selected,
}: ReactionCountPillProps) => {
  return (
    <View style={[styles.root, selected && styles.rootSelected]}>
      <Text size="BODY" color={selected ? "WHITE" : "FONT_HIGH_EMPHASIS"}>
        {emoji} {count}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: PixelSize.S32,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: Radius.MAXIMUM,
    paddingHorizontal: Spacing.S16,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  rootSelected: {
    backgroundColor: ColorStyle.ACTION_CTA,
  },
});
