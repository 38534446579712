import { Dictionary } from "ts-essentials";

import { ANALYTICS_VERBOSE } from "@kraaft/shared/constants/global";
import { AnalyticsUtils } from "@kraaft/shared/core/services/analytics/analyticsUtils";
import { analytics } from "@kraaft/shared/core/services/firebase/sdk";

class FirebaseAnalyticsService {
  setUserProperties(properties: Dictionary<string | null>) {
    const safeProperties = properties && AnalyticsUtils.cleanParams(properties);
    if (ANALYTICS_VERBOSE) {
      console.log("setUserProperties", JSON.stringify(safeProperties));
    }

    analytics().setUserProperties(safeProperties);
  }

  /**
   * @param name name should be only alphanumeric or underscore characters. Length should be between 1 and 40 characters.
   */
  logEvent(name: string) {
    analytics().logEvent(name);
  }
}

export const firebaseAnalytics = new FirebaseAnalyticsService();
