import { memo } from "react";
import { useSelector } from "react-redux";

import { LibrarySchemaOptimisticSelector } from "@kraaft/shared/core/modules/librarySchema/librarySchema.optimistic";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { EditLibrarySchema } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder/editLibrarySchema";
import { EditLibrarySchemaMetadata } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder/editLibrarySchemaMetadata";
import { EditLibrarySchemaReportTemplates } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder/editLibrarySchemaReportTemplates";
import { LibrarySchemaBuilderHeader } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder/librarySchemaBuilderHeader";
import { LibrarySchemaBuilderTabs } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder/librarySchemaBuilderTabs";
import { EditLibraryPresentation } from "@kraaft/web/src/components/schemaBuilder/tabs/editLibraryPresentation";

import { useStyles } from "./librarySchemaBuilder.styles";

interface LibrarySchemaBuilderProps {
  librarySchema: LibrarySchema;
  tab: LibrarySchemaBuilderTabs;
}

const LibrarySchemaBuilder_ = ({
  librarySchema,
  tab,
}: LibrarySchemaBuilderProps) => {
  const classes = useStyles();
  const editedLibrarySchema = useSelector(
    LibrarySchemaOptimisticSelector.selectBuilt(librarySchema.id),
  );

  if (!editedLibrarySchema) {
    return null;
  }

  return (
    <div className={classes.root}>
      <LibrarySchemaBuilderHeader librarySchema={editedLibrarySchema} />
      <div className={classes.content}>
        {tab === LibrarySchemaBuilderTabs.schema && (
          <EditLibrarySchema librarySchema={editedLibrarySchema} />
        )}
        {tab === LibrarySchemaBuilderTabs.reports && (
          <EditLibrarySchemaReportTemplates librarySchema={librarySchema} />
        )}
        {tab === LibrarySchemaBuilderTabs.info && (
          <EditLibrarySchemaMetadata librarySchema={editedLibrarySchema} />
        )}
        {tab === LibrarySchemaBuilderTabs.presentation && (
          <EditLibraryPresentation librarySchema={editedLibrarySchema} />
        )}
      </div>
    </div>
  );
};

export const LibrarySchemaBuilder = memo(
  LibrarySchemaBuilder_,
) as typeof LibrarySchemaBuilder_;
