import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import firebase from "firebase/compat/app";
import { assert } from "ts-essentials";

import {
  getEnvironment,
  getHostEndpoint,
} from "@kraaft/shared/constants/environment/environment.utils";
import { FirebaseSDK } from "@kraaft/shared/core/services/firebase/sdk/common";

export namespace FirebaseTypes {
  export type AuthCredential = firebase.auth.AuthCredential;
  export type AuthProvider = firebase.auth.AuthProvider;
  export type EmailAuthProvider = firebase.auth.EmailAuthProvider;
  export type AdditionalUserInfo = firebase.auth.AdditionalUserInfo;
  export type UserCredential = firebase.auth.UserCredential;
  export type UserMetadata = firebase.auth.UserMetadata;
  export type IdTokenResult = firebase.auth.IdTokenResult;
  export type ConfirmationResult = firebase.auth.ConfirmationResult;
  export type ActionCodeInfo = firebase.auth.ActionCodeInfo;
  export type ActionCodeSettings = firebase.auth.ActionCodeSettings;
  export type AuthSettings = firebase.auth.AuthSettings;
  export type User = firebase.User;
}

export const { auth, storage, analytics } = firebase;

export type ApplicationVerifier = firebase.auth.ApplicationVerifier;

export const signInWithPhoneNumber = (
  phoneNumber: string,
  applicationVerifier: firebase.auth.ApplicationVerifier | undefined,
): Promise<firebase.auth.ConfirmationResult> => {
  assert(applicationVerifier, "no applicationVerifier");
  return firebase
    .auth()
    .signInWithPhoneNumber(phoneNumber, applicationVerifier);
};

export const setFirebaseLanguage = async (languageCode: string) => {
  if (firebase.apps.length > 0) {
    firebase.auth().languageCode = languageCode;
  }
};

const Crashlytics = {
  setUserId: async (_userId: string): Promise<null> => {
    return null;
  },
  recordError: (error: Error): void => {
    console.log("no crashlytics recordError on web: ", error);
  },
  log: (message: string): void => {
    console.log("no crashlytics log on web: ", message);
  },
  crash: (): void => {
    console.log("no crashlytics crash on web: ");
  },
};

export function crashlytics() {
  return Crashlytics;
}

export const firebaseSDK = new (class WebFirebaseSDK extends FirebaseSDK {
  constructor() {
    super(auth);
  }

  enableAuthTesting(): void {
    auth().settings.appVerificationDisabledForTesting = true;
  }

  init(): void {
    // Fix Hot-Reload (Firebase instance already exists)
    if (!firebase.apps.length) {
      firebase.initializeApp(getEnvironment().FIREBASE.APP_CONFIG);

      if (getEnvironment().FIREBASE.EMULATOR.ENABLED) {
        const host = getHostEndpoint() ?? "localhost";
        firebase.auth().useEmulator(`http://${host}:9099`);
        firebase.firestore().useEmulator(host, 8087);
        firebase.firestore().settings({
          experimentalForceLongPolling: true,
          experimentalAutoDetectLongPolling: false,
          merge: true,
        });
        firebase.storage().useEmulator(host, 9199);
      }
    }
  }
})();
