import { StyleSheet } from "react-native";

import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.WHITE,
    flexGrow: 1,
    flexBasis: "100%",
  },
  titleContainer: {
    marginBottom: Spacing.S24,
  },
  errorContainer: {
    marginVertical: Spacing.S8,
  },
  button: {
    marginTop: Spacing.S24,
    marginBottom: Spacing.S12,
  },
});
