import { StyleSheet, View } from "react-native";

import { PREVIEW_IMAGES_MAX_WIDTH } from "@kraaft/shared/components/imagesPreview/previewSquare";
import { ColorStyle, Radius, Spacing } from "@kraaft/ui";

export const EmptyImagesPreview = () => (
  <View style={styles.container}>
    <View style={styles.imageSkeleton} />
    <View style={styles.imageSkeleton} />
    <View style={styles.imageSkeleton} />
    <View style={styles.imageSkeleton} />
    <View style={styles.imageSkeleton} />
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    gap: Spacing.S4,
  },
  imageSkeleton: {
    maxWidth: PREVIEW_IMAGES_MAX_WIDTH,
    flexGrow: 1,
    aspectRatio: 1,
    borderRadius: Radius.SMALL,
    overflow: "hidden",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
});
