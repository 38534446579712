import { PILL_MAX_COUNT } from "@kraaft/shared/components/geolocation/baseMediaMarker/baseMediaMarkerConfig";

export function getPillCountLabel(pillCount: number | undefined) {
  if (!pillCount) {
    return undefined;
  }
  return pillCount > PILL_MAX_COUNT
    ? `${PILL_MAX_COUNT}+`
    : pillCount.toString();
}
