import { makeStyles } from "@mui/styles";

import { ColorStyle } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",

    flexGrow: 1,
  },

  videoContainer: {
    position: "relative",

    height: "100%",

    maxHeight: "100%",
  },

  videoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    position: "absolute",
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
  },

  video: {
    maxHeight: "100%",
    maxWidth: "100%",

    cursor: "pointer",
    objectFit: "contain",
    zIndex: 1,
  },

  button: {
    zIndex: 2,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: 30,
    display: "block",
    top: "50%",
    left: "50%",
    padding: 10,
    position: "absolute",
    pointerEvents: "none",
    transform: "translate(-50%, -50%)",
  },
});
