import { StyleSheet, View } from "react-native";

import { AddRoomButton } from "@kraaft/shared/components/addRoomButton";
import { EmptyState } from "@kraaft/shared/components/emptyState";
import { Color, Spacing } from "@kraaft/ui";

interface Props {
  onPressCreate: () => void;
}

export const RoomListEmptyState = ({ onPressCreate }: Props) => {
  return (
    <View style={styles.page}>
      <View style={styles.spacerTop} />
      <EmptyState type="roomList" />
      <View style={styles.buttonContainer}>
        <AddRoomButton isTextHidden={false} onPress={onPressCreate} />
      </View>
      <View style={styles.spacerBottom} />
    </View>
  );
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: Color.WHITE,
    height: "100%",
  },
  spacerTop: {
    flex: 1,
  },
  spacerBottom: {
    flex: 2,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: Spacing.S16,
  },
});
