import { Text as RNText, View } from "react-native";

import { ContextMenuButton } from "@kraaft/shared/components/contextMenuButton";
import { useHomeDateFormat } from "@kraaft/shared/components/roomCard/roomCardUtils";
import { ReactNodeRef } from "@kraaft/shared/core/types";
import { Text } from "@kraaft/ui";

import { styles } from "./index.styles";

export type RoomCardHeaderProps = {
  title: string;
  createdAt: Date;
  isHovered?: boolean;
  isUnread?: boolean;
  onContextMenuPress?: (anchor: ReactNodeRef) => void;
};

const RoomCardHeader = ({
  createdAt,
  title,
  isHovered,
  isUnread,
  onContextMenuPress,
}: RoomCardHeaderProps) => {
  const dateTime = useHomeDateFormat(createdAt);
  const dateStyle = isUnread ? [styles.date, styles.unread] : styles.date;

  return (
    <View style={styles.container}>
      <Text
        style={styles.title}
        numberOfLines={1}
        ellipsizeMode="tail"
        weight="medium"
        id="ide2e-room-card-title"
      >
        {title}
      </Text>
      {isHovered && onContextMenuPress ? (
        <ContextMenuButton onPress={onContextMenuPress} horizontal />
      ) : (
        <RNText style={dateStyle} numberOfLines={1}>
          {dateTime}
        </RNText>
      )}
    </View>
  );
};

export { RoomCardHeader };
