import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    height: 48,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minWidth: 500,
    cursor: "pointer",
  },
  icon: {
    marginRight: Spacing.S24,
  },
  name: {
    flexGrow: 1,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    margin: `0px ${Spacing.S16}px`,
  },
});
