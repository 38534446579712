import { MiniMedia } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { nullId } from "@kraaft/shared/core/utils";

export const ROOT_DIRECTORY_ID = nullId;
export const DIRECTORY_MAX_DEPTH = 2;

export interface Directory {
  id: string;
  roomId: string;
  parentId: string;
  index: number;
  name: string;
  files: MiniMedia[];
  depth: number;
  deepestChildDepth: number;
  optimistic?: boolean;
}
