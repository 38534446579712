import { StyleSheet } from "react-native";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  dialog: {
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
  },
  content: {
    flexGrow: 1,
    flexBasis: 0,
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
    height: "100%",
    paddingLeft: Spacing.S16,
  },
  fabWrapper: {
    flexWrap: "wrap",
    alignSelf: "center",
  },
  helperTextContainer: {
    padding: Spacing.S16,
  },
  helperText: {
    fontSize: FontSize.SMALL,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    textAlign: "center",
  },
});
