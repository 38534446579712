import { makeStyles } from "@mui/styles";

const TRANSITION_DURATION = 150;

interface MakeStylesProps {
  draggedRowHeight: number | undefined;
}

export const useStyles = makeStyles({
  withTransition: {
    transition: `transform ${TRANSITION_DURATION}ms`,
  },
  withTransitionUp: {
    transform: ({ draggedRowHeight }: MakeStylesProps) =>
      `translateY(-${draggedRowHeight}px)`,
  },

  withTransitionDown: {
    transform: ({ draggedRowHeight }: MakeStylesProps) =>
      `translateY(${draggedRowHeight}px)`,
  },
});
