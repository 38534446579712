import { createAction } from "@reduxjs/toolkit";

import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import {
  actionUpdateLoaderMetaFailure,
  actionUpdateLoaderMetaSuccess,
  createLoaderMeta,
  WithLoaderPayload,
} from "@kraaft/shared/core/modules/loaders/loaderUtils";

export const getDownloadMediaLoadingId = (roomId: string) =>
  `downloadingBulkMedia-${roomId}`;

export const getShareMediaLoadingId = (roomId: string) =>
  `sharingBulkMedia-${roomId}`;

interface DownloadMediaSelectionPayload extends WithLoaderPayload {
  roomId: string;
  messageIds: string[];
}

export const downloadMediaSelection = createAction(
  "@media/DOWNLOAD_MEDIA_SELECTION",
  (payload: DownloadMediaSelectionPayload) => ({
    payload,
    meta: createLoaderMeta({
      id: payload.loaderId,
      status: LoaderStatus.LOADING,
    }),
  }),
);

export const downloadMediaSelectionSuccess = createAction(
  "@media/DOWNLOAD_MEDIA_SELECTION_SUCCESS",
  actionUpdateLoaderMetaSuccess,
);

export const downloadMediaSelectionFailure = createAction(
  "@media/DOWNLOAD_MEDIA_SELECTION_FAILURE",
  actionUpdateLoaderMetaFailure,
);

interface ShareMediaSelectionPayload {
  roomId: string;
  messageIds: string[];
}

export const shareMediaSelection = createAction(
  "@media/SHARE_MEDIA_SELECTION",
  (payload: ShareMediaSelectionPayload) => ({
    payload,
    meta: createLoaderMeta({
      id: getShareMediaLoadingId(payload.roomId),
      status: LoaderStatus.LOADING,
    }),
  }),
);

export const shareMediaSelectionSuccess = createAction(
  "@media/SHARE_MEDIA_SELECTION_SUCCESS",
  actionUpdateLoaderMetaSuccess,
);

export const shareMediaSelectionFailure = createAction(
  "@media/SHARE_MEDIA_SELECTION_FAILURE",
  actionUpdateLoaderMetaFailure,
);
