import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { numberClipboardTextFromCell } from "@kraaft/web/src/components/modularTable/components/table/clipboardTextFromCellUtils";
import { NumberColumnEditor } from "@kraaft/web/src/components/modularTable/components/table/columnHeader/columnEditor/columnEditors/numberColumnEditor";
import { TextEditor } from "@kraaft/web/src/components/modularTable/components/table/editors/textEditor";
import { numberRecordComparator } from "@kraaft/web/src/components/modularTable/components/table/recordComparatorUtils";
import { numberValueFromClipboardText } from "@kraaft/web/src/components/modularTable/components/table/valueFromClipboardTextUtils";
import { IFieldConfig } from "@kraaft/web/src/components/modularTable/components/types";

import { withBasicCell } from "../_withTableCell/withBasicCell";
import { NumberCell } from "./basicCell";

export const numberConfig: IFieldConfig<KColumnType.number> = {
  type: KColumnType.number,
  TableCell: withBasicCell(NumberCell),
  TableEditor: TextEditor,
  comparator: numberRecordComparator,
  columnEditor: NumberColumnEditor,
  getClipboardTextFromCell: numberClipboardTextFromCell,
  getValueFromClipboardText: numberValueFromClipboardText,
};
