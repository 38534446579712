import moment from "moment";

import { checkForCoordinates } from "@kraaft/shared/components/placesAutocomplete/placesAutocompleteUtils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaColumnLiteralValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularDisplayRequirements } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Geocoding } from "@kraaft/shared/core/utils/geocoding";

export const simpleValueFromClipboardText = (
  text: string,
): KSchemaColumnLiteralValue<
  | KColumnType.shortText
  | KColumnType.automatedAutoIncrement
  | KColumnType.longText
> => {
  return text;
};

export const numberValueFromClipboardText = (
  text: string,
): KSchemaColumnLiteralValue<KColumnType.currency | KColumnType.number> => {
  return Number(text.replace(/\D/g, ""));
};

export function dateValueFromClipboardText(
  text: string,
): KSchemaColumnLiteralValue<
  KColumnType.date | KColumnType.automatedCreatedAt
> {
  const parsedDate = moment(text, "DD/MM/YYYY", true);

  return parsedDate.isValid() ? parsedDate.toDate() : undefined;
}

// -20.77725, -101.93073
export const geolocationValueFromClipboardText = async (
  text: string,
): Promise<KSchemaColumnLiteralValue<KColumnType.geolocation>> => {
  const coordinates = checkForCoordinates(text);

  if (coordinates !== undefined) {
    return {
      coords: coordinates,
    };
  }

  return Geocoding.getGeolocationFromAddress(text);
};

export const selectValueFromClipboardText = (
  text: string,
  column: KSchemaColumn<KColumnType.selectSingle | KColumnType.selectMultiple>,
): KSchemaColumnLiteralValue<
  KColumnType.selectSingle | KColumnType.selectMultiple
> => {
  function getIdFromLabel(label: string) {
    return (Object.entries(column.options).find(
      ([_, option]) =>
        option.label.toLowerCase() === label.trim().toLowerCase(),
    ) ?? [])[0];
  }

  if (KSchemaUtils.isMultiSelect(column)) {
    const selectItemIds: string[] = [];
    for (const subText of text.split(",")) {
      const selectItemId = getIdFromLabel(subText);

      if (selectItemId) {
        selectItemIds.push(selectItemId);
      }
    }
    return selectItemIds;
  }
  const selectItemId = getIdFromLabel(text);

  if (selectItemId) {
    return [selectItemId];
  }

  return undefined;
};

export const userValueFromClipboardText = (
  text: string,
  _column:
    | KSchemaColumn<KColumnType.user>
    | KSchemaColumn<KColumnType.roomMembers>
    | KSchemaColumn<KColumnType.automatedCreatedBy>,
  displayContext: ModularDisplayRequirements,
): string[] => {
  function getIdFromLabel(label: string) {
    return Object.entries(displayContext.getPoolUsers() ?? {}).find(
      ([_, user]) => user.username.toLowerCase() === label.trim().toLowerCase(),
    )?.[0];
  }

  const selectItemIds: string[] = [];
  for (const subText of text.split(",")) {
    const selectItemId = getIdFromLabel(subText);

    if (selectItemId) {
      selectItemIds.push(selectItemId);
    }
  }
  return selectItemIds;
};

export const booleanValueFromClipboardText = (
  text: string,
): KSchemaColumnLiteralValue<KColumnType.checkbox> => {
  return Boolean(Number(text));
};
