import { StyleSheet, TouchableOpacity } from "react-native";

import { ColorValue, Icon, IconName, Spacing } from "@kraaft/ui";
import { TrackableViewProps } from "@kraaft/ui/src/types";

export const ModularFolderLeftIcon = ({
  accessibilityLabel,
  name,
  color,
  onClick,
  disabled,
}: TrackableViewProps & {
  name: IconName;
  color?: ColorValue | undefined;
  onClick?: (() => void) | undefined;
  disabled?: boolean;
}) => {
  return (
    <TouchableOpacity
      accessibilityLabel={accessibilityLabel}
      onPress={onClick}
      disabled={disabled || !onClick}
      style={styles.leftIconButton}
    >
      <Icon name={name} size={34} color={color ?? "FONT_LOW_EMPHASIS"} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  leftIconButton: {
    paddingHorizontal: Spacing.S8,
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    height: 34,
    width: 34,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});
