import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { createMeshContext, useMeshContextSetup } from "@kraaft/helper-hooks";
import { selectModularFolders } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectAllRooms } from "@kraaft/shared/core/modules/room/selectors";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularDisplayExtendedRequirements } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";

export interface ModularDisplayExtendedRequirementsProviderProps {
  recordType: ModularDisplayExtendedRequirements["recordType"];
  noCheckboxConfirmation: boolean;
}

export const ModularDisplayExtendedRequirementsContext =
  createMeshContext<ModularDisplayExtendedRequirements>();

export const ModularDisplayExtendedRequirementsProvider = ({
  children,
  recordType,
  noCheckboxConfirmation,
}: PropsWithChildren<ModularDisplayExtendedRequirementsProviderProps>) => {
  const rooms = useSelector(selectAllRooms);
  const folders = useSelector(selectModularFolders);

  return (
    <ModularDisplayExtendedRequirementsContext.Provider
      value={useMeshContextSetup({
        getRoomFromRecordId: (id) =>
          rooms[id] ?? rooms[folders[id]?.roomId ?? ""],
        getRoomNameFromRecordId: (id: string) => {
          const roomRecord = (rooms[id] ?? rooms[folders[id]?.roomId ?? ""])
            ?.record;

          if (roomRecord) {
            return ModularRecordUtils.findColumn(
              roomRecord.properties,
              KSchemaRemarkableColumns.ROOM_TITLE,
              KColumnType.shortText,
              KColumnType.automatedAutoIncrement,
            )?.value;
          }

          return undefined;
        },
        noCheckboxConfirmation,
        recordType,
      })}
    >
      {children}
    </ModularDisplayExtendedRequirementsContext.Provider>
  );
};
