import clsx from "clsx";

import { useStyles } from "./opacitySkeleton.styles";

interface SkeletonProps {
  children: React.ReactNode;
  grow: boolean;
}

const OpacitySkeleton = (props: SkeletonProps) => {
  const { children, grow } = props;

  const classes = useStyles({ grow });

  return (
    <div className={clsx(classes.withAnimation, classes.flex)}>{children}</div>
  );
};

export { OpacitySkeleton };
