import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { ModularFoldersHeader } from "@kraaft/shared/components/modularFolders/modularFoldersHeader";
import { ModularFoldersOrDoesNotExist } from "@kraaft/shared/components/modularFolders/modularFoldersOrDoesNotExist";
import { ModularFoldersSelectionProvider } from "@kraaft/shared/components/modularFolders/modularFoldersSelection.context";
import { selectSchemaExists } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { DetailsRoute } from "@kraaft/web/src/components/detailsRoute";
import { detailsQuery } from "@kraaft/web/src/views/app/appRouter/routes";

interface Props {
  roomId: string;
  schemaId: string;
  onBackButtonPress: () => void;
  isStickyPanel?: boolean;
  hideBackButton?: boolean;
}

const ModularFoldersRouteContent = ({
  roomId,
  schemaId,
  onBackButtonPress,
  isStickyPanel,
  hideBackButton,
}: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const schemaExists = useSelector(selectSchemaExists(schemaId));

  return (
    <ModularFoldersSelectionProvider>
      {!schemaExists ? (
        <DefaultLayoutHeader headerTitle={t("modularFolderNotAccessible")} />
      ) : (
        <ModularFoldersHeader
          onBackButtonPress={onBackButtonPress}
          hideBackButton={hideBackButton}
          isStickyPanel={isStickyPanel}
          roomId={roomId}
          schemaId={schemaId}
          onFilterSet={setSearch}
        />
      )}

      <ModularFoldersOrDoesNotExist
        roomId={roomId}
        id="ide2e-modularFolders"
        search={search}
        schemaId={schemaId}
      />
    </ModularFoldersSelectionProvider>
  );
};

export const ModularFoldersRoute = (props: Props) => (
  <DetailsRoute
    path={[detailsQuery.modularFolders, detailsQuery.modularFolder]}
    onBackButtonPress={props.onBackButtonPress}
    hideHeader
  >
    <ModularFoldersRouteContent {...props} key={props.roomId} />
  </DetailsRoute>
);
