import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, FontSize, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles(() => ({
  titleContainer: {
    padding: Spacing.S16,
  },
  title: {
    fontSize: FontSize.TITLE,
    fontWeight: "bold",
    marginRight: 8,
  },
  referenceContainer: {
    padding: Spacing.S16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  parametersContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Color.BLUE_COOL,
    borderRadius: Radius.LARGE,
    padding: Spacing.S8,
    margin: "0px 16px",
    marginBottom: 24,
  },
  dateInputContainer: {
    maxWidth: 200,
    padding: Spacing.S8,
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0 16px",
  },
  sliderRoot: {
    width: 300,
    marginBottom: 8,
  },
  sliderThumb: {
    color: Color.WHITE,
  },
  sliderTrack: {
    color: ColorStyle.ACTION_CTA,
  },
  sliderRail: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
}));
