import { groupBy, keyBy } from "lodash";
import { EventChannel, eventChannel } from "redux-saga";
import { call, put } from "typed-redux-saga/macro";

import { OfflineModularFolderStateActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import {
  ModularFolderActions,
  ModularFolderStateActions,
} from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { ModularFolderRoomLoadStatus } from "@kraaft/shared/core/modules/modularFolder/modularFolderState";
import { ModularFolderVisibilityType } from "@kraaft/shared/core/modules/modularFolder/types";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { WithId } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import * as Types from "@kraaft/shared/core/services/firestore/firestoreTypes";
import {
  takeCountedDeep,
  takeFirstAndDebounce,
} from "@kraaft/shared/core/utils/sagas";

interface Payload {
  roomId: string;
  folders: WithId<Types.FirestoreModularFolder>[];
  source: ModularFolderRoomLoadStatus;
}

type Meta = EventChannel<Payload> | undefined;

export function* subscribeToRoomModularFoldersSaga() {
  yield takeCountedDeep(
    ModularFolderActions.subscribeForRoom,
    ModularFolderActions.unsubscribeForRoom,
    subscribeSaga,
    unsubscribeSaga,
    (action) => action.payload.roomId,
  );
}

function* subscribeSaga(
  registerMeta: (channel: Meta) => void,
  {
    payload: { roomId },
  }: ReturnType<typeof ModularFolderActions.subscribeForRoom>,
) {
  const channel = yield* call(createModularFoldersChannel, roomId);

  registerMeta(channel);

  yield takeFirstAndDebounce(channel, 300, receiveFolders);
}

function* unsubscribeSaga(
  channel: Meta,
  {
    payload: { roomId },
  }: ReturnType<typeof ModularFolderActions.unsubscribeForRoom>,
) {
  channel?.close();
  yield* put(
    ModularFolderStateActions.updateRoomLoadStatus({
      roomId,
      status: "none",
    }),
  );
}

function* receiveFolders(payload: Payload) {
  const foldersBySchemaId = groupBy(payload.folders, (a) => a.schemaId);
  const converted: ModularFolder[] = [];

  for (const [schemaId, folderForSchemaId] of Object.entries(
    foldersBySchemaId,
  )) {
    converted.push(
      ...(yield* KSchemaConversion.toModularFolders(
        schemaId,
        folderForSchemaId,
      )),
    );
  }

  yield* put(
    ModularFolderStateActions.setIdsForVisibility({
      visibility: {
        type: ModularFolderVisibilityType.Room,
        roomId: payload.roomId,
      },
      ids: converted.map((modularFolder) => modularFolder.id),
    }),
  );

  yield* put(
    OfflineModularFolderStateActions.receive(
      keyBy(converted, (modularFolder) => modularFolder.id),
    ),
  );

  yield* put(
    ModularFolderStateActions.updateRoomLoadStatus({
      roomId: payload.roomId,
      status: payload.source,
    }),
  );
}

function createModularFoldersChannel(roomId: string): EventChannel<Payload> {
  return eventChannel((emit) =>
    Firestore.subscribeToRoomModularFolders(
      roomId,
      ({ modularFolders, source }) =>
        emit({ roomId, folders: modularFolders, source }),
    ),
  );
}
