import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    padding: Spacing.S16,
  },
  text: {
    marginBottom: Spacing.S16,
  },
});
