import { StyleSheet } from "react-native";

import { Color, ColorStyle, FontSize, PixelSize, Spacing } from "@kraaft/ui";

function getPillHeightWithEmojiBuffer(height: number) {
  /** account for emoji height that alters line-height */
  return height + PixelSize.S4;
}

export const styles = StyleSheet.create({
  basePill: {
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1,
  },

  pillContainerPressed: {
    opacity: 0.75,
  },

  pillContent: {
    maxWidth: 280,
    flexShrink: 1,
  },

  pillMedium: {
    borderRadius:
      getPillHeightWithEmojiBuffer(PixelSize.S24 + PixelSize.S4) / 2,
    paddingHorizontal: Spacing.S8,
    paddingVertical: Spacing.S4,
    height: getPillHeightWithEmojiBuffer(PixelSize.S24 + PixelSize.S4),
  },
  pillWithIconMedium: {
    paddingRight: 12,
  },
  textMedium: {
    fontSize: FontSize.MEDIUM,
  },
  textWithIconMedium: {
    marginLeft: 4,
  },

  pillSmall: {
    borderRadius: getPillHeightWithEmojiBuffer(PixelSize.S16) / 2,
    paddingHorizontal: Spacing.S8,
    height: getPillHeightWithEmojiBuffer(PixelSize.S16),
  },
  pillWithIconSmall: {
    paddingLeft: Spacing.S4,
  },
  textSmall: {
    fontSize: FontSize.SMALL,
  },
  textWithIconSmall: {
    marginLeft: 2,
  },

  pillSpacer: {
    marginRight: 4,
  },

  pillEmpty: {
    borderStyle: "dashed",
    borderColor: ColorStyle.FONT_LOW_EMPHASIS,
    borderWidth: 1,
    backgroundColor: Color.TRANSPARENT,
  },
  textEmpty: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },

  pillButton: {
    borderStyle: "solid",
    borderColor: ColorStyle.FONT_HIGH_EMPHASIS,
    borderWidth: 1,
    backgroundColor: Color.WHITE,
  },
});
