import moment from "moment";

import { useTranslation } from "react-i18next";

import { SignatureDisplayer } from "@kraaft/shared/components/signature/signatureDisplayer";
import { SignatureDeleteButton } from "@kraaft/shared/components/signature/signatureViewer/signatureDeleteButton";
import { ImageAttachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { Image } from "@kraaft/ui";

import { styles } from "./signatureViewer.styles";

interface SignatureViewerProps {
  attachment: ImageAttachment;
  hideDeleteButton?: boolean;
  signatureSize?: number;
  onDelete: () => void;
}

const SignatureViewer = ({
  attachment,
  hideDeleteButton,
  signatureSize,
  onDelete,
}: SignatureViewerProps) => {
  const { t } = useTranslation();

  return (
    <SignatureDisplayer
      signatureSize={signatureSize}
      footer={
        !hideDeleteButton ? <SignatureDeleteButton onDelete={onDelete} /> : null
      }
      comment={t("signature.viewer.signedOn", {
        date: moment(attachment.createdAt).format("L LT"),
      })}
    >
      <Image
        style={styles.image}
        resizeMode="contain"
        preferredSize="large"
        source={attachment.original.downloadUrl}
      />
    </SignatureDisplayer>
  );
};

export { SignatureViewer };
