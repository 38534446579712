import sortBy from "lodash/sortBy";
import { Dictionary } from "ts-essentials";

export function templateRoute(route: string, params: Dictionary<string>) {
  return sortBy(Object.entries(params), ([key]) => -key.length).reduce(
    (current, [key, value]) => {
      return current.replace(`:${key}`, value);
    },
    route,
  );
}
