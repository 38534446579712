import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { geolocationClipboardTextFromCell } from "@kraaft/web/src/components/modularTable/components/table/clipboardTextFromCellUtils";
import { LocationEditor } from "@kraaft/web/src/components/modularTable/components/table/editors/locationEditor";
import { geolocationRecordComparator } from "@kraaft/web/src/components/modularTable/components/table/recordComparatorUtils";
import { geolocationValueFromClipboardText } from "@kraaft/web/src/components/modularTable/components/table/valueFromClipboardTextUtils";
import { IFieldConfig } from "@kraaft/web/src/components/modularTable/components/types";

import { withBasicCell } from "../_withTableCell/withBasicCell";
import { GeolocationCell } from "./basicCell";

export const geolocationConfig: IFieldConfig<KColumnType.geolocation> = {
  type: KColumnType.geolocation,
  TableCell: withBasicCell(GeolocationCell),
  TableEditor: LocationEditor,
  comparator: geolocationRecordComparator,
  getClipboardTextFromCell: geolocationClipboardTextFromCell,
  getValueFromClipboardText: geolocationValueFromClipboardText,
};
