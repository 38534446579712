import { StyleSheet, View } from "react-native";

import { ColorStyle, Preloader, Spacing } from "@kraaft/ui";

export const BidirectionalLoader = () => {
  return (
    <View style={styles.root}>
      <Preloader color={ColorStyle.ACTION_CTA} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    padding: Spacing.S16,
  },
});
