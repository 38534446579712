import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  moduleReply: {
    marginTop: 8,
    marginRight: 8,
  },
  moduleSelf: {
    marginLeft: 8,
    marginBottom: 10,
  },
  moduleLeft: {
    marginRight: 18,
  },
  moduleRight: {
    marginLeft: 18,
  },
});
