import { put, select, spawn, take, takeEvery } from "typed-redux-saga/macro";

import { isNative } from "@kraaft/helper-functions";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { allStoresRehydrated } from "@kraaft/shared/core/modules/memory/memoryActions";
import { MessageDataStateActions } from "@kraaft/shared/core/modules/message/messageData/messageData.actions";
import { updateProgressBar } from "@kraaft/shared/core/modules/message/offline/backgroundEngine";
import { dequeueMessagesSaga } from "@kraaft/shared/core/modules/message/offline/dequeueMessagesSaga";
import {
  createOptimisticMessage,
  selectParams,
} from "@kraaft/shared/core/modules/message/offline/offlineMessageSagaUtils";
import { selectOfflineMessageByOptimisticId } from "@kraaft/shared/core/modules/message/offline/offlineMessageSelectors";
import { OfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageState";
import { RootState } from "@kraaft/shared/core/store";
import { waitFor } from "@kraaft/shared/core/utils/sagas";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

import {
  addOfflineMessage,
  OfflineMessageActions,
  OfflineMessageStateActions,
} from "./offlineMessageActions";

export function* offlineMessageSagas() {
  yield* takeEvery(addOfflineMessage, addOptimisticMessageSaga);
  yield* takeEvery(
    OfflineMessageActions.deleteOfflineMessage,
    deleteOfflineMessageSaga,
  );

  if (isNative()) {
    yield* take(allStoresRehydrated);
    yield* rehydrateOfflineMessagesSaga();
    yield* spawn(dequeueMessagesSaga);
  } else {
    yield* spawn(dequeueMessagesSaga);
  }
}

function* addOptimisticMessageSaga({
  payload: offlineMessage,
}: ReturnType<typeof addOfflineMessage>) {
  const optimisticMessage = prepareOptimisticMessage(offlineMessage);

  if (optimisticMessage) {
    yield* put(
      MessageDataStateActions.addMessages({
        roomId: offlineMessage.roomId,
        messages: { [optimisticMessage.id]: optimisticMessage },
        shouldActAsNewMessage: false,
        messageDocs: {},
      }),
    );

    const { queue, sentCount } = yield* select(selectParams);

    yield updateProgressBar(
      {
        value: sentCount + 1,
        max: queue.length + sentCount,
      },
      optimisticMessage.type,
    );

    if (optimisticMessage && optimisticMessage.type !== "log") {
      trackEvent({
        eventName: "Send Message Add Queue",
        room_id: offlineMessage.roomId,
        request_id: offlineMessage.optimisticId,
        message_type: optimisticMessage.type,
        queue_length: queue.length,
      });
    }
  }
}

function* deleteOfflineMessageSaga(
  action: ReturnType<typeof OfflineMessageActions.deleteOfflineMessage>,
) {
  const optimisticId = action.payload;

  const message = yield* select(
    selectOfflineMessageByOptimisticId(optimisticId),
  );

  // propagate to redux
  yield* put(OfflineMessageStateActions.deleteOfflineMessage(optimisticId));

  // delete file on disk
  if (message && "file" in message) {
    try {
      yield fileAllocator.delete(message.file.path);
    } catch (e) {
      console.error("could not delete offline message file", e);
    }
  }
}

function* rehydrateOfflineMessagesSaga() {
  yield waitFor((state: RootState) => {
    const params = selectParams(state);
    return params.currentUserId !== undefined;
  });

  const { queue, currentUserId } = yield* select(selectParams);

  for (const offlineMessage of queue) {
    if (offlineMessage.senderId !== currentUserId) {
      // delete messages from other users
      yield* put(
        OfflineMessageActions.deleteOfflineMessage(offlineMessage.optimisticId),
      );
    } else {
      const optimisticMessage = prepareOptimisticMessage(offlineMessage);
      if (optimisticMessage) {
        yield* put(
          MessageDataStateActions.addMessages({
            roomId: offlineMessage.roomId,
            messages: { [optimisticMessage.id]: optimisticMessage },
            shouldActAsNewMessage: false,
            messageDocs: {},
          }),
        );
      }
    }
  }
}

function prepareOptimisticMessage(offlineMessage: OfflineMessage) {
  try {
    const optimisticMessage = createOptimisticMessage(offlineMessage);

    return optimisticMessage;
  } catch (e) {
    console.warn("error preparing optimistic message", e);
  }
}
