import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { MessageMatchProps } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageMatchProps";
import { SearchTextResult } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/searchTextResult";
import { InputPartitionHelper } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { DocumentMessage } from "@kraaft/shared/core/modules/message/messageState";
import { Icon, Spacing } from "@kraaft/ui";

export const MessageDocumentMatch = ({
  message,
  searchResult,
}: MessageMatchProps<DocumentMessage>) => {
  const filenameToInputPartition = useMemo(
    () => [
      InputPartitionHelper.textToInputPartition(
        message.attachment.original.filename,
      ),
    ],
    [message.attachment.original.filename],
  );

  return (
    <View style={styles.container}>
      <Icon name="file-04" />
      <SearchTextResult
        style={styles.text}
        text={filenameToInputPartition}
        result={searchResult}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: Spacing.S8,
  },
  text: {
    flexShrink: 1,
    marginTop: Spacing.S2,
  },
});
