import { KraaftLogo } from "@kraaft/shared/components/kraaftLogo";
import { AskPoolAcquisitionSource } from "@kraaft/shared/components/onboarding/askPoolAcquisitionSource";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";

const AskPoolAcquisitionSourceWeb = () => (
  <SplitScreenWrapper headerLeft={<KraaftLogo />}>
    <AskPoolAcquisitionSource />
  </SplitScreenWrapper>
);

export { AskPoolAcquisitionSourceWeb };
