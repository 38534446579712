import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { ModularDisplayExtendedRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { ModularDisplayRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { LibrarySchemaBuilderContextProvider } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder/librarySchemaBuilderContextProvider";
import { EditSchema } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/editSchema";

interface EditLibrarySchemaProps {
  librarySchema: LibrarySchema;
}

const EditLibrarySchema = ({ librarySchema }: EditLibrarySchemaProps) => {
  return (
    <LibrarySchemaBuilderContextProvider librarySchema={librarySchema}>
      <ModularDisplayRequirementsProvider>
        <ModularDisplayExtendedRequirementsProvider
          recordType="formPreview"
          noCheckboxConfirmation={true}
        >
          <EditSchema />
        </ModularDisplayExtendedRequirementsProvider>
      </ModularDisplayRequirementsProvider>
    </LibrarySchemaBuilderContextProvider>
  );
};
export { EditLibrarySchema };
