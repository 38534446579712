import { spawn } from "typed-redux-saga/macro";

import { changeNotificationLanguageOnPoolChangeSaga } from "@kraaft/shared/core/modules/notifications/sagas/changeNotificationLanguageOnPoolChange.saga";
import { clearNotificationsSaga } from "@kraaft/shared/core/modules/notifications/sagas/clearNotifications.saga";
import { dontShowNotificationWhenInRoomSaga } from "@kraaft/shared/core/modules/notifications/sagas/dontShowNotificationWhenInRoom.saga";
import { navigateOnNotificationClickSaga } from "@kraaft/shared/core/modules/notifications/sagas/navigateOnNotificationClick.saga";
import { registerOnLoginSaga } from "@kraaft/shared/core/modules/notifications/sagas/registerOnLogin.saga";
import { unregisterOnLogoutSaga } from "@kraaft/shared/core/modules/notifications/sagas/unregisterOnLogout.saga";
import { vibrateOnMessageSaga } from "@kraaft/shared/core/modules/notifications/sagas/vibrateOnMessage.saga";

export function* notificationsSaga() {
  yield* spawn(registerOnLoginSaga);
  yield* spawn(unregisterOnLogoutSaga);
  yield* spawn(vibrateOnMessageSaga);
  yield* spawn(changeNotificationLanguageOnPoolChangeSaga);
  yield* spawn(navigateOnNotificationClickSaga);
  yield* spawn(clearNotificationsSaga);
  yield* spawn(dontShowNotificationWhenInRoomSaga);
}
