import {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { OrderableOption } from "@kraaft/web/src/components/sortableListWithAddAndDelete/orderableOptionRenderer/orderableOption";
import { UnlabeledTextInput } from "@kraaft/web/src/components/unlabeledTextInput";

export const OrderableOptionInput = <Option extends OrderableOption>(props: {
  option: Option;
  onPressEnter: (id: string) => void;
  onChange: (id: string, value: Option) => void;
}) => {
  const { option, onPressEnter, onChange } = props;
  const isFocused = useRef(false);
  const [tempText, setTempText] = useState(option.text);

  useEffect(() => {
    if (!isFocused.current) {
      setTempText(option.text);
    }
  }, [option.text]);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter") {
        onPressEnter(option.id);
      }
    },
    [onPressEnter, option.id],
  );

  const handleBlur = useCallback(() => {
    isFocused.current = false;
    onChange(option.id, { ...option, text: tempText });
  }, [onChange, option, tempText]);

  const handleFocus = useCallback(() => {
    isFocused.current = true;
  }, []);

  return (
    <UnlabeledTextInput
      id={`orderable-option-${option.id}`}
      data-testid="ide2e-orderable-option-input"
      placeholder=""
      onChangeText={setTempText}
      value={tempText}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};
