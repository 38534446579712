import { put } from "typed-redux-saga/macro";

import { ModernFileHelper } from "@kraaft/shared/core/modules/file/file";
import * as actionsFileBucket from "@kraaft/shared/core/modules/fileBucket/fileBucketActions";
import { fillDirectoryWithFile } from "@kraaft/shared/core/modules/message/attach/attachMessageActions";
import { sendFileMessage } from "@kraaft/shared/core/modules/message/send/sendMessageActions";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { BatchActions } from "@kraaft/shared/core/store/batchingReducer";
import { generateContextId, uuid } from "@kraaft/shared/core/utils";

export function* uploadFilesSaga(
  action: ReturnType<typeof roomActions.uploadFilesRequest>,
) {
  const { files, context } = action.payload;

  try {
    switch (context.type) {
      case "shareExtension":
      case "share":
      case "room": {
        const { type: _, ...cleanContext } = context;
        const requestId = uuid();

        for (const file of files) {
          yield* put(
            sendFileMessage({
              file,
              coords: ModernFileHelper.isMedia(file)
                ? file.coordinates
                : undefined,
              caption: ModernFileHelper.isMedia(file)
                ? file.caption
                : undefined,
              requestId,
              ...cleanContext,
            }),
          );
        }

        break;
      }
      case "modularFolder": {
        const { type: _, roomId, modularFolderId, ...cleanContext } = context;

        yield* put(
          BatchActions({
            actions: files.map((file) =>
              OfflineModularFolderActions.addAttachment({
                roomId,
                id: modularFolderId,
                columnKey: cleanContext.columnKey,
                files: [file],
              }),
            ),
          }),
        );
        break;
      }

      case "directory":
        yield* put(fillDirectoryWithFile({ files, ...context }));
        break;

      default:
        break;
    }
    yield* put(
      actionsFileBucket.removeFilesSenderBucket({
        id: generateContextId(action.payload.context),
        emptyAll: true,
      }),
    );
  } catch (error) {
    yield* put(roomActions.uploadFilesFailure(error));
  }
}

export function* uploadFileSaga(
  action: ReturnType<typeof roomActions.uploadFileRequest>,
) {
  const { file, caption, context } = action.payload;

  try {
    switch (context.type) {
      case "shareExtension":
      case "share":
      case "room": {
        const { type: _, ...cleanContext } = context;
        const requestId =
          context.type === "shareExtension" ? context.requestId : uuid();

        yield* put(
          sendFileMessage({
            file,
            caption,
            requestId,
            coords: ModernFileHelper.isMedia(file)
              ? file.coordinates
              : undefined,
            ...cleanContext,
          }),
        );
        break;
      }
      case "modularFolder": {
        const { type: _, roomId, modularFolderId, ...cleanContext } = context;

        yield* put(
          OfflineModularFolderActions.addAttachment({
            roomId,
            id: modularFolderId,
            columnKey: cleanContext.columnKey,
            files: [file],
          }),
        );

        break;
      }
      case "directory": {
        const { type: _, ...cleanContext } = context;
        yield* put(fillDirectoryWithFile({ files: [file], ...cleanContext }));
        break;
      }
      default:
        break;
    }
    yield* put(
      actionsFileBucket.removeFilesSenderBucket({
        id: generateContextId(action.payload.context),
        emptyAll: true,
      }),
    );
  } catch (error) {
    yield* put(roomActions.uploadFileFailure(error));
  }
}
