import React, {
  Children,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  View,
} from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { Button, ButtonSize } from "@kraaft/ui";

import { SPACER_WIDTH, styles } from "./segmentedControl.styles";

export interface SegmentedControlProps<Id extends string> {
  children: (JSX.Element | JSX.Element[])[];
  selected?: string;
  size?: ButtonSize;
  onPress?: (identifier: Id) => void;
}

export const SegmentedControl = <Id extends string = string>({
  size,
  selected,
  onPress,
  children,
}: SegmentedControlProps<Id>) => {
  const { t } = useTranslation();

  const [containerWidth, setContainerWidth] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [segmentCoordinates, setSegmentCoordinates] = useState<
    Record<string, { coordinate: number; index: number }>
  >({});
  const [currentOffset, setCurrentOffset] = useState(0);
  const scrollviewRef = useRef<ScrollView>(null);

  const maxOffset = contentWidth - containerWidth;

  const getSortedSegments = useCallback(
    () =>
      Object.entries(segmentCoordinates).sort(
        (a, b) => a[1].coordinate - b[1].coordinate,
      ),
    [segmentCoordinates],
  );

  const getCoordinateByIndex = useCallback(
    (idx: number) => {
      const sorted = getSortedSegments();
      return sorted.find(([_, { index }]) => index === idx)?.[1].coordinate;
    },
    [getSortedSegments],
  );

  const setFirstAndLastIndexes = useCallback(
    (offset: number) => {
      let newCurrent = 0;
      let newLast = 0;
      const sorted = getSortedSegments();
      for (const [_, { coordinate, index }] of sorted) {
        if (coordinate < offset) {
          newCurrent = index;
        }
        /** Because of pixel rounding, we are leaving 1px margin */

        if (coordinate < offset + containerWidth + 1) {
          newLast = index;
        }
      }
      if (newCurrent !== undefined && newLast !== undefined) {
        setFirstIndex(newCurrent);
        setLastIndex(newLast);
      }
    },
    [containerWidth, getSortedSegments],
  );

  const onLayoutContainer = (event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
    setFirstAndLastIndexes(0);
  };

  const onContentSizeChange = (w: number, _: number) => {
    setContentWidth(w);
  };

  const scrollTo = useCallback((newOffset: number) => {
    scrollviewRef.current?.scrollTo({
      x: newOffset,
      animated: true,
    });
  }, []);

  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const newOffset = event.nativeEvent.contentOffset.x;
      setCurrentOffset(newOffset);
      setFirstAndLastIndexes(newOffset);
    },
    [setFirstAndLastIndexes],
  );

  const onLayoutSegment = useCallback(
    ({ index, id }: { index: number; id: string }) =>
      (event: LayoutChangeEvent) => {
        const coordinate = event.nativeEvent.layout.x;
        // TODO : if component list changing produces bugs, consider using ids as well
        setSegmentCoordinates((previous) => ({
          ...previous,
          [id]: { coordinate, index },
        }));
        setFirstAndLastIndexes(currentOffset);
      },
    [currentOffset, setFirstAndLastIndexes],
  );

  const scrollLeftToIndexStart = useCallback(
    (newIndex: number) => {
      const newCoordinate = getCoordinateByIndex(newIndex);
      if (newCoordinate !== undefined) {
        const newOffset = Math.min(newCoordinate - SPACER_WIDTH, maxOffset);
        scrollTo(newOffset);
      }
    },
    [getCoordinateByIndex, maxOffset, scrollTo],
  );

  const scrollRightToIndexEnd = useCallback(
    (newIndex: number) => {
      if (newIndex >= getSortedSegments().length) {
        scrollTo(maxOffset);
      } else {
        const newSegmentCoordinate = getCoordinateByIndex(newIndex);
        if (newSegmentCoordinate !== undefined) {
          const newCoordinate = Math.max(
            0,
            newSegmentCoordinate - containerWidth,
          );

          if (newCoordinate !== undefined) {
            const newOffset = Math.min(newCoordinate, maxOffset);
            scrollTo(newOffset);
          }
        }
      }
    },
    [
      containerWidth,
      getCoordinateByIndex,
      getSortedSegments,
      maxOffset,
      scrollTo,
    ],
  );

  const scrollToIndex = useCallback(
    (index: number | undefined) => {
      if (index !== undefined) {
        const segmentStartCoordinate = getCoordinateByIndex(index) ?? 0;
        const segmentEndCoordinate =
          getCoordinateByIndex(index + 1) ?? contentWidth;

        const startIsHidden = segmentStartCoordinate < currentOffset;
        const endIsHidden =
          segmentEndCoordinate > currentOffset + containerWidth;

        const segmentIsLongerThanContainer =
          segmentEndCoordinate - segmentStartCoordinate >= containerWidth;

        if (startIsHidden || segmentIsLongerThanContainer) {
          scrollLeftToIndexStart(index);
        } else if (endIsHidden) {
          scrollRightToIndexEnd(index + 1);
        }
      }
    },
    [
      containerWidth,
      contentWidth,
      currentOffset,
      getCoordinateByIndex,
      scrollLeftToIndexStart,
      scrollRightToIndexEnd,
    ],
  );

  useEffect(() => {
    if (selected !== undefined && containerWidth !== 0) {
      scrollToIndex(segmentCoordinates[selected]?.index);
    }
    /* This should only be triggered if container or number of segments changes */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerWidth, segmentCoordinates, selected]);

  const onPressLeftArrow = useCallback(() => {
    const firstSegmentCoordinate = getCoordinateByIndex(firstIndex) ?? 0;
    /** Because of pixel rounding, strict equal does not have the desired behavior ; instead, check that the values are close */
    const newIndex =
      Math.abs(currentOffset - firstSegmentCoordinate) < 1
        ? firstIndex - 1
        : firstIndex;

    if (newIndex >= 0) {
      scrollLeftToIndexStart(newIndex);
    }
  }, [getCoordinateByIndex, firstIndex, currentOffset, scrollLeftToIndexStart]);

  const onPressRightArrow = useCallback(() => {
    const newIndex = lastIndex + 1;
    scrollRightToIndexEnd(newIndex);
  }, [lastIndex, scrollRightToIndexEnd]);

  const showArrows = useMemo(
    () => !isNative() && containerWidth < contentWidth,
    [contentWidth, containerWidth],
  );

  const leftArrowButton = useMemo(
    () =>
      showArrows ? (
        <View style={[styles.arrowButton, styles.arrowButtonLeft]}>
          <Button
            accessibilityLabel={t("previous")}
            icon="chevron-left"
            variant="TERTIARY"
            onPress={onPressLeftArrow}
            disabled={currentOffset === 0}
          />
        </View>
      ) : null,
    [showArrows, onPressLeftArrow, currentOffset, t],
  );

  const rightArrowButton = useMemo(
    () =>
      showArrows ? (
        <View style={[styles.arrowButton, styles.arrowButtonRight]}>
          <Button
            accessibilityLabel={t("next")}
            icon="chevron-right"
            variant="TERTIARY"
            onPress={onPressRightArrow}
            disabled={currentOffset >= maxOffset}
          />
        </View>
      ) : null,
    [showArrows, onPressRightArrow, currentOffset, maxOffset, t],
  );

  return (
    <View style={styles.container}>
      {leftArrowButton}
      <ScrollView
        style={styles.scrollContainer}
        horizontal
        showsHorizontalScrollIndicator={false}
        ref={scrollviewRef}
        onScroll={onScroll}
        scrollEventThrottle={0}
        onLayout={onLayoutContainer}
        onContentSizeChange={onContentSizeChange}
      >
        {Children.map(children.flat(), (child, index) => {
          const handlePress = () => {
            child.props.onPress
              ? child.props.onPress()
              : onPress?.(child.props.id);
          };

          return React.cloneElement(child, {
            key: child.props.id,
            withLeftSpacer: index === 0,
            selected: child.props.selected ?? child.props.id === selected,
            onPress: handlePress,
            size: child.props.size ?? size,
            onLayout: onLayoutSegment({ index, id: child.props.id }),
          });
        })}
      </ScrollView>
      {rightArrowButton}
    </View>
  );
};
