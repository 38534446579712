import { useTranslation } from "react-i18next";

import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame/kDialogFrame";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button } from "@kraaft/ui";
import { ChangePoolTimeZoneDialogContent } from "@kraaft/web/src/views/settings/managePool/settingsTimeZone/changePoolTimeZoneDialogContent";

import { useStyles } from "../settings.styles";

interface Props {
  pool: Pool | undefined;
}

const SettingsTimeZone = (props: Props) => {
  const { pool } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogOpen, openDialog, closeDialog] = useBooleanState(false);

  return (
    <div className={classes.container}>
      <span className={classes.title}>
        {t("adminTimeZone.title")}
        {t("colon")}
      </span>
      <span className={classes.subTitle}>{pool?.timeZone}</span>
      <span className={classes.button}>
        <Button
          onPress={openDialog}
          accessibilityLabel={t("edit")}
          text={t("edit")}
          variant="SECONDARY"
          size="SMALL"
        />
      </span>
      {pool && (
        <KDialogFrame open={dialogOpen} onClose={closeDialog}>
          <ChangePoolTimeZoneDialogContent pool={pool} onClose={closeDialog} />
        </KDialogFrame>
      )}
    </div>
  );
};

export { SettingsTimeZone };
