import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  slideContent: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
  },
});
