import { call, put } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* markRoomUnreadSaga(
  action: ReturnType<typeof roomActions.markRoomUnread>,
) {
  try {
    yield* call(Api.markRoomAsUnread, {
      roomId: action.payload.roomId,
    });
  } catch (error) {
    if (error) {
      yield* put(showError({ title: i18n.t("errorServer") }));
    }
    yield* put(
      roomActions.markRoomUnreadFailure({
        roomId: action.payload.roomId,
        oldValue: action.payload.currentValue,
      }),
    );
  }
}
