import {
  CheckboxCompactedPillRenderer,
  CheckboxPillRenderer,
} from "@kraaft/shared/components/modular/pill/renderers/checkboxPillRenderer";
import {
  CurrencyCompactedPillRenderer,
  CurrencyPillRenderer,
} from "@kraaft/shared/components/modular/pill/renderers/currencyPillRenderer";
import {
  DateCompactedPillRenderer,
  DatePillRenderer,
} from "@kraaft/shared/components/modular/pill/renderers/datePillRenderer";
import {
  GeolocationCompactedPillRenderer,
  GeolocationPillRenderer,
} from "@kraaft/shared/components/modular/pill/renderers/geolocationPillRenderer";
import {
  LongTextCompactedPillRenderer,
  LongTextPillRenderer,
} from "@kraaft/shared/components/modular/pill/renderers/longTextPillRenderer";
import {
  NumberCompactedPillRenderer,
  NumberPillRenderer,
} from "@kraaft/shared/components/modular/pill/renderers/numberPillRenderer";
import {
  SelectCompactedPillRenderer,
  SelectPillRenderer,
} from "@kraaft/shared/components/modular/pill/renderers/selectPillRenderer";
import {
  ShortTextCompactedPillRenderer,
  ShortTextPillRenderer,
} from "@kraaft/shared/components/modular/pill/renderers/shortTextPillRenderer";
import {
  UserCompactedPillRenderer,
  UserPillRenderer,
} from "@kraaft/shared/components/modular/pill/renderers/userPillRenderer";
import {
  AcceptedColumnTypeForPills,
  ExtraContextForPillRenderer,
  PillRenderer,
  PillsDisplayOptions,
} from "@kraaft/shared/components/modular/pill/utils/types";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  FactoryElementWithType,
  getFactoryElement,
} from "@kraaft/shared/core/utils/factoryUtils";

// eslint-disable-next-line complexity
const getPillRendererFactory = (
  compactRenderer: boolean,
): {
  [T in AcceptedColumnTypeForPills]: PillRenderer<T>;
} => ({
  shortText: compactRenderer
    ? ShortTextCompactedPillRenderer
    : ShortTextPillRenderer,
  longText: compactRenderer
    ? LongTextCompactedPillRenderer
    : LongTextPillRenderer,
  currency: compactRenderer
    ? CurrencyCompactedPillRenderer
    : CurrencyPillRenderer,
  number: compactRenderer ? NumberCompactedPillRenderer : NumberPillRenderer,
  selectSingle: (compactRenderer
    ? SelectCompactedPillRenderer
    : SelectPillRenderer) as PillRenderer<KColumnType.selectSingle>,
  selectMultiple: (compactRenderer
    ? SelectCompactedPillRenderer
    : SelectPillRenderer) as PillRenderer<KColumnType.selectMultiple>,
  user: compactRenderer ? UserCompactedPillRenderer : UserPillRenderer,
  date: compactRenderer ? DateCompactedPillRenderer : DatePillRenderer,
  geolocation: compactRenderer
    ? GeolocationCompactedPillRenderer
    : GeolocationPillRenderer,
  checkbox: compactRenderer
    ? CheckboxCompactedPillRenderer
    : CheckboxPillRenderer,
});

export interface GetPillForColumnProps<T extends AcceptedColumnTypeForPills> {
  schemaColumn: KSchemaColumn<T>;
  property: KSchemaColumnValue<T> | undefined;
  extraContext: ExtraContextForPillRenderer;
  displayOptions: PillsDisplayOptions | undefined;
}

function getPillForColumn<T extends AcceptedColumnTypeForPills>(
  compactRenderer: boolean,
  props: GetPillForColumnProps<T>,
): ReturnType<
  FactoryElementWithType<ReturnType<typeof getPillRendererFactory>, T>
> {
  const { schemaColumn, property, extraContext, displayOptions } = props;
  const pillRendererFactory = getPillRendererFactory(compactRenderer);

  const Renderer: FactoryElementWithType<typeof pillRendererFactory, T> =
    getFactoryElement(pillRendererFactory, schemaColumn.type);

  return (
    <Renderer
      schemaColumn={schemaColumn}
      recordProperty={property}
      extraContext={extraContext}
      displayOptions={displayOptions}
    />
  ) as ReturnType<FactoryElementWithType<typeof pillRendererFactory, T>>;
}

export { getPillForColumn };
