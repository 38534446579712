import { CSSTransition } from "react-transition-group";

import { Direction } from "@kraaft/shared/components/animated/types";
import {
  AnimationContext,
  useAnimationContext,
} from "@kraaft/web/src/core/services/animationContext/animationContext";

import { ANIMATION_DURATION, useStyles } from "./slideIn.styles";

interface SlideInProps {
  children: React.ReactNode;
  visible: boolean;
  direction?: Direction;
  disableAnimation?: boolean;
  zIndex?: number;
  hideRatherThanUnmount?: boolean;
  fitContent?: boolean;
}

export const SlideIn = ({
  children,
  visible,
  direction = "left",
  disableAnimation,
  zIndex,
  fitContent,
  hideRatherThanUnmount,
}: SlideInProps) => {
  const classes = useStyles({
    zIndex,
    visible,
    hideRatherThanUnmount,
    fitContent,
  });

  const [animationContextValue, animationCallbackProps] = useAnimationContext({
    defaultAnimationState: visible ? "entered" : "exited",
  });

  return (
    <AnimationContext.Provider value={animationContextValue}>
      <CSSTransition
        in={visible}
        classNames={
          disableAnimation
            ? {}
            : {
                enter: `slideIn-${direction}-enter`,
                enterActive: `slideIn-enter-active slideIn-${direction}-enter-active`,
                exit: `slideIn-${direction}-exit`,
                exitActive: `slideIn-exit-active slideIn-${direction}-exit-active`,
              }
        }
        timeout={disableAnimation ? 0 : ANIMATION_DURATION}
        {...animationCallbackProps}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.container}>{children}</div>
      </CSSTransition>
    </AnimationContext.Provider>
  );
};
