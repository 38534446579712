import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, FontSize, PixelSize } from "@kraaft/ui";

const VISIBLE_ITEM_COUNT = 10;

interface MakeStylesProps {
  minWidth: React.CSSProperties["minWidth"];
  maxWidth: React.CSSProperties["maxWidth"];
}

export const useStyles = makeStyles({
  containerSizer: ({ maxWidth, minWidth }: MakeStylesProps) => ({
    maxWidth,
    minWidth,
  }),
  itemSizer: {
    height: PixelSize.S32,
    boxSizing: "border-box",
  },
  input: {
    fontSize: FontSize.MEDIUM,
    fontFamily: "Apercu",

    caretColor: Color.BLUE_AZURE,

    "&::placeholder": {
      overflow: "visible",
      color: ColorStyle.FONT_LOW_EMPHASIS,
    },
  },
  list: {
    overflowY: "auto",
    maxHeight: VISIBLE_ITEM_COUNT * 33, // 10 items x 33px (item height)
  },
  withSeparatorContainer: {
    display: "flex",
    flexDirection: "column",
  },
  item: {
    flexGrow: 1,
    fontSize: FontSize.MEDIUM,
    fontFamily: "Apercu",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block",
    textOverflow: "ellipsis",
    padding: "0 8px",
  },
  itemBase: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    padding: "4px 8px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: ColorStyle.ACTION_HOVERED,
    },
  },
  itemSeparator: {
    backgroundColor: ColorStyle.SEPARATOR,
    height: 1,
    marginLeft: 12,
  },
  itemSelectedContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: 8,
  },
  emptyRow: {
    height: PixelSize.S32,
  },
  noResults: {
    fontSize: FontSize.MEDIUM,
    fontFamily: "Apercu",
    padding: "8px 12px",

    display: "flex",
    justifyContent: "center",
  },
});
