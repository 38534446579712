import { useMemo } from "react";
import { makeStyles } from "@mui/styles";

import { SECTION_LOCK_INFO_FALLBACK } from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumnLiteralValue } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";
import { KFormatterProps } from "@kraaft/web/src/components/modularTable/components/types";

export function withBasicCell<T extends KColumnType>(
  BasicCellComponent: React.FC<IBasicCellProps<T>>,
): React.FC<KFormatterProps<T>> {
  return (props: KFormatterProps<T>) => {
    const value = props.row.properties[props.column.key];
    const isLoading = Boolean(
      props.column.rowContext[props.row.id]?.loadingCells?.[props.column.key],
    );
    const styles = useStyles();

    const sectionLockInfo = useMemo(
      () =>
        props.column.columnContext?.getSectionLockInfo?.(props.row.properties),
      [props.column.columnContext, props.row],
    );

    return (
      <div
        data-locked={sectionLockInfo?.isLocked}
        className={styles.cellContainer}
      >
        <BasicCellComponent
          row={props.row}
          column={props.column}
          value={value?.value as KSchemaColumnLiteralValue<T> | undefined}
          isCellSelected={props.isCellSelected}
          isLoading={isLoading}
          onRowChange={props.onRowChange}
          sectionLockInfo={sectionLockInfo ?? SECTION_LOCK_INFO_FALLBACK}
        />
      </div>
    );
  };
}

const useStyles = makeStyles({
  cellContainer: {
    flex: 1,
  },
});
