import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { EntryLayout } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayout";
import { selectModularFolderHistoryEntryElementName } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.selectors";
import { ModularFolderPropertyUnchecked } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.state";
import { selectUsername } from "@kraaft/shared/core/modules/user/userSelectors";

interface PropertyUncheckedEntryProps {
  entry: ModularFolderPropertyUnchecked;
}

export const PropertyUncheckedEntry = ({
  entry,
}: PropertyUncheckedEntryProps) => {
  const { t } = useTranslation();

  const initiatorName = useSelector(selectUsername(entry.by));
  const fieldName = useSelector(
    selectModularFolderHistoryEntryElementName(
      entry.modularFolderId,
      entry.key,
    ),
  );

  return (
    <EntryLayout
      date={entry.at}
      iconName="x-circle"
      iconColor="RED_TOMATO"
      i18nKey="history.entryLabel.propertyUnchecked"
      i18nValues={{
        initiatorName,
        fieldName: fieldName ?? t("history.unknownSchemaColumnName"),
      }}
      errorMessage={
        entry.isSchemaColumnMissing
          ? t("history.schemaColumnMissing")
          : undefined
      }
    />
  );
};
