interface EdgeInsets {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export const useSafeAreaInsets = (): EdgeInsets => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});
