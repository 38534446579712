import { ChangeEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Grid, Typography } from "@mui/material";
import clsx from "clsx";

import { KDateInput } from "@kraaft/shared/components/kDateInput";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { RemotePath } from "@kraaft/shared/core/modules/file/file";
import { selectCurrentPoolLocation } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { PoolRoleType } from "@kraaft/shared/core/modules/pool/poolState";
import { Api } from "@kraaft/shared/core/services/api";
import { Button, Preloader } from "@kraaft/ui";

import { CurrentStepProps } from "../../../multiStepDialogTypes";

import { useStyles } from "./generateStep.styles";

type GenerateStepProps = CurrentStepProps;

const GenerateStep = (props: GenerateStepProps) => {
  const { setStep, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const poolLocation = useSelector(selectCurrentPoolLocation);
  const currentPoolId =
    poolLocation?.roleType === PoolRoleType.AT_LEAST_STANDARD
      ? poolLocation.poolId
      : undefined;
  const [includeArchive, setIncludeArchive] = useState(false);
  const [enableDateRange, setEnableDateRange] = useState(false);

  const [endDate, setEndDate] = useState(new Date());
  const _startDate = new Date(endDate);
  _startDate.setDate(_startDate.getDate() - 14);
  const [startDate, setStartDate] = useState(_startDate);

  const [isLoading, toggleLoading] = useState(false);
  const classes = useStyles();

  function goToNextStep(state: { downloadUrl: RemotePath }) {
    setStep({
      index: 1,
      state,
    });
  }

  const handleIncludeArchiveChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIncludeArchive(event.target.checked);
    },
    [],
  );

  const handleEnableDateRangeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEnableDateRange(event.target.checked);
    },
    [],
  );

  async function handleGenerate() {
    if (currentPoolId === undefined) {
      return null;
    }

    try {
      toggleLoading(true);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      const dateRange = {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
      };

      const response = await Api.generateReport({
        poolId: currentPoolId,
        includeArchive,
        dateRange: enableDateRange ? dateRange : undefined,
      });

      goToNextStep({
        downloadUrl: response.downloadUrl,
      });
    } catch (error) {
      toggleLoading(false);
      dispatch(showError({ title: error.message || error }));
    }
  }

  const title = t("generateReportGenerateTitle");

  const content = (
    <Grid container direction="column">
      <Grid item>
        <Typography classes={{ root: clsx(classes.text, classes.spacer) }}>
          {t("generateReportDetails")}
        </Typography>
      </Grid>
      <Grid item container alignItems="center" spacing={1}>
        <Grid item>
          <Checkbox
            disableRipple
            disableTouchRipple
            disableFocusRipple
            checked={includeArchive}
            onChange={handleIncludeArchiveChange}
          />
        </Grid>
        <Grid item>
          <Typography classes={{ root: classes.text }}>
            {t("generateReportIncludeArchive")}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container alignItems="center" spacing={1}>
        <Grid item>
          <Checkbox
            disableRipple
            disableTouchRipple
            disableFocusRipple
            checked={enableDateRange}
            onChange={handleEnableDateRangeChange}
          />
        </Grid>

        <Grid item>
          <Typography classes={{ root: classes.text }}>
            {t("generateReportFilterDate")}
          </Typography>
        </Grid>
      </Grid>

      {enableDateRange && (
        <Grid item container>
          <KDateInput
            forceValue
            defaultValue={startDate}
            label={t("generateReportFrom")}
            onChange={setStartDate}
          />
          <KDateInput
            forceValue
            defaultValue={endDate}
            label={t("generateReportTo")}
            onChange={setEndDate}
          />
        </Grid>
      )}

      <Grid item classes={{ root: classes.buttonContainer }}>
        {isLoading ? (
          <Preloader />
        ) : (
          <Button
            icon="file-04"
            accessibilityLabel={t("generateTheReport")}
            text={t("generateTheReport")}
            onPress={handleGenerate}
          />
        )}
      </Grid>
    </Grid>
  );

  return <KDialogContent {...{ onClose, title, content }} />;
};

export { GenerateStep };
