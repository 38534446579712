import { useTranslation } from "react-i18next";

import { useNavigateToModularFolderHistory } from "@kraaft/shared/components/modularFolders/modularFolderDetails/useNavigateToModularFolderHistory";
import { Button } from "@kraaft/ui";

interface NavigateToModularFolderHistoryProps {
  roomId: string;
  schemaId: string;
  modularFolderId: string;
}

export const NavigateToModularFolderHistory = ({
  roomId,
  schemaId,
  modularFolderId,
}: NavigateToModularFolderHistoryProps) => {
  const { t } = useTranslation();

  const navigateToModularFolderHistory = useNavigateToModularFolderHistory({
    roomId,
    schemaId,
    modularFolderId,
  });

  return (
    <Button
      text={t("history.seeHistory")}
      icon="layout-alt-02"
      variant="QUATERNARY"
      onPress={navigateToModularFolderHistory}
    />
  );
};
