import { makeStyles } from "@mui/styles";

import { Color } from "@kraaft/ui";

export const useStyles = makeStyles({
  input: {
    width: "100%",
    border: "none",
    height: "35px",
    paddingLeft: "10px",
    fontSize: "16px",
    borderRadius: "7px",
  },
  inputSelect: {
    width: "100%",
    border: "none",
    height: "35px",
  },
  centeredContainer: {
    borderRadius: "7px",
    width: "80%",
    border: "none",
    height: "35px",
    padding: "0 10px 0 10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Color.WHITE,
    "&:hover": {
      cursor: "pointer",
    },
  },
  container: {
    borderRadius: "7px",
    width: "80%",
    border: "none",
    height: "35px",
    padding: "0 10px 0 10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: Color.WHITE,
    "&:hover": {
      cursor: "pointer",
    },
  },
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block",
    textOverflow: "ellipsis",
    minHeight: 1, // We need this for the onPaste event to be fired when value is empty
  },
});
