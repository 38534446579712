import { useTranslation } from "react-i18next";
import { DialogActions } from "@mui/material";

import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";
import {
  SelectChecklistTemplate,
  SelectChecklistTemplateProps,
} from "@kraaft/shared/components/selectChecklistTemplate/selectChecklistTemplate";
import { PortalHost } from "@kraaft/ui";

interface BaseSelectChecklistTemplateDialogProps {
  open: boolean;
  onClose: () => void;
  onCreateNewList: () => void;
}

type SelectChecklistTemplateDialogProps =
  BaseSelectChecklistTemplateDialogProps & SelectChecklistTemplateProps;

export const SelectChecklistTemplateDialog = ({
  open,
  onClose,
  onCreateNewList,
  ...props
}: SelectChecklistTemplateDialogProps) => {
  const { t } = useTranslation();

  return (
    <KDialogFrame
      id="ide2e-selectChecklistTemplate-dialog"
      open={open}
      onClose={onClose}
      size={KDialogSize.MEDIUM}
    >
      <>
        <KDialogContent
          title={t("selectChecklistTemplate")}
          content={
            <SelectChecklistTemplate
              actionButtonsPortalHost="dialog-buttons"
              onClose={onClose}
              onCreateNewList={onCreateNewList}
              noPadding
              {...props}
            />
          }
        />
        <DialogActions>
          <PortalHost hostname="dialog-buttons" />
        </DialogActions>
      </>
    </KDialogFrame>
  );
};
