import { call, put, select } from "typed-redux-saga/macro";

import { showSuccess } from "@kraaft/shared/core/modules/alert/alertActions";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* updateRoomVisibilitySaga(
  action: ReturnType<typeof roomActions.updateRoomVisibility>,
) {
  const room = yield* select(selectRoom(action.payload.roomId));

  const beforeVisibility = room?.visibility;
  const willBePrivate = action.payload.visibility === "private";

  try {
    yield* call(Api.toggleRoomVisibility, {
      roomId: action.payload.roomId,
      visibility: action.payload.visibility,
    });
    yield* put(
      showSuccess({
        title: i18n.t(
          willBePrivate ? "makePrivateSuccess" : "makePublicSuccess",
        ),
      }),
    );
  } catch (error) {
    if (beforeVisibility) {
      yield* put(
        roomActions.updateRoomVisibilityFailure({
          roomId: action.payload.roomId,
          visibility: beforeVisibility,
        }),
      );
    }
  }
}
