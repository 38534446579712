import { SignatureSenderProps } from "@kraaft/shared/components/signature/signaturePanel/signatureSender/signatureSenderProps";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { Text } from "@kraaft/ui";

import phone from "./assets/phone.png";

import { useStyles } from "./signatureSender.styles";

const SignatureSender = (_props: SignatureSenderProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <img src={phone} alt="phone" className={styles.image} draggable={false} />
      <Trans
        i18nKey="signature.drawer.notAvailable"
        components={{
          blue: <Text weight="bold" color="ACTION_CTA" />,
          bold: <Text size="TITLE" weight="bold" />,
        }}
      />
    </div>
  );
};

export { SignatureSender };
