import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Insets, ScrollView, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { noop } from "ts-essentials";

import { isNative } from "@kraaft/helper-functions";
import { DetailsSection } from "@kraaft/shared/components/conversationDetails/detailsSection";
import { SectionHeader } from "@kraaft/shared/components/conversationDetails/sectionHeader";
import {
  NewRoomPanel,
  NewRoomSchemaSection,
} from "@kraaft/shared/components/createRoom/roomDetails/newRoomSchemaSection";
import { HideWhenKeyboard } from "@kraaft/shared/components/hideWhenKeyboard";
import { ModularDetailsEditorContext } from "@kraaft/shared/components/modular/details/editors/types";
import { useModularDetails } from "@kraaft/shared/components/modular/details/useModularDetails";
import { ModularTableValueUpdate } from "@kraaft/shared/components/modular/details/utils";
import { useRoomTableSchemaForCreation } from "@kraaft/shared/components/modularFolders/useTableSchemaForCreation";
import { isColumnValueNonEmpty } from "@kraaft/shared/core/modules/modularFolder/modularFolderUtils";
import { updateRoomRecord } from "@kraaft/shared/core/modules/room/roomActions";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { RoomSchemaVisibilityActions } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.actions";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KRoomSchema,
  KSchemaColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordProperties } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { useDefaultModularColumnContext } from "@kraaft/shared/core/modules/schema/modularTypes/useDefaultModularColumnContext";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { ModularDisplayExtendedRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { ModularDisplayRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { applySchemaTemplates } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateActions";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useHeaderHeight } from "@kraaft/shared/core/utils/useHeaderHeight";
import { useEnsureOnline } from "@kraaft/shared/core/utils/useNetwork";
import { Button, ColorStyle, KeyboardAvoidingView } from "@kraaft/ui";

import { styles } from "./roomDetails.layout.styles";

const SCROLL_INSETS: Insets = { right: -0.1 };
const CONTENT_INSETS: Insets = { bottom: 4 };

interface ContainerProps {
  roomId: string;
  poolId: string;
  onSubmit: () => void;
  portalHostname?: string;
}

const RoomDetailsLayoutContainer = ({
  roomId,
  poolId,
  onSubmit,
  portalHostname,
}: ContainerProps) => {
  const roomSchema = useSelector(selectRoomSchema(poolId));
  const room = useSelector(selectRoom(roomId));

  if (!(roomSchema && room)) {
    return null;
  }

  return (
    <ModularDisplayRequirementsProvider>
      <ModularDisplayExtendedRequirementsProvider
        recordType="room"
        noCheckboxConfirmation={false}
      >
        <RoomDetailsLayout
          room={room}
          roomId={roomId}
          schema={roomSchema}
          onSubmit={onSubmit}
          portalHostname={portalHostname}
        />
      </ModularDisplayExtendedRequirementsProvider>
    </ModularDisplayRequirementsProvider>
  );
};

interface Props {
  roomId: string;
  room: Room;
  schema: KRoomSchema;
  onSubmit: () => void;
  portalHostname?: string;
}

const RoomDetailsLayout = (props: Props) => {
  const { schema, roomId, room, portalHostname, onSubmit } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const headerHeight = useHeaderHeight();

  const [schemaTemplateIds, setSchemaTemplateIds] = useState<string[]>([]);

  const [properties, setProperties] = useState<ModularRecordProperties>({});

  const [openedPanel, setOpenedPanel] = useState<NewRoomPanel>();

  const tableSchema = useRoomTableSchemaForCreation(schema);

  useEffect(() => {
    dispatch(RoomSchemaVisibilityActions.subscribe({ roomId }));

    return () => {
      dispatch(RoomSchemaVisibilityActions.unsubscribe({ roomId }));
    };
  }, [dispatch, roomId]);

  const closePanel = useCallback(() => {
    setOpenedPanel(undefined);
  }, []);

  useMemo(() => {
    KSchemaUtils.deleteColumn(
      tableSchema.rootSection,
      KSchemaRemarkableColumns.TITLE,
    );
    KSchemaUtils.deleteColumn(
      tableSchema.rootSection,
      KSchemaRemarkableColumns.ROOM_MEMBERS,
    );

    const firstGeolocationColumn = KSchemaUtils.getFirstColumnOfType(
      tableSchema,
      KColumnType.geolocation,
    );

    if (
      firstGeolocationColumn &&
      room.record.properties[firstGeolocationColumn.key]
    ) {
      KSchemaUtils.deleteColumn(
        tableSchema.rootSection,
        firstGeolocationColumn.key,
      );
    }
  }, [room.record.properties, tableSchema]);

  const handleSubmit = useEnsureOnline(() => {
    dispatch(updateRoomRecord({ roomId, update: properties }));
    dispatch(applySchemaTemplates({ roomId, schemaTemplateIds }));
    onSubmit();

    const propertyNames = Object.entries(properties)
      .filter(([_, columnValue]) => isColumnValueNonEmpty(columnValue))
      .map(
        ([key]) =>
          KSchemaUtils.findColumn(schema.rootSection, key)?.name ?? "unknown",
      );
    trackEvent({
      eventName: "Complete Information On Create Conversation",
      room_id: roomId,
      room_name: getExistingRoomRecordField(
        room.record.properties,
        KSchemaRemarkableColumns.ROOM_TITLE,
        "",
      ),
      properties: propertyNames,
      property_count: propertyNames.length,
    });
  }, [
    dispatch,
    roomId,
    properties,
    schemaTemplateIds,
    onSubmit,
    room,
    schema.rootSection,
  ]);

  const updateRecord = useCallback(
    (update: ModularTableValueUpdate) => {
      setProperties({
        ...properties,
        [update.column.key]: {
          columnType: update.column.type,
          value: update.value,
        } as KSchemaColumnValue,
      });
    },
    [properties],
  );

  const creatingRoomRecord = useMemo(
    () => ({
      ...room.record,
      properties: { ...room.record.properties, ...properties },
    }),
    [room, properties],
  );

  const editorContext: ModularDetailsEditorContext = useMemo(
    () => ({
      portalHostname,
      backgroundColor: ColorStyle.BACKGROUND_STANDARD,
      slideDirection: isNative() ? undefined : "left",
      isRoomCreation: true,
    }),
    [portalHostname],
  );

  const columnsContext = useDefaultModularColumnContext(
    KSchemaUtils.computeSchemaLockLookup(tableSchema.rootSection),
    KSchemaUtils.orderedColumns(tableSchema.rootSection),
  );

  const {
    ModularDetailsContextProvider,
    modularDetailsElements,
    scrollViewProps,
    topElement,
  } = useModularDetails({
    testId: "ide2e-newRoom",
    id: creatingRoomRecord.id,
    rootSection: tableSchema.rootSection,
    recordProperties: creatingRoomRecord.properties,
    updateRecord,
    updateRecordSignature: noop,
    editorContext,
    columnsContext,
    offsetIndices: 1,
  });

  const scrollView = useMemo(
    () => (
      <ScrollView
        style={styles.scrollContainer}
        scrollIndicatorInsets={SCROLL_INSETS}
        contentInset={CONTENT_INSETS}
        {...scrollViewProps}
      >
        {topElement}
        {modularDetailsElements}

        <DetailsSection>
          <SectionHeader title={t("newConversationFlow.schemasSectionLabel")} />

          <NewRoomSchemaSection
            poolId={room.poolId}
            roomId={roomId}
            schemaTemplateIds={schemaTemplateIds}
            setSchemaTemplateIds={setSchemaTemplateIds}
            portalHostname={portalHostname}
            setOpenedPanel={setOpenedPanel}
            openedPanel={openedPanel}
            closePanel={closePanel}
          />
        </DetailsSection>
      </ScrollView>
    ),
    [
      closePanel,
      modularDetailsElements,
      openedPanel,
      portalHostname,
      room.poolId,
      roomId,
      schemaTemplateIds,
      scrollViewProps,
      t,
      topElement,
    ],
  );

  return (
    <>
      <KeyboardAvoidingView
        style={styles.scrollviewWrapper}
        keyboardVerticalOffset={headerHeight}
      >
        <ModularDetailsContextProvider>
          {scrollView}
        </ModularDetailsContextProvider>
      </KeyboardAvoidingView>
      <HideWhenKeyboard>
        <View style={styles.buttonContainer}>
          <Button
            id="ide2e-newTicket-submit"
            accessibilityLabel={t("createRoom.roomDetails.submit")}
            text={t("createRoom.roomDetails.submit")}
            onPress={handleSubmit}
          />
        </View>
      </HideWhenKeyboard>
    </>
  );
};

export { RoomDetailsLayoutContainer as RoomDetailsLayout };
