import { put, select, takeEvery } from "typed-redux-saga/macro";

import { RoomStateActions } from "@kraaft/shared/core/modules/room/roomActions";
import { RoomCardStateActions } from "@kraaft/shared/core/modules/roomCard/roomCard.actions";
import { selectRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { RoomPoolCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";

export function* roomLeft(
  action: ReturnType<typeof RoomStateActions.roomLeft>,
) {
  const currentRoomCard = yield* select(selectRoomCard(action.payload.roomId));

  if (!currentRoomCard) {
    return;
  }

  if (currentRoomCard.type === "pool") {
    return;
  }

  const { userId, isArchivedForUser, pinned, ...roomPoolCard } =
    currentRoomCard;

  yield* put(
    RoomCardStateActions.setRoomCard({
      roomId: action.payload.roomId,
      roomCard: {
        ...roomPoolCard,
        pinned: false,
        type: "pool",
      } satisfies RoomPoolCard,
    }),
  );
}

export function* roomLeftSaga() {
  yield* takeEvery(RoomStateActions.roomLeft, roomLeft);
}
