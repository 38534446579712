import { call } from "typed-redux-saga/macro";

import { RoomSchemaVisibilityStateActions } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.actions";
import {
  selectOptimisticState,
  selectRoomSchemaVisibilities,
} from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.selectors";
import { RoomSchemaVisibility } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.state";
import { Api } from "@kraaft/shared/core/services/api";
import {
  createOperation,
  createOptimisticReduxBundle,
} from "@kraaft/shared/core/utils/optimistic/createReduxBundle";

export const {
  actions: RoomSchemaVisibilityOptimisticActions,
  delaySnapshot: RoomSchemaVisibilityDelaySnapshot,
  operations: RoomSchemaVisibilityOptimisticOperations,
  reducer: RoomSchemaVisibilityOptimisticReducer,
  saga: RoomSchemaVisibilityOptimisticSaga,
  selectors: RoomSchemaVisibilityOptimisticSelector,
} = createOptimisticReduxBundle(
  "RoomSchemaVisibility",
  [
    createOperation<RoomSchemaVisibility, "show", { schemaId: string }>({
      type: "show",
      optimistic: (data, { payload }) => {
        const newSet = new Set(data.whitelist);

        newSet.add(payload.schemaId);
        data.whitelist = newSet;

        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.showSchemaForRoom, {
          roomId: payload.targetId,
          schemaId: payload.schemaId,
        });

        return updatedAt;
      },
    }),
    createOperation<RoomSchemaVisibility, "hide", { schemaId: string }>({
      type: "hide",
      optimistic: (data, { payload }) => {
        const newSet = new Set(data.whitelist);

        newSet.delete(payload.schemaId);
        data.whitelist = newSet;

        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.hideSchemaForRoom, {
          roomId: payload.targetId,
          schemaId: payload.schemaId,
        });

        return updatedAt;
      },
    }),
  ],
  (roomSchemaVisibility) => roomSchemaVisibility.roomId,
  RoomSchemaVisibilityStateActions.set,
  selectOptimisticState,
  selectRoomSchemaVisibilities,
);
