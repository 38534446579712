import moment from "moment";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { ImagePreviewSquare } from "@kraaft/shared/components/imagesPreview/imagePreviewSquare";
import { ImageAttachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { ColorStyle, Spacing, Text } from "@kraaft/ui";

type SignaturePreviewProps = {
  attachment: ImageAttachment;
  onSignaturePress: () => void;
};

const SignaturePreview = ({
  attachment,
  onSignaturePress,
}: SignaturePreviewProps) => {
  const { t } = useTranslation();
  const createdAtFormatted = useMemo(
    () => moment(attachment.createdAt).format("L - LT"),
    [attachment.createdAt],
  );

  return (
    <View style={styles.signatureContainer}>
      <ImagePreviewSquare
        accessibilityLabel={attachment.original.filename}
        downloadUrl={attachment.original.downloadUrl}
        preferredSize="thumbnail"
        onPress={onSignaturePress}
        resizeMode="contain"
        backgroundColor={ColorStyle.BACKGROUND_LIGHT}
      />
      <View style={styles.infoContainer}>
        <Text size="MEDIUM" color="FONT_LOW_EMPHASIS">
          {t("modularity.signedOn")}
        </Text>
        <Text size="BODY" color="FONT_HIGH_EMPHASIS">
          {createdAtFormatted}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  signatureContainer: {
    flexDirection: "row",
    gap: Spacing.S8,
    alignItems: "center",
  },
  infoContainer: {
    flexGrow: 1,
    gap: Spacing.S4,
  },
});

export { SignaturePreview };
