import { StyleSheet } from "react-native";
import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

const detailsStylesConf = {
  guideLine: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
    fontSize: 16,
    marginBottom: Spacing.S16,
  },
  input: {
    backgroundColor: Color.WHITE,
  },
  spacer: {
    flexGrow: 1,
    flexBase: 10,
  },
  validationBox: {
    marginTop: 10,
  },
  validationText: {
    fontSize: FontSize.MEDIUM,
  },
  warning: {
    color: Color.ORANGE_BASIC,
    border: `1px solid ${Color.ORANGE_BASIC}`,
    borderRadius: 4,
    marginTop: Spacing.S24,
    padding: 12,
  },
  stopSpacer: {
    marginBottom: Spacing.S32,
  },
};

export const useDetailsSharepointStyles = makeStyles(detailsStylesConf);

export const detailsSharepointStyleSheet = StyleSheet.create(detailsStylesConf);
