import { RenderFunction } from "@kraaft/helper-hooks";
import {
  InputPartition,
  InputPartitionHelper,
} from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { MarkedText } from "@kraaft/shared/core/framework/markedText/markedText";
import { EveryoneMentionMarker } from "@kraaft/shared/core/framework/markedText/markers/everyoneMention.marker";
import { AnyMarker } from "@kraaft/shared/core/framework/markedText/markers/marker";
import {
  UserMentionMarker,
  UsernameGetter,
} from "@kraaft/shared/core/framework/markedText/markers/userMention.marker";
import { Span } from "@kraaft/shared/core/framework/markedText/span";
import { Mention } from "@kraaft/shared/core/framework/mentionnableText/mention";

export class MentionAwareText {
  public showMentions?: (filter: string) => void;
  public hideMentions?: () => void;
  public placeCursor?: (at: number) => void;

  constructor(
    private readonly render: RenderFunction,
    public readonly markedText: MarkedText,
    public readonly getUsernameFromUserId: UsernameGetter,
  ) {}

  static fromInputPartition(
    render: RenderFunction,
    inputPartitions: InputPartition[],
    getUsernameFromUserId: UsernameGetter,
  ) {
    const { text, markers } =
      InputPartitionHelper.getTextAndMarkersFromInputPartitions(
        inputPartitions,
        getUsernameFromUserId,
      );

    return new MentionAwareText(
      render,
      new MarkedText(text, markers),
      getUsernameFromUserId,
    );
  }

  public resetFromInputPartitions(
    inputPartitions: InputPartition[],
    getUsernameFromUserId: UsernameGetter,
  ) {
    const { text, markers } =
      InputPartitionHelper.getTextAndMarkersFromInputPartitions(
        inputPartitions,
        getUsernameFromUserId,
      );

    this.markedText.setTextAndMarkers(text, markers);
    this.render();
  }

  public ingestText(incomingText: string) {
    const newCursorOffset = this.markedText.ingestText(incomingText);

    if (newCursorOffset !== 0) {
      this.placeCursor?.(this.getCursor() + newCursorOffset);
    }
    this.displayMentionSelection();
    this.render();
  }

  public updateSelection(selection: { start: number; end: number }) {
    this.markedText.updateSelection(selection);
    this.displayMentionSelection();
  }

  public clear() {
    setTimeout(() => {
      this.markedText.setTextAndMarkers("", []);
      this.render();
    }, 1); // clearing when an completion is pending, it sends another onChange with the completed text so we delay the clear
  }

  public getText() {
    return this.markedText.asText();
  }

  public getTextLength() {
    return this.getText().length;
  }

  public getSegments() {
    return this.markedText.asSegments();
  }

  public getCursor() {
    return this.markedText.selection.start;
  }

  private getMarkerForMention(mention: Mention, anchorIndex: number) {
    if (mention.type === "everyone") {
      return new EveryoneMentionMarker(anchorIndex);
    }
    if (mention.type === "user") {
      return new UserMentionMarker(
        anchorIndex,
        mention.id,
        this.getUsernameFromUserId,
      );
    }
    return undefined;
  }

  private addMarkerAtIndex(marker: AnyMarker | undefined, index: number) {
    let cursorOffset = 0;
    if (marker === undefined) {
      return;
    }
    this.markedText.addMarker(marker);

    if (this.getText().at(marker.getRange().end + 1) !== " ") {
      this.markedText.addTextAtIndex(" ", marker.getRange().end);
      cursorOffset = 1;
    }

    this.placeCursor?.(marker.getRange().end + cursorOffset);
    this.render();
  }

  public insertMentionAtIndex(mention: Mention, index: number) {
    const result = this.getPotentialMentionTextAtIndex(index);
    if (result === undefined) {
      this.addMarkerAtIndex(this.getMarkerForMention(mention, index), index);
      return;
    }
    const textOffset = result.textBetweenArobaseAndCursor.length + 1;

    this.markedText.removeTextInRange(
      new Span(result.index, result.index + textOffset),
    );
    this.addMarkerAtIndex(
      this.getMarkerForMention(mention, index - textOffset),
      index,
    );
    this.hideMentions?.();
  }

  private getPotentialMentionTextAtIndex(index: number) {
    const markerAtCursor = this.markedText.getMarkerAtIndex(index);
    if (markerAtCursor !== undefined) {
      return;
    }

    const text = this.markedText.asText().slice(0, index);

    const lastWordIndex = Math.max(text.lastIndexOf(" "), 0);
    const lastWord = text.slice(lastWordIndex);

    const lastArobaseIndex = lastWord.slice(0, index).lastIndexOf("@");
    if (lastArobaseIndex < 0) {
      return undefined;
    }
    const previousChar = text[lastWordIndex + lastArobaseIndex - 1];

    if (previousChar?.match(/\w/)) {
      return undefined;
    }

    const textBetweenArobaseAndCursor = lastWord.slice(
      lastArobaseIndex + 1,
      index,
    );

    return {
      index: lastWordIndex + lastArobaseIndex,
      textBetweenArobaseAndCursor,
    };
  }

  private displayMentionSelection() {
    const result = this.getPotentialMentionTextAtIndex(this.getCursor());

    if (result === undefined) {
      this.hideMentions?.();
      return;
    }
    this.showMentions?.(result.textBetweenArobaseAndCursor);
  }
}
