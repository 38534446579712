import { put, select, spawn, take } from "typed-redux-saga/macro";

import { allStoresRehydrated } from "@kraaft/shared/core/modules/memory/memoryActions";
import { selectMemoryBlockedUserIds } from "@kraaft/shared/core/modules/memory/memorySelectors";
import { memorySagas as platformMemorySagas } from "@kraaft/shared/core/modules/memory/platformSagas";
import { UserStateActions } from "@kraaft/shared/core/modules/user/userActions";

export function* memorySagas() {
  yield* spawn(platformMemorySagas);
  yield* spawn(extractMemorySaga);
}

function* extractMemorySaga() {
  yield* take(allStoresRehydrated);

  const blockedUserIds = Object.keys(yield* select(selectMemoryBlockedUserIds));

  if (blockedUserIds.length > 0) {
    yield* put(UserStateActions.addBlockedUsers({ userIds: blockedUserIds }));
  }
}
