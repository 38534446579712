import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { getBasename, getExtension } from "@kraaft/shared/core/utils/utils";

import { FilePath } from "../file";
import { FileSaver } from "./fileSaver";

export class WebFileSaver implements FileSaver {
  private async triggerWebDownloadForUrl(url: string, name?: string) {
    const response = await fetch(url, {
      method: "GET",
      headers: {},
    });
    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = await fileAllocator.fromBlob(blob);
    link.download = name ?? getBasename(decodeURIComponent(url));
    link.click();
    link.remove();
  }

  async viewDocument(path: FilePath, name?: string) {
    const extension = name ? getExtension(name) : undefined;
    if (extension === "svg") {
      return this.triggerWebDownloadForUrl(path, name);
    }
    window.open(path, "_blank");
  }

  async downloadImage(path: FilePath, name?: string) {
    await this.triggerWebDownloadForUrl(path, name);
  }

  async download(path: FilePath, name?: string) {
    await this.triggerWebDownloadForUrl(path, name);
  }
}
