import { AnyAction } from "redux";
import { Saga, Task } from "redux-saga";
import { call, put, select, take } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import {
  ensureBackgroundServiceRunning,
  startWithBackgroundEngine,
  stopBackgroundEngine,
  updateBackgroundEngineNotification,
  updateProgressBar,
} from "@kraaft/shared/core/modules/message/offline/backgroundEngine";
import {
  errorSendingOfflineMessage,
  incrementSentCount,
  logOfflineMessageEvent,
  OfflineMessageActions,
  offlineMessageSendFailure,
  offlineMessageSendSuccess,
  stopBackgroundServiceRequest,
} from "@kraaft/shared/core/modules/message/offline/offlineMessageActions";
import {
  selectParams,
  sendMessage,
  waitBetterNetwork,
} from "@kraaft/shared/core/modules/message/offline/offlineMessageSagaUtils";
import { OfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageState";
import {
  deleteOptimisticMessage,
  errorSendingMessage,
  successSendingMessage,
} from "@kraaft/shared/core/modules/message/send/sendMessageActions";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { RootState } from "@kraaft/shared/core/store";
import { waitFor } from "@kraaft/shared/core/utils/sagas";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { getTrackDataSendMessageAttemptFromMessage } from "@kraaft/shared/core/utils/tracking/trackEvenUtils";

enum SendMessageStatus {
  SUCCESS = "success",
  NETWORK_ERROR = "networkError",
  SERVER_ERROR = "serverError",
  FILE_NOT_FOUND = "fileNotFoundError",
}

async function trySendMessage(
  message: OfflineMessage,
): Promise<{ status: SendMessageStatus; error?: Error }> {
  try {
    await sendMessage(message);

    trackEvent(getTrackDataSendMessageAttemptFromMessage(message));

    return { status: SendMessageStatus.SUCCESS };
  } catch (error) {
    console.error(error);
    const isNetworkError = HttpError.isNetworkError(error);

    if (isNetworkError) {
      trackEvent({
        eventName: "Send Message Network Failure",
        room_id: message.roomId,
        elapsed_duration_ms: error.elapsedDurationMs ?? -1,
        message_type: message.type,
      });

      return { status: SendMessageStatus.NETWORK_ERROR };
    }

    trackEvent({
      eventName: "Send Message Failure",
      error_status: error.status,
      error_message: error.message,
      room_id: message.roomId,
      request_id: message.optimisticId,
    });
    console.warn("error sending message:", error);

    if ("file" in message && !(await fileAllocator.exists(message.file.path))) {
      return { status: SendMessageStatus.FILE_NOT_FOUND };
    }

    return { status: SendMessageStatus.SERVER_ERROR, error };
  }
}

export function* backgroundSendingSaga() {
  try {
    yield* put(
      logOfflineMessageEvent({ log: "try starting background sending saga" }),
    );

    yield ensureBackgroundServiceRunning();

    yield* put(
      logOfflineMessageEvent({ log: "-> starting background sending saga" }),
    );

    while (true) {
      const {
        sendableQueue: queue,
        sentCount,
        currentUserId,
      } = yield* select(selectParams);

      const message = queue[0];

      yield* put(
        logOfflineMessageEvent({
          log: `next message is defined: ${Boolean(message)}`,
        }),
      );

      if (!message) {
        yield* put(
          logOfflineMessageEvent({
            log: "no more message -> stop service",
          }),
        );

        yield* put(stopBackgroundServiceRequest());
        return;
      }

      if (message.senderId !== currentUserId) {
        yield* put(
          OfflineMessageActions.deleteOfflineMessage(message.optimisticId),
        );
      }

      yield* put(
        logOfflineMessageEvent({
          log: "updating notification",
        }),
      );

      yield updateBackgroundEngineNotification(message.roomId);

      yield* put(
        logOfflineMessageEvent({
          log: "updating progress bar",
        }),
      );

      yield updateProgressBar(
        {
          value: sentCount + 1,
          max: queue.length + sentCount,
        },
        message.type,
      );

      yield* put(logOfflineMessageEvent({ log: "sending message" }));
      const { status, error } = yield* call(trySendMessage, message);

      switch (status) {
        case SendMessageStatus.SUCCESS: {
          yield* put(incrementSentCount());
          yield* put(
            successSendingMessage({
              roomId: message.roomId,
              optimisticId: message.optimisticId,
              requestId: message.requestId,
            }),
          );
          yield* put(offlineMessageSendSuccess(message));

          yield* put(
            OfflineMessageActions.deleteOfflineMessage(message.optimisticId),
          );
          yield* waitFor(
            (state) =>
              !state.offlineMessage.queue.find(
                (m) => m.optimisticId === message.optimisticId,
              ),
          );
          break;
        }

        case SendMessageStatus.NETWORK_ERROR: {
          yield* put(
            offlineMessageSendFailure(
              message,
              new Error(i18n.t("shareExtensionNetworkError")),
            ),
          );
          return;
        }

        case SendMessageStatus.FILE_NOT_FOUND: {
          // we could not find the file, we delete the message
          yield* put(deleteOptimisticMessage(message));
          break;
        }

        case SendMessageStatus.SERVER_ERROR: {
          yield* handleServerError(message, error);
          break;
        }
        default:
          break;
      }
    }
  } catch (e) {
    console.error(e);

    yield* put(
      logOfflineMessageEvent({
        log: `error in backgroundSendingSaga: ${e.message}`,
      }),
    );

    yield* put(stopBackgroundServiceRequest());
  }
}

function* handleServerError(message: OfflineMessage, error: Error | undefined) {
  yield* put(errorSendingOfflineMessage(message.optimisticId));

  yield* put(
    errorSendingMessage({
      roomId: message.roomId,
      optimisticId: message.optimisticId,
    }),
  );

  if (error instanceof HttpError && error.message === "unsupportedExtension") {
    yield* put(
      showError({
        title: i18n.t("messageSendingErrorTitle"),
        message: i18n.t("messageSendingErrorBodyUnsupportedExtension", {
          extension: (error.details as { extension: string } | undefined)
            ?.extension,
        }),
      }),
    );
  } else {
    yield* put(
      showError({
        title: i18n.t("messageSendingErrorTitle"),
        message: i18n.t("messageSendingErrorBody"),
      }),
    );
  }
}

export function* dequeueMessagesSaga() {
  try {
    while (true) {
      yield* put(logOfflineMessageEvent({ log: "waiting before dequeuing" }));
      yield waitFor((state: RootState) => {
        const params = selectParams(state);

        return (
          params.network.isInternetReachable &&
          params.currentUserId !== undefined &&
          params.sendableQueue.length > 0
        );
      });

      const task: Task | undefined = yield* startWithBackgroundEngine(
        backgroundSendingSaga as Saga,
      );

      yield* put(
        logOfflineMessageEvent({
          log: "waiting for stop action",
        }),
      );

      const action = yield* take<WaitBackgroundEngineStopRequestAction>(
        waitBackgroundEngineStopRequest,
      );

      yield* stopBackgroundEngine(task);

      if (action.type === offlineMessageSendFailure.type) {
        yield* put(logOfflineMessageEvent({ log: "waiting better network" }));
        yield* take(waitBetterNetwork);
      }
    }
  } catch (e) {
    console.warn("[Error] dequeueMessagesSaga ::", e);
  }
}

type WaitBackgroundEngineStopRequestAction = ReturnType<
  typeof offlineMessageSendFailure | typeof stopBackgroundServiceRequest
>;

const waitBackgroundEngineStopRequest = (action: AnyAction) =>
  offlineMessageSendFailure.match(action) ||
  stopBackgroundServiceRequest.match(action);
