import React from "react";
import { noop } from "ts-essentials";

import { ModularTableAttachmentContext } from "@kraaft/web/src/components/modularTable/schema";

export type ModularTableContextProps = ModularTableAttachmentContext & {
  onEnterChangeCell: () => void;
  setEditing: (value: boolean) => void;
  isDraggingColumn: boolean;
};

export const ModularTableContext =
  React.createContext<ModularTableContextProps>({
    onFilesUpload: noop,
    isCellLoading: () => false,
    onAttachmentClick: noop,
    onEnterChangeCell: noop,
    setEditing: noop,
    isDraggingColumn: false,
  });
