import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectEveryoneRoomId } from "@kraaft/shared/core/modules/room/selectors";
import { ModularColumnsContext } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { useDefaultModularColumnContext } from "@kraaft/shared/core/modules/schema/modularTypes/useDefaultModularColumnContext";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import {
  selectSchemaLockLookup,
  selectSchemaOrderedColumns,
} from "@kraaft/shared/core/modules/schema/schema.selectors";

export function useRoomModularColumnContext(
  schemaId: string,
): ModularColumnsContext {
  const currentPoolId = useSelector(selectCurrentPoolId);
  const schemaLockLookup = useSelector(selectSchemaLockLookup(schemaId));
  const schemaOrderedColumns = useSelector(
    selectSchemaOrderedColumns(schemaId),
  );
  const context = useDefaultModularColumnContext(
    schemaLockLookup,
    schemaOrderedColumns,
  );

  const everyoneRoomId = useSelector(selectEveryoneRoomId(currentPoolId ?? ""));

  useMemo(() => {
    context.columns[KSchemaRemarkableColumns.ROOM_TITLE] = {
      ...context.columns[KSchemaRemarkableColumns.ROOM_TITLE],
      editable: (_, id) => {
        if (id === everyoneRoomId) {
          return false;
        }
        return true;
      },
    };
  }, [context.columns, everyoneRoomId]);

  return context;
}
