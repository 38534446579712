import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, View } from "react-native";
import { useSelector } from "react-redux";
import difference from "lodash/difference";

import { ActionFooter } from "@kraaft/shared/components/actionFooter/actionFooter";
import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { useSelectableUsers } from "@kraaft/shared/components/createRoom/roomMembers/selectRoomMembersUtils";
import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { useConcurrentUserSelectionUpdate } from "@kraaft/shared/components/editMembersPanelContent/useConcurrentUserSelectionUpdate";
import { EmptyState } from "@kraaft/shared/components/emptyState";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { HideWhenKeyboard } from "@kraaft/shared/components/hideWhenKeyboard";
import { InviteRoomMemberFlow } from "@kraaft/shared/components/memberList/inviteRoomMemberFlow";
import { SelectRoomMembers } from "@kraaft/shared/components/selectRoomMembers";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Color } from "@kraaft/ui";

import { styles } from "./editMembersPanelContent.styles";

interface Props {
  roomId: string | undefined;
  poolId: string;
  selectedUserIds: string[];
  availableUsers: Record<string, User>;
  allowEdition?: boolean;
  onClose?: () => void;
  onSubmit: (selectedUserIds: string[]) => void;
}

export const EditMembersPanelContent = ({
  roomId,
  poolId,
  selectedUserIds,
  availableUsers,
  allowEdition = false,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  const currentUserId = useSelector(selectCurrentUserId);

  const isRoomCreation = roomId === undefined;

  useTrackPage("EditRoomMembers", { identifier: roomId });

  const { preselectedUsers, users, hiddenUserIds } = useSelectableUsers({
    selectedUserIds,
    availableUsers,
    canViewAllUsers: allowEdition,
    currentUserId,
    poolId,
  });

  const [selectedUsers, setSelectedUsers] = useState(preselectedUsers);
  const [isInviteOpen, openInvite, closeInvite] = useBooleanState();

  useConcurrentUserSelectionUpdate({
    preselectedUsers,
    setSelectedUsers,
  });

  const hasPreselectedExternalUsers = preselectedUsers.some(
    (user) => user.isExternalInCurrentPool,
  );

  const isEmpty = users.length <= 1;

  const headerTitle = isEmpty
    ? t("members")
    : t("newTicketMembersTitle", {
        count: selectedUsers.length,
      });

  const handleSubmit = useCallback(() => {
    const preselectedIds = [
      ...hiddenUserIds,
      ...preselectedUsers.map((user) => user.id),
    ];
    const selectedUsersIds = [
      ...hiddenUserIds,
      ...selectedUsers.map((user) => user.id),
    ];

    const toBeAdded = difference(selectedUsersIds, preselectedIds);

    function dispatchChanges() {
      onSubmit(selectedUsersIds);
      onClose?.();
    }

    const toBeAddedExternalMembers = selectedUsers.filter(
      (user) => toBeAdded.includes(user.id) && user.isExternalInCurrentPool,
    );

    if (roomId && toBeAddedExternalMembers.length > 0) {
      trackEvent({
        eventName: "Add Externals To Conversation",
        room_id: roomId,
        added_externals_count: toBeAddedExternalMembers.length,
      });
    }

    if (
      toBeAddedExternalMembers.length > 0 &&
      !hasPreselectedExternalUsers &&
      !isRoomCreation
    ) {
      newConfirmationAlert({
        title: t("newExternalAlertTitle"),
        message: t("newExternalAlertMessage"),
        confirmText: t("confirm"),
        onConfirm: dispatchChanges,
        style: "default",
        size: "MEDIUM",
      });
    } else {
      dispatchChanges();
    }
  }, [
    hiddenUserIds,
    preselectedUsers,
    selectedUsers,
    roomId,
    hasPreselectedExternalUsers,
    isRoomCreation,
    onSubmit,
    onClose,
    t,
  ]);

  if (!currentUserId) {
    return null;
  }

  return (
    <SafeAreaView style={styles.container}>
      <HideWhenKeyboard>
        <DefaultLayoutHeader
          headerTitle={headerTitle}
          headerLeft={
            <HeaderSide>
              <HeaderIconButton
                accessibilityLabel={t("close")}
                icon="chevron-left"
                onPress={onClose}
                id="ide2e-select-members-back"
              />
            </HeaderSide>
          }
          disableSafeAreaBehavior
        />
      </HideWhenKeyboard>
      {isEmpty && roomId ? (
        <View style={styles.emptyPanel}>
          <View style={styles.emptyState}>
            <EmptyState type="members" />
          </View>
          <ActionFooter
            actions={[
              { text: t("createRoom.roomMembers.invite"), onPress: openInvite },
            ]}
            backgroundColor={Color.WHITE}
          />
          <InviteRoomMemberFlow
            open={isInviteOpen}
            onClose={closeInvite}
            roomId={roomId}
            source="room"
          />
          <SafeAreaView />
        </View>
      ) : (
        <SelectRoomMembers
          poolId={poolId}
          roomId={roomId}
          currentUserId={currentUserId}
          users={users}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          onSubmit={allowEdition ? handleSubmit : onClose}
          allowEdition={allowEdition}
          freezeCurrentUser
          source="room"
        />
      )}
    </SafeAreaView>
  );
};
