import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  cornerContainer: {
    position: "absolute",
    width: "200px",
    height: "200px",
    pointerEvents: "none",
  },
  topLeft: {
    top: 0,
    left: 0,
  },
  topRight: {
    top: 0,
    right: 0,
  },
  bottomRight: {
    bottom: 0,
    right: 0,
  },
  bottomLeft: {
    bottom: 0,
    left: 0,
  },
});
