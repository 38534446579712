import { EventChannel, eventChannel } from "redux-saga";
import { assert } from "ts-essentials";
import { put, select } from "typed-redux-saga/macro";

import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { checkUserFromLoadedRooms } from "@kraaft/shared/core/modules/room/sagas/subscribeUtils";
import { computeRoomSubscriptionFilterId } from "@kraaft/shared/core/modules/room/selectors/utils";
import { WithId } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import * as Types from "@kraaft/shared/core/services/firestore/firestoreTypes";
import {
  takeCountedDeep,
  takeFirstAndDebounce,
} from "@kraaft/shared/core/utils/sagas";

type ChannelPayload = WithId<Types.FirestoreRoom>[];
type Channel = EventChannel<WithId<Types.FirestoreRoom>[]>;

function* subscribeSaga(
  registerMeta: (chan: Channel) => void,
  { payload }: ReturnType<typeof roomActions.subscribeToPoolRooms>,
) {
  const currentUserId = yield* select(selectCurrentUserId);
  assert(currentUserId, "no userid");

  const filterId = computeRoomSubscriptionFilterId(payload);

  yield* put(
    roomActions.updateRoomsLoading({
      filterId,
      isLoading: true,
      hasError: false,
    }),
  );

  const roomsChannel = createRoomsChannel(currentUserId, payload.poolId);

  function* receiveRooms(receivedRooms: ChannelPayload) {
    const convertedRooms = yield* KSchemaConversion.toRooms(
      payload.poolId,
      receivedRooms,
    );
    const rooms = convertedRooms.map((room) => ({
      room,
      userHistory: undefined,
    }));

    const result = {
      data: rooms,
      filterId,
      pageResults: {
        ids: rooms.map((item) => item.room.id),
        hasMore: false,
        cursor: undefined,
      },
    };

    yield* checkUserFromLoadedRooms(result);

    yield* put(roomActions.firstRoomsLoaded(result));
  }

  registerMeta(roomsChannel);

  yield takeFirstAndDebounce(roomsChannel, 300, receiveRooms);
}

function* unsubscribeSaga(
  channel: Channel | undefined,
  { payload }: ReturnType<typeof roomActions.subscribeToPoolRooms>,
) {
  yield* put(
    roomActions.updateRoomsLoading({
      filterId: computeRoomSubscriptionFilterId(payload),
      isLoading: false,
      hasError: false,
    }),
  );

  channel?.close();
}

function createRoomsChannel(userId: string, poolId: string) {
  return eventChannel<ChannelPayload>((emit) => {
    const unsubscribe = Firestore.subscribeToRooms(
      userId,
      poolId,
      undefined,
      undefined,
      emit,
    );

    return unsubscribe;
  });
}

export function* subscribeToPoolRoomsSaga() {
  yield takeCountedDeep(
    roomActions.subscribeToPoolRooms,
    roomActions.unsubscribeFromPoolRooms,
    subscribeSaga,
    unsubscribeSaga,
    (action) => computeRoomSubscriptionFilterId(action.payload),
  );
}
