import { Icon, IconName } from "@kraaft/ui";

import { styles } from "./moduleIcon.styles";

interface ReplyModuleIconsProps {
  type: "reply" | "right";
  iconName: IconName;
  isReply: boolean;
}

const ReplyModuleIcon = ({
  type,
  iconName,
  isReply,
}: ReplyModuleIconsProps) => {
  const finalStyles =
    type === "right"
      ? isReply
        ? styles.moduleRight
        : styles.moduleLeft
      : isReply
        ? styles.moduleReply
        : styles.moduleSelf;

  return (
    <Icon
      name={iconName}
      color="FONT_LOW_EMPHASIS"
      size="MEDIUM"
      style={finalStyles}
    />
  );
};

export { ReplyModuleIcon };
