import { datadogLogs } from "@datadog/browser-logs";
import {
  datadogRum,
  DefaultPrivacyLevel,
  RumActionEvent,
} from "@datadog/browser-rum";
import { upperFirst } from "lodash/fp";

import { DATADOG_CONFIG } from "@kraaft/shared/constants/constants";
import {
  AbstractDatadogSDK,
  ActionParams,
  ActionType,
  DynamicDatadogConfig,
  EnvironmentName,
  ErrorParams,
  ErrorSeverity,
  InfoParams,
  UserParams,
  ViewParams,
} from "@kraaft/shared/core/services/datadog/sdk/types";
import { getCurrentVersion } from "@kraaft/shared/core/utils";

export const mapActionType = (
  type?: ActionType,
): RumActionEvent["action"]["type"] => {
  switch (type) {
    case "press":
      return "tap";
    case "application_start":
    case "back":
    case "scroll":
    case "swipe":
      return type;
    default:
      return "custom";
  }
};

export const mapSeverityToLogger = (
  severity: ErrorSeverity,
): typeof datadogLogs.logger.error => {
  switch (severity) {
    case "debug":
      return datadogLogs.logger.debug;
    case "warning":
      return datadogLogs.logger.warn;
    default:
      return datadogLogs.logger.error;
  }
};

export class DatadogSDK extends AbstractDatadogSDK {
  public async init(
    envName: EnvironmentName,
    dynamicConfig: DynamicDatadogConfig,
  ) {
    const common = {
      clientToken: DATADOG_CONFIG.clientToken,
      site: DATADOG_CONFIG.region,
      env: envName,
      version: getCurrentVersion(),
      sessionSampleRate: dynamicConfig.sessionSamplingRate,
      trackResources: true,
      trackLongTasks: true,
    } as const;

    datadogLogs.init({
      ...common,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ["error", "warn"],
    });

    datadogRum.init({
      ...common,
      applicationId: DATADOG_CONFIG.applicationId,
      sessionReplaySampleRate: dynamicConfig.sessionReplaySamplingRate,
      trackUserInteractions: true,
      defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    });

    datadogRum.startSessionReplayRecording();
  }

  public async setUser(user: UserParams) {
    if (!user) {
      datadogLogs.clearUser();
      datadogRum.removeUser();
      return;
    }

    datadogLogs.setUser({ id: user.id });
    datadogRum.setUser({ id: user.id });
  }

  public async startView({ name }: ViewParams) {
    datadogRum.startView(name);
  }

  public async stopView() {
    console.debug("[DatadogSDK]", "stopView not implemented yet");
  }

  public async addAction({
    type,
    message,
    context = {},
  }: ActionParams<ActionType>) {
    datadogRum.addAction(
      `${upperFirst(mapActionType(type))}: ${message}`,
      context,
    );
  }

  public async addError({ error, context }: ErrorParams) {
    datadogRum.addError(error, context);
  }

  public async startSpan() {
    console.debug("[DatadogSDK]", "startSpan not implemented yet");
    return "";
  }

  public async stopSpan() {
    console.debug("[DatadogSDK]", "stopSpan not implemented yet");
  }

  public async logInfo({ message, context }: InfoParams) {
    datadogLogs.logger.info(message, context);
  }

  public async logError({ error, severity = "error", context }: ErrorParams) {
    const logger = mapSeverityToLogger(severity);

    logger(error.message, context, error);
  }
}
