import { StyleSheet } from "react-native";

import { authScreensTokens } from "@kraaft/shared/components/auth/authScreenTokens";
import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.WHITE,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "100%",
  },

  titleContainer: {
    marginBottom: authScreensTokens.marginBelowTitlesContainer,
  },
  title: {
    marginBottom: authScreensTokens.marginBelowTitleAndSubtitle,
  },

  togglePasswordButton: {
    position: "absolute",
    top: 16,
    right: 10,
  },

  errorContainer: {
    marginVertical: Spacing.S8,
  },

  navigateSpacer: {
    height: Spacing.S32,
    flexShrink: 1,
  },

  navigateButtonContainer: {
    justifyContent: "center",
    alignItems: "center",
  },

  buttonSpacer: {
    height: Spacing.S40,
    flexGrow: 1,
    flexShrink: 1,
  },

  button: {
    marginTop: Spacing.S24,
  },
});
