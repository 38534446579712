import { Pressable, StyleSheet, View } from "react-native";

import { Color, FontSize, Icon, IconName, Text } from "@kraaft/ui";

interface QuickAccessButtonProps {
  text: string;
  iconName: IconName;
  onPress: () => void;
}

export const QuickAccessButton = ({
  iconName,
  text,
  onPress,
}: QuickAccessButtonProps) => {
  return (
    <Pressable
      onPress={onPress}
      accessibilityLabel={text}
      style={styles.wrapper}
    >
      <View style={styles.roundedIconWrapper}>
        <Icon size="MEDIUM" name={iconName} color="BLUE_KRAAFT" />
      </View>
      <Text style={styles.text} weight="medium">
        {text}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    gap: 8,
  },
  roundedIconWrapper: {
    borderRadius: 44 / 2,
    width: 44,
    aspectRatio: 1,
    backgroundColor: Color.GREY_ANTIFLASH,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  text: {
    fontSize: FontSize.MEDIUM,
    color: Color.BLUE_KRAAFT,
  },
});
