import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { DirectoryNameSheet } from "@kraaft/shared/components/directory/directoryName.sheet";
import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import { DirectoryActions } from "@kraaft/shared/core/modules/directory/directoryActions";

interface RenameDirectorySheetProps {
  roomId: string;
  open: boolean;
  onClose: () => void;
  directory: Directory;
}

export const RenameDirectorySheet = ({
  open,
  roomId,
  onClose,
  directory,
}: RenameDirectorySheetProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [newDirectoryName, setNewDirectoryName] = useState<string>(
    directory.name,
  );

  const internOnClose = useCallback(() => {
    setNewDirectoryName(directory.name);
    onClose();
  }, [directory.name, onClose]);

  useEffect(() => {
    setNewDirectoryName(directory.name);
  }, [directory.name]);

  const handleRenameDirectory = useCallback(() => {
    if (newDirectoryName === directory.name) {
      internOnClose();
      return;
    }
    dispatch(
      DirectoryActions.rename({
        roomId: directory.roomId,
        directoryId: directory.id,
        newDirectoryName,
      }),
    );
    internOnClose();
  }, [
    directory.id,
    directory.name,
    directory.roomId,
    dispatch,
    internOnClose,
    newDirectoryName,
  ]);

  const { element } = DirectoryNameSheet.use({
    open,
    onClose: internOnClose,
    initialValue: newDirectoryName,
    currentName: directory.name,
    loading: false,
    onApply: handleRenameDirectory,
    parentId: directory.parentId,
    roomId: roomId,
    onValueChange: setNewDirectoryName,
    validateActionLabel: t("validate"),
  });

  return <>{element}</>;
};
