import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useMeshContext, useMeshContextSetup } from "@kraaft/helper-hooks";
import { Button } from "@kraaft/ui";
import { DirectoryTreeStructure } from "@kraaft/web/src/components/directoryTree/directoryTree.types";
import { DirectoryTreeChildDirectories } from "@kraaft/web/src/components/directoryTree/directoryTreeChildDirectories";
import {
  DirectoryTreeContext,
  useDirectoryTreeContextProviderValue,
} from "@kraaft/web/src/components/directoryTree/directoryTreeContext";
import { DirectoryTreeDragLayer } from "@kraaft/web/src/components/directoryTree/directoryTreeDragLayer";

import { useStyles } from "./directoryTree.styles";

const DirectoryTreeContent = () => {
  const {
    directoryTreeStructure: { rootDirectoryIds },
    createTemporaryDirectory,
  } = useMeshContext(DirectoryTreeContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const handleAddRootDirectory = useCallback(() => {
    createTemporaryDirectory(undefined);
  }, [createTemporaryDirectory]);

  return (
    <div className={classes.container}>
      <DirectoryTreeChildDirectories
        parentDirectoryId={undefined}
        directoryIds={rootDirectoryIds}
        parentLevel={0}
      />
      <div className={classes.addButtonContainer}>
        <Button
          accessibilityLabel={t("directory.addDirectory")}
          text={t("directory.addDirectory")}
          icon="plus"
          variant="TERTIARY"
          size="SMALL"
          onPress={handleAddRootDirectory}
        />
      </div>
      <DirectoryTreeDragLayer />
    </div>
  );
};

export interface BaseDirectoryTreeProps {
  onUpdate?: (directoryTreeStructure: DirectoryTreeStructure) => void;
  onAddDirectory?: (name: string, parentDirectoryId?: string) => void;
  onMoveDirectory?: (
    directoryId: string,
    newParentDirectoryId: string,
    afterSiblingDirectoryId?: string,
  ) => void;
  onRenameDirectory?: (directoryId: string, newDirectoryName: string) => void;
  onRemoveDirectory?: (directoryId: string) => Promise<boolean> | boolean;
}

interface DirectoryTreeProps extends BaseDirectoryTreeProps {
  directoryTreeStructure: DirectoryTreeStructure;
}

const DirectoryTree = ({
  directoryTreeStructure,
  onUpdate,
  onAddDirectory,
  onMoveDirectory,
  onRenameDirectory,
  onRemoveDirectory,
}: DirectoryTreeProps) => {
  return (
    <DirectoryTreeContext.Provider
      value={useMeshContextSetup(
        useDirectoryTreeContextProviderValue({
          directoryTreeStructure,
          onUpdate,
          onAddDirectory,
          onMoveDirectory,
          onRenameDirectory,
          onRemoveDirectory,
        }),
      )}
    >
      <DirectoryTreeContent />
    </DirectoryTreeContext.Provider>
  );
};

export { DirectoryTree };
