import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { SignInSSO } from "@kraaft/shared/components/auth/signInSSO/signInSSO";
import { KraaftLogo } from "@kraaft/shared/components/kraaftLogo";
import { selectLastLoginInfos } from "@kraaft/shared/core/modules/login/login.slice";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper/splitScreenWrapper";
import { ConfirmationCodeWeb } from "@kraaft/web/src/views/auth/confirmationCodeWeb";
import { EnterPasswordWeb } from "@kraaft/web/src/views/auth/enterPasswordWeb";
import { ForgotPasswordEmailSentWeb } from "@kraaft/web/src/views/auth/forgotPasswordEmailSentWeb";
import { SignInEmailWeb } from "@kraaft/web/src/views/auth/signInEmailWeb";
import { SignInPhoneWeb } from "@kraaft/web/src/views/auth/signInPhoneWeb";

import { routes } from "./routes";

const AuthFlow = () => {
  const lastLoginInfos = useSelector(selectLastLoginInfos);
  return (
    <Switch>
      <Route path={routes.SignInPhone} exact>
        <SignInPhoneWeb />
      </Route>
      <Route path={routes.SignInEmail} exact>
        <SignInEmailWeb />
      </Route>
      <Route path={routes.ForgotPasswordEmailSent} exact>
        <ForgotPasswordEmailSentWeb />
      </Route>
      <Route path={routes.EnterPassword} exact>
        <EnterPasswordWeb />
      </Route>
      <Route path={routes.ConfirmationCode} exact>
        <ConfirmationCodeWeb />
      </Route>
      <Route path={routes.SignInSSO} exact>
        <SplitScreenWrapper headerLeft={<KraaftLogo />}>
          <SignInSSO />
        </SplitScreenWrapper>
      </Route>
      <Redirect
        to={
          lastLoginInfos?.method === "email"
            ? routes.SignInEmail
            : routes.SignInPhone
        }
      />
    </Switch>
  );
};

export { AuthFlow };
