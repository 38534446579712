import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import {
  KSelectableListItem,
  KSelectionListProps,
} from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { withPermissionRequester } from "@kraaft/shared/components/permissionRequester/withPermissionRequester";
import { RoomNotificationFilter } from "@kraaft/shared/core/modules/room/roomState";
import { PoolNotificationFilter } from "@kraaft/shared/core/modules/userPool/userPool.state";

import { styles } from "./notificationPreferences.styles";

type NotificationPreferencesProps = {
  poolNotificationFilter: PoolNotificationFilter;
  noPadding?: boolean;
} & (
  | {
      type: "room";
      option: RoomNotificationFilter;
      onChange: (newOption: RoomNotificationFilter) => void;
    }
  | {
      type: "pool";
      onChange: (newOption: PoolNotificationFilter) => void;
    }
);

export const NotificationPreferences = withPermissionRequester(
  (props: NotificationPreferencesProps) => {
    const { t } = useTranslation();
    const option =
      props.type === "pool" || props.option === "inherit"
        ? props.poolNotificationFilter
        : props.option;

    const internOnChange = useCallback<
      KSelectionListProps<
        RoomNotificationFilter | PoolNotificationFilter
      >["setSelected"]
    >(
      (newOptions) => {
        const [newOption] = newOptions;
        if (!newOption) {
          return;
        }
        // Will be either one or the other
        props.onChange(newOption as any);
      },
      [props],
    );

    const items = useMemo<
      KSelectableListItem<PoolNotificationFilter | RoomNotificationFilter>[]
    >(
      () => [
        {
          identifier: "all",
          label: t("notifications.options.all"),
        },
        {
          identifier: "only_mentions",
          label: t("notifications.options.onlyMentions"),
        },
      ],
      [t],
    );

    const options = useMemo(() => [option], [option]);

    return (
      <View style={styles.root}>
        <KSelectionList
          noPadding={props.noPadding}
          style={styles.list}
          isMultiple={false}
          items={items}
          selected={options}
          setSelected={internOnChange}
          disableScroll
        />
      </View>
    );
  },
  "notification",
  "notification-preferences",
  styles.permissionWrapperContainer,
);
