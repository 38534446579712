import { StyleSheet } from "react-native";
import { makeStyles } from "@mui/styles";

import { Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  container: {
    paddingTop: Spacing.S24,
    paddingLeft: Spacing.S32,
    paddingRight: Spacing.S32,
    maxWidth: 700,
  },
  section: {
    marginBottom: Spacing.S32,
    "& > :first-child": {
      display: "block",
      marginBottom: Spacing.S16,
    },
  },

  group: {
    display: "flex",
    flexDirection: "column",
    marginBottom: Spacing.S24,
  },

  leftAlignedContainer: {
    alignItems: "flex-start",
  },
  coverPictureLabelContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
    marginBottom: Spacing.S16,
  },
  coverPictureActionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: Spacing.S8,
  },
  coverImage: {
    aspectRatio: 2.5,
    width: 320,
    objectFit: "cover",
    borderRadius: Radius.SMALL,
    marginBottom: Spacing.S16,
  },
});

export const styles = StyleSheet.create({
  textSpacer: {
    display: "flex",
    marginBottom: Spacing.S16,
  },
  multilineTextInput: {
    minHeight: 150,
    flexGrow: 1,
  },
});
