import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  ImageBackground,
  ImageSourcePropType,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";

import { Hoverable } from "@kraaft/shared/components/hoverable";
import { HoverableRenderProps } from "@kraaft/shared/components/hoverable/types";
import { Button, ButtonProps, Color, ColorStyle, Spacing } from "@kraaft/ui";

import mapModularFolders from "../../assets/images/mapModularFolders.png";
import mapModularFoldersDetails from "../../assets/images/mapModularFoldersDetails.png";
import mapPhotos from "../../assets/images/mapPhotos.png";
import mapRooms from "../../assets/images/mapRooms.png";

const HEIGHT = 60;

const IMAGE: Record<MapButtonProps["type"], string | number> = {
  modularFolders: mapModularFolders,
  modularFoldersDetails: mapModularFoldersDetails,
  photos: mapPhotos,
  rooms: mapRooms,
};

export type MapButtonProps = Required<Pick<ButtonProps, "onPress">> & {
  type: "modularFolders" | "modularFoldersDetails" | "photos" | "rooms";
  isSelected?: boolean;
};

const MapButton = ({ type, isSelected, onPress, ...props }: MapButtonProps) => {
  const { t } = useTranslation();

  const accessibilityLabel = t("openMap");

  const renderContent = useCallback(
    ({ hoverProps, isHovered }: HoverableRenderProps): JSX.Element => {
      return (
        <TouchableOpacity
          accessibilityLabel={accessibilityLabel}
          onPress={onPress}
        >
          <View
            style={[
              styles.card,
              isSelected && styles.cardSelected,
              isHovered && styles.cardHovered,
            ]}
            {...hoverProps}
          >
            <ImageBackground
              source={IMAGE[type] as ImageSourcePropType}
              resizeMode="cover"
              style={styles.image}
            >
              <Button
                accessibilityLabel={accessibilityLabel}
                id="room-map-button"
                icon={isSelected ? "minimize-01" : "expand-01"}
                variant="SECONDARY"
                size="SMALL"
                selected={isSelected}
                onPress={onPress}
                {...props}
              />
            </ImageBackground>
          </View>
        </TouchableOpacity>
      );
    },
    [onPress, type, isSelected, props, accessibilityLabel],
  );

  return <Hoverable>{renderContent}</Hoverable>;
};

const styles = StyleSheet.create({
  card: {
    borderRadius: 6,
    overflow: "hidden",
    borderWidth: 2,
    borderColor: Color.WHITE,
  },
  cardSelected: {
    borderColor: ColorStyle.ACTION_CTA,
  },
  cardHovered: {
    opacity: 0.8,
  },
  image: {
    height: HEIGHT,
    alignItems: "flex-end",
    padding: Spacing.S16,
  },
});

export { MapButton };
