import React from "react";
import { useTranslation } from "react-i18next";

import { useMeshContext } from "@kraaft/helper-hooks";
import {
  CompositeCondition,
  CoupleRecordCondition,
  MetadataCondition,
  StandaloneRecordCondition,
} from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { ModularDisplayExtendedRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { ModularDisplayRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { type ResourceKey } from "@kraaft/shared/core/services/i18next";
import { Text } from "@kraaft/ui";
import {
  getBuiltinCondition,
  getFilterValue,
} from "@kraaft/web/src/components/filterMenu/filterUtils";
import { useSchemaBuilderRootSection } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

interface StandaloneConditionExplainerProps {
  condition: StandaloneRecordCondition;
}

const StandaloneConditionExplainer = ({
  condition,
}: StandaloneConditionExplainerProps) => {
  const { t } = useTranslation();

  const rootSection = useSchemaBuilderRootSection();

  const columnInfo = KSchemaUtils.findColumnWithContext(
    rootSection,
    condition.columnKey,
  );
  if (!columnInfo) {
    return null;
  }
  const c = getBuiltinCondition(
    columnInfo.element.type,
    "standalone",
    condition.predicate,
  );
  if (!c) {
    return null;
  }

  return (
    <>
      <Text weight="bold">
        {KSchemaUtils.getStringPath(columnInfo.path, columnInfo.element)}{" "}
      </Text>
      <Text>{t(c.translationKey as ResourceKey)}</Text>
    </>
  );
};

interface CoupleConditionExplainerProps {
  condition: CoupleRecordCondition;
}

const CoupleConditionExplainer = ({
  condition,
}: CoupleConditionExplainerProps) => {
  const { t } = useTranslation();

  const { getPoolUsers } = useMeshContext(ModularDisplayRequirementsContext);
  const rootSection = useSchemaBuilderRootSection();

  const columnContext = KSchemaUtils.findColumnWithContext(
    rootSection,
    condition.columnKey,
  );

  if (!columnContext) {
    return null;
  }
  const c = getBuiltinCondition(
    columnContext.element.type,
    condition.value.columnType,
    condition.predicate,
  );
  if (!c) {
    return null;
  }

  return (
    <>
      <Text weight="bold">
        {KSchemaUtils.getStringPath(
          columnContext.path,
          columnContext.element,
        )}{" "}
      </Text>
      <Text>{t(c.translationKey as ResourceKey)} </Text>
      <Text weight="bold">
        {getFilterValue(columnContext.element, condition, getPoolUsers()) ?? ""}
      </Text>
    </>
  );
};

interface MetadataConditionExplainerProps {
  condition: MetadataCondition;
}

const MetadataConditionExplainer = ({
  condition,
}: MetadataConditionExplainerProps) => {
  const { t } = useTranslation();

  const { getRoomFromRecordId } = useMeshContext(
    ModularDisplayExtendedRequirementsContext,
  );

  return (
    <>
      <Text weight="bold">{t("filterTicket")} </Text>
      <Text>{t("is")} </Text>
      <Text weight="bold">
        {typeof condition.value?.value === "string"
          ? getExistingRoomRecordField(
              getRoomFromRecordId(condition.value.value)?.record.properties,
              KSchemaRemarkableColumns.ROOM_TITLE,
              "",
            )
          : ""}
      </Text>
    </>
  );
};

interface ConditionExplainerProps {
  compositeCondition: CompositeCondition;
}

const ConditionExplainer = ({
  compositeCondition,
}: ConditionExplainerProps) => {
  const { t } = useTranslation();

  const operator =
    compositeCondition.operator === "and" ? t("andFilter") : t("orFilter");

  return (
    <>
      {compositeCondition.conditions.map((condition, index) => (
        <React.Fragment key={index.toString()}>
          {index !== 0 && (
            <>
              <br />
              {operator}
              &nbsp;
            </>
          )}
          {condition.type === "standalone-record" && (
            <StandaloneConditionExplainer condition={condition} />
          )}
          {condition.type === "couple-record" && (
            <CoupleConditionExplainer condition={condition} />
          )}
          {condition.type === "metadata" && (
            <MetadataConditionExplainer condition={condition} />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export { ConditionExplainer };
