import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { ConversationKanbanView } from "@kraaft/web/src/components/conversationViewer/kanban";
import { ConversationMapView } from "@kraaft/web/src/components/conversationViewer/map";
import { ConversationTableView } from "@kraaft/web/src/components/conversationViewer/table";
import { FormatType } from "@kraaft/web/src/components/visualisationSelector/visualisationSelector.types";

interface Props {
  format: string | null;
  schemaId: string;
  rooms: Room[];
  schemaViewId?: string;
}

const ConversationFormatViewer = (props: Props) => {
  const { format, schemaId, rooms, schemaViewId } = props;

  if (format === FormatType.TABLE) {
    return <ConversationTableView schemaId={schemaId} rooms={rooms} />;
  }

  if (format === FormatType.MAP) {
    return <ConversationMapView rooms={rooms} />;
  }
  if (format === FormatType.KANBAN) {
    return (
      <ConversationKanbanView
        schemaId={schemaId}
        rooms={rooms}
        schemaViewId={schemaViewId}
      />
    );
  }

  return null;
};

export { ConversationFormatViewer };
