import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { selectRoomPages } from "@kraaft/shared/core/modules/room/selectors/directSelectors";
import {
  computeFilterIds,
  RoomFiltersWithPool,
} from "@kraaft/shared/core/modules/room/selectors/utils";

export const selectIsLoadingRooms = memoize((filters: RoomFiltersWithPool) =>
  createSelector(selectRoomPages, (loadedData) => {
    const { subscriptionFilterId, paginationFilterId } =
      computeFilterIds(filters);

    const isLoadingInitial = loadedData[subscriptionFilterId]?.isLoading;
    const subscriptionHasMore =
      loadedData[subscriptionFilterId]?.results.hasMore ?? false;

    return {
      isLoading: Boolean(
        isLoadingInitial || loadedData[paginationFilterId]?.isLoading,
      ),
      isLoadingInitial,
      hasError: loadedData[paginationFilterId]?.hasError,
      subscriptionHasMore,
    };
  }),
);
