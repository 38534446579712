import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";
import { selectClipboardTextFromCell } from "@kraaft/web/src/components/modularTable/components/table/clipboardTextFromCellUtils";
import { SelectColumnEditor } from "@kraaft/web/src/components/modularTable/components/table/columnHeader/columnEditor/columnEditors/selectColumnEditor";
import { SelectEditor } from "@kraaft/web/src/components/modularTable/components/table/editors/selectEditor";
import { selectRecordComparator } from "@kraaft/web/src/components/modularTable/components/table/recordComparatorUtils";
import { selectValueFromClipboardText } from "@kraaft/web/src/components/modularTable/components/table/valueFromClipboardTextUtils";
import { IFieldConfig } from "@kraaft/web/src/components/modularTable/components/types";

import { withBasicCell } from "../_withTableCell/withBasicCell";
import { SelectCell } from "./basicCell";

export const selectSingleConfig: IFieldConfig<KColumnType.selectSingle> = {
  type: KColumnType.selectSingle,
  TableCell: withBasicCell(
    SelectCell as React.FC<IBasicCellProps<KColumnType.selectSingle>>,
  ),
  TableEditor: SelectEditor,
  comparator: selectRecordComparator,
  columnEditor: SelectColumnEditor,
  getClipboardTextFromCell: selectClipboardTextFromCell,
  getValueFromClipboardText: selectValueFromClipboardText,
};

export const selectMultipleConfig: IFieldConfig<KColumnType.selectMultiple> = {
  type: KColumnType.selectMultiple,
  TableCell: withBasicCell(
    SelectCell as React.FC<IBasicCellProps<KColumnType.selectMultiple>>,
  ),
  TableEditor: SelectEditor,
  comparator: selectRecordComparator,
  columnEditor: SelectColumnEditor,
  getClipboardTextFromCell: selectClipboardTextFromCell,
  getValueFromClipboardText: selectValueFromClipboardText,
};
