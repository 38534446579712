import identity from "lodash/identity";

import { AnyUnexplained } from "@kraaft/shared/core/types";

import { KTableColumn, Order } from "./kTableTypes";

export function stableSort<T>(
  array: Array<T>,
  sortFn: ((a: T, b: T) => number) | null,
): Array<T> {
  if (!sortFn) {
    return array;
  }

  const stabilizedThis: [T, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = sortFn(a[0], b[0]);
    return order !== 0 ? order : a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export function getSorting<V>(
  order: Order,
  column: KTableColumn<AnyUnexplained, V> | undefined,
): ((a: V, b: V) => number) | null {
  if (column === undefined) {
    return null;
  }
  if (column.compare === undefined) {
    return () => 0;
  }
  const getValue: (item: AnyUnexplained, index?: number | undefined) => V =
    column.value || identity;
  return (a, b) => order * (column.compare?.(getValue(a), getValue(b)) ?? 0);
}

export function ensureColumnType<T, V>(
  config: KTableColumn<T, V>,
): KTableColumn<T, V> {
  return config;
}
