import { StyleSheet } from "react-native";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  messageSenderNameContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: Spacing.S4,
  },
  messageSenderName: {
    fontFamily: "Apercu-Medium",
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  extraText: {
    fontFamily: "Apercu-Light",
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
});
