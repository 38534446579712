import { put, takeEvery } from "typed-redux-saga/macro";

import { OfflineMessageActions } from "@kraaft/shared/core/modules/message/offline/offlineMessageActions";
import { deleteOptimisticMessage } from "@kraaft/shared/core/modules/message/send/sendMessageActions";

export function* deleteOptimisticMessageSaga() {
  yield* takeEvery(deleteOptimisticMessage, function* ({ payload }) {
    yield* put(
      OfflineMessageActions.deleteOfflineMessage(payload.optimisticId),
    );
  });
}
