import { createContext, useContext } from "react";

export const ModularFolderContext = createContext({
  preview: false,
});

export function useIsInModularFolderPreview(): boolean {
  const context = useContext(ModularFolderContext);

  return context.preview;
}
