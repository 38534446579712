import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    minWidth: 0,
  },
  innerContainer: {
    padding: 2,
  },
});
