import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { setLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { selectPoolMember } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { SuperadminApi } from "@kraaft/shared/core/services/api/superadminApi";
import { i18n } from "@kraaft/shared/core/services/i18next";

import * as actions from "./poolMemberActions";
import { PoolMember } from "./poolMemberState";

export function* poolMemberSagas() {
  yield* takeEvery(actions.loadPoolMembers, loadPoolMembersSaga);
  yield* takeEvery(actions.removeMemberFromPool, removeMemberFromPoolSaga);
  yield* takeEvery(actions.addMemberToPool, addMemberToPoolSaga);
  yield* takeEvery(actions.editPoolMember, editPoolMemberSaga);
  yield* takeEvery(
    actions.disconnectMicrosoftStorage,
    disconnectMicrosoftStorage,
  );
}

function* loadPoolMembersSaga(
  action: ReturnType<typeof actions.loadPoolMembers>,
) {
  const { poolId, superadminPanel } = action.payload;

  try {
    const data = yield* call(() =>
      Api.getPoolMembers({ poolId, counts: superadminPanel }),
    );
    yield* put(actions.populatePoolMembers({ members: data }));
  } catch (error) {
    yield* put(showError({ title: i18n.t("internalError") }));
  }
}

function* removeMemberFromPoolSaga(
  action: ReturnType<typeof actions.removeMemberFromPool>,
) {
  const { poolId, userId, superadminPanel } = action.payload;

  try {
    yield Api.removeMemberFromPool({ poolId, userId });
    yield* put(showSuccess({ title: i18n.t("removedSuccess") }));
    yield* put(actions.removeMemberFromPoolSuccess({ userId }));
    yield* put(actions.loadPoolMembers({ poolId, superadminPanel }));
  } catch (error) {
    yield* put(
      showError({ title: i18n.t("removedFailure"), message: error.message }),
    );
  }
}

function* addMemberToPoolSaga(
  action: ReturnType<typeof actions.addMemberToPool>,
) {
  const { poolId, userId } = action.payload;
  const meta = action.meta;

  try {
    yield SuperadminApi.addMemberToPool({ poolId, userId: userId.trim() });
    yield* put(actions.loadPoolMembers({ poolId, superadminPanel: true }));
    yield* put(setLoader({ ...meta.loader, status: LoaderStatus.SUCCESS }));
  } catch (error) {
    yield* put(showError({ title: i18n.t("internalError") }));
    yield* put(
      setLoader({ ...meta.loader, status: LoaderStatus.FAILURE, error }),
    );
  }
}

function* editPoolMemberSaga({
  payload: { userId, poolId, update },
}: ReturnType<typeof actions.editPoolMember>) {
  const poolMember = yield* select(selectPoolMember(userId));

  const rollback = poolMember
    ? (Object.keys(update) as (keyof PoolMember)[]).reduce(
        (res, key) => {
          // biome-ignore lint/performance/noAccumulatingSpread: Partial<T> makes it difficult not to spread
          return { ...res, [key]: poolMember[key] };
        },
        {} as Partial<PoolMember>,
      )
    : {};

  yield* put(actions.updatePoolMember({ userId, update }));

  try {
    const result = yield* call(Api.editPoolMember, { userId, poolId, update });

    yield* put(actions.updatePoolMember({ userId, update: result }));
  } catch (e) {
    yield* put(showError({ title: i18n.t("errorServer") }));
    yield* put(actions.updatePoolMember({ userId, update: rollback }));
  }
}

function* disconnectMicrosoftStorage(
  action: ReturnType<typeof actions.disconnectMicrosoftStorage>,
) {
  const { poolId, loaderId } = action.payload;

  try {
    if (loaderId) {
      yield* put(setLoader({ id: loaderId, status: LoaderStatus.SUCCESS }));
    }
    yield* call(Api.disconnectMicrosoftStorageFromPool, { poolId });
  } catch (error) {
    yield* put(showError({ title: i18n.t("internalError") }));
    if (loaderId) {
      yield* put(
        setLoader({ id: loaderId, error, status: LoaderStatus.FAILURE }),
      );
    }
  }
}
