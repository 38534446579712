import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";
import { StrictOmit } from "ts-essentials";

import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import {
  KDropdown,
  KDropdownProps,
} from "@kraaft/web/src/components/dropdown/kDropdown";

import { FilteredListItem } from "../../../../components/dropdown/filteredList/filteredList";

interface Props
  extends StrictOmit<
    KDropdownProps,
    "items" | "onSelectionChange" | "selectedItemIds"
  > {
  language: PoolLanguage;
  onChange(language: PoolLanguage): void;
}

const LanguageDropdown = ({ language, onChange, ...dropdownProps }: Props) => {
  const { t } = useTranslation();

  const items: FilteredListItem<PoolLanguage>[] = useMemo(
    () =>
      sortBy(
        [
          {
            value: "fr-FR",
            label: t("locale.fr-FR"),
          },
          {
            value: "en-GB",
            label: t("locale.en-GB"),
          },
          {
            value: "en-US",
            label: t("locale.en-US"),
          },
          {
            value: "it-IT",
            label: t("locale.it-IT"),
          },
          {
            value: "de-DE",
            label: t("locale.de-DE"),
          },
          {
            value: "es-ES",
            label: t("locale.es-ES"),
          },
        ],
        (item) => item.label,
      ),
    [t],
  );

  const selected = useMemo(() => [language], [language]);

  const onSelectionChange = useCallback(
    (value: PoolLanguage[] | undefined) => {
      if (value?.[0]) {
        onChange(value[0]);
      }
    },
    [onChange],
  );

  return (
    <KDropdown
      {...dropdownProps}
      items={items}
      selectedItemIds={selected}
      onSelectionChange={onSelectionChange}
    />
  );
};

export { LanguageDropdown };
