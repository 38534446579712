import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { SimpleSearchHeader } from "@kraaft/shared/components/searchHeader/simpleSearchHeader";

interface Props {
  onFilterSet: (search: string) => void;
  onBackButtonPress: () => void;
}

export const ModularFoldersSelectionHeader = ({
  onFilterSet,
  onBackButtonPress,
}: Props) => {
  const { t } = useTranslation();

  // ensure filter is cleared when component is unmounted
  useEffect(
    () => () => {
      onFilterSet("");
    },
    [onFilterSet],
  );

  return (
    <SimpleSearchHeader
      onFilterSet={onFilterSet}
      placeholder={t("search")}
      headerTitle={t("modularFolderBulkExport.title")}
      onGoBackPress={onBackButtonPress}
    />
  );
};
