import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useDispatch } from "react-redux";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { Api } from "@kraaft/shared/core/services/api/api";
import { auth } from "@kraaft/shared/core/services/firebase/sdk";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackEventOnce } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button, Text } from "@kraaft/ui";

import { styles } from "./askUserMail.styles";

const AskUserMail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mail, setMail] = useState("");

  useTrackEventOnce({
    eventName: "Onboarding Need To Enter Email",
  });

  const next = useCallback(async () => {
    setError(false);
    setLoading(true);
    try {
      const { newAuthToken } = await Api.setOwnMail({ mail });
      const newLoggedUser = await auth().signInWithCustomToken(newAuthToken);
      if (!newLoggedUser.user) {
        return;
      }
      dispatch(
        UserActions.userConnectedToFirebase({ userAuth: newLoggedUser.user }),
      );
    } catch (e) {
      if (e.details?.code === "MailAlreadySetOnAnotherAccount") {
        dispatch(showError({ title: t("onboarding.emailAlreadyTaken") }));
      } else if (e.details?.code === "MalformedEmailAddress") {
        dispatch(showError({ title: t("onboarding.malformedEmail") }));
        // USER_NOT_FOUND and MAIL_ALREADY_SET and INTERNAL
      } else {
        dispatch(showError({ title: t("emailError") }));
      }
      setError(true);
    }
    trackEvent({
      eventName: "Onboarding Entered needed Email",
      input: mail,
    });
    setLoading(false);
  }, [dispatch, mail, t]);

  return (
    <View style={styles.root}>
      <View style={styles.titleContainer}>
        <Text weight="bold" size="H1" style={styles.whatsYourMail}>
          {t("onboarding.whatsYourMail")}
        </Text>
        <Text size="BODY" color="FONT_LOW_EMPHASIS">
          {t("onboarding.whatsYourMailDescription")}
        </Text>
      </View>
      <KInput
        style={styles.input}
        value={mail}
        onChangeText={setMail}
        label={t("email")}
        error={error}
        keyboardType="email-address"
        autoCapitalize="none"
        textContentType="emailAddress"
        onSubmitEditing={next}
        status={error ? "erroneous" : undefined}
      />
      <View style={styles.spacer} />
      <Button
        loading={loading}
        style={styles.nextButton}
        accessibilityLabel={t("next")}
        text={t("next")}
        onPress={next}
      />
    </View>
  );
};

export { AskUserMail };
