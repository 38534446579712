import { StyleSheet } from "react-native";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  messageEditorHeader: {
    width: "100%",
    backgroundColor: ColorStyle.ACTION_CTA,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: Spacing.S12,
    paddingVertical: Spacing.S8,
  },
});
