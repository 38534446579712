import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { PoolSchemaBuilderHeaderFolderName } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderHeader/poolSchemaBuilderHeaderName/poolSchemaBuilderHeaderFolderName";
import { PoolSchemaBuilderHeaderRoomName } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderHeader/poolSchemaBuilderHeaderName/poolSchemaBuilderHeaderRoomName";

interface PoolSchemaBuilderHeaderNameProps {
  schemaName: string;
  schemaId: string;
  collection: KSchema["collection"];
}

const PoolSchemaBuilderHeaderName = ({
  schemaName,
  collection,
  schemaId,
}: PoolSchemaBuilderHeaderNameProps) => {
  if (collection === "room") {
    return <PoolSchemaBuilderHeaderRoomName />;
  }
  return (
    <PoolSchemaBuilderHeaderFolderName
      schemaId={schemaId}
      schemaName={schemaName}
    />
  );
};

export { PoolSchemaBuilderHeaderName };
