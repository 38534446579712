import { makeStyles } from "@mui/styles";

import { Color } from "@kraaft/ui";

export const useStyles = makeStyles({
  layoutContainer: {
    height: "100%",
  },

  innerContainer: {
    display: "flex",
    height: "100vh",
  },

  pageContainer: {
    backgroundColor: Color.WHITE,
    position: "relative",
    overflow: "auto",
    flexGrow: 1,
  },
});
