import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  Condition,
  CoupleRecordCondition,
} from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import {
  conditionAllowsMultiple,
  getBuiltinCondition,
} from "@kraaft/web/src/components/filterMenu/filterUtils";

import { useStyles } from "./filterOptions.styles";

interface SelectFilterOptionsProps {
  condition: CoupleRecordCondition;
  setCondition: (condition: Condition) => void;
  column: KSchemaColumn;
  valueColumnType: KColumnType.selectSingle | KColumnType.selectMultiple;
}

/** @TODO : Mutualize w/ UserFilterOptions */
const SelectFilterOptions = (props: SelectFilterOptionsProps): JSX.Element => {
  const { condition, setCondition, column, valueColumnType } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const builtinCondition = getBuiltinCondition(
    column.type,
    valueColumnType,
    condition.predicate,
  );

  const options = useMemo(() => {
    if (
      column.type !== KColumnType.selectSingle &&
      column.type !== KColumnType.selectMultiple
    ) {
      return [];
    }

    const _options: FilteredListItem[] = [];
    for (const [key, option] of KSchemaUtils.orderOptions(column)) {
      _options.push({
        value: key,
        label: option.label,
      });
    }
    return _options;
  }, [column]);

  const selectedOptionIds = useMemo(
    () =>
      condition.value.columnType === KColumnType.selectSingle ||
      condition.value.columnType === KColumnType.selectMultiple
        ? condition.value.value
        : undefined,
    [condition],
  );

  const handleSelectionChange = useCallback(
    (selectedItems: string[] | undefined) => {
      const _condition: Condition = {
        ...condition,
        value: {
          value: selectedItems,
          columnType: valueColumnType,
        },
      };
      setCondition(_condition);
    },
    [condition, setCondition, valueColumnType],
  );

  return (
    <div className={classes.inputSelect}>
      <KDropdown
        allowMultiple={conditionAllowsMultiple(builtinCondition)}
        items={options}
        selectedItemIds={selectedOptionIds}
        onSelectionChange={handleSelectionChange}
        withEmptyRow
        withSearchBar
        placeholder={t("filterOption")}
      />
    </div>
  );
};

export { SelectFilterOptions };
