import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { filterCreateableRecords } from "@kraaft/shared/core/modules/modularFolder/modularFolderUtils";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { selectFolderSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { withDependencyWrapper } from "@kraaft/shared/core/utils/withDepedencyWrapper";
import { Button, Sheet, Text } from "@kraaft/ui";
import { MAX_TABLE_HEIGHT_IN_DIALOG } from "@kraaft/web/src/components/modularTable/components/table/tableUtils";
import { SimplifiedTableRecords } from "@kraaft/web/src/components/simplifiedTable/simplifiedTableRecords";

interface Props {
  open: boolean;
  onClose: () => void;
  roomId: string;
  schemaId: string;
}

const MAX_ALLOWED_MODULAR_FOLDERS = 200;

export const ImportModularFoldersSheet = Sheet({ web: "popup" })
  .create<Props>(({ Paper, Content, Header, Footer }) =>
    withDependencyWrapper(
      ({ onClose, schema, roomId }: Props & { schema: KFolderSchema }) => {
        const dispatch = useDispatch();
        const { t } = useTranslation();

        const schemaWithoutNonUserColumns = useMemo(
          () =>
            KSchemaUtils.withoutColumnsOfType(schema, [
              KColumnType.attachment,
              KColumnType.join,
              KColumnType.automatedAutoIncrement,
              KColumnType.automatedCreatedAt,
              KColumnType.automatedCreatedBy,
              KColumnType.roomMembers,
              KColumnType.roomName,
              KColumnType.signature,
            ]),
          [schema],
        );

        const emptyRows = useMemo(() => {
          return Array.from({ length: 7 }).map<ModularRecord>(() =>
            ModularRecordUtils.create(schema),
          );
        }, [schema]);

        const [records, setRecords] = useState(emptyRows);

        const createableRecords = useMemo(
          () =>
            filterCreateableRecords(
              records.map((r) => r.properties),
              schema,
            ),
          [records, schema],
        );

        const onImport = useCallback(() => {
          if (createableRecords.length === 0) {
            onClose();
            return;
          }

          dispatch(
            OfflineModularFolderActions.create({
              roomId: roomId,
              schemaId: schema.id,
              properties: createableRecords,
            }),
          );
          onClose();
        }, [createableRecords, dispatch, onClose, roomId, schema.id]);

        const canImport = createableRecords.length > 0;

        return (
          <Paper>
            <Header onClose={onClose}>
              <Text weight="medium" size="TITLE" color="FONT_HIGH_EMPHASIS">
                {t("importModularFolders.title", { schemaName: schema.name })}
              </Text>
            </Header>
            <Content>
              {schema ? (
                <SimplifiedTableRecords
                  forcedHeight={MAX_TABLE_HEIGHT_IN_DIALOG}
                  schema={schemaWithoutNonUserColumns}
                  records={records}
                  onRecordsChange={setRecords}
                  canAddRecord
                  canDeleteRecord
                  maxRows={MAX_ALLOWED_MODULAR_FOLDERS}
                  recordType="modularFolder"
                  noCheckboxConfirmation
                />
              ) : null}
            </Content>
            <Footer>
              <Button
                text={t("cancel")}
                variant="QUATERNARY"
                onPress={onClose}
              />
              <Button
                disabled={!canImport}
                text={`${t("import")} (${createableRecords.length})`}
                onPress={onImport}
              />
            </Footer>
          </Paper>
        );
      },
      ({ schemaId }) => {
        const schema = useSelector(selectFolderSchema(schemaId));
        if (!schema) {
          return undefined;
        }
        return { schema };
      },
    ),
  )
  .withDefaults({ size: "MEDIUM" });
