import { makeStyles } from "@mui/styles";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { View } from "react-native";
import { useSharedValue } from "react-native-reanimated";

import { stopPropagation } from "../../utils";
import type { VideoPlayerHandle, VideoPlayerProps } from "./videoPlayer.types";

export const VideoPlayer = forwardRef<VideoPlayerHandle, VideoPlayerProps>(
  ({ url, style, autoplay }, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const classes = useStyles();

    const svNumber = useSharedValue(0);
    const svBoolean = useSharedValue(false);

    useImperativeHandle(ref, () => ({
      pause() {
        videoRef.current?.pause();
      },
      play(positionMs) {
        if (positionMs !== undefined) {
          videoRef.current?.fastSeek(positionMs);
        }
        videoRef.current?.play().catch(console.error);
      },
      setPosition(positionMs) {
        videoRef.current?.fastSeek(positionMs);
      },
      durationMs: svNumber,
      playing: svBoolean,
      progressMs: svNumber,
    }));

    return (
      <View style={style}>
        <video
          ref={videoRef}
          className={classes.video}
          controls
          autoPlay={autoplay}
          onClick={stopPropagation}
        >
          <source src={url} />
        </video>
      </View>
    );
  },
);

const useStyles = makeStyles({
  video: {
    flexGrow: 1,
    flexShrink: 1,
    width: "100%",
    height: 0,
  },
});
