import { PropsWithChildren, useCallback, useState } from "react";

import { useMeshContextSetup } from "@kraaft/helper-hooks";
import { ModularDetailsContext } from "@kraaft/shared/components/modular/details/modularDetails.context";

const ModularDetailsContextProvider = ({ children }: PropsWithChildren) => {
  const [expandedSectionsKeys, setExpandedSectionsKeys] = useState<
    Record<string, boolean>
  >({});

  const toggleSection = useCallback(
    (key: string) =>
      setExpandedSectionsKeys((oldValue) => ({
        ...oldValue,
        [key]: oldValue[key] !== undefined ? !oldValue[key] : true,
      })),
    [],
  );

  return (
    <ModularDetailsContext.Provider
      value={useMeshContextSetup({
        expandedSectionsKeys,
        toggleSection,
      })}
    >
      {children}
    </ModularDetailsContext.Provider>
  );
};

export { ModularDetailsContextProvider };
