import {
  ClusterGeoJSON,
  MapElementGeoJSON,
} from "@kraaft/shared/components/mapController/types";
import { GeoCoordinates } from "@kraaft/shared/core/types";

export function getMarkerCoordinates(marker: MapElementGeoJSON) {
  const [longitude, latitude] = marker.geometry.coordinates;

  return { longitude, latitude } as GeoCoordinates;
}

export const getClusterId = (cluster: ClusterGeoJSON) =>
  cluster.id ? Number.parseInt(cluster.id.toString(), 10) : undefined;

export const isMarkerCluster = (
  marker: MapElementGeoJSON,
): marker is ClusterGeoJSON => Boolean(marker.properties.cluster);
