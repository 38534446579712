import { makeStyles } from "@mui/styles";

import { PixelSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  layoutContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },

  header: {
    padding: "8px 0",
    display: "flex",
    flexDirection: "row",
  },

  picker: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  pickerLabel: {
    marginRight: Spacing.S8,
  },
  pickerDropdown: {
    minWidth: 150,
    height: PixelSize.S32,
  },

  pickerSpacer: {
    marginLeft: Spacing.S24,
  },
});
