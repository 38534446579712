import { OverlayMenuProps } from "@kraaft/shared/components/overlayMenu/overlayMenuProps";
import { PopupMenu } from "@kraaft/web/src/components/popupMenu";

const OverlayMenu = (props: OverlayMenuProps) => {
  const { visible, anchorRef, onClose, children, isDropdown, placement } =
    props;

  return (
    <PopupMenu
      anchorRef={anchorRef}
      withAnchorWidth={isDropdown}
      open={visible}
      onClose={onClose}
      placement={placement}
    >
      {children}
    </PopupMenu>
  );
};

export { OverlayMenu };
