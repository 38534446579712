import { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";

import { alphaHex } from "@kraaft/helper-functions";
import { DifferenceMethod } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/types";
import { getColorFromDifferenceMethod } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/utils";
import { Radius } from "@kraaft/ui";

export interface DifferenceBackgroundProps {
  method: DifferenceMethod;
  withBorderRadius?: boolean;
}

export const DifferenceBackground = ({
  children,
  method,
  withBorderRadius,
}: PropsWithChildren<DifferenceBackgroundProps>) => {
  return (
    <View
      style={[
        styles.container,
        withBorderRadius && styles.containerWithBorderRadius,
        {
          backgroundColor: alphaHex(getColorFromDifferenceMethod(method), 0.3),
        },
      ]}
    >
      {children}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flexShrink: 1,
  },
  containerWithBorderRadius: {
    borderRadius: Radius.SMALL,
  },
});
