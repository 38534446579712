import { assert } from "ts-essentials";
import { call, put, select } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import {
  KRoomSchema,
  KSchema,
  KSchemaColumn,
  KSchemaColumnDefinition,
  KSchemaIcon,
  KSchemaSection,
  SchemaDefaultVisibility,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  AutoNumberingMode,
  SchemaStateActions,
} from "@kraaft/shared/core/modules/schema/schema.actions";
import {
  optimisticDuplicateSchemaElement,
  optimisticSchemaAddColumn,
  optimisticSchemaAddSection,
  optimisticSchemaDeleteColumn,
  optimisticSchemaEditColumnDefinition,
  optimisticSchemaEditMetadata,
  optimisticSchemaEditSection,
  optimisticSchemaRename,
  optimisticSchemaRenameColumn,
} from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import {
  selectOptimisticState,
  selectSchemas,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import {
  getIndexFromPlacement,
  KSchemaUtils,
  reorderElement,
} from "@kraaft/shared/core/modules/schema/schema.utils";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { i18n } from "@kraaft/shared/core/services/i18next";
import {
  createOperation,
  createOptimisticReduxBundle,
} from "@kraaft/shared/core/utils/optimistic/createReduxBundle";

export interface HoverState {
  key: string;
  placement: "after" | "before";
}

export const {
  actions: SchemaOptimisticActions,
  delaySnapshot: SchemaDelaySnapshot,
  operations: SchemaOptimisticOperations,
  reducer: SchemaOptimisticReducer,
  saga: SchemaOptimisticSaga,
  selectors: SchemaOptimisticSelector,
} = createOptimisticReduxBundle(
  "Schema",
  [
    createOperation<KSchema, "rename", { name: string }>({
      type: "rename",
      optimistic: (data, { payload }) => optimisticSchemaRename(data, payload),
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.editSchema, {
          schemaId: payload.targetId,
          name: payload.name,
        });
        return updatedAt;
      },
    }),
    createOperation<
      KSchema,
      "renameColumn",
      { columnKey: string; name: string }
    >({
      type: "renameColumn",
      optimistic: (data, { payload }) =>
        optimisticSchemaRenameColumn(data, payload),
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.editSchemaColumn, {
          schemaId: payload.targetId,
          columnKey: payload.columnKey,
          column: {
            name: payload.name,
          },
        });
        return updatedAt;
      },
    }),
    createOperation<
      KSchema,
      "reorderElement",
      {
        targetKey: string;
        afterKey?: string;
        sectionKey: string;
      }
    >({
      type: "reorderElement",
      optimistic: (data, { payload }) => {
        reorderElement(data, payload);
        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.moveSchemaElement, {
          schemaId: payload.targetId,
          targetKey: payload.targetKey,
          afterKey: payload.afterKey,
          sectionKey: payload.sectionKey,
        });
        return updatedAt;
      },
    }),
    createOperation<
      KSchema,
      "editSection",
      {
        key: string;
        edits: {
          name?: string;
          color?: string;
        };
      }
    >({
      type: "editSection",
      optimistic: (data, { payload }) =>
        optimisticSchemaEditSection(data, payload),
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.editSchemaSection, {
          schemaId: payload.targetId,
          key: payload.key,
          edits: payload.edits,
        });
        return updatedAt;
      },
    }),
    createOperation<
      KSchema,
      "editMetadata",
      {
        name?: string;
        icon?: KSchemaIcon;
        autonumbering?: AutoNumberingMode;
        display?: KRoomSchema["roomCardDisplayColumns"];
      }
    >({
      type: "editMetadata",
      optimistic: (data, { payload }) =>
        optimisticSchemaEditMetadata(data, payload),
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.editSchema, {
          schemaId: payload.targetId,
          name: payload.name,
          icon: payload.icon,
          roomCardDisplayColumns: payload.display,
        });
        return updatedAt;
      },
    }),
    createOperation<
      KSchema,
      "addSection",
      { section: KSchemaSection; placement: HoverState }
    >({
      type: "addSection",
      optimistic: (data, { payload }) =>
        optimisticSchemaAddSection(data, payload),
      call: function* ({ payload }, { selectBuilt }) {
        const schema = yield* select(selectBuilt(payload.targetId));
        if (!schema) {
          return null;
        }
        const newIndex = getIndexFromPlacement(schema, payload.placement);

        if (newIndex === undefined) {
          return null;
        }

        try {
          const { key, updatedAt } = yield* call(Api.addSchemaSection, {
            schemaId: schema.id,
            index:
              newIndex + (payload.placement.placement === "before" ? -1 : 0),
            name: payload.section.name,
            parentSectionKey: KSchemaUtils.rootSectionKey,
          });
          yield* put(
            SchemaStateActions.setCreatedKey({
              schemaId: schema.id,
              columnKey: key,
            }),
          );
          return updatedAt;
        } catch (e) {
          if (HttpError.isHttpErrorWithCode(e, "MaxColumnCountExceededError")) {
            yield* put(
              showError({
                title: i18n.t(
                  "formBuilder.schema.error.maxColumnCountExceeded",
                ),
              }),
            );
          }
          throw e;
        }
      },
    }),
    createOperation<
      KSchema,
      "addColumn",
      { column: KSchemaColumn; placement: HoverState; inSection?: string }
    >({
      type: "addColumn",
      optimistic: (data, { payload }) =>
        optimisticSchemaAddColumn(data, payload),
      call: function* ({ payload }, { selectBuilt }) {
        const schema = yield* select(selectBuilt(payload.targetId));
        if (!schema) {
          return null;
        }
        const newIndex = getIndexFromPlacement(schema, payload.placement);
        const context = KSchemaUtils.findWithContext(
          schema.rootSection,
          payload.placement.key,
        );

        if (newIndex === undefined || !context) {
          return null;
        }

        try {
          const { columnKey, updatedAt } = yield* call(Api.addSchemaColumn, {
            schemaId: schema.id,
            column: {
              ...payload.column,
              definition: payload.column,
              index:
                newIndex + (payload.placement.placement === "before" ? -1 : 0),
              containerKey: payload.inSection ?? context.parentSection.key,
            },
          });
          yield* put(
            SchemaStateActions.setCreatedKey({
              schemaId: schema.id,
              columnKey,
            }),
          );
          return updatedAt;
        } catch (e) {
          if (HttpError.isHttpErrorWithCode(e, "MaxColumnCountExceededError")) {
            yield* put(
              showError({
                title: i18n.t(
                  "formBuilder.schema.error.maxColumnCountExceeded",
                ),
              }),
            );
          }
          throw e;
        }
      },
    }),
    createOperation<
      KSchema,
      "editColumnDefinition",
      { columnKey: string; definition: KSchemaColumnDefinition }
    >({
      type: "editColumnDefinition",
      optimistic: (data, { payload }) =>
        optimisticSchemaEditColumnDefinition(data, payload),
      call: {
        type: "latest",
        debounce: 1000,
        identify: (operation) => operation.payload.columnKey,
        callDelayed: function* ({ payload }) {
          const { updatedAt } = yield* call(Api.editSchemaColumn, {
            schemaId: payload.targetId,
            columnKey: payload.columnKey,
            column: {
              definition: payload.definition,
            },
          });
          return updatedAt;
        },
      },
    }),
    createOperation<KSchema, "deleteColumn", { columnKey: string }>({
      type: "deleteColumn",
      optimistic: (data, { payload }) =>
        optimisticSchemaDeleteColumn(data, payload),
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.deleteSchemaColumn, {
          schemaId: payload.targetId,
          columnKey: payload.columnKey,
        });
        return updatedAt;
      },
    }),

    createOperation<KSchema, "delete", unknown>({
      type: "delete",
      optimistic: () => {
        return null;
      },
      call: function* ({ payload }) {
        const now = new Date();
        yield* call(Api.deleteSchema, {
          schemaId: payload.targetId,
        });

        return now;
      },
    }),

    createOperation<
      KSchema,
      "setHighlightedCheckbox",
      { columnKey: string | undefined }
    >({
      type: "setHighlightedCheckbox",
      optimistic: (schema, { payload }) => {
        assert(schema.collection === "folder");
        schema.highlightedCheckbox = payload.columnKey;
        return schema;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(
          Api.setPoolSchemaHighlightedCheckbox,
          {
            schemaId: payload.targetId,
            columnKey: payload.columnKey,
          },
        );
        return updatedAt;
      },
    }),
    createOperation<
      KSchema,
      "editCondition",
      { key: string; condition: CompositeCondition | undefined }
    >({
      type: "editCondition",
      optimistic: (data, { payload }) => {
        const element = KSchemaUtils.findElement(data.rootSection, payload.key);
        if (!element) {
          return data;
        }
        element.condition = payload.condition;
        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.editElementCondition, {
          schemaId: payload.targetId,
          key: payload.key,
          condition: payload.condition,
        });
        return updatedAt;
      },
    }),
    createOperation<KSchema, "duplicateElement", { key: string }>({
      type: "duplicateElement",
      optimistic: (data, { payload }) => {
        optimisticDuplicateSchemaElement(data, payload);
        return data;
      },
      call: function* ({ payload }) {
        try {
          const { updatedAt } = yield* call(Api.duplicateElement, {
            schemaId: payload.targetId,
            key: payload.key,
          });
          return updatedAt;
        } catch (e) {
          if (HttpError.isHttpErrorWithCode(e, "MaxColumnCountExceededError")) {
            yield* put(
              showError({
                title: i18n.t(
                  "formBuilder.schema.error.maxColumnCountExceeded",
                ),
              }),
            );
          }
          throw e;
        }
      },
    }),
    createOperation<
      KSchema,
      "setDefaultVisibility",
      { defaultVisibility: SchemaDefaultVisibility }
    >({
      type: "setDefaultVisibility",
      optimistic: (data, { payload }) => {
        if (data.collection === "folder") {
          data.defaultVisibility = payload.defaultVisibility;
        }
        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.setSchemaDefaultVisibility, {
          schemaId: payload.targetId,
          defaultVisibility: payload.defaultVisibility,
        });
        return updatedAt;
      },
    }),
  ],
  (schema) => schema.id,
  SchemaStateActions.set,
  selectOptimisticState,
  selectSchemas,
);
