import { useSelector } from "react-redux";

import { selectGridLibrarySchemasForLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.selectors";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";

export function useHasLibrarySchemas() {
  const pool = useSelector(selectCurrentPool);
  const hasLibrarySchemas =
    useSelector(selectGridLibrarySchemasForLanguage(pool?.poolLanguage))
      .length > 0;
  return hasLibrarySchemas;
}
