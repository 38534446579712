import { StyleSheet } from "react-native";

import { ColorStyle, webOnlyStyle } from "@kraaft/ui";

export const styles = StyleSheet.create({
  root: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: 24,
    ...webOnlyStyle({ cursor: "pointer" }),
    height: 48,
    width: 48,
  },
  selected: {
    backgroundColor: ColorStyle.ACTION_SELECTED,
    borderColor: ColorStyle.ACTION_CTA,
    borderWidth: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  child: {
    height: 48,
    width: 48,
    alignItems: "center",
    justifyContent: "center",
  },
});
