import { makeStyles } from "@mui/styles";

import { ColorStyle, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    paddingLeft: Spacing.S12,
    paddingRight: Spacing.S8,
    borderRadius: Radius.SMALL,
  },
  infos: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 56,
  },
  left: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
  right: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
    marginLeft: Spacing.S8,
  },
  editorContainer: {
    paddingBottom: Spacing.S12,
  },
  input: {
    width: "100%",
  },
});
