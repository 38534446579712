import { View } from "react-native";

import { KSelectionListCheckerProps } from "@kraaft/shared/components/kSelectionList/kSelectionListChecker.props";
import { Color, ColorStyle, Icon, IconName } from "@kraaft/ui";

import { styles } from "./kSelectionListChecker.styles";

function getIconColor(isDisabled: boolean, isSelected: boolean) {
  if (isSelected) {
    return isDisabled ? Color.BLUE_VISTA : Color.BLUE_KRAAFT;
  }
  return isDisabled ? Color.GREY_ANTIFLASH : ColorStyle.FONT_LOW_EMPHASIS;
}

function getIconName(isMultiple: boolean, isSelected: boolean): IconName {
  if (isMultiple) {
    return isSelected ? "checkbox-on-fill" : "checkbox-off";
  }
  return isSelected ? "radio-on-fill" : "radio-off";
}

const KSelectionListChecker = <T extends string = string, U = never>(
  props: KSelectionListCheckerProps<T, U>,
) => {
  if (props.type === "all") {
    const iconName: IconName =
      props.selection === "all"
        ? "checkbox-on-fill"
        : props.selection === "none"
          ? "checkbox-off"
          : "checkbox-partial-fill";

    const iconColor =
      props.selection !== "none"
        ? ColorStyle.ACTION_CTA
        : ColorStyle.FONT_LOW_EMPHASIS;

    return (
      <View style={styles.checkbox}>
        <Icon name={iconName} size="LARGE" color={iconColor} />
      </View>
    );
  }

  const color = getIconColor(props.disabled, props.isSelected);
  const iconName = getIconName(!!props.isMultiple, props.isSelected);

  return (
    <View style={styles.checkbox}>
      <Icon name={iconName} size="LARGE" color={color} />
    </View>
  );
};

export { KSelectionListChecker };
