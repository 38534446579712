import { XYCoord } from "react-dnd";
import { assert } from "ts-essentials";

import { AnyUnexplained } from "@kraaft/shared/core/types";
import {
  OrderableListDragItem,
  ReorderPlacement,
} from "@kraaft/web/src/components/orderableList/orderableList.types";
import { ORDERABLE_LIST_ROW_TYPE } from "@kraaft/web/src/components/orderableList/orderableList.utils";
import { DragObjectWithType } from "@kraaft/web/src/core/types/dnd";

export function getPlacementFromTargetAndDraggedSourcePosition(
  targetBoundingRect: DOMRect,
  draggedSourcePosition: XYCoord | null,
): ReorderPlacement {
  const targetMiddleY =
    (targetBoundingRect.bottom - targetBoundingRect.top) / 2;

  assert(draggedSourcePosition !== null, "clientOffset is null");

  return draggedSourcePosition.y - targetBoundingRect.top < targetMiddleY
    ? "before"
    : "after";
}

export function isOrderableRow(
  item: DragObjectWithType,
): item is OrderableListDragItem<unknown> {
  return (item as AnyUnexplained).type === ORDERABLE_LIST_ROW_TYPE;
}
