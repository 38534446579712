import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Pressable,
  PressableStateCallbackType,
  StyleSheet,
} from "react-native";

import { dismissNativeKeyboard } from "@kraaft/helper-functions";
import { EmojiSelectorSheet } from "@kraaft/shared/components/avatarEmojiPicker/emojiSelector.sheet";
import { EmojiSelectorSheetContent } from "@kraaft/shared/components/avatarEmojiPicker/emojiSelectorSheetContent";
import { RoomAvatar } from "@kraaft/shared/components/roomCard/roomAvatar";
import { RoomCardType } from "@kraaft/shared/components/roomCard/roomCardUtils";
import { RecentEmojiSpace } from "@kraaft/shared/core/modules/emoji/emoji.state";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { AnimationContextProvider } from "@kraaft/ui";

export interface AvatarEmojiPickerHandle {
  openEmojiSelector: () => void;
}

interface AvatarEmojiPickerProps {
  emoji: string;
  setEmoji: (emoji: string) => void;
  roomCardType: RoomCardType;
  isPrivate: boolean;
  recentEmojiSpace: RecentEmojiSpace;
}

export const AvatarEmojiPicker = React.forwardRef<
  AvatarEmojiPickerHandle,
  AvatarEmojiPickerProps
>(
  (
    { emoji, setEmoji, roomCardType, isPrivate, recentEmojiSpace },
    externalRef,
  ) => {
    const { t } = useTranslation();

    const buttonRef = useRef(null);
    const [isEmojiSelectorOpen, openEmojiSelector, closeEmojiSelector] =
      useBooleanState(false);

    const handleOpenEmojiSelector = useCallback(() => {
      dismissNativeKeyboard();
      openEmojiSelector();
    }, [openEmojiSelector]);

    const handle = useMemo(
      () => ({ openEmojiSelector: handleOpenEmojiSelector }),
      [handleOpenEmojiSelector],
    );

    useImperativeHandle(externalRef, () => handle);

    const getContainerStyle = useCallback(
      ({ pressed }: PressableStateCallbackType) => [
        pressed && styles.containerPressed,
      ],
      [],
    );

    return (
      <>
        <Pressable
          ref={buttonRef}
          accessibilityLabel={t("editRoomEmoji")}
          onPress={handleOpenEmojiSelector}
          style={getContainerStyle}
        >
          <RoomAvatar
            withPrivateIndicator={isPrivate}
            size="large"
            roomCardType={roomCardType}
            emoji={emoji}
          />
        </Pressable>
        <AnimationContextProvider>
          <EmojiSelectorSheet.Host
            anchor={buttonRef}
            placement="bottom-start"
            open={isEmojiSelectorOpen}
            onClose={closeEmojiSelector}
          >
            <EmojiSelectorSheetContent
              onClose={closeEmojiSelector}
              onEmojiSelected={setEmoji}
              recentEmojiSpace={recentEmojiSpace}
            />
          </EmojiSelectorSheet.Host>
        </AnimationContextProvider>
      </>
    );
  },
);

const styles = StyleSheet.create({
  containerPressed: {
    opacity: 0.75,
    transform: [{ scale: 0.95 }],
  },
});
