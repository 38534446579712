import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  ActionMessageLogger as ActionMessageLoggerType,
  TemporaryWorkflow,
} from "@kraaft/shared/core/modules/workflows/types";
import { ActionInput } from "@kraaft/web/src/views/settings/workflows/actions/ActionInput";
import { WorkflowSubSection } from "@kraaft/web/src/views/settings/workflows/components/workflowSubSection";

import { InputTip } from "./inputTip";

interface ActionMessageLoggerProps {
  payload: ActionMessageLoggerType["payload"];
  onChange: (payload: ActionMessageLoggerType["payload"]) => void;
  onErrorStateChange?: (newState: boolean) => void;
  workflow: TemporaryWorkflow;
}

export const ActionMessageLogger = ({
  payload,
  onChange,
  onErrorStateChange,
  workflow,
}: ActionMessageLoggerProps) => {
  const { t } = useTranslation();

  const handleContentChange = useCallback(
    (formattedText: string) => {
      const newPayload = { ...payload };
      newPayload.content = formattedText;
      onChange(newPayload);
    },
    [onChange, payload],
  );

  return (
    <WorkflowSubSection title={t("message")}>
      <ActionInput
        placeholder={t("workflow.actions.message.messagePlaceholder")}
        workflow={workflow}
        onChange={handleContentChange}
        onErrorStateChange={onErrorStateChange}
        defaultValue={payload.content}
      />
      <InputTip>{t("suggestionTip")}</InputTip>
    </WorkflowSubSection>
  );
};
