import { Pill } from "@kraaft/shared/components/pill";
import { IconName } from "@kraaft/ui";

const CompactTextPillContent = ({
  children,
  iconName,
  iconColor,
  textColor,
}: {
  children: string;
  iconName?: IconName;
  iconColor?: string;
  numberOfLines?: number;
  textColor?: string;
}) => (
  <Pill
    icon={iconName}
    iconColor={iconColor}
    content={children}
    size="small"
    textColor={textColor}
  />
);

export { CompactTextPillContent };
