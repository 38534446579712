import { createAction } from "@reduxjs/toolkit";

import {
  InviteState,
  NavigationSwitchValue,
  NetworkType,
  UtmParams,
} from "@kraaft/shared/core/modules/app/appState";

export const changeAppState = createAction<boolean>("@app/APP_STATE_CHANGE");

export const updateExtractEmail = createAction<string>(
  "@app/UPDATE_EXTRACT_EMAIL",
);

export const forceFirestoreLongPolling = createAction(
  "@app/FORCE_FIRESTORE_LONG_POLLING",
);

export const clearArrivalNavigation = createAction(
  "@app/CLEAR_ARRIVAL_NAVIGATION",
);

export const updateNetworkState = createAction<{
  isInternetReachable: boolean;
  networkType: NetworkType;
  hasImproved: boolean;
}>("@app/UPDATE_NETWORK_STATE");

export const updateInviteState = createAction<InviteState>(
  "@app/UPDATE_INVITE_STATE",
);

export const AppStateActions = {
  setIsHandlingDeepLink: createAction<{
    isHandlingDeepLink: boolean;
  }>("@app/SET_IS_HANDLING_DEEP_LINK"),
  setNavigationSwitch: createAction<NavigationSwitchValue>(
    "@app/SET_NAVIGATION_SWITCH",
  ),
  setUtmParams: createAction<UtmParams>("@app/SET_UTM_PARAMS"),
  setDynamicLinkForDebug: createAction<string>(
    "@app/SET_DYNAMIC_LINK_FOR_DEBUG",
  ),
  showConversationOnboarding: createAction<boolean>(
    "@app/SHOW_CONVERSATION_ONBOARDING",
  ),
};
