import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectOrderedModularFoldersWithRoomIdAndSchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import {
  subscribeToRoom,
  unsubscribeFromRoom,
} from "@kraaft/shared/core/modules/room/roomActions";
import {
  KSchema,
  KSchemaColumn,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Preloader } from "@kraaft/ui";
import { useFilterFolders } from "@kraaft/web/src/components/filterMenu/filterUtils";
import { KanbanLayout } from "@kraaft/web/src/components/kanbanLayout";
import { useSchemaView } from "@kraaft/web/src/components/modularFoldersViewer/useSchemaView";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

interface Props {
  roomId: string;
  schema: KSchema;
  schemaViewId?: string;
  onRecordClick: (recordId: string) => void;
  onRecordDrop: (
    recordId: string,
    column: KSchemaColumn,
    optionKey: string | undefined,
  ) => void;
}

const ModularFoldersKanbanViewForRoom = ({
  roomId,
  schema,
  schemaViewId,
  onRecordClick,
  onRecordDrop,
}: Props) => {
  const dispatch = useDispatch();

  const { isLoaded: foldersFetched, folders } = useSelector(
    selectOrderedModularFoldersWithRoomIdAndSchemaId({
      roomId,
      schemaId: schema.id,
    }),
  );

  const schemaView = useSchemaView(
    RecordWorkspace.IN_ROOM,
    schema.id,
    schemaViewId,
  );

  const filteredFolders = useFilterFolders(
    schema.rootSection,
    folders,
    schemaView?.filters,
  );

  useEffect(() => {
    dispatch(ModularFolderActions.subscribeForRoom({ roomId }));
    dispatch(subscribeToRoom({ roomId }));
    return () => {
      dispatch(ModularFolderActions.unsubscribeForRoom({ roomId }));
      dispatch(unsubscribeFromRoom({ roomId }));
    };
  }, [dispatch, roomId]);

  return foldersFetched ? (
    <KanbanLayout
      workspace={RecordWorkspace.IN_ROOM}
      schema={schema}
      modularRecords={filteredFolders}
      onRecordClick={onRecordClick}
      onRecordDrop={onRecordDrop}
      schemaViewId={schemaViewId}
    />
  ) : (
    <Preloader absoluteFill transparent />
  );
};

export { ModularFoldersKanbanViewForRoom };
