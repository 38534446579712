import { useEffect, useState } from "react";

import { firestore } from "@kraaft/shared/core/services/firestore/sdk";

export type DeliveryNoteConfig = {
  enabled: boolean;
};

export function useDeliveryNoteConfig(
  poolId: string | undefined,
  skip?: boolean,
): DeliveryNoteConfig | undefined {
  const [deliveryNoteConfig, setDeliveryNoteConfig] =
    useState<DeliveryNoteConfig>();
  useEffect(() => {
    if (!poolId || skip) {
      return;
    }
    return firestore()
      .collection("deliveryNoteConfigs-projection-1n")
      .doc(poolId)
      .onSnapshot((snapshot) => {
        setDeliveryNoteConfig(
          snapshot.data() as DeliveryNoteConfig | undefined,
        );
      });
  }, [poolId, skip]);

  return deliveryNoteConfig;
}
