import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { SchemaOptimisticSelector } from "@kraaft/shared/core/modules/schema/schema.optimistic";
import { Api } from "@kraaft/shared/core/services/api";
import { EditPoolSchema } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/editPoolSchema";
import { EditPoolSchemaMetadata } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/editPoolSchemaMetadata";
import { EditPoolSchemaReportTemplates } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/editPoolSchemaReportTemplates";
import { SchemaBuilderHeader } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderHeader";
import { PoolSchemaBuilderTabs } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderTabs";

import { useStyles } from "./poolSchemaBuilder.styles";

interface PoolSchemaBuilderProps {
  schema: KSchema;
  tab: PoolSchemaBuilderTabs;
  hidePreview?: boolean;
}

const PoolSchemaBuilder_ = ({
  schema,
  tab,
  hidePreview,
}: PoolSchemaBuilderProps) => {
  const styles = useStyles();
  const editedSchema = useSelector(
    SchemaOptimisticSelector.selectBuilt(schema.id),
  );

  const [hasRecords, setHasRecords] = useState<boolean | undefined>(
    schema.collection === "room" ? true : undefined,
  );

  useEffect(() => {
    async function get() {
      const result = await Api.countModularFoldersForSchema({
        schemaId: schema.id,
      });
      setHasRecords(result.count > 0);
    }
    if (hasRecords === undefined) {
      get().catch(console.error);
    }
  }, [hasRecords, schema.id]);

  if (!editedSchema || !schema) {
    return null;
  }

  return (
    <div className={styles.root}>
      <SchemaBuilderHeader schema={schema} />
      <div className={styles.content}>
        {tab === PoolSchemaBuilderTabs.schema && (
          <EditPoolSchema
            hidePreview={hidePreview}
            schema={editedSchema}
            isColumnDeletionInstant={
              hasRecords === undefined ? false : !hasRecords
            }
          />
        )}
        {tab === PoolSchemaBuilderTabs.reports && (
          <EditPoolSchemaReportTemplates poolSchema={schema} />
        )}
        {tab === PoolSchemaBuilderTabs.info && (
          <EditPoolSchemaMetadata schema={editedSchema} />
        )}
      </div>
    </div>
  );
};

export const PoolSchemaBuilder = memo(
  PoolSchemaBuilder_,
) as typeof PoolSchemaBuilder_;
