import React from "react";
import clsx from "clsx";

import { Icon } from "@kraaft/ui";

import { useStyles } from "./kDropdownButton.styles";

export type DropdownVariant = "dark" | "light";

interface Props {
  content: React.ReactNode;
  onClick?: () => void;
  withSelectedStyle?: boolean;
  variant?: DropdownVariant;
  disabled?: boolean;
  testID?: string;
  variableHeight?: boolean;
}

const KDropdownButton = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      content,
      onClick,
      withSelectedStyle = false,
      variant = "light",
      disabled = false,
      testID,
    } = props;

    const classes = useStyles({ variant });

    return (
      <div
        id={testID}
        ref={ref}
        className={classes.cellContainer}
        onClick={!disabled ? onClick : undefined}
      >
        <div
          className={clsx(
            disabled ? classes.disabledContainer : classes.container,
            withSelectedStyle && classes.containerSelected,
          )}
        >
          <div className={classes.valueContainer}>
            <div className={classes.textContainer}>{content}</div>
          </div>
          <Icon
            name="chevron-down"
            size="MEDIUM"
            className={classes.icon}
            color="FONT_LOW_EMPHASIS"
          />
        </div>
      </div>
    );
  },
);

export { KDropdownButton };
