import { MouseEventHandler, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button, Text } from "@kraaft/ui";
import { getTableColumnTypeLabel } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/columnUtils";
import { ColumnIcon } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementIcon/columnIcon";
import { FormBuilderColumnElement } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";

import { useStyles } from "./elementPickerColumnItem.styles";

const ADD_COLUMN_BUTTON = "column-picker-add-column";

interface ColumnPickerItemProps {
  element: FormBuilderColumnElement;
  onAdd: () => void;
}

const ElementPickerColumnItem = ({ element, onAdd }: ColumnPickerItemProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();
      onAdd();
    },
    [onAdd],
  );

  return (
    <div
      className={styles.root}
      onClick={handleClick}
      role="button"
      tabIndex={0}
    >
      <div className={styles.iconText}>
        <ColumnIcon columnType={element.columnType} />
        <Text color="FONT_HIGH_EMPHASIS" size="BODY">
          {getTableColumnTypeLabel(t, element.columnType)}
        </Text>
      </div>
      <Button
        accessibilityLabel={t("add")}
        icon="plus"
        variant="QUATERNARY"
        size="SMALL"
        id={ADD_COLUMN_BUTTON}
        testID={`add-${element.columnType}`}
      />
    </div>
  );
};

export { ElementPickerColumnItem };
