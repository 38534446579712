import { noop } from "ts-essentials";

import { CursorSelection } from "@kraaft/shared/core/framework/markedText/cursorSelection";
import { diffText } from "@kraaft/shared/core/framework/markedText/diffText";
import { AnyMarker } from "@kraaft/shared/core/framework/markedText/markers/marker";
import { Span } from "@kraaft/shared/core/framework/markedText/span";

type Difference = {
  length: number;
  value: string;
  added: boolean | undefined;
  removed: boolean | undefined;
};

interface TextSegment {
  id: string;
  type: "text";
  text: string;
}

interface MarkerSegment {
  id: string;
  type: "marker";
  marker: AnyMarker;
}

export type Segment = TextSegment | MarkerSegment;

export class MarkedText {
  selection: CursorSelection = new CursorSelection(0, 0);

  constructor(
    private text = "",
    private markers: AnyMarker[] = [],
  ) {}

  getMarkers(): readonly AnyMarker[] {
    return this.markers;
  }

  public setTextAndMarkers(text = "", markers: AnyMarker[] = []) {
    this.text = text;
    this.markers = markers;
  }

  public asText() {
    return this.text;
  }

  public asSegments(): Segment[] {
    const segments: Segment[] = [];
    let lastIndex = 0;

    const orderedMarkers = [...this.markers].sort(
      (markerLeft, markerRight) =>
        markerLeft.anchorIndex - markerRight.anchorIndex,
    );

    for (const [index, marker] of orderedMarkers.entries()) {
      const range = marker.getRange();

      const textSegment: Segment = {
        id: `text-${index}`,
        type: "text",
        text: this.text.slice(lastIndex, range.start),
      };

      if (textSegment.text.length > 0) {
        segments.push(textSegment);
      }
      segments.push({ id: marker.id, type: "marker", marker });

      lastIndex = range.end;
    }
    if (lastIndex < this.text.length) {
      segments.push({
        id: `text-${orderedMarkers.length}`,
        type: "text",
        text: this.text.slice(lastIndex),
      });
    }

    return segments;
  }

  public updateSelection(selection: { start: number; end: number }) {
    this.selection = new CursorSelection(selection.start, selection.end);
  }

  private getMarkerLocalRangeAndText(
    marker: AnyMarker,
    difference: Difference,
    differenceStartIndex: number,
  ) {
    const localRange = new Span(
      Math.max(marker.getRange().start, differenceStartIndex) -
        marker.getRange().start,
      Math.min(
        marker.getRange().end,
        differenceStartIndex + difference.length,
      ) - marker.getRange().start,
    );

    const text = difference.removed ? "" : difference.value;

    return { localRange, text };
  }

  private updateMarkerContent(
    marker: AnyMarker,
    incomingTextIndex: number,
    difference: Difference,
    offsetCursor: (value: number) => void,
  ) {
    const { localRange, text } = this.getMarkerLocalRangeAndText(
      marker,
      difference,
      incomingTextIndex,
    );

    const contentUpdatedResultAction = marker.updateContent(
      localRange,
      text,
      this.selection,
    );

    if (contentUpdatedResultAction !== undefined) {
      switch (contentUpdatedResultAction.type) {
        case "unlink": {
          this.unlinkMarker(marker);
          break;
        }
        case "remove": {
          this.unlinkMarker(marker);
          this.removeTextInRange(contentUpdatedResultAction.rangeToRemove);

          if (incomingTextIndex <= this.selection.start) {
            offsetCursor(-marker.getRange().length());
          }
          break;
        }
      }
    }
  }

  private processMarkerForRemovedSegment(
    marker: AnyMarker,
    incomingTextIndex: number,
    difference: Difference,
    offsetCursor: (value: number) => void,
  ) {
    const range = marker.getRange();

    /** the segment belongs to a marker */
    if (
      range.includesValueExclusively(incomingTextIndex) ||
      range.includesValueExclusively(incomingTextIndex + difference.length) ||
      (incomingTextIndex <= range.start &&
        incomingTextIndex + difference.length >= range.end)
    ) {
      this.updateMarkerContent(
        marker,
        incomingTextIndex,
        difference,
        offsetCursor,
      );
    }
    /** we negatively offset the marker if the removed segment was before it */
    if (incomingTextIndex <= range.start) {
      marker.offsetAnchor(-difference.length);
    }
  }

  private processMarkerForAddedSegment(
    marker: AnyMarker,
    incomingTextIndex: number,
    difference: Difference,
    offsetCursor: (value: number) => void,
  ) {
    const range = marker.getRange();

    /** the segment belongs to a marker */
    if (range.includesValueExclusively(incomingTextIndex)) {
      this.updateMarkerContent(
        marker,
        incomingTextIndex,
        difference,
        offsetCursor,
      );
    }

    /** we positively offset the marker if the added segment was before it */
    if (incomingTextIndex <= range.start) {
      marker.offsetAnchor(difference.length);
    }
  }

  private checkMarkersAgainstDifference(
    difference: Difference,
    incomingTextIndex: number,
    offsetCursor: (value: number) => void,
  ) {
    if (!difference.added && !difference.removed) {
      return;
    }

    for (const marker of [...this.markers]) {
      if (difference.removed) {
        this.processMarkerForRemovedSegment(
          marker,
          incomingTextIndex,
          difference,
          offsetCursor,
        );
      } else if (difference.added) {
        this.processMarkerForAddedSegment(
          marker,
          incomingTextIndex,
          difference,
          offsetCursor,
        );
      }
    }
  }

  public ingestText(incomingText: string) {
    const originalText = this.text;
    this.text = incomingText;

    const differences = diffText(originalText, incomingText, this.selection);
    let incomingTextIndex = 0;
    let newCursorOffset = 0;

    const offsetCursor = (value: number) => {
      newCursorOffset += value;
    };

    for (const difference of differences) {
      if (difference.length === 0) {
        continue;
      }

      this.checkMarkersAgainstDifference(
        difference,
        incomingTextIndex,
        offsetCursor,
      );

      if (!difference.removed) {
        incomingTextIndex += difference.length;
      }
    }

    return newCursorOffset;
  }

  public addMarker(markerToAdd: AnyMarker) {
    for (const marker of [...this.markers]) {
      if (marker.getRange().includesValueExclusively(markerToAdd.anchorIndex)) {
        this.unlinkMarker(marker);
      } else if (markerToAdd.anchorIndex <= marker.getRange().start) {
        marker.offsetAnchor(markerToAdd.renderText().length);
      }
    }

    this.markers.push(markerToAdd);

    this.text =
      this.text.slice(0, markerToAdd.anchorIndex) +
      markerToAdd.renderText() +
      this.text.slice(markerToAdd.anchorIndex);
  }

  public unlinkMarker(markerToRemove: AnyMarker) {
    const markerIndex = this.markers.indexOf(markerToRemove);

    if (markerIndex === -1) {
      return;
    }

    this.markers.splice(markerIndex, 1);
  }

  public addTextAtIndex(text: string, index: number) {
    this.text = this.text.slice(0, index) + text + this.text.slice(index);

    for (const marker of [...this.markers]) {
      this.processMarkerForAddedSegment(
        marker,
        index,
        {
          length: text.length,
          value: text,
          added: true,
          removed: false,
        },
        noop,
      );
    }
  }

  public removeTextInRange(range: Span) {
    this.text = this.text.slice(0, range.start) + this.text.slice(range.end);

    for (const marker of [...this.markers]) {
      this.processMarkerForRemovedSegment(
        marker,
        range.start,
        {
          length: range.length(),
          value: this.text.slice(range.start, range.end),
          added: false,
          removed: true,
        },
        noop,
      );
    }
  }

  public getMarkerAtIndex(index: number) {
    return this.markers.find((marker) =>
      marker.getRange().includesValueExclusively(index),
    );
  }

  /** useful for tests */
  public selectionAsSubText() {
    return (
      this.text.slice(0, this.selection.start).replace(/[^\t\s]/g, " ") +
      (this.text
        .slice(this.selection.start, this.selection.end)
        .replace(/[^\n]/g, "^") || "|")
    );
  }
}
