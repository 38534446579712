import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { Button } from "@kraaft/ui";

import { CongratsContainer } from "./congratsContainer";
import { getCongratsMessage } from "./getCongratsMessage";
import { useInviteCongrats } from "./useInviteCongrats";

import { styles } from "./congratulations.styles";

const InviteCongrats = () => {
  const { t } = useTranslation();

  const { inviteState, acknowledgeInviteCongrats } = useInviteCongrats();

  const congratsMessage = getCongratsMessage(inviteState, t);

  const buttonText = isNative()
    ? t("congratsScreenContinueNative")
    : t("congratsScreenContinue");

  return (
    <CongratsContainer>
      <Text nativeID="ide2e-congrats-title" style={[styles.text, styles.first]}>
        {congratsMessage}
      </Text>
      {!isNative() && (
        <Text style={styles.text}>{t("congratsScreenSuggestApp")}</Text>
      )}
      <View style={styles.bottomButtonContainer}>
        <Button
          id="ide2e-continue-web"
          disableAccessibility
          onPress={acknowledgeInviteCongrats}
          text={buttonText}
        />
      </View>
    </CongratsContainer>
  );
};

export { InviteCongrats };
