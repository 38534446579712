import { useCallback, useMemo } from "react";
import { Pressable, StyleSheet } from "react-native";

import { useMeshContext } from "@kraaft/helper-hooks";
import { ModularDetailsContext } from "@kraaft/shared/components/modular/details/modularDetails.context";
import { MODULAR_LABEL_LIMIT } from "@kraaft/shared/constants/constants";
import { textEllipsis } from "@kraaft/shared/core/utils";
import { highlightOnFocus } from "@kraaft/shared/core/utils/highlightOnFocus";
import { Icon, Spacing, Text } from "@kraaft/ui";

type ContextAwareSectionSeparatorProps = {
  sectionKey: string;
  label: string;
  color: string;
};

const ContextAwareSectionSeparator = ({
  sectionKey,
  label,
  color,
}: ContextAwareSectionSeparatorProps) => {
  const { toggleSection } = useMeshContext(ModularDetailsContext);
  const isExpanded = useMeshContext(
    ModularDetailsContext,
    ({ expandedSectionsKeys }) => !expandedSectionsKeys[sectionKey],
  );

  const handlePress = useCallback(() => {
    toggleSection(sectionKey);
  }, [sectionKey, toggleSection]);

  const containerStyle = useMemo(
    () => [styles.container, { backgroundColor: color }],
    [color],
  );

  return (
    <Pressable
      accessibilityLabel={label}
      onPress={handlePress}
      style={containerStyle}
      nativeID="highlight-focus"
      {...highlightOnFocus}
    >
      <Icon name="distribute-spacing-vertical" color="WHITE" />
      <Text size="BODY" color="WHITE" style={styles.text}>
        {textEllipsis(label, MODULAR_LABEL_LIMIT.SECTION_LABEL)}
      </Text>
      <Icon
        color="WHITE"
        name={isExpanded ? "chevron-up" : "chevron-down"}
        style={styles.openIcon}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingHorizontal: Spacing.S16,
    paddingVertical: Spacing.S12,
    gap: Spacing.S8,
  },

  text: {
    flexGrow: 1,
    flexBasis: 0,
  },

  openIcon: {
    marginLeft: "auto",
    marginRight: Spacing.S8,
  },
});

export { ContextAwareSectionSeparator };
