import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { selectModularFolderTitle } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import {
  DownloadReportFormat,
  getReportTemplateName,
} from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.utils";
import { selectSchemaName } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { Api } from "@kraaft/shared/core/services/api";
import { shareFile } from "@kraaft/shared/core/services/share";
import { GlobalOfflineReduxBundle } from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle.init";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { getExtension } from "@kraaft/shared/core/utils/utils";

type Props = {
  schemaId: string;
  modularFolderId: string;
};

export function useDownloadReport({ schemaId, modularFolderId }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const schemaName = useSelector(selectSchemaName(schemaId));
  const modularFolderName = useSelector(
    selectModularFolderTitle(modularFolderId),
  );

  const [isGenerating, setGenerating] = useState(false);
  const persistedModularFolderId = useSelector(
    GlobalOfflineReduxBundle.selectCorrespondingId(modularFolderId),
  );

  const generateReport = useCallback(
    async (reportTemplate: ReportTemplate, format: DownloadReportFormat) => {
      trackEvent({
        eventName: "Generate Folder Report",
        folder_id: persistedModularFolderId,
        folder_name: modularFolderName,
        schema_id: schemaId,
        schema_name: schemaName ?? "",
        template_id: reportTemplate.id,
        template_name: getReportTemplateName(reportTemplate),
        format,
      });

      if (format === "pdf") {
        return Api.generateModularFolderPDFReport({
          folderId: persistedModularFolderId,
          templateId: reportTemplate.id,
        });
      }

      return Api.generateModularFolderReport({
        folderId: persistedModularFolderId,
        templateId: reportTemplate.id,
      });
    },
    [persistedModularFolderId, modularFolderName, schemaId, schemaName],
  );

  const handleDownloadReport = useCallback(
    async (reportTemplate: ReportTemplate, format: DownloadReportFormat) => {
      setGenerating(true);
      try {
        const result = await generateReport(reportTemplate, format);
        if (!result) {
          setGenerating(false);
          return;
        }
        if (isNative()) {
          let path = await fileAllocator.temporaryIfNeeded(
            result.downloadUrl,
            getExtension(result.filename),
          );
          path = await fileAllocator.rename(path, result.filename, {
            name: "useDownloadReport",
          });
          await shareFile({ remoteOrLocalUrl: path }).catch(console.error);
        } else {
          await fileSaver.download(result.downloadUrl, result.filename);
        }
      } catch (e) {
        console.error(e);
        dispatch(showError({ title: t("errorServer") }));
      }
      setGenerating(false);
    },
    [generateReport, dispatch, t],
  );

  return { downloadReport: handleDownloadReport, isGenerating };
}
