import { useMemo } from "react";
import { useSelector } from "react-redux";

import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import {
  selectEditedSchemaView,
  selectNonCreatedSchemaView,
  selectSchemaView,
} from "@kraaft/shared/core/modules/schemaView/schemaViewSelectors";
import {
  DirtyableSchemaView,
  TemporarySchemaView,
} from "@kraaft/shared/core/modules/schemaView/schemaViewState";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

export function useSchemaView(
  workspace: RecordWorkspace,
  schemaId: string,
  id: string | undefined,
): DirtyableSchemaView {
  const storedView = useSelector(selectSchemaView(id ?? ""));
  const editedView = useSelector(selectEditedSchemaView(id));
  const temporaryView = useSelector(
    selectNonCreatedSchemaView(workspace, schemaId),
  );

  const defaultView = useMemo<TemporarySchemaView>(() => {
    const defaultCondition: CompositeCondition = {
      type: "composite",
      operator: "and",
      conditions: [
        {
          type: "composite",
          operator: "and",
          conditions: [],
        },
      ],
    };

    return {
      name: "temporary",
      dirty: false,
      filters: defaultCondition,
      schemaId,
      formats: {
        kanban: {
          displayedProperties: [],
          groupedByProperty: undefined,
        },
      },
    };
  }, [schemaId]);

  return editedView ?? storedView ?? temporaryView ?? defaultView;
}
