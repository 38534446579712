import { StyleSheet } from "react-native";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  conversation: {
    height: "100%",
    minHeight: 0,
  },
  hidden: {
    display: "none",
  },
});

export const rootStyle = StyleSheet.create({
  root: {
    flex: 1,
  },
});
