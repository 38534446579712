import { StyleSheet } from "react-native";

import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.WHITE,
    flexGrow: 1,
  },
  search: {
    backgroundColor: Color.WHITE,
    padding: Spacing.S16,
  },
  content: {
    paddingHorizontal: Spacing.S16,
    paddingBottom: Spacing.S16,
  },
});
