import { Text } from "react-native";

import { WithIconContent } from "@kraaft/shared/components/modular/pill/utils/withIconContent";
import { IconName } from "@kraaft/ui";

import { styles } from "../modularPill.styles";

const TextPillContent = ({
  children,
  numberOfLines,
  ...otherProps
}: {
  children: string;
  iconName?: IconName;
  iconColor?: string;
  numberOfLines?: number;
  tooltipTitle?: string;
}) => {
  return (
    <WithIconContent {...otherProps}>
      <Text style={styles.textPillContentText} numberOfLines={numberOfLines}>
        {children}
      </Text>
    </WithIconContent>
  );
};

export { TextPillContent };
