import { createReducer } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";

import { EmojiStateActions } from "@kraaft/shared/core/modules/emoji/emoji.actions";
import { EmojiState } from "@kraaft/shared/core/modules/emoji/emoji.state";
import { storage } from "@kraaft/shared/core/modules/storage/storage.provider";

const initialState: EmojiState = {
  recent: { message_reaction: [], room_emoji: [] },
};

const RECENT_EMOJI_COUNT = 25;

const emojiReducer_ = createReducer(initialState, ({ addCase }) => {
  addCase(EmojiStateActions.used, (state, { payload: { emoji, space } }) => {
    let recentEmojiSpace = state.recent[space];
    const alreadyExistingIndex = recentEmojiSpace.indexOf(emoji);
    if (alreadyExistingIndex >= 0) {
      recentEmojiSpace.splice(alreadyExistingIndex, 1);
    }
    recentEmojiSpace.unshift(emoji);
    if (recentEmojiSpace.length > RECENT_EMOJI_COUNT) {
      recentEmojiSpace = recentEmojiSpace.slice(0, RECENT_EMOJI_COUNT);
      state.recent[space] = recentEmojiSpace;
    }
  });
});

export const emojiReducer = persistReducer(
  {
    key: "emoji",
    storage,
    version: 0,
  },
  emojiReducer_,
);
