import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button, ColorStyle } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { ColorPicker } from "@kraaft/web/src/components/colorPicker/colorPicker";
import { ColoredOrderableOption } from "@kraaft/web/src/components/sortableListWithAddAndDelete/coloredOrderableOptionRenderer/coloredOrderableOption";
import { OrderableOptionInput } from "@kraaft/web/src/components/sortableListWithAddAndDelete/orderableOptionRenderer/orderableOptionInput";

import { useStyles } from "./orderableOptionRenderer.styles";

export interface ColoredOrderableOptionRendererProps {
  option: ColoredOrderableOption;
  allowColor?: boolean;
  onDelete: (option: ColoredOrderableOption) => void;
  onPressEnter: (id: string) => void;
  onChange: (id: string, value: ColoredOrderableOption) => void;
}

export const ColoredOrderableOptionRenderer = ({
  allowColor,
  option,
  onDelete,
  onPressEnter,
  onChange,
}: ColoredOrderableOptionRendererProps) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const handlePress = useCallback(() => {
    onDelete(option);
  }, [onDelete, option]);

  const handleColorChange = useCallback(
    (color: string) => {
      onChange(option.id, { ...option, color });
    },
    [onChange, option],
  );

  return (
    <div className={classes.option}>
      {allowColor && (
        <Box mr="S8">
          <ColorPicker value={option.color} onChange={handleColorChange} />
        </Box>
      )}
      <div className={classes.labelContainer}>
        <OrderableOptionInput
          option={option}
          onPressEnter={onPressEnter}
          onChange={onChange}
        />
      </div>
      <Button
        accessibilityLabel={t("delete")}
        icon="trash-02"
        contentColor={ColorStyle.FONT_LOW_EMPHASIS}
        variant="QUATERNARY"
        onPress={handlePress}
      />
    </div>
  );
};
