import { useCallback } from "react";

import { OverlayMenu } from "@kraaft/shared/components/overlayMenu";
import { PoolSelectorImplementationProps } from "@kraaft/shared/components/poolSelectorImplementation/poolSelectorImplementationProps";
import { PoolsMenu } from "@kraaft/shared/components/poolsMenu";
import { PoolLocation } from "@kraaft/shared/core/modules/pool/poolState";

const PoolSelectorImplementation = ({
  open,
  onClose,
  currentPoolLocation,
  onChangePoolLocation,
  anchorRef,
}: PoolSelectorImplementationProps & {
  anchorRef: React.RefObject<Element>;
}) => {
  const handleChangePoolLocation = useCallback(
    (newLocation: PoolLocation, newLocationName: string) => {
      onChangePoolLocation(newLocation, newLocationName);
      onClose();
    },
    [onChangePoolLocation, onClose],
  );

  return (
    <OverlayMenu visible={open} onClose={onClose} anchorRef={anchorRef}>
      <div>
        {/* This div is needed, otherwise poolsMenu height is 0 */}
        <PoolsMenu
          currentPoolLocation={currentPoolLocation}
          onChangePoolLocation={handleChangePoolLocation}
          maxHeight={400}
        />
      </div>
    </OverlayMenu>
  );
};

export { PoolSelectorImplementation };
