import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { inputAccessoryBackgroundColor } from "@kraaft/shared/components/inputAccessory";
import { SafeAreaView } from "@kraaft/shared/components/safeAreaView";
import { Button, Color, PixelSize, Spacing } from "@kraaft/ui";

interface Props {
  onAddRecord: () => void;
}

const ModularFolderQuickCreator = ({ onAddRecord }: Props) => {
  const { t } = useTranslation();

  const onAddNewFolder = useCallback(() => {
    onAddRecord();
  }, [onAddRecord]);

  return (
    <SafeAreaView style={styles.wrapper} edges={["bottom"]}>
      <View style={styles.buttonContainer}>
        <Button
          accessibilityLabel={t("create")}
          text={t("create")}
          icon="plus"
          onPress={onAddNewFolder}
          id="ide2e-modularFolders-new-button"
        />
      </View>
    </SafeAreaView>
  );
};

export { ModularFolderQuickCreator };

const styles = StyleSheet.create({
  buttonContainer: {
    backgroundColor: Color.WHITE,
    padding: Spacing.S16,
    minHeight: PixelSize.S40,
  },
  wrapper: {
    backgroundColor: inputAccessoryBackgroundColor,
  },
});
