import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";
import { compact } from "lodash";

import { isEmailValid } from "@kraaft/shared/core/utils/utils";
import {
  ColorStyle,
  CompactTextInput,
  FontSize,
  Spacing,
  TextInputWithAutoSize,
} from "@kraaft/ui";

interface EmailListInputProps {
  placeholder?: string;
  onChange?: (emails: Array<string>) => void;
  defaultValue?: Array<string>;
  onErrorStateChange?: (newState: boolean) => void;
}

/**
 * Returns the index of the email being edited in the email list.
 *
 * @param emailList - The list of emails to search in.
 * @param isEditing - Indicates whether an input is being focused/used.
 * @returns The index of the email being edited if found, otherwise null.
 */
function useEditingIndex(
  emailList: Array<string>,
  isEditing: boolean,
): number | null {
  const lastEmailsRef = useRef<Array<string>>(emailList ?? []);
  return useMemo(() => {
    if (!isEditing) {
      return null;
    }
    const index = emailList.findIndex(
      (it, i) => lastEmailsRef.current[i] !== it,
    );
    lastEmailsRef.current = emailList;
    return index;
  }, [emailList, isEditing]);
}

export const EmailListInput = ({
  placeholder,
  onChange,
  defaultValue,
  onErrorStateChange,
}: EmailListInputProps) => {
  const { t } = useTranslation();

  const [emailListStr, setEmailListStr] = useState<string>(
    defaultValue?.join(", ") ?? "",
  );
  const [isEditing, setIsEditing] = useState(false);

  const emailList = useMemo(
    () => compact(emailListStr.split(",").map((it) => it.trim())),
    [emailListStr],
  );

  const editingIndex = useEditingIndex(emailList, isEditing);

  const invalidEmailList = useMemo(
    () =>
      emailList
        .map((it, index) => ({ value: it, originalIndex: index }))
        .filter((it) => !isEmailValid(it.value)),
    [emailList],
  );
  const displayableInvalidEmailList = useMemo(() => {
    return invalidEmailList.filter((it) => it.originalIndex !== editingIndex);
  }, [editingIndex, invalidEmailList]);

  const hasDisplayableError = useMemo(() => {
    return displayableInvalidEmailList.length > 0;
  }, [displayableInvalidEmailList]);

  const hasError = useMemo(() => {
    return invalidEmailList.length > 0;
  }, [invalidEmailList]);

  useEffect(() => {
    onErrorStateChange?.(hasError);
  }, [hasError, onErrorStateChange]);

  const handleOnChangeText = useCallback(
    (mailListStr: string) => {
      setEmailListStr(mailListStr);
      onChange?.(compact(mailListStr.split(",").map((mail) => mail.trim())));
    },
    [onChange],
  );

  const handleOnFocus = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);

  const handleOnBlur = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const invalidEmailListContent = useMemo(() => {
    return displayableInvalidEmailList.length > 0 ? (
      <ul>
        {displayableInvalidEmailList.map(({ value }, index) => (
          <li
            key={`${value}-${
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              index
            }`}
          >
            {value}
          </li>
        ))}
      </ul>
    ) : null;
  }, [displayableInvalidEmailList]);

  return (
    <div style={styles.additionalEmailTextInputContainer}>
      <CompactTextInput
        TextInputComponent={TextInputWithAutoSize}
        extraTextInputProps={{ inputStyle: styles.additionalEmailTextInput }}
        onChangeText={handleOnChangeText}
        value={emailListStr}
        hasError={hasDisplayableError}
        placeholder={placeholder}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      {hasDisplayableError && (
        <div style={styles.errorText}>
          {t("addExternalEmailsInvalid")}
          {invalidEmailListContent}
        </div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  additionalEmailTextInputContainer: {
    flexGrow: 1,
  },
  additionalEmailTextInput: {
    width: "100%",
    fontSize: FontSize.MEDIUM,
  },
  errorText: {
    color: ColorStyle.ACTION_DESCTRUCTIVE,
    fontSize: FontSize.SMALL,
    marginTop: Spacing.S8,
  },
});
