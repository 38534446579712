import { useCallback } from "react";
import { useDrop } from "react-dnd";
import clsx from "clsx";

import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  DraggableKanbanItem,
  KanbanColumnHeaderData,
} from "@kraaft/web/src/components/kanban/board/kanbanBoardTypes";
import { KANBAN_ITEM } from "@kraaft/web/src/components/kanban/board/kanbanBoardUtils";
import { KanbanColumnHeader } from "@kraaft/web/src/components/kanban/board/kanbanColumn/kanbanColumnHeader";

import { useStyles } from "./kanbanColumn.styles";

interface Props {
  column: KSchemaColumn<KColumnType.selectSingle | KColumnType.user>;
  kanbanColumnData: KanbanColumnHeaderData;
  itemCount: number;
  onItemDrop: (
    id: string,
    column: KSchemaColumn,
    optionKey: string | undefined,
  ) => void;
  children: React.ReactNode;
}

const KanbanColumn = (props: Props) => {
  const { column, kanbanColumnData, itemCount, onItemDrop, children } = props;
  const classes = useStyles();

  const checkItemCanDrop = useCallback(
    (item: DraggableKanbanItem) => {
      const recordOptionValue = ModularRecordUtils.getRecordField(
        item.modularRecord,
        column.key,
        column.type,
      )?.[0];

      return recordOptionValue !== kanbanColumnData.optionKey;
    },
    [column.key, column.type, kanbanColumnData.optionKey],
  );

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: KANBAN_ITEM,
    drop: (item: DraggableKanbanItem) => {
      onItemDrop(item.modularRecord.id, column, kanbanColumnData.optionKey);
    },
    canDrop: (item) => checkItemCanDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const shouldDisplayBorderOver = isOver && canDrop;

  return (
    <div ref={drop} className={classes.columnContainer}>
      <div
        className={clsx(
          classes.columnContentWrapper,
          shouldDisplayBorderOver && classes.columnContentDragOver,
        )}
        ref={drop}
      >
        <KanbanColumnHeader
          column={column}
          kanbanColumnData={kanbanColumnData}
          itemCount={itemCount}
          shouldDisplayBorderOver={shouldDisplayBorderOver}
        />
        <div className={classes.columnContentWrapper}>{children}</div>
      </div>
    </div>
  );
};

export { KanbanColumn };
