import { useMemo } from "react";
import { groupBy, orderBy, partition } from "lodash";

import {
  DistributedMapMarker,
  ElementTypeFromMarker,
  GroupedMarkers,
  MarkerGeoJSON,
} from "@kraaft/shared/components/mapController/types";

const convertMapMarkerToMarkerGeoJSON = <Marker extends DistributedMapMarker>(
  marker: Marker,
): MarkerGeoJSON<ElementTypeFromMarker<Marker>> => ({
  type: "Feature",
  properties: {
    cluster: false,
    marker,
  },
  geometry: {
    type: "Point",
    coordinates: [
      marker.element.geolocation.coords.longitude,
      marker.element.geolocation.coords.latitude,
    ],
  },
});

const orderMarkers = <Marker extends DistributedMapMarker>(markers: Marker[]) =>
  orderBy(markers, (marker) => marker.key);

const useFormatAndGroupMarkers = <Marker extends DistributedMapMarker>(
  markers: Marker[],
  enableClustering: boolean,
) => {
  return useMemo<GroupedMarkers<Marker>>(() => {
    if (!enableClustering) {
      return {
        withAffiliation: {} as GroupedMarkers<Marker>["withAffiliation"],
        withoutAffiliation: orderMarkers(markers).map(
          convertMapMarkerToMarkerGeoJSON,
        ),
      };
    }
    const partitionedRecords = partition(
      orderMarkers(markers),
      (marker) => marker.clusterAffiliation !== undefined,
    );

    const groupedByAffiliation = groupBy(
      partitionedRecords[0],
      (marker) => marker.clusterAffiliation,
    );

    return {
      withoutAffiliation: partitionedRecords[1].map(
        convertMapMarkerToMarkerGeoJSON,
      ),
      withAffiliation: Object.entries(groupedByAffiliation).reduce(
        (accumulator, [affiliation, markerGroup]) => ({
          // biome-ignore lint/performance/noAccumulatingSpread: accumulator is generic, hard not to spread
          ...accumulator,
          [affiliation]: markerGroup.map(convertMapMarkerToMarkerGeoJSON),
        }),
        {} as GroupedMarkers<Marker>["withAffiliation"],
      ),
    };
  }, [enableClustering, markers]);
};

export { useFormatAndGroupMarkers };
