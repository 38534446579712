import { ConditionLibraryForType } from "@kraaft/shared/core/generated/__generated/conditionTypes";
import { valueIsEmpty } from "@kraaft/shared/core/generated/generatedUtils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";

export const userCondition: ConditionLibraryForType<KColumnType.user> = {
  user: {
    is: {
      translationKey: "is",
      check: (lhs, rhs) => {
        const lhsValue = lhs.value?.[0];
        const rhsValue = rhs.value?.[0];

        return lhsValue === rhsValue;
      },
    },
    isNot: {
      translationKey: "isNot",
      check: (lhs, rhs) => {
        const lhsValue = lhs.value?.[0];
        const rhsValue = rhs.value?.[0];

        return lhsValue !== rhsValue;
      },
    },
    isAnyOf: {
      translationKey: "isAnyOf",
      typeParams: { allowMultiple: true },
      check: (lhs, rhs) => {
        const lhsValue = lhs.value?.[0];
        const rhsValue = rhs.value ?? [];

        return Boolean(lhsValue && rhsValue.includes(lhsValue));
      },
    },
    isNoneOf: {
      translationKey: "isNoneOf",
      typeParams: { allowMultiple: true },
      check: (lhs, rhs) => {
        const lhsValue = lhs.value?.[0];
        const rhsValue = rhs.value ?? [];

        return !(lhsValue && rhsValue.includes(lhsValue));
      },
    },
    //#endregion

    //#region Multiple User
    hasAnyOf: {
      translationKey: "hasAnyOf",
      typeParams: { allowMultiple: true },
      check: (lhs, rhs) => {
        const lhsValue = lhs.value ?? [];
        const rhsValue = rhs.value ?? [];

        return lhsValue.some((value) => rhsValue.includes(value));
      },
    },
    hasAllOf: {
      translationKey: "hasAllOf",
      typeParams: { allowMultiple: true },
      check: (lhs, rhs) => {
        const lhsValue = lhs.value ?? [];
        const rhsValue = rhs.value ?? [];

        return (
          lhsValue.length > 0 &&
          rhsValue.every((value) => lhsValue.includes(value))
        );
      },
    },
    isExactly: {
      translationKey: "isExactly",
      typeParams: { allowMultiple: true },
      check: (lhs, rhs) => {
        const lhsValue = lhs.value ?? [];
        const rhsValue = rhs.value ?? [];

        return (
          lhsValue.length === rhsValue.length &&
          lhsValue.every((value) => rhsValue.includes(value))
        );
      },
    },
    hasNoneOf: {
      translationKey: "hasNoneOf",
      typeParams: { allowMultiple: true },
      check: (lhs, rhs) => {
        const lhsValue = lhs.value ?? [];
        const rhsValue = rhs.value ?? [];

        return !rhsValue.some((value) => lhsValue.includes(value));
      },
    },
  },
  standalone: {
    isEmpty: {
      translationKey: "isEmpty",
      check: (value) => valueIsEmpty(value.value),
    },
    isNotEmpty: {
      translationKey: "isNotEmpty",
      check: (value) => !valueIsEmpty(value.value),
    },
  },
};
