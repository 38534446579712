import { ShareNewFileButton } from "@kraaft/shared/components/shareFilePreviewBar/shareNewFileButton";
import { useFileInput } from "@kraaft/web/src/core/utils/useFileInput";
import { useAddFilesToRoom } from "@kraaft/web/src/views/messenger/conversation/dropzone/dropzoneUtils";

interface Props {
  roomId: string;
}

/**
 * ShareNewFileButtonWrapper wraps the shared dumb component ShareNewFileButton
 * by adding a clickable component Dropzone to open the file browsing on the computer
 * @param props
 * @constructor
 */
const ShareNewFileButtonWrapper = ({ roomId }: Props) => {
  const onDrop = useAddFilesToRoom(roomId);

  const { getInputProps, open } = useFileInput({
    onAccepted: onDrop,
    multiple: true,
  });

  return (
    <>
      <ShareNewFileButton onPress={open} />
      <div>
        <input {...getInputProps()} />
      </div>
    </>
  );
};

export { ShareNewFileButtonWrapper };
