import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  columnHeader: {
    display: "flex",
    backgroundColor: Color.WHITE,

    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  columnHeaderContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    padding: Spacing.S8,
    margin: "0 8px",

    borderBottom: `1px solid ${ColorStyle.SEPARATOR}`,
  },

  withBorderOver: {
    zIndex: 2,
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    boxSizing: "border-box",

    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    border: `4px solid ${Color.BLUE_SAVOY}`,
    borderBottom: "none",
  },
  columnHeaderLabel: {
    flexGrow: 1,
    fontSize: FontSize.MEDIUM,
    marginLeft: 4,
  },
  columnHeaderItemCount: {
    marginLeft: 8,
    fontSize: FontSize.SMALL,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
});
