import { isNative } from "@kraaft/helper-functions";
import {
  getEnvironment,
  getHostEndpoint,
} from "@kraaft/shared/constants/environment/environment.utils";
import { RemotePath } from "@kraaft/shared/core/modules/file/file";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";

const pattern =
  getEnvironment().STORAGE_HOST &&
  `https://storage.googleapis.com/${
    getEnvironment().FIREBASE.APP_CONFIG.storageBucket
  }/`;
const replacement = `${getEnvironment().STORAGE_HOST}/`;

const REPLACE_LOCALHOST_ON_EMULATORS = {
  toMatch: getEnvironment().STORAGE_HOST || "http://localhost",
  toReplaceBy: `http://${getHostEndpoint()}`,
};

export function prepareDownloadUrl(downloadUrl: undefined): undefined;
export function prepareDownloadUrl(downloadUrl: RemotePath): RemotePath;
export function prepareDownloadUrl(downloadUrl: RemotePath | undefined) {
  if (!getEnvironment().FIREBASE.EMULATOR.ENABLED && !isNative() && pattern) {
    return downloadUrl?.replace(pattern, replacement);
  }
  if (getEnvironment().FIREBASE.EMULATOR.ENABLED) {
    if (
      REPLACE_LOCALHOST_ON_EMULATORS.toMatch &&
      downloadUrl?.startsWith(REPLACE_LOCALHOST_ON_EMULATORS.toMatch)
    ) {
      return fileAllocator.parseRemotePath(
        downloadUrl.replace(
          REPLACE_LOCALHOST_ON_EMULATORS.toMatch,
          REPLACE_LOCALHOST_ON_EMULATORS.toReplaceBy,
        ),
      );
    }
  }
  return downloadUrl;
}
