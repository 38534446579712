import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { compact } from "lodash";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { KDialog } from "@kraaft/shared/components/kDialog";
import {
  isOtherJob,
  userJobs,
} from "@kraaft/shared/components/onboarding/askUserJob/userJob";
import { useUserJob } from "@kraaft/shared/components/onboarding/askUserJob/useUserJob";
import { loadPoolMembers } from "@kraaft/shared/core/modules/poolMember/poolMemberActions";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button } from "@kraaft/ui";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";

import { useStyles } from "./jobColumn.styles";

interface JobColumnProps {
  job: string | undefined;
  userId: string;
  poolId: string;
}

const OTHER_JOB_ID = "@@other@@";

const JobColumn = ({ job, userId, poolId }: JobColumnProps) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const { t } = useTranslation();
  const { superadminSetUserJob, formatJob } = useUserJob();
  const [otherJob, openOtherJob, closeOtherJob] = useBooleanState();
  const [otherJobValue, setOtherJobValue] = useState("");

  const handleChangeJob = useCallback(
    async (newJobs: string[] | undefined) => {
      const [newJob] = newJobs ?? [];
      if (!newJob) {
        return;
      }
      if (newJob === OTHER_JOB_ID) {
        openOtherJob();
        return;
      }
      await superadminSetUserJob(userId, newJob);
      dispatch(loadPoolMembers({ poolId, superadminPanel: true }));
    },
    [dispatch, openOtherJob, poolId, superadminSetUserJob, userId],
  );

  const changeOtherJob = useCallback(async () => {
    await superadminSetUserJob(userId, otherJobValue);
    dispatch(loadPoolMembers({ poolId, superadminPanel: true }));
    closeOtherJob();
    setOtherJobValue("");
  }, [
    closeOtherJob,
    dispatch,
    otherJobValue,
    poolId,
    superadminSetUserJob,
    userId,
  ]);

  const items = useMemo(
    () =>
      compact([
        ...userJobs.map((j) => ({
          label: formatJob(j),
          value: j,
        })),
        { label: t("userJob.jobs.other"), value: OTHER_JOB_ID },
        job && isOtherJob(job)
          ? { label: formatJob(job), value: job, disabled: true }
          : undefined,
      ]),
    [formatJob, job, t],
  );

  return (
    <div className={styles.dropdown}>
      <KDropdown
        selectedItemIds={job ? [job] : []}
        onSelectionChange={handleChangeJob}
        items={items}
      />
      <KDialog
        open={otherJob}
        title={t("userJob.fillUserJob")}
        onClose={closeOtherJob}
        content={
          <div>
            <KInput
              label={t("userJob.job")}
              value={otherJobValue}
              onChangeText={setOtherJobValue}
            />
            <div className={styles.button}>
              <Button
                onPress={changeOtherJob}
                accessibilityLabel={t("save")}
                text={t("save")}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export { JobColumn };
