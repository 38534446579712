import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { GroupButtonWithPanel } from "@kraaft/shared/components/conversationDetails/groupButtonWithPanel";
import { NewRoomEditSchemaVisibilityButtonWithPanel } from "@kraaft/shared/components/createRoom/roomDetails/newRoomEditSchemaVisibilityButtonWithPanel";
import { NewRoomTaskList } from "@kraaft/shared/components/createRoom/roomDetails/newRoomTaskList";
import { RoomSchemaVisibilityOptimisticSelector } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.optimistic";
import { selectCheckboxSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { useRealtimeSchemaTemplates } from "@kraaft/shared/core/modules/schemaTemplate/useSchemaTemplates";
import { ColorStyle, Spacing } from "@kraaft/ui";

export enum NewRoomPanel {
  schemas = "schemas",
  taskList = "task-list",
}

type NewRoomSchemaSectionProps = {
  poolId: string;
  roomId: string;
  schemaTemplateIds: string[];
  setSchemaTemplateIds: (newValue: string[]) => void;
  portalHostname: string | undefined;
  openedPanel: NewRoomPanel | undefined;
  setOpenedPanel: (panelIdentifier: NewRoomPanel | undefined) => void;
  closePanel: () => void;
};

const NewRoomSchemaSection = ({
  poolId,
  roomId,
  schemaTemplateIds,
  setSchemaTemplateIds,
  portalHostname,
  openedPanel,
  setOpenedPanel,
  closePanel,
}: NewRoomSchemaSectionProps) => {
  const { t } = useTranslation();

  const roomSchemaVisibility = useSelector(
    RoomSchemaVisibilityOptimisticSelector.selectBuilt(roomId),
  );
  const schemaTemplates = useRealtimeSchemaTemplates();

  const taskSchema = useSelector(selectCheckboxSchema(poolId));

  const canDisplayTaskList = useMemo(
    () =>
      taskSchema &&
      roomSchemaVisibility?.whitelist.has(taskSchema.id) &&
      schemaTemplates.length > 0,
    [roomSchemaVisibility?.whitelist, schemaTemplates.length, taskSchema],
  );

  return (
    <View style={styles.container}>
      {roomSchemaVisibility ? (
        <NewRoomEditSchemaVisibilityButtonWithPanel
          poolId={poolId}
          roomId={roomId}
          roomSchemaVisibility={roomSchemaVisibility}
          name="CreateRoomSchemas"
          buttonText={t("newConversationFlow.schemasButtonLabel")}
          buttonIcon="settings-03"
          portalHostname={portalHostname}
          panelIdentifier={NewRoomPanel.schemas}
          openedPanel={openedPanel}
          setOpenedPanel={setOpenedPanel}
          backgroundColor={ColorStyle.BACKGROUND_STANDARD}
        />
      ) : null}

      {canDisplayTaskList ? (
        <GroupButtonWithPanel
          name="CreateRoomTaskList"
          buttonText={t("tasksList")}
          buttonIcon="check-done-01"
          buttonDescription={schemaTemplateIds.length.toString()}
          portalHostname={portalHostname}
          panelIdentifier={NewRoomPanel.taskList}
          openedPanel={openedPanel}
          setOpenedPanel={setOpenedPanel}
          backgroundColor={ColorStyle.BACKGROUND_STANDARD}
        >
          <NewRoomTaskList
            schemaTemplateIds={schemaTemplateIds}
            onSubmit={setSchemaTemplateIds}
            onClose={closePanel}
          />
        </GroupButtonWithPanel>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: Spacing.S8,
  },
});

export { NewRoomSchemaSection };
