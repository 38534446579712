import { useCallback, useMemo } from "react";
import { LayoutChangeEvent, View } from "react-native";

import { Button, ButtonProps } from "@kraaft/ui";

import { styles } from "./segmentedControl.styles";

export interface ControlSegmentProps<Id extends string = string>
  extends Omit<ButtonProps, "onPress" | "accessibilityLabel"> {
  id: Id;
  text: string;
  withLeftSpacer?: boolean;
  onPress?: (identifier: Id) => void;
  onLayout?: (event: LayoutChangeEvent) => void;
}

export const ControlSegment = <Id extends string>({
  withLeftSpacer,
  onLayout,
  onPress,
  ...props
}: ControlSegmentProps<Id>) => {
  const { text } = props;

  const handlePress = useCallback(() => {
    onPress?.(props.id);
  }, [onPress, props.id]);

  const additionalStyles = useMemo(
    () => (withLeftSpacer ? styles.leftSpacer : undefined),
    [withLeftSpacer],
  );

  return (
    <View style={[styles.buttonSpacer, additionalStyles]} onLayout={onLayout}>
      <Button
        accessibilityLabel={text}
        onPress={handlePress}
        variant="TERTIARY"
        {...props}
      />
    </View>
  );
};
