import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Dictionary } from "ts-essentials";

import { DetailedActionCard } from "@kraaft/shared/components/detailedActionCard";
import { PoolNotificationPreferences } from "@kraaft/shared/components/notificationPreferences/poolNotificationPreferences";
import { useSubscribedToUserPool } from "@kraaft/shared/core/modules/userPool/useSubscribedToUserPool";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Spacing } from "@kraaft/ui";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";

import { GenerateReportSteps } from "../../../components/multiStepDialog/customs";

import { useSettingsStyles } from "../settings.styles";

interface SettingsNotificationsProps {
  poolId: string | undefined;
}

const dialogs: Dictionary<React.ElementType> = {
  generateReport: GenerateReportSteps,
};

export const ManageNotifications = ({ poolId }: SettingsNotificationsProps) => {
  const { t } = useTranslation();
  const settingsClasses = useSettingsStyles();

  useSubscribedToUserPool(poolId);
  useTrackPage("SettingsNotifications");

  const classes = useStyles();

  if (!poolId) {
    return null;
  }

  return (
    <div className={clsx(settingsClasses.pageContainer)}>
      <PageHeader
        title={t("notifications.title")}
        titleElementId="settings-notifications-title"
      />
      <div className={classes.content}>
        <div className={classes.selector}>
          <PoolNotificationPreferences
            poolId={poolId}
            source="settings"
            noPadding
          />
        </div>
        <div className={classes.actionCard}>
          <DetailedActionCard
            title={t("notifications.settingsDescriptionTitle")}
            subtitle={t("notifications.settingsDescription")}
            icon="bell-04"
            rightIcon={null}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "row",
    gap: Spacing.S40,
  },
  selector: {
    flexShrink: 1,
    flexGrow: 1,
  },
  actionCard: {
    flexShrink: 0,
    width: 425,
  },
});
