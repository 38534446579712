import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { markerSize } from "@kraaft/shared/components/geolocation/baseMarker/baseMarkerConfig";
import { ClusterProps } from "@kraaft/shared/components/mapController/markers/marker/cluster/cluster.types";
import { getClusterId } from "@kraaft/shared/components/mapController/markers/markerUtils";
import { geolocationContextHover } from "@kraaft/shared/core/modules/room/roomActions";
import { selectGeolocationContext } from "@kraaft/shared/core/modules/room/roomSelectors";

import { useStyles } from "./cluster.styles";

export const Cluster = <ElementType,>(props: ClusterProps<ElementType>) => {
  const {
    cluster,
    superclusterInstance,
    isMaxAllowedZoom,
    isMaybeMaxZoom,
    ClusterRenderer,
    onPress,
    onTooltipPress,
  } = props;
  const geolocationContext = useSelector(selectGeolocationContext);
  const clusterId = getClusterId(cluster);
  const clusterIdAsString = clusterId?.toString();

  const classes = useStyles();
  const dispatch = useDispatch();

  const markers = useMemo(
    () =>
      clusterId
        ? superclusterInstance.getLeaves(clusterId, Number.POSITIVE_INFINITY)
        : [],
    [clusterId, superclusterInstance],
  );

  const isHovered = useMemo(
    () =>
      geolocationContext.hover?.id === clusterIdAsString ||
      markers.some(
        (marker) =>
          marker.properties.marker.element.id === geolocationContext.hover?.id,
      ),
    [clusterIdAsString, geolocationContext.hover?.id, markers],
  );

  const handlePress = useCallback(() => {
    onPress?.(markers, isMaybeMaxZoom);
  }, [onPress, markers, isMaybeMaxZoom]);

  const setHover = useCallback(
    (hover: boolean) => {
      dispatch(
        geolocationContextHover({
          hovered: hover,
          id: clusterIdAsString,
        }),
      );
    },
    [clusterIdAsString, dispatch],
  );

  const setHoverTrue = useCallback(() => setHover(true), [setHover]);
  const setHoverFalse = useCallback(() => setHover(false), [setHover]);

  if (ClusterRenderer === undefined) {
    return null;
  }

  return (
    <div
      className={classes.marker}
      style={{
        height: markerSize.height,
        width: markerSize.width,
        zIndex: isHovered ? 2 : 1,
      }}
      onClick={handlePress}
      onMouseEnter={setHoverTrue}
      onMouseLeave={setHoverFalse}
    >
      <ClusterRenderer
        isHovered={isHovered}
        markerCount={cluster.properties.point_count}
        markers={markers}
        isMaxAllowedZoom={isMaxAllowedZoom}
        isMaybeMaxZoom={isMaybeMaxZoom}
        onTooltipPress={onTooltipPress}
      />
    </div>
  );
};
