import { useDialog } from "@kraaft/shared/components/kDialog/context";
import { ConfirmationStep } from "@kraaft/web/src/components/multiStepDialog/customs/deleteSchemaColumn/confirmationStep";

interface Props {
  columnName: string;
  onDelete: () => void;
}

export const useDeleteSchemaColumn = (props: Props) => {
  const { columnName, onDelete } = props;
  const { openDialog, closeDialog } = useDialog();

  return () => {
    openDialog(
      <ConfirmationStep
        columnName={columnName}
        onDelete={onDelete}
        onClose={closeDialog}
      />,
    );
  };
};
