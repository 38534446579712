import { StyleSheet } from "react-native";

import { styleObject } from "@kraaft/shared/styles/textInput.styles";
import { ColorStyle, PixelSize, Radius, Spacing } from "@kraaft/ui";

const styles = StyleSheet.create({
  parentViewSizer: {
    position: "absolute",
    left: 0,
    right: 0,
  },

  searchBarContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: "100%",
    height: PixelSize.S32,
    borderRadius: Radius.SMALL,
  },
  searchBar: {
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },

  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
  },

  inputWrapper: {
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    flexShrink: 1,
    borderRadius: Radius.SMALL,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  input: styleObject.input,

  buttonContainer: {
    marginRight: 8,
  },

  clearButtonContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: Spacing.S4,
    width: PixelSize.S32,
  },

  placeholderIcon: {
    marginLeft: Spacing.S8,
  },

  rightButtonContainer: {
    marginLeft: Spacing.S8,
  },
});

export { styles };
