import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectOrderedModularFoldersWithSchemaIdAndVisibility } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { ModularFolderVisibilityType } from "@kraaft/shared/core/modules/modularFolder/types";
import {
  subscribeToPoolRooms,
  unsubscribeFromPoolRooms,
} from "@kraaft/shared/core/modules/room/roomActions";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Preloader } from "@kraaft/ui";
import { useFilterFolders } from "@kraaft/web/src/components/filterMenu/filterUtils";
import { ModularFoldersTable } from "@kraaft/web/src/components/modularFoldersTable";
import { useSchemaView } from "@kraaft/web/src/components/modularFoldersViewer/useSchemaView";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

import { useStyles } from "./modularFoldersTableView.styles";

interface Props {
  schema: KFolderSchema;
  schemaViewId?: string;
}

const ModularFoldersTableViewForSchema = (props: Props) => {
  const { schema, schemaViewId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { isLoaded: foldersFetched, folders } = useSelector(
    selectOrderedModularFoldersWithSchemaIdAndVisibility({
      visibilities: [
        { type: ModularFolderVisibilityType.User },
        { type: ModularFolderVisibilityType.Pool, poolId: schema.poolId },
      ],
      schemaId: schema.id,
    }),
  );

  const schemaView = useSchemaView(
    RecordWorkspace.ALL_ROOMS,
    schema.id,
    schemaViewId,
  );

  const filteredFolders = useFilterFolders(
    schema.rootSection,
    folders,
    schemaView.filters,
  );

  useEffect(() => {
    dispatch(ModularFolderActions.subscribeForPool({ poolId: schema.poolId }));
    dispatch(
      subscribeToPoolRooms({
        poolId: schema.poolId,
      }),
    );
    return () => {
      dispatch(
        ModularFolderActions.unsubscribeForPool({ poolId: schema.poolId }),
      );
      dispatch(
        unsubscribeFromPoolRooms({
          poolId: schema.poolId,
        }),
      );
    };
  }, [dispatch, schema]);

  return (
    <div className={classes.container}>
      {foldersFetched ? (
        <ModularFoldersTable schema={schema} folders={filteredFolders} />
      ) : (
        <Preloader absoluteFill transparent />
      )}
    </div>
  );
};

export { ModularFoldersTableViewForSchema };
