import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Pressable } from "react-native";
import { useDispatch } from "react-redux";

import { showInfo } from "@kraaft/shared/core/modules/alert/alertActions";
import {
  FolderCheckedEvent,
  FolderCreatedEvent,
  FolderUncheckedEvent,
} from "@kraaft/shared/core/modules/message/messageState";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";

interface FolderLogWrapperProps {
  children: React.ReactNode;
  roomId: string;
  event: FolderCreatedEvent | FolderCheckedEvent | FolderUncheckedEvent;
}

export const FolderLogWrapper = ({
  children,
  roomId,
  event,
}: FolderLogWrapperProps) => {
  const { t } = useTranslation();
  const navigationService = useNavigationService();
  const dispatch = useDispatch();

  const handlePress = useCallback(() => {
    if (event.relatedRessourceDeleted) {
      dispatch(
        showInfo({
          title: t("modularFolderNoLongerExists"),
        }),
      );

      return;
    }

    navigationService.navigate("ModularFolderDetails", {
      roomId,
      schemaId: event.schemaId,
      folderId: event.folderId,
    });
  }, [
    dispatch,
    event.folderId,
    event.relatedRessourceDeleted,
    event.schemaId,
    navigationService,
    roomId,
    t,
  ]);

  return (
    <Pressable accessibilityLabel={t("openFolder")} onPress={handlePress}>
      {children}
    </Pressable>
  );
};
