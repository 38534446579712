import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { selectPoolFolderSchemasEnabledForRoom } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.selectors";
import { KSchemaIcon } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { selectPoolFolderSchemas } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { lodashKeyResolver } from "@kraaft/shared/core/utils/utils";

export interface SchemaItem {
  id: string;
  icon: KSchemaIcon;
  name: string;
  highlightedCheckboxColumnKey: string | undefined;
}

export const selectSchemaItems = memoize(
  (poolId: string | undefined, roomId: string, forceEnabled: boolean) =>
    createSelector(
      forceEnabled
        ? selectPoolFolderSchemas(poolId)
        : selectPoolFolderSchemasEnabledForRoom(poolId, roomId),
      (schemas): Array<SchemaItem> => {
        return schemas.map((schema) => ({
          highlightedCheckboxColumnKey: schema.highlightedCheckbox,
          icon: schema.icon,
          id: schema.id,
          name: schema.name,
        }));
      },
    ),
  lodashKeyResolver,
);
