import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { MapTrackingInfo } from "@kraaft/shared/components/mapController/types";
import { MapOverlayStateActions } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.actions";
import {
  selectEnabledMapOverlays,
  selectMapOverlaysByPoolId,
} from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.selectors";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button } from "@kraaft/ui";
import {
  FilteredList,
  FilteredListItem,
} from "@kraaft/web/src/components/dropdown/filteredList";
import { OverlayMenu } from "@kraaft/web/src/components/overlayMenu";

const EnableMapOverlaysButton = ({
  trackingInfo,
}: {
  trackingInfo: MapTrackingInfo;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const buttonRef = useRef(null);

  const [isMenuOpen, openMenu, closeMenu] = useBooleanState();

  const poolId = useSelector(selectCurrentPoolId);
  const mapOverlays = useSelector(selectMapOverlaysByPoolId(poolId));

  const enabledMapOverlays = useSelector(selectEnabledMapOverlays(poolId));

  const selectedIds = useMemo(
    () => enabledMapOverlays.map((mapOverlay) => mapOverlay.id),
    [enabledMapOverlays],
  );

  const handleItemClick = useCallback(
    (item: FilteredListItem | undefined) => {
      if (item === undefined || poolId === undefined) {
        return;
      }
      const overlayId = item.value;
      const wasSelected = selectedIds.includes(overlayId);

      trackEvent({
        eventName: wasSelected ? "Remove Map Layer" : "Apply Map Layer",
        ...trackingInfo,
        overlay_id: overlayId,
        overlay_name: item.label,
      });

      dispatch(
        MapOverlayStateActions.toggleMapOverlay({
          poolId,
          overlayId,
        }),
      );
    },
    [dispatch, poolId, selectedIds, trackingInfo],
  );

  const items = useMemo<FilteredListItem[]>(
    () =>
      mapOverlays.map((mapOverlay) => ({
        label: mapOverlay.name,
        value: mapOverlay.id,
      })),
    [mapOverlays],
  );

  if (mapOverlays.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        ref={buttonRef}
        onPress={openMenu}
        icon="layers-two-02"
        accessibilityLabel={t("menu")}
        tooltip={t("importMapOverlay.enableOverlays")}
        size="SMALL"
        variant="MAP"
      />
      <OverlayMenu
        visible={isMenuOpen}
        onClose={closeMenu}
        anchorRef={buttonRef}
        placement="bottom-end"
      >
        <FilteredList
          items={items}
          onItemClick={handleItemClick}
          withMultiSelect
          selectedItemIds={selectedIds}
        />
      </OverlayMenu>
    </>
  );
};

export { EnableMapOverlaysButton };
