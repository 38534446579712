import { useTranslation } from "react-i18next";

import { SSOElevation } from "@kraaft/shared/components/auth/ssoElevation";
import { useSignoutAlert } from "@kraaft/shared/core/modules/user/useSignoutAlert";
import { Button } from "@kraaft/ui";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";

export const SSOElevationWeb = () => {
  const { t } = useTranslation();

  const signOut = useSignoutAlert();

  const text = t("signOut");

  return (
    <SplitScreenWrapper
      headerLeft={
        <Button
          accessibilityLabel={text}
          text={text}
          onPress={signOut}
          variant="TERTIARY"
          size="SMALL"
          icon="log-out-02"
        />
      }
    >
      <SSOElevation />
    </SplitScreenWrapper>
  );
};
