import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";

import { alphaHex } from "@kraaft/helper-functions";
import {
  ActionFooter,
  FooterActions,
} from "@kraaft/shared/components/actionFooter/actionFooter";
import { MemoryStateActions } from "@kraaft/shared/core/modules/memory/memoryActions";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { useAuth } from "@kraaft/shared/core/services/auth/useAuth";
import { useSSOProvider } from "@kraaft/shared/core/services/auth/useSSO";
import { firebaseSDK } from "@kraaft/shared/core/services/firebase/sdk";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Color, Icon, Preloader, Spacing, Text } from "@kraaft/ui";

import { SsoHelpSheet } from "./ssoHelpSheet";

export const SSOElevation = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const dispatch = useDispatch();

  const [error, setError] = useState<string | null>(null);
  const [isWorking, setWorking] = useState(false);

  useTrackPage("SSOElevation");

  const authFlow = useSSOProvider(auth?.email);

  const {
    id,
    name = "SSO",
    skippable,
    offlineAccess,
    helpText,
  } = authFlow.data?.provider || { offlineAccess: false };

  const { open: openHelp, element: helpSheet } = SsoHelpSheet.use({
    helpText: helpText ?? "",
  });

  const isAlreadyLinked = authFlow.data?.linked;

  const elevateToSSO = useCallback(async () => {
    trackEvent({ eventName: "SSO Link Account", provider_id: id });

    if (!id) {
      return;
    }
    setWorking(true);
    setError(null);

    try {
      if (isAlreadyLinked) {
        await firebaseSDK.triggerSSOLogin({ id, offlineAccess }, auth?.email);
      } else {
        await firebaseSDK.triggerSSOLinking({ id, offlineAccess });
      }

      dispatch(UserActions.refreshAuth());
      setError(null);
    } catch (e) {
      setWorking(false);
      if (
        e.code === "auth/credential-already-in-use" ||
        e.code === "auth/email-already-in-use"
      ) {
        setError(t("sso.link.error.already-in-use", { name }));
      } else {
        setError(t("sso.link.error.generic", { name, error: e.message }));
      }
    }
  }, [auth?.email, dispatch, id, isAlreadyLinked, name, offlineAccess, t]);

  const actions = useMemo<FooterActions>(
    () => [
      {
        id: "ide2e-sso-elevate",
        onPress: elevateToSSO,
        text: error ? t("sso.link.retry") : t("sso.link.action"),
        disabled: !authFlow.data,
        variant: "PRIMARY",
      },
      ...(helpText
        ? [
            {
              id: "ide2e-sso-help",
              onPress: openHelp,
              text: t("sso.link.help"),
              accessibilityLabel: t("sso.link.help"),
            },
          ]
        : []),
      ...(skippable
        ? [
            {
              id: "ide2e-sso-skip",
              onPress: () => {
                trackEvent({
                  eventName: "SSO Skip Linking",
                  provider_id: id,
                });
                dispatch(MemoryStateActions.setSSOElevationSkipped());
              },
              text: t("sso.link.skip"),
              accessibilityLabel: t("sso.link.skip"),
            },
          ]
        : []),
    ],
    [
      elevateToSSO,
      error,
      t,
      authFlow.data,
      helpText,
      openHelp,
      skippable,
      id,
      dispatch,
    ],
  );

  return (
    <View style={styles.root}>
      {isWorking && (
        <Preloader absoluteFill backgroundColor={alphaHex(Color.WHITE, 0.6)} />
      )}
      <View style={styles.content}>
        <Icon name="shield-tick" size="JUMBO" />
        <Text weight="bold" size="H1" style={styles.title}>
          {t("sso.link.title")}
        </Text>
        <Text weight="light" size="BODY" style={styles.body}>
          {t("sso.link.body", { name })}
        </Text>
        <Text
          id="ide2e-sso-elevate-error"
          weight="light"
          size="BODY"
          color="ACTION_DESCTRUCTIVE"
        >
          {error}
        </Text>
      </View>
      <ActionFooter backgroundColor={Color.WHITE} actions={actions} />
      <SafeAreaView />
      {helpSheet}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    backgroundColor: Color.WHITE,
  },
  content: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: Spacing.S16,
  },
  title: {
    marginTop: Spacing.S24,
    marginBottom: Spacing.S12,
    textAlign: "center",
  },
  body: {
    marginBottom: Spacing.S24,
    textAlign: "center",
  },
});
