import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectOrderedModularFoldersWithRoomIdAndSchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import {
  subscribeToRoom,
  unsubscribeFromRoom,
} from "@kraaft/shared/core/modules/room/roomActions";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Preloader } from "@kraaft/ui";
import { useFilterFolders } from "@kraaft/web/src/components/filterMenu/filterUtils";
import { ModularFoldersTable } from "@kraaft/web/src/components/modularFoldersTable";
import { useSchemaView } from "@kraaft/web/src/components/modularFoldersViewer/useSchemaView";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

import { useStyles } from "./modularFoldersTableView.styles";

interface Props {
  roomId: string;
  schema: KFolderSchema;
  schemaViewId?: string;
}

const ModularFoldersTableViewForRoom = (props: Props) => {
  const { schema, roomId, schemaViewId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { isLoaded: foldersFetched, folders } = useSelector(
    selectOrderedModularFoldersWithRoomIdAndSchemaId({
      roomId,
      schemaId: schema.id,
    }),
  );

  const schemaView = useSchemaView(
    RecordWorkspace.IN_ROOM,
    schema.id,
    schemaViewId,
  );

  const filteredFolders = useFilterFolders(
    schema.rootSection,
    folders,
    schemaView?.filters,
  );

  useEffect(() => {
    dispatch(ModularFolderActions.subscribeForRoom({ roomId }));
    dispatch(subscribeToRoom({ roomId }));
    return () => {
      dispatch(ModularFolderActions.unsubscribeForRoom({ roomId }));
      dispatch(unsubscribeFromRoom({ roomId }));
    };
  }, [dispatch, roomId]);

  return (
    <div className={classes.container}>
      {foldersFetched ? (
        <ModularFoldersTable schema={schema} folders={filteredFolders} />
      ) : (
        <Preloader absoluteFill transparent />
      )}
    </div>
  );
};

export { ModularFoldersTableViewForRoom };
