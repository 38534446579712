import { ReactElement } from "react";
import { StyleSheet, View } from "react-native";

import { TooltipReactionLine } from "@kraaft/shared/components/message/reactions/tooltipReactionLine";
import { SheetTooltip, Spacing, Text } from "@kraaft/ui";

interface PillTooltipProps {
  children: ReactElement;
  emoji: string;
  names: string[];
}

export const PillTooltip = ({ children, emoji, names }: PillTooltipProps) => {
  return (
    <SheetTooltip
      key={emoji}
      content={
        <View style={styles.content}>
          <View style={styles.emoji}>
            <Text>{emoji}</Text>
            <Text>{names.length}</Text>
          </View>
          <View style={styles.linesContainer}>
            {names.map((name) => (
              <TooltipReactionLine key={name} name={name} />
            ))}
          </View>
        </View>
      }
    >
      {children}
    </SheetTooltip>
  );
};

const styles = StyleSheet.create({
  content: {
    padding: Spacing.S16,
    gap: Spacing.S16,
  },
  emoji: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: Spacing.S4,
  },
  divider: {
    flexGrow: 1,
    backgroundColor: "lightgrey",
    height: 1,
    paddingHorizontal: Spacing.S16,
  },
  linesContainer: {
    gap: Spacing.S8,
  },
});
