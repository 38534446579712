import { useCallback, useRef } from "react";
import clsx from "clsx";

import { Color, ColorStyle, Icon } from "@kraaft/ui";
import { usePopupMenu } from "@kraaft/web/src/components/popupMenu";

import { ColorPickerContent } from "./colorPickerContent";

import { useStyles } from "./colorPicker.styles";

export interface ColorPickerProps {
  value?: string;
  onChange: (newValue: string) => void;
  direction?: "horizontal" | "vertical";
}

export const ColorPicker = ({
  value,
  onChange,
  direction = "horizontal",
}: ColorPickerProps) => {
  const classes = useStyles();

  const buttonRef = useRef(null);

  const { open, close } = usePopupMenu();

  const handleChange = useCallback(
    (newValue: string) => {
      onChange(newValue);
      close();
    },
    [close, onChange],
  );

  const openMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    open(
      <ColorPickerContent
        direction={direction}
        handleChange={handleChange}
        value={value}
      />,
      {
        anchorRef: buttonRef,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      },
    );
  };

  return (
    <div className={classes.wrapper}>
      <div ref={buttonRef} />
      <div
        data-testid="ide2e-color-picker"
        className={clsx(classes.color, {
          [classes.border]: value === Color.WHITE,
        })}
        style={{ backgroundColor: value }}
        onClick={openMenu}
      >
        <Icon
          name="triangle-down-fill"
          size="MINI"
          color={
            value === Color.WHITE ? ColorStyle.FONT_LOW_EMPHASIS : Color.WHITE
          }
        />
      </div>
    </div>
  );
};
