import { KraaftLogo } from "@kraaft/shared/components/kraaftLogo";
import { AskPoolCompanySize } from "@kraaft/shared/components/onboarding/askPoolCompanySize";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";

const AskPoolCompanySizeWeb = () => (
  <SplitScreenWrapper headerLeft={<KraaftLogo />}>
    <AskPoolCompanySize />
  </SplitScreenWrapper>
);

export { AskPoolCompanySizeWeb };
