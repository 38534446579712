import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFoldersMap } from "@kraaft/web/src/components/modularFoldersMap";

import { useFilteredModularFolders } from "./modularFoldersMapViewUtils";

interface Props {
  schema: KFolderSchema;
  schemaViewId?: string;
}

const ModularFoldersMapViewForSchema = ({ schema, schemaViewId }: Props) => {
  const { isLoaded, filteredFolders } = useFilteredModularFolders({
    schema,
    schemaViewId,
  });
  return (
    <ModularFoldersMap
      isLoaded={isLoaded}
      filteredFolders={filteredFolders}
      schema={schema}
    />
  );
};

export { ModularFoldersMapViewForSchema };
