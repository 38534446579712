import { useSelector } from "react-redux";

import { DoesNotExist } from "@kraaft/shared/components/modularFolders/modularFolderDetails/doesNotExist";
import { ModularFolders } from "@kraaft/shared/components/modularFolders/modularFolders";
import {
  selectSchemaExists,
  selectSchemaLoaded,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { Preloader } from "@kraaft/ui";

interface ModularFoldersOrEmptyStateProps {
  id?: string;
  search: string | undefined;
  roomId: string;
  schemaId: string;
}

export const ModularFoldersOrDoesNotExist = ({
  roomId,
  id,
  search,
  schemaId,
}: ModularFoldersOrEmptyStateProps) => {
  const loaded = useSelector(selectSchemaLoaded(schemaId));
  const exists = useSelector(selectSchemaExists(schemaId));

  if (!exists) {
    return <DoesNotExist roomId={roomId} />;
  }

  if (!loaded) {
    return <Preloader absoluteFill transparent />;
  }

  return (
    <ModularFolders
      id={id}
      search={search}
      schemaId={schemaId}
      roomId={roomId}
    />
  );
};
