type CastedFast<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => never ? never : T[K];
};

type Casted<T> = {
  [K in keyof T as T[K] extends (...args: any[]) => never ? never : K]: T[K];
};
type Expand<T> = T extends infer U ? { [K in keyof U]: U[K] } : never;

export abstract class Builder {
  protected init<T extends {} = {}>() {
    return <R extends {} = {}>(run: R) => run as { [key: string]: {} } & T & R;
  }

  state = {} as { [key: string]: {} };

  protected cast<TH>(this: TH): CastedFast<TH> {
    return this as any;
  }

  protected add<const C extends {}>(
    added: C = {} as C,
  ): this extends infer U ? U & { state: C } : never {
    this.state = { ...this.state, ...added };
    return this as any;
  }

  protected when<Condition>(): this["state"] extends Condition ? this : never {
    return this as any;
  }

  build(): Expand<this["state"]> {
    return this.state as any;
  }
}

// export type get<th extends { state: any }, K extends string> = (
//   K extends any
//     ? (k: th["state"][K]) => void
//     : never
// ) extends (k: infer I) => void
//   ? I
//   : never;

export type get<th extends { state: any }, K extends string> = th["state"][K];
