import { View } from "react-native";
import compact from "lodash/compact";

import { WithIconContent } from "@kraaft/shared/components/modular/pill/utils";
import { PillRenderer } from "@kraaft/shared/components/modular/pill/utils/types";
import { Pill } from "@kraaft/shared/components/pill";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  getColorForSelectColumnOption,
  getSelectColumnOption,
  KSchemaUtils,
} from "@kraaft/shared/core/modules/schema/schema.utils";
import { IconName } from "@kraaft/ui";

import { styles } from "../modularPill.styles";

const getSelectPillIcon = (
  allowMultiple: boolean | undefined,
  allowColor: boolean | undefined,
  color: string | undefined,
): IconName => {
  const hasColor = allowColor && color !== undefined;
  if (allowMultiple) {
    return hasColor ? "tag-01-fill" : "tag-01";
  }
  return hasColor ? "triangle-down-fill" : "triangle-down";
};

export const SelectCompactedPillRenderer: PillRenderer<
  KColumnType.selectSingle | KColumnType.selectMultiple
> = ({ schemaColumn, recordProperty }) => {
  const selectedIds = recordProperty?.value;

  if (!selectedIds || selectedIds.length === 0) {
    return null;
  }

  const firstLabelId = selectedIds[0];

  if (firstLabelId === undefined) {
    return null;
  }
  const firstLabel = schemaColumn.options[firstLabelId];
  const moreLabelCount = selectedIds.length - 1;
  const displayMoreLabel = Boolean(moreLabelCount);

  if (firstLabel === undefined) {
    return null;
  }

  return (
    <View style={styles.selectCompactedPillRendererContainer}>
      <Pill
        icon={getSelectPillIcon(
          KSchemaUtils.isMultiSelect(schemaColumn),
          schemaColumn.allowColor,
          firstLabel.color,
        )}
        content={firstLabel.label}
        iconColor={schemaColumn.allowColor ? firstLabel.color : undefined}
        size="small"
        withSpacer={displayMoreLabel}
      />
      {displayMoreLabel ? (
        <Pill
          icon={getSelectPillIcon(
            KSchemaUtils.isMultiSelect(schemaColumn),
            schemaColumn.allowColor,
            firstLabel.color,
          )}
          content={`+ ${moreLabelCount}`}
          size="small"
        />
      ) : null}
    </View>
  );
};

export const SelectPillRenderer: PillRenderer<
  KColumnType.selectSingle | KColumnType.selectMultiple
> = ({ schemaColumn, recordProperty }) => {
  const selectedIds = recordProperty?.value;

  if (
    !selectedIds ||
    selectedIds.length === 0 ||
    selectedIds.every(
      (labelId) => !Object.keys(schemaColumn.options).includes(labelId),
    )
  ) {
    return null;
  }

  return (
    <WithIconContent
      iconName={KSchemaUtils.getIcon(schemaColumn)}
      tooltipTitle={schemaColumn.name}
    >
      <View style={styles.selectPillRendererContainer}>
        {compact(
          selectedIds.map((labelId, index) => {
            const option = getSelectColumnOption(schemaColumn, labelId);
            const isNotLast = index < selectedIds.length - 1;

            if (option === undefined) {
              return undefined;
            }

            return (
              <View key={labelId} style={styles.selectPillRendererItem}>
                <Pill
                  content={option.label}
                  color={getColorForSelectColumnOption(schemaColumn, option)}
                  size="small"
                  withSpacer={isNotLast}
                />
              </View>
            );
          }),
        )}
      </View>
    </WithIconContent>
  );
};
