import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Dictionary } from "ts-essentials";

import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button } from "@kraaft/ui";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";

import { GenerateReportSteps } from "../../../components/multiStepDialog/customs";

import { useSettingsStyles } from "../settings.styles";
import { useStyles } from "./manageAnalytics.styles";

interface SettingsAnalyticsProps {
  poolId: string | undefined;
}

const dialogs: Dictionary<React.ElementType> = {
  generateReport: GenerateReportSteps,
};

const ManageAnalytics = (props: SettingsAnalyticsProps): JSX.Element => {
  const { t } = useTranslation();
  const settingsClasses = useSettingsStyles();
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState<string | null>(null);

  useTrackPage("SettingsAnalytics");

  function handleOpenDialog(type: string) {
    return () => {
      setDialogOpen(type);
    };
  }

  function handleCloseDialog() {
    setDialogOpen(null);
  }

  return (
    <div className={clsx(settingsClasses.pageContainer, classes.container)}>
      <PageHeader
        title={t("settingsAnalytics")}
        titleElementId="settings-analytics-title"
      />
      <div className={classes.titleContainer}>
        <span className={classes.title}>
          {t("generateReportGenerateTitle")}
        </span>
      </div>
      <div className={classes.referenceContainer}>
        <Button
          id="settings-analytics-generate"
          accessibilityLabel={t("generateReportDownload")}
          text={t("generateReportDownload")}
          icon="download-01"
          onPress={handleOpenDialog("generateReport")}
          variant="PRIMARY"
        />
      </div>

      {dialogOpen &&
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        React.createElement(dialogs[dialogOpen]!, {
          open: dialogOpen !== undefined,
          onClose: handleCloseDialog,
        })}
    </div>
  );
};

export { ManageAnalytics };
