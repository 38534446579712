import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { DirectoryList } from "@kraaft/shared/components/actionCardList/directoryList";
import { AddDirectoryFlow } from "@kraaft/shared/components/directory/addDirectoryFlow";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KDialogConfig } from "@kraaft/shared/components/kDialog/kDialogProps";
import {
  selectChildDirectories,
  selectDirectory,
} from "@kraaft/shared/core/modules/directory/directorySelectors";
import { attachInDirectoryFromSelection } from "@kraaft/shared/core/modules/message/attach/attachMessageActions";
import { selectMessageSelectionSource } from "@kraaft/shared/core/modules/message/messageSelectors";
import { nullId } from "@kraaft/shared/core/utils";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { useLoader } from "@kraaft/shared/core/utils/useLoader";
import { Button, PortalHost } from "@kraaft/ui";

import { CurrentStepProps } from "../../../multiStepDialogTypes";

import { useStyles } from "../chooseStep.styles";

const PORTAL_HOSTNAME = "chooseDirectoryDialog";

export interface ChooseDirectoryStepParams {
  roomId: string;
  directoryId: string;
  setSelectedDirectoryId: (directoryId: string) => void;
  onSuccess?: () => void;
}

type ChooseDirectoryStepProps = CurrentStepProps<ChooseDirectoryStepParams>;

const ChooseDirectoryStep = (props: ChooseDirectoryStepProps) => {
  const { roomId, setStep, directoryId, setSelectedDirectoryId, onSuccess } =
    props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const directories = useSelector(selectChildDirectories(roomId, directoryId));
  const directory = useSelector(selectDirectory(directoryId));
  const selectionSource = useSelector(selectMessageSelectionSource(roomId));

  const [isAddDirectoryFlowOpen, openAddDirectoryFlow, closeAddDirectoryFlow] =
    useBooleanState();

  const { loaderId: directoryLoaderId, loading: directoryLoading } = useLoader({
    prefix: "addDirectoryFromAttachFlow",
  });

  const { loaderId: attachLoaderId, loading: attachLoading } = useLoader({
    prefix: `attachInDirectoryFrom-${selectionSource}`,
  });

  const goToPreviousStep = useCallback(() => {
    if (directory?.parentId && directory.parentId !== nullId) {
      setSelectedDirectoryId(directory.parentId);
    } else {
      setStep({ index: 0 });
    }
  }, [directory, setSelectedDirectoryId, setStep]);

  const handleAttachInExisting = useCallback(() => {
    if (directoryId) {
      dispatch(
        attachInDirectoryFromSelection({
          roomId,
          directoryId,
          loaderId: attachLoaderId,
        }),
      );
    }
    onSuccess?.();
  }, [attachLoaderId, directoryId, dispatch, onSuccess, roomId]);

  const title = `${t("attachInDirectory")} ${directory?.name}`;

  const content = (
    <div className={classes.dialogContentContainer}>
      <DirectoryList
        roomId={roomId}
        directoryId={directoryId}
        directories={directories}
        onPressDirectory={setSelectedDirectoryId}
        newDirectoryElement={
          <AddDirectoryFlow
            open={isAddDirectoryFlowOpen}
            onClose={closeAddDirectoryFlow}
            roomId={roomId}
            parentId={directoryId}
            loaderId={directoryLoaderId}
            loading={directoryLoading}
          />
        }
      />
      {!isAddDirectoryFlowOpen && (
        <div className={classes.addDirectoryButton}>
          <Button
            accessibilityLabel={t("directoryActions.new")}
            text={t("directoryActions.new")}
            icon="plus"
            variant="QUATERNARY"
            onPress={openAddDirectoryFlow}
          />
        </div>
      )}
    </div>
  );

  const leftActions: KDialogConfig["leftActions"] = [
    {
      accessibilityLabel: t("previous"),
      text: t("previous"),
      variant: "SECONDARY",
      onPress: goToPreviousStep,
    },
  ];

  const actions: KDialogConfig["actions"] = [
    {
      accessibilityLabel: t("attachHere"),
      text: t("attachHere"),
      onPress: handleAttachInExisting,
      variant: "PRIMARY",
      loading: attachLoading,
    },
  ];

  return (
    <>
      <KDialogContent
        title={title}
        content={content}
        leftActions={leftActions}
        actions={actions}
        fullHeight
      />
      <PortalHost hostname={PORTAL_HOSTNAME} />
    </>
  );
};

export { ChooseDirectoryStep };
