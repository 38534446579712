import { makeStyles } from "@mui/styles";

import { PixelSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    height: PixelSize.S32,
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: 4,
  },

  name: {
    margin: "0 8px",
  },
  actionsContainer: {
    opacity: 0,
    pointerEvents: "none",

    marginLeft: "auto",
    "& > :not(:last-child)": {
      marginRight: Spacing.S8,
    },
  },

  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    "& > :not(:last-child)": {
      marginRight: Spacing.S8,
    },
  },
});
