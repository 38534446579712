import { isAtLeastPoolStandard } from "@kraaft/shared/core/modules/pool/poolUtil";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { type TFunction } from "@kraaft/shared/core/services/i18next";

export function getFriendlyUserLabel({
  userId,
  currentUserId,
  poolId,
  users,
  t,
}: {
  userId: string;
  users: Record<string, User>;
  t: TFunction;
  poolId: string;
  currentUserId?: string;
}) {
  const user = users[userId];
  if (user === undefined) {
    return t("userNonRecognized");
  }

  if (userId === currentUserId) {
    return `${user.username} (${t("you")})`;
  }

  const role = user.pools[poolId]?.role;

  if (!role) {
    return `${user.username} (${t("outsiderShort")})`;
  }

  if (isAtLeastPoolStandard(role)) {
    return user.username;
  }

  return `${user.username} (${t("externalShort")})`;
}
