import moment from "moment";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { selectUsername } from "@kraaft/shared/core/modules/user/userSelectors";
import { Color, Icon, Spacing, Text } from "@kraaft/ui";

interface LockedAnswersBannerProps {
  lockedBy: string | undefined;
  lockedAt: Date | undefined;
  mode: "singular" | "plural";
}

export const LockedAnswersBanner = ({
  lockedBy,
  lockedAt,
  mode,
}: LockedAnswersBannerProps) => {
  const actorDisplayName = useSelector(selectUsername(lockedBy));
  const { t } = useTranslation();

  const at = useMemo(
    () => ({
      date: lockedAt ? moment(lockedAt).format("L") : moment().format("L"),
      time: lockedAt ? moment(lockedAt).format("LT") : moment().format("LT"),
    }),
    [lockedAt],
  );

  return (
    <View style={styles.container}>
      <Icon name="lock-01" />
      <Text style={styles.text}>
        {t(
          mode === "plural"
            ? "modularRecords.lockMessagePlural"
            : "modularRecords.lockMessageSingular",
          {
            actor: actorDisplayName,
            date: at.date,
            time: at.time,
          },
        )}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: Spacing.S8,
    paddingHorizontal: Spacing.S16,
    gap: Spacing.S8,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Color.YELLOW_EARTH,
  },

  text: {
    flexShrink: 1,
  },
});
