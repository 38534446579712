import { Platform, StyleSheet } from "react-native";

import { Color, ColorStyle, FontSize } from "@kraaft/ui";

export const styles = StyleSheet.create({
  containerCenterContent: {
    flexGrow: 1,

    justifyContent: "center",
    alignItems: "center",

    backgroundColor: ColorStyle.ACTION_HOVERED,
  },

  initialsText: {
    fontFamily: "Apercu",
    textTransform: "uppercase",
    fontSize: FontSize.MEDIUM,
    textAlign: "center",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },

  image: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
  },

  emoji: {
    fontSize: Platform.select({ android: 22, default: 25 }),
    width: "100%",
    textAlign: "center",
    textAlignVertical: "center",
    ...Platform.select({
      native: { verticalAlign: "middle" },
      web: { textAlignVertical: "center" },
    }),
    color: Color.BLACK,
    ...Platform.select({
      ios: {
        marginLeft: 1,
      },
      android: {
        marginLeft: 1,
        marginBottom: 2,
      },
      web: {
        /**
         * with a font size of 26px
         * the emojis are centred
         * on retina and not retina screens.
         */
        fontSize: 26,
      },
    }),
  },
});
