import { useCallback } from "react";
import { NativeSyntheticEvent, TextInputKeyPressEventData } from "react-native";

import {
  MentionInputKeyPressHandler,
  UseMentionInputKeyPressHandlerProps,
} from "@kraaft/shared/components/textInputWithMentions/useMentionInputKeyPressHandler.props";

export function useMentionInputKeyPressHandler({
  onSubmit,
  textInputRef,
  mentionPaperRef,
}: UseMentionInputKeyPressHandlerProps): MentionInputKeyPressHandler {
  const onKeyPress = useCallback(
    (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      const keyboardEvent = event.nativeEvent as KeyboardEvent;

      if (
        mentionPaperRef.current?.handlesKey(
          keyboardEvent.key,
          keyboardEvent.shiftKey,
        )
      ) {
        keyboardEvent.preventDefault();
        return;
      }

      if (keyboardEvent.key === "Escape") {
        textInputRef.current?.blur();
      } else if (keyboardEvent.key === "Enter" && !keyboardEvent.shiftKey) {
        // This is deprecated, but the only way to prevent enter from occuring twice on ^ + Enter
        // Checking for undefined, as it is a deprecated feature, it may disappear from browsers
        // and we don't want to block the whole send with Enter feature if that happens
        if (
          keyboardEvent.keyCode !== 13 &&
          keyboardEvent.keyCode !== undefined
        ) {
          return;
        }
        keyboardEvent.preventDefault();
        setTimeout(() => textInputRef.current?.focus());
        onSubmit?.();
      }
    },
    [mentionPaperRef, onSubmit, textInputRef],
  );

  return onKeyPress;
}
