import OneSignal, {
  NotificationClickEvent,
  NotificationForegroundWillDisplayEvent,
} from "react-onesignal";
import { noop } from "ts-essentials";

import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { type Notifications } from "@kraaft/shared/core/modules/notifications/notifications";
import { executeAfterStatePropagation } from "@kraaft/ui";

export class OneSignalNotifications implements Notifications {
  private init: Promise<void> | undefined;

  async ensureInitialized() {
    if (this.init) {
      return this.init;
    }
    this.init = (async () => {
      await OneSignal.init({
        appId: getEnvironment().ONESIGNAL.APP_ID,
        allowLocalhostAsSecureOrigin: __DEV__,
        requiresUserPrivacyConsent: false,
        autoRegister: false,
        autoResubscribe: true,
      });
      await OneSignal.setConsentGiven(true);
      await OneSignal.setConsentRequired(false);
    })();
    return this.init;
  }

  async login(userId: string) {
    await this.ensureInitialized();
    await OneSignal.login(userId);
  }

  async logout() {
    await this.ensureInitialized();
    await OneSignal.logout();
  }

  async setLanguage(language: string) {
    await this.ensureInitialized();
    // Noop on web
  }

  addNotificationListener<T = any>(
    callback: (body: T, show: () => void, cancel: () => void) => void,
  ) {
    function cancel(event: NotificationForegroundWillDisplayEvent) {
      event.preventDefault();
    }

    function handler(event: NotificationForegroundWillDisplayEvent) {
      callback(event.notification.additionalData as T, noop, () =>
        cancel(event),
      );
    }
    OneSignal.Notifications.addEventListener("foregroundWillDisplay", handler);
    return () =>
      OneSignal.Notifications.removeEventListener(
        "foregroundWillDisplay",
        handler,
      );
  }

  addNotificationClickListener<T = any>(callback: (body: T) => void) {
    function handler(event: NotificationClickEvent) {
      callback(event.notification.additionalData as T);
    }

    OneSignal.Notifications.addEventListener("click", handler);
    return () => OneSignal.Notifications.removeEventListener("click", handler);
  }

  addPermissionChangeListener(callback: (allowed: boolean) => void) {
    OneSignal.Notifications.addEventListener("permissionChange", callback);

    executeAfterStatePropagation(() =>
      callback(OneSignal.Notifications.permission),
    );

    return () =>
      OneSignal.Notifications.removeEventListener("permissionChange", callback);
  }
}
