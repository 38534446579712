import { useMemo } from "react";
import { useSelector } from "react-redux";
import { compact } from "lodash";
import { Dictionary, StrictOmit } from "ts-essentials";

import { ExtraContextForPillRenderer } from "@kraaft/shared/components/modular/pill/utils/types";
import { ModularCardPills } from "@kraaft/shared/components/roomCard/modularCardPills";
import {
  selectCurrentPoolId,
  selectPoolCurrencyCode,
} from "@kraaft/shared/core/modules/pool/poolSelectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularRecordProperties } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { selectSchemaFirstColumnKeyOfType } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { selectUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import { User } from "@kraaft/shared/core/modules/user/userState";

interface Props {
  poolMembers: Dictionary<User, string>;
  currencyCode: string;
  schemaId: string;
  recordProperties: ModularRecordProperties;
}

const displayOptions = { displayUserAcronym: true, coloredPastDate: true };

export const ModularFolderCardPillsContainer = ({
  schemaId,
  recordProperties,
}: StrictOmit<Props, "poolMembers" | "currencyCode">) => {
  const users = useSelector(selectUsers);
  const currentPoolId = useSelector(selectCurrentPoolId);
  const currencyCode = useSelector(selectPoolCurrencyCode(currentPoolId));

  return (
    <ModularFolderCardPills
      schemaId={schemaId}
      recordProperties={recordProperties}
      poolMembers={users}
      currencyCode={currencyCode}
    />
  );
};

export const ModularFolderCardPills = (props: Props) => {
  const { poolMembers, schemaId, recordProperties, currencyCode } = props;

  const userColumnKey = useSelector(
    selectSchemaFirstColumnKeyOfType(schemaId, KColumnType.user),
  );
  const dateColumnKey = useSelector(
    selectSchemaFirstColumnKeyOfType(schemaId, KColumnType.date),
  );

  const extraContext = useMemo<ExtraContextForPillRenderer>(
    () => ({
      poolMembers,
      currencyCode,
    }),
    [currencyCode, poolMembers],
  );

  return (
    <ModularCardPills
      extraContext={extraContext}
      displayOptions={displayOptions}
      pillColumns={compact([userColumnKey, dateColumnKey])}
      recordProperties={recordProperties}
      schemaId={schemaId}
    />
  );
};
