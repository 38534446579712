import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize, PixelSize, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  titleContainer: {
    padding: Spacing.S16,
  },
  referenceContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: PixelSize.S16,
    paddingLeft: Spacing.S16,
    paddingRight: Spacing.S16,
  },
  nameContainer: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    padding: "15px 45px 15px 15px",
    borderRadius: Radius.SMALL,
  },
  nameWithActionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: PixelSize.S16,
  },
  title: {
    fontSize: FontSize.TITLE,
    fontWeight: "bold",
    marginRight: Spacing.S8,
  },
  subTitle: {
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  label: {
    fontSize: FontSize.BODY,
    marginRight: Spacing.S8,
  },
});
