import {
  EmojiObject,
  SkinTone,
  SkinToneVariant,
} from "@kraaft/shared/components/emojiSelector/emoji.types";

export const EMOJI_WIDTH = 40;

export function charFromUtf16(utf16: EmojiObject["unified"]) {
  return String.fromCodePoint(...utf16.split("-").map((u) => Number(`0x${u}`)));
}

export function charFromEmojiObject(
  emoji: EmojiObject,
  skinToneVariant: SkinToneVariant = "default",
) {
  const skinTone = getSkinTone(skinToneVariant);
  if (skinTone.alterCode) {
    const tonedEmojiObject = emoji.skin_variations?.[skinTone.alterCode];
    if (tonedEmojiObject !== undefined) {
      return charFromUtf16(tonedEmojiObject.unified);
    }
  }
  return charFromUtf16(emoji.unified);
}

export function evenlySpread(
  containerWidth: number,
  emojiWidth: number,
  minGap: number,
) {
  const countWithMinGapRaw = (containerWidth + minGap) / (emojiWidth + minGap);
  const countWithMinGap = Math.floor(countWithMinGapRaw);

  const numColumns = countWithMinGap;
  const gap = (containerWidth - numColumns * emojiWidth) / (numColumns - 1);

  return { numColumns, gap };
}

const SKIN_TONE_FACTORY = {
  default: {
    displayCode: "1F44D",
    alterCode: undefined,
  },
  light: {
    displayCode: "1F44D-1F3FB",
    alterCode: "1F3FB",
  },
  "medium-light": {
    displayCode: "1F44D-1F3FC",
    alterCode: "1F3FC",
  },
  medium: {
    displayCode: "1F44D-1F3FD",
    alterCode: "1F3FD",
  },
  "medium-dark": {
    displayCode: "1F44D-1F3FE",
    alterCode: "1F3FE",
  },
  dark: {
    displayCode: "1F44D-1F3FF",
    alterCode: "1F3FF",
  },
} satisfies {
  [K in SkinToneVariant]: SkinTone;
};

export function getSkinTone(skinToneVariant: SkinToneVariant) {
  return SKIN_TONE_FACTORY[skinToneVariant];
}
