import { DragObjectWithType } from "@kraaft/web/src/core/types/dnd";

export const FOLDER_ITEM_TYPE = "directory";

export interface DraggableDirectoryTreeItem extends DragObjectWithType {
  directory: Directory;
}

export interface Directory {
  id: string;
  name: string;
  parentDirectoryId: string | undefined;
  childDirectoryIds: string[];
  deepestChildDepth: number;
  depth: number;

  isTemporary?: boolean;
}

export type NormalizedDirectories = { [directoryId: string]: Directory };

export type DirectoryTreeStructure = {
  rootDirectoryIds: string[];
  directories: NormalizedDirectories;
};

export type HoveredBetweenDirectoryInfoPlacement = "before" | "after";

interface HoveredBetweenDirectoryInfo {
  type: "between";
  parentDirectoryId?: string;
  siblingDirectoryId: string;
  placement: HoveredBetweenDirectoryInfoPlacement;
}

interface HoveredOnDirectoryInfo {
  type: "on";
  parentDirectoryId: string;
}

export type HoveredDirectoryTreeInfo =
  | HoveredOnDirectoryInfo
  | HoveredBetweenDirectoryInfo;
