import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";
import { LibrarySchemaActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { KSchemaIcon } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { SchemaNameInput } from "@kraaft/web/src/components/schemaBuilder/tabs/editMetadata/schemaNameInput";

interface Props {
  open: boolean;
  onClose: () => void;
  language: LibrarySchemaLanguage;
}

const defaultIcon = "forms";

const AskLibrarySchemaNameAndIconDialog = ({
  open,
  onClose,
  language,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [icon, setIcon] = useState<KSchemaIcon>(defaultIcon);

  const onConfirm = useCallback(() => {
    dispatch(
      LibrarySchemaActions.addLibrarySchema({
        language,
        name,
        icon,
      }),
    );
    setName("");
    setIcon(defaultIcon);
    onClose();
  }, [dispatch, icon, language, name, onClose]);

  const actions = useMemo<FooterActions>(
    () => [
      {
        accessibilityLabel: t("confirm"),
        text: t("confirm"),
        onPress: onConfirm,
      },
      { accessibilityLabel: t("cancel"), text: t("cancel"), onPress: onClose },
    ],
    [onClose, onConfirm, t],
  );

  return (
    <KDialog
      title={t("schemaLibrary.configure.newLibrarySchemaDialog.label")}
      open={open}
      onClose={onClose}
      size={KDialogSize.SMALL}
      actions={actions}
      content={
        <SchemaNameInput
          name={name}
          icon={icon}
          onNameChange={setName}
          onIconChange={setIcon}
        />
      }
    />
  );
};

export { AskLibrarySchemaNameAndIconDialog };
