import { KeyOfEnum } from "@kraaft/shared/core/types";

export const Visibility = {
  MEMBER: "member",
  ALL: "all",
  SUPERADMIN: "superadmin",
} as const;
export type Visibility = KeyOfEnum<typeof Visibility>;

export interface RoomFilters {
  visibility: Visibility;
  statusId: string | undefined;
  labelIds: string[];
  responsibleId: string | undefined;
  showArchived: boolean;

  searchText: string | undefined;
}

export type BulkEditableRoomFilters = Omit<RoomFilters, "searchText">;

export type FilterState = { [poolId: string]: RoomFilters };
