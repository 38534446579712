import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { PhotoGallerySheet } from "@kraaft/shared/components/galleries/photoGallery/photoGalleryWithSelection/photoGallery.sheet";
import { PhotoGalleryWithSelection } from "@kraaft/shared/components/galleries/photoGallery/photoGalleryWithSelection/photoGalleryWithSelection";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { selectMiniImagesWithMapContext } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";
import { useMapNavigation } from "@kraaft/shared/core/utils/useMapNavigation";
import { DetailsRoute } from "@kraaft/web/src/components/detailsRoute";
import { detailsQuery } from "@kraaft/web/src/views/app/appRouter/routes";

type Props = {
  roomId: string;
  onBackButtonPress: () => void;
  isStickyPanel?: boolean;
  hideBackButton?: boolean;
};

const PhotoGalleryRoute = ({
  roomId,
  onBackButtonPress,
  isStickyPanel,
  hideBackButton,
}: Props) => {
  const { t } = useTranslation();

  const { isOpen: isMapOpen } = useMapNavigation();
  const medias = useSelector(selectMiniImagesWithMapContext(roomId, isMapOpen));
  const { title, handleClearSelection, headerCloseIcon, isSelecting } =
    useMediaSelectionContext({
      roomId,
      medias,
      type: "image",
    });

  const handleClose = useCallback(() => {
    handleClearSelection();
    if (!isSelecting) {
      onBackButtonPress();
    }
  }, [handleClearSelection, isSelecting, onBackButtonPress]);

  const { element: fullscreenPhotoGallery, open: enterFullScreen } =
    PhotoGallerySheet.use({
      medias,
      roomId,
    });

  return (
    <>
      <DetailsRoute
        headerId="ide2e-modularFolders-header"
        path={detailsQuery.photoGallery}
        title={title}
        hideBackButton={hideBackButton}
        headerRight={
          <HeaderSide>
            <HeaderIconButton
              accessibilityLabel={t("expand")}
              icon="maximize-01"
              onPress={enterFullScreen}
            />
          </HeaderSide>
        }
        backIcon={headerCloseIcon}
        onBackButtonPress={handleClose}
        isStickyPanel={isStickyPanel}
      >
        <PhotoGalleryWithSelection
          roomId={roomId}
          medias={medias}
          showMapButton
        />
      </DetailsRoute>
      {fullscreenPhotoGallery}
    </>
  );
};

export { PhotoGalleryRoute };
