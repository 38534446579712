import { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { Animated } from "react-native";

import { AnimationState } from "@kraaft/shared/core/types";

interface SimpleAnimatedGrowingBoxProps {
  minWidth: number;
  maxWidth: number;
  setAnimationState?: (animationState: AnimationState) => void;
  children: React.ReactNode;
  onAnimationStateChange?: (state: AnimationState) => void;
}

const ANIMATION_DURATION = 175;

export interface SimpleAnimatedGrowingBoxHandle {
  animateShrink: () => void;
  animateGrow: () => void;
}

const SimpleAnimatedGrowingBox = forwardRef<
  SimpleAnimatedGrowingBoxHandle,
  SimpleAnimatedGrowingBoxProps
>((props, ref) => {
  const { minWidth, maxWidth, setAnimationState, onAnimationStateChange } =
    props;

  useImperativeHandle(ref, () => ({
    animateGrow,
    animateShrink,
  }));

  const animatedWidth = useRef(new Animated.Value(0));

  const handleAnimationStateChange = useCallback(
    (state: AnimationState) => {
      setAnimationState?.(state);
      onAnimationStateChange?.(state);
    },
    [onAnimationStateChange, setAnimationState],
  );

  const animateGrow = useCallback(() => {
    handleAnimationStateChange("entering");
    Animated.timing(animatedWidth.current, {
      toValue: 1,
      duration: ANIMATION_DURATION,
      useNativeDriver: false,
    }).start(() => handleAnimationStateChange("entered"));
  }, [handleAnimationStateChange]);

  const animateShrink = useCallback(() => {
    handleAnimationStateChange("exiting");
    Animated.timing(animatedWidth.current, {
      toValue: 0,
      duration: ANIMATION_DURATION,
      useNativeDriver: false,
    }).start(() => handleAnimationStateChange("exited"));
  }, [handleAnimationStateChange]);

  const widthValue = animatedWidth.current.interpolate({
    inputRange: [0, 1],
    outputRange: [minWidth, maxWidth],
  });

  return (
    <Animated.View style={{ width: widthValue }}>
      {props.children}
    </Animated.View>
  );
});

export { SimpleAnimatedGrowingBox };
