import { httpsCallableUnprefixed } from "@kraaft/shared/core/services/firebase/firebaseUtils";

export const DummyApi = {
  create: async (payload: {
    requestId: string;
    name: string;
  }): Promise<{ id: string }> => {
    const result = await httpsCallableUnprefixed("dummy-createDummy")(payload);
    return result.data;
  },
  rename: async (payload: {
    requestId: string;
    id: string;
    name: string;
  }): Promise<{ updatedAt: Date }> => {
    const result = await httpsCallableUnprefixed("dummy-renameDummy")(payload);
    return { updatedAt: new Date(result.data.updatedAt) };
  },
  createDummyFileUploadPath: async (payload: {
    dummyId: string;
    attachments: Array<{ filename: string }>;
  }): Promise<Array<{ storagePath: string; uploadUrl: string }>> => {
    const result = await httpsCallableUnprefixed(
      "dummy-createDummyFileUploadPath",
    )(payload);
    return result.data;
  },
  attachFileDummy: async (payload: {
    requestId: string;
    dummyId: string;
    attachments: Array<{
      storagePath: string;
      filename: string;
    }>;
  }): Promise<{ updatedAt: Date }> => {
    const result = await httpsCallableUnprefixed("dummy-attachFileDummy")(
      payload,
    );
    return { updatedAt: new Date(result.data.updatedAt) };
  },
  renameMany: async (payload: {
    requestId: string;
    names: Array<{ id: string; name: string }>;
  }): Promise<{ updatedAt: Date }> => {
    const result = await httpsCallableUnprefixed("dummy-renameManyDummy")(
      payload,
    );
    return { updatedAt: new Date(result.data.updatedAt) };
  },
  delete: async (payload: {
    requestId: string;
    id: string;
  }): Promise<void> => {
    await httpsCallableUnprefixed("dummy-deleteDummy")(payload);
  },
};

Object.freeze(DummyApi);
