import { useEffect, useState } from "react";

import { firestore } from "@kraaft/shared/core/services/firestore/sdk";

export type DaxiumLinkDetails = {
  id: string;
  endpointId: string;
  forms: {
    id: string;
    name: string;
  }[];
};

export type DaxiumForm = DaxiumLinkDetails["forms"][number];

export function useDaxiumLinkDetails(
  poolId: string | undefined,
  skip?: boolean,
): DaxiumLinkDetails | undefined {
  const [details, setDetails] = useState<DaxiumLinkDetails>();
  useEffect(() => {
    if (!poolId || skip) {
      return;
    }
    return firestore()
      .collection("daxiumLinkDetails-1n")
      .doc(poolId)
      .onSnapshot((snapshot) => {
        setDetails(snapshot.data() as DaxiumLinkDetails | undefined);
      });
  }, [poolId, skip]);

  return details;
}
