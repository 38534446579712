import { StyleSheet, View } from "react-native";

import { Spacing, Text } from "@kraaft/ui";

interface ReactionLineProps {
  name: string;
  emoji: string;
}

export const ReactionLine = ({ name, emoji }: ReactionLineProps) => {
  return (
    <View style={styles.root}>
      <Text size="BODY" style={styles.name} numberOfLines={1}>
        {name}
      </Text>
      <Text size="BODY">{emoji}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingVertical: Spacing.S8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: Spacing.S16,
  },
  name: {
    flexShrink: 1,
  },
});
