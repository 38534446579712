import React from "react";
import { View } from "react-native";

import { Emoji } from "@kraaft/shared/components/emoji";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { IconSize, Text } from "@kraaft/ui";

import { styles } from "./preface.styles";

type PrefaceProps = React.PropsWithChildren<{
  translation: Parameters<typeof Trans>[0]["i18nKey"];
}>;

const Preface = ({ translation, children }: PrefaceProps) => (
  <View style={styles.container}>
    <View style={styles.content}>
      <View style={styles.textContainer}>
        <Emoji name="waving_hand" size={IconSize.LARGE} />
        <Text size="BODY" style={styles.text}>
          <Trans
            i18nKey={translation}
            components={{
              bold: <Text weight="medium" size="BODY" color="ACTION_CTA" />,
            }}
          />
        </Text>
      </View>
      {children}
    </View>
  </View>
);

export { Preface };
