import { makeStyles } from "@mui/styles";

export const makeMenuContainerStyle = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    boxSizing: "border-box",
  },
  messageContentWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    boxSizing: "border-box",
  },
  contextMenuButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "0px 24px",
  },
  spacer: {
    flexGrow: 1,
    flexBasis: 1,
  },
  optionContainerOwn: {
    marginLeft: 4,
  },
  optionContainerReply: {
    marginRight: 4,
  },
});
