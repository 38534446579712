import { makeStyles } from "@mui/styles";

const DIRECTORY_INDENTATION = 20;

interface MakeStylesProps {
  level: number;
}

export const useStyles = makeStyles({
  directoryIndentation: {
    height: 10,
    width: ({ level }: MakeStylesProps) =>
      level > 0 ? DIRECTORY_INDENTATION * (level - 1) : 0,
  },
});
