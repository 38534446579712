export const SUPPORT_URL = "https://help.eu.kraaft.co";
export const WHATS_NEW_URL = "https://eu.intercom.news/kraaft";

export const API_VERBOSE = false;
export const FIRESTORE_VERBOSE = false;
export const NAVIGATION_VERBOSE = false;
export const ANALYTICS_VERBOSE = false;
export const ASYNC_STORAGE_VERBOSE = false;
export const VERSION_VERBOSE = false;
export const DATADOG_VERBOSE = false;
export const LAUNCH_DARKLY_VERBOSE = false;
export const BACKGROUND_SERVICE_VERBOSE = false;
export const CLONE_DEEP_VERBOSE = false;
export const FILE_ALLOCATOR_REGISTRY_VERBOSE = false;

export const ENABLE_REACT_QUERY_DEVTOOLS = false;
