import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  selectFolderSchema,
  selectRoomSchema,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { selectSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewSelectors";
import { ScreenParams } from "@kraaft/shared/core/services/navigation/navigationParams";
import { ConnectedConversationViewer } from "@kraaft/web/src/components/conversationViewer/conversationViewer.connected";

import { ConnectedModularFoldersViewer } from "./modularFoldersViewer.connected";

const SchemaViewViewer = () => {
  const { schemaViewId } = useParams<ScreenParams<"SchemaView">>();

  const poolId = useSelector(selectCurrentPoolId);
  const schemaView = useSelector(selectSchemaView(schemaViewId));
  const schema = useSelector(selectFolderSchema(schemaView?.schemaId));

  const roomSchema = useSelector(selectRoomSchema(poolId));

  if (schemaView && roomSchema && schemaView.schemaId === roomSchema.id) {
    return (
      <ConnectedConversationViewer
        roomSchema={roomSchema}
        schemaViewId={schemaViewId}
      />
    );
  }
  if (schema && schemaView?.schemaId === schema.id) {
    return (
      <ConnectedModularFoldersViewer
        schema={schema}
        schemaViewId={schemaViewId}
      />
    );
  }
  return null;
};

export { SchemaViewViewer };
