import isEqual from "fast-deep-equal";
import {
  actionChannel,
  call,
  put,
  select,
  take,
  takeEvery,
} from "typed-redux-saga/macro";

import { isNative } from "@kraaft/helper-functions";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { fileUpload } from "@kraaft/shared/core/modules/file/fileUploader";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import {
  selectCurrentPool,
  selectCurrentPoolLocation,
  selectPoolLocation,
  selectPoolLocations,
  selectPools,
  selectPoolState,
} from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  PoolLocation,
  PoolRoleType,
} from "@kraaft/shared/core/modules/pool/poolState";
import {
  canDetermineCurrentPool,
  isAtLeastPoolStandard,
} from "@kraaft/shared/core/modules/pool/poolUtil";
import { subscribeToPoolSaga } from "@kraaft/shared/core/modules/pool/sagas/subscribeToPool";
import { switchPoolSaga } from "@kraaft/shared/core/modules/pool/sagas/switchPool";
import { subscribeToUserUnreadPoolsSaga } from "@kraaft/shared/core/modules/pool/sagas/userUnreadPools";
import {
  UserActions,
  UserStateActions,
} from "@kraaft/shared/core/modules/user/userActions";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { isUserSuperadmin } from "@kraaft/shared/core/modules/user/userUtils";
import { analytics } from "@kraaft/shared/core/services/analytics";
import { Api } from "@kraaft/shared/core/services/api";
import { dateFormatter } from "@kraaft/shared/core/services/dateFormatter/dateFormatter.provider";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { setMomentLocale } from "@kraaft/shared/core/services/i18next/momentLocale";
import { navigationService } from "@kraaft/shared/core/services/navigation/provider";
import { numberFormatter } from "@kraaft/shared/core/services/numberFormatter";
import { RootState } from "@kraaft/shared/core/store";
import { wait } from "@kraaft/shared/core/utils/promiseUtils";
import { waitFor } from "@kraaft/shared/core/utils/sagas";
import { getBasename, getExtension } from "@kraaft/shared/core/utils/utils";

import {
  PoolActions,
  PoolStateActions,
  setLogo,
  setPoolById,
  setPoolByName,
  startOnPool,
  subscribeToPool,
  updateLocations,
  uploadLogo,
} from "./poolActions";

export function* poolSagas() {
  yield* takeEvery(UserActions.userConnectedToFirebase, watchCurrentUserPools);
  yield* takeEvery(PoolStateActions.setPoolLocation, changePoolAnalyticsSaga);
  yield* takeEvery(
    [PoolStateActions.setPoolLocation, PoolStateActions.setPools],
    updatePoolLanguageSaga,
  );
  yield* takeEvery(PoolActions.receivePools, receivePoolsSaga);
  yield* takeEvery(setPoolByName, setPoolByNameSaga);
  yield* takeEvery(setPoolById, setPoolByIdSaga);
  yield* takeEvery(subscribeToPool, subscribeToPoolSaga);
  yield* takeEvery([updateLocations, startOnPool], onLocationUpdatedSaga);
  yield* takeEvery(uploadLogo, uploadLogoSaga);
  yield* takeEvery(
    UserActions.userConnectedToFirebase,
    subscribeToUserUnreadPoolsSaga,
  );

  yield* takeEvery(PoolActions.switchPool, switchPoolSaga);
}

function* watchCurrentUserPools() {
  const channel = yield* actionChannel([UserStateActions.loggedUserReceived]);

  yield* takeEvery(channel, updateLocationsSaga);

  yield* take(UserActions.userDisconnectedFromFirebase);

  channel.close();
}

function* updateLocationsSaga() {
  const currentUser = yield* select(selectCurrentUser);

  if (!canDetermineCurrentPool(currentUser)) {
    return;
  }

  const candidateLocations = Object.entries(currentUser?.pools || {})
    .sort(
      ([_id1, userPool1], [_is2, userPool2]) =>
        userPool1.joinedAt.getTime() - userPool2.joinedAt.getTime(),
    )
    .map(([id, userPool]) => ({
      roleType: isAtLeastPoolStandard(userPool.role)
        ? PoolRoleType.AT_LEAST_STANDARD
        : PoolRoleType.EXTERNAL,
      poolId: id,
    }));

  const userPoolIds = candidateLocations.map((location) => location.poolId);

  yield* put(updateLocations(candidateLocations));

  yield* loadPools(userPoolIds);
}

function* isCurrentPoolLocation(poolLocation: PoolLocation) {
  const currentLocation = yield* select(selectCurrentPoolLocation);
  if (!currentLocation) {
    return false;
  }
  return isEqual(poolLocation, currentLocation);
}

function* moveToPoolLocation(poolLocation: PoolLocation) {
  const isAlreadyThere = yield* isCurrentPoolLocation(poolLocation);
  if (!isAlreadyThere) {
    yield* put(PoolActions.switchPool(poolLocation));
  }
  yield* loadPools([poolLocation.poolId]);
}

/**
 * @behavior wont move if we can't determine a default pool
 */
function* moveToDefaultPoolLocation() {
  const locations = yield* select(selectPoolLocations);
  const defaultPoolLocation = locations?.[0];
  if (!defaultPoolLocation) {
    return;
  }
  yield* moveToPoolLocation(defaultPoolLocation);
}

function* onLocationUpdatedSaga() {
  const pool = yield* select(selectPoolState);

  if (!pool || !pool.locations) {
    return;
  }

  const startPoolId = pool.startPoolId;
  if (startPoolId) {
    let startPoolLocation = yield* select(selectPoolLocation(startPoolId));
    if (!startPoolLocation) {
      startPoolLocation = createPoolLocation(
        PoolRoleType.EXTERNAL,
        startPoolId,
      );
    }

    const canGoToStartPool = yield* isAuthorizedLocation(startPoolLocation);
    if (canGoToStartPool) {
      yield* moveToPoolLocation(startPoolLocation);
    } else {
      yield* moveToDefaultPoolLocation();
    }

    yield* put(startOnPool({ poolId: undefined }));
    return;
  }

  if (!pool.currentLocation) {
    yield* moveToDefaultPoolLocation();
    return;
  }

  const canStayHere = yield* isAuthorizedLocation(pool.currentLocation);
  if (pool.currentLocation && !canStayHere) {
    yield* moveToDefaultPoolLocation();
    return;
  }
}

function* updatePoolLanguageSaga() {
  const pool = yield* select(selectCurrentPool);

  if (pool) {
    setMomentLocale(pool.poolLanguage);
    numberFormatter.setLocale(pool.poolLanguage);
    dateFormatter.setLocale(pool.poolLanguage);
    yield i18n.changeLanguage(pool.poolLanguage);
  }
}

function* receivePoolsSaga({
  payload,
}: ReturnType<typeof PoolActions.receivePools>) {
  yield* put(PoolStateActions.setPools(payload.pools));
}

function* changePoolAnalyticsSaga({
  payload,
}: ReturnType<typeof PoolStateActions.setPoolLocation>) {
  const { poolId } = payload;

  yield waitFor(
    (state: RootState) =>
      state.pool.pools[poolId] !== undefined && state.user.currentUser?.pools,
  );
  const pools = yield* select(selectPools);
  const currentUser = yield* select(selectCurrentUser);

  const pool = pools[poolId];
  const userPool = currentUser?.pools?.[poolId];

  if (pool && userPool) {
    yield analytics.setTrackContextCurrentPool({
      id: poolId,
      name: pool.name,
      role: userPool.role,
    });
  }
}

// Only superadmin ; roleType is PoolRoleType.AT_LEAST_STANDARD
function* setPoolByNameSaga({ payload }: ReturnType<typeof setPoolByName>) {
  const { poolName } = payload;

  const pool: Pool | undefined = yield Firestore.getPoolByName(poolName);
  if (pool) {
    yield* put(PoolActions.receivePools({ pools: [pool] }));
    yield* put(
      PoolActions.switchPool({
        roleType: PoolRoleType.AT_LEAST_STANDARD,
        poolId: pool.id,
      }),
    );
  } else {
    // eslint-disable-next-line no-alert
    alert(`cannot find pool with name ${poolName}`);
  }
}

// Only admin ; roleType is PoolRoleType.AT_LEAST_STANDARD
function* setPoolByIdSaga({ payload }: ReturnType<typeof setPoolById>) {
  const { poolId } = payload;

  const pool: Pool | undefined = yield Firestore.getPool(poolId);
  if (pool) {
    yield* put(PoolActions.receivePools({ pools: [pool] }));
    yield* put(
      PoolActions.switchPool({
        roleType: PoolRoleType.AT_LEAST_STANDARD,
        poolId: pool.id,
      }),
    );
    navigationService.navigate("ManagePool");
  } else {
    // eslint-disable-next-line no-alert
    alert(`cannot find pool with id ${poolId}`);
  }
}

function* isAuthorizedLocation(targetLocation: PoolLocation) {
  const currentUser = yield* select(selectCurrentUser);
  const locations = yield* select(selectPoolLocations);

  if (!locations) {
    return false;
  }

  if (
    !isNative() &&
    isUserSuperadmin(currentUser) &&
    navigationService.isOnSuperadminPage()
  ) {
    return true;
  }

  return locations.some((location) => isEqual(location, targetLocation));
}

function createPoolLocation(
  roleType: PoolRoleType,
  poolId: string,
): PoolLocation {
  return { roleType, poolId };
}

const loadingPools = new Set<string>();
function* loadPools(poolIds: string[]) {
  const { pools } = yield* select(selectPoolState);
  const missingPoolIds = poolIds.filter(
    (poolId) => pools[poolId] === undefined && !loadingPools.has(poolId),
  );

  for (const id of missingPoolIds) {
    loadingPools.add(id);
  }
  try {
    if (missingPoolIds.length > 0) {
      let loadedPools = yield* call(() => Firestore.getPools(missingPoolIds));

      // DIRTY FIX. just after creating own pool, sometimes we don't get the pool. Retry 3 times at 1 second interval.
      for (let i = 0; i < 3; i++) {
        if (loadedPools.length < missingPoolIds.length) {
          yield wait(1000);
          loadedPools = yield* call(() => Firestore.getPools(missingPoolIds));
        }
      }

      yield* put(PoolActions.receivePools({ pools: loadedPools }));
    }
  } catch (excep) {
    console.log("currentUserPoolsReceivedSaga", excep);
  } finally {
    for (const id of missingPoolIds) {
      loadingPools.delete(id);
    }
  }
}

function* uploadLogoSaga(action: ReturnType<typeof uploadLogo>) {
  const { file, poolId } = action.payload;

  try {
    if (poolId && file) {
      const storagePath: string = yield (async () => {
        const storagePathsPayload = {
          poolId,
          filename: "",
        };

        let filename = file.filename;
        // hack to accept jfif files. We change the extension to jpg
        if (getExtension(filename) === "jfif") {
          filename = `${getBasename(filename)}.jpg`;
        }
        storagePathsPayload.filename = filename;

        const uploadPath =
          await Api.createPoolLogoUploadPath(storagePathsPayload);

        await fileUpload.upload({
          file: file,
          ...uploadPath,
        });

        return uploadPath.storagePath;
      })();

      const downloadUrl = yield* call(Api.updatePoolLogo, {
        storagePath,
        poolId,
      });
      yield* put(setLogo({ logo: downloadUrl, poolId }));
    }
  } catch (error) {
    yield* put(
      showError({
        title: i18n.t("uploadLogoErrorMessage"),
        message: error.message,
      }),
    );
  }
}
