import { useCallback, useRef } from "react";
import { useDrag } from "react-dnd";
import clsx from "clsx";

import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { isOSKeyModifierPressed } from "@kraaft/shared/core/utils";
import { Icon } from "@kraaft/ui";
import { KHeaderRendererProps } from "@kraaft/web/src/components/modularTable/components/types";

import { DroppableColumn } from "./draggableColumnHeader";

import { useStyles } from "./columnHeader.styles";

const COLUMN_DRAG_TYPE = "COLUMN_DRAG";

const ColumnHeader = ({
  column,
  onSort,
  sortDirection,
}: KHeaderRendererProps) => {
  const classes = useStyles({ sortDirection });

  const menuRef = useRef<HTMLDivElement>(null);
  const columnDragType = useRef(
    `${COLUMN_DRAG_TYPE}_${column.tableIdentifier}`,
  ).current;

  // Empty useDrag => Global Drag Listener
  const [{ hasCurrentlyDraggedColumn }] = useDrag({
    type: columnDragType,
    item: { type: columnDragType },
    isDragging: (monitor) => {
      const item: DroppableColumn | undefined = monitor.getItem();

      return item?.tableIdentifier === column.tableIdentifier;
    },
    collect: (monitor) => {
      const item: DroppableColumn | null = monitor.getItem();
      return {
        hasCurrentlyDraggedColumn: monitor.isDragging(),
        currentlyDraggedColumnIndex: item?.columnIndex,
      };
    },
  });

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      onSort(isOSKeyModifierPressed(event));
    },
    [onSort],
  );

  const { section } = column;
  const prefix = section.key !== KSchemaUtils.rootSectionKey && section.name;

  const columnHeader = (
    <>
      <div
        ref={menuRef}
        className={clsx(
          classes.headerContainer,
          sortDirection && classes.sortedHeaderContainer,
        )}
      >
        <div className={classes.text} onClick={handleClick}>
          <Icon
            name={KSchemaUtils.getIcon(column)}
            className={classes.headerIcon}
            size="SMALL"
            color="red"
          />
          <div className={classes.prefix}>{prefix}</div>
          <div>
            {prefix && "\u00A0→ "}
            {column.name}
          </div>
        </div>
        {!hasCurrentlyDraggedColumn && (
          <div className={clsx("resizeHandle", classes.resizeHandle)} />
        )}
      </div>
    </>
  );

  // If we ever want to rearrange columns but not modify the schema
  // We'll need this
  // if (!column.nonDraggable) {
  //   return (
  //     <DraggableColumnHeader
  //       column={column}
  //       onColumnsReorder={column.onReorder}
  //       columnDragType={columnDragType}
  //       tableIdentifier={column.tableIdentifier}
  //       hasCurrentlyDraggedColumn={hasCurrentlyDraggedColumn}
  //       currentlyDraggedColumnIndex={currentlyDraggedColumnIndex}>
  //       {columnHeader}
  //     </DraggableColumnHeader>
  //   );
  // }
  return columnHeader;
};

export { ColumnHeader };
