import { DimensionValue, StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { nativeStylesObject } from "@kraaft/shared/components/kDialog/kDialog.styles.native";

export const styles = StyleSheet.create({
  ...nativeStylesObject,
  frameContainer: {
    ...nativeStylesObject.frameContainer,
    height: "100%",
    maxHeight: isNative() ? undefined : ("inherit" as DimensionValue),
  },
});
