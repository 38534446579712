import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaColumnDefinition,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaColors } from "@kraaft/shared/core/modules/schema/schema.colors";
import { ResourceKey, TFunction } from "@kraaft/shared/core/services/i18next";
import { uuid } from "@kraaft/shared/core/utils";
import {
  FactoryElementWithType,
  getFactoryElement,
} from "@kraaft/shared/core/utils/factoryUtils";

// eslint-disable-next-line complexity
export function getModularColumnTypeIconBackgroundColor(type: KColumnType) {
  switch (type) {
    case KColumnType.selectSingle:
    case KColumnType.selectMultiple:
      return "#2A9786";
    case KColumnType.date:
    case KColumnType.automatedCreatedAt:
      return "#8E9D25";
    case KColumnType.user:
    case KColumnType.automatedCreatedBy:
      return "#CF7D24";
    case KColumnType.checkbox:
      return "#AD5976";
    case KColumnType.geolocation:
    case KColumnType.attachment:
    case KColumnType.signature:
      return "#6E529E";
    default:
      return "#4180AE";
  }
}

export const folderFormBuilderColumns = [
  KColumnType.shortText,
  KColumnType.longText,
  KColumnType.number,
  KColumnType.currency,
  KColumnType.selectSingle,
  KColumnType.selectMultiple,
  KColumnType.date,
  KColumnType.automatedCreatedAt,
  KColumnType.user,
  KColumnType.automatedCreatedBy,
  KColumnType.checkbox,
  KColumnType.geolocation,
  KColumnType.attachment,
  KColumnType.signature,
];

export const roomFormBuilderColumns = [
  KColumnType.shortText,
  KColumnType.longText,
  KColumnType.number,
  KColumnType.currency,
  KColumnType.selectSingle,
  KColumnType.selectMultiple,
  KColumnType.date,
  KColumnType.automatedCreatedAt,
  KColumnType.user,
  KColumnType.automatedCreatedBy,
  KColumnType.checkbox,
  KColumnType.geolocation,
];

const columnDefFactory: {
  [T in KColumnType]: Omit<KSchemaColumnDefinition<T>, "type">;
} = {
  shortText: {},
  automatedAutoIncrement: { prefix: "" },
  longText: {},
  currency: {},
  number: {},
  selectSingle: { options: {} },
  selectMultiple: {
    options: {},
  },
  user: {},
  date: { displayTime: false },
  geolocation: {},
  checkbox: { mode: "completion" },
  attachment: { mode: "gallery" },
  roomName: { property: "name" },
  roomMembers: { property: "members" },
  automatedCreatedAt: {
    displayTime: true,
  },
  automatedCreatedBy: { automation: { behavior: "createdBy" } },
  signature: {},
  join: { collection: "rooms" },
};

const translations: Record<
  Exclude<
    KColumnType,
    KColumnType.roomMembers | KColumnType.roomName | KColumnType.join
  >,
  ResourceKey
> = {
  [KColumnType.selectSingle]: "uniqueChoice",
  [KColumnType.selectMultiple]: "multipleChoice",
  [KColumnType.date]: "date",
  [KColumnType.number]: "number",
  [KColumnType.geolocation]: "geolocationChoice",
  [KColumnType.checkbox]: "checkbox",
  [KColumnType.attachment]: "attachmentChoice",
  [KColumnType.longText]: "longText",
  [KColumnType.shortText]: "shortText",
  [KColumnType.automatedAutoIncrement]: "automatedAutoIcrement",
  [KColumnType.user]: "userChoice",
  [KColumnType.currency]: "currency",
  [KColumnType.signature]: "signature.word",
  [KColumnType.automatedCreatedAt]: "automatedCreatedAt",
  [KColumnType.automatedCreatedBy]: "automatedCreatedBy",
};

export function getTableColumnTypeLabel(t: TFunction, type: KColumnType) {
  if (
    type === KColumnType.roomMembers ||
    type === KColumnType.roomName ||
    type === KColumnType.join
  ) {
    return "";
  }
  return t(translations[type]);
}

export function getDefaultColumnDefinitionValue<T extends KColumnType>(
  columnType: T,
): NonNullable<KSchemaColumnDefinition<T>> {
  const columnDef: FactoryElementWithType<typeof columnDefFactory, T> =
    getFactoryElement(columnDefFactory, columnType);

  return { ...columnDef, type: columnType };
}

export function getDefaultColumnValue<T extends KColumnType>(
  name: string,
  columnType: T,
): KSchemaColumn<T> {
  const unique = uuid();

  return {
    ...getDefaultColumnDefinitionValue(columnType),
    elementType: "column",
    type: columnType,
    index: 0,
    key: unique,
    reportKey: unique,
    name,
  } as KSchemaColumn<T>;
}

export function getDefaultSectionValue(name: string): KSchemaSection {
  const unique = uuid();

  return {
    elementType: "section",
    elements: {},
    name,
    color: KSchemaColors.DefaultSectionColor,
    key: unique,
    reportKey: unique,
    index: 0,
  };
}
