import { useKeyPressEvent } from "react-use";

import { RoomLocationLayout } from "@kraaft/shared/components/createRoom/roomLocation/roomLocation.layout";

import { useStyles } from "./roomLocation.styles";

interface RoomMembersProps {
  roomId: string;
  onNext: () => void;
}

const RoomLocation = (props: RoomMembersProps) => {
  const { roomId, onNext } = props;

  const classes = useStyles();

  useKeyPressEvent("Enter", () => onNext());

  return (
    <div className={classes.container}>
      <RoomLocationLayout roomId={roomId} onNext={onNext} />
    </div>
  );
};

export { RoomLocation };
