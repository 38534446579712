import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useDispatch } from "react-redux";

import { useWorkspaceNameInput } from "@kraaft/shared/components/createWorkspace/useWorkspaceNameInput";
import { useCreateOwnWorkspace } from "@kraaft/shared/core/modules/pool/hooks/useCreateOwnWorkspace.hook";
import { PoolActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { PoolRoleType } from "@kraaft/shared/core/modules/pool/poolState";
import { useDeviceLanguage } from "@kraaft/shared/core/services/i18next/languageDetector/useLanguage.hook";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { getDeviceTimeZone } from "@kraaft/shared/core/utils";
import { Button, Text } from "@kraaft/ui";

import { styles } from "./createWorkspace.styles";

const CreateWorkspace = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = useDeviceLanguage();
  const navigationService = useNavigationService();
  const timeZone = getDeviceTimeZone();
  const [name, setName] = useState("");

  const [create, { loading: createLoading, poolId }] = useCreateOwnWorkspace(
    name,
    language,
    timeZone,
  );
  const [WorkspaceNameInput, { isError, isLoading, isTaken }] =
    useWorkspaceNameInput(name, setName, create);

  useEffect(() => {
    if (poolId) {
      dispatch(
        PoolActions.switchPool({
          poolId: poolId,
          roleType: PoolRoleType.AT_LEAST_STANDARD,
        }),
      );
    }
  }, [poolId, dispatch, navigationService]);

  return (
    <View style={styles.container}>
      <Text
        weight="bold"
        size="H1"
        color="FONT_HIGH_EMPHASIS"
        style={styles.title}
      >
        {t("newWorkspace.title")}
      </Text>
      <Text size="BODY" color="FONT_LOW_EMPHASIS" style={styles.subtitle}>
        {t("newWorkspace.helper")}
      </Text>
      <View style={styles.inputContainer}>{WorkspaceNameInput}</View>
      <View style={styles.buttonSpacer} />
      <Button
        accessibilityLabel={t("newWorkspace.createButton")}
        text={t("newWorkspace.createButton")}
        loading={createLoading}
        disabled={!!isError || isLoading || isTaken}
        onPress={create}
      />
    </View>
  );
};

export { CreateWorkspace };
