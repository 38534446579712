import { eventChannel } from "redux-saga";
import { put, takeEvery } from "typed-redux-saga/macro";

import { CarouselStateActions } from "@kraaft/shared/core/modules/carousel/carousel.actions";
import { notifications } from "@kraaft/shared/core/modules/notifications/notifications.provider";

export function* closeCarouselOnNotificationClickSaga() {
  const channel = eventChannel((emit) =>
    notifications.addNotificationClickListener(emit),
  );
  yield* takeEvery(channel, closeCarousel);
}

function* closeCarousel() {
  yield* put(CarouselStateActions.close());
}
