import { spawn, takeEvery } from "typed-redux-saga/macro";

import { openMiniMediaSaga } from "@kraaft/shared/core/modules/miniMedia/sagas/openMiniMedia";
import { subscribeToMiniMediasSaga } from "@kraaft/shared/core/modules/miniMedia/sagas/subscribeToMiniMedias";

import { MiniMediaActions } from "./miniMedia.actions";

export function* miniMediaSagas() {
  yield* spawn(subscribeToMiniMediasSaga);

  yield* takeEvery(MiniMediaActions.openMiniMedia, openMiniMediaSaga);
}
