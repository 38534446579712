import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(100vh - 300px)", // @HACK, limit the size of content, otherwise it is scrollable
    minHeight: 200,
  },
});

export const inlineStyles = {
  list: {
    flex: 1,
  },
};

export { useStyles };
