import { StyleSheet } from "react-native";

import { Color, Radius, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  backdrop: {
    position: "absolute",
    backgroundColor: Color.TRANSPARENT,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: Spacing.S16,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  box: {
    padding: Spacing.S16,
    backgroundColor: Color.WHITE,
    borderRadius: Radius.MEDIUM,
    flex: 1,
    maxWidth: 365,
    gap: Spacing.S8,
  },
  buttonContainer: {
    flexDirection: "row",
  },
});
