import { useCallback } from "react";
import clsx from "clsx";

import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Icon } from "@kraaft/ui";
import { KHeaderRendererProps } from "@kraaft/web/src/components/modularTable/components/types";

import { useStyles } from "./columnHeader.styles";

const SimpleColumnHeader = ({
  column,
  onSort,
  sortDirection,
}: KHeaderRendererProps) => {
  const classes = useStyles({ sortDirection });

  const handleClick = useCallback(() => {
    onSort(false);
  }, [onSort]);

  const { section } = column;
  const prefix = section.key !== KSchemaUtils.rootSectionKey && section.name;

  return (
    <div
      className={clsx(
        classes.headerContainer,
        sortDirection && classes.sortedHeaderContainer,
      )}
      onClick={handleClick}
    >
      <div className={classes.textContainer}>
        <Icon
          className={classes.headerIcon}
          name={KSchemaUtils.getIcon(column)}
          size="SMALL"
          color="FONT_LOW_EMPHASIS"
        />
        <div className={classes.text}>
          <div className={classes.prefix}>{prefix}</div>
          <div>
            {prefix && "\u00A0→ "}
            {column.name}
          </div>
        </div>
      </div>
      <div className={classes.resizeHandle} />
    </div>
  );
};

export { SimpleColumnHeader };
