import { call, put, spawn, takeEvery } from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import {
  ModularFolderActions,
  ModularFolderStateActions,
} from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

import { subscribeToPoolModularFoldersSaga } from "./sagas/subscribeToPoolModularFoldersSaga";
import { subscribeToRoomModularFoldersSaga } from "./sagas/subscribeToRoomModularFoldersSaga";

export enum SuccessRate {
  TOTAL = "total",
  PARTIAL = "partial",
  NONE = "none",
}

export function* modularFolderSagas() {
  yield* spawn(subscribeToPoolModularFoldersSaga);
  yield* spawn(subscribeToRoomModularFoldersSaga);

  yield* takeEvery(
    ModularFolderActions.exportToExcel,
    exportModularFolderToExcelSaga,
  );
}

function* exportModularFolderToExcelSaga(
  action: ReturnType<typeof ModularFolderActions.exportToExcel>,
) {
  const { meta } = action;
  const { schema, filterList } = action.payload;

  try {
    const { downloadUrl, filename } = yield* call(
      Api.exportXlsFromModularFolders,
      {
        schemaId: schema.id,
        filters: filterList,
      },
    );

    yield fileSaver.download(downloadUrl, filename);
    yield* put(showSuccess({ title: i18n.t("downloadReportSuccessTitle") }));
    yield* put(ModularFolderStateActions.exportToExcelSuccess(meta));
  } catch (error) {
    yield* put(showError({ title: i18n.t("couldNotDownloadFile") }));
    yield* put(ModularFolderStateActions.exportToExcelFailure(error, meta));
  }
}
