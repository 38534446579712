import { EventChannel, eventChannel } from "redux-saga";
import { call, put, take, takeEvery } from "typed-redux-saga/macro";

import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { receiveSchemaViews } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { SchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewState";
import { Firestore } from "@kraaft/shared/core/services/firestore";

function createSchemaViewsChannel(
  poolId: string,
): EventChannel<{ poolId: string; schemaViews: SchemaView[] }> {
  return eventChannel((emit) =>
    Firestore.subscribeToSchemaViews(poolId, (schemaViews) =>
      emit({ poolId, schemaViews }),
    ),
  );
}

function* handleReceiveSchemaViews(snapshot: {
  poolId: string;
  schemaViews: SchemaView[];
}) {
  yield* put(receiveSchemaViews(snapshot.schemaViews));
}

export function* subscribeToSchemaViews({
  payload,
}: ReturnType<typeof PoolStateActions.setPoolLocation>) {
  const channel = yield* call(createSchemaViewsChannel, payload.poolId);

  yield* takeEvery(channel, handleReceiveSchemaViews);

  yield* take(PoolStateActions.setPoolLocation);

  channel.close();
}
