import { Dictionary } from "ts-essentials";

// everything can be logged to analytics, it automatically clean the params
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnalyticsParams = any;

const AnalyticsUtils = {
  cleanParams: (params: AnalyticsParams): Dictionary<string> => {
    const safeParams: Dictionary<string> = {};
    for (const key in params) {
      const value = params[key];
      if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean"
      ) {
        safeParams[key] = String(value);
      } else if (value === null) {
        safeParams[key] = "NULL";
      } else if (value === undefined) {
        safeParams[key] = "UNDEFINED";
      } else {
        console.warn("cannot log to analytics:", value);
        safeParams[key] = "INVALID_VALUE";
      }
    }
    return safeParams;
  },
};

Object.freeze(AnalyticsUtils);
export { AnalyticsUtils };
