import { useMemo } from "react";
import { makeStyles } from "@mui/styles";

import { useMeshContext } from "@kraaft/helper-hooks";
import { SECTION_LOCK_INFO_FALLBACK } from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularDisplayExtendedRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";
import { KFormatterProps } from "@kraaft/web/src/components/modularTable/components/types";

export function withRoomMemberFormatterCell(
  BasicCellComponent: React.FC<IBasicCellProps<KColumnType.roomMembers>>,
): React.FC<KFormatterProps<KColumnType.roomMembers>> {
  return (props: KFormatterProps<KColumnType.roomMembers>) => {
    const { getRoomFromRecordId } = useMeshContext(
      ModularDisplayExtendedRequirementsContext,
    );

    const value = useMemo(() => {
      return Object.keys(getRoomFromRecordId(props.row.id)?.members ?? {});
    }, [getRoomFromRecordId, props.row.id]);

    const sectionLockInfo = useMemo(
      () =>
        props.column.columnContext?.getSectionLockInfo?.(props.row.properties),
      [props.column.columnContext, props.row],
    );

    const styles = useStyles();

    return (
      <div
        data-locked={sectionLockInfo?.isLocked}
        className={styles.cellContainer}
      >
        <BasicCellComponent
          row={props.row}
          column={props.column}
          value={value}
          isCellSelected={props.isCellSelected}
          isLoading={false}
          onRowChange={props.onRowChange}
          sectionLockInfo={sectionLockInfo ?? SECTION_LOCK_INFO_FALLBACK}
        />
      </div>
    );
  };
}

const useStyles = makeStyles({
  cellContainer: {
    flex: 1,
  },
});
