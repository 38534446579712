import { useSelector } from "react-redux";

import { selectCurrentPoolLanguage } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { getCurrencySymbolFromCode } from "@kraaft/web/src/views/settings/managePool/settingsCurrency/useAvailableCurrencies.utils";

export function useAvailableCurrencies() {
  const poolLanguage = useSelector(selectCurrentPoolLanguage) ?? "fr-FR";

  const available = Intl.supportedValuesOf("currency");
  const getDisplayName = new Intl.DisplayNames(poolLanguage, {
    type: "currency",
  });

  return available.map((currencyCode) => ({
    value: currencyCode,
    label: `${getCurrencySymbolFromCode(
      poolLanguage,
      currencyCode,
    )} - ${getDisplayName.of(currencyCode)}`,
  }));
}
