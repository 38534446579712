import { StyleSheet } from "react-native";

import { ColorStyle, FontSize, Radius, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  root: {
    padding: Spacing.S12,
    borderRadius: Radius.SMALL,
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: FontSize.BODY,
    marginLeft: Spacing.S8,
    color: ColorStyle.ACTION_CTA,
    userSelect: "none",
  },
});
