import { BackgroundService } from "@kraaft/shared/core/services/backgroundService/backgroundService";
import { backgroundServiceLogger } from "@kraaft/shared/core/services/backgroundService/backgroundService.logger";
import { TaskResult } from "@kraaft/shared/core/services/backgroundService/offlineTaskManager";

/**
 * Web implementation of BackgroundService
 * This should not be used at all.
 */
export class NoopBackgroundService implements BackgroundService {
  static logger = backgroundServiceLogger.createSubLogger(["instance"]);

  public async executeAllTask(): Promise<TaskResult> {
    NoopBackgroundService.logger.log("Not implemented");
    return "error";
  }

  public async stop(): Promise<boolean> {
    NoopBackgroundService.logger.log("Not implemented");
    return false;
  }

  public async registerBackgroundFetch(): Promise<void> {
    NoopBackgroundService.logger.log("Not implemented");
    return;
  }
}
