import {
  FilePath,
  LocalPath,
  ModernFile,
} from "@kraaft/shared/core/modules/file/file";

export abstract class FileHelper {
  abstract addFileToFormData(
    file: ModernFile<LocalPath>,
    formData: FormData,
    as?: string,
  ): Promise<void>;
  abstract fromWebFile(file: File): ModernFile<LocalPath>;
  abstract convertToBase64(
    filepath: FilePath,
  ): Promise<string | ArrayBuffer | null>;
}
