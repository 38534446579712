import { DependencyList, useCallback } from "react";

export const useHandleChangeSingleSelectHelper = <T extends string = string>(
  callback: (selected: T | undefined) => void,
  deps: DependencyList,
) =>
  useCallback((newValue: string[] | undefined) => {
    const [selected] = newValue ?? [];

    callback(selected as T);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
