import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { selectOfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageSelectors";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

export function useSignoutAlert() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const offlineMessage = useSelector(selectOfflineMessage);
  const hasMessagesQueued = offlineMessage.queue.length > 0;

  const alertSignOut = useCallback(() => {
    async function signOut() {
      dispatch(UserActions.disconnectUserCommand());
    }

    const message = hasMessagesQueued
      ? t("signOutMessageQueuedMessages")
      : t("signOutMessage");
    const title = t("menuDisconnect");

    newConfirmationAlert({
      onConfirm: signOut,
      confirmText: t("signOut"),
      title: isNative() ? message : title,
      message: isNative() ? undefined : message,
    });
  }, [hasMessagesQueued, t, dispatch]);
  return alertSignOut;
}
