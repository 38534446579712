import React, { useCallback, useMemo } from "react";
import {
  Pressable,
  PressableStateCallbackType,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";
import isEqual from "fast-deep-equal";

import { getContrastColorFromColor } from "@kraaft/shared/core/utils/colorsUtils";
import { ColorStyle, Icon, IconName, IconSize, Text } from "@kraaft/ui";

import { styles } from "./pill.styles";

export type PillProps = {
  content?: string | number | null;
  icon?: IconName;
  iconColor?: string;
  color?: string;
  textColor?: string;
  onPress?: () => void;
  withSpacer?: boolean;
  withEmptyStyle?: boolean;
  withButtonStyle?: boolean;
  size?: "small" | "medium";
  style?: ViewStyle;
};

const Pill_ = ({
  content,
  icon,
  iconColor,
  color,
  textColor,
  onPress,
  withSpacer = false,
  withEmptyStyle = false,
  withButtonStyle = false,
  size = "medium",
  style,
}: PillProps) => {
  const backgroundColor = color ?? ColorStyle.BACKGROUND_STANDARD;
  const contentColor = withEmptyStyle
    ? ColorStyle.FONT_LOW_EMPHASIS
    : textColor ?? getContrastColorFromColor(backgroundColor);

  const disabled = onPress === undefined;

  const pillStyle = useMemo(() => {
    const baseStyle: StyleProp<ViewStyle> = [
      styles.basePill,
      { backgroundColor },
      style,
    ];

    const pillStyleFactory = {
      small: styles.pillSmall,
      medium: styles.pillMedium,
    } satisfies Record<NonNullable<PillProps["size"]>, ViewStyle>;
    baseStyle.push(pillStyleFactory[size]);

    if (icon) {
      const pillWithIconStyleFactory = {
        small: styles.pillWithIconSmall,
        medium: styles.pillWithIconMedium,
      } satisfies Record<NonNullable<PillProps["size"]>, ViewStyle>;
      baseStyle.push(pillWithIconStyleFactory[size]);
    }

    if (withSpacer) {
      baseStyle.push(styles.pillSpacer);
    }

    if (withEmptyStyle) {
      baseStyle.push(styles.pillEmpty);
    }
    if (withButtonStyle) {
      baseStyle.push(styles.pillButton);
    }
    return baseStyle;
  }, [
    backgroundColor,
    icon,
    size,
    style,
    withButtonStyle,
    withEmptyStyle,
    withSpacer,
  ]);

  const textStyle = useMemo(() => {
    const baseStyle: StyleProp<TextStyle> = [{ color: contentColor }];

    const textStyleFactory = {
      small: styles.textSmall,
      medium: styles.textMedium,
    } satisfies Record<NonNullable<PillProps["size"]>, TextStyle>;
    baseStyle.push(textStyleFactory[size]);

    if (icon) {
      const textWithIconStyleFactory = {
        small: styles.textWithIconSmall,
        medium: styles.textWithIconMedium,
      } satisfies Record<NonNullable<PillProps["size"]>, TextStyle>;
      baseStyle.push(textWithIconStyleFactory[size]);
    }

    if (withEmptyStyle) {
      baseStyle.push(styles.textEmpty);
    }
    return baseStyle;
  }, [contentColor, icon, size, withEmptyStyle]);

  const title = content ? `${content}` : "--";

  const iconSize = useMemo(() => {
    const iconSizeFactory = {
      small: IconSize.MINI,
      medium: IconSize.SMALL,
    } satisfies Record<NonNullable<PillProps["size"]>, number>;
    return iconSizeFactory[size];
  }, [size]);

  const getPressableStyle = useCallback(
    (state: PressableStateCallbackType) => [
      pillStyle,
      state.pressed && styles.pillContainerPressed,
    ],
    [pillStyle],
  );

  return (
    <Pressable
      accessibilityLabel={title}
      onPress={onPress}
      disabled={disabled}
      style={getPressableStyle}
    >
      {icon ? (
        <Icon name={icon} size={iconSize} color={iconColor ?? contentColor} />
      ) : null}
      <View style={styles.pillContent}>
        <Text numberOfLines={1} style={textStyle}>
          {title}
        </Text>
      </View>
    </Pressable>
  );
};

const Pill = React.memo(Pill_, isEqual) as typeof Pill_;

export { Pill };
