import { ConditionLibraryForType } from "@kraaft/shared/core/generated/__generated/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";

export const geolocationCondition: ConditionLibraryForType<KColumnType.geolocation> =
  {
    geolocation: {
      is: {
        translationKey: "is",
        check: (lhs, rhs) => lhs.value === rhs.value,
      },
    },
  };
