import { ReactNode, useContext, useMemo, useRef } from "react";
import { PopoverOrigin } from "@mui/material";

import {
  createModalContext,
  withModalFrame,
} from "@kraaft/shared/components/modalContext";
import { uuid } from "@kraaft/shared/core/utils";
import { PopupMenu } from "@kraaft/web/src/components/popupMenu/popupMenu";

interface PopupMenuProps {
  anchorRef?: React.RefObject<Element>;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  withAnchorWidth?: boolean;
}
const PopupMenuContext = createModalContext<PopupMenuProps>("PopupMenu");

export const PopupMenuProvider = withModalFrame(PopupMenu, PopupMenuContext);

export function usePopupMenu() {
  const { open, close } = useContext(PopupMenuContext);
  const key = useRef(uuid());

  return useMemo(
    () => ({
      open: (element: ReactNode, options: PopupMenuProps) => {
        open(key.current, element, options);
      },
      close: () => close(key.current),
    }),
    [open, close],
  );
}
