import { createReducer } from "@reduxjs/toolkit";
import { AnyAction } from "redux";

import {
  Loader,
  LoaderMeta,
  LoaderStatus,
} from "@kraaft/shared/core/modules/loaders/loaderTypes";

import * as actions from "./loaderActions";

interface LoaderAction extends AnyAction {
  meta: LoaderMeta;
}

interface LoaderState {
  [id: string]: Loader;
}

const isLoaderAction = (action: AnyAction): action is LoaderAction =>
  !!action?.meta?.loader?.id;

const initialState: LoaderState = {};

export const loaderReducers = createReducer(
  initialState,
  ({ addCase, addMatcher }) => {
    addCase(actions.clearLoader, (state, { payload }) => {
      delete state[payload.id];
    });
    addMatcher(isLoaderAction, (state, action) => {
      const {
        meta: { loader },
      } = action;

      const existingLoader = state[loader.id];

      let loadingCounter = existingLoader?.loadingCounter ?? 0;

      if (
        loader.status === LoaderStatus.SUCCESS ||
        loader.status === LoaderStatus.FAILURE
      ) {
        loadingCounter--;
      } else {
        loadingCounter++;
      }

      state[loader.id] = { ...existingLoader, ...loader, loadingCounter };
    });
  },
);
