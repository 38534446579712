import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { LegacyActionSheet } from "@kraaft/shared/components/legacyActionSheet";
import { AutoSaveInfoText } from "@kraaft/shared/components/modularFolders/modularFolderDetails/autoSaveInfoText";
import { useModularFolderContextMenu } from "@kraaft/shared/components/modularFolders/modularFolderDetails/modularFolderDetailsMenu/useModularFolderContextMenu";
import { NotFullSynchronizedModularFolderSheet } from "@kraaft/shared/core/modules/modularFolder/hooks/notFullSynchronizedModularFolderSheet";
import { selectModularFolderPendingOperations } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { ColorStyle } from "@kraaft/ui";

export interface ModularFolderDetailsMenuProps {
  roomId: string;
  schemaId: string;
  modularFolderId: string;
}

const ModularFolderDetailsMenu = (props: ModularFolderDetailsMenuProps) => {
  const { roomId, schemaId, modularFolderId } = props;
  const { t } = useTranslation();

  const menuButtonRef = useRef(null);
  const [isMenuOpen, openMenu, closeMenu] = useBooleanState();

  const { ReportTemplateSelection, sheetOptions } = useModularFolderContextMenu(
    {
      schemaId,
      modularFolderId,
      roomId,
      anchorRef: menuButtonRef,
    },
  );

  const pendingOperationCount = useSelector(
    selectModularFolderPendingOperations(modularFolderId),
  );

  const { open, element } = NotFullSynchronizedModularFolderSheet.use({
    modularFolderId,
    modularFolderPendingOperations: pendingOperationCount,
    titleSynchronized: t("offline.modularFolder.synchronized"),
    descriptionSynchronized: t("offline.modularFolder.synchronizedContent"),
    titleNotSynchronized: t("offline.modularFolder.notSynchronized", {
      count: pendingOperationCount,
    }),
    descriptionNotSynchronized: t("offline.modularFolder.pendingOperation", {
      count: pendingOperationCount,
    }),
    cta: null,
  });

  const header = useMemo(() => <AutoSaveInfoText />, []);

  return (
    <>
      {pendingOperationCount > 0 ? (
        <HeaderIconButton
          accessibilityLabel={t("menu")}
          ref={menuButtonRef}
          icon="clock"
          contentColor={ColorStyle.FONT_LOW_EMPHASIS}
          onPress={open}
        />
      ) : null}
      <HeaderIconButton
        accessibilityLabel={t("menu")}
        ref={menuButtonRef}
        icon="dots-vertical"
        onPress={openMenu}
      />
      <LegacyActionSheet
        anchorRef={menuButtonRef}
        open={isMenuOpen}
        options={sheetOptions}
        onClose={closeMenu}
        header={header}
      />
      {element}
      {ReportTemplateSelection}
    </>
  );
};

export { ModularFolderDetailsMenu };
