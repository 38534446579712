import { useTranslation } from "react-i18next";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { Color, Radius, Spacing, Text } from "@kraaft/ui";

interface SchemaLibraryBannerProps {
  companyName: string;
  style?: StyleProp<ViewStyle>;
}

export const SchemaLibraryBanner = ({
  companyName,
  style,
}: SchemaLibraryBannerProps) => {
  const { t } = useTranslation();

  return (
    <View style={[styles.banner, style]}>
      <Text size="TITLE" weight="bold" style={styles.bannerTitle}>
        {t("schemaLibrary.companyLibrary")}
      </Text>
      <Text>
        {t("schemaLibrary.companyLibraryDescription", {
          companyName: companyName,
        })}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  banner: {
    borderColor: Color.GREY_ANTIFLASH,
    borderWidth: 1,
    borderRadius: Radius.LARGE,
    paddingVertical: Spacing.S16,
    paddingHorizontal: Spacing.S24,
  },
  bannerTitle: {
    marginBottom: Spacing.S8,
  },
});
