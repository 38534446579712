import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StrictOmit } from "ts-essentials";

import { SelectOption } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/select";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaColors } from "@kraaft/shared/core/modules/schema/schema.colors";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { ColumnHeaderEditorProps } from "@kraaft/web/src/components/modularTable/components/types";
import { OrderedListRows } from "@kraaft/web/src/components/orderableList/orderableList.types";
import { prepareForOrderableList } from "@kraaft/web/src/components/orderableList/orderableList.utils";
import { ColoredOrderableOption } from "@kraaft/web/src/components/sortableListWithAddAndDelete/coloredOrderableOptionRenderer/coloredOrderableOption";
import {
  ColoredOrderableOptionRenderer,
  ColoredOrderableOptionRendererProps,
} from "@kraaft/web/src/components/sortableListWithAddAndDelete/coloredOrderableOptionRenderer/coloredOrderableOptionRenderer";
import { SortableListWithAddAndDelete } from "@kraaft/web/src/components/sortableListWithAddAndDelete/sortableListWithAddAndDelete";

import { DeleteOptionDialog } from "./deleteOptionDialog";
import { usePromiseAndCallbacks } from "./usePromiseAndCallbacks";

import { useStyles } from "./selectColumnEditor.styles";

const SelectColumnEditor = (
  props:
    | ColumnHeaderEditorProps<KColumnType.selectSingle>
    | ColumnHeaderEditorProps<KColumnType.selectMultiple>,
) => {
  const { columnDefinition, setColumnDefinition, isColumnDeletionInstant } =
    props;
  const [open, openDialog, closeDialog] = useBooleanState();
  const [optionToDelete, setOptionToDelete] = useState("");

  const { callbacks, newPromise } = usePromiseAndCallbacks<boolean>();

  const { t } = useTranslation();
  const styles = useStyles();

  const options = useMemo(
    () =>
      columnDefinition
        ? prepareForOrderableList<
            [string, SelectOption],
            ColoredOrderableOption
          >(Object.entries(columnDefinition.options), ([key, option]) => ({
            key,
            index: option.index,
            data: {
              id: key,
              text: option.label,
              color: option.color,
            },
          }))
        : {},
    [columnDefinition],
  );

  const handleChange = useCallback(
    (newOptions: OrderedListRows<ColoredOrderableOption>) => {
      const columnOptions: KSchemaColumn<
        KColumnType.selectSingle | KColumnType.selectMultiple
      >["options"] = {};

      for (const option of Object.values(newOptions)) {
        columnOptions[option.key] = {
          index: option.index,
          label: option.data.text,
          color: option.data.color,
        };
      }
      if (!columnDefinition) {
        return;
      }
      (
        setColumnDefinition as ColumnHeaderEditorProps<
          KColumnType.selectSingle | KColumnType.selectMultiple
        >["setColumnDefinition"]
      )({ ...columnDefinition, options: columnOptions });
    },
    [columnDefinition, setColumnDefinition],
  );

  const handleDeleteConfirmation = useCallback(
    async (option: ColoredOrderableOption) => {
      if (isColumnDeletionInstant) {
        return true;
      }
      setOptionToDelete(option.text);
      openDialog();
      return newPromise();
    },
    [isColumnDeletionInstant, newPromise, openDialog],
  );

  const handleClose = () => {
    closeDialog();
    callbacks.current.resolve(false);
  };

  const handleDelete = () => {
    callbacks.current.resolve(true);
  };

  const handleCreate = useCallback(
    (id: string, index: number) => ({
      id,
      text: t("formBuilder.schema.columnSpecific.select.newOption", {
        index,
      }),
      color: KSchemaColors.Colors[index % KSchemaColors.Colors.length],
    }),
    [t],
  );

  const renderer = useCallback(
    (
      rendererProps: StrictOmit<
        ColoredOrderableOptionRendererProps,
        "allowColor"
      >,
    ) => (
      <ColoredOrderableOptionRenderer
        allowColor={columnDefinition?.allowColor}
        {...rendererProps}
      />
    ),
    [columnDefinition?.allowColor],
  );

  return (
    <div className={styles.root}>
      <SortableListWithAddAndDelete
        noBackground
        id="create-select-sortable"
        addButtonText={t("addOption")}
        options={options}
        onChange={handleChange}
        onDeleteConfirmation={handleDeleteConfirmation}
        RowRenderer={renderer}
        onCreate={handleCreate}
      />
      <DeleteOptionDialog
        open={open}
        onClose={handleClose}
        onDelete={handleDelete}
        option={optionToDelete}
      />
    </div>
  );
};

export { SelectColumnEditor };
