import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet } from "react-native";

import { useOpenItinerary } from "@kraaft/shared/components/geolocationOpener/useItinerary";
import { UseOpenItineraryParams } from "@kraaft/shared/components/geolocationOpener/useItinerary.types";
import { GeoLocation } from "@kraaft/shared/core/types";

export type GeolocationOpenerProps = {
  children: React.ReactNode;
  geolocation: GeoLocation;
  context: UseOpenItineraryParams["context"];
  onLongPress?: () => void;
  testID?: string;
};

export const GeolocationOpener = ({
  children,
  geolocation: { coords },
  context,
  testID,
}: GeolocationOpenerProps) => {
  const { t } = useTranslation();

  const { openItinerary } = useOpenItinerary({ coords, context });

  return (
    <Pressable
      accessibilityLabel={t("openMap")}
      nativeID={testID}
      onPress={openItinerary}
      style={styles.container}
    >
      {children}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexShrink: 1,
  },
});
