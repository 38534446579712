import { Fade } from "@mui/material";
import clsx from "clsx";

import { KSchemaColors } from "@kraaft/shared/core/modules/schema/schema.colors";
import { Color, Icon } from "@kraaft/ui";

import { useStyles } from "./colorPicker.styles";

interface Props {
  direction: string;
  handleChange: (newValue: string) => void;
  value: string | undefined;
}

export const ColorPickerContent = ({
  direction,
  handleChange,
  value,
}: Props) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.vertical]: direction === "vertical",
      })}
    >
      {KSchemaColors.Colors.map((color, index) => (
        <div
          data-testid={`ide2e-color-picker-${index}`}
          key={color}
          className={clsx(classes.color, classes.margin)}
          style={{ backgroundColor: color }}
          onClick={() => handleChange(color)}
        >
          {value?.toUpperCase() === color.toUpperCase() && (
            <Fade in>
              <Icon
                name="triangle-down-fill"
                size="MINI"
                color={value === Color.WHITE ? "FONT_LOW_EMPHASIS" : "WHITE"}
              />
            </Fade>
          )}
        </div>
      ))}
    </div>
  );
};
