import { makeStyles } from "@mui/styles";

interface Props {
  grow: boolean;
}

export const useStyles = makeStyles({
  flex: ({ grow }: Props) => ({
    flexGrow: grow ? 1 : 0,
    overflow: "hidden",
  }),

  withAnimation: {
    animation: "$opacitySkeleton",
    animationDuration: "1s",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "linear",
  },
  "@keyframes opacitySkeleton": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.2,
    },
  },
});
