import { UserJob } from "@kraaft/shared/components/onboarding/askUserJob/userJob";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";

export interface PoolMemberState {
  fetched: boolean;
  members: PoolMembers;
}

export type PoolMembers = {
  [userId: string]: PoolMember;
};

export interface PoolMember {
  uid: string;
  username: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  role: UserPoolRole | undefined;
  totalRoomCount?: number;
  poolRoomCount?: number;
  poolCount?: number;
  job?: UserJob;
  ssoProviders:
    | {
        name: string;
        providerId: string;
        ssoId: string;
        email?: string;
      }[]
    | undefined;
  pricing: {
    computed: "chargeable" | "offered";
    scheme: "chargeable" | "offered" | "automatic";
  };
}

export function isPoolMemberPaying(poolMember: PoolMember) {
  if (poolMember.pricing.scheme === "automatic") {
    return poolMember.pricing.computed === "chargeable";
  }
  return poolMember.pricing.scheme === "chargeable";
}
