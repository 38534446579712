import { noop } from "ts-essentials";

import { KCodeInputProps } from "@kraaft/shared/components/input/countryCodeInput/types";
import { KInput } from "@kraaft/shared/components/input/KInput";

const KCodeInput = (props: KCodeInputProps) => {
  const { onChangeText, value, ...otherProps } = props;

  return (
    <KInput
      value={value}
      onChangeText={onChangeText ?? noop}
      showSoftInputOnFocus={false}
      {...otherProps}
    />
  );
};

export { KCodeInput };
