import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import {
  Message,
  MessageSendingStatus,
  UserMessage,
} from "@kraaft/shared/core/modules/message/messageState";
import {
  isPersisted,
  sortMessagesFn,
} from "@kraaft/shared/core/modules/message/messageUtils";
import { RootState } from "@kraaft/shared/core/store";
import { lodashKeyResolver } from "@kraaft/shared/core/utils";
import { LinkedList } from "@kraaft/shared/core/utils/useBidirectional/createLinkedLists";

export const selectMessageData = (state: RootState) => state.messageData;

const EMPTY_MESSAGES: Record<string, Message> = {};
export const selectMessageDataForRoom = memoize((roomId: string) =>
  createSelector(
    selectMessageData,
    (data) => data.messages[roomId] ?? EMPTY_MESSAGES,
  ),
);

const EMPTY_OPTIMISTIC_MESSAGES: Message[] = [];

const optimisticMessageSortPriority: Record<MessageSendingStatus, number> = {
  persisted: 0,
  sending: 1,
  optimistic: 2,
  error: 3,
};

export const selectRoomOptimisticMessages = memoize((roomId: string) =>
  createSelector(selectMessageDataForRoom(roomId), (roomMessages) => {
    if (!roomMessages) {
      return EMPTY_OPTIMISTIC_MESSAGES;
    }
    return Object.values(roomMessages)
      .filter((message): message is UserMessage => !isPersisted(message))
      .sort((a, b) => {
        const priorityDiff =
          optimisticMessageSortPriority[b.sendingStatus] -
          optimisticMessageSortPriority[a.sendingStatus];
        if (priorityDiff !== 0) {
          return priorityDiff;
        }
        return sortMessagesFn(a, b);
      });
  }),
);

export const selectMessageInRoom = memoize(
  (roomId: string, messageId: string) =>
    createSelector(
      selectMessageDataForRoom(roomId),
      (roomMessages) => roomMessages?.[messageId],
    ),
  lodashKeyResolver,
);

export const selectAllMessageLinkedLists = ({ messageData }: RootState) =>
  messageData.linkedLists;
export const selectAllMessageDocs = ({ messageData }: RootState) =>
  messageData.messageDocs;
export const selectAllMessagesIO = ({ messageData }: RootState) =>
  messageData.messageIO;

const EMPTY_LINKED_LIST: LinkedList = {};
export const selectMessageLinkedList = memoize((roomId: string) =>
  createSelector(
    selectAllMessageLinkedLists,
    (linkedLists) => linkedLists[roomId] ?? EMPTY_LINKED_LIST,
  ),
);

const EMPTY_MESSAGE_IO = { received: 0, sent: 0 };

export const selectMessagesReceived = memoize((roomId: string) =>
  createSelector(
    selectAllMessagesIO,
    (received) => received[roomId] ?? EMPTY_MESSAGE_IO,
  ),
);
