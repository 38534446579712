import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { keyBy } from "lodash";

import { SchemaStateActions } from "@kraaft/shared/core/modules/schema/schema.actions";
import { SchemaOptimisticReducer } from "@kraaft/shared/core/modules/schema/schema.optimistic";
import { SchemaState } from "@kraaft/shared/core/modules/schema/schema.state";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { mergeInPlace } from "@kraaft/shared/core/store/mergeInPlace";
import { AnyUnexplained } from "@kraaft/shared/core/types";

const initialState: SchemaState = {
  schemas: {},
  areSchemasLoadedByPoolId: {},
  createdColumnKey: undefined,
};

const schemaReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);

  addCase(SchemaStateActions.set, (state, { payload }) => {
    const schemaDict = keyBy(payload.data, (schema) => schema.id);
    mergeInPlace(state.schemas, schemaDict);
  });

  addCase(SchemaStateActions.setCreatedKey, (state, { payload }) => {
    state.createdColumnKey = payload;
  });

  // Optimistic
  addCase(SchemaStateActions.optimistic.deleteSchema, (state, { payload }) => {
    delete state.schemas[payload.id];
  });

  addCase(
    SchemaStateActions.setSchemasLoadedForPoolId,
    (state, { payload }) => {
      state.areSchemasLoadedByPoolId[payload.poolId] = true;
    },
  );

  addCase(
    SchemaStateActions.optimistic.reorderSchemas,
    (state, { payload }) => {
      for (const { schemaId, index } of payload) {
        const schema = state.schemas[schemaId];

        if (schema?.collection === "folder") {
          schema.index = index;
        }
      }
    },
  );
});

const OptimisticReducer = SchemaOptimisticReducer as AnyUnexplained;
const reducer = combineReducers({
  schemas: schemaReducers,
  optimistic: OptimisticReducer,
});

export { reducer as schemaReducers };
