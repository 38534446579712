import { StyleSheet, View } from "react-native";

import { FileRowFactory } from "@kraaft/shared/components/filesList/fileRowFactory";
import { FileRowRenderingProps } from "@kraaft/shared/components/filesList/filesList.props";
import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { ColorStyle, IconSize, Preloader, Spacing } from "@kraaft/ui";

type FilesListProps = FileRowRenderingProps & {
  attachments: Attachment[];
  isLoading: boolean;
};

const FilesList = ({
  attachments,
  isLoading,
  ...fileRowRenderingProps
}: FilesListProps) => {
  return (
    <View style={styles.container}>
      {attachments.map((attachment, index) => (
        <FileRowFactory
          key={attachment.id}
          attachment={attachment}
          index={index}
          context={fileRowRenderingProps.context}
          allowDeletion={fileRowRenderingProps.allowDeletion}
          onDelete={fileRowRenderingProps.onDelete}
          onAttachmentClick={fileRowRenderingProps.onAttachmentClick}
        />
      ))}
      {isLoading && (
        <Preloader color={ColorStyle.ACTION_CTA} size={IconSize.MEDIUM} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    gap: Spacing.S8,
  },
  baseThumbnail: {
    width: 21,
    height: 28,
    marginRight: Spacing.S8,
    borderRadius: 2,
  },
  imageThumbnail: {
    resizeMode: "cover",
  },
  documentThumbnail: {
    justifyContent: "center",
    alignItems: "center",
  },
});

export { FilesList };
