import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DialogContentText } from "@mui/material";

import { KDialog } from "@kraaft/shared/components/kDialog";
import {
  KDialogConfig,
  KDialogSize,
} from "@kraaft/shared/components/kDialog/kDialogProps";
import { Preloader } from "@kraaft/ui";
import {
  InviteLinkInput,
  InviteLinkInputHandle,
} from "@kraaft/web/src/components/inviteLinkInput";
import { makeDialogStyle } from "@kraaft/web/src/components/kDialog/customs/inviteDialogLayout/inviteDialogLayout.styles";

export type InviteDialogLayoutProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  text: string;
  link: string | null;
  inputLabel: string;
};

const InviteDialogLayout = (props: InviteDialogLayoutProps) => {
  const { t } = useTranslation();
  const classes = makeDialogStyle();

  const inputLinkRef = useRef<InviteLinkInputHandle>(null);
  const { link, open, text, title, inputLabel, onClose } = props;

  const copyToClipboard = useCallback(() => {
    inputLinkRef.current?.copyToClipboard();
  }, []);

  const renderContent = () => (
    <div className={classes.dialogContentContainer}>
      <DialogContentText classes={{ root: classes.text }}>
        {text}
      </DialogContentText>
      {link !== null ? (
        <InviteLinkInput label={inputLabel} link={link} ref={inputLinkRef} />
      ) : (
        // eslint-disable-next-line no-inline-styles/no-inline-styles
        <Preloader style={{ height: 55 }} />
      )}
    </div>
  );

  const dialog: KDialogConfig = {
    title: title,
    content: renderContent(),
    size: KDialogSize.MEDIUM,
    actions: [
      {
        accessibilityLabel: t("copy"),
        text: t("copy"),
        onPress: copyToClipboard,
        autoFocus: true,
        variant: "PRIMARY",
      },
      {
        accessibilityLabel: t("close"),
        text: t("close"),
        onPress: onClose,
      },
    ],
  };

  return <KDialog open={open} onClose={onClose} {...dialog} />;
};

export { InviteDialogLayout };
