import { put, takeEvery } from "typed-redux-saga/macro";

import {
  UserActions,
  UserStateActions,
} from "@kraaft/shared/core/modules/user/userActions";

export function* homeEmptyStateSaga() {
  yield* takeEvery(UserActions.workspaceCreated, workspaceCreatedSaga);

  yield* takeEvery(UserActions.acknowledgeHomeEmptyState, acknowledgeSaga);
}

function* workspaceCreatedSaga() {
  yield* put(UserStateActions.setMayShowHomeEmptyState(true));
}

function* acknowledgeSaga() {
  yield* put(UserStateActions.setMayShowHomeEmptyState(false));
}
