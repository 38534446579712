import { ChangeEvent, useCallback } from "react";
import { Tab as MaterialTab, Tabs as MaterialTabs } from "@mui/material";

import { Color, FontSize, Icon, IconName, Spacing } from "@kraaft/ui";

export interface KNavBarTabProps {
  value: string;
  label: string;
  id: string;
  icon?: IconName;
}

interface KNavBarProps {
  tabs: KNavBarTabProps[];
  value: string;
  onChange: (value: string) => void;
}

const TABS_SX = { minHeight: 0 };

const KNavBar = (props: KNavBarProps) => {
  const { onChange, tabs, value } = props;

  const handleChange = useCallback(
    (_e: ChangeEvent<unknown>, newValue: string) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <MaterialTabs value={value} onChange={handleChange} sx={TABS_SX}>
      {tabs.map((tab) => (
        <Tab
          icon={tab.icon}
          id={tab.id}
          key={tab.value}
          label={tab.label}
          value={tab.value}
        />
      ))}
    </MaterialTabs>
  );
};

const TAB_SX = {
  textTransform: "none",
  fontWeight: 400,
  fontSize: FontSize.BODY,
  flexDirection: "row",
  minHeight: 0,
  gap: `${Spacing.S4}px`,
  color: Color.GREY_TAUPE,
};

const Tab = (props: KNavBarTabProps) => {
  const { icon, ...otherProps } = props;

  return (
    <MaterialTab
      disableRipple
      icon={icon && <Icon name={icon} size="MINI" />}
      sx={TAB_SX}
      {...otherProps}
    />
  );
};

export { KNavBar };
