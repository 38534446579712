import { StyleSheet } from "react-native";
import { makeStyles } from "@mui/styles";

import { Color, ColorStyle } from "@kraaft/ui";
import { DRAWER_WIDTH } from "@kraaft/web/src/views/messenger/messenger.styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100%",
  },
  conversationPanelContainer: {
    display: "flex",
    flexDirection: "column",

    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    height: "100%",
  },
  conversationHeaderContainer: {
    height: 49,
    padding: "0 16px",
    borderBottomWidth: 1,
    borderBottomColor: ColorStyle.SEPARATOR,
    borderBottomStyle: "solid",
    backgroundColor: Color.WHITE,
    display: "flex",
    alignItems: "center",
  },
  detailsContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: DRAWER_WIDTH,
    borderLeft: `1px solid ${ColorStyle.SEPARATOR}`,
  },
});

export const sizerStyle = StyleSheet.create({
  root: {
    flexGrow: 1,
    height: 0,
    width: "100%",
  },
});
