import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { trackGenerateConversationReport } from "@kraaft/shared/components/report/trackingUtils";
import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { updateExtractEmail } from "@kraaft/shared/core/modules/app/appActions";
import { setLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { selectLoading } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import {
  selectRoomIsExternal,
  selectRoomMembersCount,
} from "@kraaft/shared/core/modules/room/roomSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { RoomReportFiltersJson } from "@kraaft/shared/core/services/api/api";
import { useEnsureOnline } from "@kraaft/shared/core/utils/useNetwork";

import { getReportTemplateName } from "../../core/modules/reportTemplate/reportTemplate.utils";

export function useSendRoomReportCallback(
  roomId: string,
  filters: RoomReportFiltersJson,
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const downloadLoadingId = `sendingRoomReport-${roomId}`;
  const isSendingRoomReport = useSelector(selectLoading(downloadLoadingId));

  const roomMemberCount = useSelector(selectRoomMembersCount(roomId));
  const isExternal = useSelector(selectRoomIsExternal(roomId));

  const sendRoomReport = useEnsureOnline(
    async (email: string, reportTemplate: ReportTemplate) => {
      trackGenerateConversationReport(
        roomId,
        isExternal,
        filters,
        roomMemberCount,
        reportTemplate.id,
        getReportTemplateName(reportTemplate),
      );

      dispatch(
        setLoader({ id: downloadLoadingId, status: LoaderStatus.LOADING }),
      );
      try {
        dispatch(updateExtractEmail(email));
        await Api.sendRoomReport({
          roomId: roomId,
          email: email,
          filters,
          templateId: reportTemplate.id,
        });
        dispatch(
          showSuccess({
            title: t("generateReportSendSuccessTitle"),
            message: t("generateReportSendSuccess"),
          }),
        );
      } catch (error) {
        dispatch(showError({ title: t("generateReportInvalidEmail") }));
      } finally {
        dispatch(
          setLoader({ id: downloadLoadingId, status: LoaderStatus.SUCCESS }),
        );
      }
    },
    [
      dispatch,
      downloadLoadingId,
      filters,
      isExternal,
      roomId,
      roomMemberCount,
      t,
    ],
  );

  return { sendRoomReport, isSendingRoomReport };
}
