import { RoomFilters } from "@kraaft/shared/core/modules/filter/filterState";
import { MAX_ROOM_CARDS_PER_PAGE } from "@kraaft/shared/core/modules/roomCard/const";
import { firestoreLoadRoomCards } from "@kraaft/shared/core/modules/roomCard/queries/firestore.roomCard";
import { RoomCardCursor } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { Api } from "@kraaft/shared/core/services/api";
import { parseDate } from "@kraaft/shared/core/services/firestore/firestoreUtils";

function normalizeCursorForApi(cursor?: RoomCardCursor) {
  if (!cursor) {
    return undefined;
  }
  return {
    ...cursor,
    lastEventAt: parseDate(cursor.lastEventAt).toISOString(),
  };
}

export async function loadRoomCards(
  poolId: string,
  userId: string,
  filters: RoomFilters | undefined,
  after?: RoomCardCursor,
  before?: RoomCardCursor,
) {
  if (filters?.searchText) {
    const startAfter = after ? after : undefined;
    const endBefore = before ? after : undefined;

    const response = await Api.getRoomCards({
      filters,
      poolId,
      startAfter: normalizeCursorForApi(startAfter),
      endBefore: normalizeCursorForApi(endBefore),
      limit: MAX_ROOM_CARDS_PER_PAGE,
    });

    return response.data;
  }

  return await firestoreLoadRoomCards(
    poolId,
    userId,
    filters,
    after,
    before,
    MAX_ROOM_CARDS_PER_PAGE,
  );
}
