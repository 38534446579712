import { StyleSheet } from "react-native";
import { makeStyles } from "@mui/styles";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
});

export const styleSheet = StyleSheet.create({
  conversationDetails: {
    flex: 1,
    position: "relative",
  },
  headerIconRight: {
    marginRight: 12,
  },
});

const detailsStylesConf = {
  container: {
    flex: 1,
    display: "flex" as const,
    flexDirection: "column" as const,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    padding: Spacing.S16,
    paddingBottom: Spacing.S32,
    paddingTop: Spacing.S24,
  },
};

export const useDetailsScreenStyles = makeStyles(detailsStylesConf);

export const detailsScreenStyleSheet = StyleSheet.create(detailsStylesConf);
