import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: Spacing.S4,
    marginTop: Spacing.S4,
  },
  text: {
    fontSize: FontSize.MEDIUM,
    lineHeight: "17 / 14",
    fontWeight: "normal",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    whiteSpace: "nowrap",
  },
  textHighlight: {
    fontWeight: "bold",
  },
  operatorContainer: {
    marginRight: Spacing.S4,
  },
  categoryContainer: {
    marginRight: Spacing.S4,
  },
  filterContainer: {
    marginRight: Spacing.S4,
  },
  valueContainer: {
    flex: 1,
    marginRight: Spacing.S4,
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
});
