import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { noop } from "ts-essentials";

import { ModularFolderCard } from "@kraaft/shared/components/modularFolderCard/modularFolderCard";
import { ModularFolderLeftIcon } from "@kraaft/shared/components/modularFolderCard/modularFolderLeftIcon";
import { ModularFolderCardDescription } from "@kraaft/shared/components/modularFolderList/modularFolderItem/modularFolderCardDescription";
import { getModularFolderIconWithColor } from "@kraaft/shared/components/modularFolderList/modularFolderItem/modularFolderItemUtils";
import { optimisticallyCreateIncrementTitle } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";

interface Props {
  schema: KFolderSchema;
  onChangeHighlightedCheckbox: (columnKey: string | undefined) => void;
}

const cardStyle = { width: 344 };

export const ModularFolderCardConfigurator = (props: Props) => {
  const { schema, onChangeHighlightedCheckbox } = props;

  const currentUserId = useSelector(selectCurrentUserId) ?? "";

  const title = optimisticallyCreateIncrementTitle(schema) || schema.name;

  const [record, setRecord] = useState(createFolderMock(schema, currentUserId));

  const { color, icon } = getModularFolderIconWithColor(schema, record);

  const checkboxColumn = schema.highlightedCheckbox
    ? KSchemaUtils.getHiglightedCheckboxColumn(
        schema.rootSection,
        schema.highlightedCheckbox,
      )
    : undefined;
  const toggleCheckbox = checkboxColumn
    ? () => {
        if (checkboxColumn) {
          const checkboxValue = ModularRecordUtils.findColumn(
            record.properties,
            checkboxColumn.key,
            checkboxColumn.type,
          )?.value;

          setRecord({
            ...record,
            properties: {
              ...record.properties,
              [checkboxColumn.key]: {
                columnType: checkboxColumn.type,
                value: !checkboxValue,
                updatedAt: new Date(),
                updatedBy: currentUserId,
              },
            },
          });
        }
      }
    : undefined;

  return (
    <>
      <Box
        pt="S16"
        pb="S16"
        mb="S16"
        bg="GREY_ANTIFLASH"
        items="center"
        radius="SMALL"
      >
        <ModularFolderCard
          title={title}
          leftView={
            <ModularFolderLeftIcon
              accessibilityLabel="icon"
              color={color}
              name={icon}
            />
          }
          style={cardStyle}
          onClickLeftIcon={toggleCheckbox}
          onClickCard={noop}
          enableInteractions
          description={
            <ModularFolderCardDescription
              schemaId={record.schemaId}
              recordProperties={record.properties}
            />
          }
        />
      </Box>
      <HighlighCheckboxSection
        schema={schema}
        onChangeHighlightedCheckbox={onChangeHighlightedCheckbox}
      />
    </>
  );
};

const HighlighCheckboxSection = (props: {
  schema: KFolderSchema;
  onChangeHighlightedCheckbox: (columnKey: string | undefined) => void;
}) => {
  const { schema, onChangeHighlightedCheckbox } = props;
  const { t } = useTranslation();
  const highlightedCheckboxColumn = schema.highlightedCheckbox
    ? KSchemaUtils.getHiglightedCheckboxColumn(
        schema.rootSection,
        schema.highlightedCheckbox,
      )
    : undefined;
  const selectedIds = highlightedCheckboxColumn && [
    highlightedCheckboxColumn.key,
  ];

  const items = useMemo(
    () =>
      KSchemaUtils.getColumnsOfType(schema, KColumnType.checkbox).map(
        (col) => ({
          value: col.key,
          label: col.name,
        }),
      ),
    [schema],
  );

  const handleSelectionChange = (values: string[] | undefined) => {
    onChangeHighlightedCheckbox(values?.[0]);
  };

  const disabled = items.length === 0;
  const placeholder = disabled
    ? t("formBuilder.infos.highlightCheckbox.none")
    : t("formBuilder.infos.highlightCheckbox.placeholder");

  return (
    <Box row items="center" gap="S16">
      <Text size="BODY">
        <Trans i18nKey="formBuilder.infos.highlightCheckbox.title" />
      </Text>
      <Box grow>
        <KDropdown
          items={items}
          selectedItemIds={selectedIds}
          withEmptyRow
          variant="dark"
          fullWidth
          disabled={disabled}
          placeholder={placeholder}
          onSelectionChange={handleSelectionChange}
        />
      </Box>
    </Box>
  );
};

function createFolderMock(schema: KFolderSchema, currentUserId: string) {
  const userColumn = KSchemaUtils.getFirstColumnOfType(
    schema,
    KColumnType.user,
  );
  const dateColumn = KSchemaUtils.getFirstColumnOfType(
    schema,
    KColumnType.date,
  );

  const properties: ModularFolder["properties"] = {
    title: { columnType: KColumnType.shortText, value: "Title" },
  };

  if (userColumn) {
    properties[userColumn.key] = {
      columnType: KColumnType.user,
      value: [currentUserId],
    };
  }
  if (dateColumn) {
    properties[dateColumn.key] = {
      columnType: KColumnType.date,
      value: new Date(),
    };
  }

  const record: ModularFolder = {
    id: "mockId",
    poolId: "mockId",
    roomId: "mockId",
    schemaId: "mockId",
    properties: properties,
    geolocation: undefined,
    incrementalId: 1,
    index: 0,
    updatedAt: new Date(),
  };
  return record;
}
