import { ViewStyle } from "react-native";
import { makeStyles } from "@mui/styles";

import { ColorStyle, morphClassNameAsStyle } from "@kraaft/ui";

export const useEntryLayoutWithDetailsActiveStyle = (
  isActive: boolean,
): ViewStyle => {
  const classes = useStyles();

  return isActive ? morphClassNameAsStyle(classes.activeContainer) : {};
};

const useStyles = makeStyles({
  activeContainer: {
    backgroundColor: "red",
    "& > div": {
      backgroundColor: ColorStyle.ACTION_SELECTED,
    },
  },
});
