import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { markerSize } from "@kraaft/shared/components/geolocation/baseMarker/baseMarkerConfig";
import { ClusterConfig } from "@kraaft/shared/components/mapController/markers/marker/cluster/cluster.types";
import { getMarkerCoordinates } from "@kraaft/shared/components/mapController/markers/markerUtils";
import { ModularFolderCluster } from "@kraaft/shared/components/mapController/markers/modularFolderMarker/modularFolderCluster";
import { ModularFoldersListForMap } from "@kraaft/shared/components/mapController/markers/modularFolderMarker/modularFoldersListForMap";
import { MarkerGeoJSON } from "@kraaft/shared/components/mapController/types";
import { geolocationContextActions } from "@kraaft/shared/core/modules/room/roomActions";
import { ModularFolderWithGeolocation } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";

interface Props {
  onTooltipPress: (params: {
    type: "folder";
    folderId: string;
    schemaId: string;
  }) => void;
}

const useModularFolderClusterConfig = ({
  onTooltipPress,
}: Props): {
  clusterConfig: ClusterConfig<ModularFolderWithGeolocation>;
  modularFolderListPanel?: React.ReactNode;
} => {
  const dispatch = useDispatch();
  const [selectedClusterMarkers, setSelectedClusterMarkers] = useState<
    MarkerGeoJSON<ModularFolderWithGeolocation>[]
  >([]);

  const handleTooltipPress = useCallback(
    (modularFolder: ModularFolderWithGeolocation) => {
      onTooltipPress({
        type: "folder",
        folderId: modularFolder.id,
        schemaId: modularFolder.schemaId,
      });
    },
    [onTooltipPress],
  );

  const handleClose = useCallback(() => {
    setSelectedClusterMarkers([]);
  }, []);

  const modularFolderListPanel = useMemo(
    () => (
      <ModularFoldersListForMap
        markers={selectedClusterMarkers}
        open={selectedClusterMarkers.length > 0}
        onClose={handleClose}
        onRowPress={handleTooltipPress}
      />
    ),
    [selectedClusterMarkers, handleClose, handleTooltipPress],
  );

  const clusterConfig = useMemo<ClusterConfig<ModularFolderWithGeolocation>>(
    () => ({
      ClusterRenderer: ModularFolderCluster,
      tracksViewChanges: false,
      size: markerSize,
      onPress: (markers, isMaxZoom) => {
        if (isMaxZoom) {
          if (isNative()) {
            setSelectedClusterMarkers(markers);
          }
        } else {
          const boundsList = markers.map((marker) =>
            getMarkerCoordinates(marker),
          );

          dispatch(
            geolocationContextActions({
              actions: [{ type: "zoomOnBounds", boundsList }],
            }),
          );
        }
      },
      onTooltipPress: handleTooltipPress,
    }),
    [dispatch, handleTooltipPress],
  );

  return { clusterConfig, modularFolderListPanel };
};

export { useModularFolderClusterConfig };
