import { useLayoutEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import clsx from "clsx";

import { ModularRecordWithIdAndTitle } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KANBAN_ITEM } from "@kraaft/web/src/components/kanban/board/kanbanBoardUtils";

import { useStyles } from "./draggableKanbanCardContainer.styles";

const DraggableKanbanCardContainer = ({
  modularRecord,
  children,
}: {
  modularRecord: ModularRecordWithIdAndTitle;
  children: React.ReactNode;
}) => {
  const classes = useStyles();
  const [{ isDragging }, drag, preview] = useDrag({
    type: KANBAN_ITEM,
    item: { type: KANBAN_ITEM, modularRecord },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useLayoutEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, [preview]);

  return (
    <div className={classes.draggableItem} ref={drag}>
      <div className={classes.draggableItemPlaceholder}>
        <div
          className={clsx(isDragging && classes.draggableItemContentDisable)}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export { DraggableKanbanCardContainer };
