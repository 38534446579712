import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import compact from "lodash/compact";
import keyBy from "lodash/keyBy";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectionListItemWithTooltip } from "@kraaft/shared/components/kSelectionList/kSelectionListItemWithTooltip";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { SearchBar } from "@kraaft/shared/components/searchBar";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { selectPoolForms } from "@kraaft/shared/core/modules/form/formSelectors";
import {
  KizeoForm,
  kizeoFormLoaderId,
  loadKizeoForms,
  stopLoadingKizeoForms,
} from "@kraaft/shared/core/modules/form/kizeoFormReducers";
import { selectKizeoForms } from "@kraaft/shared/core/modules/form/kizeoFormSelectors";
import { selectLoader } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { Api } from "@kraaft/shared/core/services/api";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { ResolvePromise } from "@kraaft/shared/core/typeUtils";
import { Preloader } from "@kraaft/ui";

import { CurrentStepProps } from "../../../multiStepDialogTypes";

import { inlineStyles, useStyles } from "./selectFormsStep.styles";

export interface SelectFormsStepParams {
  poolId: string;
  nextStep: number;
}

function formatForm(name: string, id: string) {
  return `${name} - ${id}`;
}

const SelectFormsStep = (props: CurrentStepProps<SelectFormsStepParams>) => {
  const dispatch = useDispatch();
  const { onClose, setStep, poolId, nextStep } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const title = t("selectKizeoFormsTitle");
  const currentForms = useSelector(selectPoolForms(poolId));
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const formsLoader = useSelector(selectLoader(kizeoFormLoaderId));
  const isLoadingForms = formsLoader?.status === LoaderStatus.LOADING;
  const [isUpdatingForms, setIsUpdatingForms] = useState(false);
  const candidateForms = useSelector(selectKizeoForms);
  const [filter, setFilter] = useState("");
  const [updateError, setUpdateError] =
    useState<ResolvePromise<typeof Api.updateKizeoForms>>();

  useEffect(() => {
    dispatch(loadKizeoForms({ poolId }));
    return () => {
      dispatch(stopLoadingKizeoForms());
    };
  }, [dispatch, poolId]);

  useEffect(() => {
    if (formsLoader?.status === LoaderStatus.FAILURE) {
      dispatch(showError({ title: formsLoader.error.message }));
    }
  }, [dispatch, formsLoader]);

  useEffect(() => {
    const ids = (currentForms || []).map((form) => form.kizeoFormId);
    setSelectedIds(ids);
  }, [currentForms]);

  const items = useMemo<KSelectableListItem[]>(() => {
    function getLabel(form: KizeoForm): string {
      const icon = form.isConfigured
        ? "✅ "
        : form.isConfigured === false
          ? "🔶 "
          : "";
      return `${icon}${formatForm(form.name, form.kizeo.formId)}`;
    }

    return candidateForms.map((form) => ({
      label: getLabel(form),
      identifier: form.kizeo.formId,
    }));
  }, [candidateForms]);

  const onValidate = useCallback(async () => {
    const formMap = keyBy(candidateForms, (form) => form.kizeo.formId);
    const selectedForms = compact(selectedIds.map((id) => formMap[id]));
    setIsUpdatingForms(true);
    setUpdateError(undefined);
    try {
      const response = await Api.updateKizeoForms({
        poolId,
        forms: selectedForms,
      });
      setUpdateError(response);
      if (!response?.error) {
        setStep({ index: nextStep });
      }
    } catch (error) {
      setUpdateError(error.message || error);
    } finally {
      setIsUpdatingForms(false);
    }
  }, [candidateForms, nextStep, poolId, selectedIds, setStep]);

  const errorElement = useMemo(() => {
    let _errorElement: React.ReactNode;
    if (updateError) {
      switch (updateError?.error) {
        case "formNotConfigured":
          _errorElement = (
            <>
              <div>{t("kizeoFormNotConfiguredError")}</div>
              {updateError.forms.map((form) => (
                <div key={`error-${form.formId}`}>
                  {formatForm(form.name, form.formId)}
                </div>
              ))}
            </>
          );
          break;
        case "writeForbidden":
          _errorElement = (
            <>
              <div>{t("kizeoWriteForbiddenError")}</div>
              {updateError.forms.map((form) => (
                <div key={`error-${form.formId}`}>
                  {formatForm(form.name, form.formId)}
                </div>
              ))}
            </>
          );
          break;
        case "kizeoApi":
          _errorElement = updateError.message;
          break;
        default:
          _errorElement = t("internalError");
      }
      return _errorElement;
    }
  }, [t, updateError]);

  const actions = useMemo<FooterActions>(
    () => [
      {
        accessibilityLabel: t("validate"),
        text: t("validate"),
        onPress: onValidate,
        autoFocus: true,
        variant: "PRIMARY",
        loading: isUpdatingForms,
      },
      {
        accessibilityLabel: t("cancel"),
        text: t("cancel"),
        onPress: onClose,
      },
    ],
    [isUpdatingForms, onClose, onValidate, t],
  );

  const renderItem = useCallback(
    (item: KSelectableListItem) => (
      <KSelectionListItemWithTooltip item={item} />
    ),
    [],
  );

  const content = useMemo(
    () => (
      <div className={classes.content}>
        {isLoadingForms ? (
          <Preloader absoluteFill transparent />
        ) : (
          <>
            <Typography classes={{ root: classes.intro }}>
              <Trans
                i18nKey="selectKizeoFormsIntro"
                components={{
                  a: <a target="_blank" />,
                }}
              />
            </Typography>

            <SearchBar
              value={filter}
              onChange={setFilter}
              placeholder={t("selectKizeoFormsFilterPlaceholder")}
              isStatic
            />

            <KSelectionList
              style={inlineStyles.list}
              items={items}
              isMultiple={true}
              selectAll={true}
              selected={selectedIds}
              setSelected={setSelectedIds}
              labelFilter={filter}
              renderItem={renderItem}
              disableScroll
            />

            {errorElement && (
              <div className={classes.errorText}>{errorElement}</div>
            )}
          </>
        )}
      </div>
    ),
    [
      classes.content,
      classes.errorText,
      classes.intro,
      errorElement,
      filter,
      isLoadingForms,
      items,
      renderItem,
      selectedIds,
      t,
    ],
  );

  return <KDialogContent {...{ onClose, title, content, actions }} />;
};

export { SelectFormsStep };
