import React, { forwardRef } from "react";

import { Button, ButtonHandle } from "@kraaft/ui";

export type SectionHeaderLabelButtonProps = {
  label: string;
  onPress?: () => void;
};

export const SectionHeaderLabelButton = React.memo(
  forwardRef<ButtonHandle, SectionHeaderLabelButtonProps>(
    ({ label, onPress }, ref) => (
      <Button
        ref={ref}
        variant="QUATERNARY"
        size="SMALL"
        text={label}
        onPress={onPress}
      />
    ),
  ),
);
