export function parseTableClipboard(text: string) {
  const result: string[][] = [];
  let currentLine: string[] = [];
  let currentCell = "";

  let inQuote = false;
  let multiline = false;

  const addCell = () => {
    if (multiline) {
      currentCell = currentCell.slice(1, -1);
    }
    inQuote = false;
    multiline = false;
    currentLine.push(currentCell);
    currentCell = "";
  };

  const addLine = () => {
    result.push(currentLine);
    currentLine = [];
  };

  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    const nextChar = text[i + 1];

    // when quoted cell, check if a quote is escaped
    if (char === '"' && nextChar === '"' && inQuote) {
      currentCell += char;
      i += 1;
      continue;
    }

    // when encountering a quote at the begining of the cell, toggle inQuote
    if (currentCell === "" && char === '"') {
      inQuote = true;
      currentCell += char;
      continue;
    }

    if (char === "\t") {
      addCell();
      continue;
    }

    if (char === "\n") {
      if (inQuote) {
        multiline = true;
        currentCell += char;
        continue;
      }
      addCell();
      addLine();
      continue;
    }

    currentCell += char;
  }

  addCell();
  addLine();
  return result;
}
