import { call, put } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import {
  clearLoader,
  setLoader,
} from "@kraaft/shared/core/modules/loaders/loaderActions";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { getSharepointErrorMessage } from "@kraaft/shared/core/modules/room/roomUtils";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* connectRoomToExternalSaga(
  action: ReturnType<typeof roomActions.connectRoomToExternal>,
) {
  const {
    payload: { loaderId, roomId, microsoftStorage: sharepointUrl },
  } = action;

  yield* put(setLoader({ id: loaderId, status: LoaderStatus.LOADING }));

  if (sharepointUrl) {
    try {
      const {
        data: { error },
      } = yield* call(Api.synchronizeRoomWithSharepoint, {
        roomId,
        sharepointUrl,
      });

      if (error) {
        throw new Error(error);
      }
    } catch (err) {
      const message = getSharepointErrorMessage(err);
      yield* put(
        showError({
          title: i18n.t("sharepointErrorTitle"),
          message,
        }),
      );
    }
  }

  yield* put(clearLoader(loaderId));
}
