/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import { useRef, useState } from "react";
import { Column } from "react-data-grid";
import { makeStyles } from "@mui/styles";
import { cloneDeep } from "lodash";

import { useMeshContext } from "@kraaft/helper-hooks";
import { ModularTableValueUpdate } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  ModularRecord,
  ModularRecordProperties,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { ModularDisplayRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { isOSKeyModifierPressed, uuid } from "@kraaft/shared/core/utils";
import { copyTextToClipboard } from "@kraaft/shared/core/utils/clipboard";
import { Color } from "@kraaft/ui";
import { getFieldProp } from "@kraaft/web/src/components/modularTable/components/fields";
import { addUpdateForRow } from "@kraaft/web/src/components/modularTable/components/table/copyPastetableManager.utils";
import { parseTableClipboard } from "@kraaft/web/src/components/modularTable/components/table/parseTableClipboard/parseTableClipboard";
import { Position } from "@kraaft/web/src/components/modularTable/components/table/table";
import { getCellFromColumnAndRecord } from "@kraaft/web/src/components/modularTable/components/table/tableUtils";
import { Cell } from "@kraaft/web/src/components/modularTable/components/types";
import { TableContext } from "@kraaft/web/src/components/modularTable/tableContext";

interface CopyPasteTableManagerProps<T extends ModularRecord> {
  position: React.RefObject<Position | undefined>;
  columns: Column<T, unknown>[];
  rows: T[];
  updateRows: (rows: T[], update: ModularTableValueUpdate[]) => void;
  children: JSX.Element;
  enableCopy: boolean;
  enablePaste: boolean;
  isEditing: boolean;
  canCreateRow?: boolean;
  maxRows?: number;
  tableContext: TableContext;
}

const NON_COPYABLE_CELLS = [
  KColumnType.attachment,
  KColumnType.roomName,
  KColumnType.roomMembers,
  KColumnType.signature,
];

export const CopyPasteTableManager = <T extends ModularRecord>(
  props: CopyPasteTableManagerProps<T>,
) => {
  const {
    children,
    columns,
    position,
    rows,
    updateRows,
    enableCopy,
    enablePaste,
    isEditing,
    canCreateRow,
    maxRows,
    tableContext,
  } = props;
  const displayRequirements = useMeshContext(ModularDisplayRequirementsContext);
  const [copiedCell, setCopiedCell] = useState<Cell>();

  const isProcessing = useRef(false);

  const classes = useStyles();

  const addOneRowTo = (newRows: ModularRecord[]) => {
    const properties: ModularRecordProperties = {};

    newRows.push({
      id: uuid(),
      schemaId: "",
      properties,
    });
  };

  function getCellAtPosition(pos: Position): Cell | undefined {
    const column = columns[pos.idx] as KSchemaColumn | undefined;
    const record = rows[pos.rowIdx];

    if (column && record) {
      return getCellFromColumnAndRecord(column, record);
    }
    return undefined;
  }

  const pasteFromClipboard = async (text: string) => {
    const updates: ModularTableValueUpdate[] = [];
    if (!position.current) {
      return;
    }

    const newRows = cloneDeep(rows);

    const lines = parseTableClipboard(text);

    const processor: Promise<void>[] = [];

    for (const [lineIndex, line] of lines.entries()) {
      if (!position.current) {
        break;
      }

      const rowIndex = position.current.rowIdx + lineIndex;

      // Cannot add more rows than already existing
      if (rowIndex >= rows.length && !canCreateRow) {
        break;
        // Maximum rows reached
      }
      if (maxRows !== undefined && rowIndex + 1 > maxRows) {
        break;
      }
      if (rowIndex >= rows.length) {
        addOneRowTo(newRows);
      }

      const row = newRows[rowIndex];

      if (row === undefined) {
        console.error(
          `pasteFromClipboard :: row at index ${rowIndex} is undefined`,
        );
        break;
      }

      for (const [tabIndex, cell] of line.entries()) {
        const cleanText = cell?.trim() ?? "";
        if (!position.current) {
          break;
        }
        const column = columns[
          position.current.idx + tabIndex
        ] as KSchemaColumn;

        if (column) {
          const result = getFieldProp(
            "getValueFromClipboardText",
            column.type,
          )?.(cleanText, column, displayRequirements);
          const isPromise = result instanceof Promise;

          if (isPromise) {
            // setLoaderForRowAtColumn(row, column, true);
            processor.push(
              new Promise(async (resolve) => {
                const value = cleanText.length > 0 ? await result : undefined;

                addUpdateForRow(row, column, rowIndex, value, updates);
                // setLoaderForRowAtColumn(row, column, false);
                resolve(undefined);
              }),
            );
          } else {
            const value = cleanText.length > 0 ? result : undefined;

            addUpdateForRow(row, column, rowIndex, value, updates);
          }
        }
      }
    }

    // Wait refresh the table
    if (updates.length > 0) {
      updateRows(newRows, updates);
    }

    // Wait for async cells then refresh
    await Promise.all(processor);

    if (updates.length > 0) {
      updateRows(newRows, updates);
    }
  };

  const pasteFromCell = () => {
    if (
      !position.current ||
      !copiedCell ||
      NON_COPYABLE_CELLS.includes(copiedCell.column.type)
    ) {
      return;
    }

    const cell = getCellAtPosition(position.current);

    if (cell && cell.column.key === copiedCell.column.key) {
      const updates: ModularTableValueUpdate[] = [];
      const newRows = cloneDeep(rows);
      const columnKey = cell.column.key;
      const rowIndex = position.current.rowIdx;
      const row = newRows[rowIndex];

      const value = copiedCell.value;
      if (row) {
        //@TODO fix cast
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        row.properties[columnKey] = value as any;
        updates.push({
          id: row.id,
          column: cell.column,
          rowIndex,
          value,
        });
      }

      updateRows(newRows, updates);
    }
  };

  function getCellText(cell: Cell) {
    return (
      getFieldProp("getClipboardTextFromCell", cell.column.type)?.(
        cell,
        displayRequirements,
      ) ?? ""
    );
  }

  const copyCellText = async (cell: Cell) => {
    const clipboardText = getCellText(cell);

    await copyTextToClipboard(clipboardText);
  };

  const onKeyDownCapture = async (
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (!enableCopy) {
      return;
    }
    event.persist();

    if (isOSKeyModifierPressed(event) && event.key === "c") {
      if (!isEditing) {
        event.stopPropagation();
        if (position.current) {
          const currentCell = getCellAtPosition(position.current);
          if (currentCell) {
            setCopiedCell(currentCell);
            await copyCellText(currentCell);
          }
        }
      }
    }
  };

  const onPaste = async (event: React.ClipboardEvent<HTMLDivElement>) => {
    if (isProcessing.current) {
      console.log("onPaste :: abort (already processing)");
      return;
    }
    isProcessing.current = true;
    document.body.style.cursor = "wait";

    if (!position.current) {
      return;
    }
    const clipboardText = event.clipboardData.getData("text");
    const copiedCellAsText = copiedCell ? getCellText(copiedCell) : undefined;
    const currentCell = getCellAtPosition(position.current);
    const row = rows[position.current.rowIdx];
    const currentCellColumnKey = currentCell?.column.key;
    const columnContext = currentCellColumnKey
      ? tableContext.columns[currentCellColumnKey]
      : undefined;
    const isCellLocked = Boolean(
      row
        ? columnContext?.getSectionLockInfo?.(row.properties).isLocked
        : false,
    );

    const shouldPasteFromClipboard =
      (copiedCell === undefined ||
        copiedCellAsText !== clipboardText ||
        copiedCell.column.key !== currentCell?.column.key) &&
      !isCellLocked;

    if (shouldPasteFromClipboard) {
      await pasteFromClipboard(clipboardText);
    } else {
      pasteFromCell();
    }

    isProcessing.current = false;
    document.body.style.cursor = "unset";
  };

  return (
    <div
      onPaste={enablePaste ? onPaste : undefined}
      onKeyDown={onKeyDownCapture}
      className={classes.copyPasteContainer}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  copyPasteContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxHeight: "100%",

    overflow: "auto",

    backgroundColor: Color.WHITE,
    borderRadius: 10,
  },
});
