import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { RecentEmojiSpace } from "@kraaft/shared/core/modules/emoji/emoji.state";
import { RootState } from "@kraaft/shared/core/store";

const selectState = ({ emoji }: RootState) => emoji;

export const selectRecentEmojis = memoize((space: RecentEmojiSpace) =>
  createSelector(selectState, ({ recent }) => recent[space]),
);
