import { useCallback, useMemo } from "react";
import {
  GestureResponderEvent,
  Platform,
  StyleSheet,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

import {
  ReactionPills,
  ReactionsDescription,
} from "@kraaft/shared/components/message/reactions/reactionPills";
import { ReactionsDetailsSheet } from "@kraaft/shared/components/message/reactions/reactionSheet/reactionsDetailsSheet";
import { reactToMessage } from "@kraaft/shared/core/modules/message/messageActions";
import { UserMessage } from "@kraaft/shared/core/modules/message/messageState";
import {
  selectCurrentUserId,
  selectUsers,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { Spacing } from "@kraaft/ui";

interface ReactionsContainerProps {
  roomId: string;
  message: UserMessage;
}

const ReactionsContainer = ({ roomId, message }: ReactionsContainerProps) => {
  const dispatch = useDispatch();
  const usernames = useSelector(selectUsers);
  const currentUserId = useSelector(selectCurrentUserId);
  const { isReply } = message;

  const { pillStyles, reactions, userReaction } = useMemo(() => {
    const _pillStyles = [
      styles.reactions,
      isReply ? { left: Spacing.S4 } : { right: Spacing.S4 },
    ];

    return {
      pillStyles: _pillStyles,
      reactions: Object.entries(message.reactions).reduce<ReactionsDescription>(
        (acc, [userId, { emoji, reactedAt }]) => {
          const description = acc[emoji] ?? {
            names: [],
            count: 0,
            firstReactedAt: reactedAt.getTime(),
          };
          description.count += 1;
          description.names.push(getUsername(usernames[userId]));
          description.firstReactedAt = Math.min(
            description.firstReactedAt,
            reactedAt.getTime(),
          );
          acc[emoji] = description;
          return acc;
        },
        {},
      ),
      userReaction: message.reactions[currentUserId ?? ""]?.emoji,
    };
  }, [currentUserId, isReply, message.reactions, usernames]);

  const handleReactionPress = useCallback(
    (emoji: string) => {
      dispatch(reactToMessage({ roomId, messageId: message.id, emoji }));
    },
    [dispatch, message.id, roomId],
  );

  const { open, element } = ReactionsDetailsSheet.use({ reactions });

  const handleLongPress = useCallback(
    (event: GestureResponderEvent) => {
      open({ anchor: { current: event.currentTarget } });
    },
    [open],
  );

  if (Object.keys(reactions).length === 0) {
    return null;
  }

  return (
    <>
      <View style={pillStyles}>
        <ReactionPills
          isReply={isReply}
          onReactionPress={handleReactionPress}
          reactions={reactions}
          userReaction={userReaction}
          onLongPress={Platform.select({ native: handleLongPress })}
        />
      </View>
      {element}
    </>
  );
};

const REACTIONS_MESSAGE_INSET = 10;

const styles = StyleSheet.create({
  reactions: {
    zIndex: 1,
    top: -REACTIONS_MESSAGE_INSET,
    marginBottom: -REACTIONS_MESSAGE_INSET,
  },
});

export { ReactionsContainer };
