import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { ModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";

export function useSubscribeToModularFolders(roomId: string) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ModularFolderActions.subscribeForRoom({ roomId }));

    return () => {
      dispatch(ModularFolderActions.unsubscribeForRoom({ roomId }));
    };
  }, [dispatch, roomId]);
}
