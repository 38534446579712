import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

interface MakeStylesProps {
  width: number | undefined;
}

export const useStyles = makeStyles({
  itemContainer: {
    display: "flex",
    alignItems: "center",
    width: ({ width }: MakeStylesProps) => width,
  },
  handleIcon: {
    marginRight: Spacing.S8,
  },
});
