/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from "lodash";
import { v4 } from "uuid";

export interface OptimisticOperation<P, T extends string> {
  type: T;
  optimisticId: string;
  updatedAt: Date | undefined;
  payload: P & ({ targetId: string } | { targetIds: string[] });
}

export type BaseAggregation = { updatedAt: Date };

export class OptimisticOperationCreator<
  P,
  T extends string,
  A extends BaseAggregation,
> {
  public shouldRemove: (
    newData: A,
    operation: OptimisticOperation<P, T>,
  ) => boolean;

  constructor(
    public readonly type: string,
    public readonly aggregate: (
      data: A,
      operation: OptimisticOperation<P, T>,
    ) => A,
    shouldRemove?: (
      newData: A,
      operation: OptimisticOperation<P, T>,
    ) => boolean,
  ) {
    if (shouldRemove) {
      this.shouldRemove = shouldRemove;
    } else {
      this.shouldRemove = this.genericOperationRemover;
    }
  }

  private genericOperationRemover(
    newData: A,
    instance: OptimisticOperation<any, any>,
  ) {
    if (!instance.updatedAt) {
      return false;
    }
    return instance.updatedAt.getTime() <= newData.updatedAt.getTime();
  }

  create(
    payload: OptimisticOperation<P, T>["payload"],
  ): OptimisticOperation<P, T> {
    return {
      type: this.type,
      optimisticId: v4(),
      payload,
      updatedAt: undefined,
    } as OptimisticOperation<P, T>;
  }
}

export type Type<T> = T extends OptimisticOperationCreator<any, infer K, any>
  ? K
  : never;
export type Payload<T> = T extends OptimisticOperationCreator<infer K, any, any>
  ? K
  : never;
export type Aggregation<T> = T extends OptimisticOperationCreator<
  any,
  any,
  infer K
>
  ? K
  : never;

type D<A> = A extends OptimisticOperationCreator<infer P, infer T, any>
  ? OptimisticOperation<P, T>
  : never;

export type OptimisticCreatorOperation<
  T extends OptimisticOperationCreator<any, any, any>,
> = D<T>;

export class OptimisticBuilder<
  U extends OptimisticOperationCreator<any, any, any>,
> {
  constructor(
    public readonly name: string,
    private readonly getIdFromAggregate: (data: Aggregation<U>) => string,
    private readonly operationCreators: Record<Type<U>, U>,
    public operations: D<U>[],
    private readonly enableLog?: boolean,
  ) {}

  public log(module: string, ...args: any[]) {
    if (!this.enableLog) {
      return;
    }
    console.log(
      `[%cOptimisticBuilder%c:%c${this.name}%c/%c${module}%c]:`,
      "color: chartreuse; font-weight: bold",
      "color: unset",
      "color: aquamarine",
      "color: unset",
      "color: yellow",
      "color: unset",
      ...args,
    );
  }

  ourLog(...args: any[]) {
    this.log("Core", ...args);
  }

  private printCurrentState() {
    this.ourLog(`${this.operations.length} items in queue`);
  }

  getUnfulfilled() {
    return this.operations.filter((operation) => !operation.updatedAt);
  }

  addOperation(operation: ReturnType<U["create"]>) {
    this.ourLog("Adding", operation.type);
    this.operations.push(operation as any);
    this.printCurrentState();
  }

  removeOperation(optimisticId: string) {
    this.ourLog("Removing", optimisticId);
    this.operations = this.operations.filter(
      (op) => op.optimisticId !== optimisticId,
    );
    this.printCurrentState();
  }

  removeOperations(optimisticIds: string[]) {
    this.ourLog("Removing", optimisticIds);
    this.operations = this.operations.filter((op) =>
      optimisticIds.includes(op.optimisticId),
    );
    this.printCurrentState();
  }

  link(optimisticId: string, date: Date) {
    this.ourLog("Linking", optimisticId);
    const operation = this.operations.find(
      (op) => op.optimisticId === optimisticId,
    );
    if (!operation) {
      return;
    }
    operation.updatedAt = date;
  }

  private getCreator(type: string) {
    return this.operationCreators[type as keyof Record<Type<U>, U>];
  }

  private payloadAppliesToId(
    payload: OptimisticOperation<unknown, any>["payload"],
    id: string,
  ) {
    if ("targetIds" in payload) {
      return payload.targetIds.includes(id);
    }
    return payload.targetId === id;
  }

  build(initialValue: Aggregation<U>) {
    let clonedValue = cloneDeep(initialValue);
    const aggregateId = this.getIdFromAggregate(clonedValue);
    for (const operation of this.operations) {
      const operationPayload = operation.payload as OptimisticOperation<
        unknown,
        any
      >["payload"];
      const creator = this.getCreator(operation.type);

      if (!creator || !this.payloadAppliesToId(operationPayload, aggregateId)) {
        continue;
      }
      clonedValue = creator.aggregate(clonedValue, operation);

      if (clonedValue === null) {
        return null;
      }
    }
    return clonedValue;
  }

  prune(newData: Aggregation<U>[]) {
    this.ourLog("Analyzing...");
    const pruneFunction = (operation: D<U>) => {
      const operationPayload = operation.payload as OptimisticOperation<
        unknown,
        any
      >["payload"];
      const item = newData.find((d) =>
        this.payloadAppliesToId(operationPayload, this.getIdFromAggregate(d)),
      );

      if (!item) {
        return true;
      }

      const creator = this.getCreator(operation.type);
      if (!creator) {
        return true;
      }
      return creator.shouldRemove(item, operation);
    };
    const [removed, kept] = this.operations.reduce<[D<U>[], D<U>[]]>(
      (acc, operation) => {
        const shouldRemove = pruneFunction(operation);
        if (shouldRemove) {
          this.ourLog("Pruning", operation.optimisticId);
          acc[0].push(operation);
        } else {
          acc[1].push(operation);
        }
        return acc;
      },
      [[], []],
    );
    this.operations = kept;
    this.printCurrentState();
    return removed;
  }
}

export class OptimisticWorkspace<
  U extends OptimisticOperationCreator<any, any, any>,
> {
  public operationCreators: Record<Type<U>, U>;
  public optimisticBuilder: OptimisticBuilder<U>;

  constructor(
    public readonly name: string,
    operationCreators: U[],
    private readonly getIdFromAggregate: (data: Aggregation<U>) => string,
    private readonly enableLog?: boolean,
  ) {
    this.operationCreators = operationCreators.reduce<Record<Type<U>, U>>(
      (acc, curr) => {
        acc[curr.type as Type<U>] = curr;
        return acc;
      },
      {} as Record<Type<U>, U>,
    );
    this.optimisticBuilder = this.createOptimisticBuilder([]);
  }

  public log(module: string, ...args: any[]) {
    this.optimisticBuilder.log(module, ...args);
  }

  createOptimisticBuilder(operations: D<U>[]): OptimisticBuilder<U> {
    return new OptimisticBuilder(
      this.name,
      this.getIdFromAggregate,
      this.operationCreators,
      operations,
      this.enableLog,
    );
  }
}

export type WOperation<T extends OptimisticWorkspace<any>> =
  T["optimisticBuilder"]["operations"][number];
export type WType<T> = T extends OptimisticWorkspace<infer K> ? Type<K> : never;
export type WAggregation<T> = T extends OptimisticWorkspace<infer K>
  ? Aggregation<K>
  : never;
