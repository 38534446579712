import { useTranslation } from "react-i18next";

import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button } from "@kraaft/ui";
import { ChangePoolNameDialogContent } from "@kraaft/web/src/views/settings/managePool/settingsName/changePoolNameDialogContent";

import { useStyles } from "../managePool.styles";

interface SettingsNameProps {
  isSuperadmin: boolean;
  pool: Pool | undefined;
}

export const SettingsName = ({ isSuperadmin, pool }: SettingsNameProps) => {
  const poolId = pool?.id;
  const poolName = pool?.name;

  const [dialogOpen, openDialog, closeDialog] = useBooleanState(false);

  const { t } = useTranslation();
  const classes = useStyles();

  if (!poolId || !poolName) {
    return null;
  }

  return (
    <>
      <div className={classes.titleContainer}>
        <span className={classes.title}>{t("adminReference")}</span>
      </div>
      <div className={classes.referenceContainer}>
        <div className={classes.nameWithActionContainer}>
          <div className={classes.nameContainer}>
            <span className={classes.label}>{pool?.name}</span>
          </div>
          {isSuperadmin && (
            <Button
              accessibilityLabel={t("edit")}
              text={t("edit")}
              variant="SECONDARY"
              size="SMALL"
              onPress={openDialog}
            />
          )}
        </div>
        <span className={classes.subTitle}>{pool?.id}</span>
      </div>
      {pool && (
        <KDialogFrame open={dialogOpen} onClose={closeDialog}>
          <ChangePoolNameDialogContent pool={pool} onClose={closeDialog} />
        </KDialogFrame>
      )}
    </>
  );
};
