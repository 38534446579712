import { createSelector } from "@reduxjs/toolkit";
import { memoize, orderBy, partition } from "lodash";

import { LibrarySchemaOptimisticSelector } from "@kraaft/shared/core/modules/librarySchema/librarySchema.optimistic";
import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { RootState } from "@kraaft/shared/core/store";

export const selectState = ({ librarySchema: { data } }: RootState) => data;

export const selectRawLibrarySchemas = createSelector(
  selectState,
  ({ librarySchemas, companyLibrarySchemas }) => ({
    ...librarySchemas,
    ...companyLibrarySchemas,
  }),
);

export const selectLibrarySchemasOptimictic = createSelector(
  LibrarySchemaOptimisticSelector.selectAllBuilt,
  (librarySchemas) => Object.values(librarySchemas),
);

export const selectLibrarySchemas = createSelector(
  selectRawLibrarySchemas,
  (librarySchemas) => Object.values(librarySchemas),
);

export const selectOrderedLibrarySchemas = createSelector(
  selectLibrarySchemas,
  (librarySchemas) =>
    orderBy(librarySchemas, (librarySchema) => librarySchema.index),
);

export const selectGridLibrarySchemasForLanguage = memoize(
  (language: LibrarySchemaLanguage | undefined) =>
    createSelector(
      selectOrderedLibrarySchemas,
      selectCurrentUserIsSuperadmin,
      (librarySchemas, isSuperadmin) => {
        const [enabled, disabled] = partition(
          librarySchemas.filter(
            (librarySchema) => librarySchema.language === language,
          ),
          (librarySchema) => librarySchema.enabled,
        );
        return isSuperadmin ? [...enabled, ...disabled] : enabled;
      },
    ),
);

export const selectLibrarySchema = memoize(
  (librarySchemaId: string | undefined) =>
    createSelector(selectRawLibrarySchemas, (librarySchemas) =>
      librarySchemaId !== undefined
        ? librarySchemas[librarySchemaId]
        : undefined,
    ),
);

export const selectCreatedColumn = memoize((librarySchemaId: string) =>
  createSelector(selectState, ({ createdColumnKey }) =>
    createdColumnKey?.librarySchemaId === librarySchemaId
      ? createdColumnKey.columnKey
      : undefined,
  ),
);
