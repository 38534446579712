import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Platform, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import isEqual from "fast-deep-equal";

import { RoomCardActionSheet } from "@kraaft/shared/components/roomCard/roomCardActionSheet";
import { RoomCardDisplay } from "@kraaft/shared/components/roomCard/roomCardDisplay";
import { arePillsEmpty } from "@kraaft/shared/components/roomCard/roomCardUtils";
import { NotificationSheet } from "@kraaft/shared/core/modules/notifications/roomNotificationSheet/roomNotification.sheet";
import { isConversationMuted } from "@kraaft/shared/core/modules/room/roomUtils";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { selectUserPoolNotificationFilter } from "@kraaft/shared/core/modules/userPool/userPool.selectors";
import { HapticService } from "@kraaft/shared/core/services/haptic";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { ContextMenus } from "@kraaft/ui";

import { styles } from "./roomCard.styles";

export interface RoomCardProps {
  roomCard: AnyRoomCard;
  disabled?: boolean;
  isHovered?: boolean;
  onLongPress?: (roomCard: AnyRoomCard) => void;
  onPress?: (roomCard: AnyRoomCard) => void;
  description?: string;
  pills?: JSX.Element | null;
  isActive: boolean;
}

const RoomCard_ = ({
  roomCard,
  disabled,
  isActive,
  isHovered,
  onLongPress,
  onPress,
  description,
  pills,
}: RoomCardProps) => {
  const { t } = useTranslation();

  const wrapperRef = useRef(null);

  const poolNotificationFilter = useSelector(
    selectUserPoolNotificationFilter(roomCard.poolId),
  );

  const roomNotificationFilter =
    roomCard.type === "member" ? roomCard.notificationSource : "inherit";

  const conversationMuted = isConversationMuted(
    poolNotificationFilter,
    roomNotificationFilter,
  );

  const trackCardActionsOpenEvent = useCallback(
    () =>
      trackEvent({
        eventName: "Open Conversation Action Sheet",
        room_id: roomCard.roomId,
        room_name: roomCard.title,
        platform: Platform.OS,
      }),
    [roomCard],
  );

  const handleLongPress = useCallback(() => {
    trackCardActionsOpenEvent();
    onLongPress?.(roomCard);
    HapticService.renderFeedback("info-light").catch(console.error);
  }, [onLongPress, roomCard, trackCardActionsOpenEvent]);

  const handlePress = useCallback(() => {
    onPress?.(roomCard);
  }, [onPress, roomCard]);

  const displayCardPills = arePillsEmpty(pills);

  const { open: openNotificationsSheet, element: notificationSheet } =
    NotificationSheet.use({
      poolId: roomCard.poolId,
      roomId: roomCard.roomId,
      source: "room_list",
    });

  const { element, open } = RoomCardActionSheet.use({
    roomCard,
    openNotificationsSheet,
  });

  ContextMenus.useFromActionSheet(
    wrapperRef,
    useCallback(
      (anchor) => open({ anchor: { current: anchor }, withoutBackdrop: true }),
      [open],
    ),
  );

  const handlePressContextMenu = useCallback(
    (anchor: React.RefObject<Element>): void => open({ anchor }),
    [open],
  );

  return (
    <>
      <TouchableOpacity
        accessibilityLabel={t("openRoom")}
        disabled={disabled}
        onLongPress={handleLongPress}
        onPress={handlePress}
        ref={wrapperRef}
      >
        {isActive && <View style={styles.selectedIndicator} />}
        <RoomCardDisplay
          description={description}
          displayCardPills={displayCardPills}
          hovered={isHovered ?? false}
          muted={conversationMuted}
          roomCard={roomCard}
          unread={"unread" in roomCard ? roomCard.unread : false}
          pills={pills}
          active={isActive}
          onHeaderContextMenuPress={handlePressContextMenu}
        />
      </TouchableOpacity>
      {element}
      {notificationSheet}
    </>
  );
};

export const RoomCard = React.memo(RoomCard_, isEqual) as typeof RoomCard_;
