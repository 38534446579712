import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { useBasicCellStyles } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCell.styles";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";

export const LongTextCell = ({
  value,
  isCellSelected,
}: IBasicCellProps<KColumnType.longText>) => {
  const classes = useBasicCellStyles({ isCellSelected });

  // add ellipsis if several lines
  const lines = value?.trimEnd().split("\n");
  if (lines && lines?.length > 1) {
    value = `${lines[0]}…`;
  }

  return <span className={classes.cellCursor}>{value}</span>;
};
