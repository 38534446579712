import { StyleSheet } from "react-native";

import { styleObject } from "@kraaft/shared/styles/panel.styles";
import { Color, ColorStyle, FontSize, PixelSize, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  smallCard: {
    height: undefined,
    marginVertical: 4,
  },
  hover: {
    backgroundColor: ColorStyle.ACTION_HOVERED,
  },

  selectedIndicator: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 4,
    height: "100%",
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: ColorStyle.ACTION_CTA,
    zIndex: 1,
  },

  image: {
    borderRadius: 50,
    height: PixelSize.S40,
    width: PixelSize.S40,
  },
  locker: {
    backgroundColor: Color.BLACK,
    borderRadius: 10,
    bottom: 0,
    padding: 1,
    position: "absolute",
    right: 0,
  },
  bodySeparator: {
    ...styleObject.borderBottom,
  },
  avatarContainer: {
    marginRight: 12,
    marginLeft: Spacing.S16,
  },
  bottomLayout: {
    flexDirection: "row",
    flexGrow: 1,
  },
  bottomLeftLayout: {
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
  },
  bottomRightLayout: {
    width: PixelSize.S24,
    marginTop: 4,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: -8,
  },
  descriptionWrapper: {
    flex: 1,
  },
  description: {
    lineHeight: 20,
    fontSize: FontSize.MEDIUM,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  dot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: Color.BLUE_SAVOY,
  },
  pillsContainer: {
    width: "100%",
    flexDirection: "row",
  },
  pills: {
    paddingBottom: 4,
    flexDirection: "row",
    alignContent: "center",
  },
  firstPill: {
    flexShrink: 0,
    maxWidth: "60%",
  },
  otherPill: {
    flexShrink: 1,
  },
  short: {
    width: "90%",
  },
  memberIcon: {
    marginTop: "auto",
    marginBottom: Spacing.S4,
    justifyContent: "flex-end",
  },
});
