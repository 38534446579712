import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { SelectPreserveCaptions } from "@kraaft/shared/components/forwardMessages/selectPreserveCaptions";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";

import { CurrentStepProps } from "../../../multiStepDialogTypes";

export interface PreserveCaptionStepParams {
  handleForward: (selectedRoomId: string, preserveCaptions: boolean) => void;
}

export interface PreserveCaptionStepState {
  selectedRoomId: string;
}

type PreserveCaptionStepProps = CurrentStepProps<
  PreserveCaptionStepParams,
  PreserveCaptionStepState
>;

const PreserveCaptionStep = (props: PreserveCaptionStepProps) => {
  const { onClose, step, setStep, handleForward } = props;
  const { t } = useTranslation();

  const [shouldPreserveCaption, setShouldPreserveCaption] =
    useState<boolean>(true);

  // TODO: Refacto Types (state shouldn't be undefined there)
  const selectedRoomId = step.state?.selectedRoomId;

  const goToPreviousStep = useCallback(() => {
    if (selectedRoomId) {
      setStep({
        index: 0,
        state: { selectedRoomId },
      });
    }
  }, [selectedRoomId, setStep]);

  const handleSubmit = useCallback(() => {
    if (selectedRoomId) {
      handleForward(selectedRoomId, shouldPreserveCaption);
    }
  }, [handleForward, selectedRoomId, shouldPreserveCaption]);

  const title = t("mediaCaptions");

  const content = (
    <SelectPreserveCaptions
      setShouldPreserveCaption={setShouldPreserveCaption}
      defaultState={true}
    />
  );

  const actions: FooterActions = [
    {
      icon: "send-03-fill",
      accessibilityLabel: t("send"),
      text: t("send"),
      onPress: handleSubmit,
      variant: "PRIMARY",
    },
    {
      accessibilityLabel: t("previous"),
      text: t("previous"),
      onPress: goToPreviousStep,
    },
  ];

  return <KDialogContent {...{ onClose, title, content, actions }} />;
};

export { PreserveCaptionStep };
