import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ActionCard } from "@kraaft/shared/components/actionCard";
import {
  selectSchemaIcon,
  selectSchemaName,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";

interface Props {
  selected: string[] | undefined;
  schemaId: string;
  openSelectionPanel: (schemaId: string) => () => void;
}

const ElementsItem = ({ selected, schemaId, openSelectionPanel }: Props) => {
  const { t } = useTranslation();

  const schemaName = useSelector(selectSchemaName(schemaId));
  const schemaIcon = useSelector(selectSchemaIcon(schemaId));

  return (
    <ActionCard
      text={schemaName ?? ""}
      iconName={KSchemaUtils.getSchemaIconName(schemaIcon)}
      showBracket
      description={t("generateReportSelected", {
        count: selected ? selected.length : 0,
      })}
      action={openSelectionPanel(schemaId)}
    />
  );
};

export { ElementsItem };
