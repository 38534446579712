import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { useMeshContext } from "@kraaft/helper-hooks";
import { DIRECTORY_MAX_DEPTH } from "@kraaft/shared/core/modules/directory/directory";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button, Icon } from "@kraaft/ui";
import { Directory } from "@kraaft/web/src/components/directoryTree/directoryTree.types";
import { DirectoryTreeContext } from "@kraaft/web/src/components/directoryTree/directoryTreeContext";
import { UnlabeledTextInput } from "@kraaft/web/src/components/unlabeledTextInput";

import { useValidateDirectoryName } from "./directoryTreeSimpleRow.utils";

import { useStyles } from "./directoryTreeSimpleRow.styles";

interface DirectoryTreeSimpleRowProps {
  directory: Directory;
  allowEdition?: boolean;
  isTemporaryDirectory?: boolean;
}

const DirectoryTreeSimpleRow = (props: DirectoryTreeSimpleRowProps) => {
  const { directory, allowEdition, isTemporaryDirectory } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const {
    renameDirectory,
    createTemporaryDirectory,
    persistTemporaryDirectory,
    removeTemporaryDirectory,
    removeDirectory,
  } = useMeshContext(DirectoryTreeContext);

  const renameDirectoryInputRef = useRef<HTMLInputElement>(null);
  const [isRenameState, openRenameFlow, closeRenameFlow] =
    useBooleanState(isTemporaryDirectory);

  const [newDirectoryName, setNewDirectoryName] = useState(
    isTemporaryDirectory ? "" : directory.name,
  );

  const newDirectoryNameIsValid = useValidateDirectoryName({
    directoryId: directory.id,
    newDirectoryName,
  });

  const handleRenameDirectoryPress = useCallback(() => {
    openRenameFlow();
  }, [openRenameFlow]);

  const handleCreateSubDirectoryPress = useCallback(() => {
    createTemporaryDirectory(directory.id);
  }, [createTemporaryDirectory, directory.id]);

  const handleDeleteDirectoryPress = useCallback(() => {
    removeDirectory(directory.id);
  }, [removeDirectory, directory.id]);

  const handleValidateRenameDirectory = useCallback(() => {
    if (!newDirectoryNameIsValid) {
      return;
    }
    if (isTemporaryDirectory) {
      persistTemporaryDirectory(
        directory.id,
        newDirectoryName,
        directory.parentDirectoryId,
      );
    } else {
      renameDirectory(directory.id, newDirectoryName);
    }
    closeRenameFlow();
  }, [
    closeRenameFlow,
    directory.id,
    directory.parentDirectoryId,
    isTemporaryDirectory,
    newDirectoryName,
    newDirectoryNameIsValid,
    persistTemporaryDirectory,
    renameDirectory,
  ]);

  const handleCancelRenameDirectory = useCallback(() => {
    if (isTemporaryDirectory) {
      removeTemporaryDirectory(directory.id);
    } else {
      setNewDirectoryName(directory.name);
    }
    closeRenameFlow();
  }, [
    closeRenameFlow,
    removeTemporaryDirectory,
    directory.id,
    directory.name,
    isTemporaryDirectory,
  ]);

  const handleInputBlur = useCallback(() => {
    if (!newDirectoryNameIsValid) {
      handleCancelRenameDirectory();
    } else {
      handleValidateRenameDirectory();
    }
  }, [
    newDirectoryNameIsValid,
    handleCancelRenameDirectory,
    handleValidateRenameDirectory,
  ]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        handleValidateRenameDirectory();
      }
    },
    [handleValidateRenameDirectory],
  );

  useEffect(() => {
    if (isRenameState) {
      setTimeout(() => renameDirectoryInputRef.current?.focus(), 0);
    }
  }, [isRenameState]);

  return (
    <div className={classes.container}>
      <Icon name="folder-fill" className={classes.icon} />
      {allowEdition && isRenameState ? (
        <div className={classes.inputContainer}>
          <UnlabeledTextInput
            ref={renameDirectoryInputRef}
            placeholder={
              isTemporaryDirectory
                ? t("directory.newSubDirectory")
                : directory.name
            }
            value={newDirectoryName}
            onChangeText={setNewDirectoryName}
            onKeyPress={handleKeyPress}
            hasError={!newDirectoryNameIsValid}
            onBlur={handleInputBlur}
          />
          <Button
            accessibilityLabel={t("close")}
            icon="x-close"
            variant="TERTIARY"
            size="SMALL"
            // onPressIn to fire the click event before the blur event
            onPressIn={handleCancelRenameDirectory}
          />
          <Button
            accessibilityLabel={t("validate")}
            icon="check"
            variant="TERTIARY"
            size="SMALL"
            onPressIn={handleValidateRenameDirectory}
            disabled={!newDirectoryNameIsValid}
          />
        </div>
      ) : (
        <>
          <div className={classes.name}>{directory.name}</div>
          {allowEdition && (
            <div
              className={clsx("rowActionsContainer", classes.actionsContainer)}
            >
              <Button
                accessibilityLabel={t("directoryActions.rename")}
                text={t("directoryActions.rename")}
                icon="edit-02"
                variant="TERTIARY"
                size="SMALL"
                onPress={handleRenameDirectoryPress}
              />
              <Button
                accessibilityLabel={t("directory.addSubDirectory")}
                text={t("directory.addSubDirectory")}
                icon="plus"
                variant="TERTIARY"
                size="SMALL"
                onPress={handleCreateSubDirectoryPress}
                disabled={directory.depth + 1 > DIRECTORY_MAX_DEPTH}
              />
              <Button
                accessibilityLabel={t("delete")}
                icon="trash-03"
                variant="TERTIARY"
                size="SMALL"
                onPress={handleDeleteDirectoryPress}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export { DirectoryTreeSimpleRow };
