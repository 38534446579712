import { ImageStyle, View } from "react-native";

import { Image } from "@kraaft/ui";

import MarkerBlue from "../assets/markerBlue.png";
import MarkerGrey from "../assets/markerGrey.png";

import { styles } from "./centerMarker.styles";

interface Props {
  width?: ImageStyle["width"];
  height?: ImageStyle["height"];
  bottom?: ImageStyle["bottom"];
  color?: "BLUE" | "GREY";
}

const CenterMarker = (props: Props) => {
  const { width, height = 50, bottom = "46.5%", color = "BLUE" } = props;

  const source = color === "GREY" ? MarkerGrey : MarkerBlue;

  return (
    <View>
      <Image
        source={source}
        style={[styles.marker, { width, height, bottom }]}
      />
    </View>
  );
};

export { CenterMarker };
