import { call, put, takeLatest } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import {
  SchemaActions,
  SchemaStateActions,
} from "@kraaft/shared/core/modules/schema/schema.actions";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { wait } from "@kraaft/shared/core/utils/promiseUtils";

export function* reorderSchemaSaga() {
  yield* takeLatest(SchemaActions.reorderSchemas, reorderSchemas);
}

function* reorderSchemas(
  action: ReturnType<(typeof SchemaActions)["reorderSchemas"]>,
) {
  try {
    yield* put(SchemaStateActions.optimistic.reorderSchemas(action.payload));
    yield* call(wait, 1000);

    yield* call(Api.reorderSchema, action.payload);
  } catch (e) {
    console.log("error reordering schema", e);
    yield* put(showError({ title: i18n.t("cannotReorderColumn") }));
  }
}
