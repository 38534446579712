import { LAUNCH_DARKLY_VERBOSE } from "@kraaft/shared/constants/global";
import { LaunchDarklySDK } from "@kraaft/shared/core/services/launchDarkly/sdk";
import {
  AbstractLaunchDarklySDK,
  FeatureFlagsListener,
  UserParams,
} from "@kraaft/shared/core/services/launchDarkly/sdk/types";

export type LogParams = {
  message: string;
  context?: Record<string, unknown>;
};

export abstract class LaunchDarklyService {
  private static instance: AbstractLaunchDarklySDK | undefined;

  /**
   * Inits the SDK at start
   * @param envName the environment name associated to logs
   */
  public static async init() {
    if (LaunchDarklyService.instance) {
      return LaunchDarklyService.debug("Prevented duplicated init");
    }

    LaunchDarklyService.debug("Init");

    LaunchDarklyService.instance = new LaunchDarklySDK();

    await LaunchDarklyService.instance.init();
  }

  /**
   * Declare a user to DD
   * @param user the user associated to logs
   */
  public static async setUser(user: UserParams) {
    LaunchDarklyService.debug("[User]", "Set user", user);

    await LaunchDarklyService.instance?.setUser(user);
  }

  public static async subscribe(listener: FeatureFlagsListener) {
    LaunchDarklyService.debug("[Subscription]", "Subscribed");

    await LaunchDarklyService.instance?.subscribe(listener);
  }

  public static async unsubscribe() {
    LaunchDarklyService.debug("[Subscription]", "Unsubscribed");

    LaunchDarklyService.instance?.unsubscribe();
  }

  /**
   * Debugger, used as a milestone during the service's inner processes
   */
  private static debug(...args: unknown[]) {
    if (LAUNCH_DARKLY_VERBOSE) {
      console.groupCollapsed("LaunchDarklyService");
      console.log(...args);
      console.groupEnd();
    }
  }
}
