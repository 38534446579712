import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { simpleClipboardTextFromCell } from "@kraaft/web/src/components/modularTable/components/table/clipboardTextFromCellUtils";
import { MultiLineTextEditor } from "@kraaft/web/src/components/modularTable/components/table/editors/multiLineTextEditor";
import { stringRecordComparator } from "@kraaft/web/src/components/modularTable/components/table/recordComparatorUtils";
import { simpleValueFromClipboardText } from "@kraaft/web/src/components/modularTable/components/table/valueFromClipboardTextUtils";
import { IFieldConfig } from "@kraaft/web/src/components/modularTable/components/types";

import { withBasicCell } from "../_withTableCell/withBasicCell";
import { LongTextCell } from "./basicCell";

export const longTextConfig: IFieldConfig<KColumnType.longText> = {
  type: KColumnType.longText,
  TableCell: withBasicCell(LongTextCell),
  TableEditor: MultiLineTextEditor,
  comparator: stringRecordComparator,
  getClipboardTextFromCell: simpleClipboardTextFromCell,
  getValueFromClipboardText: simpleValueFromClipboardText,
};
