import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { serializePollingDescriptor } from "@kraaft/shared/core/modules/polling/pollingHelper";
import { PollingDescriptor } from "@kraaft/shared/core/modules/polling/pollingState";
import { RootState } from "@kraaft/shared/core/store";
import { lodashKeyResolver } from "@kraaft/shared/core/utils";

const selectPooling = (state: RootState) => state.polling;

export const selectPollingUnit = (descriptor: PollingDescriptor) =>
  createSelector(
    selectPooling,
    (polling) => polling[serializePollingDescriptor(descriptor)],
  );

export const selectPollingData = memoize(
  (descriptor: PollingDescriptor | string) =>
    createSelector(selectPooling, (polling) => {
      const key =
        typeof descriptor === "string"
          ? descriptor
          : serializePollingDescriptor(descriptor);
      return polling[key]?.lastData;
    }),
  lodashKeyResolver,
);
