import { useSelector } from "react-redux";
import { flatten } from "lodash";

import { selectSubDirectoriesOfDirectoryId } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";

export function useFilteredDirectorySubItems(
  directoryId: string,
  filter: string,
) {
  const allSubDirectories = useSelector(
    selectSubDirectoriesOfDirectoryId(directoryId, filter.length === 0),
  );
  if (filter.length === 0) {
    return { directories: [], files: [] };
  }
  const allFiles = flatten(allSubDirectories.map((d) => d.files));
  return {
    directories: allSubDirectories.filter(
      (d) =>
        d.id !== directoryId &&
        normalizeTextForSearch(d.name).includes(normalizeTextForSearch(filter)),
    ),
    files: allFiles.filter((f) =>
      normalizeTextForSearch(f.name).includes(normalizeTextForSearch(filter)),
    ),
  };
}
