import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { AssignOrManageCompanySheet } from "@kraaft/shared/core/modules/company/components/assignOrManageCompany.sheet";
import { useCompany } from "@kraaft/shared/core/modules/company/hooks/useCompany";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { Api } from "@kraaft/shared/core/services/api";
import { Button } from "@kraaft/ui";

import { useStyles as useSettingsStyles } from "../settings.styles";

interface Props {
  pool: Pool | undefined;
}

export const SettingsCompany = (props: Props) => {
  const { pool } = props;
  const { t } = useTranslation();
  const company = useCompany(props.pool?.companyId);

  const settingsClasses = useSettingsStyles();

  const handleSetCompany = useCallback(
    async (companyId: string | undefined) => {
      if (!pool) {
        return;
      }
      try {
        await Api.setPoolCompany({
          poolId: pool.id,
          companyId: companyId,
        });
      } catch (e) {}
    },
    [pool],
  );

  const { open, element } = AssignOrManageCompanySheet.use({
    value: pool?.companyId,
    onChange: handleSetCompany,
  });

  return (
    <>
      <div className={settingsClasses.container}>
        <span className={settingsClasses.title}>
          {t("support.company")}
          {t("colon")}
        </span>
        <span className={settingsClasses.subTitle}>
          {company ? company.name : t("unassigned")}
        </span>
        <span className={settingsClasses.button}>
          <Button
            onPress={open}
            text={t("edit")}
            size="SMALL"
            variant="SECONDARY"
          />
        </span>
      </div>
      {element}
    </>
  );
};
