import { View } from "react-native";

import { RoomPreface } from "@kraaft/shared/components/conversation/preface/roomPreface";

import { MessageListTopProps, styles } from "./messageListTop.utils";

export const MessageListTop = ({ roomId }: MessageListTopProps) => {
  return (
    <View style={styles.preface}>
      <RoomPreface roomId={roomId} />
    </View>
  );
};
