import { makeStyles } from "@mui/styles";

import { PixelSize } from "@kraaft/ui";

export const useStyles = makeStyles({
  modalContentContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: PixelSize.S32,
  },
});
