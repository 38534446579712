import { AvatarProps } from "@kraaft/shared/components/avatar/avatar.types";

export function generateAvatarProps(
  imageUri?: string,
  initials?: string,
): AvatarProps {
  if (imageUri) {
    return { type: "image", source: { uri: imageUri } };
  }
  if (initials) {
    return { type: "initials", initials };
  }
  return { type: "icon", name: "user-01" };
}
