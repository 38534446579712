import React, { useCallback, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  TextMessageInput,
  TextMessageInputHandle,
} from "@kraaft/shared/components/textMessageInput";
import { constants } from "@kraaft/shared/constants/constants";
import { InputPartitionHelper } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { Mention } from "@kraaft/shared/core/framework/mentionnableText/mention";
import { MentionAwareText } from "@kraaft/shared/core/framework/mentionnableText/mentionAwareText";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { fileHelper } from "@kraaft/shared/core/modules/file/fileHelper";
import { unsetReplyingSourceMessage } from "@kraaft/shared/core/modules/message/messageActions";
import { selectReplyingSourceMessage } from "@kraaft/shared/core/modules/message/messageSelectors";
import { sendTextMessage } from "@kraaft/shared/core/modules/message/send/sendMessageActions";
import { uuid } from "@kraaft/shared/core/utils";
import { checkInputFileSize } from "@kraaft/web/src/core/utils/useCheckFile";
import { useAddFilesToRoom } from "@kraaft/web/src/views/messenger/conversation/dropzone/dropzoneUtils";

interface Props {
  roomId: string;
  mentionAwareText: MentionAwareText;
  availableMentions: Mention[];
}

export interface TextMessageSenderHandle {
  focus(): void;
}

const TextMessageSender = React.forwardRef<TextMessageSenderHandle, Props>(
  ({ roomId, mentionAwareText, availableMentions }, ref) => {
    const inputRef = useRef<TextMessageInputHandle>(null);
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
    }));

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const answerTo = useSelector(selectReplyingSourceMessage(roomId));

    const addFilesToRoom = useAddFilesToRoom(roomId);

    const onPublishMessage = useCallback(() => {
      const trimmedInputPartitions = InputPartitionHelper.trimInputPartitions(
        InputPartitionHelper.segmentsToInputPartitions(
          mentionAwareText.getSegments(),
        ),
      );

      if (trimmedInputPartitions.length === 0) {
        return;
      }

      const requestId = uuid();

      dispatch(
        sendTextMessage({
          roomId,
          text: trimmedInputPartitions,
          requestId,
          answerTo: answerTo?.id,
        }),
      );
      dispatch(unsetReplyingSourceMessage(roomId));
      setTimeout(() => {
        mentionAwareText.clear();
      }, 0);
    }, [answerTo?.id, dispatch, mentionAwareText, roomId]);

    const handlePaste = useCallback(
      (event: React.ClipboardEvent) => {
        const files = Array.from(event.clipboardData.files);

        if (files.length === 0) {
          return;
        }
        const result = checkInputFileSize({
          files,
          maxSize: constants.FILE_SIZE_LIMIT,
        });

        addFilesToRoom(
          result.acceptedFiles.map((file) => fileHelper.fromWebFile(file)),
        );

        if (!result.status) {
          dispatch(showError({ title: t("pasteFilesError") }));
          event.stopPropagation();
          event.preventDefault();
        }
      },
      [addFilesToRoom, dispatch, t],
    );

    return (
      <div onPaste={handlePaste}>
        <TextMessageInput
          ref={inputRef}
          mentionAwareText={mentionAwareText}
          availableMentions={availableMentions}
          isEditingMessage={false}
          onPublish={onPublishMessage}
        />
      </div>
    );
  },
);

export { TextMessageSender };
