import { ConditionLibraryForType } from "@kraaft/shared/core/generated/__generated/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";

export const numberCondition: ConditionLibraryForType<KColumnType.number> = {
  number: {
    isEqual: {
      translationKey: "equalSymbol",
      check: (lhs, rhs) => lhs.value === rhs.value,
    },
    isNotEqual: {
      translationKey: "unequalSymbol",
      check: (lhs, rhs) => lhs.value !== rhs.value,
    },
    isGreaterThan: {
      translationKey: "greaterThanSymbol",
      check: (lhs, rhs) =>
        lhs.value !== undefined &&
        rhs.value !== undefined &&
        lhs.value > rhs.value,
    },
    isGreaterThanOrEqual: {
      translationKey: "greaterThanOrEqualSymbol",
      check: (lhs, rhs) =>
        lhs.value !== undefined &&
        rhs.value !== undefined &&
        lhs.value >= rhs.value,
    },
    isLessThan: {
      translationKey: "lessThanSymbol",
      check: (lhs, rhs) =>
        lhs.value !== undefined &&
        rhs.value !== undefined &&
        lhs.value < rhs.value,
    },
    isLessThanOrEqual: {
      translationKey: "lessThanOrEqualSymbol",
      check: (lhs, rhs) =>
        lhs.value !== undefined &&
        rhs.value !== undefined &&
        lhs.value <= rhs.value,
    },
  },
  standalone: {
    isEmpty: {
      translationKey: "isEmpty",
      check: (value) => value.value === undefined,
    },
    isNotEmpty: {
      translationKey: "isNotEmpty",
      check: (value) => value.value !== undefined,
    },
  },
};
