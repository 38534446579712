import { MouseEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { AlertDialog } from "@kraaft/shared/components/alertDialog";
import { PoolMember } from "@kraaft/shared/core/modules/poolMember/poolMemberState";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { Icon } from "@kraaft/ui";
import { ensureColumnType } from "@kraaft/web/src/components/kTable/kTableUtils";
import { SelectRole } from "@kraaft/web/src/views/settings/manageMembers/useRoleColumn/selectRole";

import { comparePoolMembers } from "./useRoleColumn.utils";

import { useStyles } from "./useRoleColumn.styles";

const useRoleColumn = (props: {
  width?: React.CSSProperties["width"];
  currentUserRole: UserPoolRole;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentUserRole = props.currentUserRole;

  const handleClickRoleHeader = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      AlertDialog.alert(t("role"), t("roleExplanation"));
    },
    [t],
  );

  return ensureColumnType({
    id: "role",
    label: (
      <span className={classes.header}>
        {t("role")}
        <span onClick={handleClickRoleHeader} className={classes.infoAlertIcon}>
          <Icon name="info-circle" size="MEDIUM" />
        </span>
      </span>
    ),
    value: (item: PoolMember): PoolMember => item,
    component: (value) => {
      return <SelectRole member={value} currentUserRole={currentUserRole} />;
    },
    compare: comparePoolMembers,
    width: props.width,
  });
};

export { useRoleColumn };
