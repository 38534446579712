import moment from "moment";

import { useCallback, useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";

import { DateTimePicker } from "@kraaft/shared/components/dateTimePicker";
import { baseStyle } from "@kraaft/shared/components/kDateInput/kDateInput.styles";
import { TrackableViewProps } from "@kraaft/shared/core/types";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Icon } from "@kraaft/ui";

type KDateInputProps = Partial<TrackableViewProps> & {
  label: string;
  defaultValue?: Date;
  width?: number;
} & (
    | {
        forceValue?: false;
        onChange: (value: Date | undefined) => void;
      }
    | {
        forceValue: true;
        onChange: (value: Date) => void;
      }
  );

const KDateInput = ({
  accessibilityLabel: _accessibilityLabel,
  label,
  defaultValue,
  forceValue,
  width,
  onChange,
}: KDateInputProps) => {
  const [value, setValue] = useState(defaultValue ?? new Date());
  const [isOpened, open, close] = useBooleanState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const styles = StyleSheet.create({
    ...baseStyle,
    wrapper: { ...baseStyle.wrapper, width },
  });

  const setAndClose = useCallback(
    (newDate: Date | undefined) => {
      if (!forceValue) {
        onChange(newDate);
      } else if (newDate) {
        onChange(newDate);
      }
      close();
    },
    // Disabling otherwise eslint want to set [props] here
    // Cant destructure otherwise onChange is not linked to forceValue anymore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [close, onChange, forceValue],
  );

  const accessibilityLabel = _accessibilityLabel || label;

  return (
    <>
      <TouchableOpacity
        accessibilityLabel={accessibilityLabel}
        style={styles.wrapper}
        onPress={open}
      >
        <View style={styles.label}>
          <Text style={styles.labelText}>{label}</Text>
          <Icon style={styles.labelIcon} size="SMALL" name="calendar" />
        </View>
        <TextInput
          ref={ref}
          accessibilityLabel={accessibilityLabel}
          nativeID="ide2e-date-input"
          style={styles.text}
          onTouchEnd={open}
          value={moment(value).format("L")}
          editable={false}
        />
      </TouchableOpacity>
      <DateTimePicker
        visible={isOpened}
        date={value}
        onClose={close}
        onChange={setAndClose}
        anchorRef={ref}
        forceValue={forceValue}
      />
    </>
  );
};

export { KDateInput };
