import { forwardRef, useCallback } from "react";
import { FlatList, ListRenderItem, StyleSheet, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { EveryoneMentionItem } from "@kraaft/shared/components/textInputWithMentions/everyoneMentionItem";
import {
  MAX_MENTION_PAPER_HEIGHT,
  MENTION_PORTAL_RENDERER_HOSTNAME,
} from "@kraaft/shared/components/textInputWithMentions/mentionPortalRenderer";
import { useNavigateMentions } from "@kraaft/shared/components/textInputWithMentions/useNavigateMentions";
import { MentionPaperHandle } from "@kraaft/shared/components/textInputWithMentions/useNavigateMentions.props";
import { UserMentionItem } from "@kraaft/shared/components/textInputWithMentions/userMentionItem";
import { UsernameGetter } from "@kraaft/shared/core/framework/markedText/markers/userMention.marker";
import { Mention } from "@kraaft/shared/core/framework/mentionnableText/mention";
import { Color, Portal, Radius, Spacing } from "@kraaft/ui";

interface MentionPaperProps {
  getUsernameFromUserId: UsernameGetter;
  mentions?: Mention[];
  onClickMention: (mention: Mention) => void;
  inputFocused: boolean;
}

function mentionKeyExtractor(mention: Mention, index: number) {
  if (mention.type === "everyone") {
    return `${index}-everyone`;
  }
  return `${index}-${mention.id}`;
}

export const MentionPaper = forwardRef<MentionPaperHandle, MentionPaperProps>(
  ({ getUsernameFromUserId, mentions, onClickMention, inputFocused }, ref) => {
    const {
      show,
      onCursorInPaper,
      onMentionHovered,
      hoveredIndex,
      userMentionRef,
    } = useNavigateMentions({
      mentions,
      inputFocused,
      handle: ref,
      insertMention: onClickMention,
    });

    const handleMouseEnter = useCallback(() => {
      onCursorInPaper(true);
    }, [onCursorInPaper]);

    const handleMouseLeave = useCallback(() => {
      onCursorInPaper(false);
    }, [onCursorInPaper]);

    const renderMentionItem = useCallback<ListRenderItem<Mention>>(
      ({ item: mention, index }) => {
        if (mention.type === "everyone") {
          return (
            <EveryoneMentionItem
              ref={userMentionRef(index)}
              onHovered={() => onMentionHovered(index)}
              onPress={() => onClickMention(mention)}
              hovered={hoveredIndex === index}
            />
          );
        }
        return (
          <UserMentionItem
            ref={userMentionRef(index)}
            onHovered={() => onMentionHovered(index)}
            username={getUsernameFromUserId(mention.id)}
            onPress={() => onClickMention(mention)}
            hovered={hoveredIndex === index}
          />
        );
      },
      [
        getUsernameFromUserId,
        hoveredIndex,
        onClickMention,
        onMentionHovered,
        userMentionRef,
      ],
    );

    if (!show) {
      return null;
    }

    if (!mentions || mentions.length === 0) {
      return null;
    }

    return (
      <Portal hostname={MENTION_PORTAL_RENDERER_HOSTNAME}>
        <View style={styles.root}>
          <FlatList
            keyExtractor={mentionKeyExtractor}
            data={mentions}
            renderItem={renderMentionItem}
            keyboardShouldPersistTaps="handled"
            style={styles.mentions}
            disableVirtualization={!isNative()}
            // @ts-expect-error this prop exists
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </View>
      </Portal>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
    left: Spacing.S8,
    right: Spacing.S8,
    justifyContent: "flex-end",
  },
  mentions: {
    pointerEvents: "auto",
    flexGrow: 0,
    borderRadius: Radius.MEDIUM,
    borderColor: Color.GREY_FRENCH,
    borderWidth: 1,
    backgroundColor: Color.WHITE,
    maxHeight: MAX_MENTION_PAPER_HEIGHT,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 4.65,

    elevation: 6,
  },
});
