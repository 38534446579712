import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { DefaultLayoutHeaderContentProps } from "@kraaft/shared/components/defaultLayoutHeader/defaultLayoutHeader.types";
import { Spacing } from "@kraaft/ui";

export const SimpleHeaderLayout = ({
  headerLeft,
  headerRight,
  headerOverflow,
}: DefaultLayoutHeaderContentProps) => {
  const headerLeftStyles = useMemo(
    () => [
      styles.leftContainer,
      Boolean(headerLeft) && styles.innerSpacingLeft,
    ],
    [headerLeft],
  );

  const headerRightStyles = useMemo(
    () => [
      styles.rightContainer,
      Boolean(headerRight) && styles.innerSpacingRight,
    ],
    [headerRight],
  );

  return (
    <View style={styles.container}>
      {(headerLeft || !headerOverflow) && (
        <View style={headerLeftStyles}>{headerLeft}</View>
      )}
      {(headerRight || !headerOverflow) && (
        <View style={headerRightStyles}>{headerRight}</View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    paddingHorizontal: Spacing.S16,
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  rightContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  innerSpacingLeft: {
    paddingRight: Spacing.S16,
  },
  innerSpacingRight: {
    paddingLeft: Spacing.S16,
  },
});
