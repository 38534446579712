import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { stringContainsCharacters } from "@kraaft/shared/core/utils";
import { Button } from "@kraaft/ui";

import { styles } from "./inputAccessory.styles";

interface Props {
  value: string;
  onChangeText: (value: string) => void;
  label: string;
  onSubmit: () => void;
  id?: string;
}

export const inputAccessoryBackgroundColor = styles.background.backgroundColor;

const InputAccessory = ({
  onSubmit,
  label: placeholder,
  value,
  onChangeText,
  id,
}: Props) => {
  const { t } = useTranslation();

  const isValueEmpty = !stringContainsCharacters(value);

  const handleSubmit = useCallback(() => {
    if (!isValueEmpty) {
      onSubmit();
    }
  }, [isValueEmpty, onSubmit]);

  return (
    <View style={styles.background}>
      <View style={styles.inputContainer}>
        <KInput
          nativeID={id}
          value={value}
          label={placeholder}
          onChangeText={onChangeText}
          returnKeyType="done"
          onSubmitEditing={handleSubmit}
          blurOnSubmit={false}
        />
      </View>
      <Button
        accessibilityLabel={t("validate")}
        disabled={isValueEmpty}
        icon="check"
        onPress={handleSubmit}
        id={id && `${id}-button`}
      />
    </View>
  );
};

export { InputAccessory };
