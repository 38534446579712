import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import differenceBy from "lodash/differenceBy";
import uniqBy from "lodash/uniqBy";

import { SelectableUser } from "@kraaft/shared/core/modules/user/userTypes";

type Props = {
  preselectedUsers: Array<SelectableUser>;
  setSelectedUsers: Dispatch<SetStateAction<Array<SelectableUser>>>;
};

/**
 * Updates the selected users based on the changes coming from the server/firebase.
 * This effect tries to enable concurrent update for the member of a room.
 *
 * @param props - The properties object containing the necessary parameters.
 * @param props.preselectedUsers - The array of preselected users.
 * @param props.setSelectedUsers - The function to set the selected users.
 */
export function useConcurrentUserSelectionUpdate({
  preselectedUsers,
  setSelectedUsers,
}: Props) {
  const initialSelectedUserRef = useRef(preselectedUsers);

  useEffect(() => {
    const usersToDelete = differenceBy(
      initialSelectedUserRef.current,
      preselectedUsers,
      (u) => u.id,
    );

    const usersToAdd = differenceBy(
      preselectedUsers,
      initialSelectedUserRef.current,
      (u) => u.id,
    );

    setSelectedUsers((oldSelectedUser) => {
      const newSelectedUser = uniqBy(
        oldSelectedUser
          .filter((user) => !usersToDelete.some((it) => it.id === user.id))
          .concat(usersToAdd),
        (user) => user.id,
      );
      initialSelectedUserRef.current = preselectedUsers;
      return newSelectedUser;
    });
  }, [preselectedUsers, setSelectedUsers]);
}
