import moment from "moment";

import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { KInput, KInputProps } from "@kraaft/shared/components/input/KInput";
import { ErrorInfo } from "@kraaft/shared/core/modules/common";
import { TFunction } from "@kraaft/shared/core/services/i18next";
import { Color, ColorStyle, Text } from "@kraaft/ui";
import {
  detailsSharepointStyleSheet,
  useDetailsSharepointStyles,
} from "@kraaft/web/src/components/editRoomSharepointLink/editRoomSharepointLink.style";

const isSharePointLink = (url: string) => {
  return !/\s/.test(url) && /^https:\/\/[^\s.]*.sharepoint.com/.test(url);
};

export const SharepointUrlInput = ({
  url,
  setUrl,
  synchronized,
  loading,
  disabled,
  style,
}: {
  url: string;
  setUrl: (url: { value: string; isValid: boolean }) => void;
  synchronized: boolean;
  loading?: boolean;
  disabled?: boolean;
  style?: KInputProps["inputContainerStyle"];
}) => {
  const { t } = useTranslation();

  const isValid = isSharePointLink(url);

  let status: KInputProps["status"];
  if (url && !isValid) {
    status = "erroneous";
  } else if (synchronized) {
    status = "validated";
  }

  const onChangeText = useCallback(
    (text: string) => {
      setUrl({ value: text, isValid: isSharePointLink(text) });
    },
    [setUrl],
  );

  return (
    <KInput
      defaultValue={url}
      inputContainerStyle={style}
      label={t("sharepointConnectorInputPlaceholder")}
      returnKeyType="done"
      returnKeyLabel={t("validate")}
      onChangeText={onChangeText}
      nativeID="ide2e-sharepoint-endpoint"
      blurOnSubmit={true}
      status={status}
      showEditIcon={!disabled}
      disabled={disabled || loading}
    />
  );
};

export function sharepointSyncMessage(
  t: TFunction,
  synchronizedAt: Date | undefined,
) {
  return (
    t("sharepointSynchonizationActive") +
    (synchronizedAt ? ` (${moment(synchronizedAt).format("LLL")})` : "")
  );
}

export const SharepointUrlValidationBox = ({
  message,
  status,
}: {
  message: string;
  status?: "validated" | "erroneous";
}) => {
  const color =
    (status === "validated" && Color.GREEN_LAGOON) ||
    (status === "erroneous" && ColorStyle.ACTION_DESCTRUCTIVE) ||
    ColorStyle.FONT_LOW_EMPHASIS;

  return (
    <View style={detailsSharepointStyleSheet.validationBox}>
      <Text
        style={[detailsSharepointStyleSheet.validationText, { color }]}
        weight="light"
      >
        {message}
      </Text>
    </View>
  );
};

export const SharepointUrlErrorBox = ({
  error,
}: {
  error: ErrorInfo | undefined;
}) => {
  const classes = {
    ...useDetailsSharepointStyles(),
  };
  const { t } = useTranslation();

  return (
    <>
      {error ? (
        <div className={classes.warning}>
          <Text
            numberOfLines={6}
            selectable={false}
            color="ORANGE_BASIC"
            size={12}
            weight="bold"
          >
            {t("microsoftStorageRoomErrorTitle")}
          </Text>
          <Text selectable={false} color="ORANGE_BASIC" size={11}>
            {t("microsoftStorageRoomErrorDetails", {
              lastFailedAt: error.lastFailedAt,
            })}
          </Text>
          <Text numberOfLines={5} selectable color="ORANGE_BASIC" weight="bold">
            {"\n"}
            {error.lastMessage}
          </Text>
        </div>
      ) : null}
    </>
  );
};
