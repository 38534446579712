import { StyleSheet, View } from "react-native";

import { ColorStyle, Spacing } from "@kraaft/ui";

const SectionSpacer = () => {
  return <View style={styles.spacer} />;
};

const styles = StyleSheet.create({
  spacer: {
    height: Spacing.S8,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
});

export { SectionSpacer };
