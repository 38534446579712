import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useBooleanState } from "@kraaft/helper-hooks";
import { useCheckboxGuardProps } from "@kraaft/shared/components/modularFolderList/useCheckboxGuardProps";
import { CheckboxGuard } from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuard";
import {
  selectSchemaHighlightedColumn,
  selectSchemaLockLookup,
} from "@kraaft/shared/core/modules/schema/schema.selectors";

interface CheckboxGuardCallbacks {
  onConfirm: () => void;
  onCancel?: () => void;
}

export type OpenCheckboxGuard = (
  modularFolderId: string,
  callbacks?: CheckboxGuardCallbacks,
) => void;

export function useCheckboxGuard(schemaId: string) {
  const [focusedModularFolderId, setFocusedModularFolderId] = useState<
    string | undefined
  >(undefined);
  const checkboxGuardCallbacks = useRef<CheckboxGuardCallbacks | undefined>(
    undefined,
  );
  const [isGuardOpen, setGuardOpened, setGuardClosed] = useBooleanState();

  const closeCheckboxGuard = useCallback(() => {
    checkboxGuardCallbacks.current = undefined;
    setGuardClosed();
    setFocusedModularFolderId(undefined);
  }, [setGuardClosed]);

  const handleConfirm = useCallback(() => {
    checkboxGuardCallbacks.current?.onConfirm();
    closeCheckboxGuard();
  }, [closeCheckboxGuard]);

  const handleCancel = useCallback(() => {
    checkboxGuardCallbacks.current?.onCancel?.();
    closeCheckboxGuard();
  }, [closeCheckboxGuard]);

  const openCheckboxGuard = useCallback<OpenCheckboxGuard>(
    (modularFolderId, callbacks) => {
      checkboxGuardCallbacks.current = callbacks;
      setFocusedModularFolderId(modularFolderId);
      setGuardOpened();
    },
    [setGuardOpened],
  );

  const highlightedCheckboxColumn = useSelector(
    selectSchemaHighlightedColumn(schemaId),
  );

  const schemaLockLookup = useSelector(selectSchemaLockLookup(schemaId));

  const checkboxGuardProps = useCheckboxGuardProps({
    schemaLockLookup,
    highlightedCheckboxColumn,
    focusedModularFolderId,
  });

  return {
    checkboxGuard: checkboxGuardProps ? (
      <CheckboxGuard
        strategy={isGuardOpen ? checkboxGuardProps.strategy : undefined}
        sectionLockInfo={checkboxGuardProps.sectionLockInfo}
        onConfirm={handleConfirm}
        onClose={handleCancel}
        columnName={checkboxGuardProps.columnName}
      />
    ) : null,
    openCheckboxGuard,
  };
}
