import { useMemo } from "react";
import clsx from "clsx";
import type { ImageStyle } from "expo-image";

import { BaseMediaMarkerProps } from "@kraaft/shared/components/geolocation/baseMediaMarker/baseMediaMarker.types";
import { getPillCountLabel } from "@kraaft/shared/components/geolocation/baseMediaMarker/baseMediaMarker.utils";
import { Pill } from "@kraaft/shared/components/pill";
import { Color, ColorStyle, Image, morphClassNameAsStyle } from "@kraaft/ui";

import { ReactComponent as MarkerSvg } from "../assets/mediaMarker.svg";

import { useStyles } from "./baseMediaMarker.styles";

const BaseMediaMarker = (props: BaseMediaMarkerProps) => {
  const { thumbnailUrl, pillCount, isHovered, isSelected } = props;
  const isActive = Boolean(isHovered || isSelected);

  const { bodyColor, strokeColor } = useMemo(() => {
    if (isActive) {
      return { bodyColor: ColorStyle.ACTION_CTA, strokeColor: "none" };
    }
    return { bodyColor: Color.WHITE, strokeColor: Color.GREY_TAUPE };
  }, [isActive]);

  const pillCountLabel = getPillCountLabel(pillCount);

  const classes = useStyles({
    isActive,
    bodyColor,
    strokeColor,
  });

  return (
    <div
      className={clsx(
        classes.markerContainer,
        isActive && classes.markerContainerActive,
      )}
    >
      {pillCountLabel ? (
        <div className={classes.pillContainer}>
          <Pill color={ColorStyle.ACTION_CTA} content={pillCountLabel} />
        </div>
      ) : null}
      <div className={classes.markerContent}>
        <div className={classes.innerContent}>
          <Image
            style={morphClassNameAsStyle(classes.media) as ImageStyle}
            source={thumbnailUrl}
            alt="media preview marker"
            preferredSize="thumbnail"
          />
        </div>
      </div>
      <MarkerSvg className={classes.marker} />
    </div>
  );
};

export { BaseMediaMarker };
