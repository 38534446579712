import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles(() => ({
  rowContainer: {
    padding: `${Spacing.S12 / 2}px 0px`,
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: Spacing.S16,
  },
}));
