import { Dummy } from "@kraaft/shared/core/modules/dummy/dummy";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { BlobResolveError } from "@kraaft/shared/core/modules/file/fileUploader";
import { FileUploadHelper } from "@kraaft/shared/core/modules/file/fileUploadHelper";
import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { createLocalAttachmentFromModernFile } from "@kraaft/shared/core/modules/modularFolder/modularFolderUtils";
import { DummyApi } from "@kraaft/shared/core/services/api/dummyApi";
import { taskManager } from "@kraaft/shared/core/store/offline";
import { DeclareOfflineFeature } from "@kraaft/shared/core/utils/optimistic/newOptimistic/createOfflineFeature";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";
import { TaskExecutionError } from "@kraaft/shared/core/utils/optimistic/newOptimistic/taskStore/taskStore";
import { uuid } from "@kraaft/web/src/core/utils";

export const DummyOfflineFeature = DeclareOfflineFeature<Dummy>("Dummy")(
  {
    rename: Operation.editOne<Dummy>()
      .payload<{ name: string }>()
      .expected((data, payload) => {
        data.name = payload.name;
        return data;
      })
      .mutate(async (payload, task) => {
        const { updatedAt } = await DummyApi.rename({
          requestId: task.id,
          id: payload.id,
          name: payload.name,
        });
        return { updatedAt };
      }),
    delete: Operation.deleteOne<Dummy>().mutate(async (payload, { id }) => {
      await DummyApi.delete({
        requestId: id,
        id: payload.id,
      });
    }),
    create: Operation.createOne<Dummy>()
      .payload<{ name: string }>({ replaceId(payload, oldId, newId) {} })
      .expected((payload) => {
        return {
          id: payload.id,
          name: payload.name,
          optimisticId: payload.id,
          files: [],
          updatedAt: new Date(),
        };
      })
      .mutate(async (payload, { id: taskId }) => {
        const { id } = await DummyApi.create({
          requestId: taskId,
          name: payload.name,
        });
        return id;
      }),
    uploadAndAttachFiles: Operation.editOne<Dummy>()
      .payload<{
        files: Array<ModernFile<LocalPath>>;
        currentUserId: string;
      }>()
      .expected((data, payload) => {
        const attachements = payload.files.map((file) => {
          return {
            id: `local-attachment-${uuid()}`,
            createdAt: new Date(),
            senderUserId: payload.currentUserId,
            ...createLocalAttachmentFromModernFile(file),
          } satisfies Attachment;
        });
        data.files = data.files.concat(attachements);
        return data;
      })
      .mutate(async (payload, task) => {
        const { files, id } = payload;

        let updatedAt: Date;
        try {
          ({ updatedAt } = await FileUploadHelper.uploadThreeSteps(files, {
            async createUploadPaths(f) {
              return await DummyApi.createDummyFileUploadPath({
                dummyId: id,
                attachments: f.map((it) => ({
                  filename: it.filename,
                })),
              });
            },
            async onceUploaded(uploadedFiles) {
              return DummyApi.attachFileDummy({
                requestId: task.id,
                dummyId: payload.id,
                attachments: uploadedFiles.map((it) => ({
                  filename: it.file.filename,
                  storagePath: it.storagePath,
                })),
              });
            },
          }));
        } catch (e) {
          if (e instanceof BlobResolveError) {
            throw new TaskExecutionError("skip", e);
          }
          throw e;
        }

        return { updatedAt };
      }),
    renameMany: Operation.edit<Dummy>()
      .payload<{ names: Record<string, string> }>({
        gatherIds(payload) {
          return Object.keys(payload.names);
        },
        replaceId(payload, oldId, newId) {
          const data = payload.names[oldId];
          if (!data) {
            return;
          }
          delete payload.names[oldId];
          payload.names[newId] = data;
        },
      })
      .expected((datas, payload) => {
        for (const data of Object.values(datas)) {
          data.name = payload.names[data.id] ?? "omg??";
        }
        return datas;
      })
      .mutate((payload, { id }) =>
        DummyApi.renameMany({
          requestId: id,
          names: Object.entries(payload.names).map(([key, value]) => ({
            id: key,
            name: value,
          })),
        }),
      )
      .acknowledgeOn((datas, payload, result) => {
        return Object.keys(payload.names).some(
          (id) =>
            (datas[id]?.updatedAt.getTime() ?? 0) >= result.updatedAt.getTime(),
        );
      }),
  },
  taskManager.createTaskManager("MyAggregate"),
);
