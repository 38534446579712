import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useUserDropdownItems } from "@kraaft/shared/components/modular/details/editors/userEditor/useUserDropdownItems";
import {
  Condition,
  CoupleRecordCondition,
} from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { useGetOrLoadUsers } from "@kraaft/shared/core/modules/user/useGetOrLoadUsers";
import { selectAllPoolUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { getBuiltinCondition } from "@kraaft/web/src/components/filterMenu/filterUtils";

import { useStyles } from "./filterOptions.styles";

interface UserFilterOptionsProps {
  condition: CoupleRecordCondition;
  setCondition: (condition: Condition) => void;
  column: KSchemaColumn;
  valueColumnType: KColumnType.user | KColumnType.automatedCreatedBy;
}

/** @TODO : Mutualize w/ SelectFilterOptions */
const UserFilterOptions = (props: UserFilterOptionsProps): JSX.Element => {
  const { condition, setCondition, column, valueColumnType } = props;
  const classes = useStyles();
  const currentPoolId = useSelector(selectCurrentPoolId);
  const users = useSelector(selectAllPoolUsers(currentPoolId));
  const getOrLoadUsers = useGetOrLoadUsers();
  const { t } = useTranslation();

  const builtinCondition = getBuiltinCondition(
    column.type,
    KColumnType.user,
    condition.predicate,
  );

  const selectedOptionIds = useMemo(
    () =>
      condition.value.columnType === KColumnType.user ||
      condition.value.columnType === KColumnType.automatedCreatedBy
        ? condition.value.value ?? []
        : [],
    [condition],
  );

  const options = useUserDropdownItems({
    selectedUserIds: selectedOptionIds,
    selectedUsers: getOrLoadUsers(selectedOptionIds),
    poolUsers: users,
    poolId: currentPoolId ?? "",
  });

  const handleSelectionChange = useCallback(
    (selectedItems: string[] | undefined) => {
      const _condition: Condition = {
        ...condition,
        value: {
          value: selectedItems,
          columnType: valueColumnType,
        },
      };
      setCondition(_condition);
    },
    [condition, setCondition, valueColumnType],
  );

  return (
    <div className={classes.inputSelect}>
      <KDropdown
        allowMultiple={builtinCondition?.typeParams?.allowMultiple}
        items={options}
        selectedItemIds={selectedOptionIds}
        onSelectionChange={handleSelectionChange}
        withEmptyRow
        withSearchBar
        placeholder={t("filterOption")}
      />
    </div>
  );
};

export { UserFilterOptions };
