import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Toggle } from "@kraaft/shared/components/toggle";
import {
  DeliveryNoteConfig,
  useDeliveryNoteConfig,
} from "@kraaft/shared/core/modules/deliveryNote/deliveryNote.hooks";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { Text } from "@kraaft/ui";

import { IntegrationSection } from "../integrationSection";

import { useStyles } from "./deliveryNoteSection.styles";

const SuperAdminDeliveryNote = (props: {
  config: DeliveryNoteConfig | undefined;
}) => {
  const { t } = useTranslation();
  const pool = useSelector(selectCurrentPool);
  const classes = useStyles();
  const poolId = pool?.id;

  const config = useDeliveryNoteConfig(poolId);
  const [isEnabled, setIsEnabled] = useState(config?.enabled || false);

  useEffect(() => {
    setIsEnabled(config?.enabled || false);
  }, [config]);

  const toggleEnable = async (enabled: boolean) => {
    if (poolId) {
      setIsEnabled(enabled);
      try {
        if (enabled) {
          await Api.enableDeliveryNote({ poolId });
        } else {
          await Api.disableDeliveryNote({ poolId });
        }
      } catch (e) {
        console.log("Error toggling delivery note config", e);
        setIsEnabled(config?.enabled || false);
      }
    }
  };

  return (
    <IntegrationSection
      actions={[]}
      details={
        <div className={classes.sectionContainer}>
          <div className={classes.description}>
            <Text color="FONT_LOW_EMPHASIS">
              {t("deliveryNote.description")}
            </Text>
          </div>

          <div>
            <Toggle value={isEnabled} setValue={toggleEnable} />
          </div>
        </div>
      }
      title={t("deliveryNote.title")}
    />
  );
};

export const DeliveryNoteSection = () => {
  const poolId = useSelector(selectCurrentPool)?.id;
  const isSuperAdmin = useSelector(selectCurrentUserIsSuperadmin);
  const config = useDeliveryNoteConfig(poolId);

  if (isSuperAdmin) {
    return <SuperAdminDeliveryNote config={config} />;
  }

  return null;
};
