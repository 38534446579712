import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { TranslationKey } from "@kraaft/shared/core/types";
import { isDev } from "@kraaft/shared/core/utils";

interface BaseActionUserRef {
  type: string;
}

interface ActionIdUserRef extends BaseActionUserRef {
  type: "id";
  value: string;
}

interface ActionColumnUserRef extends BaseActionUserRef {
  type: "columnKey";
  // value is a column key
  value: string;
}

export type ActionUserRef = ActionIdUserRef | ActionColumnUserRef;

export interface ActionAttachment {
  columnKeys: string[];
  templateIds: string[];
}

interface ActionBase {
  name: string;
}

export interface ActionLogger extends ActionBase {
  name: "logger";
  payload: {
    content: string;
  };
}

export interface ActionNotification extends ActionBase {
  name: "notification";
  payload: {
    users: ActionUserRef[];
    content: string;
  };
}

export interface ActionEmail extends ActionBase {
  name: "email";
  payload: {
    users: ActionUserRef[];
    subject: string;
    content: string;
    attachments: ActionAttachment;
    additionalEmails: string[];
  };
}

export interface ActionMessageLogger extends ActionBase {
  name: "messageLogger";
  payload: {
    content: string;
  };
}

export type Action =
  | ActionLogger
  | ActionNotification
  | ActionEmail
  | ActionMessageLogger;
export type ActionName = Action["name"];

export type ActionFromName<N extends ActionName> = Extract<Action, { name: N }>;

export interface AvailableAction {
  name: ActionName;
  translationKey: TranslationKey;
}

export const availableActions: AvailableAction[] = [
  { name: "notification", translationKey: "notificationAction" },
  { name: "email", translationKey: "emailAction" },
  { name: "messageLogger", translationKey: "workflow.actions.messageLogger" },
];

if (isDev) {
  availableActions.unshift({ name: "logger", translationKey: "loggerAction" });
}

export interface Workflow {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  name: string;
  poolId: string;
  enabled: boolean;
  condition: CompositeCondition;
  actions: Action[];
  schemaId: string;
}

export type TemporaryWorkflow = Omit<Workflow, "id" | "schemaId"> & {
  id?: string;
  schemaId?: string;
};
