import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  ActionSheetItem,
  LegacyActionSheet,
} from "@kraaft/shared/components/legacyActionSheet";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { useFallbackRef } from "@kraaft/shared/core/utils/useRefUtils";
import { Button } from "@kraaft/ui";

export interface ContextMenuButtonProps {
  testID?: string;
  actions: Array<ActionSheetItem>;
  onContextButtonClick?: () => void;
  disabled?: boolean;
  onVisibilityChange?: (newState: boolean) => void;
  vertical?: boolean;
  anchorRef?: React.RefObject<null>;
}

// TODO: replace all instances with ContextMenuButton
/** @deprecated */
export const LegacyContextMenuButton = ({
  testID,
  actions,
  onContextButtonClick,
  disabled = false,
  onVisibilityChange,
  vertical,
  anchorRef,
}: ContextMenuButtonProps) => {
  const { t } = useTranslation();
  const [open, setOpen, setClosed] = useBooleanState();

  const menuRef = useFallbackRef(anchorRef, null);

  useEffect(() => {
    onVisibilityChange?.(open);
  }, [open, onVisibilityChange]);

  const openMenu = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onContextButtonClick?.();
      setOpen();
    },
    [onContextButtonClick, setOpen],
  );

  const icon = vertical ? "dots-vertical" : "dots-horizontal";

  return (
    <>
      <Button
        accessibilityLabel={t("menu")}
        testID={testID}
        ref={menuRef}
        icon={icon}
        onPress={openMenu}
        size="SMALL"
        variant="TERTIARY"
        disabled={disabled}
      />
      {menuRef && (
        <LegacyActionSheet
          open={open}
          onClose={setClosed}
          anchorRef={menuRef}
          options={actions}
        />
      )}
    </>
  );
};
