import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, View } from "react-native";

import { KSelectionListCheckerProps } from "@kraaft/shared/components/kSelectionList/kSelectionListChecker.props";

import { KSelectableListItem } from "./kSelectionListProps";

import { styles } from "./kSelectionList.styles";

interface Props<T extends string, U> {
  item: KSelectableListItem<T, U>;
  renderItem: (item: KSelectableListItem<T, U>) => ReactNode;
  renderChecker: React.FC<KSelectionListCheckerProps<T, U>>;
  onPressHandler: (item: KSelectableListItem<T, U>) => void;
  isSelected: boolean;
  isMultiple: boolean | undefined;
  disabled: boolean;
  showChecker: boolean;
  noPadding?: boolean;
}

export const KSelectionListCell = <T extends string, U>({
  item,
  renderItem,
  isSelected,
  onPressHandler,
  isMultiple,
  disabled,
  showChecker,
  noPadding,
  renderChecker: RenderChecker,
}: Props<T, U>) => {
  const { t } = useTranslation();

  return (
    <Pressable
      accessibilityLabel={t("select")}
      disabled={disabled || item.disabled}
      key={item.identifier}
      style={[
        styles.rowContainer,
        !noPadding && styles.rowContainerPadding,
        styles.forcePointerEvents,
      ]}
      onPress={() => onPressHandler(item)}
    >
      <View style={styles.itemContainer}>{renderItem(item)}</View>
      {showChecker && (
        <RenderChecker
          type="item"
          isMultiple={isMultiple ?? false}
          isSelected={isSelected}
          item={item}
          disabled={disabled || !!item.disabled}
          onPress={() => onPressHandler(item)}
        />
      )}
    </Pressable>
  );
};
