import { StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { authScreensTokens } from "@kraaft/shared/components/auth/authScreenTokens";
import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: isNative() ? ColorStyle.ACTION_HOVERED : Color.WHITE,
    flexDirection: "column",
  },
  welcomeTitleContainer: {
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 56,
  },
  welcomeTitle: {
    marginBottom: authScreensTokens.marginBelowTitleAndSubtitle,
  },
  lottieContainer: {
    width: "30%",
    marginBottom: Spacing.S40,
    alignSelf: "center",
  },
  cardsContainer: {
    marginTop: Spacing.S16,
  },
  cardSpacer: {
    marginBottom: Spacing.S16,
  },
});
