import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { KDateInput } from "@kraaft/shared/components/kDateInput";
import { RoomReportFilters } from "@kraaft/shared/components/report/roomReportFilters";
import { useRoomReportFilters } from "@kraaft/shared/components/report/useRoomReportFilters";

import { styles } from "./reportGenerator.styles";

interface ReportDatesProps {
  dateRange: RoomReportFilters["dateRange"];
  updateDateFilter: ReturnType<typeof useRoomReportFilters>["updateDateFilter"];
}

const ReportDates = (props: ReportDatesProps) => {
  const { dateRange, updateDateFilter } = props;
  const { t } = useTranslation();

  return (
    <View style={styles.dateContainer}>
      <View style={styles.dateLeft}>
        <KDateInput
          forceValue
          defaultValue={dateRange?.from}
          label={t("generateReportFrom")}
          onChange={updateDateFilter("from")}
        />
      </View>
      <View style={styles.dateRight}>
        <KDateInput
          forceValue
          defaultValue={dateRange?.until}
          label={t("generateReportTo")}
          onChange={updateDateFilter("until")}
        />
      </View>
    </View>
  );
};

export { ReportDates };
