import { StyleSheet } from "react-native";

import {
  Color,
  ColorStyle,
  FontSize,
  Radius,
  Spacing,
  webOnlyStyle,
} from "@kraaft/ui";

export const LEFT_CONTAINER_SIZE = 38;
const CARD_PADDING = Spacing.S8;

export const styles = StyleSheet.create({
  root: {
    overflow: "hidden",
    backgroundColor: Color.WHITE,
    flexDirection: "row",
    alignItems: "center",
    padding: CARD_PADDING,
    borderRadius: Radius.SMALL,
  },
  left: {
    marginRight: 8,
    width: LEFT_CONTAINER_SIZE,
    height: LEFT_CONTAINER_SIZE,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: Radius.SMALL,
    overflow: "hidden",
  },
  image: {
    width: LEFT_CONTAINER_SIZE,
    height: LEFT_CONTAINER_SIZE,
  },
  title: {
    marginBottom: 4,
    fontSize: FontSize.MEDIUM,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  pills: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  pill: {
    marginRight: Spacing.S4,
    flexShrink: 0,
  },
  middle: {
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    overflow: "hidden",
    ...webOnlyStyle({ whiteSpace: "nowrap" }),
  },
});
