import {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  LegacyRef,
} from "react";
import clsx from "clsx";

import { ColorStyle, Icon } from "@kraaft/ui";

import { useStyles } from "./legacyDraggableLineDecoration.styles";

interface LegacyDraggableLineDecorationProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  preventDrag?: boolean;
  handleRef?: LegacyRef<HTMLDivElement>;
}

const DraggableLineDecoration = forwardRef<
  HTMLDivElement,
  LegacyDraggableLineDecorationProps
>(({ preventDrag, className, children, handleRef, ...other }, ref) => {
  const styles = useStyles();

  return (
    <div ref={ref} className={clsx(styles.root, className)} {...other}>
      {!preventDrag && (
        <div ref={handleRef}>
          <Icon
            className={styles.dragIcon}
            name="dots-six"
            color={ColorStyle.FONT_LOW_EMPHASIS}
          />
        </div>
      )}
      <div className={styles.content}>{children}</div>
    </div>
  );
});

export { DraggableLineDecoration };
