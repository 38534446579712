import {
  CompactTextPillContent,
  TextPillContent,
} from "@kraaft/shared/components/modular/pill/utils";
import { PillRenderer } from "@kraaft/shared/components/modular/pill/utils/types";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { formatNumber } from "@kraaft/shared/core/utils/useNumberInput";

export const CurrencyCompactedPillRenderer: PillRenderer<KColumnType.currency> =
  ({ schemaColumn, recordProperty, extraContext }) => {
    if (!recordProperty?.value) {
      return null;
    }

    const currencyAsString = formatNumber(
      recordProperty.value,
      extraContext.currencyCode,
      true,
    );
    return (
      <CompactTextPillContent iconName={KSchemaUtils.getIcon(schemaColumn)}>
        {currencyAsString}
      </CompactTextPillContent>
    );
  };

export const CurrencyPillRenderer: PillRenderer<KColumnType.currency> = ({
  schemaColumn,
  recordProperty,
  extraContext,
}) => {
  if (!recordProperty?.value) {
    return null;
  }

  const currencyAsString = formatNumber(
    recordProperty.value,
    extraContext.currencyCode,
    true,
  );

  return (
    <TextPillContent
      iconName={KSchemaUtils.getIcon(schemaColumn)}
      numberOfLines={1}
      tooltipTitle={schemaColumn.name}
    >
      {currencyAsString}
    </TextPillContent>
  );
};
