import { useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";

import { ConnectedConversationViewer } from "./conversationViewer.connected";

const ConversationViewer = () => {
  const poolId = useSelector(selectCurrentPoolId);
  const roomSchema = useSelector(selectRoomSchema(poolId));

  if (!roomSchema) {
    return null;
  }

  return <ConnectedConversationViewer roomSchema={roomSchema} />;
};

export { ConversationViewer };
