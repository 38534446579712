import { useMemo } from "react";
import { StyleProp, Text, TextStyle, View, ViewStyle } from "react-native";

import { Icon, Image } from "@kraaft/ui";

import { AvatarProps, AvatarPropsForType } from "../avatar.types";

import { styles } from "./avatarContent.styles";

const AvatarIconContent = (props: AvatarPropsForType<"icon">) => {
  const { name, backgroundColor, color } = props;

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.containerCenterContent,
      backgroundColor ? { backgroundColor } : undefined,
    ],
    [backgroundColor],
  );

  return (
    <View style={containerStyle}>
      <Icon name={name} color={color} />
    </View>
  );
};

const AvatarInitialsContent = (props: AvatarPropsForType<"initials">) => {
  const { initials, backgroundColor, color } = props;

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.containerCenterContent,
      backgroundColor ? { backgroundColor } : undefined,
    ],
    [backgroundColor],
  );

  const textStyle = useMemo<StyleProp<TextStyle>>(
    () => [styles.initialsText, color ? { color } : undefined],
    [color],
  );

  return (
    <View style={containerStyle}>
      <Text style={textStyle} selectable={false}>
        {initials}
      </Text>
    </View>
  );
};

const AvatarImageContent = (props: AvatarPropsForType<"image">) => {
  const { source } = props;

  return (
    <Image
      style={styles.image}
      source={source}
      preferredSize={props.size || "thumbnail"}
    />
  );
};

const AvatarEmojiContent = (props: AvatarPropsForType<"emoji">) => {
  const { emoji, backgroundColor } = props;

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.containerCenterContent,
      backgroundColor ? { backgroundColor } : undefined,
    ],
    [backgroundColor],
  );

  return (
    <View style={containerStyle}>
      <Text style={styles.emoji} selectable={false}>
        {emoji}
      </Text>
    </View>
  );
};

const AvatarContentRenderer = (props: AvatarProps) => {
  const { type } = props;

  switch (type) {
    case "icon":
      return (
        <AvatarIconContent
          type={type}
          name={props.name}
          color={props.color}
          backgroundColor={props.backgroundColor}
        />
      );
    case "initials":
      return (
        <AvatarInitialsContent
          type={type}
          initials={props.initials}
          color={props.color}
          backgroundColor={props.backgroundColor}
        />
      );
    case "image":
      return <AvatarImageContent type={type} source={props.source} />;
    case "emoji":
      return (
        <AvatarEmojiContent
          type={type}
          emoji={props.emoji}
          backgroundColor={props.backgroundColor}
        />
      );
  }
};

export { AvatarContentRenderer };
