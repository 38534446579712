import { call, delay, put, select, takeEvery } from "typed-redux-saga/macro";

import {
  startPolling,
  StartPollingPayload,
  updatePolling,
} from "@kraaft/shared/core/modules/polling/pollingActions";
import { serializePollingDescriptor } from "@kraaft/shared/core/modules/polling/pollingHelper";
import { selectPollingUnit } from "@kraaft/shared/core/modules/polling/pollingSelectors";
import { PollingDescriptor } from "@kraaft/shared/core/modules/polling/pollingState";
import { Api } from "@kraaft/shared/core/services/api";

export function* pollingSagas() {
  yield* takeEvery(startPolling, pollingOrchestratorGenerator());
}

function pollingOrchestratorGenerator(cache: Record<string, boolean> = {}) {
  return function* pollingOrchestrator({
    payload: descriptor,
  }: {
    payload: StartPollingPayload;
  }) {
    const key = serializePollingDescriptor(descriptor);

    if (cache[key]) {
      return;
    }

    cache[key] = true;
    yield* call(polling, descriptor);
    cache[key] = false;
  };
}

function* polling(descriptor: PollingDescriptor) {
  while (true) {
    try {
      const caller = Api[descriptor.endpoint];

      if (!caller) {
        throw new Error("no caller found");
      }

      // Arguments cannot be typed here
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = yield* call(caller, ...(descriptor.arguments as any));

      yield* put(
        updatePolling({
          ...descriptor,
          // TODO add types for pooling data
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data: (response as any).data,
        }),
      );
    } catch (_) {}

    const pollingUnit = yield* select(selectPollingUnit(descriptor));

    if (!pollingUnit) {
      return;
    }

    const subscribersTime = Object.values(pollingUnit.subscribers);

    if (subscribersTime.length < 1) {
      return;
    }

    yield* delay(Math.min(...subscribersTime));
  }
}
