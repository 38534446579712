import { StyleSheet } from "react-native";

import { webOnlyStyle } from "@kraaft/ui";

import { nativeStylesObject } from "./kInput.styles.native";

export const styles = StyleSheet.create({
  ...nativeStylesObject,
  container: {
    ...nativeStylesObject.container,
    ...webOnlyStyle({ cursor: "text" }),
  },
  labelContainer: {
    ...nativeStylesObject.labelContainer,
    left: 0,
    width: "auto",
    paddingLeft: 0,
    transformOrigin: "0 0",
  },
});
