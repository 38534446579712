import { ActionCreator } from "redux";
import { Saga } from "redux-saga";

import {
  BaseAggregate,
  DependenciesFromDeclaredOperations,
  UserDeclaredOperations,
} from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/optimistic.types";
import { CreateReduxBundleActions } from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle.actions";
import { GlobalOfflineReduxBundle } from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle.init";
import { CreateReduxBundleSaga } from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle.saga";
import { CreateReduxBundleSelectors } from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle.selectors";
import {
  ReduxActionsFromDeclaredOperations,
  ReduxErrorAction,
  ReduxExtraGenerator,
  ReduxSelectorsFromDeclaredOperations,
  ReduxStateActions,
  UserProvidedSelectors,
} from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle.types";
import { NamedTaskManager } from "@kraaft/shared/core/utils/optimistic/newOptimistic/taskStore/taskStore";

export const createReduxBundle =
  <
    Aggregate extends BaseAggregate,
    DeclaredOperations extends UserDeclaredOperations,
  >(
    name: string,
    declaredOperations: DeclaredOperations,
    taskProcessor: NamedTaskManager,
  ) =>
  (
    userProvidedSelectors: UserProvidedSelectors<Aggregate>,
    errorAction: ReduxErrorAction,
    resetAction: ActionCreator<any>,
    extra:
      | ReduxExtraGenerator<
          Aggregate,
          DependenciesFromDeclaredOperations<DeclaredOperations>
        >
      | undefined,
  ): {
    Actions: ReduxActionsFromDeclaredOperations<DeclaredOperations>;
    StateActions: ReduxStateActions<Aggregate>;
    Selectors: ReduxSelectorsFromDeclaredOperations<Aggregate>;
    internal: { saga: Saga };
  } => {
    const bundleActions = CreateReduxBundleActions<Aggregate>(
      name,
      declaredOperations,
    );
    const selectors = CreateReduxBundleSelectors(
      name,
      declaredOperations,
      userProvidedSelectors,
    );

    let builtDependencies:
      | DependenciesFromDeclaredOperations<DeclaredOperations>
      | undefined;
    const getDependencies = () => {
      if (builtDependencies) {
        return builtDependencies;
      }
      builtDependencies = extra?.(
        GlobalOfflineReduxBundle.store.getState,
        selectors.selectors.select,
      ) as DependenciesFromDeclaredOperations<DeclaredOperations>;
      return builtDependencies;
    };

    const saga = CreateReduxBundleSaga(
      name,
      taskProcessor,
      declaredOperations,
      bundleActions,
      selectors.internalSelectors,
      userProvidedSelectors,
      errorAction,
      resetAction,
      getDependencies,
    );

    const { stateActions, actions } = bundleActions;

    return {
      internal: { saga },
      Actions: actions,
      StateActions: stateActions,
      Selectors: selectors.selectors,
    };
  };
