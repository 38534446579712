import { createAction } from "@reduxjs/toolkit";

import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { createLoaderMeta } from "@kraaft/shared/core/modules/loaders/loaderUtils";
import { MiniMedia } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { MiniMediaLimitedResult } from "@kraaft/shared/core/modules/miniMedia/miniMedia.types";
import { OpenDocumentSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

interface MiniMediaSubscriberPayload {
  roomId: string;
  limitForFreemium: boolean;
}

export function generateOpenMiniMediaLoaderId(
  roomId: string,
  messageId: string,
) {
  return `${roomId}-${messageId}`;
}

interface OpenMiniMediaCarousel {
  roomId: string;
  messageId: string;
  mediaIds?: string[];
}

interface OpenMiniMedia {
  roomId: string;
  // Mandatory if you want to open a carousel
  directoryId?: string;
  messageId: string;
  trackingSource: OpenDocumentSource;
}

interface SearchMiniMedia {
  search: string;
}

export const MiniMediaActions = {
  subscribeToMiniMedias: createAction<MiniMediaSubscriberPayload>(
    "@miniMedia/SUBSCRIBE",
  ),
  unsubscribeFromMiniMedias: createAction<MiniMediaSubscriberPayload>(
    "@miniMedia/UNSUBSCRIBE",
  ),
  openMiniMedia: createAction("@miniMedia/OPEN", (payload: OpenMiniMedia) => ({
    payload,
    meta: createLoaderMeta({
      id: generateOpenMiniMediaLoaderId(payload.roomId, payload.messageId),
      status: LoaderStatus.LOADING,
    }),
  })),
};

interface SetMiniMedias {
  roomId: string;
  medias: MiniMedia[];
  isLimitedResult: MiniMediaLimitedResult;
}

export const MiniMediaStateActions = {
  set: createAction<SetMiniMedias>("@miniMedia/SET"),
  setSearchText: createAction<SearchMiniMedia>("@miniMedia/SET_SEARCH_TEXT"),
  closeMiniMediaCarousel: createAction("@miniMedia/CLOSE_CAROUSEL"),
  openMiniMediaCarousel: createAction<OpenMiniMediaCarousel>(
    "@miniMedia/OPEN_CAROUSEL",
  ),
};
