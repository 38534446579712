import { ReactNode } from "react";
import { StyleSheet, View } from "react-native";

import { PressableRoomAvatar } from "@kraaft/shared/components/roomCard/pressableRoomAvatar";
import { RoomCardHeader } from "@kraaft/shared/components/roomCard/roomCardHeader";
import { TextProcessor } from "@kraaft/shared/components/textProcessor/textProcessor";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { Color, ColorStyle, FontSize, Icon, Spacing, Text } from "@kraaft/ui";

interface RoomCardDisplayProps {
  roomCard: AnyRoomCard;
  muted: boolean;
  unread: boolean;
  description: string | undefined;
  hovered: boolean;
  displayCardPills: boolean;
  pills: ReactNode;
  onHeaderContextMenuPress?: (anchor: React.RefObject<Element>) => void;
  active: boolean;
}

export const RoomCardDisplay = ({
  roomCard,
  muted,
  unread,
  description,
  hovered,
  displayCardPills,
  pills,
  active,
  onHeaderContextMenuPress,
}: RoomCardDisplayProps) => {
  const mainStyle = [styles.main, active && styles.active];

  return (
    <View style={mainStyle}>
      <View style={styles.avatarContainer}>
        <PressableRoomAvatar roomCard={roomCard} />
      </View>
      <View style={styles.body}>
        <RoomCardHeader
          title={roomCard.title}
          createdAt={
            roomCard.lastMessageInfo?.createdAt ?? roomCard.roomCreatedAt
          }
          isHovered={hovered}
          isUnread={unread}
          onContextMenuPress={onHeaderContextMenuPress}
        />

        <View style={styles.bottomLayout}>
          <View style={styles.bottomLeftLayout}>
            <View style={styles.descriptionWrapper}>
              <Text
                numberOfLines={displayCardPills ? 1 : 2}
                ellipsizeMode="tail"
                color="FONT_LOW_EMPHASIS"
              >
                <TextProcessor textStyle={styles.description}>
                  {description}
                </TextProcessor>
              </Text>
            </View>
            <View style={styles.pills}>{pills}</View>
          </View>
          <View style={styles.bottomRightLayout}>
            {unread && <View style={styles.dot} />}
            <View style={styles.memberIcon}>
              {muted && (
                <Icon
                  name="bell-off-03"
                  size="SMALL"
                  color="FONT_LOW_EMPHASIS"
                />
              )}
              {roomCard.pinned && (
                <Icon name="pin-02" size="SMALL" color="FONT_LOW_EMPHASIS" />
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    overflow: "hidden",
    height: 88,
  },
  active: {
    backgroundColor: Color.BLUE_LAVENDER,
  },
  body: {
    height: "100%",
    flex: 1,
    flexDirection: "column",
    paddingTop: 12,
    paddingBottom: Spacing.S8,
    paddingRight: Spacing.S16,
  },
  avatarContainer: {
    marginRight: 12,
    marginLeft: Spacing.S16,
  },
  bottomLayout: {
    flexDirection: "row",
    flexGrow: 1,
  },
  bottomLeftLayout: {
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
  },
  bottomRightLayout: {
    marginTop: 4,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionWrapper: {
    flex: 1,
  },
  description: {
    lineHeight: 20,
    fontSize: FontSize.MEDIUM,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  dot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: Color.BLUE_SAVOY,
  },
  pillsContainer: {
    width: "100%",
    flexDirection: "row",
  },
  pills: {
    paddingBottom: 4,
    flexDirection: "row",
    alignContent: "center",
  },
  memberIcon: {
    marginTop: "auto",
    marginBottom: Spacing.S4,
    justifyContent: "flex-end",
    flexDirection: "row",
  },
});
