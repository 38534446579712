import React, { useMemo } from "react";
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from "@mui/material";

import { isDOMElement } from "@kraaft/helper-functions";

const Tooltip = ({ children: _children, ...props }: MuiTooltipProps) => {
  const children = useMemo(() => {
    if (isDOMElement(_children)) {
      return _children;
    }
    return React.cloneElement(_children, {
      dataSet: { "mui-internal-clone-element": true },
    });
  }, [_children]);
  return <MuiTooltip {...props}>{children}</MuiTooltip>;
};

export { Tooltip };
