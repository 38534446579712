import { useMemo } from "react";

import {
  DifferenceBackground,
  DifferenceBackgroundProps,
} from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceBackground";
import { DifferenceText } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceText";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularRecordValueOfType } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { formatAddress } from "@kraaft/shared/core/utils/utils";

interface GeolocationDifferenceProps extends DifferenceBackgroundProps {
  value: ModularRecordValueOfType<KColumnType.geolocation>;
}

export const GeolocationDifference = ({
  value,
  method,
}: GeolocationDifferenceProps) => {
  const label = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    const formattedAddress = formatAddress(value);

    return formattedAddress;
  }, [value]);

  return label !== undefined ? (
    <DifferenceBackground method={method}>
      <DifferenceText>{label}</DifferenceText>
    </DifferenceBackground>
  ) : null;
};
