import moment, { Moment } from "moment";

import { useMemo } from "react";
import { StyleSheet, TextStyle, ViewStyle } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { AVAILABLE_EMOJIS } from "@kraaft/shared/components/message/emojiPicker/emojiPickerAvailableEmojis";
import { getMaxWidthOfMessage } from "@kraaft/shared/components/message/messageSize";
import { MessageTypes } from "@kraaft/shared/core/modules/message";
import {
  Message,
  MessageModularModuleAttribute,
  MessageSelectionType,
} from "@kraaft/shared/core/modules/message/messageState";
import {
  isDeleted,
  isGeolocationMessage,
  isLogMessage,
  isPersisted,
} from "@kraaft/shared/core/modules/message/messageUtils";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { conditionalObject } from "@kraaft/shared/core/utils";
import { FontSize } from "@kraaft/ui";

import { styles } from "./message.styles";

const JUMBO_SIZE = isNative() ? 50 : 64;

export interface MessageRendererProps<T extends MessageTypes.Message> {
  roomId: string;
  message: T;
  answer?: MessageTypes.Message;
  onLongPress?: (message: MessageTypes.Message) => void;
}

export function formatDate(date: Date | number, isRelative?: boolean): string {
  const momentObj: Moment = moment(date);
  return isRelative === false
    ? momentObj.format("L")
    : momentObj.calendar({ sameElse: `L [${i18n.t("at")}] LT` });
}

export function useMessageStyles({
  isReply,
  hasReactions,
  isOnlyEmojis,
  isFontSizeLargeBody,
  sizerWidth,
}: {
  isReply: boolean;
  hasReactions: boolean;
  isOnlyEmojis?: boolean;
  isFontSizeLargeBody?: boolean;
  sizerWidth?: number;
}) {
  return useMemo(() => {
    const messageStyle = isReply ? styles.messageReply : styles.messageDefault;
    const messageBackgroundColor =
      StyleSheet.flatten(messageStyle).backgroundColor?.toString();

    const textStyle = {
      fontSize: isFontSizeLargeBody ? FontSize.BODY : FontSize.MEDIUM,
      ...(isReply ? styles.textReply : styles.textDefault),
      ...conditionalObject(
        {
          lineHeight: JUMBO_SIZE + 10,
          fontSize: JUMBO_SIZE,
        },
        Boolean(isOnlyEmojis),
      ),
    };

    const headerStyle = {
      ...textStyle,
      fontSize: FontSize.MEDIUM,
    };

    const textColor = StyleSheet.flatten<TextStyle>(textStyle).color as string;

    return {
      messageStyle: [
        styles.message,
        messageStyle,
        hasReactions ? { minWidth: 50 } : {},
        sizerWidth
          ? {
              maxWidth: getMaxWidthOfMessage(isNative(), sizerWidth),
            }
          : ({
              alignSelf: isReply ? "flex-start" : "flex-end",
            } as ViewStyle),
      ],
      messageBackgroundColor,
      textContainerStyle: styles.text,
      textStyle,
      headerStyle,
      textColor,
      modifiedStyle: isReply ? styles.modifiedReply : styles.modifiedDefault,
    };
  }, [isReply, hasReactions, isOnlyEmojis, isFontSizeLargeBody, sizerWidth]);
}

export function isNotReplyable(message: MessageTypes.Message): boolean {
  return isLogMessage(message) || isDeleted(message);
}

export function isMessageSelectable(message: MessageTypes.Message) {
  return !isLogMessage(message);
}

export function canMessageBeAttached(message: MessageTypes.Message) {
  return (
    isPersisted(message) &&
    !isDeleted(message) &&
    !isLogMessage(message) &&
    !isGeolocationMessage(message)
  );
}

export function isMessageAttached(message: MessageTypes.Message) {
  if (
    !message ||
    isLogMessage(message) ||
    message.modularObjects === undefined
  ) {
    return false;
  }
  return Object.values(message.modularObjects).some(
    (object: MessageModularModuleAttribute) => object.isFlagged,
  );
}

export function formatDraggableText(createdAt: Date, text: string) {
  const formattedDate = moment(createdAt).format("LT L");
  const draggableText = `[${formattedDate}] ${text}`;
  return draggableText;
}

export function canMessageBeForwarded(message: MessageTypes.Message) {
  return !isDeleted(message) && !isLogMessage(message);
}

export function canMessageBeSelected(
  message: MessageTypes.Message,
  selectionType: MessageSelectionType | undefined,
) {
  switch (selectionType) {
    case "notDefined":
      return false;
    case "attach":
      return canMessageBeAttached(message);
    case "forward":
      return canMessageBeForwarded(message);
    default:
      break;
  }
  return true;
}

export function isReplyOrForward(message: MessageTypes.UserMessage) {
  return (
    message.answerTo !== undefined ||
    (message.forwarded !== undefined &&
      message.forwarded.by !== message.senderId)
  );
}

export function isForwardedMessage(
  message: MessageTypes.UserMessage,
): message is MessageTypes.ForwardedMessage {
  return message.forwarded !== undefined;
}

export function messageHasTranscription(message: Message) {
  return message.type === "audio" && message.attachment.transcription;
}

export function sortEmojiEntries(a: [string, number], b: [string, number]) {
  const [aEmoji, aAmount] = a;
  const [bEmoji, bAmount] = b;

  if (aAmount !== bAmount) {
    return bAmount - aAmount;
  }

  const aIndex = AVAILABLE_EMOJIS.findIndex((emoji) => emoji === aEmoji);
  const bIndex = AVAILABLE_EMOJIS.findIndex((emoji) => emoji === bEmoji);
  return aIndex - bIndex;
}
