import { DimensionValue, Platform, StyleSheet, View } from "react-native";

import { FolderChecked } from "@kraaft/shared/components/message/messageLog/messageLogs/folderChecked";
import { FolderCreated } from "@kraaft/shared/components/message/messageLog/messageLogs/folderCreated";
import { FolderUnchecked } from "@kraaft/shared/components/message/messageLog/messageLogs/folderUnchecked";
import { GroupedMessage } from "@kraaft/shared/components/message/messageLog/messageLogs/grouped";
import { InternalMemberJoinedPool } from "@kraaft/shared/components/message/messageLog/messageLogs/internalMemberJoinedPool";
import { WorkflowMessage } from "@kraaft/shared/components/message/messageLog/messageLogs/workflowMessage";
import { LogMessage } from "@kraaft/shared/core/modules/message/messageState";
import { Spacing, Text } from "@kraaft/ui";

import * as utils from "../messageUtils";

import { styles as baseStyles } from "../message.styles";

interface Props {
  roomId: string;
  message: LogMessage;
}

export const MessageLog = ({ roomId, message }: Props) => {
  const messageBubble = getMessageBubble(roomId, message);

  if (!messageBubble) {
    // forward compatibility: can happen for unknown types
    return null;
  }
  const { createdAt } = message;

  return (
    <View testID="ide2e-log-message" style={styles.main}>
      {messageBubble}
      <Text style={[baseStyles.date, styles.date]}>
        {utils.formatDate(createdAt)}
      </Text>
    </View>
  );
};

function getMessageBubble(roomId: string, message: LogMessage) {
  const { event } = message;

  switch (event.type) {
    case "folderCompleted":
      return <FolderChecked message={message} event={event} roomId={roomId} />;
    case "folderUnchecked":
      return (
        <FolderUnchecked message={message} event={event} roomId={roomId} />
      );
    case "folderCreated":
      return <FolderCreated message={message} event={event} roomId={roomId} />;
    case "internalMemberJoinedPool":
      return <InternalMemberJoinedPool event={event} />;
    case "group":
      return <GroupedMessage roomId={roomId} event={event} />;
    case "workflowMessage":
      return <WorkflowMessage roomId={roomId} event={event} />;
    default:
      return null;
  }
}

export const styles = StyleSheet.create({
  main: {
    width: Platform.select({
      default: "100%",
      web: "max(300px, 50%)" as DimensionValue,
    }),
    alignSelf: "center",
  },
  date: {
    marginTop: Spacing.S4,
    textAlign: "center",
  },
});
