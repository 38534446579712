import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StrictOmit } from "ts-essentials";

import {
  PlaceSelection,
  PlaceSelectionProps,
} from "@kraaft/shared/components/placeSelection";
import { GeoLocation } from "@kraaft/shared/core/types";

export type EditLocationPanelProps = StrictOmit<
  PlaceSelectionProps,
  "defaultValue" | "sendButtonLabel" | "isEditable" | "onClose" | "onSubmit"
> & {
  geolocation: GeoLocation | null | undefined;
  setOpen: (isOpen: boolean) => void;
  updateGeolocation: (newGeolocation: GeoLocation | null | undefined) => void;
};

export const EditLocationPanel = ({
  geolocation,
  setOpen,
  updateGeolocation,
  sectionLockInfo,
  context,
  title,
  centerOnCurrentLocation,
  closeIcon,
  editTitle,
  isOnlyEditable,
  thumbnailUrl,
}: EditLocationPanelProps) => {
  const { t } = useTranslation();

  const handleChangeLocation = useCallback(
    (newGeolocation: GeoLocation | null | undefined) => {
      updateGeolocation(newGeolocation ? { ...newGeolocation } : null);
    },
    [updateGeolocation],
  );

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <PlaceSelection
      context={context}
      title={title}
      closeIcon={closeIcon}
      editTitle={editTitle}
      isOnlyEditable={isOnlyEditable}
      thumbnailUrl={thumbnailUrl}
      centerOnCurrentLocation={centerOnCurrentLocation}
      defaultValue={geolocation ?? undefined}
      sendButtonLabel={t("placeSelection.confirm")}
      isEditable={!sectionLockInfo?.isLocked}
      sectionLockInfo={sectionLockInfo}
      onClose={closeModal}
      onSubmit={handleChangeLocation}
    />
  );
};
