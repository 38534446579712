import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { ImagePreviewSquare } from "@kraaft/shared/components/imagesPreview/imagePreviewSquare";
import { LoadingPreviewSquare } from "@kraaft/shared/components/imagesPreview/loadingPreviewSquare";
import { MorePreviewSquare } from "@kraaft/shared/components/imagesPreview/morePreviewSquare";
import { ImageAttachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { Spacing } from "@kraaft/ui";

const DISPLAYED_IMAGE_COUNT = 4;

type ImagesPreviewProps = {
  attachments: ImageAttachment[];
  openCarousel: (initialIndex: number) => void;
  isLoading: boolean;
};

const ImagesPreview = ({
  attachments,
  openCarousel,
  isLoading,
}: ImagesPreviewProps) => {
  const { t } = useTranslation();

  const { firstImages, nextImage } = useMemo(() => {
    const firstAttachments = attachments.slice(0, DISPLAYED_IMAGE_COUNT);
    const nextAttachment = attachments[DISPLAYED_IMAGE_COUNT];

    const extraCount =
      attachments.length > DISPLAYED_IMAGE_COUNT
        ? attachments.length - DISPLAYED_IMAGE_COUNT
        : 0;

    return {
      firstImages: firstAttachments.map((attachment, index) => {
        return (
          <ImagePreviewSquare
            key={attachment.id}
            accessibilityLabel={t("openMedia")}
            onPress={() => {
              openCarousel(index);
            }}
            preferredSize="thumbnail"
            downloadUrl={attachment.original.downloadUrl}
          />
        );
      }),
      nextImage: nextAttachment ? (
        <MorePreviewSquare
          key={nextAttachment.id}
          accessibilityLabel={t("openMedia")}
          onPress={() => {
            openCarousel(DISPLAYED_IMAGE_COUNT);
          }}
          preferredSize="thumbnail"
          downloadUrl={nextAttachment.original.downloadUrl}
          extraCount={extraCount}
        />
      ) : null,
    };
  }, [attachments, openCarousel, t]);

  return (
    <View style={styles.container}>
      {firstImages}
      {isLoading ? <LoadingPreviewSquare /> : nextImage}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    gap: Spacing.S4,
  },
});

export { ImagesPreview };
