import { EventChannel, eventChannel } from "redux-saga";
import { put, takeEvery } from "typed-redux-saga/macro";

import {
  FirestoreRoomCard,
  firestoreSubscribeToPinnedRoomCards,
  normalizeRoomCards,
} from "@kraaft/shared/core/modules/roomCard/queries/firestore.roomCard";
import {
  RoomCardActions,
  RoomCardStateActions,
} from "@kraaft/shared/core/modules/roomCard/roomCard.actions";
import { RoomMemberCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { BatchActions } from "@kraaft/shared/core/store/batchingReducer";
import { takeCountedDeep, waitFor } from "@kraaft/shared/core/utils/sagas";

type Meta = EventChannel<FirestoreRoomCard[]> | undefined;

function createChannel(poolId: string, userId: string) {
  return eventChannel<FirestoreRoomCard[]>((emit) => {
    return firestoreSubscribeToPinnedRoomCards(poolId, userId, emit);
  });
}

function* subscribeToPinnedRoomCards(
  registerMeta: (meta: Meta) => void,
  action: ReturnType<typeof RoomCardActions.subscribeToPinned>,
) {
  yield* put(RoomCardStateActions.resetPinnedState());

  const channel = createChannel(action.payload.poolId, action.payload.userId);

  registerMeta(channel);
  yield* takeEvery(channel, (firestoreRoomCards) =>
    receivePinnedRoomCards(firestoreRoomCards, action.payload.poolId),
  );
}

function* receivePinnedRoomCards(
  firestoreRoomCards: FirestoreRoomCard[],
  poolId: string,
) {
  const schema = yield* waitFor(selectRoomSchema(poolId));
  const allSchemaColumns = schema
    ? KSchemaUtils.flattenColumnsDict(schema.rootSection)
    : {};

  yield* put(
    BatchActions({
      actions: [
        RoomCardStateActions.setPinnedRoomCards({
          roomCards: normalizeRoomCards(
            firestoreRoomCards,
            allSchemaColumns,
          ) as Record<string, RoomMemberCard>,
        }),
        RoomCardStateActions.setArePinnedRoomCardsLoading({ is: false }),
      ],
    }),
  );
}

function* unsubscribeFromPinnedRoomCards(meta: Meta) {
  meta?.close();
}

export function* subscribeToPinnedRoomCardsSaga() {
  yield takeCountedDeep(
    RoomCardActions.subscribeToPinned,
    RoomCardActions.unsubscribeFromPinned,
    subscribeToPinnedRoomCards,
    unsubscribeFromPinnedRoomCards,
    (action) => `${action.payload.userId}@${action.payload.poolId}`,
  );
}
