import { useTranslation } from "react-i18next";

import { DeleteConfirmationDialogContent } from "@kraaft/shared/components/deleteConfirmationDialogContent";

interface Props {
  option: string;
  onDelete: () => void;
  onClose: () => void;
}
export const DeleteOptionDialogContent = (props: Props) => {
  const { onClose, option, onDelete } = props;
  const { t } = useTranslation();

  const expectedValue = option.trim() || t("deleteOption.emptyExpected");

  return (
    <DeleteConfirmationDialogContent
      onDelete={onDelete}
      onClose={onClose}
      title={t("deleteOption.title")}
      subtitle={t("deleteOption.subtitle", { option, expectedValue })}
      inputLabel={t("deleteOption.inputLabel")}
      expectedValue={expectedValue}
      deleteButton={t("deleteOption.delete")}
      context="deleteOption"
    />
  );
};
