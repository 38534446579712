import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { UserPoolActions } from "@kraaft/shared/core/modules/userPool/userPool.actions";
import { PoolNotificationFilter } from "@kraaft/shared/core/modules/userPool/userPool.state";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { PoolNotificationFilterSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

type Params = {
  poolId: string;
  source: PoolNotificationFilterSource;
};

export const usePoolNotificationFilter = ({ poolId, source }: Params) => {
  const dispatch = useDispatch();

  const changeNotificationFilter = useCallback(
    (notificationFilter: PoolNotificationFilter) => {
      trackEvent({
        eventName: "Edit General Notification Settings",
        notification_setting: notificationFilter,
        source,
      });

      dispatch(
        UserPoolActions.changeNotificationFilter({
          poolId,
          notificationFilter,
        }),
      );
    },
    [dispatch, poolId, source],
  );

  return {
    changeNotificationFilter,
  };
};
