import { StyleSheet } from "react-native";

import { Color } from "@kraaft/ui";

export const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
    flexShrink: 1,
  },
  dimmedBackground: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Color.BLACK,
    opacity: 0.25,
  },
});
