import { PickProperties } from "ts-essentials";

import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { ImageQuality } from "@kraaft/shared/core/modules/file/imageHelper";
import { UploadAttachmentContext } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import {
  ExternalElement,
  ShareFlow,
} from "@kraaft/shared/core/modules/shareExtension/types";
import { PlatformScreens } from "@kraaft/shared/core/services/navigation";
import { AnyUnexplained, GeoCoordinates } from "@kraaft/shared/core/types";
import {
  CleanFullOptional,
  OptionalUndefined,
  ReplaceUndefined,
  UndefinedFullOptional,
  UnionMapping,
} from "@kraaft/shared/core/typeUtils";

export type MapContext =
  | {
      location: "Home";
    }
  | {
      location: "Conversation";
      roomId: string;
      schemaId?: string;
      initialCenter?: GeoCoordinates;
    }
  | { location: "ModularFolders"; roomId: string; schemaId?: string }
  | { location: "PhotoGallery"; roomId: string }
  | {
      location: "ModularFolderDetails";
      roomId: string;
      schemaId: string;
      folderId: string;
    };

export enum ConversationDetailsPanel {
  editMembers = "editMembers",
  members = "members",
  sharepointConnector = "sharepoint-connector",
  report = "report",
  worksiteFolder = "folder",
}

export type SharedScreens = {
  Sandbox: undefined;
  DevStack: undefined;
  AddMembersAtPoolCreation: {
    showHide?: boolean;
  };
  Analytics: undefined;
  AskPoolCompanySize: undefined;
  AskPoolCompanyIndustry: undefined;
  AskPoolAcquisitionSource: undefined;
  AskUserJob: undefined;
  AskUserMail: undefined;
  Billing: undefined;
  Camera: {
    context: UploadAttachmentContext;
    preventRecording?: boolean;
    transition?: "fromPreview" | "default" | "disabled";
    savedCaption?: InputPartition[];
    uploadQuality?: ImageQuality;
  };
  CreateChecklistTemplate: {
    roomId: string;
  };
  CreateWorkspace: undefined;
  ConfirmationCode: {
    fullNumber: string;
    exists: boolean;
  };
  Conversation: {
    roomId: string;

    backOptions?: NavigationOptions;
    defaultInput?: InputPartition[];

    // From Deep Linking
    kizeoFormId?: string;
    kizeoDataId?: string;

    //Web Only
    preserveDetails?: boolean;
    openedTab?: "folder" | "infos";
  };
  ConversationMessenger: SharedScreens["Conversation"];
  ConversationFolder: {
    roomId: string;
    openedPanel?: ConversationDetailsPanel;
  };
  ConversationSearchMember: {
    roomId: string;
  };
  ConversationEditMember: {
    roomId: string;
  };
  ConversationNotificationPreference: {
    poolId: string;
    roomId: string;
  };
  PhotoTab: SharedScreens["Conversation"];
  PhotoMapScreen: {
    roomId: string;
  };
  ConversationInfo: {
    roomId: string;
    openedPanel?: ConversationDetailsPanel;
    isStickyPanel?: boolean;
    backOptions?: NavigationOptions;
  };
  ConversationTable: undefined;
  CreateRoom: {
    backOptions?: NavigationOptions;
    labelIds?: string[];
  };
  Database: undefined;
  DocumentGallery: {
    roomId: string;
  };
  RoomDirectory: {
    roomId: string;
    directoryId: string;
    parentId?: string;
    title?: string;

    transition?: "default" | "reversed" | "none";
  };
  EditMembers: {
    roomId: string;
  };
  Members: {
    roomId: string;
  };
  EnterPassword: {
    email: string;
    exists: boolean;
  };
  ForgotPasswordEmailSent: {
    email: string;
    mode: "forced" | "user";
  };
  HasNoPool: undefined;
  Home: undefined;
  Integrations: undefined;
  Introduction: undefined;
  InviteCongrats: undefined;
  EnterUsername: undefined;
  LoaderCreatingPool: { hasInvited: boolean };
  MediaSender: {
    context: UploadAttachmentContext;
    transition?: "default" | "disabled";
    backButton: ScreenSpecifier | "back";
    shouldDeleteFilesOnBack: boolean;
    savedCaption?: InputPartition[];
    allowAdd: boolean;
    uploadQuality?: ImageQuality;
  };
  ManageChecklist: undefined;
  ManageMembers: undefined;
  ManageNotifications: undefined;
  ManagePool: undefined;
  RoomFormBuilder: undefined;
  ManageDefaultDirectories: undefined;
  ManageTaskTemplates: undefined;
  ManageTemplates: undefined;
  ModularFolders: {
    roomId: string;
    schemaId: string;
    title?: string;
    viewMode?: "list" | "map";
    isStickyPanel?: boolean;
  };
  ModularFolderDetails: {
    roomId: string; // needed in URL
    folderId: string;
    schemaId: string;
    title?: string;
    animateBack?: boolean;

    // Only web
    doNotTogglePanel?: boolean;
  };
  ModularFolderHistory: {
    roomId: string; // needed in URL
    schemaId: string; // needed in URL
    folderId: string;
  };
  Parameters: undefined;
  PhotoGallery: {
    roomId: string;
  };
  ReceiptAcknowledgement: {
    roomId: string;
    messageId: string;
  };
  ImportChecklistTemplate: {
    roomId: string;
  };
  EditSchemaVisibilityForRoom: {
    roomId: string;
  };
  RoomSelection: {
    flow: ShareFlow;
    elements: ExternalElement[];
  };
  SchemaView: {
    schemaViewId: string;
  };
  SignInEmail: undefined;
  SignInPhone: undefined;
  SignInSSO: undefined;
  SSOElevation: undefined;
  Status: undefined;
  Storybook: undefined;
  WebView: {
    title: string;
    uri: string;
  };
  WelcomeOnKraaft: undefined;
  Settings: undefined;
  Welcome: undefined;

  // Tech & debug
  TechStatus: undefined;
  LogScreen: undefined;
  TaskManagerScreen: undefined;
  NetworkScreen: undefined;
  LegacyOfflineScreen: undefined;
  AdScreen: undefined;
  FileAllocatorRegistryScreen: undefined;
  PermanentFileRegistryScreen: undefined;
};

// Allows having an undefined params in shared
// But {} params in PlatformScreens, such as Home
// Also combines params defined in both, such as Conversation
export type Screens = {
  [k in
    | keyof SharedScreens
    | keyof PlatformScreens]: k extends keyof SharedScreens
    ? k extends keyof PlatformScreens
      ? SharedScreens[k] extends undefined
        ? PlatformScreens[k]
        : SharedScreens[k] & PlatformScreens[k]
      : SharedScreens[k]
    : k extends keyof PlatformScreens
      ? PlatformScreens[k]
      : undefined;
};

type ScreenSpecifier<T extends keyof Screens = keyof Screens> = T extends any
  ? {
      name: T;
      options: ScreenParams<T>;
    }
  : never;

export type ScreenKey = keyof Screens;

export type ScreenKeyWithoutParams = keyof PickProperties<
  CleanFullOptional<Screens>,
  undefined
>;

export type ScreenParams<RouteName extends ScreenKey> = OptionalUndefined<
  UnionMapping<ReplaceUndefined<Screens[RouteName]>>
>;

export type ScreenParamsUndefined<RouteName extends ScreenKey> =
  | ScreenParams<RouteName>
  | UndefinedFullOptional<ScreenParams<RouteName>>;

interface NavigationParams {
  [key: string]: AnyUnexplained;
}

export interface BehaviorOptions {
  push?: boolean;
}

export type NavigationOptions<
  RouteName extends ScreenKey | undefined = undefined,
  ParamsOptions = unknown,
  Options = unknown,
> = RouteName extends ScreenKey
  ? RouteName extends ScreenKeyWithoutParams
    ? {
        routeName: RouteName;
        params?: ScreenParams<RouteName> & ParamsOptions;
      } & Options
    : {
        routeName: RouteName;
        params: ScreenParams<RouteName> & ParamsOptions;
      } & Options
  : {
      routeName: ScreenKey;
      params?: NavigationParams & ParamsOptions;
    } & Options;
