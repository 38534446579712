import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
  },
  infoAlertIcon: {
    marginLeft: 4,
  },
});
