import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@kraaft/shared/core/store";

type PhotoTabStateType = {
  selectedTab: "map" | "gallery";
};

export const initialState: PhotoTabStateType = {
  selectedTab: "gallery",
};

export const PhotoTabSelectors = {
  selectedTab: (state: RootState) => {
    return state.photoTab.selectedTab;
  },
};

export const PhotoTabSlice = createSlice({
  name: "photoTab",
  initialState,
  reducers: {
    updateSelectedTab(
      state,
      { payload }: PayloadAction<PhotoTabStateType["selectedTab"]>,
    ) {
      state.selectedTab = payload;
    },
  },
});
