import { makeStyles } from "@mui/styles";

import { Color } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    backgroundColor: Color.WHITE,
    position: "relative",
    flexGrow: 1,
    overflow: "hidden",
    height: "100%",
  },
});
