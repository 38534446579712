import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchema,
  KSchemaColumn,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { editSchemaViewKanbanFormat } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { useHandleChangeSingleSelectHelper } from "@kraaft/web/src/components/dropdown/kDropdownUtils";
import { getAvailableGroupByFromSchema } from "@kraaft/web/src/components/kanban/board/kanbanBoardUtils";
import { useSchemaView } from "@kraaft/web/src/components/modularFoldersViewer/useSchemaView";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

const useGroupByColumnFromSchema = (
  workspace: RecordWorkspace,
  schema: KSchema,
  schemaViewId?: string,
) => {
  const dispatch = useDispatch();
  const schemaView = useSchemaView(workspace, schema.id, schemaViewId);

  const availableGroupBy = useMemo(
    () => getAvailableGroupByFromSchema(schema),
    [schema],
  );

  const groupedByItems = useMemo<FilteredListItem[]>(
    () =>
      availableGroupBy.map((column) => ({
        name: column.name,
        label: column.name,
        value: column.key,
      })),
    [availableGroupBy],
  );

  const defaultGroupedByColumnKey = availableGroupBy.find(
    (groupBy) => groupBy.key === schemaView.formats.kanban.groupedByProperty,
  )
    ? schemaView.formats.kanban.groupedByProperty
    : undefined;

  const [groupedByColumnKey, setGroupedByColumnKey] = useState(
    defaultGroupedByColumnKey ?? groupedByItems[0]?.value,
  );

  useEffect(() => {
    setGroupedByColumnKey(
      defaultGroupedByColumnKey ?? groupedByItems[0]?.value,
    );
    // We don't want to update when memoryKanban changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedByItems]);

  const groupedByColumnOnSelectionChange = useHandleChangeSingleSelectHelper(
    (columnKey: string | undefined) => {
      setGroupedByColumnKey(columnKey);
      dispatch(
        editSchemaViewKanbanFormat({
          workspace,
          schemaId: schema.id,
          schemaViewId,
          editions: {
            groupedByProperty: columnKey,
          },
        }),
      );
    },
    [dispatch, schema.id, schemaViewId, workspace],
  );

  const [groupedByColumn, groupedByColumnSelectedItemIds] = useMemo(
    () =>
      groupedByColumnKey
        ? [
            KSchemaUtils.findColumn(
              schema.rootSection,
              groupedByColumnKey,
            ) as KSchemaColumn<KColumnType.selectSingle | KColumnType.user>,
            [groupedByColumnKey],
          ]
        : [undefined, []],
    [groupedByColumnKey, schema.rootSection],
  );

  return {
    groupedByColumn,
    groupedByItems,
    groupedByColumnSelectedItemIds,
    groupedByColumnOnSelectionChange,
  };
};

export { useGroupByColumnFromSchema };
