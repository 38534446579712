import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Condition,
  MetadataCondition,
} from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectAllRoomsOfPoolAlphaSorted } from "@kraaft/shared/core/modules/room/roomSelectors";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";

import { useStyles } from "./filterOptions.styles";

interface MetadataFilterOptionsProps {
  condition: MetadataCondition;
  setCondition: (condition: Condition) => void;
}

const MetadataFilterOptions = (
  props: MetadataFilterOptionsProps,
): JSX.Element => {
  const { condition, setCondition } = props;
  const poolId = useSelector(selectCurrentPoolId);
  const rooms = useSelector(selectAllRoomsOfPoolAlphaSorted(poolId ?? ""));
  const classes = useStyles();
  const { t } = useTranslation();

  const dropdownItems = useMemo(() => {
    return rooms.map((room) => ({
      value: room.id,
      label: getExistingRoomRecordField(room.record.properties, "title", ""),
    }));
  }, [rooms]);

  const selectedIds = useMemo(
    () =>
      condition.value?.value !== undefined
        ? [condition.value?.value as string]
        : undefined,
    [condition],
  );

  const setValueSelect = useCallback(
    (selectedItem: string[] | undefined) => {
      const filterDetails = {
        ...condition,
        type: "metadata",
        value: {
          value: selectedItem?.[0],
          columnType: KColumnType.join,
        },
      };
      setCondition(filterDetails as Condition);
    },
    [condition, setCondition],
  );

  return (
    <div className={classes.inputSelect}>
      <KDropdown
        items={dropdownItems}
        selectedItemIds={selectedIds}
        onSelectionChange={setValueSelect}
        withEmptyRow
        withSearchBar
        placeholder={t("filterOption")}
      />
    </div>
  );
};

export { MetadataFilterOptions };
