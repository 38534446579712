import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, View } from "react-native";

import { Color, FontSize, Radius, Spacing, Text } from "@kraaft/ui";

interface EveryoneMentionItemProps {
  onPress: () => void;
  onPressIn?: () => void;
  onHovered?: () => void;
  hovered: boolean;
}

export const EveryoneMentionItem = forwardRef<View, EveryoneMentionItemProps>(
  ({ onPress, onPressIn, onHovered, hovered }, ref) => {
    const { t } = useTranslation();

    const everyone = t("mentions.atEveryone");

    return (
      <Pressable
        ref={ref}
        accessibilityLabel={everyone}
        onPress={onPress}
        onPressIn={onPressIn}
        onHoverIn={onHovered}
        // @ts-expect-error focused exists in the callback
        style={({ focused, pressed }) => [
          styles.root,
          hovered || focused || pressed ? styles.hovered : undefined,
        ]}
      >
        <Text weight="bold" size={FontSize.BODY}>
          {everyone}
        </Text>
      </Pressable>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    margin: Spacing.S4,
    padding: Spacing.S12,
    borderRadius: Radius.SMALL,
  },
  hovered: {
    backgroundColor: Color.BLUE_LAVENDER,
  },
});
