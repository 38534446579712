import { ReactNode } from "react";
import GoogleMapReact from "google-map-react";

interface IdentityMarkerProps extends GoogleMapReact.Coords {
  children?: ReactNode;
}

const IdentityMarker = (props: IdentityMarkerProps) => {
  const { children } = props;

  return <div className="ide2e-geomarker">{children}</div>;
};

export { IdentityMarker };
