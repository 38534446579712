import { cloneDeep } from "lodash";
import { EventChannel, eventChannel } from "redux-saga";
import {
  call,
  put,
  select,
  spawn,
  take,
  takeEvery,
} from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { setLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { PoolRoleType } from "@kraaft/shared/core/modules/pool/poolState";
import { isAtLeastPoolAdmin } from "@kraaft/shared/core/modules/pool/poolUtil";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { isUserSuperadmin } from "@kraaft/shared/core/modules/user/userUtils";
import { Workflow } from "@kraaft/shared/core/modules/workflows/types";
import {
  WorkflowDelaySnapshot,
  WorkflowOptimisticSaga,
} from "@kraaft/shared/core/modules/workflows/workflow.optimistic";
import {
  selectEditWorkflow,
  selectWorkflow,
} from "@kraaft/shared/core/modules/workflows/workflowSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { waitFor } from "@kraaft/shared/core/utils/sagas";
import { navigationService } from "@kraaft/web/src/core/services/navigation/navigationServiceProvider";

import { WorkflowActions, WorkflowStateActions } from "./workflowActions";

export function* workflowSagas() {
  yield* takeEvery(PoolStateActions.setPoolLocation, loadWorkflows);
  yield* takeEvery(WorkflowActions.create, createWorkflow);
  yield* takeEvery(WorkflowActions.duplicate, duplicateWorkflow);
  yield* takeEvery(WorkflowActions.setEditing, setEditingWorkflow);
  yield* spawn(WorkflowOptimisticSaga);
}

function* createWorkflow(action: ReturnType<typeof WorkflowActions.create>) {
  const newWorkflow = yield* select(selectEditWorkflow);

  try {
    if (!newWorkflow?.schemaId) {
      console.error("Edited schema has no schemaId and tries to call API");
      return;
    }

    yield* call(Api.addWorkflow, {
      name: newWorkflow.name,
      actions: newWorkflow.actions,
      enabled: newWorkflow.enabled,
      condition: newWorkflow.condition,
      schemaId: newWorkflow.schemaId,
    });

    if (action.payload.congrats) {
      yield* put(showSuccess({ title: i18n.t("modificationSaved") }));
    }
  } catch (e) {
    console.error(e);
    yield* put(showError({ title: i18n.t("errorServer") }));
  }
}

function createWorkflowChannel(poolId: string): EventChannel<Workflow[]> {
  return eventChannel((emit) => Firestore.subscribeToWorkflows(poolId, emit));
}

function* loadWorkflows({
  payload,
}: ReturnType<typeof PoolStateActions.setPoolLocation>) {
  if (payload.roleType === PoolRoleType.AT_LEAST_STANDARD) {
    const { poolId } = payload;

    const user: ReturnType<typeof selectCurrentUser> =
      yield* select(selectCurrentUser);
    if (
      !isAtLeastPoolAdmin(user?.pools?.[poolId]?.role) &&
      !isUserSuperadmin(user)
    ) {
      return;
    }

    const channel = createWorkflowChannel(poolId);

    yield* takeEvery(channel, function* (workflows) {
      yield* WorkflowDelaySnapshot(WorkflowStateActions.set, {
        data: workflows,
      });
    });

    yield* take([
      UserActions.userDisconnectedFromFirebase,
      PoolStateActions.setPoolLocation,
    ]);
    channel.close();
  }
}

function* duplicateWorkflow({
  payload: { workflowId, loaderId },
}: ReturnType<typeof WorkflowActions.duplicate>) {
  try {
    yield* put(setLoader({ id: loaderId, status: LoaderStatus.LOADING }));

    const res = yield* call(Api.duplicateWorkflow, { workflowId });

    yield waitFor(selectWorkflow(res.workflowId));

    yield* put(showSuccess({ title: i18n.t("workflow.duplicatedSuccess") }));
    yield* put(setLoader({ id: loaderId, status: LoaderStatus.SUCCESS }));

    navigationService.navigateToWorkflow(res.workflowId);
  } catch (e) {
    console.error(e);

    yield* put(
      setLoader({ id: loaderId, status: LoaderStatus.FAILURE, error: e }),
    );
    yield* put(showError({ title: i18n.t("errorServer") }));
  }
}

function* setEditingWorkflow({
  payload: { workflowId },
}: ReturnType<typeof WorkflowActions.setEditing>) {
  const workflow = yield* select(selectWorkflow(workflowId));
  if (workflow) {
    yield* put(WorkflowStateActions.setEditingOrCreating(cloneDeep(workflow)));
  }
}
