import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { PoolSelectorImplementation } from "@kraaft/shared/components/poolSelectorImplementation";
import { PoolLocation } from "@kraaft/shared/core/modules/pool/poolState";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";

interface Props {
  currentPoolLocation: PoolLocation | undefined;
  onChangePoolLocation: (newPoolLocation: PoolLocation) => void;
}

const HeaderPoolSelector_ = ({
  currentPoolLocation,
  onChangePoolLocation,
}: Props) => {
  const anchorRef = useRef<View>(null);

  const { t } = useTranslation();

  const [open, setOpen, setClose] = useBooleanState();

  return (
    <>
      <View ref={anchorRef} />
      <HeaderIconButton
        accessibilityLabel={t("pools")}
        icon="users-01"
        onPress={setOpen}
      />
      <PoolSelectorImplementation
        anchorRef={anchorRef as unknown as React.RefObject<Element>}
        open={open}
        onClose={setClose}
        currentPoolLocation={currentPoolLocation}
        onChangePoolLocation={onChangePoolLocation}
      />
    </>
  );
};

export const HeaderPoolSelector = React.memo(HeaderPoolSelector_);
