import { useCallback } from "react";

import { useRoomCardActions } from "@kraaft/shared/components/roomList/useRoomCardActions";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { ActionSheet } from "@kraaft/ui";

interface RoomCardActionSheetProps {
  roomCard: AnyRoomCard;
  openNotificationsSheet(options: { roomId: string }): void;
}

export const RoomCardActionSheet =
  ActionSheet().create<RoomCardActionSheetProps>(
    ({ ActionSheetContent, ActionSheetItem }) =>
      ({ roomCard, openNotificationsSheet, onClose }) => {
        const handleOpenNotificationsSheet = useCallback(
          (roomId: string) => {
            openNotificationsSheet({ roomId });
            onClose();
          },
          [onClose, openNotificationsSheet],
        );

        const { items: actions } = useRoomCardActions({
          poolId: roomCard.poolId,
          roomCard,
          openNotificationsSheet: handleOpenNotificationsSheet,
        });

        return (
          <ActionSheetContent>
            {actions.map((action) => (
              <ActionSheetItem
                key={action.label}
                label={action.label}
                onClick={action.onClick}
                destructive={action.destructive}
                icon={action.icon}
                loading={action.loading}
              />
            ))}
          </ActionSheetContent>
        );
      },
  );
