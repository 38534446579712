import { assert } from "ts-essentials";

import {
  CursorState,
  CursorStateType,
  LinkedList,
  LinkedListElement,
} from "@kraaft/shared/core/utils/useBidirectional/createLinkedLists";
import { nullId } from "@kraaft/shared/core/utils/utils";

export class LinkedListHelpers {
  static empty() {
    return {
      [nullId]: {
        earlierId: CursorState.FINISHED,
        itemId: nullId,
        laterId: CursorState.FINISHED,
      },
    };
  }

  static isEmpty(linkedList: LinkedList) {
    return LinkedListHelpers.len(linkedList) === 1 && linkedList[nullId];
  }

  static fromArray<T>(data: T[], getIdFromItem: (item: T) => string) {
    const linkedList: LinkedList = {};
    let lastId: string | undefined;
    for (let i = 0; i < data.length; i += 1) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const thisId = getIdFromItem(data[i]!);
      const next = data[i + 1];
      linkedList[thisId] = {
        earlierId: lastId ?? CursorState.UNKNOWN,
        itemId: thisId,
        laterId: next ? getIdFromItem(next) : CursorState.UNKNOWN,
      };
      lastId = thisId;
    }
    return linkedList;
  }

  static getItemFromId(linkedList: LinkedList, id: string) {
    return linkedList[id];
  }

  static getEarliest(linkedList: LinkedList, state?: CursorStateType) {
    return Object.values(linkedList).find((e) =>
      state
        ? e.earlierId === state
        : e.earlierId === CursorState.UNKNOWN ||
          e.earlierId === CursorState.FINISHED,
    );
  }

  static getLatest(linkedList: LinkedList, state?: CursorStateType) {
    return Object.values(linkedList).find((e) =>
      state
        ? e.laterId === state
        : e.laterId === CursorState.UNKNOWN ||
          e.laterId === CursorState.FINISHED,
    );
  }

  static getOrigin(linkedList: LinkedList, anchor: LinkedListElement) {
    let newAnchor = anchor;

    for (const { item } of LinkedListHelpers.crawl(linkedList, {
      direction: "backward",
      from: newAnchor,
    })) {
      newAnchor = item;
    }
    return newAnchor;
  }

  static getEarliestAnchored(
    linkedList: LinkedList,
    from: LinkedListElement,
    state?: CursorStateType,
  ) {
    const origin = LinkedListHelpers.getOrigin(linkedList, from);
    for (const { item } of LinkedListHelpers.crawl(linkedList, {
      from: origin,
      direction: "backward",
    })) {
      if (
        state
          ? item.earlierId === state
          : item.earlierId === CursorState.UNKNOWN ||
            item.earlierId === CursorState.FINISHED
      ) {
        return item;
      }
    }
    return undefined;
  }

  static getLatestAnchored(
    linkedList: LinkedList,
    from: LinkedListElement,
    state?: CursorStateType,
  ) {
    const origin = LinkedListHelpers.getOrigin(linkedList, from);
    for (const { item } of LinkedListHelpers.crawl(linkedList, {
      from: origin,
      direction: "forward",
    })) {
      if (
        state
          ? item.laterId === state
          : item.laterId === CursorState.UNKNOWN ||
            item.laterId === CursorState.FINISHED
      ) {
        return item;
      }
    }
    return undefined;
  }

  static buildEarlier(
    linkedList: LinkedList,
    id: string,
    includeItem: boolean,
  ) {
    const currentItem = LinkedListHelpers.getItemFromId(linkedList, id);
    if (!currentItem) {
      return {};
    }
    const builtLinkedList: LinkedList = includeItem
      ? { [currentItem.itemId]: currentItem }
      : {};
    for (const { item } of LinkedListHelpers.crawl(linkedList, {
      direction: "backward",
      from: currentItem,
    })) {
      builtLinkedList[item.itemId] = item;
    }
    return builtLinkedList;
  }

  static buildLater(linkedList: LinkedList, id: string, includeItem: boolean) {
    const currentItem = LinkedListHelpers.getItemFromId(linkedList, id);
    if (!currentItem) {
      return {};
    }
    const builtLinkedList: LinkedList = includeItem
      ? { [currentItem.itemId]: currentItem }
      : {};
    for (const { item } of LinkedListHelpers.crawl(linkedList, {
      direction: "forward",
      from: currentItem,
    })) {
      builtLinkedList[item.itemId] = item;
    }
    return builtLinkedList;
  }

  static containsEarliest(linkedList: LinkedList) {
    return Object.values(linkedList).some(
      (e) => e.earlierId === CursorState.FINISHED,
    );
  }

  static containsEarliestAnchored(linkedList: LinkedList, anchorId: string) {
    const anchor = LinkedListHelpers.getItemFromId(linkedList, anchorId);
    if (!anchor) {
      return false;
    }
    for (const { item } of LinkedListHelpers.crawl(linkedList, {
      from: anchor,
      direction: "backward",
    })) {
      if (item.earlierId === CursorState.FINISHED) {
        return true;
      }
    }
    return false;
  }

  static containsLatest(linkedList: LinkedList) {
    return Object.values(linkedList).some(
      (e) => e.laterId === CursorState.FINISHED,
    );
  }

  static containsLatestAnchored(linkedList: LinkedList, anchorId: string) {
    const anchor = LinkedListHelpers.getItemFromId(linkedList, anchorId);
    if (!anchor) {
      return false;
    }
    for (const { item } of LinkedListHelpers.crawl(linkedList, {
      from: anchor,
      direction: "forward",
    })) {
      if (item.laterId === CursorState.FINISHED) {
        return true;
      }
    }
    return false;
  }

  static isCursor(itemId: string) {
    return [CursorState.UNKNOWN, CursorState.FINISHED].includes(itemId);
  }

  private static preferIdThanCursorState(a: string | undefined, b: string) {
    if (!a) {
      return b;
    }
    if (!LinkedListHelpers.isCursor(a)) {
      return a;
    }
    return b;
  }

  static insert(target: LinkedList, source: LinkedList) {
    if (LinkedListHelpers.isEmpty(target)) {
      return source;
    }
    // If the item alreay exists in target, we want to compare earlier and later
    // And always prefer a real id over UNKNOWN or FINISHED
    for (const link of Object.values(source)) {
      target[link.itemId] = link;
    }
    return target;
  }

  static len(linkedList: LinkedList) {
    return Object.keys(linkedList).length;
  }

  static lenFrom(linkedList: LinkedList, from: LinkedListElement) {
    let length = 0;
    for (const _ of LinkedListHelpers.crawl(linkedList, { from })) {
      length += 1;
    }
    return length;
  }

  static *crawl(
    linkedList: LinkedList,
    options?: { direction?: "backward" | "forward"; from: LinkedListElement },
  ) {
    let index = 0;
    let currentItem = options?.from
      ? options.from
      : options?.direction === "backward"
        ? LinkedListHelpers.getLatest(linkedList)
        : LinkedListHelpers.getEarliest(linkedList);
    if (!currentItem) {
      return;
    }
    while (currentItem) {
      yield { item: currentItem, index };
      currentItem = LinkedListHelpers.getItemFromId(
        linkedList,
        options?.direction === "backward"
          ? currentItem.earlierId
          : currentItem.laterId,
      );
      index += 1;
    }
  }

  static positionOfAnchored(
    linkedList: LinkedList,
    anchorId: string,
    id: string,
  ) {
    const from = LinkedListHelpers.getItemFromId(linkedList, anchorId);
    if (!from) {
      return -1;
    }
    const anchor = LinkedListHelpers.getOrigin(linkedList, from);
    for (const { item, index } of LinkedListHelpers.crawl(linkedList, {
      from: anchor,
    })) {
      if (item.itemId === id) {
        return index;
      }
    }
    return -1;
  }

  static deleteReference(linkedList: LinkedList, id: string) {
    const value = linkedList[id];
    if (!value) {
      return;
    }
    const earlier = linkedList[value.earlierId];
    const later = linkedList[value.laterId];

    if (earlier) {
      earlier.laterId = value.laterId;
    }
    if (later) {
      later.earlierId = value.earlierId;
    }
    delete linkedList[id];
  }

  // eslint-disable-next-line complexity
  static checkSanity(linkedList: LinkedList) {
    const early: Record<string, LinkedListElement[]> = {};
    const late: Record<string, LinkedListElement[]> = {};
    const ids: Record<string, LinkedListElement[]> = {};

    for (const value of Object.values(linkedList)) {
      if (value.earlierId !== CursorState.UNKNOWN) {
        const e = early[value.earlierId] ?? [];
        e.push(value);
        early[value.earlierId] = e;
      }

      if (value.laterId !== CursorState.UNKNOWN) {
        const l = late[value.laterId] ?? [];
        l.push(value);
        late[value.laterId] = l;
      }

      const i = ids[value.itemId] ?? [];
      i.push(value);
      ids[value.itemId] = i;
    }

    for (const value of Object.values(early)) {
      if (value.length > 1) {
        assert(false, `Early: ${JSON.stringify(value, null, " ")}`);
      }
    }
    for (const value of Object.values(late)) {
      if (value.length > 1) {
        assert(false, `Late: ${JSON.stringify(value, null, " ")}`);
      }
    }
    for (const value of Object.values(ids)) {
      if (value.length > 1) {
        assert(false, `Ids: ${JSON.stringify(value, null, " ")}`);
      }
    }
  }

  static checkBrokenLinks(linkedList: LinkedList) {
    for (const link of Object.values(linkedList)) {
      if (
        link.earlierId === CursorState.UNKNOWN ||
        link.earlierId === CursorState.FINISHED
      ) {
        const linkHasThisAsLater = Object.values(linkedList).find(
          (e) => e.laterId === link.itemId,
        );
        if (linkHasThisAsLater) {
          console.log("Broken link, earlier is unknown but later ref this");
        }
      }

      if (
        link.laterId === CursorState.UNKNOWN ||
        link.laterId === CursorState.FINISHED
      ) {
        const linkHasThisAsLater = Object.values(linkedList).find(
          (e) => e.earlierId === link.itemId,
        );
        if (linkHasThisAsLater) {
          console.log("Broken link, later is unknown but earlier ref this");
        }
      }
    }
  }

  static prettyPrint(linkedList: LinkedList) {
    console.log(`LinkedList, length=${LinkedListHelpers.len(linkedList)}`);
    for (const { item } of LinkedListHelpers.crawl(linkedList)) {
      console.log(item.earlierId, item.itemId, item.laterId);
    }
  }
}
