import { StyleSheet } from "react-native";

import { Color, FontSize, Radius, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  banner: {
    width: "100%",
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: Spacing.S16,
    justifyContent: "center",
    alignItems: "center",
    borderTopLeftRadius: Radius.SMALL,
    borderTopRightRadius: Radius.SMALL,
  },
  floating: {
    borderBottomLeftRadius: Radius.SMALL,
    borderBottomRightRadius: Radius.SMALL,
  },
  text: {
    color: Color.WHITE,
    fontFamily: "Apercu",
    fontSize: FontSize.MEDIUM,
  },
  icon: {
    marginRight: 8,
  },
});
