import { useCallback } from "react";

import { VoidFunction } from "@kraaft/helper-types";
import { PermissionRequest } from "@kraaft/shared/components/permissionRequester/permissionRequester.types";
import { usePermissionRequester } from "@kraaft/shared/components/permissionRequester/usePermissionRequester";
import { permissions } from "@kraaft/shared/core/services/permission/permissions.provider";
import { Permissions } from "@kraaft/shared/core/services/permission/permissions.types";

export const useEnsurePermission = <T extends VoidFunction>(
  memoizedCallback: T,
  type: PermissionRequest["type"],
  trackingFrom: PermissionRequest["trackingFrom"],
) => {
  const request = usePermissionRequester();

  const ensurePermissionBeforeCallback = useCallback(
    async (...args: Parameters<T>) => {
      if (
        Permissions.convertStatusToBoolean(await permissions.statusOf(type)) ||
        Permissions.convertStatusToBoolean(await request(type, trackingFrom))
      ) {
        return memoizedCallback(...args);
      }

      return;
    },
    [type, request, trackingFrom, memoizedCallback],
  );

  return ensurePermissionBeforeCallback;
};
