import React, { useEffect, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { PhotoGalleryPicker } from "@kraaft/shared/components/galleries/photoGallery/photoGalleryPicker/photoGalleryPicker";
import { selectMiniMediasOfType } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";

export interface FillModularFolderPickerHandle {
  clearSelection(): void;
}

interface FillModularFolderPhotoGalleryPickerProps {
  roomId: string;
  onClose: () => void;
  onTitleChanged: (title: string) => void;
  onAttachFromConversation: () => void;
  isLoading: boolean;
}

export const FillModularFolderPhotoGalleryPicker = React.forwardRef<
  FillModularFolderPickerHandle,
  FillModularFolderPhotoGalleryPickerProps
>(
  (
    { onAttachFromConversation, onClose, onTitleChanged, roomId, isLoading },
    ref,
  ) => {
    const { t } = useTranslation();
    const medias = useSelector(selectMiniMediasOfType(roomId, ["image"]));

    const { title, handleClearSelection, selectionCount } =
      useMediaSelectionContext({
        medias,
        roomId,
        type: "image",
      });

    useImperativeHandle(ref, () => ({
      clearSelection: handleClearSelection,
    }));

    useEffect(() => {
      onTitleChanged(
        selectionCount === 0
          ? t("directoryActions.addFromPhotoGallery")
          : title,
      );
    }, [onTitleChanged, selectionCount, t, title]);

    return (
      <PhotoGalleryPicker
        roomId={roomId}
        medias={medias}
        onSubmit={onAttachFromConversation}
        isLoading={isLoading}
      />
    );
  },
);
