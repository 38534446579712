import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Linking, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { useContactInfoCopyIcon } from "@kraaft/shared/components/userContactInfoSheet/useContactInfoCopyIcon";
import { UserInfoRow } from "@kraaft/shared/components/userContactInfoSheet/userInfoRow";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { UserContactInfos } from "@kraaft/shared/core/modules/userContactInfos";
import { Button, Color, Icon, Spacing, Text } from "@kraaft/ui";

import { UserContactInfoSheetDefinition } from "./userContactInfoSheet.definition";

type UserContactInfoSheetContentProps = {
  onClose: () => void;
  userContactInfos?: UserContactInfos;
  isOutsider: boolean;
  isLoading: boolean;
};

export const UserContactInfoContent = ({
  onClose,
  userContactInfos,
  isOutsider,
  isLoading,
}: UserContactInfoSheetContentProps) => {
  const currentUser = useSelector(selectCurrentUser);
  const { t } = useTranslation();
  const {
    triggerEmailCopy,
    triggerPhoneCopy,
    renderEmailCopyIcon,
    renderPhoneCopyIcon,
  } = useContactInfoCopyIcon({
    email: userContactInfos?.email,
    phone: userContactInfos?.phone,
  });

  const isFirstLoading =
    isLoading && !userContactInfos?.phone && !userContactInfos?.email;

  const isMyProfile = currentUser?.id === userContactInfos?.userId;

  const handleNativeStartCall = useCallback(() => {
    if (userContactInfos?.phone) {
      Linking.openURL(`tel:${userContactInfos?.phone}`).catch(console.error);
    }
  }, [userContactInfos?.phone]);

  return (
    <UserContactInfoSheetDefinition.Paper>
      {isNative() && (
        <UserContactInfoSheetDefinition.Header onClose={onClose}>
          {t("userContactInfo.title")}
        </UserContactInfoSheetDefinition.Header>
      )}
      <UserContactInfoSheetDefinition.GrowingContent>
        <View style={styles.content}>
          <Text size="TITLE" weight="bold">
            {userContactInfos?.username}
          </Text>
          {isOutsider ? (
            <DeletedUserInfoText />
          ) : userContactInfos ? (
            <>
              <View style={styles.infoList}>
                <UserInfoRow
                  onPress={triggerPhoneCopy}
                  iconName="phone"
                  value={userContactInfos.phone}
                  renderCopyButton={renderPhoneCopyIcon}
                  isLoading={isFirstLoading}
                />
                <UserInfoRow
                  onPress={triggerEmailCopy}
                  iconName="mail-02"
                  value={userContactInfos.email}
                  renderCopyButton={renderEmailCopyIcon}
                  isLoading={isFirstLoading}
                />
              </View>
              {isNative() && !isMyProfile && (
                <Button
                  id="user-contatct-info-call-button"
                  accessibilityLabel={t("userContactInfo.call")}
                  text={t("userContactInfo.call")}
                  icon="phone"
                  disabled={!userContactInfos.phone}
                  onPress={handleNativeStartCall}
                  variant="PRIMARY"
                />
              )}
            </>
          ) : null}
        </View>
      </UserContactInfoSheetDefinition.GrowingContent>
    </UserContactInfoSheetDefinition.Paper>
  );
};

const DeletedUserInfoText = () => {
  const { t } = useTranslation();
  return (
    <View style={styles.deletedUserInfoRow}>
      <Icon name="info-circle" />
      <Text style={styles.deletedUserText} size="BODY">
        {t("userContactInfo.deletedUserText")}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    rowGap: Spacing.S16,
    minWidth: !isNative() ? 300 : "auto",
    maxWidth: !isNative() ? 350 : "auto",
  },
  infoList: {
    rowGap: Spacing.S12,
  },
  emptyInfoItemText: {
    flexGrow: 1,
    color: Color.GREEN_LAGOON,
  },
  deletedUserInfoRow: {
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    columnGap: Spacing.S8,
  },
  deletedUserText: {
    flex: 1,
  },
});
