import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { FirebaseCaptchaVerifierHandle } from "@kraaft/shared/components/auth/firebaseCaptchaVerifierHandle";
import {
  ApplicationVerifier,
  Firebase,
} from "@kraaft/shared/core/services/firebase";

declare global {
  interface Window {
    recaptchaVerifier: ApplicationVerifier;
  }
}

export function getApplicationVerifier() {
  return window.recaptchaVerifier;
}

const FirebaseCaptchaVerifier = React.forwardRef<FirebaseCaptchaVerifierHandle>(
  (_, ref) => {
    const [isVerified, setIsVerified] = useState(false);
    const onVerifiedRef = useRef<(value: void | PromiseLike<void>) => void>();

    useImperativeHandle(ref, () => ({
      verify: () => {
        if (isVerified) {
          console.log("Captcha already verified");
          return Promise.resolve();
        }
        console.log("verifying Captcha...");
        return new Promise((resolved, reject) => {
          onVerifiedRef.current = resolved;
          window.recaptchaVerifier.verify().catch(reject);
        });
      },
      getApplicationVerifier: () => {
        return window.recaptchaVerifier;
      },
    }));

    const onCaptchaContainerChange = useCallback((node: HTMLDivElement) => {
      if (node) {
        setIsVerified(false);
        window.recaptchaVerifier = new Firebase.auth.RecaptchaVerifier(
          "recaptcha",
          {
            size: "invisible",
            callback: () => {
              console.log("Captcha ok...");
              setIsVerified(true);
              onVerifiedRef.current?.();
            },
          },
        );
      }
    }, []);

    return (
      <div
        ref={onCaptchaContainerChange}
        id="recaptcha"
        data-size="invisible"
      />
    );
  },
);

export { FirebaseCaptchaVerifier };
