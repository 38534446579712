import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { KeyboardAwareScrollView } from "@kraaft/shared/components/keyboardAware";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { SearchBar } from "@kraaft/shared/components/searchBar";

export type SearchableMultiselectProps<T extends string = string, U = never> = {
  items: KSelectableListItem<T, U>[];
  selected: T[];
  onCheck?: (id: string) => void;
  onUncheck?: (id: string) => void;
};

export const SearchableMultiselect = <T extends string, U>({
  items,
  selected,
  onCheck = () => {},
  onUncheck = () => {},
}: SearchableMultiselectProps<T, U>) => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const onSelectionChange = useCallback(
    (next: T[]) => {
      const added = next.filter((id) => !selected.includes(id));
      const removed = selected.filter((f) => !next.includes(f));

      added.forEach(onCheck);
      removed.forEach(onUncheck);
    },
    [selected, onCheck, onUncheck],
  );

  return (
    <>
      <SearchBar
        isStatic
        onChange={setSearch}
        value={search}
        placeholder={t("search")}
      />
      <KeyboardAwareScrollView>
        <KSelectionList
          items={items}
          isMultiple
          labelFilter={search}
          selected={selected}
          setSelected={onSelectionChange}
        />
      </KeyboardAwareScrollView>
    </>
  );
};
