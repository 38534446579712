import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { CheckboxGuardSheet } from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuardSheet";
import { Button, Text } from "@kraaft/ui";

interface LockUnlockConfirmationProps {
  targetDisplayName: string;
  isTargetRootSection: boolean;
  onClose: () => void;
  onConfirm: () => void;
  variant: "unlockConfirmation" | "lockConfirmation";
  isInRoom: boolean;
}

export const LockUnlockConfirmation = ({
  isTargetRootSection,
  targetDisplayName,
  onClose,
  onConfirm,
  variant,
  isInRoom,
}: LockUnlockConfirmationProps) => {
  const { t } = useTranslation();

  const title = t(
    variant === "lockConfirmation"
      ? "modularRecords.lockAnswers"
      : "modularRecords.unlockAnswers",
  );

  const info = t(
    `modularRecords.checkboxGuard.${isInRoom ? "inRoom" : "inFolders"}.${
      variant === "lockConfirmation"
        ? isTargetRootSection
          ? "lockRootSection"
          : "lockSection"
        : isTargetRootSection
          ? "unlockRootSection"
          : "unlockSection"
    }`,
    { targetDisplayName },
  );

  const warning = t(
    `modularRecords.checkboxGuard.${isInRoom ? "inRoom" : "inFolders"}.${
      variant === "lockConfirmation"
        ? "lockAnswersWarning"
        : "unlockAnswersWarning"
    }`,
  );

  const confirmText = t(
    variant === "lockConfirmation"
      ? "modularRecords.lock"
      : "modularRecords.unlock",
  );

  const text = `${info}\n\n${warning}`;

  return (
    <CheckboxGuardSheet.Paper>
      <CheckboxGuardSheet.Header onClose={onClose}>
        {title}
      </CheckboxGuardSheet.Header>
      <CheckboxGuardSheet.Content>
        <View>
          <Text size="BODY">{text}</Text>
        </View>
      </CheckboxGuardSheet.Content>
      <CheckboxGuardSheet.Footer>
        <Button text={t("cancel")} variant="QUATERNARY" onPress={onClose} />
        <Button text={confirmText} onPress={onConfirm} />
      </CheckboxGuardSheet.Footer>
    </CheckboxGuardSheet.Paper>
  );
};
