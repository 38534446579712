import { useStyles } from "./draggableLineDecoration.styles";

type DraggableLineDecorationProps = {
  children: React.ReactNode;
};

const DraggableLineDecoration = ({
  children,
}: DraggableLineDecorationProps) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export { DraggableLineDecoration };
