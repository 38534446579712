import { useTranslation } from "react-i18next";

import { DeleteConfirmationDialogContent } from "@kraaft/shared/components/deleteConfirmationDialogContent";
import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame/kDialogFrame";
import { useSchemaModularFolderCount } from "@kraaft/shared/core/modules/modularFolder/hooks/useSchemaModularFolderCount";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { Preloader, Text } from "@kraaft/ui";

import { useStyles } from "./deleteSchemaDialog.styles";

interface DeleteSchemaDialogProps {
  schemaId: string;
  schemaName: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const LoadingText = () => {
  const classes = useStyles();

  return (
    <span className={classes.loader}>
      <Preloader size={12} />
    </span>
  );
};

const DeleteSchemaDialog = ({
  onDelete,
  schemaName,
  schemaId,
  onClose,
  open,
}: DeleteSchemaDialogProps) => {
  const { t } = useTranslation();
  const { count, loaded } = useSchemaModularFolderCount(schemaId, open);

  return (
    <KDialogFrame open={open} onClose={onClose}>
      <DeleteConfirmationDialogContent
        title={t("formBuilder.deleteSchemaTitle")}
        subtitle={
          <Trans
            i18nKey="formBuilder.deleteSchemaDisclaimer"
            values={{
              schemaName,
              count,
            }}
            components={{
              Loading: loaded ? <Text /> : <LoadingText />,
            }}
          />
        }
        onDelete={onDelete}
        onClose={onClose}
        inputLabel={t("delete")}
        expectedValue={schemaName}
        deleteButton={t("delete")}
        loading={!loaded}
        context=""
      />
    </KDialogFrame>
  );
};

export { DeleteSchemaDialog };
