import { Fragment, useMemo } from "react";
import {
  Animated,
  Easing,
  GestureResponderEvent,
  Platform,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";

import { RoundSelectButton } from "@kraaft/shared/components/roundSelectButton";
import { Color, Icon, Spacing } from "@kraaft/ui";

import { AVAILABLE_EMOJIS } from "./emojiPickerAvailableEmojis";

interface BaseEmojisProps {
  selectedEmoji?: string;
  evenlySpaced?: boolean;
  initialAnimationDelay?: number;

  onEmojiPicked(emoji: string): void;
  onPressAll(event: GestureResponderEvent): void;
}

export const BaseEmojisWidth = 328;

export const BaseEmojis = ({
  onEmojiPicked,
  selectedEmoji,
  evenlySpaced,
  initialAnimationDelay = 0,
  onPressAll,
}: BaseEmojisProps) => {
  const animationValues = useMemo(
    () =>
      [...Array.from({ length: AVAILABLE_EMOJIS.length + 1 }).keys()].map(
        (_, index) => {
          const value = new Animated.Value(0);
          Animated.timing(value, {
            toValue: 1,
            useNativeDriver: Platform.select({ web: false }) ?? true,
            delay: initialAnimationDelay + index * 30,
            duration: 250,
            easing: Easing.elastic(1),
          }).start();
          return value;
        },
      ),
    [initialAnimationDelay],
  );

  const selectedEmojiIndex = useMemo(() => {
    if (!selectedEmoji) {
      return -1;
    }
    const index = (AVAILABLE_EMOJIS as readonly string[]).indexOf(
      selectedEmoji,
    );
    if (index === -1) {
      return AVAILABLE_EMOJIS.length;
    }
    return index;
  }, [selectedEmoji]);

  const emojis = useMemo(
    () => (
      <>
        {AVAILABLE_EMOJIS.map((emoji, index) => {
          const finalStyle = {
            transform: [{ scale: animationValues[index] ?? 1 }],
          };

          return (
            <Fragment key={emoji}>
              <Animated.View style={finalStyle}>
                <View>
                  <RoundSelectButton
                    accessibilityLabel={emoji}
                    nativeId={`emoji-picker-emoji-${index}`}
                    onPress={() => onEmojiPicked(emoji)}
                    selected={index === selectedEmojiIndex}
                  >
                    <Text selectable={false} style={styles.emoji}>
                      {emoji}
                    </Text>
                  </RoundSelectButton>
                </View>
              </Animated.View>
            </Fragment>
          );
        })}
        <Animated.View
          style={{
            transform: [
              { scale: animationValues[AVAILABLE_EMOJIS.length] ?? 1 },
            ],
          }}
        >
          <View>
            <RoundSelectButton
              accessibilityLabel="more"
              onPress={onPressAll}
              selected={selectedEmojiIndex === AVAILABLE_EMOJIS.length}
            >
              <Icon color="GREY_TAUPE" size="LARGE" name="face-wink-plus" />
            </RoundSelectButton>
          </View>
        </Animated.View>
      </>
    ),
    [animationValues, onEmojiPicked, onPressAll, selectedEmojiIndex],
  );

  const rootStyle = useMemo<(ViewStyle | undefined)[]>(
    () => [
      styles.root,
      evenlySpaced ? { justifyContent: "space-between" } : undefined,
    ],
    [evenlySpaced],
  );

  return <View style={rootStyle}>{emojis}</View>;
};

export const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    borderRadius: 48 / 2,
    backgroundColor: Color.WHITE,
    gap: Spacing.S8,
  },
  emoji: {
    fontSize: 24,
    // Needed for android, see PillReactions for more info
    color: Color.WHITE,
  },
});
