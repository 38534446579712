import { NotBranded } from "@kraaft/helper-types";

import {
  BrowserPath,
  DevicePath,
  FilePath,
  LocalPath,
  RemotePath,
} from "../file";
import { FileAllocator, PermanentPathMeta } from "./fileAllocator";

export class WebFileAllocator implements FileAllocator {
  async temporaryIfNeeded(path: FilePath) {
    return path as BrowserPath;
  }

  async permanentIfNeeded(path: FilePath, meta: PermanentPathMeta) {
    return path as BrowserPath;
  }

  affirmExternalDevicePath<T extends string>(path: NotBranded<T>) {
    return path as any as BrowserPath;
  }

  allocatePermanentFilepath(
    extension: string,
    meta: PermanentPathMeta,
  ): LocalPath {
    throw new Error("Method not implemented.");
  }

  async temporaryFromData(extension: string, data: Uint8Array) {
    return URL.createObjectURL(new Blob([data])) as BrowserPath;
  }

  async temporaryFromRemotePath(path: RemotePath) {
    return path as any as BrowserPath;
  }

  async temporaryFromExternallyAllocatedDevicePath<T extends string>(
    path: NotBranded<T>,
  ) {
    return path as any as BrowserPath;
  }

  async permanentFromData(
    extension: string,
    data: Uint8Array,
    meta: PermanentPathMeta,
  ) {
    return this.temporaryFromData(extension, data);
  }

  permanentFromRemotePath(path: RemotePath, meta: PermanentPathMeta) {
    return this.temporaryFromRemotePath(path);
  }

  permanentFromExternallyAllocatedDevicePath<T extends string>(
    path: NotBranded<T>,
    meta: PermanentPathMeta,
  ) {
    return this.temporaryFromExternallyAllocatedDevicePath(path);
  }

  accessed(path: DevicePath): Promise<void> {
    throw new Error("Method not implemented.");
  }

  parseRemotePath<T extends string>(path: NotBranded<T>): RemotePath {
    return path as any as RemotePath;
  }

  async delete(path: LocalPath) {
    URL.revokeObjectURL(path);
  }

  async fromBlob(blob: Blob) {
    return URL.createObjectURL(blob) as BrowserPath;
  }

  fromWebFile(file: File) {
    return URL.createObjectURL(file) as BrowserPath;
  }

  async exists(file: LocalPath) {
    return true;
  }

  async size(file: LocalPath) {
    return 0;
  }

  async move(source: LocalPath, destination: LocalPath) {}

  async rename(filepath: LocalPath, name: string, meta: PermanentPathMeta) {
    return filepath;
  }
}
