import { InviteCongrats } from "@kraaft/shared/components/auth/congratulations/inviteCongrats";
import { MobileWebCongratsPanel } from "@kraaft/shared/components/auth/congratulations/mobileWebCongratsPanel";
import { useSignoutAlert } from "@kraaft/shared/core/modules/user/useSignoutAlert";
import { Box } from "@kraaft/web/src/components/box";
import { SplitScreenBackButton } from "@kraaft/web/src/components/splitScreenWrapper/splitScreenBackButton";
import { isWebMobile } from "@kraaft/web/src/core/utils";

import { SplitScreenWrapper } from "../../components/splitScreenWrapper";

const InviteCongratsScreenWeb = () => {
  const isMobileWeb = isWebMobile();

  const logout = useSignoutAlert();

  return (
    <SplitScreenWrapper>
      {isMobileWeb ? (
        <>
          <Box mb="S16">
            <SplitScreenBackButton onClick={logout} icon="log-out-02" />
          </Box>
          <MobileWebCongratsPanel />
        </>
      ) : (
        <InviteCongrats />
      )}
    </SplitScreenWrapper>
  );
};

export { InviteCongratsScreenWeb };
