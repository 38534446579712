import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useDrop } from "react-dnd";

import {
  FormBuilderDropTypes,
  InsertElementDropItem,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";

interface ElementPickerDropProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    "onDrop" | "ref"
  > {
  onDrop: (item: InsertElementDropItem) => void;
}

const ElementPickerDrop_ = ({ onDrop, ...other }: ElementPickerDropProps) => {
  const [_, drop] = useDrop({
    accept: [FormBuilderDropTypes.INSERT],
    drop: onDrop,
    canDrop: (__, monitor) => monitor.isOver({ shallow: true }),
  });

  return <div {...other} ref={drop} />;
};

export const ElementPickerDrop = memo(
  ElementPickerDrop_,
) as typeof ElementPickerDrop_;
