export enum RecordWorkspace {
  ALL_ROOMS = "ALL_ROOMS",
  IN_ROOM = "IN_ROOM",
}

export function createEntryKeyForSchemaViews(
  workspace: RecordWorkspace,
  schemaId: string,
) {
  return `${workspace}-${schemaId}`;
}
