import { useMemo, useState } from "react";

import {
  RoomListContext,
  RoomListContextType,
} from "@kraaft/shared/components/roomList/roomListContext";

export const RoomListProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [largeAddRoomButton, setLargeAddRoomButton] = useState(false);

  const context = useMemo<RoomListContextType>(
    () => ({
      isScrollingDown: largeAddRoomButton,
      setScrollingDown: setLargeAddRoomButton,
    }),
    [largeAddRoomButton],
  );

  return (
    <RoomListContext.Provider value={context}>
      {children}
    </RoomListContext.Provider>
  );
};
