import { StyleSheet } from "react-native";

import { PixelSize, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  checkbox: {
    marginLeft: Spacing.S16,
    height: PixelSize.S32,
    width: PixelSize.S32,
  },
});
