import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Icon } from "@kraaft/ui";
import { getModularColumnTypeIconBackgroundColor } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/columnUtils";

import { useStyles } from "./elementIcon.styles";

interface ColumnIconProps {
  columnType: KColumnType;
}

const ColumnIcon = ({ columnType }: ColumnIconProps) => {
  const styles = useStyles();

  return (
    <div
      className={styles.root}
      style={{
        backgroundColor: getModularColumnTypeIconBackgroundColor(columnType),
      }}
    >
      <Icon
        name={KSchemaUtils.getIconFromColumnType(columnType)}
        size="SMALL"
        color="WHITE"
      />
    </div>
  );
};

export { ColumnIcon };
