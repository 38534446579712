import { useTranslation } from "react-i18next";
import { TouchableOpacity } from "react-native";

import { DocumentLoaderProps } from "@kraaft/shared/components/documentLoader/documentLoaderProps";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

const DocumentLoader = ({
  type,
  children,
  attachment,
  onLongPress,
  trackingSource,
}: DocumentLoaderProps) => {
  const { t } = useTranslation();

  async function openDocument() {
    trackEvent({
      eventName: "Open Document",
      source: trackingSource,
      document_name: attachment.original.filename,
    });

    if (type === "view") {
      await fileSaver.viewDocument(
        attachment.original.downloadUrl,
        attachment.original.filename,
      );
    } else if (type === "download") {
      await fileSaver.download(
        attachment.original.downloadUrl,
        attachment.original.filename,
      );
    }
  }

  return (
    <TouchableOpacity
      accessibilityLabel={t("openDocument")}
      onPress={openDocument}
      onLongPress={onLongPress}
    >
      {children}
    </TouchableOpacity>
  );
};

export { DocumentLoader };
