export function getMaxRatioOfMessage(isValueForNative: boolean) {
  return isValueForNative ? 0.8 : 0.5;
}

export function getMaxWidthOfMessage(
  isValueForNative: boolean,
  sizerWidth: number,
) {
  return getMaxRatioOfMessage(isValueForNative) * sizerWidth;
}
