import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";

import {
  InfoPanel,
  InfoPanelImageType,
} from "@kraaft/shared/components/infoPanel";
import { selectPoolCurrencyCode } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  KSchema,
  KSchemaColumn,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordWithIdAndTitle } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { selectInternalPoolUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { KanbanBoard } from "@kraaft/web/src/components/kanban/board";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

import { useDisplayPropertiesFromSchema } from "./useDisplayPropertiesFromSchema";
import { useGroupByColumnFromSchema } from "./useGroupByColumnFromSchema";

import { useStyles } from "./kanbanLayout.styles";

interface Props {
  workspace: RecordWorkspace;
  schema: KSchema;
  modularRecords: ModularRecordWithIdAndTitle[];
  schemaViewId?: string;
  onRecordClick?: (recordId: string) => void;
  onRecordDrop: (
    recordId: string,
    column: KSchemaColumn,
    optionKey: string | undefined,
  ) => void;
}

const KanbanLayout = ({
  workspace,
  schema,
  schemaViewId,
  modularRecords,
  onRecordClick,
  onRecordDrop,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const poolMembers = useSelector(selectInternalPoolUsers(schema.poolId));
  const currencyCode = useSelector(selectPoolCurrencyCode(schema.poolId));

  const {
    groupedByColumn,
    groupedByItems,
    groupedByColumnOnSelectionChange,
    groupedByColumnSelectedItemIds,
  } = useGroupByColumnFromSchema(workspace, schema, schemaViewId);

  const {
    displayPropertiesItems,
    dislayPropertiesOnSelectionChange,
    displayPropertiesSelectedItemIds,
    displayedPropertiesLabel,
  } = useDisplayPropertiesFromSchema(workspace, schema, schemaViewId);

  if (!groupedByColumn) {
    return (
      <InfoPanel
        imageType={InfoPanelImageType.DATABASE}
        title={t("kanban.cannotDisplayKanbanTitle")}
        message={t("kanban.cannotDisplayKanbanMessage", {
          schemaName: schema.name,
        })}
      />
    );
  }

  return (
    <div className={classes.layoutContainer}>
      <div className={classes.header}>
        <span className={classes.picker}>
          <span className={classes.pickerLabel}>
            {t("kanban.pickColumnLabel")}
          </span>
          <span className={classes.pickerDropdown}>
            <KDropdown
              items={groupedByItems}
              onSelectionChange={groupedByColumnOnSelectionChange}
              selectedItemIds={groupedByColumnSelectedItemIds}
              withSearchBar
              variant="dark"
            />
          </span>
        </span>
        <span className={clsx(classes.picker, classes.pickerSpacer)}>
          <span className={classes.pickerLabel}>
            {t("kanban.pickPropertiesLabel")}
          </span>
          <span className={classes.pickerDropdown}>
            <KDropdown
              items={displayPropertiesItems}
              onSelectionChange={dislayPropertiesOnSelectionChange}
              selectedItemIds={displayPropertiesSelectedItemIds}
              withSearchBar
              variant="dark"
              label={displayedPropertiesLabel}
              minWidth={250}
              allowMultiple
            />
          </span>
        </span>
      </div>

      <KanbanBoard
        currencyCode={currencyCode}
        schema={schema}
        modularRecords={modularRecords}
        onRecordDrop={onRecordDrop}
        groupedByColumn={groupedByColumn}
        displayProperties={displayPropertiesSelectedItemIds}
        poolMembers={poolMembers}
        onRecordClick={onRecordClick}
      />
    </div>
  );
};

export { KanbanLayout };
