import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { AddDirectorySheetProps } from "@kraaft/shared/components/directory/addDirectorySheet";
import { useValidateNewDirectoryName } from "@kraaft/shared/components/directory/useDirectoryValidation";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { DirectoryActions } from "@kraaft/shared/core/modules/directory/directoryActions";
import { selectDirectoryIsMaxDepth } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { Button, Icon } from "@kraaft/ui";
import { UnlabeledTextInput } from "@kraaft/web/src/components/unlabeledTextInput";

import { useStyles } from "./addDirectoryInput.styles";

const AddDirectoryInput = ({
  open,
  onClose,
  roomId,
  parentId,
  loaderId,
  loading,
}: AddDirectorySheetProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const isMaxDepth = useSelector(selectDirectoryIsMaxDepth(parentId));

  const [name, setName] = useState("");

  const newDirectoryNameIsValid = useValidateNewDirectoryName({
    roomId,
    parentId,
    newName: name,
  });

  const handleCreateDirectory = useCallback(() => {
    if (isMaxDepth) {
      dispatch(
        showError({ title: "", message: t("directory.errorDepthExceeded") }),
      );
    } else {
      dispatch(
        DirectoryActions.add({
          name,
          roomId,
          loaderId,
          parentId,
        }),
      );

      setName("");
    }
  }, [dispatch, isMaxDepth, loaderId, name, parentId, roomId, t]);

  const handleCancel = useCallback(() => {
    setName("");
    onClose();
  }, [onClose]);

  const handleInputBlur = useCallback(() => {
    if (!newDirectoryNameIsValid || !name) {
      handleCancel();
    } else {
      handleCreateDirectory();
    }
  }, [newDirectoryNameIsValid, name, handleCancel, handleCreateDirectory]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (newDirectoryNameIsValid && event.key === "Enter") {
        handleCreateDirectory();
      }
    },
    [newDirectoryNameIsValid, handleCreateDirectory],
  );

  if (!open) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <Icon name="folder" size="MEDIUM" />
      </div>
      <div className={classes.textInputStyle}>
        <UnlabeledTextInput
          placeholder={t("directoryActions.new")}
          value={name}
          onChangeText={setName}
          autoFocus
          onKeyPress={handleKeyPress}
          hasError={!newDirectoryNameIsValid}
          onBlur={handleInputBlur}
        />
      </div>
      <Button
        accessibilityLabel={t("close")}
        icon="x-close"
        variant="TERTIARY"
        size="SMALL"
        // onPressIn to fire the click event before the blur event
        onPressIn={handleCancel}
      />
      <Button
        accessibilityLabel={t("add")}
        disabled={!newDirectoryNameIsValid}
        loading={loading}
        icon="check"
        variant="TERTIARY"
        size="SMALL"
        onPressIn={handleCreateDirectory}
      />
    </div>
  );
};

export { AddDirectoryInput };
