import { call, put, takeEvery } from "typed-redux-saga/macro";

import {
  UserActions,
  UserStateActions,
} from "@kraaft/shared/core/modules/user/userActions";
import { Api } from "@kraaft/shared/core/services/api/api";

export function* acknowledgeConversationOnboardingSaga() {
  yield* takeEvery(UserActions.acknowledgeConversationOnboarding, function* () {
    yield* put(
      UserStateActions.updateCurrentUserOnboardingState({
        shouldShowConversationOnboardingButton: false,
      }),
    );
    try {
      yield* call(Api.acknowledgeConversationOnboarding);
    } catch (e) {
      console.error("error calling acknowledgeConversationOnboarding", e);
    }
  });
}
