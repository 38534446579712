import { useMeshContext } from "@kraaft/helper-hooks";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularDisplayExtendedRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";

import { useStyles } from "./basicCell.styles";

export const RoomNameCell = ({
  row,
  column,
}: IBasicCellProps<KColumnType.roomName>) => {
  const classes = useStyles();

  const roomName = useMeshContext(
    ModularDisplayExtendedRequirementsContext,
    (context) => context.getRoomNameFromRecordId(row.id) ?? null,
  );

  return <span className={classes.text}>{roomName}</span>;
};
