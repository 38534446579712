import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import {
  deleteSchemaTemplate,
  startEditExistingSchemaTemplate,
} from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateActions";
import { SchemaTemplate } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";
import { EditChecklistTemplate } from "@kraaft/web/src/components/checklistTemplate/editChecklistTemplate/editChecklistTemplate";
import { KTableContext } from "@kraaft/web/src/components/kTable";
import { TableContextMenuButton } from "@kraaft/web/src/components/TableContextMenuButton/TableContextMenuButton";

interface OptionButtonProps {
  context: KTableContext;
  schema: SchemaTemplate;
}

const OptionButton = ({ context, schema }: OptionButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editModalOpen, setEditModalOpen] = useState(false);

  const closeEditModal = useCallback(() => {
    setEditModalOpen(false);
  }, [setEditModalOpen]);

  const onEdit = useCallback(() => {
    dispatch(startEditExistingSchemaTemplate({ schemaTemplateId: schema.id }));
    setEditModalOpen(true);
  }, [dispatch, setEditModalOpen, schema]);

  const onDelete = useCallback(() => {
    newConfirmationAlert({
      title: t("deleteTemplateListAlert"),
      cancelText: t("cancel"),
      confirmText: t("delete"),
      onConfirm: () =>
        dispatch(deleteSchemaTemplate({ schemaTemplateId: schema.id })),
    });
  }, [dispatch, schema, t]);

  const actions: ActionSheetItem[] = useMemo(
    () => [
      {
        label: t("editTaskTemplate"),
        onPress: onEdit,
      },
      {
        label: t("deleteTaskTemplate"),
        onPress: onDelete,
        style: "destructive",
      },
    ],
    [onDelete, onEdit, t],
  );

  return (
    <>
      <TableContextMenuButton
        testID="ide2e-task-template-edit"
        actions={actions}
        context={context}
      />
      <KDialogFrame open={editModalOpen} onClose={closeEditModal}>
        <KDialogContent
          title={t("editTaskTemplate")}
          content={<EditChecklistTemplate onClose={closeEditModal} />}
        />
      </KDialogFrame>
    </>
  );
};

export { OptionButton };
