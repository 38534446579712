import { memo, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, View, ViewStyle } from "react-native";
import isEqual from "fast-deep-equal";

import { useTextBubbleStyle } from "@kraaft/shared/components/message/messageText/useTextBubbleStyle";
import { useMessageStyles } from "@kraaft/shared/components/message/messageUtils";
import { PartitionTextView } from "@kraaft/shared/components/textInputWithMentions/partitionTextView";
import { getUsernameFromUserId } from "@kraaft/shared/components/textInputWithMentions/useMentionAwareTextState";
import { textIsOnlyEmojis } from "@kraaft/shared/components/textProcessor/textProcessor.utils";
import {
  InputPartition,
  InputPartitionHelper,
} from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { Text } from "@kraaft/ui";

import { styles } from "./textBubble.styles";

export interface TextBubbleProps {
  isReply: boolean;
  text: InputPartition[];
  withBubbleStyle?: boolean;
  style?: StyleProp<ViewStyle>;
  headerText?: string;
  modifiedText?: string;
  hasReactions: boolean;
  isFontSizeLargeBody?: boolean;
  onLongPress?: () => void;
  onPressEdit?: () => void;
  draggableElement?: ReactNode;
}

const TextBubble_ = ({
  isReply,
  text,
  withBubbleStyle = true,
  headerText,
  modifiedText,
  style,
  hasReactions,
  isFontSizeLargeBody,
  onLongPress,
  draggableElement,
}: TextBubbleProps) => {
  const { t } = useTranslation();
  const isOnlyEmojis = useMemo(
    () =>
      textIsOnlyEmojis(
        InputPartitionHelper.inputPartitionsToText(text, getUsernameFromUserId),
      ),
    [text],
  );
  const {
    messageStyle,
    textContainerStyle,
    textStyle,
    headerStyle,
    modifiedStyle,
  } = useMessageStyles({
    isReply,
    hasReactions,
    isOnlyEmojis,
    isFontSizeLargeBody,
  });

  const linkStyle = [
    styles.link,
    isReply ? styles.linkReply : styles.linkDefault,
  ];

  return (
    <View
      style={useTextBubbleStyle(
        isOnlyEmojis,
        withBubbleStyle,
        messageStyle,
        textContainerStyle,
        style,
      )}
    >
      {draggableElement}
      <View pointerEvents="box-none">
        {headerText ? (
          <View pointerEvents="none" style={styles.headerTextContainer}>
            <Text weight="bold" style={headerStyle}>
              {headerText}
            </Text>
          </View>
        ) : null}
        <View style={styles.contentContainer} pointerEvents="box-none">
          <Text>
            <PartitionTextView
              black={isReply}
              linkStyle={linkStyle}
              onLongPress={onLongPress}
              textStyle={[textStyle]}
              partitions={text}
            />
          </Text>
        </View>
        {modifiedText ? (
          <View pointerEvents="none">
            <Text style={[modifiedStyle, styles.modified]}>
              ({modifiedText})
            </Text>
          </View>
        ) : null}
      </View>
    </View>
  );
};

export const TextBubble = memo(TextBubble_, isEqual);
