import React, { useCallback, useMemo } from "react";
import {
  Pressable,
  PressableStateCallbackType,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { ColorStyle } from "@kraaft/ui";

type FileRowProps = {
  attachment: Attachment;
  leftComponent: React.ReactNode;
  extractTitle: (attachment: Attachment) => string;
  rightComponent?: React.ReactNode;
  onPress?: (attachment: Attachment) => void;
};

const FileRow = ({
  attachment,
  leftComponent,
  extractTitle,
  rightComponent,
  onPress,
}: FileRowProps) => {
  const handlePress = useCallback(() => {
    return onPress?.(attachment);
  }, [onPress, attachment]);

  const getPressableStyle = useCallback(
    (state: PressableStateCallbackType) => [
      styles.item,
      state.pressed && styles.itemPressed,
    ],
    [],
  );

  const title = useMemo(
    () => extractTitle(attachment),
    [extractTitle, attachment],
  );

  const content = (
    <View style={styles.content}>
      {leftComponent}
      <Text numberOfLines={1} style={styles.title} ellipsizeMode="tail">
        {title}
      </Text>
      {rightComponent}
    </View>
  );

  return onPress ? (
    <Pressable
      accessibilityLabel={title}
      onPress={handlePress}
      style={getPressableStyle}
    >
      {content}
    </Pressable>
  ) : (
    <View style={styles.item}>{content}</View>
  );
};

const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1,
  },
  title: {
    flexGrow: 1,
    flexShrink: 1,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  item: {},
  itemPressed: {
    opacity: 0.75,
  },
});

export { FileRow };
