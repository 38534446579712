import { formatDateCell } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { Icon } from "@kraaft/ui";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";

import { useStyles } from "./basicCell.styles";

export const DateCell = ({
  value,
  column,
  isCellSelected,
  sectionLockInfo,
}: IBasicCellProps<KColumnType.date | KColumnType.automatedCreatedAt>) => {
  const classes = useStyles({ isCellSelected });

  const formatted = formatDateCell(value, column.displayTime);

  return (
    <div className={classes.container}>
      <span className={classes.text}>{formatted}</span>
      {column.editable && !sectionLockInfo.isLocked ? (
        <span className={classes.iconContainer}>
          <Icon name="calendar" size="MEDIUM" color="FONT_HIGH_EMPHASIS" />
        </span>
      ) : null}
    </div>
  );
};
