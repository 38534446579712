import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LayoutChangeEvent,
  Platform,
  Pressable,
  TextInput,
  View,
} from "react-native";

import { FadeIn } from "@kraaft/shared/components/animated";
import { SimpleAnimatedGrowingBox } from "@kraaft/shared/components/animatedGrowingBox";
import { SimpleAnimatedGrowingBoxHandle } from "@kraaft/shared/components/animatedGrowingBox/simpleAnimatedGrowingBox";
import { AnimationState, TrackableViewProps } from "@kraaft/shared/core/types";
import { dismissNativeKeyboard } from "@kraaft/shared/core/utils/platformUtils";
import { Button, Color, ColorStyle, Icon, IconName } from "@kraaft/ui";

import { styles } from "./searchBar.styles";

export type ActionButtonProps = TrackableViewProps & {
  icon: IconName;
  onPress?: () => void;
  id?: string;
};

const ActionButton = ({
  accessibilityLabel,
  icon,
  onPress,
  id,
}: ActionButtonProps) => (
  <Button
    id={id}
    accessibilityLabel={accessibilityLabel}
    icon={icon}
    onPress={onPress}
    variant="TERTIARY"
    size="SMALL"
  />
);

export const ClearButton = ({ onPress }: { onPress?: () => void }) => {
  const { t } = useTranslation();

  return (
    <Pressable accessibilityLabel={t("clear")} onPress={onPress}>
      <View style={styles.clearButtonContainer}>
        <Icon name="x-sm" size="MINI" color="FONT_HIGH_EMPHASIS" />
      </View>
    </Pressable>
  );
};

export interface SearchBarProps {
  value: string;
  onChange: (text: string) => void;
  placeholder: string;
  onStartSearch?: () => void;
  onStopSearch?: () => void;
  onClearSearch?: () => void;
  clearSearchShouldFocus?: boolean;
  onAnimationStateChange?: (state: AnimationState) => void;
  onSubmit?: () => void;
  searchIconId?: string;
  isStatic?: boolean;
  withPlaceholderIcon?: boolean;
  withCancelButton?: boolean;
  autoFocus?: boolean;
}

const SearchBar = ({
  value,
  onChange,
  placeholder,
  onStartSearch,
  onStopSearch,
  onClearSearch,
  onAnimationStateChange,
  searchIconId,
  isStatic = false,
  withPlaceholderIcon,
  withCancelButton,
  autoFocus,
  onSubmit,
  clearSearchShouldFocus,
}: SearchBarProps) => {
  const { t } = useTranslation();

  const [parentWidth, setParentWidth] = useState(0);
  const [animationState, setAnimationState] =
    useState<AnimationState>("exited");
  const growingBoxRef = useRef<SimpleAnimatedGrowingBoxHandle>(null);

  const inputRef = useRef<TextInput>(null);

  const showClearButton = value.length > 0;

  const handleStartSearch = useCallback(() => {
    // setTimeout to fix an issue on Android in RoomSelection where the input is not focused
    // (actually another non-visible input is focused instead because the width of the input is 0)
    Platform.OS === "android"
      ? setTimeout(() => inputRef.current?.focus(), 40)
      : inputRef.current?.focus();

    growingBoxRef.current?.animateGrow();
    onStartSearch?.();
  }, [onStartSearch]);

  const handleStopSearch = useCallback(() => {
    growingBoxRef.current?.animateShrink();
    onStopSearch?.();
  }, [onStopSearch]);

  const handleClearSearch = useCallback(() => {
    onClearSearch?.();
    onChange("");
    if (clearSearchShouldFocus) {
      inputRef.current?.focus();
    }
  }, [clearSearchShouldFocus, onChange, onClearSearch]);

  const getParentWidth = useCallback(
    (e: LayoutChangeEvent) => {
      if (animationState !== "entering" && animationState !== "exiting") {
        setParentWidth(e.nativeEvent.layout.width);
      }
    },
    [animationState],
  );

  const searchBarLeft = useMemo(
    () => (
      <View style={styles.buttonContainer}>
        {["exited", "exiting"].includes(animationState) ? (
          <ActionButton
            accessibilityLabel={t("search")}
            icon="search-md"
            onPress={handleStartSearch}
            id={searchIconId}
          />
        ) : (
          <ActionButton
            accessibilityLabel={t("cancel")}
            icon="chevron-left"
            onPress={handleStopSearch}
          />
        )}
      </View>
    ),
    [animationState, handleStartSearch, handleStopSearch, searchIconId, t],
  );

  const searchBarRight = useMemo(
    () =>
      showClearButton ? <ClearButton onPress={handleClearSearch} /> : null,
    [handleClearSearch, showClearButton],
  );

  const searchBar = useMemo(
    () => (
      <View style={styles.inputContainer}>
        <View style={styles.inputWrapper}>
          {withPlaceholderIcon && (
            <Icon
              name="search-md"
              color="FONT_LOW_EMPHASIS"
              size="MEDIUM"
              style={styles.placeholderIcon}
            />
          )}
          <TextInput
            accessibilityLabel={t("search")}
            nativeID="searchbar"
            ref={inputRef}
            numberOfLines={1}
            style={styles.input}
            placeholder={placeholder}
            onChangeText={onChange}
            onFocus={handleStartSearch}
            value={value}
            autoCapitalize="none"
            autoComplete="off"
            data-lpignore="true"
            data-form-type="other"
            placeholderTextColor={ColorStyle.FONT_LOW_EMPHASIS}
            selectionColor={Color.BLUE_AZURE}
            autoFocus={autoFocus}
            onSubmitEditing={onSubmit}
            returnKeyType={onSubmit ? "search" : "default"}
          />
          {searchBarRight}
        </View>

        {withCancelButton && (
          <FadeIn visible>
            <Button
              style={styles.rightButtonContainer}
              accessibilityLabel={t("cancel")}
              text={t("cancel")}
              variant="QUATERNARY"
              size="SMALL"
              onPress={() => {
                handleClearSearch();
                dismissNativeKeyboard();
              }}
            />
          </FadeIn>
        )}
      </View>
    ),
    [
      withPlaceholderIcon,
      t,
      placeholder,
      onChange,
      handleStartSearch,
      value,
      autoFocus,
      onSubmit,
      searchBarRight,
      withCancelButton,
      handleClearSearch,
    ],
  );

  return isStatic ? (
    searchBar
  ) : (
    <>
      <View style={styles.parentViewSizer} onLayout={getParentWidth} />
      <View style={styles.searchBarContainer}>
        {searchBarLeft}
        <SimpleAnimatedGrowingBox
          ref={growingBoxRef}
          minWidth={0}
          maxWidth={parentWidth - 64}
          setAnimationState={setAnimationState}
          onAnimationStateChange={onAnimationStateChange}
        >
          {searchBar}
        </SimpleAnimatedGrowingBox>
      </View>
    </>
  );
};

export { SearchBar };
