import { useMemo } from "react";

import { Avatar, AvatarProps } from "@kraaft/shared/components/avatar";
import { RoomCardType } from "@kraaft/shared/components/roomCard/roomCardUtils";
import { opacify } from "@kraaft/shared/core/utils/colorsUtils";
import { Color, ColorStyle } from "@kraaft/ui";

interface Props {
  size?: "medium" | "large";
  roomCardType: RoomCardType;
  withPrivateIndicator?: boolean;
  withNavigationIndicator?: boolean;
  emoji?: string;
}

const RoomAvatar = ({
  withPrivateIndicator,
  size = "medium",
  roomCardType,
  withNavigationIndicator,
  emoji,
}: Props) => {
  const avatarProps = useMemo<AvatarProps>(() => {
    const baseProps: Partial<AvatarProps> = {
      ...(withNavigationIndicator
        ? { bottomIndicatorIconName: "arrow-narrow-up-right" }
        : withPrivateIndicator
          ? { bottomIndicatorIconName: "lock-03-fill" }
          : undefined),
    };

    if (roomCardType === RoomCardType.EVERYONE) {
      return {
        ...baseProps,
        ...(emoji !== undefined
          ? { type: "emoji", emoji }
          : { type: "icon", name: "home-smile" }),
        color: ColorStyle.FONT_HIGH_EMPHASIS,
        backgroundColor: Color.BLUE_COOL,
      };
    }

    if (roomCardType === RoomCardType.EXTERNAL) {
      return {
        ...baseProps,
        ...(emoji !== undefined
          ? { type: "emoji", emoji }
          : { type: "icon", name: "shake-hands" }),
        color: ColorStyle.FONT_HIGH_EMPHASIS,
        backgroundColor: opacify(Color.ORANGE_BASIC, 0.15),
      };
    }

    return {
      ...baseProps,
      ...(emoji !== undefined
        ? { type: "emoji", emoji }
        : { type: "icon", name: "message-circle-01" }),
      color: ColorStyle.FONT_HIGH_EMPHASIS,
      backgroundColor: Color.BLUE_COOL,
    };
  }, [emoji, roomCardType, withNavigationIndicator, withPrivateIndicator]);

  return <Avatar size={size} {...avatarProps} />;
};

export { RoomAvatar };
