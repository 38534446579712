import type { Task } from "../../taskStore/task";
import type { BaseAggregate } from "../optimistic.types";
import { Builder, type get } from "./builder";

export class DeleteOneOperationBuilder<
  A extends BaseAggregate,
> extends Builder {
  state = this.init<{
    input: {};
    param: { id: string };
  }>()({
    type: "custom" as const,
    replaceId: (input: { id: string }, oldId: string, newId: string) => {
      if (input.id === oldId) {
        input.id = newId;
      }
    },
    gatherIds: (input: { id: string }) => [input.id],
    shouldAcknowledge: (datas: Record<string, A>, input: { id: string }) => {
      return !datas[input.id];
    },
    expected: (datas: Record<string, A>, input: { id: string }) => {
      delete datas[input.id];
    },
  });

  mutate(mutate: (payload: get<this, "param">, task: Task) => Promise<void>) {
    return this.add({
      mutate: async (input: get<this, "param">, task: Task) => {
        await mutate(input, task);
        return true;
      },
    }).cast();
  }

  static create<A extends BaseAggregate>() {
    return new this<A>().cast();
  }

  getOperation() {
    return this.when<{ mutate: any }>().build();
  }
}
