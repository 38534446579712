import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { KSelectionList } from "@kraaft/shared/components/kSelectionList/kSelectionList";
import { poolCompanySizes } from "@kraaft/shared/components/onboarding/askPoolCompanySize/poolCompanySize";
import { usePoolCompanySize } from "@kraaft/shared/components/onboarding/askPoolCompanySize/usePoolCompanySize";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackEventOnce } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button, Text } from "@kraaft/ui";

import { styles } from "./askPoolCompanySize.styles";

const AskPoolCompanySize = () => {
  const { t } = useTranslation();

  const { setPoolCompanySize, isLoading } = usePoolCompanySize();

  useTrackEventOnce({
    eventName: "Onboarding Need To Select Company Size",
  });

  const [selected, setSelected] = useState<string>("");

  const handleSelect = useCallback((newSelection: string[]) => {
    const [newSelected] = newSelection;
    if (!newSelected) {
      return;
    }
    setSelected(newSelected);
  }, []);

  const items = useMemo(
    () =>
      poolCompanySizes.map((companySize) => ({
        label: t(`poolCompanySize.choices.${companySize}`),
        identifier: companySize,
      })),
    [t],
  );

  const next = useCallback(() => {
    setPoolCompanySize(selected).catch(console.error);

    trackEvent({
      eventName: "Onboarding Selected Company Size",
      companySize: selected,
    });
  }, [selected, setPoolCompanySize]);

  return (
    <View style={styles.root}>
      <Text weight="bold" size="H1" style={styles.title}>
        {t("poolCompanySize.whatIsYourCompanySize")}
      </Text>
      <KSelectionList
        selected={[selected]}
        setSelected={handleSelect}
        items={items}
        noPadding
      />
      <View style={styles.spacer} />
      <Button
        loading={isLoading}
        disabled={!selected}
        style={styles.nextButton}
        accessibilityLabel={t("next")}
        text={t("next")}
        onPress={next}
      />
    </View>
  );
};

export { AskPoolCompanySize };
