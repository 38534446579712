import { useCallback } from "react";
import { deburr } from "lodash";

import { KInput, KInputProps } from "@kraaft/shared/components/input/KInput";
import { sanitizeEmail } from "@kraaft/shared/core/utils";

const KInputEmail = (props: KInputProps) => {
  const { onChangeText, ...otherProps } = props;

  const handleChangeText = useCallback(
    (newValue: string) => onChangeText(sanitizeEmail(deburr(newValue))),
    [onChangeText],
  );

  return <KInput {...otherProps} onChangeText={handleChangeText} />;
};

export { KInputEmail };
