import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: Spacing.S8,
    borderRadius: Radius.SMALL,
    cursor: "grab",
    "&:hover": {
      backgroundColor: Color.BLUE_LAVENDER,
    },
    "& #column-picker-add-column svg": {
      color: ColorStyle.FONT_LOW_EMPHASIS,
    },
    "&:hover #column-picker-add-column svg": {
      color: ColorStyle.FONT_HIGH_EMPHASIS,
    },
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
