import { createContext } from "react";
import { noop } from "ts-essentials";

import { MessageWithText } from "@kraaft/shared/core/modules/message/messageState";

export interface MessageTextEditorContextType {
  selectedMessage: MessageWithText | undefined;
  setSelectedMessage(message: MessageWithText | undefined): void;
}

export const MessageTextEditorContext =
  createContext<MessageTextEditorContextType>({
    selectedMessage: undefined,
    setSelectedMessage: noop,
  });
