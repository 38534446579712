import { CheckboxGuardStrategy } from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuard";
import { CantCheckUncheckParentLocked } from "@kraaft/shared/components/modularFolders/checkboxGuard/strategies/cantCheckUncheckParentLocked";
import { CantLockUnlockParentLocked } from "@kraaft/shared/components/modularFolders/checkboxGuard/strategies/cantLockUnclockParentLocked";
import { CantUnlockNotAllowed } from "@kraaft/shared/components/modularFolders/checkboxGuard/strategies/cantUnlockNotAllowed";
import { CheckUncheckConfirmation } from "@kraaft/shared/components/modularFolders/checkboxGuard/strategies/checkUncheckConfirmation";
import { LockUnlockConfirmation } from "@kraaft/shared/components/modularFolders/checkboxGuard/strategies/lockUnlockConfirmation";
import { SectionLockInfo } from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { usePrevious } from "@kraaft/shared/core/utils/hooks";

interface CheckboxGuardCompositionProps {
  strategy: CheckboxGuardStrategy | undefined;
  sectionLockInfo: SectionLockInfo;
  onConfirm: () => void;
  onClose: () => void;
  isInRoom?: boolean;
  columnName: string;
}

// just a list of cases so
// eslint-disable-next-line complexity
export const CheckboxGuardComposition = ({
  strategy,
  sectionLockInfo,
  onConfirm,
  onClose,
  isInRoom = false,
  columnName,
}: CheckboxGuardCompositionProps) => {
  const previousStrategy = usePrevious(strategy);

  const _strategy = strategy ?? previousStrategy;

  const isLockedAtRoot =
    sectionLockInfo.section.key === KSchemaUtils.rootSectionKey;

  const targetDisplayName = isLockedAtRoot
    ? sectionLockInfo.recordTitle
    : sectionLockInfo.section.name;

  switch (_strategy) {
    case "checkConfirmation":
    case "uncheckConfirmation":
      return (
        <CheckUncheckConfirmation
          onClose={onClose}
          onConfirm={onConfirm}
          variant={_strategy}
          columnName={columnName}
        />
      );
    case "lockConfirmation":
    case "unlockConfirmation":
      return (
        <LockUnlockConfirmation
          onClose={onClose}
          onConfirm={onConfirm}
          targetDisplayName={targetDisplayName}
          isTargetRootSection={isLockedAtRoot}
          variant={_strategy}
          isInRoom={isInRoom}
        />
      );
    case "cantUnlockNotAllowed":
      return (
        <CantUnlockNotAllowed
          onClose={onClose}
          targetDisplayName={targetDisplayName}
          isTargetRootSection={isLockedAtRoot}
          isInRoom={isInRoom}
        />
      );
    case "cantUnlockParentLocked":
    case "cantLockParentLocked":
      return (
        <CantLockUnlockParentLocked
          onClose={onClose}
          sectionName={sectionLockInfo.section.name}
          modularDetailTitle={sectionLockInfo.recordTitle}
          variant={_strategy}
          isInRoom={isInRoom}
        />
      );
    case "cantCheckParentLocked":
    case "cantUncheckParentLocked":
      return (
        <CantCheckUncheckParentLocked onClose={onClose} variant={_strategy} />
      );
    default:
      return null;
  }
};
