import { createAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { EventChannel, eventChannel } from "redux-saga";
import { call, put, takeEvery } from "typed-redux-saga/macro";

import { NotUndefined } from "@kraaft/shared/core/types";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function createBasicSubscription<T, R extends NotUndefined>({
  name,
  subscribe: subcribeCall,
  onReceived,
  identifier,
}: {
  name: string;
  subscribe: (args: T, cb: (result: R) => void) => () => void;
  onReceived: (result: R, args: T) => Action;
  identifier: (args: { payload: T }) => string;
}) {
  const subscribe = createAction<T>(`${name}/subscribe`);
  const unsubscribe = createAction<T>(`${name}/unsubscribe`);

  function createChannel(args: T): EventChannel<R> {
    return eventChannel((emit) => subcribeCall(args, emit));
  }

  function* onChannelEvent(result: R, args: T) {
    yield* put(onReceived(result, args));
  }

  function* subscribeSaga(
    registerMeta: (a: ReturnType<typeof createChannel>) => void,
    { payload }: ReturnType<typeof subscribe>,
  ) {
    const channel = yield* call(createChannel, payload);

    registerMeta(channel);

    yield* takeEvery(channel, (event) => onChannelEvent(event, payload));
  }

  function* unsubscribeSaga(
    meta: ReturnType<typeof createChannel> | undefined,
  ) {
    meta?.close();
  }

  function* saga() {
    yield takeCountedDeep(
      subscribe,
      unsubscribe,
      subscribeSaga,
      unsubscribeSaga,
      identifier,
    );
  }

  return {
    saga,
    actions: {
      subscribe,
      unsubscribe,
    },
  };
}
