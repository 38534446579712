import { useCallback, useEffect } from "react";
import { ViewStyle } from "react-native";
import { useSelector } from "react-redux";

import { selectMessageSelectionAsMessageList } from "@kraaft/shared/core/modules/message/messageSelectors";
import {
  doesSchemaMatchMessageAttachmentTypes,
  getAllMessagesAttachmentTypes,
} from "@kraaft/shared/core/modules/messageAttachment/messageAttachmentUtils";
import { selectModularFoldersBySchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectRoomPoolId } from "@kraaft/shared/core/modules/room/roomSelectors";
import {
  SchemaItem,
  selectSchemaItems,
} from "@kraaft/shared/core/modules/schema/schema.items.selectors";
import { selectPoolFolderSchemasDict } from "@kraaft/shared/core/modules/schema/schema.selectors";

import { ModularSchemaListContent } from "./modularSchemaListContent";

interface Props {
  roomId: string;
  onPress: (schemaId: string) => void;
  enableScroll?: boolean;
  setOnDisabled?: () => void;
  style?: ViewStyle | undefined;
}

const ModularSchemaListForSelection = (props: Props) => {
  const {
    roomId,
    onPress,
    enableScroll,
    setOnDisabled = () => {},
    style,
  } = props;

  const poolId = useSelector(selectRoomPoolId(roomId));
  const schemas = useSelector(selectPoolFolderSchemasDict(poolId));
  const schemaItems = useSelector(selectSchemaItems(poolId, roomId, true));
  const { folders: modularFoldersBySchemaId } = useSelector(
    selectModularFoldersBySchemaId(roomId),
  );

  const selectedMessages = useSelector(
    selectMessageSelectionAsMessageList(roomId, undefined),
  );
  const selectedMessagesAttachmentTypes =
    getAllMessagesAttachmentTypes(selectedMessages);

  const isSchemaDisabled = useCallback(
    (schemaItem: SchemaItem) => {
      const schema = schemas[schemaItem.id];
      if (!schema) {
        return true;
      }
      return !doesSchemaMatchMessageAttachmentTypes(
        schema,
        selectedMessagesAttachmentTypes,
      );
    },
    [schemas, selectedMessagesAttachmentTypes],
  );

  useEffect(() => {
    if (schemaItems.some(isSchemaDisabled)) {
      setOnDisabled();
    }
  }, [isSchemaDisabled, schemaItems, setOnDisabled]);

  return (
    <ModularSchemaListContent
      modularFoldersBySchemaId={modularFoldersBySchemaId}
      isSchemaDisabled={isSchemaDisabled}
      enableScroll={enableScroll}
      onPress={onPress}
      style={style}
      schemas={schemaItems}
    />
  );
};

export { ModularSchemaListForSelection };
