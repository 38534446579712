import { StyleSheet } from "react-native";

import { styleObject } from "@kraaft/shared/styles/textInput.styles";
import { Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: "row",
    flex: 1,
    ...styleObject.inputContainer,
  },
  leftMargin: {
    marginLeft: Spacing.S16,
  },
  rightMargin: {
    marginRight: Spacing.S16,
  },
});
