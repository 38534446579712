import compact from "lodash/compact";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import partition from "lodash/partition";

import { isValidSchemaColumnForPills } from "@kraaft/shared/components/modular/pill/utils/types";
import { getModularRecordTitle } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchema,
  KSchemaColumn,
  KSchemaColumnLiteralValue,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordWithIdAndTitle } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { UserMap } from "@kraaft/shared/core/modules/user/userState";
import { i18n } from "@kraaft/shared/core/services/i18next";
import {
  GroupedRecord,
  KanbanColumnHeaderData,
  KanbanColumnHeaderDataSelect,
  KanbanColumnHeaderDataUser,
} from "@kraaft/web/src/components/kanban/board/kanbanBoardTypes";

export const KANBAN_ITEM = "kanban-item";

export const getAvailableDisplayPropertiesFromSchema = (
  rootSection: KSchemaSection,
) =>
  compact(
    KSchemaUtils.orderedColumns(rootSection).map((column) =>
      column.key !== "title" && isValidSchemaColumnForPills(column)
        ? column
        : undefined,
    ),
  );

export const getAvailableGroupByFromSchema = (schema: KSchema) => [
  ...KSchemaUtils.getColumnsOfType(schema, KColumnType.selectSingle),
  ...KSchemaUtils.getColumnsOfType(schema, KColumnType.user).filter(
    (column) => !column.allowMultiple,
  ),
];

export const groupModularRecordsByColumnValue = (
  modularRecords: ModularRecordWithIdAndTitle[],
  schemaColumn:
    | KSchemaColumn<KColumnType.selectSingle>
    | KSchemaColumn<KColumnType.user>,
  poolMembers: UserMap,
): GroupedRecord => {
  const orderedModularRecords = orderBy(modularRecords, (modularRecord) =>
    getModularRecordTitle(modularRecord),
  );

  const partitionedRecords = partition(orderedModularRecords, (modularRecord) =>
    isValidValueForColumn(modularRecord, schemaColumn, poolMembers),
  );

  return {
    withoutValue: partitionedRecords[1],
    withValue: groupBy(
      partitionedRecords[0],
      (modularRecord) =>
        ModularRecordUtils.getRecordField(
          modularRecord,
          schemaColumn.key,
          schemaColumn.type,
        )?.[0],
    ),
  };
};

export const isValidValueForColumn = (
  modularRecord: ModularRecordWithIdAndTitle,
  schemaColumn:
    | KSchemaColumn<KColumnType.selectSingle>
    | KSchemaColumn<KColumnType.user>,
  poolMembers: UserMap,
): boolean => {
  if (schemaColumn.type === KColumnType.selectSingle) {
    const value = ModularRecordUtils.getRecordField(
      modularRecord,
      schemaColumn.key,
      schemaColumn.type,
    );
    return isValidValueForSelectColumn(schemaColumn, value);
  }
  if (schemaColumn.type === KColumnType.user) {
    const value = ModularRecordUtils.getRecordField(
      modularRecord,
      schemaColumn.key,
      schemaColumn.type,
    );
    return isValidValueForUserColumn(value, poolMembers);
  }
  return false;
};

const isValidValueForSelectColumn = (
  schemaColumn: KSchemaColumn<KColumnType.selectSingle>,
  value: KSchemaColumnLiteralValue<KColumnType.selectSingle>,
) =>
  (value?.length ?? 0) > 0 &&
  Object.keys(schemaColumn.options).some((key) => key && value?.includes(key));

const isValidValueForUserColumn = (
  value: KSchemaColumnLiteralValue<KColumnType.user>,
  poolMembers: UserMap,
) =>
  (value?.length ?? 0) > 0 &&
  Object.keys(poolMembers).some((key) => key && value?.includes(key));

export const getKanbanColumnsFromSchemaColumn = (
  schemaColumn:
    | KSchemaColumn<KColumnType.selectSingle>
    | KSchemaColumn<KColumnType.user>,
  modularRecordsGrouped: GroupedRecord["withValue"],
  poolMembers: UserMap,
): KanbanColumnHeaderData[] => {
  const defaultColumns: KanbanColumnHeaderData[] = [
    { columnType: undefined, optionKey: undefined },
  ];

  if (schemaColumn.type === KColumnType.selectSingle) {
    defaultColumns.push(...getKanbanColumnForSelectColumn(schemaColumn));
  } else if (schemaColumn.type === KColumnType.user) {
    defaultColumns.push(
      ...getKanbanColumnForUserColumn(modularRecordsGrouped, poolMembers),
    );
  }
  return defaultColumns;
};

const getKanbanColumnForSelectColumn = (
  schemaColumn: KSchemaColumn<KColumnType.selectSingle>,
) =>
  KSchemaUtils.orderOptions(schemaColumn).map(
    ([key, option]): KanbanColumnHeaderDataSelect => ({
      columnType: KColumnType.selectSingle,
      optionKey: key,
      label: option.label,
      color: option.color,
    }),
  );

const getKanbanColumnForUserColumn = (
  modularRecordsGrouped: Record<string, ModularRecordWithIdAndTitle[]>,
  poolMembers: UserMap,
) =>
  orderBy(
    Object.keys(modularRecordsGrouped).map(
      (key): KanbanColumnHeaderDataUser => {
        const user = poolMembers[key];

        return {
          columnType: KColumnType.user,
          optionKey: key,
          username: user?.username ?? i18n.t("outsider"),
        };
      },
    ),
    (data) => data.username,
  );

export const getLabelForColumn = (
  kanbanColumnData: KanbanColumnHeaderData,
  column: KSchemaColumn,
) => {
  if (!kanbanColumnData.columnType) {
    return i18n.t("kanban.headerNoValue", { groupedByColumnName: column.name });
  }
  if (kanbanColumnData.columnType === KColumnType.selectSingle) {
    return kanbanColumnData.label;
  }
  if (column.type === KColumnType.user) {
    return kanbanColumnData.username;
  }
};
