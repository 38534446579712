import { messageHasTranscription } from "@kraaft/shared/components/message/messageUtils";
import {
  Message,
  MessageType,
} from "@kraaft/shared/core/modules/message/messageState";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";

/** Adding audioWithTranscription to differentiate audio messages with and without transcription because have different column requirements */
export type MessageAttachmentType = MessageType | "audioWithTranscription";

export type ColumnAttachmentType =
  | "longText"
  | "geolocation"
  | "list"
  | "gallery";

export function getAllMessagesAttachmentTypes(
  messages: Message[],
): MessageAttachmentType[] {
  const types: MessageAttachmentType[] = [];
  for (const message of messages) {
    const hasTranscription = messageHasTranscription(message);
    if (hasTranscription && !types.includes("audioWithTranscription")) {
      types.push("audioWithTranscription");
    }
    const type = hasTranscription ? "audioWithTranscription" : message.type;

    if (!types.includes(message.type)) {
      types.push(type);
    }
  }
  return types;
}

export function getColumnTypesFromMessageType(
  messageType: MessageAttachmentType,
): ColumnAttachmentType[] | undefined {
  switch (messageType) {
    case "text":
      return ["longText"];
    case "audioWithTranscription":
      return ["longText", "list"];
    case "image":
      return ["gallery", "list"];
    case "audio":
    case "video":
    case "document":
      return ["list"];
    case "geolocation":
      return ["geolocation"];
    default:
      console.warn(
        "getColumnTypeFromMessageType :: unknown column type for message type :",
        messageType,
      );
      return undefined;
  }
}

export function doesSchemaHaveColumnType<
  S extends KFolderSchema,
  T extends ColumnAttachmentType,
>(schema: S, type: T) {
  for (const column of KSchemaUtils.iterateColumns(schema.rootSection)) {
    if (column.type === KColumnType.attachment && column.mode === type) {
      return column;
    }
    if (column.type === type) {
      return column;
    }
  }
}

export function doesSchemaMatchMessageAttachmentTypes<
  S extends KFolderSchema,
  M extends MessageAttachmentType[],
>(schema: S, messageTypes: M) {
  let schemaMatchesMessageTypes = true;

  for (const messageType of messageTypes) {
    const columnTypes = getColumnTypesFromMessageType(messageType);

    let schemaHasAnyOfColumnTypes = false;
    if (columnTypes !== undefined) {
      for (const columnType of columnTypes) {
        if (doesSchemaHaveColumnType(schema, columnType)) {
          schemaHasAnyOfColumnTypes = true;
        }
      }
    }

    if (!schemaHasAnyOfColumnTypes) {
      schemaMatchesMessageTypes = false;
    }
  }

  return schemaMatchesMessageTypes;
}

export function doAllMessageAttachmentTypesMatchDirectory<
  M extends MessageAttachmentType[],
>(messageTypes: M) {
  return messageTypes.every(
    (messageType) =>
      messageType === "document" ||
      messageType === "image" ||
      messageType === "video",
  );
}
