import { StyleSheet } from "react-native";

import { ColorStyle, FontSize } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    overflow: "hidden",
    width: "100%",
  },
  roomInfo: {
    flex: 1,
    justifyContent: "center",
  },
  title: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.TITLE,
  },
});
