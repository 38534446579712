import { incrementPendingOperations } from "@kraaft/shared/core/modules/modularFolder/operations/modularFolder.offline.tools";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { ModularRecordEditionPayload } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import { Api } from "@kraaft/shared/core/services/api";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

export const editOperation = Operation.edit<ModularFolder>()
  .payload<{
    editions: Array<{
      id: string;
      properties: ModularRecordEditionPayload[];
    }>;
  }>({
    gatherIds(payload) {
      return payload.editions.map((edition) => edition.id);
    },
    replaceId(payload, oldId, newId) {
      for (const edition of payload.editions) {
        if (edition.id === oldId) {
          edition.id = newId;
        }
      }
      return payload;
    },
  })
  .dependsOn<{ getCurrentUserId(): string }>()
  .augment((payload, { getCurrentUserId }) => ({ userId: getCurrentUserId() }))
  .expected((data, payload) => {
    for (const edition of payload.editions) {
      const modularFolder = data[edition.id];
      if (!modularFolder) {
        continue;
      }
      for (const [columnKey, columnValue] of edition.properties) {
        modularFolder.properties[columnKey] = {
          ...columnValue,
          updatedBy: columnValue.updatedBy ?? payload.userId,
        };
        incrementPendingOperations(modularFolder);
      }
    }
    return data;
  })
  .mutate((payload, task) =>
    Api.editModularFolders({
      requestId: task.id,
      editions: payload.editions.map((edition) => ({
        folderId: edition.id,
        properties: KSchemaConversion.toRawTupleProperties(edition.properties),
      })),
    }),
  )
  .acknowledgeOn((modularFolders, payload, result) => {
    return payload.editions.some((edition) => {
      const modularFolder = modularFolders[edition.id];
      if (!modularFolder) {
        return false;
      }
      return modularFolder.updatedAt.getTime() >= result.updatedAt.getTime();
    });
  });
