import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { CguLinks } from "@kraaft/shared/components/auth/cguLinks";
import { useSigninTitleForInvitation } from "@kraaft/shared/components/auth/useSigninTitleForInvitation";
import { selectInviteState } from "@kraaft/shared/core/modules/app/appSelector";
import { MemoryStateActions } from "@kraaft/shared/core/modules/memory/memoryActions";
import { authErrorToTranslation } from "@kraaft/shared/core/services/auth/authErrorToTranslation";
import { firebaseSDK } from "@kraaft/shared/core/services/firebase/sdk";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { AnyUnexplained } from "@kraaft/shared/core/types";
import { Button, NonAccessibleText } from "@kraaft/ui";

import { styles } from "./signInSSO.styles";

const SignInSSO = () => {
  const navigation = useNavigationService();
  const dispatch = useDispatch();
  const inviteState = useSelector(selectInviteState);
  const provider =
    ("identityProvider" in inviteState && inviteState.identityProvider) ||
    undefined;
  const name = provider?.name ?? "SSO";

  const { t } = useTranslation();

  const [error, setError] = useState<string | null>(null);
  const [isWorking, setWorking] = useState(false);

  const title = useSigninTitleForInvitation();

  const login = useCallback(async () => {
    if (provider) {
      setWorking(true);
      try {
        await firebaseSDK.triggerSSOLogin(provider);
      } catch (e) {
        setWorking(false);
        const translation = authErrorToTranslation(e);
        if (translation === "auth.error.firebase.unlinkedAccount") {
          const email = e.email as unknown as AnyUnexplained as string;

          dispatch(MemoryStateActions.resetSSOElevationSkipped());

          const methods = await firebaseSDK.getLoginMethods(email);
          if (methods.includes("password")) {
            return navigation.navigate("EnterPassword", {
              email: email,
              exists: true,
            });
          }
          if (methods.includes("phone")) {
            return navigation.navigate("SignInPhone");
          }
        }
        setError(t(translation, { name, error: e.message }));
      }
    }
  }, [provider, t, name, dispatch, navigation]);

  const buttonText = isWorking
    ? t("sso.signin.connecting")
    : t("sso.signin.next");

  return (
    <View style={styles.container}>
      <NonAccessibleText
        weight="bold"
        size="H1"
        color="FONT_HIGH_EMPHASIS"
        id="ide2e-signinphone-title"
        style={styles.titleContainer}
        numberOfLines={3}
      >
        {title}
      </NonAccessibleText>
      {error && (
        <View style={styles.errorContainer}>
          <NonAccessibleText
            id="ide2e-error"
            size="SMALL"
            color="ACTION_DESCTRUCTIVE"
          >
            {error}
          </NonAccessibleText>
        </View>
      )}
      <Button
        variant="PRIMARY"
        onPress={login}
        accessibilityLabel={buttonText}
        text={buttonText}
        style={styles.button}
        disabled={isWorking}
      />
      <CguLinks />
    </View>
  );
};

export { SignInSSO };
