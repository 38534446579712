import { createReducer } from "@reduxjs/toolkit";

import { CompanyStateActions } from "@kraaft/shared/core/modules/company/company.actions";
import { CompanyState } from "@kraaft/shared/core/modules/company/company.state";

export const initialState: CompanyState = {
  companies: {},
};

export const companyReducers = createReducer(initialState, ({ addCase }) => {
  addCase(CompanyStateActions.set, (state, { payload }) => {
    state.companies = payload;
  });
});
