import { StyleSheet } from "react-native";

import { authScreensTokens } from "@kraaft/shared/components/auth/authScreenTokens";
import { Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  spacer: {
    flexGrow: 1,
  },
  scrollView: {
    flexGrow: 0,
    flexShrink: 1,
  },
  jobText: {
    marginBottom: authScreensTokens.marginBelowTitlesContainer,
  },
  nextButton: {
    marginTop: Spacing.S16,
  },
});
