import React, { useCallback, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FillDirectoryPickerHandle } from "@kraaft/shared/components/directory/fillDirectoryFlowProps";
import { DocumentGalleryPicker } from "@kraaft/shared/components/galleries/documentGallery/documentGalleryPicker/documentGalleryPicker";
import { selectMiniMediasOfType } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";

export interface FillModularFolderFlowProps {
  onClose: () => void;
  onTitleChanged: (title: string) => void;
  roomId: string;
  isLoading: boolean;
  onAttachFromConversation: () => void;
}

export const FillModularFolderDocumentGalleryPicker = React.forwardRef<
  FillDirectoryPickerHandle,
  FillModularFolderFlowProps
>(
  (
    { roomId, onAttachFromConversation, onClose, onTitleChanged, isLoading },
    ref,
  ) => {
    const { t } = useTranslation();
    const medias = useSelector(selectMiniMediasOfType(roomId, ["document"]));

    const { title, handleClearSelection, selectionCount } =
      useMediaSelectionContext({
        medias,
        roomId,
        type: "document",
      });

    useImperativeHandle(ref, () => ({
      clearSelection: handleClearSelection,
    }));

    const onSubmit = useCallback(() => {
      onAttachFromConversation();
    }, [onAttachFromConversation]);

    useEffect(() => {
      onTitleChanged(
        selectionCount === 0
          ? t("directoryActions.addFromDocumentGallery")
          : title,
      );
    }, [onTitleChanged, selectionCount, t, title]);

    return (
      <DocumentGalleryPicker
        roomId={roomId}
        medias={medias}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    );
  },
);
