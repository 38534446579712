import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { TFunction } from "@kraaft/shared/core/services/i18next";
import { languageFlag } from "@kraaft/shared/core/utils";
import { KNavBarTabProps } from "@kraaft/web/src/components/kNavBar/kNavBar";

const librarySchemaLanguageWithFlag = {
  ...languageFlag,
} as const satisfies {
  [key in LibrarySchemaLanguage]: string;
};

export const generateTabForLocale = (
  locale: LibrarySchemaLanguage,
  t: TFunction,
): KNavBarTabProps => ({
  id: locale,
  label: `${librarySchemaLanguageWithFlag[locale]} ${t(`locale.${locale}`)}`,
  value: locale,
});
