import { chunk } from "lodash";

import { selectMessageDataForRoom } from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import {
  Message,
  UserMessage,
} from "@kraaft/shared/core/modules/message/messageState";
import { isUserMessage } from "@kraaft/shared/core/modules/message/messageUtils";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { getStore } from "@kraaft/shared/core/store";
import { trackSinceAppStarted } from "@kraaft/shared/core/utils/datadog/useTrackAppSinceAppStarted";

export async function fetchAnsweredMessages(
  userId: string,
  roomId: string,
  messages: Record<string, Message>,
  fromCache: boolean,
) {
  const state = getStore().getState();
  if (!state) {
    return {};
  }

  const storedMessages = selectMessageDataForRoom(roomId)(state);
  const allAnsweredIds = (
    Object.values(messages).filter(
      (m) => isUserMessage(m) && m.answerTo && !storedMessages?.[m.answerTo],
    ) as UserMessage[]
  ).map((m) => m.answerTo as string);
  if (allAnsweredIds.length === 0) {
    trackSinceAppStarted("performances_first_message_fetch");
    return {};
  }
  const missingMessagesPromises = chunk(allAnsweredIds, 10).map((chk) =>
    Firestore.getUnknownMessages(chk, roomId, userId, fromCache),
  );
  const missingMessages = await Promise.all(missingMessagesPromises);
  const mergedMessages: Record<string, Message> = {};
  for (const message of missingMessages) {
    Object.assign(mergedMessages, message);
  }
  trackSinceAppStarted("performances_first_message_fetch");
  return mergedMessages;
}
