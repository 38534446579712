import React, { useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PopoverProps } from "@mui/material";

import {
  useAnswerMenuItems,
  useAttachMenuItems,
  useEditCaptionMenuItems,
  useEditTextMenuItems,
  useEditTranscriptionMenuItems,
  useForwardMenuItems,
  useRemoveMenuItems,
  useSuperadminMenuItems,
} from "@kraaft/shared/components/message/menuMessageItems";
import { useWebInfoMenuItems } from "@kraaft/shared/components/message/menuMessageItems/info/useWebInfoMenuItems";
import {
  MessageTypes,
  utils as messageUtils,
} from "@kraaft/shared/core/modules/message";
import {
  reactToMessage,
  setReplyingSourceMessage,
} from "@kraaft/shared/core/modules/message/messageActions";
import { selectMessageSelectionSource } from "@kraaft/shared/core/modules/message/messageSelectors";
import { isUserMessage } from "@kraaft/shared/core/modules/message/messageUtils";
import { selectRoomIsExternal } from "@kraaft/shared/core/modules/room/roomSelectors";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useEnsureOnline } from "@kraaft/shared/core/utils/useNetwork";
import { ActionSheetItemProps, ContextMenus } from "@kraaft/ui";
import { LegacyContextMenuButton } from "@kraaft/web/src/components/contextMenuButton";
import { useDevMenuItems } from "@kraaft/web/src/components/menuMessageContainer/dev";
import { ReactButton } from "@kraaft/web/src/components/menuMessageContainer/reactButton";
import { ReplyButton } from "@kraaft/web/src/components/menuMessageContainer/replyButton";
import { useDownloadMediaMenuItems } from "@kraaft/web/src/components/message/useDownloadMediaMenuItems";

import { makeMenuContainerStyle } from "./menuMessageContainer.styles";

interface Props {
  children: React.ReactNode;
  roomId: string;
  message: MessageTypes.Message;
  showMenu?: boolean;
  isReply?: boolean;
}

// eslint-disable-next-line complexity
const MenuMessageContainer_ = (props: Props) => {
  const dispatch = useDispatch();

  const messageRef = useRef<HTMLDivElement>(null);

  const { children, roomId, message, showMenu, isReply } = props;
  const selectionSource = useSelector(selectMessageSelectionSource(roomId));
  const showContextMenu = selectionSource === undefined;

  const classes = makeMenuContainerStyle();

  const isExternal = useSelector(selectRoomIsExternal(roomId));

  const showReplyOption = !messageUtils.isDeleted(message);
  const showReactOption = !messageUtils.isDeleted(message);

  const modifyAnchor = useMemo((): {
    anchorOrigin: PopoverProps["anchorOrigin"];
    transformOrigin: PopoverProps["transformOrigin"];
  } => {
    const horizontal = isReply ? "left" : "right";

    return {
      anchorOrigin: {
        vertical: "bottom",
        horizontal,
      },
      transformOrigin: {
        vertical: "top",
        horizontal,
      },
    };
  }, [isReply]);

  const { classifyMenuItems, unclassifyMenuItems } = useAttachMenuItems(
    roomId,
    message,
  );
  const answerMessageMenuItems = useAnswerMenuItems(roomId, message);
  const forwardMessageMenuItems = useForwardMenuItems(roomId, message);
  const downloadMenuItems = useDownloadMediaMenuItems(roomId, message);
  const editTextMenuItems = useEditTextMenuItems(roomId, message);
  const editCaptionMenuItems = useEditCaptionMenuItems(roomId, message);
  const editTranscriptionMenuItems = useEditTranscriptionMenuItems(
    roomId,
    message,
  );
  const removeMessageMenuItems = useRemoveMenuItems(roomId, message);
  const superadminMenuItems = useSuperadminMenuItems(roomId, message);
  const devMenuItems = useDevMenuItems(roomId, message);
  const { infoMenuItems, infoDialog } = useWebInfoMenuItems(roomId, message);

  const allLegacyMenuItems = useMemo(
    () =>
      messageUtils.isDeleted(message)
        ? superadminMenuItems
        : [
            ...devMenuItems,
            ...classifyMenuItems,
            ...unclassifyMenuItems,
            ...answerMessageMenuItems,
            ...forwardMessageMenuItems,
            ...downloadMenuItems,
            ...editCaptionMenuItems,
            ...editTextMenuItems,
            ...editTranscriptionMenuItems,
            ...infoMenuItems,
            ...removeMessageMenuItems,
            ...superadminMenuItems,
          ],
    [
      message,
      superadminMenuItems,
      devMenuItems,
      answerMessageMenuItems,
      forwardMessageMenuItems,
      classifyMenuItems,
      unclassifyMenuItems,
      downloadMenuItems,
      editTextMenuItems,
      editCaptionMenuItems,
      editTranscriptionMenuItems,
      infoMenuItems,
      removeMessageMenuItems,
    ],
  );

  const allMenuItems = useMemo(() => {
    return allLegacyMenuItems.map<ActionSheetItemProps>((it) => ({
      destructive: it.style === "destructive",
      icon: it.icon,
      label: it.label,
      loading: false,
      onClick: it.onPress as () => void,
    }));
  }, [allLegacyMenuItems]);

  ContextMenus.useGlobal({
    data: allMenuItems,
    contextAnchor: messageRef,
  });

  const handleOnReply = useCallback(() => {
    trackEvent({
      eventName: "Reply To Message",
      room_id: roomId,
      message_type: message.type,
      source: "web_icon_shortcut",
    });
    dispatch(setReplyingSourceMessage(roomId, message.id));
  }, [dispatch, roomId, message]);

  const handleOnReact = useEnsureOnline(
    (emoji: string) => {
      trackEvent({
        eventName: "React To Message",
        room_id: roomId,
        is_shared: isExternal,
        message_type: message.type,
        reaction: emoji,
      });

      dispatch(reactToMessage({ roomId, messageId: message.id, emoji }));
    },
    [dispatch, isExternal, message, roomId],
  );

  const optionContainerStyle = isReply
    ? classes.optionContainerReply
    : classes.optionContainerOwn;

  return (
    <>
      {infoDialog}
      <div
        className={classes.container}
        style={{
          flexDirection: isReply ? "row-reverse" : "row",
        }}
        id="message-container"
      >
        {/* Fix Long Messages on IE */}
        <div className={classes.spacer} />

        <div
          className={classes.contextMenuButton}
          style={{
            flexDirection: isReply ? "row-reverse" : "row",
          }}
        >
          {showMenu && (
            <>
              {showContextMenu && allLegacyMenuItems.length > 0 && (
                <div className={optionContainerStyle}>
                  <LegacyContextMenuButton
                    actions={allLegacyMenuItems}
                    {...modifyAnchor}
                  />
                </div>
              )}
              {showReplyOption && (
                <div className={optionContainerStyle}>
                  <ReplyButton onClick={handleOnReply} />
                </div>
              )}
              {showReactOption && isUserMessage(message) && (
                <div className={optionContainerStyle}>
                  <ReactButton onReact={handleOnReact} message={message} />
                </div>
              )}
            </>
          )}
        </div>
        <div
          className={classes.messageContentWrapper}
          ref={messageRef}
          style={{
            flexDirection: isReply ? "row-reverse" : "row",
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export const MenuMessageContainer = React.memo(MenuMessageContainer_);
