import { eventChannel } from "redux-saga";
import {
  call,
  put,
  select,
  spawn,
  takeEvery,
  takeLatest,
} from "typed-redux-saga/macro";

import * as actions from "@kraaft/shared/core/modules/app/appActions";
import { selectNetwork } from "@kraaft/shared/core/modules/app/appSelector";
import { NetworkState } from "@kraaft/shared/core/modules/app/appState";
import { network } from "@kraaft/shared/core/modules/network/network.provider";

function* updateNetworkStateSaga() {
  const channel = yield* call(createNetworkStateChannel);

  function compareNetworkStates(
    state: NetworkState,
    previousState: NetworkState,
  ) {
    let hasImproved: boolean;
    if (!state.isInternetReachable) {
      hasImproved = false;
    } else if (!previousState.isInternetReachable) {
      hasImproved = true;
    } else if (state.networkType === "2g") {
      hasImproved = false;
    } else {
      hasImproved = true;
    }
    return hasImproved;
  }

  function* receiveNetworkState(state: NetworkState) {
    const previousState = yield* select(selectNetwork);

    const hasImproved = compareNetworkStates(state, previousState);

    yield* put(actions.updateNetworkState({ ...state, hasImproved }));
  }

  const fetchNetworkState = function* () {
    const networkState = yield* call(() => network.getState());

    yield receiveNetworkState(networkState);
  };

  yield* takeEvery(channel, receiveNetworkState);

  yield* takeLatest(actions.changeAppState, fetchNetworkState);
}

function createNetworkStateChannel() {
  return eventChannel<NetworkState>((emit) =>
    network.onStateChange.register(emit),
  );
}

export function* watchNetworkSaga() {
  yield* spawn(updateNetworkStateSaga);
}
