import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { KDialogConfig } from "@kraaft/shared/components/kDialog/kDialogProps";
import { actions as loaderActions } from "@kraaft/shared/core/modules/loaders";
import { selectLoader } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { MapOverlayActions } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.actions";
import { generateAddMapOverlayLoaderId } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.actions.utils";
import { ColorStyle, Text } from "@kraaft/ui";
import {
  FileDropzone,
  FileResult,
} from "@kraaft/web/src/components/fileDropzone";

import { useStyles } from "./addMapOverlayDialog.styles";

interface AddOverlayDialogProps {
  open: boolean;
  onClose: () => void;
  poolId: string;
}

const AddMapOverlayDialog = (props: AddOverlayDialogProps) => {
  const { open, onClose, poolId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [name, setName] = useState("");
  const [fileResult, setFileResult] = useState<FileResult | undefined>(
    undefined,
  );
  const loader = useSelector(
    selectLoader(generateAddMapOverlayLoaderId(poolId)),
  );

  const isLoading = loader?.status === LoaderStatus.LOADING;
  const isDisabled = fileResult === undefined || name.length === 0;

  const resetFileErrors = useCallback(() => {
    if (loader !== undefined) {
      dispatch(loaderActions.clearLoader(loader.id));
    }
  }, [dispatch, loader]);

  useEffect(() => {
    if (open) {
      setName("");
      setFileResult(undefined);
      resetFileErrors();
    }
    // we don't want to listen to resetFileErrors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    resetFileErrors();
    // we don't want to listen to resetFileErrors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileResult]);

  useEffect(() => {
    if (loader !== undefined && loader.status === LoaderStatus.SUCCESS) {
      onClose();
    }
  }, [onClose, loader]);

  const handleUploadMapOverlay = useCallback(() => {
    if (fileResult === undefined) {
      return;
    }

    dispatch(
      MapOverlayActions.addMapOverlay({
        poolId,
        file: fileResult.file,
        name,
      }),
    );
  }, [dispatch, fileResult, name, poolId]);

  const content = useMemo(
    () => (
      <div
        className={clsx(classes.dialogContentContainer, {
          [classes.withInteractionsDisabled]: isLoading,
        })}
      >
        <Text size="MEDIUM" color={ColorStyle.FONT_HIGH_EMPHASIS}>
          {t("importMapOverlay.nameInputLabel")}
        </Text>
        <div className={classes.inputContainer}>
          <KInput
            label={t("importMapOverlay.nameInputLabel")}
            value={name}
            onChangeText={setName}
            disableAutocomplete
            autoComplete="off"
            autoFocus
          />
        </div>

        <FileDropzone
          accept={{
            "": [".kml", ".kmz"],
          }}
          maxSize={10_000_000}
          invalidFileErrorKey="importMapOverlay.importFileErrorFileInvalidTypeKML"
          fileResult={fileResult}
          setFileResult={setFileResult}
        />
      </div>
    ),
    [
      classes.dialogContentContainer,
      classes.withInteractionsDisabled,
      classes.inputContainer,
      isLoading,
      t,
      name,
      fileResult,
    ],
  );

  const dialog: KDialogConfig = {
    title: t("importMapOverlay.dialogTitle"),
    content,
    actions: [
      {
        accessibilityLabel: t("add"),
        text: t("add"),
        onPress: handleUploadMapOverlay,
        disabled: isDisabled,
        loading: isLoading,
        variant: "PRIMARY",
      },
      {
        accessibilityLabel: t("close"),
        text: t("close"),
        onPress: onClose,
        autoFocus: true,
      },
    ],
  };

  return <KDialog open={open} onClose={onClose} {...dialog} />;
};

export { AddMapOverlayDialog };
