import { FIRESTORE_VERBOSE } from "@kraaft/shared/constants/global";
import {
  FirestoreError,
  onSnapshot,
  Query,
  QuerySnapshot,
} from "@kraaft/shared/core/services/firebase/modularQuery";

export function onSnapshotQuery<T extends Query>(
  message: string,
  query: T,
  onNext: (snapshot: QuerySnapshot) => void,
  onError?: (error: FirestoreError) => void,
  shouldUpdateOnMetadataUpdate?: boolean,
) {
  if (FIRESTORE_VERBOSE) {
    console.log("subscribing to", message);
  }

  const unsubscribe = onSnapshot(
    query,
    {
      includeMetadataChanges: shouldUpdateOnMetadataUpdate ?? false,
    },
    {
      next: (snapshot: QuerySnapshot) => {
        if (snapshot) {
          if (FIRESTORE_VERBOSE) {
            console.log(
              `snapshot received for ${message}: ${snapshot.size} docs cache=${snapshot.metadata.fromCache}`,
            );
          }
          onNext(snapshot);
        } else {
          if (FIRESTORE_VERBOSE) {
            console.log(`snapshot received for ${message}: ${snapshot}`);
          }
        }
      },

      error: (error: FirestoreError) => {
        console.warn(`snapshot error for ${message}`, error);
        onError?.(error);
      },
    },
  );

  return () => {
    if (FIRESTORE_VERBOSE) {
      console.log("unsubscribing to", message);
    }
    unsubscribe();
  };
}
