import { makeStyles } from "@mui/styles";

import { IconSize } from "@kraaft/ui";

const SCALE_ANIMATION_TIME = ".05s";
const SCALE_ANIMATION_ENTER_DELAY = ".10s";

const enteredSize = {
  height: `${IconSize.XLARGE}px !important`,
  width: `${IconSize.XLARGE}px !important`,
};
const exitedSize = {
  height: `${IconSize.MEDIUM}px !important`,
  width: `${IconSize.MEDIUM}px !important`,
};

export const useScaleIconAnimationStyles = makeStyles({
  "scale-icon": {
    transition: `all ${SCALE_ANIMATION_TIME}`,
  },
  "scale-icon-in": {
    '&& svg[data-icon-name="plus"]': enteredSize,
  },
  "scale-icon-out": {
    '&& svg[data-icon-name="plus"]': exitedSize,
  },
  "@global": {
    ".scale-icon-enter": {
      '&& svg[data-icon-name="plus"]': exitedSize,
    },
    ".scale-icon-enter-active": {
      '&& svg[data-icon-name="plus"]': {
        ...enteredSize,
        transition: `all ${SCALE_ANIMATION_TIME} ease-out`,
        transitionDelay: SCALE_ANIMATION_ENTER_DELAY,
      },
    },
    ".scale-icon-enter-done": {
      '&& svg[data-icon-name="plus"]': enteredSize,
    },
    ".scale-icon-exit": {
      '&& svg[data-icon-name="plus"]': enteredSize,
    },
    ".scale-icon-exit-active": {
      '&& svg[data-icon-name="plus"]': {
        ...exitedSize,
        transition: `all ${SCALE_ANIMATION_TIME} ease-in`,
      },
    },
    ".scale-icon-exit-done": {
      '&& svg[data-icon-name="plus"]': exitedSize,
    },
  },
});
