import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useMeshContext } from "@kraaft/helper-hooks";
import { RoomFiltersContext } from "@kraaft/shared/components/filters/roomFilters.context";
import { selectUsers } from "@kraaft/shared/core/modules/user/userSelectors";

export function useResponsibleSummary() {
  const { responsibleId } = useMeshContext(RoomFiltersContext);
  const users = useSelector(selectUsers);

  const statusLabel = useMemo(() => {
    if (responsibleId === undefined) {
      return responsibleId;
    }

    const user = users[responsibleId];

    return user?.username;
  }, [responsibleId, users]);

  return statusLabel;
}
