import { useMemo } from "react";
import { TouchableOpacity } from "react-native";

import { Color, Icon, IconName, IconSize, Preloader } from "@kraaft/ui";

import { styles } from "./roundButton.styles";

// @TODO add in design system
export const RoundButton = ({
  large,
  onPress,
  accessibilityLabel,
  disabled,
  loading,
  icon,
}: {
  icon: IconName;
  onPress: () => void;
  accessibilityLabel: string;
  large?: boolean;
  disabled?: boolean;
  loading?: boolean;
}) => {
  const iconSize = large ? IconSize.MEDIUM : 20;

  const style = useMemo(
    () => [
      styles.button,
      large && styles.largeButton,
      disabled && styles.disabledButton,
    ],
    [disabled, large],
  );

  return (
    <TouchableOpacity
      accessibilityLabel={accessibilityLabel}
      style={style}
      onPress={onPress}
      disabled={disabled}
    >
      {loading ? (
        <Preloader color={Color.WHITE} size="small" absoluteFill transparent />
      ) : (
        <Icon
          id="room-text-message-submit"
          name={icon}
          size={iconSize}
          color="WHITE"
        />
      )}
    </TouchableOpacity>
  );
};
