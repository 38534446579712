import { Color, ColorStyle, IconName } from "@kraaft/ui";

export const getIconName = (
  checked: boolean,
  semiChecked: boolean,
): IconName =>
  semiChecked
    ? "checkbox-partial-fill"
    : checked
      ? "checkbox-on-fill"
      : "checkbox-off";

type getIconColorOption = {
  hovered: boolean;
  whiteOnHover: boolean;
  greyedNotSelected: boolean;
  checked: boolean;
  semiChecked: boolean;
  disabled: boolean;
};
export const getIconColor = ({
  hovered,
  whiteOnHover,
  greyedNotSelected,
  checked,
  semiChecked,
  disabled,
}: getIconColorOption) => {
  if (disabled) {
    return ColorStyle.FONT_LOW_EMPHASIS;
  }
  if (!checked && hovered && whiteOnHover) {
    return Color.WHITE;
  }
  if (greyedNotSelected && !(checked || semiChecked)) {
    return ColorStyle.FONT_LOW_EMPHASIS;
  }
  return ColorStyle.ACTION_CTA;
};
