import { call, put, select } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import {
  RoomActions,
  RoomStateActions,
} from "@kraaft/shared/core/modules/room/roomActions";
import { selectRoomNotificationFilter } from "@kraaft/shared/core/modules/room/selectors/roomOrRoomCardSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next/i18next";

export function* changeNotificationFilter(
  action: ReturnType<(typeof RoomActions)["changeNotificationFilter"]>,
) {
  const currentValue = yield* select(
    selectRoomNotificationFilter(action.payload.roomId),
  );

  try {
    yield* put(
      RoomStateActions.setNotificationFilter({
        roomId: action.payload.roomId,
        notificationFilter: action.payload.notificationFilter,
      }),
    );
    yield* call(Api.changeRoomNotificationFilter, {
      roomId: action.payload.roomId,
      notificationFilter: action.payload.notificationFilter,
    });
  } catch (e) {
    yield* put(
      RoomStateActions.setNotificationFilter({
        roomId: action.payload.roomId,
        notificationFilter: currentValue,
      }),
    );
    yield* put(showError({ title: i18n.t("serverError") }));
  }
}
