import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { WorkflowOptimisticSelector } from "@kraaft/shared/core/modules/workflows/workflow.optimistic";
import {
  WorkflowActions,
  WorkflowStateActions,
} from "@kraaft/shared/core/modules/workflows/workflowActions";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button } from "@kraaft/ui";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { useNavigationService } from "@kraaft/web/src/core/services/navigation/useNavigationService";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";
import { queries } from "@kraaft/web/src/views/app/appRouter/routes";
import { WorkflowLine } from "@kraaft/web/src/views/settings/workflows/workflowLine";
import { WorkflowSider } from "@kraaft/web/src/views/settings/workflows/workflowSider";
import { useStyles } from "@kraaft/web/src/views/settings/workflows/workflowsView.styles";

const WorkflowsView = () => {
  const workflows = useSelector(WorkflowOptimisticSelector.selectAllBuilt);

  const navigationService = useNavigationService();
  const { t } = useTranslation();
  const queryParams = useQueryString();
  const selectedWorkflowId = queryParams.get(queries.workflowId);
  const dispatch = useDispatch();
  const poolId = useSelector(selectCurrentPoolId);
  useTrackPage("Workflows");

  const shouldShowSider = useMemo(() => {
    if (!selectedWorkflowId) {
      return false;
    }

    if (selectedWorkflowId === "new") {
      return true;
    }

    return Boolean(
      workflows.find((workflow) => workflow.id === selectedWorkflowId),
    );
  }, [selectedWorkflowId, workflows]);

  const classes = useStyles({
    isDrawerOpen: shouldShowSider,
  });

  useEffect(() => {
    if (!shouldShowSider || !selectedWorkflowId) {
      return;
    }
    if (selectedWorkflowId === "new") {
      dispatch(
        WorkflowStateActions.setCreating({
          poolId,
          baseProps: {
            name: t("newWorkflow"),
          },
        }),
      );
    } else {
      dispatch(WorkflowActions.setEditing({ workflowId: selectedWorkflowId }));
    }
  }, [dispatch, shouldShowSider, poolId, selectedWorkflowId, t]);

  const closeSider = useCallback(
    () => navigationService.navigateToWorkflow(),
    [navigationService],
  );

  const handleNewWorkflowPress = useCallback(
    () => navigationService.navigateToWorkflow("new"),
    [navigationService],
  );

  const sortedWorkflows = useMemo(
    () =>
      Object.values(workflows).sort(
        (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
      ),
    [workflows],
  );

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer}>
        <PageHeader
          title={t("menuWorkflows")}
          titleSpecification={t("workflowExplanation")}
          right={
            <Button
              id="workflows-new-workflow"
              accessibilityLabel={t("newWorkflow")}
              text={t("newWorkflow")}
              onPress={handleNewWorkflowPress}
              variant="PRIMARY"
            />
          }
        />
        <div className={classes.workflows}>
          {sortedWorkflows.map((workflow) => (
            <WorkflowLine
              key={workflow.id}
              selected={workflow.id === selectedWorkflowId}
              onSelect={() => navigationService.navigateToWorkflow(workflow.id)}
              workflow={workflow}
            />
          ))}
        </div>
      </div>
      <div className={classes.rightPanelContainer}>
        {shouldShowSider ? (
          <WorkflowSider onClose={closeSider} poolId={poolId} />
        ) : null}
      </div>
    </div>
  );
};

export { WorkflowsView };
