import React, {
  UIEventHandler,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { executeAfterStatePropagation } from "@kraaft/shared/core/utils/promiseUtils";
import {
  BidirectionalListProps,
  CONSIDER_NEAR_BOTTOM_OF_LIST,
  ScrollAnchor,
  ScrollPosition,
} from "@kraaft/shared/core/utils/useBidirectional/implementations/bidirectionalList.props";
import { useScrollPromise } from "@kraaft/shared/core/utils/useBidirectional/implementations/scrollPromise";
import { useMouseButtonPressed } from "@kraaft/shared/core/utils/useBidirectional/implementations/useMouseButtonPressed";

function createIdForItem(id: string) {
  return `bidirectional-list-item-${id}`;
}

function isInViewport(rect: any) {
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function getBrowserAnchorFromScrollAnchor(
  scrollAnchor: ScrollAnchor,
): ScrollLogicalPosition {
  if (scrollAnchor === "start") {
    return "end";
  }
  if (scrollAnchor === "center") {
    return "center";
  }
  if (scrollAnchor === "end") {
    return "start";
  }
  return "center";
}

const BidirectionalList_ = <T,>({
  items,
  renderItem,
  getRenderKeyFromItem,
  getIdFromItem,
  earlierEdgeComponent,
  laterEdgeComponent,
  onScroll,
  handle,
}: BidirectionalListProps<T>) => {
  const scrollableDiv = useRef<HTMLDivElement | undefined>(undefined);
  const oldScrollHeight = useRef<number | undefined>(undefined);

  const hasScrolled = useRef(false);
  const { assign: assignScrollResolve, resolve: resolveScroll } =
    useScrollPromise();

  const handleFunctions = useMemo(
    () => ({
      scrollToBottomOfList: () =>
        new Promise<void>((res) => {
          executeAfterStatePropagation(() => {
            scrollableDiv.current?.scrollTo({
              behavior: "smooth",
              top: 0,
            });
            if (scrollableDiv.current?.scrollTop === 0) {
              return res();
            }
            assignScrollResolve(res);
            hasScrolled.current = false;
          });
        }),
      scrollToElement: (
        id: string,
        _position: ScrollPosition,
        shouldAnimate: boolean,
        _isAlreadyDisplayed: boolean,
        scrollAnchor: ScrollAnchor,
      ) =>
        new Promise<void>((res) => {
          executeAfterStatePropagation(() => {
            const element = document.getElementById(createIdForItem(id));
            if (!element) {
              return;
            }
            element.scrollIntoView({
              behavior: shouldAnimate ? "smooth" : "auto",
              block: getBrowserAnchorFromScrollAnchor(scrollAnchor),
            });
            if (isInViewport(element.getBoundingClientRect())) {
              return res();
            }
            assignScrollResolve(res);
            hasScrolled.current = false;
          });
        }),
      isNearBottomOfList: () =>
        !scrollableDiv.current ||
        scrollableDiv.current.scrollTop < CONSIDER_NEAR_BOTTOM_OF_LIST,
    }),
    [assignScrollResolve],
  );

  useImperativeHandle(handle, () => handleFunctions);

  // When starting to scroll already in the threshold zone
  // It will try to adjust scroll even if the chunk loaded is above
  // Meaning we dont want to adjust scroll in this specific case
  const unsubscribe = useRef<(() => void) | undefined>(undefined);
  const setupObserver = useCallback(
    (event: HTMLDivElement | null) => {
      const element = event;

      if (!element) {
        return unsubscribe.current?.();
      }
      scrollableDiv.current = element;

      const observer = new MutationObserver(() => {
        hasScrolled.current = false;
        if (oldScrollHeight.current === undefined) {
          return;
        }

        // This means we scrolled bottom and browser handles this automatically
        if (element.scrollTop > 200) {
          oldScrollHeight.current = element.scrollHeight;
          return;
        }
        const distanceToEdge = element.scrollTop;
        const heightDifference = element.scrollHeight - oldScrollHeight.current;

        element.scrollTop = distanceToEdge + heightDifference;

        oldScrollHeight.current = element.scrollHeight;
      });

      observer.observe(element, { childList: true });
      // Scrollable content is displayed with scaleY(-1)
      // This keeps the scroll in the right direction
      const el = element;
      function onWheel(mouseEvent: WheelEvent) {
        hasScrolled.current = true;
        el.scrollTop -= mouseEvent.deltaY;
        mouseEvent.preventDefault();
      }

      element.addEventListener("wheel", onWheel);
      element.addEventListener("scrollend", resolveScroll);

      unsubscribe.current = () => {
        element.removeEventListener("scrollend", resolveScroll);
        element.removeEventListener("wheel", onWheel);
        observer.disconnect();
      };
    },
    [resolveScroll],
  );

  useEffect(() => {
    // We need to wait for a render from the browser
    executeAfterStatePropagation(() => {
      if (items.length === 0) {
        return;
      }
      if (oldScrollHeight.current === undefined && scrollableDiv.current) {
        oldScrollHeight.current = scrollableDiv.current.scrollHeight;
      }
    });
  }, [items.length]);

  // Use to detect scroll from scrollbar
  const mouseButtonsPressed = useMouseButtonPressed();
  const internOnScroll = useCallback<UIEventHandler<HTMLDivElement>>(
    (event) => {
      onScroll(
        {
          contentHeight: event.currentTarget.clientHeight,
          scrollHeight: event.currentTarget.scrollHeight,
          scroll:
            event.currentTarget.scrollHeight -
            event.currentTarget.scrollTop -
            event.currentTarget.clientHeight,
        },
        hasScrolled.current || mouseButtonsPressed.current > 0,
      );
    },
    [mouseButtonsPressed, onScroll],
  );

  const classes = useStyles();
  const renderedItems = useMemo(
    () =>
      items.map((message, index) => (
        <div
          key={getRenderKeyFromItem(message)}
          className={classes.flipped}
          id={createIdForItem(getIdFromItem(message))}
        >
          {renderItem(message, index)}
        </div>
      )),
    [classes.flipped, getIdFromItem, getRenderKeyFromItem, items, renderItem],
  );

  return (
    <div
      ref={setupObserver}
      className={clsx(classes.root, classes.flipped)}
      onScroll={internOnScroll}
    >
      <div className={classes.flipped}>{laterEdgeComponent}</div>
      {renderedItems}
      <div className={classes.flipped}>{earlierEdgeComponent}</div>
    </div>
  );
};

export const BidirectionalList = React.memo(
  BidirectionalList_,
) as typeof BidirectionalList_;

const useStyles = makeStyles({
  root: {
    overflowY: "scroll",
    flexGrow: 1,
  },
  flipped: {
    transform: "scaleY(-1)",
  },
});
