import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import {
  Action,
  ActionFromName,
  ActionName,
  TemporaryWorkflow,
} from "@kraaft/shared/core/modules/workflows/types";

export const getDefaultAction = (
  name: ActionName,
): ActionFromName<typeof name> => {
  if (name === "logger") {
    return { name, payload: { content: "" } };
  }
  if (name === "notification") {
    return { name, payload: { content: "", users: [] } };
  }
  if (name === "email") {
    return {
      name,
      payload: {
        subject: "",
        content: "",
        users: [],
        additionalEmails: [],
        attachments: {
          columnKeys: [],
          templateIds: [],
        },
      },
    };
  }
  if (name === "messageLogger") {
    return { name, payload: { content: "" } };
  }
  // Below cannot happen
  return { name, payload: { content: "", users: [] } };
};

export function conditionNeedsRooms(condition: CompositeCondition): boolean {
  return condition.conditions.some((e) => {
    if (e.type === "composite") {
      return conditionNeedsRooms(e);
    }
    return e.type === "metadata" && e.predicate === "isInRoom";
  });
}

export function createBlankWorkflowActions(): Action[] {
  return [];
}

export function createBlankWorkflowCondition(): CompositeCondition {
  return { type: "composite", operator: "and", conditions: [] };
}

export function createBlankTemporaryWorkflow(
  poolId: string,
): TemporaryWorkflow {
  return {
    createdAt: new Date(),
    updatedAt: new Date(),
    id: undefined,
    schemaId: undefined,
    poolId: poolId ?? "",
    enabled: true,
    name: "",
    condition: createBlankWorkflowCondition(),
    actions: createBlankWorkflowActions(),
  };
}
