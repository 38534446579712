import { ensureStepType } from "@kraaft/web/src/components/multiStepDialog/multiStepDialogUtils";

import { MultiStepDialog } from "../../multiStepDialog";
import { DownloadStep } from "./downloadStep";
import { GenerateStep } from "./generateStep";

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

const GenerateReportSteps = (props: DialogProps) => {
  const { open, onClose } = props;

  const generateSteps = [
    ensureStepType<undefined>({
      dialog: GenerateStep,
    }),
    ensureStepType<undefined>({
      dialog: DownloadStep,
    }),
  ];

  return (
    <MultiStepDialog open={open} onClose={onClose} steps={generateSteps} />
  );
};

export { GenerateReportSteps };
