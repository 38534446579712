/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import {
  conditions,
  metadataConditions,
  sanitizeColumnType,
} from "@kraaft/shared/core/generated/__generated/builtinsConditions";
import {
  MetadataConditionContext,
  StandaloneMetadataConditionImplementation,
} from "@kraaft/shared/core/generated/__generated/conditionTypes";
import { ColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/column";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaSection } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";

import {
  Condition,
  ConditionObjectTypes,
  ConditionObjectTypesToType,
  CoupleConditionImplementation,
  MetadataConditionImplementation,
  Predicate,
  StandaloneConditionImplementation,
} from "./conditionTypes";

function getCoupleCondition<LHS extends KColumnType, RHS extends KColumnType>(
  lhsType: LHS,
  rhsType: RHS | undefined,
  name: Predicate,
): CoupleConditionImplementation<LHS, RHS> | undefined {
  const couples = conditions[sanitizeColumnType(lhsType)];
  if (!rhsType) {
    return;
  }
  const pairConditions = couples?.[rhsType];
  if (!pairConditions) {
    return;
  }
  if (name in pairConditions) {
    const found = pairConditions?.[
      name
    ] as unknown as CoupleConditionImplementation<LHS, RHS>;
    return found;
  }
  return;
}

function getStandaloneCondition<LHS extends KColumnType>(
  lhsType: LHS,
  name: Predicate,
): StandaloneConditionImplementation<LHS> | undefined {
  const standalones = conditions[sanitizeColumnType(lhsType)].standalone;

  if (!standalones) {
    return;
  }
  const found = standalones?.[
    name
  ] as unknown as StandaloneConditionImplementation<LHS>;
  return found;
}

export function getMetadataCondition<
  O extends ConditionObjectTypes,
  C extends KColumnType,
>(
  objectType: ConditionObjectTypes,
  columnType: C,
  name: string,
): MetadataConditionImplementation<O, C> | undefined {
  const condition = metadataConditions[objectType][columnType]?.[name];
  return condition as any;
}

export function getStandaloneMetadataCondition<O extends ConditionObjectTypes>(
  objectType: ConditionObjectTypes,
  name: string,
): StandaloneMetadataConditionImplementation<O> | undefined {
  const condition = metadataConditions[objectType].standalone?.[name];
  return condition as any;
}

export function executeCondition(
  rootSection: KSchemaSection,
  context: MetadataConditionContext,
  originalData: ConditionObjectTypesToType[ConditionObjectTypes],
  data: ModularRecord["properties"],
  condition: Condition,
  typeOfData: ConditionObjectTypes,
): boolean {
  if (condition.type === "draft") {
    return true;
  }
  if (condition.type === "composite") {
    const results = condition.conditions.map((cond) =>
      executeCondition(
        rootSection,
        context,
        originalData,
        data,
        cond,
        typeOfData,
      ),
    );
    if (condition.operator === "and") {
      return results.every((result) => result === true);
    }
    if (condition.operator === "or") {
      return results.some((result) => result === true);
    }
    return false;
  }
  if (condition.type === "metadata") {
    if (!condition.value) {
      return false;
    }
    const conditionExecuter = getMetadataCondition(
      typeOfData,
      condition.value?.columnType,
      condition.predicate,
    );
    if (!conditionExecuter) {
      console.warn(
        `Metadata condition ${condition.predicate} was written but does not exist`,
      );
      return false;
    }
    return conditionExecuter.check(context, originalData, condition.value);
  }
  if (condition.type === "standalone-metadata") {
    const conditionExecuter = getStandaloneMetadataCondition(
      typeOfData,
      condition.predicate,
    );
    if (!conditionExecuter) {
      console.warn(
        `Standalone metadata condition ${condition.predicate} was written but does not exist`,
      );
      return false;
    }
    return conditionExecuter.check(context, originalData);
  }

  let leftSide: ColumnValue | undefined = data[condition.columnKey];

  const columnKey = condition?.columnKey;
  const column = KSchemaUtils.findColumn(rootSection, columnKey);

  if (!column) {
    return false;
  }

  leftSide = leftSide || {
    value: undefined,
    columnType: column.type,
  };

  if (condition.type === "standalone-record") {
    const conditionExecuter = getStandaloneCondition(
      leftSide.columnType,
      condition.predicate,
    );

    if (!conditionExecuter) {
      console.warn(
        `Standalone condition ${leftSide?.columnType} ${condition.predicate} was written but does not exist`,
      );
      return false;
    }
    return conditionExecuter.check(leftSide);
  }
  if (condition.type === "couple-record") {
    const rightSide = condition.value;
    const conditionExecuter = getCoupleCondition(
      leftSide.columnType,
      rightSide.columnType,
      condition.predicate,
    );

    if (!conditionExecuter) {
      console.warn(
        `Condition ${leftSide.columnType} ${condition.predicate} ${rightSide.columnType} was written but does not exist`,
      );
      return false;
    }
    return conditionExecuter.check(leftSide, rightSide);
  }
  return false;
}

export { conditions };
