import { useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  ScreenKey,
  ScreenParams,
} from "@kraaft/shared/core/services/navigation/navigationParams";
import { useNavigationInit } from "@kraaft/web/src/views/app/useNavigationInit";

import { NavigationService } from "./navigationService";
import { RouteService } from "./routeService";

export function useNavigationService() {
  const history = useHistory();

  const service = useRef(new NavigationService(history));
  useNavigationInit(service.current);

  return service.current;
}

export function useRouteService<RouteName extends ScreenKey>() {
  // This is needed because useHistory doesn't re-render initially
  // Source: https://github.com/remix-run/react-router/issues/7918#issuecomment-885992777
  useLocation();

  const history = useHistory<ScreenParams<RouteName>>();

  const service = useMemo(
    () => new RouteService<RouteName>(history),
    [history],
  );

  return service;
}
