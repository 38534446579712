import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useSelector } from "react-redux";

import { DirectoryList } from "@kraaft/shared/components/actionCardList/directoryList";
import { DetailsSection } from "@kraaft/shared/components/conversationDetails/detailsSection";
import {
  SectionHeader,
  SectionHeaderLabelButton,
} from "@kraaft/shared/components/conversationDetails/sectionHeader";
import { DocumentGalleryActionCard } from "@kraaft/shared/components/conversationDetails/worksiteFolder/documentSection/documentGalleryActionCard";
import { DocumentSectionMenu } from "@kraaft/shared/components/conversationDetails/worksiteFolder/documentSection/documentSectionMenu";
import { AddDirectoryActionSheet } from "@kraaft/shared/components/directory/addDirectoryActionSheet";
import { AddDirectorySheet } from "@kraaft/shared/components/directory/addDirectorySheet";
import { useAiDirectorySettings } from "@kraaft/shared/core/modules/aiDirectory/aiDirectory.hook";
import { ROOT_DIRECTORY_ID } from "@kraaft/shared/core/modules/directory/directory";
import { selectRootDirectories } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { selectPoolByRoomId } from "@kraaft/shared/core/modules/room/selectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { nullId } from "@kraaft/shared/core/utils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { useLoader } from "@kraaft/shared/core/utils/useLoader";
import { ButtonHandle } from "@kraaft/ui";

import { styles } from "../section.styles";

interface DocumentSectionProps {
  roomId: string;
}

export const DocumentSection: React.FC<DocumentSectionProps> = ({ roomId }) => {
  const { t } = useTranslation();
  const navigation = useNavigationService();
  const directories = useSelector(selectRootDirectories(roomId));
  const pool = useSelector(selectPoolByRoomId(roomId));

  const [hasPressedAddButton, setHasPressedAddButton] = useState(false);

  const aiDirectorySettings = useAiDirectorySettings(
    pool?.id,
    !(pool?.aiDirectoryEnabled && hasPressedAddButton),
  );

  const [isAddDirectoryFlowOpen, openAddDirectoryFlow, closeAddDirectoryFlow] =
    useBooleanState();

  const addButtonRef = useRef<ButtonHandle>(null);

  const {
    element: addDirectoryActionSheet,
    open: openAddDirectoryActionSheet,
  } = AddDirectoryActionSheet.use({
    poolId: pool?.id,
    roomId,
    parentDirectoryId: ROOT_DIRECTORY_ID,
    aiDirectorySettings,
    directories,
    onOpenNewDirectorySheet: openAddDirectoryFlow,
    anchor: addButtonRef,
  });

  const { loaderId, loading } = useLoader({
    prefix: "addRootDirectoryFromWorksiteFolder",
  });

  const handleDirectoryPress = useCallback(
    (directoryId: string) => {
      navigation.navigate("RoomDirectory", { roomId, directoryId });
      const pressedDirectory = directories.find(
        (_directory) => _directory.id === directoryId,
      );

      if (pressedDirectory) {
        trackEvent({
          eventName: "Click On Directory",
          room_id: roomId,
          directory_id: directoryId,
          directory_name: pressedDirectory.name,
          directory_level: pressedDirectory.depth,
        });
      }
    },
    [directories, navigation, roomId],
  );

  const handleAddDirectory = useCallback(() => {
    setHasPressedAddButton(true);
    if (pool?.aiDirectoryEnabled) {
      openAddDirectoryActionSheet();
    } else {
      openAddDirectoryFlow();
    }
  }, [
    openAddDirectoryActionSheet,
    openAddDirectoryFlow,
    pool?.aiDirectoryEnabled,
  ]);

  if (!directories) {
    return null;
  }

  return (
    <DetailsSection>
      <SectionHeader
        title={t("documents")}
        titleElementId="room-directory-section"
      >
        <SectionHeaderLabelButton
          ref={addButtonRef}
          onPress={handleAddDirectory}
          label={t("add")}
        />
        <DocumentSectionMenu roomId={roomId} />
      </SectionHeader>
      <View style={styles.sectionContent}>
        <DocumentGalleryActionCard roomId={roomId} />
        <DirectoryList
          roomId={roomId}
          directoryId={ROOT_DIRECTORY_ID}
          directories={directories}
          cropListSize={8}
          onPressDirectory={handleDirectoryPress}
        />
        <AddDirectorySheet
          open={isAddDirectoryFlowOpen}
          onClose={closeAddDirectoryFlow}
          roomId={roomId}
          parentId={nullId}
          loaderId={loaderId}
          loading={loading}
        />
        {addDirectoryActionSheet}
      </View>
    </DetailsSection>
  );
};
