import { useCallback, useState } from "react";
import { LayoutChangeEvent, View } from "react-native";
import { useSelector } from "react-redux";

import { ConversationContext } from "@kraaft/shared/components/conversation/conversationContext";
import { ConversationTitle } from "@kraaft/shared/components/conversation/header/conversationTitle";
import { Infos } from "@kraaft/shared/components/conversationDetails/infos";
import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import {
  MessageListContext,
  useMessageListContext,
} from "@kraaft/shared/components/replyPreview/replyHooks";
import { useSubscribeToRoom } from "@kraaft/shared/core/modules/room/hooks/subscribeToRoom.hook";
import {
  selectRoom,
  selectRoomTitle,
} from "@kraaft/shared/core/modules/room/selectors";
import { selectRoomSchemaId } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { ConversationDetailsPanel } from "@kraaft/shared/core/services/navigation/navigationParams";
import { PortalHost } from "@kraaft/ui";
import { useOpenExternalUrl } from "@kraaft/web/src/core/utils/useOpenExternalUrl";
import { Conversation } from "@kraaft/web/src/views/messenger/conversation/conversation";

import { sizerStyle, useStyles } from "./conversationPreview.styles";

const PORTAL_HOSTNAME = "conversationPreviewDetails";

interface Props {
  roomId: string;
  onClose: () => void;
}

const ConversationPreview = (props: Props) => {
  const { roomId } = props;
  const classes = useStyles();

  const [openedPanel, setOpenedPanel] = useState<
    ConversationDetailsPanel | undefined
  >(undefined);
  const [sizerWidth, setSizerWidth] = useState(0);

  const room = useSelector(selectRoom(roomId));
  const roomTitle = useSelector(selectRoomTitle(roomId));
  const roomSchemaId = useSelector(selectRoomSchemaId(room?.poolId ?? ""));

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setSizerWidth(event.nativeEvent.layout.width);
  }, []);

  useSubscribeToRoom(roomId);

  return (
    <MessageListContext.Provider value={useMessageListContext()}>
      <ConversationContext.Provider value={{ preview: true }}>
        <div className={classes.container}>
          <div className={classes.conversationPanelContainer}>
            <div className={classes.conversationHeaderContainer}>
              <ConversationTitle roomId={roomId} />
            </div>
            <View style={sizerStyle.root} onLayout={onLayout}>
              <Conversation sizerWidth={sizerWidth} roomId={roomId} visible />
            </View>
          </div>
          <div className={classes.detailsContainer}>
            <DefaultLayoutHeader headerTitle={roomTitle} />
            <Infos
              roomId={roomId}
              schemaId={roomSchemaId ?? ""}
              goToUrl={useOpenExternalUrl()}
              portalHostname={PORTAL_HOSTNAME}
              openedPanel={openedPanel}
              setOpenedPanel={setOpenedPanel}
            />
            <PortalHost hostname={PORTAL_HOSTNAME} />
          </div>
        </div>
      </ConversationContext.Provider>
    </MessageListContext.Provider>
  );
};

export { ConversationPreview };
