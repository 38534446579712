import { makeStyles } from "@mui/styles";

import { Color, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
    color: Color.BLACK,
  },
  input: {
    color: Color.BLACK,
    height: "100%",
    padding: Spacing.NONE,
  },
});
