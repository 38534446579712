import { combineReducers, createReducer } from "@reduxjs/toolkit";
import isEqual from "fast-deep-equal";

import { CarouselStateActions } from "@kraaft/shared/core/modules/carousel/carousel.actions";
import { roomDataReducers } from "@kraaft/shared/core/modules/room/data/roomDataReducers";
import { RoomState } from "@kraaft/shared/core/modules/room/roomState";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import * as actions from "./roomActions";

const initialState: RoomState = {
  lastCreatedRoom: undefined,
  roomMemberLists: {},
  carousel: undefined,
  roomCarousel: undefined,
  imagePreview: undefined,
  map: {},
  geolocation: {},
  photoGalleries: {},
};

const reducer = createReducer(initialState, (builder) => {
  const { addCase } = builder;

  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);

  addCase(actions.setRoomMembers, (state, { payload }) => {
    if (!isEqual(state.roomMemberLists[payload.roomId], payload.members)) {
      state.roomMemberLists[payload.roomId] = payload.members;
    }
  });

  addCase(actions.openRoomCarousel, (state, { payload }) => {
    const { messageId } = payload;

    state.roomCarousel = { type: payload.type, show: true, messageId };
  });

  addCase(actions.closeRoomCarousel, (state) => {
    state.roomCarousel = { show: false };
  });

  addCase(CarouselStateActions.close, (state) => {
    state.roomCarousel = {
      show: false,
    };
    state.carousel = {
      show: false,
    };
  });

  addCase(actions.geolocationContextActions, (state, { payload }) => {
    const { actions: geolocationActions } = payload;

    state.geolocation.actions = geolocationActions;
  });

  addCase(actions.geolocationContextHover, (state, { payload }) => {
    const currentId = state.geolocation?.hover?.id;
    const { hovered, id, actions: geolocationActions } = payload;

    if (hovered === true) {
      state.geolocation.hover = {
        id,
      };
      state.geolocation.actions = geolocationActions;
    } else if (id === currentId || id === undefined) {
      state.geolocation.hover = undefined;
    }
  });

  addCase(actions.geolocationContextSelect, (state, { payload }) => {
    const currentId = state.geolocation?.select?.id;
    const { selected, id, actions: geolocationActions } = payload;

    if (selected === true) {
      state.geolocation.select = {
        id,
      };
      state.geolocation.actions = geolocationActions;
    } else if (id === currentId || id === undefined) {
      state.geolocation.select = undefined;
    }
  });

  addCase(actions.setMapDisplayModeForRoom, (state, { payload }) => {
    const { roomId, displayMode, schemaId } = payload;

    const roomMapContext = state.map[roomId];

    if (roomMapContext) {
      roomMapContext.displayMode = displayMode;
      roomMapContext.schemaId = schemaId;
    } else {
      state.map[roomId] = { displayMode, schemaId };
    }
  });
  addCase(actions.setVisibleMapMarkersForRoom, (state, { payload }) => {
    const { roomId, visibleMarkerIds } = payload;

    const roomMapContext = state.map[roomId];

    if (roomMapContext) {
      roomMapContext.visibleMarkerIds = visibleMarkerIds;
    } else {
      state.map[roomId] = {
        visibleMarkerIds: visibleMarkerIds,
      };
    }
  });
  addCase(actions.setMinZoomReachedForRoom, (state, { payload }) => {
    const { roomId, newState } = payload;

    const roomMapContext = state.map[roomId];

    if (roomMapContext) {
      roomMapContext.isMinZoom = newState;
    } else {
      state.map[roomId] = {
        isMinZoom: newState,
      };
    }
  });
  addCase(actions.RoomStateActions.updateRoomMembers, (state, { payload }) => {
    const { roomId, addedUserIds, removedUserIds } = payload;

    const roomMemberList = state.roomMemberLists[roomId];
    if (roomMemberList) {
      if (addedUserIds !== undefined) {
        for (const userId of addedUserIds) {
          roomMemberList.push({ userId, roomId });
        }
      }
      state.roomMemberLists[roomId] = roomMemberList.filter(
        (member) => !removedUserIds?.includes(member.userId),
      );
    }
  });
});

const combined = combineReducers({
  ui: reducer,
  data: roomDataReducers,
});

export { combined as roomReducers };
