import { View } from "react-native";

import { Icon } from "@kraaft/ui";

import { styles } from "./sendingIndicator.styles";

interface SendingIndicatorProps {
  nativeId?: string;
}

const SendingIndicator = ({ nativeId }: SendingIndicatorProps) => {
  return (
    <View style={styles.container} nativeID={nativeId}>
      <Icon size="SMALL" name="clock" color="FONT_LOW_EMPHASIS" />
    </View>
  );
};

export { SendingIndicator };
