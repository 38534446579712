import { useCallback } from "react";

import { BaseMarker } from "@kraaft/shared/components/geolocation/baseMarker";
import { markerSize } from "@kraaft/shared/components/geolocation/baseMarker/baseMarkerConfig";
import { GeolocatedElement } from "@kraaft/shared/components/geolocation/types";
import { Marker } from "@kraaft/shared/components/mapController/markers/marker";
import { MarkerContentRenderer } from "@kraaft/shared/components/mapController/markers/marker/marker.types";
import { SimpleMarkerContent } from "@kraaft/shared/components/mapController/markers/marker/simpleMarkerContent/simpleMarkerContent";
import {
  AttachmentWithGPS,
  IndexedAttachmentWithGPS,
} from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { Color, ColorStyle, IconName } from "@kraaft/ui";

interface MediaMarkerProps {
  attachment: GeolocatedElement<IndexedAttachmentWithGPS>;
  onMarkerAction: (attachmentIndex: number) => void;
}

const getIconType = (attachment: AttachmentWithGPS): IconName => {
  switch (attachment.type) {
    case "image":
      return "camera-01";
    case "video":
      return "play";
  }
};

const SimpleMediaMarker = ({
  attachment,
  onMarkerAction,
}: MediaMarkerProps) => {
  const onPress = useCallback(() => {
    onMarkerAction(attachment.ref.originalIndex);
  }, [onMarkerAction, attachment.ref.originalIndex]);

  const renderMarkerContent: MarkerContentRenderer = ({
    isHovered,
    isSelected,
  }) => (
    <BaseMarker
      isHovered={isHovered}
      isSelected={isSelected}
      baseMarkerColors={{
        bodyColor: Color.BLACK,
        strokeColor: Color.BLACK,
      }}
      hoverMarkerColors={{
        bodyColor: Color.BLACK,
        strokeColor: Color.BLACK,
      }}
      selectMarkerColors={{
        bodyColor: ColorStyle.ACTION_CTA,
        strokeColor: ColorStyle.ACTION_CTA,
      }}
    >
      <SimpleMarkerContent
        isHovered={isHovered}
        isSelected={isSelected}
        icon={getIconType(attachment.ref)}
        defaultColor={Color.WHITE}
      />
    </BaseMarker>
  );

  return (
    <Marker
      coordinates={attachment.geolocation.coords}
      id={attachment.id}
      size={markerSize}
      testID="ide2e-modularFolderPicture-marker"
      onMarkerAction={onPress}
      renderContent={renderMarkerContent}
      forceMarkerActionAndroid
    />
  );
};

export { SimpleMediaMarker };
