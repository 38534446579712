import { eventChannel } from "redux-saga";
import { call, takeLatest } from "typed-redux-saga/macro";

import { notifications } from "@kraaft/shared/core/modules/notifications/notifications.provider";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { permissions } from "@kraaft/shared/core/services/permission/permissions.provider";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

export function* registerOnLoginSaga() {
  yield* call(() => notifications.ensureInitialized());

  const channel = eventChannel((emit) =>
    permissions.addOnPermissionRequestListener(() => {
      emit(true);
    }),
  );
  yield* takeLatest(channel, logUserIn);
  yield* takeLatest(UserActions.userConnectedToFirebase, logUserIn);
}

function* logUserIn() {
  const userId = yield* waitFor(selectCurrentUserId);

  yield* call(() => notifications.login(userId));
}
