import rawEmojiList from "emoji-datasource";

import { EmojiObject } from "@kraaft/shared/components/emojiSelector/emoji.types";

function sanitizeEmojiList(emojiData: EmojiObject[]) {
  return emojiData.filter((emoji) => !emoji.obsoleted_by).filter(Boolean);
}

const filteredEmojiList = sanitizeEmojiList(rawEmojiList).sort(
  (a, b) => a.sort_order - b.sort_order,
);

export { filteredEmojiList as emojiList };
