import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { Api } from "@kraaft/shared/core/services/api";
import { Firestore } from "@kraaft/shared/core/services/firestore/firestore";
import { useLiveQuery } from "@kraaft/shared/core/utils/useLiveQuery";

import { IdentityProvider, OfflineAccessType } from "./identityProvider";
import { IdentityProviderForm } from "./identityProviderForm";

export const IdentityProviderPage = () => {
  const dispatch = useDispatch();

  const { data: identityProviders } = useLiveQuery(
    Firestore.subscribeToIdentityProviders,
    [],
  );

  const [editingProvider, setEditingProvider] =
    useState<IdentityProvider | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleAddProvider = () => {
    setEditingProvider(null); // Set to null to indicate adding a new provider
    setIsFormOpen(true);
  };

  const handleEditProvider = (provider: IdentityProvider) => {
    setEditingProvider(provider); // Populate form with selected provider for editing
    setIsFormOpen(true);
  };

  const handleFormSubmit = async (provider: IdentityProvider) => {
    if (provider.subConfigs?.length === 0) {
      provider.subConfigs = undefined;
    }

    try {
      if (editingProvider) {
        // Edit existing provider
        await Api.editIdentityProvider(provider);
      } else {
        // Add new provider
        await Api.addIdentityProvider(provider);
      }
      setIsFormOpen(false);
      setEditingProvider(null);
      dispatch(showSuccess({ title: "Modification saved" }));
    } catch (error) {
      dispatch(showError({ title: "Error", message: error.message }));
    }
  };

  return (
    <div>
      <h1>Identity Providers</h1>

      {/* Display table of identity providers */}
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {identityProviders?.map((provider) => (
            <tr key={provider.id}>
              <td>{provider.id}</td>
              <td>{provider.name}</td>
              <td>
                <button onClick={() => handleEditProvider(provider)}>
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={handleAddProvider}>Add Identity Provider</button>

      {/* Show form for adding/editing provider */}
      {isFormOpen && (
        <div>
          <h2>{editingProvider ? "Edit" : "Add"} Identity Provider</h2>
          <IdentityProviderForm
            onSubmit={handleFormSubmit}
            key={editingProvider?.id ?? "new"}
            // Pass the provider to edit or empty object for adding new
            initialValue={
              editingProvider || {
                id: "",
                name: "",
                domains: [],
                skippable: false,
                offlineAccess: "none" as OfflineAccessType,
                helpText: "",
                subConfigs: [],
              }
            }
          />
          <button onClick={() => setIsFormOpen(false)}>Cancel</button>
        </div>
      )}
    </div>
  );
};
