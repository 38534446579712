import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";

import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { RemotePath } from "@kraaft/shared/core/modules/file/file";
import { ColorStyle, Icon } from "@kraaft/ui";

import { CurrentStepProps } from "../../../multiStepDialogTypes";

import { useStyles } from "./downloadStep.styles";

interface DownloadStepState {
  downloadUrl: RemotePath;
}

type DownloadStepProps = CurrentStepProps<undefined, DownloadStepState>;

const DownloadStep = (props: DownloadStepProps) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  function downloadFile() {
    const downloadUrl = props.step.state?.downloadUrl;

    if (downloadUrl) {
      window.open(downloadUrl, "_blank");
    }
    onClose();
  }

  const buttonIcon = () => (
    <Icon name="download-cloud-01" size="MEDIUM" color="WHITE" />
  );

  const title = t("generateReportDownloadTitle");

  const content = (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Icon name="check" size={70} color={ColorStyle.ACTION_CTA} />
      </Grid>
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <Typography align="center" classes={{ root: classes.text }}>
            {t("generateReportSuccess")}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={buttonIcon()}
            disableElevation
            onClick={downloadFile}
          >
            {t("generateReportDownload")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return <KDialogContent {...{ onClose, title, content }} />;
};

export { DownloadStep };
