import { ViewStyle } from "react-native";
import { useSelector } from "react-redux";

import { selectModularFoldersBySchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectRoomPoolId } from "@kraaft/shared/core/modules/room/roomSelectors";
import { selectSchemaItems } from "@kraaft/shared/core/modules/schema/schema.items.selectors";

import { ModularSchemaListContent } from "./modularSchemaListContent";

interface Props {
  roomId: string;
  onPress: (schemaId: string) => void;
  enableScroll?: boolean;
  style?: ViewStyle | undefined;
}

const ModularSchemaList = (props: Props) => {
  const { roomId, onPress, enableScroll, style } = props;

  const poolId = useSelector(selectRoomPoolId(roomId));
  const schemas = useSelector(selectSchemaItems(poolId, roomId, false));
  const { folders: modularFoldersBySchemaId } = useSelector(
    selectModularFoldersBySchemaId(roomId),
  );

  return (
    <ModularSchemaListContent
      modularFoldersBySchemaId={modularFoldersBySchemaId}
      enableScroll={enableScroll}
      onPress={onPress}
      style={style}
      schemas={schemas}
    />
  );
};

export { ModularSchemaList };
