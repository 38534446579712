import { makeStyles } from "@mui/styles";

interface MakeStylesProps {
  isCellSelected: boolean;
}

export const useBasicCellStyles = makeStyles({
  cellCursor: ({ isCellSelected }: MakeStylesProps) => ({
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block",
    textOverflow: "ellipsis",
    height: "100%",

    ...(isCellSelected ? { cursor: "text" } : {}),
  }),
});
