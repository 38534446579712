import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { OverlayMenu } from "@kraaft/shared/components/overlayMenu";
import { ModularFolderCarousel } from "@kraaft/shared/core/modules/modularFolder/components/modularFolderCarousel";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { addTopConditionToCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionUtils";
import { selectOrderedModularFoldersWithSchemaIdAndVisibility } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import {
  ModularFolderVisibility,
  ModularFolderVisibilityType,
} from "@kraaft/shared/core/modules/modularFolder/types";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { editSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { ScreenParams } from "@kraaft/shared/core/services/navigation/navigationParams";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button } from "@kraaft/ui";
import { ExportPopup } from "@kraaft/web/src/components/exportPopup";
import { FilterMenu } from "@kraaft/web/src/components/filterMenu";
import {
  countNonCompositeConditions,
  useFilterFolders,
} from "@kraaft/web/src/components/filterMenu/filterUtils";
import { ModularFoldersFormatViewer } from "@kraaft/web/src/components/modularFoldersViewer/modularFoldersFormatViewer";
import { SaveSchemaViewButton } from "@kraaft/web/src/components/modularFoldersViewer/saveSchemaViewButton";
import { useSchemaView } from "@kraaft/web/src/components/modularFoldersViewer/useSchemaView";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { VisualisationSelector } from "@kraaft/web/src/components/visualisationSelector/visualisationSelector";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";
import { queries } from "@kraaft/web/src/views/app/appRouter/routes";

import { useSchemaViewActionSheet } from "./useSchemaViewActionSheet";

import { useStyles } from "./modularFoldersViewer.styles";

interface ConnectedModularFoldersViewerProps {
  schema: KFolderSchema;
  schemaViewId?: string | undefined;
}

const ConnectedModularFoldersViewer = ({
  schema,
  schemaViewId,
}: ConnectedModularFoldersViewerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const query = useQueryString();

  const filterButtonRef = useRef<HTMLButtonElement | null>(null);
  const exportButtonRef = useRef<HTMLButtonElement | null>(null);

  const { roomId } =
    useParams<
      ScreenParams<"ModularFoldersTable" | "ModularFoldersTableForRoom">
    >();

  const [filterOverlay, setFilterOverlay] = useState(false);
  const filterWorkspace = useMemo(
    () => (roomId ? RecordWorkspace.IN_ROOM : RecordWorkspace.ALL_ROOMS),
    [roomId],
  );
  const schemaView = useSchemaView(filterWorkspace, schema.id, schemaViewId);

  const nonCompositeConditions = countNonCompositeConditions(
    schemaView.filters,
  );
  const buttonText =
    nonCompositeConditions > 0 ? `(${nonCompositeConditions})` : undefined;

  const format = query.get(queries.format);

  const visibilities = useMemo<ModularFolderVisibility[]>(() => {
    if (roomId) {
      return [{ type: ModularFolderVisibilityType.Room, roomId }];
    }
    return [
      { type: ModularFolderVisibilityType.User },
      { type: ModularFolderVisibilityType.Pool, poolId: schema.poolId },
    ];
  }, [roomId, schema.poolId]);

  const { folders } = useSelector(
    selectOrderedModularFoldersWithSchemaIdAndVisibility({
      visibilities,
      schemaId: schema.id,
    }),
  );

  const filteredFolders = useFilterFolders(
    schema.rootSection,
    folders,
    schemaView?.filters,
  );

  useTrackPage("ModularFoldersTableForRoom", {
    identifier: `${roomId}-${schema?.id}`,
    shouldTrack: Boolean(roomId && schema?.id),
  });

  const onApplyFilter = useCallback(
    (filter: CompositeCondition) => {
      if (!schema) {
        return;
      }
      dispatch(
        editSchemaView({
          workspace: filterWorkspace,
          schemaId: schema.id,
          schemaViewId,
          schemaView: {
            filters: filter,
          },
        }),
      );
      setFilterOverlay(false);
    },
    [dispatch, filterWorkspace, schema, schemaViewId],
  );

  const exportFilters = useMemo<CompositeCondition | undefined>(
    () =>
      roomId
        ? addTopConditionToCondition(schemaView?.filters, {
            type: "metadata",
            predicate: "isInRoom",
            value: { columnType: KColumnType.join, value: roomId },
          })
        : schemaView?.filters,
    [schemaView, roomId],
  );

  const { schemaViewActionSheet, schemaViewActionsPopupElements } =
    useSchemaViewActionSheet(filterWorkspace, schema.id, schemaView);

  const { open: openExportPopup, element: exportPopupElement } =
    ExportPopup.use({
      schema,
      filtersList: exportFilters,
      numberOfRecords: filteredFolders.length,
    });

  const rightHeaderButtons = useMemo(
    () => (
      <>
        <SaveSchemaViewButton
          workspace={filterWorkspace}
          schemaView={schemaView}
          roomId={roomId}
          schemaId={schema.id}
        />
        <Button
          id="database-export-button"
          accessibilityLabel={t("excelExport")}
          text={t("excelExport")}
          onPress={openExportPopup}
          ref={exportButtonRef}
          variant="PRIMARY"
        />
        {schemaViewActionSheet}
      </>
    ),
    [
      filterWorkspace,
      openExportPopup,
      roomId,
      schema.id,
      schemaView,
      schemaViewActionSheet,
      t,
    ],
  );

  const schemaColumns = KSchemaUtils.flattenColumnsWithPath(schema.rootSection);

  const pageName = schemaView.id ? schemaView.name : schema.name;
  const pageSpecification = schemaView.id ? schema.name : undefined;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.headerContainer}>
          <PageHeader
            title={pageName}
            titleSpecification={pageSpecification}
            right={rightHeaderButtons}
          />

          <div className={classes.databaseSubHeader}>
            <VisualisationSelector containerId="database-format" />
            <div className={classes.filterButton}>
              <Button
                id="database-filter-button"
                variant="TERTIARY"
                size="SMALL"
                icon="filter-funnel-03"
                text={buttonText}
                accessibilityLabel={t("filter")}
                condensed
                selected={nonCompositeConditions > 0}
                onPress={() => setFilterOverlay(true)}
                ref={filterButtonRef}
              />
            </div>
          </div>
        </div>

        <div className={classes.viewerContainer}>
          <ModularFoldersFormatViewer
            format={format}
            schema={schema}
            roomId={roomId}
            schemaViewId={schemaViewId}
          />
        </div>
      </div>

      <OverlayMenu
        visible={filterOverlay}
        anchorRef={filterButtonRef}
        onClose={() => setFilterOverlay(false)}
      >
        <FilterMenu
          disabledColumnTypes={
            roomId ? [KColumnType.roomMembers, KColumnType.roomName] : []
          }
          onApplyFilter={onApplyFilter}
          columns={schemaColumns}
          filtersList={schemaView?.filters}
          showIncludeArchivedRoomsShortcut
        />
      </OverlayMenu>

      {schemaViewActionsPopupElements}
      {exportPopupElement}

      <ModularFolderCarousel />
    </>
  );
};

export { ConnectedModularFoldersViewer };
