import { getMarkerTintColor } from "@kraaft/shared/components/mapController/markers/marker/marker.utils";
import { Color, ColorStyle, Icon, IconName } from "@kraaft/ui";

import { styles } from "./simpleMarkerContent.styles";

interface SimpleMarkerContentProps {
  isHovered: boolean | undefined;
  isSelected: boolean | undefined;
  icon: IconName;
  tintColor?: React.CSSProperties["color"];
  defaultColor?: React.CSSProperties["color"];
}

const SimpleMarkerContent = ({
  isHovered,
  isSelected,
  icon,
  tintColor = Color.WHITE,
  defaultColor = ColorStyle.FONT_LOW_EMPHASIS,
}: SimpleMarkerContentProps) => (
  <Icon
    name={icon}
    color={getMarkerTintColor(
      { isHovered, isSelected },
      tintColor,
      defaultColor,
    )}
    style={styles.icon}
    size="MEDIUM"
  />
);

export { SimpleMarkerContent };
