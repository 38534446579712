import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, StyleSheet, View } from "react-native";

import {
  MODULAR_FOLDERS_SELECTION_PORTAL,
  useModularFoldersSelection,
} from "@kraaft/shared/components/modularFolders/modularFoldersSelection.context";
import { useReportTemplateSelection } from "@kraaft/shared/components/reportTemplate/useReportTemplateSelection";
import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { DownloadReportFormat } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.utils";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button, Spacing } from "@kraaft/ui";

import { ModularFolderBulkExportSheet } from "./modularFolderBulkExportSheet";

interface Props {
  roomId: string;
  schemaId: string;
}

export const ModularFolderListSelectionFooter = ({
  roomId,
  schemaId,
}: Props) => {
  const anchorRef = useRef(null);
  const selectionCount = useModularFoldersSelection().size;

  const [templateParams, setTemplateParams] = useState<
    | {
        reportTemplate: ReportTemplate;
        format: DownloadReportFormat;
      }
    | undefined
  >();
  const [isSheetOpen, openSheet, closeSheet] = useBooleanState();

  const { ReportTemplateSelection, openReportTemplateSelection } =
    useReportTemplateSelection({
      schemaId,
      callback: async (reportTemplate, format) => {
        setTemplateParams({ reportTemplate, format });
        openSheet();
      },
      anchorRef,
      allowPDF: true,
      withAnchorWidth: true,
    });

  const { t } = useTranslation();

  return (
    <View style={styles.wrapper}>
      <Button
        text={t("modularFolderBulkExport.downloadButton", {
          count: selectionCount,
        })}
        onPress={openReportTemplateSelection}
        ref={anchorRef}
        disabled={selectionCount === 0}
      />
      {ReportTemplateSelection}
      {templateParams && (
        <ModularFolderBulkExportSheet
          roomId={roomId}
          schemaId={schemaId}
          {...templateParams}
          open={isSheetOpen}
          onClose={closeSheet}
          size="SMALL"
          portalHostname={MODULAR_FOLDERS_SELECTION_PORTAL}
        />
      )}
      <SafeAreaView />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    padding: Spacing.S16,
  },
});
