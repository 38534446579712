import { PropsWithChildren, useCallback, useRef, useState } from "react";

import { createMeshContext, useMeshContextSetup } from "@kraaft/helper-hooks";
import { globalPermissionRequester } from "@kraaft/shared/components/permissionRequester/globalPermissionRequester";
import {
  PermissionRequest,
  PermissionRequestFunction,
} from "@kraaft/shared/components/permissionRequester/permissionRequester.types";
import { PermissionRequesterSheetProvider } from "@kraaft/shared/components/permissionRequester/permissionRequesterSheetProvider";
import { PermissionResult } from "@kraaft/shared/core/services/permission/permissions.types";

function dequeueRequestedType(requestedTypeQueue: Array<PermissionRequest>) {
  const nextRequestedType = requestedTypeQueue.shift();
  if (nextRequestedType === undefined) {
    return undefined;
  }
  return nextRequestedType;
}

interface PermissionRequesterContextValue {
  request: PermissionRequestFunction;

  currentPermissionRequest: PermissionRequest | undefined;
  clearCurrentPermissionRequest: () => void;
}

export const PermissionRequesterContext =
  createMeshContext<PermissionRequesterContextValue>();

export const PermissionRequesterContextProvider = ({
  children,
}: PropsWithChildren) => {
  const requestedTypeQueue = useRef<Array<PermissionRequest>>([]);
  const [currentPermissionRequest, setCurrentPermissionRequest] =
    useState<PermissionRequest>();

  const request = useCallback<PermissionRequestFunction>(
    (type, trackingFrom) =>
      new Promise<PermissionResult>((resolve) => {
        requestedTypeQueue.current.push({
          type,
          trackingFrom,
          promiseResolver: resolve,
        });
        setCurrentPermissionRequest((currentRequestedType) => {
          if (currentRequestedType !== undefined) {
            return currentRequestedType;
          }
          return dequeueRequestedType(requestedTypeQueue.current);
        });
      }),
    [],
  );

  const clearCurrentPermissionRequest = useCallback(() => {
    setCurrentPermissionRequest(() =>
      dequeueRequestedType(requestedTypeQueue.current),
    );
  }, []);

  const contextValue = useMeshContextSetup<PermissionRequesterContextValue>({
    request,
    currentPermissionRequest,
    clearCurrentPermissionRequest,
  });

  // defining this here ensures that it can be used within sagas only if the context is mounted
  globalPermissionRequester.request = request;

  return (
    <PermissionRequesterContext.Provider value={contextValue}>
      {children}
      <PermissionRequesterSheetProvider />
    </PermissionRequesterContext.Provider>
  );
};
