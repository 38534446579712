import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, View } from "react-native";

import { useHover } from "@kraaft/helper-hooks";
import {
  getIconColor,
  getIconName,
} from "@kraaft/shared/components/checkbox/checkbox.utils";
import { Icon, IconName, IconSize } from "@kraaft/ui";

import { styles } from "./checkbox.styles";

export type SelectionState = "all" | "some" | "none";

interface CheckboxProps {
  onPress: (newStatus: boolean) => void;
  checked?: boolean;
  semiChecked?: boolean;
  size?: number;
  greyedNotSelected?: boolean;
  showWhiteBackground?: boolean;
  outline?: boolean;
  whiteOnHover?: boolean;
  disabled?: boolean;
}

const Checkbox = ({
  size,
  onPress,
  checked,
  semiChecked,
  greyedNotSelected,
  showWhiteBackground,
  outline,
  whiteOnHover,
  disabled,
}: CheckboxProps) => {
  const ref = useRef<View>(null);

  const { t } = useTranslation();

  const handlePress = useCallback(() => onPress(!checked), [checked, onPress]);

  const icon: IconName = getIconName(!!checked, !!semiChecked);

  const endSize = size ?? IconSize.MEDIUM;
  const backgroundSize = outline ? endSize - 8 : showWhiteBackground ? 8 : 0;

  const hovered = useHover(ref);

  const backgroundStyles = useMemo(
    () => [
      styles.checkBackground,
      {
        bottom: endSize / 2 - backgroundSize / 2,
        left: endSize / 2 - backgroundSize / 2,
        width: backgroundSize,
        height: backgroundSize,
      },
    ],
    [backgroundSize, endSize],
  );

  const iconColor = getIconColor({
    hovered: hovered,
    whiteOnHover: !!whiteOnHover,
    greyedNotSelected: !!greyedNotSelected,
    checked: !!checked,
    disabled: !!disabled,
    semiChecked: !!semiChecked,
  });

  const accessibilityLabel = checked ? t("unselect") : t("select");

  return (
    <Pressable
      accessibilityLabel={accessibilityLabel}
      onPress={handlePress}
      disabled={disabled}
    >
      <View ref={ref}>
        {checked && showWhiteBackground && <View style={backgroundStyles} />}
        <Icon name={icon} color={iconColor} size={endSize} />
      </View>
    </Pressable>
  );
};

export { Checkbox };
