import { createAction } from "@reduxjs/toolkit";

import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";

export const addFilesSenderBucket = createAction<{
  id: string;
  files: ModernFile<LocalPath>[];
  answerTo?: string | undefined;
}>("ADD_FILES_SENDER_BUCKET");

export const editFileSenderBucket = createAction<{
  id: string;
  file: ModernFile<LocalPath>;
}>("EDIT_FILE_SENDER_BUCKET");

interface RemoveFilesSenderBucketPayload {
  id: string;
  emptyAll?: boolean;
  index?: number;
}

export const removeFilesSenderBucket =
  createAction<RemoveFilesSenderBucketPayload>("REMOVE_FILES_SENDER_BUCKET");
