import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import keyBy from "lodash/keyBy";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { openPreview } from "@kraaft/shared/core/modules/preview/previewActions";
import { useOnRoomCellupdate } from "@kraaft/shared/core/modules/room/roomRecordUtils";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { useRoomModularColumnContext } from "@kraaft/shared/core/modules/schema/modularTypes/useRoomModularColumnContext";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { selectSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { EditableSchemaTable } from "@kraaft/web/src/components/modularFoldersTable/editableSchemaTable";
import { TableColumnsContext } from "@kraaft/web/src/components/modularTable/tableContext";

interface ConversationTableProps {
  schemaId: string;
  rooms: Room[];
  isEditMode?: boolean;
}

const ConversationTableView = ({
  schemaId,
  rooms,
  isEditMode,
}: ConversationTableProps) => {
  const dispatch = useDispatch();
  const poolId = useSelector(selectCurrentPoolId);

  const defaultColumnsContext = useRoomModularColumnContext(schemaId);

  const columnContext = useMemo<TableColumnsContext>(
    () => ({
      ...defaultColumnsContext.columns,
      [KSchemaRemarkableColumns.ROOM_TITLE]: {
        ...defaultColumnsContext.columns[KSchemaRemarkableColumns.ROOM_TITLE],
        onExpand: (row) => {
          dispatch(
            openPreview({
              type: "room",
              roomId: row.id,
            }),
          );
        },
      },
    }),
    [defaultColumnsContext, dispatch],
  );

  const roomDict = useMemo(() => keyBy(rooms, (room) => room.id), [rooms]);

  const onCellUpdate = useOnRoomCellupdate(poolId, roomDict);
  const roomsRecord = useMemo(() => rooms.map((r) => r.record), [rooms]);

  const schema = useSelector(selectSchema(schemaId));

  if (!schema) {
    return null;
  }

  return (
    <EditableSchemaTable
      schema={schema}
      rows={roomsRecord}
      onCellUpdate={onCellUpdate}
      enablePropertyEdit={isEditMode}
      disableRecordEdit={isEditMode}
      disablePreview={isEditMode}
      columnContext={columnContext}
    />
  );
};

export { ConversationTableView };
