import { createAction } from "@reduxjs/toolkit";

import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import {
  PoolLocation,
  UserUnreadPools,
} from "@kraaft/shared/core/modules/pool/poolState";

export const startOnPool = createAction<{ poolId: string | undefined }>(
  "@pool/START_ON_POOL",
);

export const updateLocations = createAction<PoolLocation[]>(
  "@pool/UPDATE_LOCATIONS",
);

export const setPoolByName = createAction<{ poolName: string }>(
  "@pool/SET_POOL_BY_NAME",
);

export const setPoolById = createAction<{ poolId: string }>(
  "@pool/SET_POOL_BY_Id",
);

export const subscribeToPool = createAction<{ poolId: string }>(
  "@stopppingPoint/SUBSCRIBE_TO_POOL",
);

export const unsubscribeFromPool = createAction<{ poolId: string }>(
  "@stopppingPoint/UNSUBSCRIBE_FROM_POOL",
);

export const uploadLogo = createAction<{
  poolId: string | undefined;
  file: ModernFile<LocalPath> | undefined;
}>("@pool/UPLOAD_LOGO");

export const updateUserUnreadPools = createAction<UserUnreadPools>(
  "@pool/UPDATE_USER_UNREAD_POOLS",
);

interface SetLogoPayload {
  poolId: string;
  logo: string | undefined;
}

export const setLogo = createAction<SetLogoPayload>("@pool/SET_LOGO");

export const PoolActions = {
  switchPool: createAction<PoolLocation>("@pool/SWITCH_POOL"),
  receivePools: createAction<{ pools: Pool[] }>("@pool/RECEIVE_POOLS"),
  freemiumStatusUpdated: createAction<{
    isFreemium: boolean;
  }>("@pool/FREEMIUM_STATUS_UPDATED"),
};

export const PoolStateActions = {
  setPoolLocation: createAction<PoolLocation>("@pool/SET_POOL_LOCATION"),
  setPools: createAction<Pool[]>("@pool/SET_POOLS"),
};
