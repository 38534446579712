import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";
import { size } from "lodash/fp";
import { Dictionary } from "ts-essentials";

import { compactMap } from "@kraaft/helper-functions";
import { selectLoading } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { selectPools } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { PERSIST_ROOM_LOADING_ID } from "@kraaft/shared/core/modules/room/roomActions";
import {
  Room,
  RoomMember,
  RoomType,
} from "@kraaft/shared/core/modules/room/roomState";
import {
  computeRoomSubscriptionFilterId,
  RoomFilterIdParams,
} from "@kraaft/shared/core/modules/room/selectors/utils";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { RootState } from "@kraaft/shared/core/store";
import {
  getEmptyArray,
  lodashKeyResolver,
} from "@kraaft/shared/core/utils/utils";

import { roomAdapter, roomUserHistoryAdapter } from "../data/roomDataReducers";

export const selectAllRooms = ({ room }: RootState) =>
  roomAdapter
    .getSelectors()
    .selectEntities(room.data.roomEntities) as Dictionary<Room>;

export const selectRoom = memoize(
  (roomId: string | undefined) =>
    ({ room }: RootState) =>
      roomId !== undefined
        ? roomAdapter.getSelectors().selectById(room.data.roomEntities, roomId)
        : undefined,
);

export const selectRoomPages = ({ room }: RootState) => room.data.pages;

export const selectRoomLoadedData = memoize((payload: RoomFilterIdParams) => {
  const filterId = computeRoomSubscriptionFilterId(payload);

  return createSelector(selectRoomPages, (loadedData) => {
    return loadedData[filterId];
  });
}, lodashKeyResolver);

const EMPTY_ARRAY: Room[] = [];
const defaultSelectRoomsByPool = () => EMPTY_ARRAY;
export const selectRoomsByPool = memoize((poolId: string | undefined) => {
  if (!poolId) {
    return defaultSelectRoomsByPool;
  }
  return createSelector(
    selectAllRooms,
    selectRoomLoadedData({ poolId }),
    (rooms, loadedData) => {
      return loadedData
        ? compactMap(loadedData.results.ids, (id) => rooms[id])
        : EMPTY_ARRAY;
    },
  );
});

export const selectPoolByRoomId = memoize((roomId: string) =>
  createSelector(selectPools, selectRoomPoolId(roomId), (pools, poolId) =>
    poolId ? pools[poolId] : undefined,
  ),
);
export const selectAllRoomIds = createSelector(selectAllRooms, (rooms) =>
  Object.keys(rooms),
);

export const selectEveryoneRoomId = memoize((poolId: string) =>
  createSelector(
    selectAllRooms,
    (rooms) =>
      Object.values(rooms).find(
        (room) => room.poolId === poolId && room.type === RoomType.EVERYONE,
      )?.id,
  ),
);

export function selectRoomUserHistory(roomId: string) {
  return ({ room }: RootState) =>
    roomUserHistoryAdapter
      .getSelectors()
      .selectById(room.data.roomUserHistoryEntities, roomId);
}

const emptyList: RoomMember[] = [];

export function selectRoomMembersAndReadStatus(roomId: string) {
  return ({ room }: RootState): RoomMember[] =>
    room.ui.roomMemberLists[roomId] || emptyList;
}

export const selectRoomMembers = memoize(
  (roomId: string) =>
    ({ room }: RootState) =>
      room.ui.roomMemberLists[roomId] ?? getEmptyArray(),
);

export const selectRoomMember = memoize(
  (roomId: string, userId: string) =>
    createSelector(selectRoomMembers(roomId), (userRooms) =>
      userRooms.find((userRoom) => userRoom.userId === userId),
    ),
  lodashKeyResolver,
);

export const selectRoomMembersCount = memoize((roomId: string) =>
  createSelector(selectRoom(roomId), (room) => size(room?.members)),
);

export const selectRoomMemberIds = memoize((roomId: string) =>
  createSelector(selectRoom(roomId), (room) =>
    Object.keys(room?.members ?? {}),
  ),
);

export function selectIsRoomPrivate(roomId: string) {
  return (state: RootState): boolean =>
    selectRoom(roomId)(state)?.visibility === "private";
}

export const selectRoomPoolId = memoize((roomId: string | undefined) =>
  createSelector(selectRoom(roomId), (room) => room?.poolId),
);

export const selectIsCreatingRoom = selectLoading(PERSIST_ROOM_LOADING_ID);

export const selectGeolocationContext = ({ room }: RootState) =>
  room.ui.geolocation;

export const selectGeolocationHover = ({ room }: RootState) =>
  room.ui.geolocation.hover;

export const selectGeolocationIsHovered = memoize((id: string) =>
  createSelector(selectGeolocationHover, (hover) => hover?.id === id),
);

export const selectRoomTitle = memoize((roomId: string) =>
  createSelector(selectRoom(roomId), (room) =>
    room
      ? ModularRecordUtils.getPropertyField(
          room.record.properties,
          KSchemaRemarkableColumns.TITLE,
          [KColumnType.shortText, KColumnType.automatedAutoIncrement],
        )
      : undefined,
  ),
);
