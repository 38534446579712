import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { KInputWithUpdateOnBlur } from "@kraaft/shared/components/kInputWithUpdateOnBlur";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import {
  LibrarySchemaOptimisticActions,
  LibrarySchemaOptimisticOperations,
} from "@kraaft/shared/core/modules/librarySchema/librarySchema.optimistic";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Button, Icon, Text } from "@kraaft/ui";
import { EditLibrarySchemaCompany } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder/editLibrarySchemaCompany";
import { Tooltip } from "@kraaft/web/src/components/tooltip/tooltip";
import { useFileInput } from "@kraaft/web/src/core/utils/useFileInput";

import { styles, useStyles } from "./editLibraryPresentation.styles";

const VIDEO_URL_PLACEHOLDER = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";

interface EditLibraryPresentationProps {
  librarySchema: LibrarySchema;
}

const EditLibraryPresentation = (props: EditLibraryPresentationProps) => {
  const { librarySchema } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleAddCoverPictureFile = useCallback(
    ([file]: ModernFile<LocalPath>[]) => {
      if (file === undefined) {
        return;
      }
      console.log("ADDING", file);
      dispatch(
        LibrarySchemaOptimisticActions.addOperation(
          LibrarySchemaOptimisticOperations.editPresentationCoverPicture.create(
            {
              targetId: librarySchema.id,
              coverPictureFile: file,
            },
          ),
        ),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleRemoveCoverPictureFile = useCallback(() => {
    dispatch(
      LibrarySchemaOptimisticActions.addOperation(
        LibrarySchemaOptimisticOperations.editPresentationCoverPicture.create({
          targetId: librarySchema.id,
          coverPictureFile: null,
        }),
      ),
    );
  }, [dispatch, librarySchema.id]);

  const handleAddSampleReportFile = useCallback(
    ([file]: ModernFile<LocalPath>[]) => {
      if (file === undefined) {
        return;
      }
      dispatch(
        LibrarySchemaOptimisticActions.addOperation(
          LibrarySchemaOptimisticOperations.editPresentationSampleReport.create(
            {
              targetId: librarySchema.id,
              sampleReportFile: file,
            },
          ),
        ),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleRemoveSampleReportFile = useCallback(() => {
    dispatch(
      LibrarySchemaOptimisticActions.addOperation(
        LibrarySchemaOptimisticOperations.editPresentationSampleReport.create({
          targetId: librarySchema.id,
          sampleReportFile: null,
        }),
      ),
    );
  }, [dispatch, librarySchema.id]);

  const {
    getInputProps: getCoverPictureFileInputProps,
    open: openCoverPictureFilePicker,
  } = useFileInput({
    onAccepted: handleAddCoverPictureFile,
    accept: "image/*",
  });

  const {
    getInputProps: getSampleReportFileInputProps,
    open: openSampleReportFilePicker,
  } = useFileInput({
    onAccepted: handleAddSampleReportFile,
    accept: [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
  });

  const handleChangeDescription = useCallback(
    (newDescription: string) => {
      dispatch(
        LibrarySchemaOptimisticActions.addOperation(
          LibrarySchemaOptimisticOperations.editPresentationDescription.create({
            targetId: librarySchema.id,
            description: newDescription,
          }),
        ),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleChangeVideoUrl = useCallback(
    (newVideoUrl: string) => {
      dispatch(
        LibrarySchemaOptimisticActions.addOperation(
          LibrarySchemaOptimisticOperations.editPresentationVideo.create({
            targetId: librarySchema.id,
            videoUrl: newVideoUrl || undefined,
          }),
        ),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleChangeCompany = useCallback(
    (companyId: string | undefined) => {
      dispatch(
        LibrarySchemaOptimisticActions.addOperation(
          LibrarySchemaOptimisticOperations.setCompany.create({
            targetId: librarySchema.id,
            companyId,
          }),
        ),
      );
    },
    [dispatch, librarySchema.id],
  );

  const {
    presentation: { coverPictureUrl, sampleReport },
  } = librarySchema;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.section}>
          <div className={classes.group}>
            <EditLibrarySchemaCompany
              onChangeCompany={handleChangeCompany}
              companyId={librarySchema.companyId}
            />
          </div>
          <div className={classes.group}>
            <Text size="H2" weight="bold" style={styles.textSpacer}>
              {t("formBuilder.presentation.label")}
            </Text>
            <KInputWithUpdateOnBlur
              multiline
              value={librarySchema.presentation.description}
              onChangeText={handleChangeDescription}
              showEditIcon
              label={t("formBuilder.presentation.descriptionInputLabel")}
              inputContainerStyle={styles.multilineTextInput}
            />
          </div>
          <div className={clsx(classes.group, classes.leftAlignedContainer)}>
            <div className={classes.coverPictureLabelContainer}>
              <Text size="H2" weight="bold">
                {t("formBuilder.presentation.coverPictureLabel")}
              </Text>
              <Tooltip title="500x200" placement="right">
                <div>
                  <Icon name="info-circle" size="SMALL" />
                </div>
              </Tooltip>
            </div>
            {coverPictureUrl ? (
              <img
                className={classes.coverImage}
                src={coverPictureUrl}
                alt="cover"
              />
            ) : null}
            <div className={classes.coverPictureActionsContainer}>
              <input {...getCoverPictureFileInputProps()} />
              <Button
                variant="SECONDARY"
                icon="upload-cloud-01"
                accessibilityLabel={t(
                  coverPictureUrl
                    ? "formBuilder.presentation.coverPhotoReplace"
                    : "formBuilder.presentation.coverPhotoAdd",
                )}
                text={t(
                  coverPictureUrl
                    ? "formBuilder.presentation.coverPhotoReplace"
                    : "formBuilder.presentation.coverPhotoAdd",
                )}
                onPress={openCoverPictureFilePicker}
              />
              {coverPictureUrl ? (
                <Button
                  accessibilityLabel={t("remove")}
                  variant="TERTIARY"
                  icon="trash-03"
                  onPress={handleRemoveCoverPictureFile}
                />
              ) : null}
            </div>
          </div>
          <div className={clsx(classes.group, classes.leftAlignedContainer)}>
            <Text size="H2" weight="bold" style={styles.textSpacer}>
              {t("formBuilder.presentation.sampleReportLabel")}
            </Text>
            {sampleReport ? (
              <Text size="BODY" style={styles.textSpacer}>
                {sampleReport.filename}
              </Text>
            ) : null}
            <div className={classes.coverPictureActionsContainer}>
              <input {...getSampleReportFileInputProps()} />
              <Button
                variant="SECONDARY"
                icon="upload-cloud-01"
                accessibilityLabel={t(
                  sampleReport
                    ? "formBuilder.presentation.sampleReportReplace"
                    : "formBuilder.presentation.sampleReportAdd",
                )}
                text={t(
                  sampleReport
                    ? "formBuilder.presentation.sampleReportReplace"
                    : "formBuilder.presentation.sampleReportAdd",
                )}
                onPress={openSampleReportFilePicker}
              />
              {sampleReport ? (
                <Button
                  accessibilityLabel={t("remove")}
                  text={t("remove")}
                  variant="TERTIARY"
                  icon="trash-03"
                  onPress={handleRemoveSampleReportFile}
                />
              ) : null}
            </div>
          </div>
          <div className={classes.group}>
            <Text size="H2" weight="bold" style={styles.textSpacer}>
              {t("formBuilder.presentation.videoLinkLabel")}
            </Text>
            <KInputWithUpdateOnBlur
              value={librarySchema.presentation.video?.url ?? ""}
              onChangeText={handleChangeVideoUrl}
              showEditIcon
              label={t("formBuilder.presentation.videoLinkInputLabel")}
              placeholder={VIDEO_URL_PLACEHOLDER}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { EditLibraryPresentation };
