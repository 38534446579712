import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";

import { KraaftLogo } from "@kraaft/shared/components/kraaftLogo";
import { AddMembersAtPoolCreation } from "@kraaft/shared/components/onboarding/addMembersAtPoolCreation";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";

const AddMembersAtPoolCreationWeb = () => {
  const poolId = useSelector(selectCurrentPoolId);

  const isSmallScreen = useMediaQuery("(max-height:720px)");

  if (!poolId) {
    return null;
  }

  return (
    <SplitScreenWrapper headerLeft={<KraaftLogo />} fullHeight>
      <AddMembersAtPoolCreation
        poolId={poolId}
        hideAvatarList={isSmallScreen}
      />
    </SplitScreenWrapper>
  );
};

export { AddMembersAtPoolCreationWeb };
