import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import {
  MyProfileDetails,
  MyRole,
} from "@kraaft/shared/components/myProfileDetails";
import { intercom } from "@kraaft/shared/core/modules/intercom/intercom.provider";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Button, ColorStyle, Icon, Radius, Spacing, Text } from "@kraaft/ui";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";

import { useSettingsStyles } from "../settings.styles";

export const MyProfile = () => {
  const settingsClasses = useSettingsStyles();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleContactSupport = useCallback(() => {
    trackEvent({
      eventName: "Click On Contact Support Button",
      from_page: "profile page",
    });
    intercom.openChat();
  }, []);

  return (
    <div className={settingsClasses.pageContainer}>
      <PageHeader
        titleElementId="settings-profile-title"
        title={t("menuMyProfile")}
      />
      <div className={classes.myRole}>
        <MyRole />
      </div>
      <div className={classes.detailsContainer}>
        <div className={classes.fakeInputsContainer}>
          <MyProfileDetails />
        </div>
        <div className={classes.contactSupportContainer}>
          <Icon name="user-edit" size="XLARGE" />
          <Text size="BODY" weight="medium">
            {t("contactSupportProfileEdition.wannaEditYourProfile")}
          </Text>
          <Text size="MEDIUM">
            <Trans
              i18nKey="contactSupportProfileEdition.contactSupport"
              components={{
                blue: <Text color="BLUE_KRAAFT" />,
              }}
            />
          </Text>
          <Button
            onPress={handleContactSupport}
            text={t("menuContactSupport")}
            accessibilityLabel={t("menuContactSupport")}
            variant="SECONDARY"
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  myRole: {
    marginBottom: Spacing.S16,
  },
  detailsContainer: {
    display: "flex",
    width: "100%",
    gap: Spacing.S24,
    flexWrap: "wrap",
  },
  contactSupportContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 305,
    flexGrow: 4,
    flexBasis: 0,
    background: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: Radius.SMALL,
    padding: Spacing.S16,
    gap: Spacing.S16,
    height: "fit-content",
  },
  fakeInputsContainer: {
    flexGrow: 6,
  },
});
