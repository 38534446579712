import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    maxWidth: 240,
  },
  title: {
    textAlign: "center",
  },
  subtitle: {
    textAlign: "center",
    marginTop: Spacing.S8,
  },
});

export { styles };
