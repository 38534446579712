import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { difference } from "lodash";
import { Dispatch } from "redux";
import { Dictionary } from "ts-essentials";

import { ModularTableValueUpdate } from "@kraaft/shared/components/modular/details/utils";
import { isAtLeastPoolStandard } from "@kraaft/shared/core/modules/pool/poolUtil";
import {
  changeRoomUsersRequest,
  updateRoomRecord,
} from "@kraaft/shared/core/modules/room/roomActions";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumnLiteralValue,
  KSchemaColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { selectInternalPoolUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export function useOnRoomCellupdate(
  poolId: string | undefined,
  roomDict: Dictionary<Room>,
) {
  const dispatch = useDispatch();
  const poolUsers = useSelector(selectInternalPoolUsers(poolId));

  return useCallback<(updates: ModularTableValueUpdate[]) => void>(
    (updates) => {
      for (const update of updates) {
        const room = roomDict[update.id];
        if (room) {
          trackEvent({
            eventName: "Edit Conversation Property",
            is_shared: hasRoomExternalMembers(room, poolUsers),
            room_id: room.id,
            property_name: update.column.name,
            property_type: update.column.type,
          });
        }
        updateRecordFn(update, roomDict[update.id], dispatch);
      }
    },
    [roomDict, dispatch, poolUsers],
  );
}

function updateRecordFn(
  update: ModularTableValueUpdate,
  room: Room | undefined,
  dispatch: Dispatch,
) {
  const { id, column, value } = update;
  const { key } = column;
  if (column.type === KColumnType.roomMembers) {
    if (!room) {
      return;
    }

    const targetUserIds =
      (value as KSchemaColumnLiteralValue<KColumnType.roomMembers>) || [];
    const originUserIds = Object.keys(room.members);

    const removedUserIds = difference(originUserIds, targetUserIds);
    const addedUserIds = difference(targetUserIds, originUserIds);

    dispatch(
      changeRoomUsersRequest({ roomId: id, addedUserIds, removedUserIds }),
    );
  } else {
    dispatch(
      updateRoomRecord({
        roomId: id,
        update: {
          [key]: {
            columnType: column.type,
            value: value,
          } as KSchemaColumnValue,
        },
      }),
    );
  }
}

export function hasRoomExternalMembers(
  room: Room | undefined,
  users: Dictionary<User, string>,
) {
  const members = room?.members ?? {};
  return Object.keys(members).some((memberId) => {
    const user = users[memberId];
    const userRole = user?.pools[room?.poolId ?? ""]?.role;
    return userRole !== undefined && !isAtLeastPoolStandard(userRole);
  });
}
