import { useCallback, useMemo } from "react";

import { DIRECTORY_MAX_DEPTH } from "@kraaft/shared/core/modules/directory/directory";
import { DirectoryDropper } from "@kraaft/web/src/components/directoryTree/directoryDropper";
import {
  DraggableDirectoryTreeItem,
  HoveredBetweenDirectoryInfoPlacement,
  HoveredDirectoryTreeInfo,
} from "@kraaft/web/src/components/directoryTree/directoryTree.types";
import { useDirectoryTreeRowDrop } from "@kraaft/web/src/components/directoryTree/useDirectoryTreeRowDrop";

interface BetweenDirectoryDropperProps {
  level: number;
  parentDirectoryId: string | undefined;
  siblingDirectoryId: string;
  placement: HoveredBetweenDirectoryInfoPlacement;
  followingDirectoryId?: string;
  isGroupBeingDragged?: boolean;
}

const BetweenDirectoryDropper = (props: BetweenDirectoryDropperProps) => {
  const {
    level,
    parentDirectoryId,
    siblingDirectoryId,
    placement,
    followingDirectoryId,
    isGroupBeingDragged,
  } = props;

  const checkItemCanDrop = useCallback(
    (item: DraggableDirectoryTreeItem) =>
      !isGroupBeingDragged &&
      item.directory.id !== siblingDirectoryId &&
      item.directory.id !== followingDirectoryId &&
      level + item.directory.deepestChildDepth <= DIRECTORY_MAX_DEPTH + 1,
    [isGroupBeingDragged, siblingDirectoryId, followingDirectoryId, level],
  );

  const hoveredInfo = useMemo<HoveredDirectoryTreeInfo>(
    () => ({
      type: "between",
      parentDirectoryId: parentDirectoryId,
      siblingDirectoryId,
      placement,
    }),
    [parentDirectoryId, placement, siblingDirectoryId],
  );

  const [{ isOver, canDrop }, drop] = useDirectoryTreeRowDrop({
    info: hoveredInfo,
    checkItemCanDrop,
  });

  return (
    <DirectoryDropper
      dropRef={drop}
      level={level}
      isVisible={canDrop}
      isActive={isOver}
    />
  );
};

export { BetweenDirectoryDropper };
