export enum KColumnType {
  join = "join",

  // same as ColumnType
  attachment = "attachment",
  checkbox = "checkbox",
  currency = "currency",
  date = "date",
  geolocation = "geolocation",
  longText = "longText",
  number = "number",
  shortText = "shortText",
  user = "user",
  signature = "signature",

  // only front
  roomMembers = "roomMembers",
  roomName = "roomName",
  selectMultiple = "selectMultiple",
  selectSingle = "selectSingle",
  automatedCreatedBy = "automatedCreatedBy",
  automatedCreatedAt = "automatedCreatedAt",
  automatedAutoIncrement = "automatedAutoIncrement",
}
