import { createSelector } from "@reduxjs/toolkit";
import { memoize, orderBy, pickBy } from "lodash";

import { ModularFolderHistoryEntry } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.state";
import {
  SchemaColumnWithPath,
  SchemaElementWithPath,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { RootState } from "@kraaft/shared/core/store";
import {
  getEmptyArray,
  lodashKeyResolver,
} from "@kraaft/shared/core/utils/utils";

function selectState({ modularFolderHistory }: RootState) {
  return modularFolderHistory;
}

export const selectOrderedModularFolderHistory = memoize(
  (modularFolderId: string) =>
    createSelector(selectState, (state) => {
      const history = state.histories[modularFolderId];
      if (history === undefined) {
        return getEmptyArray<ModularFolderHistoryEntry>();
      }
      return orderBy(history.data, (entry) => entry.at, "desc");
    }),
);

const EMPTY_SCHEMA_ELEMENT_WITH_PATHS = {} as Record<
  string,
  SchemaElementWithPath
>;
export const selectModularFolderHistoryEntrySchemaElementWithPaths = memoize(
  (modularFolderId: string) =>
    createSelector(selectState, (state) => {
      const history = state.histories[modularFolderId];

      if (history === undefined) {
        return EMPTY_SCHEMA_ELEMENT_WITH_PATHS;
      }

      return history.schemaElementWithPaths;
    }),
  lodashKeyResolver,
);

export const selectModularFolderHistoryEntrySchemaElementWithPath = memoize(
  (modularFolderId: string, propertyKey: string) =>
    createSelector(
      selectModularFolderHistoryEntrySchemaElementWithPaths(modularFolderId),
      (schemaElementWithPaths) => {
        return schemaElementWithPaths[propertyKey];
      },
    ),
  lodashKeyResolver,
);

const EMPTY_SCHEMA_COLUMN_WITH_PATHS = {} as Record<
  string,
  SchemaColumnWithPath
>;
export const selectModularFolderHistoryEntrySchemaColumnWithPaths = memoize(
  (modularFolderId: string) =>
    createSelector(selectState, (state) => {
      const history = state.histories[modularFolderId];

      if (history === undefined) {
        return EMPTY_SCHEMA_COLUMN_WITH_PATHS;
      }

      return pickBy(
        history.schemaElementWithPaths,
        (schemaElementWithPath) =>
          schemaElementWithPath.element.elementType === "column",
      ) as Record<string, SchemaColumnWithPath>;
    }),
  lodashKeyResolver,
);

export const selectModularFolderHistoryEntrySchemaColumn = memoize(
  (modularFolderId: string, propertyKey: string) =>
    createSelector(
      selectModularFolderHistoryEntrySchemaElementWithPath(
        modularFolderId,
        propertyKey,
      ),
      (schemaElementWithPath) => {
        if (schemaElementWithPath === undefined) {
          return undefined;
        }

        return schemaElementWithPath.element.elementType === "column"
          ? schemaElementWithPath.element
          : undefined;
      },
    ),
  lodashKeyResolver,
);

export const selectModularFolderHistoryEntryElementName = memoize(
  (modularFolderId: string, propertyKey: string) =>
    createSelector(
      selectModularFolderHistoryEntrySchemaElementWithPath(
        modularFolderId,
        propertyKey,
      ),
      (schemaElementWithPath) => {
        if (schemaElementWithPath === undefined) {
          return undefined;
        }

        return KSchemaUtils.getStringPath(
          schemaElementWithPath.path,
          schemaElementWithPath.element,
          false,
        );
      },
    ),
  lodashKeyResolver,
);

export const selectModularFolderHistoryFirstLoading = memoize(
  (modularFolderId: string) =>
    createSelector(selectState, (state) => {
      const history = state.histories[modularFolderId];

      if (history === undefined) {
        return false;
      }

      return !history.hasFetched && history.isLoading;
    }),
);
