import { createSelector } from "@reduxjs/toolkit";
import compact from "lodash/compact";

import {
  selectCurrentPoolLocation,
  selectPoolState,
  selectUnreadPools,
} from "@kraaft/shared/core/modules/pool/poolSelectors";
import { PoolLocation } from "@kraaft/shared/core/modules/pool/poolState";

import { Pool } from "./pool";

export type PoolInfo = {
  location: PoolLocation;
  pool?: Pool;
  unreadCount: number;
};

export const selectUserPools = createSelector(
  selectPoolState,
  selectUnreadPools,
  ({ pools, locations }, unreadPools): PoolInfo[] => {
    const result = compact(
      (locations ?? []).map((location) => {
        const poolId = location.poolId;
        const pool = pools[poolId];
        const unreadCount = unreadPools[poolId] ?? 0;

        return (
          pool && {
            location,
            pool,
            unreadCount,
          }
        );
      }),
    );
    return result;
  },
);

export const selectHasUnreadPool = createSelector(
  selectCurrentPoolLocation,
  selectUnreadPools,
  (poolLocation, unreadPools): boolean => {
    const currentPoolId = poolLocation?.poolId;

    return Object.entries(unreadPools).some(
      ([poolId, nb]) => poolId !== currentPoolId && nb > 0,
    );
  },
);
