export type EnvironmentName = "local" | "dev" | "qa" | "staging" | "prod";

export type ErrorSeverity = "debug" | "error" | "warning" | "critical";

export type ViewParams = {
  name: string;
  key: string;
  params?: Record<string, unknown>;
};

export type ActionType =
  | "press"
  | "scroll"
  | "swipe"
  | "back"
  | "application_start"
  | "custom";

export type ActionParams<T> = {
  type?: T;
  message: string;
  context?: Record<string, unknown>;
};

// Can be an union type to be more strict
export type SpanOperation = string;

export type SpanID = string;

export type StartSpanParams = {
  operation: SpanOperation;
  context?: Record<string, unknown>;
};

export type StopSpanParams = {
  id: SpanID;
  context?: Record<string, unknown>;
};

export type InfoParams = {
  message: string;
  context?: Record<string, unknown>;
};

export type ErrorParams = {
  error: Error;
  severity?: ErrorSeverity;
  context: Record<string, unknown>;
};

export type UserParams = { id: string } | null;

export type DynamicDatadogConfig = {
  sessionSamplingRate: number;
  sessionReplaySamplingRate: number;
  resourceTracingSamplingRate: number;
};

export abstract class AbstractDatadogSDK<T = ActionType> {
  public abstract init(
    envName: EnvironmentName,
    config: DynamicDatadogConfig,
  ): Promise<void>;
  // RUM
  public abstract setUser(user: UserParams): Promise<void>;
  public abstract startView(view: ViewParams): Promise<void>;
  public abstract stopView(view: ViewParams): Promise<void>;
  public abstract addAction(action: ActionParams<T>): Promise<void>;
  public abstract addError(error: ErrorParams): Promise<void>;
  // Traces
  public abstract startSpan(span: StartSpanParams): Promise<SpanID>;
  public abstract stopSpan(span: StopSpanParams): Promise<void>;
  // Logs
  public abstract logInfo(info: InfoParams): Promise<void>;
  public abstract logError(error: ErrorParams): Promise<void>;
}
