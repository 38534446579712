import { StyleSheet } from "react-native";

import { styleObject } from "@kraaft/shared/styles/panel.styles";
import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  mapControllerWeb: {
    position: "relative",
    display: "flex",
    flex: 1,
    height: "100%",
    flexDirection: "row",
  },
  mapContainer: {
    flexGrow: 1,
    flexBasis: 0,
  },
  mapDetailsContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: 400,
    ...styleObject.borderLeft,
  },

  overlayContainer: StyleSheet.absoluteFillObject,

  headerContainer: {
    padding: Spacing.S12,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },

  loader: {
    marginTop: Spacing.S16,
  },

  footerContainer: {
    backgroundColor: Color.WHITE,
  },
});
