import { StyleSheet } from "react-native";
import includes from "lodash/includes";
import mime from "mime";
import { Dictionary } from "ts-essentials";

import { constants } from "@kraaft/shared/constants/constants";
import {
  Attachment,
  AttachmentType,
  UploadAttachmentContext,
  VideoAttachment,
} from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import { SelectOption } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/select";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { FirestoreAttachment } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { i18n } from "@kraaft/shared/core/services/i18next";
import {
  AnyUnexplained,
  GeoCoordinates,
  GeoLocation,
} from "@kraaft/shared/core/types";

export function sanitizeEmail(value: string): string {
  const valid = value.replace(/[^a-zA-Z0-9!#$%&'*+/=?^_`{|}~.\-@]/g, "");
  return valid.toLowerCase().trim();
}

export function validateEmail(value: string): boolean {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(value.toLowerCase());
}

export function onlyDigits(value: string): boolean {
  const regex = /^\d+$/;

  return regex.test(value);
}
export const nullId = "00000000000000000000";

export function compareStrings(
  a: string | undefined,
  b: string | undefined,
  caseSensitive = false,
): -1 | 0 | 1 {
  const strA = caseSensitive ? a : a?.toLowerCase();
  const strB = caseSensitive ? b : b?.toLowerCase();
  if (strA === strB) {
    return 0;
  }
  if (!strA) {
    return 1;
  }
  if (!strB) {
    return -1;
  }
  return strA < strB ? -1 : 1;
}

export function compareNumbers(a: number, b: number): number {
  return a - b;
}

/**
 * https://stackoverflow.com/a/35890816/5331998
 */

export function convertMS(ms: number | undefined = 0) {
  const centiSeconds = (ms % 1000).toString().padStart(3, "0");

  const seconds = Math.floor((ms / 1000) % 60)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((ms / (60 * 1000)) % 60)
    .toString()
    .padStart(2, "0");

  return `${minutes}:${seconds}.${centiSeconds}`;
}

/**
 * https://gist.github.com/nmsdvid/8807205
 */
export function debounce(
  callback: (...args: AnyUnexplained[]) => void,
  time: number,
  immediate = true,
) {
  let interval: ReturnType<typeof setTimeout> | undefined;
  return (...args: AnyUnexplained[]) => {
    if (immediate && !interval) {
      callback(...args);
    }
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = undefined;
      if (!immediate) {
        callback(...args);
      }
    }, time);
  };
}

/**
 * Returns formatted time string HH:MM localized
 */
export function getLocalTime(time: Date) {
  return `${time.getHours()}:${time.getMinutes()}`;
}

/**
 * Checks if an email is valid.
 * https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 * @param {string} email - The email to be validated.
 * @return {boolean} - True if the email is valid, false otherwise.
 */
export function isEmailValid(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 * https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 */
export function prepareEmail(email: string) {
  const newEmail = isEmailValid(email) ? email.toLowerCase() : email;

  return encodeURIComponent(newEmail);
}

export function errString(err: Error | string) {
  console.log("errString", err);
  return typeof err === "string" ? err : err.message;
}

/**
 * https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
 * @param {string} string
 */
export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

type PossibleDeduction = "image" | "video";

// if you add an extension here, also add it on the back-end (fileUtils.ts)
const extensionToFileType: Record<PossibleDeduction, string[]> = {
  image: ["jpg", "jpeg", "jfif", "png", "heic"],
  video: ["mp4", "mov"],
};

const typeFromExtension = Object.entries(extensionToFileType).reduce<
  Record<string, PossibleDeduction>
>((acc, [key, value]) => {
  for (const v of value) {
    acc[v] = key as PossibleDeduction;
  }
  return acc;
}, {});

export function getMessageTypeByFile(filename: string) {
  return typeFromExtension[getExtension(filename)] ?? "document";
}

/**
 * decode Firebase timestamps into Dates
 * any is allowed because this fonction is meant to work with any object
 * and typecheck of the object is done carefully within the function
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function decodeFirebaseTimestamps(obj: any): any {
  if (obj instanceof Date) {
    return obj;
  }
  if (obj?.toDate) {
    return obj.toDate();
  }
  if (Array.isArray(obj)) {
    return obj.map(decodeFirebaseTimestamps);
  }
  if (obj instanceof Object) {
    return Object.entries(obj).reduce(
      (map, [key, value]) => {
        map[key] = decodeFirebaseTimestamps(value);
        return map;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      },
      {} as { [key: string]: any },
    );
  }
  return obj;
}

export function isURL(url: string | undefined): boolean {
  return url !== undefined && /^https?:\/\//.test(url);
}

export function buildLocalPathFromBlob(blob: Blob, filename: string): string {
  const newBlob = blob.slice(0, blob.size, getMimeType(filename));

  return URL.createObjectURL(newBlob);
}

// eslint-disable-next-line complexity
export function getMimeType(filename: string): string {
  const extension = getExtension(filename);
  switch (extension) {
    case "pdf":
      return "application/pdf";
    case "jpg":
    case "jpeg":
    case "jfif":
    case "png":
      return "image/jpeg";
    case "heic":
      return "image/heic";
    case "mp4":
    case "mov":
      return "video/mp4";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    default:
      return "application/octet-stream";
  }
}

export function getAttachmentTypeFromMimeType(
  mimeType: string,
): AttachmentType {
  const [firstPart] = mimeType.split("/");
  if (!firstPart) {
    return "document";
  }
  switch (firstPart) {
    case "image":
      return "image";
    case "video":
      return "video";
    case "audio":
      return "audio";
    default:
      return "document";
  }
}

export function getFileType(filename: string): AttachmentType {
  const mimeType = mime.getType(filename);
  if (!mimeType) {
    return "document";
  }
  return getAttachmentTypeFromMimeType(mimeType);
}

export function normalizePath(path: string): string {
  const filePrefix = /^file:\/{2}/;
  const normalizedPath = path.replace(filePrefix, "");
  return normalizedPath;
}

export function stripLocalPathPrefix(path: string): string {
  return path.replace("file://", "");
}

/**
 * Extracts the basename from a path
 * Uses a regex to exclude if path contains query parameters
 */
export function getBasename(filename: string) {
  const basename = filename.match(/[^/?#]+(?=[?#].*$|$)/)?.[0];
  return basename ?? filename;
}

export function replaceUriComponents(name: string) {
  return name.replace(/%[0-9A-F]{2}/g, "_");
}

const EXTRACT_EXTENSION_REGEX = /\.(\w+)(?:[?#]|$)/i;
/**
 * Extracts the extension from a filename
 * Uses a regex to exclude if filename contains query parameters
 */
export function getExtension(filename: string) {
  const extension = filename.match(EXTRACT_EXTENSION_REGEX)?.[1];
  return extension ?? "";
}

export function replaceExtension(filename: string, extension: string) {
  return `${getFilename(filename)}.${extension}`;
}

/**
 * Extracts the filename **without** the extension from a path
 * Uses a regex to exclude if filename contains query parameters
 */
export function getFilename(path: string) {
  const basename = getBasename(path);
  const matchedExtension = basename.match(EXTRACT_EXTENSION_REGEX);
  const matchedExtensionString = matchedExtension?.[1] ?? undefined;
  const matchedExtensionIndex = matchedExtension?.index ?? undefined;

  if (
    matchedExtensionString === undefined ||
    matchedExtensionIndex === undefined
  ) {
    return basename;
  }

  return basename.slice(0, matchedExtensionIndex);
}

export function isImageFile(filename: string) {
  const extension = getExtension(filename);
  const imageExtensions = ["jpg", "jpeg", "jfif", "png", "gif", "heic"];
  return imageExtensions.includes(extension);
}

export async function readBlobOrString(data: Blob | string): Promise<string> {
  if (data instanceof Blob) {
    return await data.text();
  }
  return data;
}

// TODO: Can be replace by lodash/filter
export function objectFilter<T>(
  obj: Dictionary<T>,
  predicate: ([key, value]: [string, T]) => boolean,
) {
  return Object.fromEntries(Object.entries(obj).filter(predicate));
}

/**
 * Build a string like 'Category Name +2'
 * @param labelsId Room labels
 * @param labels All labels from store
 */
export function buildLabelsString(
  labelsId: string[],
  labels: Dictionary<SelectOption>,
) {
  let catName = "";
  const firstLabelId = labelsId[0];
  if (firstLabelId && labels) {
    const label = labels[firstLabelId];
    if (label) {
      catName = label.label;
    }
  }

  if (labelsId.length > 1) {
    return `${catName} +${labelsId.length - 1}`;
  }
  return catName;
}

/**
 * Build a string like 'Category Name +2'
 * @param selected List of selectedItems
 */
export function buildMultiSelectString(selected: string[]) {
  const result = selected[0];

  if (selected.length > 1) {
    return `${result} +${selected.length - 1}`;
  }
  return result;
}

/**
 * Attempt to copy the eliipsisMode="middle" from react-native to work on web
 */
export function truncateMiddle(text: string) {
  const tooLongChars = 17;

  if (text.length < tooLongChars) {
    return text;
  }

  const ellipsis = "...";
  const charsOnEitherSide = Math.floor(tooLongChars / 2) - ellipsis.length;

  return (
    text.slice(0, charsOnEitherSide) + ellipsis + text.slice(-charsOnEitherSide)
  );
}

export async function retry<T>(
  fn: () => Promise<T>,
  retriesLeft = 5,
  interval = 1000,
): Promise<T> {
  try {
    return await fn();
  } catch (error) {
    if (retriesLeft > 0) {
      await new Promise((resolve) => setTimeout(resolve, interval));
      return retry(fn, retriesLeft - 1, interval);
    }
    throw new Error("Max retries reached");
  }
}

/**
 * Source: https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/core/ExecutionEnvironment.js
 */
export const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export function generateContextId(context: UploadAttachmentContext) {
  switch (context.type) {
    case "share":
      return `${context.previousRoomId}-${context.roomId}`;
    case "modularFolder":
      return `${context.modularFolderId}-${context.columnKey}`;
    default:
      return context.roomId;
  }
}

export function filterAttachments<
  T extends Attachment | FirestoreAttachment,
  U extends AttachmentType,
>(list: T[] | undefined, types: readonly U[]): Extract<T, { type: U }>[] {
  if (list) {
    return list.filter((element) => includes(types, element.type)) as Extract<
      T,
      { type: U }
    >[];
  }
  return [];
}

export function getVideoMedia(attachment: VideoAttachment) {
  return attachment.converted.mp4 || attachment.original;
}

function getTranslationGroup(column: KSchemaColumn | undefined) {
  switch (column?.type) {
    case "attachment": {
      return `${column.type}_${column.mode}` as `${typeof column.type}_${typeof column.mode}`;
    }
    default:
      return "default";
  }
}

type TranslationGroups = ReturnType<typeof getTranslationGroup>;
type ModularTranslationKeys =
  | "uploadError"
  | "dropSuccess"
  | "dropSuccessWithFolderTitle"
  | "confirmDeleteMessage"
  | "confirmDeleteMessageWithFolderTitle";
export const getModularTranslationKey = (
  translationKey: ModularTranslationKeys,
  column?: KSchemaColumn,
) => {
  const translationGroup = getTranslationGroup(column);

  return `modular_${translationGroup}.${translationKey}` as `${`modular_${TranslationGroups}`}.${ModularTranslationKeys}`;
};

export function formatCoordinates(coords: GeoCoordinates | undefined) {
  if (coords) {
    return `${coords.latitude}, ${coords.longitude}`;
  }
  return i18n.t("addressNotRecognized");
}

export function formatAddress(
  geolocation: GeoLocation | null | undefined,
  displayDefault = true,
) {
  if (geolocation?.address?.description) {
    return geolocation.address.description;
  }
  if (geolocation?.coords) {
    return formatCoordinates(geolocation.coords);
  }
  return displayDefault ? i18n.t("addressNotRecognized") : "";
}

export function hasGeolocation(
  geolocation?: GeoLocation | null,
): geolocation is GeoLocation {
  return (
    geolocation?.coords?.latitude !== undefined &&
    geolocation?.coords?.longitude !== undefined
  );
}

export const lodashKeyResolver = (...args: unknown[]) => JSON.stringify(args);

export const isDev = typeof __DEV__ !== "undefined" && __DEV__;

export const displaySandbox = isDev;

export const ensureValidStyleSheet = <T>(styleSheet: T) =>
  styleSheet as StyleSheet.NamedStyles<T>;

export const conditionalArray = <T>(array: T[], value: boolean) => {
  return value ? array : [];
};

export const conditionalEntry = <T>(item: T, value: boolean): T | undefined =>
  value ? item : undefined;

export const conditionalObject = (
  item: Record<string, unknown>,
  value: boolean,
) => (value ? item : {});

export function getFilenameFromStoragePath(storagePath: string) {
  const parts = storagePath.split("/");
  return parts[parts.length - 1] as string;
}

export function getFileNameFromUrl(url: string) {
  const urlObject = new URL(url);
  const pathname = urlObject.pathname;

  return getFilename(pathname);
}

export function stringContainsCharacters(
  str: string | undefined,
): str is string {
  return Boolean(str && str.trim().length > 0);
}

export function getFreemiumLimitDate() {
  const limitDate = new Date();
  limitDate.setDate(
    limitDate.getDate() - constants.FREEMIUM_CONTENT_LIMIT_DAYS,
  );

  return limitDate;
}

export const languageFlag = {
  "fr-FR": "🇫🇷",
  "en-GB": "🇬🇧",
  "en-US": "🇺🇸",
  "it-IT": "🇮🇹",
  "de-DE": "🇩🇪",
  "es-ES": "🇪🇸",
} as const satisfies {
  [key in PoolLanguage]: string;
};

export function explodeLink(link: string): {
  protocol: string | undefined;
  host: string | undefined;
  port: string | undefined;
  path: string | undefined;
  query: string | undefined;
} {
  const parts = new URL(link);

  return {
    protocol: parts.protocol,
    host: parts.host,
    port: parts.port,
    path: parts.pathname,
    query: parts.search,
  };
}

export function isNotNull<T>(value: T): value is NonNullable<T> {
  return Boolean(value);
}

export function textEllipsis(text: string, numberOfChar: number) {
  if (text.length <= numberOfChar) {
    return text;
  }
  return `${text.slice(0, numberOfChar)}…`;
}

const EMPTY_ARRAY: readonly unknown[] = Object.freeze([]);
export function getEmptyArray<T>() {
  return EMPTY_ARRAY as T[];
}
