import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deselectMessage } from "@kraaft/shared/core/modules/message/messageActions";
import { selectMessageSelectionSource } from "@kraaft/shared/core/modules/message/messageSelectors";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectRoomSchemaId } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { PortalHost } from "@kraaft/ui";
import { ConversationDetails } from "@kraaft/web/src/components/conversationDetails/conversationDetails";
import { DirectoryRoute } from "@kraaft/web/src/components/directory/directoryRoute";
import { DocumentGalleryRoute } from "@kraaft/web/src/components/galleries/documentGallery/documentGalleryRoute";
import { PhotoGalleryRoute } from "@kraaft/web/src/components/galleries/photoGallery/photoGalleryRoute";
import { ModularFolderHistoryRoute } from "@kraaft/web/src/components/modularFolder/modularFolderHistoryRoute";
import { ModularFolderRoute } from "@kraaft/web/src/components/modularFolder/modularFolderRoute";
import { ModularFoldersRoute } from "@kraaft/web/src/components/modularFolder/modularFoldersRoute";
import { EditSchemaVisibilityForRoomRoute } from "@kraaft/web/src/components/roomSchemaVisibility/editSchemaVisibilityForRoomRoute";
import {
  useNavigationService,
  useRouteService,
} from "@kraaft/web/src/core/services/navigation/useNavigationService";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";
import { queries } from "@kraaft/web/src/views/app/appRouter/routes";
import { useConversationDetailsPortalQuery } from "@kraaft/web/src/views/messenger/detailsDrawer/useConversationDetailsPortalQuery";

import { styleSheet, useStyles } from "./detailsDrawer.styles";

const PORTAL_HOSTNAME = "detailsDrawer";

interface Props {
  roomId: string;
  hideConversation: boolean;
}

const useClearSelection = (roomId: string) => {
  const selectionSource = useSelector(selectMessageSelectionSource(roomId));
  const dispatch = useDispatch();
  return {
    clearSelectedPhotos: useCallback(() => {
      if (selectionSource === "photoGallery") {
        dispatch(deselectMessage({ roomId, all: true }));
      }
    }, [dispatch, roomId, selectionSource]),
    clearSelectedDocuments: useCallback(() => {
      if (selectionSource === "documentGallery") {
        dispatch(deselectMessage({ roomId, all: true }));
      }
    }, [dispatch, roomId, selectionSource]),
  };
};

/**
 * Within the details drawer, we can have nested navigation.
 *
 * For chat:
 * [Info/Folder] -> [ModularFolders] -> [ModularFolder]
 * [Info/Folder] -> [PhotoGallery]
 * [Info/Folder] -> [DocumentGallery]
 * [Info/Folder] -> [Directory]
 *
 * For map:
 * [PhotoGallery]
 * [ModularFolders] -> [ModularFolder]
 *
 * goToDrawerRoot callback is used to go back to the root of the drawer.
 * on chat it means [Info/Folder]
 * on map it means [PhotoGallery] or [ModularFolders]
 *
 */
const DetailsDrawer = (props: Props) => {
  const { roomId, hideConversation } = props;
  const navigationService = useNavigationService();
  const route = useRouteService();
  const isMapOpen = route.isMapOpen();

  const currentPoolId = useSelector(selectCurrentPoolId);

  const query = useQueryString();
  const folderId = query.get(queries.folderId);
  const schemaId = query.get(queries.schemaId);
  const roomSchemaId = useSelector(selectRoomSchemaId(currentPoolId ?? ""));
  const directoryId = query.get(queries.directoryId) ?? undefined;

  const [openedPanel, setOpenedPanel] = useConversationDetailsPortalQuery({
    roomId,
  });

  const styles = useStyles();

  const { clearSelectedPhotos, clearSelectedDocuments } =
    useClearSelection(roomId);

  // directory state cannot be persisted among rooms
  // because there is no "similar" directory in another room
  useEffect(() => {
    if (navigationService.isDrawerOpenOnDirectory()) {
      navigationService.navigate("ConversationFolder", { roomId });
    }
  }, [roomId, navigationService]);

  const goToDrawerRoot = useCallback(() => {
    if (isMapOpen) {
      // when we are in map, the only way to reach this callback is to click on the back button from one of the root views (photoGallery or modularFolders)
      // we then want to close the drawer, as the back button is hidden if we are in a big enough screen.
      // this has to be refactored at some point to avoid these kind of comments.
      navigationService.toggleConversationDetail({ roomId, open: false });
    } else {
      navigationService.navigate("ConversationFolder", { roomId });
    }
  }, [isMapOpen, navigationService, roomId]);

  const onPhotoGalleryBackButtonPress = useCallback(() => {
    clearSelectedPhotos();
    goToDrawerRoot();
  }, [goToDrawerRoot, clearSelectedPhotos]);

  const onDocumentGalleryBackButtonPress = useCallback(() => {
    clearSelectedDocuments();
    goToDrawerRoot();
  }, [goToDrawerRoot, clearSelectedDocuments]);

  const onModularFolderBackButtonPress = useCallback(() => {
    if (schemaId) {
      navigationService.navigate("ModularFolders", {
        roomId,
        schemaId,
      });
    }
  }, [schemaId, navigationService, roomId]);

  const handleModularFolderHistoryBackButtonPress = useCallback(() => {
    if (schemaId && folderId) {
      navigationService.navigate("ModularFolderDetails", {
        roomId,
        schemaId,
        folderId,
      });
    }
  }, [schemaId, navigationService, roomId, folderId]);

  const hideBackButton = isMapOpen && !hideConversation;

  return (
    <div className={styles.container} id="ide2e-room-details-panel">
      {!isMapOpen && (
        <ConversationDetails
          schemaId={roomSchemaId ?? ""}
          style={styleSheet.conversationDetails}
          roomId={roomId}
          portalHostname={PORTAL_HOSTNAME}
          openedPanel={openedPanel}
          setOpenedPanel={setOpenedPanel}
          isStickyPanel={isMapOpen}
        />
      )}

      <EditSchemaVisibilityForRoomRoute
        roomId={roomId}
        isStickyPanel={isMapOpen}
        onBackButtonPress={goToDrawerRoot}
      />

      <ModularFoldersRoute
        roomId={roomId}
        schemaId={schemaId ?? ""}
        isStickyPanel={isMapOpen}
        onBackButtonPress={goToDrawerRoot}
        hideBackButton={hideBackButton}
      />

      <ModularFolderRoute
        roomId={roomId}
        schemaId={schemaId ?? ""}
        folderId={folderId ?? ""}
        onBackButtonPress={onModularFolderBackButtonPress}
        hideBackButton={hideBackButton}
      />

      <ModularFolderHistoryRoute
        modularFolderId={folderId ?? undefined}
        onBackButtonPress={handleModularFolderHistoryBackButtonPress}
      />

      <PhotoGalleryRoute
        roomId={roomId}
        hideBackButton={hideBackButton}
        onBackButtonPress={onPhotoGalleryBackButtonPress}
        isStickyPanel={isMapOpen}
      />

      <DocumentGalleryRoute
        roomId={roomId}
        onBackButtonPress={onDocumentGalleryBackButtonPress}
      />

      <DirectoryRoute
        roomId={roomId}
        directoryId={directoryId}
        onBackButtonPress={goToDrawerRoot}
      />

      <PortalHost hostname={PORTAL_HOSTNAME} />
    </div>
  );
};

export { DetailsDrawer };
