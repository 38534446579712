import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  dialogContentContainer: {
    flex: 1,
    width: "100%",
  },
  addDirectoryButton: {
    paddingTop: Spacing.S8,
  },
});
