import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { compact } from "lodash/fp";

import { isNative } from "@kraaft/helper-functions";
import { useOpenItinerary } from "@kraaft/shared/components/geolocationOpener/useItinerary";
import { UseOpenItineraryParams } from "@kraaft/shared/components/geolocationOpener/useItinerary.types";
import { useRoomAccess } from "@kraaft/shared/core/modules/room/hooks/roomAccess.hooks";
import { useArchive } from "@kraaft/shared/core/modules/room/hooks/roomArchive.hooks";
import { useRoomPinning } from "@kraaft/shared/core/modules/room/hooks/roomPinning.hook";
import { useRoomUnread } from "@kraaft/shared/core/modules/room/hooks/roomUnread.hooks";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { ActionSheetItemProps } from "@kraaft/ui";

interface UseRoomCardActions {
  poolId: string;
  roomCard?: AnyRoomCard;
  openNotificationsSheet(roomId: string): void;
}

export const useRoomCardActions = ({
  poolId,
  roomCard,
  openNotificationsSheet,
}: UseRoomCardActions) => {
  const { t } = useTranslation();
  const roomId = roomCard?.roomId || "";

  const isRoomMember = roomCard?.type === "member";

  const { archive, unarchive, isArchived } = useArchive(roomId, "room_list");

  const { joinRoom } = useRoomAccess({
    poolId,
    roomId,
    source: "room_list",
  });

  const coords =
    roomCard &&
    getExistingRoomRecordField(roomCard.properties, "geolocation")?.coords;

  const context = useMemo<UseOpenItineraryParams["context"]>(
    () => ({
      roomId,
      source: "room_list",
    }),
    [roomId],
  );

  const {
    ItineraryActionSheet,
    openItinerary: openAvailableMapAppsActionSheet,
  } = useOpenItinerary({
    coords,
    context,
  });

  const itineraryItem = useMemo<ActionSheetItemProps | undefined>(
    () =>
      coords && {
        icon: isNative() ? "navigation-pointer-01" : "marker-pin-01",
        label: t(isNative() ? "itinerary" : "seeAddress"),
        onClick: openAvailableMapAppsActionSheet,
      },
    [coords, openAvailableMapAppsActionSheet, t],
  );

  const notificationsItem = useMemo<ActionSheetItemProps | undefined>(() => {
    return isRoomMember
      ? {
          icon: "bell-04",
          label: t("notifications.title"),
          onClick: () => roomCard && openNotificationsSheet(roomCard.roomId),
        }
      : undefined;
  }, [isRoomMember, t, roomCard, openNotificationsSheet]);

  const { pin, unpin, isPinned, isPinnable } = useRoomPinning(roomId);

  const roomPinning = useMemo<ActionSheetItemProps | undefined>(() => {
    if (!isPinnable) {
      return undefined;
    }

    if (isPinned) {
      return {
        icon: "pin-02",
        label: t("roomPinning.unpin"),
        onClick: unpin,
      };
    }

    return {
      icon: "pin-02",
      label: t("roomPinning.pin"),
      onClick: pin,
    };
  }, [isPinnable, isPinned, pin, t, unpin]);

  const { markAsUnread, markAsRead, status } = useRoomUnread(roomId);

  const markUnread = useMemo<ActionSheetItemProps | undefined>(() => {
    if (!isRoomMember) {
      return undefined;
    }
    switch (status) {
      case "has-no-messages":
        return undefined;
      case "has-read-messages":
        return {
          icon: "message-notification-circle",
          label: t("markAsUnread"),
          onClick: markAsUnread,
        };
      case "has-unread-messages":
        return {
          icon: "message-notification-circle",
          label: t("markAsRead"),
          onClick: markAsRead,
        };
    }
  }, [isRoomMember, status, t, markAsRead, markAsUnread]);

  const archiveItem = useMemo<ActionSheetItemProps | undefined>(() => {
    if (isArchived && unarchive) {
      return {
        icon: "unarchive",
        label: t("unarchiveButton"),
        onClick: unarchive,
      };
    }

    if (!isArchived && archive) {
      return {
        icon: "archive",
        label: t("archiveButton"),
        onClick: archive,
      };
    }
  }, [isArchived, archive, unarchive, t]);

  const roomAccessItem = useMemo<ActionSheetItemProps | undefined>(() => {
    if (joinRoom) {
      return {
        icon: "log-in-02",
        label: t("enterConversation"),
        onClick: joinRoom,
      };
    }
  }, [joinRoom, t]);

  const items = useMemo<Array<ActionSheetItemProps>>(
    () =>
      compact([
        roomPinning,
        itineraryItem,
        notificationsItem,
        archiveItem,
        roomAccessItem,
        markUnread,
      ]),
    [
      roomPinning,
      itineraryItem,
      notificationsItem,
      archiveItem,
      roomAccessItem,
      markUnread,
    ],
  );

  return { items, ItineraryActionSheet };
};
