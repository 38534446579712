import { View } from "react-native";

import { ColorStyle, Icon } from "@kraaft/ui";

import { AvatarProps } from "./avatar.types";
import { getStylesForSizeAndType } from "./avatar.utils";
import { AvatarContentRenderer } from "./avatarContent";

import { styles } from "./avatar.styles";

const Avatar = (props: AvatarProps) => {
  const {
    size = "medium",
    topIndicatorIconName,
    bottomIndicatorIconName,
  } = props;

  const {
    containerStyle,
    indicatorDotStyle,
    topIndicatorContainerStyle,
    bottomIndicatorContainerStyle,
    indicatorIconSize,
  } = getStylesForSizeAndType(size);

  return (
    <View>
      <View style={containerStyle}>
        <AvatarContentRenderer {...props} />
      </View>

      {topIndicatorIconName && (
        <View style={topIndicatorContainerStyle}>
          <View style={indicatorDotStyle} />
          <Icon
            name={topIndicatorIconName}
            size={indicatorIconSize}
            color={ColorStyle.FONT_HIGH_EMPHASIS}
            style={styles.indicatorIcon}
          />
        </View>
      )}
      {bottomIndicatorIconName && (
        <View style={bottomIndicatorContainerStyle}>
          <View style={indicatorDotStyle} />
          <Icon
            name={bottomIndicatorIconName}
            size={indicatorIconSize}
            color={ColorStyle.FONT_HIGH_EMPHASIS}
            style={styles.indicatorIcon}
          />
        </View>
      )}
    </View>
  );
};

export { Avatar };
