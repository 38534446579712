import {
  CompactTextPillContent,
  TextPillContent,
} from "@kraaft/shared/components/modular/pill/utils";
import { PillRenderer } from "@kraaft/shared/components/modular/pill/utils/types";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";

export const LongTextCompactedPillRenderer: PillRenderer<KColumnType.longText> =
  ({ recordProperty, schemaColumn }) => {
    if (!recordProperty?.value) {
      return null;
    }
    return (
      <CompactTextPillContent iconName={KSchemaUtils.getIcon(schemaColumn)}>
        {recordProperty.value}
      </CompactTextPillContent>
    );
  };

export const LongTextPillRenderer: PillRenderer<KColumnType.longText> = ({
  schemaColumn,
  recordProperty,
}) => {
  if (!recordProperty?.value) {
    return null;
  }
  return (
    <TextPillContent
      iconName={KSchemaUtils.getIcon(schemaColumn)}
      tooltipTitle={schemaColumn.name}
    >
      {recordProperty.value}
    </TextPillContent>
  );
};
