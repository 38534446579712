import { setFirebaseLanguage } from "@kraaft/shared/core/services/firebase/sdk";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { getFallbackLanguage } from "@kraaft/shared/core/services/i18next/i18nextUtils";

class FirebaseLanguageService {
  async setupAndSubscribe() {
    await setFirebaseLanguage(getFallbackLanguage(i18n.language)).catch(
      console.error,
    );

    i18n.on("languageChanged", async (language) => {
      setFirebaseLanguage(getFallbackLanguage(language)).catch(console.error);
    });

    // We don't have to unsubscribe as we need to be subscribed the whole time the app is running
  }
}

export const firebaseLanguageService = new FirebaseLanguageService();
