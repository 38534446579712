import { compact } from "lodash";

import {
  selectMultipleCondition,
  selectSingleCondition,
} from "@kraaft/shared/core/generated/__generated/select";
import { Condition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import {
  FirestoreCondition,
  FirestoreSchemaColumnValue,
} from "@kraaft/shared/core/services/firestore/firestoreTypes";

export class WorkflowConversion {
  static toRawCondition(condition: Condition): FirestoreCondition | undefined {
    if (condition.type === "composite") {
      return {
        type: "composite",
        operator: condition.operator,
        conditions: compact(
          condition.conditions.map(WorkflowConversion.toRawCondition),
        ),
      };
    }
    if (condition.type === "standalone-record") {
      return {
        type: "standalone-record",
        columnKey: condition.columnKey,
        predicate: condition.predicate,
      };
    }
    if (condition.type === "couple-record") {
      return {
        type: "couple-record",
        columnKey: condition.columnKey,
        predicate: condition.predicate,
        value: KSchemaConversion.toRawProperty(condition.value),
      };
    }
    if (condition.type === "metadata") {
      return {
        type: "metadata",
        predicate: condition.predicate,
        value: condition.value
          ? KSchemaConversion.toRawProperty(condition.value)
          : undefined,
      };
    }
    if (condition.type === "standalone-metadata") {
      return {
        type: "standalone-metadata",
        predicate: condition.predicate,
      };
    }
  }

  static toValue(
    predicate: string,
    rawValue: FirestoreSchemaColumnValue,
  ): KSchemaColumnValue | undefined {
    const allSelectToSingle = {
      ...selectSingleCondition.selectSingle,
      ...selectMultipleCondition.selectSingle,
    };
    if (rawValue.columnType === "select") {
      if (predicate in allSelectToSingle) {
        return {
          columnType: KColumnType.selectSingle,
          value: rawValue.value,
        };
      }
      return {
        columnType: KColumnType.selectMultiple,
        value: rawValue.value,
      };
    }
    return KSchemaConversion.toProperty(rawValue);
  }

  static toCondition(rawCondition: FirestoreCondition): Condition | undefined {
    if (rawCondition.type === "standalone-record") {
      return {
        type: "standalone-record",
        columnKey: rawCondition.columnKey,
        predicate: rawCondition.predicate,
      };
    }
    if (rawCondition.type === "couple-record") {
      const value = WorkflowConversion.toValue(
        rawCondition.predicate,
        rawCondition.value,
      );
      if (!value) {
        return undefined;
      }
      return {
        type: "couple-record",
        columnKey: rawCondition.columnKey,
        predicate: rawCondition.predicate,
        value,
      };
    }
    if (rawCondition.type === "composite") {
      return {
        type: "composite",
        operator: rawCondition.operator,
        conditions: compact(
          rawCondition.conditions.map(WorkflowConversion.toCondition),
        ),
      };
    }
    if (rawCondition.type === "metadata") {
      return {
        type: "metadata",
        predicate: rawCondition.predicate,
        value: rawCondition.value
          ? WorkflowConversion.toValue(
              rawCondition.predicate,
              rawCondition.value,
            )
          : undefined,
      };
    }
  }
}
