import { makeStyles } from "@mui/styles";

import { ColorStyle, Spacing } from "@kraaft/ui";

interface Props {
  noBackground?: boolean;
}

export const useStyles = makeStyles({
  renderItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
    width: "100%",
    backgroundColor: ({ noBackground }: Props) =>
      noBackground ? undefined : ColorStyle.BACKGROUND_STANDARD,
  },
  renderDnDContainer: {
    width: 20,
    marginRight: Spacing.S8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  renderDndContainerPadLeft: {
    paddingLeft: 10,
    marginRight: Spacing.S12,
  },
  renderTextContainer: {
    width: "100%",
  },
  renderCross: {
    "&:hover": {
      cursor: "pointer",
    },
    marginLeft: 20,
  },
  sortableDiv: {
    maxHeight: 240,
    display: "flex",
    flexDirection: "column",
    background: ({ noBackground }: Props) =>
      noBackground ? undefined : ColorStyle.BACKGROUND_STANDARD,
  },
  inputOptionContainer: {
    width: "100%",
  },

  rowContainer: {
    padding: `${Spacing.S8 / 2}px 0`,
  },
});
