import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import {
  KNavBar,
  KNavBarTabProps,
} from "@kraaft/web/src/components/kNavBar/kNavBar";
import { FormatType } from "@kraaft/web/src/components/visualisationSelector/visualisationSelector.types";
import { history } from "@kraaft/web/src/core/services/history";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";
import { queries } from "@kraaft/web/src/views/app/appRouter/routes";

interface VisualisationSelectorProps {
  containerId?: string;
  selectId?: string;
}

const VisualisationSelector = ({ containerId }: VisualisationSelectorProps) => {
  const { t } = useTranslation();
  const query = useQueryString();
  const location = useLocation();
  const schemaType = location.pathname.split("/")[2] as string;

  const [formatType, setFormatType] = useState<string>(
    query.get(queries.format) ?? FormatType.TABLE,
  );

  const onChange = (newValue: string) => {
    trackEvent({
      eventName: "Click On Visualization Selector",
      source_visualization: formatType,
      target_visualization: newValue,
      content_type: schemaType,
    });
    setFormatType(newValue);
  };

  const formatItems: KNavBarTabProps[] = useMemo(() => {
    return [
      {
        id: FormatType.TABLE,
        label: t("databaseFormat.table"),
        value: FormatType.TABLE,
        icon: "list",
      },
      {
        id: FormatType.KANBAN,
        label: t("databaseFormat.kanban"),
        value: FormatType.KANBAN,
        icon: "columns-01",
      },
      {
        id: FormatType.MAP,
        label: t("databaseFormat.map"),
        value: FormatType.MAP,
        icon: "map-04",
      },
    ];
  }, [t]);

  const navigationService = useNavigationService();

  useEffect(() => {
    const historyParams = new URLSearchParams(history.location.search);
    historyParams.set(queries.format, formatType);
    navigationService.navigateAndPreserveSearch({
      pathname: history.location.pathname,
      search: historyParams,
    });
  }, [formatType, navigationService]);

  return (
    <div id={containerId}>
      <KNavBar value={formatType} onChange={onChange} tabs={formatItems} />
    </div>
  );
};

export { VisualisationSelector };
