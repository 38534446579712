import React, { useRef } from "react";
import clsx from "clsx";

import { uuid } from "@kraaft/shared/core/utils";
import { Color } from "@kraaft/ui";

import { useStyles } from "./cornerGradient.styles";

const gradientDirections = {
  topLeft: {
    direction: { x1: "0%", y1: "0%", x2: "100%", y2: "100%" },
    path: "M0 0 H100 L0 100 Z",
  },
  topRight: {
    direction: { x1: "100%", y1: "0%", x2: "0%", y2: "100%" },
    path: "M0 0 H100 V100 Z",
  },
  bottomRight: {
    direction: { x1: "100%", y1: "100%", x2: "0%", y2: "0%" },
    path: "M100 0v100 H0 L100 0z",
  },
  bottomLeft: {
    direction: { x1: "0%", y1: "100%", x2: "100%", y2: "0%" },
    path: "M0 0L100 100 H0Z",
  },
};

type CornerGradientProps = {
  direction?: keyof typeof gradientDirections;
};

const CornerGradient_ = ({
  direction = "bottomRight",
}: CornerGradientProps) => {
  const classes = useStyles();

  const gradientId = useRef(`gradient-${uuid()}`).current;

  const {
    direction: { x1, y1, x2, y2 },
    path,
  } = gradientDirections[direction];
  return (
    <svg
      preserveAspectRatio="xMaxYMax"
      viewBox="0 0 100 100"
      fill="none"
      className={clsx(classes.cornerContainer, classes[direction])}
    >
      <defs>
        <linearGradient id={gradientId} x1={x1} y1={y1} x2={x2} y2={y2}>
          <stop offset="0%" stopColor={Color.BLACK} stopOpacity={0.6} />
          <stop offset="20%" stopColor={Color.BLACK} stopOpacity={0} />
        </linearGradient>
      </defs>
      <path d={path} fill={`url(#${gradientId})`} />
    </svg>
  );
};

export const CornerGradient = React.memo(CornerGradient_);
