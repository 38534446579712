/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import { Condition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { CheckboxFilterOptions } from "@kraaft/web/src/components/filterMenu/filterOptions/checkboxFilterOptions";
import { DateFilterOptions } from "@kraaft/web/src/components/filterMenu/filterOptions/dateFilterOptions";
import { InputFilterOptions } from "@kraaft/web/src/components/filterMenu/filterOptions/inputFilterOptions";
import { MetadataFilterOptions } from "@kraaft/web/src/components/filterMenu/filterOptions/metadataFilter";
import { SelectFilterOptions } from "@kraaft/web/src/components/filterMenu/filterOptions/selectFilterOptions";
import { UserFilterOptions } from "@kraaft/web/src/components/filterMenu/filterOptions/userFilterOptions";

interface FilterRendererOptionsProps {
  condition: Condition;
  setCondition: (condition: Condition) => void;
  columns: { [key: string]: KSchemaColumn };
}

const PredicateValueInputFactory = (props: FilterRendererOptionsProps) => {
  const { condition, setCondition, columns } = props;

  if (
    condition.type === "composite" ||
    condition.type === "draft" ||
    condition.type === "standalone-metadata"
  ) {
    return null;
  }

  if (condition.type === "metadata") {
    return (
      <MetadataFilterOptions
        setCondition={setCondition}
        condition={condition}
      />
    );
  }

  const column = columns[condition.columnKey];

  if (!column || condition.type !== "couple-record") {
    return null;
  }

  const valueColumnType = condition.value.columnType;

  switch (valueColumnType) {
    case KColumnType.automatedCreatedBy:
    case KColumnType.user: {
      return (
        <UserFilterOptions
          condition={condition}
          setCondition={setCondition}
          column={column}
          valueColumnType={valueColumnType}
        />
      );
    }
    case KColumnType.selectSingle:
    case KColumnType.selectMultiple:
      return (
        <SelectFilterOptions
          condition={condition}
          setCondition={setCondition}
          column={column}
          valueColumnType={valueColumnType}
        />
      );
    case KColumnType.automatedCreatedAt:
    case KColumnType.date:
      return (
        <DateFilterOptions
          condition={condition}
          setCondition={setCondition}
          column={column}
          valueColumnType={valueColumnType}
        />
      );
    case KColumnType.longText:
    case KColumnType.shortText:
    case KColumnType.number:
    case KColumnType.currency:
      return (
        <InputFilterOptions
          key={condition.predicate}
          condition={condition}
          setCondition={setCondition}
          column={column}
          valueColumnType={valueColumnType}
        />
      );
    case KColumnType.checkbox:
      return (
        <CheckboxFilterOptions
          condition={condition}
          setCondition={setCondition}
          column={column}
          valueColumnType={valueColumnType}
        />
      );
    default:
      return null;
  }
};

export { PredicateValueInputFactory };
