import { useDragLayer } from "react-dnd";

import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { PixelSize, Spacing } from "@kraaft/ui";
import { useDragLayerStyles } from "@kraaft/web/src/components/dragAndDrop/dragLayerBase.styles";
import { ColumnIcon } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementIcon/columnIcon";
import { SectionIcon } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementIcon/sectionIcon";
import { useSchemaBuilderRootSection } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

import { useStyles } from "./elementDragLayer.styles";

// eslint-disable-next-line complexity
const ElementDragLayer = () => {
  const rootSection = useSchemaBuilderRootSection();

  const { source, item, initialClientOffset, initialSourceClientOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem() as { elementKey: string; width: number },
      dragging: monitor.isDragging(),
      initialClientOffset: monitor.getInitialClientOffset(),
      initialSourceClientOffset: monitor.getInitialSourceClientOffset(),
      source: monitor.getSourceClientOffset(),
    }));
  const dragLayerStyles = useDragLayerStyles();

  const styles = useStyles();

  const element = item
    ? KSchemaUtils.findElement(rootSection, item.elementKey)
    : undefined;

  const borderColor =
    element?.elementType === "section" ? element.color : undefined;

  const sourceX = source?.x ?? 0;
  const sourceY = source?.y ?? 0;

  const offsetX = initialClientOffset?.x ?? 0;
  const offsetY = initialClientOffset?.y ?? 0;

  const sourceOffsetX = initialSourceClientOffset?.x ?? 0;
  const sourceOffsetY = initialSourceClientOffset?.y ?? 0;

  const translateX = sourceX + (offsetX - sourceOffsetX);
  const translateY = sourceY + (offsetY - sourceOffsetY) - 56 / 2;

  return (
    <div className={dragLayerStyles.layerBase}>
      {item && element && (
        <div
          className={styles.containerStyle}
          style={{
            borderLeft: `${PixelSize.S8}px solid ${borderColor}`,
            transform: `translateX(${translateX}px) translateY(${translateY}px)`,
          }}
        >
          {element.elementType === "column" ? (
            <ColumnIcon columnType={element.type} />
          ) : (
            <SectionIcon color={element.color} />
          )}
          <div
            style={{
              marginLeft: Spacing.S12,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {element?.name}
          </div>
        </div>
      )}
    </div>
  );
};

export { ElementDragLayer };
