import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { selectShouldDisplayShowAllMiniImages } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { geolocationContextActions } from "@kraaft/shared/core/modules/room/roomActions";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Button } from "@kraaft/ui";

interface Props {
  roomId: string;
}

const MapZoomOutButton = (props: Props) => {
  const { roomId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const shouldDisplay = useSelector(
    selectShouldDisplayShowAllMiniImages(roomId),
  );

  const handlePress = useCallback(() => {
    trackEvent({ eventName: "Click On View All Photos Button" });
    dispatch(
      geolocationContextActions({
        actions: [{ type: "zoomOnMapBounds" }],
      }),
    );
  }, [dispatch]);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Button
      onPress={handlePress}
      icon="zoom-out"
      accessibilityLabel={t("seeAllMapMedias")}
      text={t("seeAllMapMedias")}
      size="SMALL"
      variant="MAP"
    />
  );
};

export { MapZoomOutButton };
