import { isNative } from "@kraaft/helper-functions";
import { BaseMarker } from "@kraaft/shared/components/geolocation/baseMarker";
import { ClusterRendererProps } from "@kraaft/shared/components/mapController/markers/marker/cluster/cluster.types";
import { SimpleClusterContent } from "@kraaft/shared/components/mapController/markers/marker/cluster/simpleClusterContent/simpleClusterContent";
import { RoomClusterTooltip } from "@kraaft/shared/components/mapController/markers/roomMarker/roomClusterTooltip";
import { RoomCardWithGeolocation } from "@kraaft/shared/core/modules/roomCard/roomCard.state";

const RoomCluster = (props: ClusterRendererProps<RoomCardWithGeolocation>) => {
  const { isHovered, markerCount, markers, isMaybeMaxZoom, onTooltipPress } =
    props;

  const renderTooltip = () => (
    <RoomClusterTooltip
      markerCount={markerCount}
      markers={markers}
      onPress={onTooltipPress}
    />
  );

  return (
    <BaseMarker
      isHovered={isHovered}
      tooltipComponent={!isNative() && isMaybeMaxZoom && renderTooltip()}
    >
      <SimpleClusterContent isHovered={isHovered} markerCount={markerCount} />
    </BaseMarker>
  );
};

export { RoomCluster };
