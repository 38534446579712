import { AudioPlayer, AudioPlayerStatus } from "./audioPlayer";

export class HTMLAudioPlayer implements AudioPlayer {
  currentSound: HTMLAudioElement | undefined;
  currentAbortController = new AbortController();

  async toggle(
    audioFileUri: string,
    onProgress?: (progress: number) => void,
    onPlayingStatusUpdated?: (status: AudioPlayerStatus) => void,
  ) {
    if (this.currentSound === undefined) {
      return this.playNew(audioFileUri, onProgress, onPlayingStatusUpdated);
    }

    if (this.currentSound.src !== audioFileUri) {
      await this.playNew(audioFileUri, onProgress, onPlayingStatusUpdated);
    } else if (this.currentSound.paused) {
      await this.currentSound.play();
    } else {
      this.currentSound.pause();
    }
  }

  async cleanup(audioFileUri: string) {
    if (this.currentSound === undefined) {
      return;
    }

    if (this.currentSound.src !== audioFileUri) {
      return;
    }

    await this.stopCurrentSound();
    this.currentAbortController.abort();
    this.currentSound.srcObject = null;
    this.currentSound = undefined;
  }

  private async stopCurrentSound() {
    if (this.currentSound === undefined) {
      console.log(
        "HTMLAudioPlayer::stopCurrentSound currentSound is undefined",
      );
      return;
    }

    this.currentSound.pause();
    this.currentSound.currentTime = 0;
  }

  private async playNew(
    audioFileUri: string,
    onProgress?: (progress: number) => void,
    onPlayingStatusUpdated?: (status: AudioPlayerStatus) => void,
  ) {
    if (this.currentSound !== undefined) {
      await this.stopCurrentSound();
    }
    this.currentSound = new Audio(audioFileUri);
    this.currentAbortController = new AbortController();

    const currentSound = this.currentSound;
    function onChangeEvent() {
      onPlayingStatusUpdated?.(
        currentSound.paused
          ? AudioPlayerStatus.PAUSED
          : AudioPlayerStatus.PLAYING,
      );
    }
    function onHoldEvent() {
      onPlayingStatusUpdated?.(AudioPlayerStatus.BUFFERING);
    }
    function onProgressEvent() {
      onProgress?.((currentSound.currentTime / currentSound.duration) * 100);
    }
    function onEndEvent() {
      onProgress?.(0);
    }

    this.currentSound.addEventListener("playing", onChangeEvent, {
      signal: this.currentAbortController.signal,
    });
    this.currentSound.addEventListener("pause", onChangeEvent, {
      signal: this.currentAbortController.signal,
    });
    this.currentSound.addEventListener("waiting", onHoldEvent, {
      signal: this.currentAbortController.signal,
    });
    this.currentSound.addEventListener("ended", onEndEvent, {
      signal: this.currentAbortController.signal,
    });
    this.currentSound.addEventListener("timeupdate", onProgressEvent, {
      signal: this.currentAbortController.signal,
    });

    await this.currentSound.play();
  }
}
