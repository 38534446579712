import { useCallback, useRef } from "react";
import compact from "lodash/compact";

export const useCombinedRefs = <T>(
  ...refs: (React.Ref<T> | undefined)[]
): React.RefCallback<T> => {
  return useCallback(
    (element: T) => {
      for (const ref of compact(refs)) {
        if (typeof ref === "function") {
          ref(element);
        } else if (ref && typeof ref === "object") {
          (ref as React.MutableRefObject<T>).current = element;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refs,
  );
};

export const useFallbackRef = <T>(
  ref: React.MutableRefObject<T> | undefined,
  fallbackValue: T,
): React.MutableRefObject<T> => {
  const fallbackRef = useRef<T>(fallbackValue);

  return ref ?? fallbackRef;
};
