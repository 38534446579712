import Geocoder from "react-native-geocoding";

import { getGoogleGeocodeApiKey } from "@kraaft/shared/constants/environment/environment.utils";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { GeoLocation } from "@kraaft/shared/core/types";

export class GoogleGeocoding {
  static initialized = false;

  private static ensureInitialized() {
    if (this.initialized) {
      return;
    }
    Geocoder.init(getGoogleGeocodeApiKey(), {
      language: i18n.language,
      location_type: "ROOFTOP|RANGE_INTERPOLATED",
      result_type: "street_address|park|point_of_interest",
    });
  }

  private static from(...args: Parameters<(typeof Geocoder)["from"]>) {
    GoogleGeocoding.ensureInitialized();
    return Geocoder.from(...args);
  }

  private static getAddressFromResponse(
    addressList: Geocoder.GeocoderResponse,
  ) {
    const badRoadName = ["Unnamed Road"];

    if (addressList.results.length > 0) {
      return addressList.results.find((address) => {
        const addressComp = address.address_components[0];
        if (addressComp && badRoadName.includes(addressComp.long_name)) {
          return false;
        }
        return true;
      });
    }
    return undefined;
  }

  static async getGeolocationFromAddress(address: string) {
    try {
      const response = await GoogleGeocoding.from(address);

      if (response && response.status === "OK") {
        const foundAddress = GoogleGeocoding.getAddressFromResponse(response);

        if (foundAddress) {
          return {
            coords: {
              latitude: foundAddress.geometry.location.lat,
              longitude: foundAddress.geometry.location.lng,
            },
            address: {
              description: foundAddress.formatted_address,
              googlePlaceId: foundAddress.place_id,
            },
          };
        }
      }
    } catch (error) {
      console.log("Error in getGeolocationFromAddress", error);
    }
  }

  static async getGeolocationFromCoordinates(coords: {
    latitude: number;
    longitude: number;
  }): Promise<GeoLocation> {
    try {
      const response = await GoogleGeocoding.from(coords);

      if (response && response.status === "OK") {
        const foundAddress = GoogleGeocoding.getAddressFromResponse(response);

        if (foundAddress) {
          return {
            coords,
            address: {
              description: foundAddress.formatted_address,
              googlePlaceId: foundAddress.place_id,
            },
          };
        }
      }
    } catch (error) {
      console.log("Error in getGeolocationFromCoordinates", error);
    }

    return { coords };
  }
}
