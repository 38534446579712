import { createAction } from "@reduxjs/toolkit";

import { LoaderConfig } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { createLoaderMeta } from "@kraaft/shared/core/modules/loaders/loaderUtils";

export const setLoader = createAction(
  "@loader/setLoader",
  (payload: LoaderConfig) => ({ payload, meta: createLoaderMeta(payload) }),
);

export const clearLoader = createAction(
  "@loader/clearLoader",
  (loaderId: string) => ({
    payload: { id: loaderId },
  }),
);
