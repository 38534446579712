import { FormEvent, useState } from "react";
import { makeStyles } from "@mui/styles";

import {
  IdentityProvider,
  OfflineAccessType,
  SubConfig,
} from "./identityProvider";

interface IdentityProviderFormProps {
  onSubmit: (payload: IdentityProvider) => void;
  initialValue: IdentityProvider;
}

export const IdentityProviderForm = ({
  onSubmit,
  initialValue,
}: IdentityProviderFormProps) => {
  const classes = useStyles();

  // Initial states for main form fields
  const [id, setId] = useState<string>(initialValue.id);
  const [name, setName] = useState<string>(initialValue.name);
  const [domains, setDomains] = useState<string[]>(initialValue.domains);
  const [skippable, setSkippable] = useState<boolean>(initialValue.skippable);
  const [offlineAccess, setOfflineAccess] = useState<string | undefined>(
    initialValue.offlineAccess,
  );
  const [helpText, setHelpText] = useState<string>(initialValue.helpText || "");
  const [subConfigs, setSubConfigs] = useState<SubConfig[]>(
    initialValue.subConfigs ?? [],
  );

  // Helper functions for handling changes in fields and lists
  const handleDomainChange = (index: number, value: string) => {
    const newDomains = [...domains];
    newDomains[index] = value;
    setDomains(newDomains);
  };

  const handleAddDomain = () => setDomains([...domains, ""]);
  const handleRemoveDomain = (index: number) =>
    setDomains(domains.filter((_, i) => i !== index));

  // Sub-config handlers
  const handleSubConfigChange = (
    index: number,
    field: keyof SubConfig,
    value: string | boolean,
  ) => {
    const newSubConfigs = [...subConfigs];
    if (!newSubConfigs[index]) {
      return subConfigs;
    }

    newSubConfigs[index][field] = value as never;
    setSubConfigs(newSubConfigs);
  };

  const handleAddSubConfig = () =>
    setSubConfigs([
      ...subConfigs,
      { id: "", name: "", domains: [], skippable: false, helpText: "" },
    ]);

  const handleRemoveSubConfig = (index: number) =>
    setSubConfigs(subConfigs.filter((_, i) => i !== index));

  const handleSubConfigDomainChange = (
    configIndex: number,
    domainIndex: number,
    value: string,
  ) => {
    const newSubConfigs = [...subConfigs];

    if (!newSubConfigs[configIndex]) {
      return subConfigs;
    }

    newSubConfigs[configIndex].domains[domainIndex] = value;
    setSubConfigs(newSubConfigs);
  };

  const handleAddSubConfigDomain = (configIndex: number) => {
    const newSubConfigs = [...subConfigs];

    if (!newSubConfigs[configIndex]) {
      return subConfigs;
    }

    newSubConfigs[configIndex].domains.push("");
    setSubConfigs(newSubConfigs);
  };

  const handleRemoveSubConfigDomain = (
    configIndex: number,
    domainIndex: number,
  ) => {
    const newSubConfigs = [...subConfigs];

    if (!newSubConfigs[configIndex]) {
      return subConfigs;
    }

    newSubConfigs[configIndex].domains = newSubConfigs[
      configIndex
    ].domains.filter((_, i) => i !== domainIndex);
    setSubConfigs(newSubConfigs);
  };

  // Form submission
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const payload: IdentityProvider = {
      id,
      name,
      domains,
      skippable,
      offlineAccess,
      helpText: helpText || undefined,
      subConfigs: subConfigs.map((subConfig) => ({
        ...subConfig,
        helpText: subConfig.helpText || undefined,
      })),
    };
    onSubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.column}>
        <label>
          ID:
          <input
            type="text"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
        </label>

        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>

        <label>Domains:</label>
        {domains.map((domain, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <div key={index}>
            <input
              type="text"
              value={domain}
              onChange={(e) => handleDomainChange(index, e.target.value)}
            />
            <button type="button" onClick={() => handleRemoveDomain(index)}>
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddDomain}>
          Add Domain
        </button>

        <label>
          Skippable:
          <input
            type="checkbox"
            checked={skippable}
            onChange={(e) => setSkippable(e.target.checked)}
          />
        </label>

        <label>
          Offline Access:
          <select
            value={offlineAccess}
            onChange={(e) =>
              setOfflineAccess(e.target.value as OfflineAccessType)
            }
          >
            <option value="required">Required</option>
            <option value="optional">Optional</option>
            <option value="none">None</option>
          </select>
        </label>

        <label>
          Help Text:
          <textarea
            value={helpText}
            onChange={(e) => setHelpText(e.target.value)}
          />
        </label>

        <h3>Sub Configurations</h3>
        {subConfigs.map((subConfig, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <div key={index} className={classes.column}>
            <label>
              SubConfig ID:
              <input
                type="text"
                value={subConfig.id}
                onChange={(e) =>
                  handleSubConfigChange(index, "id", e.target.value)
                }
              />
            </label>

            <label>
              Name:
              <input
                type="text"
                value={subConfig.name}
                onChange={(e) =>
                  handleSubConfigChange(index, "name", e.target.value)
                }
              />
            </label>

            <label>Domains:</label>
            {subConfig.domains.map((domain, domainIndex) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <div key={domainIndex}>
                <input
                  type="text"
                  value={domain}
                  onChange={(e) =>
                    handleSubConfigDomainChange(
                      index,
                      domainIndex,
                      e.target.value,
                    )
                  }
                />
                <button
                  type="button"
                  onClick={() =>
                    handleRemoveSubConfigDomain(index, domainIndex)
                  }
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => handleAddSubConfigDomain(index)}
            >
              Add Domain
            </button>

            <label>
              Skippable:
              <input
                type="checkbox"
                checked={subConfig.skippable}
                onChange={(e) =>
                  handleSubConfigChange(index, "skippable", e.target.checked)
                }
              />
            </label>

            <label>
              Help Text:
              <textarea
                value={subConfig.helpText}
                onChange={(e) =>
                  handleSubConfigChange(index, "helpText", e.target.value)
                }
              />
            </label>

            <button type="button" onClick={() => handleRemoveSubConfig(index)}>
              Remove SubConfig
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddSubConfig}>
          Add SubConfig
        </button>

        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

const useStyles = makeStyles({
  column: {
    display: "flex",
    flexDirection: "column",
  },
});
