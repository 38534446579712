import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GoogleMapReact, { MapOptions, Maps } from "google-map-react";

import { useMapMarkers } from "@kraaft/shared/components/mapController/hooks/useMapMarkers";
import {
  MAX_MAP_ZOOM,
  MIN_MAP_ZOOM,
} from "@kraaft/shared/components/mapController/markers/marker/marker.utils";
import {
  DistributedMapMarker,
  MapProps,
} from "@kraaft/shared/components/mapController/types";
import { FRANCE_COORDINATES } from "@kraaft/shared/constants/constants";
import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { selectGeolocationContext } from "@kraaft/shared/core/modules/room/roomSelectors";
import { GeolocationAction } from "@kraaft/shared/core/modules/room/roomState";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { Button, Portal } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { EnableMapOverlaysButton } from "@kraaft/web/src/components/enableMapOverlaysButton";
import {
  distanceToMouse,
  getMarkerBounds,
  roundToCoordinates,
} from "@kraaft/web/src/components/geolocation/mapUtils";
import {
  MapLayout,
  MapsHelper,
} from "@kraaft/web/src/components/geolocation/types";
import { useMapLayoutMemory } from "@kraaft/web/src/components/geolocation/useMapLayoutMemory";
import { useMapOverlayLayers } from "@kraaft/web/src/components/geolocation/useMapOverlayLayers";

import { useMapScreenshoter } from "./useMapScreenshoter";

import { useStyles } from "./mapWeb.styles";

const defaultMapLayout: MapLayout = {
  center: {
    lat: FRANCE_COORDINATES.latitude,
    lng: FRANCE_COORDINATES.longitude,
  },
  zoom: 6,
  bounds: undefined,
};

const MAP_ID = "map-area";

export const MapView = <Markers extends DistributedMapMarker>({
  markers,
  initialCenter,
  enableClustering,
  clustersConfig,
  geoDetail,
  onVisibleMarkersChange,
  onMinZoomReached,
  trackingInfo,
  mapControllerPortalHostname,
}: MapProps<Markers>) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { layout, updateLayout } = useMapLayoutMemory(
    geoDetail,
    defaultMapLayout,
  );

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [mapsHelper, setMapsHelper] = useState<MapsHelper | null>(null);
  const [mapTypeId, setMapTypeId] = useState<google.maps.MapTypeId>(
    "hybrid" as google.maps.MapTypeId.HYBRID,
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const geolocationContext = useSelector(selectGeolocationContext);

  const { components: markersAsComponent, markersWithinBounds } = useMapMarkers(
    markers,
    layout.bounds,
    layout.zoom,
    enableClustering,
    clustersConfig,
  );

  useMapOverlayLayers(mapsHelper, map);

  useEffect(() => {
    onVisibleMarkersChange?.(
      markersWithinBounds.map((marker) => marker.properties.marker.element),
    );
  }, [markersWithinBounds, onVisibleMarkersChange]);

  useEffect(() => {
    onMinZoomReached?.(layout.zoom === MIN_MAP_ZOOM);
  }, [layout.zoom, onMinZoomReached]);

  const mapBounds = useMemo(() => {
    if (!mapsHelper || markers.length === 0) {
      return;
    }

    const bounds = new mapsHelper.LatLngBounds();

    for (const {
      element: {
        geolocation: { coords },
      },
    } of markers) {
      bounds.extend(new mapsHelper.LatLng(coords.latitude, coords.longitude));
    }

    return bounds;
  }, [markers, mapsHelper]);

  const centerBounds = useMemo(() => {
    if (!mapsHelper || !initialCenter) {
      return;
    }

    const bounds = new mapsHelper.LatLngBounds();

    return bounds.extend(
      new mapsHelper.LatLng(initialCenter.latitude, initialCenter.longitude),
    );
  }, [initialCenter, mapsHelper]);

  useEffect(() => {
    function doActions(action: GeolocationAction) {
      switch (action.type) {
        case "centerOn": {
          const { latitude: lat, longitude: lng } = action.coords;

          map?.panTo({ lat, lng });
          break;
        }
        case "zoomOnBounds": {
          const bounds = getMarkerBounds(action.boundsList);

          map?.fitBounds(bounds);
          break;
        }

        case "zoomOnMapBounds":
          if (mapBounds) {
            map?.fitBounds(mapBounds);
          }
          break;
      }
    }

    if (geolocationContext.actions) {
      for (const action of geolocationContext.actions) {
        doActions(action);
      }
    }
  }, [map, geolocationContext, mapBounds]);

  useEffect(() => {
    if (!map || mapBounds === undefined) {
      return;
    }

    if (centerBounds) {
      map.fitBounds(centerBounds);
      return;
    }

    map.fitBounds(mapBounds);
  }, [mapBounds, centerBounds, map]);

  const handleGoogleApiLoaded = useCallback(
    ({ map: newMap, maps }: { map: google.maps.Map; maps: MapsHelper }) => {
      setMap(newMap);
      setMapsHelper(maps);
    },
    [],
  );

  const createMapOptions = useCallback(
    (maps: Maps): MapOptions => ({
      scaleControl: true,
      zoomControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      disableDoubleClickZoom: true,
      clickableIcons: false,
      mapTypeControl: true,
      zoomControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
      maxZoom: MAX_MAP_ZOOM,
      minZoom: MIN_MAP_ZOOM,
      mapTypeId,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_LEFT, // overrided in maps.css
        mapTypeIds: [maps.MapTypeId.HYBRID, maps.MapTypeId.ROADMAP],
      },
    }),
    [mapTypeId],
  );

  const onMapChange = useCallback(
    (value: GoogleMapReact.ChangeEventValue) => {
      if (
        value.zoom !== layout.zoom ||
        roundToCoordinates(value.center.lat) !==
          roundToCoordinates(layout.center.lat) ||
        roundToCoordinates(value.center.lng) !==
          roundToCoordinates(layout.center.lng)
      ) {
        const newLayout: MapLayout = {
          center: value.center,
          zoom: value.zoom,
          bounds: [
            value.bounds.nw.lng,
            value.bounds.se.lat,
            value.bounds.se.lng,
            value.bounds.nw.lat,
          ],
        };

        updateLayout(newLayout);
      }
    },
    [layout.center.lat, layout.center.lng, layout.zoom, updateLayout],
  );

  const [language, region] = i18n.language.split("-");

  const { downloadScreenshot, isScreenshoting } = useMapScreenshoter(
    MAP_ID,
    trackingInfo,
  );

  return (
    <>
      <div
        id={MAP_ID}
        data-testid="ide2e-geomap"
        className={classes.mapContainer}
        ref={containerRef}
      >
        <GoogleMapReact
          onMapTypeIdChange={setMapTypeId}
          bootstrapURLKeys={{
            key: getEnvironment().GOOGLE.WEB_ONLY_MAPS_KEY,
            language,
            region,
          }}
          debounced={false}
          onGoogleApiLoaded={handleGoogleApiLoaded}
          yesIWantToUseGoogleMapApiInternals
          options={createMapOptions}
          hoverDistance={1}
          onChange={onMapChange}
          zoom={layout.zoom}
          center={layout.center}
          distanceToMouse={distanceToMouse}
        >
          {markersAsComponent}
        </GoogleMapReact>
      </div>
      {mapControllerPortalHostname ? (
        <Portal hostname={mapControllerPortalHostname}>
          <Box mb="S8">
            <Button
              accessibilityLabel={t("download")}
              tooltip={t("mapScreenshot.button")}
              onPress={downloadScreenshot}
              loading={isScreenshoting}
              variant="MAP"
              size="SMALL"
              icon="download-01"
            />
          </Box>
          <EnableMapOverlaysButton trackingInfo={trackingInfo} />
        </Portal>
      ) : null}
    </>
  );
};
