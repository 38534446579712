import { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";

import { shadeColor } from "@kraaft/shared/core/utils/colorsUtils";
import { ColorStyle } from "@kraaft/ui";

export const MessageSearchResultHoverable = ({
  children,
}: PropsWithChildren) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

const useStyles = makeStyles({
  container: {
    "&:hover": {
      "& > div": {
        backgroundColor: shadeColor(ColorStyle.BACKGROUND_STANDARD, -0.05),
      },
    },
  },
});
