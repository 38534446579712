import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";

import { UserDeclaredOperations } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/optimistic.types";
import { OptimisticHelper } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/optimisticHelper";
import {
  ReduxActionsFromDeclaredOperations,
  ReduxBundleHelper,
  ReduxStateActions,
} from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle.types";

export function CreateReduxBundleActions<Aggregate>(
  name: string,
  declaredOperations: UserDeclaredOperations,
) {
  const actionPrefix = ReduxBundleHelper.getActionPrefix(name);

  const stateActions: ReduxStateActions<Aggregate> = {
    set: createAction(`OfflineSetter/${name}/set`),
    receive: createAction(`${name}/receive`),
  };

  const actions: Record<string, ActionCreatorWithPayload<any>> = {};

  for (const [key, operation] of Object.entries(declaredOperations)) {
    if (operation.type === "creations") {
      actions[key] = createAction(`${actionPrefix}${key}`, (payload) => ({
        payload: {
          ...payload,
          ids: OptimisticHelper.generateIds(operation.count(payload)),
        },
      }));
    } else {
      actions[key] = createAction(`${actionPrefix}${key}`, (payload) => ({
        payload: {
          ...payload,
          ...(operation.creates
            ? {
                allocatedIds: OptimisticHelper.generateIds(
                  operation.creates(payload),
                ),
              }
            : {}),
        },
      }));
    }
  }

  return {
    stateActions,
    actions: actions as ReduxActionsFromDeclaredOperations<any>,
  };
}
