import { StyleSheet, View } from "react-native";

import { getRecordPropertyDifferenceRenderer } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/getRecordPropertyDifferenceRenderer";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordValue } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { Spacing } from "@kraaft/ui";

export interface DifferenceLayoutProps {
  valueType: KColumnType;
  removedValue?: ModularRecordValue;
  addedValue?: ModularRecordValue;
  schemaColumn: KSchemaColumn | undefined;
}

export const DifferenceLayout = ({
  valueType,
  removedValue,
  addedValue,
  schemaColumn,
}: DifferenceLayoutProps) => {
  return (
    <View style={styles.container}>
      {removedValue ? (
        <View style={styles.alignedStart}>
          {getRecordPropertyDifferenceRenderer(
            valueType,
            removedValue,
            schemaColumn,
            { method: "removed" },
          )}
        </View>
      ) : null}
      {addedValue ? (
        <View style={styles.alignedStart}>
          {getRecordPropertyDifferenceRenderer(
            valueType,
            addedValue,
            schemaColumn,
            { method: "added" },
          )}
        </View>
      ) : null}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    gap: Spacing.S8,
  },
  alignedStart: {
    alignItems: "flex-start",
  },
});
