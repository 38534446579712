import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import isEqual from "fast-deep-equal";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { AutoNumberingMode } from "@kraaft/shared/core/modules/schema/schema.actions";
import { Button, Text } from "@kraaft/ui";

import { useStyles } from "./schemaNamingStrategy.styles";

interface SchemaNamingStrategyProps {
  autoNumberingMode: AutoNumberingMode;
  setAutoNumberingMode: (newMode: AutoNumberingMode) => void;
}

const SchemaNamingStrategy = ({
  autoNumberingMode,
  setAutoNumberingMode,
}: SchemaNamingStrategyProps) => {
  const isFocused = useRef(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const [tempAutoNumberingMode, setTempAutoNumberingMode] =
    useState(autoNumberingMode);

  useEffect(() => {
    setTempAutoNumberingMode((oldTemp) => {
      if (!isFocused.current) {
        return autoNumberingMode;
      }
      if (
        oldTemp.mode === "autonumber" &&
        autoNumberingMode.mode === "autonumber"
      ) {
        return oldTemp;
      }
      return autoNumberingMode;
    });
  }, [autoNumberingMode]);

  const onFocus = useCallback(() => {
    isFocused.current = true;
  }, []);
  const onBlur = useCallback(() => {
    isFocused.current = false;
  }, []);

  const isSame = isEqual(autoNumberingMode, tempAutoNumberingMode);

  const items = useMemo<KSelectableListItem<AutoNumberingMode["mode"]>[]>(
    () => [
      { label: t("formBuilder.infos.modeManual"), identifier: "manual" },
      {
        label: t("formBuilder.infos.modeAutonumber"),
        identifier: "autonumber",
      },
    ],
    [t],
  );

  const onSelect = useCallback((newValue: AutoNumberingMode["mode"][]) => {
    const [first] = newValue;
    if (!first) {
      return;
    }
    if (first === "manual") {
      setTempAutoNumberingMode({ mode: "manual" });
    } else {
      setTempAutoNumberingMode({ mode: "autonumber", prefix: "" });
    }
  }, []);

  const onChangePrefix = useCallback((newPrefix: string) => {
    setTempAutoNumberingMode((mode) => {
      if (mode.mode !== "autonumber") {
        return mode;
      }
      return { ...mode, prefix: newPrefix };
    });
  }, []);

  const onSave = useCallback(() => {
    setAutoNumberingMode(tempAutoNumberingMode);
  }, [setAutoNumberingMode, tempAutoNumberingMode]);

  return (
    <>
      <div>
        <KSelectionList
          noPadding
          items={items}
          selected={[tempAutoNumberingMode.mode]}
          setSelected={onSelect}
          isMultiple={false}
        />
        {tempAutoNumberingMode.mode === "autonumber" && (
          <>
            <KInput
              onFocus={onFocus}
              onBlur={onBlur}
              label={t("formBuilder.infos.prefixPlaceholder")}
              value={tempAutoNumberingMode.prefix}
              onChangeText={onChangePrefix}
            />
            <Text size="MEDIUM" color="FONT_LOW_EMPHASIS">
              {t("formBuilder.infos.prefixDisclaimer")}
            </Text>
          </>
        )}
        {!isSame && (
          <div className={classes.save}>
            <Button
              accessibilityLabel={t("save")}
              text={t("save")}
              size="SMALL"
              onPress={onSave}
            />
          </div>
        )}
      </div>
    </>
  );
};

export { SchemaNamingStrategy };
