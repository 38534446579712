import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { KDialog } from "@kraaft/shared/components/kDialog/kDialog";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { Api } from "@kraaft/shared/core/services/api/api";
import { Firestore } from "@kraaft/shared/core/services/firestore/firestore";
import { useLiveQuery } from "@kraaft/shared/core/utils/useLiveQuery";
import { Button, Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";

function getDropdownId(
  providerId: string,
  subConfigId: string | undefined,
): string {
  if (!subConfigId) {
    return providerId;
  }
  return `${providerId}@${subConfigId}`;
}

function splitDropdownId(value: string | undefined) {
  const [providerId, subConfigId] = value ? value.split("@") : [];
  return { providerId, subConfigId };
}

export const SettingsSSO = ({ pool }: { pool: Pool }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { data: providers } = useLiveQuery(
    Firestore.subscribeToIdentityProviders,
    [],
  );

  const options = useMemo(
    () =>
      providers
        ? providers.flatMap((provider) => {
            const providerItem = {
              value: provider.id,
              label: `${provider.name}`,
            };
            const subConfigItems = provider.subConfigs?.map((subConfig) => ({
              value: getDropdownId(provider.id, subConfig.id),
              label: `${provider.name} > ${subConfig.name}`,
            }));
            return [providerItem, ...(subConfigItems || [])];
          })
        : [],
    [providers],
  );

  const selectedId = pool?.identityProviderId
    ? getDropdownId(pool.identityProviderId, pool.identityProviderSubConfigId)
    : undefined;

  const selected = useMemo(
    () => (selectedId ? [selectedId] : undefined),
    [selectedId],
  );

  const selectedName = useMemo(
    () => options?.find((o) => o.value === selectedId)?.label,
    [options, selectedId],
  );

  const handleSelectionChange = async (newValue: string[] | undefined) => {
    const { providerId, subConfigId } = splitDropdownId(
      newValue ? newValue[0] : undefined,
    );

    await Api.setPoolIdentityProvider({
      poolId: pool.id,
      providerId,
      subConfigId,
    });
  };

  return (
    <Box ph="S16" pv="S16" row items="center">
      <Text weight="bold" size="BODY">
        {t("sso.settings.label")}
      </Text>
      <Box pl="S8">
        <Text color="FONT_LOW_EMPHASIS" size="BODY">
          {selectedName || t("unassigned")}
        </Text>
      </Box>
      <Box pl="S16">
        <Button
          variant="SECONDARY"
          size="SMALL"
          accessibilityLabel={t("edit")}
          text={t("edit")}
          onPress={() => setOpen(true)}
        />
      </Box>
      <KDialog
        open={open}
        onClose={() => setOpen(false)}
        title={t("sso.settings.dialog.title")}
        content={
          <Box>
            <Text color="FONT_LOW_EMPHASIS" size="BODY">
              {t("sso.settings.dialog.description")}
            </Text>
            <Box mt="S16">
              <KDropdown
                withEmptyRow
                selectedItemIds={selected}
                placeholder={t("sso.settings.dialog.placeholder")}
                items={options}
                onSelectionChange={handleSelectionChange}
              />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};
