import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { NameAndEmojiWithTip } from "@kraaft/shared/components/createRoom/roomName/variants/nameAndEmojiWithTip";
import { NameOnly } from "@kraaft/shared/components/createRoom/roomName/variants/nameOnly";
import { NameWithTip } from "@kraaft/shared/components/createRoom/roomName/variants/nameWithTip";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { getTemporaryRoomDefaultStatus } from "@kraaft/shared/core/modules/room/roomUtils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api/api";
import { dismissNativeKeyboard } from "@kraaft/shared/core/utils/platformUtils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { useEnterRoomNameVariant } from "@kraaft/shared/core/utils/useEnterRoomNameVariant";
import { useEnsureOnline } from "@kraaft/shared/core/utils/useNetwork";

interface Props {
  poolId: string;
  labelIds: string[] | undefined;
  onRoomCreated: (roomId: string) => void;
}

export const RoomNameLayout = (props: Props) => {
  const { labelIds, onRoomCreated, poolId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(selectCurrentUserId);
  const [isLoading, startLoading, stopLoading] = useBooleanState(false);
  const roomSchema = useSelector(selectRoomSchema(poolId));

  const variant = useEnterRoomNameVariant();

  useTrackPage("NewRoom");

  const createRoom = useEnsureOnline(
    async (title: string, emoji?: string) => {
      if (userId === undefined) {
        return;
      }
      if (title.trim().length === 0) {
        return;
      }

      startLoading();
      dismissNativeKeyboard();

      try {
        const status = roomSchema && getTemporaryRoomDefaultStatus(roomSchema);

        const newRoomId = await Api.createRoomFromRecord({
          poolId,
          members: [userId],
          emoji,
          recordProperties: KSchemaConversion.toRawProperties({
            [KSchemaRemarkableColumns.ROOM_TITLE]: {
              columnType: KColumnType.shortText,
              value: title,
            },
            [KSchemaRemarkableColumns.ROOM_LABELS]: {
              columnType: KColumnType.selectMultiple,
              value: labelIds,
            },
            [KSchemaRemarkableColumns.ROOM_RESPONSIBLE]: {
              columnType: KColumnType.user,
              value: [userId],
            },
            [KSchemaRemarkableColumns.ROOM_STATUS]: {
              columnType: KColumnType.selectSingle,
              value: status ? [status] : undefined,
            },
          }),
        });

        trackEvent({
          eventName: "Create Conversation",
          origin: "add_button",
          room_id: newRoomId,
          room_name: title,
          category_count: labelIds?.length ?? 0,
        });

        onRoomCreated(newRoomId);
      } catch (e) {
        console.error("Error while creating room", e);
        dispatch(showError({ title: t("internalError"), message: e.message }));
      }
      stopLoading();
    },
    [
      dispatch,
      labelIds,
      onRoomCreated,
      poolId,
      roomSchema,
      startLoading,
      stopLoading,
      t,

      userId,
    ],
  );

  if (variant === "name-with-tip") {
    return <NameWithTip onSubmit={createRoom} isLoading={isLoading} />;
  }
  if (variant === "name-and-emoji-with-tip") {
    return <NameAndEmojiWithTip onSubmit={createRoom} isLoading={isLoading} />;
  }
  if (variant === "name-only") {
    return <NameOnly onSubmit={createRoom} isLoading={isLoading} />;
  }
};
