import {
  KSchemaElement,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";

type OrphanElementGroup = {
  type: "orphan";
  headerIndice: number;
  editorsKeys: string[];
};

type SectionElementGroup = {
  type: "section";
  sectionKey: string;
  headerIndice: number;
  editorsKeys: string[];
};

export type ElementGroup = OrphanElementGroup | SectionElementGroup;

export const gatherElementsFromSection = (
  section: KSchemaSection,
  shouldAddElement: (element: KSchemaElement) => boolean,
  aggregatedGroups: ElementGroup[] = [],
) => {
  const groups: ElementGroup[] = [];
  const currentGroupType: ElementGroup["type"] =
    section.key === KSchemaUtils.rootSectionKey ? "orphan" : "section";
  let currentGroupEditorsKeys: ElementGroup["editorsKeys"] = [];

  function bundleCurrentElementGroup(): ElementGroup {
    const allGroups = [...aggregatedGroups, ...groups].at(-1);
    const headerIndice = allGroups ? allGroups.headerIndice + 2 : 0;

    switch (currentGroupType) {
      case "orphan": {
        return {
          type: currentGroupType,
          headerIndice,
          editorsKeys: currentGroupEditorsKeys,
        };
      }
      case "section": {
        return {
          type: currentGroupType,
          headerIndice,
          editorsKeys: currentGroupEditorsKeys,
          sectionKey: section.key,
        };
      }
    }
  }

  function flush() {
    if (currentGroupEditorsKeys.length === 0) {
      return;
    }

    groups.push(bundleCurrentElementGroup());
    currentGroupEditorsKeys = [];
  }

  for (const element of KSchemaUtils.orderedSectionElements(section)) {
    if (!shouldAddElement(element)) {
      continue;
    }
    if (element.elementType === "section") {
      flush();
      groups.push(
        ...gatherElementsFromSection(element, shouldAddElement, groups),
      );
      continue;
    }
    currentGroupEditorsKeys.push(element.key);
  }
  flush();

  return groups;
};
