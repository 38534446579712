import { compact } from "lodash";
import { assert, Dictionary } from "ts-essentials";
import { call, put, select, spawn, takeEvery } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { attachMessageSagas } from "@kraaft/shared/core/modules/message/attach/attachMessageSagas";
import { selectMessageInRoom } from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import { addOfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageActions";
import { ForwardedOfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageState";
import { handleReactionsToMessageSaga } from "@kraaft/shared/core/modules/message/react/reactSagas";
import { acknowledgeReadingMessageSaga } from "@kraaft/shared/core/modules/message/sagas/acknowledgeReadingMessage";
import { deleteOptimisticMessageSaga } from "@kraaft/shared/core/modules/message/sagas/deleteOptimisticMessage.saga";
import { editMessageTextSaga } from "@kraaft/shared/core/modules/message/sagas/editMessageText.saga";
import { sendMessageSagas } from "@kraaft/shared/core/modules/message/send";
import { PoolActions } from "@kraaft/shared/core/modules/pool/poolActions";
import {
  selectCurrentPoolId,
  selectPoolLocations,
} from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectRoom } from "@kraaft/shared/core/modules/room/roomSelectors";
import { selectors as userSel } from "@kraaft/shared/core/modules/user";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { navigationService } from "@kraaft/shared/core/services/navigation/provider";
import { uuid } from "@kraaft/shared/core/utils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

import * as actions from "./messageActions";
import * as messageSel from "./messageSelectors";
import * as MessageTypes from "./messageState";
import * as utils from "./messageUtils";

export function* messageSagas() {
  yield* spawn(sendMessageSagas);
  yield* spawn(editMessageTextSaga);
  yield* spawn(deleteOptimisticMessageSaga);
  yield* spawn(attachMessageSagas);
  yield* spawn(acknowledgeReadingMessageSaga);

  yield* takeEvery(
    actions.forwardMessageSelection,
    forwardMessageSelectionSaga,
  );
  yield* takeEvery(actions.fetchUnknownMessages, fetchUnknownMessagesSaga);

  yield* takeEvery(actions.reactToMessage, handleReactionsToMessageSaga);
  yield* takeEvery(
    actions.MessageActions.updateAttachmentGeolocation,
    updateAttachmentGeolocation,
  );
}

// Forked Sagas
function* fetchAllMessagesForForwarding({ roomId }: { roomId: string }) {
  const selectedMessageIds = yield* select(
    messageSel.selectMessageSelectionAsArray(roomId, undefined),
  );
  const messageList = yield* select(
    messageSel.selectMessageSelectionAsMessageList(roomId, undefined),
  );

  const nonLoadedMessageIds = selectedMessageIds.filter(
    (messageId) => !messageList.some((message) => message.id === messageId),
  );

  let allMessages = messageList;
  if (nonLoadedMessageIds.length > 0) {
    const newMessages = yield* fetchAndAddUnknownMessages({
      roomId,
      messageIds: nonLoadedMessageIds,
    });
    if (!newMessages) {
      throw new Error("Could not fetch non-loaded messages");
    }
    const newMessageList = compact(
      Object.entries(newMessages).map(([msgId]) => {
        return newMessages[msgId];
      }),
    ).filter(utils.isUserMessage);
    allMessages = utils.orderMessages([...messageList, ...newMessageList]);
  }

  if (allMessages.length !== selectedMessageIds.length) {
    throw new Error("Messages could only be partially fetched");
  }
  return allMessages;
}

function* forwardMessageSelectionSaga(
  action: ReturnType<typeof actions.forwardMessageSelection>,
) {
  const { roomId, destRoomId, preserveCaptions } = action.payload;

  const selectionSource = yield* select(
    messageSel.selectMessageSelectionSource(roomId),
  );

  try {
    const currentUserId = yield* select(selectCurrentUserId);
    assert(currentUserId, "no currentUserId");

    const allMessages = yield* fetchAllMessagesForForwarding({ roomId });

    if (selectionSource === "photoGallery") {
      trackEvent({
        eventName: "Forward Bulk Photos",
        room_id: roomId,
        photo_count: allMessages.length,
      });
    }

    if (selectionSource === "documentGallery") {
      // TODO TrackDataForwardBulkDocuments
      // TODO NNG : will this be used ?
    }

    const sendableMessageIds: { id: string; optimisticId: string }[] = [];

    /** @INFO: We reverse the array because we send the messages one by one (needs to be changed at some point) */
    for (const message of allMessages.reverse()) {
      const initialMessage: MessageTypes.UserMessage = message ?? {};
      const optimisticId = uuid();

      sendableMessageIds.push({
        id: message.id,
        optimisticId,
      });

      const offlineMessage: ForwardedOfflineMessage = {
        type: "forwarded",
        senderId: currentUserId,
        roomId: destRoomId,
        requestId: uuid(),
        writtenAt: new Date().getTime(),
        optimisticId,
        initialRoomId: roomId,
        initialMessage,
        preserveCaptions,
      };

      yield* put(addOfflineMessage(offlineMessage));
    }

    yield* put(actions.deselectMessage({ roomId, all: true }));
    yield* put(
      actions.setMessageSelectionProperties({
        roomId,
        selectionType: "forward",
        status: undefined,
      }),
    );

    const currentPoolId = yield* select(selectCurrentPoolId);
    const destRoom = yield* select(selectRoom(destRoomId));
    const destRoomPoolId = destRoom?.poolId;

    if (destRoomPoolId !== undefined && currentPoolId !== destRoomPoolId) {
      const locations = yield* select(selectPoolLocations);
      const destLocation = locations?.find(
        (location) => location.poolId === destRoomPoolId,
      );

      if (destLocation) {
        yield* put(PoolActions.switchPool(destLocation));
      }
    }

    navigationService.afterForwardingMessageTo(destRoomId);
  } catch (error) {
    yield* put(showError({ title: i18n.t("forwardMessagesError") }));
    yield* put(
      actions.setMessageSelectionProperties({
        roomId,
        selectionType: "forward",
        status: undefined,
      }),
    );
  }
}

function* getChunkUnknownMessages(
  messageIds: string[],
  roomId: string,
  chunkSize: number,
) {
  const currentUserId = yield* select(userSel.selectCurrentUserId);
  const baseLength = messageIds.length;
  let result: Dictionary<MessageTypes.Message> = {};

  for (let index = 0; index < baseLength; index += chunkSize) {
    const chunk = messageIds.slice(index, index + chunkSize);
    const response = yield* call(() =>
      Firestore.getUnknownMessages(chunk, roomId, currentUserId, false),
    );

    result = { ...result, ...response };
  }

  return result;
}

function* fetchAndAddUnknownMessages({
  messageIds,
  roomId,
}: {
  messageIds: string[];
  roomId: string;
}) {
  if (messageIds.length === 0) {
    return undefined;
  }

  const messages: Dictionary<MessageTypes.Message> =
    yield getChunkUnknownMessages(messageIds, roomId, 10);

  yield* put(
    actions.addUnknownMessages({
      messages,
      roomId,
      initialMessageIds: messageIds,
    }),
  );

  return messages;
}

export function* updateAttachmentGeolocation({
  payload: { roomId, messageId, geolocation },
}: ReturnType<typeof actions.MessageActions.updateAttachmentGeolocation>) {
  const message = yield* select(selectMessageInRoom(roomId, messageId));

  if (!message) {
    return;
  }

  const attachment = utils.hasGeolocatedAttachment(message)
    ? message.attachment
    : undefined;

  if (!attachment) {
    return;
  }

  try {
    yield* put(
      actions.MessageStateActions.setAttachmentGeolocation({
        roomId,
        messageId,
        geolocation,
      }),
    );

    yield* call(Api.updateMessageAttachmentGeolocation, {
      roomId,
      messageId,
      geolocation,
    });
  } catch (error) {
    yield* put(showError({ title: i18n.t("updateGeolocationFailed") }));
    yield* put(
      actions.MessageStateActions.setAttachmentGeolocation({
        roomId,
        messageId,
        geolocation: attachment.geolocation,
      }),
    );
  }
}

export function* fetchAndAddUnknownMessage({
  messageId,
  roomId,
}: {
  messageId: string;
  roomId: string;
}) {
  const result = yield* fetchAndAddUnknownMessages({
    messageIds: [messageId],
    roomId,
  });
  return result?.[messageId];
}

function* fetchUnknownMessagesSaga(
  action: ReturnType<typeof actions.fetchUnknownMessages>,
) {
  const { messageIds, roomId } = action.payload;
  const messagesBeingLoaded = yield* select(
    messageSel.selectAreMessagesBeingLoaded(roomId, messageIds),
  );
  const notBeingLoadedMessageIds = messageIds.filter(
    (_, idx) => !messagesBeingLoaded[idx],
  );

  yield* put(
    actions.fetchingUnknownMessages({
      messageIds: notBeingLoadedMessageIds,
      roomId,
    }),
  );

  yield* fetchAndAddUnknownMessages({
    messageIds: notBeingLoadedMessageIds,
    roomId,
  });
}
