import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "react-native";

import { AttachmentColumnMode } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/attachment";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { useHandleChangeSingleSelectHelper } from "@kraaft/web/src/components/dropdown/kDropdownUtils";
import { ColumnHeaderEditorProps } from "@kraaft/web/src/components/modularTable/components/types";
import { Toggle } from "@kraaft/web/src/components/toggle";

import { useStyles } from "./attachmentColumnEditor.styles";

const AttachmentColumnEditor = (
  props: ColumnHeaderEditorProps<KColumnType.attachment>,
) => {
  const { columnDefinition, setColumnDefinition } = props;

  const { t } = useTranslation();
  const styles = useStyles();

  const items = useMemo<FilteredListItem[]>(() => {
    return [
      { value: "list", label: t("documents") },
      { value: "gallery", label: t("photos") },
    ];
  }, [t]);

  const handleChange = useHandleChangeSingleSelectHelper(
    (newValue: AttachmentColumnMode | undefined) => {
      if (newValue === undefined || columnDefinition === undefined) {
        return;
      }

      setColumnDefinition({
        ...columnDefinition,
        mode: newValue,
        photoQualityHD:
          newValue === "gallery" ? columnDefinition.photoQualityHD : false,
      });
    },
    [columnDefinition, setColumnDefinition],
  );

  const onSetPhotoQuality = useCallback(
    (val: boolean) => {
      if (columnDefinition === undefined) {
        return;
      }

      setColumnDefinition({
        ...columnDefinition,
        photoQualityHD: columnDefinition.mode === "gallery" ? val : false,
      });
    },
    [columnDefinition, setColumnDefinition],
  );

  const selectedItemId = columnDefinition?.mode ?? "list";

  return (
    <>
      <div className={styles.container}>
        <KDropdown
          items={items}
          placeholder={t("subOption")}
          selectedItemIds={[selectedItemId]}
          onSelectionChange={handleChange}
        />
      </div>
      {selectedItemId === "gallery" && (
        <div>
          <Toggle
            value={props.columnDefinition?.photoQualityHD ?? false}
            setValue={onSetPhotoQuality}
          />
          <Text>{t("hdPhotos")}</Text>
        </div>
      )}
    </>
  );
};

export { AttachmentColumnEditor };
