import { useMemo } from "react";

import {
  DifferenceBackground,
  DifferenceBackgroundProps,
} from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceBackground";
import { DifferenceText } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceText";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordValueOfType } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { dateFormatter } from "@kraaft/shared/core/services/dateFormatter/dateFormatter.provider";

interface DateDifferenceProps extends DifferenceBackgroundProps {
  value:
    | ModularRecordValueOfType<KColumnType.date>
    | ModularRecordValueOfType<KColumnType.automatedCreatedAt>;
  schemaColumn:
    | KSchemaColumn<KColumnType.date>
    | KSchemaColumn<KColumnType.automatedCreatedAt>
    | undefined;
}

export const DateDifference = ({
  value,
  schemaColumn,
  method,
}: DateDifferenceProps) => {
  const label = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    let formattedDate = dateFormatter.formatDateShort(value);

    if (schemaColumn?.displayTime) {
      formattedDate += ` ${dateFormatter.formatTimeShort(value)}`;
    }

    return formattedDate;
  }, [schemaColumn?.displayTime, value]);

  return label !== undefined ? (
    <DifferenceBackground method={method}>
      <DifferenceText>{label}</DifferenceText>
    </DifferenceBackground>
  ) : null;
};
