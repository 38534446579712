import { eventChannel } from "redux-saga";
import { call, put, select, spawn, take } from "typed-redux-saga/macro";

import { actions } from "@kraaft/shared/core/modules/app";
import { forceFirestoreLongPolling } from "@kraaft/shared/core/modules/app/appActions";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { selectCurrentUserAuth } from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { firestore } from "@kraaft/shared/core/services/firestore/sdk";
import { RootState } from "@kraaft/shared/core/store";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

export function* appSagas() {
  yield* spawn(forceFirestoreLongPollingSaga);
  yield* spawn(wakeUpSaga);

  const channel = yield* call(createAppStateChannel);
  while (true) {
    const newState: boolean = yield* take(channel);
    yield* put(actions.changeAppState(newState));
  }
}

function createAppStateChannel() {
  return eventChannel((emit: (input: boolean) => void) => {
    const windowFocused = {
      value: true,
    };

    function handleWindowBlur() {
      windowFocused.value = false;
      handleChange();
    }

    function handleWindowFocus() {
      windowFocused.value = true;
      handleChange();
    }

    function handleChange() {
      emit(document?.hidden !== true && windowFocused.value);
    }

    window.addEventListener("blur", handleWindowBlur);
    window.addEventListener("focus", handleWindowFocus);
    document.addEventListener("visibilitychange", handleChange);

    return () => {
      window.removeEventListener("blur", handleWindowBlur);
      window.removeEventListener("focus", handleWindowFocus);
      document.removeEventListener("visibilitychange", handleChange);
    };
  });
}

function* forceFirestoreLongPollingSaga() {
  yield* take(forceFirestoreLongPolling);

  console.warn("Restarting Firestore with long polling");
  yield firestore().terminate();
  firestore().settings({
    experimentalForceLongPolling: true,
    experimentalAutoDetectLongPolling: false,
  });

  const userAuth = yield* select(selectCurrentUserAuth);
  if (userAuth) {
    yield* put(UserActions.userConnectedToFirebase({ userAuth }));
  }
}

function* wakeUpSaga() {
  yield waitFor((state: RootState) => state.app.navigationSwitch !== "loading");
  yield Api.wakeUp();
}
