import { createSelector } from "@reduxjs/toolkit";

import { selectInviteState } from "@kraaft/shared/core/modules/app/appSelector";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { TrackingEvent } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

export const selectTrackDataViewLoginPhone = createSelector(
  selectInviteState,
  (inviteState): TrackingEvent<"View Login Phone"> | undefined => {
    if (inviteState.status !== "valid") {
      return {
        eventName: "View Login Phone",
        origin: "organic",
      };
    }

    switch (inviteState.type) {
      case "pool":
        return {
          eventName: "View Login Phone",
          origin: "pool_invitation",
          pool_id: inviteState.poolId,
        };
      case "room":
        return {
          eventName: "View Login Phone",
          origin: "room_invitation",
          room_id: inviteState.roomId,
        };
    }
  },
);

export const selectTrackDataViewHome = createSelector(
  selectCurrentPool,
  selectCurrentUser,
  (currentPool, currentUser): TrackingEvent<"View Home"> | undefined => {
    if (!currentPool || !currentUser) {
      return undefined;
    }

    return {
      eventName: "View Home",
      pool_id: currentPool.id,
      pool_name: currentPool.name,
      pool_count: Object.keys(currentUser.pools ?? {}).length,
    };
  },
);
