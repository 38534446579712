import { eventChannel } from "redux-saga";
import { assert } from "ts-essentials";
import { call, put, take, takeEvery } from "typed-redux-saga/macro";

import { updateUserUnreadPools } from "@kraaft/shared/core/modules/pool/poolActions";
import { UserUnreadPools } from "@kraaft/shared/core/modules/pool/poolState";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { setDocumentTitle } from "@kraaft/shared/core/utils";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

function createUserUnreadPoolsChannel(userId: string) {
  return eventChannel<UserUnreadPools[]>((emit) =>
    Firestore.subscribeToUserUnreadPools(userId, emit),
  );
}

function* receiveUserUnreadPools(userUnreadPools: UserUnreadPools[]) {
  if (userUnreadPools.length > 1) {
    console.warn(
      "User unread pools should not have more than 1 document per userId",
    );
  }

  const userUnreadPool = userUnreadPools[0];
  if (userUnreadPool) {
    yield* put(updateUserUnreadPools(userUnreadPool));

    const notifCounter = Object.values(userUnreadPool.unreadPools).reduce(
      (totalCount, poolNotifCount) => totalCount + poolNotifCount,
      0,
    );

    setDocumentTitle(
      i18n.t(notifCounter ? "webTitleWithCounter" : "webTitle", {
        notifCounter,
      }),
    );
  }
}

export function* subscribeToUserUnreadPoolsSaga() {
  const userId = yield* waitFor(selectCurrentUserId);

  if (!userId) {
    assert(userId, "User id should be present");
  }

  const channel = yield* call(createUserUnreadPoolsChannel, userId);

  yield* takeEvery(channel, receiveUserUnreadPools);
  yield* take(UserActions.userDisconnectedFromFirebase);
  channel.close();
}
