import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, View } from "react-native";
import { useSelector } from "react-redux";

import { compactMap } from "@kraaft/helper-functions";
import { DialogActionButtons } from "@kraaft/shared/components/dialogActionButtons/dialogActionButtons";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { selectCurrentContextAtLeastAdminOrSuperadmin } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { SchemaTemplate } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";
import { useRealtimeSchemaTemplates } from "@kraaft/shared/core/modules/schemaTemplate/useSchemaTemplates";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";
import { Portal } from "@kraaft/ui";

import { styles } from "./selectChecklistTemplate.styles";

interface SelectChecklistTemplateBaseProps {
  onClose?: () => void;
  selected: string[];
  onCreateNewList?: () => void;
  forceSelection?: boolean;
  multiple: boolean;
  actionButtonsPortalHost?: string;
  filter?: string;
  noPadding?: boolean;
}

interface SelectChecklistTemplateMultipleProps
  extends SelectChecklistTemplateBaseProps {
  multiple: true;
  onSelectTemplates: () => void;
  onChange: (templates: SchemaTemplate[]) => void;
}

interface SelectChecklistTemplateSingleProps
  extends SelectChecklistTemplateBaseProps {
  multiple: false;
  onSelectTemplate: () => void;
  onChange: (templates: SchemaTemplate) => void;
}

export type SelectChecklistTemplateProps =
  | SelectChecklistTemplateSingleProps
  | SelectChecklistTemplateMultipleProps;

export const SelectChecklistTemplate = ({
  actionButtonsPortalHost,
  onCreateNewList,
  selected,
  forceSelection,
  filter,
  noPadding,
  ...props
}: SelectChecklistTemplateProps) => {
  const templates = useRealtimeSchemaTemplates();
  const { t } = useTranslation();

  const canCreateChecklistTemplate =
    useSelector(selectCurrentContextAtLeastAdminOrSuperadmin) &&
    onCreateNewList !== undefined;

  const onTemplateSelection = useCallback(
    (newSelected: string[]) => {
      const selectedTemplates = compactMap(newSelected, (s) =>
        templates?.find((template) => template.id === s),
      );
      if (props.multiple) {
        props.onChange(selectedTemplates);
      } else {
        const single = selectedTemplates[0];
        if (single) {
          props.onChange(single);
        }
      }
    },
    [props, templates],
  );

  const items = useMemo<KSelectableListItem[]>(() => {
    const filteredTemplates = filter
      ? templates.filter((template) =>
          normalizeTextForSearch(template.name).includes(
            normalizeTextForSearch(filter),
          ),
        )
      : templates;
    return filteredTemplates.map(({ id, name }) => ({
      label: name,
      identifier: id,
    }));
  }, [filter, templates]);

  const actionButtons = useMemo(
    () => (
      <DialogActionButtons
        primaryButton={{
          onPress: props.multiple
            ? props.onSelectTemplates
            : props.onSelectTemplate,
          accessibilityLabel: t("confirm"),
          text: t("confirm"),
          disabled: forceSelection && selected.length === 0,
        }}
        secondaryButton={
          canCreateChecklistTemplate
            ? {
                onPress: onCreateNewList,
                accessibilityLabel: t("createNewTemplateList"),
                text: t("createNewTemplateList"),
              }
            : undefined
        }
      />
    ),
    [
      props,
      t,
      selected.length,
      forceSelection,
      canCreateChecklistTemplate,
      onCreateNewList,
    ],
  );

  return (
    <View style={styles.rootContainer}>
      <View style={styles.list}>
        <KSelectionList
          id="select-checklist-template-list"
          leftIcon="check-done-01"
          items={items}
          isMultiple={props.multiple}
          setSelected={onTemplateSelection}
          selected={selected}
          noPadding={noPadding}
        />
      </View>
      {actionButtonsPortalHost ? (
        <Portal hostname={actionButtonsPortalHost}>{actionButtons}</Portal>
      ) : (
        <>
          {actionButtons}
          <SafeAreaView />
        </>
      )}
    </View>
  );
};
