import { createContext } from "react";
import { noop } from "ts-essentials";

export interface RoomListContextType {
  isScrollingDown: boolean;
  setScrollingDown: (value: boolean) => void;
}

export const RoomListContext = createContext<RoomListContextType>({
  isScrollingDown: false,
  setScrollingDown: noop,
});
