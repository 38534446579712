import { useTranslation } from "react-i18next";

import { DetailedActionCard } from "@kraaft/shared/components/detailedActionCard";
import { useDialog } from "@kraaft/shared/components/kDialog/context";
import { ColorStyle } from "@kraaft/ui";
import { AddSchemaFromPaperDialogContent } from "@kraaft/web/src/views/schemasView/addSchemaFromPaper";

import docImage from "./assets/004-doc.png";

export const AddSchemaFromPaperActionCard = () => {
  const { t } = useTranslation();

  const { openDialog, closeDialog } = useDialog();

  const handlePress = () => {
    openDialog(<AddSchemaFromPaperDialogContent onClose={closeDialog} />);
  };

  return (
    <DetailedActionCard
      title={t("schemasView.addDialog.fromPaper.title")}
      subtitle={t("schemasView.addDialog.fromPaper.subtitle")}
      titleColor={ColorStyle.ACTION_CTA}
      image={docImage}
      onPress={handlePress}
    />
  );
};
