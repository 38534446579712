import { createSelector } from "@reduxjs/toolkit";
import { compact, includes, keyBy, memoize } from "lodash";
import { Dictionary } from "ts-essentials";

import { constants } from "@kraaft/shared/constants/constants";
import { MessageTypes } from "@kraaft/shared/core/modules/message";
import {
  selectMessageDataForRoom,
  selectMessageInRoom,
} from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import { groupSimilarLogs } from "@kraaft/shared/core/modules/message/messageGrouping";
import {
  isDeleted,
  isImageMessage,
  isLogMessage,
  isMessageAwaitingSend,
  isUserMessage,
  isVideoMessage,
  orderMessages,
  sortMessages,
} from "@kraaft/shared/core/modules/message/messageUtils";
import { selectCurrentPoolFreemiumState } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectFolderSchemas } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { selectBlockedUserIds } from "@kraaft/shared/core/modules/user/userSelectors";
import { RootState } from "@kraaft/shared/core/store";
import { getEmptyArray, lodashKeyResolver } from "@kraaft/shared/core/utils";
import { IconName } from "@kraaft/ui";

import {
  Message,
  MessageSelectionSource,
  MessageSelectionType,
  MessageType,
} from "./messageState";

export const selectAllMessageRooms = ({ message }: RootState) => message.rooms;

export const selectMessageRoom = (roomId: string) => (state: RootState) =>
  selectAllMessageRooms(state)[roomId];

export const selectLastRoomMessage = (roomId: string) =>
  createSelector(selectMessages(roomId), (messages) =>
    messages ? messages[0] : undefined,
  );

export const selectMessagesWithType = memoize(
  <T extends MessageType>(messageTypes: T[], roomId: string) => {
    return createSelector(
      selectMessageDataForRoom(roomId),
      (messageRoom): Extract<Message, { type: T }>[] => {
        const messages = Object.values(messageRoom || {}).filter(
          (message) =>
            !isDeleted(message) && includes(messageTypes, message.type),
        ) as Extract<Message, { type: T }>[];
        return orderMessages(messages, true);
      },
    );
  },
  lodashKeyResolver,
);

export const selectMessageSelection = memoize(
  (
    roomId: string | undefined,
    selectionSource: MessageSelectionSource | undefined,
  ): ((state: RootState) => MessageTypes.MaybeMessageSelection) => {
    const defaultValue = {
      selectionSource: undefined,
      selectionType: undefined,
      selection: {},
    };

    return (state: RootState) => {
      if (roomId) {
        const selection = state.message.messageSelectionByRoom?.[roomId];
        if (
          selection &&
          (!selectionSource || selection?.selectionSource === selectionSource)
        ) {
          return selection;
        }
      }

      return defaultValue;
    };
  },
  lodashKeyResolver,
);

export const selectIsInSelectionFlow = (data: {
  roomId: string | undefined;
  selectionSource: MessageSelectionSource | undefined;
  selectionType: MessageSelectionType;
}) => {
  return (state: RootState) => {
    const selection = selectMessageSelection(
      data.roomId,
      data.selectionSource,
    )(state);
    return (
      selection.selectionType === data.selectionType &&
      selection.status === "inDialogFlow"
    );
  };
};

export const selectMessageSelectionSource = memoize(
  (roomId: string | undefined) =>
    createSelector(
      selectMessageSelection(roomId, undefined),
      (messageSelection) => messageSelection.selectionSource,
    ),
);

export const selectMessageSelectionType = memoize(
  (
    roomId: string | undefined,
    selectionSource: MessageSelectionSource | undefined,
  ) =>
    createSelector(
      selectMessageSelection(roomId, selectionSource),
      (messageSelection) => messageSelection.selectionType ?? "notDefined",
    ),
  lodashKeyResolver,
);

export const selectMessageSelectionStatus = memoize(
  (
    roomId: string | undefined,
    selectionSource: MessageSelectionSource | undefined,
  ) =>
    createSelector(
      selectMessageSelection(roomId, selectionSource),
      (messageSelection) => {
        if (messageSelection.selectionType) {
          return messageSelection.status;
        }
        return undefined;
      },
    ),
  lodashKeyResolver,
);

export const selectMessageSelectionCount = memoize(
  (
    roomId: string | undefined,
    selectionSource: MessageSelectionSource | undefined,
  ) =>
    createSelector(
      selectMessageSelectionAsArray(roomId, selectionSource),
      (messageSelectionArray) => messageSelectionArray.length,
    ),
  lodashKeyResolver,
);

export const selectMessageSelectionAsArray = memoize(
  (
    roomId: string | undefined,
    selectionSource: MessageSelectionSource | undefined,
  ) =>
    createSelector(
      selectMessageSelection(roomId, selectionSource),
      (messageSelection) =>
        Object.entries(messageSelection.selection)
          .filter(([_, selected]) => selected === true)
          .map(([msgId]) => msgId),
    ),
  lodashKeyResolver,
);

export const selectMessageSelectionAsMessageList = memoize(
  (
    roomId: string | undefined,
    selectionSource: MessageSelectionSource | undefined,
  ) =>
    createSelector(
      selectMessageSelection(roomId, selectionSource),
      selectMessageDataForRoom(roomId ?? ""),
      (messageSelection, messages) =>
        messages
          ? orderMessages(
              compact(
                Object.entries(messageSelection.selection)
                  .filter(([_, selected]) => selected === true)
                  .map(([msgId]) => messages[msgId]),
              ).filter(isUserMessage),
            )
          : [],
    ),
  lodashKeyResolver,
);

export const selectMessageSelectionContainsCaption = memoize((roomId: string) =>
  createSelector(
    selectMessageSelection(roomId, undefined),
    selectMessageDataForRoom(roomId),
    (messageSelection, messages) => {
      return Object.entries(messageSelection.selection)
        .filter(([_, selected]) => selected === true)
        .some(([msgId]) => {
          const message = messages?.[msgId];

          return (
            (isImageMessage(message) || isVideoMessage(message)) &&
            Boolean(message.attachment.caption)
          );
        });
    },
  ),
);

const EMPTY_ARRAY: MessageTypes.Message[] = [];
const EMPTY_DICT: Record<string, MessageTypes.Message> = {};

function isMessageBlocked(
  message: MessageTypes.Message,
  blockedUserIds: Dictionary<true>,
): boolean {
  return blockedUserIds[message.senderId] !== undefined;
}

export const selectMessages = memoize(
  (roomId: string): ((state: RootState) => Message[]) =>
    createSelector(
      selectMessageDataForRoom(roomId),
      selectBlockedUserIds,
      (messages, blockedUserIds) => {
        if (!messages) {
          return EMPTY_ARRAY;
        }
        const messageList = Object.values(messages);

        if (messageList.length === 0) {
          return EMPTY_ARRAY;
        }

        const filteredMessages = messageList.filter(
          (message) => !isMessageBlocked(message, blockedUserIds),
        );

        return orderMessages(filteredMessages);
      },
    ),
);

export const selectUnorderedMessages = memoize((roomId: string) =>
  createSelector(
    selectMessageDataForRoom(roomId),
    selectBlockedUserIds,
    (messages, blockedUserIds) => {
      if (!messages) {
        return EMPTY_DICT;
      }

      return Object.entries(messages).reduce<Record<string, Message>>(
        (acc, [key, value]) => {
          if (!isMessageBlocked(value, blockedUserIds)) {
            acc[key] = value;
          }
          return acc;
        },
        {},
      );
    },
  ),
);

const selectLastCurrentUserSentMessageId = memoize((roomId: string) =>
  createSelector([selectMessages(roomId)], (messages) => {
    return messages.find(
      (message) =>
        "isReply" in message &&
        !message.isReply &&
        !isMessageAwaitingSend(message),
    )?.id;
  }),
);

export const selectIsLastCurrentUserSentMessage =
  (roomId: string, messageId: string) => (state: RootState) =>
    selectLastCurrentUserSentMessageId(roomId)(state) === messageId;

export interface Module {
  schemaId: string;
  moduleId: string | undefined;
  icon: IconName;
}

export const selectMessageModules = memoize(
  (message: MessageTypes.Message): ((state: RootState) => Module[]) =>
    createSelector(selectFolderSchemas, (schemas) => {
      const modules = !isLogMessage(message)
        ? compact(
            Object.entries(message.modularObjects ?? [])
              .filter(([_, content]) => content.isFlagged === true)
              .map(([schemaId, content]) => {
                const schema = schemas[schemaId];
                if (schema) {
                  const moduleId = (Object.keys(content.modularFolders) ??
                    [])[0];

                  return {
                    moduleId,
                    schemaId,
                    icon: KSchemaUtils.getSchemaIconName(schema.icon),
                  } as const;
                }
                return undefined;
              }),
          )
        : getEmptyArray<Module>();

      return modules;
    }),
  (message: MessageTypes.Message) => {
    const modules = !isLogMessage(message)
      ? Object.entries(message.modularObjects ?? {}).reduce(
          (acc, [key, value]) => acc + (value.isFlagged ? `${key}-` : ""),
          "",
        )
      : [];
    return `${message.id}-${modules}`;
  },
);

export const selectReplyingSourceMessage = (roomId: string) =>
  createSelector(
    (state: RootState) =>
      selectMessageInRoom(
        roomId,
        state.message.rooms[roomId]?.replyingSourceMessageId || "",
      )(state),
    (message) => {
      if (!message) {
        return null;
      }
      return message;
    },
  );

export const selectAreMessagesBeingLoaded = (
  roomId: string,
  messageIds: string[],
) =>
  createSelector(selectMessageRoom(roomId), (room) =>
    messageIds.map((messageId) => room?.loadingStates[messageId]),
  );

export const selectFreemiumFriendlyMessages = memoize((roomId: string) =>
  createSelector(
    selectCurrentPoolFreemiumState,
    selectUnorderedMessages(roomId),
    (freemium, messages) => {
      if (!freemium) {
        return {
          messages: keyBy(
            groupSimilarLogs(sortMessages(messages)),
            (m) => m.id,
          ),
          didStripMessages: false,
        };
      }
      const now = Date.now();
      const dayMs = 1000 * 60 * 60 * 24;
      const oldestDateAuthorized =
        now - constants.FREEMIUM_CONTENT_LIMIT_DAYS * dayMs;

      let stripped = false;
      const strippedMessages = Object.entries(messages).reduce<
        Record<string, Message>
      >((acc, [key, value]) => {
        if (value.createdAt.getTime() >= oldestDateAuthorized) {
          acc[key] = value;
        } else {
          stripped = true;
        }
        return acc;
      }, {});

      return {
        messages: keyBy(
          groupSimilarLogs(sortMessages(strippedMessages)),
          (m) => m.id,
        ),
        didStripMessages: stripped,
      };
    },
  ),
);
