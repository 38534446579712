import { call, put } from "typed-redux-saga/macro";

import { showSuccess } from "@kraaft/shared/core/modules/alert/alertActions";
import { Workflow } from "@kraaft/shared/core/modules/workflows/types";
import { WorkflowStateActions } from "@kraaft/shared/core/modules/workflows/workflowActions";
import {
  selectAllWorkflows,
  selectOptimisticState,
} from "@kraaft/shared/core/modules/workflows/workflowSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import {
  createOperation,
  createOptimisticReduxBundle,
} from "@kraaft/shared/core/utils/optimistic/createReduxBundle";

export const {
  actions: WorkflowOptimisticActions,
  delaySnapshot: WorkflowDelaySnapshot,
  operations: WorkflowOptimisticOperations,
  reducer: WorkflowOptimisticReducer,
  saga: WorkflowOptimisticSaga,
  selectors: WorkflowOptimisticSelector,
} = createOptimisticReduxBundle(
  "Workflow",
  [
    createOperation<
      Workflow,
      "update",
      { updatedWorkflow: Partial<Workflow>; withToast?: boolean }
    >({
      type: "update",
      optimistic: (data, { payload: { updatedWorkflow } }) => ({
        ...data,
        ...updatedWorkflow,
      }),
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.editWorkflow, {
          id: payload.targetId,
          ...payload.updatedWorkflow,
        });

        if (payload.withToast) {
          yield* put(showSuccess({ title: i18n.t("modificationSaved") }));
        }

        return updatedAt;
      },
    }),

    createOperation<Workflow, "delete", unknown>({
      type: "delete",
      optimistic: () => null,
      call: function* ({ payload }) {
        const { deletedAt } = yield* call(Api.deleteWorkflow, {
          id: payload.targetId,
        });

        return deletedAt;
      },
    }),
  ],
  (workflow: Workflow) => workflow.id,
  WorkflowStateActions.set,
  selectOptimisticState,
  selectAllWorkflows,
);
