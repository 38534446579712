import { useCallback, useEffect, useRef, useState } from "react";
import { useToggle } from "react-use";

interface Props {
  playOnClick?: boolean;
  isStatic?: boolean;
}

const useVideoPlayerContext = (props: Props) => {
  const { playOnClick = true, isStatic } = props;

  const [shouldShowControls, setShowControls] = useState(false);
  const [isPlaying, togglePlaying] = useToggle(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video !== null) {
      video.addEventListener("pause", () => togglePlaying(false));
      video.addEventListener("ended", () => togglePlaying(false));
      video.addEventListener("playing", () => togglePlaying(true));
      return () => {
        video.removeEventListener("pause", () => togglePlaying(false));
        video.removeEventListener("ended", () => togglePlaying(false));
        video.removeEventListener("playing", () => togglePlaying(true));
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onVideoClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (isStatic) {
        return;
      }
      const video = videoRef.current;
      if (isPlaying && video !== null) {
        video.pause();
      } else if (video !== null && playOnClick) {
        video.play().catch(console.error);
      }
      setShowControls(true);
    },
    [isStatic, isPlaying, playOnClick],
  );

  return {
    videoRef,
    onVideoClick,
    shouldShowControls,
    isPlaying,
  };
};

export { useVideoPlayerContext };
