import { useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useBooleanState } from "@kraaft/helper-hooks";
import {
  FillModularFolderFromGalleryFlow,
  FillModularFolderFromPhotoGalleryFlowType,
} from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/fillModularFolderGalleryFlow/fillModularFolderFromGalleryFlow";
import { attachToFolderFromSelection } from "@kraaft/shared/core/modules/message/attach/attachMessageActions";
import { selectModularFolderTitle } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectRoomIsExternal } from "@kraaft/shared/core/modules/room/selectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { useLoader } from "@kraaft/shared/core/utils/useLoader";

interface UseModularDetailConversationAttachementContextProps {
  roomId: string;
  schemaId: string;
  modularFolderId: string;
  type: FillModularFolderFromPhotoGalleryFlowType["type"];
}

export function useModularDetailConversationAttachementContext({
  roomId,
  schemaId,
  modularFolderId,
  type,
}: UseModularDetailConversationAttachementContextProps) {
  const dispatch = useDispatch();
  const isRoomExternal = useSelector(selectRoomIsExternal(roomId));

  const modularFolderTitle = useSelector(
    selectModularFolderTitle(modularFolderId),
  );

  const columnRef = useRef<KSchemaColumn<KColumnType.attachment>>();
  const [
    isImportFromConversationFlowOpen,
    openImportFromConversationFlow,
    closeImportFromConversationFlow,
  ] = useBooleanState(false);

  const { loaderId, loading } = useLoader({
    prefix: `fillModularFolderFromConversation-${type}`,
    onSuccess: closeImportFromConversationFlow,
  });

  const importFromConversation = useCallback(() => {
    if (!columnRef.current) {
      return;
    }
    dispatch(
      attachToFolderFromSelection({
        roomId,
        columnKey: columnRef.current.key,
        schemaId,
        folderId: modularFolderId,
        loaderId,
        folderTitle: modularFolderTitle,
        isRoomExternal,
      }),
    );
  }, [
    dispatch,
    roomId,
    schemaId,
    modularFolderId,
    loaderId,
    modularFolderTitle,
    isRoomExternal,
  ]);

  const renderAttachToFolderModals = useMemo(() => {
    return (
      <FillModularFolderFromGalleryFlow
        roomId={roomId}
        type={type}
        open={isImportFromConversationFlowOpen}
        onClose={closeImportFromConversationFlow}
        onAttachFromConversation={importFromConversation}
        isLoading={loading}
      />
    );
  }, [
    closeImportFromConversationFlow,
    importFromConversation,
    isImportFromConversationFlowOpen,
    loading,
    roomId,
    type,
  ]);

  const openImportFromConversationModal = useCallback(
    (column: KSchemaColumn<KColumnType.attachment>) => {
      columnRef.current = column;
      openImportFromConversationFlow();
    },
    [openImportFromConversationFlow],
  );

  return { openImportFromConversationModal, renderAttachToFolderModals };
}
