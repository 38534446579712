import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import { useSelector } from "react-redux";

import { ActionCard } from "@kraaft/shared/components/actionCard";
import { EditRoomSharepointLink } from "@kraaft/shared/components/editRoomSharepointLink";
import { SlidePanel } from "@kraaft/shared/components/slidePanel";
import { selectOnePool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentUserAtLeastAdmin } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { ConversationDetailsPanel } from "@kraaft/shared/core/services/navigation/navigationParams";

interface Props {
  room: Room;
  goToUrl: (url: string) => void;
  openedPanel: ConversationDetailsPanel | undefined;
  setOpenedPanel: (
    panelIdentifier: ConversationDetailsPanel | undefined,
  ) => void;
  portalHostname?: string;
  setDisplayed: () => void;
  onPanelClose?: () => void;
}

const SharepointSection = ({
  room,
  goToUrl,
  openedPanel,
  setOpenedPanel,
  portalHostname,
  setDisplayed,
  onPanelClose,
}: Props) => {
  const { t } = useTranslation();

  const pool = useSelector(selectOnePool(room.poolId));
  const canShowSharepoint = useSelector(
    selectCurrentUserAtLeastAdmin(room.poolId),
  );

  const sharepointUrl = room?.external?.microsoftStorage?.url;

  const isPoolSynchronizedWithSharepoint = Boolean(
    pool?.external?.microsoftStorage?.isSynchronized,
  );
  const isRoomSynchonizedWithSharepoint = Boolean(
    room?.external?.microsoftStorage?.url,
  );

  const isWeb = Platform.OS === "web";
  const displaySharepoint =
    isPoolSynchronizedWithSharepoint &&
    ((canShowSharepoint && isWeb) || isRoomSynchonizedWithSharepoint);

  useEffect(() => {
    if (displaySharepoint) {
      setDisplayed();
    }
  }, [setDisplayed, displaySharepoint]);

  const handleSharepointPress = useCallback(() => {
    if (isWeb && canShowSharepoint) {
      setOpenedPanel(ConversationDetailsPanel.sharepointConnector);
      return;
    }

    if (sharepointUrl) {
      goToUrl(sharepointUrl);
    }
  }, [isWeb, canShowSharepoint, sharepointUrl, setOpenedPanel, goToUrl]);

  return displaySharepoint ? (
    <>
      <ActionCard
        id="ide2e-sharepoint"
        text={t("menuSharepoint")}
        iconName="share-07"
        action={handleSharepointPress}
        showBracket
      />
      {isWeb && canShowSharepoint && (
        <SlidePanel
          name="RoomSharepoint"
          portalHostname={portalHostname}
          title={t("detailsRouteSharepoint")}
          open={openedPanel === ConversationDetailsPanel.sharepointConnector}
          onClose={onPanelClose}
        >
          <EditRoomSharepointLink
            roomId={room.id}
            poolId={room.poolId}
            goToUrl={goToUrl}
          />
        </SlidePanel>
      )}
    </>
  ) : null;
};

export { SharepointSection };
