import { makeStyles } from "@mui/styles";

import { ColorStyle, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  rowContainer: {
    padding: "8px 12px",
    cursor: "grab",

    "&:hover": {
      backgroundColor: ColorStyle.ACTION_HOVERED,
      borderRadius: Radius.SMALL,
    },

    "&:hover .rowActionsContainer": {
      opacity: 1,
      pointerEvents: "all",
    },
  },
  rowContainerDisableHover: {
    "&:hover": {
      backgroundColor: "unset",
      borderRadius: "unset",
    },
    "&:hover .rowActionsContainer": {
      opacity: 0,
      pointerEvents: "none",
    },
  },

  rowDraggableWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rowDragIconContainer: {
    marginRight: Spacing.S8,
  },
});
