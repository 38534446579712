import React from "react";
import { makeStyles } from "@mui/styles";

import { ColorStyle } from "@kraaft/ui";

export const WebOnlyUnderlineHover = ({
  children,
  color,
}: React.PropsWithChildren<{ color?: string }>) => {
  const classes = useStyles({ color });
  return <span className={classes.container}>{children}</span>;
};

export const useStyles = makeStyles({
  container: ({ color }: { color?: string }) => {
    return {
      "&:hover": {
        textDecoration: "underline",
        textDecorationColor: color || ColorStyle.FONT_LOW_EMPHASIS,
      },
    };
  },
});
