import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { DeleteConfirmationDialogContent } from "@kraaft/shared/components/deleteConfirmationDialogContent";
import { RoomStateActions } from "@kraaft/shared/core/modules/room/roomActions";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { Api } from "@kraaft/shared/core/services/api/api";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";

export const DeleteRoomDialogContent = (props: {
  room: Room;
  onClose: () => void;
}) => {
  const { room, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigationService();
  const roomTitle =
    getExistingRoomRecordField(room.record.properties, "title") ?? "";

  async function deleteRoom() {
    await Api.deleteRoom({ roomId: room.id });
    dispatch(RoomStateActions.roomDeleted({ roomId: room.id }));
    navigation.navigate("Home");
  }

  const sucessMessage = t("deleteRoom.success", { title: roomTitle });
  const deleteButton = t("deleteRoom.delete");
  const title = t("deleteRoom.title");
  const subtitle = t("deleteRoom.subtitle", { title: roomTitle });
  const inputLabel = t("deleteRoom.input");

  return (
    <DeleteConfirmationDialogContent
      onDelete={deleteRoom}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      inputLabel={inputLabel}
      expectedValue={roomTitle}
      deleteButton={deleteButton}
      successMessage={sucessMessage}
      context="deleteRoom"
    />
  );
};
