import { useSelector } from "react-redux";

import { EntryLayout } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayout";
import { ModularFolderUnlocked } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.state";
import { selectUsername } from "@kraaft/shared/core/modules/user/userSelectors";

interface UnlockedEntryProps {
  entry: ModularFolderUnlocked;
}

export const UnlockedEntry = ({ entry }: UnlockedEntryProps) => {
  const initiatorName = useSelector(selectUsername(entry.by));

  return (
    <EntryLayout
      date={entry.at}
      iconName="lock-01"
      iconColor="YELLOW_EARTH"
      i18nKey="history.entryLabel.unlocked"
      i18nValues={{ initiatorName }}
    />
  );
};
