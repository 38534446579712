import { useCallback, useMemo } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

import {
  DroppableComponentProps,
  DroppableItem,
} from "@kraaft/shared/components/droppable";
import { fileHelper } from "@kraaft/shared/core/modules/file/fileHelper";

import { useStyles } from "./droppable.styles";

const Droppable = (props: DroppableComponentProps) => {
  const {
    children,
    id,
    acceptType,
    acceptSystemFile = true,
    shallow,
    style,
  } = props;

  const classes = useStyles();

  const accept = useMemo(() => {
    const result: string[] = [];
    if (acceptSystemFile) {
      result.push(NativeTypes.FILE);
    }
    if (acceptType === undefined) {
      return result;
    }
    if (Array.isArray(acceptType)) {
      result.push(...acceptType);
    } else {
      result.push(acceptType);
    }
    return result;
  }, [acceptType, acceptSystemFile]);

  const onDrop = useCallback(
    (item: DroppableItem, monitor: DropTargetMonitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop && shallow) {
        return;
      }

      const { onDrop: onDropCallback, onDropFiles } = props;

      if (item.files) {
        onDropFiles?.(item.files.map((file) => fileHelper.fromWebFile(file)));
      } else {
        onDropCallback?.(item);
      }
    },
    [props, shallow],
  );

  const [{ canDrop, isOver }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow }),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  return (
    <div ref={drop} className={classes.container} style={style} id={id}>
      {children({ isActive, canDrop })}
    </div>
  );
};

export { Droppable };
