import { createReducer } from "@reduxjs/toolkit";
import { keyBy } from "lodash";
import { combineReducers } from "redux";

import { ReportTemplateOptimisticReducer } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.optimistic";
import { TemplateState } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import * as actions from "./reportTemplate.actions";

const initialState: TemplateState = {
  fetched: false,
  templates: {},
};

const templateReducers = createReducer(initialState, ({ addCase }) => {
  addCase(actions.ReportTemplateStateActions.set, (state, { payload }) => {
    state.fetched = true;
    state.templates = keyBy(
      payload.data,
      (reportTemplate) => reportTemplate.id,
    );
  });

  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OptimisticReducer = ReportTemplateOptimisticReducer as any;
const reducer = combineReducers({
  data: templateReducers,
  optimistic: OptimisticReducer,
});

export { reducer as reportTemplateReducers };
