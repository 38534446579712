import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { Text } from "@kraaft/ui";

import { SchemaListWithToggles } from "./schemaListWithToggles";

import { styles } from "./roomSchemaVisibilitySelection.styles";

type RoomSchemaVisibilityProps = {
  poolId: string;
  roomId: string;
  selectedSchemaIds: Set<string>;
  onShowSchema: (schemaId: string) => void;
  onHideSchema: (schemaId: string) => void;
  showModularFolderCount?: boolean;
};

const RoomSchemaVisibilitySelection = (props: RoomSchemaVisibilityProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Text size="MEDIUM" color="FONT_LOW_EMPHASIS" style={styles.helperText}>
        {t("conversationFolder.schemas.selectUsefulReports")}
      </Text>
      <SchemaListWithToggles {...props} id="room-schema-visibility-selection" />
    </View>
  );
};

export { RoomSchemaVisibilitySelection };
