import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";
import { useDispatch } from "react-redux";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { AddCompanySheet } from "@kraaft/shared/core/modules/company/components/addCompany.sheet";
import { CompanyLine } from "@kraaft/shared/core/modules/company/components/companyLine";
import { useCompanies } from "@kraaft/shared/core/modules/company/hooks/useCompanies";
import { Api } from "@kraaft/shared/core/services/api";
import { Button, Sheet, Spacing } from "@kraaft/ui";

export const ManageCompaniesSheet = Sheet({ web: "popup" })
  .create(({ Paper, Content, Header, Footer }) => ({ onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const companies = useCompanies();

    const handleCompanyDeletion = useCallback(
      async (companyId: string) => {
        try {
          await Api.deleteCompany({ companyId });
        } catch (e) {
          dispatch(showError({ title: t("serverError") }));
        }
      },
      [dispatch, t],
    );

    const handleChangeColor = useCallback(
      async (companyId: string, color: string) => {
        try {
          await Api.setCompanyColor({ companyId, color });
        } catch (e) {
          dispatch(showError({ title: t("serverError") }));
        }
      },
      [dispatch, t],
    );

    const { open, element } = AddCompanySheet.use({});

    return (
      <>
        <Paper>
          <Header onClose={onClose}>{t("support.manageCompanies")}</Header>
          <Content>
            {companies.map((company) => (
              <CompanyLine
                key={company.id}
                company={company}
                onChangeColor={handleChangeColor}
                onDelete={handleCompanyDeletion}
              />
            ))}
          </Content>
          <Footer>
            <Button
              style={styles.add}
              text={t("support.createCompany")}
              onPress={open}
            />
          </Footer>
        </Paper>
        {element}
      </>
    );
  })
  .withDefaults({ size: "SMALL" });

const styles = StyleSheet.create({
  add: {
    marginTop: Spacing.S8,
  },
});
