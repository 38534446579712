import { useMemo } from "react";

import { isNative } from "@kraaft/helper-functions";
import { UseGeolocatedMediaDialogParams } from "@kraaft/shared/components/geolocatedMediaDialog/useGeolocatedMediaDialog.types";
import {
  useLocationEditor,
  UseLocationEditorParams,
} from "@kraaft/shared/components/geolocationOpener/useLocationEditor";

export const useGeolocatedMediaSheet = ({
  media,
  roomId,
  source,
  portalHostname,
  onLocationChange,
}: UseGeolocatedMediaDialogParams) => {
  const context = useMemo<UseLocationEditorParams["context"]>(
    () => ({
      mediaId: media?.id,
      roomId,
      source,
    }),
    [media?.id, roomId, source],
  );

  const thumbnailUrl =
    media?.type === "image" ? media.original.downloadUrl : undefined;

  const { editLocation, LocationEditorPanel, LocationEditorSheet } =
    useLocationEditor({
      panelName: "MediaLocationEditor",
      context,
      thumbnailUrl,
      geolocation: media?.geolocation,
      portalHostname,
      onLocationChange,
    });

  const GeolocatedMediaDialog = useMemo(
    () => <>{isNative() ? LocationEditorPanel : LocationEditorSheet}</>,
    [LocationEditorSheet, LocationEditorPanel],
  );

  return {
    openEditor: editLocation,
    GeolocatedMediaDialog,
  };
};
