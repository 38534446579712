import { createContext, useContext } from "react";

export const ConversationContext = createContext({
  preview: false,
});

export function useIsInConversationPreview(): boolean {
  const context = useContext(ConversationContext);
  return context.preview;
}
