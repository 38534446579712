import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Platform, Pressable, StyleSheet, View } from "react-native";
import { chunk } from "lodash";

import { EMOJI_WIDTH } from "@kraaft/shared/components/emojiSelector/emoji.utils";
import { Color, FontSize, Spacing, Text } from "@kraaft/ui";

interface RecentEmojisSectionProps {
  emojis: string[];
  onPress(emoji: string): void;
  numColumns: number;
  gap: number;
}

export const MAX_RECENT_EMOJI_LINES = 3;

export const RecentEmojisSection = ({
  emojis,
  onPress,
  numColumns,
  gap,
}: RecentEmojisSectionProps) => {
  const { t } = useTranslation();

  const strippedEmojis = useMemo(
    () => emojis.slice(0, numColumns * MAX_RECENT_EMOJI_LINES),
    [emojis, numColumns],
  );

  return (
    <View style={styles.root}>
      <Text size="SMALL" color="FONT_LOW_EMPHASIS" style={styles.text}>
        {t("emojiSelector.recent")}
      </Text>
      <View style={styles.lines}>
        {chunk(strippedEmojis, numColumns).map((line) => (
          <View key={line.join(",")} style={[styles.emojiLine, { gap }]}>
            {line.map((emoji) => (
              <Pressable
                key={emoji}
                accessibilityLabel={emoji}
                onPress={() => onPress(emoji)}
                style={styles.emojiContainer}
              >
                <Text style={styles.emoji}>{emoji}</Text>
              </Pressable>
            ))}
          </View>
        ))}
      </View>
      <Text size="SMALL" color="FONT_LOW_EMPHASIS" style={styles.text}>
        {t("emojiSelector.emojis")}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: Spacing.S16,
  },
  text: {
    height: FontSize.SMALL,
  },
  lines: {
    marginBottom: Spacing.S8,
  },
  emojiLine: {
    flexDirection: "row",
  },
  emojiContainer: {
    width: EMOJI_WIDTH,
    height: EMOJI_WIDTH,
    justifyContent: "center",
    alignItems: "center",
  },
  emoji: {
    width: "100%",
    textAlign: "center",
    fontSize: Platform.select({ android: 25, default: 30 }),
    color: Color.BLACK,
  },
});
