import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DocumentGalleryWithSelection } from "@kraaft/shared/components/galleries/documentGallery/documentGalleryWithSelection/documentGalleryWithSelection";
import { useDocumentGallerySearch } from "@kraaft/shared/components/galleries/documentGallery/useDocumentGallerySearch";
import { SearchHeader } from "@kraaft/shared/components/searchHeader";
import { selectMiniMediasOfType } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";
import { DetailsRoute } from "@kraaft/web/src/components/detailsRoute";
import { detailsQuery } from "@kraaft/web/src/views/app/appRouter/routes";

import { useStyles } from "./documentGalleryRoute.styles";

const DocumentGalleryRoute = ({
  roomId,
  onBackButtonPress,
  isStickyPanel,
}: {
  roomId: string;
  onBackButtonPress: () => void;
  isStickyPanel?: boolean;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const medias = useSelector(selectMiniMediasOfType(roomId, ["document"]));
  const { title, handleClearSelection, isSelecting, headerCloseIcon } =
    useMediaSelectionContext({
      roomId,
      medias,
      type: "document",
    });

  const handleClose = useCallback(() => {
    handleClearSelection();
    if (!isSelecting) {
      onBackButtonPress();
    }
  }, [handleClearSelection, isSelecting, onBackButtonPress]);

  const { setSearch, filteredDocuments } = useDocumentGallerySearch(roomId);

  return (
    <DetailsRoute
      headerId="ide2e-modularFolders-header"
      path={[detailsQuery.documentGallery, detailsQuery.directory]}
      onBackButtonPress={handleClose}
      isStickyPanel={isStickyPanel}
      hideHeader
    >
      <div className={classes.root}>
        <SearchHeader
          centerHeader
          headerTitle={title}
          leftIcon={headerCloseIcon}
          onGoBackPress={handleClose}
          onFilterSet={setSearch}
          placeholder={t("search")}
        />
        <div className={classes.wrapper}>
          <DocumentGalleryWithSelection
            roomId={roomId}
            medias={filteredDocuments}
          />
        </div>
      </div>
    </DetailsRoute>
  );
};

export { DocumentGalleryRoute };
