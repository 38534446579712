import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { PhotoGalleryWithSelection } from "@kraaft/shared/components/galleries/photoGallery/photoGalleryWithSelection/photoGalleryWithSelection";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { MarkerGeoJSON } from "@kraaft/shared/components/mapController/types";
import { SlidePanel } from "@kraaft/shared/components/slidePanel";
import { MiniImageWithGeolocation } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";
import { IconName } from "@kraaft/ui";

interface Props {
  roomId: string;
  open: boolean;
  onClose: () => void;
  markers: MarkerGeoJSON<MiniImageWithGeolocation>[];
}

const PhotoGalleryPanelForMap = ({ roomId, open, onClose, markers }: Props) => {
  const { t } = useTranslation();

  const medias = useMemo(
    () => markers.map((marker) => marker.properties.marker.element.ref),
    [markers],
  );

  const { title, isSelecting, handleClearSelection } = useMediaSelectionContext(
    {
      roomId,
      medias,
      type: "image",
    },
  );

  const backAction = isSelecting ? handleClearSelection : onClose;
  const headerCloseIcon: IconName = !isSelecting ? "chevron-down" : "x-close";

  return (
    <SlidePanel
      name="MapPhotoGallery"
      open={open}
      direction="up"
      onClose={backAction}
    >
      <DefaultLayoutHeader
        headerTitle={title}
        headerLeft={
          <HeaderSide>
            <HeaderIconButton
              accessibilityLabel={t("close")}
              icon={headerCloseIcon}
              onPress={backAction}
            />
          </HeaderSide>
        }
      />
      <PhotoGalleryWithSelection roomId={roomId} medias={medias} />
    </SlidePanel>
  );
};

export { PhotoGalleryPanelForMap };
