import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

const styles = StyleSheet.create({
  scrollviewWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
  scrollContainer: {
    marginTop: Spacing.S16,
  },
  buttonContainer: {
    padding: Spacing.S16,
  },
});

export { styles };
