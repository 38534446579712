import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { formatNumber } from "@kraaft/shared/core/utils/useNumberInput";
import { useBasicCellStyles } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCell.styles";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";

export const NumberCell = ({
  value,
  column,
  isCellSelected,
}: IBasicCellProps<KColumnType.number>) => {
  const classes = useBasicCellStyles({ isCellSelected });

  if (value !== undefined) {
    const formatted = formatNumber(value, column.symbol);
    return <span className={classes.cellCursor}>{formatted}</span>;
  }

  return null;
};
