import { StyleSheet } from "react-native";

import { ColorStyle, Radius, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    padding: Spacing.S8,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: Radius.SMALL,
    alignItems: "flex-start",
  },
  text: {
    marginTop: Spacing.S8,
  },
});
