import { Button } from "@mui/material";

import { ColorStyle, Icon, IconName } from "@kraaft/ui";

import { useStyles } from "./headerButton.styles";

interface Props {
  iconName: IconName;
  callback: () => void;
}

const HeaderButton = (props: Props) => {
  const { iconName, callback } = props;
  const classes = useStyles();

  return (
    <Button
      onClick={callback}
      classes={{ root: classes.container, text: classes.innerContainer }}
    >
      <Icon name={iconName} size={20} color={ColorStyle.FONT_LOW_EMPHASIS} />
    </Button>
  );
};

export { HeaderButton };
