import { useCallback } from "react";

import { bidirectionalLog } from "@kraaft/shared/core/utils/useBidirectional/bidirectionalLog";
import {
  CursorState,
  LinkedList,
  LinkedListElement,
  useLinkedListCreation,
} from "@kraaft/shared/core/utils/useBidirectional/createLinkedLists";
import { LinkedListHelpers } from "@kraaft/shared/core/utils/useBidirectional/linkedList";

export interface UseDataLoaderProps<T> {
  linkedListAnchor: LinkedListElement | undefined;
  getIdFromItem: (item: T) => string;
  getLinkedList: () => LinkedList;
  storeLinkedList: (linkedList: LinkedList) => void;
  pageSize: number;
  fetchBeforeId: (
    id: string,
    pageSize: number,
  ) => Promise<{ items: T[]; end: boolean }>;
  fetchAfterId: (
    id: string,
    pageSize: number,
  ) => Promise<{ items: T[]; end: boolean }>;
  fetchAroundId: (
    id: string,
    pageSize: number,
  ) => Promise<
    | {
        before: T[];
        item: T;
        after: T[];
        ends: {
          before: boolean;
          after: boolean;
        };
      }
    | undefined
  >;
  fetchLast: (pageSize: number) => Promise<{ items: T[]; end: boolean }>;
}

export function useDataLoader<T>({
  fetchAfterId,
  fetchAroundId,
  fetchBeforeId,
  fetchLast,
  getIdFromItem,
  getLinkedList,
  linkedListAnchor,
  pageSize,
  storeLinkedList,
}: UseDataLoaderProps<T>) {
  const {
    createLaterLinkedList,
    createAroundLinkedList,
    createEarlierLinkedList,
    createBottomLinkedList,
  } = useLinkedListCreation(getIdFromItem, pageSize);

  const loadBeforeId = useCallback(async () => {
    if (!linkedListAnchor) {
      return;
    }
    const earliestItem = LinkedListHelpers.getEarliestAnchored(
      getLinkedList(),
      linkedListAnchor,
    );
    if (!earliestItem) {
      return;
    }
    if (earliestItem.earlierId === CursorState.FINISHED) {
      return bidirectionalLog("Reached first border, not loading");
    }

    bidirectionalLog(`Fetching before (${earliestItem.itemId})`);

    const { items, end } = await fetchBeforeId(earliestItem.itemId, pageSize);
    bidirectionalLog(`Fetched ${items.length} items`);
    const linkedList = createEarlierLinkedList(items, end, earliestItem);
    storeLinkedList(linkedList);
  }, [
    createEarlierLinkedList,
    fetchBeforeId,
    getLinkedList,
    linkedListAnchor,
    pageSize,
    storeLinkedList,
  ]);

  const loadAfterId = useCallback(async () => {
    if (!linkedListAnchor) {
      return;
    }
    const latestItem = LinkedListHelpers.getLatestAnchored(
      getLinkedList(),
      linkedListAnchor,
    );
    if (!latestItem) {
      return;
    }
    if (latestItem.laterId === CursorState.FINISHED) {
      return bidirectionalLog("Reached last border, not loading");
    }

    bidirectionalLog(`Fetching after (${latestItem.itemId})`);

    const { items, end } = await fetchAfterId(latestItem.itemId, pageSize);
    const linkedList = createLaterLinkedList(items, end, latestItem);
    storeLinkedList(linkedList);
  }, [
    createLaterLinkedList,
    fetchAfterId,
    getLinkedList,
    linkedListAnchor,
    pageSize,
    storeLinkedList,
  ]);

  const loadAroundId = useCallback(
    async (id: string) => {
      const news = await fetchAroundId(id, pageSize);
      if (!news) {
        console.warn("Fetch around id returned undefined");
        return;
      }
      const linkedList = createAroundLinkedList(news);

      storeLinkedList(linkedList);

      return { item: news.item, linkedList };
    },
    [createAroundLinkedList, fetchAroundId, pageSize, storeLinkedList],
  );

  const loadLast = useCallback(async () => {
    const { items, end } = await fetchLast(pageSize);
    const linkedList = createBottomLinkedList(items, end);

    storeLinkedList(linkedList);

    return { linkedList };
  }, [createBottomLinkedList, fetchLast, pageSize, storeLinkedList]);

  return { loadBeforeId, loadAfterId, loadAroundId, loadLast };
}
