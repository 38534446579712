import { StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { styleObject } from "@kraaft/shared/styles/panel.styles";
import {
  Color,
  ColorStyle,
  FontSize,
  PixelSize,
  Radius,
  Spacing,
} from "@kraaft/ui";

export const SEARCH_BAR_HEIGHT = isNative() ? 54 : 49;

export const styles = StyleSheet.create({
  spaceSaver: {
    height: SEARCH_BAR_HEIGHT,
  },
  PAOverlayContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Color.WHITE,
    zIndex: 1,
  },
  PAOverlaySafeContainer: {
    backgroundColor: Color.WHITE,
    flex: 1,
  },
  PATextInputContainer: {
    height: SEARCH_BAR_HEIGHT,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    ...styleObject.borderBottom,
    borderBottomColor: "transparent",
  },
  PATextInputContainerFocused: {
    borderBottomColor: styleObject.borderBottom.borderBottomColor,
  },
  PATextInput: {
    fontFamily: "Apercu",
    fontSize: FontSize.BODY,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: Radius.SMALL,
    marginBottom: 0,
    height: PixelSize.S32,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  PARightButtonStyle: {
    position: "absolute",
    right: PixelSize.S16,
  },
  PASeparator: {
    backgroundColor: ColorStyle.SEPARATOR,
    marginLeft: Spacing.S16,
  },
  PARow: {
    padding: 0,
    paddingHorizontal: Spacing.S16,
    paddingVertical: 13,
  },
  description: {
    fontFamily: "Apercu",
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  searchIcon: {
    zIndex: 1,
  },
});
