import { makeStyles } from "@mui/styles";

import { Color } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: "flex",
    backgroundColor: Color.WHITE,
    flexDirection: "column",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
});
