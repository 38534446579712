import React from "react";
import { makeStyles } from "@mui/styles";

import { ColorStyle, Radius } from "@kraaft/ui";

interface MakeStylesProps {
  height: React.CSSProperties["height"];
  width: React.CSSProperties["width"];
  borderRadius: React.CSSProperties["borderRadius"];
}

export const useStyles = makeStyles({
  container: ({ height, width, borderRadius }: MakeStylesProps) => ({
    height,
    width,
    borderRadius,
    backgroundColor: "grey",
  }),
  withAnimation: {
    animation: "$colorChange",
    animationDuration: "1s",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "linear",
  },
  textSkeleton: {
    // 0.19 is ratio between line height and font height
    margin: "0.19em 0",
    borderRadius: Radius.MINI,
  },

  "@keyframes colorChange": {
    "0%": {
      backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    },
    "100%": {
      backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    },
  },
});
