import { useCallback, useRef, useState } from "react";

import { FillDirectoryPickerHandle } from "@kraaft/shared/components/directory/fillDirectoryFlowProps";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";
import { FillModularFolderDocumentGalleryPicker } from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/fillModularFolderGalleryFlow/fillModularFolderDocumentGalleryPicker";

import { FillModularFolderPhotoGalleryPicker } from "./fillModularFolderPhotoGalleryPicker";

export interface FillModularFolderFromPhotoGalleryFlowType {
  roomId: string;
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  type: "image" | "document";
  onAttachFromConversation: () => void;
}

export const FillModularFolderFromGalleryFlow = ({
  open,
  roomId,
  onClose,
  type,
  isLoading,
  onAttachFromConversation,
}: FillModularFolderFromPhotoGalleryFlowType) => {
  const pickerRef = useRef<FillDirectoryPickerHandle>(null);

  const [title, setTitle] = useState("");

  const handleClose = useCallback(() => {
    pickerRef.current?.clearSelection();
    onClose();
  }, [onClose]);

  return (
    <KDialogFrame
      fullHeight
      size={KDialogSize.MEDIUM}
      open={open}
      onClose={handleClose}
    >
      <KDialogContent
        size={KDialogSize.MEDIUM}
        title={title}
        content={
          type === "image" ? (
            <FillModularFolderPhotoGalleryPicker
              ref={pickerRef}
              onClose={onClose}
              onTitleChanged={setTitle}
              onAttachFromConversation={onAttachFromConversation}
              roomId={roomId}
              isLoading={isLoading}
            />
          ) : (
            <FillModularFolderDocumentGalleryPicker
              roomId={roomId}
              ref={pickerRef}
              onClose={onClose}
              onTitleChanged={setTitle}
              isLoading={isLoading}
              onAttachFromConversation={onAttachFromConversation}
            />
          )
        }
      />
    </KDialogFrame>
  );
};
