import { Platform } from "react-native";
import { LDMultiKindContext } from "launchdarkly-js-client-sdk";
import { maxBy } from "lodash";

import { FeatureFlags } from "@kraaft/shared/core/modules/featureFlags/featureFlags.state";
import { UserRoleRank } from "@kraaft/shared/core/modules/pool/poolUtil";
import {
  CurrentUser,
  UserAuth,
} from "@kraaft/shared/core/modules/user/userState";

export type UserParams = {
  user: CurrentUser;
  auth: UserAuth;
} | null;

export type FeatureFlagsListener = (values: Partial<FeatureFlags>) => void;

export abstract class AbstractLaunchDarklySDK {
  protected getContext(params?: UserParams) {
    const commonContext: LDMultiKindContext = {
      kind: "multi",
      platform: {
        key: Platform.OS,
      },
    };

    if (params) {
      const identifiedContext: LDMultiKindContext = {
        ...commonContext,
        user: {
          kind: "user",
          key: params.user.id,
          createdAt: params.user.createdAt?.toISOString(),
          emailDomain: params.auth.email?.split("@")[1],
          poolIds: params.user.pools && Object.keys(params.user.pools),
          poolCount: params.user.pools?.length,
          highestRole: maxBy(
            Object.values(params.user.pools ?? {}).map((pool) => pool.role),
            (role) => UserRoleRank[role],
          ),
        },
      };

      return identifiedContext;
    }

    return commonContext;
  }

  public abstract init(): Promise<void>;
  public abstract setUser(user: UserParams): Promise<void>;
  public abstract subscribe(listener: FeatureFlagsListener): Promise<void>;
  public abstract unsubscribe(): void;
}
