import React, { Fragment, ReactNode } from "react";
import { StyleSheet, View } from "react-native";

import { filterNullableChildren } from "@kraaft/shared/core/utils/filterNullableChildren";
import { ColorStyle, Radius, Spacing } from "@kraaft/ui";

interface CompactActionGroupProps {
  children: ReactNode[];
}

const CompactActionGroup = ({ children }: CompactActionGroupProps) => {
  const maybeChildren = filterNullableChildren(
    ...children.filter(React.isValidElement),
  );

  if (!maybeChildren || maybeChildren.length === 0) {
    return null;
  }

  return (
    <View style={styles.container}>
      {maybeChildren.map((e, index, a) => (
        // biome-ignore lint : index may not change
        <Fragment key={index}>
          {e}
          {index !== a.length - 1 && <View style={styles.divider} />}
        </Fragment>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: Radius.SMALL,
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
    marginHorizontal: Spacing.S4,
  },
  divider: {
    height: StyleSheet.hairlineWidth,
    flexGrow: 1,
    marginLeft: Spacing.S24,
    marginVertical: Spacing.S4,
    backgroundColor: ColorStyle.SEPARATOR,
  },
});

export { CompactActionGroup };
