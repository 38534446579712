import { useContext, useEffect, useRef } from "react";

import { KTextInputHandle } from "@kraaft/shared/components/KTextInput";
import { AnimationContext } from "@kraaft/web/src/core/services/animationContext/animationContext";

export const useInputFocusAfterTransition = (
  inputRef: React.RefObject<KTextInputHandle>,
  shouldFocus: boolean,
) => {
  const { animationState } = useContext(AnimationContext);
  const inputHandle = inputRef.current;
  const alreadyTriggered = useRef<boolean>(false);

  useEffect(() => {
    if (
      !alreadyTriggered.current &&
      shouldFocus &&
      (animationState === "undetermined" || animationState === "entered") &&
      inputHandle
    ) {
      inputHandle?.focus();
      alreadyTriggered.current = true;
    }
  }, [animationState, inputHandle, shouldFocus]);
};
