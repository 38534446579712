import { useCallback, useMemo, useRef } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";

import { uuid } from "@kraaft/helper-functions";
import { AttachMessageSelectionFlow } from "@kraaft/shared/components/galleries/messageActionFlow/attachFlow";
import { ForwardMessageSelectionFlow } from "@kraaft/shared/components/galleries/messageActionFlow/forwardFlow";
import {
  PhotoGallery,
  PhotoGalleryProps,
} from "@kraaft/shared/components/galleries/photoGallery/photoGallery";
import { PhotoGallerySelectionFooter } from "@kraaft/shared/components/galleries/photoGallery/photoGalleryWithSelection/photoGallerySelectionFooter";
import { usePhotoGalleryWebFooterOptions } from "@kraaft/shared/components/galleries/photoGallery/photoGalleryWithSelection/usePhotoGalleryActions";
import { DEFAULT_PHOTO_GALLERY_CONSTRAINTS } from "@kraaft/shared/components/galleries/photoGallery/useSortedGallery";
import { selectMiniMediasCount } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";
import {
  ActionSheetItemProps,
  ColorStyle,
  ImperativeActionContextMenu,
  ImperativeContextMenuHandle,
  Preloader,
} from "@kraaft/ui";

import { styles } from "./photoGalleryWithSelection.styles";

interface Props extends Pick<PhotoGalleryProps, "medias"> {
  roomId: string;
  showMapButton?: boolean;
}

const PhotoGalleryWithSelection = ({
  roomId,
  medias,
  showMapButton = false,
}: Props) => {
  const { handleCloseFlow } = useMediaSelectionContext({
    medias,
    roomId,
    type: "image",
  });

  const totalMediaCount = useSelector(selectMiniMediasCount(roomId, ["image"]));
  const areMediaFiltered = totalMediaCount !== medias.length;
  const imperativeContextMenuRef = useRef<ImperativeContextMenuHandle>(null);
  const legacyOptions = usePhotoGalleryWebFooterOptions(roomId);

  const options = useMemo(() => {
    return legacyOptions.map<ActionSheetItemProps>((option) => ({
      onClick: option.onPress as () => void,
      icon: option.icon,
      label: option.label,
      destructive: option.style === "destructive",
      loading: false,
    }));
  }, [legacyOptions]);

  const handleOnRightClick = useCallback<
    NonNullable<PhotoGalleryProps["onRightClick"]>
  >(
    (virtualElement) => {
      imperativeContextMenuRef.current?.openWith(
        uuid(),
        options,
        virtualElement,
      );
    },
    [imperativeContextMenuRef, options],
  );

  return (
    <>
      <View style={styles.content}>
        {!medias && (
          <Preloader
            absoluteFill
            backgroundColor={ColorStyle.BACKGROUND_LIGHT}
          />
        )}
        <PhotoGallery
          roomId={roomId}
          medias={medias}
          imageHeights={DEFAULT_PHOTO_GALLERY_CONSTRAINTS}
          backgroundColor={ColorStyle.BACKGROUND_LIGHT}
          areMediaFiltered={areMediaFiltered}
          showMapButton={showMapButton}
          onRightClick={handleOnRightClick}
        />
      </View>
      <ImperativeActionContextMenu ref={imperativeContextMenuRef} />
      <PhotoGallerySelectionFooter roomId={roomId} />
      <AttachMessageSelectionFlow onClose={handleCloseFlow} roomId={roomId} />
      <ForwardMessageSelectionFlow onClose={handleCloseFlow} roomId={roomId} />
    </>
  );
};

export { PhotoGalleryWithSelection };
