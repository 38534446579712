import {
  HapticFeedbackType,
  IHapticFeedbackService,
} from "@kraaft/shared/core/services/haptic/types";

const HapticService: IHapticFeedbackService = {
  async renderFeedback(_type: HapticFeedbackType) {},
};

Object.freeze(HapticService);
export { HapticService };
