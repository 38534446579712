/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import moment from "moment";

import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  conditions,
  sanitizeColumnType,
} from "@kraaft/shared/core/generated/__generated/builtinsConditions";
import { executeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditions";
import {
  CompositeCondition,
  Condition,
  ConditionKey,
  CoupleConditionImplementation,
  CoupleRecordCondition,
  DraftCondition,
  MetadataCondition,
  Predicate,
  StandaloneRecordCondition,
} from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { selectAllRooms } from "@kraaft/shared/core/modules/room/selectors";
import { ColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/column";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaColumnNot,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import {
  ModularDisplayExtendedRequirements,
  ModularDisplayRequirements,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { User } from "@kraaft/shared/core/modules/user/userState";
import {
  i18n,
  ResourceKey,
  TFunction,
} from "@kraaft/shared/core/services/i18next";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";

type ConditionsEntries = [
  ConditionKey,
  { [s: string]: { translationKey: ResourceKey } },
][];

type ConditionKeyForPredicate = Record<string, ConditionKey>;

export const isConditionWithColumnKey = (
  condition: Condition,
): condition is
  | StandaloneRecordCondition
  | CoupleRecordCondition
  | DraftCondition => {
  return (
    condition.type === "couple-record" ||
    condition.type === "standalone-record" ||
    condition.type === "draft"
  );
};

export const isConditionWithPredicateValue = (
  condition: Condition,
): condition is CoupleRecordCondition | MetadataCondition => {
  return condition.type === "couple-record" || condition.type === "metadata";
};

export const isColumnAvailableFilter = (
  column: KSchemaColumn,
): column is KSchemaColumnNot<
  KColumnType.geolocation | KColumnType.roomMembers | KColumnType.roomName
> => {
  return ![
    KColumnType.geolocation,
    KColumnType.roomMembers,
    KColumnType.roomName,
  ].includes(column.type);
};

export const getFilterOptions = (
  column: KSchemaColumn | undefined,
): {
  conditionKeyForPredicate: ConditionKeyForPredicate;
  filterOptions: FilteredListItem[];
} => {
  if (column === undefined) {
    return { conditionKeyForPredicate: {}, filterOptions: [] };
  }
  const conditionKeyForPredicate: ConditionKeyForPredicate = {};
  const filterOptions: FilteredListItem[] = [];

  const builtinCondition = conditions[sanitizeColumnType(column.type)];
  if (builtinCondition) {
    const conditionEntries = Object.entries(
      builtinCondition,
    ) as ConditionsEntries;

    for (const [conditionKey, conditionValue] of conditionEntries) {
      const predicateEntries = Object.entries(conditionValue);

      for (const [predicateKey, predicateValue] of predicateEntries) {
        filterOptions.push({
          value: predicateKey,
          label: i18n.t(predicateValue.translationKey),
        });
        conditionKeyForPredicate[predicateKey] = conditionKey;
      }
    }
  }
  return { conditionKeyForPredicate, filterOptions };
};

interface BuiltinConditionResult<C1 extends KColumnType, C2 extends KColumnType>
  extends CoupleConditionImplementation<C1, C2> {
  type: C2;
}

type D<A extends KColumnType, T extends KColumnType> = T extends KColumnType
  ? BuiltinConditionResult<A, T>
  : never;
export const getBuiltinCondition = <
  ColumnType1 extends KColumnType,
  ColumnType2 extends KColumnType,
>(
  conditionKey1: ColumnType1,
  conditionKey2: ColumnType2 | "standalone",
  predicate: Predicate,
) =>
  ({
    ...conditions[sanitizeColumnType(conditionKey1)]?.[conditionKey2]?.[
      predicate
    ],
    type: conditionKey2,
  }) as D<ColumnType1, ColumnType2> | undefined;

export function conditionAllowsMultiple(
  condition: D<KColumnType, KColumnType> | undefined,
) {
  return (
    (condition?.type === KColumnType.user &&
      condition?.typeParams?.allowMultiple) ||
    condition?.type === KColumnType.selectMultiple
  );
}

export const transferPredicateValue = (
  condition: StandaloneRecordCondition | CoupleRecordCondition | DraftCondition,
  column: KSchemaColumn | undefined,
  newColumnType: KColumnType,
  newPredicate: Predicate,
): ColumnValue => {
  const value: ColumnValue = {
    columnType: newColumnType,
    value: undefined,
  };

  if (
    column &&
    condition.type === "couple-record" &&
    condition.value.columnType === newColumnType &&
    condition.value.value
  ) {
    if (
      ((condition.value.columnType === KColumnType.selectSingle ||
        condition.value.columnType === KColumnType.selectMultiple) &&
        (newColumnType === KColumnType.selectSingle ||
          newColumnType === KColumnType.selectMultiple)) ||
      (condition.value.columnType === KColumnType.user &&
        newColumnType === KColumnType.user)
    ) {
      const oldBuiltinCondition = getBuiltinCondition(
        KColumnType[column.type],
        KColumnType[condition.value.columnType],
        condition.predicate,
      );

      const newBuiltinCondition = getBuiltinCondition(
        KColumnType[column.type],
        KColumnType[newColumnType],
        newPredicate,
      );

      const oldMultiple = conditionAllowsMultiple(oldBuiltinCondition);
      const newMultiple = conditionAllowsMultiple(newBuiltinCondition);

      if (oldMultiple !== newMultiple && !newMultiple) {
        const [firstValue] = condition.value.value;
        value.value = firstValue ? [firstValue] : undefined;

        return value;
      }
    }
    value.value = condition.value.value;
  }

  return value;
};

export const filterRooms = (
  rootSection: KSchemaSection,
  allRooms: Room[],
  condition: CompositeCondition,
) => {
  const newRooms: Room[] = [];

  for (const room of allRooms) {
    if (
      executeCondition(
        rootSection,
        { room },
        room,
        room.record.properties,
        condition,
        "room",
      )
    ) {
      newRooms.push(room);
    }
  }
  return newRooms;
};

export const filterFolders = (
  rootSection: KSchemaSection,
  rooms: { [id: string]: Room },
  allFolders: ModularFolder[],
  condition: CompositeCondition,
) => {
  const newFolders: ModularFolder[] = [];

  for (const folder of allFolders) {
    const room = rooms[folder.roomId];
    if (!room) {
      continue;
    }
    if (
      executeCondition(
        rootSection,
        { room },
        folder,
        folder.properties,
        condition,
        "folder",
      )
    ) {
      newFolders.push(folder);
    }
  }
  return newFolders;
};

export const useFilterFolders = (
  rootSection: KSchemaSection,
  allFolders: ModularFolder[],
  condition?: CompositeCondition,
) => {
  const rooms = useSelector(selectAllRooms);
  return useMemo(() => {
    if (!condition) {
      return allFolders;
    }
    return filterFolders(rootSection, rooms, allFolders, condition);
  }, [allFolders, condition, rooms, rootSection]);
};

export const useFilterRooms = (
  rootSection: KSchemaSection,
  rooms: Room[],
  condition?: CompositeCondition,
) => {
  return useMemo(() => {
    if (!condition) {
      return rooms;
    }
    return rooms.filter((room) =>
      executeCondition(
        rootSection,
        { room },
        room,
        room.record.properties,
        condition,
        "room",
      ),
    );
  }, [condition, rooms, rootSection]);
};

export function getFilterValue(
  column: KSchemaColumn,
  condition: CoupleRecordCondition,
  users: Record<string, User>,
) {
  switch (condition.value.columnType) {
    case KColumnType.selectSingle:
    case KColumnType.selectMultiple: {
      if (
        column?.type !== KColumnType.selectSingle &&
        column?.type !== KColumnType.selectMultiple
      ) {
        return null;
      }
      return `${condition.value.value
        ?.map((id) => column?.options?.[id]?.label)
        .join(", ")}`;
    }
    case KColumnType.automatedCreatedBy:
    case KColumnType.user: {
      return `${condition.value.value
        ?.map((id) => users[id]?.username)
        .join(", ")}`;
    }
    case KColumnType.automatedCreatedAt:
    case KColumnType.date: {
      if (condition.value.value) {
        return moment(condition.value.value).format("L");
      }
      return null;
    }
    case KColumnType.checkbox:
      return condition.value.value ? "✅" : "⬜";
    default:
      return `${condition.value.value?.toString()}`;
  }
}

export function getConditionAsString(
  columns: Record<string, KSchemaColumn>,
  compositeCondition: CompositeCondition,
  displayContext: ModularDisplayRequirements,
  extendedRequirements: ModularDisplayExtendedRequirements,
  t: TFunction,
) {
  const operator =
    compositeCondition.operator === "and" ? t("andFilter") : t("orFilter");

  return compositeCondition.conditions
    .map((condition) => {
      if (condition.type === "standalone-record") {
        const column = columns[condition.columnKey];
        if (!column) {
          return null;
        }
        const c = getBuiltinCondition(
          column.type,
          "standalone",
          condition.predicate,
        );
        if (!c) {
          return undefined;
        }
        return [column.name, t(c.translationKey as ResourceKey)].join(" ");
      }
      if (condition.type === "couple-record") {
        const column = columns[condition.columnKey];
        if (!column) {
          return null;
        }
        const c = getBuiltinCondition(
          column.type,
          condition.value.columnType,
          condition.predicate,
        );
        if (!c) {
          return undefined;
        }
        return [
          column.name,
          t(c.translationKey as ResourceKey),
          getFilterValue(
            column,
            condition,
            displayContext.getPoolUsers() ?? {},
          ),
        ].join(" ");
      }
      if (condition.type === "metadata") {
        return [
          t("filterTicket"),
          t("is"),
          typeof condition.value?.value === "string"
            ? getExistingRoomRecordField(
                extendedRequirements.getRoomFromRecordId(condition.value.value)
                  ?.record.properties,
                KSchemaRemarkableColumns.ROOM_TITLE,
                "",
              )
            : "",
        ].join(" ");
      }
      return undefined;
    })
    .join(`\n${operator} `);
}

export function simplifyCompositeCondition(
  condition: CompositeCondition,
): CompositeCondition {
  const [firstCondition, secondCondition] = condition.conditions;
  if (firstCondition?.type === "composite" && !secondCondition) {
    return firstCondition;
  }
  return {
    type: "composite",
    operator: condition.operator,
    conditions: condition.conditions.map((c) => {
      if (c.type === "composite") {
        return simplifyCompositeCondition(c);
      }
      return c;
    }),
  };
}

// Allows to identify condition created by hideArchivedConversation + regular filters
export function getTwoPartsCompositeCondition(condition: CompositeCondition) {
  const [firstCondition] = condition.conditions;
  if (firstCondition?.type === "composite") {
    return [firstCondition, condition] as const;
  }
  return [condition, undefined] as const;
}

export function countNonCompositeConditions(
  condition: CompositeCondition,
): number {
  return condition.conditions.reduce((acc, curr) => {
    if (curr.type === "composite") {
      return acc + countNonCompositeConditions(curr);
    }
    if (curr.type === "draft") {
      return acc;
    }
    return acc + 1;
  }, 0);
}
