import { useCallback } from "react";
import { Falsy } from "react-native";

import { InviteCollaborators } from "@kraaft/shared/components/inviteCollaborators/index";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { InvitationTargetParams } from "@kraaft/shared/core/utils/useInviteLinkMessageConfigGenerator/useInviteLinkMessageConfigGenerator.types";

type UseInvitationDialogProps =
  | (InvitationTargetParams & {
      onClose?: () => void;
    })
  | Falsy;

export function useInvitationDialog(props?: UseInvitationDialogProps) {
  const [isOpen, open, close] = useBooleanState();

  const handleClose = useCallback(() => {
    if (!props) {
      return;
    }
    props.onClose?.();
    close();
  }, [close, props]);

  if (!props) {
    return [null, open] as const;
  }

  const dialog = (
    <InviteCollaborators {...props} open={isOpen} onClose={handleClose} />
  );

  return [dialog, open] as const;
}
