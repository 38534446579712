import { useCallback, useContext, useEffect, useMemo } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { fileHelper } from "@kraaft/shared/core/modules/file/fileHelper";
import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { getModularFolderLoadingId } from "@kraaft/shared/core/modules/loaders/loaderUtils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { formatError } from "@kraaft/shared/core/utils";
import { ColorStyle, Icon, Preloader } from "@kraaft/ui";
import { ModularTableContext } from "@kraaft/web/src/components/modularTable/components/table/modularTableContext";
import { KFormatterProps } from "@kraaft/web/src/components/modularTable/components/types";
import { ErrorType } from "@kraaft/web/src/core/types/dropzoneFile";

import { AttachmentPreview } from "./attachmentPreview";

import { useBasicCellStyle } from "./basicCell.styles";

export const AttachmentCell = (
  props: KFormatterProps<KColumnType.attachment>,
) => {
  const { column, row } = props;

  const value = row.properties[column.key];
  const classes = useBasicCellStyle();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const acceptedFileTypes = useMemo<Accept>(() => {
    return { [column.mode === "gallery" ? "image/*" : ""]: [] };
  }, [column.mode]);

  const { onFilesUpload, isCellLoading, onAttachmentClick } =
    useContext(ModularTableContext);

  const isLoading = isCellLoading(
    getModularFolderLoadingId(row.id, column.key),
  );

  const sectionLockInfo = useMemo(
    () => column.columnContext?.getSectionLockInfo?.(row.properties),
    [column.columnContext, row],
  );

  function handleDropFiles(acceptedFiles: File[]) {
    if (acceptedFiles.length > 0 && !sectionLockInfo?.isLocked) {
      const files = acceptedFiles.map((file) => fileHelper.fromWebFile(file));

      onFilesUpload(row.id, column, files);
    }
  }

  function handleAttachmentClick(attachment: Attachment) {
    return () => {
      onAttachmentClick(row.id, column, attachment);
    };
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
    inputRef,
  } = useDropzone({
    onDrop: handleDropFiles,
    accept: acceptedFileTypes,
    multiple: true,
    noClick: true,
    maxSize: 100000000, // 100 MBytes
  });

  useEffect(() => {
    const fileRejection = fileRejections?.[0];

    if (fileRejection) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const firstError = fileRejection.errors[0]!;
      const error = formatError(firstError.code) as ErrorType;

      const getError = (errorType: ErrorType) => {
        switch (errorType) {
          case "FileInvalidType":
            return "importFileErrorFileInvalidTypeColumn";
          case "FileTooLarge":
            return "importFileErrorFileTooLarge";
          case "TooManyFiles":
            return "importFileErrorTooManyFiles";
          default:
            return "importFileErrorUnknown";
        }
      };

      dispatch(
        showError({
          title: t(getError(error), { fileType: fileRejection.file.type }),
        }),
      );
    }
  }, [dispatch, fileRejections, t]);

  const openFilePicker = useCallback(() => {
    inputRef?.current?.click();
  }, [inputRef]);

  const documents = value?.value ?? [];
  const firstDocuments = documents.slice(isLoading ? 1 : 0, 9);
  const hiddenDocumentsCount = documents.length - 9;

  return (
    <div
      {...getRootProps()}
      className={clsx({
        [classes.root]: true,
        [classes.rootDrop]: isDragActive && !sectionLockInfo?.isLocked,
        [classes.rootDropReject]: isDragReject && !sectionLockInfo?.isLocked,
      })}
      data-locked={sectionLockInfo?.isLocked}
    >
      <input {...getInputProps()} />
      {isLoading && (
        <div className={classes.loadingPreview}>
          {<Preloader color={ColorStyle.ACTION_CTA} size="small" />}
        </div>
      )}
      {firstDocuments.length > 0 && (
        <div className={classes.previewsContainer}>
          <div className={classes.previews}>
            {firstDocuments.map((doc) => (
              <div key={doc.id} className={classes.preview}>
                <AttachmentPreview
                  attachment={doc}
                  handleAttachmentClick={handleAttachmentClick}
                  preventEdition={column.preventEdition}
                />
              </div>
            ))}
            {hiddenDocumentsCount > 0 && (
              <div
                className={classes.morePreviews}
              >{`+${hiddenDocumentsCount}`}</div>
            )}
          </div>
        </div>
      )}
      {column.preventEdition || sectionLockInfo?.isLocked ? null : (
        <button className={classes.addButton} onClick={openFilePicker}>
          <Icon name="plus" size={20} color={ColorStyle.FONT_HIGH_EMPHASIS} />
        </button>
      )}
    </div>
  );
};
