import { AlertDialog } from "@kraaft/shared/components/alertDialog";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { PopupSize } from "@kraaft/ui/src/layout/sheet/popupSheet/popupSheet.host";

export function newConfirmationAlert({
  title,
  message,
  cancelText,
  confirmText,
  style = "destructive",
  onConfirm,
  onCancel,
  size,
}: {
  title: string;
  message?: string;
  cancelText?: string;
  confirmText?: string;
  style?: "default" | "destructive";
  onConfirm: () => void;
  onCancel?: () => void;
  size?: PopupSize;
}) {
  AlertDialog.alert(
    title,
    message,
    [
      {
        text: confirmText || i18n.t("confirm"),
        onPress: onConfirm,
        style,
      },
      {
        text: cancelText || i18n.t("cancel"),
        style: "cancel",
        onPress: onCancel,
      },
    ],
    size,
  );
}
