import { ReactNode, useCallback } from "react";
import { useSelector } from "react-redux";

import { createMeshContext, useMeshContextSetup } from "@kraaft/helper-hooks";
import {
  selectCurrentPoolId,
  selectPoolCurrencyCode,
} from "@kraaft/shared/core/modules/pool/poolSelectors";
import { ModularDisplayRequirements } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { useGetOrLoadUsers } from "@kraaft/shared/core/modules/user/useGetOrLoadUsers";
import {
  selectAllPoolUsers,
  selectCurrentUserId,
} from "@kraaft/shared/core/modules/user/userSelectors";

export const ModularDisplayRequirementsContext =
  createMeshContext<ModularDisplayRequirements>();

interface ModularDisplayRequirementsProviderProps {
  children: ReactNode;
}

export const ModularDisplayRequirementsProvider = ({
  children,
}: ModularDisplayRequirementsProviderProps) => {
  const poolId = useSelector(selectCurrentPoolId);
  const currentUserId = useSelector(selectCurrentUserId);
  const poolUsers = useSelector(selectAllPoolUsers(poolId));
  const currencyCode = useSelector(selectPoolCurrencyCode(poolId));

  const getOrLoadUsers = useGetOrLoadUsers();
  const getPoolUsers = useCallback(() => poolUsers, [poolUsers]);

  return (
    <ModularDisplayRequirementsContext.Provider
      value={useMeshContextSetup({
        poolId: poolId ?? "",
        currentUserId: currentUserId ?? "",
        getOrLoadUsers,
        getPoolUsers,
        currencyCode,
      })}
    >
      {children}
    </ModularDisplayRequirementsContext.Provider>
  );
};
