import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GooglePlacesAutocompleteRef } from "react-native-google-places-autocomplete";
import { useDispatch } from "react-redux";

import { EditPlaceAutocomplete } from "@kraaft/shared/components/editPlaceAutocomplete/editPlaceAutocomplete";
import { usePlacesAutocompleteValue } from "@kraaft/shared/components/placesAutocomplete/usePlacesAutocompleteValue";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { GeoLocation } from "@kraaft/shared/core/types";
import { formatAddress } from "@kraaft/shared/core/utils";
import { KEditorProps } from "@kraaft/web/src/components/modularTable/components/types";

import { useStyles } from "./locationEditor.styles";

export const LocationEditor = (
  props: KEditorProps<KColumnType.geolocation>,
) => {
  const { column, row, onRowChange, onClose } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const classes = useStyles();

  const defaultValue = row.properties[column.key]?.value;

  const [placesAutocompleteText, setPlacesAutocompleteText] =
    useState<string>();

  const placesAutocompleteRef = useRef<GooglePlacesAutocompleteRef>(null);

  const handleChangeLocationError = useCallback(() => {
    dispatch(
      showError({
        title: t("addressNotFoundTitle"),
        message: t("addressNotValidMessage"),
      }),
    );
  }, [dispatch, t]);

  const handleChangeLocation = useCallback(
    (newGeolocation: GeoLocation | null) => {
      onRowChange(
        {
          ...row,
          properties: {
            ...row.properties,
            ...(newGeolocation !== null
              ? {
                  [column.key]: {
                    columnType: column.type,
                    value: { ...newGeolocation },
                  } as KSchemaColumnValue,
                }
              : undefined),
          },
        },
        true,
      );

      onClose(true);
    },
    [column.key, column.type, onClose, onRowChange, row],
  );

  const [
    setPlacesAutocompleteValue,
    onPlacesAutocompleteBlur,
    setPlacesAutocompleteDefaultValue,
  ] = usePlacesAutocompleteValue(
    placesAutocompleteRef,
    handleChangeLocation,
    handleChangeLocationError,
  );

  useEffect(() => {
    const addressText = formatAddress(defaultValue, false);

    if (addressText) {
      setPlacesAutocompleteText(addressText);
      setPlacesAutocompleteDefaultValue(addressText);
      placesAutocompleteRef.current?.setAddressText(addressText);
    }
  }, [defaultValue, row.properties, setPlacesAutocompleteDefaultValue]);

  const handleBlur = useCallback(async () => {
    const textValue = placesAutocompleteText ?? "";

    onPlacesAutocompleteBlur(textValue);
  }, [onPlacesAutocompleteBlur, placesAutocompleteText]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Escape") {
        const addressText = formatAddress(defaultValue, false);

        placesAutocompleteRef.current?.setAddressText(addressText);
        onClose();
      }
    },
    [defaultValue, onClose],
  );

  const handleChangeLocationFail = useCallback(() => {
    dispatch(showError({ title: t("addressLoadFail") }));
  }, [dispatch, t]);
  return (
    <div id="places-autocomplete-container" className={classes.container}>
      <EditPlaceAutocomplete
        placesAutocompleteRef={placesAutocompleteRef}
        setPlacesAutocompleteValue={setPlacesAutocompleteValue}
        setPlacesAutocompleteText={setPlacesAutocompleteText}
        handleBlur={handleBlur}
        handleChangeLocationFail={handleChangeLocationFail}
        useKInput={false}
        useTableStyle
        autoFocus
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};
