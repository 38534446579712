import { StyleSheet } from "react-native";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const TEXT_INPUT_MAX_HEIGHT = 130;

export const nativeStylesObject = {
  hidden: { opacity: 0 },
  textInput: {
    padding: Spacing.NONE, // force 0 padding on android
    paddingTop: Spacing.NONE, // force 0 padding on iOS
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  overflowing: {}, // overriden in web
};

export const styles = StyleSheet.create(nativeStylesObject);
