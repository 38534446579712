import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";

import { Color, ColorStyle, Icon, IconName } from "@kraaft/ui";

import { styles } from "./roomBanner.styles";

export enum BannerType {
  DEFAULT = "default",
  INFO = "info",
  WARNING = "warning",
  EXTERNAL = "external",
}
interface Props {
  bannerType?: BannerType;
  floating?: boolean;
  bannerText?: string;
  bannerIconName?: IconName;
}

const RoomBanner_ = (props: Props) => {
  const {
    bannerType = BannerType.DEFAULT,
    floating,
    bannerText,
    bannerIconName,
  } = props;
  const { t } = useTranslation();

  const bannerTypes: Record<
    BannerType,
    { color: string; text: string; iconName: IconName | undefined }
  > = {
    [BannerType.DEFAULT]: {
      color: ColorStyle.ACTION_CTA,
      text: t("internalTicket"),
      iconName: undefined,
    },
    [BannerType.INFO]: {
      color: Color.BLUE_AZURE,
      text: bannerText ?? "",
      iconName: bannerIconName ?? "alert-triangle",
    },
    [BannerType.WARNING]: {
      color: Color.ORANGE_BASIC,
      text: bannerText ?? "",
      iconName: bannerIconName ?? "alert-triangle",
    },
    [BannerType.EXTERNAL]: {
      color: Color.ORANGE_BASIC,
      text: t("sharedWithClient"),
      iconName: "shake-hands" as IconName,
    },
  };

  const { color, text, iconName } = bannerTypes[bannerType];

  return (
    <View
      nativeID="banner"
      style={[
        styles.banner,
        floating && styles.floating,
        { backgroundColor: color },
      ]}
    >
      {iconName && (
        <Icon name={iconName} size="MEDIUM" color="WHITE" style={styles.icon} />
      )}
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

export const RoomBanner = React.memo(RoomBanner_);
