import { assert } from "ts-essentials";
import { put, select, takeEvery } from "typed-redux-saga/macro";

import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { selectCheckboxSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";

import * as schemaTemplateActions from "../schemaTemplateActions";
import { selectEditedSchemaTemplate } from "../schemaTemplateSelectors";
import * as actions from "./taskTemplateActions";

function* createTaskTemplate({
  payload: { name },
}: ReturnType<typeof actions.createTaskTemplate>) {
  const pool = yield* select(selectCurrentPool);
  assert(pool, "Not current pool");
  const schema = yield* select(selectCheckboxSchema(pool.id));
  assert(schema, "Did not find checkbox schema of pool");
  yield* put(
    schemaTemplateActions.createSchemaTemplate({
      name,
      schemaId: schema.id,
      poolId: pool.id,
    }),
  );
}

function* editTaskTemplateTaskName({
  payload: { taskName, index },
}: ReturnType<typeof actions.editTaskTemplateTaskName>) {
  yield* put(
    schemaTemplateActions.editSchemaTemplateModifier({
      index,
      modifier: {
        title: { value: taskName, columnType: KColumnType.shortText },
      },
    }),
  );
}

function* editTaskTemplate({
  payload: { taskNames, name },
}: ReturnType<typeof actions.editTaskTemplate>) {
  yield* put(
    schemaTemplateActions.editSchemaTemplate({
      modifiers: taskNames?.map((taskName) => ({
        title: { value: taskName, columnType: KColumnType.shortText },
      })),
      name,
    }),
  );
}

function* editTaskTemplateAddNewTask() {
  const schema = yield* select(selectEditedSchemaTemplate);
  if (!schema) {
    return;
  }
  yield* put(
    schemaTemplateActions.editSchemaTemplate({
      modifiers: [
        ...schema.modifiers,
        { title: { value: "", columnType: KColumnType.shortText } },
      ],
    }),
  );
}

export function* taskTemplateSaga() {
  yield* takeEvery(actions.createTaskTemplate, createTaskTemplate);
  yield* takeEvery(actions.editTaskTemplate, editTaskTemplate);
  yield* takeEvery(actions.editTaskTemplateTaskName, editTaskTemplateTaskName);
  yield* takeEvery(
    actions.editTaskTemplateAddNewTask,
    editTaskTemplateAddNewTask,
  );
}
