import { useSelector } from "react-redux";

import { selectRoomFilterLabelIds } from "@kraaft/shared/core/modules/filter/filterSelectors";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { Preloader } from "@kraaft/ui";
import {
  ConnectedCreateRoomFlowPanelContent,
  CreateRoomFlowPanelContentBaseProps,
} from "@kraaft/web/src/components/createRoomFlow/createRoomFlow.panelContent.connected";

export type CreateRoomFlowPanelContentProps =
  CreateRoomFlowPanelContentBaseProps;

const CreateRoomFlowPanelContent = (props: CreateRoomFlowPanelContentProps) => {
  const poolId = useSelector(selectCurrentPoolId);
  const labelIds = useSelector(selectRoomFilterLabelIds(poolId));

  if (poolId === undefined || labelIds === undefined) {
    return <Preloader />;
  }

  return (
    <ConnectedCreateRoomFlowPanelContent
      {...props}
      poolId={poolId}
      labelIds={labelIds}
    />
  );
};

export { CreateRoomFlowPanelContent };
