import { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";

import { useMeshContext } from "@kraaft/helper-hooks";
import { CollapsibleHorizontal } from "@kraaft/shared/components/animated/collapsibleHorizonal";
import { SkinToneVariant } from "@kraaft/shared/components/emojiSelector/emoji.types";
import { getSkinTone } from "@kraaft/shared/components/emojiSelector/emoji.utils";
import { EmojiSelectorContext } from "@kraaft/shared/components/emojiSelector/emojiSelector.context";
import { SkinToneElement } from "@kraaft/shared/components/emojiSelector/skinToneSelector/skinToneElement";
import { SkinToneSelectorCollapsibleContent } from "@kraaft/shared/components/emojiSelector/skinToneSelector/skinToneSelectorCollapsibleContent";

const SkinToneSelector = () => {
  const { selectedSkinToneVariant, setSelectedSkinToneVariant } =
    useMeshContext(EmojiSelectorContext);

  const [isExpanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setExpanded((oldValue) => !oldValue);
  }, []);

  const collapse = useCallback(() => {
    setExpanded(false);
  }, []);

  const handleSelect = useCallback(
    (selectedVariant: SkinToneVariant) => {
      collapse();
      setSelectedSkinToneVariant(selectedVariant);
    },
    [setSelectedSkinToneVariant, collapse],
  );

  return (
    <View style={styles.container}>
      <SkinToneElement
        skinTone={getSkinTone(selectedSkinToneVariant)}
        onPress={toggleExpanded}
      />
      <CollapsibleHorizontal open={isExpanded}>
        <SkinToneSelectorCollapsibleContent
          selectedVariant={selectedSkinToneVariant}
          onSelect={handleSelect}
        />
      </CollapsibleHorizontal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
});

export { SkinToneSelector };
