import { MentionMarker } from "@kraaft/shared/core/framework/markedText/markers/mention.marker";

export type UsernameGetter = (userId: string) => string;

export class UserMentionMarker extends MentionMarker<"user-mention"> {
  constructor(
    anchorIndex: number,
    public readonly userId: string,
    private readonly getUsernameFromUserId: UsernameGetter,
  ) {
    super(anchorIndex);
  }

  get type() {
    return "user-mention" as const;
  }

  getLength() {
    return this.getUsernameFromUserId(this.userId).length + 1;
  }

  renderText() {
    return `@${this.getUsernameFromUserId(this.userId)}`;
  }
}
