import { useTranslation } from "react-i18next";

import { Pool } from "@kraaft/shared/core/modules/pool/pool";

export function useExternalAwarePoolName(
  pool: Pool | undefined,
  isExternalInPool: boolean,
) {
  const { t } = useTranslation();
  const poolName = pool?.name ?? t("unknownTeam");

  if (isExternalInPool) {
    `${poolName} (${t("sharedWithMe")})`;
  }
  return poolName;
}
