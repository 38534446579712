import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  HoverableMenu,
  HoverableMenuItemProps,
} from "@kraaft/shared/components/menu/hoverableMenu";
import { geolocationContextHover } from "@kraaft/shared/core/modules/room/roomActions";
import { selectGeolocationContext } from "@kraaft/shared/core/modules/room/roomSelectors";
import { GeoLocation } from "@kraaft/shared/core/types";

export interface MapMenuItem extends HoverableMenuItemProps {
  geolocation: GeoLocation;
}

interface Props {
  items: MapMenuItem[];
  onPress: (id: string) => void;
}

export const MapMenu = ({ items, onPress }: Props) => {
  const dispatch = useDispatch();
  const geolocationContext = useSelector(selectGeolocationContext);
  const idToGeolocation = useMemo(
    () =>
      items.reduce(
        (acc, item) => {
          acc[item.id] = item.geolocation;

          return acc;
        },
        {} as Record<string, GeoLocation>,
      ),
    [items],
  );

  const onHover = useCallback(
    (id: string, value: boolean) => {
      const geolocation = idToGeolocation[id];
      dispatch(
        geolocationContextHover({
          hovered: value,
          id: id,
          actions: geolocation
            ? [
                {
                  type: "centerOn",
                  coords: geolocation.coords,
                },
              ]
            : [],
        }),
      );
    },
    [dispatch, idToGeolocation],
  );

  return (
    <HoverableMenu
      items={items}
      onPress={onPress}
      onHover={onHover}
      externallyHoveredId={geolocationContext.hover?.id}
    />
  );
};
