import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { KDialog } from "@kraaft/shared/components/kDialog";
import {
  KDialogConfig,
  KDialogSize,
} from "@kraaft/shared/components/kDialog/kDialogProps";
import { ROOT_DIRECTORY_ID } from "@kraaft/shared/core/modules/directory/directory";
import { DirectoryActions } from "@kraaft/shared/core/modules/directory/directoryActions";
import { selectRoomDirectories } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { RoomDirectoryTree } from "@kraaft/web/src/components/directoryTree/wrappers/roomDirectoryTree";

interface Props {
  roomId: string;
  open: boolean;
  onClose: () => void;
}

const EditDirectoryTreeDialog = (props: Props): JSX.Element => {
  const { roomId, open, onClose } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const roomDirectories = useSelector(selectRoomDirectories(roomId));

  const handleAddDirectory = useCallback(
    (name: string, parentDirectoryId?: string) => {
      dispatch(
        DirectoryActions.add({
          roomId,
          name,
          parentId: parentDirectoryId ?? ROOT_DIRECTORY_ID,
          loaderId: "",
        }),
      );
    },
    [dispatch, roomId],
  );

  const handleRemoveDirectory = useCallback(
    (directoryId: string) => {
      return new Promise<boolean>((resolve) => {
        newConfirmationAlert({
          title: t("directory.removeFolderConfirmationTitle"),
          message: t("directory.removeFolderConfirmationMessage"),
          style: "destructive",
          confirmText: t("delete"),
          onConfirm: () => {
            dispatch(
              DirectoryActions.remove({
                roomId,
                directoryId,
              }),
            );
            resolve(true);
          },
          onCancel: () => resolve(false),
        });
      });
    },
    [dispatch, roomId, t],
  );

  const handleRenameDirectory = useCallback(
    (directoryId: string, newDirectoryName: string) => {
      dispatch(
        DirectoryActions.rename({
          roomId,
          directoryId,
          newDirectoryName,
        }),
      );
    },
    [dispatch, roomId],
  );

  const handleMoveDirectory = useCallback(
    (
      directoryId: string,
      newParentDirectoryId: string,
      afterSiblingDirectoryId?: string,
    ) => {
      dispatch(
        DirectoryActions.move({
          roomId,
          directoryId,
          newParentDirectoryId,
          afterSiblingDirectoryId,
        }),
      );
    },
    [dispatch, roomId],
  );

  const content = (
    <RoomDirectoryTree
      directories={roomDirectories}
      onAddDirectory={handleAddDirectory}
      onRemoveDirectory={handleRemoveDirectory}
      onRenameDirectory={handleRenameDirectory}
      onMoveDirectory={handleMoveDirectory}
    />
  );

  const dialog: KDialogConfig = {
    title: t("directory.manageDirectories"),
    content,
    actions: [
      {
        id: "ide2e-defaultDirectoryTree-save",
        accessibilityLabel: t("ok"),
        text: t("ok"),
        onPress: onClose,
        variant: "PRIMARY",
      },
    ],
  };

  return (
    <KDialog
      open={open}
      onClose={onClose}
      size={KDialogSize.MEDIUM}
      {...dialog}
    />
  );
};

export { EditDirectoryTreeDialog };
