import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  searchBar: {
    position: "absolute",
    width: "100%",
  },
});

export { styles };
