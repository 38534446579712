import { makeStyles } from "@mui/styles";

import { isNative } from "@kraaft/helper-functions";
import { ColorStyle, FontSize } from "@kraaft/ui";

type StyleProps = {
  forceOpen: boolean;
};

export const CHECKBOX_SIZE = 32;

export const useStyles = makeStyles({
  root: {
    paddingTop: 12,
    paddingBottom: 12,
    overflow: "hidden",
  },
  wrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  wrapperLeft: ({ forceOpen }: StyleProps) => ({
    flexDirection: "row",
    justifyContent: "flex-start",
    // So that the checkboxes dodge the scrollbar
    marginRight: !isNative() ? 12 : undefined,
    // Used to hide the checkbox, icon is size CHECKBOX_SIZE, margin is 4
    marginLeft: !forceOpen ? -(CHECKBOX_SIZE + 4) : undefined,
    transition: "all 250ms",
    "&:hover": {
      marginLeft: -4,
    },
    "& > div": {
      marginRight: 4,
    },
  }),
  wrapperRight: ({ forceOpen }: StyleProps) => ({
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    "& > div": {
      opacity: !forceOpen ? 0 : 1,
    },
    "&:hover > div": {
      opacity: 1,
    },
  }),
  month: {
    fontFamily: "Apercu-Bold",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.TITLE,
  },
});
