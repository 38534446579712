import { noop } from "ts-essentials";

export interface Listener<T extends (...args: any[]) => void> {
  register(cb: T): () => void;
  trigger(...params: Parameters<T>): Promise<void>;
}

export class Listeners<T extends (...args: any) => void>
  implements Listener<T>
{
  private listeners = new Set<T>();

  clear() {
    this.listeners.clear();
  }

  register(cb: T) {
    this.listeners.add(cb);
    return () => {
      this.listeners.delete(cb);
    };
  }

  async trigger(...params: Parameters<T>) {
    for (const listener of this.listeners) {
      try {
        await listener(...params);
      } catch (e) {
        console.error(e);
      }
    }
  }
}

export class NoopListeners<T extends (...args: any[]) => void>
  implements Listener<T>
{
  register() {
    return noop;
  }

  async trigger() {}
}
