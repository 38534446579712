import { makeStyles } from "@mui/styles";

import {
  MARKER_HEIGHT,
  MARKER_WIDTH,
} from "@kraaft/shared/components/placeSelection/placeSelection.styles";

export const useStyles = makeStyles({
  mapContainer: {
    position: "relative",
    flexGrow: 1,
  },
  locationMarker: {
    transform: "translate(-50%, -100%)",
    width: MARKER_WIDTH,
    height: MARKER_HEIGHT,
  },
  editingMarker: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -100%)",
    pointerEvents: "none",
  },
});
