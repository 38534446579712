import { makeStyles } from "@mui/styles";

import { Color } from "@kraaft/ui";

export const FROZEN_INDICATOR_WIDTH = 6;

export const useStyles = makeStyles({
  container: {
    position: "relative",
    flexGrow: 1,
    maxHeight: "100%",
    maxWidth: "100%",
  },
  fullSize: {
    height: "100%",
  },

  frozenIndicator: {
    "& .rdg-cell-frozen-last::after": {
      content: '""',
      position: "absolute",
      right: FROZEN_INDICATOR_WIDTH + 1,
      top: 0,
      height: "100%",
      width: FROZEN_INDICATOR_WIDTH,
    },
  },
  displayFrozenIndicator: {
    "& .rdg-cell-frozen-last::after": {
      boxShadow: "6px 0px 0px 0px rgba(41, 41, 41, 0.15)",
    },
  },
  "@global": {
    ".rdg-table": {
      "--rdg-selection-color": Color.BLUE_VISTA,
    },
  },
});
