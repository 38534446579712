import { DuplicateSchemaSheet } from "@kraaft/web/src/views/schemasView/poolSchemaList/orderablePoolSchemaItem/duplicateSchemaPopup/duplicateSchema.sheet";
import { DuplicateSchemaPopupContent } from "@kraaft/web/src/views/schemasView/poolSchemaList/orderablePoolSchemaItem/duplicateSchemaPopup/duplicateSchemaPopup.content";

interface DuplicateSchemaPopupProps {
  schemaId: string;
  onClose: () => void;
  open: boolean;
}

export const DuplicateSchemaPopup = ({
  open,
  onClose,
  schemaId,
}: DuplicateSchemaPopupProps) => {
  return (
    <DuplicateSchemaSheet.Host size="SMALL" open={open} onClose={onClose}>
      <DuplicateSchemaPopupContent onClose={onClose} schemaId={schemaId} />
    </DuplicateSchemaSheet.Host>
  );
};
