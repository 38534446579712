import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Direction, SlideIn } from "@kraaft/shared/components/animated";
import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { IconName } from "@kraaft/ui";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";
import {
  detailsQuery,
  queries,
} from "@kraaft/web/src/views/app/appRouter/routes";

import { useStyles } from "./detailsRoute.styles";

export interface PanelContext {
  handleBack: () => void;
}

interface Props {
  headerId?: string;
  contentId?: string;
  path: detailsQuery | Array<detailsQuery>;
  title?: string;
  backIcon?: IconName;
  onBackButtonPress: () => void;
  isStickyPanel?: boolean;
  headerRight?: React.ReactNode;
  hideHeader?: boolean;
  direction?: Direction;
  querySource?: queries;
  hideBackButton?: boolean;
  children:
    | React.ReactElement
    | ((context: PanelContext) => React.ReactElement)
    | null;
}

const DetailsRoute = ({
  headerId,
  contentId,
  path,
  title,
  hideHeader,
  direction = "left",
  backIcon = "chevron-left",
  onBackButtonPress,
  headerRight,
  children,
  querySource = queries.detailScreen,
  isStickyPanel,
  hideBackButton,
}: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const query = useQueryString();
  const current = query.get(querySource);

  const isOpened = useMemo(
    () =>
      current && Array.isArray(path)
        ? (path as string[]).includes(current)
        : path === current,
    [current, path],
  );
  const [opened, setOpened] = useState(isOpened);

  useEffect(() => {
    setOpened(isOpened);
  }, [isOpened]);

  const handleBack = useCallback(() => {
    onBackButtonPress();
  }, [onBackButtonPress]);

  const renderHeader = useCallback(
    () => (
      <DefaultLayoutHeader
        id={headerId}
        headerRight={headerRight}
        headerTitle={title}
        headerLeft={
          !hideBackButton && (
            <HeaderSide>
              <HeaderIconButton
                id="ide2e-header-back"
                accessibilityLabel={t("close")}
                icon={isStickyPanel ? "x-close" : backIcon}
                onPress={handleBack}
              />
            </HeaderSide>
          )
        }
      />
    ),
    [
      headerId,
      headerRight,
      title,
      hideBackButton,
      isStickyPanel,
      backIcon,
      handleBack,
      t,
    ],
  );

  const renderChildren = useCallback(() => {
    if (typeof children === "function") {
      return children({ handleBack });
    }
    return children;
  }, [children, handleBack]);

  return (
    <SlideIn
      direction={direction}
      visible={opened}
      hideRatherThanUnmount={isStickyPanel}
      disableAnimation={isStickyPanel}
    >
      <div className={classes.slideContent} id={contentId}>
        {!hideHeader && renderHeader()}
        {renderChildren()}
      </div>
    </SlideIn>
  );
};

export { DetailsRoute };
