import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@kraaft/shared/core/store";

export const selectState = ({ permissionRequestStrategy }: RootState) =>
  permissionRequestStrategy;

export const selectCurrentSessionUserId = createSelector(
  selectState,
  (state) => state.currentSessionUserId,
);

export const selectNumberOfSession = createSelector(
  selectState,
  (state) => state.numberOfSessions,
);

export const selectNumberOfSentInvites = createSelector(
  selectState,
  (state) => state.numberOfSentInvites,
);

export const selectNumberOfSentMessages = createSelector(
  selectState,
  (state) => state.numberOfSentMessages,
);
