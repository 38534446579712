import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";

import { KInput } from "@kraaft/shared/components/input/KInput";

const cleanValue = (rawName: string) =>
  rawName
    .toLowerCase()
    .replace(/[^a-z0-9-]/g, "-")
    .replace(" ", "-")
    .replace(".", "-")
    .replace("--", "-");

interface Props {
  onChange: (value: string) => void;
  hasError: boolean;
  onSubmitEditing?: () => void;
  defaultValue?: string;
}

const LimitedInput = (props: Props) => {
  const { onChange, hasError, onSubmitEditing, defaultValue } = props;
  const [value, setValue] = useState(defaultValue ?? "");
  const { t } = useTranslation();

  const handleTextChange = useCallback(
    (rawValue: string) => {
      const newValue = cleanValue(rawValue);

      setValue(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <KInput
      label={t("newWorkspace.input")}
      value={value}
      /** https://github.com/facebook/react-native/issues/11068#issuecomment-586346549 */
      secureTextEntry={Platform.OS === "android" ? true : false}
      keyboardType={Platform.OS === "android" ? "visible-password" : undefined}
      onChangeText={handleTextChange}
      autoFocus
      error={hasError}
      onSubmitEditing={onSubmitEditing}
    />
  );
};

export { LimitedInput };
