import { EventChannel, eventChannel } from "redux-saga";
import { call, put, takeEvery } from "typed-redux-saga/macro";

import {
  UserPoolActions,
  UserPoolStateActions,
} from "@kraaft/shared/core/modules/userPool/userPool.actions";
import { UserPool } from "@kraaft/shared/core/modules/userPool/userPool.state";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { AnyUnexplained } from "@kraaft/shared/core/types";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

interface ChannelPayload {
  poolId: string;
  userPool: UserPool;
}

function createUserPoolChannel(
  poolId: string,
  userId: string,
): EventChannel<ChannelPayload> {
  return eventChannel((emit) =>
    Firestore.subscribeToUserPool(poolId, userId, (userPool) =>
      emit({ poolId, userPool }),
    ),
  );
}

function* handleReceiveUserPool(snapshot: ChannelPayload) {
  yield* put(
    UserPoolStateActions.setUserPool({
      poolId: snapshot.poolId,
      userPool: snapshot.userPool,
    }),
  );
}

export function* subscribeToUserPool(
  registerMeta: (channel: EventChannel<ChannelPayload>) => void,
  { payload }: ReturnType<(typeof UserPoolActions)["subscribeToUserPool"]>,
) {
  const channel = yield* call(
    createUserPoolChannel,
    payload.poolId,
    payload.userId,
  );
  registerMeta(channel);

  yield* takeEvery(channel, handleReceiveUserPool);
}

export function* unsubscribeFromUserPool(
  meta: EventChannel<AnyUnexplained> | undefined,
) {
  meta?.close();
}

export function* subscribeToUserPoolSaga() {
  yield takeCountedDeep(
    UserPoolActions.subscribeToUserPool,
    UserPoolActions.unsubscribeFromUserPool,
    subscribeToUserPool,
    unsubscribeFromUserPool,
    (action) => `${action.payload.userId}@${action.payload.poolId}`,
  );
}
