import { createReducer } from "@reduxjs/toolkit";
import { keyBy } from "lodash";
import { combineReducers } from "redux";

import { MapOverlayOptimisticReducer } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.optimistic";
import { MapOverlayState } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.state";
import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import * as actions from "./mapOverlay.actions";

const initialState: MapOverlayState = {
  overlays: {},
  selectedOverlays: {},
};

const mapOverlayReducers = createReducer(initialState, ({ addCase }) => {
  addCase(actions.MapOverlayStateActions.set, (state, { payload }) => {
    state.overlays = keyBy(payload.data, (mapOverlay) => mapOverlay.id);
  });

  addCase(
    actions.MapOverlayStateActions.toggleMapOverlay,
    (state, { payload }) => {
      const selectedArray = state.selectedOverlays[payload.poolId];
      if (selectedArray) {
        if (selectedArray.indexOf(payload.overlayId) === -1) {
          selectedArray.push(payload.overlayId);
        } else {
          state.selectedOverlays[payload.poolId] = selectedArray.filter(
            (id) => id !== payload.overlayId,
          );
        }
      } else {
        state.selectedOverlays[payload.poolId] = [payload.overlayId];
      }
    },
  );

  addCase(
    actions.MapOverlayStateActions.setEnabledMapOverlays,
    (state, { payload }) => {
      state.selectedOverlays[payload.poolId] = payload.enabledOverlayIds;
    },
  );

  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);
  addCase(PoolStateActions.setPoolLocation, () => initialState);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OptimisticReducer = MapOverlayOptimisticReducer as any;
const reducer = combineReducers({
  data: mapOverlayReducers,
  optimistic: OptimisticReducer,
});

export { reducer as mapOverlayReducers };
