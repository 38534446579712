import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { selectMessagesWithType } from "@kraaft/shared/core/modules/message/messageSelectors";
import { RootState } from "@kraaft/shared/core/store";

export const selectRoomCarousel = ({ room }: RootState) => room.ui.roomCarousel;

export const selectRoomCarouselData = memoize((roomId: string) =>
  createSelector(
    selectRoomCarousel,
    selectMessagesWithType(["image", "video"], roomId),
    (roomCarousel, messages) => {
      if (!roomCarousel?.show) {
        return undefined;
      }

      const initialIndex = messages.findIndex(
        (message) => message.id === roomCarousel.messageId,
      );

      return {
        messages,
        initialIndex,
      };
    },
  ),
);
