import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useSelector } from "react-redux";

import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { useDefaultModularColumnContext } from "@kraaft/shared/core/modules/schema/modularTypes/useDefaultModularColumnContext";
import {
  selectSchemaLockLookup,
  selectSchemaOrderedColumns,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Button } from "@kraaft/ui";
import { Table } from "@kraaft/web/src/components/modularTable/components/table";
import { ModularTableProps } from "@kraaft/web/src/components/modularTable/schema";
import { TableContext } from "@kraaft/web/src/components/modularTable/tableContext";
import { SimplifiedTableRecordsProps } from "@kraaft/web/src/components/simplifiedTable/simplifiedTableTypes";

import { useDeletionColumn } from "./simplifiedTableUtils";

import { styles } from "./simplifiedTable.styles";

export const SimplifiedTableRecords = ({
  onRecordsChange,
  records,
  schema,
  canAddRecord,
  canDeleteRecord,
  getNewRecord,
  forcedHeight,
  maxRows,
  ...tableProps
}: SimplifiedTableRecordsProps) => {
  const { t } = useTranslation();

  const onDelete = useCallback(
    (id: string) => {
      const newRecords = [...records];
      const index = newRecords.findIndex((r) => r.id === id);
      if (index >= 0) {
        newRecords.splice(index, 1);
        onRecordsChange(newRecords);
      }
    },
    [onRecordsChange, records],
  );

  const lockingColumns = useMemo(
    () => (schema ? KSchemaUtils.getLockingColumnsForSchema(schema) : []),
    [schema],
  );

  const deletionColumn = useDeletionColumn(onDelete, lockingColumns);

  const maxRowReached = maxRows !== undefined && records.length === maxRows;

  const addRow = useCallback(() => {
    if (!canAddRecord) {
      return;
    }
    if (maxRowReached) {
      return;
    }

    const newRecords = [...records];
    const idx = records.length;
    newRecords.push(getNewRecord?.(idx) ?? ModularRecordUtils.create(schema));
    onRecordsChange(newRecords);
  }, [
    canAddRecord,
    maxRowReached,
    records,
    getNewRecord,
    schema,
    onRecordsChange,
  ]);

  const state: ModularTableProps<ModularRecord>["tableState"] = useMemo(
    () => ({
      schema,
      rows: records,
    }),
    [schema, records],
  );

  const actions: ModularTableProps<ModularRecord>["tableActions"] = useMemo(
    () => ({
      rows: {
        onUpdate: onRecordsChange,
      },
    }),
    [onRecordsChange],
  );

  const context: ModularTableProps<ModularRecord>["attachmentContext"] =
    useMemo(
      () => ({
        isCellLoading: () => false,
        onAttachmentClick: () => false,
        onFilesUpload: () => false,
      }),
      [],
    );

  const finalContainerStyle = useMemo(
    () => [styles.tableContainer, { height: forcedHeight }],
    [forcedHeight],
  );

  const schemaLockLookup = useSelector(selectSchemaLockLookup(schema.id));
  const orderedColumns = useSelector(selectSchemaOrderedColumns(schema.id));

  const defaultColumnsContext = useDefaultModularColumnContext(
    schemaLockLookup,
    orderedColumns,
  );
  const tableContext = useMemo<TableContext>(
    () => ({
      columns: defaultColumnsContext.columns,
      records: {},
      additionalColumns: canDeleteRecord ? [deletionColumn] : [],
    }),
    [defaultColumnsContext, canDeleteRecord, deletionColumn],
  );

  return (
    <>
      <View style={finalContainerStyle}>
        <Table
          tableActions={actions}
          tableState={state}
          attachmentContext={context}
          fullSize={false}
          tableContext={tableContext}
          canCreateRow={canAddRecord}
          maxRows={maxRows}
          {...tableProps}
        />
      </View>
      {canAddRecord && (
        <View style={styles.addRow}>
          <Button
            disabled={maxRowReached}
            tooltip={
              maxRowReached
                ? t("modularFoldersMenu.maxRowReached", {
                    count: maxRows,
                  })
                : undefined
            }
            accessibilityLabel={t("add")}
            onPress={addRow}
            icon="plus"
            variant="TERTIARY"
          />
        </View>
      )}
    </>
  );
};
