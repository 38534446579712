import { useSelector } from "react-redux";

import { DetailGroup } from "@kraaft/shared/components/groupButton/detailGroup";
import { ElementsItem } from "@kraaft/shared/components/report/elementsItem";
import { SelectedFoldersState } from "@kraaft/shared/components/report/reportGenerator.connected";
import { selectModularFoldersBySchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";

interface ReportElementsProps {
  roomId: string;
  schemas: KFolderSchema[];
  selectedFolders: SelectedFoldersState;
  openSelectionPanel: (schemaId: string) => () => void;
}

const ReportElements = (props: ReportElementsProps) => {
  const { roomId, schemas, selectedFolders, openSelectionPanel } = props;

  const { folders: modularFoldersBySchemaId } = useSelector(
    selectModularFoldersBySchemaId(roomId),
  );

  return (
    <DetailGroup>
      {schemas.map((schema) => {
        const selected = selectedFolders[schema.id];
        const schemaFolders = modularFoldersBySchemaId[schema.id];

        return (
          schemaFolders && (
            <ElementsItem
              key={schema.id}
              selected={selected}
              schemaId={schema.id}
              openSelectionPanel={openSelectionPanel}
            />
          )
        );
      })}
    </DetailGroup>
  );
};

export { ReportElements };
