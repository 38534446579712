import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { ActionFooter } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { Button, Color, Spacing } from "@kraaft/ui";

interface NameOnlyProps {
  onSubmit: (title: string) => void;
  isLoading: boolean;
}

export const NameOnly = ({ onSubmit, isLoading }: NameOnlyProps) => {
  const { t } = useTranslation();
  const [newRoomTitle, setNewRoomTitle] = useState("");

  const isValidTitle = newRoomTitle.trim().length > 0;

  const handleSubmit = useCallback(() => {
    onSubmit(newRoomTitle);
  }, [newRoomTitle, onSubmit]);

  return (
    <>
      <View style={styles.content}>
        <KInput
          label={t("createRoom.roomName.nameInput")}
          value={newRoomTitle}
          onChangeText={setNewRoomTitle}
          disableAutocomplete
          autoFocus
          returnKeyType="done"
          onSubmitEditing={handleSubmit}
        />
      </View>
      <View style={styles.buttonSpacer} />
      <ActionFooter backgroundColor={Color.WHITE}>
        <Button
          accessibilityLabel={t("createRoom.roomName.createButton")}
          text={t("createRoom.roomName.validateButton")}
          disabled={!isValidTitle}
          loading={isLoading}
          onPress={handleSubmit}
        />
      </ActionFooter>
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    marginTop: Spacing.S16,
    marginHorizontal: Spacing.S16,
  },
  buttonSpacer: {
    height: Spacing.S40,
    flexGrow: 1,
    flexShrink: 1,
  },
});
