import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

export const SPACER_WIDTH = Spacing.S8;

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
  },
  scrollContainer: {
    flexGrow: 0,
    flexDirection: "row",
    paddingVertical: Spacing.S8,
  },
  arrowButton: {
    flexShrink: 0,
    justifyContent: "center",
  },
  arrowButtonLeft: {
    paddingRight: Spacing.S8,
  },
  arrowButtonRight: {
    paddingLeft: Spacing.S8,
  },
  leftSpacer: {
    paddingLeft: SPACER_WIDTH,
  },
  buttonSpacer: {
    paddingRight: SPACER_WIDTH,
  },
});
