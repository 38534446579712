import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { compact } from "lodash";

import { useMeshContext } from "@kraaft/helper-hooks";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { KSchemaElement } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { conditionalEntry } from "@kraaft/shared/core/utils";
import { SchemaBuilderContext } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

export function useConditionActionItems(
  element: KSchemaElement,
  open: () => void,
) {
  const { t } = useTranslation();

  const { editCondition } = useMeshContext(SchemaBuilderContext);

  const deleteCondition = useCallback(() => {
    editCondition(element.key, undefined);
  }, [editCondition, element.key]);

  return useMemo<ActionSheetItem[]>(
    () =>
      compact([
        conditionalEntry(
          {
            label: t("formBuilder.schema.condition.displayIf"),
            onPress: open,
          },
          Boolean(!element.condition),
        ),
        conditionalEntry(
          {
            label: t("formBuilder.schema.condition.editCondition"),
            onPress: open,
          },
          Boolean(element.condition),
        ),
        conditionalEntry(
          {
            label: t("formBuilder.schema.condition.deleteCondition"),
            onPress: deleteCondition,
          },
          Boolean(element.condition),
        ),
      ]),
    [deleteCondition, element.condition, open, t],
  );
}
