import type { Task } from "../../taskStore/task";
import type { BaseAggregate } from "../optimistic.types";
import { Builder, type get } from "./builder";

export class EditOperationBuilder<A extends BaseAggregate> extends Builder {
  state = this.init<{
    input: {};
  }>()({
    type: "custom" as const,
  });

  payload<P>(description: {
    replaceId(payload: P, oldId: string, newId: string): void;
    gatherIds(payload: P): string[];
  }) {
    return this.add<{ param: P }>()
      .add({
        replaceId: description.replaceId,
        gatherIds: description.gatherIds,
      })
      .cast();
  }

  dependsOn<D>() {
    return this.when<{ param: any }>().add<{ dependencies: D }>().cast();
  }

  augment<Augment>(
    augment: (
      payload: get<this, "input"> & get<this, "param">,
      dependencies: get<this, "dependencies">,
    ) => Augment,
  ) {
    return this.when<{ dependencies: any }>()
      .add({ augment })
      .add<{ augmented: Partial<Augment> }>()
      .cast();
  }

  creates(count: (payload: get<this, "param">) => number) {
    return this.add({ creates: count })
      .add<{
        input: { allocatedIds: string[] };
        result: { ids: string[] };
      }>()
      .cast();
  }

  expected(
    expected: (
      datas: Record<string, A>,
      payload: get<this, "input"> & get<this, "param"> & get<this, "augmented">,
    ) => void,
  ) {
    return this.when<{ param: any }>().add({ expected }).cast();
  }

  mutate<R extends get<this, "result">>(
    mutate: (
      payload: get<this, "input"> & get<this, "param">,
      task: Task,
    ) => Promise<R>,
  ) {
    return this.when<{ param: any; expected: any }>()
      .add({ mutate })
      .add<{ result: R }>()
      .cast();
  }

  static create<A extends BaseAggregate>() {
    return new this<A>().cast();
  }

  acknowledgeOn(
    acknowledgeOn: (
      datas: Record<string, A>,
      payload: get<this, "input"> & get<this, "param">,
      mutateResult: get<this, "result">,
    ) => boolean,
  ) {
    return this.when<{ mutate: any }>()
      .add({ shouldAcknowledge: acknowledgeOn })
      .cast();
  }
  getOperation() {
    return this.when<{
      param: any;
      expected: any;
      mutate: any;
    }>().build();
  }
}
