import { makeStyles } from "@mui/styles";

import { Color, Spacing } from "@kraaft/ui";

const WORKFLOW_DRAWER_SIZE = 600;

interface MakeStyleProps {
  isDrawerOpen: boolean;
}

export const useStyles = makeStyles((theme) => {
  const {
    transitions: { easing, duration, ...transitions },
  } = theme;

  return {
    root: {
      display: "flex",
      height: "100vh",

      backgroundColor: Color.WHITE,
    },
    leftContainer: {
      flex: 1,
      minWidth: 0,
      padding: `${Spacing.S24}px ${Spacing.S32}px`,
      overflow: "auto",
    },
    workflows: {
      display: "flex",
      flexDirection: "column",
    },
    rightPanelContainer: ({ isDrawerOpen }: MakeStyleProps) => ({
      display: "flex",
      width: isDrawerOpen ? WORKFLOW_DRAWER_SIZE : 0,
      transition: transitions.create(["width"], {
        easing: easing[isDrawerOpen ? "easeOut" : "sharp"],
        duration: duration[isDrawerOpen ? "enteringScreen" : "leavingScreen"],
      }),
    }),
  };
});
