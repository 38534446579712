import moment from "moment";

import { View } from "react-native";
import { useSelector } from "react-redux";
import { Dictionary } from "ts-essentials";

import { buildUserAcronym } from "@kraaft/shared/components/conversation/header/conversationTitleUtils";
import { Tooltip } from "@kraaft/shared/components/tooltip";
import { selectUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { Text } from "@kraaft/ui";

export const CompletedDescriptionContainer = ({
  completedBy,
  completedAt,
}: {
  completedBy: string | undefined;
  completedAt: Date | undefined;
}) => {
  const users = useSelector(selectUsers);

  return (
    <CompletedDescription
      completedAt={completedAt}
      completedBy={completedBy}
      users={users}
    />
  );
};

export const CompletedDescription = ({
  users,
  completedBy,
  completedAt,
}: {
  users: Dictionary<User>;
  completedBy: string | undefined;
  completedAt: Date | undefined;
}) => {
  const completionUserAcronym =
    (completedBy && buildUserAcronym(users[completedBy])) ??
    i18n.t("userNonRecognized");

  const completionDate = completedAt
    ? `${moment(completedAt).format("LT")} ${moment(completedAt).format("L")}`
    : "";

  const description = `${i18n.t(
    "doneBy",
  )} ${completionUserAcronym} ${completionDate}`;

  return (
    <Tooltip title={description} placement="bottom-start">
      <View>
        <Text
          size="MEDIUM"
          color="GREEN_LAGOON"
          numberOfLines={1}
          ellipsizeMode="middle"
        >
          {description}
        </Text>
      </View>
    </Tooltip>
  );
};
