import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

interface MakeStylesProps {
  isDragging: boolean;
}

export const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    opacity: ({ isDragging }: MakeStylesProps) => (isDragging ? 0 : 1),
    pointerEvents: ({ isDragging }: MakeStylesProps) =>
      isDragging ? "none" : "all",
  },
  handleIcon: {
    marginRight: Spacing.S8,
    cursor: "grab",
  },
});
