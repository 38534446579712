import { useTranslation } from "react-i18next";
import { TouchableOpacity } from "react-native";
import { useDispatch } from "react-redux";

import { useIsInConversationPreview } from "@kraaft/shared/components/conversation/conversationContext";
import { openRoomCarousel } from "@kraaft/shared/core/modules/room/roomActions";

interface Props {
  children: React.ReactNode;
  messageId: string;
  onLongPress?: () => void; // Native exclusive.
}

const MediaOpener = ({ children, messageId }: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const isInConversationPreview = useIsInConversationPreview();

  function handlePress() {
    dispatch(openRoomCarousel({ type: "room", messageId }));
  }

  return isInConversationPreview ? (
    <>{children}</>
  ) : (
    <TouchableOpacity
      accessibilityLabel={t("openMedia")}
      onPress={handlePress}
      delayPressIn={0}
    >
      {children}
    </TouchableOpacity>
  );
};

export { MediaOpener };
