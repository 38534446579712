import { useSelector } from "react-redux";

import { InviteCollaboratorsAtPoolCreation } from "@kraaft/shared/components/onboarding/inviteCollaboratorsAtPoolCreation";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";

import { AddMembersAtPoolCreationProps } from "./addMembersAtPoolCreation.props";

const AddMembersAtPoolCreation = (props: AddMembersAtPoolCreationProps) => {
  const { poolId } = props;
  const userId = useSelector(selectCurrentUserId);

  if (userId === undefined || poolId === undefined) {
    return null;
  }

  return <InviteCollaboratorsAtPoolCreation poolId={poolId} />;
};

export { AddMembersAtPoolCreation };
