import { useTranslation } from "react-i18next";

import { ReadAcknowledgement } from "@kraaft/shared/components/readAcknowledgement/readAcknowledgement";
import { Button } from "@kraaft/ui";

import { MessageInfoSheet } from "./messageInfoSheet";

interface MessageInfoContentProps {
  onClose: () => void;
  roomId: string;
  messageId: string;
}

export const MessageInfoContent = ({
  onClose,
  messageId,
  roomId,
}: MessageInfoContentProps) => {
  const { t } = useTranslation();

  return (
    <MessageInfoSheet.Paper maxHeight={600}>
      <MessageInfoSheet.Header onClose={onClose}>
        {t("messageInfo")}
      </MessageInfoSheet.Header>
      <MessageInfoSheet.Content>
        <ReadAcknowledgement roomId={roomId} messageId={messageId} />
      </MessageInfoSheet.Content>
      <MessageInfoSheet.Footer>
        <Button text={t("close")} variant="PRIMARY" onPress={onClose} />
      </MessageInfoSheet.Footer>
    </MessageInfoSheet.Paper>
  );
};
