import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useSelector } from "react-redux";
import { StrictOmit } from "ts-essentials";

import type { GroupButtonWithPanelProps } from "@kraaft/shared/components/conversationDetails/groupButtonWithPanel";
import { GroupButtonWithPanel } from "@kraaft/shared/components/conversationDetails/groupButtonWithPanel";
import { EditSchemaVisibilityForRoom } from "@kraaft/shared/components/roomSchemaVisibility/editSchemaVisibilityForRoom";
import { SafeAreaView } from "@kraaft/shared/components/safeAreaView";
import { RoomSchemaVisibility } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.state";
import { selectPoolFolderSchemaCount } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { Button, Preloader } from "@kraaft/ui";

import { styles } from "./newRoomEditSchemaVisibilityButtonWithPanel.styles";

type EditSchemaVisibilityForRoomProps<T extends string> = StrictOmit<
  GroupButtonWithPanelProps<T>,
  "children"
> & {
  poolId: string;
  roomId: string;
  roomSchemaVisibility: RoomSchemaVisibility;
};

const NewRoomEditSchemaVisibilityButtonWithPanel = <T extends string>({
  poolId,
  roomId,
  roomSchemaVisibility,
  setOpenedPanel,
  ...otherProps
}: EditSchemaVisibilityForRoomProps<T>) => {
  const { t } = useTranslation();

  const schemaCount = useSelector(selectPoolFolderSchemaCount(poolId));

  const handleNext = useCallback(() => {
    setOpenedPanel(undefined);
  }, [setOpenedPanel]);

  const enabledSchemaCount = useMemo(
    () =>
      roomSchemaVisibility?.whitelist
        ? `${roomSchemaVisibility.whitelist.size}/${schemaCount}`
        : undefined,
    [roomSchemaVisibility?.whitelist, schemaCount],
  );

  return (
    <GroupButtonWithPanel
      {...otherProps}
      setOpenedPanel={setOpenedPanel}
      buttonDescription={enabledSchemaCount}
      panelTitle={t("conversationFolder.schemas.conversationSchemas")}
    >
      <SafeAreaView edges={["bottom"]} style={styles.container}>
        <View style={styles.content}>
          {roomSchemaVisibility !== undefined ? (
            <EditSchemaVisibilityForRoom
              roomId={roomId}
              source="room_creation"
            />
          ) : (
            <Preloader absoluteFill transparent />
          )}
        </View>
        <Button
          accessibilityLabel={t("next")}
          text={t("next")}
          style={styles.button}
          onPress={handleNext}
        />
      </SafeAreaView>
    </GroupButtonWithPanel>
  );
};

export { NewRoomEditSchemaVisibilityButtonWithPanel };
