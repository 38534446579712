import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { Button } from "@kraaft/ui";
import { ChangePoolLanguageDialog } from "@kraaft/web/src/views/settings/changePoolLanguageDialog";

import { useStyles } from "../settings.styles";

interface SettingsLanguageProps {
  isPoolOwnerOrSuperadmin: boolean;
  pool: Pool | undefined;
  poolId: string | undefined;
}

const SettingsLanguage = (props: SettingsLanguageProps) => {
  const { pool, isPoolOwnerOrSuperadmin } = props;
  const classes = useStyles();
  const poolLanguage = pool?.poolLanguage;
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const openDisplayDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  if (!poolLanguage || !isPoolOwnerOrSuperadmin) {
    return null;
  }

  return (
    <div className={classes.container}>
      <span className={classes.title}>{t("adminLanguage")}</span>
      <span className={classes.subTitle}>{t(`locale.${poolLanguage}`)}</span>
      <span className={classes.button}>
        <Button
          onPress={openDisplayDialog}
          accessibilityLabel={t("edit")}
          text={t("edit")}
          variant="SECONDARY"
          size="SMALL"
        />
      </span>
      <ChangePoolLanguageDialog open={dialogOpen} onClose={closeDialog} />
    </div>
  );
};

export { SettingsLanguage };
