import { StyleSheet, View, ViewStyle } from "react-native";
import { useSelector } from "react-redux";

import {
  LastCredentialMethod,
  selectLastLoginInfos,
} from "@kraaft/shared/core/modules/login/login.slice";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { Color, Spacing, Text } from "@kraaft/ui";

type LoginHintChipsProps = {
  method: LastCredentialMethod;
  style?: ViewStyle;
};

export const LoginHintPill = ({ method, style }: LoginHintChipsProps) => {
  const lastLoginInfos = useSelector(selectLastLoginInfos);

  if (!lastLoginInfos || method !== lastLoginInfos.method) {
    return null;
  }

  const stylizedHint = lastLoginInfos.hint.replaceAll(
    /([^*]+)/g,
    "<bold>$1</bold>",
  );
  return (
    <View style={[style, styles.chips]}>
      <Text>
        <Trans
          i18nKey="loginHint"
          values={{ hint: stylizedHint }}
          components={{
            bold: <Text weight="medium" />,
          }}
        />
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  chips: {
    flexShrink: 1,
    alignSelf: "flex-start",
    borderRadius: 20,
    // Size of the text + padding
    minHeight: 26,
    backgroundColor: Color.YELLOW_KRAAFT,
    paddingHorizontal: Spacing.S8,
    paddingVertical: Spacing.S4,
    justifyContent: "center",
  },
});
