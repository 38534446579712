import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity } from "react-native";

import { Icon } from "@kraaft/ui";

import { styles } from "./shareNewFileButton.styles";

interface Props {
  onPress: () => void;
}

const ShareNewFileButton = ({ onPress }: Props) => {
  const { t } = useTranslation();

  const title = t("fileAddNew");

  return (
    <TouchableOpacity
      accessibilityLabel={title}
      style={styles.main}
      onPress={onPress}
    >
      <Icon name="plus" size={22} style={styles.icon} />
      <Text numberOfLines={2} style={styles.name}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export { ShareNewFileButton };
