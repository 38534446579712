import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { editSchemaViewKanbanFormat } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { getAvailableDisplayPropertiesFromSchema } from "@kraaft/web/src/components/kanban/board/kanbanBoardUtils";
import { useSchemaView } from "@kraaft/web/src/components/modularFoldersViewer/useSchemaView";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

const useDisplayPropertiesFromSchema = (
  workspace: RecordWorkspace,
  schema: KSchema,
  schemaViewId?: string,
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schemaView = useSchemaView(workspace, schema.id, schemaViewId);

  const availableDisplayProperties = useMemo(
    () => getAvailableDisplayPropertiesFromSchema(schema.rootSection),
    [schema],
  );
  const displayPropertiesItems = useMemo<FilteredListItem[]>(
    () =>
      availableDisplayProperties.map((column) => ({
        name: column.name,
        label: column.name,
        value: column.key,
        icon: KSchemaUtils.getIcon(column),
      })),
    [availableDisplayProperties],
  );

  const defaultDisplayProperties = schemaView
    ? schemaView.formats.kanban.displayedProperties.filter((displayProperty) =>
        availableDisplayProperties.some(
          (property) => property.key === displayProperty,
        ),
      )
    : undefined;

  const displayProperties = useMemo(
    () =>
      defaultDisplayProperties ??
      displayPropertiesItems.map((property) => property.value),
    [defaultDisplayProperties, displayPropertiesItems],
  );

  const dislayPropertiesOnSelectionChange = useCallback(
    (newValue: string[] | undefined) => {
      dispatch(
        editSchemaViewKanbanFormat({
          workspace,
          schemaId: schema.id,
          schemaViewId: schemaViewId,
          editions: {
            displayedProperties: newValue,
          },
        }),
      );
    },
    [dispatch, schema.id, schemaViewId, workspace],
  );

  return {
    displayPropertiesItems,
    displayPropertiesSelectedItemIds: displayProperties,
    dislayPropertiesOnSelectionChange,
    displayedPropertiesLabel: t("kanban.pickedPropertiesWithCount", {
      count: displayProperties.length,
    }),
  };
};

export { useDisplayPropertiesFromSchema };
