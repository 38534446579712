import {
  Loader,
  LoaderStatus,
} from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { RootState } from "@kraaft/shared/core/store";

export function selectLoading(loadingId: string) {
  return ({ loader }: RootState): boolean =>
    (loader[loadingId]?.loadingCounter ?? 0) > 0;
}

export function selectIsLoading(loadingId: string) {
  return ({ loader }: RootState): boolean =>
    loader[loadingId]?.status === LoaderStatus.LOADING;
}

export function selectLoadingStartsWith(loadingId: string) {
  return ({ loader }: RootState) =>
    Object.entries(loader)
      .filter(([key]) => key.startsWith(loadingId))
      .some(([, value]) => value.status === LoaderStatus.LOADING);
}

export function selectLoader(loadingId: string) {
  return ({ loader }: RootState): Loader | undefined => loader[loadingId];
}

export const selectLoaderState = ({ loader }: RootState) => loader;
