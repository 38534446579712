import { makeStyles } from "@mui/styles";

import { ColorStyle, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  containerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    height: 56,
    width: 200,
    borderRadius: Radius.SMALL,
    paddingLeft: Spacing.S12,
    paddingRight: Spacing.S12,
  },
});
