import { eventChannel } from "redux-saga";
import { call, put } from "typed-redux-saga/macro";

import { findAndFetchUnknownUsers } from "@kraaft/shared/core/modules/message/messageUtils";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { RoomMember } from "@kraaft/shared/core/modules/room/roomState";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { ResolvePromise } from "@kraaft/shared/core/typeUtils";
import { takeFirstAndDebounce } from "@kraaft/shared/core/utils/sagas";

function* receiveRoomMembers(action: {
  payload: { roomId: string; members: RoomMember[] };
}) {
  yield findAndFetchUnknownUsers(
    action.payload.members.map((member) => member.userId),
  );

  yield* put(roomActions.setRoomMembers(action.payload));
}

function createRoomMembersChannel(roomId: string) {
  return eventChannel<{ payload: { roomId: string; members: RoomMember[] } }>(
    (emit) => {
      const unsubscribe = Firestore.subscribeToRoomMembers(roomId, (members) =>
        emit({ payload: { roomId, members } }),
      );

      return () => {
        unsubscribe();
      };
    },
  );
}

export function* subscribeToRoomUsersSaga(
  registerMeta: (
    channel: ResolvePromise<typeof createRoomMembersChannel>,
  ) => void,
  action: ReturnType<typeof roomActions.subscribeToRoomUsers>,
) {
  try {
    const { roomId } = action.payload;
    const channel = yield* call(createRoomMembersChannel, roomId);
    registerMeta(channel);
    yield takeFirstAndDebounce(channel, 300, receiveRoomMembers);
  } catch (err) {
    console.log("chatConnect err", err);
  }
}

export function* unsubscribeFromRoomUsersSaga(
  channel: ResolvePromise<typeof createRoomMembersChannel>,
) {
  channel.close();
}
