import { StyleSheet } from "react-native";

import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.WHITE,
    paddingTop: Spacing.S16,
  },
  helperText: {
    paddingHorizontal: Spacing.S16,
    marginBottom: Spacing.S16,
  },
});
