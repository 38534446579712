import { useMemo } from "react";
import { cloneDeep, orderBy } from "lodash";
import { assert } from "ts-essentials";

import {
  OrderableListItemData,
  OrderedListRows,
  ReorderPlacement,
  TransitionDirection,
  WithOrderableListItemData,
} from "./orderableList.types";

export const ORDERABLE_LIST_ROW_TYPE = "orderable-list-row";

export function prepareForOrderableList<T, U = T>(
  array: T[],
  prepareValue: (value: T, index: number) => WithOrderableListItemData<U>,
) {
  return array.reduce(
    (acc, value, index): OrderedListRows<U> => {
      const preparedValue = prepareValue(value, index);

      acc[preparedValue.key] = preparedValue;
      return acc;
    },
    {} as OrderedListRows<U>,
  );
}

export function listRowsAsArray<T>(
  listRows: OrderedListRows<T>,
): WithOrderableListItemData<T>[] {
  return orderBy(Object.values(listRows), (row) => row.index);
}

export function getNewSourceIndex(
  placement: ReorderPlacement,
  sourceIndex: number,
  targetIndex: number,
) {
  if (sourceIndex < targetIndex) {
    return placement === "before" ? targetIndex - 1 : targetIndex;
  }
  return placement === "before" ? targetIndex : targetIndex + 1;
}

export function getNewIndexBeforeAsc(
  row: OrderableListItemData,
  sourceIndex: number,
  targetIndex: number,
) {
  if (row.index > sourceIndex && row.index < targetIndex) {
    row.index -= 1;
  }
}

export function getNewIndexBeforeDesc(
  row: OrderableListItemData,
  sourceIndex: number,
  targetIndex: number,
) {
  if (row.index >= targetIndex && row.index < sourceIndex) {
    row.index += 1;
  }
}

export function getNewIndexAfterAsc(
  row: OrderableListItemData,
  sourceIndex: number,
  targetIndex: number,
) {
  if (row.index > sourceIndex && row.index <= targetIndex) {
    row.index -= 1;
  }
}

export function getNewIndexAfterDesc(
  row: OrderableListItemData,
  sourceIndex: number,
  targetIndex: number,
) {
  if (row.index > targetIndex && row.index < sourceIndex) {
    row.index += 1;
  }
}

function getTransitionDirectionBefore(
  index: number,
  draggedRowIndex: number,
  hoveredRowIndex: number,
): TransitionDirection | undefined {
  if (index < draggedRowIndex && index >= hoveredRowIndex) {
    return "down";
  }
  if (index > draggedRowIndex && index < hoveredRowIndex) {
    return "up";
  }
  return undefined;
}

function getTransitionDirectionAfter(
  index: number,
  draggedRowIndex: number,
  hoveredRowIndex: number,
): TransitionDirection | undefined {
  if (index < draggedRowIndex && index > hoveredRowIndex) {
    return "down";
  }
  if (index > draggedRowIndex && index <= hoveredRowIndex) {
    return "up";
  }
  return undefined;
}

// eslint-disable-next-line complexity
export function getDirectionFromIndexes(
  index: number,
  draggedRowIndex: number | undefined,
  hoveredRowIndex: number | undefined,
  hoveredRowPosition: ReorderPlacement | undefined,
): TransitionDirection | undefined {
  if (hoveredRowIndex === undefined || index === draggedRowIndex) {
    return undefined;
  }

  if (draggedRowIndex === undefined) {
    if (hoveredRowPosition === "before") {
      if (index >= hoveredRowIndex) {
        return "down";
      }
      if (index < hoveredRowIndex) {
        return undefined;
      }
    } else if (hoveredRowPosition === "after") {
      if (index > hoveredRowIndex) {
        return "down";
      }
      if (index <= hoveredRowIndex) {
        return undefined;
      }
    }
    return undefined;
  }

  if (hoveredRowPosition === "before") {
    return getTransitionDirectionBefore(
      index,
      draggedRowIndex,
      hoveredRowIndex,
    );
  }
  if (hoveredRowPosition === "after") {
    return getTransitionDirectionAfter(index, draggedRowIndex, hoveredRowIndex);
  }
}

export function useDroppableAcceptTypes(
  // We dont care about the function arguments
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalAccepts: Record<string, (...args: any[]) => void> | undefined,
) {
  return useMemo(
    () => [ORDERABLE_LIST_ROW_TYPE, ...Object.keys(additionalAccepts ?? {})],
    [additionalAccepts],
  );
}

export function insertWithPlacementAndKey<T>(
  rows: OrderedListRows<T>,
  row: T & { key: string },
  placement: ReorderPlacement,
  targetKey: string,
) {
  console.log(`insert('${placement}', '${targetKey}')`);
  const oldRows = cloneDeep(rows);

  const target = oldRows[targetKey];

  assert(target, "target is missing");

  const targetIndex = target.index;
  const newSourceIndex = placement === "after" ? targetIndex + 1 : targetIndex;

  for (const oldListRow of Object.values(oldRows)) {
    if (placement === "before") {
      if (newSourceIndex <= oldListRow.index) {
        oldListRow.index += 1;
      }
    } else if (placement === "after") {
      if (newSourceIndex <= oldListRow.index) {
        oldListRow.index += 1;
      }
    }
  }

  oldRows[row.key] = {
    index: newSourceIndex,
    key: row.key,
    data: row,
  };

  return oldRows;
}
