import CryptoES from "crypto-es";

export function generateHMACSha256(
  value: string,
  secretKey: string,
  encoder: CryptoES.enc.Encoder,
) {
  const hash = CryptoES.HmacSHA256(value, secretKey).toString(encoder);
  return hash;
}
