import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import clsx from "clsx";

import { useStyles } from "./transition.styles";

interface TransitionProps {
  identifier: string;
  children: React.ReactNode;
}

function roll(index: 0 | 1) {
  return ((index + 1) % 2) as 0 | 1;
}

export const Transition = (props: TransitionProps) => {
  const { identifier, children } = props;

  const classes = useStyles();

  const [disappearingIndex, setDisappearingIndex] = useState<0 | 1>(1);
  const contents = useRef<[ReactNode | undefined, ReactNode | undefined]>([
    children,
    undefined,
  ]);
  const firstRender = contents.current[1] === undefined;
  const previousIdentifier = useRef<TransitionProps["identifier"]>();

  const animateTransition = useCallback(() => {
    setDisappearingIndex((oldIndex) => {
      contents.current[oldIndex] = children;
      return roll(oldIndex);
    });
  }, [children]);

  useEffect(() => {
    if (
      identifier !== previousIdentifier.current &&
      previousIdentifier.current !== undefined
    ) {
      animateTransition();
    }
    previousIdentifier.current = identifier;
  }, [animateTransition, identifier, previousIdentifier]);

  return (
    <div className={classes.container}>
      <div
        className={clsx(
          classes.transitionContainer,
          !firstRender &&
            disappearingIndex === 1 &&
            classes.withSlideInAnimation,
          disappearingIndex === 0 && classes.withSlideOutAnimation,
        )}
      >
        {contents.current[0]}
      </div>
      <div
        className={clsx(
          classes.absoluteContainer,
          disappearingIndex === 0 && classes.withSlideInAnimation,
          disappearingIndex === 1 && classes.withSlideOutAnimation,
        )}
      >
        {contents.current[1]}
      </div>
    </div>
  );
};
