import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useInviteCollaboratorsTrackingEvent } from "@kraaft/shared/components/inviteCollaborators/useInviteCollaboratorsTrackingEvent";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { Api } from "@kraaft/shared/core/services/api";
import { useTrackEventOnceConditionnally } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { RoomExternalInvitationTargetParams } from "@kraaft/shared/core/utils/useInviteLinkMessageConfigGenerator/useInviteLinkMessageConfigGenerator.types";
import { InviteDialogLayout } from "@kraaft/web/src/components/kDialog/customs/inviteDialogLayout/inviteDialogLayout";

type InviteExternalUserDialogProps = {
  open: boolean;
  onClose: () => void;
  room: Room;
  invitationTargetParams: RoomExternalInvitationTargetParams;
};

const InviteExternalUserDialog = ({
  open,
  onClose,
  room,
  invitationTargetParams,
}: InviteExternalUserDialogProps) => {
  const { t } = useTranslation();

  const trackingEvent = useInviteCollaboratorsTrackingEvent(
    "copy_link",
    invitationTargetParams,
  );

  useTrackEventOnceConditionnally(trackingEvent, open);

  const [link, setLink] = useState<string | null>(null);

  const _onClose = () => {
    setLink(null);
    onClose();
  };

  useEffect(() => {
    async function getLink() {
      const linkResult = await Api.generateRoomInviteLinkForExternal({
        roomId: room.id,
      });

      setLink(linkResult);
    }

    if (open) {
      void getLink();
    }
  }, [open, room.id]);

  return (
    <InviteDialogLayout
      title={t("shareInviteExternalsToRoom.title")}
      open={open}
      onClose={_onClose}
      text={t("shareInviteExternalsToRoom.content", {
        roomName: getExistingRoomRecordField(
          room.record.properties,
          "title",
          "",
        ),
      })}
      link={link}
      inputLabel={t("shareInviteExternalsToRoom.linkLabel", {
        roomName: getExistingRoomRecordField(
          room.record.properties,
          "title",
          "",
        ),
      })}
    />
  );
};

export { InviteExternalUserDialog };
