import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  lottieContainer: {
    width: "100%",
  },
  text: {
    marginTop: Spacing.S16,
    textAlign: "center",
  },
});
