import { InvalidInvitation } from "@kraaft/shared/components/auth/invalidInvitation";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";

const InvalidInvitationScreenWeb = () => {
  return (
    <SplitScreenWrapper>
      <InvalidInvitation />
    </SplitScreenWrapper>
  );
};

export { InvalidInvitationScreenWeb };
