import {
  ActionFooter,
  FooterActions,
} from "@kraaft/shared/components/actionFooter/actionFooter";
import {
  ActionSheetItem,
  LegacyActionSheet,
} from "@kraaft/shared/components/legacyActionSheet";
import { useStyles } from "@kraaft/shared/components/selectionFooter/selectionFooter.styles";
import { DocumentGallerySelectionFooterProps } from "@kraaft/shared/components/selectionFooter/selectionFooterProps";
import { ColorStyle } from "@kraaft/ui";

interface SelectionFooterWebProps extends DocumentGallerySelectionFooterProps {
  selectionCount: number;
  moreButtonAnchorRef: React.RefObject<Element>;
  actions: FooterActions;
  options: ActionSheetItem[];
  sheetOpen: boolean;
  setSheetClosed: () => void;
}

const SelectionFooter = (props: SelectionFooterWebProps) => {
  const {
    selectionCount,
    moreButtonAnchorRef,
    actions,
    options,
    sheetOpen,
    setSheetClosed,
  } = props;

  const classes = useStyles();

  if (selectionCount === 0) {
    return null;
  }

  return (
    <div className={classes.footer}>
      <ActionFooter
        direction="horizontal"
        actions={actions}
        backgroundColor={ColorStyle.BACKGROUND_LIGHT}
      />
      <LegacyActionSheet
        open={sheetOpen}
        onClose={setSheetClosed}
        anchorRef={moreButtonAnchorRef}
        options={options}
      />
    </div>
  );
};

export { SelectionFooter };
