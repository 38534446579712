import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  headerContentContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: Spacing.S16,
  },
  listContainer: {
    flex: 1,
    height: 0,
    display: "flex",
    flexDirection: "column",
  },
  listSectionContainer: {
    paddingBottom: Spacing.S24,
  },
  title: {
    fontSize: FontSize.TITLE,
    paddingBottom: Spacing.S16,
  },
  bannerContainer: {
    marginBottom: Spacing.S24,
  },
  headerRightIcons: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: Spacing.S8,
  },
  addDirectoryButton: {
    paddingTop: Spacing.S8,
  },
});

export const inlineStyles: WebStyleSheet = {
  input: {
    flexGrow: 1,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    padding: "8px 16px",
  },
};

export { useStyles };
