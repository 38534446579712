import { AutomatedCreatedAtEditor } from "@kraaft/shared/components/modular/details/editors/automatedCreatedAtEditor";
import { AutomatedCreatedByEditor } from "@kraaft/shared/components/modular/details/editors/automatedCreatedByEditor";
import { CheckboxEditor } from "@kraaft/shared/components/modular/details/editors/checkboxEditor";
import { CurrencyEditor } from "@kraaft/shared/components/modular/details/editors/currencyEditor";
import { DateEditor } from "@kraaft/shared/components/modular/details/editors/dateEditor";
import { LocationEditor } from "@kraaft/shared/components/modular/details/editors/locationEditor";
import { LongTextEditor } from "@kraaft/shared/components/modular/details/editors/longTextEditor";
import { NumberEditor } from "@kraaft/shared/components/modular/details/editors/numberEditor";
import { SelectEditor } from "@kraaft/shared/components/modular/details/editors/selectEditor";
import { ShortTextEditor } from "@kraaft/shared/components/modular/details/editors/shortTextEditor";
import { SignatureEditor } from "@kraaft/shared/components/modular/details/editors/signatureEditor";
import { UserEditor } from "@kraaft/shared/components/modular/details/editors/userEditor/userEditor";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { AnyUnexplained } from "@kraaft/shared/core/types";

export const EditorsUtils = {
  editors: {
    number: NumberEditor,
    shortText: ShortTextEditor,
    longText: LongTextEditor,
    currency: CurrencyEditor,
    date: DateEditor,
    geolocation: LocationEditor,
    selectSingle: SelectEditor,
    selectMultiple: SelectEditor,
    user: UserEditor,
    checkbox: CheckboxEditor,
    roomMembers: null,
    attachment: null,
    roomName: null,
    automatedCreatedAt: AutomatedCreatedAtEditor,
    automatedCreatedBy: AutomatedCreatedByEditor,
    automatedAutoIncrement: ShortTextEditor,
    signature: SignatureEditor,
    join: null,
  } satisfies {
    [key in KColumnType]: React.ComponentType<AnyUnexplained> | null;
  },
  getEditor(columnType: KColumnType) {
    return this.editors[
      columnType
    ] as React.ComponentType<AnyUnexplained> | null;
  },
  editorExists(columnType: KColumnType) {
    if (columnType === KColumnType.attachment) {
      return true;
    }
    return Boolean(this.editors[columnType]);
  },
};
