import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  breakWord: {
    wordBreak: "break-word",
  },
  limitPointerEventsToText: {
    pointerEvents: "none",
    "& div": {
      pointerEvents: "none",
    },
    "& span": {
      pointerEvents: "auto",
    },
  },
});
