import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";

import { useMeshContext } from "@kraaft/helper-hooks";
import { DetailsEditorBox } from "@kraaft/shared/components/modular/details/editors/detailsEditorBox";
import { EditorProps } from "@kraaft/shared/components/modular/details/editors/types";
import { EMPTY_LOCKED_RECORD_PLACEHOLDER } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularDisplayRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { numberFormatter } from "@kraaft/shared/core/services/numberFormatter";
import { useNumberInput } from "@kraaft/shared/core/utils/useNumberInput";
import { TextInput } from "@kraaft/ui";

type Props = EditorProps<KColumnType.currency>;

const CurrencyEditor = (props: Props) => {
  const {
    editor: { label, disabled, value, setValue, sectionLockInfo },
    column,
    testID,
  } = props;
  const { t } = useTranslation();

  const { currencyCode } = useMeshContext(ModularDisplayRequirementsContext);

  const {
    amount,
    displayableAmount,
    inputChangeHandler,
    inputFocusHandler,
    inputBlurHandler,
  } = useNumberInput({
    defaultValue: value,
    symbol: currencyCode,
    isCurrency: true,
  });

  const handleBlur = useCallback(() => {
    inputBlurHandler();
    if (!sectionLockInfo.isLocked && !disabled) {
      setValue(amount ?? undefined);
    }
  }, [amount, sectionLockInfo.isLocked, disabled, inputBlurHandler, setValue]);

  const placeholder = !sectionLockInfo.isLocked
    ? numberFormatter.getCurrencySymbolFromCode(currencyCode)
    : EMPTY_LOCKED_RECORD_PLACEHOLDER;

  return (
    <DetailsEditorBox
      isLocked={sectionLockInfo.isLocked}
      label={label}
      columnKey={column.key}
    >
      <TextInput
        accessibilityLabel={label}
        nativeID={`${testID}-${column.key}`}
        disabled={disabled || sectionLockInfo.isLocked}
        value={displayableAmount}
        onChangeText={inputChangeHandler}
        placeholder={placeholder}
        onFocus={inputFocusHandler}
        onBlur={handleBlur}
        keyboardType={Platform.select({
          ios: "numbers-and-punctuation",
          default: "numeric",
        })}
        returnKeyType="done"
        returnKeyLabel={t("validate")}
        disableAutocomplete
      />
    </DetailsEditorBox>
  );
};

export { CurrencyEditor };
