import { fromPairs } from "lodash";
import { StrictOmit } from "ts-essentials";
import { select as storeSelect } from "typed-redux-saga/macro";

import { compactMap } from "@kraaft/helper-functions";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { AttachmentColumnDef } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/attachment";
import { CheckboxColumnDef } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/checkbox";
import { CurrencyColumnDef } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/currency";
import {
  DateAutomatedCreatedAtColumnDef,
  DateColumnDef,
} from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/date";
import { GeolocationColumnDef } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/geolocation";
import { JoinColumnDef } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/join";
import { LongTextColumnDef } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/longText";
import { NumberColumnDef } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/number";
import {
  RoomMembersColumnDef,
  RoomNameColumnDef,
} from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/roomProperty";
import {
  SelectMultipleColumnDef,
  SelectSingleColumnDef,
} from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/select";
import {
  UserAutomatedCreatedByColumnDef,
  UserColumnDef,
} from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/user";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaColumnDefinition,
  KSchemaColumnLiteralValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import {
  ModularRecord,
  ModularRecordProperties,
  ModularRecordProperty,
  WithId,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import {
  selectRoomSchema,
  selectSchemaRootSection,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import * as Types from "@kraaft/shared/core/services/firestore/firestoreTypes";
import {
  normalizeFirestoreRoom,
  normalizeModularRecordPropertiesValue,
  parseDate,
} from "@kraaft/shared/core/services/firestore/firestoreUtils";
import { AnyUnexplained } from "@kraaft/shared/core/types";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RawSchema = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RawSchemaColumn = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RawSchemaColumnValue = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RawModularRecord = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RawModularRecordProperty = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RawModularRecordProperties = any;

export enum RawColumnType {
  attachment = "attachment",
  checkbox = "checkbox",
  currency = "currency",
  date = "date",
  geolocation = "geolocation",
  join = "join",
  longText = "longText",
  roomProperty = "roomProperty",
  select = "select",
  shortText = "shortText",
  user = "user",
  number = "number",
  signature = "signature",
}

// Please use utils in this class only when normalizing data or sending data to the server
export class KSchemaConversion {
  // eslint-disable-next-line complexity
  static toRawColumnType(type: KColumnType): RawColumnType {
    switch (type) {
      case KColumnType.attachment:
        return RawColumnType.attachment;
      case KColumnType.automatedAutoIncrement:
        return RawColumnType.shortText;
      case KColumnType.automatedCreatedAt:
        return RawColumnType.date;
      case KColumnType.automatedCreatedBy:
        return RawColumnType.user;
      case KColumnType.checkbox:
        return RawColumnType.checkbox;
      case KColumnType.currency:
        return RawColumnType.currency;
      case KColumnType.date:
        return RawColumnType.date;
      case KColumnType.geolocation:
        return RawColumnType.geolocation;
      case KColumnType.join:
        return RawColumnType.join;
      case KColumnType.longText:
        return RawColumnType.longText;
      case KColumnType.number:
        return RawColumnType.number;
      case KColumnType.roomMembers:
        return RawColumnType.roomProperty;
      case KColumnType.roomName:
        return RawColumnType.roomProperty;
      case KColumnType.selectMultiple:
        return RawColumnType.select;
      case KColumnType.selectSingle:
        return RawColumnType.select;
      case KColumnType.shortText:
        return RawColumnType.shortText;
      case KColumnType.signature:
        return RawColumnType.signature;
      case KColumnType.user:
        return RawColumnType.user;
    }
  }

  static toRawProperty(
    property: ModularRecordProperties[string],
  ): RawModularRecordProperty {
    return {
      columnType: KSchemaConversion.toRawColumnType(property.columnType),
      value: property.value,
    };
  }

  // eslint-disable-next-line complexity
  static toRawDefinition(definition: KSchemaColumnDefinition) {
    switch (definition.type) {
      case KColumnType.attachment:
        return {
          mode: definition.mode,
          type: KSchemaConversion.toRawColumnType(definition.type),
          photoQualityHD: definition.photoQualityHD,
        };
      case KColumnType.automatedAutoIncrement:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
          automation: {
            behavior: "autoIncrement",
            prefix: definition.prefix,
          },
        };
      case KColumnType.automatedCreatedAt:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
          automation: {
            behavior: "createdAt",
          },
          displayTime: definition.displayTime,
        };
      case KColumnType.automatedCreatedBy:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
          automation: {
            behavior: "createdBy",
          },
        };
      case KColumnType.checkbox:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
          isLockingSection: definition.isLockingSection,
          mode: definition.mode,
        };
      case KColumnType.currency:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
        };
      case KColumnType.date:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
          displayTime: definition.displayTime,
        };
      case KColumnType.geolocation:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
        };
      case KColumnType.join:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
        };
      case KColumnType.longText:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
        };
      case KColumnType.number:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
          symbol: definition.symbol,
        };
      case KColumnType.roomMembers:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
          property: "members",
        };
      case KColumnType.roomName:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
          property: "name",
        };
      case KColumnType.selectMultiple:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
          allowColor: definition.allowColor,
          allowMultiple: true,
          options: definition.options,
        };
      case KColumnType.selectSingle:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
          allowColor: definition.allowColor,
          allowMultiple: false,
          options: definition.options,
        };
      case KColumnType.shortText:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
        };
      case KColumnType.signature:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
        };
      case KColumnType.user:
        return {
          type: KSchemaConversion.toRawColumnType(definition.type),
          allowMultiple: definition.allowMultiple,
        };
    }
  }

  static toRawColumn(column: KSchemaColumn) {
    return {
      index: column.index,
      name: column.name,
      definition: KSchemaConversion.toRawDefinition(column),
    };
  }

  static toRawProperties(properties: ModularRecordProperties) {
    return fromPairs(
      Object.entries(properties).map(([key, value]) => [
        key,
        KSchemaConversion.toRawProperty(value),
      ]),
    );
  }

  static toRawTupleProperties(
    properties: [string, ModularRecordProperty][],
  ): [string, RawModularRecordProperty][] {
    return properties.map(
      ([key, value]) => [key, KSchemaConversion.toRawProperty(value)] as const,
    );
  }

  static toRawRecord(record: ModularRecord) {
    return {
      schemaId: record.id,
      properties: KSchemaConversion.toRawProperties(record.properties),
    };
  }

  // eslint-disable-next-line complexity
  static toColumnDefinitionFromFirestoreColumnDefinition(
    firestoreColumnDefinition: Types.FirestoreSchemaColumnDefinition,
  ) {
    switch (firestoreColumnDefinition.type) {
      case "user":
        if (!firestoreColumnDefinition.automation) {
          return {
            type: KColumnType.user,
            allowMultiple: firestoreColumnDefinition.allowMultiple,
          } as UserColumnDef;
        }
        if (firestoreColumnDefinition.automation?.behavior === "createdBy") {
          return {
            type: KColumnType.automatedCreatedBy,
          } as UserAutomatedCreatedByColumnDef;
        }
        break;
      case "checkbox":
        return {
          type: KColumnType.checkbox,
          isLockingSection: firestoreColumnDefinition.isLockingSection ?? false,
          mode: "completion",
        } as CheckboxColumnDef;
      case "join":
        return {
          type: KColumnType.join,
          collection: firestoreColumnDefinition.collection,
        } as JoinColumnDef;
      case "attachment":
        return {
          type: KColumnType.attachment,
          mode: firestoreColumnDefinition.mode,
          photoQualityHD: firestoreColumnDefinition.photoQualityHD,
        } as AttachmentColumnDef;
      case "currency":
        return {
          type: KColumnType.currency,
        } as CurrencyColumnDef;
      case "date":
        if (!firestoreColumnDefinition.automation) {
          return {
            type: KColumnType.date,
            displayTime: firestoreColumnDefinition.displayTime,
          } as DateColumnDef;
        }
        if (firestoreColumnDefinition.automation?.behavior === "createdAt") {
          return {
            type: KColumnType.automatedCreatedAt,
            displayTime: firestoreColumnDefinition.displayTime,
          } as DateAutomatedCreatedAtColumnDef;
        }
        break;
      case "geolocation":
        return {
          type: KColumnType.geolocation,
        } as GeolocationColumnDef;
      case "longText":
        return {
          type: KColumnType.longText,
        } as LongTextColumnDef;
      case "number":
        return {
          type: KColumnType.number,
          symbol: firestoreColumnDefinition.symbol,
        } as NumberColumnDef;
      case "roomProperty":
        if (firestoreColumnDefinition.property === "name") {
          return {
            type: KColumnType.roomName,
          } as RoomNameColumnDef;
        }
        if (firestoreColumnDefinition.property === "members") {
          return {
            type: KColumnType.roomMembers,
          } as RoomMembersColumnDef;
        }
        break;
      case "select":
        if (!firestoreColumnDefinition.allowMultiple) {
          return {
            type: KColumnType.selectSingle,
            options: firestoreColumnDefinition.options,
            allowColor: firestoreColumnDefinition.allowColor,
          } as SelectSingleColumnDef;
        }
        return {
          type: KColumnType.selectMultiple,
          options: firestoreColumnDefinition.options,
          allowColor: firestoreColumnDefinition.allowColor,
        } as SelectMultipleColumnDef;
      case "shortText":
        if (!firestoreColumnDefinition.automation) {
          return {
            type: KColumnType.shortText,
          };
        }
        if (firestoreColumnDefinition.automation.behavior === "autoIncrement") {
          return {
            type: KColumnType.automatedAutoIncrement,
            prefix: firestoreColumnDefinition.automation.prefix,
          };
        }
        break;
      case "signature":
        return { type: KColumnType.signature };
    }
  }

  // Not 100% reliable, does not take schema into account
  // eslint-disable-next-line complexity
  static toColumnType(type: RawColumnType): KColumnType {
    switch (type) {
      case RawColumnType.attachment:
        return KColumnType.attachment;
      case RawColumnType.shortText:
        return KColumnType.shortText;
      case RawColumnType.date:
        return KColumnType.date;
      case RawColumnType.user:
        return KColumnType.user;
      case RawColumnType.checkbox:
        return KColumnType.checkbox;
      case RawColumnType.currency:
        return KColumnType.currency;
      case RawColumnType.geolocation:
        return KColumnType.geolocation;
      case RawColumnType.join:
        return KColumnType.join;
      case RawColumnType.longText:
        return KColumnType.longText;
      case RawColumnType.number:
        return KColumnType.number;
      case RawColumnType.roomProperty:
        return KColumnType.roomMembers;
      case RawColumnType.select:
        return KColumnType.selectMultiple;
      case RawColumnType.signature:
        return KColumnType.signature;
    }
  }

  static toProperty(
    rawProperty: RawModularRecordProperty,
  ): ModularRecordProperties[string] | undefined {
    const columnType = KSchemaConversion.toColumnType(rawProperty.columnType);
    if (!columnType) {
      return undefined;
    }
    if (
      columnType === KColumnType.date ||
      columnType === KColumnType.automatedCreatedAt
    ) {
      return {
        columnType,
        value: rawProperty.value && parseDate(rawProperty.value),
      };
    }

    return {
      columnType,
      value: rawProperty.value,
    };
  }

  static toProperties(
    rawProperties: RawModularRecordProperties,
  ): ModularRecordProperties {
    return fromPairs(
      compactMap(Object.entries(rawProperties), ([key, value]) => {
        const converted = KSchemaConversion.toProperty(value);
        if (!converted) {
          return undefined;
        }
        return [key, converted];
      }),
    );
  }

  static toRecord(rawRecord: RawModularRecord): ModularRecord {
    return {
      id: rawRecord.id,
      properties: KSchemaConversion.toProperties(rawRecord.properties),
      schemaId: rawRecord.schemaId,
    };
  }

  static alignModularRecordPropertiesWithSchemaColumn(
    firestoreModularRecordProperty: Types.FirestoreModularRecord["properties"][string],
    columnType: KColumnType,
  ) {
    return {
      columnType,
      value: normalizeModularRecordPropertiesValue(
        firestoreModularRecordProperty,
      ) as KSchemaColumnLiteralValue,
      updatedAt: firestoreModularRecordProperty.updatedAt
        ? parseDate(firestoreModularRecordProperty.updatedAt)
        : undefined,
      updatedBy: firestoreModularRecordProperty.updatedBy,
    } as ModularRecordProperty;
  }

  static alignModularRecordPropertiesWithSchemaColumns(
    firestoreModularRecordProperties: Types.FirestoreModularRecord["properties"],
    columns: Record<string, KSchemaColumn>,
  ) {
    return Object.entries(firestoreModularRecordProperties).reduce<
      ModularRecord["properties"]
    >((properties, [key, firestoreModularRecordProperty]) => {
      const column = columns[key];
      if (
        column !== undefined &&
        firestoreModularRecordProperty.columnType ===
          KSchemaConversion.toRawColumnType(column.type)
      ) {
        const property =
          KSchemaConversion.alignModularRecordPropertiesWithSchemaColumn(
            firestoreModularRecordProperty,
            column.type,
          );
        if (property !== undefined) {
          properties[key] = property;
        }
      }
      return properties;
    }, {});
  }

  static *toModularFolders(
    schemaId: string,
    firestoreModularFolders: WithId<Types.FirestoreModularFolder>[],
  ): Generator<AnyUnexplained, ModularFolder[]> {
    const schemaRootSection = yield* storeSelect(
      selectSchemaRootSection(schemaId),
    );

    const allColumns = schemaRootSection
      ? KSchemaUtils.flattenColumnsDict(schemaRootSection)
      : {};

    return firestoreModularFolders.map((firestoreModularFolder) => ({
      id: firestoreModularFolder.id,
      poolId: firestoreModularFolder.poolId,
      roomId: firestoreModularFolder.roomId,
      schemaId: firestoreModularFolder.schemaId,
      properties: {
        title: {
          columnType: KColumnType.shortText,
          value: "",
        },
        ...KSchemaConversion.alignModularRecordPropertiesWithSchemaColumns(
          firestoreModularFolder.properties,
          allColumns,
        ),
      },
      incrementalId: firestoreModularFolder.incrementalId,
      geolocation: firestoreModularFolder.geolocation || undefined,
      index: firestoreModularFolder.roomSchemaOrder,
      updatedAt: parseDate(firestoreModularFolder.updatedAt),
      isPersisted: true,
    }));
  }

  static *toRooms(
    poolId: string,
    firestoreRooms: WithId<Types.FirestoreRoom>[],
  ): Generator<AnyUnexplained, Room[]> {
    const schema = yield* waitFor(selectRoomSchema(poolId));

    const allColumns = schema
      ? KSchemaUtils.flattenColumnsDict(schema.rootSection)
      : {};

    return firestoreRooms.map((firestoreRoom) => {
      const roomWithoutRecord: StrictOmit<Room, "record"> =
        normalizeFirestoreRoom(firestoreRoom.id, firestoreRoom);
      return {
        ...roomWithoutRecord,
        record: {
          id: firestoreRoom.id,
          type: roomWithoutRecord.type,
          properties: {
            title: {
              columnType: KColumnType.shortText,
              value: "",
            },
            ...KSchemaConversion.alignModularRecordPropertiesWithSchemaColumns(
              firestoreRoom.record.properties,
              allColumns,
            ),
          },
          schemaId: firestoreRoom.record.schemaId,
        },
      };
    });
  }
}
