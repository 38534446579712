import { useState } from "react";
import { useSelector } from "react-redux";

import { useUpdate } from "@kraaft/helper-hooks";
import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { Mention } from "@kraaft/shared/core/framework/mentionnableText/mention";
import { MentionAwareText } from "@kraaft/shared/core/framework/mentionnableText/mentionAwareText";
import { selectRoomMemberIds } from "@kraaft/shared/core/modules/room/roomSelectors";
import {
  selectCurrentUserId,
  selectUsers,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { getStore } from "@kraaft/shared/core/store";
import { useRender } from "@kraaft/shared/core/utils/useRender";

export function getUsernameFromUserId(id: string) {
  const state = getStore().getState();
  if (!state) {
    return getUsername(undefined);
  }
  const users = selectUsers(state);
  return getUsername(users[id]);
}

export function useMentionAwareTextState(
  roomId: string,
  defaultInputPartitions?: InputPartition[],
) {
  const render = useRender();
  const currentUserId = useSelector(selectCurrentUserId);
  const roomMemberIds = useSelector(selectRoomMemberIds(roomId));

  const [mentionAwareText] = useState(() => {
    return MentionAwareText.fromInputPartition(
      render,
      defaultInputPartitions ?? [],
      getUsernameFromUserId,
    );
  });

  useUpdate(() => {
    mentionAwareText.resetFromInputPartitions(
      defaultInputPartitions ?? [],
      getUsernameFromUserId,
    );
  }, [defaultInputPartitions]);

  const availableMentions = roomMemberIds.reduce<Mention[]>(
    (mentions, userId) => {
      if (userId === currentUserId) {
        return mentions;
      }
      mentions.push({ type: "user", id: userId });

      return mentions;
    },
    [],
  );

  if (availableMentions.length > 0) {
    availableMentions.unshift({ type: "everyone" });
  }

  return { mentionAwareText, availableMentions };
}
