import { KeyOfEnum } from "@kraaft/helper-types";

export const AudioPlayerStatus = {
  BUFFERING: "BUFFERING",
  PLAYING: "PLAYING",
  PAUSED: "PAUSED",
} as const;
export type AudioPlayerStatus = KeyOfEnum<typeof AudioPlayerStatus>;

export interface AudioPlayer {
  toggle: (
    audioFileUri: string,
    onProgress?: (progress: number) => void,
    onPlayingStatusUpdated?: (status: AudioPlayerStatus) => void,
  ) => Promise<void>;
  cleanup: (audioFileUri: string) => Promise<void>;
}
