import { createAction } from "@reduxjs/toolkit";

import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import {
  Modifier,
  SchemaTemplate,
} from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";

interface SchemaTemplateIdPayload {
  schemaTemplateId: string;
}

interface AddSchemaTemplatePayload {
  poolId: string;
  schemaTemplate: SchemaTemplate;
}

export const addSchemaTemplate = createAction(
  "@template/ADD_SCHEMA_TEMPLATE",
  (payload: AddSchemaTemplatePayload) => ({ payload }),
);

interface UpdatePoolSchemasPayload {
  schemaTemplates: SchemaTemplate[];
  poolId: string;
}

export const updatePoolSchemaTemplates = createAction(
  "@template/UPDATE_POOL_SCHEMAS_TEMPLATE",
  (payload: UpdatePoolSchemasPayload) => ({ payload }),
);

export const saveEditSchemaTemplate = createAction(
  "@template/SAVE_EDIT_SCHEMA_TEMPLATE",
);

export const startEditExistingSchemaTemplate = createAction(
  "@template/EDIT_EXISTING_SCHEMA_TEMPLATE",
  (payload: SchemaTemplateIdPayload) => ({ payload }),
);

interface CreateSchemaTemplatePayload {
  name: string;
  schemaId: string;
  poolId: string;
}

export const createSchemaTemplate = createAction(
  "@template/CREATE_SCHEMA_TEMPLATE",
  (payload: CreateSchemaTemplatePayload) => ({ payload }),
);

export const deleteSchemaTemplate = createAction(
  "@template/DELETE_SCHEMA_TEMPLATE",
  (payload: SchemaTemplateIdPayload) => ({ payload }),
);

interface EditSchemaTemplatePayload {
  modifiers?: Modifier[];
  name?: string;
}

export const editSchemaTemplate = createAction(
  "@template/EDIT_SCHEMA_TEMPLATE",
  (payload: EditSchemaTemplatePayload) => ({ payload }),
);

interface EditSchemaTemplateModifier {
  modifier: Modifier;
  index: number;
}

export const editSchemaTemplateModifier = createAction(
  "@template/EDIT_SCHEMA_TEMPLATE_MODIFIER",
  (payload: EditSchemaTemplateModifier) => ({ payload }),
);

interface EditSchemaTemplateModifierIndex {
  from: number;
  to: number;
}

export const editSchemaTemplateModifierIndex = createAction(
  "@template/EDIT_SCHEMA_TEMPLATE_MODIFIER_INDEX",
  (payload: EditSchemaTemplateModifierIndex) => ({ payload }),
);

export interface RequestSchemaTemplateRenderPayload {
  schemaTemplateId: string;
}

export const requestSchemaTemplateRender = createAction(
  "@template/REQUEST_SCHEMA_TEMPLATE_RENDER",
  (payload: RequestSchemaTemplateRenderPayload) => ({ payload }),
);

export interface SetSchemaTemplateRenderPayload {
  schemaTemplateId: string;
  rendered: ModularRecord[] | undefined;
}

export const setSchemaTemplateRender = createAction(
  "@template/SET_SCHEMA_TEMPLATE_RENDER",
  (payload: SetSchemaTemplateRenderPayload) => ({ payload }),
);

interface ApplySchemaTemplatesPayload {
  schemaTemplateIds: string[];
  roomId: string;
}

export const applySchemaTemplates = createAction(
  "@template/APPLY_SCHEMA_TEMPLATES",
  (payload: ApplySchemaTemplatesPayload) => ({ payload }),
);

export interface ApplyRenderUpdatesToSchemaTemplatePayload {
  schema: KSchema;
  schemaTemplateId: string;
}

export const applyRenderUpdatesToSchemaTemplate = createAction(
  "@template/APPLY_RENDER_UPDATES_TO_SCHEMA_TEMPLATE",
  (payload: ApplyRenderUpdatesToSchemaTemplatePayload) => ({ payload }),
);

export const SchemaTemplateActions = {
  subscribe: createAction<{ poolId: string }>("@template/SUBSCRIBE"),
  unsubscribe: createAction<{ poolId: string }>("@template/UNSUBSCRIBE"),
};
