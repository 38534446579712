import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";
import { withRoomMemberFormatterCell } from "@kraaft/web/src/components/modularTable/components/fields/user/withRoomMemberCell";
import { userClipboardTextFromCell } from "@kraaft/web/src/components/modularTable/components/table/clipboardTextFromCellUtils";
import { UserColumnEditor } from "@kraaft/web/src/components/modularTable/components/table/columnHeader/columnEditor/columnEditors/userColumnEditor";
import { RoomMemberEditor } from "@kraaft/web/src/components/modularTable/components/table/editors/roomMemberEditor";
import { UserEditor } from "@kraaft/web/src/components/modularTable/components/table/editors/userEditor";
import { userRecordComparator } from "@kraaft/web/src/components/modularTable/components/table/recordComparatorUtils";
import { userValueFromClipboardText } from "@kraaft/web/src/components/modularTable/components/table/valueFromClipboardTextUtils";
import { IFieldConfig } from "@kraaft/web/src/components/modularTable/components/types";

import { withBasicCell } from "../_withTableCell/withBasicCell";
import { UserCell } from "./basicCell";

export const userConfig: IFieldConfig<KColumnType.user> = {
  type: KColumnType.user,
  TableCell: withBasicCell(
    UserCell as React.FC<IBasicCellProps<KColumnType.user>>,
  ),
  TableEditor: UserEditor,
  comparator: userRecordComparator,
  columnEditor: UserColumnEditor,
  getClipboardTextFromCell: userClipboardTextFromCell,
  getValueFromClipboardText: userValueFromClipboardText,
};

export const roomMembersConfig: IFieldConfig<KColumnType.roomMembers> = {
  type: KColumnType.roomMembers,
  TableCell: withRoomMemberFormatterCell(
    UserCell as React.FC<IBasicCellProps<KColumnType.roomMembers>>,
  ),
  TableEditor: RoomMemberEditor,
  comparator: userRecordComparator,
  getClipboardTextFromCell: userClipboardTextFromCell,
  getValueFromClipboardText: userValueFromClipboardText,
};

export const automatedCreatedByConfig: IFieldConfig<KColumnType.automatedCreatedBy> =
  {
    type: KColumnType.automatedCreatedBy,
    TableCell: withBasicCell(
      UserCell as React.FC<IBasicCellProps<KColumnType.automatedCreatedBy>>,
    ),
    TableEditor: null,
    comparator: userRecordComparator,
    getClipboardTextFromCell: userClipboardTextFromCell,
    getValueFromClipboardText: userValueFromClipboardText,
  };
