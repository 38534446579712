import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  image: {
    height: 32,
    width: 32,
  },
});
