import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { Button } from "@kraaft/ui";

export const CloseMapButton = () => {
  const { t } = useTranslation();
  const nav = useNavigationService();

  return (
    <Button
      testID="ide2e-close-map-button"
      icon="x-close"
      variant="MAP"
      size="SMALL"
      onPress={useCallback(() => nav.clickOnCloseRoomMap(), [nav])}
      accessibilityLabel={t("map.close")}
      text={t("map.close")}
    />
  );
};
