import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Pressable } from "react-native";
import { useDispatch } from "react-redux";

import { showInfo } from "@kraaft/shared/core/modules/alert/alertActions";
import { WorkflowMessageEvent } from "@kraaft/shared/core/modules/message/messageState";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";

export interface WorkflowLogWrapperProps {
  children: React.ReactNode;
  roomId: string;
  event: WorkflowMessageEvent;
}

export const WorkflowLogWrapper = ({
  children,
  roomId,
  event,
}: WorkflowLogWrapperProps) => {
  const navigationService = useNavigationService();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handlePress = useCallback(() => {
    if (event.source && event.source.type === "modularFolder") {
      if (event.relatedRessourceDeleted) {
        dispatch(
          showInfo({
            title: t("modularFolderNoLongerExists"),
          }),
        );

        return;
      }

      const { id: folderId, schemaId } = event.source;

      navigationService.navigate("ModularFolderDetails", {
        roomId,
        schemaId,
        folderId,
      });
    }
  }, [
    dispatch,
    event.relatedRessourceDeleted,
    event.source,
    navigationService,
    roomId,
    t,
  ]);

  return (
    <Pressable
      accessibilityLabel={t("open")}
      onPress={handlePress}
      disabled={event.source?.type !== "modularFolder"}
    >
      {children}
    </Pressable>
  );
};
