import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { showInfo } from "@kraaft/shared/core/modules/alert/alertActions";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { Api } from "@kraaft/shared/core/services/api";
import { useAuth } from "@kraaft/shared/core/services/auth/useAuth";

const MINUTE_MS = 1000 * 60;
const REFRESH_INTERVAL_MS = 30 * MINUTE_MS;

export function useRefreshSSOConnection() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const auth = useAuth();

  useEffect(() => {
    const refreshSSOConnection = async () => {
      const { shouldLogout } = await Api.refreshSsoConnection();
      if (shouldLogout) {
        dispatch(showInfo({ title: t("sso.logout.title") }));
        dispatch(UserActions.disconnectUserCommand());
      }
    };

    if (auth?.id && auth.providerId.startsWith("oidc.")) {
      const timer = setInterval(refreshSSOConnection, REFRESH_INTERVAL_MS);

      refreshSSOConnection().catch(console.error);

      return () => {
        clearInterval(timer);
      };
    }
  }, [auth?.id, auth?.providerId, dispatch, t]);
}
