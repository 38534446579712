import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import {
  DifferenceBackground,
  DifferenceBackgroundProps,
} from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceBackground";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordValueOfType } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { Spacing, Text } from "@kraaft/ui";

interface SelectDifferenceProps extends DifferenceBackgroundProps {
  value:
    | ModularRecordValueOfType<KColumnType.selectSingle>
    | ModularRecordValueOfType<KColumnType.selectMultiple>;
  schemaColumn:
    | KSchemaColumn<KColumnType.selectSingle>
    | KSchemaColumn<KColumnType.selectMultiple>
    | undefined;
}

export const SelectDifference = ({
  value,
  schemaColumn,
  method,
}: SelectDifferenceProps) => {
  const content = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }
    return value.map((optionId) => {
      const option = schemaColumn?.options[optionId];

      return (
        <DifferenceBackground key={optionId} method={method}>
          <Text size="BODY">{option?.label ?? optionId}</Text>
        </DifferenceBackground>
      );
    });
  }, [method, schemaColumn?.options, value]);

  return content !== undefined ? (
    <View style={styles.container}>{content}</View>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: Spacing.S8,
  },
});
