import {
  MarkedText,
  Segment,
} from "@kraaft/shared/core/framework/markedText/markedText";
import { EveryoneMentionMarker } from "@kraaft/shared/core/framework/markedText/markers/everyoneMention.marker";
import { AnyMarker } from "@kraaft/shared/core/framework/markedText/markers/marker";
import {
  UserMentionMarker,
  UsernameGetter,
} from "@kraaft/shared/core/framework/markedText/markers/userMention.marker";

export type TextInputPartition = {
  type: "text";
  content: string;
};

export type MentionInputPartition = {
  type: "mention";
  userId: string;
};

export type EveryoneMentionInputPartition = {
  type: "mention-everyone";
};

export type InputPartition =
  | TextInputPartition
  | MentionInputPartition
  | EveryoneMentionInputPartition;

export class InputPartitionHelper {
  static getTextAndMarkersFromInputPartitions(
    inputPartitions: InputPartition[],
    getUsernameFromUserId: UsernameGetter,
  ) {
    return inputPartitions.reduce<{
      text: string;
      markers: AnyMarker[];
    }>(
      (data, inputPartition) => {
        if (inputPartition.type === "text") {
          data.text = `${data.text}${inputPartition.content}`;
        }
        if (inputPartition.type === "mention") {
          const marker = new UserMentionMarker(
            data.text.length,
            inputPartition.userId,
            getUsernameFromUserId,
          );
          data.markers.push(marker);
          data.text = `${data.text}${marker.renderText()}`;
        }
        if (inputPartition.type === "mention-everyone") {
          const marker = new EveryoneMentionMarker(data.text.length);
          data.markers.push(marker);
          data.text = `${data.text}${marker.renderText()}`;
        }
        return data;
      },
      { text: "", markers: [] },
    );
  }

  static segmentsToInputPartitions(segments: Segment[]): InputPartition[] {
    return segments.map<InputPartition>((segment) => {
      if (segment.type === "text") {
        return {
          type: "text",
          content: segment.text,
        };
      }
      if (segment.type === "marker") {
        if (segment.marker instanceof UserMentionMarker) {
          return {
            type: "mention",
            userId: segment.marker.userId,
          };
        }
        if (segment.marker instanceof EveryoneMentionMarker) {
          return {
            type: "mention-everyone",
          };
        }
      }

      console.warn(
        `PartitionHelper.toInputPartitions :: marker type ${segment.marker.type} is not handled properly`,
      );
      return {
        type: "text",
        content: segment.marker.renderText(),
      };
    });
  }

  static isEmpty(inputPartitions: InputPartition[] | undefined) {
    return !inputPartitions?.some(
      (partition) => partition.type !== "text" || partition.content.length > 0,
    );
  }

  static isNotEmpty(
    inputPartitions: InputPartition[] | undefined,
  ): inputPartitions is InputPartition[] {
    return !InputPartitionHelper.isEmpty(inputPartitions);
  }

  // eslint-disable-next-line complexity
  static trimInputPartitions(partitions: readonly InputPartition[]) {
    const mutablePartitions = [...partitions];
    const toSpliceFromStart: number[] = [];
    const toSpliceFromEnd: number[] = [];
    for (let i = 0; i < mutablePartitions.length; i += 1) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const partition = mutablePartitions[i]!;
      if (partition.type !== "text" || partition.content.trim().length > 0) {
        break;
      }
      toSpliceFromStart.unshift(i);
    }
    for (const indexToSplice of toSpliceFromStart) {
      mutablePartitions.splice(indexToSplice, 1);
    }
    for (let i = mutablePartitions.length - 1; i >= 0; i -= 1) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const partition = mutablePartitions[i]!;
      if (partition.type !== "text" || partition.content.trim().length > 0) {
        break;
      }
      toSpliceFromEnd.push(i);
    }
    for (const indexToSplice of toSpliceFromEnd) {
      mutablePartitions.splice(indexToSplice, 1);
    }
    const [firstPartition] = mutablePartitions;
    if (firstPartition?.type === "text") {
      firstPartition.content = firstPartition.content.trimStart();
    }
    const lastPartition = mutablePartitions.at(-1);
    if (lastPartition?.type === "text") {
      lastPartition.content = lastPartition.content.trimEnd();
    }

    return mutablePartitions;
  }

  public static inputPartitionsToText(
    inputPartitions: InputPartition[],
    getUsernameFromUserId: UsernameGetter,
  ) {
    const { text, markers } =
      InputPartitionHelper.getTextAndMarkersFromInputPartitions(
        inputPartitions,
        getUsernameFromUserId,
      );

    return new MarkedText(text, markers).asText();
  }

  static textToInputPartition(text: string): TextInputPartition {
    return { type: "text", content: text };
  }
}
