import { Icon, IconName, Text } from "@kraaft/ui";

import { useStyles } from "./schemaLibraryCard.styles";

interface Props {
  title: string;
  onPress: () => void;
  coverPictureUrl?: string;
  icon?: IconName;
  caption?: string;
  stealthy?: boolean;
}

export const SchemaLibraryCard = ({
  onPress,
  coverPictureUrl,
  icon,
  title,
  caption,
  stealthy = false,
}: Props) => {
  const classes = useStyles({ stealthy });

  return (
    <div className={classes.container} onClick={onPress}>
      <div className={classes.thumbnailContainer}>
        {coverPictureUrl ? (
          <img className={classes.image} src={coverPictureUrl} />
        ) : (
          <div className={classes.icon}>
            <Icon name="camera-off" size="LARGE" color="WHITE" />
          </div>
        )}
      </div>
      <div className={classes.textContainer}>
        <div className={classes.titleContainer}>
          {icon && <Icon className={classes.iconContainer} name={icon} />}
          <Text size="BODY" numberOfLines={2}>
            {title}
          </Text>
        </div>
        {caption && (
          <div className={classes.caption}>
            <Text size="MEDIUM" color="FONT_LOW_EMPHASIS">
              {caption}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};
