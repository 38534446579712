export function getCurrencySymbolFromCode(poolLanguage: string, code: string) {
  return (
    new Intl.NumberFormat(poolLanguage, {
      style: "currency",
      currency: code,
    })
      .formatToParts(1)
      .find((x) => x.type === "currency")?.value ?? "??"
  );
}
