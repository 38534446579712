import { createAction } from "@reduxjs/toolkit";

import {
  PoolNotificationFilter,
  UserPool,
} from "@kraaft/shared/core/modules/userPool/userPool.state";

export const UserPoolActions = {
  subscribeToUserPool: createAction<{ poolId: string; userId: string }>(
    "@userPool/SUBSCRIBE",
  ),
  unsubscribeFromUserPool: createAction<{ poolId: string; userId: string }>(
    "@userPool/UNSUBSCRIBE",
  ),
  changeNotificationFilter: createAction<{
    poolId: string;
    notificationFilter: PoolNotificationFilter;
  }>("@userPool/CHANGE_NOTIFICATION_FILTER"),
};

export const UserPoolStateActions = {
  setUserPool: createAction<{ poolId: string; userPool: UserPool }>(
    "@userPool/SET",
  ),
  setNotificationFilter: createAction<{
    poolId: string;
    notificationFilter: PoolNotificationFilter;
  }>("@userPool/SET_NOTIFICATION_FILTER"),
};
