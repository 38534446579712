import React from "react";

import { useStyles } from "@kraaft/web/src/components/pageHeader/pageHeader.styles";

interface PageHeaderProps {
  title: React.ReactNode;
  titleSpecification?: string;
  right?: React.ReactNode;
  white?: boolean;
  titleElementId?: string;
}

export const PageHeader = ({
  title,
  titleSpecification,
  right,
  white,
  titleElementId,
}: PageHeaderProps) => {
  const classes = useStyles({ white: Boolean(white) });

  return (
    <div className={classes.header}>
      <div className={classes.headerText}>
        <div className={classes.title} id={titleElementId}>
          {title}
        </div>
        {titleSpecification ? (
          <div className={classes.subtitle}>{titleSpecification}</div>
        ) : null}
      </div>
      <div className={classes.headerButton}>{right}</div>
    </div>
  );
};
