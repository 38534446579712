import { useKeyPressEvent } from "react-use";

import { RoomMembersLayout } from "@kraaft/shared/components/createRoom/roomMembers/roomMembers.layout";

interface RoomMembersProps {
  poolId: string;
  roomId: string;
  onMembersAdded: () => void;
}

const RoomMembers = (props: RoomMembersProps) => {
  const { poolId, roomId, onMembersAdded } = props;

  useKeyPressEvent("Enter", onMembersAdded);

  return (
    <RoomMembersLayout
      poolId={poolId}
      roomId={roomId}
      onMembersAdded={onMembersAdded}
    />
  );
};

export { RoomMembers };
