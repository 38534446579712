import { useMemo } from "react";
import {
  StyleProp,
  StyleSheet,
  useWindowDimensions,
  View,
  ViewStyle,
} from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { useKeyboardHeight } from "@kraaft/helper-hooks";
import { useConversationHeaderHeight } from "@kraaft/shared/core/utils/useConversationHeaderHeight";
import { useLayoutMeasurer } from "@kraaft/shared/core/utils/useLayoutMeasurer";
import { useSafeAreaInsets } from "@kraaft/shared/core/utils/useSafeAreaInsets";
import { PortalHost } from "@kraaft/ui";

export const MENTION_PORTAL_RENDERER_HOSTNAME =
  "MENTION_PORTAL_RENDERER_HOSTNAME";
export const MAX_MENTION_PAPER_HEIGHT = 300;

interface MentionPortalRendererProps {
  style?: StyleProp<ViewStyle>;
}

export const MentionPortalRenderer = ({
  style,
}: MentionPortalRendererProps) => {
  const keyboardHeight = useKeyboardHeight();
  const { top, bottom } = useSafeAreaInsets();
  const { height: screenHeight } = useWindowDimensions();
  const headerHeight = useConversationHeaderHeight();
  const [{ height: containerHeight }, handleLayout] = useLayoutMeasurer();

  const dodgeHeaderStyle = useMemo(() => {
    const takenSpaceTop = top + headerHeight;
    const anchorY = screenHeight - bottom - containerHeight - keyboardHeight;
    const height = anchorY - takenSpaceTop;

    return {
      height,
      transform: [{ translateY: -height }],
    };
  }, [
    bottom,
    containerHeight,
    headerHeight,
    keyboardHeight,
    screenHeight,
    top,
  ]);

  return (
    <>
      <View style={[styles.root, dodgeHeaderStyle, style]}>
        <PortalHost hostname={MENTION_PORTAL_RENDERER_HOSTNAME} />
      </View>
      <View style={styles.sizer} onLayout={handleLayout} pointerEvents="none" />
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    position: "absolute",
    width: "100%",
    pointerEvents: isNative() ? "box-none" : "none",
  },
  sizer: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
});
