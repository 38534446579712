import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { SearchBar } from "@kraaft/shared/components/searchBar";
import { styles } from "@kraaft/shared/components/searchHeader/searchHeader.styles";

enum SearchStateType {
  DEFAULT = 0,
  SEARCHING = 1,
}

interface Props {
  onFilterSet: (filter: string) => void;
  placeholder: string;
  children: (onSearch: () => void) => React.ReactElement;
  autoFocus?: boolean;
  onQuitSearch?: () => void;
}

const SearchHeader = ({
  onFilterSet,
  placeholder,
  autoFocus,
  children,
  onQuitSearch,
}: Props) => {
  const { t } = useTranslation();

  const [filter, _setFilter] = useState<string>("");
  const [searchState, setSearchState] = useState(SearchStateType.DEFAULT);

  const setFilter = useCallback(
    (newFilter: string) => {
      _setFilter(newFilter);
      onFilterSet(newFilter);
    },
    [onFilterSet],
  );

  const onLeftIconPress = useCallback(() => {
    setFilter("");
    setSearchState(SearchStateType.DEFAULT);
    onQuitSearch?.();
  }, [onQuitSearch, setFilter]);

  const onStartSearch = useCallback(() => {
    setSearchState(SearchStateType.SEARCHING);
  }, []);

  return (
    <>
      {children(onStartSearch)}
      {searchState === SearchStateType.SEARCHING && (
        <View style={styles.searchBar}>
          <DefaultLayoutHeader
            header={
              <SearchBar
                onChange={setFilter}
                value={filter}
                placeholder={placeholder}
                isStatic
                autoFocus={autoFocus}
              />
            }
            headerLeft={
              <HeaderSide>
                <HeaderIconButton
                  accessibilityLabel={t("close")}
                  icon="chevron-left"
                  onPress={onLeftIconPress}
                />
              </HeaderSide>
            }
            headerOverflow
          />
        </View>
      )}
    </>
  );
};

export { SearchHeader };
