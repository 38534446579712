import { EventChannel, eventChannel } from "redux-saga";
import { takeEvery } from "typed-redux-saga/macro";

import {
  RoomSchemaVisibilityActions,
  RoomSchemaVisibilityStateActions,
} from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.actions";
import { RoomSchemaVisibilityDelaySnapshot } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.optimistic";
import { RoomSchemaVisibility } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.state";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToRoomSchemaVisibilitySaga() {
  yield takeCountedDeep(
    RoomSchemaVisibilityActions.subscribe,
    RoomSchemaVisibilityActions.unsubscribe,
    subscribeToRoomSchemaVisibility,
    unsubscribeFromRoomSchemaVisibility,
    (action) => action.payload.roomId,
  );
}

type Meta =
  | EventChannel<{ roomId: string; data: RoomSchemaVisibility[] }>
  | undefined;

function createChannel(roomId: string) {
  return eventChannel<{ roomId: string; data: RoomSchemaVisibility[] }>(
    (emit) => {
      return Firestore.subscribeToRoomSchemaVisibility(
        roomId,
        (roomSchemaVisibility) =>
          emit({ roomId, data: [roomSchemaVisibility] }),
      );
    },
  );
}

function* receiveRoomSchemaVisibility(payload: {
  roomId: string;
  data: RoomSchemaVisibility[];
}) {
  yield* RoomSchemaVisibilityDelaySnapshot(
    RoomSchemaVisibilityStateActions.setWithMerge,
    payload,
  );
}

function* subscribeToRoomSchemaVisibility(
  registerMeta: (meta: Meta) => void,
  action: ReturnType<typeof RoomSchemaVisibilityActions.subscribe>,
) {
  const channel = createChannel(action.payload.roomId);
  registerMeta(channel);

  yield* takeEvery(channel, receiveRoomSchemaVisibility);
}

function* unsubscribeFromRoomSchemaVisibility(meta: Meta) {
  meta?.close();
}
