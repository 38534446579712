// MODULAR SCHEMA
export interface LockingColumn {
  columnKey: string;
  parentSection: Section;
}

export interface SectionLockLookup {
  section: Section;
  lockingColumns: LockingColumn[];
}

export interface SchemaLockLookup {
  elementSectionKey: Record<string, string>;
  sections: Record<string, SectionLockLookup>;
  isCurrentUserAuthorizedToUnlock: boolean;
}

// MODULAR RECORD
export interface SectionLockInfo {
  isLocked: boolean;
  lockedAt: Date | undefined;
  lockedBy: string | undefined;
  section: Section;
  highestLockedSectionKey: undefined | string;
  recordTitle: string;
  isCurrentUserAuthorizedToUnlock: boolean;
}

export const SECTION_LOCK_INFO_FALLBACK: SectionLockInfo = {
  isLocked: false,
  highestLockedSectionKey: undefined,
  recordTitle: "",
  lockedAt: undefined,
  lockedBy: undefined,
  isCurrentUserAuthorizedToUnlock: false,
  section: {
    key: "",
    name: "",
  },
};

// MISC
export type Section = {
  name: string;
  key: string;
};
