import {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { MarkerGeoJSON } from "@kraaft/shared/components/mapController/types";
import { Button } from "@kraaft/ui";

import { styles } from "./simpleClusterTooltip.styles";

interface SimpleClusterTooltipProps<ElementType> {
  markerCount: number;
  markers: MarkerGeoJSON<ElementType>[];
  contentWidth: number;
  renderItem: (element: ElementType) => ReactNode;
}

const SimpleClusterTooltip = <ElementType,>({
  markerCount,
  markers,
  contentWidth,
  renderItem,
}: SimpleClusterTooltipProps<ElementType>) => {
  const scrollviewRef = useRef<ScrollView>(null);

  const { t } = useTranslation();

  const [currentMarkerIndex, setCurrentMarkerIndex] = useState(0);

  const incrementIndex = useCallback(
    (event?: MouseEvent) => {
      if (!isNative()) {
        event?.stopPropagation();
      }
      setCurrentMarkerIndex((oldValue) => (oldValue + 1) % markerCount);
    },
    [markerCount],
  );

  const decrementIndex = useCallback(
    (event?: MouseEvent) => {
      if (!isNative()) {
        event?.stopPropagation();
      }
      setCurrentMarkerIndex(
        (oldValue) => (oldValue + markerCount - 1) % markerCount,
      );
    },
    [markerCount],
  );

  useEffect(() => {
    scrollviewRef.current?.scrollTo({
      x: contentWidth * currentMarkerIndex,
      animated: true,
    });
  }, [contentWidth, currentMarkerIndex, markerCount]);

  return (
    <View style={styles.tooltipContent}>
      <Button
        accessibilityLabel={t("previous")}
        icon="chevron-left"
        variant="TERTIARY"
        size="SMALL"
        onPress={decrementIndex}
      />
      <ScrollView
        ref={scrollviewRef}
        horizontal
        style={{ width: contentWidth }}
        scrollEnabled={false}
        showsHorizontalScrollIndicator={false}
      >
        {markers.map((marker) =>
          renderItem(marker.properties.marker.element.ref),
        )}
      </ScrollView>
      <Button
        accessibilityLabel={t("next")}
        icon="chevron-right"
        variant="TERTIARY"
        size="SMALL"
        onPress={incrementIndex}
      />
    </View>
  );
};

export { SimpleClusterTooltip };
