import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Toggle } from "@kraaft/shared/components/toggle";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Text } from "@kraaft/ui";
import { ColumnHeaderEditorProps } from "@kraaft/web/src/components/modularTable/components/types";

import { useStyles } from "./checkboxColumnEditor.styles";

export const CheckboxColumnEditor = (
  props: ColumnHeaderEditorProps<KColumnType.checkbox>,
) => {
  const { columnDefinition, setColumnDefinition } = props;
  const styles = useStyles();
  const { t } = useTranslation();

  const changeIsLockingSection = useCallback(
    (value: boolean) => {
      if (columnDefinition) {
        setColumnDefinition({
          ...columnDefinition,
          isLockingSection: value,
        });

        trackEvent({
          eventName: "Toggled Checkbox Column Locking Parent Section",
          newValue: value,
        });
      }
    },
    [columnDefinition, setColumnDefinition],
  );

  return (
    <div className={styles.container}>
      <Toggle
        value={columnDefinition?.isLockingSection ?? false}
        setValue={changeIsLockingSection}
      />
      <Text>{t("modularRecords.lockAnswersConfiguration")}</Text>
    </div>
  );
};
