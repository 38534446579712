import { makeStyles } from "@mui/styles";

import { ColorStyle, Radius, Spacing } from "@kraaft/ui";

interface Props {
  color: string;
}

export const useStyles = makeStyles({
  baseEditors: {
    "& > *": {
      marginBottom: Spacing.S8,
    },
  },
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flexGrow: 1,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    paddingLeft: Spacing.S8,
    paddingRight: Spacing.S8,
    borderRadius: Radius.SMALL,
    borderLeft: ({ color }: Props) => `8px solid ${color}`,
  },
  infos: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 56,
  },
  left: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
  right: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
    marginLeft: Spacing.S8,
  },
  outline: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: "none",
  },
  input: {
    width: "100%",
  },
  editorContainer: {
    marginTop: Spacing.S8,
    "& > div > :not(:last-child)": {
      marginBottom: Spacing.S8,
    },
  },
});
