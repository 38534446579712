import { Message } from "@kraaft/shared/core/modules/message/messageState";
import { hasAttachment } from "@kraaft/shared/core/modules/message/messageUtils";
import {
  getModularFolderAttachmentValue,
  incrementPendingOperations,
} from "@kraaft/shared/core/modules/modularFolder/operations/modularFolder.offline.tools";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Api } from "@kraaft/shared/core/services/api";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

export const addAttachmentFromMessageOperation =
  Operation.editOne<ModularFolder>()
    .payload<{
      roomId: string;
      columnKey: string | undefined;
      messageId: string;
    }>()
    .dependsOn<{
      getMessage(roomId: string, id: string): Message | undefined;
    }>()
    .augment((payload, { getMessage }) => {
      const message = getMessage(payload.roomId, payload.messageId);
      if (!message || !hasAttachment(message)) {
        return;
      }
      return { attachment: message.attachment };
    })
    .expected((modularFolder, payload) => {
      incrementPendingOperations(modularFolder);
      if (!payload.attachment) {
        return;
      }
      const propertyValue = getModularFolderAttachmentValue(
        modularFolder,
        payload.columnKey,
      );
      propertyValue?.push(payload.attachment);
    })
    .mutate((payload) =>
      Api.addModularFolderAttachmentFromMessage({
        columnKey: payload.columnKey,
        folderId: payload.id,
        messageId: payload.messageId,
        roomId: payload.roomId,
      }),
    );
