import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { intercom } from "@kraaft/shared/core/modules/intercom/intercom.provider";
import {
  PermissionName,
  PermissionResult,
  Permissions,
} from "@kraaft/shared/core/services/permission/permissions.types";

export class BrowserPermissions extends Permissions {
  private webPermissionToStatus(
    webStatus: "default" | "denied" | "granted",
  ): PermissionResult {
    switch (webStatus) {
      case "denied":
        return "blocked";
      case "default":
        return "denied";
      case "granted":
        return "granted";
    }
  }

  async statusOf(type: PermissionName) {
    if (type === "notification") {
      if (!("Notification" in window)) {
        return "unavailable";
      }
      return this.webPermissionToStatus(Notification.permission);
    }
    return "unavailable";
  }

  async request(type: PermissionName) {
    if (type === "notification") {
      const status = this.webPermissionToStatus(
        await Notification.requestPermission(),
      );
      this.requestedPermissions();
      return status;
    }
    return "unavailable" as const;
  }

  async handleEnableBlockedPermission(type: PermissionName) {
    if (type === "notification") {
      return intercom.openArticle(
        getEnvironment().INTERCOM.ARTICLES.ENABLE_WEB_NOTIFICATIONS,
      );
    }
  }
}
