import { makeStyles } from "@mui/styles";

export const useBasicCellStyle = makeStyles({
  basicCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block",
    textOverflow: "ellipsis",
    minHeight: 1, // We need this for the onPaste event to be fired when value is empty
  },
  loaderContainer: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
