import { StyleSheet } from "react-native";

import { styleObject } from "@kraaft/shared/styles/panel.styles";
import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  headerMessageSelectedCross: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: 12,
  },
  headerMessageSelectedTitle: {
    fontWeight: "500",
    fontSize: FontSize.TITLE,
    letterSpacing: -0.09,
  },
  headerMessageSelected: {
    backgroundColor: Color.WHITE,
    ...styleObject.headerLayout,
    ...styleObject.borderBottom,
    top: 0,
    height: 50,
  },
  footerMessageSelected: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    ...styleObject.borderTop,
  },
  headerContainer: {
    backgroundColor: Color.WHITE,
    ...styleObject.headerLayout,
    ...styleObject.borderBottom,
    paddingHorizontal: Spacing.S16,
    height: 50,
  },
  headerTitle: {
    flex: 1,
    justifyContent: "center",
  },
  detailsButton: {
    padding: 5,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
  },
  members: {
    marginRight: Spacing.S16,
  },
});
