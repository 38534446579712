import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  mapContainer: {
    position: "relative",
    display: "flex",
    flex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },

  screenshoter: {
    flex: 1,
  },

  mapOverlay: {
    position: "absolute",
    bottom: Spacing.S16,
    display: "flex",
    gap: Spacing.S16,
    alignItems: "center",
  },
});
