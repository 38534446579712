import { useMemo } from "react";
import { BBox } from "geojson";
import { flatten } from "lodash";

import { markerSize } from "@kraaft/shared/components/geolocation/baseMarker/baseMarkerConfig";
import { IdentityMarker } from "@kraaft/shared/components/geolocation/identityMarker";
import { useGroupedClusters } from "@kraaft/shared/components/mapController/hooks/useGroupedClusters";
import { Cluster } from "@kraaft/shared/components/mapController/markers/marker/cluster";
import { ClusterConfig } from "@kraaft/shared/components/mapController/markers/marker/cluster/cluster.types";
import {
  MAP_PROBABLE_MAX_ZOOM_LIMIT,
  MAX_MAP_ZOOM,
} from "@kraaft/shared/components/mapController/markers/marker/marker.utils";
import {
  getMarkerCoordinates,
  isMarkerCluster,
} from "@kraaft/shared/components/mapController/markers/markerUtils";
import {
  DistributedMapMarker,
  ElementTypeFromMarker,
  MapProps,
} from "@kraaft/shared/components/mapController/types";

const useMapMarkers = <Marker extends DistributedMapMarker>(
  markers: MapProps<Marker>["markers"],
  bounds: BBox | undefined,
  zoom: number | undefined,
  enableClustering = false,
  clustersConfig?: Record<
    string,
    ClusterConfig<ElementTypeFromMarker<Marker>> | undefined
  >,
) => {
  const markerType = useMemo(
    () =>
      markers.some((marker) => marker.renderType === "big") ? "big" : "small",
    [markers],
  );

  const superclusterOptions = useMemo(
    () => ({
      Radius: markerType === "big" ? 100 : 43,
      maxZoom: MAX_MAP_ZOOM,
    }),
    [markerType],
  );

  const { groupedClusters, markersWithinBounds } = useGroupedClusters(
    enableClustering,
    markers,
    bounds,
    zoom,
    superclusterOptions,
  );

  return useMemo(
    () => ({
      components: [
        ...flatten(
          Object.entries(groupedClusters.withAffiliation).map(
            ([affiliation, clusterGroup]) =>
              clusterGroup.markers.map((marker) => {
                const { longitude, latitude } = getMarkerCoordinates(marker);

                const key = marker.properties.cluster
                  ? marker.id
                  : marker.properties.marker.key;

                const clusterConfig = clustersConfig?.[affiliation];

                if (isMarkerCluster(marker)) {
                  return (
                    <IdentityMarker key={key} lat={latitude} lng={longitude}>
                      <Cluster
                        size={markerSize}
                        cluster={marker}
                        superclusterInstance={clusterGroup.superclusterInstance}
                        isMaxAllowedZoom={zoom === MAX_MAP_ZOOM}
                        isMaybeMaxZoom={
                          (zoom ?? 0) >= MAP_PROBABLE_MAX_ZOOM_LIMIT
                        }
                        {...clusterConfig}
                      />
                    </IdentityMarker>
                  );
                }

                return (
                  <IdentityMarker key={key} lat={latitude} lng={longitude}>
                    {marker.properties?.marker.Marker}
                  </IdentityMarker>
                );
              }),
          ),
        ),
        ...groupedClusters.withoutAffiliation.map((marker) => {
          const { longitude, latitude } = getMarkerCoordinates(marker);

          return (
            <IdentityMarker
              key={marker.properties.marker.key}
              lat={latitude}
              lng={longitude}
            >
              {marker.properties?.marker.Marker}
            </IdentityMarker>
          );
        }),
      ],
      markersWithinBounds,
    }),
    [
      clustersConfig,
      groupedClusters.withAffiliation,
      groupedClusters.withoutAffiliation,
      markersWithinBounds,
      zoom,
    ],
  );
};

export { useMapMarkers };
