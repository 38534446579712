import { makeStyles } from "@mui/styles";

import { ColorStyle, Radius } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    position: "relative",
    height: "inherit",
    alignSelf: "flex-start",
  },

  hoveredContainer: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: Radius.SMALL,
  },

  "@keyframes blink": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },

  blinkingContainer: {
    animation: "$blink",
    animationDuration: ".2s",
    animationIterationCount: 3,
  },
});
