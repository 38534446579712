import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet } from "react-native";

import { Icon, Spacing, Text } from "@kraaft/ui";

type DisplayMoreButtonProps = {
  onPress: () => void;
  isOn: boolean;
  moreCount: number;
};

const DisplayMoreButton = ({
  onPress,
  isOn,
  moreCount,
}: DisplayMoreButtonProps) => {
  const { t } = useTranslation();
  const containerStyle = useMemo(
    () => [styles.container, isOn && styles.containerOn],
    [isOn],
  );

  const text = useMemo(
    () => (isOn ? t("showLess") : t("displayMoreCount", { count: moreCount })),
    [isOn, moreCount, t],
  );

  return (
    <Pressable
      onPress={onPress}
      accessibilityLabel={text}
      style={containerStyle}
    >
      <Icon
        name={isOn ? "chevron-up" : "chevron-down"}
        size="MINI"
        color="ACTION_CTA"
      />
      <Text size="MEDIUM" color="ACTION_CTA">
        {text}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    gap: Spacing.S8,
    paddingHorizontal: Spacing.S16,
    paddingVertical: Spacing.S4,
  },
  containerOn: {
    marginTop: Spacing.S8,
  },
});

export { DisplayMoreButton };
