import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { useGetOrLoadUsers } from "@kraaft/shared/core/modules/user/useGetOrLoadUsers";
import { selectAllPoolUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import {
  ActionNotification as ActionNotificationType,
  ActionUserRef,
  TemporaryWorkflow,
} from "@kraaft/shared/core/modules/workflows/types";
import { Spacing } from "@kraaft/ui";
import { ActionInput } from "@kraaft/web/src/views/settings/workflows/actions/ActionInput";
import { FilteredDropdownActionUserRef } from "@kraaft/web/src/views/settings/workflows/actions/FilteredDropdownActionUserRef";
import { WorkflowSubSection } from "@kraaft/web/src/views/settings/workflows/components/workflowSubSection";
import { useWorkflowMentionPrefix } from "@kraaft/web/src/views/settings/workflows/workflowUtils";

import { InputTip } from "./inputTip";

interface ActionNotificationProps {
  payload: ActionNotificationType["payload"];
  onChange: (payload: ActionNotificationType["payload"]) => void;
  onErrorStateChange?: (newState: boolean) => void;
  workflow: TemporaryWorkflow;
}

export const ActionNotification = ({
  payload,
  onChange,
  onErrorStateChange,
  workflow,
}: ActionNotificationProps) => {
  const { t } = useTranslation();
  const mentionPrefix = useWorkflowMentionPrefix(workflow.schemaId);

  const poolId = useSelector(selectCurrentPoolId);
  const users = useSelector(selectAllPoolUsers(poolId, true));
  const getOrLoadUsers = useGetOrLoadUsers();
  const schema = useSelector(selectSchema(workflow.schemaId));

  const handleContentChange = useCallback(
    (newText: string) => {
      const newPayload = { ...payload };
      newPayload.content = newText;
      onChange(newPayload);
    },
    [onChange, payload],
  );

  const handleDestChange = useCallback(
    (actionUserRefs: ActionUserRef[]) => {
      const newPayload: typeof payload = {
        ...payload,
        users: actionUserRefs,
      };
      onChange(newPayload);
    },
    [onChange, payload],
  );

  if (!schema || !poolId) {
    return null;
  }

  return (
    <View style={style.actionContainer}>
      <WorkflowSubSection title={t("recipient")}>
        <FilteredDropdownActionUserRef
          mentionPrefix={mentionPrefix}
          schema={schema}
          poolUsers={users}
          poolId={poolId}
          getUsers={getOrLoadUsers}
          items={payload.users}
          onChange={handleDestChange}
        />
      </WorkflowSubSection>
      <WorkflowSubSection title={t("message")}>
        <ActionInput
          placeholder={t("workflow.actions.notification.messagePlaceholder")}
          workflow={workflow}
          onChange={handleContentChange}
          onErrorStateChange={onErrorStateChange}
          defaultValue={payload.content}
        />
        <InputTip>{t("suggestionTip")}</InputTip>
      </WorkflowSubSection>
    </View>
  );
};

const style = StyleSheet.create({
  actionContainer: {
    rowGap: Spacing.S16,
  },
});
