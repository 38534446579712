import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  tableContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  addRow: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
});
