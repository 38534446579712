import { StyleSheet } from "react-native";

import { Color, ColorStyle, FontSize, Radius } from "@kraaft/ui";

export const styles = StyleSheet.create({
  text: {
    fontFamily: "Apercu",
    fontWeight: "400",
    fontSize: FontSize.TITLE,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  toggle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Color.WHITE,
    borderRadius: Radius.SMALL,
  },
  smallText: {
    fontSize: FontSize.BODY,
  },
});
