import GoogleLogo from "../assets/google_logo.png";

import { styles } from "./poweredBy.styles";

const PoweredBy = () => {
  return (
    <div style={styles.container}>
      <span style={styles.text}>powered by</span>
      <div style={styles.logoContainer}>
        <img src={GoogleLogo} style={styles.logo} alt="google" />
      </div>
    </div>
  );
};

export { PoweredBy };
