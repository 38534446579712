import { KraaftLogo } from "@kraaft/shared/components/kraaftLogo";
import { AskPoolCompanyIndustry } from "@kraaft/shared/components/onboarding/askPoolCompanyIndustry";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";

const AskPoolCompanyIndustryWeb = () => (
  <SplitScreenWrapper headerLeft={<KraaftLogo />}>
    <AskPoolCompanyIndustry />
  </SplitScreenWrapper>
);

export { AskPoolCompanyIndustryWeb };
