import { StyleSheet } from "react-native";

import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  list: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    backgroundColor: Color.WHITE,
  },
  emptyList: {
    flexGrow: 1,
    backgroundColor: Color.WHITE,
  },
  noResultsContainer: {
    paddingHorizontal: Spacing.S16,
    paddingTop: Spacing.S8,
    paddingBottom: Spacing.S16,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Color.WHITE,
  },
});
