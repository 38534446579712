import { useMemo } from "react";
import { StyleProp, StyleSheet, TextStyle } from "react-native";

import { TextProcessor } from "@kraaft/shared/components/textProcessor/textProcessor";
import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { Color, Text } from "@kraaft/ui";

import {
  BasicMentionPartitionTextView,
  ContactInfoMentionPartitionTextView,
} from "./mentionPartitionTextView";

export interface PartitionTextViewProps {
  partitions: InputPartition[];
  linkStyle?: StyleProp<TextStyle>;
  onLongPress?: () => void;
  black?: boolean;
  textStyle?: StyleProp<TextStyle>;
  numberOfLines?: number;
  highlight?: string[];
}

function getKeyFromMentionPartition(inputPartition: InputPartition) {
  return inputPartition.type === "mention"
    ? `user-${inputPartition.userId}`
    : "everyone";
}

export const PartitionTextView = ({
  partitions,
  linkStyle,
  onLongPress,
  black,
  textStyle,
  numberOfLines,
  highlight,
}: PartitionTextViewProps) => {
  const textProcessorProps = useMemo(
    () => ({
      linkStyle: linkStyle,
      onLongPress: onLongPress,
      textStyle: [black ? styles.theirText : styles.myText, textStyle],
    }),
    [black, linkStyle, onLongPress, textStyle],
  );

  return (
    <Text numberOfLines={numberOfLines} style={textStyle}>
      {partitions.map((partition, index) => {
        if (partition.type === "text") {
          return (
            <TextProcessor
              key={`${partition.content}-${index}`}
              {...textProcessorProps}
              enableLinks
              enablePhone
              highlight={highlight}
            >
              {partition.content}
            </TextProcessor>
          );
        }
        if (partition.type === "mention") {
          return (
            <ContactInfoMentionPartitionTextView
              key={`${getKeyFromMentionPartition(partition)}-${index}`}
              partition={partition}
              textStyle={textStyle}
              black={black}
            />
          );
        }
        if (partition.type === "mention-everyone") {
          return (
            <BasicMentionPartitionTextView
              key={`${getKeyFromMentionPartition(partition)}-${index}`}
              partition={partition}
              textStyle={textStyle}
              black={black}
            />
          );
        }
        return null;
      })}
    </Text>
  );
};

const styles = StyleSheet.create({
  myText: {
    color: Color.WHITE,
  },
  theirText: {
    color: Color.BLACK,
  },
});
