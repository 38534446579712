import { createReducer } from "@reduxjs/toolkit";

import { UserPoolStateActions } from "@kraaft/shared/core/modules/userPool/userPool.actions";
import { UserPoolState } from "@kraaft/shared/core/modules/userPool/userPool.state";

const initialState: UserPoolState = {};

const userPoolReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserPoolStateActions.setUserPool, (state, { payload }) => {
    state[payload.poolId] = payload.userPool;
  });

  addCase(UserPoolStateActions.setNotificationFilter, (state, { payload }) => {
    state[payload.poolId] = {
      notificationFilter: payload.notificationFilter,
    };
  });
});

export { userPoolReducers };
