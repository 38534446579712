import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { TrackableViewProps } from "@kraaft/shared/core/types";
import { IconName } from "@kraaft/ui";

export type Props = TrackableViewProps & {
  side: "left" | "right";
  onPress: () => void;
};

const ConversationDetailButton = ({
  accessibilityLabel,
  side,
  onPress,
}: Props) => {
  const iconName: IconName =
    side === "left" ? "chevron-left-double" : "chevron-right-double";

  return (
    <HeaderIconButton
      accessibilityLabel={accessibilityLabel}
      variant="TERTIARY"
      icon={iconName}
      onPress={onPress}
    />
  );
};

export { ConversationDetailButton };
