import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ImportImageMethodSheetProps } from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/importImageMethodSheet/importImageMethodSheet";
import { ActionSheetContent, ActionSheetItem } from "@kraaft/ui";

export const ImportImageMethodSheetContent = ({
  onPickImage,
  handleImageFromConversation,
  onClose,
}: ImportImageMethodSheetProps) => {
  const { t } = useTranslation();

  const handleAddFromPhotoGallery = useCallback(() => {
    onClose();
    handleImageFromConversation();
  }, [handleImageFromConversation, onClose]);

  const handleAddFromComputer = useCallback(() => {
    onClose();
    onPickImage?.();
  }, [onClose, onPickImage]);

  return (
    <ActionSheetContent>
      <ActionSheetItem
        icon="image-03"
        label={t("directoryActions.addFromPhotoGallery")}
        onClick={handleAddFromPhotoGallery}
      />
      <ActionSheetItem
        icon="share-01"
        label={t("directoryActions.addFromComputer")}
        onClick={handleAddFromComputer}
      />
    </ActionSheetContent>
  );
};
