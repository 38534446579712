import { httpsCallable } from "@kraaft/shared/core/services/firebase/firebaseUtils";

export const AiDirectoryApi = {
  enableAiDirectory: async (payload: {
    poolId: string;
  }): Promise<void> => {
    await httpsCallable("enableAiDirectory")(payload);
  },

  disableAiDirectory: async (payload: {
    poolId: string;
  }): Promise<void> => {
    await httpsCallable("disableAiDirectory")(payload);
  },

  resetAiDirectory: async (payload: {
    poolId: string;
  }): Promise<void> => {
    await httpsCallable("resetAiDirectory")(payload);
  },

  addAiCategory: async (payload: {
    poolId: string;
    name: string;
  }): Promise<string> => {
    return (await httpsCallable("addAiCategory")(payload)).data;
  },

  addDirectoryFromAiCategory: async (payload: {
    poolId: string;
    roomId: string;
    parentDirectoryId: string;
    categoryId: string;
  }): Promise<void> => {
    await httpsCallable("addDirectoryFromAiCategory")(payload);
  },

  deleteAiCategory: async (payload: {
    poolId: string;
    categoryId: string;
  }): Promise<void> => {
    await httpsCallable("deleteAiCategory")(payload);
  },

  renameAiCategory: async (payload: {
    poolId: string;
    categoryId: string;
    name: string;
  }): Promise<void> => {
    await httpsCallable("renameAiCategory")(payload);
  },
};
