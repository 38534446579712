import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { ActionFooter } from "@kraaft/shared/components/actionFooter/actionFooter";
import { AvatarEmojiPicker } from "@kraaft/shared/components/avatarEmojiPicker";
import { AvatarEmojiPickerHandle } from "@kraaft/shared/components/avatarEmojiPicker/avatarEmojiPicker";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { RoomCardType } from "@kraaft/shared/components/roomCard/roomCardUtils";
import { Tip } from "@kraaft/shared/components/tip";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Button, Color, Spacing } from "@kraaft/ui";

interface NameAndEmojiWithTipProps {
  onSubmit: (title: string, emoji: string) => void;
  isLoading: boolean;
}

export const NameAndEmojiWithTip = ({
  onSubmit,
  isLoading,
}: NameAndEmojiWithTipProps) => {
  const { t } = useTranslation();

  const emojiPickerRef = useRef<AvatarEmojiPickerHandle>(null);
  const [newRoomTitle, setNewRoomTitle] = useState("");
  const [emoji, setEmoji] = useState("💬");

  const isValidTitle = newRoomTitle.trim().length > 0;

  const handlePress = useCallback(() => {
    emojiPickerRef.current?.openEmojiSelector();
  }, []);

  const handleChangeEmoji = useCallback((newEmoji: string) => {
    trackEvent({
      eventName: "Change New Room Emoji",
      emoji: newEmoji,
    });
    setEmoji(newEmoji);
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(newRoomTitle, emoji);
  }, [emoji, newRoomTitle, onSubmit]);

  return (
    <>
      <View style={styles.content}>
        <View style={styles.emojiPickerContainer}>
          <AvatarEmojiPicker
            ref={emojiPickerRef}
            emoji={emoji}
            setEmoji={handleChangeEmoji}
            isPrivate={false}
            roomCardType={RoomCardType.DEFAULT}
            recentEmojiSpace="room_emoji"
          />
          <Button
            text={t("createRoom.roomName.editEmoji")}
            variant="QUATERNARY"
            onPress={handlePress}
          />
        </View>
        <KInput
          label={t("createRoom.roomName.nameInput")}
          value={newRoomTitle}
          onChangeText={setNewRoomTitle}
          disableAutocomplete
          autoFocus
          returnKeyType="done"
          onSubmitEditing={handleSubmit}
        />
        <View style={styles.tipContainer}>
          <Tip text={t("tips.setRoomName")} />
        </View>
      </View>
      <View style={styles.buttonSpacer} />
      <ActionFooter backgroundColor={Color.WHITE}>
        <Button
          accessibilityLabel={t("createRoom.roomName.createButton")}
          text={t("createRoom.roomName.createButton")}
          disabled={!isValidTitle}
          loading={isLoading}
          onPress={handleSubmit}
        />
      </ActionFooter>
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    marginTop: Spacing.S16,
    marginHorizontal: Spacing.S16,
  },
  emojiPickerContainer: {
    alignItems: "center",
  },
  tipContainer: {
    marginTop: Spacing.S12,
  },
  buttonSpacer: {
    height: Spacing.S40,
    flexGrow: 1,
    flexShrink: 1,
  },
});
