import { StyleSheet } from "react-native";

import { Color } from "@kraaft/ui";

export const styles = StyleSheet.create({
  panelContainer: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: Color.WHITE,
    alignItems: "center",
    paddingTop: 80,
    paddingHorizontal: 50,
  },
  panelTitle: {
    marginBottom: 20,
    textAlign: "center",
  },
  panelMessage: {
    marginBottom: 20,
    textAlign: "center",
  },
  panelImage: {
    height: 275,
    width: "100%",
  },
});
