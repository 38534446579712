import { KraaftLogo } from "@kraaft/shared/components/kraaftLogo";
import { AskUserJob } from "@kraaft/shared/components/onboarding/askUserJob";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";

const AskUserJobWeb = () => {
  return (
    <SplitScreenWrapper headerLeft={<KraaftLogo />}>
      <AskUserJob />
    </SplitScreenWrapper>
  );
};

export { AskUserJobWeb };
