import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EmojiSelector } from "@kraaft/shared/components/emojiSelector/emojiSelector";
import { EmojiStateActions } from "@kraaft/shared/core/modules/emoji/emoji.actions";
import { selectRecentEmojis } from "@kraaft/shared/core/modules/emoji/emoji.selectors";
import { RecentEmojiSpace } from "@kraaft/shared/core/modules/emoji/emoji.state";
import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";

interface RecentAwareEmojiSelectorProps {
  width: number;
  poolLanguage: PoolLanguage | undefined;
  onEmojiSelected: (emoji: string) => void;
  recentEmojiSpace: RecentEmojiSpace;
  isAnimating?: boolean;
}

export const RecentAwareEmojiSelector = ({
  onEmojiSelected,
  poolLanguage,
  width,
  isAnimating,
  recentEmojiSpace,
}: RecentAwareEmojiSelectorProps) => {
  const dispatch = useDispatch();
  const recentEmojis = useSelector(selectRecentEmojis(recentEmojiSpace));

  const handleEmojiSelected = useCallback(
    (emoji: string) => {
      onEmojiSelected(emoji);
      setTimeout(
        () =>
          dispatch(EmojiStateActions.used({ space: recentEmojiSpace, emoji })),
        // If used in a sheet, it dispatches after the popup has been closed,
        // to prevent another useless render to happen
        250,
      );
    },
    [dispatch, onEmojiSelected, recentEmojiSpace],
  );

  return (
    <EmojiSelector
      recentEmojis={recentEmojis}
      onEmojiSelected={handleEmojiSelected}
      poolLanguage={poolLanguage}
      width={width}
      isAnimating={isAnimating}
    />
  );
};
