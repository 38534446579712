import findLast from "lodash/findLast";
import { call, select } from "typed-redux-saga/macro";

import { selectIsActive } from "@kraaft/shared/core/modules/app/appSelector";
import { MessageDataStateActions } from "@kraaft/shared/core/modules/message/messageData/messageData.actions";
import { UserMessage } from "@kraaft/shared/core/modules/message/messageState";
import {
  isPersisted,
  isUserMessage,
  sortMessages,
} from "@kraaft/shared/core/modules/message/messageUtils";
import { focusRoom } from "@kraaft/shared/core/modules/room/roomActions";
import {
  selectIsCurrentUserRoomMember,
  selectRoom,
  selectRoomUserHistory,
} from "@kraaft/shared/core/modules/room/selectors";
import { Api } from "@kraaft/shared/core/services/api";
import { takeLatestDeep, waitFor } from "@kraaft/shared/core/utils/sagas";

export function* acknowledgeReadingMessageSaga() {
  yield* takeLatestDeep(
    MessageDataStateActions.addMessages,
    acknowledgeReadingNewMessage,
    ({ payload: { roomId } }) => roomId,
  );

  yield* takeLatestDeep(
    focusRoom,
    acknowledgeReadingUnreadRoom,
    ({ payload: { roomId } }) => roomId,
  );
}

function* acknowledgeReadingUnreadRoom({
  payload: { roomId },
}: ReturnType<typeof focusRoom>) {
  const roomUserHistory = yield* select(selectRoomUserHistory(roomId));
  if (!roomUserHistory?.isMarkedUnread) {
    return;
  }

  const room = yield* select(selectRoom(roomId));
  const latestMessage = room?.lastMessage;
  if (!latestMessage) {
    return;
  }

  yield* acknowledgeReadingMessage({
    latestUserMessageId: latestMessage.id,
    roomId: room.id,
  });
}

const lastReadMessagesByRoom: {
  // Store latest createdAt read by room id
  [roomId: string]: number;
} = {};

function* acknowledgeReadingNewMessage({
  payload: { roomId, messages },
}: ReturnType<typeof MessageDataStateActions.addMessages>) {
  const isInRoom = yield* select(selectIsCurrentUserRoomMember(roomId));

  if (!isInRoom) {
    return;
  }

  // Wait for browser tab to be focused
  yield* waitFor(selectIsActive);

  const lastReadForRoomCreatedAt = lastReadMessagesByRoom[roomId] ?? 0;

  const latestUserMessage = findLast(
    sortMessages(messages),
    (message): message is UserMessage =>
      isUserMessage(message) && isPersisted(message),
  );
  if (!latestUserMessage) {
    return;
  }
  const latestUserMessageCreatedAt = latestUserMessage.createdAt.getTime();
  if (latestUserMessageCreatedAt < lastReadForRoomCreatedAt) {
    return;
  }
  yield* acknowledgeReadingMessage({
    roomId,
    latestUserMessageId: latestUserMessage.id,
  });
}

function* acknowledgeReadingMessage({
  roomId,
  latestUserMessageId,
}: { roomId: string; latestUserMessageId: string }) {
  const isInRoom = yield* select(selectIsCurrentUserRoomMember(roomId));

  if (!isInRoom) {
    return;
  }

  try {
    yield* call(Api.acknowledgeReading, {
      roomId,
      messageId: latestUserMessageId,
    });
  } catch (e) {}
}
