import { useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { ActionCard } from "@kraaft/shared/components/actionCard";
import { useDirectoryMenuActions } from "@kraaft/shared/components/directory/editDirectoryMenu";
import { RenameDirectorySheet } from "@kraaft/shared/components/directory/renameDirectorySheet";
import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import { selectChildFileCount } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { ContextMenuView, ContextMenuViewHandle, Preloader } from "@kraaft/ui";

export interface DirectoryListCardProps {
  directory: Directory;
  roomId: string;
  disabled: boolean;
  onPress: (directoryId: string) => void;
  loading?: boolean;
}

export const DirectoryListCard = (props: DirectoryListCardProps) => {
  const { directory, disabled, onPress, loading } = props;

  const childFileCount = useSelector(selectChildFileCount(directory.id));

  const onPressCard = useCallback(() => {
    if (!directory.optimistic) {
      onPress(directory.id);
    }
  }, [directory.id, directory.optimistic, onPress]);

  const [isRenameDialogOpen, openRenameDialog, closeRenameDialog] =
    useBooleanState();

  const { options, isDownloading } = useDirectoryMenuActions({
    roomId: props.roomId,
    directory: props.directory,
    onOpenRenameDirectoryDialog: openRenameDialog,
  });

  const isLoading = useMemo(() => {
    return isDownloading || loading;
  }, [isDownloading, loading]);

  const contextMenuRef = useRef<ContextMenuViewHandle>(null);

  const handleActionCardLongPress = useCallback(() => {
    if (isNative()) {
      contextMenuRef.current?.open?.();
    }
  }, []);

  return (
    <>
      <ContextMenuView data={options} ref={contextMenuRef}>
        <ActionCard
          id={`ide2e-ticket-${directory.name}`}
          key={directory.id}
          disabled={disabled}
          greyedWhenDisabled
          text={directory.name}
          iconName="folder-fill"
          description={`${childFileCount || ""}`}
          action={onPressCard}
          showBracket={!directory.optimistic}
          onLongPress={handleActionCardLongPress}
          content={
            directory.optimistic || isLoading ? (
              <Preloader size="small" />
            ) : null
          }
        />
      </ContextMenuView>
      <RenameDirectorySheet
        roomId={props.roomId}
        directory={directory}
        open={isRenameDialogOpen}
        onClose={closeRenameDialog}
      />
    </>
  );
};
