import { StyleSheet } from "react-native";

import { ensureValidStyleSheet } from "@kraaft/shared/core/utils";
import { Color, Radius } from "@kraaft/ui";

export const nativeStylesObject = {
  modalContainer: {
    justifyContent: "center" as const,
    height: "100%",
    flex: 1,
  },
  container: {
    backgroundColor: Color.WHITE,
    position: "relative" as const,
    borderRadius: Radius.SMALL,
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.4)",
    maxHeight: "100%",
  },

  fullHeightContainer: {
    height: "100%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.55)",
  },
  content: {
    borderRadius: Radius.SMALL,
    flexShrink: 1,
  },
};

export const styles = StyleSheet.create(
  ensureValidStyleSheet(nativeStylesObject),
);
