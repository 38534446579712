import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Linking, Text, View } from "react-native";
import { useSelector } from "react-redux";

import { selectInviteState } from "@kraaft/shared/core/modules/app/appSelector";
import { Api } from "@kraaft/shared/core/services/api";
import { Button } from "@kraaft/ui";

import { CongratsContainer } from "./congratsContainer";
import { getCongratsMessage } from "./getCongratsMessage";

import { styles } from "./congratulations.styles";

const MobileWebCongratsPanel = () => {
  const { t } = useTranslation();
  const inviteState = useSelector(selectInviteState);

  // load deep link asap
  const [urlPromise] = useState(() =>
    Api.generateAuthenticationDeepLink({
      poolId: inviteState.status === "joined" ? inviteState.poolId : undefined,
    }),
  );

  const onPress = useCallback(async () => {
    const url = await urlPromise;
    await Linking.openURL(url);
  }, [urlPromise]);

  const congratsMessage = getCongratsMessage(inviteState, t);

  return (
    <CongratsContainer>
      <Text nativeID="ide2e-congrats-title" style={[styles.text, styles.first]}>
        {congratsMessage}
      </Text>
      <View style={styles.bottomButtonContainer}>
        <Button
          disableAccessibility
          onPress={onPress}
          text={t("congratsScreenOpenApp")}
        />
      </View>
    </CongratsContainer>
  );
};

export { MobileWebCongratsPanel };
