/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { getSharepointErrorMessage } from "@kraaft/shared/core/modules/room/roomUtils";
import { useLoader } from "@kraaft/shared/core/utils/useLoader";
import {
  connectPoolToExternal,
  disconnectPoolToExternal,
} from "@kraaft/web/src/core/modules/poolAdmin/poolAdminActions";
import { selectPoolAdmin } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminSelectors";
import { makeDialogStyle } from "@kraaft/web/src/views/settings/manageIntegrations/sharePointSection/sharePointPoolSyncDialog.styles";

import {
  sharepointSyncMessage,
  SharepointUrlErrorBox,
  SharepointUrlInput,
  SharepointUrlValidationBox,
} from "../../../../components/editRoomSharepointLink/sharepointUrlInput";

const SharePointPoolSyncDialog = ({
  open,
  onClose,
  poolId,
}: {
  open: boolean;
  onClose: () => void;
  poolId: string;
}) => {
  const poolAdmin = useSelector(selectPoolAdmin(poolId));

  const { loaderId, loading, loader } = useLoader({
    prefix: "SharePointPoolSyncDialog",
  });
  const dispatch = useDispatch();
  const [url, setUrl] = useState<{ value: string; isValid: boolean }>({
    value: "",
    isValid: false,
  });
  const [lastConnectedUrl, setLastConnectedUrl] = useState("");
  const { t } = useTranslation();
  const classes = makeDialogStyle();

  const microsoftStorage = poolAdmin?.microsoftStoragePoolSync;
  const persistedUrl = microsoftStorage?.url ?? "";

  // clean input when pool changes
  useEffect(() => {
    setUrl({ value: persistedUrl, isValid: persistedUrl !== "" });
  }, [poolId, open, persistedUrl]);

  const update = useCallback(() => {
    if (url.value) {
      setLastConnectedUrl(url.value);
      dispatch(
        connectPoolToExternal({
          poolId,
          sharepointUrl: url.value,
          loaderId,
        }),
      );
    } else {
      newConfirmationAlert({
        title: t("disconnectMicrosoftStorageTitle"),
        message: t("disconnectMicrosoftStorageRoomContent"),
        confirmText: t("confirm"),
        onConfirm: () => {
          dispatch(
            disconnectPoolToExternal({
              poolId,
            }),
          );
        },
      });
    }
  }, [url.value, dispatch, poolId, loaderId, t]);

  // synchronization errors saved on the database
  const syncError = microsoftStorage?.error;

  // connection errors when trying to connectp
  const connectError = loader?.status === LoaderStatus.FAILURE && loader.error;
  const connectErrorMessage =
    connectError &&
    lastConnectedUrl === url.value &&
    getSharepointErrorMessage(connectError);

  const isUrlPersisted = url.value === persistedUrl;
  const isSynchronized = isUrlPersisted && Boolean(url.value);
  const canSynchronize = !isUrlPersisted && (url.isValid || !url.value);

  const actions: FooterActions = [
    {
      accessibilityLabel: t("validate"),
      text: t("validate"),
      onPress: update,
      loading: loading,
      disabled: !canSynchronize,
      variant: "PRIMARY",
    },
    {
      accessibilityLabel: t("cancel"),
      text: t("cancel"),
      onPress: onClose,
    },
  ];

  return (
    <KDialog
      title={t("sharePointPoolFolder")}
      subtitle={t("sharePointPoolDialogContent")}
      open={open}
      size={KDialogSize.MEDIUM}
      onClose={onClose}
      actions={actions}
      content={
        <div className={classes.dialogContentContainer}>
          <SharepointUrlInput
            url={url.value}
            setUrl={setUrl}
            loading={loading}
            synchronized={isSynchronized}
          />

          {isSynchronized && (
            <SharepointUrlValidationBox
              message={sharepointSyncMessage(
                t,
                microsoftStorage?.synchronizedAt,
              )}
              status="validated"
            />
          )}

          {connectErrorMessage && (
            <SharepointUrlValidationBox
              message={connectErrorMessage}
              status="erroneous"
            />
          )}

          {isSynchronized && syncError && (
            <SharepointUrlErrorBox error={syncError} />
          )}
        </div>
      }
    />
  );
};

export { SharePointPoolSyncDialog };
