import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { capitalize, isNative } from "@kraaft/helper-functions";
import { Pill } from "@kraaft/shared/components/pill";
import { ModularDisplayRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { dateFormatter } from "@kraaft/shared/core/services/dateFormatter/dateFormatter.provider";
import { type ResourceKey } from "@kraaft/shared/core/services/i18next";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { Sheet, Spacing, Text } from "@kraaft/ui";

interface EntryDetailsSheetProps {
  date: Date;
  i18nKey: ResourceKey;
  i18nValues: Record<string, unknown>;
  content: ReactNode;
}

export const EntryDetailsSheet = Sheet({
  native: "bottom",
  web: "anchored",
})
  .create<EntryDetailsSheetProps>(
    ({ Paper, Header, Content }) =>
      ({ onClose, date, i18nKey, i18nValues, content }) => {
        const { t } = useTranslation();

        return (
          <ModularDisplayRequirementsProvider>
            <Paper width={365}>
              {isNative() && (
                <Header onClose={onClose}>{t("history.details")}</Header>
              )}
              <Content>
                <View style={styles.headerContainer}>
                  <View style={styles.pillContainer}>
                    <Pill
                      icon="calendar"
                      content={capitalize(dateFormatter.format(date))}
                      size="small"
                    />
                  </View>
                  <Text size="BODY" style={styles.text}>
                    <Trans
                      i18nKey={i18nKey}
                      values={i18nValues}
                      components={{
                        bold: <Text size="BODY" weight="bold" />,
                      }}
                    />
                  </Text>
                </View>

                {content}
              </Content>
            </Paper>
          </ModularDisplayRequirementsProvider>
        );
      },
  )
  .withDefaults({ placement: "left-start", withoutBackdrop: true });

const styles = StyleSheet.create({
  pillContainer: {
    alignItems: "flex-start",
  },
  text: {
    flexShrink: 1,
  },
  headerContainer: {
    gap: Spacing.S8,
    marginBottom: Spacing.S12,
  },
});
