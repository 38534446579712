import { useCallback } from "react";
import { useSelector } from "react-redux";

import { NotificationPreferences } from "@kraaft/shared/components/notificationPreferences/notificationPreferences";
import { useRoomNotificationFilter } from "@kraaft/shared/core/modules/notifications/roomNotificationSheet/roomNotificationFilter.hooks";
import { RoomNotificationFilter } from "@kraaft/shared/core/modules/room/roomState";
import { selectRoomNotificationFilter } from "@kraaft/shared/core/modules/room/selectors/roomOrRoomCardSelectors";
import { selectUserPoolNotificationFilter } from "@kraaft/shared/core/modules/userPool/userPool.selectors";
import { RoomNotificationFilterSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

interface RoomNotificationPreferencesProps {
  poolId: string;
  roomId: string;
  source: RoomNotificationFilterSource;
  onChanged?: () => void;
}

export const RoomNotificationPreferences = ({
  poolId,
  roomId,
  source,
  onChanged,
}: RoomNotificationPreferencesProps) => {
  const poolOption = useSelector(selectUserPoolNotificationFilter(poolId));
  const option = useSelector(selectRoomNotificationFilter(roomId));

  const { changeNotificationFilter } = useRoomNotificationFilter({
    roomId,
    source,
  });

  const handleChangeNotificationFilter = useCallback(
    (newFilter: RoomNotificationFilter) => {
      changeNotificationFilter?.(newFilter);
      onChanged?.();
    },
    [changeNotificationFilter, onChanged],
  );

  if (!changeNotificationFilter) {
    return null;
  }

  return (
    <NotificationPreferences
      type="room"
      poolNotificationFilter={poolOption}
      option={option}
      onChange={handleChangeNotificationFilter}
    />
  );
};
