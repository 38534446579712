/** Not implemented on the web */
interface Props {
  selectedCode: string;
  onChangeCode: (value: string) => void;
}

const CountryPicker = (_props: Props) => {
  return null;
};

export { CountryPicker };
