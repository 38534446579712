import { takeEvery } from "typed-redux-saga/macro";

import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { addSchemaViewSaga } from "@kraaft/shared/core/modules/schemaView/sagas/addSchemaView";
import { deleteSchemaViewSaga } from "@kraaft/shared/core/modules/schemaView/sagas/deleteSchemaView";
import { editSchemaViewSaga } from "@kraaft/shared/core/modules/schemaView/sagas/editSchemaView";
import { editSchemaViewNameSaga } from "@kraaft/shared/core/modules/schemaView/sagas/editSchemaViewName";
import { subscribeToSchemaViews } from "@kraaft/shared/core/modules/schemaView/sagas/subscribeToSchemaViews";
import * as schemaViewActions from "@kraaft/shared/core/modules/schemaView/schemaViewActions";

export function* schemaViewSagas() {
  yield* takeEvery(PoolStateActions.setPoolLocation, subscribeToSchemaViews);
  yield* takeEvery(schemaViewActions.addSchemaView, addSchemaViewSaga);
  yield* takeEvery(schemaViewActions.saveEditedSchemaView, editSchemaViewSaga);
  yield* takeEvery(schemaViewActions.deleteSchemaView, deleteSchemaViewSaga);
  yield* takeEvery(
    schemaViewActions.editSchemaViewName,
    editSchemaViewNameSaga,
  );
}
