import { createReducer } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";

import { updateSentMessagesCount } from "@kraaft/shared/core/modules/message/send/sendMessageActions";
import { PermissionRequestStrategyStateActions } from "@kraaft/shared/core/modules/permissionRequestStrategy/permissionRequestStrategy.actions";
import { PermissionRequestStrategyState } from "@kraaft/shared/core/modules/permissionRequestStrategy/permissionRequestStrategy.state";
import { storage } from "@kraaft/shared/core/modules/storage/storage.provider";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

const initialState: PermissionRequestStrategyState = {
  currentSessionUserId: undefined,
  numberOfSessions: 0,
  numberOfSentInvites: 0,
  numberOfSentMessages: 0,
};

const permissionRequestStrategyReducers = createReducer(
  initialState,
  ({ addCase }) => {
    addCase(
      PermissionRequestStrategyStateActions.setCurrentSessionUserId,
      (state, { payload }) => {
        state.currentSessionUserId = payload;
      },
    );

    addCase(
      PermissionRequestStrategyStateActions.incrementNumberOfSessions,
      (state) => {
        state.numberOfSessions += 1;
      },
    );

    addCase(
      PermissionRequestStrategyStateActions.incrementNumberOfSentInvites,
      (state) => {
        state.numberOfSentInvites += 1;
      },
    );

    addCase(updateSentMessagesCount, (state) => {
      state.numberOfSentMessages += 1;
    });

    addCase(UserActions.userDisconnectedFromFirebase, (state) => {
      state.currentSessionUserId = undefined;
      state.numberOfSessions = 0;
      state.numberOfSentInvites = 0;
      state.numberOfSentMessages = 0;
    });
  },
);

const permissionRequestStrategyPersistConfig: PersistConfig<PermissionRequestStrategyState> =
  {
    key: "permissionRequestStrategy",
    storage,
    version: 0,
    whitelist: [
      "numberOfSessions",
      "numberOfSentInvites",
      "numberOfSentMessages",
    ],
  };

const persistedReducer = persistReducer(
  permissionRequestStrategyPersistConfig,
  permissionRequestStrategyReducers,
);

export { persistedReducer as permissionRequestStrategyReducers };
