import { makeStyles } from "@mui/styles";

interface MakeStylesProps {
  isCellSelected: boolean;
}

export const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block",
    textOverflow: "ellipsis",
    minHeight: 1, // We need this for the onPaste event to be fired when value is empty
  },
  iconContainer: ({ isCellSelected }: MakeStylesProps) => ({
    cursor: "pointer",
    ...(isCellSelected ? {} : { display: "none" }),
  }),
});
