import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { selectMessageSelectionCount } from "@kraaft/shared/core/modules/message/messageSelectors";
import { MessageSelectionSource } from "@kraaft/shared/core/modules/message/messageState";
import { MiniMediaActions } from "@kraaft/shared/core/modules/miniMedia/miniMedia.actions";
import { MiniMedia } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { OpenDocumentSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

export function useFileCardOnPress(
  media: MiniMedia,
  directoryId: string | undefined,
  selectionSource: MessageSelectionSource,
  forceSelection: boolean,
  onSelect: () => void,
  trackingSource: OpenDocumentSource,
) {
  const dispatch = useDispatch();

  const selectionCount = useSelector(
    selectMessageSelectionCount(media.roomId, selectionSource),
  );

  const onPress = useCallback(() => {
    if (forceSelection || selectionCount > 0) {
      onSelect();
    } else {
      dispatch(
        MiniMediaActions.openMiniMedia({
          roomId: media.roomId,
          directoryId,
          messageId: media.messageId,
          trackingSource,
        }),
      );
    }
  }, [
    directoryId,
    dispatch,
    forceSelection,
    media.messageId,
    media.roomId,
    onSelect,
    selectionCount,
    trackingSource,
  ]);
  const onLongPress = isNative() ? onSelect : undefined;

  return { onPress, onLongPress };
}
