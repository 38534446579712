import { useCallback, useMemo } from "react";
import { t } from "i18next";

import {
  CompositeCondition,
  Condition,
} from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import {
  KSchemaColumn,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Button } from "@kraaft/ui";
import {
  FilterItem,
  FilterItemProps,
} from "@kraaft/web/src/components/filterMenu/filterItem";
import { FilterOperator } from "@kraaft/web/src/components/filterMenu/filterMenuTypes";

import { useStyles } from "./filterMenu.styles";

interface FilterMenuContentProps {
  columns: { [key: string]: { path: KSchemaSection[]; column: KSchemaColumn } };
  filters: CompositeCondition;
  setFilters: (filter: CompositeCondition) => void;
  disabledColumnTypes?: FilterItemProps["disabledColumnTypes"];
}

const FilterMenuContent = ({
  columns,
  filters,
  setFilters,
  disabledColumnTypes,
}: FilterMenuContentProps) => {
  const classes = useStyles();

  const addNewFilter = useCallback(() => {
    setFilters({
      ...filters,
      conditions: [...filters.conditions, { type: "draft" }],
    });
  }, [filters, setFilters]);

  const setCondition = useCallback(
    (item: Condition, index: number) => {
      const newFilter = {
        ...filters,
        conditions: [...filters.conditions],
      };
      newFilter.conditions[index] = item;
      setFilters(newFilter);
    },
    [filters, setFilters],
  );

  const setOperator = useCallback(
    (operator: FilterOperator) => {
      const newNewFilter = {
        ...filters,
        operator,
      };
      setFilters(newNewFilter);
    },
    [filters, setFilters],
  );

  const deleteItem = useCallback(
    (index: number) => {
      const newFilter = {
        ...filters,
        conditions: [
          ...filters.conditions.slice(0, index),
          ...filters.conditions.slice(index + 1),
        ],
      };
      setFilters(newFilter);
    },
    [filters, setFilters],
  );

  const getKeyFromCondition = useCallback(
    (condition: Condition, index: number) => {
      if (
        condition.type === "standalone-record" ||
        condition.type === "couple-record"
      ) {
        return `record-${condition.columnKey}-${condition.predicate}-${index}`;
      }
      if (condition.type === "metadata") {
        return `metadata-${condition.predicate}-${index}`;
      }
      if (condition.type === "composite") {
        return `composite-${index}`;
      }
      return `draft-${index}`;
    },
    [],
  );

  const items = useMemo(
    () =>
      filters.conditions.map((condition, index) => (
        <FilterItem
          key={getKeyFromCondition(condition, index)}
          condition={condition}
          index={index}
          columns={columns}
          operator={filters.operator}
          setCondition={setCondition}
          deleteItem={deleteItem}
          setOperator={setOperator}
          disabledColumnTypes={disabledColumnTypes}
        />
      )),
    [
      filters.conditions,
      filters.operator,
      getKeyFromCondition,
      columns,
      setCondition,
      deleteItem,
      setOperator,
      disabledColumnTypes,
    ],
  );

  return (
    <div className={classes.contentRoot}>
      <div className={classes.filterContainer}>{items}</div>
      <div className={classes.addFilterButton}>
        <Button
          onPress={addNewFilter}
          variant="QUATERNARY"
          size="SMALL"
          accessibilityLabel={t("filterAdd")}
          text={t("filterAdd")}
          icon="plus"
        />
      </div>
    </div>
  );
};

export { FilterMenuContent };
