import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  useLocationEditor,
  UseLocationEditorParams,
} from "@kraaft/shared/components/geolocationOpener/useLocationEditor";
import { DetailsEditorBox } from "@kraaft/shared/components/modular/details/editors/detailsEditorBox";
import { EditorProps } from "@kraaft/shared/components/modular/details/editors/types";
import { EMPTY_LOCKED_RECORD_PLACEHOLDER } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { GeoLocation } from "@kraaft/shared/core/types";
import { formatAddress } from "@kraaft/shared/core/utils";
import { Button, ColorStyle, LabelledActionCard } from "@kraaft/ui";

type Props = EditorProps<KColumnType.geolocation>;

const LocationEditor = ({
  editor: {
    label,
    disabled,
    value,
    setValue,
    sectionLockInfo,
    context: { portalHostname, slideDirection, roomId = "" },
  },
  column,
  testID,
}: Props) => {
  const { t } = useTranslation();

  const locationString = formatAddress(value);

  const handleChange = useCallback(
    (location: GeoLocation | null | undefined) => {
      setValue(location ?? undefined);
    },
    [setValue],
  );

  const context = useMemo<UseLocationEditorParams["context"]>(
    () => ({
      roomId,
      source: "location_field",
    }),
    [roomId],
  );

  const { editLocation, LocationEditorPanel } = useLocationEditor({
    panelName: "LocationEditor",
    context,
    title: column.name,
    editTitle: `${t("modify")} ${column.name}`,
    portalHostname,
    slideDirection,
    isOnlyEditable: false,
    geolocation: value,
    onLocationChange: handleChange,
    sectionLockInfo: sectionLockInfo,
  });

  const emptyContent = useMemo(
    () => (
      <>
        {!sectionLockInfo.isLocked ? (
          <Button
            id={`${testID}-${column.key}`}
            icon={KSchemaUtils.getIcon(column)}
            text={t("modularity.select")}
            onPress={editLocation}
            disabled={disabled}
            variant="QUATERNARY"
            alignContent="left"
          />
        ) : (
          <LabelledActionCard
            color={ColorStyle.FONT_LOW_EMPHASIS}
            accessibilityLabel={t("modularity.select")}
            label={EMPTY_LOCKED_RECORD_PLACEHOLDER}
            iconName={KSchemaUtils.getIcon(column)}
            disabled
            hideChevron
          />
        )}
      </>
    ),
    [sectionLockInfo.isLocked, column, disabled, editLocation, t, testID],
  );

  const content = useMemo(() => {
    if (value !== undefined) {
      return (
        <LabelledActionCard
          id={`${testID}-${column.key}`}
          iconName={KSchemaUtils.getIcon(column)}
          color={ColorStyle.FONT_HIGH_EMPHASIS}
          label={locationString}
          accessibilityLabel={label}
          onPress={editLocation}
          disabled={disabled}
          numberOfLines={3}
        />
      );
    }

    return emptyContent;
  }, [
    value,
    emptyContent,
    testID,
    column,
    locationString,
    label,
    editLocation,
    disabled,
  ]);

  return (
    <>
      <DetailsEditorBox
        isLocked={sectionLockInfo.isLocked}
        label={label}
        columnKey={column.key}
      >
        {content}
      </DetailsEditorBox>
      {LocationEditorPanel}
    </>
  );
};

export { LocationEditor };
