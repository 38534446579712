import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  LayoutChangeEvent,
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  View,
  ViewStyle,
} from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { TrackableViewProps } from "@kraaft/shared/core/types";
import { useInputFocusManager } from "@kraaft/shared/core/utils/inputFocusManager/useInputFocusManager";
import { useFallbackRef } from "@kraaft/shared/core/utils/useRefUtils";
import { Color, ColorStyle } from "@kraaft/ui";

import { styles, TEXT_INPUT_MAX_HEIGHT } from "./kTextInput.styles";

/**
 Replacement for the React Native TextInput.
 This component allows for multiline support on web
**/

export interface KTextInputHandle {
  focus(): void;
  blur(): void;
  setNativeProps(nativeProps: object): void;
}

interface Selection {
  start: number;
  end: number;
}

export type KTextInputProps = TextInputProps &
  TrackableViewProps & {
    maxHeight?: number;
    textPadding?: number;
    textLineHeight?: number;
    wrapperStyle?: StyleProp<ViewStyle>;
    inputRef?: React.RefObject<TextInput>;
  };

const KTextInput = forwardRef<KTextInputHandle, KTextInputProps>(
  (
    {
      style,
      value,
      multiline,
      maxHeight = TEXT_INPUT_MAX_HEIGHT,
      textLineHeight = 17,
      textPadding = 0,
      wrapperStyle,
      inputRef,
      onBlur,
      onFocus,
      ...props
    },
    ref,
  ) => {
    const [rows, setRows] = useState<number>(1);
    const [overflowing, setOverflowing] = useState<boolean>(false);
    const [ready, setReady] = useState(isNative());
    const [defaultHeight, setDefaultHeight] = useState<number | undefined>();
    const [textInputNode, setTextInputNode] = useState<
      HTMLTextAreaElement | undefined
    >();
    const [selectionOnWeb, setSelectionOnWeb] = useState<Selection>();

    const wrapperRef = useRef<View>(null);
    const textInputRef = useFallbackRef(inputRef, null);
    const { onFocusChange } = useInputFocusManager();

    useEffect(() => {
      return onFocusChange; // on unmount
    }, [onFocusChange]);

    const handle = useMemo(
      () => ({
        focus: () => {
          if (!isNative() && !textInputRef.current?.isFocused()) {
            setSelectionOnWeb({ start: 1_000_000, end: 1_000_000 }); // move cursor to the end
          }
          textInputRef.current?.focus();
        },
        blur: () => {
          textInputRef.current?.blur();
        },
        setNativeProps: (nativeProps: object) => {
          textInputRef.current?.setNativeProps(nativeProps);
        },
      }),
      [textInputRef],
    );

    useImperativeHandle(ref, () => handle);

    const handleChange = useCallback(
      (target: HTMLTextAreaElement) => {
        const scrollHeight = target.scrollHeight;

        if (!multiline || !target || scrollHeight === 0) {
          return;
        }

        setTextInputNode(target);
        if (defaultHeight === undefined || scrollHeight < defaultHeight) {
          setDefaultHeight(scrollHeight);
        }

        const previousRows = target.rows;
        target.rows = 1;
        if (isNative()) {
          wrapperRef.current?.setNativeProps({ style: { height: "auto" } });
        }

        const currentRows = Math.floor(
          (target.scrollHeight - textPadding) / textLineHeight,
        );

        if (currentRows === previousRows) {
          target.rows = currentRows;
        }

        // on IE11, we need to set the height of the wrapper
        const height =
          Math.min(maxHeight, target.scrollHeight) || defaultHeight;
        if (isNative()) {
          wrapperRef.current?.setNativeProps({ style: { height } });
        }

        setOverflowing(target.scrollHeight > maxHeight);
        setRows(currentRows);
        setReady(true);
      },
      [defaultHeight, maxHeight, multiline, textLineHeight, textPadding],
    );

    const handleFocus = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        onFocusChange();
        onFocus?.(event);
      },
      [onFocus, onFocusChange],
    );

    const handleBlur = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        onFocusChange();
        onBlur?.(event);
      },
      [onBlur, onFocusChange],
    );

    useEffect(() => {
      if (!isNative()) {
        if (
          value === "" ||
          textInputNode !== document.activeElement // in case the value changes when we don't have focus
        ) {
          setRows(1);
          if (textInputNode) {
            handleChange(textInputNode);
          }
        }
      }
    }, [handleChange, textInputNode, value]);

    function handleLayoutChangeWeb(event: LayoutChangeEvent) {
      // target exists but is not present in nativeEvent type definition... (https://reactnative.dev/docs/textinput#onlayout)
      // PR to add type https://github.com/bgrieder/react-native-typescript-definitions/pull/1
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const target = (event.nativeEvent as any).target as
        | HTMLTextAreaElement
        | null
        | undefined;
      if (target) {
        handleChange(target);
      }
    }

    // PR to add type https://github.com/bgrieder/react-native-typescript-definitions/pull/1
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleInputChangeWeb(event: any) {
      handleChange(event.nativeEvent.target as unknown as HTMLTextAreaElement);
    }

    const webConfig = {
      onLayout: handleLayoutChangeWeb,
      onChange: handleInputChangeWeb,
      numberOfLines: rows,
    };

    // react-native does not allow uncontrolled input but when react-native-web converts it he allows it
    // we replace the undefined value to an empty string to prevent switching from controlled to uncontrolled
    // thus we keep control over the value and we can set it to undefined
    const displayValue = !isNative() ? value ?? "" : value;

    return (
      <View style={wrapperStyle} ref={wrapperRef}>
        <TextInput
          style={[
            styles.textInput,
            style,
            multiline && {
              maxHeight,
              lineHeight: textLineHeight,
            },
            overflowing && styles.overflowing,
            multiline && !ready && styles.hidden,
          ]}
          placeholderTextColor={ColorStyle.FONT_LOW_EMPHASIS}
          selectionColor={Color.BLUE_AZURE}
          ref={textInputRef}
          value={displayValue}
          multiline={multiline}
          {...Platform.select({ web: webConfig })}
          {...props}
          onFocus={handleFocus}
          onBlur={handleBlur}
          selection={selectionOnWeb}
        />
      </View>
    );
  },
);

export { KTextInput };
