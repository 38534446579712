import { useEffect, useRef } from "react";

export function useMouseButtonPressed() {
  const buttonsPressed = useRef(0);

  useEffect(() => {
    function handleMouseDown(event: MouseEvent) {
      buttonsPressed.current = event.buttons;
    }
    function handleMouseUp(event: MouseEvent) {
      buttonsPressed.current = event.buttons;
    }

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return buttonsPressed;
}
