import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularDisplayExtendedRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { ModularDisplayRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { PoolSchemaBuilderContextProvider } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderContextProvider";
import { EditSchema } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/editSchema";

interface EditPoolSchemaProps {
  schema: KSchema;
  isColumnDeletionInstant: boolean;
  hidePreview?: boolean;
}

const EditPoolSchema = ({
  schema,
  isColumnDeletionInstant,
  hidePreview,
}: EditPoolSchemaProps) => {
  return (
    <PoolSchemaBuilderContextProvider
      schema={schema}
      isColumnDeletionInstant={isColumnDeletionInstant}
      hidePreview={hidePreview}
    >
      <ModularDisplayRequirementsProvider>
        <ModularDisplayExtendedRequirementsProvider
          recordType="formPreview"
          noCheckboxConfirmation={true}
        >
          <EditSchema />
        </ModularDisplayExtendedRequirementsProvider>
      </ModularDisplayRequirementsProvider>
    </PoolSchemaBuilderContextProvider>
  );
};
export { EditPoolSchema };
