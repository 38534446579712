import { CursorSelection } from "@kraaft/shared/core/framework/markedText/cursorSelection";
import { Span } from "@kraaft/shared/core/framework/markedText/span";

import { ContentUpdatedResultAction, Marker } from "./marker";

export abstract class MentionMarker<T extends string> extends Marker<T> {
  updateContent(
    localRange: Span,
    text: string,
    selection: CursorSelection,
  ): ContentUpdatedResultAction | undefined {
    if (
      localRange.start === this.getRange().end - this.anchorIndex - 1 &&
      localRange.end === this.getRange().end - this.anchorIndex &&
      text === "" &&
      selection.isCursor()
    ) {
      const rangeToRemove = new Span(
        this.anchorIndex,
        this.anchorIndex + this.getLength() - 1,
      );
      return { type: "remove", rangeToRemove };
    }
    return { type: "unlink" };
  }
}
