import { useState } from "react";

import { ScrollToBottomButton } from "@kraaft/shared/components/scrollToBottomButton";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";

interface UseScrollToBottomButtonProps {
  roomId: string;
  onPress: () => void;
  isNearBottomOfList: (() => boolean) | undefined;
  isLastChunkDisplayed: boolean;
}

export function useScrollToBottomButton({
  roomId,
  onPress,
  isLastChunkDisplayed,
  isNearBottomOfList,
}: UseScrollToBottomButtonProps) {
  const [show, setShow] = useState(false);

  const checkIfButtonHasToShow = useCallbackRealtime(
    ([_isLastChunkDisplayed]) => {
      const isNearBottom = isNearBottomOfList?.();
      if (isNearBottom === undefined) {
        return;
      }
      if (!_isLastChunkDisplayed) {
        return setShow(true);
      }
      return setShow(!isNearBottom);
    },
    [isNearBottomOfList],
    [isLastChunkDisplayed],
  );

  return {
    element: show ? (
      <ScrollToBottomButton roomId={roomId} onPress={onPress} />
    ) : null,
    checkIfButtonHasToShow,
  };
}
