import { useEffect, useState } from "react";

import { firestore } from "@kraaft/shared/core/services/firestore/sdk";

export type AiCategory = {
  id: string;
  name: string;
};

export type PoolAiDirectorySettings = {
  enabled: boolean;
  categories: AiCategory[];
};

export function useAiDirectorySettings(
  poolId: string | undefined,
  skip?: boolean,
): PoolAiDirectorySettings | undefined {
  const [aiDirectorySettings, setAiDirectorySettings] =
    useState<PoolAiDirectorySettings>();
  useEffect(() => {
    if (!poolId || skip) {
      return;
    }
    return firestore()
      .collection("poolAiDirectorySettings-projection-1n")
      .doc(poolId)
      .onSnapshot((snapshot) => {
        setAiDirectorySettings(
          snapshot.data() as PoolAiDirectorySettings | undefined,
        );
      });
  }, [poolId, skip]);

  return aiDirectorySettings;
}
