import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { NotFullSynchronizedModularFolderSheet } from "@kraaft/shared/core/modules/modularFolder/hooks/notFullSynchronizedModularFolderSheet";
import { selectModularFolderPendingOperations } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { Button, Spacing } from "@kraaft/ui";

export function useNotPersistedModularFolderWarning<P extends any[]>(
  modularFolderId: string,
  memoizedCallback: (...args: P) => void,
) {
  const { t } = useTranslation();
  const storedArguments = useRef<P | undefined>(undefined);

  const pendingOperationCount = useSelector(
    selectModularFolderPendingOperations(modularFolderId),
  );
  const hasPendingOperations = pendingOperationCount > 0;

  const { open, element, close } = NotFullSynchronizedModularFolderSheet.use({
    modularFolderPendingOperations: pendingOperationCount,
    titleSynchronized: t("offline.modularFolder.synchronized"),
    descriptionSynchronized: t("offline.modularFolder.synchronizedContent"),
    titleNotSynchronized: t("offline.modularFolder.notSynchronized"),
    descriptionNotSynchronized: t("offline.modularFolder.versionDiffers"),
    cta: (
      <View style={styles.root}>
        <Button
          text={
            hasPendingOperations
              ? t("offline.modularFolder.continueAnyway")
              : t("offline.modularFolder.continue")
          }
          onPress={callWithStoredArgs}
        />
        {hasPendingOperations && (
          <Button text={t("offline.modularFolder.wait")} variant="SECONDARY" />
        )}
      </View>
    ),
  });

  function callWithStoredArgs() {
    close();
    memoizedCallback(...(storedArguments.current as P));
  }

  function guard(...args: P) {
    if (hasPendingOperations) {
      storedArguments.current = args;
      open();
      return;
    }
    memoizedCallback(...args);
  }

  return { open, element, guard };
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "column",
    gap: Spacing.S8,
  },
});
