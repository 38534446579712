import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton/headerIconButton";
import { withOnPressTracking } from "@kraaft/shared/components/tracking/withOnPressTracking";

type Props = {
  count: number;
  onPress: () => void;
};

export const MembersIcon = ({ count, onPress }: Props) => {
  const text = `${count}`;

  return (
    <HeaderIconButton
      variant="TERTIARY"
      icon="users-01"
      accessibilityLabel={text}
      text={text}
      condensed
      onPress={onPress}
    />
  );
};

export const TrackedMembersIcon = withOnPressTracking(MembersIcon);
