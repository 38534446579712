import { useCallback, useState } from "react";
import clsx from "clsx";

import { useMeshContext } from "@kraaft/helper-hooks";
import {
  CheckboxGuard,
  CheckboxGuardStrategy,
} from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuard";
import { getAppropriateCheckboxGuardStrategy } from "@kraaft/shared/components/modularFolders/checkboxGuard/getAppropriateCheckboxGuardStrategy";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularDisplayExtendedRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Color, Icon, IconName } from "@kraaft/ui";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";

import { useStyles } from "./basicCell.styles";

export const CheckboxCell = ({
  value,
  row,
  column,
  sectionLockInfo,
  onRowChange,
}: IBasicCellProps<KColumnType.checkbox>) => {
  const classes = useStyles();

  const noCheckboxConfirmation = useMeshContext(
    ModularDisplayExtendedRequirementsContext,
    (context) => context.noCheckboxConfirmation,
  );
  const [checkboxGuardStrategy, setCheckboxGuardStrategy] = useState<
    CheckboxGuardStrategy | undefined
  >(undefined);

  const checkIcon: IconName = column.isLockingSection
    ? value
      ? "locked"
      : "lock-01"
    : value
      ? "round-checkbox-on-fill"
      : "round-checkbox-unchecked";

  const checkIconColor = column.isLockingSection
    ? value
      ? Color.YELLOW_EARTH
      : Color.BLACK
    : value
      ? Color.GREEN_LAGOON
      : Color.BLACK;

  const onChange = useCallback(
    (newValue: boolean) => {
      onRowChange({
        ...row,
        properties: {
          ...row.properties,
          [column.key]: { columnType: column.type, value: newValue },
        },
      });
    },
    [column.key, column.type, onRowChange, row],
  );

  const handleClick = useCallback(() => {
    if (
      sectionLockInfo.isLocked &&
      !(column.isLockingSection && value === true)
    ) {
      return;
    }
    // Need timeout because react-data-grid needs to know which cell is selected when onRowChange is called
    setTimeout(() => {
      const appropriateCheckboxGuardStrategy =
        getAppropriateCheckboxGuardStrategy(
          !!column.isLockingSection,
          !!value,
          sectionLockInfo.isCurrentUserAuthorizedToUnlock,
          sectionLockInfo.highestLockedSectionKey,
          sectionLockInfo.section.key,
        );

      if (column.isLockingSection && value) {
        trackEvent({
          eventName: "Tried To Unlock A Section",
          source: "table",
        });
      }

      const shouldSkipConfirmation =
        !!noCheckboxConfirmation &&
        (appropriateCheckboxGuardStrategy === "checkConfirmation" ||
          appropriateCheckboxGuardStrategy === "uncheckConfirmation");

      if (shouldSkipConfirmation) {
        onChange(!value);
        return;
      }

      setCheckboxGuardStrategy(appropriateCheckboxGuardStrategy);
    });
  }, [
    sectionLockInfo.isLocked,
    sectionLockInfo.isCurrentUserAuthorizedToUnlock,
    sectionLockInfo.highestLockedSectionKey,
    sectionLockInfo.section.key,
    column.isLockingSection,
    value,
    noCheckboxConfirmation,
    onChange,
  ]);

  const handleCheckboxGuardClose = useCallback(() => {
    setCheckboxGuardStrategy(undefined);
  }, []);

  const handleCheckboxGuardConfirm = useCallback(() => {
    setCheckboxGuardStrategy(undefined);
    onChange(!value);
  }, [onChange, value]);

  const getCheckbox = useCallback(() => {
    return (
      <div
        className={clsx(!column.preventEdition && classes.button)}
        onClick={column.preventEdition ? undefined : handleClick}
      >
        <Icon
          name={checkIcon}
          color={checkIconColor}
          size="MEDIUM"
          fixChromePaste
        />
      </div>
    );
  }, [
    checkIcon,
    checkIconColor,
    classes.button,
    column.preventEdition,
    handleClick,
  ]);

  return (
    <div className={classes.container}>
      {getCheckbox()}
      <CheckboxGuard
        strategy={checkboxGuardStrategy}
        sectionLockInfo={sectionLockInfo}
        onClose={handleCheckboxGuardClose}
        onConfirm={handleCheckboxGuardConfirm}
        columnName={column.name}
      />
    </div>
  );
};
