import { ModularTableValueUpdate } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaColumnLiteralValue,
  KSchemaColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { TableRecordContext } from "@kraaft/web/src/components/modularTable/tableContext";

export function addUpdateForRow<T extends KColumnType>(
  row: ModularRecord,
  column: KSchemaColumn<T>,
  rowIndex: number,
  value: KSchemaColumnLiteralValue<T>,
  updates: ModularTableValueUpdate[],
) {
  const columnKey = column.key;
  row.properties[columnKey] = {
    columnType: column.type,
    value,
  } as KSchemaColumnValue;

  updates.push({ id: row.id, column, rowIndex, value });
}

export function setLoaderForRowAtColumn<T extends KColumnType>(
  row: TableRecordContext,
  column: KSchemaColumn<T>,
  state: boolean,
) {
  row.loadingCells = row.loadingCells ?? {};
  row.loadingCells[column.key] = state;
}
