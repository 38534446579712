import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  dialogContentContainer: {
    flex: 1,
  },

  inputContainer: {
    marginTop: Spacing.S16,
  },
});
