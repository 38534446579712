import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { useHandleChangeSingleSelectHelper } from "@kraaft/web/src/components/dropdown/kDropdownUtils";
import { ColumnHeaderEditorProps } from "@kraaft/web/src/components/modularTable/components/types";

import { useStyles } from "./userColumnEditor.styles";

enum ChoiceVariant {
  SINGLE = "single",
  MULTIPLE = "multiple",
}

const UserColumnEditor = (
  props:
    | ColumnHeaderEditorProps<KColumnType.user>
    | ColumnHeaderEditorProps<KColumnType.automatedCreatedBy>,
) => {
  const { columnDefinition, setColumnDefinition } = props;

  const { t } = useTranslation();
  const styles = useStyles();

  const items = useMemo<FilteredListItem[]>(() => {
    return [
      { value: ChoiceVariant.SINGLE, label: t("uniqueChoice") },
      { value: ChoiceVariant.MULTIPLE, label: t("multipleChoice") },
    ];
  }, [t]);
  const selectedItemId =
    columnDefinition?.type === KColumnType.user &&
    columnDefinition.allowMultiple
      ? ChoiceVariant.MULTIPLE
      : ChoiceVariant.SINGLE;

  const handleChange = useHandleChangeSingleSelectHelper(
    (newValue: ChoiceVariant | undefined) => {
      if (columnDefinition?.type === KColumnType.user) {
        (
          setColumnDefinition as ColumnHeaderEditorProps<KColumnType.user>["setColumnDefinition"]
        )({
          ...columnDefinition,
          allowMultiple: newValue === ChoiceVariant.MULTIPLE,
        });
      }
    },
    [columnDefinition, setColumnDefinition],
  );

  return (
    <div className={styles.container}>
      <KDropdown
        items={items}
        placeholder={t("subOption")}
        selectedItemIds={[selectedItemId]}
        onSelectionChange={handleChange}
      />
    </div>
  );
};

export { UserColumnEditor };
