import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, Radius, Spacing } from "@kraaft/ui";

const TRANSITION =
  "transform 150ms ease-in-out, background-color 150ms ease-in-out";

export const useStyles = makeStyles({
  container: {
    width: 300,
    borderRadius: Radius.SMALL,
    border: `1px solid ${Color.GREY_ANTIFLASH}`,
    backgroundColor: Color.WHITE,
    overflow: "hidden",
    cursor: "pointer",
    opacity: ({ stealthy }: { stealthy: boolean }) => (stealthy ? 0.5 : 1),

    "&:hover $textContainer": {
      backgroundColor: Color.BLUE_LAVENDER,
    },

    "&:hover $icon": {
      transform: "scale(1.1)",
    },

    "&:hover $image": {
      transform: "scale(1.025)",
    },
  },

  thumbnailContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: "100%",
    aspectRatio: 2.5,
    backgroundColor: ColorStyle.ACTION_CTA,
    overflow: "hidden",
  },

  icon: {
    transition: TRANSITION,
  },

  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: TRANSITION,
  },

  textContainer: {
    padding: Spacing.S12,
    transition: TRANSITION,
  },

  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  caption: {
    paddingTop: Spacing.S8,
  },

  iconContainer: {
    marginRight: Spacing.S4,
  },
});
