import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { MapOverlayStateActions } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.actions";
import { selectEnabledMapOverlays } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.selectors";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { I18nKeys } from "@kraaft/shared/core/services/i18next/locales/i18n.keys";
import { MapsHelper } from "@kraaft/web/src/components/geolocation/types";

const OverlayStatusToTranslation: Record<
  Exclude<google.maps.KmlLayerStatus, "OK">,
  I18nKeys
> = {
  DOCUMENT_NOT_FOUND: "importMapOverlay.errors.notFound",
  DOCUMENT_TOO_LARGE: "importMapOverlay.errors.tooLarge",
  FETCH_ERROR: "importMapOverlay.errors.networkError",
  INVALID_DOCUMENT: "importMapOverlay.errors.invalidDocument",
  INVALID_REQUEST: "importMapOverlay.errors.invalidRequest",
  LIMITS_EXCEEDED: "importMapOverlay.errors.limitExceeded",
  TIMED_OUT: "importMapOverlay.errors.timedOut",
  UNKNOWN: "importMapOverlay.errors.unknown",
};

export const useMapOverlayLayers = (
  mapsHelper: MapsHelper | null,
  map: google.maps.Map | null,
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const poolId = useSelector(selectCurrentPoolId);
  const enabledMapOverlays = useSelector(selectEnabledMapOverlays(poolId));
  const kmlLayers = useRef<{ [overlayId: string]: google.maps.KmlLayer }>({});

  const enabledOverlayIds = useMemo(() => {
    const set = new Set<string>();

    for (const mapOverlay of enabledMapOverlays) {
      set.add(mapOverlay.id);
    }
    return set;
  }, [enabledMapOverlays]);

  useEffect(() => {
    if (mapsHelper && map) {
      for (const mapOverlay of enabledMapOverlays) {
        if (kmlLayers.current[mapOverlay.id] === undefined) {
          const mapOverlayDownloadUrl = mapOverlay.file.downloadUrl;
          const newLayer = new mapsHelper.KmlLayer({
            url: mapOverlayDownloadUrl,
            map,
            preserveViewport: true,
          });

          kmlLayers.current[mapOverlay.id] = newLayer;

          newLayer.addListener("status_changed", () => {
            const status = newLayer.getStatus();

            if (status !== google.maps.KmlLayerStatus.OK) {
              console.log("cannot set overlay", status, mapOverlayDownloadUrl);
              dispatch(
                showError({
                  title: t("importMapOverlay.cannotSetOverlay"),
                  message: t(OverlayStatusToTranslation[status]),
                }),
              );
              if (poolId) {
                dispatch(
                  MapOverlayStateActions.toggleMapOverlay({
                    poolId,
                    overlayId: mapOverlay.id,
                  }),
                );
              }
            }
          });
        }
      }
      for (const overlayId of Object.keys(kmlLayers.current)) {
        if (!enabledOverlayIds.has(overlayId)) {
          kmlLayers.current[overlayId]?.setMap(null);
          delete kmlLayers.current[overlayId];
        }
      }
    }
  }, [
    enabledMapOverlays,
    enabledOverlayIds,
    map,
    mapsHelper,
    dispatch,
    t,
    poolId,
  ]);
};
