import { StyleSheet } from "react-native";

import { PixelSize, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  containerDefault: {
    alignItems: "flex-end",
  },
  containerReply: {
    alignItems: "flex-start",
  },

  playerContainer: {
    alignItems: "center",
  },
  playerContainerDefault: {
    flexDirection: "row-reverse",
  },
  playerContainerReply: {
    flexDirection: "row",
  },
  playerContainerWithinBox: {
    paddingHorizontal: Spacing.S8,
    paddingBottom: Spacing.S8,
  },

  wavesContainer: {
    position: "relative",
    overflow: "hidden",
    borderRadius: 25,
    paddingHorizontal: 14,
    paddingVertical: 10,
  },
  answerStyle: {
    marginHorizontal: 3,
    minWidth: 230,
  },
  wavesContainerDefault: {
    marginLeft: 10,
  },
  wavesContainerReply: {
    marginRight: 10,
  },

  waves: {
    flexDirection: "row",
  },

  wavesOpacity: {
    opacity: 0.4,
  },

  button: {
    alignItems: "center",
    borderRadius: 30,
    height: PixelSize.S36,
    justifyContent: "center",
    width: PixelSize.S36,
  },
  progress: {
    ...StyleSheet.absoluteFillObject,
    overflow: "hidden",
    marginHorizontal: 14,
    marginVertical: 10,
  },
});
