import { ColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/column";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import {
  KSchemaConversion,
  RawModularRecordProperties,
} from "@kraaft/shared/core/modules/schema/schema.conversion";
import {
  Modifier,
  SchemaTemplate,
} from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";

export function applyRenderUpdatesToSchemaTemplate(
  rendered: ModularRecord[],
  schemaTemplate: SchemaTemplate,
) {
  if (rendered.length < schemaTemplate.modifiers.length) {
    schemaTemplate.modifiers.splice(rendered.length - 1);
  }
  if (rendered.length > schemaTemplate.modifiers.length) {
    for (let i = schemaTemplate.modifiers.length; i < rendered.length; i += 1) {
      schemaTemplate.modifiers.push({
        title: { columnType: KColumnType.shortText, value: "" },
      });
    }
  }
  for (let i = 0; i < rendered.length; i += 1) {
    const modifier = schemaTemplate.modifiers[i];
    const render = rendered[i];
    if (!modifier || !render) {
      console.error("Rendered length not equal to modifiers length");
      return;
    }

    for (const [key, value] of Object.entries(render.properties)) {
      modifier[key] = {
        columnType: value.columnType,
        value: value.value,
      } as ColumnValue;
    }
  }
}

export function toRawModifier(modifier: Modifier) {
  return Object.entries(modifier).reduce<RawModularRecordProperties>(
    (acc, [key, mod]) => {
      acc[key] = KSchemaConversion.toRawProperty(mod);
      return acc;
    },
    {},
  );
}
