import React from "react";

import { AnyUnexplained } from "@kraaft/shared/core/types";

export enum Order {
  DESC = -1,
  NONE = 0,
  ASC = 1,
}

export const OrderLabel: {
  [key in Order]: "asc" | "desc" | undefined;
} = {
  [Order.DESC]: "desc",
  [Order.ASC]: "asc",
  [Order.NONE]: undefined,
};

export interface KTableContext {
  selectRow: (state: boolean) => void;
  rowRef: React.RefObject<HTMLTableRowElement>;
}

export interface KTableColumn<T, V = AnyUnexplained> {
  id: string;
  label?: React.ReactNode;
  value?: (item: T, index?: number) => V;
  compare?: (a: V, b: V) => number;
  component?: (value: V, context: KTableContext) => React.ReactNode;
  width?: React.CSSProperties["width"];
  style?: {
    header?: React.CSSProperties;
    column?: React.CSSProperties;
  };
}

export interface KTableConfig<T> {
  id?: string;
  columns: Array<KTableColumn<T>>;
  defaultOrderBy?: string;
  defaultOrder?: Order;
  autoLayout?: boolean;
  enableHover?: boolean;
  onRowClicked?: (item: T) => void;
  onRowEnter?: (item: T) => void;
  onRowLeave?: (item: T) => void;
  style?: {
    columns?: React.CSSProperties;
    rows?: React.CSSProperties;
    hover?: WebStyleSheet;
    selected?: React.CSSProperties;
  };
}
