import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { InputBase } from "@mui/material";
import clsx from "clsx";

import { Tooltip } from "@kraaft/shared/components/tooltip";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";
import { Icon, IconName } from "@kraaft/ui";

import { useStyles } from "./filteredList.styles";

export interface FilteredListItem<T extends string = string> {
  value: T;
  label: string;
  icon?: IconName;
  inlineStyle?: string;
  testId?: string;
  disabled?: boolean;
}

export interface ForwardedFilteredListProp {
  withSearchBar?: boolean;
  withEmptyRow?: boolean;
  fullWidth?: boolean;
  minWidth?: React.CSSProperties["minWidth"];
  maxWidth?: React.CSSProperties["maxWidth"];
}

interface FilteredListPropsBase<T extends string = string>
  extends ForwardedFilteredListProp {
  items: FilteredListItem<T>[];
  defaultFilterText?: string;
  onItemClick?: (item: FilteredListItem<T> | undefined) => void;

  testID?: string;
}

interface FilteredListPropsWithoutMultiSelect {
  withMultiSelect?: false;
}
interface FilteredListPropsWithMultiSelect {
  withMultiSelect: true;
  selectedItemIds?: string[];
}

type FilterListProps<T extends string = string> = FilteredListPropsBase<T> &
  (FilteredListPropsWithMultiSelect | FilteredListPropsWithoutMultiSelect);

const FilteredList = <T extends string>(props: FilterListProps<T>) => {
  const {
    items,
    defaultFilterText = "",
    onItemClick,
    withSearchBar = false,
    withEmptyRow = false,
    withMultiSelect = false,
    fullWidth = false,
    maxWidth = 400,
    minWidth = 100,
    testID,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles({ maxWidth, minWidth });

  const inputRef = useRef<HTMLInputElement>(null);
  const [filterText, setFilterText] = useState(defaultFilterText);

  const itemsFiltered = useMemo(() => {
    const normalizedFilter = normalizeTextForSearch(filterText);

    return items.filter((item) => {
      const normalizedLabel = normalizeTextForSearch(item.label);

      return normalizedLabel.includes(normalizedFilter);
    });
  }, [filterText, items]);

  const handleChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterText(event.target.value.toLowerCase());
    },
    [],
  );

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const filterInput = useMemo(() => {
    return (
      <div className={classes.withSeparatorContainer}>
        <InputBase
          inputRef={inputRef}
          sx={{
            "& .MuiInputBase-input": {
              padding: "8px 10px",
              margin: "0 8px 0 0",
            },
          }}
          classes={{ input: clsx(classes.itemSizer, classes.input) }}
          onChange={handleChangeText}
          defaultValue={defaultFilterText}
          placeholder={t("searchOption")}
        />
        <span className={classes.itemSeparator} />
      </div>
    );
  }, [
    classes.input,
    classes.itemSeparator,
    classes.itemSizer,
    classes.withSeparatorContainer,
    defaultFilterText,
    handleChangeText,
    t,
  ]);

  const isItemSelected = useCallback(
    (item: FilteredListItem<T>) => {
      if (
        props.withMultiSelect &&
        props.selectedItemIds &&
        props.selectedItemIds.length > 0
      ) {
        return (
          props.selectedItemIds.findIndex(
            (selectedItem) => selectedItem === item.value,
          ) >= 0
        );
      }
      return false;
    },
    [props],
  );

  const renderFilteredItem = useCallback(
    (item: FilteredListItem<T>, index: number) => {
      const hasSeparator = index < itemsFiltered.length - 1;
      const isSelected = isItemSelected(item);

      const handleClick = () => {
        if (!item.disabled) {
          onItemClick?.(item);
        }
      };

      return (
        <div
          id={item.testId}
          key={item.value}
          className={classes.withSeparatorContainer}
          onClick={handleClick}
        >
          <span className={clsx(classes.itemSizer, classes.itemBase)}>
            {item.icon ? (
              <Icon name={item.icon} color="FONT_HIGH_EMPHASIS" size="MEDIUM" />
            ) : null}
            <Tooltip
              title={item.label}
              placement="bottom-start"
              enterDelay={1000}
            >
              <span className={clsx(classes.item, item.inlineStyle)}>
                {item.label}
              </span>
            </Tooltip>
            {withMultiSelect && (
              <span className={classes.itemSelectedContainer}>
                {isSelected && (
                  <Icon
                    name="checkbox-on-fill"
                    color="ACTION_CTA"
                    size="MEDIUM"
                  />
                )}
              </span>
            )}
          </span>
          {hasSeparator && <span className={classes.itemSeparator} />}
        </div>
      );
    },
    [
      itemsFiltered.length,
      isItemSelected,
      classes.withSeparatorContainer,
      classes.itemSizer,
      classes.itemBase,
      classes.item,
      classes.itemSelectedContainer,
      classes.itemSeparator,
      withMultiSelect,
      onItemClick,
    ],
  );
  const handleClearClick = useCallback(() => {
    onItemClick?.(undefined);
  }, [onItemClick]);

  const emptyRow = useMemo(() => {
    return (
      withEmptyRow &&
      filterText.length === 0 && (
        <div
          className={classes.withSeparatorContainer}
          onClick={handleClearClick}
        >
          <span
            className={clsx(
              classes.itemSizer,
              classes.itemBase,
              classes.emptyRow,
            )}
          />
          <span className={classes.itemSeparator} />
        </div>
      )
    );
  }, [
    classes.emptyRow,
    classes.itemBase,
    classes.itemSeparator,
    classes.itemSizer,
    classes.withSeparatorContainer,
    filterText.length,
    handleClearClick,
    withEmptyRow,
  ]);

  const filteredItems = useMemo(() => {
    return itemsFiltered.length > 0 ? (
      <div className={classes.list}>
        {emptyRow}
        {itemsFiltered.map(renderFilteredItem)}
      </div>
    ) : (
      <span className={classes.noResults}>{t("noResults")}</span>
    );
  }, [
    classes.list,
    classes.noResults,
    emptyRow,
    itemsFiltered,
    renderFilteredItem,
    t,
  ]);

  return (
    <div className={clsx(!fullWidth && classes.containerSizer)} id={testID}>
      {withSearchBar && filterInput}
      {filteredItems}
    </div>
  );
};

export { FilteredList };
