import { useMemo, useState } from "react";

import {
  MessageTextEditorContext,
  MessageTextEditorContextType,
} from "@kraaft/shared/components/messageTextEditor/messageTextEditorContext";
import { MessageWithText } from "@kraaft/shared/core/modules/message/messageState";

export const MessageTextEditorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedMessage, setSelectedMessage] = useState<
    MessageWithText | undefined
  >();

  const context = useMemo<MessageTextEditorContextType>(
    () => ({
      selectedMessage: selectedMessage,
      setSelectedMessage: setSelectedMessage,
    }),
    [selectedMessage],
  );

  return (
    <MessageTextEditorContext.Provider value={context}>
      {children}
    </MessageTextEditorContext.Provider>
  );
};
