import { useCallback, useEffect } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { fileHelper } from "@kraaft/shared/core/modules/file/fileHelper";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { uploadLogo } from "@kraaft/shared/core/modules/pool/poolActions";
import { formatError } from "@kraaft/shared/core/utils";
import { Icon } from "@kraaft/ui";
import { ErrorType } from "@kraaft/web/src/core/types/dropzoneFile";

import { useStyles } from "./settingsLogo.styles";

interface SettingsLogoProps {
  isPoolOwnerOrSuperadmin: boolean;
  pool: Pool | undefined;
  poolId: string | undefined;
}

const acceptedFileTypes: Accept = { "image/png": [], "image/jpeg": [] };

const SettingsLogo = (props: SettingsLogoProps) => {
  const { isPoolOwnerOrSuperadmin, pool, poolId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleDropFiles(webFiles: File[]) {
    const [first] = webFiles;
    if (!first) {
      return;
    }
    dispatch(uploadLogo({ poolId, file: fileHelper.fromWebFile(first) }));
  }

  const { getRootProps, getInputProps, fileRejections, inputRef } = useDropzone(
    {
      onDrop: handleDropFiles,
      accept: acceptedFileTypes,
      multiple: true,
      noClick: true,
      maxSize: 100000000, // 100 MBytes
    },
  );

  const openFilePicker = useCallback(() => {
    inputRef?.current?.click();
  }, [inputRef]);

  useEffect(() => {
    const fileRejection = fileRejections?.[0];

    if (fileRejection) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const firstError = fileRejection.errors[0]!;
      const error = formatError(firstError.code) as ErrorType;

      const getError = (errorType: ErrorType) => {
        switch (errorType) {
          case "FileInvalidType":
            return "importFileErrorFileInvalidType";
          case "FileTooLarge":
            return "importFileErrorFileTooLarge";
          case "TooManyFiles":
            return "importFileErrorTooManyFiles";
          default:
            return "importFileErrorUnknown";
        }
      };

      dispatch(
        showError({
          title: t(getError(error), { fileType: fileRejection.file.type }),
        }),
      );
    }
  }, [dispatch, fileRejections, t]);

  return (
    <>
      <div className={classes.titleContainer}>
        <span className={classes.title}>{t("adminLogo")}</span>
        <span className={classes.subTitle}>{t("adminLogoDescription")}</span>
      </div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <button
          disabled={!isPoolOwnerOrSuperadmin}
          className={clsx(classes.addButton, {
            [classes.addButtonWithHover]: isPoolOwnerOrSuperadmin,
          })}
          onClick={openFilePicker}
        >
          {pool?.logoDownloadUrl ? (
            <img
              src={pool.logoDownloadUrl}
              alt="team logo"
              className={classes.image}
            />
          ) : (
            <Icon name="plus" size="MEDIUM" color="FONT_HIGH_EMPHASIS" />
          )}
        </button>
      </div>
    </>
  );
};

export { SettingsLogo };
