import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { getModularRecordTitle } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { baseStyles } from "@kraaft/shared/components/report/selection.styles";
import { selectOrderedModularFoldersWithRoomIdAndSchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { Button } from "@kraaft/ui";

const webScroll = !isNative() ? { overflowY: "auto" } : {};

const styles = StyleSheet.create({
  ...baseStyles,
  list: { ...baseStyles.list, ...webScroll },
});

interface Props {
  roomId: string;
  schemaId: string;
  onSelect: (selected: string[]) => void;
  onClose: () => void;
  defaultValues?: string[];
}

const FolderSelectionForSchema = (props: Props) => {
  const { roomId, schemaId, onSelect, onClose, defaultValues } = props;

  const { t } = useTranslation();
  const [selected, setSelected] = useState(defaultValues ?? []);

  const { folders } = useSelector(
    selectOrderedModularFoldersWithRoomIdAndSchemaId({ roomId, schemaId }),
  );

  const items = useMemo<KSelectableListItem[]>(
    () =>
      folders.map((folder) => ({
        label: getModularRecordTitle(folder),
        identifier: folder.id,
      })),
    [folders],
  );

  const handleSubmit = useCallback(() => {
    onSelect(selected);
    onClose();
  }, [onClose, onSelect, selected]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.list}>
        {items.length === 0 ? (
          <Text style={styles.emptyList}>{t("generateReportNoTasks")}</Text>
        ) : (
          <KSelectionList
            items={items}
            selected={selected}
            setSelected={setSelected}
            isMultiple
            selectAll
          />
        )}
      </View>
      <View style={styles.buttonContainer}>
        <Button
          onPress={handleSubmit}
          accessibilityLabel={t("generateReportConfirm")}
          text={t("generateReportConfirm")}
        />
      </View>
    </SafeAreaView>
  );
};

export { FolderSelectionForSchema };
