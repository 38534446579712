import { useCallback, useMemo } from "react";
import AutolinkText from "react-native-autolink";
import clsx from "clsx";

import { customAutoLinkUrlMatcher } from "@kraaft/shared/components/autolink/customMatchers";
import {
  AutolinkComponentProps,
  CustomMatcher,
} from "@kraaft/shared/components/autolink/types";

import { useStyles } from "./autolink.styles";

const AutoLink = (props: AutolinkComponentProps) => {
  const {
    children: text,
    renderText,
    enableLinks,
    enableMail,
    linkStyle,
    matchers,
    onLongPress,
  } = props;
  const classes = useStyles();

  const onPress = useCallback((url: string) => {
    const target = url.indexOf("http") === 0 ? "_blank" : "_self";
    window.open(url, target);
  }, []);

  const finalMatchers = useMemo<CustomMatcher[]>(() => {
    const _finalMatchers = [...(matchers ?? [])];
    if (enableLinks) {
      _finalMatchers.push(customAutoLinkUrlMatcher);
    }
    return _finalMatchers;
  }, [enableLinks, matchers]);

  return (
    <span className={clsx(classes.limitPointerEventsToText, classes.breakWord)}>
      <AutolinkText
        onPress={onPress}
        text={text}
        renderText={renderText}
        matchers={finalMatchers}
        url={false}
        email={enableMail ?? false}
        phone={false}
        linkStyle={linkStyle}
        onLongPress={onLongPress}
      />
    </span>
  );
};

export { AutoLink };
