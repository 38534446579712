import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaColumnLiteralValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import {
  ModularDisplayExtendedRequirements,
  ModularDisplayRequirements,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { formatAddress } from "@kraaft/shared/core/utils";
import { ColumnComparator } from "@kraaft/web/src/components/modularTable/components/types";

/**
 * Sets the value to `undefined` if `validator` returns false
 * @param value
 * @param validator
 */
const prepareValue = <T>(value: T, validator: (v: T) => boolean) =>
  validator(value) ? undefined : value;

/**
 * Sort the `undefined` values before using the comparator function.
 * @param a
 * @param b
 * @param compare Function to compare both values (values won't be `undefined`)
 * @param reverseOrder Change this if you want the `undefined` values to be before/after the others (default: false)
 */
const compareWithUndefined = <T>(
  a: T,
  b: T,
  compare: (a: NonNullable<T>, b: NonNullable<T>) => number,
  reverseOrder = false,
) => {
  if (a && b) {
    return compare(a as NonNullable<T>, b as NonNullable<T>);
  }
  if (a && !b) {
    return reverseOrder ? -1 : 1;
  }
  if (!a && b) {
    return reverseOrder ? 1 : -1;
  }
  return 0;
};

const compareStrings = (a: string, b: string) =>
  a.trim().localeCompare(b.trim());
const compareNumbers = (a: number, b: number) => (a === b ? 0 : a > b ? 1 : -1);
const compareBoolean = (a: boolean, b: boolean) => (a === b ? 0 : a ? 1 : -1);
const compareDates = (a: Date, b: Date) =>
  a.getTime() === b.getTime() ? 0 : a.getTime() > b.getTime() ? 1 : -1;

function getValues<T extends KColumnType>(
  column: KSchemaColumn<T>,
  a: ModularRecord,
  b: ModularRecord,
) {
  return [a.properties[column.key]?.value, b.properties[column.key]?.value] as [
    KSchemaColumnLiteralValue<T>,
    KSchemaColumnLiteralValue<T>,
  ];
}

export const roomNameRecordComparator =
  (
    _: KSchemaColumn<KColumnType.roomName>,
    displayContext: ModularDisplayRequirements,
    extendedDisplayContext: ModularDisplayExtendedRequirements,
  ): ColumnComparator =>
  (a, b) => {
    const value1 = extendedDisplayContext.getRoomNameFromRecordId(a.id);
    const value2 = extendedDisplayContext.getRoomNameFromRecordId(b.id);
    return compareWithUndefined(value1, value2, compareStrings);
  };

export const stringRecordComparator =
  (
    column: KSchemaColumn<
      | KColumnType.shortText
      | KColumnType.automatedAutoIncrement
      | KColumnType.longText
    >,
  ): ColumnComparator =>
  (a, b) => {
    const values = getValues(column, a, b);

    return compareWithUndefined(values[0], values[1], compareStrings);
  };

export const numberRecordComparator =
  (column: KSchemaColumn<KColumnType.number>): ColumnComparator =>
  (a, b) => {
    const values = getValues(column, a, b);

    return compareWithUndefined(values[0], values[1], compareNumbers);
  };

export const currencyRecordComparator =
  (column: KSchemaColumn<KColumnType.currency>): ColumnComparator =>
  (a, b) => {
    const values = getValues(column, a, b);

    return compareWithUndefined(values[0], values[1], compareNumbers);
  };

export const dateRecordComparator =
  (
    column: KSchemaColumn<KColumnType.date | KColumnType.automatedCreatedAt>,
  ): ColumnComparator =>
  (a, b) => {
    const values = getValues(column, a, b);

    return compareWithUndefined(values[0], values[1], compareDates, false);
  };

export const booleanRecordComparator =
  (column: KSchemaColumn<KColumnType.checkbox>): ColumnComparator =>
  (a, b) => {
    const values = getValues(column, a, b);

    return compareWithUndefined(values[0], values[1], compareBoolean);
  };

export const geolocationRecordComparator =
  (column: KSchemaColumn<KColumnType.geolocation>): ColumnComparator =>
  (geoRecord1, geoRecord2) => {
    const values = getValues(column, geoRecord1, geoRecord2);

    return compareWithUndefined(values[0], values[1], (a, b) =>
      compareStrings(formatAddress(a), formatAddress(b)),
    );
  };

export const attachmentRecordComparator =
  (column: KSchemaColumn<KColumnType.attachment>): ColumnComparator =>
  (attachment1, attachment2) => {
    const values = getValues(column, attachment1, attachment2);

    return compareWithUndefined(
      prepareValue(values[0], isEmpty),
      prepareValue(values[1], isEmpty),
      (a, b) => compareNumbers(a.length, b.length),
    );
  };

export const signatureRecordComparator =
  (column: KSchemaColumn<KColumnType.signature>): ColumnComparator =>
  (attachment1, attachment2) => {
    const values = getValues(column, attachment1, attachment2);

    return compareWithUndefined(
      prepareValue(values[0], isEmpty),
      prepareValue(values[1], isEmpty),
      (a, b) => {
        if (a === b) {
          return 0;
        }
        if (a && !b) {
          return 1;
        }
        return -1;
      },
    );
  };

export const selectRecordComparator =
  (
    column: KSchemaColumn<
      KColumnType.selectSingle | KColumnType.selectMultiple
    >,
  ): ColumnComparator =>
  (record1, record2) => {
    const values = getValues(column, record1, record2);
    const options = column.options;

    const valuesFormatted = [
      values[0]?.map((v) => options[v]),
      values[1]?.map((v) => options[v]),
    ];

    return compareWithUndefined(
      prepareValue(valuesFormatted[0], isEmpty),
      prepareValue(valuesFormatted[1], isEmpty),
      (a, b) => {
        if (a.length !== b.length) {
          return compareNumbers(a.length, b.length);
        }

        if (a[0] && b[0]) {
          return compareNumbers(a[0].index, b[0].index);
        }

        return 0;
      },
    );
  };

export const userRecordComparator =
  (
    column: KSchemaColumn<
      | KColumnType.user
      | KColumnType.roomMembers
      | KColumnType.automatedCreatedBy
    >,
    displayContext: ModularDisplayRequirements,
  ): ColumnComparator =>
  (user1, user2) => {
    const values = getValues(column, user1, user2);
    const userIds = compact(values.flat());
    const users = displayContext.getOrLoadUsers(userIds);

    const valuesFormatted = [
      compact(values[0]?.map((v) => users[v])),
      compact(values[1]?.map((v) => users[v])),
    ];

    return compareWithUndefined(
      prepareValue(valuesFormatted[0], isEmpty),
      prepareValue(valuesFormatted[1], isEmpty),
      (a, b) => {
        if (a.length !== b.length) {
          return compareNumbers(a.length, b.length);
        }

        if (a[0] && b[0]) {
          return compareStrings(a[0].username, b[0].username);
        }

        return 0;
      },
    );
  };
