import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import {
  showError,
  showInfo,
} from "@kraaft/shared/core/modules/alert/alertActions";
import {
  UserPoolActions,
  UserPoolStateActions,
} from "@kraaft/shared/core/modules/userPool/userPool.actions";
import { selectUserPoolNotificationFilter } from "@kraaft/shared/core/modules/userPool/userPool.selectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next/i18next";

export function* changeNotificationFilter(
  action: ReturnType<(typeof UserPoolActions)["changeNotificationFilter"]>,
) {
  const currentValue = yield* select(
    selectUserPoolNotificationFilter(action.payload.poolId),
  );
  try {
    yield* put(
      UserPoolStateActions.setNotificationFilter({
        poolId: action.payload.poolId,
        notificationFilter: action.payload.notificationFilter,
      }),
    );
    yield* call(Api.changePoolNotificationFilter, {
      poolId: action.payload.poolId,
      notificationFilter: action.payload.notificationFilter,
    });
    yield* put(
      showInfo({
        title: i18n.t("notifications.poolPreferencesUpdateInfo"),
      }),
    );
  } catch (e) {
    console.log("on aurait une erreur");
    yield* put(
      UserPoolStateActions.setNotificationFilter({
        poolId: action.payload.poolId,
        notificationFilter: currentValue,
      }),
    );
    yield* put(showError({ title: i18n.t("serverError") }));
  }
}

export function* changeNotificationFilterSaga() {
  yield* takeEvery(
    UserPoolActions.changeNotificationFilter,
    changeNotificationFilter,
  );
}
