import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { MapControllerLayout } from "@kraaft/shared/components/mapController/mapControllerLayout";
import { MapOrNoMarkers } from "@kraaft/shared/components/mapController/mapOrNoMarkers";
import { useMiniMediaClusterConfig } from "@kraaft/shared/components/mapController/markers/miniMediaMarker/useMiniMediaClusterConfig";
import { useModularFolderClusterConfig } from "@kraaft/shared/components/mapController/markers/modularFolderMarker/useModularFolderClusterConfig";
import { MapZoomOutButton } from "@kraaft/shared/components/mapController/roomMap/mapZoomOutButton";
import { useRoomMarkers } from "@kraaft/shared/components/mapController/roomMap/useRoomMarkers";
import { MapTrackingInfo } from "@kraaft/shared/components/mapController/types";
import { useMapControllerPortalHostname } from "@kraaft/shared/components/mapController/useMapControllerPortalHostname";
import { MiniImageWithGeolocation } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import {
  setMinZoomReachedForRoom,
  setVisibleMapMarkersForRoom,
} from "@kraaft/shared/core/modules/room/roomActions";
import { ModularFolderWithGeolocation } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { selectSchemaName } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { RoomUseMapNavigationReturnType } from "@kraaft/shared/core/utils/useMapNavigation/types";

interface ModularFoldersMapProps {
  mapNavigation: RoomUseMapNavigationReturnType;
  hideCloseButton?: boolean;
}

export const RoomMap = ({
  mapNavigation: {
    roomId,
    schemaId,
    initialCenter,
    geoDetail,
    onMarkerAction,
    display,
  },
  hideCloseButton,
}: ModularFoldersMapProps) => {
  const dispatch = useDispatch();
  const schemaName = useSelector(selectSchemaName(schemaId));

  const { isLoading, markers, hasMarkers } = useRoomMarkers({
    display,
    roomId,
    schemaId,
    onMarkerAction,
  });

  const { clusterConfig: modularFolderClusterConfig, modularFolderListPanel } =
    useModularFolderClusterConfig({
      onTooltipPress: onMarkerAction,
    });
  const { clusterConfig: miniMediaClusterConfig, photoGalleryPanel } =
    useMiniMediaClusterConfig({ roomId });

  const handleVisibleMarkersChange = useCallback(
    (
      visibleMarkers: (
        | ModularFolderWithGeolocation
        | MiniImageWithGeolocation
      )[],
    ) => {
      dispatch(
        setVisibleMapMarkersForRoom({
          roomId,
          visibleMarkerIds: visibleMarkers.map((marker) => marker.id),
        }),
      );
    },
    [dispatch, roomId],
  );

  const handleMinZoomReached = useCallback(
    (newState: boolean) => {
      dispatch(setMinZoomReachedForRoom({ roomId, newState }));
    },
    [dispatch, roomId],
  );

  const trackingInfo = useMemo<MapTrackingInfo>(
    () => ({
      map_type: "conversation",
      schema_id: schemaId,
      schema_name: schemaName,
    }),
    [schemaId, schemaName],
  );

  const mapControllerPortalHostname = useMapControllerPortalHostname();

  return (
    <>
      <MapControllerLayout
        withCloseButton={!hideCloseButton}
        topLeftButton={
          hasMarkers && !isNative() && display === "media" ? (
            <MapZoomOutButton roomId={roomId} />
          ) : null
        }
        mapControllerPortalHostname={mapControllerPortalHostname}
      >
        <MapOrNoMarkers
          isLoading={isLoading}
          markers={markers}
          initialCenter={initialCenter}
          geoDetail={geoDetail}
          enableClustering
          clustersConfig={{
            folder: modularFolderClusterConfig,
            media: miniMediaClusterConfig,
          }}
          onVisibleMarkersChange={handleVisibleMarkersChange}
          onMinZoomReached={handleMinZoomReached}
          trackingInfo={trackingInfo}
          mapControllerPortalHostname={mapControllerPortalHostname}
        />
      </MapControllerLayout>
      {modularFolderListPanel}
      {photoGalleryPanel}
    </>
  );
};
