import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { Api } from "@kraaft/shared/core/services/api";
import { CurrencyDropdown } from "@kraaft/web/src/views/settings/managePool/settingsCurrency/currencyDropdown";

interface Props {
  pool: Pool;
  onClose: () => void;
}

const ChangePoolCurrencyDialogContent = ({ onClose, pool }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currencyCode, setCurrencyCode] = useState(pool.currencyCode ?? "");

  const onConfirm = async () => {
    setIsLoading(true);
    try {
      await Api.changePoolCurrency({
        poolId: pool.id,
        currencyCode,
      });
      onClose();
    } catch (error) {
      dispatch(showError({ title: t("error"), message: error.message }));
    } finally {
      setIsLoading(false);
    }
  };

  const actions: FooterActions = [
    {
      accessibilityLabel: t("saveChanges"),
      text: t("saveChanges"),
      onPress: onConfirm,
      loading: isLoading,
      disabled: currencyCode === pool.timeZone,
      variant: "PRIMARY",
    },
  ];

  return (
    <KDialogContent
      title={t("adminCurrency.title")}
      content={
        <CurrencyDropdown
          currency={currencyCode}
          onChange={setCurrencyCode}
          variant="dark"
        />
      }
      actions={actions}
    />
  );
};

export { ChangePoolCurrencyDialogContent };
