import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectRoom } from "@kraaft/shared/core/modules/room/roomSelectors";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { analytics } from "@kraaft/shared/core/services/analytics";

export function useTrackCurrentRoom(roomId: string) {
  const room = useSelector(selectRoom(roomId));
  const roomName = getExistingRoomRecordField(
    room?.record.properties,
    KSchemaRemarkableColumns.ROOM_TITLE,
  );

  useEffect(() => {
    analytics.enterRoom(roomId, roomName);
  }, [roomName, roomId]);

  useEffect(() => {
    return () => analytics.leaveRoom(roomId);
  }, [roomId]);
}
