import { makeStyles } from "@mui/styles";

import { CommonSandbox } from "@kraaft/shared/components/sandbox/common";
import { ColorStyle } from "@kraaft/ui";

export const Sandbox = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CommonSandbox />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100vh",
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
  },
});
