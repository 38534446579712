import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectModularFolderExists,
  selectModularFolderRoomLoadStatus,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { selectSchemaLoaded } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { useTimedOut } from "@kraaft/shared/core/utils/useTimeout";

const TIME_OUT_WAITING_FOR_NETWORK = 5000;

export function useModularFolderStatus(
  roomId: string,
  schemaId: string | undefined,
  modularFolderId: string,
) {
  const dispatch = useDispatch();
  const fetched = useRef<boolean>(false);

  useEffect(() => {
    dispatch(ModularFolderActions.subscribeForRoom({ roomId }));
    return () => {
      dispatch(ModularFolderActions.unsubscribeForRoom({ roomId }));
    };
  }, [dispatch, roomId]);

  const modularFolderExists = useSelector(
    selectModularFolderExists(modularFolderId),
  );
  const schemaExists = useSelector(selectSchemaLoaded(schemaId));
  const roomLoadStatus = useSelector(selectModularFolderRoomLoadStatus(roomId));

  const timedOut = useTimedOut(
    TIME_OUT_WAITING_FOR_NETWORK,
    roomLoadStatus !== "network",
  );

  const isDeleted = roomLoadStatus === "network" && !modularFolderExists;
  const considerNoConnection =
    roomLoadStatus === "cache" && timedOut && !modularFolderExists;

  if (modularFolderExists) {
    fetched.current = true;
  }

  return {
    fetched: fetched.current,
    schemaNotFound: Boolean(!schemaExists && modularFolderExists),
    isDeleted: isDeleted,
    considerNoConnection: considerNoConnection,
    schemaExists,
    modularFolderExists,
  };
}
