import { ModularFolderMap } from "@kraaft/shared/components/mapController/modularFolderMap/modularFolderMap";
import { RoomMap } from "@kraaft/shared/components/mapController/roomMap/roomMap";
import { RoomsMap } from "@kraaft/shared/components/mapController/roomsMap/roomsMap";
import { useMapNavigation } from "@kraaft/shared/core/utils/useMapNavigation";

export const MODULAR_FOLDER_MENU_PORTAL_ID = "modularFolderMenuPortal";

interface MapControllerProps {
  hideCloseButton?: boolean;
  modularFolderMapTopLeftButton?: React.ReactNode;
}

export const MapController = ({
  hideCloseButton,
  modularFolderMapTopLeftButton,
}: MapControllerProps) => {
  const mapNavigation = useMapNavigation();

  // zoom level should be hoisted here to be preserved when switching between maps
  // this way, we can make the deeply nested Map component agnostic of geoDetail query param
  // each no marker info will need to be hoisted up in their specific map component

  switch (mapNavigation.geoDetail) {
    case "room":
      return (
        <RoomMap
          mapNavigation={mapNavigation}
          hideCloseButton={hideCloseButton}
        />
      );
    case "modularFolder":
      return (
        <ModularFolderMap
          mapNavigation={mapNavigation}
          withCloseButton={!hideCloseButton}
          topLeftButton={modularFolderMapTopLeftButton}
          withDetail={false}
        />
      );
    case "rooms":
      return (
        <RoomsMap
          mapNavigation={mapNavigation}
          hideCloseButton={hideCloseButton}
        />
      );
    default:
      return null;
  }
};
