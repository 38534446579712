import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { AttachmentColumnEditor } from "@kraaft/web/src/components/modularTable/components/table/columnHeader/columnEditor/columnEditors/attachmentColumnEditor";
import { attachmentRecordComparator } from "@kraaft/web/src/components/modularTable/components/table/recordComparatorUtils";
import { IFieldConfig } from "@kraaft/web/src/components/modularTable/components/types";

import { AttachmentCell } from "./basicCell";

export const attachmentConfig: IFieldConfig<KColumnType.attachment> = {
  type: KColumnType.attachment,

  TableCell: AttachmentCell,
  TableEditor: null,
  comparator: attachmentRecordComparator,
  columnEditor: AttachmentColumnEditor,
};
