import {
  CompositeCondition,
  Condition,
} from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";

export function addFirstConditionToCompositeCondition(
  baseCondition: CompositeCondition,
  newTopCondition: Condition,
): CompositeCondition {
  return {
    type: "composite",
    operator: baseCondition.operator,
    conditions: [newTopCondition, ...baseCondition.conditions],
  };
}

export function addTopConditionToCondition(
  baseCondition: CompositeCondition | undefined,
  newTopCondition: Condition,
): CompositeCondition {
  return {
    type: "composite",
    operator: "and",
    conditions: baseCondition
      ? [newTopCondition, baseCondition]
      : [newTopCondition],
  };
}
