import { makeStyles } from "@mui/styles";

import { ColorStyle, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    borderRadius: Radius.SMALL,
    overflow: "hidden",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    paddingLeft: Spacing.S12,
  },
});
