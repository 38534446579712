import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import compact from "lodash/compact";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { PoolMember } from "@kraaft/shared/core/modules/poolMember/poolMemberState";
import { Api } from "@kraaft/shared/core/services/api/api";
import { conditionalEntry } from "@kraaft/shared/core/utils/utils";
import { ActionSheetItemProps, ContextMenus } from "@kraaft/ui";
import { RemoveMemberDialog } from "@kraaft/web/src/components/kDialog/customs";
import { TableContextMenuButton } from "@kraaft/web/src/components/TableContextMenuButton/TableContextMenuButton";
import { EditUserAuthDialog } from "@kraaft/web/src/views/settings/editUserAuthDialog";
import { MigrateRoomsDialog } from "@kraaft/web/src/views/settings/migrateRoomsDialog";

import { KTableContext } from "../../../components/kTable";

interface Props {
  isSuperadmin: boolean;
  poolId: string;
  value: PoolMember;
  context: KTableContext;
  disabled: boolean;
  reload: () => void;
}

const OptionButton = (props: Props) => {
  const { isSuperadmin, value, poolId, context, disabled, reload } = props;

  const [dialogOpen, setDialogOpen] = useState<
    "removePoolMember" | "editUserAuth" | "migrateRooms" | "none"
  >("none");
  const { t } = useTranslation();

  const handleCloseDialog = () => {
    setDialogOpen("none");
  };

  const actionsFromRole = useMemo<Array<ActionSheetItemProps>>(
    () =>
      compact([
        conditionalEntry(
          {
            label: t("editUserAuth"),
            onClick: () => setDialogOpen("editUserAuth"),
          },
          isSuperadmin,
        ),
        conditionalEntry(
          {
            label: t("migrateRooms"),
            onClick: () => setDialogOpen("migrateRooms"),
          },
          isSuperadmin,
        ),
        {
          label: t("removeMember"),
          onClick: () => setDialogOpen("removePoolMember"),
          destructive: true,
        },
        conditionalEntry(
          {
            label: t("sso.members.unlink"),
            onClick: async () => {
              await Api.unlinkSSO(value.uid);
              reload();
            },
            destructive: true,
          },
          isSuperadmin,
        ),
      ]),
    [isSuperadmin, t, value.uid, reload],
  );
  const legacyActionFromRole = useMemo<Array<ActionSheetItem>>(
    () =>
      actionsFromRole.map((action) => ({
        style: action.destructive ? "destructive" : "default",
        icon: action.icon,
        label: action.label,
        onPress: action.onClick,
      })),
    [actionsFromRole],
  );

  ContextMenus.useGlobal({
    data: actionsFromRole,
    contextAnchor: context.rowRef,
  });

  return (
    <>
      <TableContextMenuButton
        disabled={disabled}
        actions={legacyActionFromRole}
        context={context}
      />
      {dialogOpen !== "none" && (
        <>
          <RemoveMemberDialog
            superadminPanel={isSuperadmin}
            poolId={poolId}
            data={value}
            open={dialogOpen === "removePoolMember"}
            onClose={handleCloseDialog}
          />
          <EditUserAuthDialog
            poolId={poolId}
            member={value}
            open={dialogOpen === "editUserAuth"}
            onClose={handleCloseDialog}
          />
          <MigrateRoomsDialog
            userId={value.uid}
            poolId={poolId}
            open={dialogOpen === "migrateRooms"}
            onClose={handleCloseDialog}
          />
        </>
      )}
    </>
  );
};

export { OptionButton };
