import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MiniMediaMarker } from "@kraaft/shared/components/mapController/markers/miniMediaMarker/miniMediaMarker";
import { ModularFolderMarker } from "@kraaft/shared/components/mapController/markers/modularFolderMarker/modularFolderMarker";
import { MapMarker } from "@kraaft/shared/components/mapController/types";
import { formatGeolocatedModularFolders } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { MiniMediaActions } from "@kraaft/shared/core/modules/miniMedia/miniMedia.actions";
import { selectGeolocatedMiniMedias } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { MiniImageWithGeolocation } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { selectGeolocatedModularFoldersWithRoomIdAndSchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { RoomMapDisplayMode } from "@kraaft/shared/core/modules/room/roomState";
import { formatGeolocatedMiniMedias } from "@kraaft/shared/core/modules/room/roomUtils";
import { useIsRoomFreemium } from "@kraaft/shared/core/modules/room/useIsRoomFreemium";
import { ModularFolderWithGeolocation } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { RoomUseMapNavigationReturnType } from "@kraaft/shared/core/utils/useMapNavigation/types";

export interface UseRoomMarkersProps<DisplayMode extends RoomMapDisplayMode> {
  roomId: string;
  schemaId: string | undefined;
  onMarkerAction: RoomUseMapNavigationReturnType<DisplayMode>["onMarkerAction"];
  display: DisplayMode;
}

export const useRoomMarkers = <DisplayMode extends RoomMapDisplayMode>({
  roomId,
  schemaId,
  onMarkerAction,
  display,
}: UseRoomMarkersProps<DisplayMode>): {
  isLoading: boolean;
  markers: (
    | MapMarker<ModularFolderWithGeolocation, "folder">
    | MapMarker<MiniImageWithGeolocation, "media">
  )[];
  hasMarkers: boolean;
} => {
  const dispatch = useDispatch();
  const geolocatedModularFolders = useSelector(
    selectGeolocatedModularFoldersWithRoomIdAndSchemaId({ roomId, schemaId }),
  );

  const geolocatedMedias = useSelector(selectGeolocatedMiniMedias(roomId));
  const limitForFreemium = useIsRoomFreemium(roomId);

  useEffect(() => {
    dispatch(ModularFolderActions.subscribeForRoom({ roomId }));
    return () => {
      dispatch(ModularFolderActions.unsubscribeForRoom({ roomId }));
    };
  }, [dispatch, roomId]);

  const handleModularFolderMarkerAction = useCallback(
    (_folderId: string, _schemaId: string) => {
      const onMarkerActionFolder =
        onMarkerAction as RoomUseMapNavigationReturnType<"folder">["onMarkerAction"];
      onMarkerActionFolder({
        type: "folder",
        folderId: _folderId,
        schemaId: _schemaId,
      });
    },
    [onMarkerAction],
  );

  const handleMiniMediaMarkerAction = useCallback(
    (_messageId: string) => {
      const onMarkerActionMedia =
        onMarkerAction as RoomUseMapNavigationReturnType<"media">["onMarkerAction"];
      onMarkerActionMedia({
        type: "media",
        messageId: _messageId,
      });
    },
    [onMarkerAction],
  );

  useEffect(() => {
    dispatch(
      MiniMediaActions.subscribeToMiniMedias({ roomId, limitForFreemium }),
    );
    return () => {
      dispatch(
        MiniMediaActions.unsubscribeFromMiniMedias({
          roomId,
          limitForFreemium,
        }),
      );
    };
  }, [dispatch, limitForFreemium, roomId]);

  const { markers, isLoading } = useMemo(() => {
    const exhaustiveDisplayModeMap = {
      folder: () => {
        const modularFoldersWithCoords = formatGeolocatedModularFolders(
          geolocatedModularFolders,
        );

        return {
          isLoading: false,
          markers: modularFoldersWithCoords.map<
            MapMarker<ModularFolderWithGeolocation, "folder">
          >((geolocatedElement) => ({
            key: geolocatedElement.id,
            element: geolocatedElement,
            clusterAffiliation: "folder",
            renderType: "small",

            Marker: (
              <ModularFolderMarker
                onMarkerAction={handleModularFolderMarkerAction}
                geolocatedElement={geolocatedElement}
              />
            ),
          })),
        };
      },
      media: () => {
        const mediasWithCoords =
          geolocatedMedias && formatGeolocatedMiniMedias(geolocatedMedias);

        return {
          isLoading: mediasWithCoords === undefined,
          markers: (mediasWithCoords || []).map<
            MapMarker<MiniImageWithGeolocation, "media">
          >((geolocatedElement) => ({
            key: geolocatedElement.id,
            element: geolocatedElement,
            clusterAffiliation: "media",
            renderType: "big",

            Marker: (
              <MiniMediaMarker
                onMarkerAction={handleMiniMediaMarkerAction}
                geolocatedElement={geolocatedElement}
              />
            ),
          })),
        };
      },
    } satisfies Record<RoomMapDisplayMode, () => unknown>;

    return exhaustiveDisplayModeMap[display]();
  }, [
    display,
    geolocatedMedias,
    geolocatedModularFolders,
    handleMiniMediaMarkerAction,
    handleModularFolderMarkerAction,
  ]);

  return {
    isLoading,
    markers,
    hasMarkers: markers.length > 0,
  };
};
