import { FlatListProps } from "react-native";

import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import { MiniMedia } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";

export const DOWNLOAD_DIRECTORY_LIST_LOADER_ID =
  "downloadFromDirectoryFrom-directoryList";
export const REMOVE_DIRECTORY_LIST_LOADER_ID =
  "removeFromDirectoryFrom-directoryList";

export type VirtualizedDirectoryListProps = Omit<
  FlatListProps<VirtualizedDirectoryListItem>,
  "data" | "renderItem" | "extractKey" | "ItemSeparatorComponentl"
> & {
  roomId: string;
  directoryId: string;
  directories: Directory[];
  files?: MiniMedia[];
  disableIncompatible?: boolean;
  newDirectoryElement?: React.ReactElement;
  onPressDirectory: (directoryId: string) => void;
  onSelect?: (messageIds: string[], newStatus: boolean) => void;
  cropListSize?: number;
};

export type VirtualizedDirectoryListItem =
  | { displayType: "directory"; data: Directory }
  | { displayType: "file"; data: MiniMedia };
