import { StyleSheet } from "react-native";

import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  link: {
    textDecorationLine: "underline",
  },
  linkDefault: {
    color: Color.WHITE,
  },
  linkReply: {
    color: Color.BLUE_AZURE,
  },
  modified: {
    marginVertical: 2,
  },
  answerStyle: {
    marginHorizontal: 3,
    minWidth: 200,
  },
  headerTextContainer: {
    marginBottom: 2,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: Spacing.S8,
  },
  textContainer: {
    flex: 1,
  },
  editButton: {
    marginRight: -8,
  },
});
