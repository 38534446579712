import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFoldersKanbanView } from "@kraaft/web/src/components/modularFoldersViewer/kanban";
import { ModularFoldersMapView } from "@kraaft/web/src/components/modularFoldersViewer/map";
import { ModularFoldersTableView } from "@kraaft/web/src/components/modularFoldersViewer/table";
import { FormatType } from "@kraaft/web/src/components/visualisationSelector/visualisationSelector.types";

interface Props {
  format: string | null;
  schema: KFolderSchema;
  schemaViewId?: string;
  roomId?: string;
}

const ModularFoldersFormatViewer = (props: Props) => {
  const { format, schema, roomId, schemaViewId } = props;

  if (format === FormatType.TABLE) {
    return (
      <ModularFoldersTableView
        schema={schema}
        roomId={roomId}
        schemaViewId={schemaViewId}
      />
    );
  }

  if (format === FormatType.MAP) {
    return (
      <ModularFoldersMapView
        schema={schema}
        roomId={roomId}
        schemaViewId={schemaViewId}
      />
    );
  }

  if (format === FormatType.KANBAN) {
    return (
      <ModularFoldersKanbanView
        schema={schema}
        roomId={roomId}
        schemaViewId={schemaViewId}
      />
    );
  }

  return null;
};

export { ModularFoldersFormatViewer };
