import { useCallback, useMemo } from "react";
import { FlatList, ListRenderItemInfo, View, ViewStyle } from "react-native";
import { Dictionary } from "ts-essentials";

import { ActionCard } from "@kraaft/shared/components/actionCard";
import { styles } from "@kraaft/shared/components/actionCardList/actionCardList.styles";
import { DetailGroup } from "@kraaft/shared/components/groupButton/detailGroup";
import { getModularFolderSummary } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { SchemaItem } from "@kraaft/shared/core/modules/schema/schema.items.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";

export const SchemaButton = ({
  schemaItem,
  modularFolderList,
  isSchemaDisabled,
  enableScroll,
  onPress,
}: {
  schemaItem: SchemaItem;
  modularFolderList: Array<ModularFolder> | undefined;
  isSchemaDisabled?: (schema: SchemaItem) => boolean;
  enableScroll: boolean | undefined;
  onPress: (schemaId: string) => void;
}) => {
  const description = getModularFolderSummary(
    schemaItem.highlightedCheckboxColumnKey,
    modularFolderList ?? [],
  );

  const disabled = isSchemaDisabled?.(schemaItem);

  const action = useCallback(() => {
    onPress(schemaItem.id);
  }, [onPress, schemaItem.id]);

  return (
    <View style={enableScroll && styles.flatListItem}>
      <ActionCard
        id={`ide2e-ticket-${schemaItem.name.split(" ").join("-")}`}
        disabled={disabled}
        greyedWhenDisabled
        text={schemaItem.name}
        iconName={KSchemaUtils.getSchemaIconName(schemaItem.icon)}
        description={description}
        action={action}
        showBracket
      />
    </View>
  );
};

function extractKey(schemaItem: SchemaItem) {
  return schemaItem.id;
}

export const ModularSchemaListContent = ({
  modularFoldersBySchemaId,
  isSchemaDisabled,
  enableScroll,
  onPress,
  style,
  schemas,
}: {
  schemas: SchemaItem[];
  modularFoldersBySchemaId: Dictionary<ModularFolder[]>;
  isSchemaDisabled?: (schema: SchemaItem) => boolean;
  enableScroll: boolean | undefined;
  onPress: (schemaId: string) => void;
  style: ViewStyle | undefined;
}) => {
  const renderSchemaButton = useCallback(
    (schema: SchemaItem) => {
      return (
        <SchemaButton
          key={schema.id}
          schemaItem={schema}
          modularFolderList={modularFoldersBySchemaId[schema.id]}
          isSchemaDisabled={isSchemaDisabled}
          enableScroll={enableScroll}
          onPress={onPress}
        />
      );
    },
    [enableScroll, isSchemaDisabled, modularFoldersBySchemaId, onPress],
  );

  const renderFlatListItem = useCallback(
    ({ item }: ListRenderItemInfo<SchemaItem>) => renderSchemaButton(item),
    [renderSchemaButton],
  );

  const flatListSyle = useMemo(() => [styles.flatList, style], [style]);

  return enableScroll ? (
    <FlatList
      style={flatListSyle}
      data={schemas}
      keyExtractor={extractKey}
      renderItem={renderFlatListItem}
    />
  ) : (
    <DetailGroup style={style}>{schemas.map(renderSchemaButton)}</DetailGroup>
  );
};
