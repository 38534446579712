import { useContext, useEffect } from "react";
import memoize from "lodash/memoize";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularTableContext } from "@kraaft/web/src/components/modularTable/components/table/modularTableContext";
import { KEditorProps } from "@kraaft/web/src/components/modularTable/components/types";

export const withEditionListener = memoize(
  (EditorComponent: React.ComponentType<KEditorProps<KColumnType>>) =>
    (props: KEditorProps<KColumnType>) => {
      const { setEditing } = useContext(ModularTableContext);

      useEffect(() => {
        setEditing(true);
        return () => {
          setEditing(false);
        };
      }, [setEditing]);

      return <EditorComponent {...props} />;
    },
);
