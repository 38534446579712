import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { addFilesSenderBucket } from "@kraaft/shared/core/modules/fileBucket/fileBucketActions";

export function useAddFilesToRoom(roomId: string) {
  const dispatch = useDispatch();

  const onFilesAdded = useCallback(
    (files: ModernFile<LocalPath>[]) => {
      dispatch(addFilesSenderBucket({ id: roomId, files }));
    },
    [dispatch, roomId],
  );

  const onDrop = useCallback(
    (acceptedFiles: ModernFile<LocalPath>[]) => {
      onFilesAdded(acceptedFiles);
    },
    [onFilesAdded],
  );

  return onDrop;
}
