import { useMemo } from "react";
import { useDragLayer } from "react-dnd";
import { makeStyles } from "@mui/styles";
import { noop } from "ts-essentials";

import { Color, Radius } from "@kraaft/ui";
import { useDragLayerStyles } from "@kraaft/web/src/components/dragAndDrop/dragLayerBase.styles";
import { getTranslationStyle } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/editSchemaDragLayer.utils";
import { ElementPickerColumnItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementPicker/elementPickerColumnItem/elementPickerColumnItem";
import { ElementPickerSectionItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementPicker/elementPickerSectionItem";
import {
  FormBuilderDropTypes,
  InsertElementDropItem,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";

export const EditSchemaDragLayer = () => {
  const dragLayerClasses = useDragLayerStyles();

  const { item, currentOffset, isDragging } = useDragLayer((monitor) => {
    const dragItem = monitor.getItem() as InsertElementDropItem | null;

    return {
      item: dragItem,
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.getItemType() === FormBuilderDropTypes.INSERT,
    };
  });
  const classes = useStyles();

  const content = useMemo(
    () =>
      item?.insertType === "section" ? (
        <ElementPickerSectionItem onAdd={noop} />
      ) : item?.insertType === "column" ? (
        <ElementPickerColumnItem
          element={{ type: "column", columnType: item.columnType }}
          onAdd={noop}
        />
      ) : null,
    [item],
  );

  if (!isDragging) {
    return null;
  }

  return (
    <div className={dragLayerClasses.layerBase}>
      <div style={getTranslationStyle(currentOffset)}>
        <div className={classes.itemContainer}>{content}</div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  itemContainer: {
    width: 250,
    backgroundColor: Color.BLUE_LAVENDER,
    borderRadius: Radius.SMALL,
  },
});
