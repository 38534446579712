import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    position: "relative",
    maxWidth: 600,
    zIndex: 1,
  },

  poolNameInput: {
    border: `1px solid ${ColorStyle.SEPARATOR}`,
  },

  suggestionsContainer: {
    marginTop: Spacing.S8,
    width: "100%",
    borderRadius: Radius.SMALL,
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
  },

  suggestionsList: {
    listStyleType: "none",
    padding: Spacing.NONE,
    margin: Spacing.NONE,
    backgroundColor: Color.WHITE,

    "& li:not(:last-child)": {
      "& $suggestionRow:after": {
        content: '""',
        height: 1,
        width: `calc(100% - ${Spacing.S12}px)`,
        alignSelf: "flex-end",
        backgroundColor: ColorStyle.SEPARATOR,
      },
    },
  },

  suggestionHighlighted: {
    backgroundColor: ColorStyle.ACTION_HOVERED,
  },

  suggestionRow: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },

  suggestionRowContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S16,
    justifyContent: "space-between",
    padding: `${Spacing.S8}px ${Spacing.S16}px`,
    boxSizing: "border-box",
  },

  suggestionRowTitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  suggestionRowTags: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
  },
});
