import { ensureValidStyleSheet } from "@kraaft/shared/core/utils";
import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export const baseStyles = ensureValidStyleSheet({
  container: {
    flex: 1,
    display: "flex" as const,
    flexDirection: "column" as const,
    backgroundColor: Color.WHITE,
    width: "100%",
    height: "100%",
  },
  emptyList: {
    fontFamily: "Apercu",
    fontWeight: "700" as const,
    fontSize: 16,
    color: ColorStyle.FONT_LOW_EMPHASIS,
    textAlign: "center" as const,
    marginVertical: Spacing.S16,
  },
  buttonContainer: {
    paddingLeft: Spacing.S16,
    paddingTop: Spacing.S24,
    paddingBottom: Spacing.S16,
    paddingRight: Spacing.S16,
    bottom: 0,
  },
  list: {
    flex: 1,
    height: 0,
  },
});
