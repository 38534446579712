import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { useStartEditingMessageText } from "@kraaft/shared/components/messageTextEditor";
import { getEditMessageMenuLabel } from "@kraaft/shared/components/messageTextEditor/messageTextEditorUtils";
import { utils as messageUtils } from "@kraaft/shared/core/modules/message";
import { Message } from "@kraaft/shared/core/modules/message/messageState";
import { isReply } from "@kraaft/shared/core/modules/message/messageUtils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useNetwork } from "@kraaft/shared/core/utils/useNetwork";

export function useEditTranscriptionMenuItems(
  roomId: string,
  message: Message,
) {
  const { t } = useTranslation();
  const { ensureOnline } = useNetwork();
  const startEditingMessageText = useStartEditingMessageText();

  return useMemo(() => {
    const items: ActionSheetItem[] = [];

    if (
      message.type === "audio" &&
      messageUtils.isPersisted(message) &&
      !isReply(message)
    ) {
      items.push({
        label: getEditMessageMenuLabel(t, message),
        onPress: ensureOnline(() => {
          trackEvent({
            eventName: "Click Message Action Sheet Option",
            room_id: roomId,
            action: "edit transcription",
          });
          startEditingMessageText(message);
        }),
        icon: "edit-02",
      });
    }
    return items;
  }, [ensureOnline, message, roomId, startEditingMessageText, t]);
}
