import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, Text } from "react-native";

import { SkinTone } from "@kraaft/shared/components/emojiSelector/emoji.types";
import { charFromUtf16 } from "@kraaft/shared/components/emojiSelector/emoji.utils";
import { Color, PixelSize } from "@kraaft/ui";

export const SKIN_TONE_ELEMENT_WIDTH = PixelSize.S32;

type SkinToneElementProps = {
  skinTone: SkinTone;
  onPress: () => void;
};

const SkinToneElement = ({ skinTone, onPress }: SkinToneElementProps) => {
  const { t } = useTranslation();

  return (
    <Pressable
      style={styles.container}
      onPress={onPress}
      accessibilityLabel={t("changeSkinTone")}
    >
      <Text style={styles.emoji} selectable={false}>
        {charFromUtf16(skinTone.displayCode)}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    height: PixelSize.S40,
    width: SKIN_TONE_ELEMENT_WIDTH,
    justifyContent: "center",
    alignItems: "center",
  },
  emoji: {
    fontSize: 20,
    color: Color.BLACK,
  },
});

export { SkinToneElement };
