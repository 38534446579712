import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  text: {
    fontSize: FontSize.MEDIUM,
    fontWeight: "normal",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    whiteSpace: "pre-line",
  },
  spacer: {
    marginBottom: Spacing.S16,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: Spacing.S24,
  },
});
