import { CurrentUser, User } from "@kraaft/shared/core/modules/user/userState";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";

export enum UserRoleRank {
  outsider = 0,
  external = 1,
  standard = 2,
  administrator = 3,
  owner = 4,
}

export function canDetermineCurrentPool(currentUser: CurrentUser | undefined) {
  return currentUser?.pools;
}

export function getUserPoolRole(
  currentUser: CurrentUser | User | undefined,
  poolId: string | undefined,
): UserPoolRole {
  if (poolId === undefined) {
    return UserPoolRole.OUTSIDER;
  }
  const userRole = currentUser?.pools?.[poolId]?.role;
  if (userRole) {
    return userRole;
  }
  return UserPoolRole.OUTSIDER;
}

export const isPoolOwner = (role: UserPoolRole) => {
  if (!role) {
    return false;
  }
  return UserRoleRank[role] === UserRoleRank.owner;
};

export const isAtLeastPoolAdmin = (role: UserPoolRole | undefined) => {
  if (!role) {
    return false;
  }
  return UserRoleRank[role] >= UserRoleRank.administrator;
};

export const isAtLeastPoolStandard = (role: UserPoolRole | undefined) => {
  if (!role) {
    return false;
  }
  return UserRoleRank[role] >= UserRoleRank.standard;
};

export const isRoleHigher = (
  currentRole = UserPoolRole.OUTSIDER,
  role = UserPoolRole.STANDARD,
) => UserRoleRank[currentRole] >= UserRoleRank[role];

export const isRoleStrictlyHigher = (
  firstRole = UserPoolRole.OUTSIDER,
  secondRole = UserPoolRole.OUTSIDER,
) => UserRoleRank[firstRole] > UserRoleRank[secondRole];
