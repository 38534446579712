import { SyntheticEvent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { SchemaDefaultVisibility } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  SchemaOptimisticActions,
  SchemaOptimisticOperations,
  SchemaOptimisticSelector,
} from "@kraaft/shared/core/modules/schema/schema.optimistic";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Button } from "@kraaft/ui";

export function useSchemaDefaultVisibility(
  schemaId: string,
  source: "schema_list" | "schema_builder",
) {
  const dispatch = useDispatch();
  const schema = useSelector(SchemaOptimisticSelector.selectBuilt(schemaId));

  const setDefaultVisibility = useCallback(
    async (defaultVisibility: SchemaDefaultVisibility) => {
      dispatch(
        SchemaOptimisticActions.addOperation(
          SchemaOptimisticOperations.setDefaultVisibility.create({
            targetId: schemaId,
            defaultVisibility,
          }),
        ),
      );

      trackEvent({
        eventName:
          defaultVisibility === "visible"
            ? "Schema Visible By Default"
            : "Schema Hidden By Default",
        schema_id: schemaId,
        schema_name: schema?.name ?? "",
        source: source,
      });
    },
    [dispatch, schemaId, schema?.name, source],
  );

  if (schema?.collection !== "folder") {
    return [] as const;
  }

  return [schema.defaultVisibility, setDefaultVisibility] as const;
}

export function useSchemaDefaultVisibilityActionSheetItem(
  schemaId: string,
  source: "schema_list" | "schema_builder",
): ActionSheetItem {
  const { t } = useTranslation();

  const [defaultVisibility, setDefaultVisibility] = useSchemaDefaultVisibility(
    schemaId,
    source,
  );

  return useMemo(
    () => ({
      label:
        defaultVisibility === "visible"
          ? t("schemasView.hide")
          : t("schemasView.show"),
      icon: defaultVisibility === "visible" ? "eye-off" : "eye",
      onPress: () => {
        setDefaultVisibility?.(
          defaultVisibility === "visible" ? "hidden" : "visible",
        ).catch(console.error);
      },
    }),
    [defaultVisibility, setDefaultVisibility, t],
  );
}

export const SchemaDefaultVisibilityButton = (props: {
  schemaId: string;
  source: "schema_list" | "schema_builder";
}) => {
  const { t } = useTranslation();

  const [defaultVisibility, setDefaultVisibility] = useSchemaDefaultVisibility(
    props.schemaId,
    props.source,
  );

  const onPress = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      setDefaultVisibility?.(
        defaultVisibility === "visible" ? "hidden" : "visible",
      ).catch(console.error);
      e.stopPropagation();
    },
    [defaultVisibility, setDefaultVisibility],
  );

  return (
    <Button
      checked={defaultVisibility === "visible"}
      tooltip={
        defaultVisibility === "visible"
          ? t("schemasView.tooltipVisible")
          : t("schemasView.tooltipHidden")
      }
      tooltipPlacement="right"
      icon={defaultVisibility === "visible" ? "eye" : "eye-off"}
      variant="TERTIARY"
      onPress={onPress}
    />
  );
};
