import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { KDialogConfig } from "@kraaft/shared/components/kDialog/kDialogProps";
import { Toggle } from "@kraaft/shared/components/toggle";
import { BrowserPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { fileHelper } from "@kraaft/shared/core/modules/file/fileHelper/fileHelper.provider";
import { actions as loaderActions } from "@kraaft/shared/core/modules/loaders";
import { selectLoader } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { generateAddReportTemplateLoaderId } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.actions.utils";
import { getReportTemplateDropzoneParameters } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.utils";
import { selectSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { Text } from "@kraaft/ui";
import {
  FileDropzone,
  FileResult,
} from "@kraaft/web/src/components/fileDropzone";

import { useStyles } from "./addReportTemplateDialog.styles";

export type AddReportTemplate = (params: {
  file: ModernFile<BrowserPath>;
  schemaId: string;
  name: string;
  forceAdd: boolean;
}) => void;

export interface AddReportTemplateDialogProps {
  open: boolean;
  onClose: () => void;
  schemaId: string;
  onAdd: AddReportTemplate;
}

const AddReportTemplateDialog = (props: AddReportTemplateDialogProps) => {
  const { open, onClose, schemaId, onAdd } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [name, setName] = useState("");
  const [fileResult, setFileResult] = useState<FileResult | undefined>(
    undefined,
  );
  const [forceAdd, setForceAdd] = useState(false);
  const loader = useSelector(
    selectLoader(generateAddReportTemplateLoaderId(schemaId)),
  );
  const schema = useSelector(selectSchema(schemaId));

  const isSuperAdmin = useSelector(selectCurrentUserIsSuperadmin);

  const isLoading = loader?.status === LoaderStatus.LOADING;
  const hasErrors = loader?.status === LoaderStatus.FAILURE;
  const isDisabled =
    fileResult === undefined || name.length === 0 || (hasErrors && !forceAdd);

  const resetFileErrors = useCallback(() => {
    if (loader !== undefined) {
      dispatch(loaderActions.clearLoader(loader.id));
    }
  }, [dispatch, loader]);

  useEffect(() => {
    if (open) {
      setName("");
      setForceAdd(false);
      setFileResult(undefined);
      resetFileErrors();
    }
    // we don't want to listen to resetFileErrors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    resetFileErrors();
    // we don't want to listen to resetFileErrors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaId, fileResult]);

  useEffect(() => {
    if (loader !== undefined && loader.status === LoaderStatus.SUCCESS) {
      onClose();
    }
  }, [onClose, loader]);

  const handleUploadTemplate = useCallback(() => {
    if (fileResult === undefined) {
      return;
    }
    onAdd({
      file: fileHelper.fromWebFile(fileResult.file),
      schemaId,
      name,
      forceAdd,
    });
  }, [fileResult, onAdd, schemaId, name, forceAdd]);

  const extraErrorsMessages = useMemo(() => {
    if (loader?.status === LoaderStatus.FAILURE) {
      return [loader.error.message];
    }
  }, [loader]);

  const forceAddToggle = useMemo(() => {
    if (!isSuperAdmin || loader?.status !== LoaderStatus.FAILURE) {
      return null;
    }
    return (
      <div className={classes.forceAddContainer}>
        <Toggle value={forceAdd} setValue={setForceAdd} />
        <Text size="BODY" color="FONT_HIGH_EMPHASIS">
          {t("forceEdition")}
        </Text>
      </div>
    );
  }, [classes.forceAddContainer, forceAdd, isSuperAdmin, loader?.status, t]);

  const { acceptedMimeTypes, invalidFileErrorKey } =
    getReportTemplateDropzoneParameters(schema?.collection ?? "folder");

  const content = useMemo(
    () => (
      <div
        className={clsx(classes.dialogContentContainer, {
          [classes.withInteractionsDisabled]: isLoading,
        })}
      >
        <Text size="MEDIUM" color="FONT_HIGH_EMPHASIS">
          {t("reportTemplate.nameLabel")}
        </Text>
        <div className={classes.inputContainer}>
          <KInput
            nativeID="ide2e-template-name"
            label={t("reportTemplate.nameLabel")}
            value={name}
            onChangeText={setName}
            disableAutocomplete
            autoComplete="off"
            autoFocus
          />
        </div>

        <FileDropzone
          accept={acceptedMimeTypes}
          maxSize={100_000_000}
          invalidFileErrorKey={invalidFileErrorKey}
          fileResult={fileResult}
          setFileResult={setFileResult}
          extraErrorsMessages={extraErrorsMessages}
        />

        {forceAddToggle}
      </div>
    ),
    [
      classes.dialogContentContainer,
      classes.withInteractionsDisabled,
      classes.inputContainer,
      isLoading,
      t,
      name,
      acceptedMimeTypes,
      invalidFileErrorKey,
      fileResult,
      extraErrorsMessages,
      forceAddToggle,
    ],
  );

  const dialog: KDialogConfig = {
    title: t("addTemplate"),
    content,
    actions: [
      {
        id: "ide2e-template-add",
        accessibilityLabel: t("add"),
        text: t("add"),
        onPress: handleUploadTemplate,
        disabled: isDisabled,
        loading: isLoading,
        variant: "PRIMARY",
      },
      {
        accessibilityLabel: t("close"),
        text: t("close"),
        onPress: onClose,
        autoFocus: true,
      },
    ],
  };

  return <KDialog open={open} onClose={onClose} {...dialog} />;
};

export { AddReportTemplateDialog };
