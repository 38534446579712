import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useMeshContext } from "@kraaft/helper-hooks";
import { RoomFiltersContext } from "@kraaft/shared/components/filters/roomFilters.context";

export function useVisibilitySummary() {
  const { t } = useTranslation();

  const { visibility, showArchived } = useMeshContext(RoomFiltersContext);

  const visibilityLabel = useMemo(() => {
    if (visibility === "superadmin") {
      return t("roomFilters.support");
    }
    if (visibility === "all") {
      return t("roomFilters.allConversations");
    }
    return t("roomFilters.myConversations");
  }, [t, visibility]);

  if (showArchived) {
    return `${t("roomFilters.archived")}, ${visibilityLabel}`;
  }
  return visibilityLabel;
}
