import { StyleSheet } from "react-native";

import { Color } from "@kraaft/ui";

export const styles = StyleSheet.create({
  checkBackground: {
    position: "absolute",
    backgroundColor: Color.WHITE,
    borderRadius: 5,
  },
});
