import { CarouselIconButton } from "@kraaft/ui";

interface DeleteToolbarProps {
  onDelete(): void;
}

export const DeleteToolbar = ({ onDelete }: DeleteToolbarProps) => {
  return (
    <CarouselIconButton
      icon="trash-01"
      accessibilityLabel="delete"
      onPress={onDelete}
    />
  );
};
