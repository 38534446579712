import { useCallback, useContext, useRef } from "react";
import { NativeScrollEvent, NativeSyntheticEvent } from "react-native";

import { RoomListContext } from "@kraaft/shared/components/roomList/roomListContext";

export function useOnScrollRoomList() {
  const prevOffset = useRef(0);
  const setScrollingDown = useContext(RoomListContext).setScrollingDown;
  const onScroll = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      const currentOffset = e.nativeEvent.contentOffset.y;
      const dif = currentOffset - prevOffset.current;

      if (currentOffset <= 0) {
        // top of page
        setScrollingDown(false);
      } else if (
        currentOffset -
          e.nativeEvent.contentSize.height +
          e.nativeEvent.layoutMeasurement.height >=
        -1
      ) {
        // bottom of page
        setScrollingDown(true);
      } else if (dif > 0) {
        setScrollingDown(true);
      } else if (dif < 0) {
        setScrollingDown(false);
      }
      prevOffset.current = currentOffset;
    },

    [setScrollingDown],
  );

  return onScroll;
}
