import { createReducer } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";

import { defaultFeatureFlags } from "@kraaft/shared/core/modules/featureFlags/featureFlags.default";
import { FeatureFlagsState } from "@kraaft/shared/core/modules/featureFlags/featureFlags.state";
import { storage } from "@kraaft/shared/core/modules/storage/storage.provider";

import { FeatureFlagsStateActions } from "./featureFlags.actions";

const initialState: FeatureFlagsState = {
  items: defaultFeatureFlags,
};

const featureFlagsReducer = createReducer(initialState, ({ addCase }) => {
  addCase(FeatureFlagsStateActions.set, (state, { payload }) => {
    state.items = payload;
  });

  addCase(FeatureFlagsStateActions.setPartial, (state, { payload }) => {
    state.items = { ...state.items, ...payload };
  });
});

const featureFlagsPersistConfig: PersistConfig<FeatureFlagsState> = {
  key: "featureFlags",
  storage,
  version: 0,
};

const persistedReducer = persistReducer(
  featureFlagsPersistConfig,
  featureFlagsReducer,
);

export { persistedReducer as featureFlagReducers };
