import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import keyBy from "lodash/keyBy";
import { Dictionary } from "ts-essentials";

import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import {
  Form,
  KizeoFormExport,
} from "@kraaft/shared/core/modules/form/formState";
import {
  getKizeoExportName,
  KizeoDefaultExport,
} from "@kraaft/shared/core/modules/form/formUtils";
import { Api } from "@kraaft/shared/core/services/api";
import { Button, Preloader, Sheet } from "@kraaft/ui";

import { inlineStyles, useStyles } from "./selectKizeoExportSheet.styles";

function getExportId(format: KizeoFormExport) {
  switch (format.type) {
    case "defaultPdf":
      return format.type;
    default:
      return `${format.type}-${format.exportId}-pdf:${format.pdf})`;
  }
}

interface Props {
  form: Form;
}

export const SelectKizeoExportSheet = Sheet({
  web: "popup",
})
  .create<Props>(({ Header, Content, Footer, Paper }) => (props) => {
    const { form, onClose } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();

    const title = t("kizeoSelectExportsTitle");
    const subtitle = t("kizeoSelectExportsSubtitle");

    const [selectedIds, setSelectedIds] = useState(
      useMemo(
        () =>
          (form.exports || [KizeoDefaultExport]).map((data) =>
            getExportId(data),
          ),
        [form.exports],
      ),
    );
    const [candidateFormats, setCandidateFormats] = useState<
      Dictionary<KizeoFormExport>
    >({});
    const [isLoadingForms, setIsLoadingForms] = useState(true);
    const [isUpdatingForms, setIsUpdatingForms] = useState(false);

    useEffect(() => {
      const loadForms = async () => {
        try {
          setCandidateFormats({});
          setIsLoadingForms(true);
          const formats = await Api.loadKizeoFormExports({
            formId: form.id,
          });
          setCandidateFormats(keyBy(formats, getExportId));
        } catch (e) {
          console.log("Error fetching exports", e);
          dispatch(showError({ title: t("serverError") }));
        } finally {
          setIsLoadingForms(false);
        }
      };

      loadForms().catch(console.log);
    }, [dispatch, form, t]);

    const items = useMemo<KSelectableListItem[]>(() => {
      return Object.entries(candidateFormats).map(([id, format]) => ({
        identifier: id,
        label: getKizeoExportName(format),
      }));
    }, [candidateFormats]);

    const onValidate = async () => {
      setIsUpdatingForms(true);
      try {
        const selectedIdSet = new Set(selectedIds);
        let exports = Object.entries(candidateFormats)
          .filter(([id]) => selectedIdSet.has(id))
          .map(([, value]) => value);
        if (exports.length === 0) {
          exports = [KizeoDefaultExport];
        }

        await Api.updateKizeoFormExports({
          formId: form.id,
          exports,
        });
        onClose();
      } catch (e) {
        console.log("Error uploading exports", e);
        dispatch(showError({ title: t("serverError") }));
      } finally {
        setIsUpdatingForms(false);
      }
    };

    const content = (
      <div className={classes.content}>
        {isLoadingForms ? (
          <Preloader absoluteFill transparent />
        ) : (
          <KSelectionList
            style={inlineStyles.list}
            items={items}
            isMultiple={true}
            selectAll={true}
            selected={selectedIds}
            setSelected={setSelectedIds}
          />
        )}
      </div>
    );

    return (
      <Paper>
        <Header onClose={onClose}>{title}</Header>
        <Content>
          {subtitle}
          {content}
        </Content>
        <Footer>
          <Button
            text={t("validate")}
            onPress={onValidate}
            loading={isUpdatingForms}
            variant="PRIMARY"
            autoFocus
          />
          <Button text={t("cancel")} onPress={onClose} />
        </Footer>
      </Paper>
    );
  })
  .withDefaults({ size: "SMALL" });
