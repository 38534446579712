import { useMemo } from "react";

import { useMeshContext } from "@kraaft/helper-hooks";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ElementPickerColumnItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementPicker/elementPickerColumnItem";
import { ElementPickerItemDrag } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementPicker/elementPickerItemDrag";
import { ElementPickerSectionItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementPicker/elementPickerSectionItem";
import { SchemaBuilderContext } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

import { useStyles } from "./elementPicker.styles";

interface ElementPickerProps {
  availableColumnTypes: KColumnType[];
}

const ElementPicker = ({ availableColumnTypes }: ElementPickerProps) => {
  const styles = useStyles();
  const { addElement, blurEverything } = useMeshContext(SchemaBuilderContext);

  const items = useMemo(
    () => [
      <ElementPickerItemDrag
        key="section"
        element={{
          type: "section",
        }}
        onDragStart={blurEverything}
      >
        <ElementPickerSectionItem
          onAdd={() => addElement({ type: "section" })}
        />
      </ElementPickerItemDrag>,
      ...availableColumnTypes.map((columnType) => (
        <ElementPickerItemDrag
          key={columnType}
          element={{ type: "column", columnType }}
          onDragStart={blurEverything}
        >
          <ElementPickerColumnItem
            element={{ type: "column", columnType }}
            onAdd={() => addElement({ type: "column", columnType })}
          />
        </ElementPickerItemDrag>
      )),
    ],
    [addElement, availableColumnTypes, blurEverything],
  );

  return <div className={styles.root}>{items}</div>;
};

export { ElementPicker };
