import { View } from "react-native";

import { Text } from "@kraaft/ui";

interface TooltipReactionLineProps {
  name: string;
}

export const TooltipReactionLine = ({ name }: TooltipReactionLineProps) => {
  return (
    <View>
      <Text size="BODY">{name}</Text>
    </View>
  );
};
