import { useMemo } from "react";

import { EmojiName, getEmoji } from "@kraaft/shared/core/utils/emoji";
import { IconSize, Text } from "@kraaft/ui";

interface Props {
  name: EmojiName;
  size?: number;
}

const Emoji = ({ name, size = IconSize.MEDIUM }: Props) => {
  const emoji = useMemo(() => getEmoji(name), [name]);

  return <Text size={size}>{emoji}</Text>;
};

export { Emoji };
