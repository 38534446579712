import { makeStyles } from "@mui/styles";

import { Color } from "@kraaft/ui";

const CURSOR_PADDING = 8;

export const useStyles = makeStyles({
  draggedItem: {
    padding: "4px 8px",
    backgroundColor: Color.WHITE,
    borderRadius: 4,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",

    transform: `translate(${CURSOR_PADDING}px, ${CURSOR_PADDING}px)`,
  },
});
