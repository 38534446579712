import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { SectionHeaderIconButton } from "@kraaft/shared/components/conversationDetails/sectionHeader";
import { DocumentSectionMenuProps } from "@kraaft/shared/components/conversationDetails/worksiteFolder/documentSection/documentSectionMenu/documentSectionMenu.types";
import {
  ActionSheetItem,
  LegacyActionSheet,
} from "@kraaft/shared/components/legacyActionSheet";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { ColorStyle } from "@kraaft/ui";
import { EditDirectoryTreeDialog } from "@kraaft/web/src/components/directory/editDirectoryTreeDialog";

export const DocumentSectionMenu = (props: DocumentSectionMenuProps) => {
  const { roomId } = props;
  const { t } = useTranslation();
  const menuRef = useRef(null);
  const [isMenuOpen, openMenu, closeMenu] = useBooleanState();
  const [
    isEditDirectoryTreeDialogOpen,
    openEditDirectoryTreeDialog,
    closeEditDirectoryTreeDialog,
  ] = useBooleanState();

  const menuActions = useMemo<ActionSheetItem[]>(
    () => [
      {
        icon: "folder-fill",
        label: t("directory.manageDirectories"),
        onPress: openEditDirectoryTreeDialog,
      },
    ],
    [openEditDirectoryTreeDialog, t],
  );

  return (
    <>
      <SectionHeaderIconButton
        accessibilityLabel={t("menu")}
        ref={menuRef}
        icon="dots-vertical"
        iconColor={ColorStyle.ACTION_CTA}
        onPress={openMenu}
      />

      <LegacyActionSheet
        anchorRef={menuRef}
        options={menuActions}
        open={isMenuOpen}
        onClose={closeMenu}
      />
      <EditDirectoryTreeDialog
        roomId={roomId}
        open={isEditDirectoryTreeDialogOpen}
        onClose={closeEditDirectoryTreeDialog}
      />
    </>
  );
};
