import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ConversationDetailButton } from "@kraaft/shared/components/conversationDetailButton";
import * as SegmentedControl from "@kraaft/shared/components/segmentedControl";
import {
  useNavigationService,
  useRouteService,
} from "@kraaft/shared/core/services/navigation";
import { Box } from "@kraaft/web/src/components/box";
import { useMapTabs } from "@kraaft/web/src/views/messenger/mapBar/useMapTabs";
import { useConversationLayoutInfo } from "@kraaft/web/src/views/messenger/useConversationLayoutInfo";

type Props = { roomId: string };

const useMapBarDrawerButton = (roomId: string) => {
  const navigation = useNavigationService();
  const routes = useRouteService();
  const isOpen = routes.isDrawerOpen();
  const toggleDrawer = useCallback(() => {
    navigation.toggleConversationDetail({
      roomId,
      open: !isOpen,
    });
  }, [navigation, roomId, isOpen]);

  const layout = useConversationLayoutInfo();

  return {
    show: !layout.canDisplayConversation,
    isOpen,
    toggleDrawer,
  };
};

export const MapBar = ({ roomId }: Props) => {
  const { t } = useTranslation();

  const [tabs, select, selected] = useMapTabs(roomId);
  const { show, isOpen, toggleDrawer } = useMapBarDrawerButton(roomId);

  return (
    <Box row justify="space-between" ph="S8" items="center" bb height={49}>
      <Box overflow="hidden">
        <SegmentedControl.Container
          size="SMALL"
          onPress={select}
          selected={selected}
        >
          {tabs.map(({ id, icon, name }) => (
            <SegmentedControl.Segment
              key={id}
              id={id}
              text={name}
              icon={icon}
            />
          ))}
        </SegmentedControl.Container>
      </Box>
      {show && (
        <ConversationDetailButton
          accessibilityLabel={isOpen ? t("hide") : t("show")}
          side={isOpen ? "right" : "left"}
          onPress={toggleDrawer}
        />
      )}
    </Box>
  );
};
