import { useCallback, useMemo } from "react";

import { useCompanies } from "@kraaft/shared/core/modules/company/hooks/useCompanies";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";

interface CompanyDropdownProps {
  value: string | undefined;
  onChange: (companyId: string | undefined) => void;
  placeholder?: string;
}

export const CompanyDropdown = ({
  value,
  onChange,
  placeholder,
}: CompanyDropdownProps) => {
  const companies = useCompanies();

  const items = useMemo<FilteredListItem[]>(
    () =>
      [...companies].map((company) => ({
        value: company.id,
        label: company.name,
      })),
    [companies],
  );

  const handleSelection = useCallback(
    (selection: string[] | undefined) => {
      if (!selection) {
        return onChange(undefined);
      }
      const [first] = selection;
      if (!first) {
        return onChange(undefined);
      }
      return onChange(first);
    },
    [onChange],
  );

  return (
    <KDropdown
      placeholder={placeholder}
      withEmptyRow
      items={items}
      allowMultiple={false}
      selectedItemIds={value ? [value] : undefined}
      onSelectionChange={handleSelection}
      fullWidth
      variant="dark"
    />
  );
};
