import { makeStyles } from "@mui/styles";

import { Color } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    width: "650px",
    maxHeight: "500px",
    padding: "15px",
    backgroundColor: Color.WHITE,
  },
  filterTitle: {
    fontWeight: "bold",
    fontSize: 20,
  },
  addFilterButton: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  filterContainer: {
    overflow: "auto",
    width: "100%",
    marginTop: "10px",
    maxHeight: "300px",
  },
  contentRoot: {
    wdith: "100%",
  },
  apply: {
    textAlign: "right",
  },
});
