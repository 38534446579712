/* eslint-disable complexity */

import { useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { Preloader } from "@kraaft/ui";
import { ConnectedChangePoolLanguageDialog } from "@kraaft/web/src/views/settings/changePoolLanguageDialog.connected";

export interface ChangePoolLanguageDialogProps {
  open: boolean;
  onClose: () => void;
}

const ChangePoolLanguageDialog = (props: ChangePoolLanguageDialogProps) => {
  const poolId = useSelector(selectCurrentPoolId);

  return poolId ? (
    <ConnectedChangePoolLanguageDialog {...props} poolId={poolId} />
  ) : (
    <Preloader absoluteFill />
  );
};

export { ChangePoolLanguageDialog };
