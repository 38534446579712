import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import {
  Directory,
  ROOT_DIRECTORY_ID,
} from "@kraaft/shared/core/modules/directory/directory";
import { DirectoryActions } from "@kraaft/shared/core/modules/directory/directoryActions";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { useLoaderState } from "@kraaft/shared/core/utils/useLoader";
import { ActionSheetItemProps } from "@kraaft/ui";

type UseDirectoryMenuActionsProps = {
  roomId: string;
  directory: Directory;
  onOpenRenameDirectoryDialog: () => void;
  disabled?: boolean;
  onPromptDelete?: () => void;
};

export function useDirectoryMenuActions({
  roomId,
  directory,
  onOpenRenameDirectoryDialog,
  onPromptDelete,
}: UseDirectoryMenuActionsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigationService = useNavigationService();

  const loaderId = `downloadDirectory${directory.id}`;

  const { loading } = useLoaderState(loaderId);

  const handleDownloadDirectory = useCallback(() => {
    dispatch(
      DirectoryActions.downloadDirectory({
        roomId: roomId,
        directoryId: directory.id,
        loaderId,
      }),
    );
  }, [directory.id, dispatch, loaderId, roomId]);

  const onDelete = useCallback(() => {
    onPromptDelete?.();
    newConfirmationAlert({
      title: t("directory.removeFolderConfirmationTitle"),
      message: t("directory.removeFolderConfirmationMessage"),
      style: "destructive",
      confirmText: t("delete"),
      onConfirm: () => {
        dispatch(
          DirectoryActions.remove({
            roomId: directory.roomId,
            directoryId: directory.id,
          }),
        );

        if (directory.parentId === ROOT_DIRECTORY_ID) {
          navigationService.navigate("ConversationFolder", {
            roomId: directory.roomId,
          });
        } else {
          navigationService.navigate("RoomDirectory", {
            roomId: directory.roomId,
            directoryId: directory.parentId,
          });
        }
      },
    });
  }, [
    directory.id,
    directory.parentId,
    directory.roomId,
    dispatch,
    navigationService,
    onPromptDelete,
    t,
  ]);

  const sheetOptions = useMemo<Array<ActionSheetItemProps>>(
    () => [
      {
        label: t("directoryActions.rename"),
        onClick: onOpenRenameDirectoryDialog,
        icon: "edit-02",
      },
      {
        label: t("download"),
        onClick: handleDownloadDirectory,
        icon: "download-01",
        loading,
      },
      {
        label: t("delete"),
        onClick: onDelete,
        icon: "trash-03",
        destructive: true,
      },
    ],
    [
      t,
      onOpenRenameDirectoryDialog,
      handleDownloadDirectory,
      loading,
      onDelete,
    ],
  );

  return {
    options: sheetOptions,
    isDownloading: loading,
  };
}
