import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { RootState } from "@kraaft/shared/core/store";

const selectCompanyState = (state: RootState) => state.company;

export const selectCompanies = createSelector(selectCompanyState, (state) =>
  Object.values(state.companies),
);

export const selectDictCompanies = createSelector(
  selectCompanyState,
  (state) => state.companies,
);

export const selectCompany = memoize((companyId: string | undefined) =>
  createSelector(selectCompanyState, (state) =>
    companyId ? state.companies[companyId] : undefined,
  ),
);
