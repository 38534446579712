import React, { useImperativeHandle, useRef } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";

import {
  MessageTextEditor,
  useEditedMessage,
} from "@kraaft/shared/components/messageTextEditor";
import { ShareFilePreviewBar } from "@kraaft/shared/components/shareFilePreviewBar";
import { MentionPortalRenderer } from "@kraaft/shared/components/textInputWithMentions/mentionPortalRenderer";
import { useMentionAwareTextState } from "@kraaft/shared/components/textInputWithMentions/useMentionAwareTextState";
import { selectFilesInSenderBucket } from "@kraaft/shared/core/modules/fileBucket/fileBucketSelectors";
import { ColorStyle, Icon, PixelSize, Spacing } from "@kraaft/ui";
import {
  TextMessageSender,
  TextMessageSenderHandle,
} from "@kraaft/web/src/components/textMessageSender";
import { Dropzone } from "@kraaft/web/src/views/messenger/conversation/dropzone";

interface Props {
  roomId: string;
}

export interface RoomActionBarHandle {
  focus(): void;
}

const RoomActionBar_ = React.forwardRef<RoomActionBarHandle, Props>(
  (props, ref) => {
    const { roomId } = props;
    const inputRef = useRef<TextMessageSenderHandle>(null);
    const editedMessage = useEditedMessage();

    const { mentionAwareText, availableMentions } =
      useMentionAwareTextState(roomId);

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
    }));

    const shareFileList = useSelector(selectFilesInSenderBucket(roomId));

    const hasFiles = shareFileList?.list?.length > 0;

    return (
      <>
        {hasFiles ? (
          <ShareFilePreviewBar roomId={roomId} />
        ) : editedMessage ? (
          <MessageTextEditor message={editedMessage} roomId={roomId} />
        ) : (
          <View nativeID="ide2e-attach" style={styles.main}>
            <TouchableOpacity accessibilityLabel="">
              <Dropzone clickable noDrag roomId={roomId}>
                <View style={styles.buttonContainer}>
                  <View style={styles.button}>
                    <Icon
                      name="attachment-01"
                      size="MEDIUM"
                      color="ACTION_CTA"
                    />
                  </View>
                </View>
              </Dropzone>
            </TouchableOpacity>
            <View style={styles.mentionPortal}>
              <MentionPortalRenderer />
            </View>
            <View style={styles.textSenderContainer}>
              <TextMessageSender
                mentionAwareText={mentionAwareText}
                availableMentions={availableMentions}
                roomId={roomId}
                ref={inputRef}
              />
            </View>
          </View>
        )}
      </>
    );
  },
);

const styles = StyleSheet.create({
  main: {
    flexDirection: "row",
    alignItems: "flex-end",
    padding: Spacing.S8,
  },
  buttonContainer: {
    height: "100%",
    justifyContent: "center",
  },
  button: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: PixelSize.S36 / 2,
    width: PixelSize.S36,
    height: PixelSize.S36,
    justifyContent: "center",
    alignItems: "center",
  },
  textSenderContainer: {
    marginLeft: Spacing.S8,
    flex: 1,
  },
  mentionPortal: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
});

export const RoomActionBar = React.memo(RoomActionBar_);
