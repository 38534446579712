import { useCallback, useMemo } from "react";
import { View } from "react-native";
import orderBy from "lodash/orderBy";

import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import {
  KSelectableListItem,
  KSelectionListProps,
} from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { SimpleRoomCard } from "@kraaft/shared/components/simpleRoomCard/simpleRoomCard";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";

import { styles } from "./roomSelectionList.styles";

interface Props {
  roomCards: AnyRoomCard[];
  hiddenRoomIds?: string[];
  selected: KSelectionListProps<string, AnyRoomCard>["selected"];
  setSelected: KSelectionListProps<string, AnyRoomCard>["setSelected"];
  isMultiple: KSelectionListProps<string, AnyRoomCard>["isMultiple"];
  minSelection?: KSelectionListProps<string, AnyRoomCard>["minSelection"];
  isLoadingMore?: boolean;
  onEndReached?: () => void;
  hasError?: boolean;
}

const RoomSelectionList = (props: Props) => {
  const {
    roomCards,
    hiddenRoomIds = [],
    selected,
    setSelected,
    isMultiple,
    minSelection,
    isLoadingMore,
    hasError,
    onEndReached,
  } = props;

  const items = useMemo<KSelectableListItem<string, AnyRoomCard>[]>(
    () =>
      orderBy(
        roomCards
          .filter((roomCard) => !hiddenRoomIds.includes(roomCard.roomId))
          .map((roomCard) => ({
            label: roomCard.title,
            identifier: roomCard.roomId,
            value: roomCard,
          })),
        "desc",
      ),
    [hiddenRoomIds, roomCards],
  );

  const renderItem = useCallback(
    (item: KSelectableListItem<string, AnyRoomCard>) => {
      if (!item.value) {
        return <></>;
      }

      return <SimpleRoomCard roomCard={item.value} />;
    },
    [],
  );

  return (
    <View style={styles.listWrapper}>
      <KSelectionList
        items={items}
        renderItem={renderItem}
        selected={selected}
        setSelected={setSelected}
        selectAll
        isMultiple={isMultiple}
        minSelection={minSelection}
        isLoadingMore={isLoadingMore}
        onEndReached={onEndReached}
        hasError={hasError}
      />
    </View>
  );
};

export { RoomSelectionList };
