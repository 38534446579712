import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  nonDeterministicContainer: {
    width: 0, // Videos needs to be rendered multiple times to have their final width, we cannot set a width for the caption because it interferes with the video layout
  },
  container: {
    marginTop: -1, // Visually link the footer to the media above
  },
  textDescription: {
    fontSize: 16,
  },
});
