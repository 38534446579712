import { useCallback, useEffect } from "react";
import { useDragDropManager, useDragLayer } from "react-dnd";
import { useTranslation } from "react-i18next";

import { useMeshContext } from "@kraaft/helper-hooks";
import { DirectoryTreeContext } from "@kraaft/web/src/components/directoryTree/directoryTreeContext";
import { useDragLayerStyles } from "@kraaft/web/src/components/dragAndDrop/dragLayerBase.styles";

import { getTranslationStyle } from "./directoryTreeDragLayer.utils";

import { useStyles } from "./directoryTreeDragLayer.styles";

const DirectoryTreeDragLayer = () => {
  const { hoveredDirectoryTreeInfo, setHoveredDirectoryTreeInfo } =
    useMeshContext(DirectoryTreeContext);
  const directories = useMeshContext(
    DirectoryTreeContext,
    ({ directoryTreeStructure }) => directoryTreeStructure.directories,
  );
  const { clientOffset, isDragging } = useDragLayer((monitor) => ({
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const { t } = useTranslation();
  const classes = useStyles();
  const dragLayerClasses = useDragLayerStyles();

  const targetCount = useDragDropManager().getMonitor().getTargetIds().length;

  useEffect(() => {
    if (targetCount < 2) {
      setHoveredDirectoryTreeInfo(undefined);
    }
  }, [setHoveredDirectoryTreeInfo, targetCount]);

  const getTextContent = useCallback(() => {
    if (hoveredDirectoryTreeInfo) {
      switch (hoveredDirectoryTreeInfo.type) {
        case "on": {
          const parentDirectory =
            directories[hoveredDirectoryTreeInfo.parentDirectoryId];

          return t("directory.moveInDirectory", {
            parentDirectoryName: parentDirectory?.name,
          });
        }
        case "between": {
          const siblingDirectory =
            directories[hoveredDirectoryTreeInfo.siblingDirectoryId];

          if (hoveredDirectoryTreeInfo.parentDirectoryId) {
            const parentDirectory =
              directories[hoveredDirectoryTreeInfo.parentDirectoryId];

            return t(
              hoveredDirectoryTreeInfo.placement === "before"
                ? "directory.moveInDirectoryBeforeSibling"
                : "directory.moveInDirectoryAfterSibling",
              {
                parentDirectoryName: parentDirectory?.name,
                siblingDirectoryName: siblingDirectory?.name,
              },
            );
          }

          return t(
            hoveredDirectoryTreeInfo.placement === "before"
              ? "directory.moveBeforeSibling"
              : "directory.moveAfterSibling",
            { siblingDirectoryName: siblingDirectory?.name },
          );
        }
      }
    }
    return t("directory.moveOverDirectoryNoDirectory");
  }, [directories, hoveredDirectoryTreeInfo, t]);

  if (!isDragging) {
    return null;
  }

  return (
    <div className={dragLayerClasses.layerBase}>
      <div style={getTranslationStyle(clientOffset)}>
        <div className={classes.draggedItem}>{getTextContent()}</div>
      </div>
    </div>
  );
};

export { DirectoryTreeDragLayer };
