import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const makeDialogStyle = makeStyles({
  container: {
    backgroundColor: Color.WHITE,
  },
  title: {
    "& .MuiTypography-h6": {
      fontSize: FontSize.H1,
      lineHeight: "22px",
      color: ColorStyle.FONT_HIGH_EMPHASIS,
      letterSpacing: -0.163333,
    },
    padding: Spacing.NONE,
  },
  explanation: {
    marginTop: Spacing.S16,
  },
  elementsContainer: {
    marginTop: Spacing.S24,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: Spacing.S24,
  },
  content: {
    padding: Spacing.NONE,
    overflow: "visible",
  },
  text: {
    fontSize: FontSize.MEDIUM,
    lineHeight: "17 / 14",
    fontWeight: "normal",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    whiteSpace: "pre-line",
  },
  header: {
    width: "100%",
    marginBottom: Spacing.S16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
