import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { noop } from "ts-essentials";

import { MiniMediaStateActions } from "@kraaft/shared/core/modules/miniMedia/miniMedia.actions";
import {
  useNavigationService,
  useRouteService,
} from "@kraaft/shared/core/services/navigation";
import {
  GeolocationDetailStatus,
  RoomUseMapNavigationReturnType,
  UseMapNavigationReturnType,
} from "@kraaft/shared/core/utils/useMapNavigation/types";
import { parseCoordinates } from "@kraaft/web/src/components/geolocation/mapUtils";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";
import {
  detailsQuery,
  geoQuery,
  queries,
} from "@kraaft/web/src/views/app/appRouter/routes";

export const useMapNavigation = (): UseMapNavigationReturnType => {
  const dispatch = useDispatch();
  const { roomId } = useParams<{
    roomId: string;
  }>();
  const query = useQueryString();
  const nav = useNavigationService();
  const route = useRouteService();

  const detailScreen = query.get(queries.detailScreen);
  const initialCenterString = query.get(geoQuery.initialCenter);
  const initialCenter = useMemo(
    () =>
      initialCenterString ? parseCoordinates(initialCenterString) : undefined,
    [initialCenterString],
  );

  const geoDetail: GeolocationDetailStatus =
    (query.get(queries.geoDetail) as GeolocationDetailStatus | null) ?? "rooms";

  const navigateToRoom = useCallback(
    (id: string) => nav.clickOnRoomMarkerFromRoomsMap(id),
    [nav],
  );

  return useMemo(() => {
    const baseProps = {
      roomId,
      initialCenter,
      setOpen: noop, // deprecated, use navigation service instead
      isOpen: route.isMapOpen(),
      schemaId: query.get(queries.schemaId) ?? undefined,
      onClose: noop, // deprecated, use navigation service instead
      isMapFullscreen: false,
    };

    const roomMapNavigation: RoomUseMapNavigationReturnType = {
      ...baseProps,
      geoDetail: "room",
      display: detailScreen === detailsQuery.photoGallery ? "media" : "folder",
      onMarkerAction: (params) => {
        if (params.type === "folder") {
          const { folderId, schemaId } = params;
          nav.clickOnFolderMarkerFromRoomMap(schemaId, folderId);
        } else if (params.type === "media") {
          dispatch(
            MiniMediaStateActions.openMiniMediaCarousel({
              roomId,
              messageId: params.messageId,
            }),
          );
        }
      },
    };

    // TODO: dont go specific here to switch again in the component
    // declare behavour in the specific map related
    switch (geoDetail) {
      case "room":
        return roomMapNavigation;
      case "modularFolder": {
        const modularFolderId = query.get(queries.folderId);
        if (!modularFolderId) {
          return roomMapNavigation;
        }
        return {
          ...baseProps,
          geoDetail: "modularFolder",
          modularFolderId,
          onMarkerAction: (_attachmentIndex: number) => {},
        };
      }
      default:
        return {
          ...baseProps,
          geoDetail: "rooms",
          isMapFullscreen: baseProps.isOpen,
          onMarkerAction: (id: string) => {
            navigateToRoom(id);
          },
        };
    }
  }, [
    roomId,
    query,
    initialCenter,
    detailScreen,
    geoDetail,
    dispatch,
    navigateToRoom,
    nav,
    route,
  ]);
};
