import { createAction } from "@reduxjs/toolkit";

import { RoomSchemaVisibility } from "./roomSchemaVisibility.state";

export interface RoomSchemaVisibilityUpdatableValues {
  name?: string;
}

export const RoomSchemaVisibilityActions = {
  subscribe: createAction<{ roomId: string }>(
    "@roomSchemaVisibility/subscribe",
  ),
  unsubscribe: createAction<{ roomId: string }>(
    "@roomSchemaVisibility/unsubscribe",
  ),
};

export const RoomSchemaVisibilityStateActions = {
  set: createAction<{ data: RoomSchemaVisibility[] }>(
    "@roomSchemaVisibility/state/set",
  ),
  setWithMerge: createAction<{ data: RoomSchemaVisibility[] }>(
    "@roomSchemaVisibility/state/setWithMerge",
  ),
};
