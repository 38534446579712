import { compactMap } from "@kraaft/helper-functions";
import {
  AttachmentSupportingGeoloc,
  AttachmentWithGPS,
  IndexedAttachmentWithGPS,
} from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { hasGeolocation } from "@kraaft/shared/core/utils";

export const getAttachmentWithGPS = (
  attachments: AttachmentSupportingGeoloc[],
): AttachmentWithGPS[] =>
  attachments.filter((attachment) =>
    hasGeolocation(attachment.geolocation),
  ) as AttachmentWithGPS[];

export const getIndexedAttachmentWithGPS = (
  attachments: AttachmentSupportingGeoloc[],
): IndexedAttachmentWithGPS[] =>
  compactMap(
    attachments,
    (attachment, index): IndexedAttachmentWithGPS | undefined => {
      const { geolocation } = attachment;
      if (hasGeolocation(geolocation)) {
        return {
          ...attachment,
          geolocation,
          originalIndex: index,
        };
      }
      return undefined;
    },
  );
