// We need to call JSON.parse recursively because redux-persist does a double JSON.stringify()
export function unserialize(str: string) {
  return JSON.parse(str, (_key, value) => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  });
}
