import type { Task } from "../../taskStore/task";
import type { BaseAggregate } from "../optimistic.types";
import { Builder, type get } from "./builder";

export class CreateOperationBuilder<A extends BaseAggregate> extends Builder {
  state = this.init<{
    input: { ids: string[] };
  }>()({
    type: "creations" as const,
    count: (input: { ids: string[] }) => input.ids.length,
    replaceId: (input: { ids: string[] }, oldId: string, newId: string) => {
      for (let i = 0; i < input.ids.length; i += 1) {
        if (input.ids[i] === oldId) {
          input.ids[i] = newId;
        }
      }
    },
  });

  payload<P>(description: { count(payload: P): number }) {
    return this.add<{ param: P }>().add({ count: description.count }).cast();
  }

  dependsOn<D>() {
    return this.when<{ param: any }>().add<{ dependencies: D }>().cast();
  }

  augment<Augment>(
    augment: (
      payload: get<this, "input"> & get<this, "param">,
      dependencies: get<this, "dependencies">,
    ) => Augment,
  ) {
    return this.when<{ dependencies: any }>()
      .add({ augment })
      .add<{ augmented: Augment }>()
      .cast();
  }

  expected(
    expected: (
      payload: get<this, "input"> & get<this, "param"> & get<this, "augmented">,
    ) => A[],
  ) {
    return this.when<{ param: any }>().add({ expected }).cast();
  }

  mutate(
    mutate: (payload: get<this, "param">, task: Task) => Promise<string[]>,
  ) {
    return this.when<{ param: any; expected: any }>().add({ mutate }).cast();
  }

  static create<A extends BaseAggregate>() {
    return new this<A>().cast();
  }

  getOperation() {
    return this.when<{
      param: any;
      expected: any;
      mutate: any;
    }>().build();
  }
}
