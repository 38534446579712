import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { useHandleChangeSingleSelectHelper } from "@kraaft/web/src/components/dropdown/kDropdownUtils";
import { ColumnHeaderEditorProps } from "@kraaft/web/src/components/modularTable/components/types";

import { useStyles } from "./attachmentColumnEditor.styles";

type Mode = "date" | "datetime";

export const DateColumnEditor = (
  props: ColumnHeaderEditorProps<
    KColumnType.date | KColumnType.automatedCreatedAt
  >,
) => {
  const { columnDefinition, setColumnDefinition } = props;

  const { t } = useTranslation();
  const styles = useStyles();

  const items = useMemo<FilteredListItem[]>(() => {
    return [
      { value: "date", label: t("dateColumnEditor.date") },
      { value: "datetime", label: t("dateColumnEditor.datetime") },
    ];
  }, [t]);

  const handleChange = useHandleChangeSingleSelectHelper(
    (newValue: Mode | undefined) => {
      if (newValue === undefined || columnDefinition === undefined) {
        return;
      }

      setColumnDefinition({
        ...columnDefinition,
        displayTime: newValue === "datetime",
      });
    },
    [columnDefinition, setColumnDefinition],
  );

  const selectedItemId = columnDefinition?.displayTime ? "datetime" : "date";

  return (
    <div className={styles.container}>
      <KDropdown
        items={items}
        placeholder={t("subOption")}
        selectedItemIds={[selectedItemId]}
        onSelectionChange={handleChange}
      />
    </div>
  );
};
