import { makeStyles } from "@mui/styles";

import { generateDashedBorder } from "@kraaft/shared/core/utils";
import { Color, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  draggableItem: {
    alignSelf: "center",
  },
  draggableItemContentDisable: {
    opacity: 0,
  },
  draggableItemPlaceholder: {
    borderRadius: 8,
    ...generateDashedBorder({
      strokeColor: Color.GREY_FRENCH,
      borderRadius: 8,
      dashArray: [8],
      dashOffset: 12,
      strokeWidth: 3,
    }),
    cursor: "grab",
  },
  itemSpacer: {
    marginBottom: Spacing.S8,
  },
});
