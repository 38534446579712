import { StyleSheet } from "react-native";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    marginHorizontal: Spacing.S32,
    marginBottom: Spacing.S12,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  text: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
    fontSize: FontSize.SMALL,
  },
  spacerRight: {
    marginRight: 3,
  },
  spacerLeft: {
    marginLeft: 3,
  },
});
