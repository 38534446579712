import { ComponentType, lazy, Suspense } from "react";

import { AnyUnexplained } from "@kraaft/shared/core/types";
import { isDev } from "@kraaft/shared/core/utils";

export function makeDevComponent(
  importFn: () => Promise<{ default: ComponentType<AnyUnexplained> }>,
) {
  const Component = isDev ? lazy(importFn) : null;

  return () =>
    Component ? (
      <Suspense>
        <Component />
      </Suspense>
    ) : null;
}
