import { useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectAllPoolUsers } from "@kraaft/shared/core/modules/user/userSelectors";

export function useUserAloneInPool() {
  const poolId = useSelector(selectCurrentPoolId);
  const users = useSelector(selectAllPoolUsers(poolId, true));
  const isAloneInPool = Object.keys(users).length === 1;

  return isAloneInPool;
}
