import { useCallback, useEffect } from "react";
import { FlatList, ListRenderItemInfo, StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { EmptyModularFolderHistory } from "@kraaft/shared/components/modularFolders/modularFolderHistory/emptyModularFolderHistory";
import { getModularFolderHistoryEntryRenderer } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/getModularFolderHistoryEntryRenderer";
import { OfflineModularFolderSelectors } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { ModularFolderHistoryActions } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.actions";
import {
  selectModularFolderHistoryFirstLoading,
  selectOrderedModularFolderHistory,
} from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.selectors";
import { ModularFolderHistoryEntry } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.state";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { ModularDisplayRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { withDependencyWrapper } from "@kraaft/shared/core/utils/withDepedencyWrapper";
import { ColorStyle, Preloader } from "@kraaft/ui";

function keyExtractor(modularFolderHistoryEntry: ModularFolderHistoryEntry) {
  return modularFolderHistoryEntry.id;
}

const Separator = () => <View style={styles.separator} />;

interface ModularFolderHistoryProps {
  modularFolderId: string | undefined;
  portalHostname?: string;

  /** injected */
  modularFolder: ModularFolder;
}

const ModularFolderHistory_ = ({
  modularFolder,
}: ModularFolderHistoryProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    selectModularFolderHistoryFirstLoading(modularFolder.id),
  );
  const historyEntries = useSelector(
    selectOrderedModularFolderHistory(modularFolder.id),
  );

  useEffect(() => {
    dispatch(
      ModularFolderHistoryActions.subscribe({
        schemaId: modularFolder.schemaId,
        modularFolderId: modularFolder.id,
      }),
    );

    return () => {
      dispatch(
        ModularFolderHistoryActions.unsubscribe({
          modularFolderId: modularFolder.id,
        }),
      );
    };
  }, [dispatch, modularFolder.id, modularFolder.schemaId]);

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<ModularFolderHistoryEntry>) =>
      getModularFolderHistoryEntryRenderer(item),
    [],
  );

  return (
    <ModularDisplayRequirementsProvider>
      <View style={styles.screen}>
        {isLoading ? (
          <Preloader absoluteFill transparent />
        ) : historyEntries.length === 0 ? (
          <EmptyModularFolderHistory />
        ) : (
          <FlatList
            data={historyEntries}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            ItemSeparatorComponent={Separator}
          />
        )}
      </View>
    </ModularDisplayRequirementsProvider>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  separator: {
    height: 1,
    backgroundColor: ColorStyle.SEPARATOR,
  },
});

export const ModularFolderHistory = withDependencyWrapper(
  ModularFolderHistory_,
  ({ modularFolderId }) => {
    const modularFolder = useSelector(
      OfflineModularFolderSelectors.select(modularFolderId ?? ""),
    );

    if (modularFolder === undefined) {
      return undefined;
    }

    return { modularFolder };
  },
);
