import { useTranslation } from "react-i18next";

import { WelcomeOnKraaft } from "@kraaft/shared/components/welcomeOnKraaft";
import { useSignoutAlert } from "@kraaft/shared/core/modules/user/useSignoutAlert";
import { Button } from "@kraaft/ui";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";

const WelcomeOnKraaftWeb = () => {
  const { t } = useTranslation();

  const signOut = useSignoutAlert();

  return (
    <SplitScreenWrapper
      headerRight={
        <Button
          id="ide2e-logout"
          onPress={signOut}
          variant="TERTIARY"
          size="SMALL"
          icon="log-out-02"
          accessibilityLabel={t("signOut")}
        />
      }
    >
      <WelcomeOnKraaft />
    </SplitScreenWrapper>
  );
};

export { WelcomeOnKraaftWeb };
