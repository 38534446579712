import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import uniq from "lodash/uniq";

import { User } from "@kraaft/shared/core/modules/user/userState";
import { compareStrings } from "@kraaft/shared/core/utils";

import { getFriendlyUserLabel } from "./getFriendlyUserLabel";
interface Item {
  value: string;
  label: string;
}

export function useUserDropdownItems({
  selectedUserIds,
  selectedUsers,
  poolUsers,
  currentUserId,
  poolId,
}: {
  selectedUserIds: string[];
  selectedUsers: Record<string, User>; // some of the selectedUseIds might not be fetched yet, so selectedUsers can be smaller than selectedUserIds
  poolUsers: Record<string, User>;
  poolId: string;
  currentUserId?: string;
}) {
  const { t } = useTranslation();

  return useMemo(() => {
    const users = { ...selectedUsers, ...poolUsers };

    const uniqUserKeys = uniq([...Object.keys(poolUsers), ...selectedUserIds]);
    return uniqUserKeys
      .map<Item>((userId) => ({
        value: userId,
        label: getFriendlyUserLabel({
          userId,
          currentUserId,
          users,
          poolId,
          t,
        }),
      }))
      .sort((a, b) => compareStrings(a.label, b.label));
  }, [currentUserId, poolId, poolUsers, selectedUserIds, selectedUsers, t]);
}
