import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { useWorkspaceNameInput } from "@kraaft/shared/components/createWorkspace/useWorkspaceNameInput";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { Api } from "@kraaft/shared/core/services/api";

import { useStyles } from "./settingsName.styles";

interface ChangePoolNameDialogContentProps {
  pool: Pool;
  onClose: () => void;
}

export const ChangePoolNameDialogContent = ({
  pool,
  onClose,
}: ChangePoolNameDialogContentProps) => {
  const [name, setName] = useState(pool.name);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const classes = useStyles();

  const changeName = useCallback(async () => {
    try {
      await Api.changePoolName({
        poolId: pool.id,
        name,
      });
      onClose();
    } catch (error) {
      console.log(error);
      dispatch(showError({ title: t("error"), message: error.message }));
    }
  }, [dispatch, name, onClose, pool.id, t]);

  const [WorkspaceNameInput, { isError, isLoading, isTaken }] =
    useWorkspaceNameInput(name, setName, changeName, pool.name);

  const actions: FooterActions = useMemo(
    () => [
      {
        text: t("validate"),
        onPress: changeName,
        disabled:
          !name?.length ||
          name === pool.name ||
          isError ||
          isTaken ||
          isLoading,
        variant: "PRIMARY",
      },
      {
        text: t("cancel"),
        onPress: onClose,
      },
    ],
    [t, changeName, name, pool.name, isError, isTaken, isLoading, onClose],
  );

  const renderContent = useCallback(() => {
    return (
      <div className={classes.modalContentContainer}>
        <span>{t("adminPoolNameUpdateModal.subtitle")}</span>
        {WorkspaceNameInput}
      </div>
    );
  }, [classes.modalContentContainer, t, WorkspaceNameInput]);

  return (
    <KDialogContent
      title={t("adminPoolNameUpdateModal.title")}
      content={renderContent()}
      actions={actions}
    />
  );
};
