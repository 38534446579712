import { createReducer } from "@reduxjs/toolkit";

import { AlertState } from "@kraaft/shared/core/modules/alert/alertState";

import * as actions from "./alertActions";

const initialState: AlertState = {};

export const alertReducers = createReducer(initialState, ({ addCase }) => {
  addCase(actions.showInfo, (_, { payload }) => ({
    ...payload,
    type: "info",
  }));

  addCase(actions.showWarn, (_, { payload }) => ({
    ...payload,
    type: "warn",
  }));

  addCase(actions.showError, (_state, { payload }) => ({
    ...payload,
    type: "error",
  }));

  addCase(actions.showSuccess, (_, { payload }) => ({
    ...payload,
    type: "success",
  }));

  addCase(actions.hideAlert, () => initialState);
});
