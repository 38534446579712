import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { KDialogConfig } from "@kraaft/shared/components/kDialog/kDialogProps";
import { CustomReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { Text } from "@kraaft/ui";

import { useStyles } from "./renameReportTemplateDialog.styles";

type RenameReportTemplateDialogProps = {
  reportTemplate: CustomReportTemplate;
  open: boolean;
  onClose: () => void;
  onRename?: (reportTemplateId: string, newName: string) => void;
};

const RenameReportTemplateDialog = ({
  open,
  onClose,
  reportTemplate,
  onRename,
}: RenameReportTemplateDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [name, setName] = useState(reportTemplate.name);

  const handleRenameTemplate = useCallback(() => {
    onRename?.(reportTemplate.id, name);

    onClose();
  }, [onRename, name, onClose, reportTemplate.id]);

  const content = useMemo(
    () => (
      <div className={classes.dialogContentContainer}>
        <Text size="MEDIUM" color="FONT_HIGH_EMPHASIS">
          {t("reportTemplate.nameLabel")}
        </Text>
        <div className={classes.inputContainer}>
          <KInput
            nativeID="ide2e-template-name"
            label={t("reportTemplate.nameLabel")}
            value={name}
            onChangeText={setName}
            disableAutocomplete
            autoComplete="off"
            autoFocus
          />
        </div>
      </div>
    ),
    [classes.dialogContentContainer, classes.inputContainer, name, t],
  );

  if (reportTemplate?.variant !== "custom") {
    return null;
  }

  const dialog: KDialogConfig = {
    title: t("reportTemplate.renameDialogTitle", {
      name: reportTemplate.name,
    }),
    content,
    actions: [
      {
        id: "ide2e-template-rename",
        accessibilityLabel: t("reportTemplate.rename"),
        text: t("reportTemplate.rename"),
        onPress: handleRenameTemplate,
      },
      {
        accessibilityLabel: t("cancel"),
        text: t("cancel"),
        onPress: onClose,
      },
    ],
  };

  return <KDialog open={open} onClose={onClose} {...dialog} />;
};

export { RenameReportTemplateDialog };
