import { EventChannel, eventChannel } from "redux-saga";
import { put, takeEvery } from "typed-redux-saga/macro";

import {
  CompanyActions,
  CompanyStateActions,
} from "@kraaft/shared/core/modules/company/company.actions";
import { Company } from "@kraaft/shared/core/modules/company/company.state";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToCompanySaga() {
  yield takeCountedDeep(
    CompanyActions.subscribeTo,
    CompanyActions.unsubscribeFrom,
    subscribe,
    unsubscribe,
    ({ payload: { companyId } }) => companyId,
  );
}

function* receiveCompanies(company: Company) {
  yield* put(CompanyStateActions.set({ [company.id]: company }));
}

function* subscribe(
  register: (channel: EventChannel<Company>) => void,
  { payload: { companyId } }: ReturnType<typeof CompanyActions.subscribeTo>,
) {
  const channel = eventChannel<Company>((emit) =>
    Firestore.subscribeToCompany(companyId, emit),
  );
  register(channel);

  yield* takeEvery(channel, receiveCompanies);
}

function* unsubscribe(channel: EventChannel<Company> | undefined) {
  channel?.close();
}
