import React, { useRef } from "react";
import { View } from "react-native";

import { Step } from "@kraaft/shared/components/stepper/step";

import { styles } from "./stepper.styles";

export interface StepperProps {
  steps: number;
  selected: number;
}

const Stepper = React.memo(({ steps, selected }: StepperProps) => {
  const firstRender = useRef(0);
  firstRender.current += 1;

  const isFirstRender = firstRender.current === 1;

  return (
    <View style={styles.stepper}>
      {[...Array(steps).keys()].map((idx) => {
        const isActive = idx <= selected;
        const isNotLast = idx < steps - 1;

        return (
          <Step
            key={`${idx}-${isActive}`}
            active={isActive}
            withSpacer={isNotLast}
            animated={idx === selected && !isFirstRender}
          />
        );
      })}
    </View>
  );
});

export { Stepper };
