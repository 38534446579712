import { useCallback, useEffect, useState } from "react";

import { KInput, KInputProps } from "@kraaft/shared/components/input/KInput";

const KInputWithUpdateOnBlur = ({
  value,
  onChangeText,
  ...other
}: KInputProps) => {
  const [tempValue, setTempValue] = useState(value ?? "");

  useEffect(() => {
    setTempValue(value ?? "");
  }, [value]);

  const done = useCallback(() => {
    if (tempValue !== value) {
      onChangeText(tempValue);
    }
  }, [onChangeText, tempValue, value]);

  return (
    <KInput
      onChangeText={setTempValue}
      value={tempValue}
      onBlur={done}
      {...other}
    />
  );
};

export { KInputWithUpdateOnBlur };
