import React from "react";

import { Props } from "@kraaft/shared/core/types";

function withDependencyWrapper<P extends Props, D extends Partial<P>>(
  Component: React.ComponentType<P>,
  usePrepareDependencies: (props: P) => D | undefined,
  options?: {
    Fallback?: React.ComponentType;
  },
) {
  return (props: Omit<P, keyof D>) => {
    const dependencies = usePrepareDependencies(props as P);

    if (!dependencies) {
      return options?.Fallback ? React.createElement(options.Fallback) : null;
    }

    const finalProps = { ...props, ...dependencies };

    return React.createElement(Component, finalProps as unknown as P);
  };
}

export { withDependencyWrapper };
