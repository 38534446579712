import React, { useRef } from "react";

import { useBooleanState } from "@kraaft/helper-hooks";
import { UserContactInfoSheet } from "@kraaft/shared/components/userContactInfoSheet/userContactInfoSheet";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { Text, TextProps } from "@kraaft/ui";

import { styles } from "../common.styles";

interface LogTextProps {
  userColor: string;
  userId: string;
  i18nKey: Parameters<typeof Trans>[0]["i18nKey"];
  values?: Record<string, unknown>;
  color: string;
}

export const LogText = ({
  userColor,
  userId,
  i18nKey,
  values,
  color,
}: LogTextProps) => {
  return (
    <Text color={color} style={styles.text}>
      <Trans
        i18nKey={i18nKey}
        values={values}
        components={{
          bold: (
            <Text
              style={styles.forceLineHeight}
              size="MEDIUM"
              color={color}
              weight="bold"
            />
          ),
          user: (
            <ClickableUserNameText
              style={styles.forceLineHeight}
              size="MEDIUM"
              color={userColor}
              weight="medium"
              userId={userId}
            />
          ),
        }}
      />
    </Text>
  );
};

type ClickableUserNameTextProps = TextProps & {
  userId: string;
};

const ClickableUserNameText = ({
  color,
  userId,
  style,
  size,
  weight,
  children,
}: React.PropsWithChildren<ClickableUserNameTextProps>) => {
  const itemRef = useRef(null);
  const [isUserContactInfoOpen, openUserContactInfo, closeUserContactInfo] =
    useBooleanState(false);
  return (
    <>
      <Text
        ref={itemRef}
        style={style}
        size={size}
        color={color}
        weight={weight}
        onPress={openUserContactInfo}
      >
        {children}
      </Text>
      <UserContactInfoSheet
        anchor={itemRef}
        open={isUserContactInfoOpen}
        onClose={closeUserContactInfo}
        userId={userId}
      />
    </>
  );
};
