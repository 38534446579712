import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectLibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.selectors";
import { LibrarySchemaBuilder } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder";
import { LibrarySchemaBuilderTabs } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder/librarySchemaBuilderTabs";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";
import { queries } from "@kraaft/web/src/views/app/appRouter/routes";

const LibrarySchemaBuilderView = () => {
  const { librarySchemaId } = useParams<{ librarySchemaId: string }>();
  const librarySchema = useSelector(selectLibrarySchema(librarySchemaId));

  const route =
    (useQueryString().get(queries.tab) as
      | LibrarySchemaBuilderTabs
      | undefined) ?? LibrarySchemaBuilderTabs.schema;

  if (!librarySchema) {
    return null;
  }

  return <LibrarySchemaBuilder librarySchema={librarySchema} tab={route} />;
};

export { LibrarySchemaBuilderView };
