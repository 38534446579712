import { ColorStyle, FontSize, PixelSize, Radius, Spacing } from "@kraaft/ui";

const styleObject = {
  inputContainer: {
    borderRadius: Radius.SMALL,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  input: {
    paddingHorizontal: Spacing.S8,
    paddingVertical: Spacing.NONE,
    flexGrow: 1,
    fontSize: FontSize.BODY,
    fontFamily: "Apercu",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    height: PixelSize.S32,
    flexShrink: 1,
  },
};

export { styleObject };
