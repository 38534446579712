import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { Button, ColorStyle, Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { SchemaDefaultVisibilityButton } from "@kraaft/web/src/components/schemaDefaultVisibilityButton";

import { useStyles } from "../poolSchemaBuilderHeaderName.styles";

interface PoolSchemaBuilderHeaderFolderNameProps {
  schemaName: string;
  schemaId: string;
}

const PoolSchemaBuilderHeaderFolderName = ({
  schemaName,
  schemaId,
}: PoolSchemaBuilderHeaderFolderNameProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const navigationService = useNavigationService();

  const navigate = useCallback(() => {
    navigationService.navigate("SchemasView");
  }, [navigationService]);

  return (
    <div className={styles.root}>
      <Button
        accessibilityLabel={t("goBackToList")}
        icon="chevron-left"
        contentColor={ColorStyle.FONT_HIGH_EMPHASIS}
        variant="QUATERNARY"
        onPress={navigate}
      />
      <Text size="H1">{t("formBuilder.schemaNameHeader", { schemaName })}</Text>
      <Box ml="S16">
        <SchemaDefaultVisibilityButton
          schemaId={schemaId}
          source="schema_builder"
        />
      </Box>
    </div>
  );
};

export { PoolSchemaBuilderHeaderFolderName };
