import { StyleSheet } from "react-native";

import { ensureValidStyleSheet } from "@kraaft/shared/core/utils";
import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const nativeStylesObject = {
  titleBlockContainer: {
    paddingBottom: Spacing.S16,
  },
  title: {
    fontFamily: "Apercu-Bold",
    fontSize: FontSize.H1,
    textAlign: "center" as const,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    paddingBottom: Spacing.S16,
  },
  subtitle: {
    fontSize: FontSize.MEDIUM,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    margin: 0,
    padding: Spacing.NONE,
    paddingBottom: Spacing.S16,
  },
  content: {
    padding: Spacing.NONE,
    flex: 1,
  },
  actions: {
    flexBasis: "auto",
    padding: Spacing.NONE,
    gap: Spacing.S16,
  },
  actionsPadding: {
    paddingTop: Spacing.S32,
  },
};

export const styles = StyleSheet.create(
  ensureValidStyleSheet(nativeStylesObject),
);
