import { useTranslation } from "react-i18next";

import { useRoomMembers } from "@kraaft/shared/components/conversationDetails/membersSection/useRoomMembers";
import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { HideWhenKeyboard } from "@kraaft/shared/components/hideWhenKeyboard";
import { MembersWithSearch } from "@kraaft/shared/components/members/membersWithSearch";
import { SafeAreaView } from "@kraaft/shared/components/safeAreaView";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";

import { styles } from "./membersPanelContent.styles";

export type MembersPanelContentProps = {
  roomId: string;
  isEditEnabled?: boolean;
  onClose: () => void;
  onEdit: () => void;
};

const MembersPanelContent = ({
  roomId,
  isEditEnabled,
  onClose,
  onEdit,
}: MembersPanelContentProps) => {
  const { t } = useTranslation();

  useTrackPage("RoomMembers", { identifier: roomId });

  const members = useRoomMembers({ roomId });

  const headerTitle = t("newTicketMembersTitle", {
    count: members.length,
  });

  return (
    <SafeAreaView edges={["top", "bottom"]} style={styles.container}>
      <HideWhenKeyboard>
        <DefaultLayoutHeader
          headerTitle={headerTitle}
          headerLeft={
            <HeaderSide>
              <HeaderIconButton
                accessibilityLabel={t("close")}
                icon="chevron-left"
                onPress={onClose}
                id="ide2e-members-back"
              />
            </HeaderSide>
          }
          headerRight={
            isEditEnabled ? (
              <HeaderSide>
                <HeaderIconButton
                  accessibilityLabel={t("edit")}
                  icon="edit-05"
                  onPress={onEdit}
                  id="ide2e-members-edit"
                />
              </HeaderSide>
            ) : null
          }
          disableSafeAreaBehavior
        />
      </HideWhenKeyboard>
      <MembersWithSearch items={members} />
    </SafeAreaView>
  );
};

export { MembersPanelContent };
