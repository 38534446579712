import { useCallback, useRef } from "react";
import { noop } from "ts-essentials";

type ExecutorParams<T> = Parameters<
  ConstructorParameters<typeof Promise<T>>[0]
>;

type Callbacks<T> = {
  resolve: ExecutorParams<T>[0];
  reject: ExecutorParams<T>[1];
};

/**
 * This hook is used to create a promise that can be resolved or rejected
 * outside of the promise constructor.
 * This is useful when you want to create a promise and resolve it later.
 * @returns A promise and its resolve and reject callbacks.
 * @example
 * const {callbacks, newPromise} = usePromiseAndCallbacks<boolean>();
 * const promise = newPromise();
 * // Later in the code
 * callbacks.current.resolve(true);
 * // or
 * callbacks.current.reject(new Error('Something went wrong'));
 */
export function usePromiseAndCallbacks<T>() {
  const callbacks = useRef<Callbacks<T>>({ resolve: noop, reject: noop });

  const newPromise = useCallback(
    () =>
      new Promise<T>((resolve, reject) => {
        callbacks.current = { resolve, reject };
      }),
    [],
  );

  return { callbacks, newPromise };
}
