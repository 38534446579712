import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import { ReactNodeRef } from "@kraaft/shared/core/types";
import { Button } from "@kraaft/ui";

interface ContextMenuButtonProps {
  onPress: (anchor: ReactNodeRef) => void;
  horizontal?: boolean;
}

// Performant version that does not include the popover
export const ContextMenuButton = ({
  onPress,
  horizontal,
}: ContextMenuButtonProps) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  const handlePress = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onPress(ref);
    },
    [onPress],
  );

  const icon = horizontal ? "dots-horizontal" : "dots-vertical";

  return (
    <Button
      ref={ref}
      accessibilityLabel={t("menu")}
      icon={icon}
      size="SMALL"
      variant="TERTIARY"
      onPress={handlePress}
    />
  );
};
