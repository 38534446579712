import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  dialogContentContainer: {
    flex: 1,
  },
  withInteractionsDisabled: {
    pointerEvents: "none",
  },

  inputContainer: {
    marginTop: Spacing.S16,
  },

  forceAddContainer: {
    marginTop: Spacing.S16,
    display: "flex",
    alignItems: "center",
    gap: Spacing.S8,
  },
});

export const styles = {
  centeredText: {
    textAlign: "center",
  },
  buttonContainer: {
    marginTop: Spacing.S8,
  },
} as const;
