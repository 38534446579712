import { useSelector } from "react-redux";

import { isAtLeastPoolStandard } from "@kraaft/shared/core/modules/pool/poolUtil";
import {
  selectCurrentUser,
  selectCurrentUserIsSuperadmin,
} from "@kraaft/shared/core/modules/user/userSelectors";

export function useExternalPoolVariant(poolId: string | undefined) {
  const userPools = useSelector(selectCurrentUser)?.pools || {};
  const isSuperadmin = useSelector(selectCurrentUserIsSuperadmin);

  if (!poolId) {
    return false;
  }

  const roleInPool = userPools[poolId]?.role;
  const withExternalVariant = roleInPool
    ? !isAtLeastPoolStandard(roleInPool)
    : isSuperadmin
      ? false
      : true;
  return withExternalVariant;
}
