import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import identity from "lodash/identity";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { selectSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { useGetOrLoadUsers } from "@kraaft/shared/core/modules/user/useGetOrLoadUsers";
import { selectAllPoolUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import {
  ActionAttachment,
  ActionEmail as ActionEmailType,
  ActionUserRef,
  TemporaryWorkflow,
} from "@kraaft/shared/core/modules/workflows/types";
import { ColorStyle, FontSize, Spacing, Text } from "@kraaft/ui";
import { ActionInput } from "@kraaft/web/src/views/settings/workflows/actions/ActionInput";
import { EmailListInput } from "@kraaft/web/src/views/settings/workflows/actions/EmailListInput";
import { FilteredDropdownActionUserRef } from "@kraaft/web/src/views/settings/workflows/actions/FilteredDropdownActionUserRef";
import { FilteredDropdownColumnRef } from "@kraaft/web/src/views/settings/workflows/actions/filteredDropdownColumnRef";
import { WorkflowSubSection } from "@kraaft/web/src/views/settings/workflows/components/workflowSubSection";
import { useWorkflowMentionPrefix } from "@kraaft/web/src/views/settings/workflows/workflowUtils";

import { InputTip } from "./inputTip";

interface ActionEmailProps {
  payload: ActionEmailType["payload"];
  onChange: (payload: ActionEmailType["payload"]) => void;
  onErrorStateChange?: (newState: boolean) => void;
  workflow: TemporaryWorkflow;
}

export const ActionEmail = ({
  payload,
  onChange,
  onErrorStateChange,
  workflow,
}: ActionEmailProps) => {
  const { t } = useTranslation();
  const mentionPrefix = useWorkflowMentionPrefix(workflow.schemaId);

  const poolId = useSelector(selectCurrentPoolId);
  const users = useSelector(selectAllPoolUsers(poolId, true));
  const getOrLoadUsers = useGetOrLoadUsers();
  const schema = useSelector(selectSchema(workflow.schemaId));
  const errorStateByField = useRef({
    addExternalEmails: false,
    subject: false,
    message: false,
  });

  const handleOnErrorStateChange = useCallback(
    (fieldKey: keyof (typeof errorStateByField)["current"]) => {
      return (errorState: boolean) => {
        errorStateByField.current = {
          ...errorStateByField.current,
          [fieldKey]: errorState,
        };
        onErrorStateChange?.(
          Object.values(errorStateByField.current).some(identity),
        );
      };
    },
    [onErrorStateChange],
  );

  const handleDestChange = useCallback(
    (actionUserRefs: ActionUserRef[]) => {
      const newPayload: typeof payload = {
        ...payload,
        users: actionUserRefs,
      };
      onChange(newPayload);
    },
    [onChange, payload],
  );

  const handleSubjectChange = useCallback(
    (newText: string) => {
      const newPayload = { ...payload };
      newPayload.subject = newText;
      onChange(newPayload);
    },
    [onChange, payload],
  );

  const handleContentChange = useCallback(
    (newText: string) => {
      const newPayload = { ...payload };
      newPayload.content = newText;
      onChange(newPayload);
    },
    [onChange, payload],
  );

  const handleAdditionalEmailsChange = useCallback(
    (emails: Array<string>) => {
      const newPayload = { ...payload };
      newPayload.additionalEmails = emails;
      onChange(newPayload);
    },
    [onChange, payload],
  );

  const handleAttachmentChange = useCallback(
    (newRefs: ActionAttachment) => {
      const newPayload = { ...payload };
      newPayload.attachments = newRefs;
      onChange(newPayload);
    },
    [onChange, payload],
  );

  if (!schema || !poolId) {
    return null;
  }

  return (
    <View style={style.actionContainer}>
      <WorkflowSubSection title={t("recipient")}>
        <FilteredDropdownActionUserRef
          mentionPrefix={mentionPrefix}
          schema={schema}
          poolUsers={users}
          getUsers={getOrLoadUsers}
          poolId={poolId}
          items={payload.users}
          onChange={handleDestChange}
        />
        <View style={style.row}>
          <Text color="FONT_LOW_EMPHASIS" style={style.additionalEmailText}>
            {t("andFilter")}
          </Text>
          <EmailListInput
            onChange={handleAdditionalEmailsChange}
            defaultValue={payload.additionalEmails}
            placeholder={t("addExternalEmails")}
            onErrorStateChange={handleOnErrorStateChange("addExternalEmails")}
          />
        </View>
        <InputTip>{t("addExternalEmailsTip")}</InputTip>
      </WorkflowSubSection>
      <WorkflowSubSection title={t("subject")}>
        <ActionInput
          placeholder={t("workflow.actions.email.subjectPlaceholder")}
          singleLine
          workflow={workflow}
          onChange={handleSubjectChange}
          onErrorStateChange={handleOnErrorStateChange("subject")}
          defaultValue={payload.subject}
        />
        <InputTip>{t("suggestionTip")}</InputTip>
      </WorkflowSubSection>
      <WorkflowSubSection title={t("attachments")}>
        <FilteredDropdownColumnRef
          placeholder={t("chooseAttachment")}
          mentionPrefix={mentionPrefix}
          columnType={KColumnType.attachment}
          schema={schema}
          value={payload.attachments}
          onChange={handleAttachmentChange}
        />
        <InputTip>{t("attachmentTip")}</InputTip>
      </WorkflowSubSection>
      <WorkflowSubSection title={t("message")}>
        <ActionInput
          placeholder={t("workflow.actions.email.messagePlaceholder")}
          workflow={workflow}
          onChange={handleContentChange}
          onErrorStateChange={handleOnErrorStateChange("message")}
          defaultValue={payload.content}
        />
        <InputTip>{t("suggestionTip")}</InputTip>
      </WorkflowSubSection>
    </View>
  );
};

const INPUT_ROW_HEIGHT = 35;
const style = StyleSheet.create({
  actionContainer: {
    rowGap: Spacing.S16,
  },
  row: {
    flexDirection: "row",
    columnGap: Spacing.S8,
  },
  tip: {
    fontSize: FontSize.MEDIUM,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  additionalEmailText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: INPUT_ROW_HEIGHT,
    marginRight: Spacing.S8,
  },
});
