import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";
import { signatureRecordComparator } from "@kraaft/web/src/components/modularTable/components/table/recordComparatorUtils";
import { IFieldConfig } from "@kraaft/web/src/components/modularTable/components/types";

import { withBasicCell } from "../_withTableCell/withBasicCell";
import { SignatureCell } from "./basicCell";

export const signatureConfig: IFieldConfig<KColumnType.signature> = {
  type: KColumnType.signature,
  TableCell: withBasicCell(
    SignatureCell as React.FC<IBasicCellProps<KColumnType.signature>>,
  ),
  TableEditor: null,
  comparator: signatureRecordComparator,
};
