import { EditModularFoldersPopupComposition } from "@kraaft/web/src/components/editModularFoldersPopup/editModularFoldersPopup.composition";
import { EditModularFoldersPopupSheet } from "@kraaft/web/src/components/editModularFoldersPopup/editModularFoldersPopup.sheet";

interface Props {
  open: boolean;
  onClose: () => void;
  schemaId: string;
}

export const EditModularFoldersPopup = ({ open, onClose, schemaId }: Props) => {
  return (
    <EditModularFoldersPopupSheet.Host
      open={open}
      onClose={onClose}
      size="MEDIUM"
    >
      <EditModularFoldersPopupComposition
        onClose={onClose}
        schemaId={schemaId}
      />
    </EditModularFoldersPopupSheet.Host>
  );
};
