import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { deleteSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { DirtyableSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewState";
import { Button, Sheet, Text } from "@kraaft/ui";

interface DeleteSchemaViewDialogProps {
  schemaView: DirtyableSchemaView;
  onClose: () => void;
}

export const DeleteSchemaViewPopup = Sheet({ web: "popup" })
  .create(
    ({ Paper, Header, Content, Footer }) =>
      ({ onClose, schemaView }: DeleteSchemaViewDialogProps) => {
        const dispatch = useDispatch();
        const { t } = useTranslation();

        const onDelete = useCallback(() => {
          if (!schemaView.id) {
            return;
          }

          dispatch(
            deleteSchemaView({
              id: schemaView.id,
            }),
          );
          onClose();
        }, [dispatch, onClose, schemaView.id]);

        return (
          <Paper>
            <Header onClose={onClose}>{t("deleteSchemaView")}</Header>
            <Content>
              <Text>{t("schemaViewWillBeDeleted")}</Text>
            </Content>
            <Footer>
              <Button
                variant="QUATERNARY"
                text={t("cancel")}
                accessibilityLabel={t("cancel")}
                onPress={onClose}
              />
              <Button
                variant="PRIMARY"
                destructive
                text={t("delete")}
                accessibilityLabel={t("delete")}
                onPress={onDelete}
              />
            </Footer>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "SMALL" });
