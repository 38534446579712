import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, PixelSize, Spacing } from "@kraaft/ui";

export const ITEM_WIDTH = PixelSize.S40;
export const ITEM_HEIGHT = PixelSize.S32;
export const ITEM_MARGIN = Spacing.S8;

export const useBasicCellStyle = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    "&:hover $addButton": {
      opacity: 1,
    },
  },
  rootDrop: {
    boxShadow: `inset 0 0 0 2px ${ColorStyle.ACTION_CTA}`,
    borderRadius: 5,
    width: "100%",
  },
  rootDropReject: {
    boxShadow: `inset 0 0 0 2px ${Color.RED_SAVOY}`,
  },
  previewsContainer: {
    height: ITEM_HEIGHT,
  },
  previews: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    overflow: "hidden",
    gap: `${ITEM_MARGIN}px`,
    marginRight: ITEM_MARGIN,
  },
  preview: {
    display: "flex",
  },
  morePreviews: {
    height: ITEM_HEIGHT,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,

    fontWeight: "bold",

    marginRight: ITEM_MARGIN,
  },
  addButton: {
    height: ITEM_HEIGHT,
    width: ITEM_WIDTH,

    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    alignItems: "center",

    border: `1px solid ${Color.GREY_ANTIFLASH}`,
    borderRadius: 5,
    backgroundColor: Color.WHITE,

    cursor: "pointer",

    transition: "opacity 0.2s",
    opacity: 0,

    "&:hover": {
      backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    },
  },
  loadingPreview: {
    height: ITEM_HEIGHT,
    width: ITEM_WIDTH,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,

    border: `1px solid ${Color.GREY_ANTIFLASH}`,
    borderRadius: 5,
    backgroundColor: Color.WHITE,

    marginRight: ITEM_MARGIN,
  },
});
