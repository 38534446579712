import { useState } from "react";
import { useSelector } from "react-redux";

import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { HeaderFilterButton } from "@kraaft/shared/components/headerFilterButton";
import { HeaderHomeContainer } from "@kraaft/shared/components/headerHomeContainer";
import { selectCurrentPoolLocation } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { useSubscribedToUserPool } from "@kraaft/shared/core/modules/userPool/useSubscribedToUserPool";
import { CreateRoomFlow } from "@kraaft/web/src/components/createRoomFlow";
import { Sidebar } from "@kraaft/web/src/components/sidebar";
import { DEFAULT_DRAWER_WIDTH } from "@kraaft/web/src/components/sidebar/sidebar.styles";
import { Rooms } from "@kraaft/web/src/views/messenger/rooms";

interface Props {
  openConversationCreationPanel: () => void;
  closeConversationCreationPanel: () => void;
  isConversationCreationPanelOpen: boolean;
}

const RoomsSidebar = ({
  openConversationCreationPanel,
  closeConversationCreationPanel,
  isConversationCreationPanelOpen,
}: Props) => {
  const [sidebarWidth, setSidebarWidth] = useState(DEFAULT_DRAWER_WIDTH);

  const poolLocation = useSelector(selectCurrentPoolLocation);
  const poolId = poolLocation?.poolId;
  useSubscribedToUserPool(poolId);

  const headerRight = (
    <HeaderSide>
      <HeaderFilterButton id="rooms-filter" />
    </HeaderSide>
  );

  return (
    <Sidebar onWidthUpdate={setSidebarWidth}>
      <HeaderHomeContainer headerRight={headerRight} />

      <Rooms onAddRoomButtonPress={openConversationCreationPanel} />
      <CreateRoomFlow
        isOpen={isConversationCreationPanelOpen}
        onClose={closeConversationCreationPanel}
        panelWidth={sidebarWidth}
      />
    </Sidebar>
  );
};

export { RoomsSidebar };
