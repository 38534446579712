import { forwardRef, ReactNode } from "react";

import { SuggestionsSheet } from "@kraaft/web/src/views/settings/backOffice/poolSuggestions.popup";

interface PoolSuggestionsPopupContentProps {
  children: ReactNode;
}

export const PoolSuggestionsPopupContent = forwardRef<
  HTMLDivElement,
  PoolSuggestionsPopupContentProps
>(({ children }, ref) => {
  return (
    <SuggestionsSheet.Paper noPadding>
      <div ref={ref}>{children}</div>
    </SuggestionsSheet.Paper>
  );
});
