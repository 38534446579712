import { StyleSheet } from "react-native";
import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export const nativeStyles = StyleSheet.create({
  menu: {
    padding: Spacing.S16,
  },
});

export const useSettingsStyles = makeStyles({
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    padding: `${Spacing.S24}px ${Spacing.S32}px`,
    boxSizing: "border-box",
    overflow: "auto",
    flexGrow: 1,
  },
  pageContainerNoPaddingBottom: {
    display: "flex",
    flexDirection: "column",
    paddingTop: Spacing.S24,
    paddingLeft: Spacing.S24,
    paddingRight: Spacing.S24,
    boxSizing: "border-box",
    overflow: "auto",
    flexGrow: 1,
  },
  page: {
    position: "relative",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    display: "flex",
    flexDirection: "row",
    height: "100%",
    overflow: "hidden",
  },
  navBar: {
    height: "100%",
  },
  settings: {
    display: "flex",
    flexGrow: 1,
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: Color.WHITE,
  },
});

export const makeResponsiveStyle = makeStyles((theme) => {
  const {
    mixins: { toolbar },
  } = theme;

  return {
    toolbarBuffer: {
      ...toolbar,
    },
    responsiveContent: {
      height: `calc(100% - ${toolbar.minHeight}px)`,
    },
  };
});
