import { KeyValueStorage } from "@kraaft/shared/core/modules/storage/storage";

export class LocalStorageStorage implements KeyValueStorage {
  async getItem(key: string) {
    const value = localStorage.getItem(key);
    if (!value) {
      return undefined;
    }
    return JSON.parse(value);
  }

  async setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  async removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
