import { View } from "react-native";

import { compactMap } from "@kraaft/helper-functions";
import { buildUserAcronym } from "@kraaft/shared/components/conversation/header/conversationTitleUtils";
import { WithIconContent } from "@kraaft/shared/components/modular/pill/utils";
import { PillRenderer } from "@kraaft/shared/components/modular/pill/utils/types";
import { Pill } from "@kraaft/shared/components/pill";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";

import { styles } from "../modularPill.styles";

export const UserCompactedPillRenderer: PillRenderer<KColumnType.user> = ({
  schemaColumn,
  recordProperty,
  extraContext: { poolMembers },
  displayOptions: { displayUserAcronym } = { displayUserAcronym: false },
}) => {
  const selectedIds = recordProperty?.value;

  if (!selectedIds || selectedIds.length === 0) {
    return null;
  }

  const firstLabelId = selectedIds[0];

  if (firstLabelId === undefined) {
    return null;
  }
  const firstUser = poolMembers[firstLabelId];
  const moreLabelCount = selectedIds.length - 1;

  if (firstUser === undefined) {
    return null;
  }

  let content = displayUserAcronym
    ? buildUserAcronym(firstUser)
    : firstUser.username;
  if (moreLabelCount > 0) {
    content += ` +${moreLabelCount}`;
  }

  return (
    <View style={styles.selectCompactedPillRendererContainer}>
      <Pill
        icon={KSchemaUtils.getIcon(schemaColumn)}
        content={content}
        size="small"
      />
    </View>
  );
};

export const UserPillRenderer: PillRenderer<KColumnType.user> = ({
  schemaColumn,
  recordProperty,
  extraContext: { poolMembers },
}) => {
  const selectedIds = recordProperty?.value;

  if (
    !selectedIds ||
    selectedIds.length === 0 ||
    selectedIds.every((labelId) => !Object.keys(poolMembers).includes(labelId))
  ) {
    return null;
  }

  return (
    <WithIconContent
      iconName={KSchemaUtils.getIcon(schemaColumn)}
      tooltipTitle={schemaColumn.name}
    >
      <View style={styles.selectPillRendererContainer}>
        {compactMap(selectedIds, (labelId, index) => {
          const user = poolMembers[labelId];
          const isNotLast = index < selectedIds.length - 1;

          if (user === undefined) {
            return undefined;
          }

          return (
            <View key={labelId} style={styles.selectPillRendererItem}>
              <Pill
                content={user.username}
                size="small"
                withSpacer={isNotLast}
              />
            </View>
          );
        })}
      </View>
    </WithIconContent>
  );
};
