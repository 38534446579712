import { useMemo } from "react";

import { AttachmentsEditorProps } from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/attachmentsEditor.props";
import { GalleryAttachmentsEditor } from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/galleryAttachmentsEditor";
import { ListAttachmentsEditor } from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/listAttachmentsEditor";
import { DetailsEditorBox } from "@kraaft/shared/components/modular/details/editors/detailsEditorBox";

const AttachmentsEditor = (props: AttachmentsEditorProps) => {
  const { column, value, testID, context, disabled, isLocked, recordType } =
    props;

  const content = useMemo(() => {
    if (column.mode === "gallery") {
      return (
        <GalleryAttachmentsEditor
          id={`${testID}-${column.key}`}
          value={value}
          disabled={disabled}
          column={column}
          context={context}
          isLocked={isLocked}
          recordType={recordType}
        />
      );
    }
    return (
      <ListAttachmentsEditor
        id={`${testID}-${column.key}`}
        value={value}
        disabled={disabled}
        column={column}
        context={context}
        isLocked={isLocked}
        recordType={recordType}
      />
    );
  }, [column, testID, value, disabled, context, isLocked, recordType]);

  return (
    <DetailsEditorBox
      isLocked={isLocked}
      label={column.name}
      columnKey={column.key}
    >
      {content}
    </DetailsEditorBox>
  );
};

export { AttachmentsEditor };
