import {
  actions as toastrActions,
  AddToastPayload,
  toastType,
} from "react-redux-toastr";
import { put, takeEvery } from "typed-redux-saga/macro";

import * as actions from "@kraaft/shared/core/modules/alert/alertActions";

export function* alertSagas() {
  yield* takeEvery(actions.showInfo, showToastr);
  yield* takeEvery(actions.showWarn, showToastr);
  yield* takeEvery(actions.showError, showToastr);
  yield* takeEvery(actions.showSuccess, showToastr);
}

function* showToastr(
  action: ReturnType<
    | typeof actions.showInfo
    | typeof actions.showWarn
    | typeof actions.showError
    | typeof actions.showSuccess
  >,
) {
  const actionTypes: {
    [type: string]: toastType;
  } = {
    [actions.showInfo.toString()]: "info",
    [actions.showWarn.toString()]: "warning",
    [actions.showError.toString()]: "error",
    [actions.showSuccess.toString()]: "success",
  };

  const type = actionTypes[action.type];
  const { message, title, isPersistent } = action.payload;
  if (type) {
    const options: AddToastPayload = {
      type,
      title,
      message,
      options: {
        showCloseButton: true,
        timeOut: isPersistent ? 0 : undefined,
      },
    };

    yield* put(toastrActions.add(options));
  }
}
