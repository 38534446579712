import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";

import { AttachMessageSelectionFlow } from "@kraaft/shared/components/galleries/messageActionFlow/attachFlow";
import { ForwardMessageSelectionFlow } from "@kraaft/shared/components/galleries/messageActionFlow/forwardFlow";
import { PhotoGallery } from "@kraaft/shared/components/galleries/photoGallery/photoGallery";
import { usePhotoGalleryWebFooterActions } from "@kraaft/shared/components/galleries/photoGallery/photoGalleryWithSelection/usePhotoGalleryActions";
import { PhotoGalleryLineContraints } from "@kraaft/shared/components/galleries/photoGallery/useSortedGallery";
import { LegacyActionSheet } from "@kraaft/shared/components/legacyActionSheet";
import { selectMessageSelectionAsArray } from "@kraaft/shared/core/modules/message/messageSelectors";
import { selectMiniMediasCount } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { MiniImage } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button, Color, Preloader, Sheet } from "@kraaft/ui";

import { styles } from "./photoGalleryWithSelection.styles";

const IMAGE_HEIGHTS: PhotoGalleryLineContraints = {
  defaultHeight: 170,
  maxHeight: 230,
  minImageCountPerLine: 2,
};

interface Props {
  roomId: string;
  medias: MiniImage[];
}

export const PhotoGallerySheet = Sheet({ web: "overlay" }).create<Props>(
  ({ Paper, Content, Header, Footer }) =>
    ({ medias, roomId, onClose }) => {
      const { t } = useTranslation();

      const { handleCloseFlow, title } = useMediaSelectionContext({
        medias,
        roomId,
        type: "image",
      });

      const moreButtonAnchorRef = useRef(null);

      const [sheetOpen, setSheetOpen, setSheetClosed] = useBooleanState();
      const selectionCount = useSelector(
        selectMessageSelectionAsArray(roomId, "photoGallery"),
      ).length;
      const totalMediaCount = useSelector(
        selectMiniMediasCount(roomId, ["image"]),
      );
      const areMediaFiltered =
        totalMediaCount !== undefined && totalMediaCount !== medias.length;

      const { actions, options } = usePhotoGalleryWebFooterActions({
        roomId,
        setSheetOpen,
        moreButtonAnchorRef,
      });

      return (
        <>
          <Paper>
            <Header onClose={onClose}>{title}</Header>
            <Content noPadding>
              <View style={styles.content}>
                {areMediaFiltered && (
                  <View style={styles.helperTextContainer}>
                    <Text style={styles.helperText}>
                      {t("filteredMapMedias")}
                    </Text>
                  </View>
                )}
                {!medias && (
                  <Preloader absoluteFill backgroundColor={Color.WHITE} />
                )}
                {medias && (
                  <PhotoGallery
                    roomId={roomId}
                    medias={medias}
                    imageHeights={IMAGE_HEIGHTS}
                    backgroundColor={Color.WHITE}
                    areMediaFiltered={areMediaFiltered}
                  />
                )}
              </View>
            </Content>
            {selectionCount > 0 && (
              <Footer>
                {actions.map((action) => (
                  <Button
                    text={action.text}
                    onPress={action.onPress}
                    icon={action.icon}
                    disabled={action.disabled}
                  />
                ))}
              </Footer>
            )}
          </Paper>
          <AttachMessageSelectionFlow
            onClose={handleCloseFlow}
            roomId={roomId}
            onSuccess={onClose}
          />
          <ForwardMessageSelectionFlow
            onClose={handleCloseFlow}
            roomId={roomId}
            onSuccess={onClose}
          />
          <LegacyActionSheet
            open={sheetOpen}
            onClose={setSheetClosed}
            anchorRef={moreButtonAnchorRef}
            options={options}
          />
        </>
      );
    },
);
