import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItemInfo, View } from "react-native";

import { SimpleFolderCard } from "@kraaft/shared/components/mapController/markers/modularFolderMarker/simpleFolderCardForMaps";
import { MarkerGeoJSON } from "@kraaft/shared/components/mapController/types";
import { SlidePanel } from "@kraaft/shared/components/slidePanel";
import {
  ModularFolder,
  ModularFolderWithGeolocation,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";

import { styles } from "./modularFoldersListForMap.styles";

interface Props {
  open: boolean;
  onClose: () => void;
  onRowPress: (modularFolder: ModularFolderWithGeolocation) => void;
  markers: MarkerGeoJSON<ModularFolderWithGeolocation>[];
}

const listSeparator = () => <View style={styles.separator} />;

const ModularFoldersListForMap = (props: Props) => {
  const { open, onClose, markers, onRowPress } = props;
  const { t } = useTranslation();

  const modularFolders = useMemo(
    () => markers.map((marker) => marker.properties.marker.element.ref),
    [markers],
  );

  const extractKey = useCallback((item: ModularFolder) => {
    return item.id;
  }, []);

  const renderModularFolderItem = useCallback(
    ({ item }: ListRenderItemInfo<ModularFolderWithGeolocation>) => {
      function handlePress() {
        onRowPress(item);
        onClose();
      }

      return (
        <SimpleFolderCard
          schemaId={item.schemaId}
          modularFolderId={item.id}
          onPress={handlePress}
        />
      );
    },

    [onClose, onRowPress],
  );

  return (
    <SlidePanel
      name="MapModularFolders"
      open={open}
      onClose={onClose}
      closeIcon="chevron-down"
      title={t("foldersWithCount", { count: modularFolders.length })}
      direction="up"
    >
      <View style={styles.container}>
        <FlatList
          keyExtractor={extractKey}
          data={modularFolders}
          renderItem={renderModularFolderItem}
          ItemSeparatorComponent={listSeparator}
          contentContainerStyle={styles.contentContainer}
        />
      </View>
    </SlidePanel>
  );
};

export { ModularFoldersListForMap };
