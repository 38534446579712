/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ActionCreatorWithPayload,
  createAction,
  createReducer,
  Draft,
} from "@reduxjs/toolkit";

import {
  OptimisticWorkspace,
  WAggregation,
  WOperation,
} from "@kraaft/shared/core/utils/optimistic/types";

export function createOptimisticReducer<
  W extends OptimisticWorkspace<any>,
  P extends { data: WAggregation<W>[] },
>(workspace: W, updateFunction: ActionCreatorWithPayload<P>) {
  const addOperation = createAction(
    `@${workspace.name}/optimistic/ADD_OPERATION`,
    (payload: WOperation<W>) => ({ payload }),
  );
  const removeOperation = createAction(
    `@${workspace.name}/optimistic/REMOVE_OPERATION`,
    (payload: { optimisticId: string }) => ({ payload }),
  );
  const removeOperations = createAction(
    `@${workspace.name}/optimistic/REMOVE_OPERATIONS`,
    (payload: { optimisticIds: string[] }) => ({ payload }),
  );
  const linkOperation = createAction(
    `@${workspace.name}/optimistic/LINK_OPERATION`,
    (payload: { optimisticId: string; date: Date }) => ({ payload }),
  );

  const initialState: {
    operations: Record<string, WOperation<W>>;
  } = { operations: {} };

  const reducer = createReducer(initialState, ({ addCase }) => {
    addCase(addOperation, (state, { payload }) => {
      workspace.optimisticBuilder.addOperation({ ...payload });
      state.operations[payload.optimisticId] = { ...payload } as Draft<
        WOperation<W>
      >;
    });

    addCase(removeOperation, (state, { payload }) => {
      workspace.optimisticBuilder.removeOperation(payload.optimisticId);
      delete state.operations[payload.optimisticId];
    });

    addCase(removeOperations, (state, { payload }) => {
      workspace.optimisticBuilder.removeOperations(payload.optimisticIds);
      for (const optimisticId of payload.optimisticIds) {
        delete state.operations[optimisticId];
      }
    });

    addCase(linkOperation, (state, { payload }) => {
      workspace.optimisticBuilder.link(payload.optimisticId, payload.date);
      const existing = state.operations[payload.optimisticId];
      if (!existing) {
        return;
      }
      existing.updatedAt = payload.date;
    });

    addCase(updateFunction, (state, { payload }) => {
      const removed = workspace.optimisticBuilder.prune(payload.data);
      for (const removedOperation of removed) {
        delete state.operations[removedOperation.optimisticId];
      }
    });
  });

  return {
    reducer,
    addOperation,
    removeOperation,
    removeOperations,
    linkOperation,
  };
}

export function universalizeOptimisticReducer<T>(reducer: T) {
  return reducer as any;
}
