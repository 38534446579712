import { Saga } from "redux-saga";

export async function runSagaAsPromise<S extends Saga>(
  saga: S,
  ...args: Parameters<S>
) {
  const { sagaMiddleware } = await import(
    "@kraaft/shared/core/store/sagaMiddleware"
  );

  return sagaMiddleware.run(saga, ...args).toPromise();
}
