import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { compact } from "lodash";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { ExpandableSection } from "@kraaft/shared/components/expandableSection";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { KDialog } from "@kraaft/shared/components/kDialog";
import {
  selectPoolFolderSchemasDict,
  selectRoomSchema,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { addSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { selectSchemaViews } from "@kraaft/shared/core/modules/schemaView/schemaViewSelectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import {
  compareStrings,
  stringContainsCharacters,
} from "@kraaft/shared/core/utils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button } from "@kraaft/ui";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { NavigationMenu } from "@kraaft/web/src/components/menu";
import { NavigationMenuItemProps } from "@kraaft/web/src/components/menu/navigationMenu";
import { routes } from "@kraaft/web/src/views/app/appRouter/routes";
import { templateRoute } from "@kraaft/web/src/views/app/appRouter/routeUtils";

import { useStyles } from "./expandableSchemaViews.styles";

interface ExpandableSchemaViewsProps {
  poolId: string | undefined;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

const ExpandableSchemaViews = (props: ExpandableSchemaViewsProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen, setClose] = useBooleanState();
  const [name, setName] = useState("");
  const [schemaId, setSchemaId] = useState<string>();
  const { expanded, setExpanded, poolId } = props;
  const { t } = useTranslation();
  const navigationService = useNavigationService();
  const schemaViews = useSelector(selectSchemaViews);
  const roomSchema = useSelector(selectRoomSchema(poolId));
  const schemas = useSelector(selectPoolFolderSchemasDict(poolId));

  const schemaViewItems = useMemo<NavigationMenuItemProps[]>(
    () =>
      schemaViews
        .sort((a, b) => compareStrings(a.name, b.name))
        .map((schemaView) => {
          const schema = schemas[schemaView.schemaId];
          return {
            id: schemaView.id,
            label: schemaView.name,
            icon: schema
              ? KSchemaUtils.getSchemaIconName(schema.icon)
              : "message-circle-01",
            matchRoute: templateRoute(routes.SchemaView, {
              schemaViewId: schemaView.id,
            }),
            onPress: () =>
              navigationService.navigate("SchemaView", {
                schemaViewId: schemaView.id,
              }),
          };
        }),
    [navigationService, schemaViews, schemas],
  );

  const selectSchema = useCallback((newIds: string[] | undefined) => {
    const newSchemaId = newIds?.[0];
    if (newSchemaId) {
      setSchemaId(newSchemaId);
    }
  }, []);

  const schemaItems = useMemo<FilteredListItem<string>[]>(
    () =>
      compact([
        roomSchema
          ? {
              label: roomSchema.name,
              icon: "message-circle-01",
              value: roomSchema.id,
            }
          : undefined,
        ...Object.values(schemas).map<FilteredListItem<string>>((schema) => ({
          label: schema.name,
          icon: KSchemaUtils.getSchemaIconName(schema.icon),
          value: schema.id,
        })),
      ]),
    [roomSchema, schemas],
  );

  const create = useCallback(() => {
    if (!schemaId || name.length === 0) {
      return;
    }
    trackEvent({
      eventName: "Create Saved View",
      content_type: roomSchema?.id === schemaId ? "conversation" : "folder",
      from: "left_panel",
    });
    dispatch(
      addSchemaView({
        name,
        schemaId,
        filters: {
          type: "composite",
          conditions: [],
          operator: "and",
        },
      }),
    );
    setName("");
    setSchemaId(undefined);
    setClose();
  }, [dispatch, name, roomSchema?.id, schemaId, setClose]);

  const dialogButtons: FooterActions = useMemo(
    () => [
      {
        accessibilityLabel: t("createTheSchemaView"),
        text: t("createTheSchemaView"),
        onPress: create,
        disabled: !schemaId || !stringContainsCharacters(name),
        variant: "PRIMARY",
      },
      { accessibilityLabel: t("cancel"), text: t("cancel"), onPress: setClose },
    ],
    [create, name, schemaId, setClose, t],
  );

  return (
    <>
      <ExpandableSection
        containerId="database-schema-views"
        title={t("schemaViews")}
        expanded={expanded}
        setExpanded={setExpanded}
      >
        <div className={classes.addButton}>
          <Button
            accessibilityLabel={t("newSchemaView")}
            text={t("newSchemaView")}
            icon="plus"
            onPress={setOpen}
            width="100%"
          />
        </div>
        <NavigationMenu
          items={schemaViewItems}
          currentRoute={navigationService.getLocation()}
        />
      </ExpandableSection>
      <KDialog
        open={open}
        onClose={setClose}
        title={t("newSchemaView")}
        content={
          <div className={classes.content}>
            <div className={classes.dropdown}>
              <KDropdown
                placeholder={t("chooseSchema")}
                selectedItemIds={schemaId ? [schemaId] : []}
                allowMultiple={false}
                items={schemaItems}
                onSelectionChange={selectSchema}
                fullWidth
                variant="dark"
              />
            </div>
            <div>
              <KInput
                label={t("schemaViewName")}
                value={name}
                onChangeText={setName}
              />
            </div>
          </div>
        }
        actions={dialogButtons}
      />
    </>
  );
};

export { ExpandableSchemaViews };
