import { put, takeEvery } from "typed-redux-saga/macro";

import { PoolActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { RoomCardStateActions } from "@kraaft/shared/core/modules/roomCard/roomCard.actions";

function* onSwitchPool() {
  yield* put(RoomCardStateActions.resetState());
}

export function* onSwitchPoolSaga() {
  yield* takeEvery(PoolActions.switchPool, onSwitchPool);
}
