export type TaskResult =
  | "empty"
  | "success"
  | "partial-success"
  | "error"
  | "timed-out";

export const TaskResultHelper = {
  mergeTaskResults: (taskResults: Array<TaskResult>): TaskResult => {
    if (taskResults.some((task) => task === "error")) {
      return "error";
    }
    if (taskResults.some((task) => task === "timed-out")) {
      return "timed-out";
    }
    if (taskResults.some((task) => task === "partial-success")) {
      return "partial-success";
    }
    if (taskResults.every((task) => task === "empty")) {
      return "empty";
    }
    return "success";
  },
};

export interface OfflineTaskManagerInterface {
  name: string;

  /**
   * Executes the task and returns a promise that resolves with the task result.
   *
   * @return A promise that resolves with the task result.
   */
  execute(): Promise<TaskResult>;

  /**
   * Stops the ongoing process and returns a promise indicating whether the stopping was successful or not.
   *
   * @return A promise that resolves to `true` if the stop operation was successful,
   *   or `false` otherwise.
   */
  stop(): Promise<boolean>;
}
