import { View } from "react-native";
import { useSelector } from "react-redux";

import { ModularPill } from "@kraaft/shared/components/modular/pill";
import {
  ExtraContextForPillRenderer,
  PillsDisplayOptions,
} from "@kraaft/shared/components/modular/pill/utils/types";
import { ModularRecordProperties } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { selectSchemaRootSection } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { filterNullableChildren } from "@kraaft/shared/core/utils/filterNullableChildren";

import { styles } from "./roomCard.styles";

interface Props {
  recordProperties: ModularRecordProperties;
  schemaId: string;
  pillColumns?: string[];
  extraContext: ExtraContextForPillRenderer;
  displayOptions?: PillsDisplayOptions;
  testID?: string;
}

export const ModularCardPills = (props: Props) => {
  const {
    schemaId,
    pillColumns,
    extraContext,
    recordProperties,
    testID,
    displayOptions,
  } = props;

  const rootSection = useSelector(selectSchemaRootSection(schemaId));

  const pills = pillColumns?.map((column, index) => {
    if (!rootSection) {
      return null;
    }

    const schemaColumn = KSchemaUtils.findColumn(rootSection, column);
    const property = recordProperties[column];

    const isNotLast = index < pillColumns.length - 1;

    if (schemaColumn) {
      const pill = filterNullableChildren(
        <ModularPill
          compactRenderer
          schemaColumn={schemaColumn}
          modularRecordProperty={property}
          extraContext={extraContext}
          withSpacer={isNotLast}
          displayOptions={displayOptions}
        />,
      );

      return (
        pill && (
          <View
            key={column}
            style={index === 0 ? styles.firstPill : styles.otherPill}
          >
            {pill}
          </View>
        )
      );
    }
    return null;
  });

  if (pills?.every((pill) => pill === null)) {
    return null;
  }

  return (
    <View style={styles.pillsContainer} testID={`${testID}-roomcard-pills`}>
      {pills}
    </View>
  );
};
