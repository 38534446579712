import { useSelector } from "react-redux";

import { selectChildDirectories } from "@kraaft/shared/core/modules/directory/directorySelectors";

export const useValidateNewDirectoryName = ({
  roomId,
  parentId,
  newName,
  currentName,
}: {
  roomId: string;
  parentId: string;
  newName: string | undefined;
  currentName?: string | undefined;
}) => {
  const directories = useSelector(selectChildDirectories(roomId, parentId));
  if (currentName === newName) {
    return true;
  }
  if (newName === undefined || newName === "") {
    return false;
  }
  return !directories.some(
    (directory) => !directory.optimistic && directory.name === newName,
  );
};
