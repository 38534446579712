import { createReducer } from "@reduxjs/toolkit";
import { keyBy } from "lodash";
import { combineReducers } from "redux";

import { LibrarySchemaStateActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import { LibrarySchemaOptimisticReducer } from "@kraaft/shared/core/modules/librarySchema/librarySchema.optimistic";
import { LibrarySchemaState } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

const initialState: LibrarySchemaState = {
  fetched: false,
  librarySchemas: {},
  companyLibrarySchemas: {},
  createdColumnKey: undefined,
};

const librarySchemaReducers = createReducer(initialState, ({ addCase }) => {
  addCase(LibrarySchemaStateActions.set, (state, { payload }) => {
    state.fetched = true;

    if (payload.from === "public") {
      state.librarySchemas = keyBy(
        payload.data,
        (librarySchema) => librarySchema.id,
      );
    } else if (payload.from === "superadmin") {
      state.librarySchemas = keyBy(
        payload.data.filter((librarySchema) => !librarySchema.companyId),
        (librarySchema) => librarySchema.id,
      );
      state.companyLibrarySchemas = keyBy(
        payload.data.filter((librarySchema) => librarySchema.companyId),
        (librarySchema) => librarySchema.id,
      );
    } else {
      state.companyLibrarySchemas = keyBy(
        payload.data,
        (librarySchema) => librarySchema.id,
      );
    }
  });

  addCase(LibrarySchemaStateActions.setCreatedKey, (state, { payload }) => {
    state.createdColumnKey = payload;
  });

  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OptimisticReducer = LibrarySchemaOptimisticReducer as any;
const reducer = combineReducers({
  data: librarySchemaReducers,
  optimistic: OptimisticReducer,
});

export { reducer as librarySchemaReducers };
