import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { useMeshContext } from "@kraaft/helper-hooks";
import { RoomFiltersContext } from "@kraaft/shared/components/filters/roomFilters.context";
import { RoomFiltersSheet } from "@kraaft/shared/components/filters/roomFilters.sheet";
import { RoomFiltersFooter } from "@kraaft/shared/components/filters/roomFiltersFooter";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { Toggle } from "@kraaft/shared/components/toggle";
import { Visibility } from "@kraaft/shared/core/modules/filter/filterState";
import { selectCurrentContextAtLeastAdminOrSuperadmin } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { conditionalArray } from "@kraaft/shared/core/utils";
import { PixelSize, Spacing, Text } from "@kraaft/ui";

interface RoomFiltersVisibilityProps {
  onClose: () => void;
  onBack: () => void;
}

export const RoomFiltersVisibility = ({
  onClose,
  onBack,
}: RoomFiltersVisibilityProps) => {
  const { t } = useTranslation();
  const canFilterAsAdmin = useSelector(
    selectCurrentContextAtLeastAdminOrSuperadmin,
  );
  const canFilterAsSuperadmin = useSelector(selectCurrentUserIsSuperadmin);
  const {
    visibility,
    setVisibility,
    resetVisibility,
    isDefaultVisibility,
    showArchived,
    setShowArchived,
    resetShowArchived,
    isDefaultShowArchived,
  } = useMeshContext(RoomFiltersContext);

  const handleErase = useCallback(() => {
    resetVisibility();
    resetShowArchived();
  }, [resetShowArchived, resetVisibility]);

  const handleSelect = useCallback(
    ([newVisibility]: Visibility[]) => {
      if (newVisibility !== undefined) {
        setVisibility(newVisibility);
      }
    },
    [setVisibility],
  );

  const items = useMemo<KSelectableListItem<Visibility>[]>(
    () => [
      { label: t("roomFilters.myConversations"), identifier: "member" },
      ...conditionalArray<KSelectableListItem<Visibility>>(
        [{ label: t("roomFilters.allConversations"), identifier: "all" }],
        canFilterAsAdmin,
      ),
      ...conditionalArray<KSelectableListItem<Visibility>>(
        [{ label: t("roomFilters.support"), identifier: "superadmin" }],
        canFilterAsSuperadmin,
      ),
    ],
    [canFilterAsAdmin, canFilterAsSuperadmin, t],
  );

  return (
    <>
      <RoomFiltersSheet.Header onClose={onBack} withBackBehavior>
        {t("roomFilters.visibility")}
      </RoomFiltersSheet.Header>
      <RoomFiltersSheet.Content>
        <KSelectionList
          minSelection={1}
          items={items}
          isMultiple={false}
          selected={[visibility]}
          setSelected={handleSelect}
          disableScroll
          noPadding
        />
        <View style={styles.archivedConversationsRow}>
          <Text size="BODY">{t("roomFilters.archivedConversations")}</Text>
          <Toggle
            id="ide2e-toggle-archived-conversations"
            value={showArchived}
            setValue={setShowArchived}
          />
        </View>
      </RoomFiltersSheet.Content>
      <RoomFiltersFooter
        onFiltered={onClose}
        onErase={handleErase}
        canErase={!isDefaultVisibility || !isDefaultShowArchived}
      />
    </>
  );
};

const styles = StyleSheet.create({
  actionList: {
    gap: Spacing.S8,
  },
  archivedConversationsRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: PixelSize.S48,
  },
});
