import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";

import { selectIsCreatingRoom } from "@kraaft/shared/core/modules/room/roomSelectors";
import { useRouteService } from "@kraaft/shared/core/services/navigation";
import { useMapNavigation } from "@kraaft/shared/core/utils/useMapNavigation";

const useConversationLayoutInfo = () => {
  const isCreatingRoom = useSelector(selectIsCreatingRoom);

  const routeService = useRouteService();

  const { isMapFullscreen, isOpen: isMapOpen } = useMapNavigation();

  const isDrawerOpen = routeService.isDrawerOpen();

  const isSmallScreen = useMediaQuery("(max-width:1250px)");

  const isDetailsDrawerOpen = useMemo(() => {
    const drawerForcedClosed = isSmallScreen || isMapFullscreen;

    if (isMapOpen && !isSmallScreen) {
      return !drawerForcedClosed;
    }
    return isDrawerOpen && !isCreatingRoom && !isMapFullscreen;
  }, [isMapFullscreen, isCreatingRoom, isSmallScreen, isMapOpen, isDrawerOpen]);

  const canDisplayConversation = !isSmallScreen;
  const shouldHideConversation = !canDisplayConversation && isDetailsDrawerOpen;

  return {
    canDisplayConversation,
    isDetailsDrawerOpen,
    shouldHideConversation,
  };
};

export { useConversationLayoutInfo };
