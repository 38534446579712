import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { DifferenceBackground } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceBackground";
import { DifferenceMethod } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/types";
import {
  Attachment,
  ImageAttachment,
} from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { filterAttachments } from "@kraaft/shared/core/utils/utils";
import { Image, PixelSize, Radius, Spacing, Text } from "@kraaft/ui";

const DISPLAYED_IMAGE_COUNT = 4;

interface GalleryAttachmentDifferenceProps {
  method: DifferenceMethod;
  attachments: Attachment[];
}

export const GalleryAttachmentDifference = ({
  method,
  attachments,
}: GalleryAttachmentDifferenceProps) => {
  const filteredAttachments = useMemo(
    () =>
      (filterAttachments(attachments, ["image"]) as ImageAttachment[]) ?? [],
    [attachments],
  );

  const { firstImages, extraImages } = useMemo(() => {
    const firstAttachments = filteredAttachments.slice(
      0,
      DISPLAYED_IMAGE_COUNT,
    );

    const extraCount =
      filteredAttachments.length > DISPLAYED_IMAGE_COUNT
        ? filteredAttachments.length - DISPLAYED_IMAGE_COUNT
        : 0;

    return {
      firstImages: firstAttachments.map((attachment) => {
        return (
          <DifferenceBackground
            key={attachment.id}
            method={method}
            withBorderRadius
          >
            <Image
              preferredSize="thumbnail"
              style={[styles.square, styles.image]}
              contentFit="cover"
              source={attachment.original.downloadUrl}
            />
          </DifferenceBackground>
        );
      }),
      extraImages:
        extraCount > 0 ? (
          <View style={[styles.square, styles.extraCountContainer]}>
            <Text>{`+${extraCount}`}</Text>
          </View>
        ) : null,
    };
  }, [filteredAttachments, method]);

  return (
    <View style={styles.container}>
      {firstImages}
      {extraImages}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    gap: Spacing.S4,
  },
  square: {
    height: PixelSize.S56,
    aspectRatio: 1,
    margin: Spacing.S4,
  },
  image: {
    borderRadius: Radius.SMALL,
  },
  extraCountContainer: {
    justifyContent: "center",
  },
});
