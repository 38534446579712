import { ReactNode, useMemo } from "react";
import { View, ViewStyle } from "react-native";

import { styles } from "@kraaft/shared/components/kDialog/kDialog.styles";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";
import { KPopup } from "@kraaft/shared/components/kPopup";
import { isIE } from "@kraaft/shared/core/utils";
import { Spacing } from "@kraaft/ui";

export interface BaseDialogFrameProps {
  fullHeight?: boolean;
  fullScreen?: boolean;
  size?: KDialogSize;
}

interface KDialogFrameProps extends BaseDialogFrameProps {
  id?: string;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  containerStyle?: ViewStyle;
}

const KDialogFrame = ({
  id,
  children,
  open,
  onClose,
  fullHeight,
  size = KDialogSize.SMALL,
  fullScreen,
  containerStyle,
}: KDialogFrameProps) => {
  // on IE, showing a popup over the carousel freezes the interface. DisableEnforceFocus=true fixes that.
  const disableEnforceFocus = isIE();

  const frameContainerStyle = useMemo(
    () => [
      styles.frameContainer,
      fullScreen ? { padding: Spacing.NONE } : {},
      containerStyle,
    ],
    [fullScreen, containerStyle],
  );

  return (
    <KPopup
      id={id ?? "ide2e-dialog"}
      open={open}
      onClose={onClose}
      maxWidth={size}
      disableEnforceFocus={disableEnforceFocus}
      fullHeight={fullHeight}
      fullScreen={fullScreen}
    >
      <View style={frameContainerStyle}>{children}</View>
    </KPopup>
  );
};

export { KDialogFrame };
