import { useCallback } from "react";
import { useSelector } from "react-redux";

import { BaseMarker } from "@kraaft/shared/components/geolocation/baseMarker";
import { markerSize } from "@kraaft/shared/components/geolocation/baseMarker/baseMarkerConfig";
import { GeolocatedElement } from "@kraaft/shared/components/geolocation/types";
import { Marker } from "@kraaft/shared/components/mapController/markers/marker";
import { MarkerContentRenderer } from "@kraaft/shared/components/mapController/markers/marker/marker.types";
import { SimpleMarkerContent } from "@kraaft/shared/components/mapController/markers/marker/simpleMarkerContent/simpleMarkerContent";
import { FolderCardTooltip } from "@kraaft/shared/components/mapController/markers/modularFolderMarker/folderCardTooltip";
import { selectModularFolderIconAndColor } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ModularFolderWithGeolocation } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";

interface ModularFolderMarkerProps {
  geolocatedElement: GeolocatedElement<ModularFolderWithGeolocation>;
  onMarkerAction: (modularFolderId: string, schemaId: string) => void;
}

const ModularFolderMarker = ({
  geolocatedElement,
  onMarkerAction,
}: ModularFolderMarkerProps) => {
  const onPress = useCallback(() => {
    onMarkerAction(geolocatedElement.id, geolocatedElement.ref.schemaId);
  }, [onMarkerAction, geolocatedElement.id, geolocatedElement.ref.schemaId]);

  const { icon, color } = useSelector(
    selectModularFolderIconAndColor(geolocatedElement.ref.id),
  );

  const renderTooltip = () => (
    <FolderCardTooltip
      schemaId={geolocatedElement.ref.schemaId}
      modularFolderId={geolocatedElement.ref.id}
      onPress={onPress}
    />
  );

  const renderMarkerContent: MarkerContentRenderer = ({
    isHovered,
    isSelected,
  }) => (
    <BaseMarker
      isHovered={isHovered}
      isSelected={isSelected}
      tooltipComponent={renderTooltip()}
    >
      <SimpleMarkerContent
        isHovered={isHovered}
        isSelected={isSelected}
        icon={icon}
        defaultColor={color}
      />
    </BaseMarker>
  );

  return (
    <Marker
      id={geolocatedElement.id}
      size={markerSize}
      coordinates={geolocatedElement.ref.geolocation.coords}
      testID="ide2e-modularFolder-marker"
      onMarkerAction={onPress}
      renderContent={renderMarkerContent}
    />
  );
};

export { ModularFolderMarker };
