import buildEmojiRegex from "emoji-regex";

import { ContentRegexSpec } from "./textProcessor.types";

const globalEmojiRegex = buildEmojiRegex();

export function getTextProcessorRegex(highlight?: string[]) {
  const regexSpecs: ContentRegexSpec[] = [];
  if (highlight !== undefined) {
    for (const h of highlight) {
      regexSpecs.push({
        type: "highlight",
        regex: new RegExp(h, "i"),
      });
    }
  }

  return regexSpecs;
}

export const textIsOnlyEmojis = (text: string) => {
  const cleanText = text.replace(globalEmojiRegex, "");

  return cleanText.length === 0;
};
