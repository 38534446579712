import { KSelectionList } from "@kraaft/shared/components/kSelectionList/kSelectionList";
import { KSelectionListCheckerProps } from "@kraaft/shared/components/kSelectionList/kSelectionListChecker.props";
import { KSelectionListProps } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { Toggle } from "@kraaft/shared/components/toggle";

const CheckerItem = <T extends string, U>(
  props: KSelectionListCheckerProps<T, U>,
) => {
  if (props.type === "all") {
    return (
      <Toggle value={props.selection === "all"} setValue={props.onPress} />
    );
  }
  return <Toggle value={props.isSelected} setValue={props.onPress} />;
};

const KSelectionListToggle = <T extends string = string, U = never>(
  props: KSelectionListProps<T, U>,
) => {
  return <KSelectionList {...props} renderChecker={CheckerItem<T, U>} />;
};

export { KSelectionListToggle };
