import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import remove from "lodash/remove";
import { persistReducer } from "redux-persist";

import { isNative } from "@kraaft/helper-functions";
import { updateNetworkState } from "@kraaft/shared/core/modules/app/appActions";
import {
  addOfflineMessage,
  errorSendingOfflineMessage,
  incrementSentCount,
  logOfflineMessageEvent,
  offlineMessageSendFailure,
  offlineMessageSendSuccess,
  OfflineMessageStateActions,
  retrySendingOfflineMessage,
  stopBackgroundServiceRequest,
} from "@kraaft/shared/core/modules/message/offline/offlineMessageActions";
import { OfflineMessageState } from "@kraaft/shared/core/modules/message/offline/offlineMessageState";
import { offlineMessagePersistConfig } from "@kraaft/shared/core/modules/message/offline/offlineMessageState.migration";

const initialState: OfflineMessageState = {
  queue: [],
  backgroundSessionSentCount: 0,
  lastActions: [],
};

const offlineMessageReducers_ = createReducer(
  initialState,
  ({ addCase, addMatcher }) => {
    addCase(addOfflineMessage, (state, { payload: message }) => {
      state.queue.push(message);
    });

    addCase(
      OfflineMessageStateActions.deleteOfflineMessage,
      (state, { payload: optimisticId }) => {
        remove(state.queue, (message) => message.optimisticId === optimisticId);
      },
    );

    addCase(errorSendingOfflineMessage, (state, action) => {
      const { payload: optimisticId } = action;
      const offlineMessage = state.queue.find(
        (message) => message.optimisticId === optimisticId,
      );

      if (offlineMessage) {
        offlineMessage.hasError = true;
      }
    });

    addCase(retrySendingOfflineMessage, (state, { payload: optimisticId }) => {
      const offlineMessage = state.queue.find(
        (message) => message.optimisticId === optimisticId,
      );

      if (offlineMessage) {
        offlineMessage.hasError = false;
      }
    });

    addCase(incrementSentCount, (state) => {
      state.backgroundSessionSentCount += 1;
    });

    addCase(stopBackgroundServiceRequest, (state) => {
      state.backgroundSessionSentCount = 0;
    });

    addMatcher(
      isAnyOf(
        logOfflineMessageEvent,
        stopBackgroundServiceRequest,
        offlineMessageSendSuccess,
        errorSendingOfflineMessage,
        offlineMessageSendFailure,
        updateNetworkState,
      ),
      (state, action) => {
        state.lastActions = [
          { date: new Date(), action },
          ...state.lastActions.slice(0, 20),
        ];
      },
    );
  },
);

export const offlineMessageReducers = (
  isNative()
    ? persistReducer(offlineMessagePersistConfig, offlineMessageReducers_)
    : offlineMessageReducers_
) as typeof offlineMessageReducers_;
