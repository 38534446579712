import { put, select, spawn, takeEvery } from "typed-redux-saga/macro";

import { isNative } from "@kraaft/helper-functions";
import { showSuccess } from "@kraaft/shared/core/modules/alert/alertActions";
import {
  selectRoom,
  selectRoomUserHistory,
} from "@kraaft/shared/core/modules/room/roomSelectors";
import { roomArchivedStatus } from "@kraaft/shared/core/modules/room/roomUtils";
import { i18n } from "@kraaft/shared/core/services/i18next";

import { sendFileMessageSagas } from "./sendFileMessageSagas";
import { sendGeolocationMessageSagas } from "./sendGeolocationMessageSagas";
import * as actions from "./sendMessageActions";
import { sendTextMessageSagas } from "./sendTextMessageSagas";

export function* sendMessageSagas() {
  yield* spawn(sendTextMessageSagas);
  yield* spawn(sendFileMessageSagas);
  yield* spawn(sendGeolocationMessageSagas);

  yield* takeEvery(
    [
      actions.sendTextMessage,
      actions.sendFileMessage,
      actions.sendAudioMessage,
      actions.sendGeolocationMessage,
    ],
    updateMessageCounterSaga,
  );
  yield* takeEvery(actions.successSendingMessage, successSendingMessageSaga);
}

function* updateMessageCounterSaga({
  payload: { roomId },
}:
  | ReturnType<typeof actions.sendTextMessage>
  | ReturnType<typeof actions.sendFileMessage>
  | ReturnType<typeof actions.sendAudioMessage>
  | ReturnType<typeof actions.sendGeolocationMessage>) {
  yield* put(actions.updateSentMessagesCount({ roomId }));
}

function* successSendingMessageSaga({
  payload: { roomId },
}: ReturnType<typeof actions.successSendingMessage>) {
  if (!isNative()) {
    const roomSummary = yield* select(selectRoom(roomId));
    const roomUserHistory = yield* select(selectRoomUserHistory(roomId));

    if (
      roomSummary !== undefined &&
      roomArchivedStatus(roomSummary, roomUserHistory)
    ) {
      yield* put(showSuccess({ title: i18n.t("messageSentUnarchiveToaster") }));
    }
  }
}
