import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { TextProcessor } from "@kraaft/shared/components/textProcessor/textProcessor";
import { Color, FontSize, Sheet } from "@kraaft/ui";

export const SsoHelpSheet = Sheet({ native: "bottom", web: "popup" })
  .create<{
    helpText: string;
  }>(({ Paper, Header, Content, Footer }) => ({ onClose, helpText }) => {
    const { t } = useTranslation();

    return (
      <Paper>
        <Header onClose={onClose}>{t("sso.link.help")}</Header>
        <Content>
          <View style={styles.content}>
            <TextProcessor
              enableLinks
              enableMail
              enablePhone
              textStyle={styles.text}
              linkStyle={styles.link}
            >
              {helpText}
            </TextProcessor>
          </View>
        </Content>
      </Paper>
    );
  })
  .withDefaults({ size: "SMALL" });

const styles = StyleSheet.create({
  content: {
    minHeight: 150,
  },
  text: { fontSize: FontSize.BODY },
  link: {
    color: Color.BLUE_KRAAFT,
    fontWeight: "500",
  },
});
