import { delay, put, select, takeEvery } from "typed-redux-saga/macro";

import {
  clearTemporarySchemaView,
  editSchemaView,
  editSchemaViewKanbanFormat,
  receiveTemporarySchemaViewsFromMemory,
} from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { selectEditedSchemaViews } from "@kraaft/shared/core/modules/schemaView/schemaViewSelectors";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { updateSchemaViewStore } from "@kraaft/web/src/core/modules/memory/memoryActions";
import { selectMemoryTemporarySchemaViews } from "@kraaft/web/src/core/modules/memory/memorySelectors";

export function* memorySagas() {
  yield* takeEvery(
    UserActions.userConnectedToFirebase,
    loadSchemaViewStoreSaga,
  );
  yield* takeEvery(
    [editSchemaView, editSchemaViewKanbanFormat, clearTemporarySchemaView],
    dumpSchemaViewStoreSaga,
  );
}

function* dumpSchemaViewStore() {
  const temporarySchemaViews = yield* select(selectEditedSchemaViews);
  yield* put(
    updateSchemaViewStore({
      temporarySchemaViews,
    }),
  );
}

function* dumpSchemaViewStoreSaga() {
  // Need to wait to ensure all the actions have edited the schemaView store
  yield* delay(300);

  yield dumpSchemaViewStore();
}

function* loadSchemaViewStoreSaga() {
  const temporarySchemaViews = yield* select(selectMemoryTemporarySchemaViews);

  yield* put(
    receiveTemporarySchemaViewsFromMemory({
      temporarySchemaViews: temporarySchemaViews,
    }),
  );
}
