import { useCallback } from "react";
import { useSelector } from "react-redux";

import { selectModularFolderTitle } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectSchemaName } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

interface UseNavigateToModularFolderHistoryProps {
  roomId: string;
  schemaId: string;
  modularFolderId: string;
}
export const useNavigateToModularFolderHistory = ({
  roomId,
  schemaId,
  modularFolderId,
}: UseNavigateToModularFolderHistoryProps) => {
  const navigationService = useNavigationService();

  const schemaName = useSelector(selectSchemaName(schemaId));
  const modularFolderTitle = useSelector(
    selectModularFolderTitle(modularFolderId),
  );

  const navigateToModularFolderHistory = useCallback(() => {
    navigationService.navigate("ModularFolderHistory", {
      roomId,
      schemaId,
      folderId: modularFolderId,
    });
    trackEvent({
      eventName: "Click On See Modular Folder History",
      room_id: roomId,
      schema_id: schemaId,
      schema_name: schemaName ?? "",
      record_id: modularFolderId,
      record_name: modularFolderTitle,
    });
  }, [
    modularFolderId,
    modularFolderTitle,
    navigationService,
    roomId,
    schemaId,
    schemaName,
  ]);

  return navigateToModularFolderHistory;
};
