import { useCallback, useMemo } from "react";

import {
  Condition,
  CoupleRecordCondition,
} from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Color, Icon } from "@kraaft/ui";

import { useStyles } from "./filterOptions.styles";

interface CheckboxFilterOptionsProps {
  condition: CoupleRecordCondition;
  setCondition: (condition: Condition) => void;
  column: KSchemaColumn;
  valueColumnType: KColumnType.checkbox;
}

const CheckboxFilterOptions = (
  props: CheckboxFilterOptionsProps,
): JSX.Element => {
  const { condition, setCondition, valueColumnType } = props;
  const classes = useStyles();

  const checkboxValue = useMemo(() => {
    if (condition.value.columnType === KColumnType.checkbox) {
      return Boolean(condition.value.value);
    }
    return false;
  }, [condition.value.columnType, condition.value.value]);

  const handleToggleValue = useCallback(() => {
    const _condition: Condition = {
      ...condition,
      value: {
        value: !checkboxValue,
        columnType: valueColumnType,
      },
    };
    setCondition(_condition);
  }, [checkboxValue, condition, setCondition, valueColumnType]);

  const checkIcon = checkboxValue
    ? "round-checkbox-on-fill"
    : "round-checkbox-unchecked";
  const checkIconColor = checkboxValue ? Color.GREEN_LAGOON : Color.BLACK;

  return (
    <div className={classes.centeredContainer} onClick={handleToggleValue}>
      <Icon
        name={checkIcon}
        color={checkIconColor}
        size="MEDIUM"
        fixChromePaste
      />
    </div>
  );
};

export { CheckboxFilterOptions };
