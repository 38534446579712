import React, {
  cloneElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LayoutChangeEvent, View } from "react-native";
import clsx from "clsx";

import { AnyUnexplained } from "@kraaft/shared/core/types";
import { Conversation } from "@kraaft/web/src/views/messenger/conversation/conversation";

import { rootStyle, useStyles } from "./conversationsDisplayer.styles";

interface Props {
  roomId: string;
}

export const ConversationsDisplayer = ({ roomId }: Props) => {
  const classes = useStyles();
  const [messagerWidth, setMessagerWidth] = useState(0);

  const [conversationsComponents, setConversationsComponents] = useState<{
    [roomId: string]: React.ReactElement<typeof Conversation>;
  }>({});

  useEffect(() => {
    if (conversationsComponents[roomId] === undefined) {
      setConversationsComponents((oldConversations) => ({
        ...oldConversations,
        [roomId]: (
          <Conversation
            sizerWidth={messagerWidth}
            roomId={roomId}
            visible={true}
            autofocusMessageInput
          />
        ),
      }));
    }
  }, [conversationsComponents, roomId, messagerWidth]);

  const conversationList = useMemo(
    () => Object.entries(conversationsComponents),
    [conversationsComponents],
  );

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setMessagerWidth(event.nativeEvent.layout.width);
  }, []);

  return (
    <View style={rootStyle.root} onLayout={onLayout}>
      {conversationList.map(
        ([id, value]: [string, React.ReactElement<typeof Conversation>]) => {
          const visible = id === roomId;

          return (
            <div
              id={visible ? "visible-conversation" : undefined}
              key={id}
              className={clsx(visible ? classes.conversation : classes.hidden)}
            >
              {cloneElement(value as ReactElement<AnyUnexplained>, {
                visible: visible,
                sizerWidth: messagerWidth,
              })}
            </div>
          );
        },
      )}
    </View>
  );
};
