import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DialogContentText } from "@mui/material";
import clsx from "clsx";

import { KDialog } from "@kraaft/shared/components/kDialog";
import { KDialogConfig } from "@kraaft/shared/components/kDialog/kDialogProps";
import { removeMemberFromPool } from "@kraaft/shared/core/modules/poolMember/poolMemberActions";
import { PoolMember } from "@kraaft/shared/core/modules/poolMember/poolMemberState";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";

import { makeDialogStyle } from "./removeMemberDialog.styles";

type RemoveMemberDialogProps = {
  poolId: string;
  data: PoolMember;
  open: boolean;
  superadminPanel: boolean;
  onClose: () => void;
};
const RemoveMemberDialog = ({
  open,
  onClose,
  data,
  poolId,
  superadminPanel,
}: RemoveMemberDialogProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = makeDialogStyle();

  const handleRemoveMember = async () => {
    const payload = {
      poolId,
      userId: data.uid,
      superadminPanel,
    };
    dispatch(removeMemberFromPool(payload));

    onClose();
  };

  const isMemberExternal = data.role === UserPoolRole.EXTERNAL;

  const renderContent = () => (
    <div className={classes.dialogContentContainer}>
      <DialogContentText classes={{ root: clsx(classes.text, classes.spacer) }}>
        {t(
          isMemberExternal
            ? "removeMemberExternalSentence1"
            : "removeMemberSentence1",
          {
            member: data.username,
          },
        )}
      </DialogContentText>
      <DialogContentText
        classes={{ root: clsx(classes.text, classes.noMargin) }}
      >
        {t(
          isMemberExternal
            ? "removeMemberExternalSentence2"
            : "removeMemberSentence2",
          {
            member: data.username,
          },
        )}
      </DialogContentText>
      <DialogContentText
        classes={{ root: clsx(classes.text, classes.noMargin) }}
      >
        {t(
          isMemberExternal
            ? "removeMemberExternalSentence3"
            : "removeMemberSentence3",
          {
            member: data.username,
          },
        )}
      </DialogContentText>
    </div>
  );

  const dialog: KDialogConfig = {
    title: t(
      isMemberExternal ? "removeMemberExternalTitle" : "removeMemberTitle",
      {
        member: data.username,
      },
    ),
    content: renderContent(),
    actions: [
      {
        accessibilityLabel: t("remove"),
        text: t("remove"),
        onPress: handleRemoveMember,
        autoFocus: true,
        destructive: true,
      },
      {
        accessibilityLabel: t("cancel"),
        text: t("cancel"),
        onPress: onClose,
      },
    ],
  };

  return <KDialog open={open} onClose={onClose} {...dialog} />;
};

export { RemoveMemberDialog };
