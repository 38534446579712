import { makeStyles } from "@mui/styles";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
  },
  columns: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  columnPicker: {
    overflowY: "auto",
    width: 250,
    padding: Spacing.S12,
    borderRight: `solid 1px ${ColorStyle.SEPARATOR}`,
  },
  columnsEditor: {
    flexGrow: 1,
    position: "relative",
    overflowY: "auto",
  },
  preview: {
    borderLeft: `1px solid ${ColorStyle.SEPARATOR}`,
    padding: Spacing.S16,
  },
});
