import { makeStyles } from "@mui/styles";

type LiteralPosition = "top" | "bottom" | "left" | "right";

function getAxisFromPosition(literalPosition: LiteralPosition) {
  switch (literalPosition) {
    case "top":
    case "bottom":
      return "vertical";
    case "left":
    case "right":
      return "horizontal";
  }
}

type UseProlongateHoverDetectionProps = {
  size: number;
  position: LiteralPosition;
};

const useProlongateHoverDetection = (
  props: UseProlongateHoverDetectionProps,
) => {
  const hoverBufferClasses = useHoverBufferStyles(props);

  return hoverBufferClasses.hoverBuffer;
};

const useHoverBufferStyles = makeStyles({
  hoverBuffer: ({ size, position }: UseProlongateHoverDetectionProps) => ({
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      ...(getAxisFromPosition(position) === "vertical"
        ? {
            height: size,
            left: 0,
            right: 0,
            ...(position === "top"
              ? {
                  top: 0,
                  transform: "translateY(-100%)",
                }
              : {
                  bottom: 0,
                  transform: "translateY(100%)",
                }),
          }
        : {
            width: size,
            top: 0,
            bottom: 0,
            ...(position === "left"
              ? {
                  left: 0,
                  transform: "translateX(-100%)",
                }
              : {
                  right: 0,
                  transform: "translateX(100%)",
                }),
          }),
    },
  }),
});

export { useProlongateHoverDetection };
