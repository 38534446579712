import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { selectRoomPoolId } from "@kraaft/shared/core/modules/room/selectors";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { selectPoolFolderSchemas } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { IconName } from "@kraaft/ui";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";
import {
  detailsQuery,
  queries,
} from "@kraaft/web/src/views/app/appRouter/routes";

type MapTab = {
  id: "photos" | string;
  name: string;
  icon: IconName;
};

function schemaToMapTab(schema: KFolderSchema): MapTab {
  return {
    id: schema.id,
    name: schema.name,
    icon: KSchemaUtils.getSchemaIconName(schema.icon),
  };
}

const useModularFolderTabs = (roomId: string) => {
  const poolId = useSelector(selectRoomPoolId(roomId));
  const roomSchemas = useSelector(selectPoolFolderSchemas(poolId));

  const modularFolderTabs = useMemo(
    () => roomSchemas.map(schemaToMapTab),
    [roomSchemas],
  );

  return modularFolderTabs;
};

const useNavigateToTab = () => {
  const navigation = useNavigationService();

  const select = useCallback(
    (id: MapTab["id"]) => {
      if (id === "photos") {
        navigation.openRoomMapOnPhotoGallery({});
      } else {
        navigation.clickOnSchemaTab(id);
      }
    },
    [navigation],
  );

  return select;
};

const useActiveTab = () => {
  const qs = useQueryString();
  const drawer = qs.get(queries.detailScreen);
  const schemaId = qs.get(queries.schemaId);

  return useMemo(() => {
    switch (drawer) {
      case detailsQuery.modularFolder:
      case detailsQuery.modularFolders:
        if (!schemaId) {
          return "pending";
        }
        return schemaId;
      default:
        return "photos";
    }
  }, [drawer, schemaId]);
};

export const useMapTabs = (roomId: string) => {
  const modularFolderTabs = useModularFolderTabs(roomId);

  const tabs: MapTab[] = [
    {
      id: "photos",
      name: "Photos",
      icon: "image-03",
    },
    ...modularFolderTabs,
  ];

  const select = useNavigateToTab();
  const active = useActiveTab();

  return [tabs, select, active] as const;
};
