import { Tab as MaterialTab } from "@mui/material";

import { ColorStyle, FontSize, Icon, IconName, Spacing } from "@kraaft/ui";

export type TabProps<Identifier extends string = string> = {
  value: Identifier;
  label: string;
  id?: string;
  icon?: IconName;
};

const SX = {
  textTransform: "none",
  flexDirection: "row",
  gap: `${Spacing.S4}px`,
  fontSize: FontSize.BODY,
  color: ColorStyle.FONT_HIGH_EMPHASIS,
  fontWeight: 400,
  minWidth: 100,
  minHeight: 36,
  padding: `${Spacing.S4}px ${Spacing.S24}px`,
  zIndex: 1,
};

const Tab = <Identifier extends string>(props: TabProps<Identifier>) => {
  const { icon, ...otherProps } = props;

  return (
    <MaterialTab
      icon={icon && <Icon name={icon} size="MEDIUM" />}
      disableRipple
      sx={SX}
      {...otherProps}
    />
  );
};

export { Tab };
