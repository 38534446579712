import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  flatList: {
    marginHorizontal: Spacing.S16,
    paddingVertical: Spacing.S4,
    flexGrow: 1,
    minHeight: 0,
  },
  flatListItem: {
    marginVertical: Spacing.S4,
  },
});
