import { useCallback, useState } from "react";

import { useMeshContext } from "@kraaft/helper-hooks";
import { useUserDropdownItems } from "@kraaft/shared/components/modular/details/editors/userEditor/useUserDropdownItems";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularDisplayExtendedRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { ModularDisplayRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { KEditorProps } from "@kraaft/web/src/components/modularTable/components/types";

import { UserEditorInternal } from "./userEditor.internal";

const RoomMemberEditor = (props: KEditorProps<KColumnType.roomMembers>) => {
  const { column, row, onClose, onRowChange } = props;
  const { poolId, getPoolUsers, getOrLoadUsers } = useMeshContext(
    ModularDisplayRequirementsContext,
  );
  const { getRoomFromRecordId } = useMeshContext(
    ModularDisplayExtendedRequirementsContext,
  );

  const [value, setValue] = useState(() =>
    Object.keys(getRoomFromRecordId(row.id)?.members ?? {}),
  );

  const users = useUserDropdownItems({
    selectedUserIds: value,
    selectedUsers: getOrLoadUsers(value),
    poolUsers: getPoolUsers(),
    poolId,
  });

  const handleClose = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleSelectionChange = useCallback(
    (newValue: string[] | undefined) => {
      setValue(newValue || []);
      onRowChange(
        {
          ...row,
          properties: {
            ...row.properties,
            [column.key]: {
              columnType: column.type,
              value: newValue,
            },
          },
        },
        false,
      );
    },
    [onRowChange, row, column.key, column.type],
  );

  return (
    <UserEditorInternal
      onClose={handleClose}
      onChange={handleSelectionChange}
      users={users}
      allowMultiple
      value={value}
    />
  );
};

export { RoomMemberEditor };
