import { StyleSheet } from "react-native";

import { Color, ColorStyle, PixelSize } from "@kraaft/ui";

export const styles = StyleSheet.create({
  documentContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconContainer: {
    height: PixelSize.S36,
    width: PixelSize.S36,
    borderRadius: PixelSize.S36 / 2,
    backgroundColor: Color.WHITE,
    justifyContent: "center",
  },
  icon: {
    alignSelf: "center",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  text: {
    flexGrow: 1,
    flexShrink: 1,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },

  textContainerWithinBubble: {
    paddingTop: 0,
  },
});
