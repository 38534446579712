import { CSSTransition } from "react-transition-group";
import clsx from "clsx";

import { useLayoutMeasurer } from "@kraaft/shared/core/utils/useLayoutMeasurer";
import { Icon, IconName, Text } from "@kraaft/ui";
import { Tooltip } from "@kraaft/web/src/components/tooltip";

import { rnStyles, useRoundButtonStyles } from "./addOrImportRoomButton.styles";

interface RoundButtonProps {
  id?: string;
  icon: IconName;
  iconSize: number;
  iconColor: string;
  buttonColor: string;
  text?: string;
  textColor?: string;
  textHidden?: boolean;
  tooltipText?: string;
  onClick?: () => void;
  className?: string;
}

export const RoundButton = ({
  id,
  icon,
  iconSize,
  iconColor,
  buttonColor,
  text,
  textColor,
  textHidden = true,
  tooltipText,
  onClick,
  className,
}: RoundButtonProps) => {
  const [{ width: labelWidth }, onLayout] = useLayoutMeasurer();
  const classes = useRoundButtonStyles({
    buttonColor,
    labelWidth,
  });

  const content = (
    <div
      className={clsx(classes.roundButton, className)}
      onClick={onClick}
      id={id}
    >
      <Icon name={icon} size={iconSize} color={iconColor} />
      {text ? (
        <CSSTransition in={!textHidden} classNames="collapse" timeout={150}>
          <div className={classes.collapsibleTextContainer}>
            <Text
              onLayout={onLayout}
              color={textColor ?? iconColor}
              size="BODY"
              weight="medium"
              style={rnStyles.text}
              numberOfLines={1}
            >
              {text}
            </Text>
          </div>
        </CSSTransition>
      ) : null}
    </div>
  );

  if (tooltipText) {
    return (
      <Tooltip title={tooltipText} placement="right">
        {content}
      </Tooltip>
    );
  }

  return content;
};
