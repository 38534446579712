import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { MessageMatchProps } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageMatchProps";
import { SearchTextResult } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/searchTextResult";
import { InputPartitionHelper } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { GeolocationMessage } from "@kraaft/shared/core/modules/message/messageState";
import { formatAddress } from "@kraaft/shared/core/utils";
import { Icon, Spacing } from "@kraaft/ui";

export const MessageGeolocationMatch = ({
  message,
  searchResult,
}: MessageMatchProps<GeolocationMessage>) => {
  const geolocationInputPartition = useMemo(
    () => [
      InputPartitionHelper.textToInputPartition(
        formatAddress(message.geolocation),
      ),
    ],
    [message.geolocation],
  );

  return (
    <View style={styles.container}>
      <Icon name="marker-pin-01" />
      <SearchTextResult
        style={styles.text}
        text={geolocationInputPartition}
        result={searchResult}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: Spacing.S8,
  },
  text: {
    flexShrink: 1,
    marginTop: Spacing.S2,
  },
});
