import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

interface MakeStylesProps {
  panelWidth: number;
}

export const useStyles = makeStyles({
  panelContent: {
    display: "flex",
    flexDirection: "column",
    width: ({ panelWidth }: MakeStylesProps) => panelWidth,
    backgroundColor: Color.WHITE,
    flexGrow: 1,
  },
  headerContainer: {
    margin: `${Spacing.S12}px ${Spacing.S24}px 0 ${Spacing.S24}px`,
  },
  stepperContainer: {
    display: "flex",
    alignItems: "center",
    gap: Spacing.S24,
    marginBottom: Spacing.S12,
  },
  name: {
    fontSize: FontSize.H2,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
});
