import { useMemo } from "react";
import { ButtonBase, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ImageStyle } from "expo-image";

import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import {
  Color,
  ColorStyle,
  FontSize,
  Icon,
  Image,
  morphClassNameAsStyle,
  Spacing,
} from "@kraaft/ui";

import { ITEM_HEIGHT, ITEM_WIDTH } from "./basicCell.styles";

interface AttachmentPreviewProps {
  attachment: Attachment;
  preventEdition: boolean;
  handleAttachmentClick: (attachment: Attachment) => () => void;
}

export const AttachmentPreview = ({
  attachment,
  handleAttachmentClick,
  preventEdition,
}: AttachmentPreviewProps) => {
  const styles = useStyles();
  const imageStyle = useMemo<ImageStyle>(() => {
    return morphClassNameAsStyle(styles.image) as ImageStyle;
  }, [styles.image]);

  if (attachment.type === "image") {
    return (
      <ButtonBase
        disabled={preventEdition}
        onClick={handleAttachmentClick(attachment)}
      >
        <Image
          style={imageStyle}
          preferredSize="thumbnail"
          source={attachment.original.downloadUrl}
          alt=""
          contentFit="cover"
        />
      </ButtonBase>
    );
  }

  return (
    <Tooltip
      title={attachment.original.filename}
      arrow
      placement="top"
      classes={{
        tooltip: styles.tooltip,
        arrow: styles.arrow,
      }}
    >
      <span className={styles.iconDocument}>
        <ButtonBase
          disabled={preventEdition}
          onClick={handleAttachmentClick(attachment)}
          classes={{ root: styles.document }}
        >
          <Icon name="file-04" size="MEDIUM" color="FONT_HIGH_EMPHASIS" />
        </ButtonBase>
      </span>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  image: {
    height: ITEM_HEIGHT,
    width: ITEM_WIDTH,

    borderRadius: 5,
  },
  document: {
    height: ITEM_HEIGHT,
    width: ITEM_WIDTH,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    border: `1px solid ${ColorStyle.SEPARATOR}`,
    borderRadius: 5,
    backgroundColor: Color.WHITE,
  },
  tooltip: {
    backgroundColor: Color.WHITE,
    color: Color.BLACK,
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.11)",
    fontSize: FontSize.MEDIUM,
  },
  arrow: {
    color: Color.WHITE,
  },
  iconDocument: {
    marginTop: -Spacing.S4,
  },
});
