import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { OverlayMenu } from "@kraaft/shared/components/overlayMenu";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KRoomSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { editSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { Button } from "@kraaft/ui";
import { FilterMenu } from "@kraaft/web/src/components/filterMenu";
import {
  countNonCompositeConditions,
  useFilterRooms,
} from "@kraaft/web/src/components/filterMenu/filterUtils";
import { useSchemaView } from "@kraaft/web/src/components/modularFoldersViewer/useSchemaView";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

export function useConversationTableRoomFilter(
  roomSchema: KRoomSchema,
  rooms: Room[],
  schemaViewId: string | undefined,
  filterButtonId?: string,
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const schemaView = useSchemaView(
    RecordWorkspace.ALL_ROOMS,
    roomSchema.id,
    schemaViewId,
  );

  const nonCompositeConditions = countNonCompositeConditions(
    schemaView.filters,
  );
  const buttonText =
    nonCompositeConditions > 0 ? `(${nonCompositeConditions})` : undefined;

  const filterButtonRef = useRef<HTMLButtonElement | null>(null);
  const [filterOverlayOpen, setFilterOverlayOpen] = useState(false);

  const filteredRooms = useFilterRooms(
    roomSchema.rootSection,
    rooms,
    schemaView.filters,
  );

  const onApplyFilter = useCallback(
    (filter: CompositeCondition) => {
      dispatch(
        editSchemaView({
          schemaId: roomSchema.id,
          workspace: RecordWorkspace.ALL_ROOMS,
          schemaViewId,
          schemaView: {
            filters: filter,
          },
        }),
      );
      setFilterOverlayOpen(false);
    },
    [dispatch, roomSchema.id, schemaViewId],
  );

  const openFilterOverlay = useCallback(() => setFilterOverlayOpen(true), []);
  const closeFilterOverlay = useCallback(() => setFilterOverlayOpen(false), []);

  const columns = useMemo(
    () => KSchemaUtils.flattenColumnsWithPath(roomSchema.rootSection),
    [roomSchema.rootSection],
  );

  const filterButton = (
    <>
      <Button
        id={filterButtonId}
        variant="TERTIARY"
        size="SMALL"
        icon="filter-funnel-03"
        onPress={openFilterOverlay}
        accessibilityLabel={t("filter")}
        text={buttonText}
        selected={nonCompositeConditions > 0}
        ref={filterButtonRef}
        condensed
      />
      <OverlayMenu
        visible={filterOverlayOpen}
        anchorRef={filterButtonRef}
        onClose={closeFilterOverlay}
      >
        <FilterMenu
          onApplyFilter={onApplyFilter}
          columns={columns}
          filtersList={schemaView.filters}
          disabledColumnTypes={[KColumnType.roomMembers, KColumnType.roomName]} // @HACK to remove metadatacondition on rooms
          showIncludeArchivedRoomsShortcut
        />
      </OverlayMenu>
    </>
  );
  return { filterButton, filteredRooms };
}
