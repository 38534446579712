import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MiniMediaStateActions } from "@kraaft/shared/core/modules/miniMedia/miniMedia.actions";
import { selectSearchedMediasOfType } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";

export function useDocumentGallerySearch(roomId: string) {
  const dispatch = useDispatch();

  const setSearch = useCallback(
    (search: string) => {
      dispatch(MiniMediaStateActions.setSearchText({ search }));
    },
    [dispatch],
  );

  const filteredDocuments = useSelector(
    selectSearchedMediasOfType(roomId, "document"),
  );

  return { setSearch, filteredDocuments };
}
