import { StyleSheet } from "react-native";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  scrollViewWrapper: {
    flex: 1,
  },
  scrollViewContainer: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  scrollViewContentContainer: {
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
  },
  mapButton: {
    paddingVertical: Spacing.S12,
    paddingHorizontal: Spacing.S16,
  },
  promotionCardContainer: {
    padding: Spacing.S16,
  },
});
