import { makeStyles } from "@mui/styles";

import { markerSelectedScale } from "@kraaft/shared/components/geolocation/baseMarker/baseMarkerConfig";

interface useStylesProps {
  bodyColor: string;
  strokeColor: string;
}

export const useStyles = makeStyles({
  markerContainer: {
    position: "relative",
    transition: "all .2s ease-in-out",
    transformOrigin: "bottom",
    cursor: "pointer",
  },
  markerContainerHovered: {
    transform: `scale(${markerSelectedScale})`,
  },
  marker: ({ bodyColor, strokeColor }: useStylesProps) => ({
    "& .body": {
      fill: bodyColor,
    },
    "& .stroke": {
      stroke: strokeColor,
    },
  }),
  content: {
    position: "absolute",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    display: "flex",
  },
  tooltip: {
    padding: 0,
    borderRadius: 0,
    maxWidth: "none",
    margin: 20,
  },
});
