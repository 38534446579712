import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ExpandableSection } from "@kraaft/shared/components/expandableSection";
import { selectPoolFolderSchemas } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { NavigationMenu } from "@kraaft/web/src/components/menu";
import { NavigationMenuItemProps } from "@kraaft/web/src/components/menu/navigationMenu";
import { routes } from "@kraaft/web/src/views/app/appRouter/routes";
import { templateRoute } from "@kraaft/web/src/views/app/appRouter/routeUtils";

interface ExpandableGlobalViewsProps {
  poolId: string | undefined;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

const ExpandableGlobalViews = (props: ExpandableGlobalViewsProps) => {
  const { expanded, setExpanded, poolId } = props;
  const { t } = useTranslation();
  const navigationService = useNavigationService();
  const schemas = useSelector(selectPoolFolderSchemas(poolId));

  const globalViewItems = useMemo<NavigationMenuItemProps[]>(
    () => [
      {
        id: "rooms",
        label: t("rooms"),
        icon: "message-circle-01",
        containerId: "database-global-views-rooms",
        onPress: () => navigationService.navigate("ConversationTable"),
        matchRoute: routes.ConversationTable,
      },
      ...schemas.map<NavigationMenuItemProps>((schema) => ({
        id: schema.id,
        label: schema.name,
        icon: KSchemaUtils.getSchemaIconName(schema.icon),
        matchRoute: templateRoute(routes.ModularFoldersTable, {
          schemaId: schema.id,
        }),
        onPress: () =>
          navigationService.navigate("ModularFoldersTable", {
            schemaId: schema.id,
          }),
      })),
    ],
    [navigationService, schemas, t],
  );

  return (
    <ExpandableSection
      containerId="database-global-views"
      title={t("globalViews")}
      expanded={expanded}
      setExpanded={setExpanded}
    >
      <NavigationMenu
        items={globalViewItems}
        currentRoute={navigationService.getLocation()}
      />
    </ExpandableSection>
  );
};

export { ExpandableGlobalViews };
