import { useSelector } from "react-redux";

import type { InviteRoomMemberFlowProps } from "@kraaft/shared/components/memberList/inviteRoomMemberFlow";
import { selectOnePool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { InviteInternalOrExternalCollaboratorDialog } from "@kraaft/web/src/components/kDialog/customs/inviteInternalOrExternalCollaboratorDialog";

const InviteRoomMemberFlow = (props: InviteRoomMemberFlowProps) => {
  const { open, onClose, roomId, source, inviteType } = props;

  const room = useSelector(selectRoom(roomId));
  const pool = useSelector(selectOnePool(room?.poolId));

  const currentUserId = useSelector(selectCurrentUserId);

  if (!pool || !room || !currentUserId) {
    return null;
  }

  return (
    <InviteInternalOrExternalCollaboratorDialog
      pool={pool}
      room={room}
      senderUserId={currentUserId}
      open={open}
      onClose={onClose}
      source={source}
      inviteType={inviteType}
    />
  );
};

export { InviteRoomMemberFlow };
