import { EventChannel, eventChannel } from "redux-saga";
import { put, take, takeEvery } from "typed-redux-saga/macro";

import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import {
  ReportTemplateActions,
  ReportTemplateStateActions,
} from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.actions";
import { ReportTemplateDelaySnapshot } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.optimistic";
import { PoolSchemaReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToReportTemplatesSaga() {
  yield takeCountedDeep(
    ReportTemplateActions.subscribe,
    ReportTemplateActions.unsubscribe,
    subscribeToReportTemplates,
    unsubscribeFromReportTemplates,
    (action) => action.payload.poolId,
  );

  let currentPoolId: string | undefined;
  while (true) {
    const action = yield* take([
      PoolStateActions.setPoolLocation,
      UserActions.userDisconnectedFromFirebase,
    ]);
    if (PoolStateActions.setPoolLocation.match(action)) {
      if (currentPoolId) {
        yield* put(
          ReportTemplateActions.unsubscribe({ poolId: currentPoolId }),
        );
      }
      currentPoolId = action.payload.poolId;
      yield* put(
        ReportTemplateActions.subscribe({ poolId: action.payload.poolId }),
      );
    } else if (currentPoolId) {
      yield* put(ReportTemplateActions.unsubscribe({ poolId: currentPoolId }));
    }
  }
}

type Meta = EventChannel<{ data: PoolSchemaReportTemplate[] }> | undefined;

function createChannel(poolId: string) {
  return eventChannel<{ data: PoolSchemaReportTemplate[] }>((emit) => {
    return Firestore.subscribeToTemplates(poolId, (data) => emit({ data }));
  });
}

function* receiveReportTemplates(reportTemplates: {
  data: PoolSchemaReportTemplate[];
}) {
  yield* ReportTemplateDelaySnapshot(
    ReportTemplateStateActions.set,
    reportTemplates,
  );
}

function* subscribeToReportTemplates(
  registerMeta: (meta: Meta) => void,
  action: ReturnType<typeof ReportTemplateActions.subscribe>,
) {
  const channel = createChannel(action.payload.poolId);
  registerMeta(channel);
  yield* takeEvery(channel, receiveReportTemplates);
}

function* unsubscribeFromReportTemplates(meta: Meta) {
  meta?.close();
}
