import { useTranslation } from "react-i18next";

import { RoomNotificationPreferences } from "@kraaft/shared/components/notificationPreferences/roomNotificationPreferences";
import { RoomNotificationFilterSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";
import { Sheet } from "@kraaft/ui";

interface NotificationPopupContentProps {
  poolId: string;
  roomId: string;
  source: RoomNotificationFilterSource;
  onClose: () => void;
}

export const NotificationSheet = Sheet({
  web: "popup",
  native: "bottom",
})
  .create<NotificationPopupContentProps>(
    ({ Paper, Header, Content }) =>
      ({ onClose, poolId, roomId, source }) => {
        const { t } = useTranslation();

        return (
          <Paper>
            <Header onClose={onClose}>{t("notifications.preferences")}</Header>
            <Content noPadding>
              <RoomNotificationPreferences
                poolId={poolId}
                roomId={roomId}
                source={source}
                onChanged={onClose}
              />
            </Content>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "SMALL" });
