import { useMemo } from "react";
import { useSelector } from "react-redux";

import { CheckboxGuardStrategy } from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuard";
import { getAppropriateCheckboxGuardStrategy } from "@kraaft/shared/components/modularFolders/checkboxGuard/getAppropriateCheckboxGuardStrategy";
import {
  selectModularFolderCompletedValue,
  selectModularFolderProperties,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import {
  SchemaLockLookup,
  SectionLockInfo,
} from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { CheckboxColumnDef } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/checkbox";
import { KSchemaColumnBase } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { usePrevious } from "@kraaft/shared/core/utils/hooks";

export function useCheckboxGuardProps({
  schemaLockLookup,
  highlightedCheckboxColumn,
  focusedModularFolderId,
}: {
  schemaLockLookup: SchemaLockLookup;
  highlightedCheckboxColumn:
    | (KSchemaColumnBase & CheckboxColumnDef)
    | undefined;
  focusedModularFolderId: string | undefined;
}) {
  const focusedModularFolderProperties = useSelector(
    selectModularFolderProperties(focusedModularFolderId),
  );
  const focusedModularFolderCompletionStatus = useSelector(
    selectModularFolderCompletedValue(focusedModularFolderId),
  );

  const checkboxGuardProps = useMemo<
    | {
        sectionLockInfo: SectionLockInfo;
        columnName: string;
        strategy: CheckboxGuardStrategy | undefined;
      }
    | undefined
  >(() => {
    if (!focusedModularFolderProperties || !highlightedCheckboxColumn) {
      return undefined;
    }

    const sectionLockInfo =
      ModularRecordUtils.getSectionLockInfoWithSchemaLockLookup(
        schemaLockLookup,
        focusedModularFolderProperties,
        highlightedCheckboxColumn.key,
      );

    let strategy: CheckboxGuardStrategy;

    const completionStatus = focusedModularFolderCompletionStatus ?? false;

    if (
      sectionLockInfo.isLocked &&
      (!highlightedCheckboxColumn.isLockingSection ||
        sectionLockInfo.highestLockedSectionKey !== sectionLockInfo.section.key)
    ) {
      strategy = completionStatus
        ? "cantUncheckParentLocked"
        : "cantCheckParentLocked";
    } else {
      strategy = getAppropriateCheckboxGuardStrategy(
        highlightedCheckboxColumn.isLockingSection ?? false,
        completionStatus,
        sectionLockInfo.isCurrentUserAuthorizedToUnlock,
        sectionLockInfo.highestLockedSectionKey,
        sectionLockInfo.section.key,
      );
    }

    return {
      sectionLockInfo,
      strategy,
      columnName: highlightedCheckboxColumn.name,
    };
  }, [
    focusedModularFolderCompletionStatus,
    focusedModularFolderProperties,
    highlightedCheckboxColumn,
    schemaLockLookup,
  ]);

  const previousCheckboxGuardProps = usePrevious(checkboxGuardProps);

  return checkboxGuardProps ?? previousCheckboxGuardProps;
}
