import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import { selectCurrentPoolLanguage } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { ChangePoolLanguageDialogProps } from "@kraaft/web/src/views/settings/changePoolLanguageDialog";
import { LanguageDropdown } from "@kraaft/web/src/views/settings/managePool/settingsLanguage/languageDropdown";

import { useStyles } from "./dialog.styles";

interface Props extends ChangePoolLanguageDialogProps {
  poolId: string;
}

const ConnectedChangePoolLanguageDialog = ({
  open,
  onClose,
  poolId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const originalPoolLanguage = useSelector(selectCurrentPoolLanguage);

  const [poolLanguage, setPoolLanguage] = useState<PoolLanguage | undefined>(
    originalPoolLanguage,
  );

  useEffect(() => {
    if (originalPoolLanguage) {
      setPoolLanguage(originalPoolLanguage);
    }
  }, [originalPoolLanguage]);

  const content = (
    <div className={classes.dialogContentContainer}>
      {poolLanguage && (
        <LanguageDropdown
          language={poolLanguage}
          onChange={setPoolLanguage}
          variant="dark"
        />
      )}
    </div>
  );

  const handleClose = () => {
    if (originalPoolLanguage) {
      setPoolLanguage(originalPoolLanguage);
    }
    onClose();
  };

  const onConfirm = async () => {
    setIsLoading(true);
    if (poolLanguage) {
      try {
        await Api.editPoolLanguage({
          poolId: poolId,
          update: { language: poolLanguage },
        });
      } catch (error) {
        dispatch(
          showError({ title: t("internalError"), message: error.message }),
        );
      } finally {
        setIsLoading(false);
        onClose();
      }
    }
  };

  const actions: FooterActions = [
    {
      accessibilityLabel: t("confirmPoolLanguage"),
      text: t("confirmPoolLanguage"),
      onPress: onConfirm,
      id: "ide2e-poolLanguage-confirm",
      loading: isLoading,
      disabled: poolLanguage === originalPoolLanguage,
      variant: "PRIMARY",
    },
  ];

  return (
    <KDialog
      open={open}
      onClose={handleClose}
      title={t("adminLanguageDialogTitle")}
      subtitle={t("adminLanguageDialogSubtitle")}
      content={content}
      actions={actions}
      size={KDialogSize.SMALL}
    />
  );
};

export { ConnectedChangePoolLanguageDialog };
