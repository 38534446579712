import React, { useCallback, useRef } from "react";

import { ContextMenuButton } from "@kraaft/shared/components/contextMenuButton/index";
import { ReactNodeRef } from "@kraaft/shared/core/types";
import { ActionSheetHost } from "@kraaft/ui";

type ActionSheetHostWithContextMenuButtonProps = {
  children: React.ReactNode;
  horizontal?: boolean;
  isOpen: boolean;
  onClose: () => void;
  handleOpen: () => void;
};

export const ActionSheetHostWithContextMenuButton = ({
  children,
  horizontal,
  handleOpen,
  isOpen,
  onClose,
}: ActionSheetHostWithContextMenuButtonProps) => {
  const contextMenuButtonRef = useRef<ReactNodeRef | null>(null);

  const handleOpenContextMenu = useCallback(
    (ref: ReactNodeRef) => {
      contextMenuButtonRef.current = ref;
      handleOpen();
    },
    [handleOpen],
  );

  return (
    <>
      <ContextMenuButton
        onPress={handleOpenContextMenu}
        horizontal={horizontal}
      />
      <ActionSheetHost
        open={isOpen}
        onClose={onClose}
        anchor={contextMenuButtonRef.current}
        withoutBackdrop
      >
        {children}
      </ActionSheetHost>
    </>
  );
};
