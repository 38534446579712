import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { CreateNewChecklistTemplateDialogProps } from "@kraaft/shared/components/createNewChecklistTemplateDialog/createNewChecklistTemplateProps";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame";
import { createTaskTemplate } from "@kraaft/shared/core/modules/schemaTemplate/taskTemplate/taskTemplateActions";
import { EditChecklistTemplate } from "@kraaft/web/src/components/checklistTemplate/editChecklistTemplate/editChecklistTemplate";

export const CreateNewChecklistTemplateDialog = ({
  open,
  onClose,
  onCreate,
}: CreateNewChecklistTemplateDialogProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      dispatch(createTaskTemplate({ name: "" }));
    }
  }, [open, dispatch]);

  return (
    <KDialogFrame
      id="ide2e-createChecklistTemplate-dialog"
      open={open}
      onClose={onClose}
    >
      <KDialogContent
        title={t("createNewTemplateList")}
        content={<EditChecklistTemplate onClose={onCreate} />}
      />
    </KDialogFrame>
  );
};
