import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, ViewStyle } from "react-native";

import { ConversationDetailButton } from "@kraaft/shared/components/conversationDetailButton";
import { Infos } from "@kraaft/shared/components/conversationDetails/infos";
import { WorksiteFolder } from "@kraaft/shared/components/conversationDetails/worksiteFolder";
import {
  useNavigationService,
  useRouteService,
} from "@kraaft/shared/core/services/navigation";
import { ConversationDetailsPanel } from "@kraaft/shared/core/services/navigation/navigationParams";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import * as TabBar from "@kraaft/web/src/components/tabBar";
import { useOpenExternalUrl } from "@kraaft/web/src/core/utils/useOpenExternalUrl";
import { useConversationLayoutInfo } from "@kraaft/web/src/views/messenger/useConversationLayoutInfo";

import { useStyles } from "./conversationDetails.styles";

interface Props {
  roomId: string;
  schemaId: string;
  portalHostname?: string;
  style?: StyleProp<ViewStyle>;
  openedPanel: ConversationDetailsPanel | undefined;
  setOpenedPanel: (
    panelIdentifier: ConversationDetailsPanel | undefined,
  ) => void;
  isStickyPanel?: boolean;
}

type Route = "ConversationFolder" | "ConversationInfo";

const useCloseDrawerButton = (roomId: string) => {
  const navigation = useNavigationService();

  const closeDrawer = useCallback(() => {
    navigation.toggleConversationDetail({ roomId: roomId, open: false });
  }, [navigation, roomId]);

  const { canDisplayConversation } = useConversationLayoutInfo();
  const shouldShowCloseButton = !canDisplayConversation;

  return { shouldShowCloseButton, closeDrawer };
};

const ConversationDetails = ({
  schemaId,
  openedPanel,
  roomId,
  setOpenedPanel,
  isStickyPanel,
  portalHostname,
  style,
}: Props) => {
  const navigation = useNavigationService();
  const route = useRouteService().isViewingConversationInfo()
    ? "ConversationInfo"
    : "ConversationFolder";

  const { t } = useTranslation();

  const classes = useStyles();

  const navigateToTab = useCallback(
    (targetRoute: Route) => {
      navigation.navigate(targetRoute, {
        roomId,
        openedPanel,
        isStickyPanel,
      });
      switch (targetRoute) {
        case "ConversationFolder":
          trackEvent({
            eventName: "Click On Folder Tab",
          });
          break;
        case "ConversationInfo":
          trackEvent({
            eventName: "Click On Information Button",
          });
          break;
        default:
          break;
      }
    },
    [isStickyPanel, navigation, openedPanel, roomId],
  );

  const openExternalUrl = useOpenExternalUrl();

  const { shouldShowCloseButton, closeDrawer } = useCloseDrawerButton(roomId);

  return (
    <div className={classes.page}>
      <div className={classes.topBar}>
        {shouldShowCloseButton && (
          <div className={classes.closeDrawer}>
            <ConversationDetailButton
              accessibilityLabel={t("close")}
              side="right"
              onPress={closeDrawer}
            />
          </div>
        )}
        <div className={classes.tabs}>
          <TabBar.Container value={route} onChange={navigateToTab} isFullWidth>
            <TabBar.Tab<Route>
              icon="folder-fill"
              label={t("conversationHeader.folder")}
              value="ConversationFolder"
              id="worksite-folder"
            />
            <TabBar.Tab<Route>
              icon="info-fill"
              label={t("conversationHeader.infos")}
              value="ConversationInfo"
              id="info"
            />
          </TabBar.Container>
        </div>
      </div>
      {route === "ConversationInfo" && (
        <Infos
          goToUrl={openExternalUrl}
          openedPanel={openedPanel}
          roomId={roomId}
          schemaId={schemaId}
          setOpenedPanel={setOpenedPanel}
          isStickyPanel={isStickyPanel}
          portalHostname={portalHostname}
          style={style}
        />
      )}
      {route === "ConversationFolder" && (
        <WorksiteFolder
          openedPanel={openedPanel}
          roomId={roomId}
          setOpenedPanel={setOpenedPanel}
          portalHostname={portalHostname}
        />
      )}
    </div>
  );
};

export { ConversationDetails };
