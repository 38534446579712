import { useCallback } from "react";

import { LinkedListHelpers } from "@kraaft/shared/core/utils/useBidirectional/linkedList";

export const CursorState = {
  FINISHED: "finished",
  UNKNOWN: "unknown",
};
export type CursorStateType = (typeof CursorState)[keyof typeof CursorState];

export interface LinkedListElement {
  earlierId: string | CursorStateType;
  itemId: string;
  laterId: string | CursorStateType;
}

export type LinkedList = Record<string, LinkedListElement>;

export function useLinkedListCreation<T>(
  getIdFromItem: (item: T) => string,
  pageSize: number,
) {
  const createEarlierLinkedList = useCallback(
    (data: T[], end: boolean, elementMissingBeforeId: LinkedListElement) => {
      const linkedList = LinkedListHelpers.fromArray(data, getIdFromItem);
      const earliestItem = LinkedListHelpers.getEarliest(linkedList);

      if (end && earliestItem) {
        earliestItem.earlierId = CursorState.FINISHED;
      }

      const latestItem = LinkedListHelpers.getLatest(linkedList);
      if (latestItem) {
        latestItem.laterId = elementMissingBeforeId.itemId;
      }
      linkedList[elementMissingBeforeId.itemId] = {
        earlierId: latestItem?.itemId ?? CursorState.FINISHED,
        itemId: elementMissingBeforeId.itemId,
        laterId: elementMissingBeforeId.laterId,
      };

      return linkedList;
    },
    [getIdFromItem],
  );

  const createLaterLinkedList = useCallback(
    (data: T[], end: boolean, elementMissingAfterId: LinkedListElement) => {
      const linkedList = LinkedListHelpers.fromArray(data, getIdFromItem);
      const latestItem = LinkedListHelpers.getLatest(linkedList);

      if (end && latestItem) {
        latestItem.laterId = CursorState.FINISHED;
      }
      const earliestItem = LinkedListHelpers.getEarliest(linkedList);
      if (earliestItem) {
        earliestItem.earlierId = elementMissingAfterId.itemId;
      }
      linkedList[elementMissingAfterId.itemId] = {
        earlierId: elementMissingAfterId.earlierId,
        itemId: elementMissingAfterId.itemId,
        laterId: earliestItem?.itemId ?? CursorState.FINISHED,
      };

      return linkedList;
    },
    [getIdFromItem],
  );

  const createBottomLinkedList = useCallback(
    (data: T[], end: boolean) => {
      const linkedList = LinkedListHelpers.fromArray(data, getIdFromItem);
      const earliestItem = LinkedListHelpers.getEarliest(linkedList);
      const latestItem = LinkedListHelpers.getLatest(linkedList);

      if (latestItem) {
        latestItem.laterId = CursorState.FINISHED;
      } else {
        return LinkedListHelpers.empty();
      }

      if (end && earliestItem) {
        earliestItem.earlierId = CursorState.FINISHED;
      }
      return linkedList;
    },
    [getIdFromItem],
  );

  const createAroundLinkedList = useCallback(
    (data: {
      before: T[];
      item: T;
      after: T[];
      ends: {
        before: boolean;
        after: boolean;
      };
    }) => {
      const allData = [...data.before, data.item, ...data.after];
      const linkedList = LinkedListHelpers.fromArray(allData, getIdFromItem);
      const earliestItem = LinkedListHelpers.getEarliest(linkedList);
      const latestItem = LinkedListHelpers.getLatest(linkedList);

      if (data.ends.before && earliestItem) {
        earliestItem.earlierId = CursorState.FINISHED;
      }

      if (data.ends.after && latestItem) {
        latestItem.laterId = CursorState.FINISHED;
      }

      return linkedList;
    },
    [getIdFromItem],
  );

  return {
    createEarlierLinkedList,
    createLaterLinkedList,
    createBottomLinkedList,
    createAroundLinkedList,
  };
}
