import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
  tableContainer: {
    flex: 1,
    display: "flex",
  },
  headerAdditionalColumns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    height: "100%",
  },
});
