import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { Pill } from "@kraaft/shared/components/pill";
import { Toggle } from "@kraaft/shared/components/toggle";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import {
  AnyReportTemplate,
  ReportTemplateFormat,
} from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import {
  getReportTemplateIcon,
  getReportTemplateName,
} from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.utils";
import { AnyUnexplained } from "@kraaft/shared/core/types";
import { conditionalArray } from "@kraaft/shared/core/utils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useAlertError } from "@kraaft/shared/core/utils/useApi.hooks";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Color, ColorStyle, Icon } from "@kraaft/ui";
import { LegacyContextMenuButton } from "@kraaft/web/src/components/contextMenuButton";
import { DraggableLineDecoration } from "@kraaft/web/src/components/draggableLineDecoration";
import {
  EditReportTemplateFileDialog,
  RemoveReportTemplateDialog,
  RenameReportTemplateDialog,
} from "@kraaft/web/src/components/kDialog/customs";

import KraaftLogoAsset from "./assets/kraaft_logo.png";

import { useStyles } from "./orderableReportTemplateItem.styles";

export type Prop<
  T extends (...args: AnyUnexplained) => AnyUnexplained,
  P extends keyof Parameters<T>[0],
> = {
  [K in P]: Parameters<T>[0][K];
};

function useLoadingCallback<T extends AnyUnexplained[]>(
  cb?: (...args: T) => Promise<AnyUnexplained>,
  onError?: (error: Error, ...args: T) => void,
) {
  const [isLoading, start, end] = useBooleanState(false);

  const loadingCb = useCallback(
    async (...args: T) => {
      if (!cb) {
        return;
      }
      start();
      try {
        return await cb(...args);
      } catch (error) {
        onError?.(error, ...args);
      } finally {
        end();
      }
    },
    [cb, start, end, onError],
  );

  return [isLoading, loadingCb] as const;
}

type OrderableReportTemplateItemProps = {
  template: AnyReportTemplate;
  onToggle?: (reportTemplateId: string, enabled: boolean) => void;
  onDownload?: () => Promise<void>;
} & Prop<typeof EditReportTemplateFileDialog, "onReplaceFile"> &
  Prop<typeof RenameReportTemplateDialog, "onRename"> &
  Prop<typeof RemoveReportTemplateDialog, "onRemove">;

export const OrderableReportTemplateItem = (
  props: OrderableReportTemplateItemProps,
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const reportTemplate = props.template;
  const onToggle = props.onToggle;

  const [
    isRenameReportTemplateDialogOpen,
    openRenameReportTemplateDialog,
    closeRenameReportTemplateDialog,
  ] = useBooleanState();

  const [
    isEditReportTemplateFileDialogOpen,
    openEditReportTemplateFileDialog,
    closeEditReportTemplateFileDialog,
  ] = useBooleanState();

  const [
    isRemoveReportTemplateFileDialogOpen,
    openRemoveReportTemplateFileDialog,
    closeRemoveReportTemplateFileDialog,
  ] = useBooleanState();

  const [isLoadingDefaultTemplate, downloadDefaultTemplate] =
    useLoadingCallback(
      props.onDownload,
      useAlertError("reportTemplate.errorGeneratingDefaultReport"),
    );

  const handleEnabledChanged = useCallback(
    (newEnabled: boolean) => onToggle?.(reportTemplate.id, newEnabled),
    [reportTemplate.id, onToggle],
  );

  const handleDownloadReportTemplateFile = useCallback(() => {
    if ("schemaId" in reportTemplate) {
      trackEvent({
        eventName: "Download Report Template",
        schema_id: reportTemplate.schemaId as AnyUnexplained,
        report_id: reportTemplate.id,
        report_name: getReportTemplateName(reportTemplate),
      });
    }
    if (reportTemplate.variant === "custom") {
      fileSaver
        .download(reportTemplate.file.downloadUrl, reportTemplate.file.filename)
        .catch(console.error);
    } else {
      downloadDefaultTemplate().catch(console.error);
    }
  }, [downloadDefaultTemplate, reportTemplate]);

  const actions = useMemo<ActionSheetItem[]>(
    () => [
      ...conditionalArray<ActionSheetItem>(
        [
          {
            label: t("reportTemplate.rename"),
            icon: "edit-02",
            onPress: openRenameReportTemplateDialog,
          },
        ],
        reportTemplate.variant === "custom",
      ),
      {
        label: t("download"),
        icon: "download-01",
        disabled: isLoadingDefaultTemplate,
        onPress: handleDownloadReportTemplateFile,
      },
      ...conditionalArray<ActionSheetItem>(
        [
          {
            label: t("replace"),
            icon: "upload-cloud-01",
            onPress: openEditReportTemplateFileDialog,
          },
          {
            label: t("delete"),
            style: "destructive",
            icon: "trash-01",
            onPress: openRemoveReportTemplateFileDialog,
          },
        ],
        reportTemplate.variant === "custom",
      ),
    ],
    [
      t,
      openRenameReportTemplateDialog,
      reportTemplate.variant,
      isLoadingDefaultTemplate,
      handleDownloadReportTemplateFile,
      openEditReportTemplateFileDialog,
      openRemoveReportTemplateFileDialog,
    ],
  );

  return (
    <>
      <DraggableLineDecoration>
        <div className={classes.row}>
          <div className={classes.iconContainer}>
            {reportTemplate.variant === "default" ? (
              <img
                className={classes.logo}
                src={KraaftLogoAsset}
                alt="kraaft logo"
              />
            ) : (
              <Icon
                name={getReportTemplateIcon(reportTemplate.format)}
                color={ColorStyle.FONT_HIGH_EMPHASIS}
              />
            )}
          </div>
          <span className={classes.name}>
            {getReportTemplateName(reportTemplate)}
          </span>
          <span className={classes.format}>
            <FormatPill format={reportTemplate.format} />
          </span>
          <span className={classes.actionsContainer}>
            <Toggle
              value={reportTemplate.enabled}
              setValue={handleEnabledChanged}
            />
            <LegacyContextMenuButton actions={actions} vertical />
          </span>
        </div>
      </DraggableLineDecoration>
      {reportTemplate.variant === "custom" && (
        <>
          <RenameReportTemplateDialog
            open={isRenameReportTemplateDialogOpen}
            onClose={closeRenameReportTemplateDialog}
            reportTemplate={reportTemplate}
            onRename={props.onRename}
          />
          <EditReportTemplateFileDialog
            open={isEditReportTemplateFileDialogOpen}
            onClose={closeEditReportTemplateFileDialog}
            reportTemplate={reportTemplate}
            onReplaceFile={props.onReplaceFile}
          />
          <RemoveReportTemplateDialog
            open={isRemoveReportTemplateFileDialogOpen}
            onClose={closeRemoveReportTemplateFileDialog}
            reportTemplate={reportTemplate}
            onRemove={props.onRemove}
          />
        </>
      )}
    </>
  );
};

const FormatPill = (props: { format: ReportTemplateFormat }) => {
  const content = props.format === "xlsx" ? "Excel" : "Word";
  const color =
    props.format === "xlsx" ? Color.GREEN_ACTIVE : Color.BLUE_KRAAFT;
  return <Pill content={content} color={color} size="small" />;
};
