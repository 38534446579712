import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { Icon } from "@kraaft/ui";

import { styles } from "./userBadge.styles";

interface Props {
  userRole: UserPoolRole;
  badgeColor?: string;
}

const UserBadge = (props: Props) => {
  const { userRole, badgeColor } = props;

  if (userRole === UserPoolRole.EXTERNAL) {
    return (
      <Icon
        name="shake-hands"
        size="MINI"
        color={badgeColor ?? "ORANGE_BASIC"}
        style={styles.icon}
      />
    );
  }

  return null;
};

export { UserBadge };
