import i18n from "i18next";
import { put, takeEvery } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";

import { OfflineErrorAction } from "./offlineErrorAction";

export function* offlineBundleSagas() {
  yield* takeEvery(OfflineErrorAction, convertToToastError);
}

function* convertToToastError() {
  yield* put(
    showError({ title: i18n.t("error"), message: i18n.t("errorServer") }),
  );
}
