import { makeStyles } from "@mui/styles";

import { markerSize } from "@kraaft/shared/components/geolocation/baseMediaMarker/baseMediaMarkerConfig";

interface MakeStylesProps {
  bodyColor: string;
  strokeColor: string;
}

export const useStyles = makeStyles({
  markerContainer: {
    position: "relative",
    transition: "all .2s ease-in-out",
    transformOrigin: "bottom",
    cursor: "pointer",
  },
  marker: ({ bodyColor, strokeColor }: MakeStylesProps) => ({
    "& .body": {
      fill: bodyColor,
    },
    "& .stroke": {
      stroke: strokeColor,
    },
  }),
  markerContent: {
    position: "absolute",
    height: markerSize.width,
    width: markerSize.width,
    justifyContent: "center",
    display: "flex",
    boxSizing: "border-box",
    padding: 4,
  },
  innerContent: {
    height: "100%",
    width: "100%",
    display: "flex",
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});
