import { useState } from "react";

import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";

export const DEFAULT_FILTER: CompositeCondition = {
  type: "composite",
  operator: "and",
  conditions: [{ type: "draft" }],
};

export function useFilterState(initialFilters: CompositeCondition | undefined) {
  const [filters, setFilters] = useState<CompositeCondition>(
    initialFilters ?? DEFAULT_FILTER,
  );

  return [filters, setFilters] as const;
}
