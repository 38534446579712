import { StyleSheet } from "react-native";
import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "& > :first-child": {
      marginRight: Spacing.S8,
    },
  },
});

export const nativeStyle = StyleSheet.create({
  input: {
    flexGrow: 1,
  },
});
