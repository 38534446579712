import { StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { Color, ColorStyle, Radius, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  root: {
    backgroundColor: Color.WHITE,
    borderRadius: Radius.SMALL,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: Spacing.S16,
  },
  title: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontWeight: "bold",
    fontSize: 18,
    fontFamily: "Apercu",
  },
  right: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    marginRight: Spacing.S8,
  },
  content: {
    position: "relative",
    ...(isNative() ? {} : { transition: "all 250ms" }),
    overflow: "hidden",
  },
  innerContent: {
    // Absolute is needed since if parent has 0 height
    // in native it returns that this element has 0 height
    // Absolute positionning fixes it
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    paddingHorizontal: Spacing.S16,
    paddingBottom: Spacing.S16,
  },
});
