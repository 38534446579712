import { StyleSheet } from "react-native";

import { FontSize } from "@kraaft/ui";

export const styles = StyleSheet.create({
  text: {
    fontSize: FontSize.MEDIUM,
    textAlign: "center",
  },
  // This is needed to align the schema icon with the first line of the text
  // Otherwise if there are emojis in the schema name, the line height is bigger
  // and the schema icon not aligned anymore
  forceLineHeight: {
    lineHeight: FontSize.MEDIUM + 4,
  },
});
