import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { EntryLayout } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayout";
import { EntryLayoutWithCreationDetails } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayoutWithDetails/entryLayoutWithCreationDetails";
import { selectModularFolderHistoryEntrySchemaColumnWithPaths } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.selectors";
import { ModularFolderCreated } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.state";
import { selectUsername } from "@kraaft/shared/core/modules/user/userSelectors";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

interface CreatedEntryProps {
  entry: ModularFolderCreated;
}

export const CreatedEntry = ({ entry }: CreatedEntryProps) => {
  const { t } = useTranslation();

  const initiatorName = useSelector(selectUsername(entry.by));
  const schemaColumnWithPaths = useSelector(
    selectModularFolderHistoryEntrySchemaColumnWithPaths(entry.modularFolderId),
  );

  const handleOpenDetails = useCallback(() => {
    trackEvent({
      eventName: "Click On See Modular Folder History Entry Creation Details",
      entry_id: entry.id,
      record_id: entry.modularFolderId,
    });
  }, [entry.id, entry.modularFolderId]);

  return (
    <EntryLayoutWithCreationDetails
      date={entry.at}
      i18nKey="history.entryDetailsLabel.created"
      i18nValues={{ initiatorName }}
      properties={entry.properties}
      schemaColumnWithPaths={schemaColumnWithPaths}
      onOpenDetails={handleOpenDetails}
    >
      <EntryLayout
        date={entry.at}
        iconName="file-plus-03"
        iconColor="GREEN_LAGOON"
        i18nKey="history.entryLabel.created"
        i18nValues={{ initiatorName }}
        errorMessage={
          Object.keys(entry.missingSchemaColumns).length > 0
            ? t("history.missingSchemaColumns")
            : undefined
        }
      />
    </EntryLayoutWithCreationDetails>
  );
};
