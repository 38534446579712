import { useCallback } from "react";

import { ActionCard } from "@kraaft/shared/components/actionCard";
import {
  SlidePanel,
  SlidePanelProps,
} from "@kraaft/shared/components/slidePanel";
import { IconName } from "@kraaft/ui";

export type GroupButtonWithPanelProps<T extends string> = Pick<
  SlidePanelProps,
  "name"
> & {
  children: React.ReactNode;
  buttonIcon: IconName;
  buttonText: string;
  buttonDescription?: string;
  portalHostname?: string;
  panelIdentifier: T;
  panelTitle?: string;
  openedPanel: T | undefined;
  setOpenedPanel: (panelIdentifier: T | undefined) => void;
  disablePanelAnimation?: boolean;
  id?: string;
  backgroundColor?: string;
};

const GroupButtonWithPanel = <T extends string>({
  name,
  children,
  buttonIcon,
  buttonText,
  buttonDescription,
  portalHostname,
  panelIdentifier,
  panelTitle,
  openedPanel,
  setOpenedPanel,
  disablePanelAnimation,
  id,
  backgroundColor,
}: GroupButtonWithPanelProps<T>) => {
  const handlePress = useCallback(() => {
    setOpenedPanel(panelIdentifier);
  }, [panelIdentifier, setOpenedPanel]);

  const closePanel = useCallback(() => {
    setOpenedPanel(undefined);
  }, [setOpenedPanel]);

  return (
    <>
      <ActionCard
        id={id}
        text={buttonText}
        iconName={buttonIcon}
        description={buttonDescription}
        action={handlePress}
        showBracket
        backgroundColor={backgroundColor}
      />
      <SlidePanel
        name={name}
        disablePanelAnimation={disablePanelAnimation}
        portalHostname={portalHostname}
        title={panelTitle}
        open={openedPanel === panelIdentifier}
        onClose={closePanel}
      >
        {children}
      </SlidePanel>
    </>
  );
};

export { GroupButtonWithPanel };
