import React, { Fragment, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";
import { Text as RNText } from "react-native/Libraries/Text/Text";

import { dismissNativeKeyboard } from "@kraaft/helper-functions";
import { useBooleanState } from "@kraaft/helper-hooks";
import {
  EveryoneMentionInputPartition,
  MentionInputPartition,
} from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { getUsernameFromUserId } from "@kraaft/shared/components/textInputWithMentions/useMentionAwareTextState";
import { UserContactInfoSheet } from "@kraaft/shared/components/userContactInfoSheet/userContactInfoSheet";
import { useGetOrLoadUsers } from "@kraaft/shared/core/modules/user/useGetOrLoadUsers";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Color, Text } from "@kraaft/ui";

import { PartitionTextViewProps } from "./partitionTextView";

export type BasicMentionPartitionTextViewProps = Pick<
  PartitionTextViewProps,
  "textStyle" | "black"
> & {
  partition: MentionInputPartition | EveryoneMentionInputPartition;
  onPress?: () => void;
};

export const BasicMentionPartitionTextView = React.forwardRef<
  RNText,
  BasicMentionPartitionTextViewProps
>(({ partition, textStyle, black, onPress }, forwardedRed) => {
  const { t } = useTranslation();

  return (
    <Text
      ref={forwardedRed}
      onPress={onPress}
      style={[
        textStyle,
        black ? styles.theirMessageMention : styles.myMessageMention,
      ]}
    >
      @
      <Text
        style={[
          styles.mention,
          textStyle,
          black ? styles.theirMessageMention : styles.myMessageMention,
        ]}
      >
        {partition.type === "mention"
          ? getUsernameFromUserId(partition.userId)
          : t("mentions.atEveryone")}
      </Text>
    </Text>
  );
});

type MentionPartitionTextViewProps = Pick<
  PartitionTextViewProps,
  "textStyle" | "black"
> & {
  partition: MentionInputPartition;
};

export const ContactInfoMentionPartitionTextView = ({
  partition,
  textStyle,
  black,
}: MentionPartitionTextViewProps) => {
  const mentionRef = useRef(null);
  const getOrLoadUsers = useGetOrLoadUsers();

  useEffect(() => {
    // By calling getUsers, we trigger a call to get deletedUser information (if needed)
    getOrLoadUsers([partition.userId]);
  }, [getOrLoadUsers, partition.userId]);

  const [isSheetOpen, openSheet, closeSheet] = useBooleanState(false);
  const handleOnPress = useCallback(() => {
    dismissNativeKeyboard();
    trackEvent({
      eventName: "Click On User Name",
      from: "mention",
    });
    openSheet();
  }, [openSheet]);

  return (
    <Fragment>
      <BasicMentionPartitionTextView
        partition={partition}
        textStyle={textStyle}
        black={black}
        onPress={handleOnPress}
        ref={mentionRef}
      />
      <UserContactInfoSheet
        anchor={mentionRef}
        open={isSheetOpen}
        onClose={closeSheet}
        userId={partition.userId}
      />
    </Fragment>
  );
};

const styles = StyleSheet.create({
  mention: {
    textDecorationLine: "underline",
    fontWeight: "bold",
  },
  myMessageMention: {
    color: Color.WHITE,
  },
  theirMessageMention: {
    color: Color.BLUE_KRAAFT,
  },
});
