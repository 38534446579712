import { makeStyles } from "@mui/styles";

import { ColorStyle } from "@kraaft/ui";

export const useStyles = makeStyles(() => ({
  tableSortLabelRoot: {
    "&.Mui-tableSortLabelActive": {
      color: ColorStyle.ACTION_CTA,
      fontWeight: "500",
    },
  },
  tableSortLabelActive: {},
}));
