import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  text: {
    cursor: "text",
    marginLeft: Spacing.S8,
  },
});
