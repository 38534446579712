import { findKey } from "lodash/fp";

import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { DATADOG_VERBOSE } from "@kraaft/shared/constants/global";
import { DatadogSDK } from "@kraaft/shared/core/services/datadog/sdk";
import {
  AbstractDatadogSDK,
  ActionType,
  DynamicDatadogConfig,
  EnvironmentName,
  ErrorParams,
  SpanID,
  SpanOperation,
  ViewParams,
} from "@kraaft/shared/core/services/datadog/sdk/types";

/** How long will the log sending be debounced for */
export const DEBOUNCE_TIMER = 10000;

export type LogType = "log" | "action" | "view";

export type LogParams = {
  type?: LogType;
  actionType?: ActionType;
  message: string;
  operation?: SpanOperation;
  context?: Record<string, unknown>;
  spanId?: SpanID;
  isDebounced?: boolean;
};

/** What we need to handle an error */
export type LogErrorParams = ErrorParams & {
  operation?: SpanOperation;
};

export abstract class DatadogService {
  private static instance: AbstractDatadogSDK | undefined;
  private static debounceLogs: LogParams[] = [];
  private static debounceLogId: ReturnType<typeof setTimeout>;
  private static lastLogView: ViewParams;
  private static ongoingTraces = {} as Record<
    SpanOperation,
    boolean | undefined
  >;
  private static associatedSpanIds = {} as Record<SpanOperation, string>;

  /**
   * Inits the SDK at start
   * @param envName the environment name associated to logs
   */
  public static async init(config: DynamicDatadogConfig) {
    if (DatadogService.instance) {
      return DatadogService.debug("Prevented duplicated init");
    }

    if (!getEnvironment().DATADOG_ENABLED) {
      return DatadogService.debug(
        `Monitoring is disabled for env: ${
          getEnvironment().DEPLOYMENT_ENVIRONMENT
        }.\nEnable DATADOG_ENABLED in config file to start logging.`,
      );
    }

    DatadogService.debug("Init, config=", config);
    DatadogService.resetSpanCaches();

    DatadogService.instance = new DatadogSDK();

    await DatadogService.instance.init(
      getEnvironment().DEPLOYMENT_ENVIRONMENT as EnvironmentName,
      config,
    );
  }

  /**
   * Declare a user to DD
   * @param user the user associated to logs
   */
  public static async setUser(user: { id: string } | null) {
    DatadogService.debug("[User]", "Set user", user);

    if (!DatadogService.instance) {
      return;
    }

    await DatadogService.instance.setUser(user);
  }

  /**
   * Declare a view start to DD
   * @param view the view data
   */
  public static async startView(view: ViewParams) {
    DatadogService.debug("[StartView]", view);

    if (!DatadogService.instance) {
      return;
    }

    const lastLogView = DatadogService.lastLogView;

    // Prevent double view logs
    if (view.name === lastLogView?.name) {
      return;
    }

    DatadogService.lastLogView = view;

    try {
      await DatadogService.instance.startView(view);
    } catch (error) {
      DatadogService.debug("Failed to complete start view logging", {
        context: {
          view,
          cause: error,
        },
        severity: "debug",
      });
    }
  }

  /**
   * Declare a view stop to DD
   * @param view the view data
   */
  public static async stopView(view: ViewParams) {
    DatadogService.debug("[StopView]", view);

    if (!DatadogService.instance) {
      return;
    }

    try {
      await DatadogService.instance.stopView(view);
    } catch (error) {
      DatadogService.debug("Failed to complete stop view logging", {
        context: {
          view,
          cause: error,
        },
        severity: "debug",
      });
    }
  }

  /**
   * Declare a span start to DD
   * @param operation the span operation
   * @param context optionnal extra contextual data
   * @returns {string} the created span's id or nothing when it fails
   */
  public static async startSpan(
    operation: SpanOperation,
    context?: Record<string, unknown>,
  ): Promise<string | undefined> {
    DatadogService.debug("[StartSpan]", operation, context);

    if (!DatadogService.instance) {
      return;
    }

    try {
      const id = await DatadogService.instance.startSpan({
        operation,
        context,
      });

      DatadogService.ongoingTraces[operation] = true;
      DatadogService.associatedSpanIds[operation] = id;

      return id;
    } catch (e) {
      DatadogService.debug("Failed to start span", {
        context: {
          operation,
          context,
          cause: e,
        },
        severity: "debug",
      });
    }
  }

  /**
   * Declare a span ending on DD
   * @param id the span ID
   * @param context optionnal extra contextual data
   */
  public static async endSpan(id: SpanID, context?: Record<string, unknown>) {
    DatadogService.debug("[EndSpan]", id, context);

    if (!DatadogService.instance) {
      return;
    }

    const operation = findKey(
      (value) => value === id,
      DatadogService.associatedSpanIds,
    );

    try {
      await DatadogService.instance.stopSpan({ id, context });

      if (operation) {
        DatadogService.resetSpanCaches(operation);
      }
      return;
    } catch (e) {
      DatadogService.debug("Failed to end span", {
        context: {
          id,
          operation,
          context,
          cause: e,
        },
        severity: "debug",
      });
    }
  }

  /**
   * Resets span cache
   * @param operation optional operation to clear
   */
  private static resetSpanCaches(operation?: SpanOperation) {
    if (!operation) {
      DatadogService.ongoingTraces = {} as typeof DatadogService.ongoingTraces;
      DatadogService.associatedSpanIds =
        {} as typeof DatadogService.associatedSpanIds;
      return;
    }

    DatadogService.ongoingTraces[operation] = false;
    DatadogService.associatedSpanIds[operation] = "";
  }

  /**
   * Log infos to DD
   * @warning Only use when you're sure the params are not too big, uncaughtable fatal errors might pop when the said params are too heavy
   * Public interface for logging anything from anywhere across the app
   */
  public static async log(params: LogParams) {
    const { message, isDebounced } = params;

    DatadogService.debug(message, params);

    if (isDebounced) {
      DatadogService.debounceLogs.push(params);
      DatadogService.debounceLogId &&
        clearTimeout(DatadogService.debounceLogId);

      DatadogService.debounceLogId = setTimeout(async () => {
        if (DatadogService.debounceLogs.length) {
          await DatadogService._log({
            message: "Debounced logs",
            context: {
              logs: DatadogService.debounceLogs,
            },
          });
        }
      }, DEBOUNCE_TIMER);
    } else {
      return DatadogService._log(params);
    }

    return;
  }

  /**
   * Actual log sending through SDK
   * Private method only used from within the class wrapper
   */
  private static async _log({
    operation,
    context = {},
    message,
    type,
    actionType,
  }: LogParams) {
    if (!DatadogService.instance) {
      return;
    }

    if (operation && !context.spanId) {
      context.spanId = DatadogService.associatedSpanIds[operation];
    }

    try {
      if (type === "action") {
        await DatadogService.instance.addAction({
          type: actionType,
          message,
          context,
        });
      } else {
        await DatadogService.instance.logInfo({ message, context });
      }
    } catch (e) {
      DatadogService.debug("Failed to log params", {
        context: {
          operation,
          context,
          message,
          type,
          actionType,
        },
        severity: "debug",
      });
    }
  }

  /**
   * Report an error to Datadog services
   * @param error contextual error
   * @param context optionnal extra contextual data
   * @param severity the error severity (default: 'error')
   * @param operation optional span operation
   */
  public static async logError({
    error,
    context: _context,
    severity = "error",
    operation,
  }: LogErrorParams) {
    if (!DatadogService.instance || !error?.message) {
      return;
    }

    const context: NonNullable<LogErrorParams["context"]> = {
      ..._context,
      severity,
    };

    if (operation && !context.spanId) {
      context.spanId = DatadogService.associatedSpanIds[operation];
    }

    try {
      if (["critical", "error"].includes(severity)) {
        await DatadogService.instance.addError({ error, context });
      }

      await DatadogService.instance.logError({ error, severity, context });
    } catch (e) {
      DatadogService.debug("failed to log error", {
        error,
        context,
        severity,
        operation,
      });
    }
  }

  /**
   * Debugger, used as a milestone during the service's inner processes
   */
  private static debug(...args: unknown[]) {
    if (DATADOG_VERBOSE) {
      console.log(...args);
    }
  }
}
