import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ListEditor,
  ListEditorItem,
} from "@kraaft/shared/components/modular/details/editors/listEditor";
import { EditorProps } from "@kraaft/shared/components/modular/details/editors/types";
import { EMPTY_LOCKED_RECORD_PLACEHOLDER } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { IconName } from "@kraaft/ui";

type Props = EditorProps<KColumnType.selectSingle | KColumnType.selectMultiple>;

const getSelectPillIcon = (
  column: KSchemaColumn<KColumnType.selectSingle | KColumnType.selectMultiple>,
  color: string | undefined,
): IconName | undefined => {
  const hasColor = column.allowColor && color !== undefined;
  if (KSchemaUtils.isMultiSelect(column)) {
    return hasColor ? "tag-01-fill" : undefined;
  }
  return hasColor ? "triangle-down-fill" : undefined;
};

const SelectEditor = (props: Props) => {
  const { column } = props;
  const { t } = useTranslation();

  const items = useMemo<ListEditorItem[]>(
    () =>
      KSchemaUtils.orderOptions(column).map(([key, option]) => ({
        identifier: key,
        label: option.label,
        value: {
          pillIcon: getSelectPillIcon(column, option.color),
          pillColor: option.color,
        },
      })),
    [column],
  );

  const placeholder = !props.editor.sectionLockInfo.isLocked
    ? t("modularity.select")
    : EMPTY_LOCKED_RECORD_PLACEHOLDER;

  return (
    <ListEditor
      searchPlaceholder={t("search")}
      placeholder={placeholder}
      iconName={KSchemaUtils.getIcon(props.column)}
      items={items}
      {...props}
    />
  );
};

export { SelectEditor };
