import { useStyles } from "./directoryTreeIndentation.styles";

interface DirectoryTreeIndentationProps {
  level: number;
}

const DirectoryTreeIndentation = (props: DirectoryTreeIndentationProps) => {
  const { level } = props;
  const classes = useStyles({ level });

  return <div className={classes.directoryIndentation} />;
};

export { DirectoryTreeIndentation };
