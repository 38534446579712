import { StyleSheet } from "react-native";

import { Spacing, webOnlyStyle } from "@kraaft/ui";

export const styles = StyleSheet.create({
  pill: {
    marginRight: Spacing.S4,
    flexShrink: 1,
    ...webOnlyStyle({ wordBreak: "break-all" }),
  },
  morePill: {
    flexShrink: 0,
  },
});
