import { call } from "typed-redux-saga/macro";

import { ReportTemplateStateActions } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.actions";
import { PoolSchemaReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { Api } from "@kraaft/shared/core/services/api";
import { RootState } from "@kraaft/shared/core/store";
import {
  createOperation,
  createOperationOnMultiple,
  createOptimisticReduxBundle,
} from "@kraaft/shared/core/utils/optimistic/createReduxBundle";

export const selectOptimisticState = ({
  reportTemplate: { optimistic },
}: RootState) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optimistic as any;

export const selectReportTemplates = ({
  reportTemplate: {
    data: { templates },
  },
}: RootState) => templates;

export const {
  actions: ReportTemplateOptimisticActions,
  delaySnapshot: ReportTemplateDelaySnapshot,
  operations: ReportTemplateOptimisticOperations,
  reducer: ReportTemplateOptimisticReducer,
  saga: ReportTemplateOptimisticSaga,
  selectors: ReportTemplateOptimisticSelector,
} = createOptimisticReduxBundle(
  "Template",
  [
    createOperationOnMultiple<
      PoolSchemaReportTemplate,
      "reorder",
      {
        poolId: string;
        editions: { [reportTemplateId: string]: { index: number } };
      }
    >({
      type: "reorder",
      optimistic: (data, { payload }) => {
        const newIndex = payload.editions[data.id]?.index;

        if (newIndex !== undefined) {
          data.index = newIndex;
        }

        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.reorderReportTemplate, payload);

        return updatedAt;
      },
    }),
    createOperation<
      PoolSchemaReportTemplate,
      "edit",
      {
        editions: { name?: string; enabled?: boolean };
      }
    >({
      type: "edit",
      optimistic: (data, { payload }) => {
        if (data.variant === "custom") {
          if (payload.editions.name !== undefined) {
            data.name = payload.editions.name;
          }
        }
        if (payload.editions.enabled !== undefined) {
          data.enabled = payload.editions.enabled;
        }

        return data;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.editReportTemplate, {
          reportTemplateId: payload.targetId,
          editions: payload.editions,
        });

        return updatedAt;
      },
    }),
    createOperation<PoolSchemaReportTemplate, "delete", unknown>({
      type: "delete",
      optimistic: () => {
        return null;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.deleteReportTemplate, {
          reportTemplateId: payload.targetId,
        });

        return updatedAt;
      },
    }),
  ],
  (schema) => schema.id,
  ReportTemplateStateActions.set,
  selectOptimisticState,
  selectReportTemplates,
);
