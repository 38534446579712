import { PropsWithChildren } from "react";
import { View } from "react-native";

import { useMeshContext } from "@kraaft/helper-hooks";
import { Collapsible } from "@kraaft/shared/components/animated/collapsible";
import { ModularDetailsContext } from "@kraaft/shared/components/modular/details/modularDetails.context";

type ContextAwareSectionCollapsibleProps = {
  sectionKey: string;
};

const ContextAwareSectionCollapsible = ({
  sectionKey,
  children,
}: PropsWithChildren<ContextAwareSectionCollapsibleProps>) => {
  const isExpanded = useMeshContext(
    ModularDetailsContext,
    ({ expandedSectionsKeys }) => !expandedSectionsKeys[sectionKey],
  );

  return (
    <View>
      <Collapsible open={isExpanded}>{children}</Collapsible>
    </View>
  );
};

export { ContextAwareSectionCollapsible };
