import { createAction } from "@reduxjs/toolkit";

import {
  CurrentUser,
  OnboardingState,
  User,
  UserAuth,
  UserMap,
} from "./userState";

interface UserConnectedPayload {
  userAuth: UserAuth;
}

export const UserActions = {
  blockUser: createAction<{ userId: string }>("@user/BLOCK_USER"),
  acknowledgeIntroductionSlidesDone: createAction(
    "@user/ACKNOWLEDGE_INTRODUCTION_SLIDES_DONE",
  ),
  acknowledgePoolOnboarding: createAction("@user/ACKNOWLEDGE_POOL_ONBOARDING"),
  acknowledgeConversationOnboarding: createAction(
    "@user/ACKNOWLEDGE_CONVERSATION_ONBOARDING",
  ),
  userConnectedToFirebase: createAction<UserConnectedPayload>(
    "@user/USER_CONNECTED_TO_FIREBASE",
  ),
  userDisconnectedFromFirebase: createAction(
    "@user/USER_DISCONNECTED_FROM_FIREBASE",
  ),
  disconnectUserCommand: createAction("@user/DISCONNECT_USER_COMMAND"),
  refreshAuth: createAction("@user/REFRESH_AUTH"),
  acknowledgeHomeEmptyState: createAction("@user/ACKNOWLEDGE_HOME_EMPTY_STATE"),
  workspaceCreated: createAction("@user/WORKSPACE_CREATED"),
  loadUser: createAction<string>("@user/LOAD_USER"),
};

export const UserStateActions = {
  addBlockedUsers: createAction<{ userIds: string[] }>(
    "@user/state/ADD_BLOCKED_USERS",
  ),
  loggedUserReceived: createAction<CurrentUser>(
    "@user/state/LOGGED_USER_RECEIVED",
  ),
  setCurrentUserOnboardingState: createAction<OnboardingState | null>(
    "@user/state/SET_CURRENT_USER_ONBOARDING_STATE",
  ),
  updateCurrentUserOnboardingState: createAction<Partial<OnboardingState>>(
    "@user/state/UPDATE_CURRENT_USER_ONBOARDING_STATE",
  ),

  setLoadingPoolUsers: createAction<{ poolId: string; isLoading: boolean }>(
    "@user/state/SET_LOADING_POOL_USERS",
  ),
  poolUsersUpdated: createAction<{
    poolId: string;
    users: Record<string, User>;
  }>("@user/state/POOL_USERS_UPDATED"),
  addUnknownUsers: createAction<UserMap>("@user/state/ADD_UNKNOWN_USERS"),
  setMayShowHomeEmptyState: createAction<boolean>(
    "@user/state/SET_MAY_SHOW_HOME_EMPTY_STATE",
  ),
};
