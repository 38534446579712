import { useCallback, useMemo } from "react";

import { DIRECTORY_MAX_DEPTH } from "@kraaft/shared/core/modules/directory/directory";
import { DirectoryDropper } from "@kraaft/web/src/components/directoryTree/directoryDropper";
import {
  DraggableDirectoryTreeItem,
  HoveredDirectoryTreeInfo,
} from "@kraaft/web/src/components/directoryTree/directoryTree.types";
import { useDirectoryTreeRowDrop } from "@kraaft/web/src/components/directoryTree/useDirectoryTreeRowDrop";

interface OnDirectoryDropperProps {
  level: number;
  parentDirectoryId: string;
  isGroupBeingDragged?: boolean;
}

const OnDirectoryDropper = (props: OnDirectoryDropperProps) => {
  const { level, parentDirectoryId, isGroupBeingDragged } = props;

  const checkItemCanDrop = useCallback(
    (item: DraggableDirectoryTreeItem) =>
      !isGroupBeingDragged &&
      item.directory.id !== parentDirectoryId &&
      level + item.directory.deepestChildDepth <= DIRECTORY_MAX_DEPTH + 1,
    [isGroupBeingDragged, level, parentDirectoryId],
  );

  const hoveredInfo = useMemo<HoveredDirectoryTreeInfo>(
    () => ({
      type: "on",
      parentDirectoryId: parentDirectoryId,
    }),
    [parentDirectoryId],
  );

  const [{ isOver, canDrop }, drop] = useDirectoryTreeRowDrop({
    info: hoveredInfo,
    checkItemCanDrop,
  });

  return (
    <DirectoryDropper
      dropRef={drop}
      level={level}
      isVisible={canDrop}
      isActive={isOver}
    />
  );
};

export { OnDirectoryDropper };
