import { Fragment, ReactNode } from "react";
import { StyleSheet, View } from "react-native";

import { compact } from "@kraaft/helper-functions";
import { ColorStyle } from "@kraaft/ui";

interface SeparatedEditorsProps {
  children: ReactNode[];
  footerComponent?: ReactNode;
}

const SeparatedEditors = ({
  children,
  footerComponent,
}: SeparatedEditorsProps) => {
  return (
    <>
      {compact(children).map((child, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <Fragment key={index}>
          {index !== 0 && <View style={styles.separator} />}
          {child}
        </Fragment>
      ))}
      {footerComponent}
    </>
  );
};

const styles = StyleSheet.create({
  separator: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: ColorStyle.SEPARATOR,
  },
});

export { SeparatedEditors };
