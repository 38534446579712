import { call, put, spawn, takeEvery } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { setLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import {
  connectPoolToExternal,
  disconnectPoolToExternal,
  toggleInboundEmail,
} from "@kraaft/web/src/core/modules/poolAdmin/poolAdminActions";
import { poolAdminSubscription } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminSubscription";

export function* poolAdminSagas() {
  yield* spawn(poolAdminSubscription.saga);

  yield* takeEvery(connectPoolToExternal, connectPoolToExternalSaga);
  yield* takeEvery(disconnectPoolToExternal, disconnectPoolToExternalSaga);
  yield* takeEvery(toggleInboundEmail, toggleInboundEmailSaga);
}

function* connectPoolToExternalSaga(
  action: ReturnType<typeof connectPoolToExternal>,
) {
  const {
    payload: { loaderId, poolId, sharepointUrl },
  } = action;

  if (sharepointUrl) {
    yield* put(setLoader({ id: loaderId, status: LoaderStatus.LOADING }));
    try {
      const {
        data: { error },
      } = yield* call(Api.synchronizePoolWithSharepoint, {
        poolId,
        sharepointUrl,
      });

      if (error) {
        throw new Error(error);
      }
      yield* put(setLoader({ id: loaderId, status: LoaderStatus.SUCCESS }));
    } catch (error) {
      yield* put(
        setLoader({ id: loaderId, status: LoaderStatus.FAILURE, error }),
      );
    }
  }
}

function* disconnectPoolToExternalSaga(
  action: ReturnType<typeof disconnectPoolToExternal>,
) {
  const {
    payload: { poolId },
  } = action;

  try {
    yield* call(Api.stopSynchronizePoolWithSharepoint, {
      poolId,
    });
  } catch (err) {
    yield* put(
      showError({
        title: i18n.t("sharepointErrorTitle"),
        message: i18n.t("unknown"),
      }),
    );
  }
}

function* toggleInboundEmailSaga(
  action: ReturnType<typeof toggleInboundEmail>,
) {
  const {
    payload: { poolId, enabled, loaderId, inboundEmailId },
  } = action;

  try {
    yield* put(setLoader({ id: loaderId, status: LoaderStatus.LOADING }));

    yield* call(Api.toggleInboundEmail, {
      poolId,
      enabled,
      inboundEmailId,
    });

    yield* put(setLoader({ id: loaderId, status: LoaderStatus.SUCCESS }));
  } catch (error) {
    yield* put(
      setLoader({ id: loaderId, status: LoaderStatus.FAILURE, error }),
    );
  }
}
