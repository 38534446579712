import { useCallback, useEffect, useRef, useState } from "react";

import { numberFormatter } from "@kraaft/shared/core/services/numberFormatter";

interface NumberInput {
  amount: number | null;
  displayableAmount: string;
  inputChangeHandler: (value: string) => void;
  inputFocusHandler: () => void;
  inputBlurHandler: () => void;
}

export function reformatNumber(value: string) {
  // remove all non-numeric characters except the first dash
  let newValue = value
    .substring(0, 15)
    .replace(",", ".")
    .replace(/[^-\d.]|(?!^)-/g, "");

  // remove overnumerous dots
  const dotIndex = newValue.indexOf(".");
  if (dotIndex !== -1) {
    newValue =
      newValue.substring(0, dotIndex + 1) +
      newValue.substring(dotIndex + 1).replace(".", "");
  }

  return newValue;
}

export const formatNumber = (
  amount: number | null,
  symbol: string | undefined,
  isCurrency = false,
) => {
  // biome-ignore lint/suspicious/noGlobalIsNan: <explanation>
  if (amount !== null && !isNaN(amount)) {
    if (isCurrency && symbol) {
      return numberFormatter.formatCurrency(amount, symbol);
    }
    return numberFormatter.formatNumber(amount, symbol);
  }
  return "";
};

const useNumberInput = (props: {
  defaultValue?: number | null;
  symbol?: string;
  isCurrency?: boolean;
  autoFocus?: boolean;
}): NumberInput => {
  const { defaultValue = null, symbol, autoFocus, isCurrency } = props;

  const amount = useRef<number | null>(defaultValue);
  const [isFocused, setFocused] = useState(autoFocus);

  useEffect(() => {
    if (isFocused) {
      return;
    }
    amount.current = defaultValue ?? null;
    setDisplayableAmount(formatNumber(defaultValue, symbol, isCurrency));
  }, [defaultValue, isCurrency, isFocused, symbol]);

  const [displayableAmount, setDisplayableAmount] = useState(
    formatNumber(defaultValue, symbol, isCurrency),
  );

  const inputChangeHandler = useCallback((value: string) => {
    const newValue = reformatNumber(value);
    const valueFloat = Number.parseFloat(newValue);

    // biome-ignore lint/suspicious/noGlobalIsNan: <explanation>
    if (newValue === "" || isNaN(valueFloat)) {
      amount.current = null;
    } else {
      amount.current = valueFloat;
    }
    setDisplayableAmount(newValue);
  }, []);

  const inputFocusHandler = useCallback(() => {
    setFocused(true);
    if (amount.current !== null) {
      setDisplayableAmount(amount.current.toString());
    } else {
      setDisplayableAmount("");
    }
  }, []);

  const inputBlurHandler = useCallback(() => {
    setFocused(false);
    setDisplayableAmount(formatNumber(amount.current, symbol, isCurrency));
  }, [isCurrency, symbol]);

  return {
    amount: amount.current,
    displayableAmount,
    inputChangeHandler,
    inputFocusHandler,
    inputBlurHandler,
  };
};

export { useNumberInput };
