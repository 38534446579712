import { makeStyles } from "@mui/styles";

import { Color, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  option: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: Spacing.S8,
    paddingRight: Spacing.NONE,
    backgroundColor: Color.WHITE,
    borderRadius: Radius.SMALL,
  },
  labelContainer: {
    flexGrow: 1,
    marginRight: Spacing.S8,
  },
});
