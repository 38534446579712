import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { copyTextToClipboard } from "@kraaft/shared/core/utils/clipboard";
import { Color, ColorStyle, Icon } from "@kraaft/ui";

import { useStyles } from "./copyButton.styles";

type UseCopyButtonProps = { value?: string | null };

export const useCopyButton = ({ value }: UseCopyButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [copiedState, setCopiedState] = useState<"not_copied" | "copied">(
    "not_copied",
  );

  useEffect(() => {
    setCopiedState("not_copied");
  }, []);

  const handleCopyToClipboard = useCallback(() => {
    if (value) {
      copyTextToClipboard(value);
    }
    setCopiedState("copied");
  }, [value]);

  const isCopied = copiedState === "copied";
  const color = isCopied ? Color.GREEN_LAGOON : ColorStyle.FONT_LOW_EMPHASIS;

  const icon = useMemo(() => {
    return (
      <span
        className={classes.copiedLabelContainer}
        onClick={handleCopyToClipboard}
      >
        {isCopied ? <span style={{ color: color }}>{t("copied")}</span> : null}
        <Icon
          className={classes.copiedLabelIcon}
          name="copy-06"
          color={color}
          size="MEDIUM"
        />
      </span>
    );
  }, [handleCopyToClipboard, isCopied, color, t, classes]);

  return {
    copyToClipboard: handleCopyToClipboard,
    icon,
  };
};
