import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { MapController } from "@kraaft/shared/components/mapController/mapController";
import {
  useNavigationService,
  useRouteService,
} from "@kraaft/shared/core/services/navigation";
import { Button } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { MapBar } from "@kraaft/web/src/views/messenger/mapBar/mapBar";

interface MapProps {
  roomId: string;
}

export const MapPreview: React.FC<MapProps> = ({ roomId }) => {
  const nav = useNavigationService();
  const route = useRouteService();
  const { t } = useTranslation();

  const goBackToList = useCallback(() => nav.clickOnGoBackToList(), [nav]);

  return (
    <Box bg="WHITE" grow overflow="hidden">
      {route.isRoomsMapOpen() || <MapBar roomId={roomId} />}
      <MapController
        modularFolderMapTopLeftButton={
          <Button
            variant="MAP"
            size="SMALL"
            icon="chevron-left"
            accessibilityLabel={t("goBackToList")}
            text={t("goBackToList")}
            onPress={goBackToList}
          />
        }
      />
    </Box>
  );
};
