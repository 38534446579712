import { Icon } from "@kraaft/ui";

import { useStyles } from "./elementIcon.styles";

interface SectionIconProps {
  color: string;
}

const SectionIcon = ({ color }: SectionIconProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root} style={{ backgroundColor: color }}>
      <Icon name="distribute-spacing-vertical" size="SMALL" color="WHITE" />
    </div>
  );
};

export { SectionIcon };
