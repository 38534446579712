import { MessageWithText } from "@kraaft/shared/core/modules/message/messageState";
import {
  isAudioMessage,
  isImageMessage,
  isVideoMessage,
} from "@kraaft/shared/core/modules/message/messageUtils";
import { type TFunction } from "@kraaft/shared/core/services/i18next";

export function getEditMessageMenuLabel(
  t: TFunction,
  message: MessageWithText,
) {
  if (isAudioMessage(message)) {
    return t("editTranscriptionAction");
  }
  if (isImageMessage(message) || isVideoMessage(message)) {
    return message.attachment.caption
      ? t("messageMenu.editCaption")
      : t("messageMenu.addCaption");
  }
  return t("editMessageAction");
}
