import { StyleSheet } from "react-native";

import { nativeStylesObject } from "@kraaft/shared/components/createRoom/roomDetails/selectRoomShared.styles.native";
import { webOnlyStyle } from "@kraaft/ui";

const styles = StyleSheet.create({
  ...nativeStylesObject,
  buttonContainer: {
    ...nativeStylesObject.buttonContainer,
  },
  list: {
    ...nativeStylesObject.list,
    ...webOnlyStyle({
      overflowY: "auto",
    }),
  },
});

export { styles };
