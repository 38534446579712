import { useCallback, useRef } from "react";

export function useLocked<A extends any[], R>(
  fn: (...args: A) => Promise<R>,
): (...args: A) => Promise<R | undefined> {
  const locked = useRef(false);

  return useCallback<(...args: A) => Promise<R | undefined>>(
    async (...args: A) => {
      if (locked.current) {
        return undefined;
      }
      locked.current = true;
      const result = await fn(...args);
      locked.current = false;
      return result;
    },
    [fn],
  );
}
