import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { keyBy } from "lodash";

import { WorkflowOptimisticReducer } from "@kraaft/shared/core/modules/workflows/workflow.optimistic";
import { WorkflowStateActions } from "@kraaft/shared/core/modules/workflows/workflowActions";
import { WorkflowState } from "@kraaft/shared/core/modules/workflows/workflowState";
import {
  createBlankTemporaryWorkflow,
  createBlankWorkflowActions,
  createBlankWorkflowCondition,
} from "@kraaft/shared/core/modules/workflows/workflowUtils";

const initialState: WorkflowState = {
  workflows: {},
  edit: null,
  currentPoolId: null,
};

const workflowReducers = createReducer(initialState, ({ addCase }) => {
  addCase(WorkflowStateActions.set, (state, { payload }) => {
    state.workflows = keyBy(payload.data, (workflow) => workflow.id);
  });

  addCase(WorkflowStateActions.setCreating, (state, { payload }) => {
    state.edit = {
      ...createBlankTemporaryWorkflow(payload.poolId ?? ""),
      ...payload.baseProps,
    };
  });

  addCase(WorkflowStateActions.setEditingOrCreating, (state, { payload }) => {
    state.edit = payload;
  });

  addCase(WorkflowStateActions.updateEditing, (state, { payload }) => {
    if (!state.edit) {
      return;
    }
    if (payload.schemaId && state.edit.schemaId !== payload.schemaId) {
      state.edit = {
        ...state.edit,
        condition: createBlankWorkflowCondition(),
        actions: createBlankWorkflowActions(),
        ...payload,
      };
    } else {
      state.edit = {
        ...state.edit,
        ...payload,
      };
    }
  });
});

const OptimisticReducer = WorkflowOptimisticReducer as any;
const reducer = combineReducers({
  data: workflowReducers,
  optimistic: OptimisticReducer,
});

export { reducer as workflowReducers };
