import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import {
  selectEditedSchemaTemplate,
  selectSchemaTemplate,
} from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateSelectors";
import {
  EditedSchemaTemplate,
  SchemaTemplate,
} from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";

const schemaToTitles = (
  schema: SchemaTemplate | EditedSchemaTemplate,
): string[] => schema.modifiers.map((m) => m.title.value);

export const selectTaskTemplateTitles = memoize((schemaId: string) => {
  createSelector(selectSchemaTemplate(schemaId), (schema): string[] => {
    if (!schema) {
      return [];
    }
    return schemaToTitles(schema);
  });
});

export const selectEditedTaskTemplateTitles = createSelector(
  [selectEditedSchemaTemplate],
  (schema) => {
    if (!schema) {
      return [];
    }
    return schemaToTitles(schema);
  },
);
