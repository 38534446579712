import { createAction } from "@reduxjs/toolkit";
import { AnyAction, Reducer } from "redux";

export const BatchActions = createAction<{
  actions: Array<AnyAction>;
}>("@meta/BATCH");

export function enableBatching<S>(reducer: Reducer<S>) {
  return function batchingReducer(state: S, action: AnyAction): S {
    if (BatchActions.match(action)) {
      return action.payload.actions.reduce(batchingReducer, state);
    }
    return reducer(state, action);
  };
}
