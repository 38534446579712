import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { PoolsMenuItem } from "@kraaft/shared/components/poolsMenu/poolsMenuItem";
import { Tooltip } from "@kraaft/shared/components/tooltip";
import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import {
  PoolInfo,
  selectUserPools,
} from "@kraaft/shared/core/modules/pool/poolAndRoomSelectors";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { isAtLeastPoolAdmin } from "@kraaft/shared/core/modules/pool/poolUtil";
import {
  selectCurrentUser,
  selectCurrentUserIsSuperadmin,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { Button } from "@kraaft/ui";
import { DuplicateSchemaSheet } from "@kraaft/web/src/views/schemasView/poolSchemaList/orderablePoolSchemaItem/duplicateSchemaPopup/duplicateSchema.sheet";
import { PoolSuggestions } from "@kraaft/web/src/views/settings/backOffice/poolSuggestions";

interface DuplicateSchemaPopupContentProps {
  schemaId: string;
  onClose: () => void;
}

export const DuplicateSchemaPopupContent = ({
  schemaId,
  onClose,
}: DuplicateSchemaPopupContentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const userPools = useSelector(selectUserPools);
  const currentPoolId = useSelector(selectCurrentPoolId);
  const isSuperadmin = useSelector(selectCurrentUserIsSuperadmin);
  const [selectedPoolId, setSelectedPoolId] = useState(
    isSuperadmin ? undefined : currentPoolId,
  );
  const [duplicating, setDuplicating] = useState(false);

  const listItems = useMemo<KSelectableListItem<string, PoolInfo>[]>(
    () =>
      userPools.map((pool) => ({
        identifier: pool.location.poolId,
        label: pool.pool?.name ?? "",
        disabled:
          !isSuperadmin &&
          !isAtLeastPoolAdmin(currentUser?.pools?.[pool.location.poolId]?.role),
        value: pool,
      })),
    [currentUser?.pools, isSuperadmin, userPools],
  );

  const handleSelectPool = useCallback((selected: string[]) => {
    const [first] = selected;
    setSelectedPoolId(first);
  }, []);

  const handleDuplication = useCallbackRealtime(
    async ([_currentPoolId, _selectedPoolId, _listItems]) => {
      if (!_selectedPoolId) {
        return;
      }
      setDuplicating(true);
      try {
        await Api.duplicateSchema({
          sourceSchemaId: schemaId,
          targetPoolId: _selectedPoolId,
        });

        const targetIsCurrentPool = _currentPoolId === _selectedPoolId;
        const selectedPool = _listItems.find(
          (pool) => pool.identifier === _selectedPoolId,
        );

        dispatch(
          showSuccess({
            title: targetIsCurrentPool
              ? t("schemasView.duplicatePopup.duplicateSuccessCurrentPool")
              : t("schemasView.duplicatePopup.duplicateSuccessOtherPool", {
                  targetPoolName: selectedPool?.label,
                }),
          }),
        );
        onClose();
      } catch (e) {
        setDuplicating(false);
        dispatch(
          showError({
            title: t("schemasView.duplicatePopup.errorOccured"),
          }),
        );
      }
    },
    [schemaId, dispatch, onClose, t],
    [currentPoolId, selectedPoolId, listItems],
  );

  const onSuggestionSelected = useCallback(
    (_name: string, id: string | undefined) => {
      if (!id) {
        return;
      }
      setSelectedPoolId(id);
    },
    [],
  );

  return (
    <DuplicateSchemaSheet.Paper>
      <DuplicateSchemaSheet.Header onClose={onClose}>
        {t("schemasView.duplicatePopup.duplicateTo")}
      </DuplicateSchemaSheet.Header>
      <DuplicateSchemaSheet.Content>
        {isSuperadmin ? (
          <PoolSuggestions
            autoFocus
            onPoolSelected={onSuggestionSelected}
            alwaysRenderSuggestions={false}
            focusInputOnSuggestionClick={false}
          />
        ) : (
          <KSelectionList<string, PoolInfo>
            noPadding
            items={listItems}
            isMultiple={false}
            selected={selectedPoolId ? [selectedPoolId] : []}
            setSelected={handleSelectPool}
            renderItem={(item) => {
              if (item.value === undefined) {
                return <></>;
              }

              return (
                <Tooltip
                  title={
                    item.disabled
                      ? t("schemasView.duplicatePopup.permissionDenied")
                      : undefined
                  }
                >
                  <div>
                    <PoolsMenuItem
                      noPadding
                      location={item.value.location}
                      pool={item.value?.pool}
                      notifCount={0}
                    />
                  </div>
                </Tooltip>
              );
            }}
          />
        )}
      </DuplicateSchemaSheet.Content>
      <DuplicateSchemaSheet.Footer>
        <Button
          text={t("schemasView.duplicatePopup.duplicate")}
          onPress={handleDuplication}
          loading={duplicating}
          disabled={!selectedPoolId}
        />
      </DuplicateSchemaSheet.Footer>
    </DuplicateSchemaSheet.Paper>
  );
};
