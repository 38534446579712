import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { GroupActions } from "@kraaft/shared/components/actionGroup/actionGroup";
import { generateAvatarProps } from "@kraaft/shared/components/avatar/generateAvatarProps";
import { buildUserAcronym } from "@kraaft/shared/components/conversation/header/conversationTitleUtils";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import {
  DetailedItemValue,
  KSelectionListItemDetailed,
} from "@kraaft/shared/components/kSelectionList/kSelectionListItemDetailed/kSelectionListItemDetailed";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { UserList } from "@kraaft/shared/components/userList";
import { AvatarContent } from "@kraaft/shared/components/userSelection/avatarList/avatarList.types";
import { SelectableUser } from "@kraaft/shared/core/modules/user/userTypes";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";
import { useKeyboardState } from "@kraaft/shared/core/utils/useKeyboardState";
import { Text } from "@kraaft/ui";

import { styles } from "./memberList.styles";

interface Props {
  currentUserId: undefined | string;
  users: SelectableUser[];
  selectedUsers: SelectableUser[];
  setSelectedUsers: (selectedUsers: SelectableUser[]) => void;
  allowEdition?: boolean;
  allowSearch?: boolean;
  showAvatarList?: boolean;
  freezeCurrentUser?: boolean;
  actions?: GroupActions;
  footer?: React.ReactNode;
}

const MemberList = (props: Props) => {
  const {
    currentUserId,
    users,
    selectedUsers,
    setSelectedUsers,
    allowEdition = false,
    showAvatarList = false,
    freezeCurrentUser = false,
    allowSearch = false,
    actions = [],
    footer,
  } = props;

  const { t } = useTranslation();
  const [isKeyboardOpen] = useKeyboardState();

  const [filter, setFilter] = useState("");

  const frozenUserIds = useMemo(() => {
    if (freezeCurrentUser && currentUserId) {
      return new Set([currentUserId]);
    }
    return new Set([]);
  }, [freezeCurrentUser, currentUserId]);

  const setSelectedUsersById = useCallback(
    (selected: string[]) => {
      if (!allowEdition) {
        return;
      }
      const newSelection: SelectableUser[] = [];
      for (const id of selected) {
        const user = users.find((u) => u.id === id);
        if (user) {
          newSelection.push(user);
        }
      }
      setSelectedUsers(newSelection);
    },
    [allowEdition, setSelectedUsers, users],
  );

  const deselectUser = useCallback(
    (userToDeselect: AvatarContent) => {
      const newSelected = selectedUsers.filter(
        (user) => user.id !== userToDeselect.id,
      );
      setSelectedUsers(newSelected);
    },
    [selectedUsers, setSelectedUsers],
  );

  const usersFiltered = useMemo<SelectableUser[]>(
    () =>
      filter.length > 0
        ? users.filter((user) =>
            normalizeTextForSearch(user.username).includes(
              normalizeTextForSearch(filter),
            ),
          )
        : users,
    [filter, users],
  );

  const userItems = useMemo<KSelectableListItem<string, DetailedItemValue>[]>(
    () =>
      usersFiltered.map((user: SelectableUser) => {
        const isCurrent = user.id === currentUserId;
        const userLabel = isCurrent
          ? `${user.username} (${t("you")})`
          : user.username;

        return {
          label: userLabel,
          identifier: user.id,
          disabled: frozenUserIds.has(user.id),
          value: {
            avatarProps: generateAvatarProps(),
            details: user.isExternalInCurrentPool ? t("external") : "",
          },
        };
      }),
    [currentUserId, t, usersFiltered, frozenUserIds],
  );

  const renderItem = useCallback(
    (item: KSelectableListItem<string, DetailedItemValue>) => (
      <KSelectionListItemDetailed item={item} />
    ),
    [],
  );

  const memberList = useMemo(
    () =>
      userItems.length > 0 ? (
        <View testID="ide2e-select-room-members" style={styles.list}>
          <KSelectionList
            id="ide2e-newTicket-roommembers"
            isMultiple
            items={userItems}
            renderItem={renderItem}
            selectAll={allowEdition && !isKeyboardOpen}
            selected={selectedUsers.map((user) => user.id)}
            setSelected={setSelectedUsersById}
            leftIcon="user-01"
            disabled={!allowEdition}
            showChecker={allowEdition}
            keyboardShouldPersistTaps="handled"
            keyboardDismissMode="on-drag"
            footer={footer}
          />
        </View>
      ) : (
        <View style={styles.emptyList}>
          <View style={styles.noResultsContainer}>
            <Text size="BODY" color="FONT_LOW_EMPHASIS">
              {t("noResults")}
            </Text>
          </View>
          {footer}
        </View>
      ),
    [
      allowEdition,
      footer,
      isKeyboardOpen,
      renderItem,
      selectedUsers,
      setSelectedUsersById,
      t,
      userItems,
    ],
  );

  const selectedAvatars = useMemo<AvatarContent[]>(
    () =>
      selectedUsers.map((user) => ({
        id: user.id,
        displayName: user.username,
        initials: buildUserAcronym(user) ?? "",
      })),
    [selectedUsers],
  );

  return (
    <UserList
      selectedAvatars={selectedAvatars}
      list={memberList}
      deselectUser={deselectUser}
      allowSearch={allowSearch}
      allowEdition={allowEdition}
      showAvatarList={showAvatarList}
      frozenUserIds={frozenUserIds}
      currentUserId={currentUserId}
      filter={filter}
      setFilter={setFilter}
      actions={actions}
    />
  );
};

export { MemberList };
