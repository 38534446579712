import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { KPopup } from "@kraaft/shared/components/kPopup";
import { closePreview } from "@kraaft/shared/core/modules/preview/previewActions";
import { selectPreviewParams } from "@kraaft/shared/core/modules/preview/previewSelectors";
import { ConversationPreview } from "@kraaft/web/src/components/previewPopup/previews/conversationPreview";
import { ModularFolderPreview } from "@kraaft/web/src/components/previewPopup/previews/modularFolderPreview";

const PreviewPopupRenderer = () => {
  const dispatch = useDispatch();
  const previewParams = useSelector(selectPreviewParams);

  const handleClose = useCallback(() => {
    dispatch(closePreview());
  }, [dispatch]);

  const previewContent = useMemo(() => {
    if (!previewParams.isOpen) {
      return null;
    }

    switch (previewParams.type) {
      case "room":
        return (
          <ConversationPreview
            roomId={previewParams.roomId}
            onClose={handleClose}
          />
        );
      case "modularFolder":
        return <ModularFolderPreview folderId={previewParams.folderId} />;
    }
  }, [previewParams, handleClose]);

  return (
    <KPopup
      open={previewParams.isOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullHeight
    >
      {previewContent}
    </KPopup>
  );
};

export { PreviewPopupRenderer };
