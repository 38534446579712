import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { DefaultLayoutHeaderContentProps } from "@kraaft/shared/components/defaultLayoutHeader/defaultLayoutHeader.types";
import { useLayoutMeasurer } from "@kraaft/shared/core/utils/useLayoutMeasurer";
import { Spacing, Text } from "@kraaft/ui";

export const ComplexHeaderLayout = ({
  headerLeft,
  headerRight,
  headerOverflow,
  centerHeader = true,
  header,
  headerTitle,
}: DefaultLayoutHeaderContentProps) => {
  const [{ width: leftWidth }, onLeftViewLayout] = useLayoutMeasurer();
  const [{ width: rightWidth }, onRightViewLayout] = useLayoutMeasurer();

  const maxWidthOfSide = Math.max(leftWidth, rightWidth);

  const headerLeftStyles = useMemo(
    () => [
      styles.leftContainer,
      Boolean(headerLeft || centerHeader) && styles.innerSpacingLeft,
      centerHeader && { minWidth: maxWidthOfSide },
    ],
    [centerHeader, headerLeft, maxWidthOfSide],
  );

  const headerMiddleStyles = useMemo(
    () => [
      styles.middleContainer,
      !centerHeader && styles.notCenteredMiddleContainer,
    ],
    [centerHeader],
  );

  const headerRightStyles = useMemo(
    () => [
      styles.rightContainer,
      Boolean(headerRight || centerHeader) && styles.innerSpacingRight,
      centerHeader && { minWidth: maxWidthOfSide },
    ],
    [centerHeader, headerRight, maxWidthOfSide],
  );

  return (
    <View style={styles.container}>
      {(headerLeft || !headerOverflow) && (
        <View style={headerLeftStyles} onLayout={onLeftViewLayout}>
          {headerLeft}
        </View>
      )}
      <View style={headerMiddleStyles}>
        {!headerTitle ? (
          header
        ) : (
          <Text
            weight="medium"
            size="TITLE"
            color="FONT_HIGH_EMPHASIS"
            numberOfLines={1}
            ellipsizeMode="tail"
            selectable={false}
            style={styles.title}
          >
            {headerTitle}
          </Text>
        )}
      </View>
      {(headerRight || !headerOverflow) && (
        <View style={headerRightStyles} onLayout={onRightViewLayout}>
          {headerRight}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    paddingHorizontal: Spacing.S16,
    alignItems: "center",
  },
  leftContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexShrink: 0,
  },
  middleContainer: {
    justifyContent: "center",
    flex: 1,
    flexDirection: "row",
  },
  notCenteredMiddleContainer: {
    justifyContent: "flex-start",
  },
  rightContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    flexShrink: 0,
  },
  innerSpacingLeft: {
    paddingRight: Spacing.S16,
  },
  innerSpacingRight: {
    paddingLeft: Spacing.S16,
  },
  title: {
    textAlign: "center",
  },
});
