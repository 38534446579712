import {
  ModernFileHelper,
  UploadedFile,
} from "@kraaft/shared/core/modules/file/file";

export function convertFilesToAttachments(sendableFiles: UploadedFile[]) {
  return sendableFiles.map(({ file, storagePath }) => ({
    filename: file.filename,
    storagePath,
    ...(ModernFileHelper.isMedia(file)
      ? {
          text: file.caption,
          coords: file.coordinates,
        }
      : {}),
  }));
}
