import { StyleSheet } from "react-native";
import { makeStyles } from "@mui/styles";

import {
  Color,
  ColorStyle,
  FontSize,
  IconSize,
  Radius,
  Spacing,
} from "@kraaft/ui";

export const useStyles = makeStyles({
  menuListPadding: {
    padding: Spacing.S4,
  },
  menuItemRoot: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    borderRadius: Radius.SMALL,
    fontSize: FontSize.MEDIUM,
    padding: Spacing.S8,
    "&:focus": {
      backgroundColor: Color.WHITE,
    },
    "&:hover": {
      backgroundColor: Color.BLUE_LAVENDER,
    },
    minWidth: 175,
  },
  menuItemRootDisabled: {
    opacity: 0.5,
  },
  leftContainer: {
    marginRight: Spacing.S8,
  },
  menuItemHeaderContainer: {
    flexDirection: "column",

    justifyContent: "center",
    opacity: "1 !important",

    borderBottom: `1px solid ${ColorStyle.SEPARATOR}`,
    marginBottom: Spacing.S4,
  },

  menuItemHeaderTitle: {
    fontSize: FontSize.MEDIUM,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },

  menuItemHeaderSubtitle: {
    marginTop: Spacing.S4,
    fontSize: FontSize.SMALL,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
});

export const styles = StyleSheet.create({
  image: {
    width: IconSize.MEDIUM,
    height: IconSize.MEDIUM,
  },
});
