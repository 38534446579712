import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { ScreenParams } from "@kraaft/shared/core/services/navigation/navigationParams";
import { routes } from "@kraaft/web/src/views/app/appRouter/routes";

export function useDatabaseExpandableState() {
  const params =
    useParams<
      ScreenParams<
        | "Database"
        | "ModularFoldersTable"
        | "ModularFoldersTableForRoom"
        | "ConversationTable"
        | "SchemaView"
      >
    >();
  const location = useLocation();

  const defaultSchemaViewsExpanded = useMemo(
    () => Boolean(params.schemaViewId),
    [params.schemaViewId],
  );

  const defaultRoomViewsExpanded = useMemo(
    () => !defaultSchemaViewsExpanded && Boolean(params.roomId),
    [defaultSchemaViewsExpanded, params.roomId],
  );

  const defaultGlobalViewsExpanded = useMemo(
    () =>
      !defaultSchemaViewsExpanded &&
      !defaultRoomViewsExpanded &&
      (Boolean(params?.schemaId) ||
        Boolean(location.pathname.match(routes.ConversationTable))),
    [
      defaultRoomViewsExpanded,
      defaultSchemaViewsExpanded,
      location.pathname,
      params?.schemaId,
    ],
  );

  useEffect(() => {
    setSchemaViewsExpanded(defaultSchemaViewsExpanded);
    setRoomViewsExpanded(defaultRoomViewsExpanded);
    setGlobalViewsExpanded(defaultGlobalViewsExpanded);
  }, [
    defaultGlobalViewsExpanded,
    defaultRoomViewsExpanded,
    defaultSchemaViewsExpanded,
  ]);

  const [schemaViewsExpanded, setSchemaViewsExpanded] = useState(
    defaultSchemaViewsExpanded,
  );
  const [roomViewsExpanded, setRoomViewsExpanded] = useState(
    defaultRoomViewsExpanded,
  );
  const [globalViewsExpanded, setGlobalViewsExpanded] = useState(
    defaultGlobalViewsExpanded,
  );

  const handleExpand = useCallback(
    (part: "schemaView" | "room" | "global") => (expanded: boolean) => {
      setSchemaViewsExpanded(false);
      setRoomViewsExpanded(false);
      setGlobalViewsExpanded(false);
      if (part === "schemaView") {
        setSchemaViewsExpanded(expanded);
      } else if (part === "room") {
        setRoomViewsExpanded(expanded);
      } else if (part === "global") {
        setGlobalViewsExpanded(expanded);
      }
    },
    [],
  );

  return {
    schemaViewsExpanded,
    roomViewsExpanded,
    globalViewsExpanded,
    handleExpand,
  };
}
