import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { User } from "@kraaft/shared/core/modules/user/userState";

const acceptedColumnTypeForPills = [
  KColumnType.checkbox,
  KColumnType.currency,
  KColumnType.date,
  KColumnType.geolocation,
  KColumnType.longText,
  KColumnType.selectSingle,
  KColumnType.selectMultiple,
  KColumnType.shortText,
  KColumnType.user,
  KColumnType.number,
] as const;

export type AcceptedColumnTypeForPills =
  (typeof acceptedColumnTypeForPills)[number];

export const isValidSchemaColumnForPills = (
  schemaColumn: KSchemaColumn,
): schemaColumn is KSchemaColumn<AcceptedColumnTypeForPills> => {
  return (acceptedColumnTypeForPills as readonly KColumnType[]).includes(
    schemaColumn.type,
  );
};

export type PillsDisplayOptions = {
  displayUserAcronym: boolean;
  coloredPastDate: boolean;
};

export type ExtraContextForPillRenderer = {
  currencyCode: string;
  poolMembers: Record<string, User>;
};

export type PillRenderer<T extends AcceptedColumnTypeForPills> =
  React.FunctionComponent<{
    schemaColumn: T extends AcceptedColumnTypeForPills
      ? KSchemaColumn<T>
      : never;
    recordProperty: T extends AcceptedColumnTypeForPills
      ? KSchemaColumnValue<T> | undefined
      : never;
    extraContext: T extends AcceptedColumnTypeForPills
      ? ExtraContextForPillRenderer
      : never;
    displayOptions: PillsDisplayOptions | undefined;
  }>;
