import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { compact } from "lodash";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  isAtLeastPoolAdmin,
  isPoolOwner,
  isRoleStrictlyHigher,
} from "@kraaft/shared/core/modules/pool/poolUtil";
import { editPoolMember } from "@kraaft/shared/core/modules/poolMember/poolMemberActions";
import { PoolMember } from "@kraaft/shared/core/modules/poolMember/poolMemberState";
import { getRoleTranslation } from "@kraaft/shared/core/modules/poolMember/poolMemberUtils";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { isUserSuperadmin } from "@kraaft/shared/core/modules/user/userUtils";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { conditionalEntry } from "@kraaft/shared/core/utils";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";

import { useStyles } from "./selectRole.styles";

interface Props {
  member: PoolMember;
  currentUserRole: UserPoolRole;
}

const SelectRole = (props: Props) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSuperadmin = isUserSuperadmin(useSelector(selectCurrentUser));
  const poolId = useSelector(selectCurrentPoolId);

  // get from store current user
  const { member, currentUserRole } = props;
  const memberRole = member.role || UserPoolRole.STANDARD;
  const userRole = currentUserRole || UserPoolRole.STANDARD;

  const handleChange = useCallback(
    (newRoles: UserPoolRole[] | undefined) => {
      const [newRole] = newRoles ?? [];
      if (!newRole) {
        return;
      }
      if (poolId) {
        dispatch(
          editPoolMember({
            poolId,
            userId: member.uid,
            update: { role: newRole },
          }),
        );
      }
    },
    [dispatch, poolId, member.uid],
  );

  const isDisabled = useMemo(() => {
    if (isSuperadmin) {
      return false;
    }
    if (!isAtLeastPoolAdmin(userRole)) {
      return true;
    }
    return isRoleStrictlyHigher(memberRole, currentUserRole);
  }, [isSuperadmin, userRole, currentUserRole, memberRole]);

  const items = useMemo(
    () =>
      compact([
        conditionalEntry(
          {
            label: getRoleTranslation(t, UserPoolRole.OWNER),
            value: UserPoolRole.OWNER,
            testId: "ide2e-owner-menu-item",
          },
          isPoolOwner(memberRole) ||
            isSuperadmin ||
            isPoolOwner(currentUserRole),
        ),
        {
          label: getRoleTranslation(t, UserPoolRole.ADMIN),
          value: UserPoolRole.ADMIN,
          testId: "ide2e-admin-menu-item",
        },
        {
          label: getRoleTranslation(t, UserPoolRole.STANDARD),
          value: UserPoolRole.STANDARD,
          testId: "ide2e-standard-menu-item",
        },
        {
          label: getRoleTranslation(t, UserPoolRole.EXTERNAL),
          value: UserPoolRole.EXTERNAL,
          testId: "ide2e-external-menu-item",
        },
      ]),
    [currentUserRole, isSuperadmin, memberRole, t],
  );

  return (
    <div className={styles.dropdown}>
      <KDropdown
        testID={`select-role-dropdown-${isDisabled ? "disabled" : "enabled"}`}
        disabled={isDisabled}
        onSelectionChange={handleChange}
        selectedItemIds={[memberRole]}
        items={items}
      />
    </div>
  );
};

export { SelectRole };
