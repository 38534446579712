import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { RoomFiltersSheet } from "@kraaft/shared/components/filters/roomFilters.sheet";
import { useFilterRooms } from "@kraaft/shared/components/filters/useFilterRooms";
import { Button } from "@kraaft/ui";

interface RoomFiltersFooterProps {
  onFiltered: () => void;
  onErase: () => void;
  canErase: boolean;
}

export const RoomFiltersFooter = ({
  onFiltered,
  onErase,
  canErase,
}: RoomFiltersFooterProps) => {
  const { t } = useTranslation();

  const filterRooms = useFilterRooms();

  const handleSubmitFilters = useCallback(() => {
    filterRooms();
    onFiltered();
  }, [filterRooms, onFiltered]);

  return (
    <RoomFiltersSheet.Footer>
      <Button
        text={t("roomFilters.eraseActionLabel")}
        variant="QUATERNARY"
        onPress={onErase}
        disabled={!canErase}
      />
      <Button
        text={t("roomFilters.filterActionLabel")}
        variant="PRIMARY"
        onPress={handleSubmitFilters}
      />
    </RoomFiltersSheet.Footer>
  );
};
