import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import {
  selectCurrentUserAtLeastAdmin,
  selectCurrentUserAtLeastStandard,
} from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { actions as roomActions } from "@kraaft/shared/core/modules/room";
import {
  selectIsEveryoneRoom,
  selectIsUserMemberOfRoom,
  selectRoomTitle,
} from "@kraaft/shared/core/modules/room/selectors/roomOrRoomCardSelectors";
import { selectors as userSelectors } from "@kraaft/shared/core/modules/user";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { RoomAccessSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";
import { useEnsureOnline } from "@kraaft/shared/core/utils/useNetwork";

type Params = {
  roomId: string;
  poolId: string;
  source: RoomAccessSource;
  onLeaveConfirm?: () => void;
  onJoinConfirm?: () => void;
};

export const useRoomAccess = ({
  poolId,
  roomId,
  source,
  onLeaveConfirm,
  onJoinConfirm,
}: Params) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const currentUserId = useSelector(userSelectors.selectCurrentUserId);
  const isCurrentUserRoomMember = useSelector(selectIsUserMemberOfRoom(roomId));
  const isEveryoneRoom = useSelector(selectIsEveryoneRoom(roomId));
  const roomTitle = useSelector(selectRoomTitle(roomId));
  const isUserAtLeastStandard = useSelector(
    selectCurrentUserAtLeastStandard(poolId),
  );
  const isUserAtLeastAdmin = useSelector(selectCurrentUserAtLeastAdmin(poolId));

  const canLeaveRoom =
    isUserAtLeastStandard && isCurrentUserRoomMember && !isEveryoneRoom;

  const canJoinRoom =
    isUserAtLeastAdmin && !isCurrentUserRoomMember && !isEveryoneRoom;

  const leaveRoom = useEnsureOnline(() => {
    if (!currentUserId) {
      return;
    }

    newConfirmationAlert({
      title: t("leaveTicketAlertTitle"),
      message: t("leaveTicketAlertMessage"),
      cancelText: t("cancel"),
      confirmText: t("leaveTicketAlertButton"),
      onConfirm: () => {
        if (!canLeaveRoom) {
          console.error("User is not allowed to leave room.");
          return;
        }

        trackEvent({
          eventName: "Leave Room",
          room_id: roomId,
          room_name: roomTitle ?? "",
          platform: Platform.OS,
          source,
        });

        dispatch(
          roomActions.changeRoomUsersRequest({
            roomId,
            removedUserIds: [currentUserId],
          }),
        );

        onLeaveConfirm?.();
      },
    });
  }, [
    currentUserId,
    t,
    canLeaveRoom,
    roomId,
    roomTitle,
    source,
    dispatch,
    onLeaveConfirm,
  ]);

  const joinRoom = useEnsureOnline(() => {
    if (!currentUserId) {
      return;
    }

    trackEvent({
      eventName: "Join Room",
      room_id: roomId,
      room_name: roomTitle ?? "",
      platform: Platform.OS,
      source,
    });

    dispatch(
      roomActions.changeRoomUsersRequest({
        roomId,
        addedUserIds: [currentUserId],
      }),
    );

    onJoinConfirm?.();
  }, [currentUserId, roomId, roomTitle, source, dispatch, onJoinConfirm]);

  return {
    leaveRoom: canLeaveRoom ? leaveRoom : null,
    joinRoom: canJoinRoom ? joinRoom : null,
  };
};
