import { call, put, select } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { RoomCardStateActions } from "@kraaft/shared/core/modules/roomCard/roomCard.actions";
import { selectRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { BatchActions } from "@kraaft/shared/core/store/batchingReducer";

export function* unpinRoomSaga(
  action: ReturnType<typeof roomActions.RoomActions.unpin>,
) {
  const roomCard = yield* select(selectRoomCard(action.payload.roomId));

  if (!roomCard || roomCard.type === "pool" || !roomCard.pinned) {
    return;
  }

  const currentPinnedAtValue = roomCard.pinnedAt ?? new Date();

  try {
    yield* put(
      RoomCardStateActions.setUnpinned({
        roomId: action.payload.roomId,
      }),
    );
    yield* call(Api.unpinRoom, {
      roomId: action.payload.roomId,
      poolId: roomCard.poolId,
    });
  } catch (error) {
    yield* put(
      BatchActions({
        actions: [
          RoomCardStateActions.setPinned({
            roomId: action.payload.roomId,
            at: currentPinnedAtValue,
          }),
          showError({ title: i18n.t("internalError") }),
        ],
      }),
    );
  }
}
