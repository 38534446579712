import { useDrop } from "react-dnd";

import {
  ColumnDropItem,
  FormBuilderDropTypes,
  InsertElementDropItem,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";
import {
  getTranslateFromTransition,
  Transition,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditor.utils";

import { useStyles } from "./elementsEditorBottomItem.styles";

interface ElementsEditorBottomItemProps {
  setLastKey: () => void;
  addAtLast: (item: InsertElementDropItem) => void;
  transition: Transition;
}

const ElementsEditorBottomItem = ({
  setLastKey,
  addAtLast,
  transition,
}: ElementsEditorBottomItemProps) => {
  const classes = useStyles();
  const [, drop] = useDrop({
    accept: [FormBuilderDropTypes.INSERT, FormBuilderDropTypes.REORDER],
    hover: setLastKey,
    drop: (item: ColumnDropItem) => {
      if (item.type === FormBuilderDropTypes.INSERT) {
        addAtLast(item);
      }
    },
  });

  return (
    <div
      ref={drop}
      className={classes.root}
      style={{ transform: getTranslateFromTransition(transition) }}
    />
  );
};

export { ElementsEditorBottomItem };
