import { useMemo } from "react";

import {
  MapMenu,
  MapMenuItem,
} from "@kraaft/shared/components/mapController/menu/mapMenu";
import { getModularFolderIcon } from "@kraaft/shared/components/modularFolderList/modularFolderItem/modularFolderItemUtils";
import {
  formatGeolocatedModularFolders,
  getGeolocatedModularFolders,
} from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";

interface ModularFoldersMenuProps {
  modularFolders: ModularFolder[];
  schema: KFolderSchema;
  onPress: (modularFolderId: string) => void;
}

export const ModularFoldersMenu = ({
  modularFolders,
  onPress,
  schema,
}: ModularFoldersMenuProps) => {
  const modularFoldersWithCoords = useMemo(() => {
    const geolocatedModularFolders =
      getGeolocatedModularFolders(modularFolders);

    return formatGeolocatedModularFolders(geolocatedModularFolders);
  }, [modularFolders]);

  const items: MapMenuItem[] = useMemo(
    () =>
      modularFoldersWithCoords.map((modularFolder) => {
        const icon = getModularFolderIcon(schema, modularFolder.ref);
        return {
          id: modularFolder.id,
          icon,
          label: modularFolder.ref.properties.title.value,
          geolocation: modularFolder.geolocation,
        };
      }),
    [modularFoldersWithCoords, schema],
  );

  return <MapMenu items={items} onPress={onPress} />;
};
