import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { EntryLayout } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayout";
import { EntryLayoutWithDifferenceDetails } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayoutWithDetails/entryLayoutWithDifferenceDetails";
import {
  selectModularFolderHistoryEntryElementName,
  selectModularFolderHistoryEntrySchemaColumn,
} from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.selectors";
import { ModularFolderPropertyErased } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.state";
import { selectUsername } from "@kraaft/shared/core/modules/user/userSelectors";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

interface PropertyErasedEntryProps {
  entry: ModularFolderPropertyErased;
}

export const PropertyErasedEntry = ({ entry }: PropertyErasedEntryProps) => {
  const { t } = useTranslation();

  const initiatorName = useSelector(selectUsername(entry.by));
  const fieldName = useSelector(
    selectModularFolderHistoryEntryElementName(
      entry.modularFolderId,
      entry.key,
    ),
  );
  const schemaColumn = useSelector(
    selectModularFolderHistoryEntrySchemaColumn(
      entry.modularFolderId,
      entry.key,
    ),
  );

  const handleOpenDetails = useCallback(() => {
    trackEvent({
      eventName: "Click On See Modular Folder History Entry Difference Details",
      entry_id: entry.id,
      entry_type: entry.oldValue.columnType,
      record_id: entry.modularFolderId,
    });
  }, [entry.id, entry.modularFolderId, entry.oldValue.columnType]);

  return (
    <EntryLayoutWithDifferenceDetails
      date={entry.at}
      i18nKey="history.entryDetailsLabel.propertyErased"
      i18nValues={{
        initiatorName,
        fieldName: fieldName ?? t("history.unknownSchemaColumnName"),
      }}
      oldValue={entry.oldValue}
      schemaColumn={schemaColumn}
      onOpenDetails={handleOpenDetails}
    >
      <EntryLayout
        date={entry.at}
        iconName="eraser"
        iconColor="RED_TOMATO"
        i18nKey="history.entryLabel.propertyErased"
        i18nValues={{
          initiatorName,
          fieldName: fieldName ?? t("history.unknownSchemaColumnName"),
        }}
        errorMessage={
          entry.isSchemaColumnMissing
            ? t("history.schemaColumnMissing")
            : undefined
        }
      />
    </EntryLayoutWithDifferenceDetails>
  );
};
