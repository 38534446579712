import { useCallback, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import clsx from "clsx";

import { DIRECTORY_DROP_ACCEPT_TYPE } from "@kraaft/shared/components/directory/useDirectoryDrop/types";

import { useStyles } from "./dragNavigator.styles";

const TRIGGER_TIMEOUT = 1200;

interface DragNavigatorProps {
  children: React.ReactNode;
  withHoverEffect?: boolean;
  onTriggered?: () => void;
}

export const DragNavigator = (props: DragNavigatorProps) => {
  const { children, withHoverEffect, onTriggered } = props;

  const classes = useStyles();
  const [isBlinking, setBlinking] = useState(false);

  const hoveredTimeout = useRef<number | undefined>(undefined);
  const triggeredTimeout = useRef<number | undefined>(undefined);

  const [{ isOver }, drop] = useDrop({
    accept: DIRECTORY_DROP_ACCEPT_TYPE,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleHovered = useCallback(() => {
    setBlinking(true);
  }, []);

  const processHovered = useCallback(
    (callback: () => void) => {
      if (onTriggered && isOver) {
        hoveredTimeout.current = setTimeout(
          handleHovered,
          TRIGGER_TIMEOUT * 0.8,
        );
        triggeredTimeout.current = setTimeout(callback, TRIGGER_TIMEOUT);
      }
    },
    [handleHovered, isOver, onTriggered],
  );

  const handleTriggered = useCallback(() => {
    setBlinking(false);
    onTriggered?.();
    processHovered(handleTriggered);
  }, [onTriggered, processHovered]);

  useEffect(() => {
    processHovered(handleTriggered);

    return () => {
      clearTimeout(hoveredTimeout.current);
      clearTimeout(triggeredTimeout.current);
      setBlinking(false);
    };
  }, [isOver, handleHovered, onTriggered, handleTriggered, processHovered]);

  return (
    <div
      ref={drop}
      className={clsx(
        classes.container,
        withHoverEffect && isOver && classes.hoveredContainer,
        isBlinking && classes.blinkingContainer,
      )}
    >
      {children}
    </div>
  );
};
