import moment from "moment";

import { useCallback, useEffect, useMemo, useState } from "react";

import { RoomReportFilters } from "@kraaft/shared/components/report/roomReportFilters";
import { useDownloadRoomReportCallback } from "@kraaft/shared/components/report/useDownloadRoomReport";
import { useSendRoomReportCallback } from "@kraaft/shared/components/report/useSendRoomReport";
import { RoomReportFiltersJson } from "@kraaft/shared/core/services/api/api";

type KeyOf<T> = T extends object ? keyof T : never;

type ValueOfKey<T, U extends KeyOf<T>> = T extends object ? T[U] : never;

const useRoomReportFilters = (roomId: string) => {
  const [filters, setFilters] = useState<RoomReportFilters>({
    includeDocuments: true,
    includeMessages: true,
  });

  const [folderIds, setFolderIds] = useState<string[]>([]);
  const [isSelectingByElements, setSelectingByElements] = useState(false);

  useEffect(() => {
    setFolderIds([]);
  }, [roomId]);

  const updateFilters = useCallback(
    <K extends KeyOf<RoomReportFilters>>(property: K) =>
      (newValue: ValueOfKey<RoomReportFilters, K>) =>
        setFilters((current) => ({ ...current, [property]: newValue })),
    [],
  );

  const updateDateFilterCache = useMemo<{
    [key in KeyOf<RoomReportFilters["dateRange"]>]?: (
      value: ValueOfKey<
        RoomReportFilters["dateRange"],
        KeyOf<RoomReportFilters["dateRange"]>
      >,
    ) => void;
  }>(() => ({}), []);

  const updateDateFilter = useCallback(
    <K extends KeyOf<RoomReportFilters["dateRange"]>>(property: K) => {
      const setter =
        updateDateFilterCache[property] ??
        ((newValue: ValueOfKey<RoomReportFilters["dateRange"], K>) =>
          setFilters((current) => ({
            ...current,
            ...{ dateRange: { ...current.dateRange, [property]: newValue } },
          })));

      updateDateFilterCache[property] = setter;

      return setter;
    },
    [updateDateFilterCache],
  );

  let dateRange: { from: string; until: string } | undefined;
  if (filters.dateRange?.from && filters.dateRange.until) {
    dateRange = {
      from: moment(filters.dateRange.from).startOf("day").toISOString(),
      until: moment(filters.dateRange.until).endOf("day").toISOString(),
    };
  }

  const filtersJson = useMemo(() => {
    let json: RoomReportFiltersJson;
    if (isSelectingByElements) {
      json = {
        ...filters,
        folderIds,
        dateRange,
      };
    } else {
      json = {
        ...filters,
        checkboxIds: undefined,
        folderIds: undefined,
        dateRange,
      };
    }
    return json;
  }, [isSelectingByElements, filters, folderIds, dateRange]);

  const { sendRoomReport, isSendingRoomReport } = useSendRoomReportCallback(
    roomId,
    filtersJson,
  );

  const { downloadRoomReport, isDownloadingRoomReport } =
    useDownloadRoomReportCallback(roomId, filtersJson);

  return {
    filters,
    updateFilters,
    updateDateFilter,
    folderIds,
    setFolderIds,
    setSelectingByElements,
    sendRoomReport,
    isSendingRoomReport,
    downloadRoomReport,
    isDownloadingRoomReport,
  };
};

export { useRoomReportFilters };
