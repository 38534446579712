import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { downloadMultiple } from "@kraaft/shared/core/modules/media/mediaSagas.utils";
import {
  actions as messageActions,
  utils as messageUtils,
} from "@kraaft/shared/core/modules/message";
import { selectMessageInRoom } from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import { fetchAndAddUnknownMessage } from "@kraaft/shared/core/modules/message/messageSagas";
import { selectMessageSelectionSource } from "@kraaft/shared/core/modules/message/messageSelectors";
import {
  DocumentMessage,
  FileMessage,
  ImageMessage,
  MessageSelectionSource,
} from "@kraaft/shared/core/modules/message/messageState";
import { i18n } from "@kraaft/shared/core/services/i18next";

import * as actions from "./media.actions";

export function* mediaSagas() {
  yield* takeEvery(actions.downloadMediaSelection, downloadMediaSelectionSaga);
}

function* getCachedUrlOfImageMessage(message: ImageMessage) {
  const downloadUrl = message.attachment.original?.downloadUrl;
  const cachedFileUrl = yield* call(() =>
    fileAllocator.temporaryIfNeeded(downloadUrl),
  );
  return {
    filename: message.attachment.original.filename,
    fileUrl: cachedFileUrl,
  };
}

function* getCachedUrlOfDocumentMessage(message: DocumentMessage) {
  const { downloadUrl, filename } = message.attachment.original;

  const cachedFileUrl = yield* call(() =>
    fileAllocator.temporaryIfNeeded(downloadUrl),
  );
  return { filename, fileUrl: cachedFileUrl };
}

function* getMessage(roomId: string, messageId: string) {
  let message = yield* select(selectMessageInRoom(roomId, messageId));

  if (!message) {
    message = yield* fetchAndAddUnknownMessage({
      roomId,
      messageId,
    });
  }
  return message;
}

export function* getCachedUrlByMessageId({
  roomId,
  messageId,
}: {
  roomId: string;
  messageId: string;
}) {
  const message = yield* getMessage(roomId, messageId);

  if (!message) {
    throw new Error("No message found");
  }
  if (messageUtils.isImageMessage(message)) {
    return { message, cached: yield* getCachedUrlOfImageMessage(message) };
  }
  if (messageUtils.isDocumentMessage(message)) {
    return { message, cached: yield* getCachedUrlOfDocumentMessage(message) };
  }
  throw new Error("Selected message is not an image or a document");
}

// Undefined allows downloading zip without ensuring a specific message type
const mediaSelectionToMessageType: Record<
  NonNullable<MessageSelectionSource>,
  FileMessage["type"] | undefined
> = {
  documentGallery: "document",
  photoGallery: "image",
  directoryList: undefined,
  conversation: undefined,
};

const messageTypeToErrors = {
  default: {
    single: "couldNotDownloadDocument",
    multiple: "couldNotDownloadDocuments",
  },
  document: {
    single: "couldNotDownloadDocument",
    multiple: "couldNotDownloadDocuments",
  },
  image: {
    single: "couldNotDownloadImage",
    multiple: "couldNotDownloadImages",
  },

  // Implement i18n translations when specific download for these types are available
  video: {
    single: "couldNotDownloadDocument",
    multiple: "couldNotDownloadDocuments",
  },
  audio: {
    single: "couldNotDownloadDocument",
    multiple: "couldNotDownloadDocuments",
  },
} as const;

function* handleDownloadSingle(roomId: string, messageIds: string[]) {
  const [messageId] = messageIds;

  if (!messageId) {
    throw new Error("Selected message id is undefined");
  }

  const message = yield* getMessage(roomId, messageId);

  if (!message) {
    return;
  }

  if (message.type === "image") {
    const { cached } = yield* getCachedUrlByMessageId({
      roomId,
      messageId,
    });

    if (!cached) {
      throw new Error("Cannot access cached file");
    }

    /** @TOOO we should compute filename according to nomenclature */
    const { filename, fileUrl } = cached;

    yield* call(() => fileSaver.downloadImage(fileUrl, filename));
  } else if (message.type === "document") {
    yield* call(() =>
      fileSaver.download(
        message.attachment.original.downloadUrl,
        message.attachment.original.filename,
      ),
    );
  }
}

function* handleDownloadMultiple(
  roomId: string,
  messageIds: string[],
  mediaType: FileMessage["type"] | undefined,
) {
  yield* call(() => downloadMultiple(roomId, messageIds, mediaType));
}

function* downloadMediaSelectionSaga(
  action: ReturnType<typeof actions.downloadMediaSelection>,
) {
  const { roomId, messageIds } = action.payload;
  const meta = action.meta;

  const selectionSource = yield* select(selectMessageSelectionSource(roomId));

  const mediaType = selectionSource
    ? mediaSelectionToMessageType[selectionSource]
    : undefined;

  const isSingle = messageIds.length === 1;

  try {
    if (isSingle) {
      yield* handleDownloadSingle(roomId, messageIds);
    } else {
      yield* handleDownloadMultiple(roomId, messageIds, mediaType);
    }
    yield* put(messageActions.deselectMessage({ roomId, all: true }));
  } catch (error) {
    console.error(error);
    yield* put(
      showError({
        title: i18n.t(
          messageTypeToErrors[mediaType ?? "default"][
            isSingle ? "single" : "multiple"
          ],
        ),
      }),
    );
    yield* put(actions.downloadMediaSelectionFailure(error, meta));
  }
  yield* put(actions.downloadMediaSelectionSuccess(meta));
}
