import { StyleSheet } from "react-native";

import { ColorStyle, FontSize, webOnlyStyle } from "@kraaft/ui";

const GAP_BETWEEN_WRAP = 6;

export const styles = StyleSheet.create({
  withSpacer: {
    marginRight: 8,
  },
  selectPillRendererContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: -GAP_BETWEEN_WRAP,
  },
  selectPillRendererItem: {
    marginBottom: GAP_BETWEEN_WRAP,
  },

  selectCompactedPillRendererContainer: {
    flexDirection: "row",
  },

  withIconContainer: {
    flexDirection: "row",
    marginHorizontal: 4,
  },
  withIconContainerIcon: {
    alignSelf: "flex-start",
    marginRight: 4,
  },
  withIconContainerContent: {
    flexShrink: 1,
    justifyContent: "center",
  },
  textPillContentText: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.SMALL,
    lineHeight: 12,
    fontFamily: "Apercu",
    ...webOnlyStyle({ wordBreak: "break-word" }),
  },
});
