import { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TextInput, View } from "react-native";
import {
  GooglePlaceData,
  GooglePlaceDetail,
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
} from "react-native-google-places-autocomplete";

import { PoweredBy } from "@kraaft/shared/components/geolocation/poweredBy";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { decorateText } from "@kraaft/shared/components/placesAutocomplete/placesAutocompleteUtils";
import {
  getAppEngineApiBaseUrl,
  getGoogleGeocodeApiKey,
} from "@kraaft/shared/constants/environment/environment.utils";
import { GeoLocation } from "@kraaft/shared/core/types";
import { canUseDOM } from "@kraaft/shared/core/utils";
import { ColorStyle } from "@kraaft/ui";

import { styles } from "./editPlaceAutocomplete.styles";

type EditPlaceAutoCompleteProps = {
  placesAutocompleteRef: React.RefObject<GooglePlacesAutocompleteRef>;
  setPlacesAutocompleteValue: (
    newTextValue: string,
    newValue: GeoLocation | null,
  ) => void;
  setPlacesAutocompleteText: (value: string) => void;
  handleBlur?: () => Promise<void>;
  handleChangeLocationFail?: () => void;
  useKInput?: boolean;
  useTableStyle?: boolean;
  autoFocus?: boolean;

  // Web Only
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const EditPlaceAutocomplete = (props: EditPlaceAutoCompleteProps) => {
  const {
    placesAutocompleteRef,
    setPlacesAutocompleteValue,
    setPlacesAutocompleteText,
    handleChangeLocationFail,
    handleBlur,
    useKInput = true,
    useTableStyle,
    autoFocus,
    onKeyDown,
    onKeyPress,
  } = props;
  const { t } = useTranslation();
  const inputRef = useRef<TextInput>();

  useLayoutEffect(() => {
    if (canUseDOM) {
      // this is not a TextInput from React-Native, it's the input from react-native-web
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentRef = inputRef.current as any;

      if (currentRef) {
        if (onKeyDown) {
          currentRef.addEventListener?.("keydown", onKeyDown);
        }
        if (onKeyPress) {
          currentRef.addEventListener?.("keypress", onKeyPress);
        }
      }

      return () => {
        if (currentRef) {
          if (onKeyDown) {
            currentRef.removeEventListener?.("keydown", onKeyDown);
          }
          if (onKeyPress) {
            currentRef.removeEventListener?.("keypress", onKeyPress);
          }
        }
      };
    }
  }, [onKeyDown, onKeyPress, useKInput]);

  function renderRow(
    { description, matched_substrings }: GooglePlaceData,
    index: number,
  ) {
    return decorateText(
      index,
      description,
      matched_substrings,
      styles.description,
      {
        fontFamily: "Apercu-Medium",
      },
    );
  }

  const handlePARowPressed = useCallback(
    (data: GooglePlaceData, details: GooglePlaceDetail | null) => {
      if (details) {
        const { lat: latitude, lng: longitude } = details.geometry.location;

        setPlacesAutocompleteText(data.description);

        const result: GeoLocation = {
          coords: {
            latitude,
            longitude,
          },
          address: {
            description: data.description,
            googlePlaceId: data.place_id,
          },
        };
        setPlacesAutocompleteValue(data.description, result);
      }
    },
    [setPlacesAutocompleteText, setPlacesAutocompleteValue],
  );

  const googlePlacesAutocompleteQuery = useMemo(
    () => ({
      key: getGoogleGeocodeApiKey(),
      language: t("language"),
    }),
    [t],
  );

  const renderPoweredComponent = useCallback(
    () => (
      <View style={styles.poweredContainer}>
        <PoweredBy />
      </View>
    ),
    [],
  );

  return (
    <GooglePlacesAutocomplete
      ref={placesAutocompleteRef}
      placeholder=""
      suppressDefaultStyles
      disableScroll
      isRowScrollable={false}
      listViewDisplayed="auto"
      keepResultsAfterBlur={false}
      enablePoweredByContainer={true}
      renderRow={renderRow}
      listUnderlayColor={ColorStyle.ACTION_SELECTED}
      onPress={handlePARowPressed}
      onFail={handleChangeLocationFail}
      onTimeout={handleChangeLocationFail}
      query={googlePlacesAutocompleteQuery}
      renderPoweredComponent={renderPoweredComponent}
      textInputProps={{
        InputComp: useKInput ? KInput : TextInput,
        nativeID: "ide2e-location",
        label: t("location"),
        onChangeText: setPlacesAutocompleteText,
        showEditIcon: true,
        clearButtonMode: "never",
        onBlur: handleBlur,
        autoComplete: "off",
        autoFocus,
        inputContainerStyle: styles.PACustomTextInputContainer,
        ref: inputRef,
      }}
      styles={{
        container: styles.PAContainer,
        ...(useKInput
          ? {
              textInput: styles.PACustomTextInput,
            }
          : {
              textInputContainer: styles.PATextInputContainer,
              textInput: styles.PATextInput,
            }),
        listView: useTableStyle ? styles.PATableListView : styles.PAListView,
        row: styles.PARow,
        separator: styles.PASeparator,
      }}
      requestUrl={{
        useOnPlatform: "web",
        url: `${getAppEngineApiBaseUrl()}/maps-proxy`,
      }}
    />
  );
};
