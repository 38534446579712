import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  useNavigationService,
  useRouteService,
} from "@kraaft/shared/core/services/navigation";
import { PoolSchemaBuilderHeaderName } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderHeader/poolSchemaBuilderHeaderName";
import { PoolSchemaBuilderTabs } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderTabs";
import * as TabBar from "@kraaft/web/src/components/tabBar";

import { useStyles } from "./poolSchemaBuilderHeader.styles";

interface SchemaBuilderHeaderProps {
  schema: KSchema;
}

const SchemaBuilderHeader = ({ schema }: SchemaBuilderHeaderProps) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const navigationService = useNavigationService();
  const route =
    useRouteService().getEditSchemaCurrentTab() as PoolSchemaBuilderTabs;

  const navigateToTab = useCallback(
    (newTab: PoolSchemaBuilderTabs) => {
      if (schema.collection === "room") {
        navigationService.navigate("RoomSchemaBuilder", {
          tab: newTab,
        });
      } else {
        navigationService.navigate("FolderSchemaBuilder", {
          schemaId: schema.id,
          tab: newTab,
        });
      }
    },
    [navigationService, schema],
  );

  return (
    <div className={styles.header}>
      <div className={styles.textContainer}>
        <PoolSchemaBuilderHeaderName
          collection={schema.collection}
          schemaName={schema.name}
          schemaId={schema.id}
        />
      </div>
      <div className={styles.tabs}>
        <TabBar.Container value={route} onChange={navigateToTab}>
          <TabBar.Tab<PoolSchemaBuilderTabs>
            icon="rows-01"
            label={t("formBuilder.schema.tabName")}
            value={PoolSchemaBuilderTabs.schema}
            id="schema"
          />
          <TabBar.Tab<PoolSchemaBuilderTabs>
            icon="settings-04"
            label={t("formBuilder.infos.tabName")}
            value={PoolSchemaBuilderTabs.info}
            id="info"
          />
          <TabBar.Tab<PoolSchemaBuilderTabs>
            icon="file-download-02"
            label={t("formBuilder.reports.tabName")}
            value={PoolSchemaBuilderTabs.reports}
            id="reports"
          />
        </TabBar.Container>
      </div>
    </div>
  );
};

export { SchemaBuilderHeader };
