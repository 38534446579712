import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { DetailsSection } from "@kraaft/shared/components/conversationDetails/detailsSection";
import { SectionHeader } from "@kraaft/shared/components/conversationDetails/sectionHeader";
import { PhotoGalleryActionCard } from "@kraaft/shared/components/conversationDetails/worksiteFolder/photoSection/photoGalleryActionCard";
import { MapButtonContainer } from "@kraaft/shared/components/mapButton/container";
import { MapContext } from "@kraaft/shared/core/services/navigation/navigationParams";

import { styles } from "../section.styles";

interface PhotoSectionProps {
  roomId: string;
}

const PhotoSection: React.FC<PhotoSectionProps> = ({ roomId }) => {
  const { t } = useTranslation();

  const mapContext = useMemo<MapContext>(
    () => ({ location: "Conversation", roomId }),
    [roomId],
  );

  return (
    <DetailsSection>
      <SectionHeader
        title={t("worksiteFolder.photoSectionTitle")}
        titleElementId="room-photo-section"
      />
      <View style={styles.sectionContent}>
        <MapButtonContainer type="photos" context={mapContext} />
        <PhotoGalleryActionCard roomId={roomId} />
      </View>
    </DetailsSection>
  );
};

export { PhotoSection };
