import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  ModularRecord,
  ModularRecordEditionPayload,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { useDefaultModularColumnContext } from "@kraaft/shared/core/modules/schema/modularTypes/useDefaultModularColumnContext";
import {
  selectSchemaLockLookup,
  selectSchemaOrderedColumns,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { uuid } from "@kraaft/shared/core/utils";
import { Button } from "@kraaft/ui";
import { Table } from "@kraaft/web/src/components/modularTable/components/table";
import { ModularTableProps } from "@kraaft/web/src/components/modularTable/schema";
import { TableContext } from "@kraaft/web/src/components/modularTable/tableContext";
import { SimplifiedTableEditionsProps } from "@kraaft/web/src/components/simplifiedTable/simplifiedTableTypes";
import {
  applyEditionsToTableRecords,
  useDeletionColumn,
} from "@kraaft/web/src/components/simplifiedTable/simplifiedTableUtils";

import { styles } from "./simplifiedTable.styles";

export const SimplifiedTableEditions = ({
  schema,
  canAddRecord,
  canDeleteRecord,
  editions,
  onEditionsChange,
  records,
  forcedHeight,
  maxRows,
  ...tableProps
}: SimplifiedTableEditionsProps) => {
  const { t } = useTranslation();

  const [tableRecords, setTableRecords] = useState<ModularRecord[]>([]);
  const [added, setAdded] = useState(0);

  const maxRowReached =
    maxRows !== undefined && tableRecords.length + added === maxRows;

  const addRow = useCallback(() => {
    if (!canAddRecord) {
      return;
    }
    if (maxRowReached) {
      return;
    }
    const newEditions: typeof editions = {
      ...editions,
      [uuid()]: { type: "addition", editions: {} },
    };
    setAdded(added + 1);
    onEditionsChange(newEditions);
  }, [added, canAddRecord, editions, maxRowReached, onEditionsChange]);

  const deleteRow = useCallback(
    (key: string) => {
      if (!canDeleteRecord) {
        return;
      }

      const newEditions: typeof editions = {
        ...editions,
      };

      const edition = newEditions[key];

      if (edition?.type === "edition") {
        edition.deleted = true;
      } else {
        newEditions[key] = {
          type: "edition",
          editions: [],
          deleted: true,
        };
      }

      onEditionsChange(newEditions);
    },
    [canDeleteRecord, editions, onEditionsChange],
  );

  const lockingColumns = useMemo(
    () => KSchemaUtils.getLockingColumnsForSchema(schema),
    [schema],
  );

  const deletionColumn = useDeletionColumn(deleteRow, lockingColumns);

  useEffect(() => {
    const newTableRecords = cloneDeep(records);
    applyEditionsToTableRecords(schema, newTableRecords, editions);
    setTableRecords(newTableRecords);
  }, [schema, records, editions]);

  const state = useMemo<ModularTableProps<ModularRecord>["tableState"]>(
    () => ({
      schema,
      rows: tableRecords,
    }),
    [schema, tableRecords],
  );

  const actions: ModularTableProps<ModularRecord>["tableActions"] = useMemo(
    () => ({
      cell: {
        onUpdate: (updates) => {
          for (const update of updates) {
            if (!(update.id in editions)) {
              editions[update.id] = { type: "edition", editions: [] };
            }
            const change = editions[update.id];
            // Can't modify a property if the row has been deleted
            if (change?.type !== "addition" && change?.type !== "edition") {
              return;
            }

            if (change.type === "edition") {
              const editionPayload: ModularRecordEditionPayload = [
                update.column.key,
                {
                  columnType: update.column.type,
                  value: update.value,
                } as KSchemaColumnValue,
              ];

              if (
                !(
                  update.column.type === KColumnType.checkbox &&
                  update.column.isLockingSection
                )
              ) {
                const columnIndexInEditions = change.editions.findIndex(
                  ([columnKey]) => columnKey === update.column.key,
                );

                if (columnIndexInEditions !== -1) {
                  change.editions[columnIndexInEditions] = editionPayload;
                } else {
                  change.editions.push(editionPayload);
                }
              } else {
                change.editions.push(editionPayload);
              }
            } else {
              change.editions[update.column.key] = {
                columnType: update.column.type,
                value: update.value,
              } as KSchemaColumnValue;
            }
          }
          onEditionsChange({ ...editions });
        },
      },
    }),
    [editions, onEditionsChange],
  );

  const context: ModularTableProps<ModularRecord>["attachmentContext"] =
    useMemo(
      () => ({
        isCellLoading: () => false,
        onAttachmentClick: () => false,
        onFilesUpload: () => false,
      }),
      [],
    );

  const finalContainerStyle = useMemo(
    () => [styles.tableContainer, { height: forcedHeight }],
    [forcedHeight],
  );

  const schemaLockLookup = useSelector(selectSchemaLockLookup(schema.id));
  const orderedColumns = useSelector(selectSchemaOrderedColumns(schema.id));

  const defaultColumnsContext = useDefaultModularColumnContext(
    schemaLockLookup,
    orderedColumns,
  );
  const tableContext = useMemo<TableContext>(
    () => ({
      columns: defaultColumnsContext.columns,
      records: {},
      additionalColumns: canDeleteRecord ? [deletionColumn] : [],
    }),
    [canDeleteRecord, defaultColumnsContext, deletionColumn],
  );

  return (
    <>
      <View style={finalContainerStyle}>
        <Table
          tableActions={actions}
          tableState={state}
          attachmentContext={context}
          fullSize={false}
          tableContext={tableContext}
          canCreateRow={canAddRecord}
          {...tableProps}
          recordType="modularFolder"
          noCheckboxConfirmation
        />
      </View>
      {canAddRecord && (
        <View style={styles.addRow}>
          <Button
            disabled={maxRowReached}
            tooltip={
              maxRowReached
                ? t("modularFoldersMenu.maxRowReached", {
                    count: maxRows,
                  })
                : undefined
            }
            accessibilityLabel={t("add")}
            onPress={addRow}
            icon="plus"
            variant="TERTIARY"
          />
        </View>
      )}
    </>
  );
};
