import { StyleSheet, View } from "react-native";

import { Spacing } from "@kraaft/ui";

export interface HeaderSideProps {
  children: React.ReactNode | React.ReactNode[];
}

export const HeaderSide = (props: HeaderSideProps) => {
  const { children } = props;

  return <View style={styles.headerFlexRow}>{children}</View>;
};

const styles = StyleSheet.create({
  headerFlexRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: Spacing.S4,
  },
});
