import { GeoCoordinates } from "@kraaft/shared/core/types";
import { getGoogleLegacyMapsUrlFromCoords } from "@kraaft/shared/core/utils/coordinates";

export async function navigateToPosition(coords: GeoCoordinates) {
  try {
    const url = getGoogleLegacyMapsUrlFromCoords(coords);
    window.open(url, "_blank");
  } catch (error) {
    console.log("Itinerary ::", error.message);
  }
}
