import { CalculatedColumn } from "react-data-grid";
import isEqual from "fast-deep-equal";
import isEmpty from "lodash/isEmpty";

import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { Cell } from "@kraaft/web/src/components/modularTable/components/types";

// Max height for tables in dialogs
export const MAX_TABLE_HEIGHT_IN_DIALOG = 350;

export const ADDITIONAL_COLUMNS = {
  actionCell: "$actionCell",
} as const;

export const isRecordEmpty = (row: ModularRecord | undefined): boolean => {
  if (row) {
    return Object.values(row.properties).every((property) => isEmpty(property));
  }
  return false;
};

export const getCellFromColumnAndRecord = (
  column: KSchemaColumn,
  record: ModularRecord,
): Cell => ({ column, value: record.properties[column.key]?.value });

export const shouldCloseEditor = <T extends ModularRecord>(
  column: CalculatedColumn<T>,
  currentRow: T,
  incomingRow: T,
) => {
  const currentValue = currentRow.properties[column.key];
  const incomingValue = incomingRow.properties[column.key];

  return !isEqual(currentValue?.value, incomingValue?.value);
};

export const stabilizeIdx = <T>(item: T, idx: number) => ({ ...item, idx });
