import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectTemplatesBySchemaIdOptimictic } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.selectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { ActionAttachment } from "@kraaft/shared/core/modules/workflows/types";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { useStyles } from "@kraaft/web/src/views/settings/workflows/actions/Action.styles";

interface FilteredDropdownColumnRefProps {
  mentionPrefix: string;
  schema: KSchema;
  columnType: KColumnType;
  value: ActionAttachment;
  onChange: (columnRefs: ActionAttachment) => void;
  placeholder?: string;
}

const columnKey = (value: string) => `column:${value}`;
const isColumnKey = (value: string) => value.startsWith("column:");
const templateKey = (value: string) => `template:${value}`;
const isTemplateKey = (value: string) => value.startsWith("template:");

const FilteredDropdownColumnRef = ({
  mentionPrefix,
  schema,
  columnType,
  value,
  onChange,
  placeholder,
}: FilteredDropdownColumnRefProps) => {
  const styles = useStyles();

  const { t } = useTranslation();

  const templates = useSelector(selectTemplatesBySchemaIdOptimictic(schema.id));
  const items = useMemo(() => {
    const columns = [...KSchemaUtils.iterateColumns(schema.rootSection)].filter(
      (c) => c.type === columnType,
    );

    const templateItems = templates.map((template) => {
      const type = t("workflow.actions.email.template");
      const name = template.variant === "default" ? "Default" : template.name;
      return {
        label: `${mentionPrefix} ${type} - ${name}`,
        value: templateKey(template.id),
        inlineStyle: styles.bold,
      };
    });

    const columnItems = columns.map((c) => {
      const type = t("workflow.actions.email.column");
      return {
        label: `${mentionPrefix} ${type} - ${c.name}`,
        value: columnKey(c.key),
        inlineStyle: styles.bold,
      };
    });

    if (schema.collection === "folder") {
      return [...templateItems, ...columnItems];
    }

    return [...columnItems];
  }, [schema, columnType, mentionPrefix, styles, templates, t]);

  const internOnChange = useCallback(
    (selection: string[] | undefined) => {
      if (!selection) {
        return;
      }
      const columnKeys = selection
        .filter(isColumnKey)
        .map((s) => s.replace("column:", ""));

      const templateIds = selection
        .filter(isTemplateKey)
        .map((s) => s.replace("template:", ""));

      onChange({ columnKeys, templateIds });
    },
    [onChange],
  );

  const selected = useMemo(() => {
    const templateSelected = value.templateIds.map(templateKey);
    const columnSelected = value.columnKeys.map(columnKey);
    return [...templateSelected, ...columnSelected];
  }, [value]);

  return (
    <KDropdown
      variant="dark"
      allowMultiple
      placeholder={placeholder}
      items={items}
      onSelectionChange={internOnChange}
      selectedItemIds={selected}
    />
  );
};

export { FilteredDropdownColumnRef };
