import { createSelector } from "@reduxjs/toolkit";
import memoize from "lodash/memoize";

import { RootState } from "@kraaft/shared/core/store";
import { poolAdminAdapter } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminReducers";
import { SerializedDirectory } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminState";

const globalizedSelectors = poolAdminAdapter.getSelectors(
  (state: RootState) => state.poolAdmin,
);

export const selectPoolAdmin = (id: string | undefined) => (state: RootState) =>
  id !== undefined ? globalizedSelectors.selectById(state, id) : undefined;

const EMPTY_SERIALIZED_DIRECTORIES: SerializedDirectory[] = [];

export const selectPoolDefaultDirectories = memoize(
  (poolId: string | undefined) =>
    createSelector(selectPoolAdmin(poolId), (poolAdmin) =>
      poolAdmin
        ? poolAdmin.defaultDirectoryTree ?? EMPTY_SERIALIZED_DIRECTORIES
        : undefined,
    ),
);
