import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { poolAdminSubscription } from "./poolAdminSubscription";

export function usePoolAdminSubscription(poolId: string | undefined) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (poolId) {
      dispatch(poolAdminSubscription.actions.subscribe(poolId));
      return () => {
        dispatch(poolAdminSubscription.actions.unsubscribe(poolId));
      };
    }
  }, [dispatch, poolId]);
}
