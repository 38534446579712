import { useCallback } from "react";

import { EnterPassword } from "@kraaft/shared/components/auth/enterPassword";
import { Box } from "@kraaft/web/src/components/box";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";
import { SplitScreenBackButton } from "@kraaft/web/src/components/splitScreenWrapper/splitScreenBackButton";
import { useNavigationService } from "@kraaft/web/src/core/services/navigation/useNavigationService";

const EnterPasswordWeb = () => {
  const navigationService = useNavigationService();

  const handleBackPress = useCallback(() => {
    navigationService.goBack();
  }, [navigationService]);

  return (
    <SplitScreenWrapper>
      <Box mb="S16">
        <SplitScreenBackButton onClick={handleBackPress} />
      </Box>
      <EnterPassword />
    </SplitScreenWrapper>
  );
};

export { EnterPasswordWeb };
