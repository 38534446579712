import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    paddingTop: Spacing.S16,
    paddingLeft: Spacing.S16,
    paddingRight: Spacing.S16,
  },
  title: {
    fontSize: FontSize.BODY,
    fontWeight: "bold",
    marginRight: Spacing.S8,
  },
  subTitle: {
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  button: {
    padding: Spacing.S16,
  },
});
