import { createReducer } from "@reduxjs/toolkit";

import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { PoolMemberState } from "@kraaft/shared/core/modules/poolMember/poolMemberState";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import * as actions from "./poolMemberActions";

const initialState: PoolMemberState = {
  fetched: false,
  members: {},
};

export const poolMemberReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);

  addCase(PoolStateActions.setPoolLocation, () => initialState);

  addCase(actions.populatePoolMembers, (state, { payload: { members } }) => {
    state.fetched = true;
    state.members = members;
  });

  addCase(
    actions.removeMemberFromPoolSuccess,
    (state, { payload: { userId } }) => {
      delete state.members[userId];
    },
  );

  addCase(
    actions.updatePoolMember,
    (state, { payload: { userId, update } }) => {
      const member = state.members[userId];
      if (member) {
        state.members[userId] = { ...member, ...update };
      }
    },
  );
});
