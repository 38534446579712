import { CreatedEntry } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/renderers/createdEntry";
import { LockedEntry } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/renderers/lockedEntry";
import { PropertyChangedEntry } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/renderers/propertyChangedEntry";
import { PropertyCheckedEntry } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/renderers/propertyCheckedEntry";
import { PropertyDefinedEntry } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/renderers/propertyDefinedEntry";
import { PropertyErasedEntry } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/renderers/propertyErasedEntry";
import { PropertyUncheckedEntry } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/renderers/propertyUncheckedEntry";
import { SectionLockedEntry } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/renderers/sectionLockedEntry";
import { SectionUnlockedEntry } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/renderers/sectionUnlockedEntry";
import { UnlockedEntry } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/renderers/unlockedEntry";
import {
  ModularFolderHistoryEntry,
  ModularFolderHistoryEntryOfType,
} from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.state";
import {
  FactoryElementWithType,
  getFactoryElement,
} from "@kraaft/shared/core/utils/factoryUtils";

const modularFolderHistoryEntryRendererFactory = {
  created: CreatedEntry,
  propertyChecked: PropertyCheckedEntry,
  propertyUnchecked: PropertyUncheckedEntry,
  propertyDefined: PropertyDefinedEntry,
  propertyChanged: PropertyChangedEntry,
  propertyErased: PropertyErasedEntry,
  sectionLocked: SectionLockedEntry,
  sectionUnlocked: SectionUnlockedEntry,
  locked: LockedEntry,
  unlocked: UnlockedEntry,
} satisfies {
  [T in ModularFolderHistoryEntry["type"]]: React.FunctionComponent<{
    entry: ModularFolderHistoryEntryOfType<T>;
  }>;
};

export function getModularFolderHistoryEntryRenderer<
  T extends ModularFolderHistoryEntry,
>(modularFolderHistoryEntry: T) {
  const ModularFolderHistoryEntryRenderer = getFactoryElement(
    modularFolderHistoryEntryRendererFactory,
    modularFolderHistoryEntry.type,
  ) as FactoryElementWithType<
    typeof modularFolderHistoryEntryRendererFactory,
    T["type"]
  >;

  return (
    <ModularFolderHistoryEntryRenderer entry={modularFolderHistoryEntry} />
  );
}
