import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { compact, range } from "lodash";

import { isValidSchemaColumnForPills } from "@kraaft/shared/components/modular/pill/utils/types";
import { RoomCardDisplay } from "@kraaft/shared/components/roomCard/roomCardDisplay";
import { selectPoolCurrencyCode } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { KRoomSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  SchemaOptimisticActions,
  SchemaOptimisticOperations,
} from "@kraaft/shared/core/modules/schema/schema.optimistic";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { selectUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import { Text } from "@kraaft/ui";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";

import { mockCardProps, mockRoomRecord } from "./roomPills.utils";

import { useStyles } from "./roomPills.styles";

const PILL_COLUMNS_LENGTH = 2;

interface RoomPillsProps {
  roomSchema: KRoomSchema;
}

const RoomPills = ({ roomSchema }: RoomPillsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const poolUsers = useSelector(selectUsers);
  const currencyCode = useSelector(selectPoolCurrencyCode(roomSchema.poolId));
  const styles = useStyles();

  const roomCardProps = useMemo(() => {
    const roomRecord = mockRoomRecord(roomSchema, poolUsers);

    return mockCardProps(
      t("mockRoomLastMessage"),
      roomSchema,
      roomRecord.properties,
      roomSchema.poolId,
      poolUsers,
      roomSchema.roomCardDisplayColumns,
      currencyCode,
    );
  }, [currencyCode, poolUsers, roomSchema, t]);

  const updatePoolCardDisplay = useCallback(
    (idx: number, value: string[] | undefined) => {
      const pillColumnsCopy: (string | undefined)[] = [
        ...roomSchema.roomCardDisplayColumns,
      ];
      pillColumnsCopy[idx] = value?.[0];

      const newPillColumns = compact(pillColumnsCopy);
      dispatch(
        SchemaOptimisticActions.addOperation(
          SchemaOptimisticOperations.editMetadata.create({
            targetId: roomSchema.id,
            display: newPillColumns,
          }),
        ),
      );
    },
    [dispatch, roomSchema.id, roomSchema.roomCardDisplayColumns],
  );

  const items: FilteredListItem[][] = useMemo(() => {
    if (roomSchema) {
      const columns = KSchemaUtils.orderedColumns(roomSchema.rootSection);
      return range(PILL_COLUMNS_LENGTH).map((idx) => {
        return columns
          .filter((column) => isValidSchemaColumnForPills(column))
          .map((column) => ({
            label: column.name,
            value: column.key,
          }))
          .filter(
            (item) =>
              !roomSchema.roomCardDisplayColumns.includes(item.value) ||
              roomSchema.roomCardDisplayColumns[idx] === item.value,
          );
      });
    }
    return [[], []];
  }, [roomSchema]);

  return (
    <div>
      <div>
        {range(PILL_COLUMNS_LENGTH).map((idx) => {
          const displayItems = items[idx];
          if (displayItems) {
            return (
              <div className={styles.container} key={idx}>
                <Text size="BODY">{`Option ${idx + 1}`}</Text>
                <div className={styles.dropdownContainer}>
                  <KDropdown
                    testID={`ide2e-pillColumns-dropdown-${idx}`}
                    items={displayItems}
                    selectedItemIds={[
                      roomSchema.roomCardDisplayColumns[idx] || "",
                    ]}
                    withEmptyRow
                    disabled={
                      idx !== 0 && !roomSchema.roomCardDisplayColumns[0]
                    }
                    onSelectionChange={(newValue) =>
                      updatePoolCardDisplay(idx, newValue)
                    }
                    variant="dark"
                  />
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
      <div className={styles.mockCard}>
        <RoomCardDisplay
          description={roomCardProps.description}
          displayCardPills
          hovered={false}
          muted={false}
          pills={roomCardProps.pills}
          roomCard={roomCardProps.roomCard}
          unread={false}
          active={false}
        />
      </div>
    </div>
  );
};

export { RoomPills };
