import { useMeshContext } from "@kraaft/helper-hooks";
import { DirectoryTreeContext } from "@kraaft/web/src/components/directoryTree/directoryTreeContext";

import { ConnectedDirectoryTreeRowGroup } from "./directoryTreeRowGroup.connected";

export interface BaseDirectoryTreeRowGroupProps {
  level?: number;
  isFirstChildDirectory?: boolean;
  isGroupBeingDragged?: boolean;
}

interface DirectoryTreeRowGroupProps extends BaseDirectoryTreeRowGroupProps {
  directoryId: string;
}

const DirectoryTreeRowGroup = (props: DirectoryTreeRowGroupProps) => {
  const { directoryId } = props;

  const {
    directoryTreeStructure: { directories },
  } = useMeshContext(DirectoryTreeContext);

  const directory = directories[directoryId];

  if (directory === undefined) {
    return null;
  }

  return <ConnectedDirectoryTreeRowGroup directory={directory} {...props} />;
};

export { DirectoryTreeRowGroup };
