import { FlatList } from "react-native";

import { MenuItem } from "@kraaft/shared/components/menu/menuItem";
import { IconName } from "@kraaft/ui";

import { MenuSeparator } from "./menuSeparator";

export interface HoverableMenuItemProps {
  id: string;
  icon: IconName;
  label: string;
}

const HoverableMenu = (props: {
  items: HoverableMenuItemProps[];
  onPress: (id: string) => void;
  onHover: (id: string, value: boolean) => void;
  externallyHoveredId?: string;
}) => {
  const { items, onPress, onHover, externallyHoveredId } = props;

  const keyExtractor = (item: HoverableMenuItemProps) => item.id;

  const renderItem = ({ item }: { item: HoverableMenuItemProps }) => {
    return (
      <MenuItem
        {...item}
        onPress={onPress}
        onHover={onHover}
        isExternallyHovered={externallyHoveredId === item.id}
      />
    );
  };

  return (
    <FlatList
      keyExtractor={keyExtractor}
      data={items}
      renderItem={renderItem}
      ItemSeparatorComponent={MenuSeparator}
    />
  );
};

export { HoverableMenu };
