import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block",
    textOverflow: "ellipsis",
  },
});
