import { OfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageState";
import { getExtension } from "@kraaft/shared/core/utils";

import { TrackingEvent } from "./trackingEvent.types";

// eslint-disable-next-line complexity
export const getTrackDataSendMessageAttemptFromMessage = (
  message: OfflineMessage,
): TrackingEvent<"Send Message Attempt"> => {
  switch (message.type) {
    case "text":
      return {
        eventName: "Send Message Attempt",
        message_type: message.type,
        room_id: message.roomId,
        request_id: message.optimisticId,
        character_count: message.text.length,
      };
    case "image":
    case "video":
      return {
        eventName: "Send Message Attempt",
        message_type: message.type,
        room_id: message.roomId,
        request_id: message.optimisticId,
        character_count: message.caption?.length ?? 0,
        media_size_mb: message.file.size ?? null,
        media_extension: getExtension(message.file.filename),
      };
    case "document":
      return {
        eventName: "Send Message Attempt",
        message_type: message.type,
        room_id: message.roomId,
        request_id: message.optimisticId,
        media_size_mb: message.file.size ?? null,
        media_extension: getExtension(message.file.filename),
      };
    case "audio":
    case "geolocation":
      return {
        eventName: "Send Message Attempt",
        message_type: message.type,
        room_id: message.roomId,
        request_id: message.optimisticId,
      };
    case "forwarded":
      return {
        eventName: "Send Message Attempt",
        message_type: message.type,
        room_id: message.roomId,
        request_id: message.optimisticId,
        from_room_id: message.initialRoomId,
      };
  }
};
