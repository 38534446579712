import {
  LocalPath,
  ModernFile,
  ModernFileHelper,
} from "@kraaft/shared/core/modules/file/file";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { fileUpload } from "@kraaft/shared/core/modules/file/fileUploader";
import { imageHelper } from "@kraaft/shared/core/modules/file/imageHelper";
import { Logger } from "@kraaft/shared/core/utils/logger/logger";

export class FileUploadHelper {
  static async prepareAndUploadFile(
    uploadPath: { storagePath: string; uploadUrl: string },
    file: ModernFile<LocalPath>,
  ) {
    await fileUpload.upload({
      file,
      storagePath: uploadPath.storagePath,
      uploadUrl: uploadPath.uploadUrl,
    });
    return { file, storagePath: uploadPath.storagePath };
  }

  static logger = Logger.create("FileUploaderHelper");

  static async uploadThreeSteps<T>(
    files: Array<ModernFile<LocalPath>>,
    {
      createUploadPaths,
      onceUploaded,
    }: {
      createUploadPaths(
        files: Array<ModernFile<LocalPath>>,
      ): Promise<Array<{ storagePath: string; uploadUrl: string }>>;
      onceUploaded(
        files: Array<{ file: ModernFile<LocalPath>; storagePath: string }>,
      ): Promise<T>;
    },
  ): Promise<T> {
    const uploadPaths = await createUploadPaths(files);
    if (uploadPaths.length !== files.length) {
      throw new Error("Upload paths not equal to files");
    }

    const filesToUpload = await Promise.all(
      files.map(async (file) => {
        if (file.contentType === "image") {
          return {
            originalFile: file,
            fileToUpload: ModernFileHelper.image(
              file.filename,
              await imageHelper.resize(file.path, file.quality),
              {
                caption: file.caption,
                coordinates: file.coordinates,
                size: file.size,
                quality: file.quality,
              },
            ),
          };
        }
        return { originalFile: file, fileToUpload: file };
      }),
    );
    const newlyCreatedFiles = filesToUpload.filter(
      (file) => file.originalFile.path !== file.fileToUpload.path,
    );

    try {
      const filesWithUploadPath = filesToUpload.map((file, index) => {
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        const { storagePath, uploadUrl } = uploadPaths[index]!;
        return {
          file: file.fileToUpload,
          uploadUrl,
          storagePath,
        };
      });
      for (const { file, storagePath, uploadUrl } of filesWithUploadPath) {
        await fileUpload.upload({
          uploadUrl,
          storagePath,
          file,
        });
      }
      const result = await onceUploaded(filesWithUploadPath);

      await Promise.all(
        filesToUpload.map((file) =>
          fileAllocator.delete(file.originalFile.path),
        ),
      );

      return result;
    } finally {
      await Promise.all(
        newlyCreatedFiles.map((file) =>
          fileAllocator.delete(file.fileToUpload.path),
        ),
      );
    }
  }
}
