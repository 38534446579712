import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useSelector } from "react-redux";

import { ActionCard } from "@kraaft/shared/components/actionCard";
import { RoomNotificationPreferences } from "@kraaft/shared/components/notificationPreferences/roomNotificationPreferences";
import { SlidePanel } from "@kraaft/shared/components/slidePanel";
import { useRoomNotificationFilter } from "@kraaft/shared/core/modules/notifications/roomNotificationSheet/roomNotificationFilter.hooks";
import { getNotificationDescription } from "@kraaft/shared/core/modules/room/roomUtils";
import { selectRoomNotificationFilter } from "@kraaft/shared/core/modules/room/selectors/roomOrRoomCardSelectors";
import { selectUserPoolNotificationFilter } from "@kraaft/shared/core/modules/userPool/userPool.selectors";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";

import { styles } from "./notificationSection.styles";

interface NotificationSectionProps {
  poolId: string;
  roomId: string;
  portalHostname?: string;
  setDisplayed: () => void;
}

const NotificationSection = ({
  poolId,
  roomId,
  portalHostname,
  setDisplayed,
}: NotificationSectionProps) => {
  const { t } = useTranslation();

  const poolOption = useSelector(selectUserPoolNotificationFilter(poolId));
  const option = useSelector(selectRoomNotificationFilter(roomId));
  const [panel, openPanel, closePanel] = useBooleanState();

  const { changeNotificationFilter } = useRoomNotificationFilter({
    roomId,
    source: "room_info_tab",
  });

  useEffect(() => {
    if (changeNotificationFilter) {
      setDisplayed();
    }
  }, [setDisplayed, changeNotificationFilter]);

  if (!changeNotificationFilter) {
    return null;
  }

  return (
    <>
      <ActionCard
        text={t("notifications.title")}
        description={getNotificationDescription(t, option, poolOption)}
        action={openPanel}
        showBracket
        iconName="bell-04"
      />
      <SlidePanel
        name="RoomNotificationPreferences"
        title={t("notifications.title")}
        open={panel}
        onClose={closePanel}
        portalHostname={portalHostname}
      >
        <View style={styles.panel}>
          <RoomNotificationPreferences
            poolId={poolId}
            roomId={roomId}
            source="room_info_tab"
          />
        </View>
      </SlidePanel>
    </>
  );
};

export { NotificationSection };
