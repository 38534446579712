import { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReturnKeyTypeOptions, View } from "react-native";

import { RoundButton } from "@kraaft/shared/components/roundButton";
import {
  TextInputWithMentions,
  TextInputWithMentionsHandle,
} from "@kraaft/shared/components/textInputWithMentions/textInputWithMentions";
import { Mention } from "@kraaft/shared/core/framework/mentionnableText/mention";
import { MentionAwareText } from "@kraaft/shared/core/framework/mentionnableText/mentionAwareText";
import { IconName } from "@kraaft/ui";

import { styles } from "./textMessageInput.styles";

export interface TextMessageInputProps {
  isEditingMessage: boolean;
  isLoading?: boolean;
  placeholder?: string;
  returnKeyType?: ReturnKeyTypeOptions | undefined;
  onPublish: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  large?: boolean;
  isSubmitDisabled?: boolean;
  mentionAwareText: MentionAwareText;
  availableMentions: Mention[];
}

export interface TextMessageInputHandle {
  focus(): void;
  blur(): void;
}

const TextMessageInput = forwardRef<
  TextMessageInputHandle,
  TextMessageInputProps
>(
  (
    {
      large,
      isEditingMessage,
      placeholder,
      returnKeyType = "done",
      onPublish,
      onBlur,
      onFocus,
      isLoading,
      isSubmitDisabled,
      mentionAwareText,
      availableMentions,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const buttonIcon: IconName = isEditingMessage ? "check" : "send-03-fill";

    const inputRef = useRef<TextInputWithMentionsHandle>(null);
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
    }));

    const handleSubmit = useCallback(() => {
      if (!isSubmitDisabled) {
        onPublish();
      }
    }, [isSubmitDisabled, onPublish]);

    return (
      <View style={styles.container}>
        <TextInputWithMentions
          ref={inputRef}
          nativeID="room-text-message-input"
          mentionAwareText={mentionAwareText}
          availableMentions={availableMentions}
          onSubmit={handleSubmit}
          containerStyle={[
            styles.textInputContainer,
            large ? styles.largeTextInputContainer : undefined,
          ]}
          placeholder={placeholder || t("messageInputPlaceholder")}
          returnKeyType={returnKeyType}
          editable={!isLoading}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <View style={styles.buttonContainer}>
          <RoundButton
            accessibilityLabel={t("send")}
            large={large}
            onPress={handleSubmit}
            disabled={isSubmitDisabled}
            loading={isLoading}
            icon={buttonIcon}
          />
        </View>
      </View>
    );
  },
);

export { TextMessageInput };
