import { StyleSheet } from "react-native";

import { Color, Radius, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
  },
  signature: {
    flexGrow: 1,
    justifyContent: "center",
  },
  drawing: {
    backgroundColor: Color.WHITE,
    borderRadius: Radius.SMALL,
    marginTop: Spacing.S8,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: Color.GREY_FRENCH,
    width: "100%",
    aspectRatio: 1,
  },
  comment: {
    marginTop: Spacing.S8,
  },
  footer: {
    justifyContent: "flex-end",
  },
});
