import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
} from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";

import {
  getEnvironment,
  getHostEndpoint,
} from "@kraaft/shared/constants/environment/environment.utils";

function init() {
  const firebaseApp = initializeApp(getEnvironment().FIREBASE.APP_CONFIG);
  const db = getEnvironment().FIREBASE.EMULATOR.ENABLED
    ? initializeFirestore(firebaseApp, {
        experimentalForceLongPolling: true,
        experimentalAutoDetectLongPolling: false,
      })
    : getFirestore(firebaseApp);

  if (getEnvironment().FIREBASE.EMULATOR.ENABLED) {
    const host = getHostEndpoint() ?? "localhost";
    const auth = getAuth();
    const storage = getStorage();
    connectAuthEmulator(auth, `http://${host}:9099`);
    connectFirestoreEmulator(db, host, 8087);
    connectStorageEmulator(storage, host, 9199);
  }

  return { db, firebaseApp };
}

export const { db, firebaseApp } = init();
