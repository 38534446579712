import { useSelector } from "react-redux";

import { selectCurrentUserAtLeastStandardOrSuperadmin } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";

export function useCanEditRoomMembersOfPool(poolId: string | undefined) {
  return useSelector(selectCurrentUserAtLeastStandardOrSuperadmin(poolId));
}

export function useCanInviteRoomMembersOfPool(poolId: string | undefined) {
  return useSelector(selectCurrentUserAtLeastStandardOrSuperadmin(poolId));
}
