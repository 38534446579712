import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import isEqual from "fast-deep-equal";

import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { KSelectionListUncontrolled } from "@kraaft/shared/components/kSelectionList/kSelectionListUncontrolled";
import { constants } from "@kraaft/shared/constants/constants";
import {
  PoolInfo,
  selectUserPools,
} from "@kraaft/shared/core/modules/pool/poolAndRoomSelectors";
import { PoolLocation } from "@kraaft/shared/core/modules/pool/poolState";

import { PoolsMenuItem } from "./poolsMenuItem";

import { styles } from "./poolsMenu.styles";

interface PoolsMenuProps {
  currentPoolLocation: PoolLocation | undefined;
  onChangePoolLocation: (
    newPoolLocation: PoolLocation,
    locationName: string,
  ) => void;
  hideNotification?: boolean;
  maxHeight?: number;
}

export const PoolsMenu = ({
  currentPoolLocation,
  onChangePoolLocation,
  hideNotification,
  maxHeight,
}: PoolsMenuProps) => {
  const poolInfos = useSelector(selectUserPools);

  const items = useMemo<KSelectableListItem<string, PoolInfo>[]>(
    () =>
      poolInfos.map((poolInfo) => ({
        identifier: poolInfo.location.poolId,
        label: poolInfo.pool?.name ?? "",
        value: poolInfo,
      })),
    [poolInfos],
  );
  const currentPoolId = currentPoolLocation?.poolId;

  const changePool = useCallback(
    (location: PoolLocation, name: string) => {
      if (!isEqual(location, currentPoolLocation)) {
        onChangePoolLocation(location, name);
      }
    },
    [currentPoolLocation, onChangePoolLocation],
  );

  const handleSelectPool = useCallback(
    ([poolId]: string[]) => {
      const poolSelectionItem = items.find(
        (item) => item.identifier === poolId,
      );

      const poolInfo = poolSelectionItem?.value;

      if (poolInfo === undefined) {
        return;
      }
      setTimeout(
        () => changePool(poolInfo.location, poolInfo.pool?.name ?? ""),
        constants.POOL_SELECTION_TIME,
      );
    },
    [changePool, items],
  );

  const renderItem = useCallback(
    (item: KSelectableListItem<string, PoolInfo>) => {
      if (!item.value) {
        return <></>;
      }

      return (
        <PoolsMenuItem
          location={item.value.location}
          pool={item.value.pool}
          notifCount={hideNotification ? 0 : item.value.unreadCount}
        />
      );
    },
    [hideNotification],
  );

  const containerStyle = useMemo(
    () => [styles.list, { maxHeight }],
    [maxHeight],
  );

  return (
    <KSelectionListUncontrolled
      id="ide2e-pool-menu"
      style={containerStyle}
      items={items}
      renderItem={renderItem}
      onSelect={handleSelectPool}
      defaultValue={currentPoolId ? [currentPoolId] : undefined}
      minSelection={1}
    />
  );
};
