import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { Pill } from "@kraaft/shared/components/pill";
import { Color, ColorStyle, Text } from "@kraaft/ui";

import { styles } from "./tip.styles";

interface TipProps {
  text: string;
}

const Tip = (props: TipProps) => {
  const { text } = props;
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Pill
        color={Color.YELLOW_KRAAFT}
        icon="lightbulb-02"
        textColor={ColorStyle.FONT_HIGH_EMPHASIS}
        content={t("tips.label")}
      />
      <Text size="MEDIUM" color="FONT_HIGH_EMPHASIS" style={styles.text}>
        {text}
      </Text>
    </View>
  );
};

export { Tip };
