import { t } from "i18next";
import { call, put } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { editSchemaViewName } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { Api } from "@kraaft/shared/core/services/api";

export function* editSchemaViewNameSaga(
  action: ReturnType<typeof editSchemaViewName>,
) {
  const { payload } = action;

  try {
    yield* call(Api.editSchemaView, {
      id: payload.schemaViewId,
      editions: {
        name: payload.name,
      },
    });
  } catch (e) {
    yield* put(showError({ title: t("errorServer") }));
  }
}
