import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles(() => ({
  row: {
    display: "flex",
    alignItems: "center",
    padding: `${Spacing.S8}px 0`,
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    marginRight: Spacing.S8,
    alignItems: "center",
  },
  logo: {
    height: 24,
    aspectRatio: 1,
  },
  name: {
    flexGrow: 1,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    gap: Spacing.S12,
    margin: `0px ${Spacing.S16}px`,
  },
}));
