import StackdriverErrorReporter from "stackdriver-errors-js";

import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";

interface StartOption {
  projectId: string;
  key: string;
}

interface IStackdriverErrorReporter {
  start(options: StartOption): void;
  setUser(userId: string): void;
  report(error: Error): void;
}

let errorHandler: IStackdriverErrorReporter | undefined;
if (getEnvironment().GOOGLE.STACKDRIVER_API_KEY) {
  errorHandler = new StackdriverErrorReporter() as IStackdriverErrorReporter;
  errorHandler.start({
    projectId: getEnvironment().FIREBASE.APP_CONFIG.projectId,
    key: getEnvironment().GOOGLE.STACKDRIVER_API_KEY,
  });
}

const errorReporting = {
  setUserId: (userId: string) => {
    if (errorHandler) {
      errorHandler.setUser(userId);
    }
  },

  reportError: (error: Error, message?: string) => {
    let newError = error;
    console.log("reporting error", message, newError);
    if (errorHandler) {
      if (message) {
        newError = new Error(`${newError.message} ${message}`);
      }

      errorHandler.report(newError);
    }
  },
};

Object.freeze(errorReporting);
export { errorReporting };
