import { useCallback } from "react";

import { BaseMediaMarker } from "@kraaft/shared/components/geolocation/baseMediaMarker";
import { ClusterRendererProps } from "@kraaft/shared/components/mapController/markers/marker/cluster/cluster.types";
import { MiniImageWithGeolocation } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";

const MiniMediaCluster = (
  props: ClusterRendererProps<MiniImageWithGeolocation>,
) => {
  const { isHovered, markerCount, markers, setTracksViewChanges } = props;

  const firstMedia = markers[0]?.properties.marker.element.ref;

  const handleImageLoaded = useCallback(
    () => setTracksViewChanges?.(false),
    [setTracksViewChanges],
  );

  return (
    <BaseMediaMarker
      isHovered={isHovered}
      pillCount={markerCount}
      thumbnailUrl={firstMedia?.preview.downloadUrl}
      onImageLoaded={handleImageLoaded}
    />
  );
};

export { MiniMediaCluster };
