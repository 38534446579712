import { ConditionLibraryForType } from "@kraaft/shared/core/generated/__generated/conditionTypes";
import {
  dateIsAfter,
  dateIsBefore,
  dateIsEqual,
  dateIsEqualOrAfter,
  dateIsEqualOrBefore,
} from "@kraaft/shared/core/generated/generatedUtils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";

export const dateCondition: ConditionLibraryForType<KColumnType.date> = {
  date: {
    isEqual: {
      translationKey: "is",
      check: (lhs, rhs) => dateIsEqual(lhs.value, rhs.value),
    },
    isNotEqual: {
      translationKey: "isNot",
      check: (lhs, rhs) => !dateIsEqual(lhs.value, rhs.value),
    },
    isBefore: {
      translationKey: "isBefore",
      check: (lhs, rhs) => dateIsBefore(lhs.value, rhs.value),
    },
    isEqualOrBefore: {
      translationKey: "isEqualOrBefore",
      check: (lhs, rhs) => dateIsEqualOrBefore(lhs.value, rhs.value),
    },
    isAfter: {
      translationKey: "isAfter",
      check: (lhs, rhs) => dateIsAfter(lhs.value, rhs.value),
    },
    isEqualOrAfter: {
      translationKey: "isEqualOrAfter",
      check: (lhs, rhs) => dateIsEqualOrAfter(lhs.value, rhs.value),
    },
  },
  standalone: {
    isEmpty: {
      translationKey: "isEmpty",
      check: (value) => value.value === undefined,
    },
    isNotEmpty: {
      translationKey: "isNotEmpty",
      check: (value) => value.value !== undefined,
    },
  },
};
