import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { Button, ColorStyle, Text } from "@kraaft/ui";

import { useStyles } from "./librarySchemaBuilderHeaderName.styles";

interface FormBuilderNameHeaderFolderProps {
  schemaName: string;
}

const LibrarySchemaBuilderHeaderName = ({
  schemaName,
}: FormBuilderNameHeaderFolderProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const navigationService = useNavigationService();

  const navigate = useCallback(() => {
    navigationService.navigate("SchemaLibraryList");
  }, [navigationService]);

  return (
    <div className={styles.root}>
      <Button
        accessibilityLabel={t("goBackToList")}
        icon="chevron-left"
        contentColor={ColorStyle.FONT_HIGH_EMPHASIS}
        variant="QUATERNARY"
        onPress={navigate}
      />
      <Text size="H1">{t("formBuilder.schemaNameHeader", { schemaName })}</Text>
    </div>
  );
};

export { LibrarySchemaBuilderHeaderName };
