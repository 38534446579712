import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { compact } from "lodash";
import { Dictionary } from "ts-essentials";

import { useUserDropdownItems } from "@kraaft/shared/components/modular/details/editors/userEditor/useUserDropdownItems";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { ActionUserRef } from "@kraaft/shared/core/modules/workflows/types";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { useStyles } from "@kraaft/web/src/views/settings/workflows/actions/Action.styles";
import {
  createActionUserRefFromString,
  createColumnId,
  createIdFromActionUserRef,
  createStringId,
} from "@kraaft/web/src/views/settings/workflows/actions/FilteredDropdownActionUserRefUtils";

interface FilteredDropdownActionIdProps {
  schema: KSchema;
  items: ActionUserRef[];
  poolUsers: Dictionary<User>;
  getUsers: (userIds: string[]) => Dictionary<User>;
  poolId: string;
  onChange: (actionUserRefs: ActionUserRef[]) => void;
  mentionPrefix: string;
}

export const FilteredDropdownActionUserRef = ({
  schema,
  items,
  poolUsers: users,
  mentionPrefix,
  onChange,
  poolId,
  getUsers,
}: FilteredDropdownActionIdProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const userColumns = useMemo(
    () => [
      ...KSchemaUtils.getColumnsOfType(schema, KColumnType.user),
      ...KSchemaUtils.getColumnsOfType(schema, KColumnType.automatedCreatedBy),
    ],
    [schema],
  );

  const selectedUserIds = useMemo(
    () => items.filter((item) => item.type === "id").map((item) => item.value),
    [items],
  );

  const userItems = useUserDropdownItems({
    selectedUserIds,
    selectedUsers: getUsers(selectedUserIds),
    poolUsers: users,
    poolId,
  });

  const dropdownItems = useMemo<FilteredListItem[]>(() => {
    return [
      ...userColumns.map((c) => ({
        label: `${mentionPrefix} ${c.name}`,
        value: createColumnId(c.key),
        inlineStyle: styles.bold,
      })),
      ...userItems.map(({ value, label }) => ({
        label,
        value: createStringId(value),
      })),
    ];
  }, [userColumns, userItems, mentionPrefix, styles.bold]);

  const selectedItemIds = useMemo(
    () => compact(items.map(createIdFromActionUserRef)),
    [items],
  );

  const onSelectionChange = useCallback(
    (newValue: string[] | undefined) => {
      if (!newValue) {
        return onChange([]);
      }
      const actions = compact(
        newValue.map((value) => createActionUserRefFromString(value)),
      );
      onChange(actions);
    },
    [onChange],
  );

  return (
    <KDropdown
      placeholder={t("chooseUser")}
      items={dropdownItems}
      selectedItemIds={selectedItemIds}
      allowMultiple
      onSelectionChange={onSelectionChange}
      variant="dark"
    />
  );
};
