import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { CoupleRecordCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { selectInternalPoolUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import { FilterOperator } from "@kraaft/web/src/components/filterMenu/filterMenuTypes";
import {
  getBuiltinCondition,
  getFilterValue,
} from "@kraaft/web/src/components/filterMenu/filterUtils";

import { useStyles } from "./exportFilterItem.styles";

interface Props {
  index: number;
  columns: { [key: string]: KSchemaColumn } | undefined;
  condition: CoupleRecordCondition;
  operator: FilterOperator;
}

const ExportFilterCoupleItem = (props: Props) => {
  const { columns, condition, index, operator } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const currentPoolId = useSelector(selectCurrentPoolId);
  const users = useSelector(selectInternalPoolUsers(currentPoolId));

  const getFilterOperand = () => {
    const columnKey = condition.columnKey || "";

    return columns?.[columnKey]?.name;
  };

  const getFilterComparison = () => {
    const column = columns?.[condition.columnKey];

    if (column) {
      const builtinCondition = getBuiltinCondition(
        KColumnType[column.type],
        condition.value.columnType,
        condition.predicate,
      );

      // We have to assume the translationKey is valid
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return builtinCondition ? t(builtinCondition.translationKey as any) : "";
    }
    return "";
  };

  const column = columns?.[condition.columnKey];
  if (!column) {
    return null;
  }

  return (
    <div className={classes.container}>
      <span className={classes.operatorContainer}>
        <span className={classes.text}>
          {index === 0
            ? t("filterWhere")
            : t(operator === "and" ? "andFilter" : "orFilter")}
        </span>
      </span>

      <span className={classes.categoryContainer}>
        <span className={clsx(classes.text, classes.textHighlight)}>
          {getFilterOperand()}
        </span>
      </span>

      <span className={classes.filterContainer}>
        <span className={classes.text}>{getFilterComparison()}</span>
      </span>

      <span className={classes.valueContainer}>
        <span className={clsx(classes.text, classes.textHighlight)}>
          {getFilterValue(column, condition, users)}
        </span>
      </span>
    </div>
  );
};

export { ExportFilterCoupleItem };
