import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectIsActive } from "@kraaft/shared/core/modules/app/appSelector";

export function useAppFocused(memoizedCallback: () => void) {
  const active = useSelector(selectIsActive);

  useEffect(() => {
    if (active) {
      memoizedCallback();
    }
  }, [active, memoizedCallback]);
}
