import { CSSTransition } from "react-transition-group";
import clsx from "clsx";

import { useScaleIconAnimationStyles } from "./iconScaler.styles";

type IconScalerProps = {
  children: React.ReactNode;
  isEntered: boolean | undefined;
  className?: string;
};

const IconScaler = ({ children, isEntered, className }: IconScalerProps) => {
  const classes = useScaleIconAnimationStyles();

  return (
    <div
      className={clsx(
        className,
        classes["scale-icon"],
        isEntered ? classes["scale-icon-in"] : classes["scale-icon-out"],
      )}
    >
      <CSSTransition in={isEntered} classNames="scale-icon" timeout={250}>
        {children}
      </CSSTransition>
    </div>
  );
};

export { IconScaler };
