import { put, select, takeLatest } from "typed-redux-saga/macro";

import { FilterStateActions } from "@kraaft/shared/core/modules/filter/filterActions";
import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { UserStateActions } from "@kraaft/shared/core/modules/user/userActions";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";

export function* setFiltersForSupportSaga() {
  yield* takeLatest(
    [PoolStateActions.setPoolLocation, UserStateActions.loggedUserReceived],
    handleSetFiltersForSupport,
  );
}

function* handleSetFiltersForSupport() {
  const currentPoolId = yield* select(selectCurrentPoolId);
  const isUserSuperadmin = yield* select(selectCurrentUserIsSuperadmin);

  if (!isUserSuperadmin || !currentPoolId) {
    return;
  }

  yield* put(
    FilterStateActions.setRoomFilterVisibility({
      visibility: "all",
      poolId: currentPoolId,
    }),
  );
}
