import { StyleSheet } from "react-native";
import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize } from "@kraaft/ui";

export const useStyles = makeStyles({
  dialogContentContainer: {
    flex: 1,
  },
  text: {
    fontSize: FontSize.MEDIUM,
    lineHeight: "17 / 14",
    fontWeight: "normal",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  errorText: {
    fontSize: FontSize.SMALL,
    color: ColorStyle.ACTION_DESCTRUCTIVE,
  },
  spacer: {
    marginBottom: 22,
  },
});

export const stylesheet = StyleSheet.create({
  tokenInput: {
    marginBottom: 8,
  },
});
