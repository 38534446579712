import { useTrackEventOnceConditionnally } from "@kraaft/shared/core/utils/tracking/useTrackEvent";

export const useTrackSearchModularFolders = ({
  roomId,
  schemaId,
  schemaName,
  searchText,
}: {
  roomId: string;
  schemaId: string;
  schemaName: string;
  searchText: string | undefined;
}) => {
  useTrackEventOnceConditionnally(
    {
      eventName: "Search ModularFolders",
      room_id: roomId,
      schema_id: schemaId,
      schema_name: schemaName,
    },
    (searchText?.length ?? 0) > 0,
  );
};
