import { call, put } from "typed-redux-saga/macro";

import {
  UserActions,
  UserStateActions,
} from "@kraaft/shared/core/modules/user/userActions";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { takeLeadingDeep } from "@kraaft/shared/core/utils/sagas";

export function* loadUserSaga() {
  yield takeLeadingDeep(
    UserActions.loadUser,
    (action) => action.payload,
    loadUser,
  );
}

function* loadUser(action: ReturnType<typeof UserActions.loadUser>) {
  const users = yield* call(() => Firestore.getUnknownUsers([action.payload]));
  yield* put(UserStateActions.addUnknownUsers(users));
}
