import { useMemo } from "react";
import { useSelector } from "react-redux";

import { ModularFolderMap } from "@kraaft/shared/components/mapController/modularFolderMap/modularFolderMap";
import { ModularFolderContext } from "@kraaft/shared/components/modularFolders/modularFolderContext";
import { OfflineModularFolderSelectors } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { ModularFolderUseMapNavigationReturnType } from "@kraaft/shared/core/utils/useMapNavigation/types";

interface Props {
  folderId: string;
}

const ModularFolderPreview = (props: Props) => {
  const { folderId } = props;

  const modularFolder = useSelector(
    OfflineModularFolderSelectors.select(folderId),
  );

  if (!modularFolder) {
    return null;
  }

  return (
    <ModularFolderContext.Provider value={{ preview: true }}>
      <ModularFolderPreviewComponent modularFolder={modularFolder} />
    </ModularFolderContext.Provider>
  );
};

interface ModularFolderPreviewComponentProps {
  modularFolder: ModularFolder;
}

const ModularFolderPreviewComponent = (
  props: ModularFolderPreviewComponentProps,
) => {
  const { modularFolder } = props;

  const mapNavigation: ModularFolderUseMapNavigationReturnType = useMemo(
    () => ({
      modularFolderId: modularFolder.id,
      schemaId: modularFolder.schemaId,
      roomId: modularFolder.roomId,
      geoDetail: "modularFolder",
      isOpen: true,
      withCloseButton: false,
      setOpen: () => {},
      onClose: () => {},
      onMarkerAction: () => {},
      isMapFullscreen: false,
    }),
    [modularFolder.id, modularFolder.roomId, modularFolder.schemaId],
  );

  return (
    <ModularFolderMap
      mapNavigation={mapNavigation}
      withCloseButton={false}
      withDetail={true}
    />
  );
};

export { ModularFolderPreview };
