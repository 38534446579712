import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { IconName } from "@kraaft/ui";

interface PlaceSelectionHeaderProps {
  title: string;
  editTitle?: string;
  closeIcon: IconName;
  hasExistingLocation: boolean;
  isEditing: boolean;
  isOnlyEditable?: boolean;
  onCancelEditing: () => void;
  onClose?: () => void;
}

export const PlaceSelectionHeader = ({
  title,
  editTitle,
  closeIcon,
  hasExistingLocation,
  isEditing,
  isOnlyEditable,
  onCancelEditing,
  onClose,
}: PlaceSelectionHeaderProps) => {
  const { t } = useTranslation();

  const headerTitle = useMemo(() => {
    if (hasExistingLocation && isEditing) {
      return editTitle;
    }

    return title;
  }, [editTitle, hasExistingLocation, isEditing, title]);

  return (
    <DefaultLayoutHeader
      headerTitle={headerTitle}
      headerLeft={
        <HeaderSide>
          {isEditing && !isOnlyEditable ? (
            <HeaderIconButton
              accessibilityLabel={t("close")}
              icon="x-close"
              onPress={onCancelEditing}
            />
          ) : (
            <HeaderIconButton
              accessibilityLabel={t("close")}
              icon={closeIcon}
              onPress={onClose}
            />
          )}
        </HeaderSide>
      }
    />
  );
};
