import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KDialogProps } from "@kraaft/shared/components/kDialog/kDialogProps";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { Text } from "@kraaft/ui";

interface Props {
  onClose: () => void;
}

export const AddSchemaFromPaperDialogContent = (props: Props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const pool = useSelector(selectCurrentPool);
  const currentUser = useSelector(selectCurrentUser);

  const mailSubject = encodeURIComponent(
    t("schemasView.addDialogFromPaper.email.subject"),
  );
  const mailBody = encodeURIComponent(
    t("schemasView.addDialogFromPaper.email.body", {
      poolName: pool?.name,
      userName: currentUser?.username,
    }),
  );

  const actions: KDialogProps["actions"] = [
    {
      text: t("schemasView.addDialogFromPaper.emailLink"),
      onPress: () => {
        window.open(
          `mailto:contact@kraaft.co?subject=${mailSubject}&body=${mailBody}`,
          "_blank",
        );
      },
    },
    {
      text: t("cancel"),
      onPress: onClose,
    },
  ];

  return (
    <KDialogContent
      title={t("schemasView.addDialogFromPaper.title")}
      actions={actions}
      content={
        <Text size="MEDIUM" color="FONT_HIGH_EMPHASIS">
          {t("schemasView.addDialogFromPaper.subtitle")}
        </Text>
      }
    />
  );
};
