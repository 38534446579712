import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    paddingTop: Spacing.S24,
    paddingLeft: Spacing.S32,
    paddingRight: Spacing.S32,
    maxWidth: 700,
  },
  section: {
    marginBottom: Spacing.S32,
    "& > :first-child": {
      display: "block",
      marginBottom: Spacing.S16,
    },
  },
  marginBottom: {
    display: "block",
    marginBottom: Spacing.S12,
  },
});
