import { eventChannel } from "redux-saga";
import { call, put, select, takeLatest } from "typed-redux-saga/macro";

import { showInfo } from "@kraaft/shared/core/modules/alert/alertActions";
import { NotificationBody } from "@kraaft/shared/core/modules/notifications/incomingNotifications.types";
import { notifications } from "@kraaft/shared/core/modules/notifications/notifications.provider";
import { startOnPool } from "@kraaft/shared/core/modules/pool/poolActions";
import {
  selectCurrentPoolId,
  selectOnePool,
} from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectRoom } from "@kraaft/shared/core/modules/room/roomSelectors";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { navigationService } from "@kraaft/shared/core/services/navigation/provider";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

export function* navigateOnNotificationClickSaga() {
  yield* call(() => notifications.ensureInitialized());

  const channel = eventChannel((emit) =>
    notifications.addNotificationClickListener<NotificationBody>(emit),
  );
  yield* takeLatest(channel, handleClick);
}

function* handleClick(body: NotificationBody) {
  if (!navigationService.isReady()) {
    return;
  }
  if (body.type === "room" || body.type === "message") {
    let room = yield* select(selectRoom(body.room_id));

    if (!room) {
      [room] = yield* call(() => Firestore.getRooms([body.room_id]));
    }

    if (!room) {
      return;
    }

    const currentPoolId = yield* waitFor(selectCurrentPoolId);
    const roomPool = yield* waitFor(selectOnePool(room.poolId));

    if (currentPoolId !== roomPool.id) {
      yield* put(startOnPool({ poolId: roomPool.id }));
      yield* put(
        showInfo({ title: i18n.t("poolChanged", { name: roomPool.name }) }),
      );
    }

    navigationService.navigate("Conversation", {
      roomId: body.room_id,
      preserveDetails: true,
    });
  }
}
