import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { useUserContactInfos } from "@kraaft/shared/components/userContactInfoSheet/useUserContactInfos";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { CurrentUser } from "@kraaft/shared/core/modules/user/userState";
import { withDependencyWrapper } from "@kraaft/shared/core/utils/withDepedencyWrapper";
import { FakeTextInputWithLabel, Spacing } from "@kraaft/ui";

interface MyProfileDetailsProps {
  /** injected */
  currentUser: CurrentUser;
}

const MyProfileDetails_ = ({ currentUser }: MyProfileDetailsProps) => {
  const { t } = useTranslation();

  const { userContactInfos, status } = useUserContactInfos(currentUser.id);

  return (
    <View style={styles.container}>
      <FakeTextInputWithLabel
        label={t("myProfile.firstName")}
        value={currentUser?.firstName ?? currentUser?.username}
      />
      <FakeTextInputWithLabel
        label={t("myProfile.lastName")}
        value={currentUser?.lastName}
      />
      <FakeTextInputWithLabel
        label={t("myProfile.phoneNumber")}
        value={userContactInfos?.phone}
        loading={status === LoaderStatus.LOADING}
      />
      <FakeTextInputWithLabel
        label={t("myProfile.email")}
        value={userContactInfos?.email}
        loading={status === LoaderStatus.LOADING}
      />
    </View>
  );
};

export const MyProfileDetails = withDependencyWrapper(MyProfileDetails_, () => {
  const currentUser = useSelector(selectCurrentUser);

  if (currentUser === undefined) {
    return undefined;
  }

  return { currentUser };
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: Spacing.S16,
    minWidth: 305,
  },
});
