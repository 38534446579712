import { StyleSheet, View } from "react-native";

import { MapView } from "@kraaft/shared/components/mapController/map";
import { NoMarkers } from "@kraaft/shared/components/mapController/noMarkers/noMarkers";
import {
  DistributedMapMarker,
  MapProps,
} from "@kraaft/shared/components/mapController/types";
import { Preloader } from "@kraaft/ui";

export type MapOrNoMarkersProps<Markers extends DistributedMapMarker> =
  MapProps<Markers> & {
    isLoading?: boolean;
    mapControllerPortalHostname?: string;
  };

const MapOrNoMarkers = <Markers extends DistributedMapMarker>({
  markers,
  geoDetail,
  isLoading,
  mapControllerPortalHostname,
  ...props
}: MapOrNoMarkersProps<Markers>) => {
  const hasNoMarkers = markers.length === 0;

  if (isLoading) {
    return (
      <View style={styles.container}>
        <Preloader absoluteFill transparent />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <MapView
        {...props}
        markers={markers}
        geoDetail={geoDetail}
        mapControllerPortalHostname={mapControllerPortalHostname}
      />
      {hasNoMarkers && <NoMarkers geoDetail={geoDetail} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
});

export { MapOrNoMarkers };
