/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "@reduxjs/toolkit";
import { compact, memoize } from "lodash";

import {
  OptimisticWorkspace,
  WAggregation,
  WOperation,
} from "@kraaft/shared/core/utils/optimistic/types";

export function createOptimisticSelectors<
  W extends OptimisticWorkspace<any>,
  S,
>(
  workspace: W,
  selectOptimisticState: (state: S) => {
    operations: Record<string, WOperation<W>>;
  },
  selectCurrentState: (state: S) => Record<string, WAggregation<W>>,
) {
  const selectBuilt = memoize((id: string) =>
    createSelector(
      selectCurrentState,
      selectOptimisticState,
      (currentState, optimisticState): WAggregation<W> | undefined => {
        const existing = currentState[id];
        if (!existing) {
          return undefined;
        }
        // Used to create redux up to date result
        const builder = workspace.createOptimisticBuilder(
          Object.values(optimisticState.operations),
        );
        return builder.build(existing) as WAggregation<W>;
      },
    ),
  );

  const selectAllBuilt = createSelector(
    selectCurrentState,
    selectOptimisticState,
    (currentState, optimisticState): WAggregation<W>[] => {
      // Used to create redux up to date result

      const builder = workspace.createOptimisticBuilder(
        Object.values(optimisticState.operations),
      );
      return compact(
        Object.values(currentState).map(builder.build.bind(builder)),
      ) as WAggregation<W>[];
    },
  );

  return { selectBuilt, selectAllBuilt };
}
