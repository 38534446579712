import { MarkerColors } from "@kraaft/shared/components/mapController/markers/marker/marker.types";
import { Color, ColorStyle } from "@kraaft/ui";

export const markerSize = { height: 50, width: 35 };

export const markerSelectedScale = 1.2;

export const defaultBaseMarkerColors: {
  [key in "base" | "hover" | "select"]: Required<MarkerColors>;
} = {
  base: {
    bodyColor: Color.WHITE,
    strokeColor: Color.GREY_TAUPE,
  },
  hover: {
    bodyColor: ColorStyle.ACTION_CTA,
    strokeColor: ColorStyle.ACTION_CTA,
  },
  select: {
    bodyColor: ColorStyle.ACTION_CTA,
    strokeColor: Color.WHITE,
  },
};
