import { StyleSheet } from "react-native";

import { ColorStyle, webOnlyStyle } from "@kraaft/ui";

import { nativeStylesObject } from "./kTextInput.styles.native";
export * from "./kTextInput.styles.native";

const styles = StyleSheet.create({
  ...nativeStylesObject,
  textInput: {
    ...webOnlyStyle({ outlineWidth: 0 }),
    overflow: "hidden",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  overflowing: {
    overflow: "auto" as "visible", // Fix IE => 'auto' is not a valid property in React-Native
  },
});

export { styles };
