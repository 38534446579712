import { createReducer } from "@reduxjs/toolkit";
import { keyBy } from "lodash";
import { combineReducers } from "redux";

import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { RoomSchemaVisibilityOptimisticReducer } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.optimistic";
import { RoomSchemaVisibilityState } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.state";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import * as actions from "./roomSchemaVisibility.actions";

const initialState: RoomSchemaVisibilityState = {
  rooms: {},
};

const roomSchemaVisibilityReducers = createReducer(
  initialState,
  ({ addCase }) => {
    addCase(
      actions.RoomSchemaVisibilityStateActions.set,
      (state, { payload }) => {
        state.rooms = keyBy(
          payload.data,
          (roomSchemaVisibility) => roomSchemaVisibility.roomId,
        );
      },
    );
    addCase(
      actions.RoomSchemaVisibilityStateActions.setWithMerge,
      (state, { payload }) => {
        for (const roomSchemaVisibility of payload.data) {
          state.rooms[roomSchemaVisibility.roomId] = roomSchemaVisibility;
        }
      },
    );

    addCase(UserActions.userDisconnectedFromFirebase, () => initialState);
    addCase(PoolStateActions.setPoolLocation, () => initialState);
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OptimisticReducer = RoomSchemaVisibilityOptimisticReducer as any;
const reducer = combineReducers({
  data: roomSchemaVisibilityReducers,
  optimistic: OptimisticReducer,
});

export { reducer as roomSchemaVisibilityReducers };
