import { useCallback, useEffect, useState } from "react";

import { GeolocationDetailStatus } from "@kraaft/shared/core/utils/useMapNavigation/types";
import { MapLayout } from "@kraaft/web/src/components/geolocation/types";

type LayoutMemory = Record<GeolocationDetailStatus, MapLayout | null>;

const useMapLayoutMemory = (
  geoDetail: GeolocationDetailStatus,
  defaultMapLayout: MapLayout,
) => {
  const [layout, setLayout] = useState<MapLayout>(defaultMapLayout);
  const [layoutMemory, setLayoutMemory] = useState<LayoutMemory>({
    modularFolder: null,
    room: null,
    rooms: null,
  });

  // Restore layout
  useEffect(() => {
    const layoutForDetails = layoutMemory[geoDetail];
    if (layoutForDetails) {
      setLayout(layoutForDetails);
    }
    switch (geoDetail) {
      case "rooms":
        layoutMemory.room = null;
        layoutMemory.modularFolder = null;
        break;
      case "room":
        layoutMemory.modularFolder = null;
        break;
      default:
        break;
    }
  }, [geoDetail, layoutMemory, setLayout]);

  const updateLayout = useCallback(
    (newLayout: MapLayout) => {
      setLayoutMemory((oldLayoutMemory) => ({
        ...oldLayoutMemory,
        [geoDetail]: newLayout,
      }));
      setLayout(newLayout);
    },
    [geoDetail],
  );

  return { layout, updateLayout };
};

export { useMapLayoutMemory };
