import { createContext, ReactNode } from "react";

export type ModalContextType<Props> = {
  open: (key: string, element: ReactNode, options: Props) => void;
  close: (key: string) => void;
};

export function createModalContext<Props>(type: string) {
  return createContext({
    open: () => {
      throw new Error(`No Provider for ${type} in tree`);
    },
    close: () => {},
  } as ModalContextType<Props>);
}
