import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { useGetOrLoadUsers } from "@kraaft/shared/core/modules/user/useGetOrLoadUsers";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import {
  selectUserContactInfosById,
  selectUserContactInfosStatusById,
  UserContactInfos,
  UserContactInfosSlice,
} from "@kraaft/shared/core/modules/userContactInfos";

export function useUserContactInfos(userId: string) {
  const getOrLoadUsers = useGetOrLoadUsers();
  const user = getOrLoadUsers([userId])[userId];
  const currentPoolId = useSelector(selectCurrentPoolId);
  const isOutsider = !!currentPoolId && !user?.pools[currentPoolId];
  const userContactInfos = useSelector(selectUserContactInfosById(userId));
  const poolId = useSelector(selectCurrentPoolId);
  const status = useSelector(selectUserContactInfosStatusById(userId));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      dispatch(UserActions.loadUser(userId));
    }
  }, [dispatch, user, userId]);

  useEffect(() => {
    if (!poolId || isOutsider) {
      return;
    }
    dispatch(
      UserContactInfosSlice.actions.getUserContactInfos({ userId, poolId }),
    );
  }, [dispatch, userId, poolId, isOutsider]);

  const partialContactInfo = useMemo<UserContactInfos | undefined>(() => {
    if (!user) {
      return undefined;
    }
    return {
      userId: userId,
      username: user.username,
      firstName: user.firstName ?? "",
      lastName: user.lastName ?? "",
      email: undefined,
      phone: undefined,
    };
  }, [user, userId]);

  return {
    userContactInfos: userContactInfos || partialContactInfo,
    isOutsider: isOutsider,
    status,
  };
}
