import { SimpleFolderCard } from "@kraaft/shared/components/mapController/markers/modularFolderMarker/simpleFolderCardForMaps";

import { styles } from "./folderCardTooltip.styles";

interface FolderCardTooltipProps {
  schemaId: string;
  modularFolderId: string;
  onPress?: () => void;
}
const FolderCardTooltip = (props: FolderCardTooltipProps) => {
  const { schemaId, modularFolderId, onPress } = props;

  return (
    <SimpleFolderCard
      schemaId={schemaId}
      modularFolderId={modularFolderId}
      onPress={onPress}
      style={styles.card}
    />
  );
};

export { FolderCardTooltip };
