import { createSelector } from "@reduxjs/toolkit";

function selectState(state: any) {
  return state.dummy;
}

export const selectRawDummies = createSelector(
  selectState,
  (state) => state.dummies,
);
