import { keyBy } from "lodash";
import { EventChannel, eventChannel } from "redux-saga";
import { put, takeEvery } from "typed-redux-saga/macro";

import { Dummy } from "@kraaft/shared/core/modules/dummy/dummy";
import { DummyActions } from "@kraaft/shared/core/modules/dummy/dummy.actions";
import { DummyStateActions } from "@kraaft/shared/core/modules/dummy/dummy.bundle";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { takeCounted } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToDummiesSaga() {
  yield takeCounted(
    DummyActions.subscribe,
    DummyActions.unsubscribe,
    subscribe,
    unsubscribe,
  );
}

type ChannelPayload = Dummy[];

function* subscribe(
  registerMeta: (meta: EventChannel<ChannelPayload>) => void,
) {
  console.log("Subscribe");
  const channel = eventChannel<ChannelPayload>((emit) =>
    Firestore.subscribeToDummies(emit),
  );
  registerMeta(channel);
  yield* takeEvery(channel, function* (dummies: Dummy[]) {
    yield* put(DummyStateActions.receive(keyBy(dummies, (dummy) => dummy.id)));
  });
}

function* unsubscribe(meta: EventChannel<ChannelPayload> | undefined) {
  meta?.close();
}
