import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  selector: {
    flexGrow: 1,
  },
  emptyPanel: {
    flex: 1,
  },
  emptyState: {
    flex: 1,
    justifyContent: "center",
  },
});
