import { StyleSheet } from "react-native";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

const styles = StyleSheet.create({
  screen: {
    flexGrow: 1,
  },
  map: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
  addressContainer: {
    marginHorizontal: Spacing.S16,
    marginTop: Spacing.S16,
    marginBottom: Spacing.S12,
    flexDirection: "row",
    alignItems: "center",
  },
  addressLabel: {
    flexGrow: 1,
    flexShrink: 1,
    fontSize: FontSize.BODY,
    fontWeight: "500",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    marginLeft: Spacing.S8,
  },
  buttonsContainer: {
    flexDirection: "column",
    gap: Spacing.S12,
    padding: Spacing.S16,
  },
});

export { styles };
