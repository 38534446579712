import { useCallback, useMemo } from "react";
import { StrictOmit } from "ts-essentials";

import {
  KDropdown,
  KDropdownProps,
} from "@kraaft/web/src/components/dropdown/kDropdown";
import timeZones from "@kraaft/web/src/views/settings/managePool/settingsTimeZone/timeZones.json";

import { FilteredListItem } from "../../../../components/dropdown/filteredList/filteredList";

interface Props
  extends StrictOmit<
    KDropdownProps,
    "items" | "onSelectionChange" | "selectedItemIds" | "withSearchBar"
  > {
  timeZone: string;
  onChange(timeZone: string): void;
}

const TimeZoneDropdown = ({ timeZone, onChange, ...dropdownProps }: Props) => {
  const items = useMemo<FilteredListItem[]>(
    () =>
      timeZones.map((tz) => ({
        value: tz,
        label: tz,
      })),
    [],
  );

  const selected = useMemo(() => [timeZone], [timeZone]);

  const onSelectionChange = useCallback(
    (value: string[] | undefined) => {
      if (value?.[0]) {
        onChange(value[0]);
      }
    },
    [onChange],
  );

  return (
    <KDropdown
      {...dropdownProps}
      items={items}
      selectedItemIds={selected}
      onSelectionChange={onSelectionChange}
      withSearchBar
    />
  );
};

export { TimeZoneDropdown };
