import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  subscribeToPoolRooms,
  unsubscribeFromPoolRooms,
} from "@kraaft/shared/core/modules/room/roomActions";
import { selectRoomsByPool } from "@kraaft/shared/core/modules/room/roomSelectors";
import { KRoomSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";

export function useSubscribeToRoomsWithSchema(roomSchema: KRoomSchema) {
  const dispatch = useDispatch();

  const poolId = roomSchema.poolId;
  const rooms = useSelector(selectRoomsByPool(roomSchema.poolId));

  useEffect(() => {
    if (poolId) {
      dispatch(subscribeToPoolRooms({ poolId }));
    }
    return () => {
      if (poolId) {
        dispatch(unsubscribeFromPoolRooms({ poolId }));
      }
    };
  }, [dispatch, poolId]);
  return rooms;
}
