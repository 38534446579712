import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultLayoutHeader,
  DefaultLayoutHeaderProps,
} from "@kraaft/shared/components/defaultLayoutHeader";
import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { SearchHeader } from "@kraaft/shared/components/searchHeader/searchHeader";
import { IconName } from "@kraaft/ui";

interface Props extends DefaultLayoutHeaderProps {
  id?: string;
  leftIcon?: IconName;
  onFilterSet: (filter: string) => void;
  onGoBackPress?: () => void;
  placeholder: string;
  onQuitSearch?: () => void;
}

const SimpleSearchHeader = ({
  id,
  leftIcon = "chevron-left",
  onFilterSet,
  onGoBackPress,
  placeholder,
  headerRight,
  onQuitSearch,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const renderHeaderLeft = () => {
    if (onGoBackPress !== undefined) {
      return (
        <HeaderSide>
          <HeaderIconButton
            accessibilityLabel={t("close")}
            id={id || "ide2e-simple-search-header-back"}
            icon={leftIcon}
            onPress={onGoBackPress}
          />
        </HeaderSide>
      );
    }
    return undefined;
  };

  const renderHeaderRight = useCallback(
    (onSearch: () => void) => {
      return (
        <HeaderSide>
          <HeaderIconButton
            accessibilityLabel={t("search")}
            icon="search-md"
            onPress={onSearch}
          />
          {headerRight}
        </HeaderSide>
      );
    },
    [headerRight, t],
  );

  return (
    <SearchHeader
      onFilterSet={onFilterSet}
      placeholder={placeholder}
      autoFocus
      onQuitSearch={onQuitSearch}
    >
      {(onSearch) => (
        <DefaultLayoutHeader
          headerLeft={renderHeaderLeft()}
          headerRight={renderHeaderRight(onSearch)}
          {...props}
        />
      )}
    </SearchHeader>
  );
};

export { SimpleSearchHeader };
