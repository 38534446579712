import React from "react";
import ReactIs from "react-is";

import { AnyUnexplained } from "@kraaft/shared/core/types";

import {
  AllowedChildren,
  ChildrenForwardRef,
  Content,
} from "./filterNullableChildren.types";
import { shouldConstruct } from "./filterNullableChildren.utils";

const filterNullableChildren = (...children: AllowedChildren[]) => {
  const nullableChildren = React.Children.map(children, getNullableChild);

  const visibleChildren = nullableChildren
    ? nullableChildren.filter(shouldRenderContent)
    : null;

  return React.Children.count(visibleChildren) > 0 ? visibleChildren : null;
};

const getNullableChild = (children: AllowedChildren) => {
  if (!React.isValidElement(children)) {
    return null;
  }
  let content: Content = null;

  const elementType: typeof children.type = ReactIs.isMemo(children)
    ? children.type.type
    : children.type;

  if (typeof elementType === "string") {
    content = React.createElement(elementType, children.props);
  } else if (shouldConstruct(elementType)) {
    // When Component is Class Component
    const instance = new elementType(children.props);

    // `render` is not present on Mobile but is there on Web
    if (typeof instance.render === "function") {
      content = instance.render();
    } else {
      content = instance as unknown as React.ReactNode;
    }
  } else if (ReactIs.isForwardRef(children)) {
    // When Component is ForwardRef
    const childrenTyped = children as ChildrenForwardRef;

    content = childrenTyped.type.render.call(
      undefined,
      children.props,
      childrenTyped.ref,
    );
  } else if (typeof elementType === "function") {
    // When Component is Function Component
    content = elementType((children as AnyUnexplained).props);
  } else {
    console.warn(
      "getNullableChild :: Unknown elementType :",
      typeof elementType,
      elementType,
    );
  }

  return shouldRenderContent(content) ? content : null;
};

const shouldRenderContent = (content: Content) => {
  return !(
    typeof content === "boolean" ||
    content === null ||
    content === undefined ||
    Object.keys(content).length === 0 ||
    (Array.isArray(content) && content.length === 0)
  );
};

export { filterNullableChildren };
