import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";

import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { AnyUnexplained } from "@kraaft/shared/core/types";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button, Text } from "@kraaft/ui";
import { AddReportTemplateDialog } from "@kraaft/web/src/components/kDialog/customs";
import { OrderableList } from "@kraaft/web/src/components/orderableList";
import { OrderedListRows } from "@kraaft/web/src/components/orderableList/orderableList.types";
import { prepareForOrderableList } from "@kraaft/web/src/components/orderableList/orderableList.utils";
import { OrderableReportTemplateItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editReportTemplates/orderableReportTemplateItem";
import { Prop } from "@kraaft/web/src/components/schemaBuilder/tabs/editReportTemplates/orderableReportTemplateItem/orderableReportTemplateItem";

import { useStyles } from "./editReportTemplates.styles";

export type EditReportTemplatesProps = {
  schema: KSchema;
  reportTemplates: ReportTemplate[];
  onReorder?: (ordered: OrderedListRows<ReportTemplate>) => void;
} & Prop<typeof AddReportTemplateDialog, "onAdd"> &
  Prop<
    typeof OrderableReportTemplateItem,
    "onToggle" | "onRename" | "onReplaceFile" | "onRemove" | "onDownload"
  >;

const EditReportTemplates = ({
  schema,
  reportTemplates: templates,
  onAdd,
  onReorder,
  onToggle,
  onRename,
  onReplaceFile,
  onRemove,
  onDownload,
}: EditReportTemplatesProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [
    isAddReportTemplateDialogOpen,
    openAddReportTemplateDialog,
    closeAddReportTemplateDialog,
  ] = useBooleanState();
  const templatesAsObject = useMemo(() => {
    let i = 0;

    return prepareForOrderableList(
      sortBy(templates, (template) => template.index),
      (template) => ({
        key: template.id,
        index: i++,
        data: {
          template,
          onToggle,
          onRename,
          onReplaceFile,
          onRemove,
          onDownload,
        },
      }),
    );
  }, [templates, onToggle, onRename, onReplaceFile, onRemove, onDownload]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.section}>
          <Text size="H2" weight="bold">
            {t("formBuilder.reports.label")}
          </Text>
          <OrderableList
            testId="ide2e-schemas-sortable-list"
            rows={templatesAsObject}
            updateRows={(newRows) => {
              onReorder?.(
                Object.entries(newRows).reduce(
                  (acc, [key, value]) => {
                    (acc as AnyUnexplained)[key] = {
                      ...value.data.template,
                      index: value.index,
                    };
                    return acc;
                  },
                  {} as OrderedListRows<ReportTemplate>,
                ),
              );
            }}
            RowRenderer={OrderableReportTemplateItem}
            rowContainerClassName={classes.rowContainer}
            withHandle
          />
          <div className={classes.footerContainer}>
            <Button
              accessibilityLabel={t("addTemplate")}
              text={t("addTemplate")}
              onPress={openAddReportTemplateDialog}
            />
          </div>
        </div>
      </div>
      <AddReportTemplateDialog
        schemaId={schema.id}
        open={isAddReportTemplateDialogOpen}
        onClose={closeAddReportTemplateDialog}
        onAdd={onAdd}
      />
    </>
  );
};

export { EditReportTemplates };
