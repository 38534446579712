import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { RootState } from "@kraaft/shared/core/store";
import {
  createEntryKeyForSchemaViews,
  RecordWorkspace,
} from "@kraaft/web/src/core/modules/memory/memoryUtils";

export const selectSchemaViewState = ({ schemaView }: RootState) => schemaView;

export const selectSchemaViews = createSelector(
  selectSchemaViewState,
  (state) => Object.values(state.schemaViews),
);

export const selectSchemaView = memoize((id: string) =>
  createSelector(selectSchemaViewState, (state) => state.schemaViews[id]),
);

export const selectEditedSchemaViews = createSelector(
  selectSchemaViewState,
  (state) => state.unsavedSchemaViews,
);

// Do not use this except for specific purposes
export const selectUnsavedSchemaViewFromRawId = memoize((rawId: string) =>
  createSelector(
    selectSchemaViewState,
    (state) => state.unsavedSchemaViews[rawId],
  ),
);

export const selectNonCreatedSchemaView = (
  workspace: RecordWorkspace,
  schemaId: string,
) =>
  selectUnsavedSchemaViewFromRawId(
    createEntryKeyForSchemaViews(workspace, schemaId),
  );

export const selectEditedSchemaView = (schemaViewId: string | undefined) =>
  selectUnsavedSchemaViewFromRawId(schemaViewId ?? "");
