import { CurrentUser, User } from "@kraaft/shared/core/modules/user/userState";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function isUserSuperadmin(user: CurrentUser | undefined) {
  return user?.superRole === "administrator";
}

export function getUsername(user: User | undefined) {
  if (user?.username) {
    return user.username;
  }
  return i18n.t("userNonRecognized");
}
