import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import compact from "lodash/compact";

import { MessageActionFlowProps } from "@kraaft/shared/components/galleries/messageActionFlow/messageActionFlowProps";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";
import { forwardMessageSelection } from "@kraaft/shared/core/modules/message/messageActions";
import {
  selectIsInSelectionFlow,
  selectMessageSelectionContainsCaption,
  selectMessageSelectionCount,
  selectMessageSelectionSource,
} from "@kraaft/shared/core/modules/message/messageSelectors";
import { selectRoomIsExternal } from "@kraaft/shared/core/modules/room/roomSelectors";
import { conditionalEntry } from "@kraaft/shared/core/utils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { PreserveCaptionStepParams } from "@kraaft/web/src/components/multiStepDialog/customs/forwardMessageSelectionSteps/preserveCaptionStep/preserveCaptionStep";
import { ensureStepType } from "@kraaft/web/src/components/multiStepDialog/multiStepDialogUtils";

import { MultiStepDialog } from "../../multiStepDialog";
import { PreserveCaptionStep } from "./preserveCaptionStep";
import { SelectRoomStep, SelectRoomStepParams } from "./selectRoomStep";

const ForwardMessageSelectionSteps = (props: MessageActionFlowProps) => {
  const { onClose, roomId, onSuccess } = props;
  const dispatch = useDispatch();

  const selectionSource = useSelector(selectMessageSelectionSource(roomId));
  const open = useSelector(
    selectIsInSelectionFlow({
      roomId,
      selectionSource: undefined,
      selectionType: "forward",
    }),
  );

  const isExternal = useSelector(selectRoomIsExternal(roomId));

  const selectedCount = useSelector(
    selectMessageSelectionCount(roomId, undefined),
  );

  const doesMessageSelectionContainCaption = useSelector(
    selectMessageSelectionContainsCaption(roomId),
  );
  const includePreserveCaptionStep =
    doesMessageSelectionContainCaption || selectionSource === "photoGallery";

  const handleForward = useCallback(
    (selectedRoomId: string, preserveCaptions = false) => {
      trackEvent({
        eventName: "Forward Messages",
        room_id: roomId,
        is_shared: isExternal,
        message_count: selectedCount,
        target_room_id: selectedRoomId,
      });

      dispatch(
        forwardMessageSelection({
          roomId,
          destRoomId: selectedRoomId,
          preserveCaptions,
        }),
      );
      onSuccess?.();
    },
    [dispatch, isExternal, onSuccess, roomId, selectedCount],
  );

  const generateSteps = useMemo(() => {
    const steps = compact([
      ensureStepType<SelectRoomStepParams>({
        dialog: SelectRoomStep,
        dialogFrameProps: { fullHeight: true, size: KDialogSize.MEDIUM },
        params: {
          roomId,
          handleForward,
          includePreserveCaptionStep,
        },
      }),
      conditionalEntry(
        ensureStepType<PreserveCaptionStepParams>({
          dialog: PreserveCaptionStep,
          params: { handleForward },
        }),
        includePreserveCaptionStep,
      ),
    ]);

    return steps;
  }, [includePreserveCaptionStep, handleForward, roomId]);

  return (
    <MultiStepDialog open={open} onClose={onClose} steps={generateSteps} />
  );
};

export { ForwardMessageSelectionSteps };
