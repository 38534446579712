import { useMeshContext } from "@kraaft/helper-hooks";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularColumnsContext } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.optimistic";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { InsertElementDropItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";
import { ElementDragLayer } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDragLayer";
import { ElementPickerDrop } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementPickerDrop";
import { getTransitionFromIndexes } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditor.utils";
import { ElementsEditorBottomItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditorBottomItem";
import { ElementsEditorSectionItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditorSectionItem";
import {
  SchemaBuilderContext,
  useSchemaBuilderRootSection,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

import { useStyles } from "./elementsEditor.styles";

interface ElementCreationBase {
  placement: HoverState;
  inSection?: string;
}

export interface SectionCreation extends ElementCreationBase {
  type: "section";
  section: KSchemaSection;
}

export interface ColumnCreation extends ElementCreationBase {
  type: "column";
  column: KSchemaColumn;
}

export type ElementCreation = SectionCreation | ColumnCreation;

interface ColumnsEditorProps {
  availableColumnTypes: KColumnType[];
  columnsContext: ModularColumnsContext;
}

const START_SHOWING_MAX_AUTHORIZED_THRESHOLD = 200;
const MAX_AUTHORIZED_ELEMENT_COUNT = 300;

const ElementsEditor = ({
  availableColumnTypes,
  columnsContext,
}: ColumnsEditorProps) => {
  const styles = useStyles();
  const {
    addElement,
    currentHoveredKey,
    setCurrentHoveredKey,
    currentDraggedKey,
  } = useMeshContext(SchemaBuilderContext);
  const rootSection = useSchemaBuilderRootSection();

  const addOfType = useCallbackRealtime(
    ([_currentHoveredKey], item: InsertElementDropItem | undefined) => {
      if (!item || !_currentHoveredKey) {
        return;
      }
      if (item.insertType === "column") {
        addElement(
          { type: "column", columnType: item.columnType },
          _currentHoveredKey,
        );
      } else {
        addElement({ type: "section" }, _currentHoveredKey);
      }
    },
    [addElement],
    [currentHoveredKey],
  );

  const setLastKey = useCallbackRealtime(
    ([_rootSection]) => {
      const columns = Object.values(_rootSection.elements).sort(
        KSchemaUtils.orderByIndex,
      );
      const lastColumn = columns[columns.length - 1];
      if (!lastColumn) {
        return;
      }
      setCurrentHoveredKey((old) => {
        if (old?.key !== lastColumn.key || old.placement !== "after") {
          return { key: lastColumn.key, placement: "after" };
        }
        return old;
      });
    },
    [setCurrentHoveredKey],
    [rootSection],
  );

  const lastElementIndex = KSchemaUtils.getSectionLength(rootSection);
  const hoverKeyIndex = Object.values(rootSection.elements)
    .sort(KSchemaUtils.orderByIndex)
    .findIndex((e) => e.key === currentHoveredKey?.key);
  const draggedKeyIndex = Object.values(rootSection.elements)
    .sort(KSchemaUtils.orderByIndex)
    .findIndex((e) => e.key === currentDraggedKey);
  const transition = getTransitionFromIndexes(
    currentHoveredKey,
    lastElementIndex - 1,
    hoverKeyIndex,
    draggedKeyIndex,
  );

  const columnCount = KSchemaUtils.getVisibleColumnCount(rootSection);

  return (
    <ElementPickerDrop className={styles.root} onDrop={addOfType}>
      <ElementsEditorSectionItem
        availableColumnTypes={availableColumnTypes}
        columnsContext={columnsContext}
        hideParentSection
        section={rootSection}
      />
      <ElementsEditorBottomItem
        setLastKey={setLastKey}
        addAtLast={addOfType}
        transition={transition}
      />
      {columnCount > START_SHOWING_MAX_AUTHORIZED_THRESHOLD ? (
        <div className={styles.counter}>
          {columnCount} / {MAX_AUTHORIZED_ELEMENT_COUNT}
        </div>
      ) : null}
      <ElementDragLayer />
    </ElementPickerDrop>
  );
};

export { ElementsEditor };
