import { PropsWithChildren, useMemo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { Radius } from "@kraaft/ui";

type PreviewSquareProps = {
  style?: StyleProp<ViewStyle>;
};

export const PREVIEW_IMAGES_MAX_WIDTH = 56;

const PreviewSquare = ({
  children,
  style,
}: PropsWithChildren<PreviewSquareProps>) => {
  const containerStyle = useMemo(
    () => StyleSheet.flatten([styles.square, style]),
    [style],
  );
  return <View style={containerStyle}>{children}</View>;
};

const styles = StyleSheet.create({
  square: {
    flexGrow: 1,
    maxWidth: PREVIEW_IMAGES_MAX_WIDTH,
    aspectRatio: 1,
    borderRadius: Radius.SMALL,
    overflow: "hidden",
  },
});

export { PreviewSquare };
