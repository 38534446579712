import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  NativeSyntheticEvent,
  Platform,
  ReturnKeyTypeOptions,
  StyleProp,
  StyleSheet,
  Text,
  TextInputChangeEventData,
  TextInputSelectionChangeEventData,
  TextStyle,
  ViewStyle,
} from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { MentionPaper } from "@kraaft/shared/components/textInputWithMentions/mentionPaper";
import { useForceNativeTextUpdate } from "@kraaft/shared/components/textInputWithMentions/useForceNativeTextUpdate";
import { useMentionInputKeyPressHandler } from "@kraaft/shared/components/textInputWithMentions/useMentionInputKeyPressHandler";
import { MentionPaperHandle } from "@kraaft/shared/components/textInputWithMentions/useNavigateMentions.props";
import { usePluginMentions } from "@kraaft/shared/components/textInputWithMentions/usePluginMentions";
import { Segment } from "@kraaft/shared/core/framework/markedText/markedText";
import { Mention } from "@kraaft/shared/core/framework/mentionnableText/mention";
import { MentionAwareText } from "@kraaft/shared/core/framework/mentionnableText/mentionAwareText";
import {
  Color,
  ColorStyle,
  executeAfterStatePropagation,
  FontSize,
  Spacing,
  TextInputWithAutoSize,
  TextInputWithAutoSizeHandle,
} from "@kraaft/ui";

interface TextSegmentProps {
  segment: Segment;
  style?: TextStyle;
}

const TextSegment = ({ segment, style }: TextSegmentProps) => {
  const textContentForSegment = useMemo(() => {
    if (segment.type === "marker") {
      return segment.marker.renderText();
    }
    return segment.text;
  }, [segment]);
  const textColorForSegment = useMemo(() => {
    if (segment.type === "marker") {
      if (segment.marker.type === "user-mention") {
        return Color.BLUE_KRAAFT;
      }
      if (segment.marker.type === "everyone-mention") {
        return Color.BLUE_KRAAFT;
      }
    }
    return ColorStyle.FONT_HIGH_EMPHASIS;
  }, [segment]);

  return (
    <Text style={[{ color: textColorForSegment }, style]}>
      {textContentForSegment}
    </Text>
  );
};

export interface TextInputWithMentionsHandle {
  focus(): void;
  blur(): void;
}

export interface TextInputWithMentionsProps {
  mentionAwareText: MentionAwareText;
  availableMentions: Mention[];
  onSubmit?: () => void;
  placeholder?: string;
  nativeID?: string;
  inputStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  returnKeyType?: ReturnKeyTypeOptions;
  editable?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const TextInputWithMentions = forwardRef<
  TextInputWithMentionsHandle,
  TextInputWithMentionsProps
>(
  (
    {
      mentionAwareText,
      availableMentions,
      onSubmit,
      inputStyle,
      containerStyle,
      placeholder,
      nativeID,
      editable,
      onBlur,
      onFocus,
    },
    ref,
  ) => {
    const mentionPaperRef = useRef<MentionPaperHandle>(null);
    const textInputRef = useRef<TextInputWithAutoSizeHandle>(null);

    useImperativeHandle(ref, () => ({
      focus: () => textInputRef.current?.focus(),
      blur: () => textInputRef.current?.blur(),
    }));

    const focusInputAtIndex = useCallback((index: number) => {
      if (!textInputRef.current) {
        return;
      }
      if (!isNative()) {
        textInputRef.current.focus();
      }
      executeAfterStatePropagation(() => {
        textInputRef.current?.setSelection(index, index);
      });
    }, []);

    const { insertMention, filteredMentions } = usePluginMentions(
      mentionAwareText,
      availableMentions,
      focusInputAtIndex,
    );

    const [focused, setFocused] = useState(false);

    const handleFocus = useCallback(() => {
      onFocus?.();
      setFocused(true);
    }, [onFocus]);

    const handleBlur = useCallback(() => {
      onBlur?.();
      setFocused(false);
    }, [onBlur]);

    const handleChange = useCallback(
      (event: NativeSyntheticEvent<TextInputChangeEventData>) => {
        mentionAwareText.ingestText(event.nativeEvent.text);
      },
      [mentionAwareText],
    );

    const handleSelectionChange = useCallback(
      (event: NativeSyntheticEvent<TextInputSelectionChangeEventData>) => {
        mentionAwareText.updateSelection(event.nativeEvent.selection);
      },
      [mentionAwareText],
    );

    const onMentionClick = useCallback(
      (mention: Mention) => {
        insertMention(mention);
      },
      [insertMention],
    );

    const onKeyPress = useMentionInputKeyPressHandler({
      onSubmit,
      textInputRef,
      mentionPaperRef,
    });

    const forceNativeUpdateStyle = useForceNativeTextUpdate(mentionAwareText);

    const renderedAsText = mentionAwareText.getText();

    return (
      <>
        <MentionPaper
          ref={mentionPaperRef}
          getUsernameFromUserId={mentionAwareText.getUsernameFromUserId}
          mentions={filteredMentions}
          onClickMention={onMentionClick}
          inputFocused={focused}
        />
        <TextInputWithAutoSize
          ref={textInputRef}
          inputStyle={[styles.textInputBase, styles.input, inputStyle]}
          containerStyle={containerStyle}
          accessibilityLabel=""
          nativeID={nativeID}
          editable={editable}
          multiline
          onKeyPress={onKeyPress}
          value={Platform.select({
            web: renderedAsText,
            ios: undefined,
            android: undefined,
          })}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onSelectionChange={handleSelectionChange}
          onChange={handleChange}
          onSubmitEditing={onSubmit}
          autoComplete="off"
          keyboardType="default"
        >
          <Text style={styles.textInputBase}>
            {mentionAwareText.getSegments().map((segment) => (
              <TextSegment
                key={segment.id}
                segment={segment}
                style={forceNativeUpdateStyle}
              />
            ))}
          </Text>
        </TextInputWithAutoSize>
      </>
    );
  },
);

const styles = StyleSheet.create({
  input: {
    paddingTop: Spacing.S8,
    paddingBottom: Spacing.S8,
    paddingLeft: Spacing.S16,
    paddingRight: Spacing.S16,
  },

  textInputBase: {
    fontFamily: "Apercu",
    fontWeight: "400",
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
});
