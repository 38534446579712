import { useMemo } from "react";

import { Placement } from "@kraaft/helper-types";
import { Sheet } from "@kraaft/ui";

type PopupMenuProps = {
  children: React.ReactNode;
  anchorRef?: React.RefObject<Element>;
  open: boolean;
  onClose: () => void;
  withAnchorWidth?: boolean;
  placement?: Placement;
};

const PopupMenuSheet = Sheet({ web: "anchored" });

const PopupMenu = (props: PopupMenuProps) => {
  const { anchorRef, open, onClose, children, withAnchorWidth, placement } =
    props;

  const parentWidth = useMemo(() => {
    if (withAnchorWidth) {
      const width = anchorRef?.current?.clientWidth ?? 0;

      return width;
    }
    return undefined;
    // Open is in dependency here to ensure the ref is set
    // In the first render, the ref does not exist
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorRef, withAnchorWidth, open]);

  return anchorRef ? (
    <PopupMenuSheet.Host
      open={open}
      onClose={onClose}
      anchor={anchorRef}
      placement={placement}
    >
      {/* eslint-disable-next-line @kraaft/self-closing-sheet-child */}
      <PopupMenuSheet.Paper width={parentWidth} noPadding>
        {children}
      </PopupMenuSheet.Paper>
    </PopupMenuSheet.Host>
  ) : null;
};

export { PopupMenu };
