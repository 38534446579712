import { DifferenceBackgroundProps } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceBackground";
import { AttachmentDifference } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/renderers/atttachmentDifference";
import { CurrencyDifference } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/renderers/currencyDifference";
import { DateDifference } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/renderers/dateDifference";
import { GeolocationDifference } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/renderers/geolocationDifference";
import { NumberDifference } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/renderers/numberDifference";
import { SelectDifference } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/renderers/selectDifference";
import { SignatureDifference } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/renderers/signatureDifference";
import { TextDifference } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/renderers/textDifference";
import { UserDifference } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/renderers/userDifference";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  ModularRecordValue,
  ModularRecordValueOfType,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { Identity } from "@kraaft/ui";

type BaseRecordPropertyDifferenceRender<T extends KColumnType> =
  React.FunctionComponent<
    DifferenceBackgroundProps & {
      value: ModularRecordValueOfType<T>;
      schemaColumn: KSchemaColumn<T> | undefined;
    }
  >;

const recordPropertyDifferenceRendererFactory = {
  join: Identity,
  attachment: AttachmentDifference,
  checkbox: Identity,
  currency: CurrencyDifference,
  date: DateDifference,
  geolocation: GeolocationDifference,
  longText: TextDifference,
  number: NumberDifference,
  shortText: TextDifference,
  user: UserDifference,
  signature: SignatureDifference,
  roomMembers: Identity,
  roomName: Identity,
  selectMultiple: SelectDifference,
  selectSingle: SelectDifference,
  automatedCreatedBy: UserDifference,
  automatedCreatedAt: DateDifference,
  automatedAutoIncrement: TextDifference,
} satisfies {
  [T in KColumnType]: BaseRecordPropertyDifferenceRender<T>;
};

export function getRecordPropertyDifferenceRenderer(
  valueType: KColumnType,
  value: ModularRecordValue,
  schemaColumn: KSchemaColumn | undefined,
  differenceBackgroundProps: DifferenceBackgroundProps,
) {
  const RecordPropertyDifferenceRenderer =
    recordPropertyDifferenceRendererFactory[
      valueType
    ] as BaseRecordPropertyDifferenceRender<typeof valueType>;

  return (
    <RecordPropertyDifferenceRenderer
      value={value}
      schemaColumn={schemaColumn}
      {...differenceBackgroundProps}
    />
  );
}
