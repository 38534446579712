import { createMeshContext } from "@kraaft/helper-hooks";

export type VerticalNavigationContextValue = {
  isExpanded: boolean;
  overrideExpanded: (state: boolean) => void;
  isPoolSelectionOpened: boolean;
  setPoolSelectionOpened: (state: boolean) => void;
};

export const VerticalNavigationContext =
  createMeshContext<VerticalNavigationContextValue>();
