import { makeStyles } from "@mui/styles";

import { Color, ColorStyle } from "@kraaft/ui";

export const useStyles = makeStyles({
  hoveredContainer: {
    "& > *": {
      "&:hover": {
        backgroundColor: ColorStyle.ACTION_HOVERED,
        border: `1px solid ${Color.BLUE_COOL}`,
      },
    },
  },
});
