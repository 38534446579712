import { StyleSheet } from "react-native";

import { ColorStyle } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    marginTop: 2,
    flexDirection: "row",
    alignItems: "center",
  },
  retryText: {
    color: ColorStyle.ACTION_DESCTRUCTIVE,
    fontFamily: "Apercu-Bold",
    fontSize: 10,
  },
});
