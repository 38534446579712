import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { SearchBar } from "@kraaft/shared/components/searchBar";
import { setRoomFilterSearchText } from "@kraaft/shared/core/modules/filter/filterActions";
import { selectPoolFilterState } from "@kraaft/shared/core/modules/filter/filterSelectors";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";

import { styles } from "./roomSearchHeader.styles";

interface Props {
  headerRight?: ReactNode;
}

export const RoomSearchHeader = (props: Props) => {
  const { headerRight } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const poolId = useSelector(selectCurrentPoolId);

  const { searchText } = useSelector(selectPoolFilterState(poolId));

  const handleSearchText = useCallback(
    (value: string) => {
      if (!poolId) {
        return;
      }

      dispatch(
        setRoomFilterSearchText({
          poolId,
          searchText: value,
        }),
      );
    },
    [dispatch, poolId],
  );

  const handleClearText = useCallback(() => {
    if (!poolId) {
      return;
    }

    dispatch(setRoomFilterSearchText({ poolId, searchText: "" }));
  }, [dispatch, poolId]);

  return (
    <DefaultLayoutHeader
      centerHeader={false}
      disableSafeAreaBehavior
      header={
        <View style={styles.inputContainer}>
          <SearchBar
            onChange={handleSearchText}
            value={searchText ?? ""}
            placeholder={t("searchRoom")}
            onClearSearch={handleClearText}
            isStatic
            withPlaceholderIcon
          />
        </View>
      }
      headerRight={headerRight}
      headerOverflow
    />
  );
};
