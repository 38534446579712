import { useCallback, useMemo, useState } from "react";
import { cloneDeep, merge } from "lodash";
import { DeepPartial } from "ts-essentials";

const getDefaultState = <T, U extends Record<string, T>>() =>
  ({}) as { [K in keyof U]: DeepPartial<U[K]> };

export const useRevertibleFolderUpdate = <T, U extends Record<string, T>>(
  record: U,
) => {
  const [alterations, setAlterations] = useState(getDefaultState<T, U>());

  const alterRecord = useCallback(
    <K extends keyof U>(key: K, value: DeepPartial<U[K]>) => {
      setAlterations((oldAlterations) => {
        oldAlterations[key] = value;
        return { ...oldAlterations };
      });
    },
    [],
  );

  const revertRecordChanges = useCallback(<K extends keyof U>(key: K) => {
    setAlterations((oldAlterations) => {
      delete oldAlterations[key];
      return { ...oldAlterations };
    });
  }, []);

  const alteredRecord = useMemo<U>(() => {
    const newRecord = cloneDeep(record);
    if (Object.keys(alterations).length === 0) {
      return newRecord;
    }
    return merge(newRecord, alterations);
  }, [alterations, record]);

  return [alteredRecord, alterRecord, revertRecordChanges] as const;
};
