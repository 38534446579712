import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { ModularFoldersMenuButton } from "@kraaft/shared/components/modularFolders/modularFoldersMenuButton";
import { SimpleSearchHeader } from "@kraaft/shared/components/searchHeader/simpleSearchHeader";
import { selectModularFoldersSummary } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectSchemaIcon } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { ColorStyle, FontSize, Icon, Spacing, Text } from "@kraaft/ui";

interface Props {
  roomId: string;
  schemaId: string;
  onFilterSet: (search: string) => void;
  isStickyPanel?: boolean;
  onBackButtonPress: () => void;
  hideBackButton?: boolean;
}

export const ModularFoldersDefaultHeader = ({
  onBackButtonPress,
  isStickyPanel,
  hideBackButton,
  roomId,
  schemaId,
  onFilterSet,
}: Props) => {
  const { t } = useTranslation();

  const title = useSelector(selectModularFoldersSummary(roomId, schemaId));
  const icon = useSelector(selectSchemaIcon(schemaId));

  return (
    <SimpleSearchHeader
      onFilterSet={onFilterSet}
      placeholder={t("search")}
      header={
        <View style={styles.titleBox}>
          {icon && <Icon name={KSchemaUtils.getSchemaIconName(icon)} />}
          <Text
            weight="medium"
            numberOfLines={1}
            ellipsizeMode="tail"
            style={styles.title}
          >
            {title}
          </Text>
        </View>
      }
      headerLeft={
        !hideBackButton && (
          <HeaderSide>
            <HeaderIconButton
              id="ide2e-header-back"
              accessibilityLabel={t("close")}
              icon={isStickyPanel ? "x-close" : "chevron-left"}
              onPress={onBackButtonPress}
            />
          </HeaderSide>
        )
      }
      headerRight={
        <HeaderSide>
          <ModularFoldersMenuButton roomId={roomId} schemaId={schemaId} />
        </HeaderSide>
      }
    />
  );
};

// Inspired from defaultLayoutHeader
const styles = StyleSheet.create({
  titleBox: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  title: {
    fontSize: FontSize.TITLE,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    textAlign: "center",
    ...(!isNative() ? { userSelect: "none" } : {}),
    marginLeft: Spacing.S8,
  },
});
