import { memo } from "react";

import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame";
import { KDialogProps } from "@kraaft/shared/components/kDialog/kDialogProps";

type kDialogWebProps = KDialogProps;

const KDialog_ = ({
  open,
  onClose,
  title,
  subtitle,
  content,
  leftActions,
  actions,
  size,
  fullScreen,
  containerStyle,
  fullHeight,
}: kDialogWebProps) => {
  return (
    <KDialogFrame
      {...{ open, onClose, size, fullScreen, fullHeight, containerStyle }}
    >
      <KDialogContent
        {...{
          onClose,
          title,
          subtitle,
          content,
          leftActions,
          actions,
          fullScreen,
        }}
      />
    </KDialogFrame>
  );
};

export const KDialog = memo(KDialog_);
