import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Action } from "redux";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { AnyUnexplained, TranslationKey } from "@kraaft/shared/core/types";

export function useApiError(error: unknown, message?: TranslationKey) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (error) {
      const translated = t((message as AnyUnexplained) || "serverError");
      setErrorMessage(translated);
      dispatch(showError({ title: translated }));
    } else {
      setErrorMessage("");
    }
  }, [error, dispatch, message, t]);

  return errorMessage;
}

const DEBUG = false;

export function useAlertError(message?: TranslationKey) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return useCallback(
    (error: AnyUnexplained, overrideMessage?: TranslationKey) => {
      if (error) {
        const m = overrideMessage || message;
        const translated = t((m as AnyUnexplained) || "serverError");
        dispatch(
          showError({
            title: DEBUG
              ? "message" in error
                ? error.message
                : translated
              : translated,
          }),
        );
      }
    },
    [dispatch, t, message],
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApi<T extends (...args: any[]) => Promise<any>>(fn: T) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<Awaited<ReturnType<T>>>();
  const [error, setError] = useState<Error>();
  const call = useCallback(
    async (...args: Parameters<T>) => {
      setLoading(true);

      try {
        const r = await fn(...args);
        setLoading(false);
        setResult(r);
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    },
    [fn],
  );

  return [call, { loading, result, error }] as const;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApiQuery<T extends () => Promise<any>>(fn: T) {
  const [call, options] = useApi(fn);
  useEffect(() => {
    (call as any)().catch((e: Error) => console.error(e));
  }, [call]);

  return options;
}

// Be sure to memo the optimistic object here
export function useOptimisticApi<T extends (...args: any[]) => Promise<any>>(
  fn: T,
  optimistic: {
    success: (...args: Parameters<T>) => Action;
    rollback: () => Action;
  },
) {
  const dispatch = useDispatch();

  const [call, options] = useApi(fn);

  useEffect(() => {
    if (options.error) {
      dispatch(optimistic.rollback());
      dispatch(showError({ title: options.error.message }));
    }
  }, [dispatch, optimistic, options.error]);

  const optimisticCall = useCallback(
    async (...args: Parameters<T>) => {
      dispatch(optimistic.success(...args));
      await call(...args);
    },
    [dispatch, optimistic, call],
  );

  return [optimisticCall, options] as const;
}
