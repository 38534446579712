import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    marginBottom: Spacing.S24,
  },
  dropdownContainer: {
    marginTop: Spacing.S8,
  },
  mockCard: {
    marginTop: Spacing.S40,
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.12)",
  },
});
