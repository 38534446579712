import { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";

import { ColorStyle, Spacing } from "@kraaft/ui";

const DetailsSection = ({ children }: PropsWithChildren) => {
  return <View style={styles.container}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
    padding: Spacing.S16,
    gap: Spacing.S16,
  },
});

export { DetailsSection };
