import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useSelector } from "react-redux";

import { selectMessageSelection } from "@kraaft/shared/core/modules/message/messageSelectors";
import { Button, IconName } from "@kraaft/ui";

import { styles } from "./conversationFooter.styles";

interface Props {
  roomId: string;
  hasSelection: boolean;
  selectionCount: number;
  onSubmitSelection: () => void;
  onCloseSelection: () => void;
}

const ConversationFooter = (props: Props) => {
  const { t } = useTranslation();
  const {
    roomId,
    hasSelection,
    selectionCount,
    onSubmitSelection,
    onCloseSelection,
  } = props;

  const messageSelection = useSelector(
    selectMessageSelection(roomId, "conversation"),
  );

  const buttonConfig = useMemo<
    { iconName?: IconName; label: string } | undefined
  >(() => {
    switch (messageSelection.selectionType) {
      case "attach":
        return {
          label: t("attachTo"),
        };
      case "forward":
        return {
          label: t("forwardMessage"),
        };
      default:
        break;
    }
    return undefined;
  }, [messageSelection.selectionType, t]);

  return hasSelection && buttonConfig ? (
    <View style={styles.footerMessageSelected}>
      <Button
        accessibilityLabel={t("cancel")}
        text={t("cancel")}
        onPress={onCloseSelection}
        variant="SECONDARY"
      />
      <Button
        icon={buttonConfig.iconName}
        accessibilityLabel={buttonConfig.label}
        text={buttonConfig.label}
        disabled={selectionCount === 0}
        onPress={onSubmitSelection}
      />
    </View>
  ) : null;
};

export { ConversationFooter };
