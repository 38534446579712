import { useCallback, useState } from "react";

import { BaseMediaMarker } from "@kraaft/shared/components/geolocation/baseMediaMarker";
import { markerSize } from "@kraaft/shared/components/geolocation/baseMediaMarker/baseMediaMarkerConfig";
import { GeolocatedElement } from "@kraaft/shared/components/geolocation/types";
import { Marker } from "@kraaft/shared/components/mapController/markers/marker";
import { MarkerContentRenderer } from "@kraaft/shared/components/mapController/markers/marker/marker.types";
import { MiniImageWithGeolocation } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";

interface ModularFolderMarkerProps {
  geolocatedElement: GeolocatedElement<MiniImageWithGeolocation>;
  onMarkerAction?: (mediaId: string) => void;
}

export const MiniMediaMarker = ({
  geolocatedElement,
  onMarkerAction,
}: ModularFolderMarkerProps) => {
  const [isReady, setReadyState] = useState(false);

  const imageUrl = geolocatedElement.ref.preview.downloadUrl;

  const handleImageLoaded = useCallback(
    () => setReadyState?.(true),
    [setReadyState],
  );

  const onPress = useCallback(() => {
    onMarkerAction?.(geolocatedElement.ref.messageId);
  }, [onMarkerAction, geolocatedElement.ref.messageId]);

  const renderMarkerContent: MarkerContentRenderer = ({
    isHovered,
    isSelected,
  }) => (
    <BaseMediaMarker
      isHovered={isHovered}
      isSelected={isSelected}
      thumbnailUrl={imageUrl}
      onImageLoaded={handleImageLoaded}
    />
  );

  return imageUrl ? (
    <Marker
      id={geolocatedElement.id}
      size={markerSize}
      preventSelection
      forceMarkerActionAndroid
      coordinates={geolocatedElement.ref.geolocation.coords}
      testID="ide2e-modularFolder-marker"
      onMarkerAction={onMarkerAction ? onPress : undefined}
      renderContent={renderMarkerContent}
      tracksViewChanges={!isReady}
    />
  ) : null;
};
