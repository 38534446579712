import { useEffect, useState } from "react";

export function useTimedOut(ms: number, enabled = true) {
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    const id = setTimeout(() => setTimedOut(true), ms);
    return () => {
      clearTimeout(id);
    };
  }, [ms, enabled]);

  return timedOut;
}
