import { useCallback } from "react";

import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";

import { useStyles } from "./userEditor.styles";

export const UserEditorInternal = (props: {
  onClose: () => void;
  onChange: (value: string[] | undefined) => void;
  users: FilteredListItem[];
  allowMultiple: boolean;
  value: string[];
}) => {
  const { onClose, onChange, users, allowMultiple, value } = props;
  const classes = useStyles();

  const handleSelectionChange = useCallback(
    (newValue: string[] | undefined) => {
      onChange(newValue);

      if (!allowMultiple) {
        onClose();
      }
    },
    [onChange, allowMultiple, onClose],
  );

  return (
    <div className={classes.container}>
      <KDropdown
        items={users}
        allowMultiple={allowMultiple}
        selectedItemIds={value}
        alwaysOpen
        onSelectionChange={handleSelectionChange}
        onClose={onClose}
        withEmptyRow={!allowMultiple}
        withSearchBar
      />
    </div>
  );
};
