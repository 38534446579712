import { createAction } from "@reduxjs/toolkit";

import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { RoomFilters } from "@kraaft/shared/core/modules/filter/filterState";
import { UploadAttachmentContext } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { createLoaderMeta } from "@kraaft/shared/core/modules/loaders/loaderUtils";
import { RoomTypes } from "@kraaft/shared/core/modules/room";
import { RoomPageResults } from "@kraaft/shared/core/modules/room/data/roomDataState";
import {
  GeolocationAction,
  Room,
  RoomMapDisplayMode,
  RoomModularRecord,
  RoomNotificationFilter,
  RoomUserHistory,
  RoomVisibility,
} from "@kraaft/shared/core/modules/room/roomState";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { GeoLocation, Thunk } from "@kraaft/shared/core/types";

export const PERSIST_ROOM_LOADING_ID = "persist-temporary-room";

export interface SubscribeToRoomsPayload {
  poolId: string;
  filters: RoomFilters;
  limit: number | undefined;
}

export const subscribeToPoolRooms = createAction<{ poolId: string }>(
  "@room/SUBSCRIBE_TO_POOL_ROOMS",
);

export const unsubscribeFromPoolRooms = createAction<{ poolId: string }>(
  "@room/UNSUBSCRIBE_FROM_POOL_ROOMS",
);

export const updateRoomsLoading = createAction<{
  filterId: string;
  isLoading: boolean;
  hasError: boolean;
}>("@room/UPDATE_FIRST_ROOMS_LOADING");

export interface RoomsLoadedPayload {
  data: {
    room: RoomTypes.Room | undefined; // undefined when the room was already loaded before
    userHistory: RoomTypes.RoomUserHistory | undefined;
  }[];
  pageResults: RoomPageResults;
  filterId: string;
}

export const firstRoomsLoaded = createAction<RoomsLoadedPayload>(
  "@room/FIRST_ROOMS_LOADED",
);

export const changeRoomUsersRequest = createAction(
  "@room/CHANGE_ROOM_USERS_REQUEST",
  (payload: {
    roomId: string;
    addedUserIds?: string[];
    removedUserIds?: string[];
  }) => ({
    payload,
    meta: { thunk: true },
  }),
);

export const changeRoomUsersFailure = createAction(
  "@room/CHANGE_ROOM_USERS_FAILURE",
  (payload: string, meta: Thunk) => ({ payload, meta, error: true }),
);

export interface ImportRoomsPayload {
  poolId: string;
  rooms: {
    row: number;
    record: ModularRecord;
    checkboxTitles: string[];
    memberIds: string[];
  }[];
}

export const importRoomsLoaderId = "importRooms";
export const importRooms = createAction(
  "@room/IMPORT_ROOMS",
  (payload: ImportRoomsPayload) => ({
    payload,
    meta: createLoaderMeta({
      id: importRoomsLoaderId,
      status: LoaderStatus.LOADING,
    }),
  }),
);

export const cleanAllRooms = createAction("@room/CLEAN_ALL_ROOMS");

export interface SetArchivePayload {
  roomId: string;
  isArchived: boolean;
  rollbackIsArchived: boolean;
}

export interface UpdateRoomRecordPayload {
  roomId: string;
  update: Partial<RoomModularRecord["properties"]>;
}

export const updateRoomRecord = createAction<UpdateRoomRecordPayload>(
  "@room/UPDATE_ROOM_RECORD_REQUEST",
);

interface UpdateRoomRecordFailurePayload {
  roomId: string;
  rollback: RoomModularRecord["properties"];
}
export const updateRoomRecordFailure = createAction(
  "@room/UPDATE_ROOM_FAILURE",
  (error: Error, payload: UpdateRoomRecordFailurePayload) => ({
    payload,
    error,
  }),
);

export const markRoomUnread = createAction<{
  roomId: string;
  currentValue: boolean;
}>("@room/MARK_ROOM_UNREAD");

export const markRoomUnreadFailure = createAction<{
  roomId: string;
  oldValue: boolean;
}>("@room/MARK_ROOM_UNREAD_FAILURE");

export const markRoomRead = createAction<{
  roomId: string;
  currentValue: boolean;
}>("@room/MARK_ROOM_READ");

export const markRoomReadFailure = createAction<{
  roomId: string;
  oldValue: boolean;
}>("@room/MARK_ROOM_READ_FAILURE");

export interface UpdateRoomVisibilityPayload {
  roomId: string;
  visibility: RoomVisibility;
}

export const updateRoomVisibility = createAction<UpdateRoomVisibilityPayload>(
  "@room/UPDATE_ROOM_VISIBILITY_REQUEST",
);

export interface UpdateRoomVisibilityFailurePayload {
  roomId: string;
  visibility: RoomVisibility;
}

export const updateRoomVisibilityFailure =
  createAction<UpdateRoomVisibilityPayload>(
    "@room/UPDATE_ROOM_VISIBILITY_FAILURE",
  );

export const focusRoom = createAction<{
  roomId: string;
}>("@room/FOCUS_ROOM");

export const setRoom = createAction<{
  room: RoomTypes.Room;
  userHistory: RoomUserHistory | undefined;
}>("@room/SET_ROOM");

export const subscribeToRoom = createAction<{ roomId: string }>(
  "@room/SUBSCRIBE_TO_ROOM",
);

export const unsubscribeFromRoom = createAction<{ roomId: string }>(
  "@room/UNSUBSCRIBE_FROM_ROOM",
);

export const subscribeToRoomUsers = createAction<{ roomId: string }>(
  "@room/SUBSCRIBE_TO_ROOM_USERS",
);

export const unsubscribeFromRoomUsers = createAction<{ roomId: string }>(
  "@room/UNSUBSCRIBE_FROM_ROOM_USERS",
);

export const setRoomMembers = createAction<{
  roomId: string;
  members: RoomTypes.RoomMember[];
}>("@room/SET_ROOM_USERS");

export interface RemoveMessagePayload {
  messageId: string;
}
export const removeMessageRequest = createAction(
  "@room/REMOVE_MESSAGE_REQUEST",
  (payload: RemoveMessagePayload) => ({ payload, meta: { thunk: true } }),
);
export const removeMessageFailure = createAction(
  "@room/REMOVE_MESSAGE_FAILURE",
  (payload: RemoveMessagePayload) => ({ payload, error: true }),
);

export const superadminDeleteMessageRequest =
  createAction<RemoveMessagePayload>("@room/SUPERADMIN_DELETE_MESSAGE_REQUEST");

export interface UploadFilePayload {
  context: UploadAttachmentContext;
  file: ModernFile<LocalPath>;
  caption?: InputPartition[];
}
export const uploadFileRequest = createAction(
  "@room/UPLOAD_FILE_REQUEST",
  (payload: UploadFilePayload) => ({
    payload,
  }),
);
export const uploadFileFailure = createAction(
  "@room/UPLOAD_FILE_FAILURE",
  (payload: UploadFilePayload) => ({ payload, error: true }),
);

export interface UploadFilesPayload {
  context: UploadAttachmentContext;
  files: Array<ModernFile<LocalPath>>;
}
export const uploadFilesRequest = createAction(
  "@room/UPLOAD_FILES_REQUEST",
  (payload: UploadFilesPayload) => ({ payload, meta: { thunk: true } }),
);
export const uploadFilesFailure = createAction(
  "@room/UPLOAD_FILES_FAILURE",
  (payload: UploadFilesPayload) => ({ payload, error: true }),
);

export const openRoomCarousel = createAction<{
  messageId: string;
  type: "room";
}>("@room/OPEN_CAROUSEL");
export const closeRoomCarousel = createAction("@room/CLOSE_CAROUSEL");

export interface GeolocationContextActionsPayload {
  actions: GeolocationAction[];
}

export const geolocationContextActions =
  createAction<GeolocationContextActionsPayload>(
    "@room/GEOLOCATION_CONTEXT_ACTIONS",
  );

export interface GeolocationContextHoverPayload {
  hovered: boolean;
  id?: string;
  actions?: GeolocationAction[];
}
export const geolocationContextHover =
  createAction<GeolocationContextHoverPayload>(
    "@room/GEOLOCATION_CONTEXT_HOVER",
  );

export interface GeolocationContextSelectPayload {
  selected: boolean;
  id?: string;
  actions?: GeolocationAction[];
}
export const geolocationContextSelect =
  createAction<GeolocationContextSelectPayload>(
    "@room/GEOLOCATION_CONTEXT_SELECT",
  );

export type ConnectRoomToExternalPayload = {
  [K in keyof Exclude<Room["external"], undefined>]?: string;
} & {
  roomId: string;
  loaderId: string;
};
export const connectRoomToExternal = createAction<ConnectRoomToExternalPayload>(
  "@room/connectToExternal",
);

export type DisconnectRoomToExternalPayload = {
  [K in keyof Exclude<Room["external"], undefined>]?: boolean;
} & {
  roomId: string;
};

export const disconnectRoomToExternal =
  createAction<DisconnectRoomToExternalPayload>("@room/disconnectToExternal");

export const updateRooms = createAction<Room[]>("@room/UPDATE_ROOMS");

interface SetMapDisplayModeForRoomPayload {
  roomId: string;
  displayMode: RoomMapDisplayMode;
  schemaId?: string;
}
export const setMapDisplayModeForRoom =
  createAction<SetMapDisplayModeForRoomPayload>(
    "@room/SET_MAP_DISPLAY_MODE_FOR_ROOM",
  );

interface SetVisibleMapMarkersForRoomPayload {
  roomId: string;
  visibleMarkerIds: string[];
}
export const setVisibleMapMarkersForRoom =
  createAction<SetVisibleMapMarkersForRoomPayload>(
    "@room/SET_VISIBLE_MAP_MARKERS_FOR_ROOM",
  );

interface SetMinZoomReachedForRoomPayload {
  roomId: string;
  newState: boolean;
}
export const setMinZoomReachedForRoom =
  createAction<SetMinZoomReachedForRoomPayload>(
    "@room/SET_MIN_ZOOM_REACHED_FOR_ROOM",
  );

export const RoomArchiveStateActions = {
  setArchived: createAction<{ roomId: string }>("@room/state/SET_ARCHIVED"),
  setUnarchived: createAction<{ roomId: string }>("@room/state/SET_UNARCHIVED"),
};

export const RoomActions = {
  changeNotificationFilter: createAction<{
    roomId: string;
    notificationFilter: RoomNotificationFilter;
  }>("@room/CHANGE_NOTIFICATION_FILTER"),
  addCreatedRoomLocation: createAction<{
    roomId: string;
    geolocation: GeoLocation;
  }>("@room/ADD_CREATED_ROOM_LOCATION"),
  changeRoomEmoji: createAction<{
    roomId: string;
    emoji: string;
  }>("@room/CHANGE_ROOM_EMOJI"),
  pin: createAction<{ roomId: string }>("@room/PIN"),
  unpin: createAction<{ roomId: string }>("@room/UNPIN"),
};

export const RoomStateActions = {
  updateRoomMembers: createAction<{
    roomId: string;
    addedUserIds?: string[];
    removedUserIds?: string[];
  }>("@room/state/UPDATE_ROOM_MEMBERS"),
  setNotificationFilter: createAction<{
    roomId: string;
    notificationFilter: RoomNotificationFilter;
  }>("@room/state/SET_ROOM_NOTIFICATION_FILTER"),
  roomDeleted: createAction<{ roomId: string }>("@room/ROOM_DELETED"),
  roomJoined: createAction<{ roomId: string }>("@room/ROOM_JOINED"),
  roomLeft: createAction<{ roomId: string }>("@room/ROOM_LEFT"),
  setRoomEmoji: createAction<{
    roomId: string;
    emoji: string;
  }>("@room/SET_ROOM_EMOJI"),
};
