import { useEffect, useState } from "react";

export function useNextFrameValue<T>(value: T) {
  const [lastValue, setLastValue] = useState<T>(value);

  useEffect(() => {
    setLastValue(value);
  }, [value]);

  return lastValue;
}
