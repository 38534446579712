import { makeStyles } from "@mui/styles";

import { Color, Radius, Spacing } from "@kraaft/ui";
import { webBoxModel } from "@kraaft/web/src/core/utils/webBoxModel";

export const useStyles = makeStyles({
  container: {
    borderRadius: Radius.SMALL,
    backgroundColor: Color.WHITE,
    flexDirection: "row",
    padding: webBoxModel([Spacing.S8, Spacing.S12]),
    alignItems: "center",
    display: "flex",
    "& > :not(:last-child)": {
      marginRight: Spacing.S8,
    },
  },
  textInputStyle: {
    backgroundColor: Color.WHITE,
    flex: 1,
  },
  iconContainer: {
    justifyContent: "center",
  },
});
