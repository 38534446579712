import { useMemo } from "react";
import { useSelector } from "react-redux";

import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { MapControllerLayout } from "@kraaft/shared/components/mapController/mapControllerLayout";
import { MapOrNoMarkers } from "@kraaft/shared/components/mapController/mapOrNoMarkers";
import { SimpleMediaMarker } from "@kraaft/shared/components/mapController/markers/simpleMediaMarker/simpleMediaMarker";
import { ModularFolderMenu } from "@kraaft/shared/components/mapController/modularFolderMap/modularFolderMenu";
import {
  MapMarker,
  MapTrackingInfo,
} from "@kraaft/shared/components/mapController/types";
import { useMapControllerPortalHostname } from "@kraaft/shared/components/mapController/useMapControllerPortalHostname";
import {
  formatGeolocatedModularFolderAttachments,
  useModularFolderTitle,
} from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { IndexedAttachmentWithGPS } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import {
  selectModularFolderGeolocatedAttachments,
  selectModularFolderSchemaId,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectSchemaName } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { ModularFolderUseMapNavigationReturnType } from "@kraaft/shared/core/utils/useMapNavigation/types";
import { PortalHost } from "@kraaft/ui";

import { ModularFolderGeolocationCarousel } from "./carousel";

const MAP_MENU_PORTAL_ID = "mapFolderMenuPortal";

interface ModularFoldersMarkersProps {
  modularFolderId: string;
  onMarkerAction: (attachmentIndex: number) => void;
}

export const useModulerFolderMarkers = ({
  modularFolderId,
  onMarkerAction,
}: ModularFoldersMarkersProps) => {
  const geolocatedAttachments = useSelector(
    selectModularFolderGeolocatedAttachments(modularFolderId),
  );

  const attachmentsWithCoords = useMemo(() => {
    return formatGeolocatedModularFolderAttachments(geolocatedAttachments);
  }, [geolocatedAttachments]);

  const markers = useMemo(
    () =>
      attachmentsWithCoords.map<MapMarker<IndexedAttachmentWithGPS>>(
        (attachement) => ({
          key: attachement.id,
          element: attachement,
          renderType: "small",

          Marker: (
            <SimpleMediaMarker
              attachment={attachement}
              onMarkerAction={onMarkerAction}
            />
          ),
        }),
      ),
    [attachmentsWithCoords, onMarkerAction],
  );

  return { markers };
};

interface ModularFolderMapProps {
  mapNavigation: ModularFolderUseMapNavigationReturnType;
  withDetail: boolean;
  withCloseButton: boolean;
  topLeftButton?: React.ReactNode;
}

export const ModularFolderMap = ({
  mapNavigation,
  withCloseButton,
  withDetail,
  topLeftButton,
}: ModularFolderMapProps) => {
  const { geoDetail, modularFolderId } = mapNavigation;

  const schemaId = useSelector(selectModularFolderSchemaId(modularFolderId));
  const schemaName = useSelector(selectSchemaName(schemaId));
  const folderTitle = useModularFolderTitle(schemaName);

  const { markers } = useModulerFolderMarkers({
    modularFolderId,
    onMarkerAction: mapNavigation.onMarkerAction,
  });

  const title = withDetail ? folderTitle : undefined;

  const rightPanel = useMemo(() => {
    if (!withDetail) {
      return undefined;
    }
    return (
      <>
        <DefaultLayoutHeader headerTitle={title} />
        <ModularFolderMenu
          mapNavigation={mapNavigation}
          portalHostname={MAP_MENU_PORTAL_ID}
        />
        <PortalHost hostname={MAP_MENU_PORTAL_ID} />
      </>
    );
  }, [withDetail, title, mapNavigation]);

  const trackingInfo = useMemo<MapTrackingInfo>(
    () => ({
      map_type: "record",
      schema_id: schemaId,
      schema_name: schemaName,
    }),
    [schemaId, schemaName],
  );

  const mapControllerPortalHostname = useMapControllerPortalHostname();

  return (
    <MapControllerLayout
      withCloseButton={withCloseButton}
      topLeftButton={topLeftButton}
      rightPanel={rightPanel}
      mapControllerPortalHostname={mapControllerPortalHostname}
    >
      <MapOrNoMarkers
        markers={markers}
        geoDetail={geoDetail}
        trackingInfo={trackingInfo}
        mapControllerPortalHostname={mapControllerPortalHostname}
      />
      <ModularFolderGeolocationCarousel
        roomId={mapNavigation.roomId}
        modularFolderId={mapNavigation.modularFolderId}
      />
    </MapControllerLayout>
  );
};
