import { AnyUnexplained } from "@kraaft/shared/core/types";

/**
 * @see node_modules/firebase/index.d.ts
 */
// eslint-disable-next-line complexity
export function authErrorToTranslation(error: unknown) {
  if (error instanceof Error) {
    if ("code" in error) {
      switch ((error as AnyUnexplained).code) {
        case "auth/invalid-phone-number":
          return "auth.error.firebase.invalidPhoneNumber";
        case "auth/auth-domain-config-required":
        case "auth/operation-not-allowed":
        case "auth/operation-not-supported-in-this-environment":
        case "auth/unauthorized-domain":
          return "auth.error.firebase.config";
        case "auth/popup-closed-by-user":
          return "auth.error.firebase.popupClosed";
        case "auth/popup-blocked":
          return "auth.error.firebase.popupBlocked";
        case "auth/cancelled-popup-request":
        case "auth/captcha-check-failed":
        case "auth/user-mismatch":
        case "auth/user-not-found":
          return "auth.error.firebase.login";
        case "auth/credential-already-in-use":
        case "auth/email-already-in-use":
          return "auth.error.firebase.otherAccount";
        case "auth/account-exists-with-different-credential":
          return "auth.error.firebase.unlinkedAccount";
      }
    }
  }

  console.log("unnown auth error", error);

  return "auth.error.firebase.generic";
}
