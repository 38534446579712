import { NotBranded } from "@kraaft/helper-types";
import {
  BrowserPath,
  FilePath,
  LocalPath,
  RemotePath,
} from "@kraaft/shared/core/modules/file/file";

export interface PermanentPathMeta {
  name: string;
}

export abstract class FileAllocator {
  /**
   * Returns whether a string is a local path or not
   */
  static isLocalPath(path: string): path is LocalPath {
    return path.startsWith("file://") || path.startsWith("blob:");
  }

  /**
   * Returns whether a string is a remote path or not
   */
  static isRemotePath(path: string): path is RemotePath {
    return path.startsWith("https://") || path.startsWith("http://");
  }

  /**
   * Returns a Blob web object from a local path, which is BrowserPath on web
   */
  static async resolveLocalPathToBlob(path: LocalPath) {
    return await fetch(path).then((response) => response.blob());
  }

  /**
   * If the file is not persisted on device, perists it and returns its local path
   * If it is already a local path, returns it directly
   *
   * Example:
   * ```
   * // Returns the downloaded version persisted on device
   * const localPath = await fileAllocator.temporaryIfNeeded("https//example.org");
   * // Returns the file directly
   * const localPath = await fileAllocator.temporaryIfNeeded("file:///some/random/file");
   * ```
   */
  abstract temporaryIfNeeded(
    path: FilePath,
    extension?: string | undefined,
  ): Promise<LocalPath>;
  /**
   * See {@link temporaryIfNeeded}
   */
  abstract permanentIfNeeded(
    path: FilePath,
    meta: PermanentPathMeta,
    extension?: string | undefined,
  ): Promise<LocalPath>;

  /**
   * Affirms a string is a LocalPath that references an external file.
   * Used for document pickers for example.
   */
  abstract affirmExternalDevicePath<T extends string>(
    path: NotBranded<T>,
  ): LocalPath;

  /**
   * Used to tell the fileAllocator a given path is going to be written.
   * It helps keeping track of written files on disk even though it's not written yet.
   *
   * Used for libraries that needs a filepath to write data.
   *
   * Also very useful for libraries that require an optional device path, instead of creating the file
   * then having to move it.
   */
  abstract allocatePermanentFilepath(
    extension: string,
    meta: PermanentPathMeta,
  ): LocalPath;

  /**
   * See {@link permanentFromData}, except it moves it to the temporary area
   * and can be deleted any time.
   */
  abstract temporaryFromData(
    extension: string,
    data: Uint8Array,
  ): Promise<LocalPath>;
  /**
   * See {@link permanentFromRemotePath}, except it moves it to the temporary area
   * and can be deleted any time.
   */
  abstract temporaryFromRemotePath(
    path: RemotePath,
    extension?: string | undefined,
  ): Promise<LocalPath>;
  /**
   * See {@link permanentFromExternallyAllocatedDevicePath}, except it moves it to the temporary area
   * and can be deleted any time.
   */
  abstract temporaryFromExternallyAllocatedDevicePath<T extends string>(
    path: NotBranded<T>,
    extension?: string | undefined,
  ): Promise<LocalPath>;

  /**
   * Moves a file to the permanent area using data.
   * The file will never be deleted unless done manually.
   */
  abstract permanentFromData(
    extension: string,
    data: Uint8Array,
    meta: PermanentPathMeta,
  ): Promise<LocalPath>;
  /**
   * Moves a file to the permanent area using a remote path, the file will be downloaded
   * on device.
   * The file will never be deleted unless done manually.
   */
  abstract permanentFromRemotePath(
    path: RemotePath,
    meta: PermanentPathMeta,
    extension?: string | undefined,
  ): Promise<LocalPath>;
  /**
   * Moves a file to the permanent area using an already existing device path.
   * Used to convert the return of a library and convert the path to a LocalPath
   *
   * The file will never be deleted unless done manually.
   */
  abstract permanentFromExternallyAllocatedDevicePath<T extends string>(
    path: NotBranded<T>,
    meta: PermanentPathMeta,
    extension?: string | undefined,
  ): Promise<LocalPath>;

  /**
   * Ensures a path is a remote path, useful for API normalization.
   */
  abstract parseRemotePath<T extends string>(path: NotBranded<T>): RemotePath;

  /**
   * Deletes a file.
   */
  abstract delete(path: LocalPath): Promise<void>;

  /**
   * Converts a blob to a remote path.
   */
  abstract fromBlob(blob: Blob): Promise<LocalPath>;

  /**
   * Converts a blob to a remote path.
   */
  abstract fromWebFile(file: File): BrowserPath;

  /**
   * Converts a blob to a remote path.
   */
  abstract exists(file: LocalPath): Promise<boolean>;

  /**
   * Converts a blob to a remote path.
   */
  abstract size(file: LocalPath): Promise<number>;

  /**
   * Moves a file to destination
   */
  abstract move(
    source: LocalPath,
    destination: LocalPath,
    meta: PermanentPathMeta,
  ): Promise<void>;

  abstract rename(
    filepath: LocalPath,
    name: string,
    meta: PermanentPathMeta,
  ): Promise<LocalPath>;
}
