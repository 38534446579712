export function hashStringToInt(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i: number) {
  const r = (i >> 16) & 0xff;
  const g = (i >> 8) & 0xff;
  const b = i & 0xff;

  // // Increase brightness
  // r = Math.min(255, r / 2 + 127);
  // g = Math.min(255, g / 2 + 127);
  // b = Math.min(255, b / 2 + 127);

  return `rgb(${r}, ${g}, ${b})`;
}

function stringToColor(str: string) {
  const hash = hashStringToInt(str);
  const color = intToRGB(hash);
  return color;
}

export function formatNamespace(
  module: string,
  ...namespaces: string[]
): Array<string> {
  const moduleTemplate = [module, ...namespaces]
    .map((it) => `%c${it}%c`)
    .join("/");

  const colors = [module, ...namespaces].flatMap((it) => [
    `color: ${stringToColor(it)}`,
    "color: unset",
  ]);

  return [`[${moduleTemplate}]`, ...colors];
}
