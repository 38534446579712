import { createSelector } from "@reduxjs/toolkit";
import memoize from "lodash/memoize";

import { hasRoomExternalMembers } from "@kraaft/shared/core/modules/room/roomRecordUtils";
import { DEFAULT_ROOM_MAP_DISPLAY_MODE } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import {
  selectRoom,
  selectRoomsByPool,
  selectRoomUserHistory,
} from "@kraaft/shared/core/modules/room/selectors/directSelectors";
import { selectUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import { RootState } from "@kraaft/shared/core/store";
import { compareStrings } from "@kraaft/shared/core/utils";

export const selectAllRoomsOfPoolAlphaSorted = memoize((poolId: string) =>
  createSelector(selectRoomsByPool(poolId), (rooms) =>
    rooms.sort((a, b) =>
      compareStrings(
        getExistingRoomRecordField(a.record.properties, "title", ""),
        getExistingRoomRecordField(b.record.properties, "title", ""),
      ),
    ),
  ),
);

export const selectIsCurrentUserRoomMember = memoize((roomId: string) =>
  createSelector(
    selectRoomUserHistory(roomId),
    (userHistory) => userHistory !== undefined,
  ),
);

export const selectRoomIsExternal = memoize((roomId: string | undefined) =>
  createSelector([selectRoom(roomId), selectUsers], (room, users) => {
    return hasRoomExternalMembers(room, users);
  }),
);

const selectMapContext = ({ room }: RootState) => room.ui.map;

export const selectMapDisplayMode = memoize((roomId: string | undefined) =>
  createSelector([selectMapContext], (mapContext) => {
    if (roomId === undefined) {
      return {
        displayMode: DEFAULT_ROOM_MAP_DISPLAY_MODE,
        schemaId: undefined,
      };
    }
    const displayMode =
      mapContext[roomId]?.displayMode ?? DEFAULT_ROOM_MAP_DISPLAY_MODE;
    const schemaId = mapContext[roomId]?.schemaId;
    return { displayMode, schemaId };
  }),
);

export const selectMapVisibleMarkerIds = memoize((roomId: string | undefined) =>
  createSelector([selectMapContext], (mapContext) => {
    if (roomId === undefined) {
      return [];
    }
    return mapContext[roomId]?.visibleMarkerIds ?? [];
  }),
);

export const selectMapMinZoomReached = memoize((roomId: string | undefined) =>
  createSelector([selectMapContext], (mapContext) => {
    if (roomId === undefined) {
      return false;
    }
    return mapContext[roomId]?.isMinZoom ?? false;
  }),
);
