import { useMemo } from "react";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { compareStrings } from "@kraaft/shared/core/utils/index.web";
import { KDropdownButton } from "@kraaft/web/src/components/dropdown/kDropdownButton";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";

import { useStyles } from "./basicCell.styles";

export const SelectCell = ({
  value: selectFieldValue,
  column,
  ref,
}: IBasicCellProps<KColumnType.selectSingle | KColumnType.selectMultiple> & {
  ref?: React.Ref<HTMLDivElement>;
}) => {
  const classes = useStyles();

  const valuesContent = useMemo(() => {
    const values = selectFieldValue ?? [];

    return values
      .map((value) => column.options[value]?.label)
      .sort(compareStrings)
      .map((valueFormatted, index) => {
        const isNotLast = index < values.length - 1;

        return (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${valueFormatted}`}
            className={classes.value}
            style={isNotLast ? { marginRight: 4 } : undefined}
          >
            {valueFormatted}
            {isNotLast ? "," : ""}
          </span>
        );
      });
  }, [classes.value, column.options, selectFieldValue]);

  return (
    <div className={classes.container}>
      {column.editable ? (
        <KDropdownButton ref={ref} content={valuesContent} />
      ) : (
        <div className={classes.notEditableContainer}>
          <span className={classes.notEditableContent}>{valuesContent}</span>
        </div>
      )}
    </div>
  );
};
