import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { FilterStateActions } from "@kraaft/shared/core/modules/filter/filterActions";
import { selectActiveFiltersCountAndText } from "@kraaft/shared/core/modules/filter/filterSelectors";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { Button, Spacing } from "@kraaft/ui";

export const ResetRoomFiltersButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const poolId = useSelector(selectCurrentPoolId);
  const { activeFiltersCount } = useSelector(
    selectActiveFiltersCountAndText(poolId),
  );

  const handleErasePress = useCallback(() => {
    if (!poolId) {
      return;
    }
    dispatch(FilterStateActions.resetFilters({ poolId }));
  }, [dispatch, poolId]);

  return activeFiltersCount > 0 ? (
    <View style={styles.button}>
      <Button
        icon="trash-01"
        text={t("roomFilters.resetFilters", { count: activeFiltersCount })}
        variant="QUATERNARY"
        size="SMALL"
        alignContent="left"
        onPress={handleErasePress}
      />
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: Spacing.S16,
    paddingBottom: Spacing.S8,
  },
});
