import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import { selectLoader } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { usePrevious } from "@kraaft/shared/core/utils/hooks";

export function useLoaderState(
  loaderId: string,
  callbacks?: {
    onSuccess?: () => void;
    onFailure?: () => void;
    onLoading?: () => void;
    onEnd?: () => void;
  },
) {
  const loader = useSelector(selectLoader(loaderId));
  const previous = usePrevious(loader);
  const refs = useRef(callbacks);

  refs.current = callbacks;

  useEffect(() => {
    if (previous && !loader) {
      refs.current?.onEnd?.();
    }
    if (previous?.status === loader?.status) {
      return;
    }
    if (loader?.status === LoaderStatus.SUCCESS) {
      refs.current?.onSuccess?.();
    }
    if (loader?.status === LoaderStatus.FAILURE) {
      refs.current?.onFailure?.();
    }
    if (loader?.status === LoaderStatus.LOADING) {
      refs.current?.onLoading?.();
    }
  }, [loader, previous]);

  return { loader, loading: loader?.status === LoaderStatus.LOADING };
}

export const useLoader = ({
  prefix,
  ts,
  onLoading,
  onFailure,
  onSuccess,
}: {
  prefix?: string;
  ts?: number;
  onLoading?: (value: boolean) => void;
  onFailure?: (error: Error) => void;
  onSuccess?: () => void;
} = {}) => {
  const loaderId = useMemo(
    () =>
      [prefix ?? Math.random().toString(36).slice(2), ts || Date.now()].join(
        "_",
      ),
    [prefix, ts],
  );
  const loader = useSelector(selectLoader(loaderId));
  const loading = loader?.status === LoaderStatus.LOADING;

  useEffect(() => {
    if (loader?.status === LoaderStatus.FAILURE) {
      onFailure?.(loader.error);
    } else if (loader?.status === LoaderStatus.SUCCESS) {
      onSuccess?.();
    }
  }, [loader, loaderId, onFailure, onSuccess]);

  const onLoadingRef = useRef(onLoading);
  onLoadingRef.current = onLoading;

  useEffect(() => {
    onLoadingRef.current?.(loading);
  }, [loading]);

  return {
    loaderId,
    loading,
    loader,
  };
};
