import React from "react";

import { RenderMessageProps } from "@kraaft/shared/components/bidirectionalMessageList/renderMessage/renderMessage.props";
import { Message } from "@kraaft/shared/components/message";

export const RenderMessage_ = ({
  enableSelection,
  message,
  openMessageActionSheet,
  roomId,
  width,
  selected,
}: RenderMessageProps) => {
  return (
    <Message
      messageRepliedId={null}
      roomId={roomId}
      message={message}
      sizerWidth={width}
      enableSelect={enableSelection}
      selected={selected}
      onLongPress={openMessageActionSheet}
    />
  );
};

export const RenderMessage = React.memo(RenderMessage_);
