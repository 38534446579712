import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useDispatch } from "react-redux";

import { alphaHex } from "@kraaft/helper-functions";
import { CguLinks } from "@kraaft/shared/components/auth/cguLinks";
import { LoginHintPill } from "@kraaft/shared/components/auth/loginHintChips";
import { useSigninTitleForInvitation } from "@kraaft/shared/components/auth/useSigninTitleForInvitation";
import { KInputEmail } from "@kraaft/shared/components/input/kInputEmail";
import { LoginSlice } from "@kraaft/shared/core/modules/login/login.slice";
import { Api } from "@kraaft/shared/core/services/api/api";
import { authErrorToTranslation } from "@kraaft/shared/core/services/auth/authErrorToTranslation";
import { useFetchSSOProvider } from "@kraaft/shared/core/services/auth/useSSO";
import { Firebase } from "@kraaft/shared/core/services/firebase";
import { firebaseSDK } from "@kraaft/shared/core/services/firebase/sdk";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { validateEmail } from "@kraaft/shared/core/utils";
import { useEnsureOnline } from "@kraaft/shared/core/utils/useNetwork";
import { Button, Color, NonAccessibleText, Preloader } from "@kraaft/ui";

import { styles } from "./signInEmail.styles";

function useResetPassword() {
  const navigationService = useNavigationService();

  return useCallback(
    async (email: string) => {
      await Firebase.auth().sendPasswordResetEmail(email);
      await Api.declarePasswordResetted(email);
      navigationService.navigate("ForgotPasswordEmailSent", {
        email,
        mode: "forced",
      });
    },
    [navigationService],
  );
}

function useEmailLogin() {
  const { t } = useTranslation();
  const navigationService = useNavigationService();
  const resetPassword = useResetPassword();
  const dispatch = useDispatch();

  const [working, setWorking] = useState(false);
  const [email, setEmail] = useState("");
  const isCorrect = validateEmail(email);
  const [error, setError] = useState<null | string>(null);

  const fetchSSO = useFetchSSOProvider();

  const handleSubmitEmailHint = useCallback(() => {
    const [username, domain] = email.split("@");
    if (!username || !domain) {
      return;
    }
    const hint = `******${username.substring(username.length - 2)}@${domain}`;
    dispatch(
      LoginSlice.actions.tryLoginHint({
        hint,
        method: "email",
      }),
    );
  }, [dispatch, email]);

  const login = useEnsureOnline(async () => {
    try {
      setWorking(true);
      handleSubmitEmailHint();
      const { provider, user, linked } = await fetchSSO(email);

      // A user can continue with SSO if the user exists and is linked to his SSO provider (login)
      // or if the user doesn't exist and relates to an SSO provider (signup)
      const canContinueWithSSO = provider && ((user && linked) || !user);

      if (canContinueWithSSO) {
        return await firebaseSDK.triggerSSOLogin(provider, email);
      }

      if (user !== false && user.shouldResetPassword) {
        return await resetPassword(email);
      }

      return navigationService.navigate("EnterPassword", {
        email,
        exists: !!user,
      });
    } catch (e) {
      console.log("error on login", e);

      if (e.code) {
        const key = authErrorToTranslation(e);
        setError(t(key, { name: "SSO", error: e.message }));
      } else {
        setError(e.message);
      }
    } finally {
      setWorking(false);
    }
  }, [
    handleSubmitEmailHint,
    resetPassword,
    email,
    navigationService,
    t,
    fetchSSO,
  ]);

  return {
    disabled: !isCorrect,
    login,
    setEmail: (value: string) => {
      setEmail(value);
      setError(null);
    },
    email,
    working,
    error,
  };
}

const SignInEmail = () => {
  const navigationService = useNavigationService();
  const { t } = useTranslation();

  const { email, setEmail, disabled, login, working, error } = useEmailLogin();

  const handleNavigateToSignInPhone = useCallback(() => {
    navigationService.navigate("SignInPhone");
  }, [navigationService]);

  const title = useSigninTitleForInvitation() ?? t("signIn.enterEmail");

  return (
    <View style={styles.container}>
      {working && (
        <Preloader absoluteFill backgroundColor={alphaHex(Color.WHITE, 0.6)} />
      )}
      <NonAccessibleText
        weight="bold"
        size="H1"
        id="ide2e-signinphone-title"
        style={styles.titleContainer}
        numberOfLines={3}
      >
        {title}
      </NonAccessibleText>
      <LoginHintPill method="email" style={styles.loginHintPillStyle} />
      <KInputEmail
        testID="ide2e-email"
        disableAccessibility
        label={t("emailAddress")}
        value={email}
        onChangeText={setEmail}
        error={!!error}
        autoFocus
        autoCapitalize="none"
        autoComplete="off"
        textContentType="none"
        clearButtonMode="while-editing"
        returnKeyType="next"
        keyboardType="email-address"
        nativeID="ide2e-email"
        autoCorrect={false}
        onSubmitEditing={login}
      />
      {error && (
        <View style={styles.errorContainer}>
          <NonAccessibleText
            id="ide2e-error"
            size="SMALL"
            color="ACTION_DESCTRUCTIVE"
          >
            {error}
          </NonAccessibleText>
        </View>
      )}
      <View style={styles.navigateSpacer} />
      <View style={styles.navigateButtonContainer}>
        <NonAccessibleText
          weight="light"
          size="BODY"
          color="FONT_LOW_EMPHASIS"
          onPress={handleNavigateToSignInPhone}
        >
          {t("signIn.useMyPhoneNumber")}
        </NonAccessibleText>
      </View>
      <View style={styles.buttonSpacer} />
      <Button
        id="ide2e-next"
        variant="PRIMARY"
        onPress={login}
        accessibilityLabel={t("next")}
        text={t("next")}
        style={styles.button}
        disabled={disabled}
      />
      <CguLinks />
    </View>
  );
};

export { SignInEmail };
