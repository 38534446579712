import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import isEqual from "fast-deep-equal";

import { ModularCardPills } from "@kraaft/shared/components/roomCard/modularCardPills";
import { RoomCard } from "@kraaft/shared/components/roomCard/roomCard";
import { selectPoolCurrencyCode } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { selectRoomSchemaId } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { selectInternalPoolUsers } from "@kraaft/shared/core/modules/user/userSelectors";

export interface RoomCardContainerProps {
  roomCard: AnyRoomCard;
  disabled?: boolean;
  isHovered?: boolean;
  onLongPress?: (roomCard: AnyRoomCard) => void;
  onPress?: (roomCard: AnyRoomCard) => void;
  pillColumns?: string[];
  description?: string;
  isActive: boolean;
}

const RoomCardContainer_ = ({
  roomCard,
  description,
  disabled,
  isHovered,
  onLongPress,
  onPress,
  pillColumns,
  isActive,
}: RoomCardContainerProps) => {
  const schemaId = useSelector(selectRoomSchemaId(roomCard.poolId));
  const poolMembers = useSelector(selectInternalPoolUsers(roomCard.poolId));
  const currencyCode = useSelector(selectPoolCurrencyCode(roomCard.poolId));

  const pills = useMemo(() => {
    return (
      <ModularCardPills
        pillColumns={pillColumns}
        schemaId={schemaId ?? ""}
        recordProperties={roomCard.properties}
        extraContext={{
          poolMembers,
          currencyCode,
        }}
        testID={roomCard.roomId}
      />
    );
  }, [
    pillColumns,
    schemaId,
    roomCard.properties,
    roomCard.roomId,
    poolMembers,
    currencyCode,
  ]);

  return (
    <RoomCard
      pills={pills}
      roomCard={roomCard}
      disabled={disabled}
      isHovered={isHovered}
      onLongPress={onLongPress}
      onPress={onPress}
      description={description}
      isActive={isActive}
    />
  );
};

export const RoomCardContainer = React.memo(RoomCardContainer_, isEqual);
