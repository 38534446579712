export const getAddLibrarySchemaLoaderId = () => {
  return "add-librarySchema";
};

export const getDuplicateLibrarySchemaLoaderId = (librarySchemaId: string) => {
  return `duplicate-librarySchema-${librarySchemaId}`;
};

export const getCopySchemaInLibraryLoaderId = (schemaId: string) => {
  return `copy-schema-in-library-${schemaId}`;
};

export const getInstantiateLibrarySchemaLoaderId = (
  librarySchemaId: string,
) => {
  return `instantiate-librarySchema-${librarySchemaId}`;
};
