import React from "react";

import { ColorStyle, Text } from "@kraaft/ui";

type InputTipProps = React.PropsWithChildren;

export const InputTip = ({ children }: InputTipProps) => {
  return (
    <Text size="MEDIUM" color={ColorStyle.FONT_LOW_EMPHASIS}>
      {children}
    </Text>
  );
};
