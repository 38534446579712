import { useTranslation } from "react-i18next";

import { CheckboxGuardSheet } from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuardSheet";
import { Text } from "@kraaft/ui";

interface CantUnlockNotAllowedProps {
  onClose: () => void;
  targetDisplayName: string;
  isTargetRootSection: boolean;
  isInRoom: boolean;
}

export const CantUnlockNotAllowed = ({
  onClose,
  targetDisplayName,
  isTargetRootSection,
  isInRoom,
}: CantUnlockNotAllowedProps) => {
  const { t } = useTranslation();

  const text = t(
    `modularRecords.checkboxGuard.${isInRoom ? "inRoom" : "inFolders"}.${
      isTargetRootSection
        ? "cantUnlockRootSectionNotAllowed"
        : "cantUnlockSectionNotAllowed"
    }`,
    {
      targetDisplayName,
    },
  );

  return (
    <CheckboxGuardSheet.Paper>
      <CheckboxGuardSheet.Header onClose={onClose}>
        {t("modularRecords.unlockAnswers")}
      </CheckboxGuardSheet.Header>
      <CheckboxGuardSheet.Content>
        <Text size="BODY">{text}</Text>
      </CheckboxGuardSheet.Content>
    </CheckboxGuardSheet.Paper>
  );
};
