import { InviteState } from "@kraaft/shared/core/modules/app/appState";
import { type TFunction } from "@kraaft/shared/core/services/i18next";

export const getCongratsMessage = (
  inviteState: InviteState | undefined,
  t: TFunction,
) => {
  if (inviteState?.status === "joined") {
    return inviteState.type === "pool"
      ? t("congratsScreenByInvitation", {
          poolName: inviteState.poolName,
        })
      : t("congratsScreenByInvitationNoTeam");
  }

  return t("congratsScreenMobileWebNoInvitation");
};
