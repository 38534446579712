import { createReducer } from "@reduxjs/toolkit";

import * as actions from "./previewActions";
import { PreviewState } from "./previewState";

const initialState: PreviewState = {
  isOpen: false,
};

export const previewReducers = createReducer<PreviewState>(
  initialState,
  ({ addCase }) => {
    addCase(actions.openPreview, (_, { payload }) => ({
      isOpen: true,
      ...payload,
    }));

    addCase(actions.closePreview, () => initialState);
  },
);
