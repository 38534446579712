import { ReactNode, useCallback, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { expandIcon } from "@kraaft/web/src/components/accordion/accordionUtils";
import { LegacyContextMenuButton } from "@kraaft/web/src/components/contextMenuButton";

const IntegrationSection = ({
  actions,
  details,
  title,
}: {
  actions?: ActionSheetItem[];
  details: ReactNode;
  title: string;
}) => {
  const [isExpanded, setExpanded] = useState(true);

  const toggleExpanded = useCallback(() => {
    setExpanded((expandedState) => !expandedState);
  }, [setExpanded]);

  return (
    <Accordion expanded={isExpanded}>
      <AccordionSummary expandIcon={expandIcon} onClick={toggleExpanded}>
        <Typography>{title}</Typography>
        {actions !== undefined && actions.length > 0 && (
          <LegacyContextMenuButton actions={actions} />
        )}
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
  );
};

export { IntegrationSection };
