import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { RoomSchemaVisibilityOptimisticSelector } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.optimistic";
import { selectFolderSchemas } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { RootState } from "@kraaft/shared/core/store";
import { lodashKeyResolver } from "@kraaft/shared/core/utils";

export const selectState = ({ roomSchemaVisibility: { data } }: RootState) =>
  data;
export const selectOptimisticState = ({
  roomSchemaVisibility: { optimistic },
}: RootState) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optimistic as any;

export const selectRoomSchemaVisibilities = createSelector(
  selectState,
  (state) => state.rooms,
);

export const selectPoolFolderSchemasEnabledForRoom = memoize(
  (poolId: string | undefined, roomId: string) =>
    createSelector(
      selectFolderSchemas,
      RoomSchemaVisibilityOptimisticSelector.selectBuilt(roomId),
      (schemas, roomSchemaVisibility) => {
        const folderSchemas = Object.values(schemas)
          .filter(
            (schema) =>
              schema.poolId === poolId &&
              (roomSchemaVisibility?.whitelist
                ? roomSchemaVisibility.whitelist.has(schema.id)
                : true),
          )
          .sort((a, b) => a.index - b.index);
        return folderSchemas;
      },
    ),
  lodashKeyResolver,
);
