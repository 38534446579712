import { useMemo } from "react";
import { BBox } from "geojson";
import Supercluster, { Options } from "supercluster";

import { useFormatAndGroupMarkers } from "@kraaft/shared/components/mapController/hooks/useFormatAndGroupMarkers";
import {
  getClusterId,
  isMarkerCluster,
} from "@kraaft/shared/components/mapController/markers/markerUtils";
import {
  DistributedMapMarker,
  ElementTypeFromMarker,
  GroupedClusters,
  MapProps,
  MarkerGeoJSON,
  MarkerProperties,
  SuperclusterInstance,
} from "@kraaft/shared/components/mapController/types";
import {
  filterMarkerWithinBounds,
  isMarkerWithinBounds,
} from "@kraaft/shared/core/utils/geolocation/map.utils";

const useGroupedClusters = <Marker extends DistributedMapMarker>(
  enableClustering: boolean,
  markers: MapProps<Marker>["markers"],
  bounds: BBox | undefined,
  zoom: number | undefined,
  superclusterOptions?: Options<
    MarkerProperties<ElementTypeFromMarker<Marker>>,
    Supercluster.AnyProps
  >,
) => {
  const markersAsClusters = useFormatAndGroupMarkers(markers, enableClustering);

  return useMemo(() => {
    const groupedClusters: GroupedClusters<Marker> = {
      withAffiliation: {},
      withoutAffiliation: [],
    };
    const markersWithinBounds: MarkerGeoJSON<ElementTypeFromMarker<Marker>>[] =
      [];

    if (bounds !== undefined && zoom !== undefined) {
      if (enableClustering) {
        const superclusterInstances: Record<
          string,
          SuperclusterInstance<ElementTypeFromMarker<Marker>>
        > = {};
        // biome-ignore lint/complexity/noForEach: <explanation>
        Object.entries(markersAsClusters.withAffiliation).forEach(
          ([affiliation, markersForAffiliation]) => {
            const superclusterInstance = new Supercluster(superclusterOptions);
            superclusterInstance.load(markersForAffiliation);

            superclusterInstances[affiliation] = superclusterInstance;
          },
        );

        // biome-ignore lint/complexity/noForEach: <explanation>
        Object.entries(superclusterInstances).forEach(
          ([affiliation, superclusterInstance]) => {
            const markerClusters = superclusterInstance.getClusters(
              bounds,
              zoom,
            );

            groupedClusters.withAffiliation[affiliation] = {
              superclusterInstance,
              markers: markerClusters,
            };

            for (const marker of markerClusters) {
              if (isMarkerCluster(marker)) {
                const clusterId = getClusterId(marker);

                if (clusterId !== undefined) {
                  const leaves = superclusterInstance.getLeaves(
                    clusterId,
                    Number.POSITIVE_INFINITY,
                  );

                  markersWithinBounds.push(...leaves);
                }
              } else if (isMarkerWithinBounds(bounds, marker)) {
                markersWithinBounds.push(marker);
              }
            }
          },
        );
      }
      groupedClusters.withoutAffiliation = markersAsClusters.withoutAffiliation;
      markersWithinBounds.push(
        ...filterMarkerWithinBounds(
          markersAsClusters.withoutAffiliation,
          bounds,
        ),
      );
    }
    return {
      groupedClusters,
      markersWithinBounds,
    };
  }, [
    bounds,
    enableClustering,
    markersAsClusters.withAffiliation,
    markersAsClusters.withoutAffiliation,
    superclusterOptions,
    zoom,
  ]);
};

export { useGroupedClusters };
