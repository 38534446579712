import { formatNamespace } from "@kraaft/shared/core/utils/logger/formatNamespace";

export class Logger {
  protected isEnabled = false;
  protected readonly formattedNameSpace: Array<string> = [];

  protected constructor(
    protected module: string,
    protected namespaces: Array<string>,
  ) {
    this.formattedNameSpace = formatNamespace(this.module, ...this.namespaces);
  }

  public static create(module: string, ...namespaces: string[]) {
    return new Logger(module, namespaces);
  }

  public setEnabled(enabled: boolean) {
    this.isEnabled = enabled;
    return this;
  }

  public createSubLogger(namespaces: string[]): Logger {
    return new Logger(
      this.module,
      this.namespaces.concat(namespaces),
    ).setEnabled(this.isEnabled);
  }

  public log(...args: any[]) {
    if (this.isEnabled) {
      console.log("I", ...this.formattedNameSpace, ...args);
    }
  }

  public warn(...args: any[]) {
    if (this.isEnabled) {
      console.log("W", ...this.formattedNameSpace, ...args);
    }
  }

  public error(...args: any[]) {
    if (this.isEnabled) {
      console.log("E", ...this.formattedNameSpace, ...args);
    }
  }
}
