import { useSelector } from "react-redux";

import {
  BaseReportGeneratorProps,
  ConnectedReportGenerator,
} from "@kraaft/shared/components/report/reportGenerator.connected";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { Preloader } from "@kraaft/ui";

type Props = BaseReportGeneratorProps & {
  roomId: string;
};

const ReportGenerator = ({ roomId, ...props }: Props) => {
  const room = useSelector(selectRoom(roomId));

  if (room === undefined) {
    return <Preloader />;
  }

  return <ConnectedReportGenerator room={room} {...props} />;
};

export { ReportGenerator };
