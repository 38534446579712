import { makeStyles } from "@mui/styles";

import { ColorStyle } from "@kraaft/ui";

const DROP_AREA_HEIGHT = 8;

export const useStyles = makeStyles({
  interDirectoryContainer: {
    height: DROP_AREA_HEIGHT,

    transition: "height .25s ease-out",

    paddingLeft: 12,

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  interDirectoryHidden: {
    height: 0,
    pointerEvents: "none",
  },

  interDirectoryLine: {
    height: 4,
    flexGrow: 1,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  interDirectoryLineActive: {
    backgroundColor: ColorStyle.ACTION_CTA,
  },
});
