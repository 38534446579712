/**
 * It's a range but the term "Range" is already taken by typescript
 * */
export class Span {
  constructor(
    public readonly start: number,
    public readonly end: number,
  ) {}

  length() {
    return this.end - this.start;
  }

  includesValueExclusively(value: number) {
    return value > this.start && value < this.end;
  }
}
