import { StyleSheet } from "react-native";

import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

const IMAGE_SIZE = 64;

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: "center",
    marginBottom: IMAGE_SIZE,
    backgroundColor: Color.WHITE,
  },

  imageContainer: {
    alignItems: "center",
    marginTop: Spacing.S32,
    marginBottom: Spacing.S32,
  },
  lottieContainer: {
    width: "30%",
  },
  text: {
    fontFamily: "Apercu",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.BODY,
    textAlign: "center",
  },
  last: {
    marginTop: Spacing.S32,
  },
  errorContainer: {
    marginVertical: Spacing.S8,
  },
  errorText: {
    color: ColorStyle.ACTION_DESCTRUCTIVE,
    fontSize: FontSize.SMALL,
  },
  bottomButtonContainer: {
    marginTop: Spacing.S24,
    marginBottom: Spacing.S8,
  },
  first: {
    marginBottom: Spacing.S16,
  },
});

export const linkStyle = {
  fontSize: FontSize.BODY,
};

export { styles };
