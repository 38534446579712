import { AnyUnexplained } from "@kraaft/shared/core/types";

import { KTableColumn, Order } from "../kTableTypes";

export function isCurrentColumnOrder(
  column: KTableColumn<AnyUnexplained>,
  order?: Order,
  orderBy?: string,
) {
  return order !== Order.NONE && orderBy === column.id;
}
