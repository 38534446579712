import { useEffect, useRef } from "react";

/**
 * Works as a useEffect, but gives as first argument the old dependencies, before they changed
 *
 * const [count, setCount] = useState(0);
 *
 * useEffectDifference(([oldCount]) => {
 *   console.log("Old count", oldCount);
 *   console.log("New count", count);
 * }, [count]);
 *
 */
export function useEffectDifference<const D extends any[]>(
  fn: (oldDeps: D) => void,
  deps: D,
) {
  const currentFn = useRef(fn);
  currentFn.current = fn;

  const old = useRef(deps);

  useEffect(() => {
    currentFn.current(old.current);
    old.current = deps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
