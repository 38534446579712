import { StyleSheet } from "react-native";

import { ColorStyle } from "@kraaft/ui";

export const styles = StyleSheet.create({
  content: {
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1,
  },
  title: {
    flexGrow: 1,
    flexShrink: 1,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
  },
});
