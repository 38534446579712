import { useTranslation } from "react-i18next";

import { AddRoomButtonProps } from "@kraaft/shared/components/addRoomButton/addRoomButton.props";
import { Color, IconSize } from "@kraaft/ui";

import { RoundButton } from "./roundButton";

export const AddRoomButton = ({
  isTextHidden,
  onPress,
}: AddRoomButtonProps) => {
  const { t } = useTranslation();

  return (
    <RoundButton
      id="rooms-add-room"
      icon="plus"
      iconColor={Color.WHITE}
      iconSize={IconSize.MEDIUM}
      buttonColor={Color.BLUE_KRAAFT}
      text={t("newRoom")}
      textHidden={isTextHidden}
      onClick={onPress}
    />
  );
};
