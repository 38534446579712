import { compact } from "lodash";

import { findAndFetchUnknownUsers } from "@kraaft/shared/core/modules/message/messageUtils";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";

function getSetOfUserIdsFromRooms(rooms: Room[]) {
  const userIds = new Set<string | undefined | null>();
  for (const room of rooms) {
    userIds.add(room.creatorUserId);
    userIds.add(
      getExistingRoomRecordField(room.record.properties, "responsible", [])[0],
    );
    userIds.add(room.lastMessage?.senderId);
  }
  return userIds;
}

export function* checkUserFromLoadedRooms(
  payload: roomActions.RoomsLoadedPayload,
) {
  const userIds = getSetOfUserIdsFromRooms(
    compact(payload.data.map((item) => item.room)),
  );
  yield findAndFetchUnknownUsers(compact([...userIds]));
}
