import { useEffect } from "react";

import { DatadogService } from "@kraaft/shared/core/services/datadog";
import { appStart } from "@kraaft/shared/core/utils/datadog/appStart";
import { PlatformStartContext } from "@kraaft/shared/core/utils/datadog/platformStartContext";

type ActionSinceAppStarted =
  | "performances_home"
  | "performances_first_message_fetch";

const startTimeTrackingStatus = {
  shouldTrack: true,
};
const tracked: Record<
  ActionSinceAppStarted,
  {
    shouldTrack: () => boolean;
    tracked: boolean;
  }
> = {
  performances_home: {
    shouldTrack: () => PlatformStartContext.startedBy === "default",
    tracked: false,
  },
  performances_first_message_fetch: {
    shouldTrack: () => PlatformStartContext.startedBy === "notification",
    tracked: false,
  },
};

export function invalidateStartTimeTracking() {
  startTimeTrackingStatus.shouldTrack = false;
}

export function trackSinceAppStarted(action: ActionSinceAppStarted) {
  const trackInfos = tracked[action];
  if (
    !startTimeTrackingStatus.shouldTrack ||
    trackInfos.tracked ||
    !trackInfos.shouldTrack()
  ) {
    return;
  }
  tracked[action].tracked = true;
  DatadogService.log({
    type: "action",
    actionType: "custom",
    message: action,
    context: {
      time: Date.now() - appStart.applicationStarted,
    },
  }).catch(console.error);
}

export function useTrackSinceAppStarted(action: ActionSinceAppStarted) {
  useEffect(() => {
    trackSinceAppStarted(action);
  }, [action]);
}
