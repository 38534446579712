import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { NoLocationReason } from "@kraaft/shared/components/placeSelection/footerPlaceLocation/noLocationReason";
import { GeoLocation } from "@kraaft/shared/core/types";
import { formatAddress } from "@kraaft/shared/core/utils/utils";
import { FontSize, Preloader, Spacing, Text } from "@kraaft/ui";

interface LocationFallbackProps {
  noLocationReason: NoLocationReason;
  location: GeoLocation | undefined;
}

export const LocationFallback = ({
  location,
  noLocationReason,
}: LocationFallbackProps) => {
  const { t } = useTranslation();

  const message = useMemo(() => {
    if (noLocationReason === "invalid-location") {
      return t("addressNotRecognized");
    }
    if (noLocationReason === "loading-current-location") {
      return t("loadingYourCurrentPosition");
    }
    if (noLocationReason === "loading-target-location") {
      return t("loadingTargetPosition");
    }
  }, [noLocationReason, t]);

  if (noLocationReason === "blank") {
    return null;
  }

  if (!location && noLocationReason !== "invalid-location") {
    return (
      <View style={styles.loading}>
        <Preloader size={FontSize.BODY} />
        <Text size="BODY">{message}</Text>
      </View>
    );
  }

  return <Text>{formatAddress(location)}</Text>;
};

const styles = StyleSheet.create({
  loading: {
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
  },
});
