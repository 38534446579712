import { PropsWithChildren, useState } from "react";

import { useMeshContextSetup } from "@kraaft/helper-hooks";
import { SkinToneVariant } from "@kraaft/shared/components/emojiSelector/emoji.types";
import { EmojiSelectorContext } from "@kraaft/shared/components/emojiSelector/emojiSelector.context";

const EmojiSelectorContextProvider = ({ children }: PropsWithChildren) => {
  const [selectedSkinToneVariant, setSelectedSkinToneVariant] =
    useState<SkinToneVariant>("default");

  return (
    <EmojiSelectorContext.Provider
      value={useMeshContextSetup({
        selectedSkinToneVariant,
        setSelectedSkinToneVariant,
      })}
    >
      {children}
    </EmojiSelectorContext.Provider>
  );
};

export { EmojiSelectorContextProvider };
