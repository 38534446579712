import { makeStyles } from "@mui/styles";

export const ANIMATION_DURATION = 200;

interface MakeStylesProps {
  visible?: boolean;
}

export const useStyles = makeStyles({
  container: ({ visible }: MakeStylesProps) => ({
    pointerEvents: visible ? "auto" : "none",
  }),
  "@global": {
    ".fadeIn-enter": {
      opacity: 0,
    },
    ".fadeIn-enter-active": {
      transition: `opacity ${ANIMATION_DURATION}ms ease-out`,
      opacity: 1,
    },
    ".fadeIn-exit": {
      opacity: 1,
    },
    ".fadeIn-exit-active": {
      transition: `opacity ${ANIMATION_DURATION}ms ease-in`,
      opacity: 0,
    },
  },
});
