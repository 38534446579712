import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { Api } from "@kraaft/shared/core/services/api";
import { Button, Sheet } from "@kraaft/ui";

export const AddCompanySheet = Sheet({
  web: "popup",
})
  .create(({ Content, Footer, Header, Paper }) => ({ onClose }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);

    const handleCreateCompany = useCallback(async () => {
      setLoading(true);
      try {
        await Api.addCompany({ name: text });
        onClose();
      } catch (e) {
        dispatch(showError({ title: t("serverError") }));
      }
      setLoading(false);
    }, [dispatch, onClose, t, text]);

    return (
      <Paper>
        <Header onClose={onClose}>{t("support.createCompany")}</Header>
        <Content>
          <KInput
            label={t("support.companyName")}
            value={text}
            onChangeText={setText}
            autoFocus
            onSubmitEditing={handleCreateCompany}
          />
        </Content>
        <Footer>
          <Button
            loading={loading}
            onPress={handleCreateCompany}
            text={t("create")}
          />
        </Footer>
      </Paper>
    );
  })
  .withDefaults({ size: "SMALL" });
