import { RefObject, useCallback, useRef, useState } from "react";

import { createMeshContext, useMeshContextSetup } from "@kraaft/helper-hooks";
import { executeAfterStatePropagation } from "@kraaft/shared/core/utils/promiseUtils";

export interface MessageListHandle {
  scrollToMessage: (
    messageId: string,
    forceAnimation?: boolean,
  ) => Promise<void>;
  scrollToBottom: () => Promise<void>;
}

export interface MessageListContext {
  messageListHandle: RefObject<MessageListHandle>;

  highlightedMessage: string | undefined;
  highlightMessage: (messageId: string) => void;
}

export function useMessageListContext() {
  const [highlightedMessage, setHighlightedMessage] = useState<
    string | undefined
  >();

  const messageListHandle = useRef<MessageListHandle>(null);

  const highlightMessage = useCallback((messageId: string) => {
    setHighlightedMessage(messageId);
    executeAfterStatePropagation(() => setHighlightedMessage(undefined));
  }, []);

  return useMeshContextSetup<MessageListContext>({
    messageListHandle,
    highlightedMessage,
    highlightMessage,
  });
}

export const MessageListContext = createMeshContext<MessageListContext>();
