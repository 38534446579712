import { createStyles, makeStyles } from "@mui/styles";

import { Color, ColorStyle } from "@kraaft/ui";

import { nativeStylesObject } from "./kPopup.styles.native";

const CLOSE_BUTTON_SIZE = 32;

interface MakeStylesProps {
  maxWidth: React.CSSProperties["maxWidth"];
}

export const useStyles = makeStyles(
  createStyles({
    ...nativeStylesObject,
    cross: {
      position: "absolute",
      right: -CLOSE_BUTTON_SIZE,
      top: -CLOSE_BUTTON_SIZE,
      height: CLOSE_BUTTON_SIZE,
      width: CLOSE_BUTTON_SIZE,
      borderRadius: CLOSE_BUTTON_SIZE / 2,
      backgroundColor: ColorStyle.ACTION_HOVERED,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: Color.GREY_ANTIFLASH,
      },
    },
    modalContainer: {
      ...nativeStylesObject.modalContainer,
      display: "flex",
      alignItems: "center",
      height: "100%",
      pointerEvents: "none",
      "& > *": {
        pointerEvents: "all",
      },
    },
    container: {
      ...nativeStylesObject.container,
      maxHeight: "85vh",
      width: "calc(100% - 64px)",
      maxWidth: ({ maxWidth }: MakeStylesProps) => maxWidth,
    },
    content: {
      ...nativeStylesObject.content,
      height: "100%",
      maxHeight: "inherit",
      overflow: "hidden",
    },
  }),
);
