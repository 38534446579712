import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { memoize } from "lodash";
import { createSelector } from "reselect";

import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import {
  UserContactInfos,
  UserContactInfosStateType,
} from "@kraaft/shared/core/modules/userContactInfos/userContactInfo.state";
import { RootState } from "@kraaft/shared/core/store";

export const initialState: UserContactInfosStateType = {};

const selectUserContactInfos = ({ userContactInfos }: RootState) =>
  userContactInfos;

export const selectUserContactInfosById = memoize((userId: string) =>
  createSelector(selectUserContactInfos, (state) => state[userId]?.item),
);

export const selectUserContactInfosStatusById = memoize((userId: string) =>
  createSelector(
    selectUserContactInfos,
    (state) => state[userId]?.status as LoaderStatus | undefined,
  ),
);

export const UserContactInfosSlice = createSlice({
  name: "userContactInfos",
  initialState,
  reducers: {
    getUserContactInfos: (
      mutableState,
      { payload }: PayloadAction<{ userId: string; poolId: string }>,
    ) => {
      const existingResponse = mutableState[payload.userId];
      mutableState[payload.userId] = {
        item: existingResponse?.item,
        error: undefined,
        status: LoaderStatus.LOADING,
      };
    },
    userContactInfosSuccess: (
      mutableState,
      { payload }: PayloadAction<{ userContactInfos: UserContactInfos }>,
    ) => {
      mutableState[payload.userContactInfos.userId] = {
        item: payload.userContactInfos,
        error: undefined,
        status: LoaderStatus.SUCCESS,
      };
    },
    userContactInfosFailure: (
      mutableState,
      { payload }: PayloadAction<{ userId: string; error: string }>,
    ) => {
      mutableState[payload.userId] = {
        item: mutableState[payload.userId]?.item,
        error: payload.error,
        status: LoaderStatus.FAILURE,
      };
    },
  },
});
