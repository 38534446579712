import { MouseEvent, useMemo } from "react";
import { MenuItem, MenuList } from "@mui/material";
import clsx from "clsx";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { ColorStyle, Icon, Image } from "@kraaft/ui";

import { styles, useStyles } from "./actionSheetMenu.styles";

type CloseableActionSheet = {
  open: boolean;
  onClose: () => void;
};

type BaseActionSheetProps = CloseableActionSheet & {
  options: ActionSheetItem[];
};

type TitleActionSheetProps = BaseActionSheetProps & {
  title: string;
  subtitle?: string;
};

type ActionSheetMenuProps = BaseActionSheetProps | TitleActionSheetProps;

const ActionSheetMenu = (props: ActionSheetMenuProps) => {
  const { options, open, onClose } = props;

  const classes = useStyles();

  const actions = useMemo(
    () =>
      options.map(({ label, style, onPress, ...other }) => ({
        label: label,
        onClick: (e: MouseEvent<HTMLLIElement>) => {
          e.stopPropagation();
          onClose();
          onPress?.(e);
        },
        style: {
          color:
            style === "destructive"
              ? ColorStyle.ACTION_DESCTRUCTIVE
              : ColorStyle.FONT_HIGH_EMPHASIS,
        },
        ...other,
      })),
    [onClose, options],
  );

  return (
    <MenuList
      autoFocusItem={open}
      classes={{ padding: classes.menuListPadding }}
    >
      <div>
        {"title" in props && (
          <MenuItem
            disabled
            classes={{ root: classes.menuItemHeaderContainer }}
          >
            <div className={classes.menuItemHeaderTitle}>{props.title}</div>
            <div className={classes.menuItemHeaderSubtitle}>
              {props.subtitle}
            </div>
          </MenuItem>
        )}
        {actions.map(
          ({ style, onClick, label, icon, testID, disabled, image }, index) => (
            <MenuItem
              key={label}
              classes={{
                root: clsx(classes.menuItemRoot, {
                  [classes.menuItemRootDisabled]: disabled,
                }),
              }}
              disabled={disabled}
              style={style}
              onClick={onClick}
              id={testID ?? `action-sheet-option-${index}`}
            >
              {(icon || image) && (
                <div className={classes.leftContainer}>
                  {icon && <Icon name={icon} size="MEDIUM" />}
                  {image && <Image source={image} style={styles.image} />}
                </div>
              )}
              {label}
            </MenuItem>
          ),
        )}
      </div>
    </MenuList>
  );
};

export { ActionSheetMenu };
