import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { useMeshContext } from "@kraaft/helper-hooks";
import { getFriendlyUserLabel } from "@kraaft/shared/components/modular/details/editors/userEditor/getFriendlyUserLabel";
import {
  DifferenceBackground,
  DifferenceBackgroundProps,
} from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceBackground";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularRecordValueOfType } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { ModularDisplayRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { Spacing, Text } from "@kraaft/ui";

interface UserDifferenceProps extends DifferenceBackgroundProps {
  value:
    | ModularRecordValueOfType<KColumnType.user>
    | ModularRecordValueOfType<KColumnType.automatedCreatedBy>;
}

export const UserDifference = ({ value, method }: UserDifferenceProps) => {
  const { t } = useTranslation();
  const { poolId, currentUserId, getOrLoadUsers } = useMeshContext(
    ModularDisplayRequirementsContext,
  );

  const content = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }
    const users = getOrLoadUsers(value);

    return value.map((userId) => {
      return (
        <DifferenceBackground key={userId} method={method}>
          <Text size="BODY">
            {getFriendlyUserLabel({ users, poolId, currentUserId, t, userId })}
          </Text>
        </DifferenceBackground>
      );
    });
  }, [currentUserId, getOrLoadUsers, method, poolId, t, value]);

  return content !== undefined ? (
    <View style={styles.container}>{content}</View>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: Spacing.S8,
  },
});
