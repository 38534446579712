import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ActionCard } from "@kraaft/shared/components/actionCard";
import { selectMiniMediasCount } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";

interface PhotoGalleryActionCardProps {
  roomId: string;
}

export const PhotoGalleryActionCard = ({
  roomId,
}: PhotoGalleryActionCardProps) => {
  const { t } = useTranslation();
  const navigationService = useNavigationService();

  const totalMediaCount = useSelector(selectMiniMediasCount(roomId, ["image"]));

  const handleOpen = useCallback(() => {
    navigationService.navigate("PhotoGallery", { roomId });
  }, [navigationService, roomId]);

  return (
    <ActionCard
      iconName="image-03"
      text={t("worksiteFolder.allPhotos")}
      description={`${totalMediaCount}`}
      action={handleOpen}
      showBracket
    />
  );
};
