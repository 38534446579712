import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateInviteState } from "@kraaft/shared/core/modules/app/appActions";
import { selectInviteState } from "@kraaft/shared/core/modules/app/appSelector";
import { PoolActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { PoolRoleType } from "@kraaft/shared/core/modules/pool/poolState";

export function useInviteCongrats() {
  const dispatch = useDispatch();

  const inviteState = useSelector(selectInviteState);
  const poolId =
    inviteState.status === "joined" ? inviteState.poolId : undefined;

  const acknowledgeInviteCongrats = useCallback(() => {
    if (poolId) {
      dispatch(
        PoolActions.switchPool({
          poolId,
          roleType: PoolRoleType.EXTERNAL,
        }),
      );
    }

    dispatch(updateInviteState({ status: "unknown" }));
  }, [dispatch, poolId]);
  return { inviteState, acknowledgeInviteCongrats };
}
