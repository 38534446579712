import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { addFirstConditionToCompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionUtils";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  selectRoomSchema,
  selectSchema,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import {
  editSchemaView,
  editSchemaViewKanbanFormat,
  saveEditedSchemaView,
} from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { DirtyableSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewState";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button } from "@kraaft/ui";
import { getAvailableGroupByFromSchema } from "@kraaft/web/src/components/kanban/board/kanbanBoardUtils";
import { SaveSchemaViewDialog } from "@kraaft/web/src/components/modularFoldersViewer/saveSchemaViewButton/saveSchemaViewDialog";
import { RecordWorkspace } from "@kraaft/web/src/core/modules/memory/memoryUtils";

interface SaveSchemaViewButtonProps {
  workspace: RecordWorkspace;
  schemaView: DirtyableSchemaView;
  roomId?: string | undefined;
  schemaId?: string | undefined;
}

const SaveSchemaViewButton = ({
  workspace,
  schemaView,
  roomId,
  schemaId,
}: SaveSchemaViewButtonProps) => {
  const poolId = useSelector(selectCurrentPoolId);
  const roomSchema = useSelector(selectRoomSchema(poolId));
  const schema = useSelector(selectSchema(schemaId));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [saveDialogOpen, setSaveDialogOpen, setSaveDialogClosed] =
    useBooleanState();

  const save = useCallback(() => {
    if (workspace === RecordWorkspace.IN_ROOM) {
      dispatch(
        editSchemaView({
          schemaId: schemaView.schemaId,
          schemaViewId: schemaView.id,
          workspace,
          schemaView: {
            filters: addFirstConditionToCompositeCondition(schemaView.filters, {
              type: "metadata",
              predicate: "isInRoom",
              value: { columnType: KColumnType.join, value: roomId },
            }),
          },
        }),
      );
    }

    if (schema && schemaView.formats.kanban.groupedByProperty === undefined) {
      const availableGroupBy = getAvailableGroupByFromSchema(schema);
      const firstColumnKey = availableGroupBy[0]?.key;

      dispatch(
        editSchemaViewKanbanFormat({
          schemaId: schemaView.schemaId,
          schemaViewId: schemaView.id,
          workspace,
          editions: {
            groupedByProperty: firstColumnKey,
          },
        }),
      );
    }

    if (schemaView.id) {
      trackEvent({
        eventName: "Edit Saved View",
        content_type:
          roomSchema?.id === schemaView.schemaId ? "conversation" : "folder",
      });
    } else {
      trackEvent({
        eventName: "Create Saved View",
        content_type:
          roomSchema?.id === schemaView.schemaId ? "conversation" : "folder",
        from: roomId ? "conversation_view" : "global_view",
      });
    }
    dispatch(
      saveEditedSchemaView({
        schemaId: schemaView.schemaId,
        schemaViewId: schemaView.id,
        workspace,
      }),
    );
    setSaveDialogClosed();
  }, [
    dispatch,
    roomId,
    roomSchema?.id,
    schema,
    schemaView.filters,
    schemaView.formats.kanban,
    schemaView.id,
    schemaView.schemaId,
    setSaveDialogClosed,
    workspace,
  ]);

  const handleSave = useCallback(() => {
    if (schemaView.id) {
      save();
    } else {
      setSaveDialogOpen();
    }
  }, [save, schemaView.id, setSaveDialogOpen]);

  if (!schemaView.dirty) {
    return null;
  }

  return (
    <>
      <Button
        accessibilityLabel={
          schemaView.id ? t("saveSchemaViewChanges") : t("saveSchemaView")
        }
        text={schemaView.id ? t("saveSchemaViewChanges") : t("saveSchemaView")}
        variant="SECONDARY"
        onPress={handleSave}
      />
      <SaveSchemaViewDialog
        workspace={workspace}
        schemaView={schemaView}
        dialogOpen={saveDialogOpen}
        setDialogClosed={setSaveDialogClosed}
        onSave={save}
      />
    </>
  );
};

export { SaveSchemaViewButton };
