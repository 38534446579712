import { ConnectDragSource } from "react-dnd";
import clsx from "clsx";

import { useMeshContext } from "@kraaft/helper-hooks";
import { Icon } from "@kraaft/ui";
import { Directory } from "@kraaft/web/src/components/directoryTree/directoryTree.types";
import { DirectoryTreeContext } from "@kraaft/web/src/components/directoryTree/directoryTreeContext";
import { DirectoryTreeIndentation } from "@kraaft/web/src/components/directoryTree/directoryTreeIndentation";
import { DirectoryTreeSimpleRow } from "@kraaft/web/src/components/directoryTree/directoryTreeSimpleRow";

import { useStyles } from "./directoryTreeDraggableRow.styles";

interface DirectoryTreeDraggableRowProps {
  directory: Directory;
  dragRef: ConnectDragSource;
  level: number;
  isGroupBeingDragged?: boolean;
  isRootDirectory?: boolean;
  isTemporaryDirectory?: boolean;
}

const DirectoryTreeDraggableRow = (props: DirectoryTreeDraggableRowProps) => {
  const { directory, dragRef, level, isRootDirectory, isTemporaryDirectory } =
    props;

  const { isDraggingDirectory } = useMeshContext(DirectoryTreeContext);

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.rowContainer, {
        [classes.rowContainerDisableHover]: isDraggingDirectory,
      })}
    >
      {isRootDirectory ? (
        <DirectoryTreeSimpleRow
          directory={directory}
          isTemporaryDirectory={isTemporaryDirectory}
        />
      ) : (
        <div ref={dragRef} className={classes.rowDraggableWrapper}>
          <DirectoryTreeIndentation level={level} />
          <div className={classes.rowDragIconContainer}>
            <Icon name="dots-six" />
          </div>
          <DirectoryTreeSimpleRow
            directory={directory}
            allowEdition={!isRootDirectory}
            isTemporaryDirectory={isTemporaryDirectory}
          />
        </div>
      )}
    </div>
  );
};

export { DirectoryTreeDraggableRow };
