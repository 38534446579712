import { MutableRefObject, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { selectIsLoading } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { getDownloadMediaLoadingId } from "@kraaft/shared/core/modules/media/media.actions";
import { deselectMessage } from "@kraaft/shared/core/modules/message/messageActions";
import { useGalleryFooterActions } from "@kraaft/shared/core/utils/mediaSelection/footers/useMediaFooterActions";

export const useDocumentGalleryWebFooterActions = ({
  roomId,
  setSheetOpen,
  moreButtonAnchorRef,
}: {
  roomId: string;
  setSheetOpen: () => void;
  moreButtonAnchorRef: MutableRefObject<null>;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const downloadBulkMediaLoadingId = getDownloadMediaLoadingId(roomId);
  const isDownloading = useSelector(
    selectIsLoading(downloadBulkMediaLoadingId),
  );
  const { onAttach, onDownload, onForward } = useGalleryFooterActions({
    roomId,
    selectionSource: "documentGallery",
    downloadLoaderId: downloadBulkMediaLoadingId,
  });

  const clearSelection = useCallback(() => {
    dispatch(deselectMessage({ roomId, all: true }));
  }, [dispatch, roomId]);

  const actions = useMemo<FooterActions>(
    () => [
      {
        accessibilityLabel: t("attachTo"),
        text: t("attachTo"),
        onPress: onAttach,
        variant: "PRIMARY",
        icon: "flag-03",
      },
      {
        accessibilityLabel: t("cancel"),
        text: t("cancel"),
        onPress: clearSelection,
      },
      {
        accessibilityLabel: t("moreOptions"),
        onPress: setSheetOpen,
        icon: "dots-horizontal",
        ref: moreButtonAnchorRef,
        loading: isDownloading,
      },
    ],
    [
      clearSelection,
      isDownloading,
      moreButtonAnchorRef,
      onAttach,
      setSheetOpen,
      t,
    ],
  );

  const options = useMemo<ActionSheetItem[]>(
    () => [
      {
        label: t("download"),
        icon: "download-01",
        onPress: onDownload,
      },
      {
        label: t("forwardMessage"),
        icon: "corner-up-right",
        onPress: onForward,
      },
      {
        label: t("attachTo"),
        icon: "flag-03",
        onPress: onAttach,
      },
    ],
    [onAttach, onDownload, onForward, t],
  );

  return { actions, options };
};
