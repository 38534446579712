import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LayoutAnimation,
  LayoutChangeEvent,
  Text,
  View,
  ViewStyle,
} from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { Button } from "@kraaft/ui";

import { styles } from "./expandableSection.style";

interface ExpandableSectionProps {
  containerId?: string;
  width?: ViewStyle["width"];
  title: string;
  expanded: boolean;
  setExpanded: (open: boolean) => void;
  children: React.ReactNode;
  buttonTitle?: string;
  buttonOnClick?: () => void;
}

export const ExpandableSection = ({
  containerId,
  title,
  expanded,
  setExpanded,
  children,
  buttonTitle,
  buttonOnClick,
  width,
}: ExpandableSectionProps) => {
  const { t } = useTranslation();

  const [contentSize, setContentSize] = useState<number | undefined>(undefined);
  const button = useMemo(() => {
    if (buttonTitle && buttonOnClick) {
      return (
        <View style={styles.button}>
          <Button
            onPress={buttonOnClick}
            accessibilityLabel={buttonTitle}
            text={buttonTitle}
          />
        </View>
      );
    }
    return null;
  }, [buttonOnClick, buttonTitle]);

  const reverse = useCallback(() => {
    if (isNative()) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  const icon = expanded ? "chevron-up" : "chevron-down";
  const accessibilityLabel = expanded ? t("hide") : t("show");

  const onContentLayout = useCallback((e: LayoutChangeEvent) => {
    setContentSize(e.nativeEvent.layout.height);
  }, []);

  const childrenWrapperStyle = useMemo(
    () => [styles.content, { height: expanded ? contentSize : 0 }],
    [contentSize, expanded],
  );

  return (
    <View
      style={[styles.root, { width: width ?? "100%" }]}
      nativeID={containerId}
    >
      <View style={styles.header}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.right}>
          {button}
          <Button
            id={`${containerId}-expand-button`}
            accessibilityLabel={accessibilityLabel}
            icon={icon}
            variant="TERTIARY"
            onPress={reverse}
          />
        </View>
      </View>
      <View style={childrenWrapperStyle}>
        <View style={styles.innerContent} onLayout={onContentLayout}>
          {children}
        </View>
      </View>
    </View>
  );
};
