import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { MapView } from "@kraaft/shared/components/mapController/map";
import { useModularFolderClusterConfig } from "@kraaft/shared/components/mapController/markers/modularFolderMarker/useModularFolderClusterConfig";
import { NoMarkers } from "@kraaft/shared/components/mapController/noMarkers/noMarkers";
import { MapTrackingInfo } from "@kraaft/shared/components/mapController/types";
import { ModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { openPreview } from "@kraaft/shared/core/modules/preview/previewActions";
import {
  subscribeToPoolRooms,
  unsubscribeFromPoolRooms,
} from "@kraaft/shared/core/modules/room/roomActions";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Preloader } from "@kraaft/ui";
import { ModularFoldersMenu } from "@kraaft/web/src/components/modularFoldersMap/modularFoldersMenu";
import { useModularFoldersMarkers } from "@kraaft/web/src/components/modularFoldersMap/useModularFoldersMarkers";

import { useStyles } from "./modularFoldersMap.styles";

interface Props {
  isLoaded: boolean;
  filteredFolders: ModularFolder[];
  schema: KFolderSchema;
}

const ModularFoldersMap = ({ filteredFolders, isLoaded, schema }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onMarkerAction = useCallback(
    (folderId: string) => {
      dispatch(
        openPreview({
          type: "modularFolder",
          folderId,
        }),
      );
    },
    [dispatch],
  );

  const markers = useModularFoldersMarkers({
    modularFolders: filteredFolders,
    onMarkerAction,
  });

  useEffect(() => {
    dispatch(ModularFolderActions.subscribeForPool({ poolId: schema.poolId }));
    dispatch(
      subscribeToPoolRooms({
        poolId: schema.poolId,
      }),
    );
    return () => {
      dispatch(
        ModularFolderActions.unsubscribeForPool({ poolId: schema.poolId }),
      );
      dispatch(
        unsubscribeFromPoolRooms({
          poolId: schema.poolId,
        }),
      );
    };
  }, [dispatch, schema]);

  const { clusterConfig: modularFolderClusterConfig } =
    useModularFolderClusterConfig({
      onTooltipPress: (params) => onMarkerAction(params.folderId),
    });

  const trackingInfo = useMemo<MapTrackingInfo>(
    () => ({
      map_type: "record_database",
      schema_id: schema?.id,
      schema_name: schema?.name,
    }),
    [schema?.id, schema?.name],
  );

  if (!isLoaded) {
    return <Preloader absoluteFill transparent />;
  }

  const isInteractive = markers === null || markers.length > 0;

  return (
    <div className={classes.root}>
      <div className={classes.mapContainer}>
        <MapView
          markers={markers ?? []}
          geoDetail="room"
          enableClustering
          clustersConfig={{
            folder: modularFolderClusterConfig,
          }}
          trackingInfo={trackingInfo}
        />
        {!isInteractive && <NoMarkers geoDetail="room" />}
      </div>
      {isInteractive && (
        <div className={classes.menuContainer}>
          <ModularFoldersMenu
            modularFolders={filteredFolders}
            schema={schema}
            onPress={onMarkerAction}
          />
        </div>
      )}
    </div>
  );
};

export { ModularFoldersMap };
