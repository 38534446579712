import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet } from "react-native";

import { ModularFolderChecker } from "@kraaft/shared/components/modularFolderCard/modularFolderChecker";
import {
  useModularFoldersSelection,
  useOnSelectMultipleModularFolders,
} from "@kraaft/shared/components/modularFolders/modularFoldersSelection.context";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Spacing, Text } from "@kraaft/ui";

interface Props {
  modularFolders: Record<string, ModularFolder>;
}

export const ModularFolderListSelectionHeader = ({ modularFolders }: Props) => {
  const { t } = useTranslation();
  const onSelectMultiple = useOnSelectMultipleModularFolders();
  const selectedIds = useModularFoldersSelection();

  const isSelected = useMemo(() => {
    const folderIds = Object.keys(modularFolders);
    const isAllSelected = folderIds.every((id) => selectedIds.has(id));
    const isNoneSelected = folderIds.every((id) => !selectedIds.has(id));

    return isAllSelected ? true : isNoneSelected ? false : "partial";
  }, [modularFolders, selectedIds]);

  const onPress = () => {
    if (isSelected !== false) {
      onSelectMultiple(Object.keys(modularFolders), false);
    } else {
      onSelectMultiple(Object.keys(modularFolders), true);
    }
  };

  return (
    <Pressable
      onPress={onPress}
      accessibilityLabel={t("modularFolderBulkExport.selectAll")}
      style={styles.pressable}
    >
      <ModularFolderChecker isSelected={isSelected} />
      <Text weight="bold" size="BODY">
        {t("modularFolderBulkExport.selectAll")}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  pressable: {
    paddingBottom: Spacing.S8,
    flexDirection: "row",
    alignItems: "center",
  },
});
