/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import { createOptimisticReducer } from "@kraaft/shared/core/utils/optimistic/optimisticReduxAdapter";
import {
  createOptimisticSaga,
  WorkspaceAPIHandling,
} from "@kraaft/shared/core/utils/optimistic/optimisticSagaAdapter";
import { createOptimisticSelectors } from "@kraaft/shared/core/utils/optimistic/optimisticSelectorAdapter";
import {
  Aggregation,
  BaseAggregation,
  OptimisticOperation,
  OptimisticOperationCreator,
  OptimisticWorkspace,
} from "@kraaft/shared/core/utils/optimistic/types";

function createBuilder<A extends NonNullable<BaseAggregation>, P>(
  aggregate: (
    aggregation: A,
    operation: OptimisticOperation<P, string>,
  ) => A | null,
): <T extends string>(type: T) => OptimisticOperationCreator<P, T, A> {
  return <T extends string>(type: T) =>
    new OptimisticOperationCreator<P, T, A>(type, aggregate as any);
}

interface BuiltOperation<A extends BaseAggregation, T extends string, P> {
  type: T;
  optimistic: (type: T) => OptimisticOperationCreator<P, T, A>;
  call:
    | ((
        operation: OptimisticOperation<P, string>,
        context: ReduxContext<any, A>,
      ) => Generator<any, Date | null>)
    | {
        type: "latest";
        debounce: number;
        identify: (operation: OptimisticOperation<P, string>) => string;
        callDelayed: (
          payload: OptimisticOperation<P, string>,
          context: ReduxContext<any, A>,
        ) => Generator<any, Date | null>;
      };
}

type BuiltOperationAggregation<T extends BuiltOperation<any, any, any>> =
  Aggregation<ReturnType<T["optimistic"]>>;

type ReduxContext<State, Aggregation extends BaseAggregation> = {
  selectBuilt: (id: string) => (state: State) => Aggregation | undefined;
};

export function createOperation<
  A extends BaseAggregation,
  T extends string,
  P,
>(infos: {
  type: T;
  optimistic: (
    aggregation: A,
    operation: OptimisticOperation<P & { targetId: string }, string>,
  ) => A | null;
  call:
    | ((
        operation: OptimisticOperation<P & { targetId: string }, string>,
        context: ReduxContext<any, A>,
      ) => Generator<any, Date | null>)
    | {
        type: "latest";
        debounce: number;
        identify: (
          operation: OptimisticOperation<P & { targetId: string }, string>,
        ) => string;
        callDelayed: (
          payload: OptimisticOperation<P & { targetId: string }, string>,
          context: ReduxContext<any, A>,
        ) => Generator<any, Date | null>;
      };
}): BuiltOperation<A, T, P & { targetId: string }> {
  return {
    type: infos.type,
    optimistic: createBuilder(infos.optimistic),
    call: infos.call,
  };
}

export function createOperationOnMultiple<
  A extends BaseAggregation,
  T extends string,
  P,
>(infos: {
  type: T;
  optimistic: (
    aggregation: A,
    operation: OptimisticOperation<P & { targetIds: string[] }, string>,
  ) => A;
  call:
    | ((
        operation: OptimisticOperation<P & { targetIds: string[] }, string>,
        context: ReduxContext<any, A>,
      ) => Generator<any, Date | null>)
    | {
        type: "latest";
        debounce: number;
        identify: (
          operation: OptimisticOperation<P & { targetIds: string[] }, string>,
        ) => string;
        callDelayed: (
          payload: OptimisticOperation<P & { targetIds: string[] }, string>,
          context: ReduxContext<any, A>,
        ) => Generator<any, Date | null>;
      };
}): BuiltOperation<A, T, P & { targetIds: string[] }> {
  return {
    type: infos.type,
    optimistic: createBuilder(infos.optimistic),
    call: infos.call,
  };
}

export function createOptimisticReduxBundle<
  State,
  B extends BuiltOperation<any, any, any>,
  UpdatePayload extends {
    data: BuiltOperationAggregation<B>[];
  },
>(
  name: string,
  creators: B[],
  getIdFromAggregate: (aggregate: BuiltOperationAggregation<B>) => string,
  updateAction: ActionCreatorWithPayload<UpdatePayload>,
  selectOptimisticState: (state: State) => {
    operations: ReturnType<B["optimistic"]>["create"][];
  },
  selectInitialValue: (
    state: State,
  ) => Record<string, BuiltOperationAggregation<B>>,
  enableLog?: boolean,
) {
  const operations = creators.reduce(
    (acc, curr) => {
      acc[curr.type as B["type"]] = curr.optimistic(curr.type) as any;
      return acc;
    },
    {} as {
      [k in B["type"]]: ReturnType<
        Extract<
          B,
          {
            optimistic: (type: k) => OptimisticOperationCreator<any, k, any>;
          }
        >["optimistic"]
      >;
    },
  );
  const workspace = new OptimisticWorkspace<ReturnType<B["optimistic"]>>(
    name,
    creators.map((value) => {
      return value.optimistic(value.type);
    }) as ReturnType<B["optimistic"]>[],
    getIdFromAggregate,
    enableLog,
  );
  const reducer = createOptimisticReducer(workspace, updateAction);
  const selectors = createOptimisticSelectors(
    workspace,
    selectOptimisticState as any,
    selectInitialValue,
  );
  const saga = createOptimisticSaga(
    reducer.addOperation,
    reducer.linkOperation,
    reducer.removeOperation,
    new WorkspaceAPIHandling(
      workspace,
      reducer.linkOperation,
      reducer.removeOperation,
      { selectBuilt: selectors.selectBuilt },
      creators.reduce(
        (acc, curr) => {
          acc[curr.type as B["type"]] = curr.call;
          return acc;
        },
        {} as { [k in B["type"]]: B["call"] },
      ),
    ),
  );

  return {
    reducer: reducer.reducer,
    actions: {
      addOperation: reducer.addOperation,
      linkOperation: reducer.linkOperation,
      removeOperation: reducer.removeOperation,
      removeOperations: reducer.removeOperations,
    },
    saga: saga.saga,
    delaySnapshot: saga.delaySnapshot,
    selectors,
    operations,
  };
}
