import { Text, TextStyle } from "react-native";
import { MatchedSubString } from "react-native-google-places-autocomplete";

export function decorateText(
  index: number,
  text: string,
  matched: MatchedSubString[],
  style?: TextStyle,
  highlightStyle?: TextStyle,
) {
  let savedOffset = 0;
  const formatted: Array<JSX.Element | string> = [];

  for (const match of matched) {
    const { length, offset } = match;
    const leftString = text.slice(savedOffset, offset);
    const rightString = text.slice(offset, offset + length);

    if (leftString.length > 0) {
      formatted.push(leftString);
    }
    if (rightString.length > 0) {
      formatted.push(
        <Text key={rightString} style={highlightStyle}>
          {rightString}
        </Text>,
      );
    }

    savedOffset = offset + length;
  }

  if (savedOffset < text.length) {
    const lastString = text.slice(-(text.length - savedOffset));
    formatted.push(lastString);
  }

  return (
    <Text
      style={style}
      numberOfLines={1}
      nativeID={`place-selection-result-${index}`}
    >
      {formatted}
    </Text>
  );
}

// 48.851047226761544, 2.31782274186645
// 48.851047226761544+2.31782274186645
// +48.851047226761544+-2.31782274186645

export function checkForCoordinates(addressText: string | undefined) {
  if (addressText === undefined) {
    return undefined;
  }
  const regex = /([-+]?\d+(?:\.\d+)?)[,+]\s*([-+]?\d+(?:\.\d+)?)/;

  const found = addressText.match(regex);

  if (found && found.length === 3) {
    const [, latitudeStr, longitudeStr] = found;
    const latitude = Number(latitudeStr);
    const longitude = Number(longitudeStr);

    if (
      latitude < -90 ||
      latitude > 90 ||
      longitude < -180 ||
      longitude > 180
    ) {
      return undefined;
    }

    return { latitude, longitude };
  }
  return undefined;
}
