import { useEffect, useMemo, useState } from "react";
import keyBy from "lodash/keyBy";
import memoize from "lodash/memoize";

import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { getCarrierCountry, getDeviceCountry } from "@kraaft/shared/core/utils";

import CountryCodes from "./assets/CountryCodes.json";

export function useCountryCodes() {
  return useMemo(() => {
    const dialCodeMap = keyBy(CountryCodes, (code) => code.dial_code);
    const countryCodeMap = keyBy(CountryCodes, (code) => code.code);

    const countryCodeItems: KSelectableListItem[] = CountryCodes.map((code) => {
      return {
        label: `${code.emoji} ${code.name} (${code.dial_code})`,
        identifier: code.code,
      };
    });

    return {
      countryCodeItems,
      countryCodeMap,
      dialCodeMap,
    };
  }, []);
}

export const getDeviceDialCode = memoize(async () => {
  const carrierCountry = await getCarrierCountry();
  const carrierDialCode = CountryCodes.find(
    (country) => country.code === carrierCountry,
  )?.dial_code;

  if (carrierDialCode) {
    return carrierDialCode;
  }

  const deviceCountry = getDeviceCountry();
  return (
    CountryCodes.find((country) => country.code === deviceCountry)?.dial_code ??
    "+33"
  );
});

export const useDeviceDialCode = () => {
  const [dialCode, setDialCode] = useState(" ");

  useEffect(() => {
    async function setInitialCode() {
      setDialCode(await getDeviceDialCode());
    }

    setInitialCode().catch(console.error);
  }, []);

  return [dialCode, setDialCode] as const;
};

export const useCarrierCountry = () => {
  const [carrierCountry, setCarrierCountry] = useState<string | undefined>();

  useEffect(() => {
    async function setInitialCountry() {
      setCarrierCountry(await getCarrierCountry());
    }

    setInitialCountry().catch(console.error);
  }, []);

  return carrierCountry;
};
