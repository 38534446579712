import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { useBooleanState } from "@kraaft/helper-hooks";
import { DraggableMessageType } from "@kraaft/shared/components/draggableMessage/types";
import {
  EmptyImagesPreview,
  ImagesPreview,
} from "@kraaft/shared/components/imagesPreview";
import type {
  AttachmentsEditorProps,
  ModularDetailsAttachmentContext,
} from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/attachmentsEditor.props";
import { fileHelper } from "@kraaft/shared/core/modules/file/fileHelper";
import type { ImageAttachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { ModularDisplayExtendedRequirements } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { filterAttachments } from "@kraaft/shared/core/utils";
import {
  ActionSheetHost,
  Button,
  ButtonHandle,
  Droppable,
  Spacing,
} from "@kraaft/ui";

import { ImportImageMethodSheetContent } from "./importImageMethodSheet/importImageMethodSheetContent";

interface GalleryAttachmentsEditorProps {
  value: AttachmentsEditorProps["value"];
  column: AttachmentsEditorProps["column"];
  context: ModularDetailsAttachmentContext;
  id?: string;
  disabled?: boolean;
  isLocked?: boolean;
  recordType: ModularDisplayExtendedRequirements["recordType"];
}

export const GalleryAttachmentsEditor = (
  props: GalleryAttachmentsEditorProps,
) => {
  const { value, column, id, context, disabled, isLocked, recordType } = props;

  const { t } = useTranslation();

  const {
    isLoadingColumn,
    onDrop,
    onPickImages,
    onDropFiles,
    openCamera,
    openImportImagesFromConversationModal,
  } = context;

  const importImageButtonRef = useRef<ButtonHandle>(null);

  const items = useMemo(
    () => (filterAttachments(value, ["image"]) as ImageAttachment[]) ?? [],
    [value],
  );

  const isLoading = isLoadingColumn(column);

  const openCarousel = useCallback(
    (index: number) => {
      context.openCarousel(column, index);
    },
    [column, context],
  );

  const [
    isImportImageMethodSheetOpen,
    openImportImageMethodSheet,
    closeImportImageMethodSheet,
  ] = useBooleanState(false);

  const onAttachFromConversation = useCallback(() => {
    openImportImagesFromConversationModal?.(column);
  }, [openImportImagesFromConversationModal, column]);

  const handleDroppedFiles = useCallback(
    (files: File[]) => {
      onDropFiles(column)(files.map((file) => fileHelper.fromWebFile(file)));
    },
    [column, onDropFiles],
  );

  return (
    <Droppable
      onDrop={onDrop(column)}
      onDropFiles={handleDroppedFiles}
      dropAcceptSystemFile={!isLocked}
      dropAcceptType={!isLocked ? DraggableMessageType.MessageImage : []}
      dropIndicatorText={t("depositHere")}
    >
      <View style={styles.container}>
        {!isLocked || items.length > 0 ? (
          <ImagesPreview
            attachments={items}
            openCarousel={openCarousel}
            isLoading={isLoading}
          />
        ) : (
          <EmptyImagesPreview />
        )}
        {!isLocked && (
          <>
            <Button
              id={id}
              ref={importImageButtonRef}
              icon="image-plus"
              text={t("modularity.add")}
              onPress={
                recordType === "formPreview"
                  ? onPickImages(column)
                  : openImportImageMethodSheet
              }
              disabled={disabled}
              variant="QUATERNARY"
              alignContent="left"
            />
            <ActionSheetHost
              anchor={importImageButtonRef}
              open={isImportImageMethodSheetOpen}
              onClose={closeImportImageMethodSheet}
            >
              <ImportImageMethodSheetContent
                onClose={closeImportImageMethodSheet}
                handleImageFromConversation={onAttachFromConversation}
                onOpenCamera={openCamera(column)}
                onPickImage={onPickImages(column)}
              />
            </ActionSheetHost>
          </>
        )}
      </View>
    </Droppable>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: Spacing.S8,
  },
});
