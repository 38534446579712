import { useCallback, useMemo } from "react";

import { useMeshContext } from "@kraaft/helper-hooks";
import { useUserDropdownItems } from "@kraaft/shared/components/modular/details/editors/userEditor/useUserDropdownItems";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularDisplayRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { KEditorProps } from "@kraaft/web/src/components/modularTable/components/types";

import { UserEditorInternal } from "./userEditor.internal";

export const UserEditor = (props: KEditorProps<KColumnType.user>) => {
  const { column, row, onClose, onRowChange } = props;

  const { poolId, getPoolUsers, getOrLoadUsers } = useMeshContext(
    ModularDisplayRequirementsContext,
  );

  const allowMultiple = column.allowMultiple ?? false;

  const value = useMemo(
    () => row.properties[column.key]?.value ?? [],
    [column.key, row.properties],
  );

  const users = useUserDropdownItems({
    selectedUserIds: value,
    selectedUsers: getOrLoadUsers(value),
    poolUsers: getPoolUsers(),
    poolId,
  });

  const handleClose = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleSelectionChange = useCallback(
    (newValue: string[] | undefined) => {
      onRowChange(
        {
          ...row,
          properties: {
            ...row.properties,
            [column.key]: {
              columnType: column.type,
              value: newValue,
            },
          },
        },
        !allowMultiple,
      );
    },
    [onRowChange, row, column.key, column.type, allowMultiple],
  );

  return (
    <UserEditorInternal
      onClose={handleClose}
      onChange={handleSelectionChange}
      users={users}
      allowMultiple={allowMultiple ?? false}
      value={value}
    />
  );
};
