import { useCallback, useEffect, useRef } from "react";
import { FlatList, ListRenderItemInfo, View } from "react-native";
import { orderBy } from "lodash";

import { Collapsible } from "@kraaft/shared/components/animated/collapsible";
import { userSortForAvatarList } from "@kraaft/shared/components/createRoom/roomMembers/selectRoomMembersUtils";
import { AvatarContent } from "@kraaft/shared/components/userSelection/avatarList/avatarList.types";
import { AvatarListItem } from "@kraaft/shared/components/userSelection/avatarList/avatarListItem";
import { usePrevious } from "@kraaft/shared/core/utils/hooks";

import { styles } from "./avatarList.styles";

const Separator = () => <View style={styles.itemSeparator} />;

interface Props {
  items: AvatarContent[];
  onPress?: (user: AvatarContent) => void;
  frozenUserIds?: Set<string>;
  displayUserThumbnailOrInitials?: boolean;
  currentUserId: string | undefined;
}

const AvatarList = (props: Props) => {
  const {
    onPress,
    items,
    displayUserThumbnailOrInitials,
    frozenUserIds = new Set([]),
    currentUserId,
  } = props;

  const flatListRef = useRef<FlatList>(null);

  const sortedUsers = orderBy(
    items,
    userSortForAvatarList(Array.from(frozenUserIds), items.length),
  );

  const itemLength = items.length;
  const previousItemLength = usePrevious(itemLength);

  useEffect(() => {
    if (previousItemLength && itemLength > previousItemLength) {
      setTimeout(() => flatListRef.current?.scrollToEnd());
    }
  }, [itemLength, items.length, previousItemLength]);

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<AvatarContent>) => (
      <AvatarListItem
        isRemovable={!frozenUserIds.has(item.id)}
        isCurrentUser={item.id === currentUserId}
        content={item}
        onPress={onPress}
        displayUserThumbnailOrInitials={displayUserThumbnailOrInitials}
      />
    ),
    [frozenUserIds, currentUserId, onPress, displayUserThumbnailOrInitials],
  );

  const keyExtractor = useCallback((item: AvatarContent) => item.id, []);

  return (
    <Collapsible open={itemLength > 0}>
      <View style={styles.container}>
        <FlatList
          ref={flatListRef}
          data={sortedUsers}
          testID="ide2e-selected-users-horizontal"
          horizontal
          persistentScrollbar
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          ItemSeparatorComponent={Separator}
          showsHorizontalScrollIndicator
          contentContainerStyle={styles.listContentContainer}
        />
      </View>
    </Collapsible>
  );
};

export { AvatarList };
