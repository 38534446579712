import { StyleSheet } from "react-native";

import { styleObject } from "@kraaft/shared/styles/panel.styles";
import { Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  searchBar: {
    marginTop: Spacing.S16,
    marginBottom: Spacing.S8,
    marginHorizontal: Spacing.S16,
  },
  actions: {
    marginVertical: Spacing.S8,
    marginHorizontal: Spacing.S16,
  },
  containerBorder: {
    marginTop: Spacing.S8,
    marginHorizontal: Spacing.S16,
    ...styleObject.borderBottom,
  },
  list: {
    flex: 1,
    marginTop: Spacing.S8,
  },
});
