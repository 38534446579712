import React from "react";
import { StyleSheet, View } from "react-native";

import { Spacing, Text } from "@kraaft/ui";

type WorkflowSubSectionProps = React.PropsWithChildren<{
  title: string;
}>;

export const WorkflowSubSection = (props: WorkflowSubSectionProps) => {
  return (
    <View style={style.section}>
      <Text weight="bold" size="MEDIUM" color="GREY_JET">
        {props.title}
      </Text>
      {props.children}
    </View>
  );
};

const style = StyleSheet.create({
  section: {
    rowGap: Spacing.S8,
  },
});
