import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { RootState } from "@kraaft/shared/core/store";

import {
  EditedSchemaTemplate,
  SchemaTemplate,
  SchemaTemplateState,
} from "./schemaTemplateState";

const selectSchemaTemplateState = (state: RootState): SchemaTemplateState =>
  state.schemaTemplate;

export const selectEditedSchemaTemplate = createSelector(
  selectSchemaTemplateState,
  (state): EditedSchemaTemplate | undefined => state.edit,
);

export const selectSchemaTemplate = memoize((schemaId) =>
  createSelector(
    selectSchemaTemplateState,
    (state): SchemaTemplate | undefined => state.schemaTemplates[schemaId],
  ),
);

export const selectSchemaTemplateIds = createSelector(
  selectCurrentPoolId,
  selectSchemaTemplateState,
  (poolId, state): string[] => {
    if (!poolId) {
      return [];
    }
    return state.poolToSchemaTemplates[poolId] || [];
  },
);

function isExistingSchemaTemplate(
  value?: SchemaTemplate,
): value is SchemaTemplate {
  return Boolean(value);
}

export const selectSchemaTemplates = createSelector(
  selectSchemaTemplateState,
  selectSchemaTemplateIds,
  (state, ids): SchemaTemplate[] =>
    ids.map((id) => state.schemaTemplates[id]).filter(isExistingSchemaTemplate),
);
