import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

import { useMeshContext } from "@kraaft/helper-hooks";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ColorStyle, FontSize, PixelSize, Preloader } from "@kraaft/ui";
import { CopiableText } from "@kraaft/web/src/components/copiableText";
import { SchemaBuilderContext } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

import { useStyles } from "./elementsEditorColumnItemKey.styles";

interface ElementsEditorColumnItemKeyProps {
  column: KSchemaColumn;
  hideKey?: boolean;
}

const ElementsEditorColumnItemKey_ = ({
  column,
  hideKey,
}: ElementsEditorColumnItemKeyProps) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const schemaCollection = useMeshContext(
    SchemaBuilderContext,
    (context) => context.schema.collection,
  );

  const prefix = schemaCollection === "room" ? "$conv_" : "";

  const reportKey = `##${prefix}${column.reportKey}##`;

  return (
    <>
      {column.isOptimistic && (
        <Preloader
          size={PixelSize.S16}
          nativeID="form-builder-column-loading"
        />
      )}
      {!hideKey && !column.isOptimistic && (
        <Tooltip
          title={t("formBuilder.schema.columnKeyTip")}
          arrow
          classes={{ tooltip: styles.tooltip }}
        >
          <div>
            <CopiableText
              color={ColorStyle.FONT_LOW_EMPHASIS}
              size={FontSize.SMALL}
              textToCopy={reportKey}
            >
              {reportKey}
            </CopiableText>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export const ElementsEditorColumnItemKey = memo(
  ElementsEditorColumnItemKey_,
) as typeof ElementsEditorColumnItemKey_;
