import { makeStyles } from "@mui/styles";

import { nativeStylesObject } from "@kraaft/shared/components/selectionFooter/selectionFooter.styles.native";

export const useStyles = makeStyles({
  ...nativeStylesObject,
  footer: {
    ...nativeStylesObject.footer,
    "& > div": {
      justifyContent: "center",
    },
  },
});
