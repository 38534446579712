import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "fast-deep-equal";

import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { openPreview } from "@kraaft/shared/core/modules/preview/previewActions";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KFolderSchema,
  KSchemaColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { useDefaultModularColumnContext } from "@kraaft/shared/core/modules/schema/modularTypes/useDefaultModularColumnContext";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import {
  selectSchemaLockLookup,
  selectSchemaOrderedColumns,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { EditableSchemaTable } from "@kraaft/web/src/components/modularFoldersTable/editableSchemaTable";
import { ModularActionsCell } from "@kraaft/web/src/components/modularFoldersTable/modularActionsCell";
import { ModularTableActions } from "@kraaft/web/src/components/modularTable/schema";
import { TableColumnsContext } from "@kraaft/web/src/components/modularTable/tableContext";

import { useModularFoldersTableAttachmentContext } from "./useModularFoldersTableAttachmentContext";

export const MODULAR_ACTIONS_CELL_WIDTH = 31;
export const MODULAR_ACTIONS_CELL_PADDING = 8;

interface Props {
  schema: KFolderSchema;
  folders: ModularFolder[];
}

const ModularFoldersTable_ = (props: Props) => {
  const { schema, folders } = props;
  const dispatch = useDispatch();

  const schemaLockLookup = useSelector(selectSchemaLockLookup(schema.id));
  const schemaOrderedColumns = useSelector(
    selectSchemaOrderedColumns(schema.id),
  );

  const defaultColumnsContext = useDefaultModularColumnContext(
    schemaLockLookup,
    schemaOrderedColumns,
  );
  const columnContext = useMemo(
    () =>
      schemaOrderedColumns.reduce<TableColumnsContext>((acc, curr) => {
        const existingContext = defaultColumnsContext.columns[curr.key];
        if (existingContext) {
          acc[curr.key] = existingContext;
        }
        if (curr.type === KColumnType.roomName) {
          acc[curr.key] = {
            ...defaultColumnsContext.columns[curr.key],
            onExpand: (row: ModularRecord) => {
              const modularFolder = folders.find(
                (folder) => folder.id === row.id,
              );

              if (modularFolder) {
                dispatch(
                  openPreview({ type: "room", roomId: modularFolder.roomId }),
                );
              }
            },
          };
        }
        if (curr.key === KSchemaRemarkableColumns.ROOM_TITLE) {
          acc[curr.key] = {
            ...defaultColumnsContext.columns[curr.key],
            onExpand: (row: ModularRecord) => {
              dispatch(
                openPreview({
                  type: "modularFolder",
                  folderId: row.id,
                }),
              );
            },
          };
        }
        return acc;
      }, {}),
    [defaultColumnsContext, dispatch, folders, schemaOrderedColumns],
  );

  const lockingColumns = useMemo(
    () => KSchemaUtils.getLockingColumnsForSchema(schema),
    [schema],
  );

  const onCellUpdate = useCallback<
    NonNullable<ModularTableActions["cell"]["onUpdate"]>
  >(
    (updates) => {
      dispatch(
        OfflineModularFolderActions.edit({
          editions: updates.map((update) => ({
            id: update.id,
            properties: [
              [
                update.column.key,
                {
                  columnType: update.column.type,
                  value: update.value,
                } as KSchemaColumnValue,
              ],
            ],
          })),
        }),
      );
    },
    [dispatch],
  );

  const actionCellFormatter = useCallback(
    ({ row }: { row: ModularRecord }) => (
      <ModularActionsCell
        row={row}
        schema={schema}
        lockingColumns={lockingColumns}
      />
    ),
    [schema, lockingColumns],
  );

  const attachmentContext = useModularFoldersTableAttachmentContext(folders);

  return (
    <EditableSchemaTable
      schema={schema}
      columnContext={columnContext}
      rows={folders}
      onCellUpdate={onCellUpdate}
      attachmentContext={attachmentContext}
      actionCellFormatter={actionCellFormatter}
    />
  );
};

const ModularFoldersTable = React.memo(
  ModularFoldersTable_,
  isEqual,
) as typeof ModularFoldersTable_;

export { ModularFoldersTable };
