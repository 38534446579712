import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";

import {
  MessageRendererProps,
  useMessageStyles,
} from "@kraaft/shared/components/message/messageUtils";
import { MessageTypes } from "@kraaft/shared/core/modules/message";
import {
  selectCurrentUserId,
  selectUsername,
} from "@kraaft/shared/core/modules/user/userSelectors";

import { styles as baseStyles } from "../message.styles";
import { styles } from "./messageDeleted.styles";

type Props = Omit<
  MessageRendererProps<Exclude<MessageTypes.Message, MessageTypes.LogMessage>>,
  "roomId"
>;

const MessageDeleted_ = (props: Props) => {
  const { message } = props;
  const { senderId } = message;
  const isReply = "isReply" in message && message.isReply === true;

  const { t } = useTranslation();
  const author = useSelector(selectUsername(senderId));
  const currentUserId = useSelector(selectCurrentUserId);
  const deletedByUsername = useSelector(
    selectUsername(message.deletedBy || ""),
  );
  const deletedBy = message.deletedBy ? deletedByUsername : author;

  const { messageStyle, textContainerStyle, textStyle } = useMessageStyles({
    isReply,
    hasReactions: false,
    isFontSizeLargeBody: true,
  });

  const text =
    currentUserId === message.deletedBy
      ? t("youRemovedMessage")
      : `${deletedBy} ${t("removedMessage")}`;

  return (
    <View
      style={[
        baseStyles.messageBox,
        messageStyle,
        textContainerStyle,
        styles.container,
      ]}
    >
      <Text style={[textStyle, styles.text]}>{text}</Text>
    </View>
  );
};

export const MessageDeleted = React.memo(MessageDeleted_);
