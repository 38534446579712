import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { clearLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { selectLoader } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { addMemberToPool } from "@kraaft/shared/core/modules/poolMember/poolMemberActions";

const LOADING_ID = "addUserDialog";

const useStyles = makeStyles({
  dialogContentContainer: {
    flex: 1,
  },
});

interface AddUserDialogProps {
  poolId: string;
  open: boolean;
  onClose: () => void;
}

const AddUserDialog = ({ poolId, open, onClose }: AddUserDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const loader = useSelector(selectLoader(LOADING_ID));
  const classes = useStyles();

  const title = t("superadminAddUser");

  const content = (
    <div className={classes.dialogContentContainer}>
      <KInput label={t("id")} value={input} onChangeText={setInput} />
    </div>
  );

  const onConfirm = () => {
    dispatch(addMemberToPool({ userId: input, poolId, loadingId: LOADING_ID }));
  };

  useEffect(() => {
    if (loader?.status === LoaderStatus.SUCCESS) {
      dispatch(clearLoader(LOADING_ID));
      onClose();
    }
  }, [dispatch, loader?.status, onClose]);

  const actions: FooterActions = [
    {
      accessibilityLabel: t("confirm"),
      text: t("confirm"),
      onPress: onConfirm,
      variant: "PRIMARY",
      loading: loader?.status === LoaderStatus.LOADING,
    },
    {
      accessibilityLabel: t("cancel"),
      text: t("cancel"),
      onPress: onClose,
    },
  ];

  return (
    <KDialog
      open={open}
      onClose={onClose}
      title={title}
      content={content}
      actions={actions}
    />
  );
};

export { AddUserDialog };
