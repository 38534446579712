import { eventChannel } from "redux-saga";
import { call, put, spawn, take, takeEvery } from "typed-redux-saga/macro";

import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { acknowledgeConversationOnboardingSaga } from "@kraaft/shared/core/modules/user/sagas/acknowledgeConversationOnboarding";
import { acknowledgePoolOnboardingSaga } from "@kraaft/shared/core/modules/user/sagas/acknowledgePoolOnboarding";
import { blockUserSaga } from "@kraaft/shared/core/modules/user/sagas/blockUser.saga";
import { homeEmptyStateSaga } from "@kraaft/shared/core/modules/user/sagas/homeEmptyState.saga";
import { loadUserSaga } from "@kraaft/shared/core/modules/user/sagas/loadUser.saga";
import {
  UserActions,
  UserStateActions,
} from "@kraaft/shared/core/modules/user/userActions";
import { Firestore } from "@kraaft/shared/core/services/firestore";

import { authSagas } from "./authSagas";
import { User } from "./userState";

export function* userSagas() {
  yield* spawn(authSagas);
  yield* spawn(blockUserSaga);
  yield* spawn(acknowledgeConversationOnboardingSaga);
  yield* spawn(acknowledgePoolOnboardingSaga);
  yield* spawn(homeEmptyStateSaga);
  yield* spawn(loadUserSaga);

  yield* takeEvery(PoolStateActions.setPoolLocation, loadPoolUsersSaga);
}

function* loadPoolUsersSaga({
  payload,
}: ReturnType<typeof PoolStateActions.setPoolLocation>) {
  const { poolId } = payload;
  yield* put(UserStateActions.setLoadingPoolUsers({ poolId, isLoading: true }));

  const channel = yield* call(createPoolChannel, poolId);

  yield* takeEvery(channel, receiveUserPool);

  yield* take([
    UserActions.userDisconnectedFromFirebase,
    PoolStateActions.setPoolLocation,
  ]);
  channel.close();
}

type PoolUsersEvent = {
  poolId: string;
  users: Record<string, User>;
};

function createPoolChannel(poolId: string) {
  return eventChannel<PoolUsersEvent>((emit) => {
    const unsubscribe = Firestore.subscribeToUserPoolInfo(poolId, (users) =>
      emit({ poolId, users }),
    );

    return () => {
      unsubscribe();
    };
  });
}

function* receiveUserPool(event: PoolUsersEvent) {
  yield* put(UserStateActions.poolUsersUpdated(event));
}
