import { makeStyles } from "@mui/styles";

import { ColorStyle, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    position: "relative",
    height: 55,
    width: 55,
    borderRadius: Radius.SMALL,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  icon: {
    borderWidth: "1px",
    width: "min-content",
  },
  icons: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateRows: "auto 1fr",
    gap: "24px",
    padding: Spacing.S24,
  },
  iconItem: {
    cursor: "pointer",
  },
  basePopupIcon: {
    transition: "all 250ms",
  },
});
