export const webBoxModel = (paddings: number[]) => {
  let result = "";

  for (const [index, padding] of paddings.entries()) {
    if (index === 0) {
      result += `${padding}px`;
    } else {
      result += ` ${padding}px`;
    }
  }
  return result;
};
