import { useCallback, useContext, useRef } from "react";
import { InputBase } from "@mui/material";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { useTableInputBehavior } from "@kraaft/web/src/components/modularTable/components/table/hooks/useTableInputBehavior";
import { ModularTableContext } from "@kraaft/web/src/components/modularTable/components/table/modularTableContext";
import { KEditorProps } from "@kraaft/web/src/components/modularTable/components/types";

export const TextEditor = (
  props:
    | KEditorProps<KColumnType.shortText>
    | KEditorProps<KColumnType.automatedAutoIncrement>
    | KEditorProps<KColumnType.currency>
    | KEditorProps<KColumnType.number>,
) => {
  const { onEnterChangeCell } = useContext(ModularTableContext);

  const { column, row, onRowChange, onClose } = props;
  const type = column.type;
  const defaultValue = row.properties[column.key]?.value;

  const inputRef = useRef<HTMLInputElement>(null);

  let inputType = "text";
  switch (type) {
    case "currency":
    case "number":
      inputType = "number";
      break;
    default:
      break;
  }

  const handleArrival = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }, []);

  const { onKeyDownDetectArrival, onKeyPressDetectArrival } =
    useTableInputBehavior({
      onInputArrival: handleArrival,
    });

  const onChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
      const inputValue = event.target.value;

      const value = inputValue
        ? inputType === "number"
          ? Number(inputValue)
          : inputValue
        : undefined;

      onRowChange({
        ...row,
        properties: {
          ...row.properties,
          [column.key]: {
            columnType: column.type,
            value,
          } as KSchemaColumnValue,
        },
      });
    },
    [column.key, column.type, inputType, onRowChange, row],
  );

  const onBlur = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleKeyDown = useCallback(
    (
      event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
      onKeyDownDetectArrival(event);
      if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
        event.stopPropagation();
      }

      if (event.key === "Escape" && defaultValue) {
        const target = event.target as HTMLInputElement | HTMLTextAreaElement;
        target.value = String(defaultValue);
      }

      if (event.key === "Enter") {
        onEnterChangeCell();
      }
    },
    [defaultValue, onKeyDownDetectArrival, onEnterChangeCell],
  );

  return (
    <InputBase
      defaultValue={defaultValue}
      type={inputType}
      fullWidth
      inputProps={{
        ref: inputRef,
      }}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus
      onKeyDown={handleKeyDown}
      onKeyPress={onKeyPressDetectArrival}
    />
  );
};
