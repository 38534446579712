import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { Pressable } from "react-native";

import { useHover } from "@kraaft/helper-hooks";
import { EntryDetailsSheet } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayoutWithDetails/entryDetails.sheet";
import { getSimplifiedValuesFromModularRecordProperties } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayoutWithDetails/entryLayoutWithDifferenceDetails.utils";
import { useEntryLayoutWithDetailsActiveStyle } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayoutWithDetails/useEntryLayoutWithDetailsActiveStyle";
import { DifferenceLayout } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceLayout";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordProperty } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { type ResourceKey } from "@kraaft/shared/core/services/i18next";

interface EntryLayoutWithDetailsProps {
  date: Date;
  i18nKey: ResourceKey;
  i18nValues: Record<string, unknown>;
  oldValue?: ModularRecordProperty;
  newValue?: ModularRecordProperty;
  schemaColumn: KSchemaColumn | undefined;
  onOpenDetails?: () => void;
}

export const EntryLayoutWithDifferenceDetails = ({
  children,
  date,
  i18nKey,
  i18nValues,
  oldValue,
  newValue,
  schemaColumn,
  onOpenDetails,
}: PropsWithChildren<EntryLayoutWithDetailsProps>) => {
  const { t } = useTranslation();
  const entryContainerRef = useRef(null);

  const isHovered = useHover(entryContainerRef);

  const simplifiedValues = useMemo(
    () => getSimplifiedValuesFromModularRecordProperties(oldValue, newValue),
    [newValue, oldValue],
  );

  const content = useMemo<ReactNode>(
    () =>
      simplifiedValues !== null ? (
        <DifferenceLayout
          valueType={simplifiedValues.valueType}
          removedValue={simplifiedValues.removedValue}
          addedValue={simplifiedValues.addedValue}
          schemaColumn={schemaColumn}
        />
      ) : null,
    [schemaColumn, simplifiedValues],
  );

  const { open, element, isOpen } = EntryDetailsSheet.use({
    anchor: entryContainerRef,
    date,
    i18nKey,
    i18nValues,
    content,
  });

  const handlePress = useCallback(() => {
    onOpenDetails?.();
    open();
  }, [onOpenDetails, open]);

  const activeStyle = useEntryLayoutWithDetailsActiveStyle(isHovered || isOpen);

  return (
    <>
      <Pressable
        ref={entryContainerRef}
        onPress={handlePress}
        style={activeStyle}
        accessibilityLabel={t("history.seeEntryDetails")}
      >
        {children}
      </Pressable>
      {element}
    </>
  );
};
