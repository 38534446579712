import { ThemeProvider } from "@mui/material/styles";

import { theme } from "./appTheme.styles";

interface Props {
  children: React.ReactNode;
}

const AppTheme = (props: Props) => {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export { AppTheme };
