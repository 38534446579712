import { useCallback } from "react";
import { FlatList, StyleProp, ViewStyle } from "react-native";
import { matchPath } from "react-router-dom";
import { StrictOmit } from "ts-essentials";

import {
  MenuItem,
  MenuItemProps,
} from "@kraaft/shared/components/menu/menuItem";
import { MenuSeparator } from "@kraaft/shared/components/menu/menuSeparator";

export type NavigationMenuItemProps = StrictOmit<
  MenuItemProps,
  "isSelected"
> & {
  matchRoute: string | string[];
};

const NavigationMenu = (props: {
  currentRoute: string;
  items: NavigationMenuItemProps[];
  menuStyle?: StyleProp<ViewStyle>;
}) => {
  const { currentRoute, items, menuStyle } = props;

  const keyExtractor = useCallback(
    (item: NavigationMenuItemProps) => item.id,
    [],
  );

  const renderItem = useCallback(
    ({ item }: { item: NavigationMenuItemProps }) => {
      const isSelected = Boolean(matchPath(currentRoute, item.matchRoute));
      return (
        <MenuItem {...item} isSelected={isSelected} onPress={item.onPress} />
      );
    },
    [currentRoute],
  );

  return (
    <FlatList
      keyExtractor={keyExtractor}
      data={items}
      renderItem={renderItem}
      ItemSeparatorComponent={MenuSeparator}
      style={menuStyle}
    />
  );
};

export { NavigationMenu };
