import { put } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { SuperadminApi } from "@kraaft/shared/core/services/api/superadminApi";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* superadminDeleteMessage(
  action: ReturnType<typeof roomActions.removeMessageRequest>,
) {
  const { payload } = action;
  try {
    const { messageId } = payload;

    yield SuperadminApi.deleteMessage({
      messageId,
    });
  } catch (error) {
    yield* put(showError({ title: i18n.t("errorRemovingMessage") }));
    yield* put(roomActions.removeMessageFailure(payload));
  }
}
