import { ReactElement, RefObject } from "react";
import { FlatListProps } from "react-native";
import { StrictOmit } from "ts-essentials";

import { OnScrollInfo } from "@kraaft/shared/components/bidirectionalMessageList";
import { PlatformSelect } from "@kraaft/shared/core/utils/platformSelect/platformSelect";

export const CONSIDER_NEAR_BOTTOM_OF_LIST = 300;

export type BidirectionalListProps<T> = PlatformSelect<{
  native: StrictOmit<FlatListProps<T>, "data" | "onScroll" | "renderItem">;
  web: {
    //not implemented
    keyboardShouldPersistTaps: FlatListProps<T>["keyboardShouldPersistTaps"];
  };
}> & {
  items: T[];
  renderItem: (item: T, index: number) => ReactElement | null;
  getIdFromItem: (item: T) => string;
  getRenderKeyFromItem: (item: T) => string;
  laterEdgeComponent?: ReactElement | undefined;
  earlierEdgeComponent?: ReactElement | undefined;
  onScroll: (info: OnScrollInfo, userTriggered: boolean) => void;
  handle?: RefObject<BidirectionalListHandle>;
};

export type ScrollAnchor = "start" | "center" | "end";

export interface ScrollPosition {
  index: number;
  length: number;
}

export interface BidirectionalListHandle {
  scrollToBottomOfList: () => Promise<void>;
  scrollToElement: (
    id: string,
    position: ScrollPosition,
    shouldAnimate: boolean,
    isAlreadyDisplayed: boolean,
    anchor: ScrollAnchor,
  ) => Promise<void>;
  isNearBottomOfList: () => boolean;
}
