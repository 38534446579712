import {
  ContextMenuButtonProps,
  LegacyContextMenuButton,
} from "@kraaft/web/src/components/contextMenuButton";
import { KTableContext } from "@kraaft/web/src/components/kTable";

import { useStyles } from "./TableContextMenuButton.styles";

interface OptionButtonProps
  extends Omit<ContextMenuButtonProps, "onVisibilityChange"> {
  context: KTableContext;
  disabled?: boolean;
}

export const TableContextMenuButton = ({
  context,
  ...props
}: OptionButtonProps) => {
  const classes = useStyles();
  const { selectRow } = context;

  return (
    <div className={classes.iconButton}>
      <LegacyContextMenuButton
        {...props}
        onVisibilityChange={(visible) => selectRow(visible)}
      />
    </div>
  );
};
