import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import isEqual from "fast-deep-equal";

import { DirectoryActions } from "@kraaft/shared/core/modules/directory/directoryActions";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button, Preloader, Spacing } from "@kraaft/ui";
import { DirectoryTreeStructure } from "@kraaft/web/src/components/directoryTree/directoryTree.types";
import {
  hashSerializeDirectories,
  serializeDirectories,
} from "@kraaft/web/src/components/directoryTree/directoryTree.utils";
import { DefaultDirectoryTree } from "@kraaft/web/src/components/directoryTree/wrappers/defaultDirectoryTree";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { selectPoolDefaultDirectories } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminSelectors";
import { SerializedDirectory } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminState";
import { usePoolAdminSubscription } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminUtils";

import { useSettingsStyles } from "../settings.styles";

export const ManageDefaultDirectories = () => {
  const { t } = useTranslation();
  const settingsClasses = useSettingsStyles();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [directoryTreeKey, setDirectoryTreeKey] = useState(false);
  const [newSerializedDirectories, setNewSerializedDirectories] = useState<
    SerializedDirectory[]
  >([]);

  useTrackPage("SettingsDefaultDirectories");

  const poolId = useSelector(selectCurrentPoolId);
  usePoolAdminSubscription(poolId);

  const serializedDirectories = useSelector(
    selectPoolDefaultDirectories(poolId),
  );

  const hasChanged = useMemo(() => {
    if (serializedDirectories === undefined) {
      return false;
    }
    const hash1 = hashSerializeDirectories(serializedDirectories);
    const hash2 = hashSerializeDirectories(newSerializedDirectories);

    return !isEqual(hash1, hash2);
  }, [newSerializedDirectories, serializedDirectories]);

  const handleDirectoriesUpdate = useCallback(
    (newDirectoryTreeStructure: DirectoryTreeStructure) => {
      setNewSerializedDirectories(
        serializeDirectories(newDirectoryTreeStructure),
      );
    },
    [],
  );

  const handleSavePress = useCallback(() => {
    if (newSerializedDirectories === undefined || poolId === undefined) {
      return;
    }
    dispatch(
      DirectoryActions.setDefaultTreeForPool({
        poolId,
        tree: newSerializedDirectories,
      }),
    );
  }, [newSerializedDirectories, dispatch, poolId]);

  return (
    <div className={settingsClasses.pageContainer}>
      <PageHeader
        title={t("settingsPageDefaultFoldersTitle")}
        titleElementId="settings-default-directories-title"
      />
      <div className={classes.panelsContainer}>
        <span className={classes.subtitle}>
          {t("settingsPageDefaultFoldersSubtitle")}
        </span>
        {serializedDirectories ? (
          <div className={classes.container}>
            <div className={classes.treeContainer}>
              <DefaultDirectoryTree
                key={directoryTreeKey.toString()}
                serializedDirectories={serializedDirectories}
                onUpdate={handleDirectoriesUpdate}
              />
            </div>
            <div className={classes.footerButtonsContainer}>
              <Button
                accessibilityLabel={t("saveChanges")}
                text={t("saveChanges")}
                onPress={handleSavePress}
                disabled={!hasChanged}
              />
            </div>
          </div>
        ) : (
          <Preloader />
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  panelsContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  subtitle: {
    margin: `0 ${Spacing.S16}px ${Spacing.S32}px ${Spacing.S16}px`,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: 0,
  },

  treeContainer: {
    overflow: "auto",
  },

  footerButtonsContainer: {
    display: "flex",
    marginTop: Spacing.S24,

    gap: Spacing.S8,
  },
});
