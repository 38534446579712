import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useKeyPressEvent } from "react-use";

import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { Condition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import {
  subscribeToPoolRooms,
  unsubscribeFromPoolRooms,
} from "@kraaft/shared/core/modules/room/roomActions";
import { selectSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import {
  WorkflowOptimisticActions,
  WorkflowOptimisticOperations,
} from "@kraaft/shared/core/modules/workflows/workflow.optimistic";
import {
  WorkflowActions,
  WorkflowStateActions,
} from "@kraaft/shared/core/modules/workflows/workflowActions";
import { selectEditWorkflow } from "@kraaft/shared/core/modules/workflows/workflowSelectors";
import { conditionNeedsRooms } from "@kraaft/shared/core/modules/workflows/workflowUtils";
import { Button, Icon, Spacing, Text } from "@kraaft/ui";
import { FilterItem } from "@kraaft/web/src/components/filterMenu/filterItem";
import { FilterOperator } from "@kraaft/web/src/components/filterMenu/filterMenuTypes";
import { WorkflowSection } from "@kraaft/web/src/views/settings/workflows/components/workflowSection";
import { WorkflowAction } from "@kraaft/web/src/views/settings/workflows/workflowAction";
import { WorkflowSchemaSelector } from "@kraaft/web/src/views/settings/workflows/workflowSchemaSelector";
import { useStyles } from "@kraaft/web/src/views/settings/workflows/workflowSider.styles";

interface WorkflowSiderProps {
  onClose: () => void;
  poolId: string | undefined;
}

export const WorkflowSider = ({ onClose, poolId }: WorkflowSiderProps) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const { t } = useTranslation();
  const workflow = useSelector(selectEditWorkflow);
  const schema = useSelector(selectSchema(workflow?.schemaId));

  useKeyPressEvent("Escape", onClose);

  useEffect(() => {
    if (!workflow?.condition) {
      return;
    }
    if (poolId && conditionNeedsRooms(workflow.condition)) {
      dispatch(
        subscribeToPoolRooms({
          poolId,
        }),
      );
      return () => {
        dispatch(
          unsubscribeFromPoolRooms({
            poolId,
          }),
        );
      };
    }
  }, [dispatch, poolId, workflow?.condition]);

  const [hasError, setError] = useState(false);
  const disableUpdateWorkflow =
    hasError ||
    !workflow?.actions[0]?.payload.content ||
    (workflow?.actions[0]?.name === "notification" &&
      workflow?.actions[0]?.payload.users.length === 0);

  const setItem = useCallback(
    (item: Condition, index: number) => {
      if (!workflow) {
        return;
      }
      dispatch(
        WorkflowStateActions.updateEditing({
          condition: {
            ...workflow.condition,
            conditions: [
              ...workflow.condition.conditions.slice(0, index),
              item,
              ...workflow.condition.conditions.slice(index + 1),
            ],
          },
        }),
      );
    },
    [workflow, dispatch],
  );

  const addCondition = useCallback(() => {
    if (!workflow) {
      return;
    }
    dispatch(
      WorkflowStateActions.updateEditing({
        condition: {
          ...workflow.condition,
          conditions: [...workflow.condition.conditions, { type: "draft" }],
        },
      }),
    );
  }, [workflow, dispatch]);

  const deleteItem = useCallback(
    (index: number) => {
      if (!workflow) {
        return;
      }
      dispatch(
        WorkflowStateActions.updateEditing({
          condition: {
            ...workflow.condition,
            conditions: [
              ...workflow.condition.conditions.slice(0, index),
              ...workflow.condition.conditions.slice(index + 1),
            ],
          },
        }),
      );
    },
    [dispatch, workflow],
  );

  const setOperator = useCallback(
    (operator: FilterOperator) => {
      if (!workflow) {
        return;
      }
      dispatch(
        WorkflowStateActions.updateEditing({
          condition: {
            ...workflow.condition,
            operator,
          },
        }),
      );
    },
    [workflow, dispatch],
  );

  const onSave = useCallback(() => {
    if (workflow?.id) {
      dispatch(
        WorkflowOptimisticActions.addOperation(
          WorkflowOptimisticOperations.update.create({
            targetId: workflow.id,
            updatedWorkflow: workflow,
            withToast: true,
          }),
        ),
      );
    } else if (workflow) {
      dispatch(WorkflowActions.create({ congrats: true }));
    }
    onClose();
  }, [dispatch, onClose, workflow]);

  const onChangeWorkflowName = useCallback(
    (name: string) => {
      dispatch(
        WorkflowStateActions.updateEditing({
          name,
        }),
      );
    },
    [dispatch],
  );

  const setSchema = useCallback(
    (schemaId: string) => {
      dispatch(
        WorkflowStateActions.updateEditing({
          schemaId,
        }),
      );
    },
    [dispatch],
  );

  const schemaColumns = useMemo(
    () =>
      schema ? KSchemaUtils.flattenColumnsWithPath(schema.rootSection) : {},
    [schema],
  );

  if (!workflow) {
    return null;
  }

  return (
    <div className={styles.root}>
      <DefaultLayoutHeader
        headerLeft={
          <HeaderSide>
            <HeaderIconButton
              accessibilityLabel={t("close")}
              onPress={onClose}
              icon="x-close"
            />
          </HeaderSide>
        }
        headerTitle={t("workflowProperties")}
      />

      <ScrollView contentContainerStyle={style.content}>
        <View style={style.sectionsWrapper}>
          <KInput
            label={t("workflow.nameInputLabel")}
            value={workflow.name}
            onChangeText={onChangeWorkflowName}
            rightIcon={
              <div className={styles.editIcon}>
                <Icon name="edit-02" />
              </div>
            }
          />
          <WorkflowSection title={t("asSoonAs")}>
            <WorkflowSchemaSelector
              schemaId={workflow.schemaId}
              setSchema={setSchema}
            />
          </WorkflowSection>
          <WorkflowSection title={t("fullfillsCondition")}>
            {schema ? (
              <View style={style.conditionSectionContent}>
                {workflow.condition.conditions.map((cond, k, a) => (
                  <View
                    // Needed since conditions are an array, so their index is their 'id'
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${workflow.id}/${k}/${a.length}`}
                    style={style.filterItemContainer}
                  >
                    <FilterItem
                      operator={workflow.condition.operator}
                      columns={schemaColumns}
                      setOperator={setOperator}
                      deleteItem={deleteItem}
                      setCondition={setItem}
                      condition={cond}
                      index={k}
                    />
                  </View>
                ))}
                <View style={style.row}>
                  <Button
                    variant="QUATERNARY"
                    id="workflow-add-condition"
                    accessibilityLabel={t("addACondition")}
                    text={t("addACondition")}
                    onPress={addCondition}
                    icon="plus"
                  />
                </View>
              </View>
            ) : (
              <Text weight="light" size="SUBTITLE" color="FONT_LOW_EMPHASIS">
                {t("workflow.fulfillsConditionEmptyStateLabel")}
              </Text>
            )}
          </WorkflowSection>

          <WorkflowAction workflow={workflow} onErrorStateChange={setError} />
        </View>
        <div className={styles.save}>
          <Button
            id="workflow-save-workflow"
            accessibilityLabel={t("saveEdits")}
            text={t("saveEdits")}
            onPress={onSave}
            width="100%"
            disabled={disableUpdateWorkflow}
          />
        </div>
      </ScrollView>
    </div>
  );
};

const style = StyleSheet.create({
  content: {
    padding: Spacing.S16,
  },
  sectionsWrapper: {
    rowGap: Spacing.S24,
  },
  section: {
    rowGap: Spacing.S12,
  },
  filterItemContainer: {
    flex: 1,
  },
  conditionSectionContent: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
  },
});
