import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentUserRole } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { getRoleTranslation } from "@kraaft/shared/core/modules/poolMember/poolMemberUtils";
import { Text } from "@kraaft/ui";

export const MyRole = () => {
  const poolId = useSelector(selectCurrentPoolId);
  const currentUserRole = useSelector(selectCurrentUserRole(poolId));
  const { t } = useTranslation();

  return (
    <Text color="FONT_LOW_EMPHASIS" size="BODY">
      {t("myProfile.loggedAs", {
        role: getRoleTranslation(t, currentUserRole),
      })}
    </Text>
  );
};
