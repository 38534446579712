import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useDispatch } from "react-redux";

import { ActionFooter } from "@kraaft/shared/components/actionFooter/actionFooter";
import { MapDisplayerHandle } from "@kraaft/shared/components/placeSelection/mapDisplayer/mapDisplayerProps";
import { MapDisplayerCenteredOnCurrentLocation } from "@kraaft/shared/components/placeSelection/mapDisplayerCenteredOnCurrentLocation";
import { PlaceSearchBar } from "@kraaft/shared/components/placeSelection/placeSearchBar";
import { RoomActions } from "@kraaft/shared/core/modules/room/roomActions";
import { GeoLocation } from "@kraaft/shared/core/types";
import { formatAddress } from "@kraaft/shared/core/utils";
import { Button, Color, Icon, Spacing, Text } from "@kraaft/ui";

import { styles } from "./roomLocation.layout.styles";

interface Props {
  roomId: string;
  onNext: () => void;
}

const RoomLocationLayout = (props: Props) => {
  const { roomId, onNext } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [location, setLocation] = useState<GeoLocation | undefined>(undefined);
  const mapRef = useRef<MapDisplayerHandle>(null);

  const handleSubmitLocation = useCallback(() => {
    if (location === undefined) {
      return;
    }

    dispatch(
      RoomActions.addCreatedRoomLocation({ roomId, geolocation: location }),
    );
    setTimeout(onNext, 0);
  }, [dispatch, location, onNext, roomId]);

  const changeLocation = useCallback((newLocation: GeoLocation | undefined) => {
    setLocation(newLocation);
    if (newLocation) {
      mapRef.current?.focusOnLocation(newLocation);
    }
  }, []);

  return (
    <View style={styles.screen}>
      <PlaceSearchBar
        setLocation={changeLocation}
        extraPadding={Spacing.S16}
        nativeID="create-room-search-bar-location"
      />
      <View style={styles.map}>
        <MapDisplayerCenteredOnCurrentLocation
          ref={mapRef}
          location={location}
          isEditing
          setLocation={setLocation}
        />
      </View>
      <View style={styles.addressContainer}>
        <Icon name="marker-pin-01" color="FONT_HIGH_EMPHASIS" />
        <Text style={styles.addressLabel} numberOfLines={2}>
          {formatAddress(location)}
        </Text>
      </View>
      <ActionFooter backgroundColor={Color.WHITE}>
        <Button
          text={t("createRoom.roomLocation.next")}
          variant="PRIMARY"
          onPress={handleSubmitLocation}
        />
        <Button
          testID="ide2e-roomLocation-ignore"
          text={t("createRoom.roomLocation.ignore")}
          variant="SECONDARY"
          onPress={onNext}
        />
      </ActionFooter>
    </View>
  );
};

export { RoomLocationLayout };
