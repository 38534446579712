import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  Action,
  ActionFromName,
  ActionName,
  availableActions,
  TemporaryWorkflow,
} from "@kraaft/shared/core/modules/workflows/types";
import { WorkflowStateActions } from "@kraaft/shared/core/modules/workflows/workflowActions";
import { getDefaultAction } from "@kraaft/shared/core/modules/workflows/workflowUtils";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { useHandleChangeSingleSelectHelper } from "@kraaft/web/src/components/dropdown/kDropdownUtils";
import { ActionEmail } from "@kraaft/web/src/views/settings/workflows/actions/ActionEmail";
import { ActionLogger } from "@kraaft/web/src/views/settings/workflows/actions/ActionLogger";
import { ActionMessageLogger } from "@kraaft/web/src/views/settings/workflows/actions/ActionMessageLogger";
import { ActionNotification } from "@kraaft/web/src/views/settings/workflows/actions/ActionNotification";
import { WorkflowSection } from "@kraaft/web/src/views/settings/workflows/components/workflowSection";

interface WorkflowActionsProps {
  workflow: TemporaryWorkflow;
  onErrorStateChange?: (newState: boolean) => void;
}

export const WorkflowAction = ({
  workflow,
  onErrorStateChange,
}: WorkflowActionsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSelectionChange = useHandleChangeSingleSelectHelper(
    (newActionName: ActionName | undefined) => {
      dispatch(
        WorkflowStateActions.updateEditing({
          actions: newActionName ? [getDefaultAction(newActionName)] : [],
        }),
      );
    },
    [dispatch],
  );

  const items = useMemo<FilteredListItem[]>(
    () =>
      availableActions.map((act) => ({
        label: t(act.translationKey) as string,
        value: act.name,
      })),
    [t],
  );

  const onChange = useCallback(
    <A extends ActionName>(name: ActionName) =>
      <P extends ActionFromName<A>["payload"]>(payload: P) => {
        const action = workflow.actions[0];

        if (!action || action.name !== name) {
          return;
        }

        dispatch(
          WorkflowStateActions.updateEditing({
            actions: [
              {
                name,
                payload,
              } as Action,
            ],
          }),
        );
      },
    [dispatch, workflow.actions],
  );

  const getActionDisplayer = useCallback(
    (action: Action) => {
      switch (action.name) {
        case "logger":
          return (
            <ActionLogger
              onChange={onChange("logger")}
              onErrorStateChange={onErrorStateChange}
              payload={action.payload}
              workflow={workflow}
            />
          );
        case "notification":
          return (
            <ActionNotification
              onChange={onChange("notification")}
              onErrorStateChange={onErrorStateChange}
              payload={action.payload}
              workflow={workflow}
            />
          );
        case "email":
          return (
            <ActionEmail
              onChange={onChange("email")}
              onErrorStateChange={onErrorStateChange}
              payload={action.payload}
              workflow={workflow}
            />
          );
        case "messageLogger":
          return (
            <ActionMessageLogger
              onChange={onChange("messageLogger")}
              onErrorStateChange={onErrorStateChange}
              payload={action.payload}
              workflow={workflow}
            />
          );
      }
    },
    [onChange, onErrorStateChange, workflow],
  );

  return (
    <>
      <WorkflowSection title={t("workflow.then")}>
        <KDropdown
          items={items}
          selectedItemIds={
            workflow.actions[0] ? [workflow.actions[0].name] : undefined
          }
          onSelectionChange={handleSelectionChange}
          withEmptyRow
          variant="dark"
          fullWidth
          placeholder={t("selectOneAction")}
        />
      </WorkflowSection>
      {workflow.actions.map((action, k, arr) => (
        // Their id here is their index in the array
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${workflow.id}/${arr.length}/${k}`}>
          {getActionDisplayer(action)}
        </div>
      ))}
    </>
  );
};
