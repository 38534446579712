import { ViewStyle } from "react-native";
import { useSelector } from "react-redux";

import { ModularFolderCard } from "@kraaft/shared/components/modularFolderCard";
import { ModularFolderLeftIcon } from "@kraaft/shared/components/modularFolderCard/modularFolderLeftIcon";
import { ModularFolderCardDescription } from "@kraaft/shared/components/modularFolderList/modularFolderItem/modularFolderCardDescription";
import {
  selectModularFolderIconAndColor,
  selectModularFolderProperties,
  selectModularFolderTitle,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";

interface SimpleFolderCardProps {
  schemaId: string;
  modularFolderId: string;
  onPress?: () => void;
  style?: ViewStyle;
}

const SimpleFolderCard = ({
  schemaId,
  modularFolderId,
  onPress,
  style,
}: SimpleFolderCardProps) => {
  const modularFolderTitle = useSelector(
    selectModularFolderTitle(modularFolderId),
  );

  const leftIcon = useSelector(
    selectModularFolderIconAndColor(modularFolderId),
  );

  const recordProperties = useSelector(
    selectModularFolderProperties(modularFolderId),
  );

  const description = (
    <ModularFolderCardDescription
      schemaId={schemaId}
      recordProperties={recordProperties ?? {}}
    />
  );

  return (
    <ModularFolderCard
      leftView={
        <ModularFolderLeftIcon
          accessibilityLabel=""
          name={leftIcon.icon}
          color={leftIcon.color}
        />
      }
      description={description}
      hideRightIcon
      title={modularFolderTitle}
      style={style}
      onClickCard={onPress}
    />
  );
};

export { SimpleFolderCard };
