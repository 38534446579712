import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";

import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { allStoresRehydrated } from "@kraaft/shared/core/modules/memory/memoryActions";
import { enableBatching } from "@kraaft/shared/core/store/batchingReducer";
import { sagaMiddleware } from "@kraaft/shared/core/store/sagaMiddleware";
import { GlobalOfflineReduxBundle } from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle.init";

import { platformReducers } from "../reducers/platformReducers";
import { platformSagas } from "../sagas/platformSagas";
import { store } from "./store.instance";

function initializeStore() {
  const _store = configureStore({
    reducer: enableBatching(platformReducers),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(sagaMiddleware),
    devTools:
      getEnvironment().DEPLOYMENT_ENVIRONMENT !== "prod"
        ? { maxAge: 100 }
        : false,
  });

  persistStore(_store, null, () => {
    _store.dispatch(allStoresRehydrated());
  });

  sagaMiddleware.run(platformSagas);
  GlobalOfflineReduxBundle.boot(_store, sagaMiddleware);

  return _store;
}

export function getStore() {
  if (!store.current) {
    store.current = initializeStore();
  }
  return store.current;
}

export type StoreType = ReturnType<typeof initializeStore>;
export type AppDispatch = StoreType["dispatch"];
