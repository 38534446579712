import { MentionMarker } from "@kraaft/shared/core/framework/markedText/markers/mention.marker";
import { i18n } from "@kraaft/shared/core/services/i18next";

export class EveryoneMentionMarker extends MentionMarker<"everyone-mention"> {
  get type() {
    return "everyone-mention" as const;
  }

  getLength() {
    return i18n.t("mentions.atEveryone").length + 1;
  }

  renderText() {
    return `@${i18n.t("mentions.atEveryone")}`;
  }
}
