import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { currencyClipboardTextFromCell } from "@kraaft/web/src/components/modularTable/components/table/clipboardTextFromCellUtils";
import { TextEditor } from "@kraaft/web/src/components/modularTable/components/table/editors/textEditor";
import { currencyRecordComparator } from "@kraaft/web/src/components/modularTable/components/table/recordComparatorUtils";
import { numberValueFromClipboardText } from "@kraaft/web/src/components/modularTable/components/table/valueFromClipboardTextUtils";
import { IFieldConfig } from "@kraaft/web/src/components/modularTable/components/types";

import { withBasicCell } from "../_withTableCell/withBasicCell";
import { CurrencyCell } from "./basicCell";

export const currencyConfig: IFieldConfig<KColumnType.currency> = {
  type: KColumnType.currency,

  TableCell: withBasicCell(CurrencyCell),
  TableEditor: TextEditor,
  comparator: currencyRecordComparator,
  getClipboardTextFromCell: currencyClipboardTextFromCell,
  getValueFromClipboardText: numberValueFromClipboardText,
};
