import { createReducer } from "@reduxjs/toolkit";
import { omit } from "lodash";

import { serializePollingDescriptor } from "@kraaft/shared/core/modules/polling/pollingHelper";
import { PollingUnit } from "@kraaft/shared/core/modules/polling/pollingState";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import * as actions from "./pollingActions";

const initialState: Record<string, PollingUnit> = {};

export const pollingReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => {
    return initialState;
  });

  addCase(
    actions.startPolling,
    (state, { payload: { subscriberId, ...payload } }) => {
      const key = serializePollingDescriptor(payload);
      return {
        ...state,
        [key]: {
          ...(state?.[key] ?? {}),
          endpoint: payload.endpoint,
          arguments: payload.arguments,
          subscribers: {
            ...(state?.[key]?.subscribers ?? {}),
            [subscriberId]: payload.delay,
          },
        },
      };
    },
  );

  addCase(actions.stopPolling, (state, { payload }) => {
    const key = serializePollingDescriptor(payload);
    return {
      ...state,
      [key]: {
        ...(state?.[key] ?? {}),
        endpoint: payload.endpoint,
        arguments: payload.arguments,
        subscribers: omit(
          state?.[key]?.subscribers ?? {},
          payload.subscriberId,
        ),
      },
    };
  });

  addCase(actions.updatePolling, (state, { payload }) => {
    const key = serializePollingDescriptor(payload);
    return {
      ...state,
      [key]: {
        subscribers: {},
        ...(state?.[key] ?? {}),
        endpoint: payload.endpoint,
        arguments: payload.arguments,
        lastData: payload.data,
      },
    };
  });
});
