import React, { useCallback, useImperativeHandle } from "react";

import type {
  FillDirectoryDroppableHandle,
  FillDirectoryDroppableProps,
} from "@kraaft/shared/components/directory/fillDirectoryDroppable";
import { useDirectoryDrop } from "@kraaft/shared/components/directory/useDirectoryDrop";
import { DIRECTORY_DROP_ACCEPT_TYPE } from "@kraaft/shared/components/directory/useDirectoryDrop/types";
import { Droppable } from "@kraaft/shared/components/droppable";
import { DroppableContainer } from "@kraaft/shared/components/droppable/droppableContainer";
import { useFileInput } from "@kraaft/web/src/core/utils/useFileInput";

import { inlineStyles } from "./fillDirectoryDroppable.styles";

const FillDirectoryDroppable = React.forwardRef<
  FillDirectoryDroppableHandle,
  FillDirectoryDroppableProps
>((props, ref) => {
  const { children, directory, onLoading } = props;

  const { onDrop, onDropFiles } = useDirectoryDrop({ directory, onLoading });

  const { getInputProps, open } = useFileInput({
    onAccepted: onDropFiles,
  });

  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Droppable
      key={directory.id}
      acceptSystemFile
      onDrop={onDrop}
      onDropFiles={onDropFiles}
      acceptType={DIRECTORY_DROP_ACCEPT_TYPE}
      shallow
    >
      {useCallback(
        ({ isActive }) => {
          return (
            <div style={inlineStyles.container}>
              <input {...getInputProps()} />
              {children}
              {isActive && (
                <DroppableContainer
                  style={inlineStyles.droppableContainer}
                  isActive
                />
              )}
            </div>
          );
        },
        [children, getInputProps],
      )}
    </Droppable>
  );
});

export { FillDirectoryDroppable };
