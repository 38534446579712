import { PillWrapperProps } from "@kraaft/shared/components/fileCard/pillWrapperProps";

import { useStyles } from "./pillWrapper.styles";

const PillWrapper = ({ children }: PillWrapperProps) => {
  const styles = useStyles();

  return <div className={styles.root}>{children}</div>;
};

export { PillWrapper };
