import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";

import { usePermissionStatusOf } from "@kraaft/shared/components/permissionRequester/usePermissionStatusOf";
import { permissions } from "@kraaft/shared/core/services/permission/permissions.provider";
import {
  PermissionName,
  PermissionResult,
} from "@kraaft/shared/core/services/permission/permissions.types";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Button, Text } from "@kraaft/ui";

interface PermissionRequesterFooterProps {
  type: PermissionName;
  onRequestPromise?: (promise: Promise<PermissionResult>) => void;
}

export const PermissionRequesterFooter = ({
  type,
  onRequestPromise,
}: PermissionRequesterFooterProps) => {
  const { t } = useTranslation();

  const status = usePermissionStatusOf(type);
  const [secondStatus, setSecondStatus] = useState<PermissionResult>();

  const trackPermissionRequested = useCallback(
    (resultStatus: PermissionResult) => {
      trackEvent({
        eventName: "Permission Explainer Click On Request",
        type,
        result: resultStatus,
      });
    },
    [type],
  );

  const handleRequestPermissionPress = useCallback(async () => {
    const requestPromise = permissions.request(type);

    if (onRequestPromise !== undefined) {
      onRequestPromise(
        requestPromise.then((result) => {
          trackPermissionRequested(result);
          setSecondStatus(result);
          return result;
        }),
      );
    } else {
      const result = await requestPromise;
      trackPermissionRequested(result);
      setSecondStatus(result);
    }
  }, [onRequestPromise, trackPermissionRequested, type]);

  const handleOpenSettingsPress = useCallback(async () => {
    trackEvent({
      eventName: "Permission Explainer Click On Open Settings",
      type,
    });
    await permissions.handleEnableBlockedPermission(type);
  }, [type]);

  const currentStatus = secondStatus ?? status;
  return currentStatus === "denied" ? (
    <Button
      text={t("permissions.grantAccess")}
      onPress={handleRequestPermissionPress}
    />
  ) : currentStatus === "blocked" ? (
    <Button
      text={t("permissions.openSettings")}
      onPress={handleOpenSettingsPress}
    />
  ) : (
    <Text style={styles.centeredText}>{t("permissions.deviceNotCapable")}</Text>
  );
};

const styles = StyleSheet.create({
  centeredText: {
    textAlign: "center",
  },
});
