import { useMemo } from "react";
import { View } from "react-native";

import { Button, ButtonProps } from "@kraaft/ui";

import { styles } from "./actionGroup.styles";

export type GroupAction = Omit<ButtonProps, "accessibilityLabel"> &
  Required<Pick<ButtonProps, "text">> & {
    variant?: "QUATERNARY";
  };

export type GroupActions = GroupAction[];

interface Props {
  actions: GroupActions;
  direction?: "vertical" | "horizontal" | "vertical-reversed";
}

const ActionGroup = (props: Props) => {
  const { actions, direction = "vertical" } = props;

  const containerStyle = useMemo(
    () => [
      direction === "vertical" && styles.buttonsContainerVertical,
      direction === "horizontal" && styles.buttonsContainerHorizontal,
      direction === "vertical-reversed" &&
        styles.buttonsContainerVerticalReversed,
    ],
    [direction],
  );

  return (
    <View style={containerStyle}>
      {actions.map((action, idx) => {
        return (
          <Button
            key={`${action.text}-${idx}`}
            accessibilityLabel={action.text}
            variant="QUATERNARY"
            size="SMALL"
            alignContent="left"
            {...action}
          />
        );
      })}
    </View>
  );
};

export { ActionGroup };
