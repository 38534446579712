import { nativeStylesObject } from "./message.styles.native";

export * from "./message.styles.native";

const styles = {
  ...nativeStylesObject,
  selectable: {
    cursor: "pointer",
  },
};

export { styles };
