import { useMemo } from "react";

import {
  MapMenu,
  MapMenuItem,
} from "@kraaft/shared/components/mapController/menu/mapMenu";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { GeoLocation } from "@kraaft/shared/core/types";

interface RoomsMapMenuProps {
  rooms: Room[];
  onPress: (id: string) => void;
}

export const RoomsMapMenu = ({ rooms, onPress }: RoomsMapMenuProps) => {
  const items = useMemo(() => {
    const roomsToDisplayWithCoords = rooms.filter(
      (room) =>
        getExistingRoomRecordField(room.record.properties, "geolocation")
          ?.coords?.latitude &&
        getExistingRoomRecordField(room.record.properties, "geolocation")
          ?.coords.longitude,
    );
    return roomsToDisplayWithCoords.map<MapMenuItem>((room) => ({
      id: room.id,
      icon: "message-circle-01",
      label: getExistingRoomRecordField(room.record.properties, "title") ?? "",
      // The check for this property is done l.23, so we can cast the result
      geolocation: getExistingRoomRecordField(
        room.record.properties,
        "geolocation",
      ) as GeoLocation,
    }));
  }, [rooms]);

  return <MapMenu items={items} onPress={onPress} />;
};
