import { StyleSheet } from "react-native";

import { Color, ColorStyle, FontSize, PixelSize, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  paddedContainer: {
    paddingHorizontal: Spacing.S16,
  },
  label: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.TITLE,
    paddingVertical: 5,
    marginLeft: 8,
    flexShrink: 1,
  },
  notif: {
    backgroundColor: Color.RED_TOMATO,
    fontSize: 13,
    color: Color.WHITE,
    lineHeight: 14,
    borderRadius: 8,
    overflow: "hidden",
    paddingVertical: 1,
    paddingHorizontal: 4,
    marginLeft: 4,
    textAlign: "center",
    minWidth: PixelSize.S16,
    height: PixelSize.S16,
  },
});
