import { put, take } from "typed-redux-saga/macro";

import {
  PoolActions,
  PoolStateActions,
  subscribeToPool,
  unsubscribeFromPool,
} from "@kraaft/shared/core/modules/pool/poolActions";

export function* switchPoolSaga({
  payload,
}: ReturnType<typeof PoolActions.switchPool>) {
  const { poolId } = payload;

  yield* put(PoolStateActions.setPoolLocation(payload));
  yield* put(subscribeToPool({ poolId }));
  yield* take(PoolActions.switchPool);
  yield* put(unsubscribeFromPool({ poolId }));
}
