import { useTranslation } from "react-i18next";

import { ModularFolderHistory } from "@kraaft/shared/components/modularFolders/modularFolderHistory";
import { DetailsRoute } from "@kraaft/web/src/components/detailsRoute";
import { detailsQuery } from "@kraaft/web/src/views/app/appRouter/routes";

export const ModularFolderHistoryRoute = ({
  modularFolderId,
  onBackButtonPress,
}: {
  modularFolderId: string | undefined;
  onBackButtonPress: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <DetailsRoute
      headerId="ide2e-modularFolder-history-header"
      contentId="ide2e-modularFolder-history-content"
      path={detailsQuery.modularFolderHistory}
      title={t("history.history")}
      onBackButtonPress={onBackButtonPress}
    >
      <ModularFolderHistory
        modularFolderId={modularFolderId}
        portalHostname="detailsDrawer"
      />
    </DetailsRoute>
  );
};
