import { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  selectCurrentPool,
  selectPools,
} from "@kraaft/shared/core/modules/pool/poolSelectors";
import { NavigationService } from "@kraaft/web/src/core/services/navigation/navigationService";

export function useNavigationInit(navigationService: NavigationService) {
  const currentPool = useSelector(selectCurrentPool);
  const pools = useSelector(selectPools);

  useEffect(() => {
    navigationService.setPoolsInformation({
      currentPoolId: currentPool?.id,
      pools: Object.values(pools),
    });
  }, [currentPool?.id, navigationService, pools]);
}
