export const userJobs = [
  "headOfCompany",
  "workSupervisor",
  "siteSupervisor",
] as const;

export type UserJob = (typeof userJobs)[number] | `other:${string}`;

export function isOtherJob(job: string): job is `other:${string}` {
  return job.startsWith("other:");
}
