import { useTranslation } from "react-i18next";

import { DetailedActionCard } from "@kraaft/shared/components/detailedActionCard";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { ColorStyle } from "@kraaft/ui";

import bookImage from "./assets/003-stack-of-books.png";

export const AddSchemaFromLibraryActionCard = ({
  onClose,
}: { onClose?: () => void }) => {
  const { t } = useTranslation();
  const navigationService = useNavigationService();

  const goToLibrary = () => {
    navigationService.navigate("SchemaLibraryView");
    onClose?.();
  };

  return (
    <DetailedActionCard
      title={t("schemasView.addDialog.fromLibrary.title")}
      subtitle={t("schemasView.addDialog.fromLibrary.subtitle")}
      titleColor={ColorStyle.ACTION_CTA}
      image={bookImage}
      onPress={goToLibrary}
    />
  );
};
