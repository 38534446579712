import { StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { styleObject } from "@kraaft/shared/styles/panel.styles";
import { Color, ColorStyle, FontSize, Radius, Spacing } from "@kraaft/ui";

const ROW_HEIGHT = 46;

export const styles = StyleSheet.create({
  PAContainer: {
    width: "100%",
    zIndex: 1, // needed on android
  },
  PATextInputContainer: {
    flex: 1,
  },
  PACustomTextInput: {
    width: "100%",
    height: 55,
  },
  PACustomTextInputContainer: {
    backgroundColor: Color.WHITE,
  },
  PATextInput: {
    flex: 1,
  },
  PAListView: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    width: "100%",
    paddingBottom: "100%",
    zIndex: 1,
    marginTop: 4,
  },
  PATableListView: {
    position: "absolute",
    top: "100%",
    backgroundColor: Color.WHITE,
    width: "100%",

    marginTop: 8,
    borderRadius: Radius.SMALL,
    overflow: "hidden",

    ...(isNative()
      ? {
          shadowColor: Color.BLACK,
          shadowOffset: { width: 0, height: 5 },
          shadowOpacity: 0.1,
          shadowRadius: 10,
        }
      : {
          boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.10)",
        }),
  },
  PARow: {
    paddingHorizontal: Spacing.S16,
    paddingVertical: 12,
    height: ROW_HEIGHT,
  },
  PASeparator: {
    backgroundColor: ColorStyle.SEPARATOR,
    height: StyleSheet.hairlineWidth,
    marginLeft: Spacing.S16,
  },
  description: {
    fontFamily: "Apercu",
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  poweredContainer: {
    ...styleObject.borderTop,
    padding: Spacing.S16,
    marginLeft: Spacing.S16,
    left: -8,
  },
});
