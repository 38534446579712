import React from "react";
import { RouteProps } from "react-router-dom";

import { PortalHost } from "@kraaft/ui";
import { VerticalNavigation } from "@kraaft/web/src/components/layout/verticalNavigationBar/verticalNavigation";
import { PreviewPopupRenderer } from "@kraaft/web/src/components/previewPopup";
import { LAYOUT_CONTENT_PORTAL_HOSTNAME } from "@kraaft/web/src/core/constants";

import { useStyles } from "./layout.styles";

interface Props {
  layoutRoutes: RouteProps[];
  children: React.ReactNode;
}

const Layout_ = (props: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.layoutContainer}>
        <div className={classes.innerContainer}>
          <VerticalNavigation />
          <div className={classes.pageContainer}>
            {props.children}
            <PortalHost hostname={LAYOUT_CONTENT_PORTAL_HOSTNAME} />
          </div>
        </div>
      </div>
      <PreviewPopupRenderer />
    </>
  );
};

export const Layout = React.memo(Layout_);
