import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useSelector } from "react-redux";

import { useMeshContext } from "@kraaft/helper-hooks";
import { ConversationTitle } from "@kraaft/shared/components/conversation/header/conversationTitle";
import { SearchConversationContext } from "@kraaft/shared/components/conversation/searchConversationContext";
import { ConversationDetailButton } from "@kraaft/shared/components/conversationDetailButton";
import { DefaultLayoutHeader } from "@kraaft/shared/components/defaultLayoutHeader";
import { HeaderSide } from "@kraaft/shared/components/defaultLayoutHeader/headerSide";
import { TrackedHeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { MembersIcon } from "@kraaft/shared/components/membersIcon";
import { selectMessageSelectionCount } from "@kraaft/shared/core/modules/message/messageSelectors";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import {
  selectRoom,
  selectRoomMembersCount,
} from "@kraaft/shared/core/modules/room/selectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useMapNavigation } from "@kraaft/shared/core/utils/useMapNavigation";
import { Text } from "@kraaft/ui";

import { styles } from "./conversationHeader.styles";

interface Props {
  roomId: string;
  drawerOpen: boolean;
  onDetailsClick: () => void;
}

const ConversationHeader = ({ roomId, onDetailsClick, drawerOpen }: Props) => {
  const { t } = useTranslation();
  const { setSearching } = useMeshContext(SearchConversationContext);
  const navigationService = useNavigationService();
  const { isMapFullscreen } = useMapNavigation();

  const room = useSelector(selectRoom(roomId));
  const membersCount = useSelector(selectRoomMembersCount(roomId));

  const selectionCount = useSelector(
    selectMessageSelectionCount(roomId, "conversation"),
  );
  const hasSelection = selectionCount > 0;

  const goToDetails = useCallback(() => {
    navigationService.navigate("ConversationFolder", { roomId });
  }, [navigationService, roomId]);

  const handleMembersPress = useCallback(() => {
    trackEvent({
      eventName: "View Room Members",
      room_id: roomId,
      room_name: getExistingRoomRecordField(
        room?.record.properties,
        "title",
        "",
      ),
      source: "room_header",
    });

    navigationService.navigate("Members", { roomId });
  }, [navigationService, roomId, room]);

  const handleSearchPress = useCallback(() => {
    setSearching(true);
  }, [setSearching]);

  if (isMapFullscreen) {
    return null;
  }

  if (hasSelection) {
    return (
      <View style={styles.headerMessageSelected}>
        <Text style={styles.headerMessageSelectedTitle}>
          {t("messageSelected", { count: selectionCount })}
        </Text>
      </View>
    );
  }

  return (
    <DefaultLayoutHeader
      headerOverflow
      header={
        <View style={styles.headerTitle}>
          <ConversationTitle roomId={roomId} onPress={goToDetails} />
        </View>
      }
      headerRight={
        <>
          <HeaderSide>
            <TrackedHeaderIconButton
              getTrackingEvent={() => ({
                eventName: "Click on Search",
                room_id: roomId,
                from: "conversation",
              })}
              accessibilityLabel="search"
              icon="search-md"
              onPress={handleSearchPress}
            />
            <MembersIcon count={membersCount} onPress={handleMembersPress} />
            <ConversationDetailButton
              onPress={onDetailsClick}
              accessibilityLabel={drawerOpen ? t("hide") : t("show")}
              side={drawerOpen ? "right" : "left"}
            />
          </HeaderSide>
        </>
      }
    />
  );
};

export { ConversationHeader };
