import { executeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditions";
import {
  SchemaLockLookup,
  SECTION_LOCK_INFO_FALLBACK,
  SectionLockInfo,
} from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchema,
  KSchemaColumnLiteralValue,
  KSchemaColumnValue,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  ExistingColumnValue,
  ModularRecord,
  ModularRecordProperties,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { uuid } from "@kraaft/shared/core/utils";

export class ModularRecordUtils {
  static create(schema: KSchema): ModularRecord {
    return {
      id: uuid(),
      properties: {},
      schemaId: schema.id,
    };
  }

  static findColumn(
    recordProperties: ModularRecordProperties,
    key: string,
  ): KSchemaColumnValue | undefined;
  static findColumn<C extends KColumnType>(
    recordProperties: ModularRecordProperties,
    key: string,
    ...columnType: C[]
  ): KSchemaColumnValue<C> | undefined;
  static findColumn(
    recordProperties: ModularRecordProperties,
    key: string,
    ...columnType: KColumnType[]
  ): KSchemaColumnValue | undefined {
    const column = recordProperties[key];

    if (!column) {
      return undefined;
    }
    if (columnType.includes(column.columnType)) {
      return column;
    }
    return undefined;
  }

  static getSectionLockInfo(
    rootSection: KSchemaSection,
    recordProperties: ModularRecordProperties,
    key: string,
    currentUserRole?: UserPoolRole,
  ): SectionLockInfo {
    const schemaLockLookup = KSchemaUtils.computeSchemaLockLookup(
      rootSection,
      currentUserRole,
    );

    return ModularRecordUtils.getSectionLockInfoWithSchemaLockLookup(
      schemaLockLookup,
      recordProperties,
      key,
    );
  }

  static getSectionLockInfoWithSchemaLockLookup(
    schemaLockLookup: SchemaLockLookup,
    recordProperties: ModularRecordProperties,
    elementKey: string,
  ) {
    const sectionLockLookup =
      schemaLockLookup.sections[elementKey] ??
      schemaLockLookup.sections[
        schemaLockLookup.elementSectionKey[elementKey] ?? ""
      ];

    if (!sectionLockLookup) {
      return SECTION_LOCK_INFO_FALLBACK;
    }

    const sectionLockInfo: SectionLockInfo = {
      ...sectionLockLookup,
      isLocked: false,
      lockedAt: undefined,
      lockedBy: undefined,
      highestLockedSectionKey: undefined,
      recordTitle: ModularRecordUtils.getPropertyField(
        recordProperties,
        KSchemaRemarkableColumns.TITLE,
        [KColumnType.shortText, KColumnType.automatedAutoIncrement],
        "",
      ),
      isCurrentUserAuthorizedToUnlock:
        schemaLockLookup.isCurrentUserAuthorizedToUnlock,
    };

    for (const lockingColumn of sectionLockLookup.lockingColumns) {
      if (recordProperties[lockingColumn.columnKey]?.value === true) {
        sectionLockInfo.isLocked = true;
        sectionLockInfo.lockedAt =
          recordProperties[lockingColumn.columnKey]?.updatedAt;
        sectionLockInfo.lockedBy =
          recordProperties[lockingColumn.columnKey]?.updatedBy;
        sectionLockInfo.highestLockedSectionKey =
          sectionLockInfo.highestLockedSectionKey ??
          lockingColumn.parentSection.key;
      }
    }

    return sectionLockInfo;
  }

  static getValidAttachmentColumn(
    recordProperties: ModularRecordProperties,
    schema: KSchema,
  ) {
    for (const schemaColumn of KSchemaUtils.iterateColumns(
      schema.rootSection,
    )) {
      if (schemaColumn.type === KColumnType.attachment) {
        const isLocked = ModularRecordUtils.getSectionLockInfo(
          schema.rootSection,
          recordProperties,
          schema.rootSection.key,
        ).isLocked;

        if (isLocked) {
          continue;
        }

        const isShown =
          schemaColumn.condition === undefined
            ? true
            : executeCondition(
                schema.rootSection,
                { room: undefined },
                {} as any,
                recordProperties,
                schemaColumn.condition,
                "folder",
              );

        if (!isShown) {
          continue;
        }

        return schemaColumn;
      }
    }

    return undefined;
  }

  static fillAutomatedUserValues(
    schema: KSchema,
    properties: ModularRecordProperties,
    currentUserId: string,
  ) {
    for (const column of KSchemaUtils.orderedColumns(schema.rootSection)) {
      if (column.key in properties) {
        return;
      }
      if (column.type === KColumnType.automatedCreatedBy) {
        properties[column.key] = {
          columnType: column.type,
          value: [currentUserId],
        };
      }
    }
    return properties;
  }

  static fillAutomatedValues(
    schema: KSchema,
    properties: ModularRecordProperties,
    currentUserId: string,
  ) {
    for (const column of KSchemaUtils.orderedColumns(schema.rootSection)) {
      if (column.key in properties) {
        return;
      }
      if (column.type === KColumnType.automatedCreatedAt) {
        properties[column.key] = {
          columnType: column.type,
          value: new Date(),
        };
      } else if (column.type === KColumnType.automatedCreatedBy) {
        properties[column.key] = {
          columnType: column.type,
          value: [currentUserId],
        };
      } else if (column.type === KColumnType.automatedAutoIncrement) {
        properties[column.key] = {
          columnType: column.type,
          value: `${column.prefix}594`,
        };
      }
    }
    return properties;
  }

  public static getPropertyField<
    T extends KColumnType,
    F extends keyof ModularRecord["properties"],
  >(
    properties: ModularRecord["properties"],
    field: F,
    type: T | T[],
  ): KSchemaColumnLiteralValue<T> | undefined;
  public static getPropertyField<
    T extends KColumnType,
    F extends keyof ModularRecord["properties"],
  >(
    properties: ModularRecord["properties"],
    field: F,
    type: T | T[],
    defaultValue: KSchemaColumnLiteralValue<T>,
  ): ExistingColumnValue<KSchemaColumnValue<T>>["value"];
  public static getPropertyField<
    T extends KColumnType,
    F extends keyof ModularRecord["properties"],
  >(
    properties: ModularRecord["properties"],
    field: F,
    type: T | T[],
    defaultValue?: KSchemaColumnLiteralValue<T>,
  ): KSchemaColumnLiteralValue<T> | undefined {
    const property = properties[field];
    if (
      property?.columnType &&
      (Array.isArray(type)
        ? type.includes(property.columnType as T)
        : property.columnType === type)
    ) {
      return (property.value ?? defaultValue) as
        | KSchemaColumnLiteralValue<T>
        | undefined;
    }
    return defaultValue;
  }

  public static getRecordField<
    T extends KColumnType,
    F extends keyof ModularRecord["properties"],
  >(
    record: ModularRecord | undefined,
    field: F,
    type: T | T[],
  ): KSchemaColumnLiteralValue<T> | undefined;
  public static getRecordField<
    T extends KColumnType,
    F extends keyof ModularRecord["properties"],
  >(
    record: ModularRecord | undefined,
    field: F,
    type: T | T[],
    defaultValue: KSchemaColumnLiteralValue<T>,
  ): ExistingColumnValue<KSchemaColumnValue<T>>["value"];
  public static getRecordField<
    T extends KColumnType,
    F extends keyof ModularRecord["properties"],
  >(
    record: ModularRecord,
    field: F,
    type: T | T[],
    defaultValue: KSchemaColumnLiteralValue<T>,
  ): ExistingColumnValue<KSchemaColumnValue<T>>["value"];
  public static getRecordField<
    T extends KColumnType,
    F extends keyof ModularRecord["properties"],
  >(
    record: ModularRecord | undefined,
    field: F,
    type: T | T[],
    defaultValue?: KSchemaColumnLiteralValue<T>,
  ): KSchemaColumnLiteralValue<T> | undefined {
    if (!record) {
      return defaultValue;
    }
    return ModularRecordUtils.getPropertyField(
      record.properties,
      field,
      type,
      defaultValue as any,
    ) as any;
  }

  static hasAtLeastOneLockedColumn(
    recordProperties: ModularRecordProperties,
    lockingColumnsOrSchema?: string[] | (KSchema | undefined),
  ) {
    let lockingColumns: string[] = [];
    if (lockingColumnsOrSchema?.constructor === Array) {
      lockingColumns = lockingColumnsOrSchema;
    } else if (lockingColumnsOrSchema) {
      lockingColumns = KSchemaUtils.getLockingColumnsForSchema(
        lockingColumnsOrSchema as KSchema,
      );
    }

    for (const columnKey of lockingColumns) {
      if (recordProperties[columnKey]?.value === true) {
        return true;
      }
    }
    return false;
  }
}
