import { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";

const ScrollViewStickyElement = ({ children }: PropsWithChildren) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

const useStyles = makeStyles({
  container: {
    position: "sticky",
    top: -1 /** needed to visually clip it to the top of the screen */,
    zIndex: 1,
  },
});

export { ScrollViewStickyElement };
