import { EventChannel, eventChannel } from "redux-saga";
import { put, take, takeEvery } from "typed-redux-saga/macro";

import {
  MapOverlayActions,
  MapOverlayStateActions,
} from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.actions";
import { MapOverlayDelaySnapshot } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.optimistic";
import { MapOverlay } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.state";
import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToMapOverlaysSaga() {
  yield takeCountedDeep(
    MapOverlayActions.subscribe,
    MapOverlayActions.unsubscribe,
    subscribeToMapOverlays,
    unsubscribeFromMapOverlays,
    (action) => action.payload.poolId,
  );

  let currentPoolId: string | undefined;
  while (true) {
    const action = yield* take([
      PoolStateActions.setPoolLocation,
      UserActions.userDisconnectedFromFirebase,
    ]);

    function* refreshSubscription(poolId: string) {
      if (currentPoolId) {
        yield* put(MapOverlayActions.unsubscribe({ poolId: currentPoolId }));
      }
      currentPoolId = poolId;

      yield* put(MapOverlayActions.subscribe({ poolId }));
    }

    if (PoolStateActions.setPoolLocation.match(action)) {
      yield* refreshSubscription(action.payload.poolId);
    } else if (currentPoolId) {
      yield* put(MapOverlayActions.unsubscribe({ poolId: currentPoolId }));
    }
  }
}

type Meta = EventChannel<{ data: MapOverlay[] }> | undefined;

function createChannel(poolId: string) {
  return eventChannel<{ data: MapOverlay[] }>((emit) => {
    return Firestore.subscribeToMapOverlays(poolId, (data) => emit({ data }));
  });
}

function* receiveMapOverlays(mapOverlays: { data: MapOverlay[] }) {
  yield* MapOverlayDelaySnapshot(MapOverlayStateActions.set, mapOverlays);
}

function* subscribeToMapOverlays(
  registerMeta: (meta: Meta) => void,
  action: ReturnType<typeof MapOverlayActions.subscribe>,
) {
  const channel = createChannel(action.payload.poolId);
  registerMeta(channel);
  yield* takeEvery(channel, receiveMapOverlays);
}

function* unsubscribeFromMapOverlays(meta: Meta) {
  meta?.close();
}
