import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectInviteState } from "@kraaft/shared/core/modules/app/appSelector";

export const useSigninTitleForInvitation = () => {
  const { t } = useTranslation();

  const inviteState = useSelector(selectInviteState);
  if (inviteState.status !== "valid") {
    return;
  }

  return inviteState.type === "pool"
    ? t("signIn.aboutToJoinPool", {
        poolName: inviteState.poolName,
      })
    : t("signIn.aboutToJoinRoom", {
        roomName: inviteState.roomName,
      });
};
