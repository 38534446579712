import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { SchemaTemplate } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";
import { useRealtimeSchemaTemplates } from "@kraaft/shared/core/modules/schemaTemplate/useSchemaTemplates";
import { compareStrings } from "@kraaft/shared/core/utils";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button, ColorStyle, FontSize, PixelSize, Spacing } from "@kraaft/ui";
import { CreateNewChecklistTemplateDialog } from "@kraaft/web/src/components/importChecklistTemplateDialog/CreateNewChecklistTemplateDialog";
import { KTable, KTableConfig } from "@kraaft/web/src/components/kTable";
import { ensureColumnType } from "@kraaft/web/src/components/kTable/kTableUtils";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { PageHeaderAction } from "@kraaft/web/src/components/pageHeader/pageHeader.types";

import { OptionButton } from "./optionButton";

import { useSettingsStyles } from "../settings.styles";

const ManageTaskTemplates = () => {
  const schemaTemplates = useRealtimeSchemaTemplates();
  const settingsClasses = useSettingsStyles();
  const { t } = useTranslation();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  useTrackPage("SettingsTaskTemplate");

  const taskListActions: Array<PageHeaderAction> = useMemo(
    () => [
      {
        id: "settings-task-templates-create",
        text: t("createNewTemplateList"),
        onPress: () => setCreateDialogOpen(true),
        variant: "PRIMARY",
      },
    ],
    [t],
  );

  const renderTasksListActions = useCallback(
    () =>
      taskListActions.map(({ text, ...buttonProps }) => (
        <Button accessibilityLabel={text} text={text} {...buttonProps} />
      )),
    [taskListActions],
  );

  const config: KTableConfig<SchemaTemplate> = {
    columns: [
      ensureColumnType({
        id: "name",
        label: t("name"),
        value: (item: SchemaTemplate): string => item.name,
        compare: compareStrings,
        width: "100%",
        style: {
          column: { fontWeight: "bold" },
        },
      }),
      ensureColumnType({
        id: "actions",
        label: "",
        value: (item: SchemaTemplate): SchemaTemplate => item,
        component: (schema, context) => {
          return <OptionButton schema={schema} context={context} />;
        },
        style: {
          header: { padding: 0 },
          column: { padding: 0 },
        },
      }),
    ],
    enableHover: true,
    style: {
      columns: {
        color: ColorStyle.FONT_HIGH_EMPHASIS,
        fontSize: FontSize.MEDIUM,
        paddingLeft: Spacing.S16,
      },
      rows: {
        height: PixelSize.S40,
      },
    },
  };

  const closeCreateDialog = useCallback(() => {
    setCreateDialogOpen(false);
  }, []);

  return (
    <div className={settingsClasses.pageContainer}>
      <PageHeader
        title={t("settingsTasksLists")}
        right={renderTasksListActions()}
        titleElementId="settings-task-templates-title"
      />
      <CreateNewChecklistTemplateDialog
        open={createDialogOpen}
        onClose={closeCreateDialog}
        onCreate={closeCreateDialog}
      />
      <KTable
        data={schemaTemplates}
        extractKey={(schemaTemplate: SchemaTemplate) => schemaTemplate.id}
        config={config}
      />
    </div>
  );
};

export { ManageTaskTemplates };
