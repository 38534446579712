import { useCallback, useContext, useEffect, useRef } from "react";
import { TextareaAutosize } from "@mui/material";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { useTableInputBehavior } from "@kraaft/web/src/components/modularTable/components/table/hooks/useTableInputBehavior";
import { ModularTableContext } from "@kraaft/web/src/components/modularTable/components/table/modularTableContext";
import { KEditorProps } from "@kraaft/web/src/components/modularTable/components/types";

import { useStyles } from "./multiLineTextEditor.styles";

export const MultiLineTextEditor = (
  props: KEditorProps<KColumnType.longText>,
) => {
  const classes = useStyles();
  const { onEnterChangeCell } = useContext(ModularTableContext);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { column, row, onRowChange, onClose } = props;
  const defaultValue = row.properties[column.key]?.value;

  useEffect(() => {
    if (inputRef.current) {
      const selectionPosition = defaultValue?.length ?? 0;

      inputRef.current.selectionStart = selectionPosition;
      inputRef.current.selectionEnd = selectionPosition;
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleArrival = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }, []);

  const { onKeyDownDetectArrival, onKeyPressDetectArrival } =
    useTableInputBehavior({
      onInputArrival: handleArrival,
    });

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    onRowChange({
      ...row,
      properties: {
        ...row.properties,
        [column.key]: {
          columnType: column.type,
          value: event.target.value,
        } as KSchemaColumnValue,
      },
    });
  };

  const onBlur = () => {
    onClose(true);
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      onKeyDownDetectArrival(event);
      if (event.shiftKey && event.key === "Enter") {
        onEnterChangeCell();
      }
      if (
        event.key === "ArrowLeft" ||
        event.key === "ArrowRight" ||
        event.key === "Enter"
      ) {
        event.stopPropagation();
      }

      if (event.key === "Escape" && defaultValue) {
        const target = event.target as HTMLTextAreaElement;
        target.value = defaultValue;
      }
    },
    [defaultValue, onEnterChangeCell, onKeyDownDetectArrival],
  );

  return (
    <div className={classes.container}>
      <div className={classes.textAreaContainer}>
        <TextareaAutosize
          ref={inputRef}
          defaultValue={defaultValue}
          className={classes.textArea}
          onChange={onChange}
          onBlur={onBlur}
          maxRows={6}
          autoFocus
          onKeyDown={handleKeyDown}
          onKeyPress={onKeyPressDetectArrival}
        />
      </div>
    </div>
  );
};
