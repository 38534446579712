import { selectMessageInRoom } from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import { addAttachmentOperation } from "@kraaft/shared/core/modules/modularFolder/operations/addAttachment.operation";
import { addAttachmentFromMessageOperation } from "@kraaft/shared/core/modules/modularFolder/operations/addAttachmentFromMessage.operation";
import { addSignatureOperation } from "@kraaft/shared/core/modules/modularFolder/operations/addSignature.operation";
import { autonumberTitlesOperation } from "@kraaft/shared/core/modules/modularFolder/operations/autonumberTitles.operation";
import { createOperation } from "@kraaft/shared/core/modules/modularFolder/operations/create.operation";
import { deleteOperation } from "@kraaft/shared/core/modules/modularFolder/operations/delete.operation";
import { deleteAttachmentOperation } from "@kraaft/shared/core/modules/modularFolder/operations/deleteAttachment.operation";
import { duplicateOperation } from "@kraaft/shared/core/modules/modularFolder/operations/duplicate.operation";
import { editOperation } from "@kraaft/shared/core/modules/modularFolder/operations/edit.operation";
import { reorderOperation } from "@kraaft/shared/core/modules/modularFolder/operations/reorder.operation";
import { updateAttachmentGeolocationOperation } from "@kraaft/shared/core/modules/modularFolder/operations/updateAttachmentGeolocation.operation";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { selectSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import {
  ReduxOfflineFeature,
  taskManager,
} from "@kraaft/shared/core/store/offline";
import { DeclareOfflineFeature } from "@kraaft/shared/core/utils/optimistic/newOptimistic/createOfflineFeature";

export const ModularFolderOfflineFeature = DeclareOfflineFeature<ModularFolder>(
  "ModularFolder",
)(
  {
    create: createOperation,
    edit: editOperation,
    autonumberTitles: autonumberTitlesOperation,
    delete: deleteOperation,
    deleteAttachment: deleteAttachmentOperation,
    duplicate: duplicateOperation,
    updateAttachmentGeolocation: updateAttachmentGeolocationOperation,
    addAttachment: addAttachmentOperation,
    addAttachmentFromMessage: addAttachmentFromMessageOperation,
    addSignature: addSignatureOperation,
    reorder: reorderOperation,
  },
  taskManager.createTaskManager("ModularFolder"),
);

export const {
  Actions: OfflineModularFolderActions,
  Selectors: OfflineModularFolderSelectors,
  StateActions: OfflineModularFolderStateActions,
} = ReduxOfflineFeature.Create(
  ModularFolderOfflineFeature,
  {
    selectRawAggregate: (state: any) => state.modularFolders.modularFolders,
  },
  (getState, selectModularFolder) => {
    return {
      getCurrentUserId() {
        return selectCurrentUserId(getState()) ?? "";
      },
      getSchema(id) {
        return selectSchema(id)(getState());
      },
      getModularFolder(id) {
        return selectModularFolder(id)(getState());
      },
      getMessage(roomId, id) {
        return selectMessageInRoom(roomId, id)(getState());
      },
    };
  },
);
