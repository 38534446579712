import { useMemo } from "react";
import compact from "lodash/compact";
import mapValues from "lodash/mapValues";
import partition from "lodash/partition";

import { AvatarContent } from "@kraaft/shared/components/userSelection/avatarList/avatarList.types";
import { getUserPoolRole } from "@kraaft/shared/core/modules/pool/poolUtil";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { SelectableUser } from "@kraaft/shared/core/modules/user/userTypes";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";

export function userSortForAvatarList(
  frozenUserIds: string[],
  itemsLength: number,
) {
  return [
    (user: AvatarContent) => {
      const index = frozenUserIds.indexOf(user.id);
      return index >= 0 ? index : itemsLength;
    },
    (user: AvatarContent) => user.id,
  ];
}

export function userSortByCurrentAndAlphabetical(
  currentUserId: string | undefined,
) {
  return (user1: SelectableUser, user2: SelectableUser) => {
    if (user1.id === currentUserId) {
      return -1;
    }
    if (user2.id === currentUserId) {
      return 1;
    }
    const username1 = normalizeTextForSearch(user1.username);
    const username2 = normalizeTextForSearch(user2.username);
    if (username1 > username2) {
      return 1;
    }
    if (username1 < username2) {
      return -1;
    }
    return 0;
  };
}

export function useSelectableUsers({
  selectedUserIds,
  availableUsers,
  canViewAllUsers,
  currentUserId,
  poolId,
}: {
  selectedUserIds: string[];
  availableUsers: Record<string, User>;
  canViewAllUsers: boolean;
  currentUserId: string | undefined;
  poolId: string;
}) {
  return useMemo(() => {
    const [displayedUserIds, hiddenUserIds] = partition(selectedUserIds, (id) =>
      Boolean(availableUsers[id]),
    );

    const selectableUsers = mapValues(
      availableUsers,
      (user, id): SelectableUser => ({
        id,
        isExternalInCurrentPool:
          getUserPoolRole(user, poolId) === UserPoolRole.EXTERNAL,
        ...user,
      }),
    );

    const preselectedUsers: SelectableUser[] = compact(
      displayedUserIds.map((id) => {
        return selectableUsers[id];
      }),
    );

    const users = canViewAllUsers
      ? Object.values(selectableUsers)
      : [...preselectedUsers];
    users.sort(userSortByCurrentAndAlphabetical(currentUserId));

    return { preselectedUsers, users, hiddenUserIds };
  }, [selectedUserIds, availableUsers, canViewAllUsers, currentUserId, poolId]);
}
