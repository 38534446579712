import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { SchemaActions } from "@kraaft/shared/core/modules/schema/schema.actions";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { navigationService } from "@kraaft/shared/core/services/navigation/provider";

export function* createSchemaSaga() {
  yield* takeEvery(SchemaActions.createSchema, createSchema);
}

function* createSchema(
  action: ReturnType<(typeof SchemaActions)["createSchema"]>,
) {
  const poolId = yield* select(selectCurrentPoolId);

  if (!poolId) {
    return;
  }

  try {
    const id = yield* call(Api.addSchema, {
      poolId,
      name: action.payload.name,
      icon: action.payload.icon,
    });
    yield* call(() => {
      navigationService.navigate("FolderSchemaBuilder", {
        schemaId: id,
      });
    });
  } catch (e) {
    console.error(e);
    yield* put(showError({ title: i18n.t("serverError") }));
  }
}
