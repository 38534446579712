/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Task } from "redux-saga";
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  take,
  takeEvery,
} from "typed-redux-saga/macro";

import {
  OptimisticWorkspace,
  WAggregation,
  WOperation,
  WType,
} from "@kraaft/shared/core/utils/optimistic/types";
import { wait } from "@kraaft/shared/core/utils/promiseUtils";

export class WorkspaceAPIHandling<W extends OptimisticWorkspace<any>, C> {
  private delayedStore: Partial<Record<WType<W>, Record<string, Task>>> = {};

  constructor(
    public readonly workspace: W,
    private readonly linkAction: ActionCreatorWithPayload<{
      optimisticId: string;
      date: Date;
    }>,
    private readonly removeOptimistic: ActionCreatorWithPayload<{
      optimisticId: string;
    }>,
    private readonly context: C,
    private readonly handlers: {
      [type in WType<W>]:
        | ((
            payload: Extract<WOperation<W>, { type: type }>,
            context: C,
          ) => Generator<any, Date | null>)
        | {
            type: "latest";
            debounce: number;
            identify: (
              operation: Extract<WOperation<W>, { type: type }>,
            ) => string;
            callDelayed: (
              payload: Extract<WOperation<W>, { type: type }>,
              context: C,
            ) => Generator<any, Date | null>;
          };
    },
  ) {}

  private *handleInstant(
    handler: (
      payload: Extract<
        WOperation<W>,
        {
          type: WType<W>;
        }
      >,
      context: C,
    ) => Generator<any, Date | null, unknown>,
    operation: WOperation<W>,
  ) {
    try {
      this.workspace.log("Saga", `API call for ${operation.type}`);
      const updatedAt = yield* call(
        handler,
        operation as Parameters<typeof handler>["0"],
        this.context,
      );
      if (updatedAt === null || !(updatedAt instanceof Date)) {
        this.workspace.log(
          "Saga",
          `API Call for ${operation.type} returned null`,
        );
        yield* put(
          this.removeOptimistic({ optimisticId: operation.optimisticId }),
        );
        return;
      }
      yield* put(
        this.linkAction({
          optimisticId: operation.optimisticId,
          date: updatedAt,
        }),
      );
    } catch (e) {
      this.workspace.log("Saga", `API Call for ${operation.type} crashed`, e);
      yield* put(
        this.removeOptimistic({ optimisticId: operation.optimisticId }),
      );
    }
  }

  private getDelayedStore(type: WType<W>, id: string) {
    let typeDelayStore = this.delayedStore[type];
    if (!typeDelayStore) {
      typeDelayStore = {};
      this.delayedStore[type] = typeDelayStore;
    }
    return typeDelayStore[id];
  }

  private setDelayedStore(type: WType<W>, id: string, task: Task) {
    let typeDelayStore = this.delayedStore[type];
    if (!typeDelayStore) {
      typeDelayStore = {};
      this.delayedStore[type] = typeDelayStore;
    }
    typeDelayStore[id] = task;
  }

  private *handleDelayed(
    handler: {
      type: "latest";
      debounce: number;
      identify: (
        operation: Extract<WOperation<W>, { type: WType<W> }>,
      ) => string;
      callDelayed: (
        payload: Extract<
          WOperation<W>,
          {
            type: WType<W>;
          }
        >,
        context: C,
      ) => Generator<any, Date | null, unknown>;
    },
    operation: WOperation<W>,
  ) {
    // eslint-disable-next-line consistent-this, @typescript-eslint/no-this-alias
    const thiss = this;
    function* handleThis() {
      try {
        yield wait(handler.debounce);
        yield* thiss.handleInstant(handler.callDelayed, operation);
      } finally {
        if (yield* cancelled()) {
          yield* put(
            thiss.removeOptimistic({ optimisticId: operation.optimisticId }),
          );
        }
      }
    }
    const id = handler.identify(
      operation as Extract<WOperation<W>, { type: WType<W> }>,
    );
    const existingTask = this.getDelayedStore(operation.type as WType<W>, id);
    if (existingTask) {
      yield* cancel(existingTask);
    }
    this.setDelayedStore(
      operation.type as WType<W>,
      id,
      yield* fork(handleThis),
    );
  }

  *handle(operation: WOperation<W>) {
    const handler = this.handlers[operation.type as WType<W>];
    if ("type" in handler) {
      return yield* this.handleDelayed(handler, operation);
    }
    return yield* this.handleInstant(handler, operation);
  }
}

export function createOptimisticSaga<W extends OptimisticWorkspace<any>, S>(
  addOperation: ActionCreatorWithPayload<WOperation<W>>,
  linkOperation: ActionCreatorWithPayload<{ optimisticId: string; date: Date }>,
  removeOperation: ActionCreatorWithPayload<{ optimisticId: string }>,
  handling: WorkspaceAPIHandling<W, S>,
) {
  const saga = function* () {
    yield* takeEvery(addOperation, function* (action) {
      yield* handling.handle(action.payload);
    });
  };

  const delaySnapshot = function* <P extends { data: WAggregation<W>[] }>(
    action: ActionCreatorWithPayload<P>,
    payload: P,
  ) {
    const unfulfilled = handling.workspace.optimisticBuilder.getUnfulfilled();
    const ids = unfulfilled.map((op) => op.optimisticId);
    const idSet = new Set(ids);

    handling.workspace.log("Saga", `Delaying snapshot: ${ids.length} items`);
    while (idSet.size > 0) {
      const {
        payload: { optimisticId },
      } = yield* take([linkOperation, removeOperation]);
      idSet.delete(optimisticId);
    }
    handling.workspace.log("Saga", "Finished delaying");
    yield* put(action(payload));
  };

  return { saga, delaySnapshot };
}
