import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";
import { dateClipboardTextFromCell } from "@kraaft/web/src/components/modularTable/components/table/clipboardTextFromCellUtils";
import { DateColumnEditor } from "@kraaft/web/src/components/modularTable/components/table/columnHeader/columnEditor/columnEditors/dateColumnEditor";
import { DateEditor } from "@kraaft/web/src/components/modularTable/components/table/editors/dateEditor";
import { dateRecordComparator } from "@kraaft/web/src/components/modularTable/components/table/recordComparatorUtils";
import { dateValueFromClipboardText } from "@kraaft/web/src/components/modularTable/components/table/valueFromClipboardTextUtils";
import {
  ColumnHeaderEditorProps,
  IFieldConfig,
} from "@kraaft/web/src/components/modularTable/components/types";

import { withBasicCell } from "../_withTableCell/withBasicCell";
import { DateCell } from "./basicCell";

export const dateConfig: IFieldConfig<KColumnType.date> = {
  type: KColumnType.date,
  TableCell: withBasicCell(
    DateCell as React.FC<IBasicCellProps<KColumnType.date>>,
  ),
  TableEditor: DateEditor,
  comparator: dateRecordComparator,
  getClipboardTextFromCell: dateClipboardTextFromCell,
  getValueFromClipboardText: dateValueFromClipboardText,
  columnEditor: DateColumnEditor as React.FC<
    ColumnHeaderEditorProps<KColumnType.date>
  >,
};

export const automatedCreatedAtConfig: IFieldConfig<KColumnType.automatedCreatedAt> =
  {
    type: KColumnType.automatedCreatedAt,
    TableCell: withBasicCell(
      DateCell as React.FC<IBasicCellProps<KColumnType.automatedCreatedAt>>,
    ),
    TableEditor: null,
    comparator: dateRecordComparator,
    getClipboardTextFromCell: dateClipboardTextFromCell,
    getValueFromClipboardText: dateValueFromClipboardText,
    columnEditor: DateColumnEditor as React.FC<
      ColumnHeaderEditorProps<KColumnType.automatedCreatedAt>
    >,
  };
