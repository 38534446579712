import { useCallback } from "react";
import { Linking } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { AlertDialog } from "@kraaft/shared/components/alertDialog";
import { AlertButtons } from "@kraaft/shared/components/alertDialog/alertDialog.types";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { urlMatcher } from "@kraaft/shared/core/utils/matchers";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { StyledNestedTextMatch, StyledNestedTextMatcher } from "@kraaft/ui";

export function useUrlMatcherForTextInput(): [StyledNestedTextMatcher] {
  const handlePress = useCallback(
    async ({ matchedText }: StyledNestedTextMatch) => {
      trackEvent({
        eventName: "Click On URL from TextInput",
      });
      await openUrlInNewTab(matchedText);
    },
    [],
  );

  return [{ ...urlMatcher, onPress: handlePress }];
}

async function openUrlInNewTab(url: string) {
  if (isNative()) {
    try {
      await Linking.openURL(url);
    } catch (e) {
      console.error("Error opening link", e);
      const title = i18n.t("openUrlError");
      const buttons: AlertButtons = [{ text: i18n.t("ok"), style: "cancel" }];

      AlertDialog.alert(title, url, buttons);
    }
  } else {
    window.open(url, "_blank");
  }
}
