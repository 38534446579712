import { useKeyPressEvent } from "react-use";

import { SlidePanel } from "@kraaft/shared/components/slidePanel";
import { LAYOUT_CONTENT_PORTAL_HOSTNAME } from "@kraaft/web/src/core/constants";

import {
  CreateRoomFlowPanelContent,
  CreateRoomFlowPanelContentProps,
} from "./createRoomFlow.panelContent";

interface CreateRoomFlowProps extends CreateRoomFlowPanelContentProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateRoomFlow = (props: CreateRoomFlowProps) => {
  const { isOpen, onClose, panelWidth } = props;

  useKeyPressEvent("Escape", onClose);
  return (
    <SlidePanel
      name="CreateRoom"
      open={isOpen}
      onClose={onClose}
      direction="right"
      portalHostname={LAYOUT_CONTENT_PORTAL_HOSTNAME}
      withDimmedBackground
    >
      <CreateRoomFlowPanelContent onClose={onClose} panelWidth={panelWidth} />
    </SlidePanel>
  );
};

export { CreateRoomFlow };
