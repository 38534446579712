import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Preface } from "@kraaft/shared/components/conversation/preface";
import {
  getUserPoolRole,
  isAtLeastPoolAdmin,
} from "@kraaft/shared/core/modules/pool/poolUtil";
import { RoomType } from "@kraaft/shared/core/modules/room/roomState";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { useUserAloneInPool } from "@kraaft/shared/core/modules/user/useUserAloneInPool";
import { useInvitationDialog } from "@kraaft/shared/core/utils/useInvitationDialog";
import { Button } from "@kraaft/ui";

type RoomPrefaceProps = React.PropsWithChildren<{
  roomId: string;
}>;

export const RoomPreface = ({ roomId, children }: RoomPrefaceProps) => {
  const { t } = useTranslation();
  const room = useSelector(selectRoom(roomId));
  const user = useSelector(selectCurrentUser);
  const role = getUserPoolRole(user, room?.poolId);
  const canInvite = isAtLeastPoolAdmin(role);
  const isAloneInPool = useUserAloneInPool();

  const shouldDisplayInviteButton =
    (room?.type === RoomType.EVERYONE && canInvite) || isAloneInPool;

  const [InvitationDialog, openInvitationDialog] = useInvitationDialog(
    room &&
      shouldDisplayInviteButton && {
        type: "pool",
        poolId: room.poolId,
        source:
          room.type === RoomType.EVERYONE ? "everyone_room" : "alone_in_pool",
      },
  );

  const inviteButton = (
    <>
      {InvitationDialog}
      <Button
        type="button"
        icon="users-plus"
        variant="QUATERNARY"
        size="SMALL"
        accessibilityLabel={t("conversationPreface.everyoneInviteButton")}
        text={t("conversationPreface.everyoneInviteButton")}
        onPress={openInvitationDialog}
      />
    </>
  );

  if (!room) {
    return null;
  }

  if (room.type === RoomType.EVERYONE) {
    if (canInvite) {
      return (
        <Preface translation="conversationPreface.everyoneInvite">
          {children}
          {inviteButton}
        </Preface>
      );
    }
    return (
      <Preface translation="conversationPreface.everyone">{children}</Preface>
    );
  }

  return (
    <Preface translation="conversationPreface.default">
      {children}
      {shouldDisplayInviteButton && inviteButton}
    </Preface>
  );
};
