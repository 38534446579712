import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { DirectoryNameSheet } from "@kraaft/shared/components/directory/directoryName.sheet";
import { DirectoryActions } from "@kraaft/shared/core/modules/directory/directoryActions";

export interface AddDirectorySheetProps {
  open: boolean;
  onClose: () => void;
  roomId: string;
  parentId: string;
  loading: boolean;
  loaderId: string;
}

export const AddDirectorySheet = ({
  open,
  onClose,
  roomId,
  parentId,
  loaderId,
  loading,
}: AddDirectorySheetProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [name, setName] = useState("");

  const internOnClose = useCallback(() => {
    setName("");
    onClose();
  }, [onClose]);

  const onCreateDirectory = useCallback(() => {
    dispatch(
      DirectoryActions.add({
        name,
        roomId,
        loaderId,
        parentId,
      }),
    );
    onClose();
  }, [dispatch, name, roomId, loaderId, parentId, onClose]);

  const { element } = DirectoryNameSheet.use({
    open,
    onClose: internOnClose,
    initialValue: "",
    onValueChange: setName,
    loading: loading,
    onApply: onCreateDirectory,
    parentId: parentId,
    roomId: roomId,
    validateActionLabel: t("create"),
  });

  return <>{element}</>;
};
