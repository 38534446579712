import {
  RoomFilters,
  Visibility,
} from "@kraaft/shared/core/modules/filter/filterState";
import { RoomNotificationFilter } from "@kraaft/shared/core/modules/room/roomState";
import {
  AnyRoomCard,
  LastMessageInfo,
  RoomCardCursor,
} from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import { db } from "@kraaft/shared/core/services/firebase/modularApp";
import {
  and,
  collection,
  endBefore,
  getDocs,
  limit,
  onSnapshotQuery,
  or,
  orderBy,
  query,
  QueryFilterConstraint,
  startAfter,
  where,
} from "@kraaft/shared/core/services/firebase/modularQuery";
import {
  FirestoreModularRecord,
  FirestoreTimestamp,
} from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { parseDate } from "@kraaft/shared/core/services/firestore/firestoreUtils";
import { firestore } from "@kraaft/shared/core/services/firestore/sdk";
import { nullId } from "@kraaft/shared/core/utils/utils";

interface FirestoreRoomCardBase {
  type: "pool" | "member";
  id: string;
  poolId: string;
  roomId: string;
  title: string;
  emoji: string;
  lastMessageInfo?: LastMessageInfo;
  isArchivedForAll: boolean;
  properties: FirestoreModularRecord["properties"];
  roomCreatedAt: FirestoreTimestamp;
  lastEventAt: FirestoreTimestamp;
  roomCreatedBy: string;
  responsibleId: string;
  statusId: string;
  labelIds: string[];
  visibility: "private" | "pool";
  isEveryoneRoom: boolean;
  archived: boolean;
}

interface FirestoreRoomPoolCard extends FirestoreRoomCardBase {
  type: "pool";
  pinned: false;
}

interface FirestoreRoomUserCard extends FirestoreRoomCardBase {
  type: "member";
  isArchivedForUser: boolean;
  userId: string;
  pinned: boolean;
  pinnedAt: FirestoreTimestamp;
  unread: boolean;
  notificationSource: RoomNotificationFilter;
}

export type FirestoreRoomCard = FirestoreRoomUserCard | FirestoreRoomPoolCard;

export function normalizeRoomCards(
  firestoreRoomCards: FirestoreRoomCard[],
  allSchemaColumns: Record<string, KSchemaColumn>,
) {
  return firestoreRoomCards.reduce<Record<string, AnyRoomCard>>(
    (roomCards, firestoreRoomCard) => {
      const existingRoomCard = roomCards[firestoreRoomCard.roomId];

      if (existingRoomCard) {
        if (existingRoomCard.archived !== firestoreRoomCard.archived) {
          delete roomCards[firestoreRoomCard.roomId];
          return roomCards;
        }
        if (firestoreRoomCard.type === "pool") {
          return roomCards;
        }
      }

      const base = {
        id: firestoreRoomCard.roomId,
        poolId: firestoreRoomCard.poolId,
        roomId: firestoreRoomCard.roomId,
        isArchivedForAll: firestoreRoomCard.isArchivedForAll,
        lastEventAt: parseDate(firestoreRoomCard.lastEventAt),
        properties: {
          title: {
            columnType: KColumnType.shortText,
            value: "",
          },
          ...KSchemaConversion.alignModularRecordPropertiesWithSchemaColumns(
            firestoreRoomCard.properties,
            allSchemaColumns,
          ),
        },
        roomCreatedAt: parseDate(firestoreRoomCard.roomCreatedAt),
        roomCreatedBy: firestoreRoomCard.roomCreatedBy,
        lastMessageInfo: firestoreRoomCard.lastMessageInfo
          ? {
              ...firestoreRoomCard.lastMessageInfo,
              createdAt: parseDate(
                firestoreRoomCard.lastMessageInfo?.createdAt,
              ),
            }
          : undefined,
        title: firestoreRoomCard.title,
        visibility: firestoreRoomCard.visibility,
        labelIds: firestoreRoomCard.labelIds,
        responsibleId: firestoreRoomCard.responsibleId,
        statusId: firestoreRoomCard.statusId,
        emoji: firestoreRoomCard.emoji,
        isEveryoneRoom: firestoreRoomCard.isEveryoneRoom,
        archived: firestoreRoomCard.archived,
      } as const;

      if (firestoreRoomCard.type === "member") {
        roomCards[firestoreRoomCard.roomId] = {
          ...base,
          type: "member",
          isArchivedForUser: firestoreRoomCard.isArchivedForUser,
          userId: firestoreRoomCard.userId,
          pinned: firestoreRoomCard.pinned,
          unread: firestoreRoomCard.unread,
          notificationSource: firestoreRoomCard.notificationSource,
          pinnedAt: firestoreRoomCard.pinnedAt
            ? parseDate(firestoreRoomCard.pinnedAt)
            : undefined,
        };
      } else {
        roomCards[firestoreRoomCard.roomId] = {
          ...base,
          type: "pool",
          pinned: false,
        };
      }

      return roomCards;
    },
    {},
  );
}

// eslint-disable-next-line complexity
function getRoomCardsQuery(
  poolId: string,
  userId: string,
  filters: RoomFilters | undefined,
  docsLimit: number,
  after?: RoomCardCursor,
  before?: RoomCardCursor,
) {
  const filterConstraints: QueryFilterConstraint[] = [
    where("poolId", "==", poolId),
    where("pinned", "==", false),
  ];

  if (filters?.visibility === Visibility.SUPERADMIN) {
    filterConstraints.push(
      and(
        where("userId", "==", nullId),
        or(
          where("isPoolCardForPrivateRoom", "==", true),
          where("isPoolCardForPrivateRoom", "==", false),
        ),
      ),
    );
  } else if (filters?.visibility === Visibility.ALL) {
    filterConstraints.push(
      and(
        where("isPoolCardForPrivateRoom", "==", false),
        or(where("userId", "==", userId), where("userId", "==", nullId)),
      ),
    );
  } else {
    filterConstraints.push(
      and(
        where("userId", "==", userId),
        where("isPoolCardForPrivateRoom", "==", false),
      ),
    );
  }

  if (filters?.showArchived) {
    filterConstraints.push(
      and(
        where("archived", "==", true),
        or(
          where("isArchivedForAll", "==", true),
          where("isArchivedForAll", "==", false),
        ),
      ),
    );
  } else {
    if (filters?.visibility === Visibility.ALL) {
      filterConstraints.push(
        and(
          where("isArchivedForAll", "==", false),
          or(where("archived", "==", true), where("archived", "==", false)),
        ),
      );
    } else {
      filterConstraints.push(
        and(
          where("archived", "==", false),
          where("isArchivedForAll", "==", false),
        ),
      );
    }
  }

  if (filters?.labelIds && filters.labelIds.length > 0) {
    filterConstraints.push(
      where("labelIds", "array-contains-any", filters.labelIds),
    );
  }

  if (filters?.responsibleId) {
    filterConstraints.push(where("responsibleId", "==", filters.responsibleId));
  }

  if (filters?.statusId) {
    filterConstraints.push(where("statusId", "==", filters.statusId));
  }

  const queryInsets: (
    | ReturnType<typeof startAfter>
    | ReturnType<typeof endBefore>
  )[] = [];

  if (after) {
    queryInsets.push(startAfter(after.lastEventAt, after.id));
  }

  if (before) {
    queryInsets.push(endBefore(before.lastEventAt, before.id));
  }

  return query(
    collection(db, "roomCard-1n"),
    and(...filterConstraints),
    orderBy("lastEventAt", "desc"),
    orderBy(firestore.FieldPath.documentId(), "desc"),
    limit(docsLimit),
    ...queryInsets,
  );
}

export function firestoreSubscribeToRoomCards(
  poolId: string,
  userId: string,
  filters: RoomFilters | undefined,
  docsLimit: number,
  callback: (roomCards: FirestoreRoomCard[]) => void,
) {
  const roomCardsQuery = getRoomCardsQuery(poolId, userId, filters, docsLimit);

  return onSnapshotQuery("subscribeToRoomCards", roomCardsQuery, (snapshot) => {
    callback(snapshot.docs.map((doc) => doc.data() as FirestoreRoomCard));
  });
}

export async function firestoreLoadRoomCards(
  poolId: string,
  userId: string,
  filters: RoomFilters | undefined,
  after: RoomCardCursor | undefined,
  before: RoomCardCursor | undefined,
  docsLimit: number,
) {
  const roomCardsQuery = getRoomCardsQuery(
    poolId,
    userId,
    filters,
    docsLimit,
    after,
    before,
  );

  const snapshot = await getDocs(roomCardsQuery);

  return snapshot.docs.map((doc) => doc.data() as FirestoreRoomCard);
}

export function firestoreSubscribeToPinnedRoomCards(
  poolId: string,
  userId: string,
  callback: (roomCards: FirestoreRoomCard[]) => void,
) {
  return onSnapshotQuery(
    "subscribeToPinnedRoomCards",
    query(
      collection(db, "roomCard-1n"),
      where("pinned", "==", true),
      where("poolId", "==", poolId),
      where("userId", "==", userId),
      orderBy(firestore.FieldPath.documentId(), "desc"),
    ),
    (snapshot) => {
      callback(snapshot.docs.map((doc) => doc.data() as FirestoreRoomCard));
    },
  );
}
