import { createSelector } from "@reduxjs/toolkit";
import memoize from "lodash/memoize";

import { RootState } from "@kraaft/shared/core/store";

export const selectState = ({ workflow }: RootState) => workflow.data;

export const selectWorkflows = createSelector(
  selectState,
  (workflowState) => workflowState.workflows,
);

export const selectEditWorkflow = createSelector(
  selectState,
  (workflowState) => workflowState.edit,
);

export const selectWorkflow = memoize((id: string) =>
  createSelector(selectState, (state) => state.workflows[id]),
);

export const selectOptimisticState = ({ workflow }: RootState) =>
  workflow.optimistic as any;
export const selectAllWorkflows = createSelector(
  selectState,
  (state) => state.workflows,
);
