import { call, takeLatest } from "typed-redux-saga/macro";

import { notifications } from "@kraaft/shared/core/modules/notifications/notifications.provider";
import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { selectOnePool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

export function* changeNotificationLanguageOnPoolChangeSaga() {
  // TODO do we want to set the language of notifications as the pool we're in?
  yield* takeLatest(PoolStateActions.setPoolLocation, handlePoolChange);
}

function* handlePoolChange({
  payload,
}: ReturnType<typeof PoolStateActions.setPoolLocation>) {
  const pool = yield* waitFor(selectOnePool(payload.poolId));
  yield* call(() => notifications.setLanguage(pool.poolLanguage));
}
