import { RefObject } from "react";

import { Placement } from "@kraaft/helper-types";
import { UserContactInfoContent } from "@kraaft/shared/components/userContactInfoSheet/userContactInfoContent";
import { UserContactInfoSheetDefinition } from "@kraaft/shared/components/userContactInfoSheet/userContactInfoSheet.definition";
import { useUserContactInfos } from "@kraaft/shared/components/userContactInfoSheet/useUserContactInfos";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { PlatformSelect } from "@kraaft/shared/core/utils/platformSelect/platformSelect";

const UserContactInfoSheetContentWrapper = ({
  onClose,
  userId,
}: Pick<UserProfileSheetProps, "onClose" | "userId">) => {
  const { userContactInfos, isOutsider, status } = useUserContactInfos(userId);

  if (!userContactInfos) {
    return null;
  }

  return (
    <UserContactInfoContent
      onClose={onClose}
      isOutsider={isOutsider}
      userContactInfos={userContactInfos}
      isLoading={status === LoaderStatus.LOADING}
    />
  );
};

type UserProfileSheetProps = {
  open: boolean;
  onClose: () => void;
  placement?: Placement;
  userId: string;
} & PlatformSelect<{
  web: { anchor: RefObject<Element> | null };
  native: { anchor?: RefObject<unknown> | null };
}>;

export const UserContactInfoSheet = ({
  anchor,
  open,
  onClose,
  userId,
}: UserProfileSheetProps) => {
  return (
    <UserContactInfoSheetDefinition.Host
      anchor={anchor}
      open={open}
      onClose={onClose}
    >
      <UserContactInfoSheetContentWrapper onClose={onClose} userId={userId} />
    </UserContactInfoSheetDefinition.Host>
  );
};
