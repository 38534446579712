import React, { useContext } from "react";
import { noop } from "ts-essentials";

type KeyboardAwareScrollViewContextType = {
  update: () => void;
};

const defaultContext = {
  update: noop,
};

export const KeyboardAwareScrollViewContext =
  React.createContext<KeyboardAwareScrollViewContextType>(defaultContext);

export const useKeyboardAwareScrollViewContext =
  (): KeyboardAwareScrollViewContextType => {
    try {
      return useContext(KeyboardAwareScrollViewContext);
    } catch (e) {
      console.warn(
        "useKeyboardAwareScrollViewContext must be used within a KeyboardAwareScrollViewContext.Provider",
      );
      return defaultContext;
    }
  };

export const KeyboardAwareScrollViewContextNoopProvider = (
  props: React.PropsWithChildren,
) => {
  return (
    <KeyboardAwareScrollViewContext.Provider value={defaultContext}>
      {props.children}
    </KeyboardAwareScrollViewContext.Provider>
  );
};
