import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

export interface MessageListTopProps {
  roomId: string;
}

export const styles = StyleSheet.create({
  preface: {
    paddingTop: Spacing.S16,
  },
});
