import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { WHATS_NEW_URL } from "@kraaft/shared/constants/global";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { ButtonElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/buttonElement";

const WhatsNewElement = () => {
  const { t } = useTranslation();

  const goToWhatsNew = useCallback(() => {
    trackEvent({
      eventName: "Click On What's New Button",
    });
    window.open(WHATS_NEW_URL, "_blank");
  }, []);

  return (
    <ButtonElement
      id="nav-whats-new"
      icon="announcement-01"
      text={t("whatsNew")}
      onPress={goToWhatsNew}
    />
  );
};

export { WhatsNewElement };
