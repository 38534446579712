import { MessageInfoContent } from "./messageInfoContent";
import { MessageInfoSheet } from "./messageInfoSheet";

interface MenuMessageInfoPopupProps {
  open: boolean;
  onClose: () => void;
  roomId: string;
  messageId: string;
}

export const MessageInfoPopup = ({
  open,
  onClose,
  messageId,
  roomId,
}: MenuMessageInfoPopupProps) => {
  return (
    <MessageInfoSheet.Host open={open} onClose={onClose} size="SMALL">
      <MessageInfoContent
        roomId={roomId}
        messageId={messageId}
        onClose={onClose}
      />
    </MessageInfoSheet.Host>
  );
};
