import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useMeshContext } from "@kraaft/helper-hooks";
import { RoomFiltersContext } from "@kraaft/shared/components/filters/roomFilters.context";
import { RoomFiltersScrollableList } from "@kraaft/shared/components/filters/roomFiltersScrollableList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { selectRoomSchemaLabelsColumn } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { getEmptyArray } from "@kraaft/shared/core/utils";

interface RoomFiltersLabelsProps {
  onClose: () => void;
  onBack: () => void;
}

export const RoomFiltersLabels = ({
  onClose,
  onBack,
}: RoomFiltersLabelsProps) => {
  const { t } = useTranslation();

  const { poolId, labelIds, setLabelIds, resetLabelIds, isDefaultLabelIds } =
    useMeshContext(RoomFiltersContext);
  const labelsColumn = useSelector(selectRoomSchemaLabelsColumn(poolId));

  const handleSelect = useCallback(
    (newLabelIds: string[]) => {
      setLabelIds(newLabelIds);
    },
    [setLabelIds],
  );

  const items = useMemo<KSelectableListItem[]>(() => {
    if (labelsColumn === undefined) {
      return getEmptyArray<KSelectableListItem>();
    }

    return KSchemaUtils.orderOptions(labelsColumn).map(([labelId, label]) => ({
      label: label.label,
      identifier: labelId,
    }));
  }, [labelsColumn]);

  return (
    <RoomFiltersScrollableList
      headerTitle={t("roomFilters.label")}
      items={items}
      selected={labelIds}
      setSelected={handleSelect}
      isMultiple
      onFiltered={onClose}
      onErase={resetLabelIds}
      canErase={!isDefaultLabelIds}
      onBack={onBack}
    />
  );
};
