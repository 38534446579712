import { StyleSheet } from "react-native";

import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  step: {
    height: 4,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    flexGrow: 1,
    borderRadius: 100,
    overflow: "hidden",
  },
  stepSpacer: {
    marginRight: Spacing.S8,
  },
  stepActive: {
    backgroundColor: Color.YELLOW_KRAAFT,
    height: 4,
    width: "100%",
  },
  stepper: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
