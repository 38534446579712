import { StyleSheet } from "react-native";

import { Color, ColorStyle, PixelSize, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  scrollToBottomButton: {
    width: PixelSize.S36,
    height: PixelSize.S36,
    borderRadius: 18,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    alignItems: "center",
    justifyContent: "center",
  },
  scrollToBottomButtonContainer: {
    position: "absolute",
    bottom: 24,
    right: 0,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: ColorStyle.BACKGROUND_STANDARD,
    backgroundColor: Color.WHITE,
    padding: Spacing.S8,
  },
});
