import { RootState } from "@kraaft/shared/core/store";

export const selectNavigationSwitch = ({ app }: RootState) =>
  app.navigationSwitch;

export const selectInviteState = ({ app }: RootState) => app.inviteState;

export const selectIsActive = ({ app }: RootState) => app.isActive;

export const selectNetwork = ({ app }: RootState) => app.network;

export const selectSavedExportEmail = ({ user, app }: RootState) => {
  const userAuth = user.userAuth;
  const savedEmail = app.extractEmail;

  return savedEmail || userAuth?.email || "";
};

export const selectIsInternetReachable = (state: RootState) =>
  state.app.network.isInternetReachable;

export const selectIsHandlingDeepLink = (state: RootState) =>
  state.app.isHandlingDeepLink;

export const selectUtmParams = (state: RootState) => state.app.utmParams;

export const selectDynamicLinkForDebug = (state: RootState) =>
  state.app.dynamicLinkForDebug;

export const selectShouldShowConversationOnboarding = ({ app }: RootState) =>
  Boolean(app.showConversationOnboarding);
