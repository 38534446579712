import { assert } from "ts-essentials";
import {
  actionChannel,
  call,
  put,
  select,
  spawn,
  take,
  takeEvery,
} from "typed-redux-saga/macro";

import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { imageHelper } from "@kraaft/shared/core/modules/file/imageHelper";
import { addOfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageActions";
import {
  AudioOfflineMessage,
  OfflineMessage,
} from "@kraaft/shared/core/modules/message/offline/offlineMessageState";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { GeoCoordinates, Size } from "@kraaft/shared/core/types";
import { getBasename, getFilename, uuid } from "@kraaft/shared/core/utils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

import * as messageUtils from "../messageUtils";
import * as actions from "./sendMessageActions";

export function* sendFileMessageSagas() {
  yield* takeEvery(actions.sendAudioMessage, sendAudioMessageSaga);
  yield* spawn(watchSendFileRequests);
}

function* watchSendFileRequests() {
  const requestChan = yield* actionChannel(actions.sendFileMessage);

  while (true) {
    const payload = yield* take(requestChan);
    yield* call(sendFileMessageSaga, payload);
  }
}

function* sendFileMessageSaga({
  payload: { roomId, requestId, file: originFile, answerTo, caption, coords },
}: ReturnType<typeof actions.sendFileMessage>) {
  try {
    const senderId = yield* select(selectCurrentUserId);
    assert(senderId);

    let processedFile = originFile;

    if (originFile.contentType === "image") {
      processedFile = {
        ...originFile,
        path: yield* call(() =>
          imageHelper.resize(originFile.path, originFile.quality ?? "standard"),
        ),
      };
      yield* call(() => fileAllocator.delete(originFile.path));
    }

    // can happen on android videos
    // TODO this should not be done here
    if (!processedFile.filename) {
      processedFile.filename = getFilename(processedFile.path);
    }

    yield* call(checkFileSize, processedFile);

    const optimisticId = uuid();

    const offlineMessage = yield* call(() =>
      createOfflineMessage({
        file: processedFile,
        senderId,
        roomId,
        requestId,
        optimisticId,
        caption,
        coords,
        answerTo,
      }),
    );

    yield* put(addOfflineMessage(offlineMessage));
  } catch (error) {
    console.log("sendFileMessageSaga ::", error);

    yield* call(() =>
      trackEvent({
        eventName: "Send Message Failure",
        error_status: error.status,
        error_message: error.message,
        room_id: roomId,
        request_id: requestId,
      }),
    );
  }
}

async function createOfflineMessage({
  file,
  senderId,
  roomId,
  requestId,
  optimisticId,
  caption,
  coords,
  answerTo,
}: {
  file: ModernFile<LocalPath>;
  senderId: string;
  roomId: string;
  requestId: string;
  optimisticId: string;
  caption: InputPartition[] | undefined;
  coords: GeoCoordinates | undefined;
  answerTo: string | undefined;
}): Promise<OfflineMessage> {
  const commonAttributes = {
    senderId,
    roomId,
    requestId,
    writtenAt: new Date().getTime(),
    optimisticId,
    caption,
    coords,
    answerTo,
  };

  if (file.contentType === "image") {
    let size: Size | undefined;

    try {
      size = await imageHelper.size(file.path);
    } catch (e) {}

    return {
      ...commonAttributes,
      type: "image",
      file,
      size: size ?? { width: 0, height: 0 },
    };
  }
  return {
    type: file.contentType,
    file,
    ...commonAttributes,
  };
}

function* sendAudioMessageSaga({
  payload: { roomId, requestId, path, answerTo },
}: ReturnType<typeof actions.sendAudioMessage>) {
  try {
    const senderId = yield* select(selectCurrentUserId);
    assert(senderId);

    const optimisticId = uuid();

    const offlineMessage: AudioOfflineMessage = {
      type: "audio",
      senderId,
      roomId,
      requestId,
      writtenAt: new Date().getTime(),
      optimisticId,
      file: {
        contentType: "audio",
        filename: getBasename(path),
        path: path,
        size: undefined,
      },
      answerTo,
    };

    yield* put(addOfflineMessage(offlineMessage));
  } catch (error) {
    console.log("error sending file message", error);
  }
}

function* checkFileSize(file: ModernFile<LocalPath>) {
  const { isBelowMaxSize, message } = messageUtils.checkFileSize(file);
  if (!isBelowMaxSize && message) {
    yield* put(
      showError({ title: i18n.t("fileServiceFileTooBigTitle"), message }),
    );
    throw new Error(message);
  }
}
