import { StyleProp, ViewStyle } from "react-native";

export function useTextBubbleStyle(
  isOnlyEmojis: boolean,
  withBubbleStyle: boolean,
  messageStyle: StyleProp<ViewStyle>,
  textContainerStyle: StyleProp<ViewStyle>,
  customStyle?: StyleProp<ViewStyle>,
) {
  return [
    !isOnlyEmojis && withBubbleStyle && messageStyle,
    !(isOnlyEmojis && withBubbleStyle) && textContainerStyle,
    customStyle,
  ];
}
