import clsx from "clsx";

import { CenterMediaMarkerProps } from "@kraaft/shared/components/geolocation/centerMediaMarker/centerMediaMarker.types";
import { Color, ColorStyle } from "@kraaft/ui";

import { ReactComponent as MarkerSvg } from "../assets/mediaMarker.svg";

import { useStyles } from "./centerMediaMarker.styles";

const CenterMediaMarker = ({ thumbnailUrl }: CenterMediaMarkerProps) => {
  const classes = useStyles({
    bodyColor: Color.WHITE,
    strokeColor: ColorStyle.FONT_LOW_EMPHASIS,
  });

  return (
    <div className={clsx(classes.markerContainer)}>
      <div className={classes.markerContent}>
        <div className={classes.innerContent}>
          <img
            className={classes.media}
            src={thumbnailUrl}
            alt="media preview marker"
          />
        </div>
      </div>
      <MarkerSvg className={classes.marker} />
    </div>
  );
};

export { CenterMediaMarker };
