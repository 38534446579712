import { PropsWithChildren, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, View } from "react-native";
import { orderBy } from "lodash";

import { useHover } from "@kraaft/helper-hooks";
import { EntryDetailsSheet } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayoutWithDetails/entryDetails.sheet";
import { useEntryLayoutWithDetailsActiveStyle } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayoutWithDetails/useEntryLayoutWithDetailsActiveStyle";
import { DifferenceLayout } from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceLayout";
import { SchemaColumnWithPath } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordProperties } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { type ResourceKey } from "@kraaft/shared/core/services/i18next";
import { Spacing, Text } from "@kraaft/ui";

interface EntryLayoutWithCreationDetailsProps {
  date: Date;
  i18nKey: ResourceKey;
  i18nValues: Record<string, unknown>;
  properties: ModularRecordProperties;
  schemaColumnWithPaths: Record<string, SchemaColumnWithPath>;
  onOpenDetails?: () => void;
}

export const EntryLayoutWithCreationDetails = ({
  children,
  date,
  i18nKey,
  i18nValues,
  properties,
  schemaColumnWithPaths,
  onOpenDetails,
}: PropsWithChildren<EntryLayoutWithCreationDetailsProps>) => {
  const { t } = useTranslation();
  const entryContainerRef = useRef(null);
  const isHovered = useHover(entryContainerRef);

  const content = useMemo(() => {
    const orderedProperties = orderBy(
      Object.entries(properties),
      [
        ([propertyKey]) => schemaColumnWithPaths[propertyKey]?.absoluteIndex,
        ([propertyKey]) => propertyKey,
      ],
      ["asc"],
    );

    return (
      <View style={styles.properties}>
        {orderedProperties.map(([propertyKey, property]) => {
          const schemaColumnWithPath = schemaColumnWithPaths[propertyKey];

          return (
            <View key={propertyKey} style={styles.propertyContainer}>
              <Text size="BODY" weight="bold">
                {schemaColumnWithPath !== undefined
                  ? KSchemaUtils.getStringPath(
                      schemaColumnWithPath.path,
                      schemaColumnWithPath.element,
                      false,
                    )
                  : t("history.unknownSchemaColumnName")}
                :
              </Text>
              <DifferenceLayout
                valueType={property.columnType}
                addedValue={property.value}
                schemaColumn={schemaColumnWithPath?.element}
              />
            </View>
          );
        })}
      </View>
    );
  }, [properties, schemaColumnWithPaths, t]);

  const { open, element, isOpen } = EntryDetailsSheet.use({
    anchor: entryContainerRef,
    date,
    i18nKey,
    i18nValues,
    content,
  });

  const handlePress = useCallback(() => {
    onOpenDetails?.();
    open();
  }, [onOpenDetails, open]);

  const activeStyle = useEntryLayoutWithDetailsActiveStyle(isHovered || isOpen);

  return (
    <>
      <Pressable
        ref={entryContainerRef}
        onPress={handlePress}
        style={activeStyle}
        accessibilityLabel={t("history.seeEntryDetails")}
      >
        {children}
      </Pressable>
      {element}
    </>
  );
};

const styles = StyleSheet.create({
  properties: {
    gap: Spacing.S12,
  },
  propertyContainer: {
    gap: Spacing.S4,
  },
});
