import { createTheme, Theme } from "@mui/material/styles";
// https://github.com/mui/mui-x/issues/13483#issue-2353333203
import type {} from "@mui/x-date-pickers/themeAugmentation";

import { Color, ColorStyle, FontSize, Radius, Spacing } from "@kraaft/ui";

declare module "@mui/styles" {
  interface DefaultTheme extends Theme {}
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Apercu, "Helvetica Neue", Arial, sans-serif',
    h1: {
      color: ColorStyle.ACTION_CTA,
      fontSize: `${39 / 16}rem`,
      fontWeight: 700,
      lineHeight: 49 / 39,
    },
    h2: {
      fontSize: `${25 / 16}rem`,
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h6: {
      fontWeight: 700,
    },
    body1: {
      fontSize: `${14 / 16}rem`,
      lineHeight: 17 / 14,
    },
  },

  palette: {
    mode: "light",
    background: {
      default: ColorStyle.ACTION_HOVERED,
      paper: Color.WHITE,
    },
    primary: {
      main: ColorStyle.ACTION_CTA,
      contrastText: Color.WHITE,
    },
    secondary: {
      main: Color.GREY_ANTIFLASH,
      contrastText: Color.BLACK,
    },
    warning: {
      main: Color.YELLOW_KRAAFT,
    },
  },

  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },

  components: {
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          "&&": {
            ".MuiList-root": {
              width: "65px",
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementTop: {
          marginBottom: `${Spacing.S8}px !important`,
        },
        tooltipPlacementLeft: {
          marginRight: `${Spacing.S8}px !important`,
        },
        tooltipPlacementBottom: {
          marginTop: `${Spacing.S8}px !important`,
        },
        tooltipPlacementRight: {
          marginLeft: `${Spacing.S8}px !important`,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          maxWidth: 646,
        },
        paper: {
          position: "relative",
          borderRadius: Radius.SMALL,
          padding: Spacing.S24,
          boxSizing: "border-box",
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.4)",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: `${70 / 16}rem`,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: `1px solid ${ColorStyle.SEPARATOR}`,
          "&:before": {
            display: "none",
          },
          marginTop: Spacing.S16,
          marginBottom: Spacing.S16,
        },
        rounded: {
          borderRadius: Radius.SMALL,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: "row-reverse",
          paddingLeft: Spacing.S12,
          paddingRight: Spacing.S12,

          "&.Mui-expanded": {
            minHeight: 48,
          },
        },
        expandIconWrapper: {
          transform: "rotate(90deg)",
          margin: 0,
          marginRight: 6,
          padding: 0,
        },
        content: {
          color: Color.BLUE_SAVOY,
          justifyContent: "space-between",
          alignItems: "center",
          "& .MuiTypography-root": {
            fontSize: FontSize.BODY,
            fontWeight: "bold",
          },
          "&.Mui-expanded": {
            marginTop: Spacing.S12,
            marginBottom: Spacing.S12,
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          background: Color.WHITE,
          color: ColorStyle.FONT_LOW_EMPHASIS,
          fontSize: `${13 / 16}rem`,
          fontWeight: 400,
        },
        paddingNone: {
          paddingTop: Spacing.S4,
          paddingRight: Spacing.S12,
          paddingBottom: Spacing.S4,
          paddingLeft: Spacing.S12,
        },
      },
    },
  },
});
