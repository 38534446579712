import Coordinates from "coordinate-parser";
import { BBox } from "geojson";
import GoogleMapReact, { Size } from "google-map-react";

import { markerSize } from "@kraaft/shared/components/geolocation/baseMarker/baseMarkerConfig";
import { getMarkerCoordinates } from "@kraaft/shared/components/mapController/markers/markerUtils";
import {
  MapElementGeoJSON,
  MarkerGeoJSON,
} from "@kraaft/shared/components/mapController/types";
import { GeoCoordinates } from "@kraaft/shared/core/types";

export const roundToCoordinates = (value: number) =>
  Math.round((value + Number.EPSILON) * 10000) / 10000;

function compute(value: number, modifier: string | number | undefined) {
  let modifierNumber = Number(modifier);

  if (modifier === undefined) {
    return value;
  }

  if (typeof modifier === "string") {
    const matchPercent = modifier.match(/^([-]{0,1}\d*)%$/);
    if (matchPercent !== null && matchPercent[1] !== undefined) {
      modifierNumber = Number(matchPercent[1]);

      return (value * modifierNumber) / 100;
    }
    modifierNumber = Number(modifier);
  }

  return value + modifierNumber;
}

const OFFSET_TRANSLATION = ["-50%", "-100%"];
export function distanceToMouse(
  pt: GoogleMapReact.Point,
  mousePos: GoogleMapReact.Point,
) {
  const offsetWidth = compute(markerSize.width, OFFSET_TRANSLATION[0]);
  const offsetHeight = compute(markerSize.height, OFFSET_TRANSLATION[1]);

  const bb = {
    tl: {
      x: pt.x + offsetWidth,
      y: pt.y + offsetHeight,
    },
    br: {
      x: pt.x + markerSize.width + offsetWidth,
      y: pt.y + markerSize.height + offsetHeight,
    },
  };

  const dx = Math.max(bb.tl.x - mousePos.x, 0, mousePos.x - bb.br.x);
  const dy = Math.max(bb.tl.y - mousePos.y, 0, mousePos.y - bb.br.y);

  const distance = Math.sqrt(dx * dx + dy * dy);

  return distance;
}

export function getMarkerBounds(coordinates: GeoCoordinates[]) {
  const bounds = new google.maps.LatLngBounds();

  for (const { latitude, longitude } of coordinates) {
    bounds.extend(new google.maps.LatLng(latitude, longitude));
  }
  return bounds;
}

function getLatLngBoundsFromBBox(bbox: BBox) {
  const [nwLng, seLat, seLng, nwLat] = bbox;

  const ne = new google.maps.LatLng(nwLat, seLng);
  const sw = new google.maps.LatLng(seLat, nwLng);

  return new google.maps.LatLngBounds(sw, ne);
}

export function isMarkerWithinBounds(bounds: BBox, marker: MapElementGeoJSON) {
  const mapBounds = getLatLngBoundsFromBBox(bounds);

  const { latitude, longitude } = getMarkerCoordinates(marker);

  return mapBounds.contains(new google.maps.LatLng(latitude, longitude));
}

export function filterMarkerWithinBounds<ElementType>(
  markers: MarkerGeoJSON<ElementType>[],
  bounds: BBox,
) {
  const mapBounds = getLatLngBoundsFromBBox(bounds);

  return markers.filter((marker) => {
    const { latitude, longitude } = getMarkerCoordinates(marker);

    return mapBounds.contains(new google.maps.LatLng(latitude, longitude));
  });
}

export const expandBoundsByOffsetPixel = (
  _bounds: [number, number, number, number],
  _mapSize: Size,
  _offset: { top?: number; right?: number; bottom?: number; left?: number },
) => {
  // TODO Implement for the web
};

export const toCoordinatesString = (coords: GeoCoordinates): string =>
  `${coords.latitude},${coords.longitude}`;

export const parseCoordinates = (value: string): GeoCoordinates | undefined => {
  try {
    const coords = new Coordinates(value);

    return {
      latitude: coords.getLatitude(),
      longitude: coords.getLongitude(),
    };
  } catch (error) {
    console.error("Unable to parse coordinates", value);
    return;
  }
};
