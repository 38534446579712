import { createAction } from "@reduxjs/toolkit";

import { SchemaElementWithPath } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";

import { ModularFolderHistoryEntry } from "./modularFolderHistory.state";

export const ModularFolderHistoryActions = {
  subscribe: createAction<{ schemaId: string; modularFolderId: string }>(
    "@modularFolderHistory/subscribe",
  ),
  unsubscribe: createAction<{ modularFolderId: string }>(
    "@modularFolderHistory/unsubscribe",
  ),
};

export const ModularFolderHistoryStateActions = {
  set: createAction<{
    modularFolderId: string;
    modularFolderHistoryEntries: ModularFolderHistoryEntry[];
    schemaElementWithPaths: Record<string, SchemaElementWithPath>;
  }>("@modularFolderHistory/state/set"),

  setLoading: createAction<{
    modularFolderId: string;
  }>("@modularFolderHistory/state/setLoading"),
};
