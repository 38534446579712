import { StyleSheet } from "react-native";

import { Color, ColorStyle, FontSize, Radius, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    padding: Spacing.S24,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: Radius.SMALL,
    alignItems: "flex-start",
  },
  title: {
    fontSize: FontSize.TITLE,
    marginBottom: Spacing.S16,
  },
  subtitle: {
    fontSize: FontSize.BODY,
    marginBottom: Spacing.S16,
  },

  containerForConversation: {
    marginVertical: Spacing.S16,
    marginHorizontal: Spacing.S32,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },

  containerForPhotoGallery: {
    marginTop: Spacing.S16,
    marginBottom: Spacing.S16,
    marginRight: Spacing.S16,
    backgroundColor: Color.WHITE,
  },
  containerForDocuments: {
    marginTop: Spacing.S16,
    marginBottom: Spacing.S16,
    backgroundColor: Color.WHITE,
  },
});
