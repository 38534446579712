import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { disconnectKizeoForms } from "@kraaft/shared/core/modules/form/formActions";
import { selectPoolForms } from "@kraaft/shared/core/modules/form/formSelectors";
import { Form } from "@kraaft/shared/core/modules/form/formState";
import { getKizeoExportNames } from "@kraaft/shared/core/modules/form/formUtils";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { ColorStyle, FontSize, PixelSize, Spacing } from "@kraaft/ui";
import {
  KTable,
  KTableConfig,
  KTableContext,
} from "@kraaft/web/src/components/kTable";
import { ensureColumnType } from "@kraaft/web/src/components/kTable/kTableUtils";
import {
  ConnectKizeoSteps,
  ConnectKizeoType,
} from "@kraaft/web/src/components/multiStepDialog/customs/connectKizeoSteps/connectKizeoSteps";
import { TableContextMenuButton } from "@kraaft/web/src/components/TableContextMenuButton/TableContextMenuButton";

import { IntegrationSection } from "../integrationSection";
import { SelectKizeoExportSheet } from "./selectKizeoExportSheet";

import { useStyles } from "./kizeoSection.styles";

const KizeoSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const pool = useSelector(selectCurrentPool);
  const [syncType, setSyncType] = useState<"none" | ConnectKizeoType>("none");
  const { open, element } = SelectKizeoExportSheet.use({});

  const setToken = useCallback(() => setSyncType("setToken"), [setSyncType]);
  const updateForms = useCallback(
    () => setSyncType("selectForms"),
    [setSyncType],
  );
  const stopSynchronizing = useCallback(
    () => setSyncType("none"),
    [setSyncType],
  );

  const poolId = pool?.id ?? "";

  const forms = useSelector(selectPoolForms(poolId)) || [];
  const formsToDisplay = forms.filter((form) => form.type === "kizeo");

  const isSynchronized = pool?.external?.kizeo?.isSynchronized ?? false;

  const actions: ActionSheetItem[] = useMemo(() => {
    return isSynchronized
      ? [
          {
            label: t("modifyApiToken"),
            onPress: setToken,
          },
          {
            label: t("synchronizeForms"),
            onPress: updateForms,
          },
          {
            label: t("disconnectKizeo"),
            onPress: () => {
              newConfirmationAlert({
                title: t("disconnectKizeoTitle"),
                message: t("disconnectKizeoContent"),
                confirmText: t("delete"),
                onConfirm: () =>
                  dispatch(
                    disconnectKizeoForms({
                      poolId,
                    }),
                  ),
              });
            },
            style: "destructive",
          },
        ]
      : [
          {
            label: t("connect"),
            onPress: setToken,
          },
        ];
  }, [isSynchronized, t, setToken, updateForms, dispatch, poolId]);

  const config: KTableConfig<Form> = useMemo(() => {
    return {
      enableHover: true,
      columns: [
        ensureColumnType({
          id: "name",
          label: t("kizeoName"),
          width: "50%",
          // eslint-disable-next-line react/no-unstable-nested-components
          component: (form: Form) => (
            <Typography className={classes.formName}>{form.name}</Typography>
          ),
        }),
        ensureColumnType({
          id: "exports",
          label: t("kizeoExports"),
          width: "50%",
          // eslint-disable-next-line react/no-unstable-nested-components
          component: (form: Form) => (
            <Typography className={classes.formName}>
              {getKizeoExportNames(form.exports)}
            </Typography>
          ),
        }),
        ensureColumnType({
          id: "actions",
          // eslint-disable-next-line react/no-unstable-nested-components
          component: (form: Form, context: KTableContext) => {
            const options: ActionSheetItem[] = [
              {
                label: t("kizeoModifyExports"),
                onPress: () => open({ form }),
              },
            ];

            return (
              <TableContextMenuButton context={context} actions={options} />
            );
          },
        }),
      ],
      style: {
        columns: {
          color: ColorStyle.FONT_HIGH_EMPHASIS,
          fontSize: FontSize.MEDIUM,
          paddingLeft: Spacing.S16,
        },
        rows: {
          height: PixelSize.S40,
        },
      },
    };
  }, [t, classes.formName, open]);

  return poolId ? (
    <>
      <IntegrationSection
        actions={actions}
        details={
          isSynchronized ? (
            <KTable
              data={formsToDisplay}
              extractKey={(form: Form) => form.id}
              config={config}
            />
          ) : null
        }
        title={t("kizeoFormsSectionTitle")}
      />
      <ConnectKizeoSteps
        open={syncType !== "none"}
        onClose={stopSynchronizing}
        poolId={poolId}
        type={syncType !== "none" ? syncType : undefined}
      />

      {element}
    </>
  ) : null;
};

export { KizeoSection };
