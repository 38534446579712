import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize } from "@kraaft/ui";

const makeDialogStyle = makeStyles(() => ({
  dialogContentContainer: {
    flex: 1,
  },
  text: {
    fontSize: FontSize.MEDIUM,
    fontWeight: "normal",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  spacer: {
    marginBottom: 22,
  },
  noMargin: {
    margin: 0,
    marginBottom: 3,
  },
}));

export { makeDialogStyle };
