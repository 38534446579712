import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { selectHasRecentlySkippedSSO } from "@kraaft/shared/core/modules/memory/memorySelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { useAuth } from "@kraaft/shared/core/services/auth/useAuth";

// support only oidc providers
async function getSupportedAuthencationFlow(
  emailOrPhoneNumber: string,
): Promise<{
  user:
    | false
    | {
        shouldResetPassword: boolean;
      };
  provider:
    | false
    | {
        id: string;
        name: string;
        skippable: boolean;
        offlineAccess: boolean;
        helpText: string | undefined;
      };
  linked: boolean;
}> {
  const result = await Api.getAuthenticationFlow(emailOrPhoneNumber);
  if (!result.provider) {
    return result;
  }
  if (result.provider.id.startsWith("oidc")) {
    return result;
  }
  // ignore non-oidc providers
  return {
    user: result.user,
    provider: false,
    linked: result.linked,
  };
}

export function useSSOProvider(emailOrPhoneNumber?: string) {
  return useQuery(
    ["authenticationDetails", emailOrPhoneNumber],
    () => getSupportedAuthencationFlow(emailOrPhoneNumber || ""),
    {
      enabled: !!emailOrPhoneNumber,
      networkMode: "offlineFirst",
    },
  );
}

export function useFetchSSOProvider() {
  const client = useQueryClient();
  return (emailOrPhoneNumber?: string) =>
    client.fetchQuery(["authenticationDetails", emailOrPhoneNumber], () =>
      getSupportedAuthencationFlow(emailOrPhoneNumber || ""),
    );
}

export function useShouldElevateToSSO(): boolean {
  const hasRecentlySkippedSSO = useSelector(selectHasRecentlySkippedSSO);
  const auth = useAuth();
  const sso = useSSOProvider(auth?.email);

  if (!auth || !auth.email) {
    return false;
  }

  if (sso.isLoading) {
    return false;
  }

  if (!sso.isSuccess) {
    return false;
  }

  if (sso.data.provider) {
    const isLoggedWithSSO = auth.providerId === sso.data.provider.id;

    if (isLoggedWithSSO) {
      return false;
    }

    if (sso.data.provider.skippable && hasRecentlySkippedSSO) {
      return false;
    }

    return true;
  }

  return false;
}
