import { StyleSheet, Text } from "react-native";
import { useSelector } from "react-redux";

import { GradientLogHolder } from "@kraaft/shared/components/message/messageLog/holders/gradientLogHolder";
import { FolderLogWrapper } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/folderLogWrapper";
import { LogText } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/logText";
import { OpenWithArrow } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/openWithArrow";
import { PrependWithSchemaIcon } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/prependWithSchemaIcon";
import {
  FolderUncheckedEvent,
  LogMessage,
} from "@kraaft/shared/core/modules/message/messageState";
import { selectUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { Color } from "@kraaft/ui";

interface FolderUncheckedProps {
  roomId: string;
  message: LogMessage;
  event: FolderUncheckedEvent;
}

export const FolderUnchecked = ({
  roomId,
  message,
  event,
}: FolderUncheckedProps) => {
  const user = useSelector(selectUser(message.senderId));
  const folderName = event.folderName;

  return (
    <GradientLogHolder>
      <FolderLogWrapper event={event} roomId={roomId}>
        <Text style={styles.center}>
          <PrependWithSchemaIcon event={event} />
          <LogText
            color={Color.WHITE}
            i18nKey="logFolderUnchecked"
            userId={message.senderId}
            values={{
              username: getUsername(user),
              folderName,
            }}
            userColor={Color.WHITE}
          />
        </Text>
        <OpenWithArrow />
      </FolderLogWrapper>
    </GradientLogHolder>
  );
};

const styles = StyleSheet.create({
  center: {
    textAlign: "center",
  },
});
