import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { firebaseSDK } from "@kraaft/shared/core/services/firebase/sdk";

export type IdToken = {
  providerId: string;
};

export type User = {
  id: string;
  email?: string;
  name?: string;
};

const AuthContext = createContext<(User & IdToken) | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [idToken, setIdToken] = useState<IdToken | null>(null);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribeIdToken = firebaseSDK.onIdTokenChanged(setIdToken);
    const unsubscribeAuthState = firebaseSDK.onAuthStateChanged(setUser);

    return () => {
      unsubscribeIdToken();
      unsubscribeAuthState();
    };
  }, []);

  const value = useMemo(() => {
    if (user && idToken) {
      return {
        ...user,
        ...idToken,
      };
    }

    return null;
  }, [user, idToken]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return useContext(AuthContext);
}
