import { MiniMedia } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { Icon, IconSize, Image, Preloader } from "@kraaft/ui";

import { styles } from "./fileCard.styles";

interface FileCardIcon {
  isLoading: boolean;
  media: MiniMedia;
}

export const FileCardIcon = ({ isLoading, media }: FileCardIcon) => {
  return (
    <>
      {isLoading && <Preloader size={IconSize.MEDIUM} />}
      {!isLoading && media.type === "image" && (
        <Image
          style={styles.image}
          preferredSize="thumbnail"
          source={media.preview.downloadUrl}
        />
      )}
      {!isLoading && media.type === "document" && (
        <Icon name="file-04" size="MEDIUM" color="FONT_HIGH_EMPHASIS" />
      )}
      {!isLoading && media.type === "video" && (
        <Icon name="play" size="MEDIUM" color="FONT_HIGH_EMPHASIS" />
      )}
    </>
  );
};
