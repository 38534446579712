import { Platform, StyleSheet } from "react-native";

import { ensureValidStyleSheet } from "@kraaft/shared/core/utils";
import { Color, ColorStyle, FontSize, PixelSize } from "@kraaft/ui";

export const nativeStylesObject = ensureValidStyleSheet({
  container: {
    flexDirection: "row",
  },
  borderedContainer: {
    position: "relative",
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    flexDirection: "row",
    alignItems: "stretch",
    minHeight: 55,
    flex: 1,
  },
  lightContainer: {
    backgroundColor: Color.WHITE,
  },
  labelContainer: {
    position: "absolute",
    top: 0,
    left: "-100%",
    width: "200%",
    paddingLeft: "50%",
  },
  labelText: {
    textAlign: "left",
    includeFontPadding: false,
    textAlignVertical: "top",
  },
  centerView: {
    flexDirection: "row",
    flex: 1,
  },
  textInputContainer: {
    flexDirection: "row",
    flex: 1,
  },
  textInputWrapper: {
    flex: 1,
  },
  textInput: {
    paddingBottom: Platform.OS === "android" ? 4 : 8,
    fontSize: FontSize.BODY,
  },
  textInputPaddingTop: {
    paddingTop: Platform.OS === "android" ? 21 : 25,
  },
  leftIconContainer: {
    justifyContent: "center",
    padding: 7,
  },
  rightIconContainer: {
    justifyContent: "center",
    paddingRight: 12,
    paddingLeft: 7,
  },
  iconLessContainer: {
    paddingLeft: 15,
  },
  helperTextContainer: {
    minHeight: PixelSize.S16,
    marginTop: 4,
    marginLeft: 4,
  },
  helperText: {
    fontSize: FontSize.SMALL,
  },
});

export const styles = StyleSheet.create(nativeStylesObject);
