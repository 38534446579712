import { useRef } from "react";
import { useSelector } from "react-redux";

import { useDocumentGalleryWebFooterActions } from "@kraaft/shared/components/galleries/documentGallery/useDocumentGalleryActions";
import { DocumentGallerySelectionFooterProps } from "@kraaft/shared/components/selectionFooter/selectionFooterProps";
import { selectMessageSelectionCount } from "@kraaft/shared/core/modules/message/messageSelectors";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { SelectionFooter } from "@kraaft/web/src/components/selectionFooter";

const DocumentGallerySelectionFooter = (
  props: DocumentGallerySelectionFooterProps,
) => {
  const { roomId } = props;

  const selectionCount = useSelector(
    selectMessageSelectionCount(roomId, "documentGallery"),
  );
  const moreButtonAnchorRef = useRef(null);

  const [sheetOpen, setSheetOpen, setSheetClosed] = useBooleanState();

  const { actions, options } = useDocumentGalleryWebFooterActions({
    roomId,
    setSheetOpen,
    moreButtonAnchorRef,
  });

  if (selectionCount === 0) {
    return null;
  }

  return (
    <SelectionFooter
      roomId={roomId}
      selectionCount={selectionCount}
      moreButtonAnchorRef={moreButtonAnchorRef}
      options={options}
      actions={actions}
      sheetOpen={sheetOpen}
      setSheetClosed={setSheetClosed}
    />
  );
};

export { DocumentGallerySelectionFooter };
