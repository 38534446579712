import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { MapView } from "@kraaft/shared/components/mapController/map";
import { useRoomClusterConfig } from "@kraaft/shared/components/mapController/markers/roomMarker/useRoomClusterConfig";
import { NoMarkers } from "@kraaft/shared/components/mapController/noMarkers/noMarkers";
import { RoomsMapMenu } from "@kraaft/shared/components/mapController/roomsMap/roomsMapMenu";
import { useRoomsMarkers } from "@kraaft/shared/components/mapController/roomsMap/useRoomsMarker";
import { MapTrackingInfo } from "@kraaft/shared/components/mapController/types";
import { openPreview } from "@kraaft/shared/core/modules/preview/previewActions";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { RoomCardUtils } from "@kraaft/shared/core/modules/roomCard/roomCard.utils";
import { useMapViewStyles } from "@kraaft/web/src/components/conversationViewer/map/conversationMapView.styles";

interface Props {
  rooms: Room[];
}

const trackingInfo: MapTrackingInfo = { map_type: "conversation_database" };

const ConversationMapView = ({ rooms }: Props) => {
  const classes = useMapViewStyles();
  const dispatch = useDispatch();

  const onMarkerAction = useCallback(
    (id: string) => {
      dispatch(
        openPreview({
          type: "room",
          roomId: id,
        }),
      );
    },
    [dispatch],
  );

  const roomCardsFromRooms = useMemo(
    () => rooms.map(RoomCardUtils.fromRoom),
    [rooms],
  );

  const markers = useRoomsMarkers({
    roomCards: roomCardsFromRooms,
    onMarkerAction,
  });

  const { clusterConfig: roomClusterConfig } = useRoomClusterConfig({
    onTooltipPress: onMarkerAction,
  });

  const isInteractive = markers === null || markers.length > 0;

  return (
    <div className={classes.root}>
      <div className={classes.mapContainer}>
        <MapView
          markers={markers}
          geoDetail="rooms"
          enableClustering
          clustersConfig={{
            room: roomClusterConfig,
          }}
          trackingInfo={trackingInfo}
        />
        {!isInteractive && <NoMarkers geoDetail="room" />}
      </div>
      {isInteractive && (
        <div className={classes.menuContainer}>
          <RoomsMapMenu rooms={rooms} onPress={onMarkerAction} />
        </div>
      )}
    </div>
  );
};

export { ConversationMapView };
