import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, Text, View } from "react-native";

import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { LockedAnswersBanner } from "@kraaft/shared/components/modular/details/lockedAnswersBanner";
import { SearchHeader } from "@kraaft/shared/components/searchHeader";
import { constants } from "@kraaft/shared/constants/constants";
import { SectionLockInfo } from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";
import { Button, KeyboardAvoidingView } from "@kraaft/ui";

import { styles } from "./listEditorPanel.styles";

function useFilteredItems(items: KSelectableListItem<string, unknown>[]) {
  const [filter, setFilter] = useState<string>("");

  const filteredItems = useMemo(() => {
    const normalizedFilter = normalizeTextForSearch(filter);

    return items.filter(
      (item) =>
        filter.length === 0 ||
        normalizeTextForSearch(item.label).indexOf(normalizedFilter) > -1,
    );
  }, [items, filter]);
  return { filteredItems, setFilter, filter };
}

interface ListEditorPanelProps {
  value: string[] | undefined;
  items: KSelectableListItem<string, unknown>[];
  onClose: () => void;
  setValue: (newValue: string[] | undefined) => void;
  column: KSchemaColumn<
    KColumnType.user | KColumnType.selectMultiple | KColumnType.selectSingle
  >;
  placeholder: string;
  label: string;
  disabled: boolean;
  testID: string | undefined;
  sectionLockInfo: SectionLockInfo;
}

const ListEditorPanel = ({
  value,
  items,
  onClose,
  setValue,
  column,
  placeholder,
  label,
  disabled,
  testID,
  sectionLockInfo,
}: ListEditorPanelProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const [internalValue, setInternalValue] = useState(value);

  const { filteredItems, setFilter } = useFilteredItems(items);

  const handleClose = useCallback(() => {
    setTimeout(() => {
      onClose();
    }, constants.CONFIRMATION_TIME);
  }, [onClose]);

  const goBack = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const submitNow = useCallback(
    (selected?: string[]) => {
      setValue(selected);
      handleClose();
    },
    [handleClose, setValue],
  );

  const handleSubmit = useCallback(() => {
    submitNow(internalValue);
  }, [submitNow, internalValue]);

  const handleChange = useCallback(
    (selected: string[]) => {
      setInternalValue(selected);
      if (!KSchemaUtils.isMultiSelect(column)) {
        submitNow(selected);
      }
    },
    [column, submitNow],
  );

  return (
    <>
      <SearchHeader
        placeholder={placeholder}
        headerTitle={label}
        leftIcon={
          KSchemaUtils.isMultiSelect(column) ? "x-close" : "chevron-left"
        }
        onFilterSet={setFilter}
        onGoBackPress={goBack}
      />
      <KeyboardAvoidingView style={styles.screen}>
        <SafeAreaView style={styles.safeAreaView}>
          <View style={styles.container}>
            {filteredItems.length === 0 ? (
              <Text style={styles.emptyList}>{t("emptyList")}</Text>
            ) : (
              <View style={styles.container}>
                {sectionLockInfo.isLocked && (
                  <LockedAnswersBanner
                    mode="singular"
                    lockedBy={sectionLockInfo.lockedBy}
                    lockedAt={sectionLockInfo.lockedAt}
                  />
                )}
                <KSelectionList
                  disabled={disabled || sectionLockInfo.isLocked}
                  style={styles.listContainer}
                  isMultiple={KSchemaUtils.isMultiSelect(column)}
                  items={filteredItems}
                  setSelected={handleChange}
                  selected={internalValue}
                  id={`${testID}-${column.key}-list`}
                  keyboardShouldPersistTaps="handled"
                  selectAll
                />
                {!disabled && KSchemaUtils.isMultiSelect(column) && (
                  <View style={styles.buttonContainer}>
                    <Button
                      accessibilityLabel={t("confirm")}
                      text={t("confirm")}
                      onPress={handleSubmit}
                    />
                  </View>
                )}
              </View>
            )}
          </View>
        </SafeAreaView>
      </KeyboardAvoidingView>
    </>
  );
};

export { ListEditorPanel };
