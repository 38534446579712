import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import clsx from "clsx";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { Api } from "@kraaft/shared/core/services/api";
import { ColorStyle, Preloader } from "@kraaft/ui";

import { CurrentStepProps } from "../../../multiStepDialogTypes";

import { stylesheet, useStyles } from "./enterTokenStep.styles";

export interface EnterTokenStepParams {
  poolId: string;
  nextStep: number;
}

type EnterTokenStepProps = CurrentStepProps<EnterTokenStepParams>;

const EnterTokenStep = (props: EnterTokenStepProps) => {
  const { poolId, nextStep, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, toggleLoading] = useState(false);
  const [token, setToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();

  async function handleConnect() {
    try {
      toggleLoading(true);
      const response = await Api.setKizeoToken({ poolId, token });
      toggleLoading(false);

      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        props.setStep({ index: nextStep });
      }
    } catch (error) {
      toggleLoading(false);
      dispatch(showError({ title: error.message || error }));
    }
  }

  const resetError = useCallback(() => {
    setErrorMessage("");
  }, [setErrorMessage]);

  const title = t("connectKizeoSetTokenTitle");

  const content = (
    <div className={classes.dialogContentContainer}>
      <Typography classes={{ root: clsx(classes.text, classes.spacer) }}>
        {t("connectKizeoSetTokenHeader")}
      </Typography>
      <KInput
        error={errorMessage !== ""}
        style={stylesheet.tokenInput}
        value={token}
        label={t("kizeoToken")}
        returnKeyType="done"
        returnKeyLabel={t("validate")}
        onChangeText={setToken}
        onFocus={resetError}
        showEditIcon
        onSubmitEditing={handleConnect}
      />
      {errorMessage && (
        <Typography classes={{ root: classes.errorText }}>
          {errorMessage}
        </Typography>
      )}
      {isLoading && <Preloader color={ColorStyle.ACTION_CTA} />}
    </div>
  );

  const actions: FooterActions = [
    {
      accessibilityLabel: t("connect"),
      text: t("connect"),
      onPress: handleConnect,
      disabled: token.length === 0,
      autoFocus: true,
      variant: "PRIMARY",
    },
    {
      accessibilityLabel: t("cancel"),
      text: t("cancel"),
      onPress: onClose,
    },
  ];

  return <KDialogContent {...{ onClose, title, content, actions }} />;
};

export { EnterTokenStep };
