import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CompanyActions } from "@kraaft/shared/core/modules/company/company.actions";
import {
  selectCompanies,
  selectDictCompanies,
} from "@kraaft/shared/core/modules/company/company.selectors";
import { compareStrings } from "@kraaft/shared/core/utils/utils";

export function useCompanies() {
  const dispatch = useDispatch();
  const companies = useSelector(selectCompanies);

  useEffect(() => {
    dispatch(CompanyActions.subscribe());
    return () => {
      dispatch(CompanyActions.unsubscribe());
    };
  }, [dispatch]);

  return useMemo(
    () => [...companies].sort((a, b) => compareStrings(a.name, b.name)),
    [companies],
  );
}

export function useDictCompanies() {
  const dispatch = useDispatch();
  const companies = useSelector(selectDictCompanies);

  useEffect(() => {
    dispatch(CompanyActions.subscribe());
    return () => {
      dispatch(CompanyActions.unsubscribe());
    };
  }, [dispatch]);

  return companies;
}
