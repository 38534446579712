import { FontSize, Spacing } from "@kraaft/ui";

export const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: Spacing.S12,
  },
  text: {
    fontFamily: "Apercu",
    fontSize: FontSize.SMALL,
    marginRight: 4,
  },
  logoContainer: {
    width: 50,
  },
  logo: {
    display: "block",
    width: "100%",
    height: "auto",
  },
} satisfies Record<string, React.CSSProperties>;
