import React, { useCallback, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FillDirectoryPickerHandle } from "@kraaft/shared/components/directory/fillDirectoryFlowProps";
import { DocumentGalleryPicker } from "@kraaft/shared/components/galleries/documentGallery/documentGalleryPicker/documentGalleryPicker";
import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import { attachInDirectoryFromSelection } from "@kraaft/shared/core/modules/message/attach/attachMessageActions";
import { selectMiniMediasOfType } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";
import { useLoader } from "@kraaft/shared/core/utils/useLoader";

const FillDirectoryDocumentGalleryPicker = React.forwardRef<
  FillDirectoryPickerHandle,
  {
    directory: Directory;
    onClose: () => void;
    onTitleChanged: (title: string) => void;
  }
>((props, ref) => {
  const { directory, onClose, onTitleChanged } = props;
  const roomId = directory.roomId;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const medias = useSelector(selectMiniMediasOfType(roomId, ["document"]));
  const { title, handleClearSelection, selectionCount } =
    useMediaSelectionContext({
      medias,
      roomId,
      type: "document",
    });

  useImperativeHandle(ref, () => ({
    clearSelection: handleClearSelection,
  }));

  const { loaderId, loading } = useLoader({
    prefix: "fillDirectoryFromDocumentGallery",
    onSuccess: onClose,
  });

  const onSubmit = useCallback(() => {
    dispatch(
      attachInDirectoryFromSelection({
        directoryId: directory.id,
        roomId,
        loaderId,
      }),
    );
  }, [directory.id, dispatch, loaderId, roomId]);

  useEffect(() => {
    onTitleChanged(
      selectionCount === 0
        ? t("directoryActions.addFromDocumentGallery")
        : title,
    );
  }, [onTitleChanged, selectionCount, t, title]);

  return (
    <DocumentGalleryPicker
      roomId={roomId}
      medias={medias}
      onSubmit={onSubmit}
      isLoading={loading}
    />
  );
});

export { FillDirectoryDocumentGalleryPicker };
