import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";

export class BlobResolveError extends Error {}

export interface FileUploader<FileType extends ModernFile<LocalPath>> {
  upload(params: {
    file: FileType;
    storagePath: string;
    uploadUrl: string;
  }): Promise<void>;
}
