import { useMeshContext } from "@kraaft/helper-hooks";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularDisplayRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { formatNumber } from "@kraaft/shared/core/utils/useNumberInput";
import { useBasicCellStyles } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCell.styles";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";

export const CurrencyCell = ({
  value,
  column,
  isCellSelected,
}: IBasicCellProps<KColumnType.currency>) => {
  const classes = useBasicCellStyles({ isCellSelected });

  const { currencyCode } = useMeshContext(ModularDisplayRequirementsContext);

  if (value !== undefined) {
    const formatted = formatNumber(value, currencyCode, true);
    return <span className={classes.cellCursor}>{formatted}</span>;
  }

  return null;
};
