import { useFeatureFlag } from "@kraaft/shared/core/modules/featureFlags/featureFlags.hooks";

type EnterRoomNameVariant =
  | "name-with-tip"
  | "name-and-emoji-with-tip"
  | "name-only";

export function useEnterRoomNameVariant(): EnterRoomNameVariant {
  const variant = useFeatureFlag("ENTER_ROOM_NAME_VARIANT");

  if (variant === "name-with-tip") {
    return "name-with-tip";
  }
  if (variant === "name-and-emoji-with-tip") {
    return "name-and-emoji-with-tip";
  }
  if (variant === "name-only") {
    return "name-only";
  }

  return "name-with-tip";
}
