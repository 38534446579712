import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";

import { Api } from "@kraaft/shared/core/services/api";
import { useApiError } from "@kraaft/shared/core/utils/useApi.hooks";
import { useDebounce } from "@kraaft/shared/core/utils/useDebounce";

export function useIsWorkspaceNameTaken(
  name: string,
  defaultName?: string,
  enabled = true,
) {
  const debouncedName = useDebounce(name);

  const { isLoading, data, error } = useQuery(
    ["isWorkspaceNameTaken", debouncedName],
    useCallback(() => Api.isWorkspaceNameTaken(debouncedName), [debouncedName]),
    { enabled },
  );

  const errorMessage = useApiError(error, "serverError");

  const hasChanged = debouncedName !== name;

  return {
    error: errorMessage,
    loading: isLoading || hasChanged,
    isTaken: data && defaultName !== name,
  };
}
