import { useCallback } from "react";

import { MapContext } from "@kraaft/shared/core/services/navigation/navigationParams";
import {
  useNavigationService,
  useRouteService,
} from "@kraaft/web/src/core/services/navigation/useNavigationService";

type Params = {
  context: MapContext;
};

type Result = {
  isMapOpened: boolean;
  toggleMap: () => void;
};

export const useMapRedirection = ({ context }: Params): Result => {
  const navigation = useNavigationService();
  const route = useRouteService();

  const isMapOpened =
    context.location === "Home" ? route.isRoomsMapOpen() : route.isMapOpen();

  const toggleMap = useCallback(() => {
    if (context.location === "Home") {
      if (!isMapOpened) {
        navigation.toggleOnRoomsMap();
      } else {
        navigation.toggleOffRoomsMap();
      }
    } else {
      if (!isMapOpened) {
        navigation.clickOnOpenRoomMap({});
      } else {
        navigation.clickOnCloseRoomMap();
      }
    }
  }, [context, navigation, isMapOpened]);

  return {
    isMapOpened,
    toggleMap,
  };
};
