export enum ModularFolderVisibilityType {
  User = "user",
  Room = "room",
  Pool = "pool",
}

type UserModularFoldersSnapshotReceived = {
  type: ModularFolderVisibilityType.User;
};

type PoolModularFoldersSnapshotReceived = {
  type: ModularFolderVisibilityType.Pool;
  poolId: string | undefined;
};

type RoomModularFoldersSnapshotReceived = {
  type: ModularFolderVisibilityType.Room;
  roomId: string | undefined;
};

export type ModularFolderVisibility =
  | UserModularFoldersSnapshotReceived
  | RoomModularFoldersSnapshotReceived
  | PoolModularFoldersSnapshotReceived;
