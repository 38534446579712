import { View } from "react-native";

import {
  SimpleRoomCard,
  SimpleRoomCardProps,
} from "@kraaft/shared/components/mapController/markers/modularFolderMarker/simpleRoomCardForMaps";

import { styles } from "./roomCardTooltip.styles";

const RoomCardTooltip = (props: SimpleRoomCardProps) => {
  return (
    <View style={styles.card}>
      <SimpleRoomCard {...props} />
    </View>
  );
};

export { RoomCardTooltip };
