import { StyleSheet } from "react-native";

import { Color, ColorStyle } from "@kraaft/ui";

export const styles = StyleSheet.create({
  rootContainer: {
    flexGrow: 1,
    width: "100%",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: Color.WHITE,
  },
  list: {
    flexGrow: 1,
    flexShrink: 1,
  },
  rowRootContainer: {
    width: "100%",
    paddingHorizontal: 10,
  },
  flexRow: {
    paddingVertical: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: ColorStyle.SEPARATOR,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  text: {
    fontSize: 17,
  },
  checkIcon: {
    marginLeft: "auto",
  },
});
