import { NetworkError } from "@kraaft/shared/core/services/firebase/networkError";

/**
 * Wrapper around fetch to throw Kraaft NetworkError and
 * automatically compute NetworkError.elapsedDurationMs property
 */
export async function kfetch(...args: Parameters<typeof fetch>) {
  const start = Date.now();
  try {
    return await fetch(...args);
  } catch (e) {
    const request = args[0];
    const path = typeof request === "string" ? request : request.url;

    throw new NetworkError({
      path,
      message: e.message,
      cause: e,
      elapsedDurationMs: Date.now() - start,
    });
  }
}
