import { useEffect, useState } from "react";

import { Api } from "@kraaft/shared/core/services/api";

export function useSchemaModularFolderCount(schemaId: string, enabled = true) {
  const [count, setCount] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (enabled) {
      setLoaded(false);
      async function fetchCount() {
        const countFetched = await Api.countModularFoldersForSchema({
          schemaId,
        });
        setCount(countFetched.count);
        setLoaded(true);
      }
      fetchCount().catch(console.error);
    }
  }, [enabled, schemaId]);

  return { count, loaded };
}
