import { useCallback, useRef } from "react";
import { GooglePlacesAutocompleteRef } from "react-native-google-places-autocomplete";

import { checkForCoordinates } from "@kraaft/shared/components/placesAutocomplete/placesAutocompleteUtils";
import { GeoLocation } from "@kraaft/shared/core/types";

const SECOND_MS = 1000;

/**
 *
 * @param onSuccess
 * @param onError
 *
 * @returns [ setPlacesAutocompleteValue, onPlacesAutocompleteBlur, setPlacesAutocompleteDefaultValue ]
 */
export const usePlacesAutocompleteValue = (
  placesAutocompleteRef: React.RefObject<GooglePlacesAutocompleteRef>,
  onSuccess: (geolocation: GeoLocation | null) => void,
  onError?: () => void,
): [
  (newTextValue: string, newValue: GeoLocation | null) => void,
  (text: string) => void,
  (text: string) => void,
] => {
  const timer = useRef<number>();
  const lastSetValueDate = useRef(new Date());
  const text = useRef("");

  const setTextValue = useCallback((value: string) => {
    text.current = value;
  }, []);

  const callOnSuccess = (
    textValue: string,
    geolocation: GeoLocation | null,
  ) => {
    setTextValue(textValue);
    onSuccess(geolocation);
  };

  const handleSetValue = (
    newTextValue: string,
    newValue: GeoLocation | null,
  ) => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }

    lastSetValueDate.current = new Date();
    callOnSuccess(newTextValue, newValue);
  };

  function hasJustSetValue() {
    return (
      new Date().getTime() - lastSetValueDate.current.getTime() < SECOND_MS
    );
  }

  const handleBlur = (blurredText: string) => {
    if (!hasJustSetValue()) {
      timer.current = setTimeout(async () => {
        if (!hasJustSetValue()) {
          if (blurredText === text.current) {
            return;
          }

          if (blurredText.length === 0) {
            return callOnSuccess(blurredText, null);
          }

          // Check if input text is coordinates
          const coordinates = checkForCoordinates(blurredText);

          if (coordinates !== undefined) {
            const result: GeoLocation = {
              coords: {
                latitude: coordinates.latitude,
                longitude: coordinates.longitude,
              },
            };

            return callOnSuccess(blurredText, result);
          }

          const place = await placesAutocompleteRef.current?.selectRow(0);
          if (hasJustSetValue()) {
            return;
          }

          if (place?.details !== undefined) {
            const result: GeoLocation = {
              coords: {
                latitude: place.details.geometry.location.lat,
                longitude: place.details.geometry.location.lng,
              },
              address: {
                description: place.data.description,
                googlePlaceId: place.data.place_id,
              },
            };

            return callOnSuccess(blurredText, result);
          }

          onError?.();
        }

        timer.current = undefined;
      }, 300);
    }
  };

  return [handleSetValue, handleBlur, setTextValue];
};
