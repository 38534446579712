import React, { ReactNode, useCallback, useRef } from "react";
import { Tabs as MaterialTabs, TabsActions } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";

import { Color } from "@kraaft/ui";

interface TabBarProps<Identifier extends string = string> {
  value: Identifier;
  onChange: (value: Identifier) => void;
  isFullWidth?: boolean;
  children: ReactNode;
}

const SX = {
  minHeight: 0,
  position: "relative", // position relative + zindex 0 reset the stacking context
  zIndex: 0,
  "& .MuiTabs-indicator": {
    height: "100%",
    backgroundColor: Color.BLUE_LAVENDER,
    borderRadius: 48,
  },
};

const TabBar = <Identifier extends string>(props: TabBarProps<Identifier>) => {
  const { value, onChange, isFullWidth, children } = props;
  const tabsActions = React.useRef<TabsActions>(null);

  const ref = useRef<HTMLDivElement>(null);

  useResizeObserver(ref.current, () => {
    tabsActions.current?.updateIndicator();
  });

  const handleChange = useCallback(
    (_e: React.ChangeEvent<unknown>, newValue: Identifier) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <MaterialTabs
      action={tabsActions}
      ref={ref}
      value={value}
      onChange={handleChange}
      variant={isFullWidth ? "fullWidth" : "standard"}
      sx={SX}
    >
      {children}
    </MaterialTabs>
  );
};

export { TabBar };
