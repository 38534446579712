import { useCallback, useState } from "react";

import {
  DirectoryListCard,
  DirectoryListCardProps,
} from "@kraaft/shared/components/actionCardList/directoryList/directoryListCard";
import { useDirectoryDrop } from "@kraaft/shared/components/directory/useDirectoryDrop";
import { DIRECTORY_DROP_ACCEPT_TYPE } from "@kraaft/shared/components/directory/useDirectoryDrop/types";
import { DragNavigator } from "@kraaft/shared/components/dragNavigator";
import {
  Droppable,
  DroppableChildrenRenderer,
} from "@kraaft/shared/components/droppable";
import { DroppableContainer } from "@kraaft/shared/components/droppable/droppableContainer";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";

type DroppableDirectoryListCardProps = DirectoryListCardProps;

const DroppableDirectoryListCard = (props: DroppableDirectoryListCardProps) => {
  const { directory } = props;

  const navigationService = useNavigationService();

  const [isLoading, setLoading] = useState(false);

  const { onDrop, onDropFiles } = useDirectoryDrop({
    directory,
    onLoading: setLoading,
  });

  const handleDragNavigatorTriggered = useCallback(() => {
    navigationService.navigate("RoomDirectory", {
      roomId: directory.roomId,
      directoryId: directory.id,
    });
  }, [directory.id, directory.roomId, navigationService]);

  const renderDroppableContent = useCallback<DroppableChildrenRenderer>(
    ({ isActive }) => {
      return (
        <>
          <DragNavigator onTriggered={handleDragNavigatorTriggered}>
            <DirectoryListCard
              directory={props.directory}
              onPress={props.onPress}
              disabled={props.disabled}
              loading={isLoading}
              roomId={props.roomId}
            />
            {isActive && <DroppableContainer isActive />}
          </DragNavigator>
        </>
      );
    },
    [
      handleDragNavigatorTriggered,
      isLoading,
      props.directory,
      props.disabled,
      props.onPress,
      props.roomId,
    ],
  );

  return (
    <Droppable
      id={`ide2e-ticket-${directory.name}-drop`}
      onDrop={onDrop}
      onDropFiles={onDropFiles}
      acceptType={DIRECTORY_DROP_ACCEPT_TYPE}
      acceptSystemFile
      style={overrideDroppableStyle}
    >
      {renderDroppableContent}
    </Droppable>
  );
};

const overrideDroppableStyle = { height: "auto" };

export { DroppableDirectoryListCard };
