import {
  firestore,
  FirestoreTypes,
} from "@kraaft/shared/core/services/firestore/sdk";

export class FirestoreUtils {
  static getFirestoreCollection<T extends FirestoreTypes.DocumentData>(
    collectionPath: string,
  ) {
    return firestore().collection(
      collectionPath,
    ) as FirestoreTypes.CollectionReference<T>;
  }
}
