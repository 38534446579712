import { useMemo } from "react";

import { RoomMarker } from "@kraaft/shared/components/mapController/markers/roomMarker/roomMarker";
import { MapMarker } from "@kraaft/shared/components/mapController/types";
import {
  AnyRoomCard,
  RoomCardWithGeolocation,
} from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { RoomCardUtils } from "@kraaft/shared/core/modules/roomCard/roomCard.utils";

interface UseRoomsMarkersProps {
  roomCards: AnyRoomCard[];
  onMarkerAction: (id: string) => void;
}

export const useRoomsMarkers = ({
  roomCards,
  onMarkerAction,
}: UseRoomsMarkersProps) => {
  const roomCardsWithCoords = useMemo(() => {
    const geolocatedRoomCards =
      RoomCardUtils.filterNonGeolocatedRoomCards(roomCards);

    return RoomCardUtils.formatGeolocatedRoomCards(geolocatedRoomCards);
  }, [roomCards]);

  return useMemo(() => {
    return roomCardsWithCoords.map<MapMarker<RoomCardWithGeolocation, "room">>(
      (geolocatedElement) => ({
        key: geolocatedElement.id,
        element: geolocatedElement,
        clusterAffiliation: "room",
        renderType: "small",

        Marker: (
          <RoomMarker
            onMarkerAction={onMarkerAction}
            geolocatedElement={geolocatedElement}
          />
        ),
      }),
    );
  }, [roomCardsWithCoords, onMarkerAction]);
};
