import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";

import { Button, Image } from "@kraaft/ui";

import CrossImage from "./assets/cross.png";

import { styles } from "./invalidInvitation.styles";

interface Props {
  onClose?: () => void;
}

const InvalidInvitation = ({ onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <View style={styles.imageContainer}>
          <Image source={CrossImage} style={styles.image} />
        </View>
        <Text nativeID="ide2e-invalid-title" style={styles.text}>
          {t("verifyLinkInvalid")}
        </Text>
        <Text style={[styles.text, styles.last]}>
          {t("verifyLinkInvalidText")}
        </Text>
        {onClose && (
          <Button
            accessibilityLabel={t("verifyLinkInvalidClose")}
            text={t("verifyLinkInvalidClose")}
            onPress={onClose}
          />
        )}
      </View>
    </View>
  );
};

export { InvalidInvitation };
