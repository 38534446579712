import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { CompanyDropdown } from "@kraaft/shared/core/modules/company/components/companyDropdown";
import { ManageCompaniesSheet } from "@kraaft/shared/core/modules/company/components/manageCompanies.sheet";
import { Button, Sheet } from "@kraaft/ui";

interface AssignOrManageCompanyProps {
  value: string | undefined;
  onChange: (companyId: string | undefined) => void;
}

export const AssignOrManageCompanySheet = Sheet({
  web: "popup",
})
  .create<AssignOrManageCompanyProps>(
    ({ Content, Footer, Header, Paper }) =>
      ({ onClose, value, onChange }) => {
        const { t } = useTranslation();
        const [tempValue, setTempValue] = useState<string | undefined>(
          undefined,
        );

        const handleSubmit = useCallback(() => {
          if (tempValue !== value) {
            onChange(tempValue);
          }
          onClose();
        }, [onChange, onClose, tempValue, value]);

        const { open, element } = ManageCompaniesSheet.use({});

        return (
          <>
            <Paper>
              <Header onClose={onClose}>{t("support.company")}</Header>
              <Content>
                <CompanyDropdown
                  value={tempValue}
                  onChange={setTempValue}
                  placeholder={t("unassigned")}
                />
              </Content>
              <Footer>
                <Button
                  text={t("support.manageCompanies")}
                  onPress={open}
                  variant="QUATERNARY"
                />
                <Button text={t("save")} onPress={handleSubmit} />
              </Footer>
            </Paper>
            {element}
          </>
        );
      },
  )
  .withDefaults({ size: "SMALL" });
