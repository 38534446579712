import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: Spacing.S24,
    overflowY: "auto",
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 700,
  },
  rowContainer: {
    padding: `${Spacing.S12 / 2}px 0`,
  },
});
