import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DialogProvider } from "@kraaft/shared/components/kDialog/context";
import { useHasLibrarySchemas } from "@kraaft/shared/core/modules/librarySchema/librarySchema.hooks";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { AddSchemaButton } from "@kraaft/web/src/views/schemasView/addSchema/addSchemaButton";
import { AddSchemaFromJsonButton } from "@kraaft/web/src/views/schemasView/addSchemaFromJson/addSchemaFromJsonButton";
import { PoolSchemaList } from "@kraaft/web/src/views/schemasView/poolSchemaList";

import { AddSchemaFromLibraryActionCard } from "./addSchema/addSchemaFromLibraryActionCard";
import { AddSchemaFromPaperActionCard } from "./addSchema/addSchemaFromPaperActionCard";
import { AddSchemaFromScratchActionCard } from "./addSchema/addSchemaFromScratchActionCard";

import { useStyles } from "./schemasView.styles";

export const SchemasView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isSuperadmin = useSelector(selectCurrentUserIsSuperadmin);

  const hasLibrarySchemas = useHasLibrarySchemas();

  return (
    <DialogProvider>
      <div className={classes.root}>
        <div className={classes.header}>
          <PageHeader
            title={t("schemasView.title")}
            titleElementId="settings-schemas-view"
            right={
              <>
                <AddSchemaButton />
                {__DEV__ && isSuperadmin && <AddSchemaFromJsonButton />}
              </>
            }
          />
        </div>
        <div className={classes.cardsContainer}>
          <div className={classes.card}>
            <AddSchemaFromScratchActionCard />
          </div>
          {hasLibrarySchemas && (
            <div className={classes.card}>
              <AddSchemaFromLibraryActionCard />
            </div>
          )}
          <div className={classes.card}>
            <AddSchemaFromPaperActionCard />
          </div>
        </div>
        <PoolSchemaList />
      </div>
    </DialogProvider>
  );
};
