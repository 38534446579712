import { MobileWebCongratsPanel } from "@kraaft/shared/components/auth/congratulations/mobileWebCongratsPanel";
import { useSignoutAlert } from "@kraaft/shared/core/modules/user/useSignoutAlert";
import { Box } from "@kraaft/web/src/components/box";
import { SplitScreenBackButton } from "@kraaft/web/src/components/splitScreenWrapper/splitScreenBackButton";

import { SplitScreenWrapper } from "../../components/splitScreenWrapper";

const MobileWebCongratsScreenWeb = () => {
  const logout = useSignoutAlert();

  return (
    <SplitScreenWrapper>
      <Box mb="S16">
        <SplitScreenBackButton onClick={logout} icon="log-out-02" />
      </Box>
      <MobileWebCongratsPanel />
    </SplitScreenWrapper>
  );
};

export { MobileWebCongratsScreenWeb };
