import { MouseEventHandler, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { KSchemaColors } from "@kraaft/shared/core/modules/schema/schema.colors";
import { Button, Text } from "@kraaft/ui";
import { SectionIcon } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementIcon/sectionIcon";

import { useStyles } from "./elementPickerSectionItem.styles";

const ADD_COLUMN_BUTTON = "column-picker-add-column";

interface ColumnPickerItemProps {
  onAdd: () => void;
}

const ElementPickerSectionItem = ({ onAdd }: ColumnPickerItemProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();
      onAdd();
    },
    [onAdd],
  );

  return (
    <div
      className={styles.root}
      onClick={handleClick}
      role="button"
      tabIndex={0}
    >
      <div className={styles.iconText}>
        <SectionIcon color={KSchemaColors.DefaultSectionColor} />
        <Text color="FONT_HIGH_EMPHASIS" size="BODY">
          {t("formBuilder.schema.section")}
        </Text>
      </div>
      <Button
        accessibilityLabel={t("add")}
        icon="plus"
        variant="QUATERNARY"
        size="SMALL"
        id={ADD_COLUMN_BUTTON}
        testID="add-section"
      />
    </div>
  );
};

export { ElementPickerSectionItem };
