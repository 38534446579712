import { StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: !isNative() ? Spacing.S32 : Spacing.S16,
    marginBottom: Spacing.S8,
  },
  content: {
    paddingBottom: Spacing.S16,
    borderColor: ColorStyle.SEPARATOR,
    borderBottomWidth: 1,
    gap: Spacing.S16,
  },
  textContainer: {
    alignItems: "center",
  },
  text: {
    marginTop: Spacing.S8,
    textAlign: "center",
  },
});
