import { DialogContentText, DialogTitle } from "@mui/material";

import { useStyles } from "@kraaft/shared/components/kDialog/kDialogContent/kDialogContent.styles";
import { KDialogProps } from "@kraaft/web/src/components/kDialog/KDialogContent.types";

const KDialogTitle = ({ title, subtitle, fullScreen }: KDialogProps) => {
  const classes = useStyles({ fullScreen });

  if (fullScreen) {
    return (
      <>
        <div className={classes.fullscreenHeader}>
          <span className={classes.fullscreenHeaderTitle}>{title}</span>
        </div>
        <div className={classes.fullscreenHeaderSeparator} />
      </>
    );
  }
  return (
    <>
      <div className={classes.titleBlockContainer}>
        <DialogTitle classes={{ root: classes.title }}>{title}</DialogTitle>
      </div>
      {subtitle && (
        <DialogContentText color="textPrimary" className={classes.subtitle}>
          {subtitle}
        </DialogContentText>
      )}
    </>
  );
};

export { KDialogTitle };
