import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MapMarkerProps } from "@kraaft/shared/components/mapController/markers/marker/marker.types";
import {
  geolocationContextHover,
  geolocationContextSelect,
} from "@kraaft/shared/core/modules/room/roomActions";
import { selectGeolocationContext } from "@kraaft/shared/core/modules/room/roomSelectors";

import { useStyles } from "./marker.styles";

const Marker = (props: MapMarkerProps) => {
  const { id, onMarkerAction, renderContent, preventSelection, size, testID } =
    props;
  const geolocationContext = useSelector(selectGeolocationContext);
  const isHovered = geolocationContext.hover?.id === id;
  const isSelected = geolocationContext.select?.id === id;
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    if (!preventSelection) {
      dispatch(
        geolocationContextSelect({
          selected: true,
          id,
        }),
      );
    }
    onMarkerAction?.();
  }, [preventSelection, onMarkerAction, dispatch, id]);

  const setHover = useCallback(
    (hover: boolean) => {
      dispatch(
        geolocationContextHover({
          hovered: hover,
          id: id,
        }),
      );
    },
    [dispatch, id],
  );

  const setHoverTrue = useCallback(() => setHover(true), [setHover]);
  const setHoverFalse = useCallback(() => setHover(false), [setHover]);

  return (
    <div
      data-testid={testID}
      className={classes.marker}
      style={{
        ...size,
        zIndex: isSelected ? 2 : isHovered ? 1 : 0,
      }}
      onClick={onClick}
      onMouseEnter={setHoverTrue}
      onMouseLeave={setHoverFalse}
    >
      {renderContent?.({ isHovered, isSelected })}
    </div>
  );
};

export { Marker };
