import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { omitBy } from "lodash";

import { useMeshContext } from "@kraaft/helper-hooks";
import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { useDialog } from "@kraaft/shared/components/kDialog/context";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaElement } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { FilterMenuContent } from "@kraaft/web/src/components/filterMenu/filterMenuContent";
import {
  DEFAULT_FILTER,
  useFilterState,
} from "@kraaft/web/src/components/filterMenu/useFilterState";
import {
  SchemaBuilderContext,
  useSchemaBuilderRootSection,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

export function useAddElementCondition(element: KSchemaElement) {
  const { openDialog, closeDialog } = useDialog();

  return () => {
    openDialog(
      <AddElementConditionDialogContent
        element={element}
        onClose={closeDialog}
      />,
    );
  };
}

interface Props {
  element: KSchemaElement;
  onClose: () => void;
}

const AddElementConditionDialogContent = (props: Props) => {
  const { element, onClose: close } = props;
  const { editCondition } = useMeshContext(SchemaBuilderContext);
  const rootSection = useSchemaBuilderRootSection();

  const { t } = useTranslation();
  const [filters, setFilters] = useFilterState(undefined);

  useEffect(() => {
    setFilters(element.condition ? element.condition : DEFAULT_FILTER);
  }, [element.condition, setFilters]);

  const columns = useMemo(() => {
    // Cant execute condition on itself or children
    const forbiddenColumns =
      element.elementType === "column"
        ? [element.key]
        : [
            element.key,
            ...KSchemaUtils.flattenSection(element).map((e) => e.key),
          ];
    return omitBy(
      KSchemaUtils.flattenColumnsWithPath(rootSection),
      ({ column }) => forbiddenColumns.includes(column.key),
    );
  }, [element, rootSection]);

  const onAddCondition = useCallback(() => {
    const finalFilters = {
      ...filters,
      conditions: filters.conditions.filter((c) => c.type !== "draft"),
    };
    if (finalFilters.conditions.length === 0) {
      editCondition(element.key, undefined);
    } else {
      editCondition(element.key, finalFilters);
    }
    close();
  }, [close, editCondition, element.key, filters]);

  const footerActions = useMemo<FooterActions>(
    () => [
      {
        accessibilityLabel: t("confirm"),
        text: t("confirm"),
        onPress: onAddCondition,
      },
      { accessibilityLabel: t("cancel"), text: t("cancel"), onPress: close },
    ],
    [close, onAddCondition, t],
  );

  return (
    <KDialogContent
      title={t("formBuilder.schema.condition.dialogTitle", {
        elementName: element.name,
      })}
      actions={footerActions}
      content={
        <Box grow>
          <Box grow mb="S16">
            <Text size="MEDIUM">
              {t("formBuilder.schema.condition.dialogSubtitle", {
                elementName: element.name,
              })}
            </Text>
          </Box>
          <FilterMenuContent
            columns={columns}
            filters={filters}
            setFilters={setFilters}
            disabledColumnTypes={[KColumnType.roomName]}
          />
        </Box>
      }
    />
  );
};
