import { EventChannel, eventChannel } from "redux-saga";
import { all, call, put, select, takeEvery } from "typed-redux-saga/macro";

import {
  MiniMediaActions,
  MiniMediaStateActions,
} from "@kraaft/shared/core/modules/miniMedia/miniMedia.actions";
import { MiniMedia } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { MiniMediaLimitedResult } from "@kraaft/shared/core/modules/miniMedia/miniMedia.types";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { getFreemiumLimitDate } from "@kraaft/shared/core/utils";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

type MiniMediaEventChannelPayload = {
  roomId: string;
  medias: MiniMedia[];
  limitDate: Date | undefined;
};

function createMiniMediaChannel({
  roomId,
  limitDate,
}: {
  roomId: string;
  limitDate?: Date;
}): EventChannel<MiniMediaEventChannelPayload> {
  return eventChannel((emit) =>
    Firestore.subscribeToMiniMedias(roomId, limitDate, (medias) =>
      emit({ roomId, medias, limitDate }),
    ),
  );
}

function* handleReceiveMiniMedia(
  { roomId, medias }: MiniMediaEventChannelPayload,
  isLimitedResult: MiniMediaLimitedResult,
) {
  yield* put(
    MiniMediaStateActions.set({
      roomId,
      medias,
      isLimitedResult,
    }),
  );
}

const miniMediaTypeThatCanHaveMore = ["image", "document"] as const;

function* subscribe(
  registerMeta: (meta: EventChannel<MiniMediaEventChannelPayload>) => void,
  { payload }: ReturnType<typeof MiniMediaActions.subscribeToMiniMedias>,
) {
  const { roomId, limitForFreemium } = payload;
  const userId = yield* select(selectCurrentUserId);

  const limitDate = limitForFreemium ? getFreemiumLimitDate() : undefined;

  if (!userId) {
    return;
  }

  const isLimitedResult = (yield* all(
    miniMediaTypeThatCanHaveMore.reduce(
      (obj, miniMediaType) =>
        Object.assign(obj, {
          [miniMediaType]: limitDate
            ? call(
                Firestore.hasMoreMiniMediasOfType,
                roomId,
                miniMediaType,
                limitDate,
              )
            : false,
        }),
      { image: false, document: false, video: false },
    ),
  )) as MiniMediaLimitedResult;

  const channel = yield* call(createMiniMediaChannel, { roomId, limitDate });
  registerMeta(channel);

  yield* takeEvery(channel, (channelPayload: MiniMediaEventChannelPayload) =>
    handleReceiveMiniMedia(channelPayload, isLimitedResult),
  );
}

function* unsubscribe(
  meta: EventChannel<MiniMediaEventChannelPayload> | undefined,
  _: ReturnType<typeof MiniMediaActions.unsubscribeFromMiniMedias>,
) {
  if (meta) {
    meta.close();
  }
}

export function* subscribeToMiniMediasSaga() {
  yield takeCountedDeep(
    MiniMediaActions.subscribeToMiniMedias,
    MiniMediaActions.unsubscribeFromMiniMedias,
    subscribe,
    unsubscribe,
    (action) =>
      `miniMedia-${action.payload.roomId}-${action.payload.limitForFreemium}`,
  );
}
