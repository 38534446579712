import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { AlertDialog } from "@kraaft/shared/components/alertDialog";
import {
  PrimaryAlertButton,
  SecondaryAlertButton,
} from "@kraaft/shared/components/alertDialog/alertDialog.types";
import { isRoomPrivate } from "@kraaft/shared/components/roomCard/roomCardUtils";
import { selectCurrentUserAtLeastStandard } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { updateRoomVisibility } from "@kraaft/shared/core/modules/room/roomActions";
import { Room, RoomType } from "@kraaft/shared/core/modules/room/roomState";
import {
  selectIsCurrentUserRoomMember,
  selectRoomIsExternal,
} from "@kraaft/shared/core/modules/room/selectors";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { useEnsureOnline } from "@kraaft/shared/core/utils/useNetwork";
import { Button } from "@kraaft/ui";

interface VisibilitySwitchProps {
  room: Room;
  setDisplayed?: () => void;
}

const VisibilitySwitch_ = ({ room, setDisplayed }: VisibilitySwitchProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isPrivate, setIsPrivate] = useState(false);
  const isExternal = useSelector(selectRoomIsExternal(room.id));
  const isCurrentUserRoomMember = useSelector(
    selectIsCurrentUserRoomMember(room.id),
  );
  const isAtLeastStandard = useSelector(
    selectCurrentUserAtLeastStandard(room.poolId),
  );

  const isSuperadmin = useSelector(selectCurrentUserIsSuperadmin);

  const showVisibilitySwitch =
    room.type !== RoomType.EVERYONE &&
    (isSuperadmin || (isCurrentUserRoomMember && isAtLeastStandard));

  useEffect(() => {
    if (showVisibilitySwitch) {
      setDisplayed?.();
    }
  }, [setDisplayed, showVisibilitySwitch]);

  useEffect(() => {
    if (room) {
      setIsPrivate(isRoomPrivate(room));
    }
  }, [room]);

  const handleToggleVisibility = useEnsureOnline(() => {
    const currentVisibility = room?.visibility;
    const nextVisibility =
      currentVisibility !== "private"
        ? "private"
        : isExternal
          ? "administrator"
          : "pool";
    const toggleRoomVisibility = () => {
      dispatch(
        updateRoomVisibility({
          roomId: room.id,
          visibility: nextVisibility,
        }),
      );
    };

    const alertTitle = isPrivate
      ? t("turnTicketPublicAlertTitle")
      : t("turnTicketPrivateAlertTitle");
    const alertMessage = isPrivate
      ? t("turnTicketPublicAlertMessage")
      : t("turnTicketPrivateAlertMessage");

    const confirmButton: PrimaryAlertButton = {
      text: t("confirm"),
      style: "default",
      onPress: toggleRoomVisibility,
    };

    const cancelButton: SecondaryAlertButton = {
      text: t("cancel"),
      style: "cancel",
    };

    AlertDialog.alert(alertTitle, alertMessage, [confirmButton, cancelButton]);
  }, [dispatch, isExternal, isPrivate, room, t]);

  const text = t(isPrivate ? "makePublic" : "makePrivate");

  return showVisibilitySwitch ? (
    <Button
      id="room-details-visibility-switch"
      variant="QUATERNARY"
      size="SMALL"
      alignContent="left"
      accessibilityLabel={text}
      text={text}
      icon={isPrivate ? "lock-unlocked-01" : "lock-01"}
      onPress={handleToggleVisibility}
    />
  ) : null;
};

export const VisibilitySwitch = React.memo(VisibilitySwitch_);
