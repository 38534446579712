import { useMemo } from "react";
import { makeStyles } from "@mui/styles";

import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Spacing } from "@kraaft/ui";
import { SchemaLibraryThumbnail } from "@kraaft/web/src/views/schemaLibrary/schemaLibraryGrid/schemaLibraryThumbnail";

interface SchemaLibraryGridProps {
  onSelectSchema: (librarySchemaId: string) => void;
  librarySchemas: LibrarySchema[];
}

const SchemaLibraryGrid = ({
  onSelectSchema,
  librarySchemas,
}: SchemaLibraryGridProps) => {
  const classes = useStyles();

  const thumbnails = useMemo(
    () =>
      librarySchemas.map((librarySchema) => (
        <SchemaLibraryThumbnail
          key={librarySchema.id}
          librarySchema={librarySchema}
          onSelectSchema={onSelectSchema}
        />
      )),
    [librarySchemas, onSelectSchema],
  );

  return <div className={classes.container}>{thumbnails}</div>;
};

const useStyles = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, 300px)",
    gridColumnGap: Spacing.S24,
    gridRowGap: Spacing.S24,
    paddingBottom: Spacing.S24,
  },
});

export { SchemaLibraryGrid };
