import { spawn } from "typed-redux-saga/macro";

import { RoomSchemaVisibilityOptimisticSaga } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.optimistic";
import { subscribeToRoomSchemaVisibilitySaga } from "@kraaft/shared/core/modules/roomSchemaVisibility/sagas/subscribeToRoomSchemaVisibility";

export function* roomSchemaVisibilitySagas() {
  yield* spawn(subscribeToRoomSchemaVisibilitySaga);

  yield* spawn(RoomSchemaVisibilityOptimisticSaga);
}
