import { type ResourceKey } from "@kraaft/shared/core/services/i18next";
import { ErrorType } from "@kraaft/web/src/core/types/dropzoneFile";

export const getFileDropzoneErrorMessage = (
  invalidFileErrorKey: ResourceKey | undefined,
  errorType: ErrorType,
): ResourceKey => {
  switch (errorType) {
    case "FileInvalidType":
      return invalidFileErrorKey ?? "importFileErrorFileInvalidType";
    case "FileTooLarge":
      return "importFileErrorFileTooLarge";
    case "TooManyFiles":
      return "importFileErrorTooManyFiles";
    default:
      return "importFileErrorUnknown";
  }
};
