import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { MapOverlayOptimisticSelector } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.optimistic";
import { MapOverlay } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.state";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { RootState } from "@kraaft/shared/core/store";

export const selectState = ({ mapOverlay: { data } }: RootState) => data;
export const selectOptimisticState = ({
  mapOverlay: { optimistic },
}: RootState) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optimistic as any;

export const selectMapOverlays = createSelector(
  selectState,
  (state) => state.overlays,
);

const EMPTY_ARRAY: MapOverlay[] = [];

export const selectMapOverlaysByPoolIdOptimictic = memoize(
  (poolId: string | undefined) =>
    createSelector(
      MapOverlayOptimisticSelector.selectAllBuilt,
      (mapOverlays) =>
        poolId !== undefined
          ? Object.values(mapOverlays).filter((t) => t.poolId === poolId)
          : EMPTY_ARRAY,
    ),
);

export const selectMapOverlaysByPoolId = memoize((poolId: string | undefined) =>
  createSelector(selectMapOverlays, (mapOverlays) =>
    poolId !== undefined
      ? Object.values(mapOverlays).filter((t) => t.poolId === poolId)
      : EMPTY_ARRAY,
  ),
);

const EMPTY_ID_ARRAY: string[] = [];

export const selectEnabledMapOverlayIds = memoize(
  (poolId: string | undefined) =>
    createSelector(selectState, (state) =>
      poolId !== undefined
        ? state.selectedOverlays[poolId] ?? EMPTY_ID_ARRAY
        : EMPTY_ID_ARRAY,
    ),
);

export const selectEnabledMapOverlays = memoize((poolId: string | undefined) =>
  createSelector(
    selectMapOverlaysByPoolId(poolId),
    selectEnabledMapOverlayIds(poolId),
    (mapOverlays, enabledMapOverlayIds) =>
      poolId !== undefined
        ? mapOverlays.filter((mapOverlay) =>
            enabledMapOverlayIds.includes(mapOverlay.id),
          )
        : EMPTY_ARRAY,
  ),
);

export const selectMapOverlay = memoize((mapOverlayId: string | undefined) =>
  createSelector(selectMapOverlays, (mapOverlays) =>
    mapOverlayId !== undefined ? mapOverlays[mapOverlayId] : undefined,
  ),
);

export const selectCurrentPoolHasMapOverlays = createSelector(
  selectCurrentPoolId,
  selectMapOverlays,
  (currentPoolId, mapOverlays) =>
    currentPoolId !== undefined
      ? Object.values(mapOverlays).some((t) => t.poolId === currentPoolId)
      : false,
);
