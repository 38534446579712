import { StyleProp, View, ViewStyle } from "react-native";

import { PermissionRequester } from "@kraaft/shared/components/permissionRequester/permissionRequester";
import { PermissionRequest } from "@kraaft/shared/components/permissionRequester/permissionRequester.types";
import { usePermissionStatusOf } from "@kraaft/shared/components/permissionRequester/usePermissionStatusOf";
import { Permissions } from "@kraaft/shared/core/services/permission/permissions.types";
import { Props } from "@kraaft/shared/core/types";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Preloader } from "@kraaft/ui";

export function withPermissionRequester<P extends Props>(
  Component: React.ComponentType<P>,
  type: PermissionRequest["type"],
  trackingFrom: PermissionRequest["trackingFrom"],
  containerStyle?: StyleProp<ViewStyle>,
): (props: P) => JSX.Element {
  return (props: P) => {
    const status = usePermissionStatusOf(type);

    if (status === "unavailable") {
      return <Preloader absoluteFill transparent />;
    }
    if (!Permissions.convertStatusToBoolean(status)) {
      trackEvent({
        eventName: "Permission Explainer Displayed",
        type,
        current_status: status,
        from: trackingFrom,
      });
      return (
        <View style={containerStyle}>
          <PermissionRequester type={type} />
        </View>
      );
    }
    return <Component {...props} />;
  };
}
