import { createReducer } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

import { SchemaTemplateState } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";
import { applyRenderUpdatesToSchemaTemplate } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateUtils";

import * as actions from "./schemaTemplateActions";

const initialState: SchemaTemplateState = {
  poolToSchemaTemplates: {},
  schemaTemplates: {},
};

export const schemaTemplateReducers = createReducer(
  initialState,
  ({ addCase }) => {
    addCase(
      actions.addSchemaTemplate,
      (state, { payload: { poolId, schemaTemplate } }) => {
        let schemaInPool = state.poolToSchemaTemplates[poolId] || [];
        if (!schemaInPool) {
          schemaInPool = [];
          state.poolToSchemaTemplates[poolId] = schemaInPool;
        }
        schemaInPool.push(schemaTemplate.id);
        state.schemaTemplates[schemaTemplate.id] = schemaTemplate;
      },
    );

    addCase(
      actions.updatePoolSchemaTemplates,
      (state, { payload: { poolId, schemaTemplates } }) => {
        const existingPoolSchemas = state.poolToSchemaTemplates[poolId];
        if (existingPoolSchemas) {
          const deletedSchemasIds = schemaTemplates.filter(
            ({ id }) => !existingPoolSchemas.includes(id),
          );
          for (const { id } of deletedSchemasIds) {
            delete state.schemaTemplates[id];
          }
        }
        state.poolToSchemaTemplates[poolId] = schemaTemplates.map(
          ({ id }) => id,
        );
        for (const schema of schemaTemplates) {
          state.schemaTemplates[schema.id] = schema;
        }
      },
    );

    addCase(
      actions.createSchemaTemplate,
      (state, { payload: { name, schemaId, poolId } }) => {
        state.edit = { poolId, name, schemaId, modifiers: [] };
      },
    );

    addCase(
      actions.deleteSchemaTemplate,
      (state, { payload: { schemaTemplateId } }) => {
        delete state.schemaTemplates[schemaTemplateId];
        state.poolToSchemaTemplates[schemaTemplateId] =
          state.poolToSchemaTemplates[schemaTemplateId]?.filter(
            (id) => id !== schemaTemplateId,
          ) || [];
      },
    );

    addCase(
      actions.editSchemaTemplate,
      (state, { payload: { modifiers, name } }) => {
        if (!state.edit) {
          return;
        }
        if (modifiers !== undefined) {
          state.edit.modifiers = modifiers;
        }
        if (name !== undefined) {
          state.edit.name = name;
        }
      },
    );

    addCase(
      actions.editSchemaTemplateModifier,
      (state, { payload: { index, modifier } }) => {
        if (!state.edit) {
          return;
        }
        state.edit.modifiers[index] = modifier;
      },
    );

    addCase(
      actions.editSchemaTemplateModifierIndex,
      (state, { payload: { from, to } }) => {
        if (!state.edit) {
          return;
        }
        const tmp = state.edit.modifiers[from];
        const toObj = state.edit.modifiers[to];
        if (!tmp || !toObj) {
          return;
        }
        state.edit.modifiers[from] = toObj;
        state.edit.modifiers[to] = tmp;
      },
    );

    addCase(actions.saveEditSchemaTemplate, (state) => {
      if (!state.edit) {
        return;
      }
      const schemaTemplateId = state.edit.id;
      // We are saving only existing schema. New schemas will be added once the server has given us an id
      if (!schemaTemplateId || !state.schemaTemplates[schemaTemplateId]) {
        return;
      }
      state.schemaTemplates[schemaTemplateId] = {
        ...state.edit,
        id: schemaTemplateId,
      };
    });

    addCase(
      actions.startEditExistingSchemaTemplate,
      (state, { payload: { schemaTemplateId } }) => {
        if (!state.schemaTemplates[schemaTemplateId]) {
          return;
        }
        state.edit = cloneDeep(state.schemaTemplates[schemaTemplateId]);
      },
    );

    addCase(
      actions.setSchemaTemplateRender,
      (state, { payload: { schemaTemplateId, rendered } }) => {
        const schemaTemplate = state.schemaTemplates[schemaTemplateId];
        if (!schemaTemplate) {
          return;
        }
        if (state.edit?.id === schemaTemplate.id) {
          state.edit.rendered = rendered;
        }
        schemaTemplate.rendered = rendered;
      },
    );

    addCase(
      actions.applyRenderUpdatesToSchemaTemplate,
      (state, { payload: { schemaTemplateId } }) => {
        const schemaTemplate = state.schemaTemplates[schemaTemplateId];
        if (!schemaTemplate) {
          return;
        }
        const rendered = schemaTemplate.rendered;
        if (!rendered) {
          return;
        }
        applyRenderUpdatesToSchemaTemplate(rendered, schemaTemplate);
      },
    );
  },
);
