import { useTranslation } from "react-i18next";

import { CheckboxGuardSheet } from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuardSheet";
import { Text } from "@kraaft/ui";

interface CantCheckUncheckParentLockedProps {
  onClose: () => void;
  variant: "cantCheckParentLocked" | "cantUncheckParentLocked";
}

export const CantCheckUncheckParentLocked = ({
  onClose,
  variant,
}: CantCheckUncheckParentLockedProps) => {
  const { t } = useTranslation();

  const title = t(variant === "cantCheckParentLocked" ? "check" : "uncheck");

  const text = t(
    `modularRecords.checkboxGuard.${
      variant === "cantCheckParentLocked"
        ? "cantCheckParentSectionLocked"
        : "cantUncheckParentSectionLocked"
    }`,
  );

  return (
    <CheckboxGuardSheet.Paper>
      <CheckboxGuardSheet.Header onClose={onClose}>
        {title}
      </CheckboxGuardSheet.Header>
      <CheckboxGuardSheet.Content>
        <Text size="BODY">{text}</Text>
      </CheckboxGuardSheet.Content>
    </CheckboxGuardSheet.Paper>
  );
};
