import { useCallback, useState } from "react";

export function useBooleanState(
  defaultValue = false,
): [boolean, () => void, () => void] {
  const [open, setOpen] = useState(defaultValue);

  const handleOpen = useCallback(async () => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return [open, handleOpen, handleClose];
}
