import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectFolderSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { ScreenParams } from "@kraaft/shared/core/services/navigation/navigationParams";

import { ConnectedModularFoldersViewer } from "./modularFoldersViewer.connected";

const ModularFoldersViewer = () => {
  const { schemaId } =
    useParams<
      ScreenParams<"ModularFoldersTable" | "ModularFoldersTableForRoom">
    >();

  const schema = useSelector(selectFolderSchema(schemaId));

  if (!schema) {
    return null;
  }

  return <ConnectedModularFoldersViewer schema={schema} />;
};

export { ModularFoldersViewer };
