/**
 * when you add a language, make sure to download the matching file for emoji keyword internationalization
 * https://github.com/Armaldio/localize-emoji-db/tree/master/dist/emojis
 * then run
 * yarn reformat:emojis ./shared/components/roomCard/emojiSelector/emojiFinder/data/[lang-LANG].json
 */

import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";

import emojis_de from "./data/de.json";
import emojis_en from "./data/en.json";
import emojis_es from "./data/es.json";
import emojis_fr from "./data/fr.json";
import emojis_it from "./data/it.json";

type LocalizedEmojiKeywords = Record<string, string[]>;

type ErrorMessage<T> = T extends LocalizedEmojiKeywords
  ? T
  : "The emoji file reference is not formatted, please use `yarn reformat:emojis`";

const fileMatchRequiredFormat = <MT extends ErrorMessage<T>, T extends {} = MT>(
  fileContent: MT,
) => fileContent;

export const emojiListForLanguage = {
  "en-GB": fileMatchRequiredFormat(emojis_en),
  "en-US": fileMatchRequiredFormat(emojis_en),
  "fr-FR": fileMatchRequiredFormat(emojis_fr),
  "it-IT": fileMatchRequiredFormat(emojis_it),
  "de-DE": fileMatchRequiredFormat(emojis_de),
  "es-ES": fileMatchRequiredFormat(emojis_es),
} as const satisfies { [key in PoolLanguage]: LocalizedEmojiKeywords };

export function getLocalizedKeywordsForEmoji(
  emoji: string,
  language: PoolLanguage,
) {
  const emojiList: LocalizedEmojiKeywords = emojiListForLanguage[language];

  return emojiList[emoji] ?? [];
}
