import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import { updateInviteState } from "@kraaft/shared/core/modules/app/appActions";
import { selectInviteState } from "@kraaft/shared/core/modules/app/appSelector";
import { Preloader } from "@kraaft/ui";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";
import { routes } from "@kraaft/web/src/views/app/appRouter/routes";

const VerifyInvitationScreenWeb = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const inviteState = useSelector(selectInviteState);

  useEffect(() => {
    dispatch(updateInviteState({ status: "pending_check", key: id }));
  }, [dispatch, id]);

  const isAcceptingInvitation = inviteState.status === "valid";
  const ssoProvider =
    isAcceptingInvitation &&
    "identityProvider" in inviteState &&
    inviteState.identityProvider;

  const redirect =
    isAcceptingInvitation && ssoProvider ? routes.SignInSSO : routes.SignIn;

  return (
    <SplitScreenWrapper>
      {inviteState.status === "valid" && <Redirect to={redirect} />}
      {inviteState.status === "invalid" && (
        <Redirect to={routes.InvalidInvitation} />
      )}
      <Preloader absoluteFill transparent />
    </SplitScreenWrapper>
  );
};

export { VerifyInvitationScreenWeb };
