import { useCallback } from "react";

import { UseOpenItineraryParams } from "@kraaft/shared/components/geolocationOpener/useItinerary.types";
import { navigateToPosition } from "@kraaft/shared/core/services/itinerary";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export const useOpenItinerary = ({
  context: { roomId, source },
  coords,
}: UseOpenItineraryParams) => {
  const openItinerary = useCallback(async () => {
    if (coords) {
      trackEvent({
        eventName: "Start GPS Navigation",
        room_id: roomId,
        source,
      });

      await navigateToPosition(coords);
    }
  }, [coords, roomId, source]);

  return {
    openItinerary,
    ItineraryActionSheet: null,
  };
};
