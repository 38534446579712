import { DependencyList, EffectCallback, useEffect, useRef } from "react";
import isEqual from "fast-deep-equal";

import { usePrevious } from "@kraaft/shared/core/utils/hooks";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

import { TrackingEvent, TrackingEventName } from "./trackingEvent.types";

export const useTrackEventOnceWithData = <T extends TrackingEventName>(
  event: TrackingEvent<T> | undefined,
) => {
  const previousEvent = usePrevious(event);

  useEffect(() => {
    if (event && !isEqual(previousEvent, event)) {
      trackEvent(event);
    }
  }, [previousEvent, event]);
};

export const useTrackEventOnceConditionnally = <T extends TrackingEventName>(
  event: TrackingEvent<T>,
  condition: boolean,
) => {
  const trackedEvent = useRef<TrackingEvent<T> | undefined>();

  useEffect(() => {
    if (condition && !isEqual(trackedEvent.current, event)) {
      trackedEvent.current = event;
      trackEvent(event);
    }
    if (!condition) {
      trackedEvent.current = undefined;
    }
  }, [event, condition]);
};

export const useTrackEventOnce = <T extends TrackingEventName>(
  event: TrackingEvent<T>,
) => {
  useEffect(() => {
    trackEvent(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useEffectCallOrdered = (
  effect: EffectCallback,
  deps?: DependencyList,
) => {
  const previousDeps = usePrevious(deps);
  const cleanup = useRef<ReturnType<EffectCallback>>();

  if (!isEqual(deps, previousDeps)) {
    if (cleanup.current) {
      cleanup.current();
    }
    cleanup.current = effect();
  }
};

let globalPreviousPage: TrackingEvent<"View Page">["last_tracked_page"];
export const useTrackPage = (
  page: TrackingEvent<"View Page">["page"],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: { identifier?: any; shouldTrack?: boolean },
) => {
  const { identifier, shouldTrack = true } = params ?? {};

  useEffectCallOrdered(() => {
    if (shouldTrack) {
      trackEvent({
        eventName: "View Page",
        page,
        last_tracked_page: globalPreviousPage,
      });
      globalPreviousPage = page;
    }
  }, [page, shouldTrack, identifier]);
};
