import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { isWebMobile } from "@kraaft/shared/core/utils";

import logo from "./assets/logo.png";

import { useStyles } from "./splitScreenWrapper.styles";

interface Props {
  children: React.ReactNode;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  fullHeight?: boolean;
}

const SplitScreenWrapper = (props: Props) => {
  const { children, headerLeft, headerRight, fullHeight } = props;
  const shouldSplitScreen = !isWebMobile();

  const classes = useStyles({ fullHeight, shouldSplitScreen });

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        {headerLeft || headerRight ? (
          <div className={classes.header}>
            {headerLeft ?? <div />}
            {headerRight}
          </div>
        ) : null}
        <div className={classes.contentContainer}>
          <div className={classes.wrapper}>{children}</div>
        </div>
      </div>
      {shouldSplitScreen && (
        <div className={classes.rightContainer}>
          <div className={classes.backgroundContainer} />
          <div className={classes.leadWrap}>
            <span className={classes.leadTitle}>
              <Trans
                i18nKey="authTitle"
                components={{
                  bold: <span className={classes.leadTitleBold} />,
                }}
              />
            </span>
            <img className={classes.leadPartners} src={logo} />
          </div>
        </div>
      )}
    </div>
  );
};

export { SplitScreenWrapper };
