import { useRef } from "react";

interface UseTableInputBehaviorProps {
  onInputArrival: (event: React.KeyboardEvent) => void;
}

interface UseTableInputBehaviorReturn {
  onKeyDownDetectArrival: (event: React.KeyboardEvent) => void;
  onKeyPressDetectArrival: (event: React.KeyboardEvent) => void;
}

const useTableInputBehavior = (
  props: UseTableInputBehaviorProps,
): UseTableInputBehaviorReturn => {
  const { onInputArrival } = props;
  const keyDownCount = useRef(0);

  const onKeyDownDetectArrival = (_event: React.KeyboardEvent) => {
    keyDownCount.current++;
  };

  const onKeyPressDetectArrival = (event: React.KeyboardEvent) => {
    if (keyDownCount.current === 0 && event.key.length === 1) {
      // event.key.length === 1 -> only chars will replace cell value
      onInputArrival(event);
    }
  };

  return {
    onKeyDownDetectArrival,
    onKeyPressDetectArrival,
  };
};

export { useTableInputBehavior };
