import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { ActionFooter } from "@kraaft/shared/components/actionFooter/actionFooter";
import { useSelectableUsers } from "@kraaft/shared/components/createRoom/roomMembers/selectRoomMembersUtils";
import { EmptyState } from "@kraaft/shared/components/emptyState";
import { InviteRoomMemberFlow } from "@kraaft/shared/components/memberList/inviteRoomMemberFlow";
import { SelectRoomMembers } from "@kraaft/shared/components/selectRoomMembers";
import { changeRoomUsersRequest } from "@kraaft/shared/core/modules/room/roomActions";
import {
  selectAllPoolUsers,
  selectCurrentUserId,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { SelectableUser } from "@kraaft/shared/core/modules/user/userTypes";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Color } from "@kraaft/ui";

import { styles } from "./roomMembers.layout.styles";

interface Props {
  poolId: string;
  roomId: string;
  onMembersAdded: () => void;
}

const RoomMembersLayout = (props: Props) => {
  const { roomId, poolId, onMembersAdded } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userId = useSelector(selectCurrentUserId);
  const usersInPool = useSelector(selectAllPoolUsers(poolId, true));

  const atLeastCurrentUserId = useMemo(() => [userId ?? ""], [userId]);

  const { users, preselectedUsers } = useSelectableUsers({
    poolId,
    selectedUserIds: atLeastCurrentUserId,
    canViewAllUsers: true,
    availableUsers: usersInPool,
    currentUserId: userId,
  });

  const [selectedUsers, setSelectedUsers] =
    useState<SelectableUser[]>(preselectedUsers);

  const onSubmit = useCallback(() => {
    dispatch(
      changeRoomUsersRequest({
        roomId,
        addedUserIds: selectedUsers.map((member) => member.id),
      }),
    );
    onMembersAdded();
  }, [dispatch, onMembersAdded, roomId, selectedUsers]);

  const isEmpty = users.length <= 1;

  const [isInviteOpen, openInvite, closeInvite] = useBooleanState();

  const onCloseInviteFlow = useCallback(() => {
    closeInvite();
    onSubmit();
  }, [closeInvite, onSubmit]);

  if (!userId) {
    return null;
  }

  return isEmpty ? (
    <View style={styles.emptyPanel}>
      <View style={styles.emptyState}>
        <EmptyState type="members" />
      </View>
      <ActionFooter
        actions={[
          { text: t("createRoom.roomMembers.invite"), onPress: openInvite },
          {
            text: t("createRoom.roomMembers.later"),
            onPress: onMembersAdded,
          },
        ]}
        backgroundColor={Color.WHITE}
      />
      <InviteRoomMemberFlow
        open={isInviteOpen}
        onClose={onCloseInviteFlow}
        roomId={roomId}
        source="room_creation"
        inviteType="internal"
      />
      <SafeAreaView />
    </View>
  ) : (
    <View style={styles.selector} nativeID="ide2e-create-room-members">
      <SelectRoomMembers
        poolId={poolId}
        currentUserId={userId}
        users={users}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        onSubmit={onSubmit}
        allowEdition={true}
        roomId={roomId}
        freezeCurrentUser
        source="room_creation"
        buttonLabel={t("createRoom.roomMembers.next")}
      />
    </View>
  );
};

export { RoomMembersLayout };
