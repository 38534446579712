import { useCallback } from "react";

import { BaseMarker } from "@kraaft/shared/components/geolocation/baseMarker";
import { markerSize } from "@kraaft/shared/components/geolocation/baseMarker/baseMarkerConfig";
import { GeolocatedElement } from "@kraaft/shared/components/geolocation/types";
import { Marker } from "@kraaft/shared/components/mapController/markers/marker";
import { MarkerContentRenderer } from "@kraaft/shared/components/mapController/markers/marker/marker.types";
import { SimpleMarkerContent } from "@kraaft/shared/components/mapController/markers/marker/simpleMarkerContent/simpleMarkerContent";
import { RoomCardTooltip } from "@kraaft/shared/components/mapController/markers/roomMarker/roomCardTooltip";
import * as roomCardUtils from "@kraaft/shared/components/roomCard/roomCardUtils";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { RoomCardWithGeolocation } from "@kraaft/shared/core/modules/roomCard/roomCard.state";

interface Props {
  geolocatedElement: GeolocatedElement<RoomCardWithGeolocation>;
  onMarkerAction: (id: string) => void;
}

const RoomMarker = (props: Props) => {
  const {
    geolocatedElement: { ref: roomCard },
    onMarkerAction,
  } = props;

  const roomStatusColor = roomCardUtils.useRoomColor(roomCard);

  const onPress = useCallback(() => {
    onMarkerAction(roomCard.roomId);
  }, [onMarkerAction, roomCard.roomId]);

  const renderTooltip = useCallback(
    () => <RoomCardTooltip roomCard={roomCard} onPress={onPress} />,
    [onPress, roomCard],
  );

  const coordinates = getExistingRoomRecordField(
    roomCard.properties,
    "geolocation",
  )?.coords;

  if (!coordinates) {
    return null;
  }

  const renderMarkerContent: MarkerContentRenderer = ({
    isHovered,
    isSelected,
  }) => (
    <BaseMarker
      isHovered={isHovered}
      isSelected={isSelected}
      tooltipComponent={renderTooltip()}
    >
      <SimpleMarkerContent
        isHovered={isHovered}
        isSelected={isSelected}
        icon="dot"
        defaultColor={roomStatusColor}
      />
    </BaseMarker>
  );

  return (
    <Marker
      testID="ide2e-room-marker"
      id={roomCard.roomId}
      size={markerSize}
      coordinates={coordinates}
      onMarkerAction={onPress}
      renderContent={renderMarkerContent}
    />
  );
};

export { RoomMarker };
