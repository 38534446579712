import { makeStyles } from "@mui/styles";

import { ColorStyle } from "@kraaft/ui";

const PHONE_WIDTH = 590;
const PHONE_HEIGHT = 1006;

const PHONE_RATIO = PHONE_HEIGHT / PHONE_WIDTH;
const TOP = 28 / PHONE_HEIGHT;
const LEFT = 24 / PHONE_HEIGHT;
const RIGHT = 26 / PHONE_HEIGHT;
const BOTTOM = 21 / PHONE_HEIGHT;

const WIDTH = `min(max(25vw, 280px), 500px, ${1 / PHONE_RATIO} * 70vh)`;

export const useStyles = makeStyles({
  phone: {
    width: WIDTH,
    height: `calc(${PHONE_RATIO} * ${WIDTH})`,
    paddingTop: `calc(${PHONE_RATIO * TOP} * ${WIDTH})`,
    paddingBottom: `calc(${PHONE_RATIO * BOTTOM} * ${WIDTH})`,
    paddingLeft: `calc(${PHONE_RATIO * LEFT} * ${WIDTH})`,
    paddingRight: `calc(${PHONE_RATIO * RIGHT} * ${WIDTH})`,
    boxSizing: "border-box",
    position: "relative",
  },
  phoneContent: {
    position: "relative",
    width: "100%",
    height: "100%",
    borderRadius: 30,
    overflow: "hidden",
  },
  phoneOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: WIDTH,
    zIndex: 1,
    pointerEvents: "none",
  },
  relativeContent: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    overflowX: "hidden",
    overflowY: "auto",
  },
  absoluteContent: {
    overflowX: "hidden",
    overflowY: "auto",

    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: "none",
  },
});
