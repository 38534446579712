import { useCallback, useEffect, useRef } from "react";

import type { CollapsibleProps } from "@kraaft/shared/components/animated/collapsible/types";

import { ANIMATION_DURATION, useStyles } from "./collapsible.styles";

const Collapsible = ({ open, children }: CollapsibleProps) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const observer = useRef<ResizeObserver | null>(null);
  const classes = useStyles();

  const applyHeight = useCallback((isOpen: boolean) => {
    if (containerRef.current && rootRef.current) {
      if (!isOpen) {
        rootRef.current.style.height = "0px";
      } else {
        rootRef.current.style.height = `${containerRef.current.clientHeight}px`;
      }
    }
  }, []);

  const onRootRef = useCallback(
    (node: HTMLDivElement) => {
      rootRef.current = node;
      observer.current = new ResizeObserver(() => applyHeight(open));
      if (containerRef.current) {
        observer.current.observe(containerRef.current);
      }
      applyHeight(open);
    },
    [applyHeight, open],
  );

  useEffect(() => {
    if (open) {
      containerRef.current?.classList.remove(classes.contentDisabled);
    } else {
      const timeout = setTimeout(() => {
        containerRef.current?.classList.add(classes.contentDisabled);
      }, ANIMATION_DURATION);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [classes.contentDisabled, open]);

  useEffect(() => {
    applyHeight(open);
  }, [applyHeight, open, children]);

  return (
    <div ref={onRootRef} className={classes.root}>
      <div ref={containerRef} className={classes.children}>
        {children}
      </div>
    </div>
  );
};

export { Collapsible };
