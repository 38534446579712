import { useCallback, useState } from "react";
import deburr from "lodash/fp/deburr";
import { StrictOmit } from "ts-essentials";

import { KInput, KInputProps } from "@kraaft/shared/components/input/KInput";

const confirmationMatch = (base: string | undefined, value: string): boolean =>
  Boolean(base && deburr(base) === deburr(value));

interface Props extends StrictOmit<KInputProps, "onChangeText"> {
  expectedValue: string;
  onValueMatches: (value: boolean) => void;
}

const ConfirmationInput = ({
  onValueMatches,
  expectedValue,
  ...props
}: Props) => {
  const [text, setText] = useState("");

  const onChangeText = useCallback(
    (value: string) => {
      setText(value);
      onValueMatches(confirmationMatch(expectedValue, value));
    },
    [expectedValue, onValueMatches],
  );

  return <KInput {...props} onChangeText={onChangeText} value={text} />;
};

export { ConfirmationInput };
