import {
  LoaderConfig,
  LoaderFailure,
  LoaderMeta,
  LoaderStatus,
} from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { DistributiveOmit } from "@kraaft/shared/core/typeUtils";

export interface WithLoaderPayload {
  loaderId: string;
}

export function createLoaderMeta(loader: LoaderConfig): LoaderMeta {
  return {
    loader,
  };
}

export function updateLoaderMeta(
  meta: LoaderMeta,
  loader: DistributiveOmit<LoaderConfig, "id">,
): LoaderMeta {
  return {
    loader: { ...meta.loader, ...loader },
  };
}

export function actionUpdateLoaderMetaSuccess(meta: LoaderMeta) {
  return {
    payload: undefined,
    meta: updateLoaderMeta(meta, { status: LoaderStatus.SUCCESS }),
  };
}

export function actionUpdateLoaderMetaFailure(error: Error, meta: LoaderMeta) {
  return {
    payload: undefined,
    meta: updateLoaderMeta(meta, { status: LoaderStatus.FAILURE, error }),
  };
}

export function loaderHasError(
  loader: LoaderConfig | undefined,
): loader is LoaderFailure {
  return loader?.status === LoaderStatus.FAILURE;
}

export function getModularFolderLoadingId(
  recordId: string,
  columnKey?: string,
) {
  return `${recordId}${columnKey !== undefined ? `-${columnKey}` : ""}`;
}

export function getOfflineMessageLoadingId(requestId: string) {
  return `offlineMessage-${requestId}`;
}

export const excelExportLoadingId = "export-dialog-button";
