import { ReactNode, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { compact } from "lodash";

import { createMeshContext, useMeshContextSetup } from "@kraaft/helper-hooks";
import { UserMessage } from "@kraaft/shared/core/modules/message/messageState";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { unstable_batchedUpdates } from "@kraaft/shared/core/utils/unstableBatchedUpdates";

export interface SearchResult {
  id: string;
  roomId: string;
  poolId: string;
  createdAt: Date;
  matches: string[];
}

export interface DisplayableSearchResult {
  searchResult: SearchResult;
  message: UserMessage;
}

export interface SearchConversationContext {
  setRoomId: (roomId: string) => void;

  query: string;
  setQuery: (newQuery: string) => void;
  executeSearch: () => void;
  fetching: boolean;

  searching: boolean;
  setSearching: (searching: boolean) => void;

  result: SearchResult | undefined;
  setResult: (result: SearchResult) => void;

  results: DisplayableSearchResult[];
  hasSearched: boolean;
  reset: (shouldClose: boolean) => void;
}

export const SearchConversationContext =
  createMeshContext<SearchConversationContext>();

interface SearchConversationContextProviderProps {
  children: ReactNode;
}

export const SearchConversationContextProvider = ({
  children,
}: SearchConversationContextProviderProps) => {
  const [hasSearched, setHasSearched] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [fetching, setFetching] = useState(false);
  const currentUserId = useSelector(selectCurrentUserId);
  const [query, setQuery] = useState("");
  const [searching, setSearching] = useState(false);
  const [result, setResult] = useState<SearchResult | undefined>(undefined);
  const [results, setResults] = useState<DisplayableSearchResult[]>([]);

  const handleExecuteSearch = useCallback(async () => {
    unstable_batchedUpdates(() => {
      setFetching(true);
      setHasSearched(true);
    });
    trackEvent({
      eventName: "Search Message",
      room_id: roomId,
      content: query,
    });
    const newResults = await Api.searchMessagesInRoom({
      roomId: roomId,
      query,
    });
    const messages = await Promise.all(
      newResults.map((r) => Firestore.fetchMessage(r.id, currentUserId ?? "")),
    );
    setResults(
      compact(
        newResults.map((newResult) => {
          const message = messages.find(
            (msg) => msg?.message?.id === newResult.id,
          )?.message;
          if (!message || message.type === "log") {
            return undefined;
          }
          return {
            searchResult: newResult,
            message,
          };
        }),
      ),
    );
    setFetching(false);
  }, [currentUserId, query, roomId]);

  const reset = useCallback((shouldClose: boolean) => {
    setResults([]);
    setResult(undefined);
    setQuery("");
    setHasSearched(false);
    if (shouldClose) {
      setSearching(false);
    }
  }, []);

  const handleSetRoomId = useCallback(
    (newRoomId: string) => {
      setRoomId((old) => {
        if (old !== newRoomId) {
          reset(true);
          return newRoomId;
        }
        return old;
      });
    },
    [reset],
  );

  const contextValue = useMeshContextSetup<SearchConversationContext>({
    searching,
    setSearching,
    result,
    setResult,
    results,
    query,
    setQuery,
    executeSearch: handleExecuteSearch,
    fetching,
    setRoomId: handleSetRoomId,
    reset,
    hasSearched,
  });

  return (
    <SearchConversationContext.Provider value={contextValue}>
      {children}
    </SearchConversationContext.Provider>
  );
};
