import {
  SelectFormsStep,
  SelectFormsStepParams,
} from "@kraaft/web/src/components/multiStepDialog/customs/connectKizeoSteps/selectFormsStep";
import { ensureStepType } from "@kraaft/web/src/components/multiStepDialog/multiStepDialogUtils";

import { MultiStepDialog } from "../../multiStepDialog";
import { DoneStep } from "./doneStep";
import { EnterTokenStep, EnterTokenStepParams } from "./enterTokenStep";

interface ConnectKizeoStepsProps {
  open: boolean;
  onClose: () => void;
  poolId: string;
  type: ConnectKizeoType | undefined;
}

export type ConnectKizeoType = "setToken" | "selectForms";

const ConnectKizeoSteps = (props: ConnectKizeoStepsProps) => {
  const { open, onClose, poolId, type } = props;

  const steps =
    type === "setToken"
      ? [
          ensureStepType<EnterTokenStepParams>({
            params: { poolId, nextStep: 1 },
            dialog: EnterTokenStep,
          }),
          ensureStepType<SelectFormsStepParams>({
            params: { poolId, nextStep: 2 },
            dialog: SelectFormsStep,
          }),
          ensureStepType<undefined>({
            dialog: DoneStep,
          }),
        ]
      : type === "selectForms"
        ? [
            ensureStepType<SelectFormsStepParams>({
              params: { poolId, nextStep: 1 },
              dialog: SelectFormsStep,
            }),
            ensureStepType<undefined>({
              dialog: DoneStep,
            }),
          ]
        : [];

  return <MultiStepDialog open={open} onClose={onClose} steps={steps} />;
};

export { ConnectKizeoSteps };
