import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    "& > *": {
      marginBottom: Spacing.S4,
    },
  },
});
