import isEqual from "fast-deep-equal";
import orderBy from "lodash/orderBy";

import { hasTitleProperty } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import {
  LocalPath,
  ModernFile,
  ModernFileHelper,
} from "@kraaft/shared/core/modules/file/file";
import {
  Attachment,
  ImageAttachment,
  TypeDependentAttachmentProperties,
  TypeDependentAttachmentPropertiesWithType,
  VideoAttachment,
} from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { ColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/column";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { ModularRecordProperties } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { getSchemaTitleColumn } from "@kraaft/shared/core/modules/schema/schema.utils";

export const orderFolders = <T extends ModularFolder>(
  folders: Record<string, T>,
): T[] => {
  const orderedArray = orderBy(folders, [(item) => item.index]);

  return orderedArray;
};

export function isColumnValueNonEmpty(prop: ColumnValue): boolean {
  return (
    prop.value !== undefined && prop.value !== "" && !isEqual(prop.value, [])
  );
}

export function filterCreateableRecords<T extends ModularRecordProperties>(
  foldersProperties: T[],
  schema: KFolderSchema,
) {
  const titleColumn = getSchemaTitleColumn(schema);
  if (titleColumn.type === KColumnType.automatedAutoIncrement) {
    return foldersProperties.filter((properties) =>
      Object.values(properties).some(isColumnValueNonEmpty),
    );
  }

  return foldersProperties.filter(
    (folder) => hasTitleProperty(folder) && folder.title.value.length > 0,
  );
}

/**
 * Equivalent of groupBy(modularFolders, (folder) => folder.roomId)
 * but as a specialized/optimized version, no keyExtractor function called every iteration.
 */
export function groupModularFolderDictByRoomId(
  dict: Record<string, ModularFolder>,
) {
  const newState: Record<string, Record<string, ModularFolder>> = {};
  for (const modularFolderId in dict) {
    const modularFolder = dict[modularFolderId];
    if (modularFolder) {
      const roomId = modularFolder.roomId;
      newState[roomId] ??= {};
      newState[roomId][modularFolder.id] = modularFolder;
    }
  }
  return newState;
}

// eslint-disable-next-line complexity
export function createLocalAttachmentFromModernFile(
  file: ModernFile<LocalPath>,
): TypeDependentAttachmentProperties {
  const isNativeMediaFile = ModernFileHelper.isMedia(file);

  switch (file.contentType) {
    case "image": {
      const attachment: TypeDependentAttachmentPropertiesWithType<"image"> = {
        type: "image",
        original: {
          filename: file.filename,
          downloadUrl: file.path,
        },
        size: undefined,
        geolocation:
          isNativeMediaFile && file.coordinates
            ? { coords: file.coordinates }
            : undefined,
        caption: undefined,
      };
      return attachment;
    }
    case "video": {
      const attachment: TypeDependentAttachmentPropertiesWithType<"video"> = {
        type: "video",
        original: {
          filename: file.filename,
          downloadUrl: file.path,
        },
        converted: {},
        geolocation:
          isNativeMediaFile && file.coordinates
            ? { coords: file.coordinates }
            : undefined,
        caption: undefined,
      };
      return attachment;
    }
    case "audio": {
      const attachment: TypeDependentAttachmentPropertiesWithType<"audio"> = {
        type: "audio",
        original: {
          filename: file.filename,
          downloadUrl: file.path,
        },
      };
      return attachment;
    }
    case "document": {
      const attachment: TypeDependentAttachmentPropertiesWithType<"document"> =
        {
          type: "document",
          original: {
            filename: file.filename,
            downloadUrl: file.path,
          },
        };
      return attachment;
    }
  }
}

export function createAttachmentFromModernFile(
  id: string,
  userId: string,
  // TODO this is not required to be Uplaodable, maybe the naming is shit, to meditate
  file: ModernFile<LocalPath>,
): Attachment {
  if (file.contentType === "image" || file.contentType === "video") {
    return {
      ...(createLocalAttachmentFromModernFile(file) as
        | ImageAttachment
        | VideoAttachment),
      id,
      createdAt: new Date(),
      senderUserId: userId,
      caption: ModernFileHelper.getText(file),
    };
  }
  return {
    ...createLocalAttachmentFromModernFile(file),
    id,
    createdAt: new Date(),
    senderUserId: userId,
  };
}
