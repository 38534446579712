import { useCallback } from "react";
import { StyleSheet, View } from "react-native";

import { PermissionRequester } from "@kraaft/shared/components/permissionRequester/permissionRequester";
import { PermissionRequest } from "@kraaft/shared/components/permissionRequester/permissionRequester.types";
import {
  PermissionResult,
  Permissions,
} from "@kraaft/shared/core/services/permission/permissions.types";
import { Sheet, Spacing } from "@kraaft/ui";

interface PermissionRequesterSheetProps {
  permissionRequest: PermissionRequest;
}

export const PermissionRequesterSheet = Sheet({
  native: "bottom",
  web: "popup",
})
  .create<PermissionRequesterSheetProps>(
    ({ Paper, Content }) =>
      ({ onClose, permissionRequest }) => {
        const handleRequestPromise = useCallback(
          async (requestPromise: Promise<PermissionResult>) => {
            const status = await requestPromise;

            if (Permissions.convertStatusToBoolean(status)) {
              onClose();
            }
            permissionRequest.promiseResolver(status);
          },
          [onClose, permissionRequest],
        );

        return (
          <Paper withHandle>
            <Content>
              <View style={styles.content}>
                <PermissionRequester
                  type={permissionRequest.type}
                  onRequestPromise={handleRequestPromise}
                />
              </View>
            </Content>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "SMALL" });

const styles = StyleSheet.create({
  content: {
    marginTop: Spacing.S16,
  },
});
