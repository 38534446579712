import { View } from "react-native";
import {
  NativeSafeAreaViewProps,
  SafeAreaView,
} from "react-native-safe-area-context";

const typedIdentity = View as unknown as typeof SafeAreaView;

export type { NativeSafeAreaViewProps as SafeAreaViewProps };

export { typedIdentity as SafeAreaView };
