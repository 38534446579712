import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RoomActions } from "@kraaft/shared/core/modules/room/roomActions";
import { RoomNotificationFilter } from "@kraaft/shared/core/modules/room/roomState";
import {
  selectIsUserMemberOfRoom,
  selectRoomTitle,
} from "@kraaft/shared/core/modules/room/selectors/roomOrRoomCardSelectors";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { RoomNotificationFilterSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

type Params = {
  roomId: string;
  source: RoomNotificationFilterSource;
};

export const useRoomNotificationFilter = ({ roomId, source }: Params) => {
  const dispatch = useDispatch();

  const roomTitle = useSelector(selectRoomTitle(roomId));
  const isCurrentUserMemberOfRoom = useSelector(
    selectIsUserMemberOfRoom(roomId),
  );

  const changeNotificationFilter = useCallback(
    (notificationFilter: RoomNotificationFilter) => {
      trackEvent({
        eventName: "Edit Conversation Notifications Settings",
        notification_setting: notificationFilter,
        room_id: roomId,
        room_name: roomTitle ?? "",
        source: source,
      });

      dispatch(
        RoomActions.changeNotificationFilter({
          roomId,
          notificationFilter,
        }),
      );
    },
    [dispatch, roomTitle, roomId, source],
  );

  return {
    changeNotificationFilter: isCurrentUserMemberOfRoom
      ? changeNotificationFilter
      : null,
  };
};
