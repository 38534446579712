import { Text, View } from "react-native";

import { Icon, IconName } from "@kraaft/ui";

import { KSelectableListItem } from "./kSelectionListProps";

import { styles } from "./kSelectionList.styles";

const KSelectionListItemBasic = <T extends string, U>({
  item,
  leftIcon,
  noPadding,
}: {
  item: KSelectableListItem<T, U>;
  leftIcon?: IconName;
  noPadding?: boolean;
}) => {
  return (
    <View style={[styles.item, !noPadding && styles.itemPadding]}>
      {leftIcon && !item.icon ? (
        <View style={styles.iconContainerLeft}>
          <Icon name={leftIcon} size="MEDIUM" color="FONT_HIGH_EMPHASIS" />
        </View>
      ) : null}
      {item.icon ? (
        <View style={styles.iconContainerLeft}>
          <Icon name={item.icon} size="MEDIUM" color="FONT_HIGH_EMPHASIS" />
        </View>
      ) : null}
      <View style={styles.textContainer}>
        <Text numberOfLines={1} style={styles.text}>
          {item.label}
        </Text>
      </View>
    </View>
  );
};

export { KSelectionListItemBasic };
