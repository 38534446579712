import { ConditionLibraryForType } from "@kraaft/shared/core/generated/__generated/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";

export const attachmentConditions: ConditionLibraryForType<KColumnType.attachment> =
  {
    standalone: {
      isEmpty: {
        translationKey: "isEmpty",
        check: (value) => !(value.value?.length ?? 0),
      },
      isNotEmpty: {
        translationKey: "isNotEmpty",
        check: (value) => Boolean(value.value?.length ?? 0),
      },
    },
  };
