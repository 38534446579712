import {
  ReportTemplate,
  ReportTemplateFormat,
} from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { i18n, ResourceKey } from "@kraaft/shared/core/services/i18next";
import { IconName } from "@kraaft/ui";

export type DownloadReportFormat = "docx" | "xlsx" | "pdf";

export function getReportTemplateIcon(format: ReportTemplateFormat): IconName {
  return format === "xlsx" ? "table" : "file-download-02";
}

export const getReportTemplateName = (reportTemplate: ReportTemplate) =>
  reportTemplate.variant === "default"
    ? i18n.t("defaultKraaftTemplate")
    : reportTemplate.name;

const ModularFolderMimeTypes = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // .docx
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
};
const RoomMimeTypes = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // .docx
};

export function getReportTemplateDropzoneParameters(
  schemaCollection: KSchema["collection"] | undefined,
) {
  const acceptedMimeTypes =
    schemaCollection === "room" ? RoomMimeTypes : ModularFolderMimeTypes;
  const invalidFileErrorKey: ResourceKey =
    schemaCollection === "room"
      ? "importFileErrorFileInvalidTypeDOCX"
      : "importFileErrorFileInvalidTypeDocxXlsx";
  return { acceptedMimeTypes, invalidFileErrorKey };
}
