import { makeStyles } from "@mui/styles";

import { Color, Radius } from "@kraaft/ui";

export const CROSS_SIZE = 32;

export const useStyles = makeStyles({
  container: {
    backgroundColor: Color.WHITE,
    position: "relative",
    width: "100vw",
    height: "100vh",
  },
  content: {
    maxHeight: "inherit",
    height: "100%",
    overflow: "hidden",
    borderRadius: Radius.SMALL,
  },
});
