import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { FlashList, ListRenderItem } from "@shopify/flash-list";
import { chunk } from "lodash";

import { isNative } from "@kraaft/helper-functions";
import { emojiList } from "@kraaft/shared/components/emojiSelector/emoji.data";
import { EmojiObject } from "@kraaft/shared/components/emojiSelector/emoji.types";
import { getLocalizedKeywordsForEmoji } from "@kraaft/shared/components/emojiSelector/emojiFinder/emojiFinder";
import { EmojiSelectorContextProvider } from "@kraaft/shared/components/emojiSelector/emojiSelectorContextProvider";
import {
  MAX_RECENT_EMOJI_LINES,
  RecentEmojisSection,
} from "@kraaft/shared/components/emojiSelector/recentEmojisSection";
import { SkinToneSelector } from "@kraaft/shared/components/emojiSelector/skinToneSelector/skinToneSelector";
import { SafeAreaView } from "@kraaft/shared/components/safeAreaView";
import { SearchBar } from "@kraaft/shared/components/searchBar";
import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";
import { Spacing } from "@kraaft/ui";

import { Emoji } from "./emoji";
import { charFromEmojiObject, EMOJI_WIDTH, evenlySpread } from "./emoji.utils";
import { EmojiListSkeleton } from "./emojiListSkeleton";
import { ScrollView } from "./scrollViewComponent";

type ItemLine = { emojis: Array<string> } | Array<EmojiObject>;

function extractItemLineKey(itemLine: ItemLine) {
  return "emojis" in itemLine
    ? "recent"
    : itemLine.map((e) => e.name).join(",");
}

function useItemLayout(numColumns: number) {
  return useCallback(
    (
      layout: {
        span?: number;
        size?: number;
      },
      item: ItemLine,
    ) => {
      if ("emojis" in item) {
        if (item.emojis.length === 0) {
          layout.size = 0;
          return;
        }
        const lines = Math.min(
          Math.max(Math.ceil(item.emojis.length / numColumns), 1),
          MAX_RECENT_EMOJI_LINES,
        );
        layout.size =
          // Recent + Emojis texts
          12 * 2 +
          // Margin between recent and all emojis
          8 +
          // Lines of recent emojis
          lines * EMOJI_WIDTH;
        return;
      }
      layout.size = EMOJI_WIDTH;
    },
    [numColumns],
  );
}

const ListFooterComponent = () => <SafeAreaView edges={["bottom"]} />;

interface EmojiSelectorProps {
  width: number;
  poolLanguage: PoolLanguage | undefined;
  onEmojiSelected: (emoji: string) => void;
  isAnimating?: boolean;
  recentEmojis?: string[];
}

export const EmojiSelector = ({
  width,
  poolLanguage,
  onEmojiSelected,
  isAnimating,
  recentEmojis,
}: EmojiSelectorProps) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");

  const { numColumns, gap } = useMemo(
    () => evenlySpread(width - Spacing.S16 * 2, EMOJI_WIDTH, Spacing.S8),
    [width],
  );

  const filteredEmojiList = useMemo<Array<ItemLine>>(() => {
    if (searchText.length === 0) {
      return recentEmojis && recentEmojis.length > 0
        ? [
            {
              emojis: recentEmojis,
            },
            ...chunk(emojiList, numColumns),
          ]
        : chunk(emojiList, numColumns);
    }
    const sanitizedSearchText = searchText.toLowerCase();

    function searchMatch(value: string | null) {
      if (value === null || value === undefined) {
        return false;
      }
      return normalizeTextForSearch(value).includes(sanitizedSearchText);
    }

    const newEmojiList: EmojiObject[][] = [];
    let currentChunk: EmojiObject[] = [];

    function addEmojiToChunk(emojiObject: EmojiObject) {
      currentChunk.push(emojiObject);
      if (currentChunk.length === numColumns) {
        newEmojiList.push(currentChunk);
        currentChunk = [];
      }
    }

    for (let i = 0; i < emojiList.length; i += 1) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const emojiObject = emojiList[i]!;
      if (emojiObject.short_names.some((name) => searchMatch(name))) {
        addEmojiToChunk(emojiObject);
        continue;
      }
      if (searchMatch(emojiObject.text)) {
        addEmojiToChunk(emojiObject);
        continue;
      }
      if (
        poolLanguage &&
        getLocalizedKeywordsForEmoji(
          charFromEmojiObject(emojiObject),
          poolLanguage,
        ).some((keyword) => searchMatch(keyword))
      ) {
        addEmojiToChunk(emojiObject);
      }
    }
    if (currentChunk.length > 0) {
      newEmojiList.push(currentChunk);
    }
    return newEmojiList;
  }, [numColumns, poolLanguage, recentEmojis, searchText]);

  const renderEmojiItem = useCallback<ListRenderItem<ItemLine>>(
    ({ item: itemsLine }) => {
      if ("emojis" in itemsLine) {
        return (
          <RecentEmojisSection
            emojis={itemsLine.emojis}
            onPress={onEmojiSelected}
            numColumns={numColumns}
            gap={gap}
          />
        );
      }
      return (
        <View style={[styles.columnWrapperStyle, { gap }]}>
          {itemsLine.map((item, index) => (
            <Emoji
              //https://github.com/Shopify/flash-list#usage
              key={index.toString()}
              emojiObject={item}
              onPress={onEmojiSelected}
              emojiWidth={EMOJI_WIDTH}
            />
          ))}
        </View>
      );
    },
    [gap, numColumns, onEmojiSelected],
  );

  const itemLayout = useItemLayout(numColumns);

  return (
    <EmojiSelectorContextProvider>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <View style={styles.searchRowContainer}>
            <View style={styles.searchContainer}>
              <SearchBar
                value={searchText}
                onChange={setSearchText}
                placeholder={t("search")}
                isStatic
                autoFocus={!isNative()}
              />
            </View>
            <SkinToneSelector />
          </View>
        </View>
        <View style={[styles.listWrapper, { width }]}>
          {!isAnimating ? (
            <FlashList
              estimatedItemSize={EMOJI_WIDTH}
              estimatedListSize={{ width, height: 400 }} // faster initial rendering
              data={filteredEmojiList}
              overrideItemLayout={itemLayout}
              renderItem={renderEmojiItem}
              keyExtractor={extractItemLineKey}
              keyboardShouldPersistTaps="handled"
              ListFooterComponent={ListFooterComponent}
              // we need to use ScrollView from react-native-gesture-handler because we are in a BottomSheet
              renderScrollComponent={ScrollView}
            />
          ) : (
            <EmojiListSkeleton gap={gap} numColumns={numColumns} />
          )}
        </View>
      </View>
    </EmojiSelectorContextProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
  },

  contentContainer: {
    justifyContent: "center",
  },

  columnWrapperStyle: {
    flexDirection: "row",
    paddingHorizontal: Spacing.S16,
  },

  headerContainer: {
    padding: Spacing.S8,
    gap: Spacing.S8,
  },

  eraseButtonContainer: {
    alignItems: "center",
  },

  searchRowContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
  },

  searchContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },

  list: {
    flexGrow: 1,
  },
  listWrapper: {
    flexGrow: 1,
    flexBasis: 0,
    /** Needed for the web */
    overflow: "auto" as any,
  },
});

export const emojiSelectorStyles = styles;
