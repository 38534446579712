import { makeStyles } from "@mui/styles";

import { Color, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    height: "100%",
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    backgroundColor: Color.WHITE,
  },
  header: {
    paddingTop: Spacing.S24,
    paddingLeft: Spacing.S32,
    paddingRight: Spacing.S32,
  },
  cardsContainer: {
    display: "flex",
    gap: Spacing.S24,
    marginLeft: Spacing.S32,
    marginRight: Spacing.S32,
  },
  card: {
    flex: 1,
  },
});
