import {
  basicLogger,
  initialize,
  LDClient,
  LDOptions,
} from "launchdarkly-react-client-sdk";
import { mapValues } from "lodash/fp";

import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { LAUNCH_DARKLY_VERBOSE } from "@kraaft/shared/constants/global";
import { FeatureFlags } from "@kraaft/shared/core/modules/featureFlags/featureFlags.state";
import {
  AbstractLaunchDarklySDK,
  FeatureFlagsListener,
  UserParams,
} from "@kraaft/shared/core/services/launchDarkly/sdk/types";
import { getCurrentVersion } from "@kraaft/shared/core/utils";

type NativeFeatureFlagsReadyListener = () => Promise<void>;

type NativeFeatureFlagsListener = (
  items: Record<string, { current: unknown; previous: unknown }>,
) => void;

export class LaunchDarklySDK extends AbstractLaunchDarklySDK {
  private client: LDClient | undefined;
  private onReady: NativeFeatureFlagsReadyListener | undefined;
  private onChange: NativeFeatureFlagsListener | undefined;

  public async init() {
    const options: LDOptions = {
      application: {
        id: "kraaft-web",
        version: getCurrentVersion(),
      },
      logger: basicLogger({
        level: LAUNCH_DARKLY_VERBOSE ? "debug" : "warn",
      }),
    };

    this.client = initialize(
      getEnvironment().LAUNCH_DARKLY.WEB_CLIENT_ID,
      this.getContext(),
      options,
    );
  }

  public async setUser(user: UserParams) {
    await this.client?.identify(this.getContext(user));
  }

  public async subscribe(listener: FeatureFlagsListener) {
    this.onChange = (
      items: Record<string, { current: unknown; previous: unknown }>,
    ) => {
      const values = mapValues((item) => item.current, items) as FeatureFlags;

      listener(values);
    };

    this.onReady = async () => {
      const result = this.client?.allFlags();

      if (result) {
        listener(result);
      }
    };

    this.client?.on("ready", this.onReady);
    this.client?.on("change", this.onChange);
  }

  public async unsubscribe() {
    if (this.onReady) {
      this.client?.off("ready", this.onReady);
    }

    if (this.onChange) {
      this.client?.off("change", this.onChange);
    }
  }
}
