import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  subscribeToRoom,
  unsubscribeFromRoom,
} from "@kraaft/shared/core/modules/room/roomActions";

export function useSubscribeToRoom(roomId: string | undefined) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (roomId) {
      dispatch(subscribeToRoom({ roomId }));
      return () => {
        dispatch(unsubscribeFromRoom({ roomId }));
      };
    }
  }, [dispatch, roomId]);
}
