import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useMeshContext } from "@kraaft/helper-hooks";
import {
  ListEditor,
  ListEditorItem,
} from "@kraaft/shared/components/modular/details/editors/listEditor";
import { EditorProps } from "@kraaft/shared/components/modular/details/editors/types";
import { EMPTY_LOCKED_RECORD_PLACEHOLDER } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { ModularDisplayRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";

import { useUserDropdownItems } from "./useUserDropdownItems";

type Props = EditorProps<KColumnType.user>;

export const UserEditor = ({
  editor,
  column,
  columnContext,
  testID,
}: Props) => {
  const { sectionLockInfo, value } = editor;
  const { t } = useTranslation();

  const selectedUserIds = useMemo(() => value ?? [], [value]);

  const { getOrLoadUsers, getPoolUsers, currentUserId, poolId } =
    useMeshContext(ModularDisplayRequirementsContext);

  const dropdownItems = useUserDropdownItems({
    selectedUserIds,
    selectedUsers: getOrLoadUsers(selectedUserIds),
    poolUsers: getPoolUsers(),
    currentUserId,
    poolId,
  });
  const items = useMemo<ListEditorItem[]>(
    () =>
      dropdownItems.map((item) => ({
        identifier: item.value,
        label: item.label,
        icon: "user-01",
        value: { pillIcon: "user-01" },
      })),
    [dropdownItems],
  );

  const placeholder = !sectionLockInfo.isLocked
    ? t("modularity.select")
    : EMPTY_LOCKED_RECORD_PLACEHOLDER;

  return (
    <ListEditor
      searchPlaceholder={t("search")}
      placeholder={placeholder}
      iconName={KSchemaUtils.getIcon(column)}
      items={items}
      column={column}
      columnContext={columnContext}
      testID={testID}
      editor={editor}
    />
  );
};
