import { PropsWithChildren, useCallback, useState } from "react";

import {
  createMeshContext,
  useMeshContext,
  useMeshContextSetup,
} from "@kraaft/helper-hooks";

export type ModularFoldersSelectionType = "none" | "download";

interface ModularFoldersSelectionValue {
  selectionType: ModularFoldersSelectionType;
  onChangeSelectionType: (type: ModularFoldersSelectionType) => void;
  selectedIds: Set<string>;
  onSelect(id: string): void;
  onSelectMultiple(ids: string[], value: boolean): void;
  onFinish(): void;
}

const ModularFoldersSelectionContext =
  createMeshContext<ModularFoldersSelectionValue>();

export const MODULAR_FOLDERS_SELECTION_PORTAL = "ModularFoldersSelectionPortal";

export const ModularFoldersSelectionProvider = ({
  children,
}: PropsWithChildren) => {
  const [selectedIds, setSelected] = useState(new Set<string>());
  const [selectionType, setSelectionType] =
    useState<ModularFoldersSelectionType>("none");

  const onChangeSelectionType = useCallback(
    (type: ModularFoldersSelectionType) => {
      setSelectionType(type);
      setSelected(new Set());
    },
    [],
  );

  const onFinish = useCallback(() => {
    setSelectionType("none");
    setSelected(new Set());
  }, []);

  const onSelect = useCallback((id: string) => {
    setSelected((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (prevSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  }, []);

  const onSelectMultiple = useCallback((ids: string[], value: boolean) => {
    setSelected((prevSelected) => {
      const newSelected = new Set(prevSelected);
      for (const id of ids) {
        if (value) {
          newSelected.add(id);
        } else {
          newSelected.delete(id);
        }
      }
      return newSelected;
    });
  }, []);

  const contextValue = useMeshContextSetup<ModularFoldersSelectionValue>({
    selectionType,
    onChangeSelectionType,
    selectedIds,
    onSelect,
    onSelectMultiple,
    onFinish,
  });

  return (
    <ModularFoldersSelectionContext.Provider value={contextValue}>
      {children}
    </ModularFoldersSelectionContext.Provider>
  );
};

export const useIsModularFoldersSelectionEnabled = () => {
  return useMeshContext(
    ModularFoldersSelectionContext,
    (state) => state.selectionType !== "none",
  );
};

export const useIsModularFolderSelected = (id: string) => {
  return useMeshContext(ModularFoldersSelectionContext, (state) =>
    state.selectedIds.has(id),
  );
};

export const useOnSelectModularFolder = () => {
  const { onSelect } = useMeshContext(ModularFoldersSelectionContext);
  return onSelect;
};

export const useOnSelectMultipleModularFolders = () => {
  const { onSelectMultiple } = useMeshContext(ModularFoldersSelectionContext);
  return onSelectMultiple;
};

export const useOnChangeModularFoldersSelectionType = () => {
  const { onChangeSelectionType } = useMeshContext(
    ModularFoldersSelectionContext,
  );
  return onChangeSelectionType;
};

export const useModularFoldersSelection = () => {
  const { selectedIds } = useMeshContext(ModularFoldersSelectionContext);
  return selectedIds;
};
