import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api/api";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button, ColorStyle, Spacing, Text } from "@kraaft/ui";
import { DateInput } from "@kraaft/web/src/components/dateInput/dateInput";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";

import { useSettingsStyles } from "../settings.styles";

type ValidApiMethods = {
  [C in keyof typeof Api]: Parameters<(typeof Api)[C]>[1] extends undefined
    ? C
    : never;
}[keyof typeof Api];

export const KAPI = {
  useCommand<C extends ValidApiMethods>(
    endpoint: C,
    refetch: (() => any)[],
  ): UseMutationResult<
    unknown,
    unknown,
    Parameters<(typeof Api)[C]>[0],
    unknown
  >["mutate"] {
    const { mutate } = useMutation(
      [endpoint] as const,
      (variables: Parameters<(typeof Api)[C]>[0]) =>
        Api[endpoint](variables as any),
      {
        onSuccess: () => {
          for (const fn of refetch) {
            fn();
          }
        },
      },
    );

    return mutate;
  },
  useQuery<Q extends ValidApiMethods>(
    endpoint: Q,
    getVariables: () => Parameters<(typeof Api)[Q]>[0] | undefined | "",
  ): UseQueryResult<Awaited<ReturnType<(typeof Api)[Q]>>> {
    const variables = getVariables();

    return useQuery(
      [endpoint, variables] as const,
      () => Api[endpoint](variables as any),
      {
        enabled: !!variables,
      },
    );
  },
};

function useBilling(poolId?: string) {
  const { data: result, refetch } = KAPI.useQuery(
    "getBillingInformation",
    () => poolId && { poolId },
  );

  const setPoolTrial = KAPI.useCommand("setPoolTrial", [refetch]);
  const setRestriction = KAPI.useCommand("setPoolRestrictionScheme", [refetch]);
  const setSubscriptionId = KAPI.useCommand("setPoolStripeSubscriptionId", [
    refetch,
  ]);

  return {
    result: result && {
      ...result,
      trialPeriodEnd: result?.trialPeriodEnd
        ? new Date(result.trialPeriodEnd)
        : undefined,
    },
    setPoolTrial: (trialPeriodEnd: Date | undefined) => {
      if (poolId) {
        setPoolTrial({ poolId, trialEnd: trialPeriodEnd });
      }
    },
    setRestriction: (
      restrictionScheme: "automatic" | "restricted" | "unrestricted",
    ) => {
      if (poolId) {
        setRestriction({ poolId, restrictionScheme });
      }
    },
    setSubscriptionId: (subscriptionId?: string) => {
      if (poolId) {
        if (!subscriptionId) {
          return setSubscriptionId({ poolId, stripeSubscriptionId: undefined });
        }
        return setSubscriptionId({
          poolId,
          stripeSubscriptionId: subscriptionId,
        });
      }
    },
  };
}

const ManageBilling = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const settingsClasses = useSettingsStyles();

  const poolId = useSelector(selectCurrentPoolId);

  useTrackPage("ManageBilling");

  const isSuperAdmin = useSelector(selectCurrentUserIsSuperadmin);

  const { result, setPoolTrial, setRestriction, setSubscriptionId } =
    useBilling(poolId);

  const hasStripeSubscription = result?.stripeCustomerPortalUrl;
  const isFree = !result?.licenses;

  return (
    <div className={settingsClasses.pageContainer}>
      <PageHeader title={t("settingsBilling")} />
      {hasStripeSubscription ? (
        <Button
          icon="shield-dollar"
          accessibilityLabel={t("billing.stripeButton")}
          text={t("billing.stripeButton")}
          onPress={() => {
            window.open(result?.stripeCustomerPortalUrl, "_blank");
          }}
        />
      ) : !result ? null : isFree ? (
        <Text>{t("billing.workspaceFree")}</Text>
      ) : (
        <Text>{t("billing.stripeNotConfigured")}</Text>
      )}
      {isSuperAdmin && (
        <section className={classes.superadminSection}>
          <Text size="SMALL">Support</Text>
          <DateInput
            label={t("billing.trialInput")}
            value={result?.trialPeriodEnd}
            onChange={setPoolTrial}
          />
          <KDropdown
            variant="dark"
            selectedItemIds={[result?.restrictionScheme || "automatic"]}
            items={[
              {
                label: `Restriction: Automatique (${result?.billingRestriction})`,
                value: "automatic",
              },
              {
                label: "Restriction: Restreint",
                value: "restricted",
              },
              {
                label: "Restriction: Non Restreint",
                value: "unrestricted",
              },
            ]}
            onSelectionChange={(ids) => ids?.[0] && setRestriction(ids[0])}
          />
          <KInput
            label={t("billing.subscriptionId")}
            placeholder={t("billing.subscriptionId")}
            defaultValue={result?.stripeSubscriptionId}
            onChangeText={() => {}}
            onBlur={(e) => setSubscriptionId(e?.nativeEvent.text)}
            showEditIcon
          />
        </section>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  superadminSection: {
    display: "flex",
    flexDirection: "column",
    gap: Spacing.S8,
    marginTop: Spacing.S16,
    paddingTop: Spacing.S16,
    borderTop: `1px solid ${ColorStyle.SEPARATOR}`,
  },
});

export { ManageBilling };
