import { call, put, select, spawn, takeEvery } from "typed-redux-saga/macro";

import {
  showError,
  showInfo,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { selectDirectory } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { setLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { fillDirectoryFromFileDropSaga } from "@kraaft/shared/core/modules/message/attach/fillDirectoryWithFile.saga";
import { fillDirectoryFromMessageDropSaga } from "@kraaft/shared/core/modules/message/attach/fillDirectoryWithMessage.saga";
import { OfflineModularFolderSelectors } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { SuccessRate } from "@kraaft/shared/core/modules/modularFolder/modularFolderSagas";
import * as schemaSel from "@kraaft/shared/core/modules/schema/schema.selectors";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { navigationService } from "@kraaft/shared/core/services/navigation/provider";
import { GlobalOfflineReduxBundle } from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle.init";
import { waitFor } from "@kraaft/shared/core/utils/sagas";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { TrackTypeAttachSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

import * as messageActions from "../messageActions";
import * as messageSel from "../messageSelectors";
import * as MessageTypes from "../messageState";
import * as actions from "./attachMessageActions";

export function* attachMessageSagas() {
  yield* takeEvery(
    actions.createFolderFromSelection,
    createFolderFromSelectionSaga,
  );
  yield* takeEvery(
    actions.attachToFolderFromSelection,
    attachToFolderFromSelectionSaga,
  );
  yield* takeEvery(
    actions.attachInDirectoryFromSelection,
    attachInDirectoryFromSelectionSaga,
  );
  yield* takeEvery(actions.unflagMessage, unflagMessageSaga);
  yield* takeEvery(
    actions.removeMessagesFromDirectory,
    removeMessagesFromDirectorySaga,
  );

  yield* spawn(fillDirectoryFromMessageDropSaga);
  yield* spawn(fillDirectoryFromFileDropSaga);
}

export function* unflagMessageSaga(
  action: ReturnType<typeof actions.unflagMessage>,
) {
  const { roomId, messageId } = action.payload;

  yield Api.unflagModularFoldersMessage({
    roomId,
    messageId,
  });
}

type Payload =
  | {
      successRate: SuccessRate;
      attachmentType: "attachToModularFolderAlert";
      folderType: "newFolder" | "existingFolder";
    }
  | {
      successRate: SuccessRate;
      attachmentType: "attachInDirectoryAlert";
      folderType: undefined;
    };

function getAttachmentPrefix({ attachmentType, folderType }: Payload) {
  if (attachmentType === "attachInDirectoryAlert") {
    return "attachInDirectoryAlert" as const;
  }
  if (attachmentType === "attachToModularFolderAlert") {
    return `attachToModularFolderAlert.${folderType}` as const;
  }
}

function* attachToAlertsBySuccessRate(payload: Payload) {
  const { successRate } = payload;
  const prefix = getAttachmentPrefix(payload);

  if (successRate === SuccessRate.TOTAL && prefix) {
    yield* put(
      showSuccess({
        title: i18n.t("alert.titleConfirmation"),
        message: i18n.t(`${prefix}.totalSuccess`),
      }),
    );
  }
  if (successRate === SuccessRate.PARTIAL && prefix) {
    yield* put(showInfo({ title: i18n.t(`${prefix}.partialSuccess`) }));
  }
  if (successRate === SuccessRate.NONE && prefix) {
    yield* put(showError({ title: i18n.t(`${prefix}.error`) }));
  }
}

function* setMessageSelectionProperties(
  roomId: string,
  selectionType: MessageTypes.MessageSelectionType,
  status: MessageTypes.MessageSelectionStatus,
) {
  yield* put(
    messageActions.setMessageSelectionProperties({
      roomId,
      selectionType,
      status,
    }),
  );
}

function* resetFlow(
  roomId: string,
  selectionType: MessageTypes.MessageSelectionType,
) {
  yield* put(messageActions.deselectMessage({ roomId, all: true }));
  yield* setMessageSelectionProperties(roomId, selectionType, undefined);
}

export function* createFolderFromSelectionSaga(
  action: ReturnType<typeof actions.createFolderFromSelection>,
) {
  const { roomId, schemaId } = action.payload;

  const messageIds = yield* select(
    messageSel.selectMessageSelectionAsArray(roomId, undefined),
  );

  if (messageIds.length > 0) {
    const currentLocation = navigationService.getLocation();

    try {
      const { folderId, successRate } = yield* call(Api.addModularFolder, {
        roomId,
        schemaId,
        title: undefined,
        messageIds,
      });

      yield* waitFor(OfflineModularFolderSelectors.select(folderId));

      yield* attachToAlertsBySuccessRate({
        successRate,
        attachmentType: "attachToModularFolderAlert",
        folderType: "newFolder",
      });

      yield* put(actions.createFolderFromSelectionSuccess(action.meta));

      if (currentLocation === navigationService.getLocation()) {
        navigationService.navigate("ModularFolderDetails", {
          roomId,
          folderId,
          schemaId,
          animateBack: false,
        });
      }
    } catch (error) {
      yield* setMessageSelectionProperties(roomId, "attach", undefined);
      yield* put(
        showError({
          title: i18n.t("attachToModularFolderAlert.newFolder.error"),
        }),
      );
      yield* put(actions.createFolderFromSelectionFailure(error, action.meta));
    }
    yield* resetFlow(roomId, "attach");
  }
}

export function* attachToFolderFromSelectionSaga(
  action: ReturnType<typeof actions.attachToFolderFromSelection>,
) {
  const {
    roomId,
    schemaId,
    folderId: maybeNotPersistedFolderId,
    folderTitle,
    isRoomExternal,
    columnKey,
  } = action.payload;
  const folderId = yield* select(
    GlobalOfflineReduxBundle.selectCorrespondingId(maybeNotPersistedFolderId),
  );

  const schemaName = yield* select(schemaSel.selectSchemaName(schemaId));

  const messageIds = yield* select(
    messageSel.selectMessageSelectionAsArray(roomId, undefined),
  );

  if (messageIds.length > 0) {
    const selectionSource = yield* select(
      messageSel.selectMessageSelectionSource(roomId),
    );

    const trackingSource = selectionSourceToTrackingSource(selectionSource);

    trackEvent({
      eventName: "Attach To Object Record",
      room_id: roomId,
      element_count: messageIds.length,
      from: trackingSource,
      schema_id: schemaId,
      schema_name: schemaName ?? "",
      record_title: folderTitle,
      media_extension: "unknown",
      is_shared: isRoomExternal,
    });

    const currentLocation = navigationService.getLocation();

    try {
      const { successRate } = yield Api.addModularFolderAttachmentsFromMessages(
        {
          roomId,
          folderId,
          messageIds,
          columnKey,
        },
      );

      yield* attachToAlertsBySuccessRate({
        successRate,
        attachmentType: "attachToModularFolderAlert",
        folderType: "existingFolder",
      });

      yield* put(actions.attachToFolderFromSelectionSuccess(action.meta));
    } catch (error) {
      yield* setMessageSelectionProperties(roomId, "attach", undefined);

      let title: string;
      let message: string | undefined;
      if (
        HttpError.isHttpErrorWithCode(error, "AttachmentCountExceededError")
      ) {
        title = i18n.t("modular_default.uploadError");
        message = i18n.t("modular_default.attachmentCountExceededError");
      } else {
        title = i18n.t("attachToModularFolderAlert.existingFolder.error");
      }

      yield* put(showError({ title, message }));
      yield* put(
        actions.attachToFolderFromSelectionFailure(error, action.meta),
      );
    }
    yield* resetFlow(roomId, "attach");
    if (currentLocation === navigationService.getLocation()) {
      navigationService.navigate("ModularFolderDetails", {
        roomId,
        folderId,
        schemaId,
        animateBack: false,
      });
    }
  }
}

function selectionSourceToTrackingSource(
  selectionSource: MessageTypes.MessageSelectionSource,
): TrackTypeAttachSource {
  if (selectionSource === "photoGallery") {
    return "photo gallery";
  }
  if (selectionSource === "documentGallery") {
    return "document gallery";
  }
  if (selectionSource === "conversation") {
    return "message classification";
  }
  return "unknown";
}

export function* attachInDirectoryFromSelectionSaga(
  action: ReturnType<typeof actions.attachInDirectoryFromSelection>,
) {
  const { roomId, directoryId, loaderId } = action.payload;

  const messageIds = yield* select(
    messageSel.selectMessageSelectionAsArray(roomId, undefined),
  );

  if (messageIds.length === 0) {
    yield* put(setLoader({ id: loaderId, status: LoaderStatus.SUCCESS }));
    return;
  }

  const selectionSource = yield* select(
    messageSel.selectMessageSelectionSource(roomId),
  );

  const directory = yield* select(selectDirectory(directoryId));

  trackEvent({
    eventName: "Attach To Directory",
    room_id: roomId,
    element_count: messageIds.length,
    from: selectionSourceToTrackingSource(selectionSource),
    directory_id: directoryId,
    directory_name: directory?.name ?? "",
    media_extension: "unknown",
  });

  if (messageIds.length > 0) {
    try {
      yield Api.addDirectoryFilesFromMessages({
        roomId,
        directoryId,
        messageIds,
      });

      yield* attachToAlertsBySuccessRate({
        successRate: SuccessRate.TOTAL, // TODO: we are supposed to retrieve this from the api
        attachmentType: "attachInDirectoryAlert",
        folderType: undefined,
      });
      yield* put(actions.attachInDirectoryFromSelectionSuccess(action.meta));
    } catch (error) {
      if (HttpError.isHttpErrorWithCode(error, "AllFilesAlreadyExist")) {
        yield* put(
          showInfo({
            title: i18n.t("attachInDirectoryAlert.allFilesAlreadyExist", {
              count: messageIds.length,
            }),
          }),
        );
      } else if (HttpError.isHttpErrorWithCode(error, "NoSupportedFiles")) {
        yield* put(
          showError({
            title: i18n.t("attachInDirectoryAlert.noSupportedFiles", {
              count: messageIds.length,
            }),
          }),
        );
      } else {
        yield* put(
          showError({
            title: i18n.t("attachInDirectoryAlert.error"),
          }),
        );
      }

      yield* put(
        actions.attachInDirectoryFromSelectionFailure(error, action.meta),
      );
    }
    yield* resetFlow(roomId, "attach");
    navigationService.afterAttachInDirectory(roomId, directoryId);
  }
}

export function* removeMessagesFromDirectorySaga(
  action: ReturnType<typeof actions.removeMessagesFromDirectory>,
) {
  const { roomId, directoryId, messageIds } = action.payload;

  try {
    yield Api.removeDirectoryFiles({
      roomId,
      directoryId,
      messageIds,
    });
    yield* resetFlow(roomId, "notDefined");

    yield* put(actions.removeMessagesFromDirectorySuccess(action.meta));
  } catch (error) {
    yield* put(actions.removeMessagesFromDirectoryFailure(error, action.meta));
  }
}
