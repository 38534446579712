import React, { ElementType } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { Provider as ReduxProvider, useDispatch } from "react-redux";
import { IntercomProvider } from "react-use-intercom";
import { FloatingTree } from "@floating-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import firebase from "firebase/compat/app";

import { AlertDialogView } from "@kraaft/shared/components/alertDialog";
import { DraggableMessageType } from "@kraaft/shared/components/draggableMessage/types";
import { MODULAR_FOLDER_ORDERABLE_LIST } from "@kraaft/shared/components/modularFolderList/modularFolderList.utils";
import { PermissionRequesterContextProvider } from "@kraaft/shared/components/permissionRequester/permissionRequester.context";
import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { ENABLE_REACT_QUERY_DEVTOOLS } from "@kraaft/shared/constants/global";
import {
  showError,
  showInfo,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { AuthProvider } from "@kraaft/shared/core/services/auth/useAuth";
import { getStore } from "@kraaft/shared/core/store";
import { useInitScrollbarWidth } from "@kraaft/shared/core/utils/useInitScrollbarWidth";
import { ContextMenuProvider, PortalHost, PortalProvider } from "@kraaft/ui";
import { FOLDER_ITEM_TYPE } from "@kraaft/web/src/components/directoryTree/directoryTree.types";
import { ORDERABLE_LIST_ROW_TYPE } from "@kraaft/web/src/components/orderableList/orderableList.utils";
import { FormBuilderDropTypes } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";
import { navigationService } from "@kraaft/web/src/core/services/navigation/navigationServiceProvider";
import { useNavigationInit } from "@kraaft/web/src/views/app/useNavigationInit";

import { AppRouter } from "./appRouter";
import { AppTheme } from "./appTheme";

import "./__assets__/index.css";

const dropToasts = {
  noError: [
    FormBuilderDropTypes.REORDER,
    FormBuilderDropTypes.INSERT,
    ORDERABLE_LIST_ROW_TYPE,
    MODULAR_FOLDER_ORDERABLE_LIST,
  ],
  info: {
    [FOLDER_ITEM_TYPE]: "directory.dropMoveFailed",
  },
  error: {
    [DraggableMessageType.MessageText]: "folderDropTextLinkedFailed",
    [DraggableMessageType.MessageImage]: "folderDropPhotoLinkedFailed",
    [DraggableMessageType.MessageVideo]: "folderDropVideoLinkedFailed",
    [DraggableMessageType.MessageDocument]: "folderDropDocumentLinkedFailed",
  },
} as const;

const dropToastInfoKeys = Object.keys(dropToasts.info);
const dropToastErrorKeys = Object.keys(dropToasts.error);

type DropToastInfoType = `${keyof typeof dropToasts.info}`;
type DropToastErrorType = `${keyof typeof dropToasts.error}`;

const ReactQueryDevtools = React.lazy(() =>
  import("@tanstack/react-query-devtools").then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

const DroppableApp = () => {
  useInitScrollbarWidth();
  useNavigationInit(navigationService);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [, drop] = useDrop({
    accept: [
      ...dropToastErrorKeys,
      ...dropToastInfoKeys,
      ...dropToasts.noError,
    ],
    drop: (item, monitor) => {
      const type = monitor.getItemType();

      if (!monitor.didDrop() && type && typeof type === "string") {
        if (dropToastInfoKeys.includes(type)) {
          const key = dropToasts.info[type as DropToastInfoType];
          if (key) {
            dispatch(showInfo({ title: t(key) }));
          }
        }
        if (dropToastErrorKeys.includes(type)) {
          const key = dropToasts.error[type as DropToastErrorType];
          if (key) {
            dispatch(showError({ title: t(key) }));
          }
        }
      }
    },
  });

  return (
    // eslint-disable-next-line no-inline-styles/no-inline-styles
    <div ref={drop} style={{ height: "100%" }}>
      <AppTheme>
        <FloatingTree>
          <PortalProvider>
            <PermissionRequesterContextProvider>
              <ContextMenuProvider>
                <IntercomProvider appId={getEnvironment().INTERCOM.APP_ID}>
                  <AlertDialogView />
                  <AppRouter />
                  <PortalHost />
                </IntercomProvider>
              </ContextMenuProvider>
            </PermissionRequesterContextProvider>
          </PortalProvider>
        </FloatingTree>
      </AppTheme>
    </div>
  );
};

const queryClient = new QueryClient();

const App: ElementType = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={getStore()}>
        <AuthProvider>
          <DndProvider backend={HTML5Backend}>
            <DroppableApp />
          </DndProvider>
        </AuthProvider>
      </ReduxProvider>
      {ENABLE_REACT_QUERY_DEVTOOLS && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtools />
        </React.Suspense>
      )}
    </QueryClientProvider>
  );
};

// This is for tests only
if (navigator.webdriver) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).store = getStore();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).firebase = firebase;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).scriptsLoaded = true;
}

export { App };
