import { useCallback } from "react";
import { Dimensions, GestureResponderEvent, Platform } from "react-native";

import { AllEmojisSheet } from "@kraaft/shared/components/message/emojiPicker/allEmojisSheet";
import {
  BaseEmojis,
  BaseEmojisWidth,
} from "@kraaft/shared/components/message/emojiPicker/baseEmojis";

interface EmojiPickerProps {
  onEmojiPicked: (emoji: string) => void;
  selectedEmoji?: string;
  evenlySpaced?: boolean;
  initialAnimationDelay?: number;
}

const EmojiPicker = ({
  onEmojiPicked,
  selectedEmoji,
  evenlySpaced,
  initialAnimationDelay = 0,
}: EmojiPickerProps) => {
  const { element, open } = AllEmojisSheet.use({
    onEmojiSelected: onEmojiPicked,
    offsetSpacing: "S12",
    placement: "top-end",
    height: Platform.select({
      web: 300,
      default: 400,
    }),
    width: Platform.select({
      web: BaseEmojisWidth,
      default: Dimensions.get("screen").width,
    }),
  });

  const handlePressAll = useCallback(
    (event: GestureResponderEvent) => {
      open({ anchor: { current: event.currentTarget } });
    },
    [open],
  );

  return (
    <>
      <BaseEmojis
        onEmojiPicked={onEmojiPicked}
        onPressAll={handlePressAll}
        evenlySpaced={evenlySpaced}
        initialAnimationDelay={initialAnimationDelay}
        selectedEmoji={selectedEmoji}
      />
      {element}
    </>
  );
};

export { EmojiPicker };
