import { useSelector } from "react-redux";

import { selectCurrentContextAtLeastStandardOrSuperadmin } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { selectRoomIsExternal } from "@kraaft/shared/core/modules/room/roomSelectors";

export function useContextShowExternalBanner(
  roomId: string | undefined,
): boolean {
  const isAtLeastStandardOrSuperadmin = useSelector(
    selectCurrentContextAtLeastStandardOrSuperadmin,
  );
  const isExternal = useSelector(selectRoomIsExternal(roomId));
  return isExternal && isAtLeastStandardOrSuperadmin;
}
