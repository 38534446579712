import { useTranslation } from "react-i18next";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { KSchemaIcon } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { IconChooser } from "@kraaft/web/src/components/schemaBuilder/tabs/editMetadata/schemaNameInput/iconChooser";

import { nativeStyle, useStyles } from "./schemaNameInput.styles";

interface SchemaNameInputProps {
  name: string;
  editNameDisabled?: boolean;
  onNameChange: (newName: string) => void;

  icon?: KSchemaIcon;
  editIconDisabled?: boolean;
  onIconChange?: (newIcon: KSchemaIcon) => void;

  onSubmit?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const SchemaNameInput = ({
  name,
  editNameDisabled,
  onNameChange,
  icon,
  editIconDisabled,
  onIconChange,
  onSubmit,
  onBlur,
  onFocus,
}: SchemaNameInputProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {icon && onIconChange && (
        <IconChooser
          disabled={editIconDisabled}
          value={icon}
          onChange={onIconChange}
        />
      )}
      <KInput
        disabled={editNameDisabled}
        style={nativeStyle.input}
        label={t("schemaNameLabel")}
        nativeID="ide2e-schema-name"
        value={name}
        onChangeText={onNameChange}
        showEditIcon
        onSubmitEditing={onSubmit}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  );
};

export { SchemaNameInput };
