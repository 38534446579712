import { MediaViewerAttachment } from "@kraaft/shared/components/mediaViewer/types";
import { isMiniMedia } from "@kraaft/shared/core/modules/miniMedia/miniMedia.utils";
import { GeoLocation } from "@kraaft/shared/core/types";

export const shouldLoadMedia = (index: number, activeIndex: number) =>
  activeIndex - 1 <= index && index <= activeIndex + 1;

export function getMediaGeolocation(
  media: MediaViewerAttachment,
): GeoLocation | undefined {
  if (isMiniMedia(media)) {
    if (media.type === "image") {
      return media.geolocation;
    }
    return undefined;
  }
  switch (media.type) {
    case "image":
    case "video":
      return media.geolocation;
  }
}
