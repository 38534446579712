import { createReducer } from "@reduxjs/toolkit";
import keyBy from "lodash/keyBy";

import { PoolState } from "@kraaft/shared/core/modules/pool/poolState";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import * as actions from "./poolActions";

const initialState: PoolState = {
  startPoolId: undefined,
  currentLocation: undefined,
  locations: undefined,
  pools: {},
  unreadPools: {},
  fetchedOnce: false,
};

export const poolReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => {
    return initialState;
  });

  addCase(actions.PoolStateActions.setPools, (state, { payload }) => {
    const receivedPoolDict = keyBy(payload, (pool) => pool.id);
    state.pools = { ...state.pools, ...receivedPoolDict };
    state.fetchedOnce = true;
  });

  addCase(
    actions.PoolStateActions.setPoolLocation,
    (state, { payload: location }) => {
      state.currentLocation = location;
    },
  );

  addCase(actions.updateLocations, (state, { payload: locations }) => {
    state.locations = locations;
  });

  addCase(actions.setLogo, (state, { payload }) => {
    const { logo, poolId } = payload;
    const { pools } = state;
    const pool = pools[poolId];

    if (!pool) {
      return;
    }

    pools[poolId] = {
      ...pool,
      logoDownloadUrl: logo,
    };
  });

  addCase(actions.startOnPool, (state, { payload: { poolId } }) => {
    state.startPoolId = poolId;
  });

  addCase(actions.updateUserUnreadPools, (state, { payload }) => {
    state.unreadPools = payload.unreadPools;
  });
});
