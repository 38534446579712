import { EventChannel, eventChannel } from "redux-saga";
import { put, takeEvery } from "typed-redux-saga/macro";

import * as actions from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateActions";
import { SchemaTemplate } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

type Meta = EventChannel<SchemaTemplate[]> | undefined;

export function* subscribeToSchemaTemplatesSaga() {
  yield takeCountedDeep(
    actions.SchemaTemplateActions.subscribe,
    actions.SchemaTemplateActions.unsubscribe,
    subscribeToSchemaTemplates,
    unsubscribeFromSchemaTemplates,
    ({ payload: { poolId } }) => poolId,
  );
}

function createSchemaTemplatesChannel(
  poolId: string,
): EventChannel<SchemaTemplate[]> {
  return eventChannel((emit) =>
    Firestore.subscribeToSchemaTemplates(poolId, emit),
  );
}

function* subscribeToSchemaTemplates(
  registerMeta: (meta: Meta) => void,
  {
    payload: { poolId },
  }: ReturnType<(typeof actions.SchemaTemplateActions)["subscribe"]>,
) {
  const channel = createSchemaTemplatesChannel(poolId);
  registerMeta(channel);

  yield* takeEvery(channel, function* (schemaTemplates) {
    yield* put(
      actions.updatePoolSchemaTemplates({
        poolId,
        schemaTemplates,
      }),
    );
  });
}

function* unsubscribeFromSchemaTemplates(meta: Meta) {
  meta?.close();
}
