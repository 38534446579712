import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { Api } from "@kraaft/shared/core/services/api/api";

import { PoolCompanySize } from "./poolCompanySize";

export const usePoolCompanySize = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const poolId = useSelector(selectCurrentPoolId);

  const [isLoading, setLoading] = useState(false);

  const setPoolCompanySize = useCallback(
    async (companySize: string) => {
      setLoading(true);

      try {
        if (!poolId) {
          throw new Error("Unable to resolve current poolId");
        }

        await Api.setPoolCompanySize(poolId, companySize);
      } catch (e) {
        dispatch(
          showError({
            title: t("errorTitle"),
            message: t("poolCompanyIndustry.couldNotUpdate"),
          }),
        );
        console.error(e);
      }

      setLoading(false);
    },
    [poolId, dispatch, t],
  );

  const formatCompanySize = useCallback(
    (companySize: PoolCompanySize) =>
      t(`poolCompanySize.choices.${companySize}`),
    [t],
  );

  return {
    isLoading,
    formatCompanySize,
    setPoolCompanySize,
  };
};
