import { View } from "react-native";

import { LockedAnswersBanner } from "@kraaft/shared/components/modular/details/lockedAnswersBanner";
import { SafeAreaView } from "@kraaft/shared/components/safeAreaView";
import { SignatureSender } from "@kraaft/shared/components/signature/signaturePanel/signatureSender";
import { SignatureViewer } from "@kraaft/shared/components/signature/signatureViewer";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { ImageAttachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { SectionLockInfo } from "@kraaft/shared/core/modules/schema/lockInfo.utils";

import { styles } from "./signaturePanel.styles";

interface SignaturePanelProps {
  attachment: ImageAttachment | undefined;
  onChange: (file: ModernFile<LocalPath> | undefined) => void;
  sectionLockInfo: SectionLockInfo;
}

const SignaturePanel = ({
  attachment,
  onChange,
  sectionLockInfo,
}: SignaturePanelProps) => {
  return (
    <SafeAreaView style={styles.root} edges={["bottom"]}>
      {sectionLockInfo.isLocked && (
        <LockedAnswersBanner
          lockedBy={sectionLockInfo.lockedBy}
          lockedAt={sectionLockInfo.lockedAt}
          mode="singular"
        />
      )}
      <View style={styles.content}>
        {attachment ? (
          <SignatureViewer
            onDelete={() => onChange(undefined)}
            attachment={attachment}
            hideDeleteButton={sectionLockInfo.isLocked}
          />
        ) : (
          <SignatureSender onChange={onChange} />
        )}
      </View>
    </SafeAreaView>
  );
};

export { SignaturePanel };
