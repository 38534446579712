import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { deburr } from "lodash";

import { AiDirectoryApi } from "@kraaft/shared/core/modules/aiDirectory/aiDirectory.api";
import {
  AiCategory,
  PoolAiDirectorySettings,
} from "@kraaft/shared/core/modules/aiDirectory/aiDirectory.hook";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import { ActionSheet } from "@kraaft/ui";

export interface AddDirectoryActionSheetProps {
  poolId: string | undefined;
  roomId: string;
  parentDirectoryId: string;
  directories: Directory[];
  aiDirectorySettings?: PoolAiDirectorySettings;
  onOpenNewDirectorySheet: () => void;
}

function normalize(name: string) {
  const result = deburr(name)
    .toLowerCase()
    .replace(/[^a-z0-9]/g, " ")
    .trim();
  return result;
}

export const AddDirectoryActionSheet =
  ActionSheet().create<AddDirectoryActionSheetProps>(
    ({ ActionSheetItem, ActionSheetContent }) =>
      ({
        onClose,
        roomId,
        poolId,
        parentDirectoryId,
        directories,
        onOpenNewDirectorySheet,
        aiDirectorySettings,
      }) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();

        const [creatingCategory, setCreatingCategory] = useState<string>();

        const onAddCategoryDirectory = async (category: AiCategory) => {
          if (!poolId) {
            return;
          }
          setCreatingCategory(category.id);
          try {
            await AiDirectoryApi.addDirectoryFromAiCategory({
              roomId,
              poolId,
              parentDirectoryId,
              categoryId: category.id,
            });
            onClose();
          } catch (error) {
            console.error("Error adding directory", error);
            dispatch(
              showError({ title: t("errorServer"), message: error.message }),
            );
            setCreatingCategory(undefined);
          }
        };

        const filteredCategories = useMemo(
          () =>
            aiDirectorySettings?.categories.filter((category) => {
              const normalizedSearch = normalize(category.name);

              return directories.every(
                (d) => normalize(d.name) !== normalizedSearch,
              );
            }),
          [aiDirectorySettings?.categories, directories],
        );

        return (
          <ActionSheetContent>
            <ActionSheetItem
              label={t("directoryActions.new")}
              icon="folder-fill"
              onClick={onOpenNewDirectorySheet}
            />
            {filteredCategories?.map((category) => (
              <ActionSheetItem
                key={category.id}
                label={category.name}
                icon="star-06"
                onClick={() => onAddCategoryDirectory(category)}
                loading={category.id === creatingCategory}
                preventAutoClose
              />
            ))}
          </ActionSheetContent>
        );
      },
  );
