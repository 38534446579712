import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  dropdown: {
    width: 200,
  },
  button: {
    marginTop: Spacing.S16,
  },
});
