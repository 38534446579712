import { useCallback, useMemo } from "react";
import { Text, TouchableOpacity, View } from "react-native";

import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";

import { styles } from "./attachmentListItem.styles";

interface Props {
  attachment: Attachment;
  leftComponent?: React.ReactNode;
  extractTitle: (attachment: Attachment) => string;
  rightComponent?: React.ReactNode;
  onClick?: (attachment: Attachment) => void;
}

const AttachmentListItem = ({
  attachment,
  leftComponent,
  extractTitle,
  rightComponent,
  onClick,
}: Props) => {
  const handlePress = useCallback(() => {
    return onClick?.(attachment);
  }, [onClick, attachment]);

  const title = useMemo(
    () => extractTitle(attachment),
    [extractTitle, attachment],
  );

  const content = (
    <View style={styles.content}>
      {leftComponent}
      <Text numberOfLines={1} style={styles.title} ellipsizeMode="tail">
        {title}
      </Text>
      {rightComponent}
    </View>
  );

  if (onClick) {
    return (
      <TouchableOpacity
        accessibilityLabel={title}
        onPress={handlePress}
        style={styles.item}
      >
        {content}
      </TouchableOpacity>
    );
  }
  return <View>{content}</View>;
};

export { AttachmentListItem };
