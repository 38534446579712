import { StyleSheet } from "react-native";

import { URL_REGEX } from "@kraaft/shared/core/utils/urlRegex";
import { Color, type StyledNestedTextMatcher } from "@kraaft/ui";

const styles = StyleSheet.create({
  urlMatchText: {
    pointerEvents: "auto",
  },
});

export const urlMatcher = {
  pattern: URL_REGEX,
  style: {
    color: Color.BLUE_AZURE,
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textDecorationColor: Color.BLUE_AZURE,
  },
  overrideTextProps: {
    style: styles.urlMatchText,
  },
} satisfies Partial<StyledNestedTextMatcher>;
