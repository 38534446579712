import { Dictionary, MarkRequired } from "ts-essentials";

import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import {
  AudioAttachment,
  DocumentAttachment,
  ImageAttachment,
  StorageImagePreview,
  VideoAttachment,
} from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { GeoLocation, GetDiscriminatedType } from "@kraaft/shared/core/types";

export interface MessageState {
  rooms: Dictionary<MessageRoom>;
  messageSelectionByRoom: Dictionary<MessageSelection>;
}

export type MessageSelectionStatus = undefined | "inDialogFlow";

interface MessageSelectionShare {
  selectionType: "share";
  status: MessageSelectionStatus;
}

interface MessageSelectionAttach {
  selectionType: "attach";
  status: MessageSelectionStatus;
}

interface MessageSelectionForward {
  selectionType: "forward";
  status: MessageSelectionStatus;
}

interface MessageSelectionNotDefined {
  selectionType: "notDefined";
  status: MessageSelectionStatus;
}

export type MessageSelectionProperties =
  | MessageSelectionShare
  | MessageSelectionAttach
  | MessageSelectionForward
  | MessageSelectionNotDefined;

export type MessageSelectionType = MessageSelectionProperties["selectionType"];

export type MessageSelectionSource =
  | "conversation"
  | "photoGallery"
  | "documentGallery"
  | "directoryList"
  | undefined;

export type MessageSelection = {
  selection: Record<string, true>;
  selectionSource: MessageSelectionSource;
} & MessageSelectionProperties;

export type MaybeMessageSelection =
  | MessageSelection
  | {
      selectionSource: undefined;
      selectionType: undefined;
      selection: Record<string, never>;
    };

export interface MessageRoom {
  loadingStates: {
    [messageId: string]: true;
  };
  sentMessagesCount: number;
  replyingSourceMessageId: string | null;
}

export enum ReceiptStatus {
  NONE = 0,
  ALL = 1,
  SOME = 2,
}

export type EditableMessage = TextMessage | FileMessage;

export type UserMessage = Exclude<Message, LogMessage>;

// It ensures it is up to date upon adding a new type
const _userMessageRecord: Record<UserMessage["type"], boolean> = {
  audio: true,
  document: true,
  image: true,
  geolocation: true,
  text: true,
  video: true,
} as const;
export const UserMessageTypesSet = new Set(Object.keys(_userMessageRecord));

export type Message =
  | TextMessage
  | FileMessage
  | GeolocationMessage
  | LogMessage;

export type FileMessage =
  | ImageMessage
  | AudioMessage
  | VideoMessage
  | DocumentMessage;

export type MessageWithText =
  | TextMessage
  | AudioMessage
  | ImageMessage
  | VideoMessage;

export type MessageType = "text" | "geolocation" | "log" | FileMessageType;

export type FileMessageType = "image" | "audio" | "video" | "document";

export type MessageSendingStatus =
  | "sending" // being sent or waiting in sending queue
  | "optimistic" // sent but not received from back-end, rendering is still optimistic
  | "persisted" // received from back-end
  | "error"; // error from back-end

export interface BaseMessage {
  id: string;
  type: MessageType;

  senderId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface LastMessage {
  id: string;
  createdAt: Date;
}

export interface MessageReaction {
  reactedAt: Date;
  emoji: string;
}

export interface MessageModularModuleAttribute {
  isFlagged: boolean;
  modularFolders: {
    [recordId: string]: Record<string, unknown>;
  };
}

export interface MessageModularModuleAttributes {
  [schemaId: string]: MessageModularModuleAttribute;
}

export interface BaseUserMessage extends BaseMessage {
  optimisticId: string | undefined;

  answerTo?: string;
  forwarded?: {
    by: string;
    from: string;
  };
  deletedBy?: string;
  deletedAt?: Date;

  modularObjects?: MessageModularModuleAttributes;

  sendingStatus: MessageSendingStatus;
  isReply: boolean;
  reactions: {
    [userId: string]: MessageReaction;
  };
}

export interface TextMessage extends BaseUserMessage {
  type: "text";
  text: InputPartition[];
  textModifiedAt?: Date;
}

export interface ImageMessage extends BaseUserMessage {
  type: "image";
  attachment: ImageAttachment;
}

export interface AudioMessage extends BaseUserMessage {
  type: "audio";
  attachment: AudioAttachment;
}

export interface VideoMessage extends BaseUserMessage {
  type: "video";
  attachment: VideoAttachment;
}

export interface DocumentMessage extends BaseUserMessage {
  type: "document";
  attachment: DocumentAttachment;
}

export interface GeolocationMessage extends BaseUserMessage {
  type: "geolocation";
  geolocation: GeoLocation;
  thumbnail: StorageImagePreview;
}

export type MessageOfType<T extends Message["type"]> = GetDiscriminatedType<
  Message,
  "type",
  T
>;

export type LogMessageEvent =
  | FolderCheckedEvent
  | FolderUncheckedEvent
  | FolderCreatedEvent
  | InternalMemberJoinedPoolEvent
  | WorkflowMessageEvent;

export interface LogMessage extends BaseMessage {
  type: "log";
  event: LogMessageEvent | GroupedEvent<LogMessageEvent["type"]>;
}

export type LogMessageOfType<T extends LogMessage["event"]["type"]> =
  T extends any
    ? LogMessage & { event: Extract<LogMessage["event"], { type: T }> }
    : never;

export interface FolderCheckedEvent {
  type: "folderCompleted";
  folderId: string;
  folderName: string;
  schemaId: string;
  schemaName: string;
  relatedRessourceDeleted: boolean | undefined;
}

export interface FolderUncheckedEvent {
  type: "folderUnchecked";
  folderId: string;
  folderName: string;
  schemaId: string;
  schemaName: string;
  relatedRessourceDeleted: boolean | undefined;
}

export interface FolderCreatedEvent {
  type: "folderCreated";
  folderId: string;
  folderName: string;
  schemaId: string;
  schemaName: string;
  relatedRessourceDeleted: boolean | undefined;
}

export type GroupedEvent<T extends LogMessageEvent["type"]> = T extends any
  ? {
      type: "group";
      groupedBy: T;
      messages: LogMessageOfType<T>[];
    }
  : never;

export interface InternalMemberJoinedPoolEvent {
  type: "internalMemberJoinedPool";
  poolId: string;
  userId: string;
}

export interface WorkflowMessageEvent {
  type: "workflowMessage";
  initiatorId: string;
  text: string;
  source:
    | {
        id: string;
        type: "modularFolder" | "room";
        schemaId: string;
      }
    | undefined;
  relatedRessourceDeleted: boolean | undefined;
}

export type ForwardedMessage = MarkRequired<UserMessage, "forwarded">;
