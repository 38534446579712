import { useDragLayer } from "react-dnd";

import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { useDragLayerStyles } from "@kraaft/web/src/components/dragAndDrop/dragLayerBase.styles";
import { DraggableKanbanItem } from "@kraaft/web/src/components/kanban/board/kanbanBoardTypes";
import { getTranslationStyle } from "@kraaft/web/src/components/kanban/board/kanbanDragLayer/kanbanDragLayer.utils";
import { KanbanCard } from "@kraaft/web/src/components/kanban/card";

import { useStyles } from "./kanbanDragLayer.styles";

interface Props {
  schema: KSchema;
  displayProperties: string[];
  poolMembers: Record<string, User>;
  currencyCode: string;
}

const KanbanDragLayer = (props: Props) => {
  const { schema, displayProperties, poolMembers, currencyCode } = props;
  const classes = useStyles();
  const dragLayerClasses = useDragLayerStyles();

  const { item, currentOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem() as DraggableKanbanItem,
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <div className={dragLayerClasses.layerBase}>
      <div style={getTranslationStyle(currentOffset)}>
        <div className={classes.draggedItem}>
          <KanbanCard
            currencyCode={currencyCode}
            schema={schema}
            modularRecord={item.modularRecord}
            poolMembers={poolMembers}
            displayProperties={displayProperties}
          />
        </div>
      </div>
    </div>
  );
};

export { KanbanDragLayer };
