import { ConditionLibraryForType } from "@kraaft/shared/core/generated/__generated/conditionTypes";
import { normalizeText } from "@kraaft/shared/core/generated/generatedUtils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";

export const shortTextCondition: ConditionLibraryForType<KColumnType.shortText> =
  {
    shortText: {
      contains: {
        translationKey: "contains",
        check: (lhs, rhs) => {
          const lhsValue = normalizeText(lhs.value);
          const rhsValue = normalizeText(rhs.value);

          return lhsValue.indexOf(rhsValue) > -1;
        },
      },
      doesNotContain: {
        translationKey: "doesNotContain",
        check: (lhs, rhs) => {
          const lhsValue = normalizeText(lhs.value);
          const rhsValue = normalizeText(rhs.value);

          return lhsValue.indexOf(rhsValue) === -1;
        },
      },
      is: {
        translationKey: "is",
        check: (lhs, rhs) =>
          normalizeText(lhs.value) === normalizeText(rhs.value),
      },
      isNot: {
        translationKey: "isNot",
        check: (lhs, rhs) =>
          normalizeText(lhs.value) !== normalizeText(rhs.value),
      },
    },
    standalone: {
      isEmpty: {
        translationKey: "isEmpty",
        check: (value) => !value.value,
      },
      isNotEmpty: {
        translationKey: "isNotEmpty",
        check: (value) => Boolean(value.value),
      },
    },
  };
