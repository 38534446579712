import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, FontSize, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: Spacing.S8,
    borderRadius: Radius.SMALL,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    marginTop: Spacing.S16,
    cursor: "pointer",
  },
  selected: {
    background: Color.BLUE_LAVENDER,
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 0,
    marginLeft: Spacing.S16,
  },
  name: {
    flexGrow: 1,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.TITLE,

    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  switch: {
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    width: 144,
    color: ColorStyle.FONT_LOW_EMPHASIS,
    marginRight: Spacing.S16,
  },
  toggleText: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.BODY,
    marginLeft: Spacing.S8,
  },
});
