import React from "react";

/**
 * Function that will simply do a React.memo, but it will preserve the genericity
 *
 * @example
 *```tsx
 * const MyComponent = <T,>(props: { type: T }) => null;
 * const MemoizedMyComponent = React.memo(MyComponent);
 * const BetterMemoizedMyComponent = betterMemo(MyComponent);
 *
 * <MyComponent type="hello" />;
 * //           ^? (property) type: string ✅
 * <MemoizedMyComponent type="hello" />;
 * //                   ^? (property) type: unknown ❌
 * <BetterMemoizedMyComponent type="hello" />;
 * //                         ^? (property) type: string ✅
 *```
 */

export function betterMemo<T extends Parameters<typeof React.memo>>(
  ...args: T
) {
  return React.memo(...(args as Parameters<typeof React.memo>)) as T[0];
}
