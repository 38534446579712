import { CursorSelection } from "@kraaft/shared/core/framework/markedText/cursorSelection";
import { Span } from "@kraaft/shared/core/framework/markedText/span";

export type ContentUpdatedResultAction =
  | { type: "unlink" }
  | { type: "remove"; rangeToRemove: Span };

export abstract class Marker<T extends string> {
  private static uniqueId = 0;
  public readonly id: string;

  constructor(public anchorIndex: number) {
    this.id = (Marker.uniqueId++).toString();
  }

  offsetAnchor(offset: number) {
    this.anchorIndex += offset;
  }

  getRange() {
    return new Span(this.anchorIndex, this.anchorIndex + this.getLength());
  }

  abstract get type(): T;
  abstract getLength(): number;
  abstract renderText(): string;
  abstract updateContent(
    localRange: Span,
    text: string,
    selection: CursorSelection,
  ): ContentUpdatedResultAction | undefined;
}

export type AnyMarker = Marker<string>;
