import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame/kDialogFrame";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button } from "@kraaft/ui";
import { ChangePoolCurrencyDialogContent } from "@kraaft/web/src/views/settings/managePool/settingsCurrency/changePoolCurrencyDialogContent";
import { useAvailableCurrencies } from "@kraaft/web/src/views/settings/managePool/settingsCurrency/useAvailableCurrencies";

import { useStyles } from "../settings.styles";

interface Props {
  pool: Pool | undefined;
}

const SettingsCurrency = (props: Props) => {
  const { pool } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogOpen, openDialog, closeDialog] = useBooleanState(false);
  const currencies = useAvailableCurrencies();

  const selected = useMemo(() => {
    if (pool?.currencyCode) {
      const poolCurrency = pool.currencyCode;
      return currencies.find((currency) => currency.value === poolCurrency)
        ?.label;
    }
  }, [pool, currencies]);

  return (
    <div className={classes.container}>
      <span className={classes.title}>
        {t("adminCurrency.title")}
        {t("colon")}
      </span>
      <span className={classes.subTitle}>{selected}</span>
      <span className={classes.button}>
        <Button
          onPress={openDialog}
          accessibilityLabel={t("edit")}
          text={t("edit")}
          variant="SECONDARY"
          size="SMALL"
        />
      </span>
      {pool && (
        <KDialogFrame open={dialogOpen} onClose={closeDialog}>
          <ChangePoolCurrencyDialogContent pool={pool} onClose={closeDialog} />
        </KDialogFrame>
      )}
    </div>
  );
};

export { SettingsCurrency };
