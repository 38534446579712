import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, FontSize, Radius, Spacing } from "@kraaft/ui";

const ANIMATION_WOBBLE_DURATION = 500;

export const useStyles = makeStyles({
  dropzone: {
    display: "flex",
    justifyContent: "center",
    marginTop: Spacing.S16,
    border: `2px solid ${ColorStyle.ACTION_CTA}`,
    borderRadius: Radius.SMALL,
    boxSizing: "border-box",
    padding: Spacing.S32,
  },

  dropzonContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  filenameContainer: {
    display: "flex",
    alignItems: "center",
    gap: Spacing.S4,
  },

  text: {
    fontSize: FontSize.MEDIUM,
    lineHeight: "17 / 14",
    fontWeight: "normal",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },

  borderAccept: {
    border: `2px solid ${Color.BLUE_AZURE}`,
  },
  borderReject: {
    border: `2px solid ${Color.RED_SAVOY}`,
  },

  withWobbleAnimation: {
    animation: "$wobble",
    animationDuration: `${ANIMATION_WOBBLE_DURATION}ms`,
    animationFillMode: "forwards",
    animationTimingFunction: "quad",
  },

  "@keyframes wobble": {
    "0%, 100%": {
      transform: "translateX(0%)",
      transformOrigin: "50% 50%",
    },
    "15%": {
      transform: "translateX(-4px) rotate(-1deg)",
    },
    "30%": {
      transform: "translateX(calc(4px / 2)) rotate(1deg)",
    },
    "45%": {
      transform: "translateX(calc(-4px / 2)) rotate(calc(-1deg / 1.8))",
    },
    "60%": {
      transform: "translateX(calc(4px / 3.3)) rotate(calc(1deg / 3))",
    },
    "75%": {
      transform: "translateX(calc(-4px / 5.5)) rotate(calc(-1deg / 5))",
    },
  },

  errorLabel: {
    marginTop: Spacing.S8,
  },
  errorBullet: {
    marginLeft: Spacing.S8,
  },
});

export const styles = {
  centeredText: {
    textAlign: "center",
  },
  buttonContainer: {
    marginTop: Spacing.S8,
  },
} as const;
