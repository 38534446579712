import { useSelector } from "react-redux";

import { InviteCollaboratorsProps } from "@kraaft/shared/components/inviteCollaborators/types";
import { selectOnePool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { useInvitationTargetProps } from "@kraaft/shared/core/utils/useInviteLinkMessageConfigGenerator/useInviteLinkMessageConfigGenerator.utils";
import {
  InviteCollaboratorDialog,
  InviteExternalUserDialog,
} from "@kraaft/web/src/components/kDialog/customs";

const getRoomIdFromParams = (props: InviteCollaboratorsProps) =>
  props.type === "room" || props.type === "room-external" ? props.roomId : "";

const InviteCollaborators = (props: InviteCollaboratorsProps) => {
  const { onClose, open } = props;

  const room = useSelector(selectRoom(getRoomIdFromParams(props)));

  const poolId = "poolId" in props ? props.poolId : room?.poolId;
  const pool = useSelector(selectOnePool(poolId));

  const inviteTargetProps = useInvitationTargetProps(props);

  if (!pool) {
    return null;
  }

  if (inviteTargetProps.type === "pool") {
    return (
      <InviteCollaboratorDialog
        pool={pool}
        open={open}
        onClose={onClose}
        invitationTargetParams={inviteTargetProps}
      />
    );
  }

  if (!room) {
    return null;
  }

  if (inviteTargetProps.type === "room") {
    return (
      <InviteCollaboratorDialog
        pool={pool}
        room={room}
        open={open}
        onClose={onClose}
        invitationTargetParams={inviteTargetProps}
      />
    );
  }
  if (inviteTargetProps.type === "room-external") {
    return (
      <InviteExternalUserDialog
        room={room}
        open={open}
        onClose={onClose}
        invitationTargetParams={inviteTargetProps}
      />
    );
  }

  return null;
};

export { InviteCollaborators };
