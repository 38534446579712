import { StyleSheet } from "react-native";

import { AVATAR_CONTAINER_MEDIUM } from "@kraaft/shared/components/avatar/avatar.utils";
import { FontSize, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    height: AVATAR_CONTAINER_MEDIUM + Spacing.S16 * 2,
  },
  listContentContainer: {
    paddingHorizontal: Spacing.S8,
  },
  itemSeparator: {
    width: Spacing.S8,
  },
  itemContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: 70,
  },
  nameContainer: {
    width: "100%",
    marginTop: Spacing.S4,
    alignItems: "center",
  },
  name: {
    fontSize: FontSize.MINI,
    fontFamily: "Apercu",
    textAlign: "center",
  },
});
