import { DraggableMessageType } from "@kraaft/shared/components/draggableMessage/types";
import { DroppableItem } from "@kraaft/shared/components/droppable";
import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import { BrowserPath, ModernFile } from "@kraaft/shared/core/modules/file/file";

export const DIRECTORY_DROP_ACCEPT_TYPE = [
  DraggableMessageType.MessageDocument,
  DraggableMessageType.MessageImage,
  DraggableMessageType.MessageVideo,
];

export interface DirectoryDropHelpers {
  onDrop: (item: DroppableItem) => void;
  onDropFiles: (files: ModernFile<BrowserPath>[]) => void;
}

export interface UseDirectoryDropProps {
  directory: Directory;
  onLoading?: (value: boolean) => void;
}
