import { useState } from "react";
import { Dimensions, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { Button, ButtonProps } from "@kraaft/ui";

import { styles } from "./dialogActionButtons.styles";

interface DialogActionButtonsProps {
  secondaryButton?: ButtonProps;
  primaryButton: ButtonProps;
}

const smallThreshold = 400;
export const DialogActionButtons: React.FC<DialogActionButtonsProps> = ({
  primaryButton,
  secondaryButton,
}) => {
  const [width, setWidth] = useState(Dimensions.get("window").width);
  const isSmall = isNative() || width < smallThreshold;
  const buttonStyle = isSmall ? styles.buttonMobile : {};

  return (
    <View
      style={[
        styles.buttonContainer,
        isSmall ? styles.buttonContainerMobile : {},
      ]}
      onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
    >
      {secondaryButton && (
        <View style={isSmall ? styles.secondaryButtonContainerMobile : {}}>
          <Button
            id="ide2e-dialog-button-1"
            {...secondaryButton}
            style={buttonStyle}
            variant="SECONDARY"
          />
        </View>
      )}
      <View
        style={isSmall ? styles.primaryButtonMobile : styles.primaryButtonWeb}
      >
        <Button
          id="ide2e-dialog-button-0"
          {...primaryButton}
          style={buttonStyle}
          variant="PRIMARY"
        />
      </View>
    </View>
  );
};
