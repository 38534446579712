import { createSelector } from "@reduxjs/toolkit";
import { memoize, orderBy } from "lodash";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  getUserPoolRole,
  isAtLeastPoolAdmin,
  isAtLeastPoolStandard,
  isPoolOwner,
} from "@kraaft/shared/core/modules/pool/poolUtil";
import {
  selectCurrentUser,
  selectCurrentUserIsSuperadmin,
  selectMultipleUsers,
  selectUser,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { RootState } from "@kraaft/shared/core/store";
import { lodashKeyResolver } from "@kraaft/shared/core/utils";

const selectPoolMembersState = (state: RootState) => state.poolMember.members;

export const selectPoolMembers = createSelector(
  selectPoolMembersState,
  (memberDict) =>
    orderBy(Object.values(memberDict), (member) => member.username),
);

export const selectPoolMember = memoize((userId: string) =>
  createSelector(selectPoolMembersState, (members) => members[userId]),
);

// Select roles

export const selectUserRole = memoize(
  (userId: string | undefined, poolId: string | undefined) =>
    createSelector(selectUser(userId), (user): UserPoolRole => {
      return getUserPoolRole(user, poolId);
    }),
  lodashKeyResolver,
);

export const selectUserRoles = memoize(
  (userIds: string[], poolId: string | undefined) =>
    createSelector(selectMultipleUsers(userIds), (users): UserPoolRole[] =>
      users.map((user) => {
        return getUserPoolRole(user, poolId);
      }),
    ),
  lodashKeyResolver,
);

export const selectCurrentUserRole = memoize((poolId: string | undefined) =>
  createSelector(
    selectCurrentUser,
    (currentUser): UserPoolRole => getUserPoolRole(currentUser, poolId),
  ),
);

export const selectCurrentContextRole = createSelector(
  selectCurrentUser,
  selectCurrentPoolId,
  (currentUser, currentPoolId): UserPoolRole => {
    return getUserPoolRole(currentUser, currentPoolId);
  },
);

// Select Role-based permissions

export const selectCurrentContextAtLeastStandard = createSelector(
  selectCurrentUser,
  selectCurrentPoolId,
  (currentUser, currentPoolId): boolean => {
    return isAtLeastPoolStandard(getUserPoolRole(currentUser, currentPoolId));
  },
);

export const selectCurrentContextAtLeastAdmin = createSelector(
  selectCurrentUser,
  selectCurrentPoolId,
  (currentUser, currentPoolId): boolean => {
    return isAtLeastPoolAdmin(getUserPoolRole(currentUser, currentPoolId));
  },
);

export const selectCurrentContextOwner = createSelector(
  selectCurrentUser,
  selectCurrentPoolId,
  (currentUser, currentPoolId): boolean => {
    return getUserPoolRole(currentUser, currentPoolId) === UserPoolRole.OWNER;
  },
);

export const selectCurrentContextAtLeastStandardOrSuperadmin = createSelector(
  selectCurrentUserIsSuperadmin,
  selectCurrentContextAtLeastStandard,
  (isSuperadmin, isAtLeastStandard): boolean => {
    return isSuperadmin || isAtLeastStandard;
  },
);

export const selectCurrentContextAtLeastAdminOrSuperadmin = createSelector(
  selectCurrentUserIsSuperadmin,
  selectCurrentContextAtLeastAdmin,
  (isSuperadmin, isAtLeastAdmin): boolean => {
    return isSuperadmin || isAtLeastAdmin;
  },
);

export const selectCurrentContextOwnerOrSuperadmin = createSelector(
  selectCurrentUserIsSuperadmin,
  selectCurrentContextOwner,
  (isSuperadmin, isOwner): boolean => {
    return isSuperadmin || isOwner;
  },
);

// Current User : using current user and given pool

export const selectCurrentUserAtLeastStandard = memoize(
  (poolId: string | undefined) =>
    createSelector(selectCurrentUser, (currentUser): boolean => {
      return isAtLeastPoolStandard(getUserPoolRole(currentUser, poolId));
    }),
);

export const selectCurrentUserAtLeastAdmin = memoize(
  (poolId: string | undefined) =>
    createSelector(selectCurrentUser, (currentUser): boolean => {
      return isAtLeastPoolAdmin(getUserPoolRole(currentUser, poolId));
    }),
);

export const selectCurrentUserAccountOwner = memoize(
  (poolId: string | undefined) =>
    createSelector(selectCurrentUser, (currentUser): boolean => {
      return isPoolOwner(getUserPoolRole(currentUser, poolId));
    }),
);

export const selectCurrentUserAtLeastStandardOrSuperadmin = memoize(
  (poolId: string | undefined) =>
    createSelector(
      selectCurrentUserIsSuperadmin,
      selectCurrentUser,
      (isSuperadmin, currentUser): boolean => {
        const isAtLeastStandard = isAtLeastPoolStandard(
          getUserPoolRole(currentUser, poolId),
        );
        return isSuperadmin || isAtLeastStandard;
      },
    ),
);

export const selectCurrentUserAtLeastAdminOrSuperadmin = memoize(
  (poolId: string | undefined) =>
    createSelector(
      selectCurrentUserIsSuperadmin,
      selectCurrentUser,
      (isSuperadmin, currentUser): boolean => {
        const isAtLeastAdmin = isAtLeastPoolAdmin(
          getUserPoolRole(currentUser, poolId),
        );
        return isSuperadmin || isAtLeastAdmin;
      },
    ),
);
