import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useValidateWorkspaceName = (name: string) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const errors = [];
    if (name.length < 3) {
      errors.push(t("newWorkspace.validation.nameTooShort"));
      return errors.length === 0 ? undefined : errors;
    }
    if (name.toLocaleLowerCase() !== name) {
      errors.push(t("newWorkspace.validation.nameNotLowerCase"));
    }
    if (!name.match(/^[a-z0-9-]+$/)) {
      errors.push(t("newWorkspace.validation.nameInvalidCharacters"));
    }
    if (!name.match(/^[a-z0-9](.+)?[a-z0-9]$/)) {
      errors.push(t("newWorkspace.validation.nameInvalidStartEnd"));
    }
    return errors.length === 0 ? undefined : errors;
  }, [name, t]);
};

export { useValidateWorkspaceName };
