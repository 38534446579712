import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

const makeDialogStyle = makeStyles(() => ({
  dialogContentContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: Spacing.S16,
  },
  text: {
    fontSize: FontSize.MEDIUM,
    fontWeight: "normal",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  spacer: {
    marginBottom: Spacing.S32,
  },
  spacerTop: {
    marginTop: Spacing.S8,
  },
  spacerBottom: {
    marginBottom: Spacing.S16,
  },
  copiedLabelContainer: {
    display: "flex",
    alignItems: "center",
    paddingRight: 12,
    cursor: "pointer",
  },
  copiedLabelIcon: {
    marginLeft: 6,
  },

  cardsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: Spacing.S16,
    "& > div": {
      flex: 1,
    },
  },
}));

export { makeDialogStyle };
