import { call, put, select, spawn, takeEvery } from "typed-redux-saga/macro";

import { nanoid } from "@kraaft/helper-functions";
import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import { subscribeToSchemaTemplatesSaga } from "@kraaft/shared/core/modules/schemaTemplate/sagas/subscribeToSchemaTemplates";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

import * as actions from "./schemaTemplateActions";
import { RequestSchemaTemplateRenderPayload } from "./schemaTemplateActions";
import {
  selectEditedSchemaTemplate,
  selectSchemaTemplate,
} from "./schemaTemplateSelectors";
import { EditedSchemaTemplate, SchemaTemplate } from "./schemaTemplateState";

export function* schemaTemplateSaga() {
  yield* spawn(subscribeToSchemaTemplatesSaga);
  yield* takeEvery(actions.saveEditSchemaTemplate, saveEditSchemaTemplate);
  yield* takeEvery(actions.deleteSchemaTemplate, deleteSchemaTemplate);
  yield* takeEvery(
    actions.requestSchemaTemplateRender,
    requestSchemaTemplateRender,
  );
  yield* takeEvery(actions.applySchemaTemplates, applySchemaTemplates);
  yield* takeEvery(
    actions.applyRenderUpdatesToSchemaTemplate,
    applyRenderUpdatesToSchemaTemplate,
  );
}

function* saveEditSchemaTemplate() {
  const schemaTemplate = yield* select(selectEditedSchemaTemplate);
  if (!schemaTemplate) {
    return;
  }
  if (schemaTemplate.id) {
    yield* call(saveEditOfExistingSchemaTemplate, {
      ...schemaTemplate,
      id: schemaTemplate.id,
    });
  } else {
    yield* call(createNewSchemaTemplate, schemaTemplate);
  }
}

function* createNewSchemaTemplate(schema: EditedSchemaTemplate) {
  try {
    yield* call(Api.addSchemaTemplate, {
      schemaId: schema.schemaId,
      name: schema.name,
      modifiers: schema.modifiers.filter((mod) => mod.title.value.length > 0),
    });
  } catch (e) {
    console.error(e);
    yield* put(showError({ title: i18n.t("errorServer") }));
  }
}

function* saveEditOfExistingSchemaTemplate(schema: SchemaTemplate) {
  try {
    yield* call(Api.editSchemaTemplate, schema);

    yield* put(showSuccess({ title: i18n.t("editSchemaTemplateSuccess") }));
  } catch (e) {
    console.error(e);
    yield* put(showError({ title: i18n.t("errorServer") }));
  }
}

function* deleteSchemaTemplate({
  payload: { schemaTemplateId },
}: ReturnType<typeof actions.deleteSchemaTemplate>) {
  try {
    yield* call(Api.deleteSchemaTemplate, { id: schemaTemplateId });
  } catch (e) {
    console.error(e);
    yield* put(showError({ title: i18n.t("errorServer") }));
  }
}

function* requestSchemaTemplateRender({
  payload: { schemaTemplateId },
}: {
  payload: RequestSchemaTemplateRenderPayload;
}) {
  const schemaTemplate: ReturnType<ReturnType<typeof selectSchemaTemplate>> =
    yield* select(selectSchemaTemplate(schemaTemplateId));

  if (!schemaTemplate || schemaTemplate.rendered) {
    return;
  }

  try {
    const rendered = yield* call(Api.renderSchemaTemplate, {
      id: schemaTemplateId,
    });
    yield* put(
      actions.setSchemaTemplateRender({
        schemaTemplateId: schemaTemplate.id,
        rendered: rendered,
      }),
    );
  } catch (e) {
    console.error(e);
    yield* put(showError({ title: i18n.t("errorServer"), message: e.message }));
  }
}

function* applySchemaTemplates({
  payload: { schemaTemplateIds, roomId },
}: ReturnType<typeof actions.applySchemaTemplates>) {
  for (const schemaTemplateId of schemaTemplateIds) {
    const schemaTemplate = yield* renderSchema(schemaTemplateId);
    const rendered = schemaTemplate?.rendered;
    if (!rendered) {
      continue;
    }
    const requestId = nanoid();
    yield* call(Api.addModularFolders, {
      requestId,
      roomId,
      schemaId: schemaTemplate.schemaId,
      folders: rendered.map((render) =>
        KSchemaConversion.toRawProperties(render.properties),
      ),
    });
    yield* put(
      actions.setSchemaTemplateRender({
        schemaTemplateId: schemaTemplate.id,
        rendered: undefined,
      }),
    );
  }
}

function* renderSchema(schemaTemplateId: string) {
  let schemaTemplate = yield* select(selectSchemaTemplate(schemaTemplateId));
  if (!schemaTemplate?.rendered) {
    yield* put(actions.requestSchemaTemplateRender({ schemaTemplateId }));
    yield waitFor(
      (state) => selectSchemaTemplate(schemaTemplateId)(state)?.rendered,
    );
    schemaTemplate = yield* select(selectSchemaTemplate(schemaTemplateId));
  }
  return schemaTemplate;
}

function* applyRenderUpdatesToSchemaTemplate({
  payload: { schemaTemplateId },
}: ReturnType<typeof actions.applyRenderUpdatesToSchemaTemplate>) {
  const schemaTemplate = yield* select(selectSchemaTemplate(schemaTemplateId));
  if (!schemaTemplate) {
    return;
  }
  const finalModifiers = schemaTemplate.modifiers.filter(
    (mod) => mod.title.value,
  );
  yield* call(Api.editSchemaTemplate, {
    id: schemaTemplateId,
    modifiers: finalModifiers,
  });
  yield* put(showSuccess({ title: i18n.t("editSchemaTemplateSuccess") }));
}
