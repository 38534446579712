import { PropsWithChildren } from "react";

import { useMeshContext } from "@kraaft/helper-hooks";
import { HideHorizontal } from "@kraaft/ui";
import { VerticalNavigationContext } from "@kraaft/web/src/components/layout/verticalNavigationBar/verticalNavigation.context";

export const CollapsibleNavigationBarElement = ({
  children,
}: PropsWithChildren) => {
  const { isExpanded } = useMeshContext(VerticalNavigationContext);

  return (
    <HideHorizontal hidden={!isExpanded} animationDuration={50}>
      {children}
    </HideHorizontal>
  );
};
