import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View, ViewStyle } from "react-native";

import { Color, ColorStyle, Icon, Radius, Spacing, Text } from "@kraaft/ui";

interface NotReadLineProps {
  style?: ViewStyle;
}

const NotReadLine = ({ style }: NotReadLineProps) => {
  const { t } = useTranslation();

  const finalStyle = useMemo(() => [styles.divider, style], [style]);
  const label = t("messagesNotRead");

  return (
    <View style={finalStyle}>
      <View style={styles.pillWrapper}>
        <View style={styles.textWrapper}>
          <Icon
            name="message-notification-circle"
            size="SMALL"
            color="FONT_HIGH_EMPHASIS"
          />
          <Text style={styles.text}>{label}</Text>
        </View>
      </View>
    </View>
  );
};

export const styles = StyleSheet.create({
  divider: {
    width: "100%",
    borderBottomColor: Color.YELLOW_KRAAFT,
    borderBottomWidth: 1,
  },
  pillWrapper: {
    flexDirection: "row",
    justifyContent: "center",
  },
  textWrapper: {
    borderTopEndRadius: Radius.SMALL,
    borderTopStartRadius: Radius.SMALL,
    backgroundColor: Color.YELLOW_KRAAFT,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: Spacing.S8,
    paddingVertical: Spacing.S4,
  },
  text: {
    backgroundColor: Color.YELLOW_KRAAFT,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    marginLeft: Spacing.S8,
  },
});

export { NotReadLine };
