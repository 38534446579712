import {
  ApplicationVerifier,
  auth,
  signInWithPhoneNumber,
} from "@kraaft/shared/core/services/firebase/sdk";

export type { FirebaseTypes } from "@kraaft/shared/core/services/firebase/sdk";
export type { ApplicationVerifier } from "@kraaft/shared/core/services/firebase/sdk";

export const Firebase = {
  auth,

  signInWithPhoneNumber: (params: {
    phoneNumber: string;
    applicationVerifier: ApplicationVerifier | undefined;
  }) => {
    const { phoneNumber, applicationVerifier } = params;
    return signInWithPhoneNumber(phoneNumber, applicationVerifier);
  },
};

Object.freeze(Firebase);

export type FirebaseServiceType = typeof Firebase;
