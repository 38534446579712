import { Platform } from "react-native";
import Clipboard from "@react-native-community/clipboard";

import { i18n } from "@kraaft/shared/core/services/i18next";
import { shareMessage } from "@kraaft/shared/core/services/share";
import { GeoCoordinates, GeoLocation } from "@kraaft/shared/core/types";
import { formatAddress } from "@kraaft/shared/core/utils/utils";

export function getGoogleLegacyMapsUrlFromCoords(coords: GeoCoordinates) {
  // WARN: we use old, no-official documentation in order to open maps with pin + sattelite view
  // https://stackoverflow.com/a/47051786
  const baseUrl = "https://maps.google.com/maps?t=k";
  return encodeURI(`${baseUrl}&q=loc:${coords.latitude}+${coords.longitude}`);
}

export function getGoogleMapsUrlFromCoords(geolocation: GeoLocation) {
  const baseUrl = "https://www.google.com/maps/search/?api=1";

  if (geolocation.address) {
    return encodeURI(`${baseUrl}&query=${geolocation.address.description}`);
  }
  return encodeURI(
    `${baseUrl}&query=${geolocation.coords.latitude} ${geolocation.coords.longitude}`,
  );
}

export function copyGeolocationToClipboard(geolocation: GeoLocation) {
  const coordinates = `${geolocation.coords.latitude}, ${geolocation.coords.longitude}`;

  Clipboard.setString(coordinates);
}

export async function shareGoogleMapsGeolocation(geolocation: GeoLocation) {
  const url = getGoogleMapsUrlFromCoords(geolocation);

  await shareMessage({
    message: Platform.select({
      ios: formatAddress(geolocation),
      default: `${formatAddress(geolocation)}\n${url}`,
    }),
    title: i18n.t("sharingPosition"),
    url: url,
  });
}
