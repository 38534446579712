import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { AiDirectoryApi } from "@kraaft/shared/core/modules/aiDirectory/aiDirectory.api";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { Button, Sheet, TextInput } from "@kraaft/ui";

export const AddCategorySheet = Sheet({ web: "popup" })
  .create<{ poolId: string }>(
    ({ Paper, Header, Content, Footer }) =>
      ({ onClose, poolId }) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const [name, setName] = useState("");
        const [loading, setLoading] = useState(false);
        const disabled = name.length === 0;

        const save = async () => {
          if (disabled) {
            return;
          }

          setLoading(true);
          try {
            await AiDirectoryApi.addAiCategory({ poolId, name });
            onClose();
          } catch (e) {
            dispatch(
              showError({ title: t("errorServer"), message: e.message }),
            );
            setLoading(false);
          }
        };

        return (
          <Paper>
            <Header onClose={onClose}>{t("aiDirectory.addCategory")}</Header>
            <Content>
              <TextInput
                onChangeText={setName}
                value={name}
                placeholder={t("aiDirectory.categoryPlaceholder")}
                accessibilityLabel={t("aiDirectory.categoryPlaceholder")}
                onSubmitEditing={save}
              />
            </Content>
            <Footer>
              <Button
                onPress={save}
                text={t("validate")}
                loading={loading}
                disabled={disabled}
              />
            </Footer>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "SMALL" });
