import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { editSchemaViewName } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { DirtyableSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewState";
import { stringContainsCharacters } from "@kraaft/shared/core/utils/utils";
import { Button, Sheet } from "@kraaft/ui";

interface EditSchemaViewNamePopupProps {
  onClose: () => void;
  schemaView: DirtyableSchemaView;
}

export const EditSchemaViewNamePopup = Sheet({ web: "popup" })
  .create(
    ({ Paper, Header, Content, Footer }) =>
      ({ onClose, schemaView }: EditSchemaViewNamePopupProps) => {
        const dispatch = useDispatch();
        const { t } = useTranslation();
        const [name, setName] = useState(schemaView.name);

        useEffect(() => {
          setName(schemaView.name);
        }, [schemaView.name]);

        const save = useCallback(() => {
          if (!schemaView.id) {
            return;
          }

          dispatch(
            editSchemaViewName({
              schemaViewId: schemaView.id,
              name,
            }),
          );
          onClose();
        }, [dispatch, name, onClose, schemaView.id]);

        const isSaveDisabled = !stringContainsCharacters(schemaView.name);

        return (
          <Paper>
            <Header onClose={onClose}>{t("renameView")}</Header>
            <Content>
              <KInput
                label={t("schemaViewName")}
                value={name}
                onChangeText={setName}
              />
            </Content>
            <Footer>
              <Button
                text={t("cancel")}
                accessibilityLabel={t("cancel")}
                onPress={onClose}
                variant="QUATERNARY"
              />
              <Button
                text={t("save")}
                accessibilityLabel={t("save")}
                disabled={isSaveDisabled}
                onPress={save}
                variant="PRIMARY"
              />
            </Footer>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "SMALL" });
