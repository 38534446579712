import { useMemo } from "react";
import { useSelector } from "react-redux";

import { compactMap } from "@kraaft/helper-functions";
import { useMeshContext } from "@kraaft/helper-hooks";
import { RoomFiltersContext } from "@kraaft/shared/components/filters/roomFilters.context";
import { selectRoomSchemaLabelsColumn } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { getSelectColumnOption } from "@kraaft/shared/core/modules/schema/schema.utils";

export function useLabelsSummary() {
  const { poolId, labelIds } = useMeshContext(RoomFiltersContext);
  const labelsColumn = useSelector(selectRoomSchemaLabelsColumn(poolId));

  const labelsLabel = useMemo(() => {
    if (labelsColumn === undefined || labelIds.length === 0) {
      return undefined;
    }
    return compactMap(labelIds, (labelId) => {
      const option = getSelectColumnOption(labelsColumn, labelId);

      return option?.label;
    }).join(", ");
  }, [labelsColumn, labelIds]);

  return labelsLabel;
}
