import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { KSelectionListUncontrolled } from "@kraaft/shared/components/kSelectionList/kSelectionListUncontrolled";

type Option = "preserveCaptions" | "dontPreserveCaptions";

interface Props {
  setShouldPreserveCaption: (newState: boolean) => void;
  defaultState?: boolean;
}

const SelectPreserveCaptions = (props: Props) => {
  const { setShouldPreserveCaption, defaultState } = props;
  const { t } = useTranslation();

  const options = useMemo<KSelectableListItem<Option>[]>(
    () => [
      {
        identifier: "preserveCaptions",
        label: t("preserveMediaCaptions"),
      },
      {
        identifier: "dontPreserveCaptions",
        label: t("dontPreserveMediaCaptions"),
      },
    ],
    [t],
  );

  const handleSelect = useCallback(
    ([selectedOption]: Option[]) => {
      setShouldPreserveCaption(selectedOption === "preserveCaptions");
    },
    [setShouldPreserveCaption],
  );

  return (
    <KSelectionListUncontrolled
      items={options}
      onSelect={handleSelect}
      defaultValue={defaultState ? "preserveCaptions" : "dontPreserveCaptions"}
      disableScroll
      minSelection={1}
    />
  );
};

export { SelectPreserveCaptions };
