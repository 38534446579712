import { Color, ColorStyle, FontSize, Radius, Spacing } from "@kraaft/ui";

export const baseStyle = {
  wrapper: {
    backgroundColor: Color.WHITE,
    paddingHorizontal: Spacing.S16,
    paddingVertical: Spacing.S8,
    borderRadius: Radius.SMALL,
    flex: 1,
    maxHeight: 54,
  },
  label: {
    marginBottom: 2,
    display: "flex" as const,
    flexDirection: "row" as const,
    justifyContent: "space-between" as const,
  },
  labelText: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
    fontFamily: "Apercu" as const,
    fontWeight: "400" as const,
    fontSize: FontSize.SMALL,
  },
  labelIcon: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  text: {
    fontFamily: "Apercu" as const,
    fontWeight: "400" as const,
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    maxWidth: 130,
    paddingVertical: Spacing.NONE, // needed on android
  },
};
