import { makeStyles } from "@mui/styles";

import { nativeStylesObject } from "@kraaft/shared/components/kDialog/kDialogContent/kDialogContent.styles.native";
import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  ...nativeStylesObject,
  fullscreenHeader: {
    height: 48,
    flexShrink: 0,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fullscreenHeaderSeparator: {
    height: 1,
    width: "100%",
    backgroundColor: ColorStyle.SEPARATOR,
  },
  fullscreenHeaderTitle: {
    fontSize: FontSize.TITLE,
    fontFamily: "Apercu",
    fontWeight: 700,
  },
  title: {
    ...nativeStylesObject.title,
    textAlign: "left",
    padding: `${Spacing.NONE}px !important`,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    "& .MuiTypography-h6": {
      fontSize: FontSize.H1,
      letterSpacing: -0.163333,
    },
  },
  subtitle: {
    ...nativeStylesObject.subtitle,
    textAlign: "left",
    whiteSpace: "pre-line",
  },
  content: {
    ...nativeStylesObject.content,
    display: "flex",
    overflow: "auto",
    padding: ({
      fullScreen,
    }: {
      fullScreen?: boolean;
      showBottomBar?: boolean;
    }) => (fullScreen ? Spacing.S24 : Spacing.NONE),
    paddingBottom: ({ showBottomBar }: { showBottomBar?: boolean }) =>
      showBottomBar ? Spacing.NONE : Spacing.S16,
  },
  actionsPadding: {
    ...nativeStylesObject.actionsPadding,
    paddingTop: ({ fullScreen }: { fullScreen?: boolean }) =>
      fullScreen ? Spacing.NONE : Spacing.S32,
  },
  actionSpacer: {
    flexGrow: 1,
  },
});
