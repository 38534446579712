import { t } from "i18next";
import { call, put } from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { addSchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { Api } from "@kraaft/shared/core/services/api";
import { navigationService } from "@kraaft/shared/core/services/navigation/provider";

export function* addSchemaViewSaga(action: ReturnType<typeof addSchemaView>) {
  try {
    const { payload } = action;

    const id = yield* call(Api.addSchemaView, {
      name: payload.name,
      schemaId: payload.schemaId,
      filters: payload.filters,
    });
    yield* call(() =>
      navigationService.navigate("SchemaView", {
        schemaViewId: id,
      }),
    );
    yield* put(
      showSuccess({
        title: t("schemaViewCreateSuccessTitle"),
        message: t("schemaViewCreateSuccessContent"),
      }),
    );
  } catch (e) {
    console.error(e);
    yield* put(showError({ title: t("errorServer") }));
  }
}
