import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    overflowY: "hidden",
  },
  mapContainer: {
    height: "100%",
    flex: 1,
    display: "flex",
    position: "relative",
  },
  menuContainer: {
    height: "100%",
    width: 400,
    display: "flex",
    paddingLeft: Spacing.S16,
    paddingRight: Spacing.S16,
  },
});
