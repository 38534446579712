import { StyleSheet, View } from "react-native";

import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { Icon, Spacing, Text } from "@kraaft/ui";

export const OpenWithArrow = () => {
  return (
    <View style={styles.displayModularFolder}>
      <Text size="MEDIUM" weight="bold" color="WHITE">
        <Trans i18nKey="displayModularFolder" />
      </Text>
      <Icon color="WHITE" size="MINI" name="chevron-right" />
    </View>
  );
};

const styles = StyleSheet.create({
  displayModularFolder: {
    justifyContent: "center",
    marginTop: Spacing.S4,
    alignSelf: "center",
    flexDirection: "row",
    gap: Spacing.S4,
    alignItems: "center",
  },
});
