import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { ConfirmationInput } from "@kraaft/shared/components/input/confirmationInput";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { useAlert } from "@kraaft/shared/core/modules/alert/useAlert";

import { styles } from "./deleteConfirmationDialogContent.styles";

interface Props {
  onDelete: () => void | Promise<void>;
  onClose: () => void;
  title: ReactNode;
  subtitle: ReactNode;
  inputLabel: string;
  expectedValue: string;
  deleteButton: string;
  successMessage?: string;
  context: string;
  loading?: boolean;
}

export const DeleteConfirmationDialogContent = ({
  onDelete,
  onClose,
  title,
  subtitle,
  inputLabel,
  expectedValue,
  deleteButton,
  successMessage,
  context,
  loading,
}: Props) => {
  const { t } = useTranslation();
  const { alertError, alertSuccess } = useAlert();
  const [canDelete, setCanDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete();
      onClose();
      if (successMessage) {
        alertSuccess({ message: successMessage });
      }
    } catch (error) {
      alertError(error, context);
    }
    setIsDeleting(false);
  };

  const actions: FooterActions = [
    {
      accessibilityLabel: deleteButton,
      text: deleteButton,
      onPress: handleDelete,
      disabled: !canDelete,
      destructive: true,
      loading: isDeleting || loading,
    },
    {
      accessibilityLabel: t("cancel"),
      text: t("cancel"),
      onPress: onClose,
    },
  ];

  return (
    <KDialogContent
      title={title}
      subtitle={subtitle}
      actions={actions}
      content={
        <View style={styles.flex}>
          <ConfirmationInput
            label={inputLabel}
            expectedValue={expectedValue}
            onValueMatches={setCanDelete}
            autoFocus
          />
        </View>
      }
    />
  );
};
