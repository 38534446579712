import { Api } from "@kraaft/shared/core/services/api";
import { GeoLocation } from "@kraaft/shared/core/types";

export class LocationIQGeocoding {
  static async getGeolocationFromAddress(
    address: string,
  ): Promise<GeoLocation | undefined> {
    try {
      const result = await Api.geocode({ address });
      return result;
    } catch (error) {
      console.log("Error in getGeolocationFromAddress", error);
    }
  }

  static async getGeolocationFromCoordinates(
    coords: {
      latitude: number;
      longitude: number;
    },
    language: string,
  ): Promise<GeoLocation> {
    try {
      const result = await Api.reverseGeocode({ coords, language });
      return result ?? { coords };
    } catch (error) {
      console.log("Error in getGeolocationFromCoordinates", error);
    }

    return { coords };
  }
}
