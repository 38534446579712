import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { useRouteService } from "@kraaft/shared/core/services/navigation";
import { Image, NonAccessibleText } from "@kraaft/ui";

import imageForced from "./assets/password-sent-forced.png";
import imageUser from "./assets/password-sent-user.png";

import { styles } from "./forgotPasswordEmailSent.styles";

const ForgotPasswordEmailSent = () => {
  const { t } = useTranslation();
  const routeService = useRouteService<"ForgotPasswordEmailSent">();

  const { email, mode } = routeService.getParams();

  const { image, title, content } = useMemo(() => {
    switch (mode) {
      case "forced":
        return {
          image: imageForced,
          title: t("forgotPasswordForcedTitle"),
          content: t("forgotPasswordContent"),
        };

      case "user":
        return {
          image: imageUser,
          title: t("forgotPasswordTitle"),
          content: t("forgotPasswordContent"),
        };
    }
  }, [t, mode]);

  return (
    <View style={styles.container}>
      <Image style={styles.imageContainer} source={image} />
      <View>
        <NonAccessibleText
          weight="medium"
          size="H2"
          color="FONT_HIGH_EMPHASIS"
          style={styles.title}
        >
          {title}
        </NonAccessibleText>
        <NonAccessibleText
          weight="light"
          size="BODY"
          color="FONT_LOW_EMPHASIS"
          style={styles.content}
        >
          <Trans
            defaults={content}
            values={{ email }}
            components={{
              bold: (
                <NonAccessibleText
                  weight="bold"
                  size="BODY"
                  color="FONT_LOW_EMPHASIS"
                  style={styles.content}
                />
              ),
            }}
          />
        </NonAccessibleText>
      </View>
    </View>
  );
};

export { ForgotPasswordEmailSent };
