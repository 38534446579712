import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  Condition,
  CoupleRecordCondition,
} from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { UnlabeledTextInput } from "@kraaft/web/src/components/unlabeledTextInput";

interface InputFilterOptionsProps {
  condition: CoupleRecordCondition;
  setCondition: (condition: Condition) => void;
  column: KSchemaColumn;
  valueColumnType:
    | KColumnType.number
    | KColumnType.currency
    | KColumnType.shortText
    | KColumnType.longText;
}

const InputFilterOptions = (props: InputFilterOptionsProps): JSX.Element => {
  const { condition, setCondition, valueColumnType } = props;
  const { t } = useTranslation();

  const defaultValue = useMemo(() => {
    if (
      condition.value.columnType === KColumnType.number ||
      condition.value.columnType === KColumnType.currency
    ) {
      return condition.value.value?.toString();
    }
    if (
      condition.value.columnType === KColumnType.shortText ||
      condition.value.columnType === KColumnType.longText
    ) {
      return condition.value.value;
    }
    return undefined;
  }, [condition.value.columnType, condition.value.value]);

  const handleValueChange = useCallback(
    (text: string) => {
      let _condition: Condition = {
        ...condition,
      };

      if (
        valueColumnType === KColumnType.number ||
        valueColumnType === KColumnType.currency
      ) {
        _condition = {
          ..._condition,
          value: {
            value: text ? Number.parseInt(text, 10) : undefined,
            columnType: valueColumnType,
          },
        };
      } else if (
        valueColumnType === KColumnType.shortText ||
        valueColumnType === KColumnType.longText
      ) {
        _condition = {
          ..._condition,
          value: {
            value: text,
            columnType: valueColumnType,
          },
        };
      } else {
        return;
      }

      setCondition(_condition);
    },
    [condition, setCondition, valueColumnType],
  );

  return (
    <UnlabeledTextInput
      placeholder={t("filterOption")}
      onChangeText={handleValueChange}
      defaultValue={defaultValue}
    />
  );
};

export { InputFilterOptions };
