import { memo, ReactNode } from "react";
import { useDrop } from "react-dnd";

import { FormBuilderDropTypes } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";

import { useStyles } from "./elementsEditorSectionBackdrop.styles";

export type SpaceBehavior = "more" | "less" | "normal";

interface ElementsEditorSectionBackdropProps {
  spaceBehavior: SpaceBehavior;
  children: ReactNode;
}

const ElementsEditorSectionBackdrop_ = ({
  spaceBehavior,
  children,
}: ElementsEditorSectionBackdropProps) => {
  const classes = useStyles();
  const [, sectionRef] = useDrop({
    accept: [FormBuilderDropTypes.REORDER, FormBuilderDropTypes.INSERT],
  });

  return (
    <div
      ref={sectionRef}
      className={classes.root}
      style={{
        paddingBottom: spaceBehavior === "more" ? 64 : undefined,
        marginBottom: spaceBehavior === "less" ? -64 : undefined,
      }}
    >
      {children}
    </div>
  );
};

export const ElementsEditorSectionBackdrop = memo(
  ElementsEditorSectionBackdrop_,
) as typeof ElementsEditorSectionBackdrop_;
