import { useRef } from "react";
import { useSelector } from "react-redux";

import { DirectoryListSelectionFooterProps } from "@kraaft/shared/components/actionCardList/directoryList/directoryListWithSelection/directoryListSelectionFooter/selectionFooterProps";
import { useDirectoryListActions } from "@kraaft/shared/components/actionCardList/directoryList/directoryListWithSelection/useDirectoryListActions";
import {
  DOWNLOAD_DIRECTORY_LIST_LOADER_ID,
  REMOVE_DIRECTORY_LIST_LOADER_ID,
} from "@kraaft/shared/components/actionCardList/directoryList/types";
import { selectMessageSelectionCount } from "@kraaft/shared/core/modules/message/messageSelectors";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { SelectionFooter } from "@kraaft/web/src/components/selectionFooter";

const DirectoryListSelectionFooter = (
  props: DirectoryListSelectionFooterProps,
) => {
  const { roomId, directoryId } = props;

  const selectionCount = useSelector(
    selectMessageSelectionCount(roomId, "directoryList"),
  );
  const moreButtonAnchorRef = useRef(null);

  const [sheetOpen, setSheetOpen, setSheetClosed] = useBooleanState();

  const { actions, options } = useDirectoryListActions({
    roomId,
    directoryId,
    setSheetOpen,
    moreButtonAnchorRef,
    downloadLoaderId: DOWNLOAD_DIRECTORY_LIST_LOADER_ID,
    removeLoaderId: REMOVE_DIRECTORY_LIST_LOADER_ID,
  });

  if (selectionCount === 0) {
    return null;
  }

  return (
    <SelectionFooter
      roomId={roomId}
      selectionCount={selectionCount}
      moreButtonAnchorRef={moreButtonAnchorRef}
      actions={actions}
      options={options}
      sheetOpen={sheetOpen}
      setSheetClosed={setSheetClosed}
    />
  );
};

export { DirectoryListSelectionFooter };
