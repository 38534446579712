import { useCallback } from "react";
import {
  Platform,
  Pressable,
  PressableStateCallbackType,
  StyleSheet,
  Text,
} from "react-native";

import { useMeshContext } from "@kraaft/helper-hooks";
import { EmojiObject } from "@kraaft/shared/components/emojiSelector/emoji.types";
import { charFromEmojiObject } from "@kraaft/shared/components/emojiSelector/emoji.utils";
import { EmojiSelectorContext } from "@kraaft/shared/components/emojiSelector/emojiSelector.context";
import { Color } from "@kraaft/ui";

type EmojiProps = {
  emojiObject: EmojiObject;
  emojiWidth: number;
  onPress?: (emoji: string) => void;
};

export const Emoji = ({ emojiObject, onPress, emojiWidth }: EmojiProps) => {
  const { selectedSkinToneVariant } = useMeshContext(EmojiSelectorContext);

  const emojiCharacter = charFromEmojiObject(
    emojiObject,
    selectedSkinToneVariant,
  );

  const handlePress = useCallback(() => {
    onPress?.(emojiCharacter);
  }, [emojiCharacter, onPress]);

  const getContainerStyle = useCallback(
    ({ pressed }: PressableStateCallbackType) => [
      styles.container,
      { width: emojiWidth, aspectRatio: 1 },
      pressed && styles.containerPressed,
    ],
    [emojiWidth],
  );

  return (
    <Pressable
      accessibilityLabel="emoji"
      onPress={handlePress}
      disabled={!onPress}
      style={getContainerStyle}
    >
      <Text style={styles.emoji} selectable={false}>
        {emojiCharacter}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  containerPressed: {
    opacity: 0.75,
    transform: [{ scale: 0.95 }],
  },
  emoji: {
    width: "100%",
    textAlign: "center",
    fontSize: Platform.select({ android: 25, default: 30 }),
    color: Color.BLACK,
  },
});
