import { ReactNode } from "react";

export enum DraggableMessageType {
  MessageDocument = "message-document",
  MessageImage = "message-image",
  MessageVideo = "message-video",
  MessageText = "message-text",
}

export interface DraggableMessageProps {
  messageType: DraggableMessageType;
  messageId: string;
  renderText?: () => string;
  style?: React.CSSProperties;
  id?: string;
  children?: ReactNode;
}
