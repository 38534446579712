import { useMemo } from "react";

import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import {
  BaseDirectoryTreeProps,
  DirectoryTree,
} from "@kraaft/web/src/components/directoryTree/directoryTree";
import { normalizeRoomDirectories } from "@kraaft/web/src/components/directoryTree/directoryTree.utils";

interface Props extends BaseDirectoryTreeProps {
  directories: Directory[];
}

const RoomDirectoryTree = (props: Props) => {
  const { directories, ...otherProps } = props;

  const directoryTreeStructure = useMemo(
    () => normalizeRoomDirectories(directories),
    [directories],
  );

  return (
    <DirectoryTree
      directoryTreeStructure={directoryTreeStructure}
      {...otherProps}
    />
  );
};

export { RoomDirectoryTree };
