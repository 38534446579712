import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  downloadMediaSelection,
  shareMediaSelection,
} from "@kraaft/shared/core/modules/media/media.actions";
import { removeMessagesFromDirectory } from "@kraaft/shared/core/modules/message/attach/attachMessageActions";
import { setMessageSelectionProperties } from "@kraaft/shared/core/modules/message/messageActions";
import { selectMessageSelectionAsArray } from "@kraaft/shared/core/modules/message/messageSelectors";
import { MessageSelectionSource } from "@kraaft/shared/core/modules/message/messageState";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export const useGalleryFooterActions = ({
  roomId,
  selectionSource,
  downloadLoaderId,
  removeLoaderId,
}: {
  roomId: string;
  selectionSource: MessageSelectionSource;
  downloadLoaderId?: string;
  removeLoaderId?: string;
}) => {
  const dispatch = useDispatch();

  const selection = useSelector(
    selectMessageSelectionAsArray(roomId, selectionSource),
  );

  const onDownload = useCallback(() => {
    if (selectionSource === "photoGallery") {
      trackEvent({
        eventName: "Download Bulk Photos",
        room_id: roomId,
        photo_count: selection.length,
      });
    }
    if (selectionSource === "documentGallery") {
      trackEvent({
        eventName: "Download Bulk Documents",
        room_id: roomId,
        document_count: selection.length,
      });
    }
    dispatch(
      downloadMediaSelection({
        roomId,
        messageIds: selection,
        loaderId: downloadLoaderId ?? `downloadLoader-${Date.now()}`,
      }),
    );
  }, [dispatch, downloadLoaderId, roomId, selection, selectionSource]);

  const onShare = useCallback(() => {
    if (selectionSource === "photoGallery") {
      trackEvent({
        eventName: "Share Bulk Photos",
        room_id: roomId,
        photo_count: Object.keys(selection).length,
      });
    }
    if (selectionSource === "documentGallery") {
      // TODO TrackDataShareBulkDocuments
      // TODO NNG : will this be used ?
    }
    dispatch(
      shareMediaSelection({
        roomId,
        messageIds: selection,
      }),
    );
  }, [dispatch, roomId, selection, selectionSource]);

  const onAttach = useCallback(() => {
    dispatch(
      setMessageSelectionProperties({
        roomId,
        selectionType: "attach",
        status: "inDialogFlow",
      }),
    );
  }, [dispatch, roomId]);

  const onForward = useCallback(() => {
    dispatch(
      setMessageSelectionProperties({
        roomId,
        selectionType: "forward",
        status: "inDialogFlow",
      }),
    );
  }, [dispatch, roomId]);

  const onRemoveFromDirectory = useCallback(
    (directoryId: string) => {
      dispatch(
        removeMessagesFromDirectory({
          roomId,
          directoryId: directoryId,
          messageIds: selection,
          loaderId: removeLoaderId ?? `removeLoader-${Date.now()}`,
        }),
      );
    },
    [dispatch, removeLoaderId, roomId, selection],
  );

  return { onDownload, onShare, onAttach, onForward, onRemoveFromDirectory };
};
