import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useDirectoryMenuActions } from "@kraaft/shared/components/directory/editDirectoryMenu/useDirectoryMenuActions";
import { RenameDirectorySheet } from "@kraaft/shared/components/directory/renameDirectorySheet";
import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import {
  ActionSheetContent,
  ActionSheetHost,
  ActionSheetItem,
} from "@kraaft/ui";

interface Props {
  roomId: string;
  directory: Directory;
  disabled?: boolean;
}
export const EditDirectoryMenu = (props: Props) => {
  const { roomId, directory, disabled } = props;
  const menuButtonRef = useRef(null);
  const [isMenuOpen, openMenu, closeMenu] = useBooleanState();
  const [isRenameDialogOpen, openRenameDialog, closeRenameDialog] =
    useBooleanState();
  const { t } = useTranslation();

  const handleOpenRenameDialog = useCallback(() => {
    closeMenu();
    openRenameDialog();
  }, [closeMenu, openRenameDialog]);

  return (
    <>
      <HeaderIconButton
        accessibilityLabel={t("menu")}
        ref={menuButtonRef}
        icon="dots-vertical"
        onPress={openMenu}
        disabled={disabled}
      />
      <RenameDirectorySheet
        roomId={roomId}
        directory={directory}
        open={isRenameDialogOpen}
        onClose={closeRenameDialog}
      />
      <ActionSheetHost
        open={isMenuOpen}
        onClose={closeMenu}
        anchor={menuButtonRef}
      >
        <ActionSheetComposition
          roomId={roomId}
          directory={directory}
          onOpenRenameDirectoryDialog={handleOpenRenameDialog}
          onClose={closeMenu}
        />
      </ActionSheetHost>
    </>
  );
};

type ActionSheetContentProps = {
  roomId: string;
  directory: Directory;
  onOpenRenameDirectoryDialog(): void;
  onClose: () => void;
};

const ActionSheetComposition = ({
  roomId,
  directory,
  onOpenRenameDirectoryDialog,
  onClose,
}: ActionSheetContentProps) => {
  const { options } = useDirectoryMenuActions({
    roomId,
    directory,
    onOpenRenameDirectoryDialog,
    onPromptDelete: onClose,
  });

  return (
    <ActionSheetContent>
      {options.map((option) => (
        <ActionSheetItem {...option} key={option.label} />
      ))}
    </ActionSheetContent>
  );
};
