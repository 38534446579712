import { attachmentConditions } from "@kraaft/shared/core/generated/__generated/attachment";
import { checkboxCondition } from "@kraaft/shared/core/generated/__generated/checkbox";
import { currencyCondition } from "@kraaft/shared/core/generated/__generated/currency";
import { dateCondition } from "@kraaft/shared/core/generated/__generated/date";
import { geolocationCondition } from "@kraaft/shared/core/generated/__generated/geolocation";
import { joinCondition } from "@kraaft/shared/core/generated/__generated/join";
import { longTextCondition } from "@kraaft/shared/core/generated/__generated/longText";
import { numberCondition } from "@kraaft/shared/core/generated/__generated/number";
import { roomPropertyCondition } from "@kraaft/shared/core/generated/__generated/roomProperty";
import {
  selectMultipleCondition,
  selectSingleCondition,
} from "@kraaft/shared/core/generated/__generated/select";
import { shortTextCondition } from "@kraaft/shared/core/generated/__generated/shortText";
import { signatureCondition } from "@kraaft/shared/core/generated/__generated/signature";
import { userCondition } from "@kraaft/shared/core/generated/__generated/user";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { AnyUnexplained } from "@kraaft/shared/core/types";

import { ConditionLibraryForType, MetadataConditions } from "./conditionTypes";

export const IS_NOT_ARCHIVED_PREDICATE = "isNotInArchivedRoom";

export const metadataConditions: MetadataConditions = {
  folder: {
    standalone: {
      [IS_NOT_ARCHIVED_PREDICATE]: {
        translationKey: "isNotArchived",
        check: ({ room }) => !room || !room.isArchivedForAll,
      },
    },
    join: {
      isInRoom: {
        translationKey: "is",
        check: (_, folder, join) => folder.roomId === join.value || false,
        typeParams: {
          collection: "rooms",
        },
      },
    },
  },
  room: {
    standalone: {
      [IS_NOT_ARCHIVED_PREDICATE]: {
        translationKey: "isNotArchived",
        check: ({ room }) => !room || !room.isArchivedForAll,
      },
    },
  },
};

const sameColumns = [
  [KColumnType.shortText, KColumnType.automatedAutoIncrement],
  [KColumnType.user, KColumnType.automatedCreatedBy],
  [KColumnType.date, KColumnType.automatedCreatedAt],
] as const;

type NotFirst<T extends readonly AnyUnexplained[]> = T extends readonly [
  AnyUnexplained,
  ...infer Rest,
]
  ? Rest[number]
  : never;
type DeletedColumns = NotFirst<(typeof sameColumns)[number]>;

export type BuiltinConditions = {
  [leftSideType in Exclude<
    KColumnType,
    DeletedColumns
  >]: ConditionLibraryForType<leftSideType>;
};
export type BuiltinConditionColumnType = keyof BuiltinConditions;

/*
 * This is used to treat automated columns the same way as the non
 * automated one, meaning that KColumnType.automatedCreatedAt will have the same
 * conditions as KColumnType.date
 */
export function sanitizeColumnType(columnType: KColumnType) {
  const sameColumn = sameColumns.find((c) =>
    (c as readonly KColumnType[]).includes(columnType),
  );
  return (sameColumn?.[0] ?? columnType) as BuiltinConditionColumnType;
}

export const conditions: BuiltinConditions = {
  attachment: attachmentConditions,
  checkbox: checkboxCondition,
  currency: currencyCondition,
  date: dateCondition,
  geolocation: geolocationCondition,
  join: joinCondition,
  longText: longTextCondition,
  number: numberCondition,
  roomMembers:
    roomPropertyCondition as ConditionLibraryForType<KColumnType.roomMembers>,
  roomName:
    roomPropertyCondition as ConditionLibraryForType<KColumnType.roomName>,
  selectMultiple: selectMultipleCondition,
  selectSingle: selectSingleCondition,
  shortText: shortTextCondition,
  signature: signatureCondition,
  user: userCondition,
};
