import { ConditionLibraryForType } from "@kraaft/shared/core/generated/__generated/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";

export const signatureCondition: ConditionLibraryForType<KColumnType.signature> =
  {
    standalone: {
      isEmpty: {
        translationKey: "isEmpty",
        check: (value) => !value.value,
      },
      isNotEmpty: {
        translationKey: "isNotEmpty",
        check: (value) => Boolean(value.value),
      },
    },
  };
