import { View } from "react-native";

import databaseImage from "@kraaft/shared/components/infoPanel/__assets__/database.png";
import telescopeImage from "@kraaft/shared/components/infoPanel/__assets__/telescop.png";
import { styles } from "@kraaft/shared/components/infoPanel/infoPanel.styles";
import { InfoPanelImageType } from "@kraaft/shared/components/infoPanel/infoPanel.types";
import { Image, Text } from "@kraaft/ui";

interface Props {
  title: string;
  message: string;
  imageType: InfoPanelImageType;
}

const getImage = (image: InfoPanelImageType) => {
  switch (image) {
    case InfoPanelImageType.TELESCOP:
      return telescopeImage;
    case InfoPanelImageType.DATABASE:
      return databaseImage;
  }
};

const InfoPanel = ({ title, message, imageType }: Props) => {
  return (
    <View style={styles.panelContainer}>
      <Text
        style={styles.panelTitle}
        size="TITLE"
        color="FONT_HIGH_EMPHASIS"
        weight="medium"
      >
        {title}
      </Text>
      <Text
        style={styles.panelMessage}
        size="MEDIUM"
        color="FONT_HIGH_EMPHASIS"
      >
        {message}
      </Text>
      <Image
        style={styles.panelImage}
        source={getImage(imageType)}
        resizeMode="contain"
      />
    </View>
  );
};

export { InfoPanel };
