import { MouseEventHandler, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { compact } from "lodash";

import { useMeshContext } from "@kraaft/helper-hooks";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { KSchemaElement } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { conditionalEntry } from "@kraaft/shared/core/utils";
import { useDeleteSchemaColumn } from "@kraaft/web/src/components/multiStepDialog/customs/deleteSchemaColumn";
import { SchemaBuilderContext } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

export function useDeleteElementActionItems(
  onDelete: (key: string) => void,
  element: KSchemaElement,
  forceInstantDeletion: boolean,
) {
  const { isColumnDeletionInstant } = useMeshContext(SchemaBuilderContext);
  const instantDeletion = forceInstantDeletion || isColumnDeletionInstant;

  const { t } = useTranslation();
  const internOnDelete = useCallback(() => {
    onDelete(element.key);
  }, [element.key, onDelete]);

  const openDeleteDialog = useDeleteSchemaColumn({
    columnName: element.name,
    onDelete: internOnDelete,
  });

  const handleOnDelete = useCallback<MouseEventHandler<HTMLLIElement>>(
    (event) => {
      event.stopPropagation();
      internOnDelete();
    },
    [internOnDelete],
  );

  const handleOpenDeleteDialog = useCallback<MouseEventHandler<HTMLLIElement>>(
    (event) => {
      event.stopPropagation();
      openDeleteDialog();
    },
    [openDeleteDialog],
  );

  const deleteActions = compact([
    conditionalEntry<ActionSheetItem>(
      {
        label: t("delete"),
        style: "destructive",
        onPress: instantDeletion ? handleOnDelete : handleOpenDeleteDialog,
      },
      !(element.elementType === "column" && element.nonDeletable),
    ),
  ]);
  return deleteActions;
}
