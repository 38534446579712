import { createReducer } from "@reduxjs/toolkit";

import * as actions from "./fileBucketActions";
import { FileSenderBucketState } from "./fileBucketState";

const initialState: FileSenderBucketState = { fileSenderBucketList: {} };

const fileBucketReducers = createReducer(initialState, ({ addCase }) => {
  addCase(actions.addFilesSenderBucket, (state, { payload }) => {
    const { id, files } = payload;
    state.fileSenderBucketList[id] = {
      list: [...(state.fileSenderBucketList?.[id]?.list || []), ...files],
    };
  });

  addCase(actions.editFileSenderBucket, (state, { payload: { id, file } }) => {
    const list = state.fileSenderBucketList[id];
    if (!list) {
      return;
    }
    const index = list.list.findIndex((f) => f.filename === file.filename);
    if (index < 0) {
      return;
    }
    list.list[index] = file;
  });

  addCase(actions.removeFilesSenderBucket, (state, { payload }) => {
    const { id, index, emptyAll } = payload;
    if (id) {
      if (emptyAll) {
        state.fileSenderBucketList[id] = {
          list: [],
        };
      } else if (index !== undefined) {
        state.fileSenderBucketList[id] = {
          list: (state.fileSenderBucketList?.[id]?.list || []).filter(
            (_file, i) => {
              return i !== index;
            },
          ),
        };
      }
    }
  });
});

export { fileBucketReducers };
