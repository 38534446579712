import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assert } from "ts-essentials";

import { getModularFolderAttachmentColumn } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import {
  Attachment,
  UploadAttachmentContext,
} from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { selectLoaderState } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { ModularFolderStateActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { uploadFilesRequest } from "@kraaft/shared/core/modules/room/roomActions";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { ModularTableAttachmentContext } from "@kraaft/web/src/components/modularTable/schema";

export function useModularFoldersTableAttachmentContext(
  folders: ModularFolder[],
): ModularTableAttachmentContext {
  const dispatch = useDispatch();
  const loaderState = useSelector(selectLoaderState);

  const attachmentContext: ModularTableAttachmentContext = useMemo(() => {
    function openCarousel(
      record: ModularFolder,
      column: KSchemaColumn,
      imageIndex: number,
    ) {
      dispatch(
        ModularFolderStateActions.openCarousel({
          roomId: record.roomId,
          folderId: record.id,
          column,
          initialIndex: imageIndex,
        }),
      );
    }

    function onFilesUpload(
      id: string,
      column: KSchemaColumn,
      files: ModernFile<LocalPath>[],
    ) {
      const record = (folders || []).find((folder) => folder.id === id);
      assert(record);

      const context: UploadAttachmentContext = {
        type: "modularFolder",
        columnKey: column.key,
        roomId: record.roomId,
        schemaId: record.schemaId,
        modularFolderId: record.id,
        isPhotoQualityHD:
          column.type === KColumnType.attachment && column.photoQualityHD,
      };
      dispatch(uploadFilesRequest({ context, files }));
    }

    function isCellLoading(loadingId: string) {
      return (loaderState[loadingId]?.loadingCounter ?? 0) > 0;
    }

    function onAttachmentClick(
      id: string,
      column: KSchemaColumn,
      attachment: Attachment,
    ) {
      switch (attachment.type) {
        case "image": {
          const record = folders.find((folder) => folder.id === id);
          assert(record);

          const items = getModularFolderAttachmentColumn(record, column.key, [
            "image",
          ]);

          const pictureIndex = items.findIndex(
            (pictureAttachment) => pictureAttachment.id === attachment.id,
          );
          openCarousel(record, column, pictureIndex);
          break;
        }

        case "document":
          return fileSaver.viewDocument(attachment.original.downloadUrl);
        default:
          break;
      }
    }

    return {
      onFilesUpload,
      isCellLoading,
      onAttachmentClick,
    };
  }, [dispatch, folders, loaderState]);

  return attachmentContext;
}
