import { CreateOperationBuilder } from "./operations/create.operation";
import { CreateOneOperationBuilder } from "./operations/createOne.operation";
import { DeleteOneOperationBuilder } from "./operations/deleteOne.operation";
import { EditOperationBuilder } from "./operations/edit.operation";
import { EditOneOperationBuilder } from "./operations/editOne.operation";
import type { BaseAggregate } from "./optimistic.types";

export class Operation {
  static createOne<A extends BaseAggregate>() {
    return CreateOneOperationBuilder.create<A>();
  }

  static create<A extends BaseAggregate>() {
    return CreateOperationBuilder.create<A>();
  }

  static edit<A extends BaseAggregate>() {
    return EditOperationBuilder.create<A>();
  }

  static editOne<A extends BaseAggregate>() {
    return EditOneOperationBuilder.create<A>();
  }

  static deleteOne<A extends BaseAggregate>() {
    return DeleteOneOperationBuilder.create<A>();
  }
}
