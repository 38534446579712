import { memo } from "react";
import { useSelector } from "react-redux";
import isEqual from "fast-deep-equal";

import { useRoomDescription } from "@kraaft/shared/components/roomCard/roomCardUtils";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { selectRoomSchemaPillColumns } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { selectUsers } from "@kraaft/shared/core/modules/user/userSelectors";

import { RoomCardWrapper } from "./roomCardWrapper";

export interface RoomCardConnectorProps {
  roomCard: AnyRoomCard;
  disabled?: boolean;
  onLongPress?: (roomCard: AnyRoomCard) => void;
  onPress?: (roomCard: AnyRoomCard) => void;
  onHover?: (roomCard: AnyRoomCard, hover: boolean) => void;
}

const RoomCardConnector_ = ({
  roomCard,
  disabled,
  onHover,
  onLongPress,
  onPress,
}: RoomCardConnectorProps) => {
  const users = useSelector(selectUsers);

  // @FIXME pillColumns should be loaded room by room for external users.
  const pillColumns = useSelector(selectRoomSchemaPillColumns(roomCard.poolId));

  const description = useRoomDescription(
    roomCard,
    users[roomCard.roomCreatedBy],
    roomCard.lastMessageInfo && users[roomCard.lastMessageInfo.senderId],
  );

  return (
    <RoomCardWrapper
      roomCard={roomCard}
      disabled={disabled}
      onLongPress={onLongPress}
      onPress={onPress}
      onHover={onHover}
      description={description}
      pillColumns={pillColumns}
    />
  );
};

export const RoomCardConnector = memo(
  RoomCardConnector_,
  isEqual,
) as typeof RoomCardConnector_;
