import { createReducer } from "@reduxjs/toolkit";

import { DirectoryState } from "@kraaft/shared/core/modules/directory/directoryState";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import { DirectoryStateActions } from "./directoryActions";

const initialState: DirectoryState = {
  directories: [],
  messageIdToDirectoryIds: {},
};

const directoryReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);

  addCase(DirectoryStateActions.setForRoom, (state, { payload }) => {
    state.directories = [
      ...state.directories.filter((d) => d.roomId !== payload.roomId),
      ...payload.directories,
    ];
    state.messageIdToDirectoryIds = {};
    for (const directory of state.directories) {
      for (const file of directory.files) {
        const directories =
          state.messageIdToDirectoryIds[file.messageId] ?? ([] as string[]);
        directories.push(directory.id);
        state.messageIdToDirectoryIds[file.messageId] = directories;
      }
    }
  });

  addCase(DirectoryStateActions.delete, (state, { payload }) => {
    state.directories = state.directories.filter(
      (directory) => directory.id !== payload.directoryId,
    );
  });

  addCase(DirectoryStateActions.add, (state, { payload }) => {
    state.directories = [...state.directories, payload.directory];
  });

  addCase(DirectoryStateActions.rename, (state, { payload }) => {
    const directory = state.directories.find(
      (d) => d.id === payload.directoryId,
    );

    if (directory) {
      directory.name = payload.newName;
    }
  });
});

export { directoryReducers };
