import { useMemo } from "react";
import pickBy from "lodash/pickBy";

import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { ADDITIONAL_COLUMNS } from "@kraaft/web/src/components/modularTable/components/table/tableUtils";
import { TableAdditionalColumn } from "@kraaft/web/src/components/modularTable/tableContext";
import { SimplifiedTableContextMenu } from "@kraaft/web/src/components/simplifiedTable/simplifiedTableContextMenu";
import {
  EditionAddition,
  EditionModification,
  SimplifiedTableEditions,
} from "@kraaft/web/src/components/simplifiedTable/simplifiedTableTypes";

export function getAdditionsOfEditions(
  editions: Record<string, SimplifiedTableEditions>,
): Record<string, EditionAddition> {
  return pickBy(editions, (value) => value.type === "addition") as Record<
    string,
    EditionAddition
  >;
}

export function getModificationsOfEditions(
  editions: Record<string, SimplifiedTableEditions>,
): Record<string, EditionModification> {
  return pickBy(editions, (value) => value.type === "edition") as Record<
    string,
    EditionModification
  >;
}

export function applyEditionsToTableRecords(
  schema: KSchema,
  tableRecords: ModularRecord[],
  editions: Record<string, SimplifiedTableEditions>,
) {
  const additions = getAdditionsOfEditions(editions);
  for (const [_, value] of Object.entries(additions)) {
    const record = ModularRecordUtils.create(schema);
    record.properties = { ...record.properties, ...value.editions };
    tableRecords.push(record);
  }

  const modifications = getModificationsOfEditions(editions);
  for (const [key, value] of Object.entries(modifications)) {
    const record = tableRecords.find((r) => r.id === key);
    if (record) {
      if (value.deleted) {
        const index = tableRecords.findIndex((r) => r.id === key);
        if (index >= 0) {
          tableRecords.splice(index, 1);
        }
        continue;
      }
      record.properties = {
        ...record.properties,
        ...value.editions.reduce(
          (acc, [cellKey, columnValue]) => {
            acc[cellKey] = columnValue;
            return acc;
          },
          {} as ModularRecord["properties"],
        ),
      };
    }
  }
}

export function useDeletionColumn(
  onDelete: (id: string) => void,
  lockingColumns: string[],
): TableAdditionalColumn {
  return useMemo(
    () => ({
      name: "",
      key: ADDITIONAL_COLUMNS.actionCell,
      width: 46,
      maxWidth: 46,
      resizable: false,
      formatter: ({ row }) => (
        <SimplifiedTableContextMenu
          onDelete={() => onDelete(row.id)}
          lockingColumns={lockingColumns}
          record={row}
        />
      ),
      headerRenderer: () => null,
    }),
    [lockingColumns, onDelete],
  );
}
