import { useLayoutEffect, useMemo } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import clsx from "clsx";

import { useMeshContext } from "@kraaft/helper-hooks";
import { BetweenDirectoryDropper } from "@kraaft/web/src/components/directoryTree/directoryDropper/betweenDirectoryDropper";
import { OnDirectoryDropper } from "@kraaft/web/src/components/directoryTree/directoryDropper/onDirectoryDropper";
import {
  Directory,
  DraggableDirectoryTreeItem,
  FOLDER_ITEM_TYPE,
} from "@kraaft/web/src/components/directoryTree/directoryTree.types";
import { DirectoryTreeChildDirectories } from "@kraaft/web/src/components/directoryTree/directoryTreeChildDirectories";
import { DirectoryTreeContext } from "@kraaft/web/src/components/directoryTree/directoryTreeContext";
import { DirectoryTreeDraggableRow } from "@kraaft/web/src/components/directoryTree/directoryTreeDraggableRow";

import { BaseDirectoryTreeRowGroupProps } from "./directoryTreeRowGroup";

import { useStyles } from "./directoryTreeRowGroup.styles";

interface ConnectedDirectoryTreeRowGroupProps
  extends BaseDirectoryTreeRowGroupProps {
  directory: Directory;
}

const ConnectedDirectoryTreeRowGroup = (
  props: ConnectedDirectoryTreeRowGroupProps,
) => {
  const {
    directory,
    level = 0,
    isFirstChildDirectory,
    isGroupBeingDragged,
  } = props;
  const { setDraggingDirectoryState } = useMeshContext(DirectoryTreeContext);

  const classes = useStyles();

  const draggableItem = useMemo<DraggableDirectoryTreeItem>(
    () => ({
      type: FOLDER_ITEM_TYPE,
      directory,
    }),
    [directory],
  );

  const [{ isDragging }, drag, preview] = useDrag({
    type: FOLDER_ITEM_TYPE,
    item: () => {
      setTimeout(() => setDraggingDirectoryState(true));
      return draggableItem;
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: () => {
      setDraggingDirectoryState(false);
    },
  });

  useLayoutEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, [preview]);

  return (
    <div
      className={clsx(classes.rowGroupContainer, {
        [classes.rowGroupContainerDisabled]: isDragging,
      })}
    >
      {isFirstChildDirectory ? (
        <BetweenDirectoryDropper
          level={level}
          parentDirectoryId={directory.parentDirectoryId}
          siblingDirectoryId={directory.id}
          placement="before"
          isGroupBeingDragged={isGroupBeingDragged || isDragging}
        />
      ) : null}
      <DirectoryTreeDraggableRow
        directory={directory}
        dragRef={drag}
        level={level}
        isGroupBeingDragged={isGroupBeingDragged}
        isTemporaryDirectory={directory.isTemporary}
      />
      {directory.childDirectoryIds.length === 0 ? (
        <OnDirectoryDropper
          level={level + 1}
          parentDirectoryId={directory.id}
          isGroupBeingDragged={isGroupBeingDragged || isDragging}
        />
      ) : null}
      <DirectoryTreeChildDirectories
        parentDirectoryId={directory.id}
        directoryIds={directory.childDirectoryIds}
        parentLevel={level}
        isGroupBeingDragged={isGroupBeingDragged || isDragging}
      />
    </div>
  );
};

export { ConnectedDirectoryTreeRowGroup };
