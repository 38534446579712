import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { EntryLayout } from "@kraaft/shared/components/modularFolders/modularFolderHistory/modularFolderHistoryEntryRenderer/entryLayout";
import { selectModularFolderHistoryEntryElementName } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.selectors";
import { ModularFolderSectionUnlocked } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.state";
import { selectUsername } from "@kraaft/shared/core/modules/user/userSelectors";

interface SectionUnlockedEntryProps {
  entry: ModularFolderSectionUnlocked;
}

export const SectionUnlockedEntry = ({ entry }: SectionUnlockedEntryProps) => {
  const { t } = useTranslation();

  const initiatorName = useSelector(selectUsername(entry.by));
  const sectionName = useSelector(
    selectModularFolderHistoryEntryElementName(
      entry.modularFolderId,
      entry.section,
    ),
  );

  return (
    <EntryLayout
      date={entry.at}
      iconName="lock-01"
      iconColor="YELLOW_EARTH"
      i18nKey="history.entryLabel.sectionUnlocked"
      i18nValues={{
        initiatorName,
        sectionName: sectionName ?? t("history.unknownSchemaSectionName"),
      }}
      errorMessage={
        entry.isSchemaSectionMissing
          ? t("history.schemaSectionMissing")
          : undefined
      }
    />
  );
};
