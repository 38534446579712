import { createAction } from "@reduxjs/toolkit";

import { Message } from "@kraaft/shared/core/modules/message/messageState";
import { FirestoreTypes } from "@kraaft/shared/core/services/firestore/sdk";
import { LinkedList } from "@kraaft/shared/core/utils/useBidirectional/createLinkedLists";

export const MessageDataActions = {
  subscribe: createAction<{ roomId: string }>("@messageData/SUBSCRIBE"),
  unsubscribe: createAction<{ roomId: string }>("@messageData/UNSUBSCRIBE"),
};

export const MessageDataStateActions = {
  addMessages: createAction<{
    roomId: string;
    messages: Record<string, Message>;
    messageDocs: Record<string, FirestoreTypes.QueryDocumentSnapshot>;
    shouldActAsNewMessage: boolean;
  }>("@messageData/state/ADD_MESSAGES"),
  addLinkedList: createAction<{
    roomId: string;
    linkedList: LinkedList;
  }>("@messageData/state/ADD_LINKED_LIST"),
};
