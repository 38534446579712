import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { DetailsEditorBox } from "@kraaft/shared/components/modular/details/editors/detailsEditorBox";
import { EditorProps } from "@kraaft/shared/components/modular/details/editors/types";
import { Pill } from "@kraaft/shared/components/pill";
import { SlidePanel } from "@kraaft/shared/components/slidePanel";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import {
  BaseActionCard,
  ColorStyle,
  IconName,
  LabelledActionCard,
  Spacing,
} from "@kraaft/ui";

import { ListEditorPanel } from "./listEditorPanel";

type Props = EditorProps<
  KColumnType.selectSingle | KColumnType.selectMultiple | KColumnType.user
> & {
  items: ListEditorItem[];
  searchPlaceholder: string;
  placeholder: string;
  iconName: IconName;
};

type PillConfig = {
  pillIcon?: IconName;
  pillColor?: string;
};

export type ListEditorItem = KSelectableListItem<string, PillConfig>;

const ListEditor = (props: Props) => {
  const {
    editor: { label, disabled, value, setValue, context, sectionLockInfo },
    column,
    testID,
    items,
    searchPlaceholder,
    placeholder,
    iconName,
  } = props;
  const [isOpen, openPanel, closePanel] = useBooleanState();

  const filteredValues = useMemo(
    () => items.filter((v) => value?.includes(v.identifier)),
    [items, value],
  );

  const emptyContent = useMemo(
    () => (
      <LabelledActionCard
        id={`${testID}-${column.key}`}
        iconName={iconName}
        label={placeholder}
        color={ColorStyle.FONT_LOW_EMPHASIS}
        accessibilityLabel={label}
        onPress={openPanel}
        disabled={disabled}
      />
    ),
    [column.key, disabled, iconName, label, openPanel, placeholder, testID],
  );

  const content = useMemo(() => {
    if (filteredValues.length > 0) {
      return (
        <BaseActionCard
          id={`${testID}-${column.key}`}
          accessibilityLabel={label}
          onPress={openPanel}
          disabled={disabled}
        >
          <View style={styles.pillsContainer}>
            {filteredValues.map((filteredValue) => (
              <Pill
                key={filteredValue.identifier}
                content={filteredValue.label}
                icon={filteredValue.value?.pillIcon}
                iconColor={filteredValue.value?.pillColor}
                size="medium"
              />
            ))}
          </View>
        </BaseActionCard>
      );
    }

    return emptyContent;
  }, [
    column.key,
    disabled,
    emptyContent,
    label,
    openPanel,
    testID,
    filteredValues,
  ]);

  return (
    <>
      <DetailsEditorBox
        isLocked={sectionLockInfo.isLocked}
        label={label}
        columnKey={column.key}
      >
        {content}
      </DetailsEditorBox>
      <SlidePanel
        name="ListEditor"
        open={isOpen}
        portalHostname={context.portalHostname}
        direction={context.slideDirection}
        onClose={closePanel}
      >
        <ListEditorPanel
          value={value}
          items={items}
          onClose={closePanel}
          setValue={setValue}
          column={column}
          placeholder={searchPlaceholder}
          label={label}
          disabled={disabled}
          sectionLockInfo={sectionLockInfo}
          testID={testID}
        />
      </SlidePanel>
    </>
  );
};

const styles = StyleSheet.create({
  pillsContainer: {
    flexDirection: "row",
    gap: Spacing.S8,
    flexWrap: "wrap",
  },
});

export { ListEditor };
