import { incrementPendingOperations } from "@kraaft/shared/core/modules/modularFolder/operations/modularFolder.offline.tools";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Api } from "@kraaft/shared/core/services/api";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

export const deleteAttachmentOperation = Operation.editOne<ModularFolder>()
  .payload<{
    attachmentId: string;
    columnKey: string | undefined;
  }>()
  .expected((modularFolder, payload) => {
    incrementPendingOperations(modularFolder);
    const properties = payload.columnKey
      ? modularFolder.properties[payload.columnKey]
        ? {
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            [payload.columnKey]: modularFolder.properties[payload.columnKey]!,
          }
        : {}
      : modularFolder.properties;
    for (const value of Object.values(properties)) {
      if (value.columnType !== KColumnType.attachment || !value.value) {
        continue;
      }
      const attachmentIndex = value.value.findIndex(
        (attachment) => attachment.id === payload.attachmentId,
      );
      if (attachmentIndex === undefined || attachmentIndex < 0) {
        return modularFolder;
      }
      value.value.splice(attachmentIndex, 1);
    }
    return modularFolder;
  })
  .mutate((payload, task) =>
    Api.deleteModularFolderAttachment({
      requestId: task.id,
      folderId: payload.id,
      columnKey: payload.columnKey,
      documentId: payload.attachmentId,
    }),
  );
