import { Color } from "@kraaft/ui";

export class KSchemaColors {
  static Colors = [
    "#EC5F4F", // RED
    Color.ORANGE_BASIC,
    Color.YELLOW_KRAAFT,
    Color.GREEN_LAGOON,
    "#3A83F7", // BLUE
    "#DD35CC", // PINK
    "#920773", // DARK PINK
    "#863B10", // BROWN
    Color.GREY_FRENCH,
    Color.GREY_TAUPE,
    Color.GREY_JET,
  ] as const;

  static DefaultSectionColor = Color.GREY_JET;
}
