import { StyleSheet } from "react-native";

import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  menuItem: {
    height: 46,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Color.WHITE,
    borderWidth: 1,
    borderColor: Color.GREY_ANTIFLASH,
    borderStyle: "solid",
    borderRadius: 4,
    paddingVertical: Spacing.S8,
    paddingHorizontal: Spacing.S16,
  },
  menuItemSelected: {
    borderColor: ColorStyle.ACTION_CTA,
  },
  menuItemHovered: {
    borderColor: ColorStyle.ACTION_CTA,
    backgroundColor: ColorStyle.ACTION_SELECTED,
  },
  menuItemText: {
    marginLeft: Spacing.S8,
    fontSize: 17,
  },
  menuItemTextSelected: {
    fontWeight: "500",
  },
  menuSeparator: {
    height: 8,
  },
});
