import { Platform } from "react-native";
import { mapKeys, snakeCase } from "lodash/fp";

import { ANALYTICS_VERBOSE } from "@kraaft/shared/constants/global";
import { FeatureFlags } from "@kraaft/shared/core/modules/featureFlags/featureFlags.state";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { permissions } from "@kraaft/shared/core/services/permission/permissions.provider";
import { Permissions } from "@kraaft/shared/core/services/permission/permissions.types";
import {
  JsonMap,
  segmentAnalytics,
} from "@kraaft/shared/core/services/segmentAnalytics";

interface TrackContext {
  currentPool?: {
    id: string;
    name: string;
    role: UserPoolRole;
  };
  currentRoom?: {
    id: string;
    name?: string;
  };
}

class AnalyticsService {
  private trackContext: TrackContext = {};

  private permissionLocationGranted?: boolean;
  private permissionNotificationGranted?: boolean;
  private featureFlags: FeatureFlags = {};

  constructor() {
    permissions.addOnPermissionRequestListener(() =>
      this.updatePermissions().catch(console.error),
    );
  }

  async resetTrackContext() {
    this.trackContext = {};
    await segmentAnalytics.setContext(undefined);
  }

  async setTrackContextCurrentPool(
    pool: Required<TrackContext>["currentPool"],
  ) {
    this.trackContext.currentPool = pool;
    await segmentAnalytics.setContext({ groupId: pool.id });
  }

  enterRoom(roomId: string, roomName?: string) {
    if (ANALYTICS_VERBOSE) {
      console.log("Entering room", roomId, roomName);
    }
    this.trackContext.currentRoom = {
      id: roomId,
      name: roomName,
    };
  }

  leaveRoom(roomId: string) {
    if (this.trackContext.currentRoom?.id === roomId) {
      if (ANALYTICS_VERBOSE) {
        console.log("Leaving room", roomId);
      }
      this.trackContext.currentRoom = undefined;
    }
  }

  setTrackContextFeatureFlags(featureFlags: FeatureFlags) {
    if (ANALYTICS_VERBOSE) {
      console.log("Segment :: setTrackContextFeatureFlags", featureFlags);
    }

    this.featureFlags = featureFlags;
  }

  async identify(userId: string, traits?: JsonMap) {
    if (ANALYTICS_VERBOSE) {
      console.log("Segment :: identify", userId, traits);
    }

    await segmentAnalytics.identify(userId, traits);
  }

  async track(eventName: string, properties: JsonMap) {
    const eventData = {
      current_pool_id: this.trackContext.currentPool?.id,
      current_pool_name: this.trackContext.currentPool?.name,
      current_pool_role: this.trackContext.currentPool?.role,
      current_room_id: this.trackContext.currentRoom?.id,
      current_room_name: this.trackContext.currentRoom?.name,

      platform: Platform.OS,

      permission_location_granted: this.permissionLocationGranted,
      permission_notification_granted: this.permissionNotificationGranted,

      ...mapKeys((key) => `ff_${snakeCase(key)}`, this.featureFlags),

      ...properties,
    };

    if (ANALYTICS_VERBOSE) {
      console.log("Segment :: track", {
        eventName,
        eventData,
      });
    }

    await segmentAnalytics.track(eventName, eventData);
  }

  async updatePermissions() {
    this.permissionLocationGranted = Permissions.convertStatusToBoolean(
      await permissions.statusOf("location"),
    );
    this.permissionNotificationGranted = Permissions.convertStatusToBoolean(
      await permissions.statusOf("notification"),
    );
  }
}

export const analytics = new AnalyticsService();
