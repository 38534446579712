import "firebase/compat/auth";
import "firebase/compat/firestore";

import firebase from "firebase/compat/app";

export namespace FirestoreTypes {
  export type Blob = firebase.firestore.Blob;
  export type CollectionReference<T = firebase.firestore.DocumentData> =
    firebase.firestore.CollectionReference<T>;
  export type DocumentReference<T = firebase.firestore.DocumentData> =
    firebase.firestore.DocumentReference<T>;
  export type DocumentData = firebase.firestore.DocumentData;
  export type DocumentSnapshot<T = firebase.firestore.DocumentData> =
    firebase.firestore.DocumentSnapshot<T>;
  export type QueryDocumentSnapshot<T = firebase.firestore.DocumentData> =
    firebase.firestore.QueryDocumentSnapshot<T>;
  export type FieldPath = firebase.firestore.FieldPath;
  export type FieldValue = firebase.firestore.FieldValue;
  export type GeoPoint = firebase.firestore.GeoPoint;
  export type Query<T = firebase.firestore.DocumentData> =
    firebase.firestore.Query<T>;
  export type QuerySnapshot<T = firebase.firestore.DocumentData> =
    firebase.firestore.QuerySnapshot<T>;
  export type FirestoreError = firebase.firestore.FirestoreError;
  export type Timestamp = firebase.firestore.Timestamp;
  export type Transaction = firebase.firestore.Transaction;
  export type WriteBatch = firebase.firestore.WriteBatch;
  export type SnapshotMetadata = firebase.firestore.SnapshotMetadata;
}

export const auth = firebase.auth;
export const firestore = firebase.firestore;
