import { EventChannel, eventChannel } from "redux-saga";
import { assert } from "ts-essentials";
import { call, put, select } from "typed-redux-saga/macro";

import { isNative } from "@kraaft/helper-functions";
import { showInfo } from "@kraaft/shared/core/modules/alert/alertActions";
import { setLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { startOnPool } from "@kraaft/shared/core/modules/pool/poolActions";
import {
  selectCurrentPoolId,
  selectFetchedPoolOnce,
  selectOnePool,
} from "@kraaft/shared/core/modules/pool/poolSelectors";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { RoomUserHistory } from "@kraaft/shared/core/modules/room/roomState";
import { getSubscribeToRoomLoaderId } from "@kraaft/shared/core/modules/room/roomUtils";
import { combineLatestChannels } from "@kraaft/shared/core/modules/room/sagas/combineLatestChannels";
import { RoomSchemaVisibilityActions } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.actions";
import { WithId } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import {
  selectCurrentUserId,
  selectCurrentUserIsSuperadmin,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import * as Types from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { navigationService } from "@kraaft/shared/core/services/navigation/provider";
import {
  takeCountedDeep,
  takeFirstAndDebounce,
  waitFor,
} from "@kraaft/shared/core/utils/sagas";

type RoomChannelPayload = WithId<Types.FirestoreRoom>;

function createRoomChannel(roomId: string) {
  return eventChannel<RoomChannelPayload>((emit) => {
    return Firestore.subscribeToRoom(
      roomId,
      emit,
      /**
       * TODO
       * https://github.com/redux-saga/redux-saga/issues/472 [RESOLVED]
       * This issue has been resolved so it should be possible to not cast
       * We may need to update redux-saga
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error) => emit(error as any),
    );
  });
}

// event cannot be undefined so we wrap it in an object
interface UserHistoryEvent {
  userHistory: RoomUserHistory | undefined;
}

function createUserHistoryChannel(payload: { userId: string; roomId: string }) {
  return eventChannel<UserHistoryEvent>((emit) => {
    return Firestore.subscribeToUserRoom(
      payload,
      (userHistory) => {
        emit({ userHistory });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error) => emit(error as any),
    );
  });
}

function* receiveRoom([room, userHistory]: [
  RoomChannelPayload,
  UserHistoryEvent,
]) {
  const currentPoolId = yield* waitFor(selectCurrentPoolId);
  const isSuperadmin = yield* select(selectCurrentUserIsSuperadmin);

  if (room.poolId !== currentPoolId) {
    yield* waitFor(selectFetchedPoolOnce);
    const pool = yield* select(selectOnePool(room.poolId));
    if (pool && (isNative() || !isSuperadmin)) {
      yield* put(startOnPool({ poolId: room.poolId }));
      yield* put(
        showInfo({ title: i18n.t("poolChanged", { name: pool.name }) }),
      );
    } else if (!isSuperadmin) {
      yield* put(showInfo({ title: i18n.t("notMemberAnymore") }));
      navigationService.navigate("Home");
      return;
    }
  }
  const [convertedRoom] = yield* KSchemaConversion.toRooms(room.poolId, [room]);

  if (!convertedRoom) {
    return;
  }

  yield* put(
    roomActions.setRoom({
      room: convertedRoom,
      ...userHistory,
    }),
  );
}

function* subscribeSaga(
  registerMeta: (
    channels: [
      EventChannel<RoomChannelPayload>,
      EventChannel<UserHistoryEvent>,
    ],
  ) => void,
  action: ReturnType<typeof roomActions.subscribeToRoom>,
) {
  const { roomId } = action.payload;

  try {
    const currentUserId = yield* select(selectCurrentUserId);
    assert(currentUserId, "no currentUserid");

    const roomChannel = yield* call(createRoomChannel, roomId);

    const userRoomChannel: ReturnType<typeof createUserHistoryChannel> =
      yield* call(createUserHistoryChannel, { userId: currentUserId, roomId });

    const combinedChannel = combineLatestChannels(roomChannel, userRoomChannel);

    registerMeta([roomChannel, userRoomChannel]);

    yield* put(RoomSchemaVisibilityActions.subscribe({ roomId }));

    yield takeFirstAndDebounce(combinedChannel, 300, receiveRoom);
  } catch (error) {
    console.log("error in room subscribeSaga", error);
    yield* put(
      setLoader({
        id: getSubscribeToRoomLoaderId(roomId),
        status: LoaderStatus.FAILURE,
        error,
      }),
    );
  }
}

function* unsubscribeSaga(
  channels:
    | [EventChannel<RoomChannelPayload>, EventChannel<UserHistoryEvent>]
    | undefined,
  action: ReturnType<typeof roomActions.unsubscribeFromRoom>,
) {
  if (channels) {
    for (const channel of channels) {
      channel.close();
    }

    yield* put(
      RoomSchemaVisibilityActions.unsubscribe({
        roomId: action.payload.roomId,
      }),
    );
  }
}

export function* subscribeToRoomSaga() {
  yield takeCountedDeep(
    roomActions.subscribeToRoom,
    roomActions.unsubscribeFromRoom,
    subscribeSaga,
    unsubscribeSaga,
    (action) => action.payload.roomId,
  );
}
