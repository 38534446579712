import { StyleSheet } from "react-native";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const MARGIN_BETWEEN_IMAGES = 4;

export const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    flexBasis: 0,
  },
  sectionList: {
    flexGrow: 1,
    flexBasis: 0,
  },
  sectionListContent: {
    paddingBottom: Spacing.S8,
  },
  header: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    paddingRight: Spacing.S8,
  },
  images: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: MARGIN_BETWEEN_IMAGES,
  },
  wrapper: {
    marginBottom: MARGIN_BETWEEN_IMAGES,
  },
  mapButton: {
    paddingTop: Spacing.S12,
    paddingRight: Spacing.S16,
  },
  helperTextContainer: {
    padding: Spacing.S16,
  },
  helperText: {
    fontSize: FontSize.SMALL,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    textAlign: "center",
  },
});
