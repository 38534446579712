import { Children, Fragment, ReactNode } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { DetailGroupSeparator } from "./detailGroupSeparator";

const DetailGroup = ({
  style,
  children,
}: {
  style?: StyleProp<ViewStyle>;
  children: ReactNode[];
}) => {
  return (
    <View style={style}>
      {Children.map(children, (child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        // biome-ignore lint :
        <Fragment key={index}>
          {index !== 0 && <DetailGroupSeparator />}
          {child}
        </Fragment>
      ))}
    </View>
  );
};

export { DetailGroup };
