import moment from "moment";

import { useCallback, useMemo } from "react";
import {
  DefaultSectionT,
  SectionListData,
  SectionListRenderItemInfo,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import { capitalize, flatten, isEmpty } from "lodash/fp";

import { SelectionState } from "@kraaft/shared/components/checkbox/checkbox";
import { SectionListWithEmptyState } from "@kraaft/shared/components/emptyState/lists/sectionList";
import { FileCard } from "@kraaft/shared/components/fileCard";
import { MessageLimitReached } from "@kraaft/shared/components/freemium/messageLimitReached";
import { MonthHeader } from "@kraaft/shared/components/galleries/monthHeader";
import { sortMediaByMonth } from "@kraaft/shared/components/galleries/photoGallery/useSortedGallery";
import {
  selectMessageSelection,
  selectMessageSelectionSource,
} from "@kraaft/shared/core/modules/message/messageSelectors";
import { selectMiniMediasIsLimitedResultForType } from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { MiniDocument } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { AnyUnexplained } from "@kraaft/shared/core/types";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";
import { useSafeAreaInsets } from "@kraaft/shared/core/utils/useSafeAreaInsets";

import { styles } from "./documentGallery.styles";

export interface DocumentGalleryProps {
  roomId: string;
  medias: MiniDocument[];
  mode?: "default" | "selection";
  backgroundColor: string;
}

const miniDocumentKeyExtractor = (media: MiniDocument) => media.id;

const DocumentGallery = ({
  roomId,
  medias,
  mode,
  backgroundColor,
}: DocumentGalleryProps) => {
  const { bottom: bottomInset } = useSafeAreaInsets();

  const isLimitedResult = useSelector(
    selectMiniMediasIsLimitedResultForType(roomId, "document"),
  );
  const selectionSource = useSelector(selectMessageSelectionSource(roomId));
  const { selection } = useSelector(
    selectMessageSelection(roomId, "documentGallery"),
  );
  const { handleSelect, isSelecting: hasSelected } = useMediaSelectionContext({
    roomId,
    medias,
    type: "document",
  });
  const isSelecting = mode === "selection" || hasSelected;

  const onSelect = useCallback(
    (messageIds: string[], status: boolean) => {
      handleSelect(messageIds, status);
    },
    [handleSelect],
  );

  const mediaByMonth = useMemo(() => sortMediaByMonth(medias), [medias]);

  const sections = useMemo(
    () =>
      Object.entries(mediaByMonth).map(([id, m]) => ({
        id,
        title: capitalize(moment(m[0]?.createdAt).format("MMMM YYYY")),
        data: m,
      })),
    [mediaByMonth],
  );

  const getSelectionState = useCallback(
    (monthId: string): SelectionState => {
      const month = mediaByMonth[monthId];
      if (!month) {
        return "none";
      }
      let state: SelectionState = "all";
      let count = 0;
      for (const media of month) {
        if (!selection[media.messageId]) {
          state = "some";
        } else {
          count += 1;
        }
      }
      if (count === 0) {
        return "none";
      }
      return state;
    },
    [mediaByMonth, selection],
  );

  const onSelectWholeMonth = useCallback(
    (monthId: string) => {
      const selected = getSelectionState(monthId);
      const month = mediaByMonth[monthId];

      if (!month) {
        return;
      }

      const idsToSelect = month.map((mds) => mds.messageId);
      handleSelect(flatten(idsToSelect), selected !== "all");
    },
    [getSelectionState, handleSelect, mediaByMonth],
  );

  const sectionListFooter = useMemo(() => {
    if (isLimitedResult) {
      return <MessageLimitReached from="documents" />;
    }

    return null;
  }, [isLimitedResult]);

  const headerStyle = useMemo(
    () => [styles.header, { backgroundColor }],
    [backgroundColor],
  );

  const renderHeader = useCallback(
    ({ section }: { section: SectionListData<MiniDocument> }) => (
      <View style={headerStyle}>
        <MonthHeader
          selectionSource={selectionSource}
          wantedSelectionSource="documentGallery"
          section={section as AnyUnexplained}
          onSelectMonth={onSelectWholeMonth}
          selectionState={getSelectionState(section.id)}
          checkboxSide="right"
        />
      </View>
    ),
    [getSelectionState, headerStyle, onSelectWholeMonth, selectionSource],
  );

  const renderItem = useCallback(
    (media: SectionListRenderItemInfo<MiniDocument, DefaultSectionT>) => (
      <View style={styles.item}>
        <FileCard
          roomId={roomId}
          media={media.item}
          onSelect={onSelect}
          selectionSource="documentGallery"
          forceSelection={isSelecting}
          trackingSource="document gallery"
        />
      </View>
    ),
    [isSelecting, onSelect, roomId],
  );

  const contentContainerStyle = useMemo(
    () => [styles.sectionListContent, { paddingBottom: bottomInset }],
    [bottomInset],
  );

  return (
    <SectionListWithEmptyState
      type="documents"
      style={styles.root}
      contentContainerStyle={contentContainerStyle}
      keyExtractor={miniDocumentKeyExtractor}
      sections={sections}
      renderItem={renderItem}
      renderSectionHeader={renderHeader}
      isEmptyStateEnabled={!isLimitedResult}
      scrollEnabled={!isEmpty(sections)}
      stickySectionHeadersEnabled
      ListFooterComponent={sectionListFooter}
    />
  );
};

export { DocumentGallery };
