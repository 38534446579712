import { StyleSheet } from "react-native";

import { authScreensTokens } from "@kraaft/shared/components/auth/authScreenTokens";
import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.WHITE,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "100%",
  },

  title: {
    marginBottom: authScreensTokens.marginBelowTitlesContainer,
  },

  firstNameInputContainer: {
    marginBottom: Spacing.S8,
  },

  errorContainer: {
    marginVertical: Spacing.S8,
  },

  spacer: {
    height: Spacing.S40,
    flexGrow: 1,
  },
});
