import { ActionUserRef } from "@kraaft/shared/core/modules/workflows/types";

export const createStringId = (userId: string) => `id/${userId}`;
export const createColumnId = (columnName: string) => `columnKey/${columnName}`;
export const createIdFromActionUserRef = (actionId: ActionUserRef) => {
  if (actionId.type === "id") {
    return createStringId(actionId.value);
  }
  if (actionId.type === "columnKey") {
    return createColumnId(actionId.value as string);
  }
  return undefined;
};
export const createActionUserRefFromString = (
  actionId: string,
): ActionUserRef | undefined => {
  const [type, ...value] = actionId.split("/");
  // In case column name contains a slash
  const allValue = value.join("");
  if ((type === "id" || type === "columnKey") && allValue) {
    return {
      type,
      value: allValue,
    };
  }
  return undefined;
};
