import { t } from "i18next";
import { call, put, select } from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import {
  clearTemporarySchemaView,
  saveEditedSchemaView,
  saveEditedSchemaViewFailure,
} from "@kraaft/shared/core/modules/schemaView/schemaViewActions";
import { selectUnsavedSchemaViewFromRawId } from "@kraaft/shared/core/modules/schemaView/schemaViewSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { navigationService } from "@kraaft/shared/core/services/navigation/provider";
import { createEntryKeyForSchemaViews } from "@kraaft/web/src/core/modules/memory/memoryUtils";

export function* editSchemaViewSaga(
  action: ReturnType<typeof saveEditedSchemaView>,
) {
  const { payload } = action;
  const temporarySchemaView = yield* select(
    selectUnsavedSchemaViewFromRawId(
      payload.schemaViewId ??
        createEntryKeyForSchemaViews(payload.workspace, payload.schemaId),
    ),
  );

  if (!temporarySchemaView) {
    console.warn("Trying to save non existing temporary schema view");
    return;
  }

  try {
    if (temporarySchemaView.id) {
      yield* call(Api.editSchemaView, {
        id: temporarySchemaView.id,
        editions: {
          name: temporarySchemaView.name,
          displayedProperties:
            temporarySchemaView.formats.kanban.displayedProperties,
          groupedByProperty:
            temporarySchemaView.formats.kanban.groupedByProperty,
          filters: temporarySchemaView.filters,
        },
      });
    } else {
      const createdId = yield* call(Api.addSchemaView, {
        name: temporarySchemaView.name,
        filters: temporarySchemaView.filters,
        schemaId: temporarySchemaView.schemaId,
        groupedBy: temporarySchemaView.formats.kanban.groupedByProperty,
        displayedProperties:
          temporarySchemaView.formats.kanban.displayedProperties,
      });
      yield* put(
        showSuccess({
          title: t("schemaViewCreateSuccessTitle"),
          message: t("schemaViewCreateSuccessContent"),
        }),
      );
      yield* call(() =>
        navigationService.navigate("SchemaView", {
          schemaViewId: createdId,
        }),
      );
    }
    yield* put(
      clearTemporarySchemaView({
        schemaId: payload.schemaId,
        schemaViewId: payload.schemaViewId,
        workspace: payload.workspace,
      }),
    );
  } catch (e) {
    console.error(e);
    // Rollbacks optimistic
    if (temporarySchemaView.id) {
      yield* put(
        saveEditedSchemaViewFailure({
          schemaView: {
            ...temporarySchemaView,
            id: temporarySchemaView.id,
          },
        }),
      );
    }
    yield* put(showError({ title: t("errorServer") }));
  }
}
