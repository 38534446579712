import { makeStyles } from "@mui/styles";

import { styleObject } from "@kraaft/shared/styles/panel.styles";
import { Color, Radius, Spacing } from "@kraaft/ui";
import { HandleSide } from "@kraaft/web/src/components/sidebar/sidebar.types";

export const DEFAULT_DRAWER_WIDTH = 400;
export const MAX_DRAWER_WIDTH = 600;

interface MakeStylesProps {
  width: React.CSSProperties["width"];
  handleSide: HandleSide;
  collapsed: boolean;
  isResizingManually: boolean;
  fullWidth: boolean;
}

const HITBOX = Spacing.S8;

export const useStyles = makeStyles((theme) => {
  const {
    transitions: { easing, duration, ...transitions },
  } = theme;

  return {
    dragger: {
      display: "flex",
      alignItems: "center",
      cursor: "ew-resize",
      padding: `${Spacing.S4}px 0 0`,
      position: "absolute",
      top: 0,
      marginLeft: -HITBOX,
      width: HITBOX,
      marginRight: -HITBOX,
      bottom: 0,
      left: ({ handleSide }: MakeStylesProps) =>
        handleSide === "left" ? HITBOX : undefined,
      right: ({ handleSide }: MakeStylesProps) =>
        handleSide === "right" ? 0 : undefined,
      zIndex: 100,
      "&:hover > div": {
        opacity: 1,
      },
    },
    draggerLine: {
      display: "flex",
      alignItems: "center",
      justifyContent: ({ handleSide }: MakeStylesProps) =>
        handleSide === "left" ? "flex-start" : "flex-end",
      width: 2,
      position: "absolute",
      top: 0,
      bottom: 0,
      backgroundColor: Color.BLUE_AZURE,
      opacity: ({ isResizingManually }: MakeStylesProps) =>
        isResizingManually ? 1 : 0,
    },
    draggerIcon: {
      backgroundColor: Color.BLUE_AZURE,
      padding: `${Spacing.S4}px 0`,
      borderRadius: ({ handleSide }: MakeStylesProps) =>
        handleSide === "left"
          ? `0 ${Radius.SMALL}px ${Radius.SMALL}px 0`
          : `${Radius.SMALL}px 0 0 ${Radius.SMALL}px`,
    },
    sidebar: {
      position: "relative",
      marginLeft: ({ handleSide }: MakeStylesProps) =>
        handleSide === "left" ? "auto" : undefined,
      display: "flex",
      flexDirection: "column",
      flexShrink: 0,
      width: ({ width, collapsed, fullWidth }: MakeStylesProps) =>
        collapsed ? 0 : fullWidth ? "100%" : width,
      backgroundColor: Color.WHITE,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      height: "100%",
      minWidth: ({ width }: MakeStylesProps) => width,
      width: "100%",
    },
    sidebarWithBorderLeft: styleObject.borderLeft,
    sidebarWithBorderRight: styleObject.borderRight,
    shift: ({ collapsed }: MakeStylesProps) => ({
      transition: transitions.create(["width"], {
        easing: easing[collapsed ? "sharp" : "easeOut"],
        duration: duration[collapsed ? "leavingScreen" : "enteringScreen"],
      }),
    }),
  };
});
