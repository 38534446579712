import { useCallback } from "react";

import { Avatar } from "@kraaft/shared/components/avatar/avatar";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import {
  KanbanCardPills,
  KanbanCardPillsProps,
} from "@kraaft/web/src/components/kanban/card/kanbanCardPills";

import { useStyles } from "./kanbanCard.styles";

interface Props extends KanbanCardPillsProps {
  onRecordClick?: (recordId: string) => void;
}

const KanbanCard = (props: Props) => {
  const { schema, modularRecord, onRecordClick, currencyCode } = props;
  const classes = useStyles();

  const handleRecordClick = useCallback(() => {
    onRecordClick?.(modularRecord.id);
  }, [modularRecord.id, onRecordClick]);

  const cardTitle = ModularRecordUtils.getRecordField(modularRecord, "title", [
    KColumnType.shortText,
    KColumnType.automatedAutoIncrement,
  ]);
  const schemaIconName = KSchemaUtils.getSchemaIconName(schema.icon);

  return (
    <div className={classes.cardContainer} onClick={handleRecordClick}>
      <div className={classes.bubbleContainer}>
        <Avatar size="medium" type="icon" name={schemaIconName} />
      </div>
      <div className={classes.infosContainer}>
        <div className={classes.cardTitle}>{cardTitle}</div>
        <KanbanCardPills
          currencyCode={currencyCode}
          displayProperties={props.displayProperties}
          modularRecord={props.modularRecord}
          poolMembers={props.poolMembers}
          schema={props.schema}
        />
      </div>
    </div>
  );
};

export { KanbanCard };
