import { ModularFoldersDefaultHeader } from "@kraaft/shared/components/modularFolders/modularFoldersDefaultHeader";
import {
  useIsModularFoldersSelectionEnabled,
  useOnChangeModularFoldersSelectionType,
} from "@kraaft/shared/components/modularFolders/modularFoldersSelection.context";
import { ModularFoldersSelectionHeader } from "@kraaft/shared/components/modularFolders/modularFoldersSelectionHeader";

interface Props {
  roomId: string;
  schemaId: string;
  onFilterSet: (search: string) => void;
  isStickyPanel?: boolean;
  onBackButtonPress: () => void;
  hideBackButton?: boolean;
}

export const ModularFoldersHeader = ({
  onBackButtonPress,
  onFilterSet,
  roomId,
  schemaId,
  hideBackButton,
  isStickyPanel,
}: Props) => {
  const isSelectionEnabled = useIsModularFoldersSelectionEnabled();
  const onChangeSelectionType = useOnChangeModularFoldersSelectionType();

  return isSelectionEnabled ? (
    <ModularFoldersSelectionHeader
      onFilterSet={onFilterSet}
      onBackButtonPress={() => onChangeSelectionType("none")}
    />
  ) : (
    <ModularFoldersDefaultHeader
      roomId={roomId}
      schemaId={schemaId}
      onBackButtonPress={onBackButtonPress}
      onFilterSet={onFilterSet}
      hideBackButton={hideBackButton}
      isStickyPanel={isStickyPanel}
    />
  );
};
