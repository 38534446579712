import { makeStyles } from "@mui/styles";

import { Color, ColorStyle, PixelSize, Spacing } from "@kraaft/ui";

import background from "./assets/split-screen-background.jpeg";

interface MakeStylesProps {
  fullHeight?: boolean;
  shouldSplitScreen: boolean;
}

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",

    height: "100%",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",

    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "0%",

    margin: Spacing.S32,
    height: `calc(100% - ${Spacing.S32 * 2}px)`,
  },
  contentContainer: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    marginBottom: Spacing.S32,
  },
  wrapper: {
    display: "flex",
    width: ({ shouldSplitScreen }: MakeStylesProps) =>
      shouldSplitScreen ? 400 : "100%",
    height: ({ fullHeight }: MakeStylesProps) => (fullHeight ? "100%" : "auto"),
    flexDirection: "column",
    justifyContent: "center",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",

    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "0%",

    minWidth: 400,

    alignItems: "center",
    justifyContent: "center",

    padding: Spacing.S32,
    textAlign: "center",
    backgroundColor: Color.BLUE_KRAAFT,
  },
  backgroundContainer: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.2,
    zIndex: 1,
  },
  headline: {
    fontSize: 32,
    fontWeight: 500,
    color: ColorStyle.ACTION_CTA,
    maxWidth: "60%",
    marginBottom: Spacing.S32,
  },
  leadWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: PixelSize.S24,
    zIndex: 2,
  },
  leadTitle: {
    fontSize: 32,
    fontWeight: 400,
    color: Color.WHITE,
    maxWidth: 620,
  },
  leadTitleBold: {
    fontWeight: 500,
  },
  leadPartners: {
    width: "70%",
    minWidth: 275,
    maxWidth: 475,
  },

  partners: {
    display: "block",
    height: "auto",
    marginTop: theme.spacing(8),
    maxWidth: theme.spacing(64),
    width: "100%",
  },
}));
