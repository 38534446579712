import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { NonAccessibleText } from "@kraaft/ui";

import { UrlLink } from "../urlLink";

import { styles } from "./cguLinks.styles";

const CguLinks = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <NonAccessibleText style={[styles.text, styles.spacerRight]}>
        {t("lastStepAgreement_ByRegistering")}
      </NonAccessibleText>
      <UrlLink text={t("lastStepAgreement_CGU")} url={t("authCGU")} />
      <NonAccessibleText
        style={[styles.text, styles.spacerLeft, styles.spacerRight]}
      >
        {t("lastStepAgreement_AndThe")}
      </NonAccessibleText>
      <UrlLink
        text={t("lastStepAgreement_Confidentiality")}
        url={t("authPrivacyPolicy")}
      />
      <NonAccessibleText style={[styles.text, styles.spacerLeft]}>
        {t("lastStepAgreement_OfKraaft")}.
      </NonAccessibleText>
    </View>
  );
};

export { CguLinks };
