import { forwardRef, ReactElement, Ref, useMemo } from "react";
import { SectionList, SectionListProps } from "react-native";
import { flatten, isEmpty } from "lodash/fp";

import {
  EmptyState,
  EmptyStateProps,
} from "@kraaft/shared/components/emptyState";

import { styles } from "./styles";

type DefaultSectionT = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type SectionListWithEmptyStateProps<ItemT, SectionT = DefaultSectionT> = Omit<
  SectionListProps<ItemT, SectionT>,
  "ListEmptyComponent"
> & {
  type: EmptyStateProps["type"];
  // should the empty state be displayed on empty data
  isEmptyStateEnabled?: boolean;
};

const SectionListWithEmptyState_ = <ItemT, SectionT>(
  {
    type,
    contentContainerStyle,
    isEmptyStateEnabled = true,
    scrollEnabled = true,
    ...props
  }: SectionListWithEmptyStateProps<ItemT, SectionT>,
  ref: Ref<SectionList<ItemT, SectionT>>,
) => {
  const { sections } = props;

  const isEmptyStateVisible = isEmptyStateEnabled && isEmpty(sections);

  const _contentContainerStyle = useMemo(
    () =>
      flatten([
        contentContainerStyle,
        isEmptyStateVisible && styles.emptyContent,
      ]),
    [contentContainerStyle, isEmptyStateVisible],
  );

  return (
    <SectionList<ItemT, SectionT>
      {...props}
      ref={ref}
      contentContainerStyle={_contentContainerStyle}
      scrollEnabled={isEmptyStateVisible ? false : scrollEnabled}
      ListEmptyComponent={
        isEmptyStateEnabled ? <EmptyState type={type} /> : null
      }
    />
  );
};

export const SectionListWithEmptyState = forwardRef(
  SectionListWithEmptyState_,
) as <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ItemT = any,
  SectionT = DefaultSectionT,
>(
  props: SectionListWithEmptyStateProps<ItemT, SectionT> & {
    ref?: Ref<SectionList<ItemT, SectionT>>;
  },
) => ReactElement;
