import { useCallback, useMemo } from "react";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { KEditorProps } from "@kraaft/web/src/components/modularTable/components/types";

import { useStyles } from "./selectEditor.styles";

const SelectEditor = (
  props:
    | KEditorProps<KColumnType.selectSingle>
    | KEditorProps<KColumnType.selectMultiple>,
) => {
  const { column, row, onClose, onRowChange } = props;
  const classes = useStyles();

  const value = useMemo(
    () => (row.properties[column.key]?.value as string[] | undefined) ?? [],
    [column.key, row.properties],
  );

  const items = useMemo<FilteredListItem[]>(
    () =>
      KSchemaUtils.orderOptions(column).map(([key, option]) => ({
        value: key,
        label: option.label,
      })),
    [column],
  );

  const handleClose = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleSelectionChange = useCallback(
    (newValue: string[] | undefined) => {
      onRowChange(
        {
          ...row,
          properties: {
            ...row.properties,
            [column.key]: {
              columnType: column.type,
              value: newValue,
            },
          },
        },
        column.type === KColumnType.selectSingle,
      );

      if (column.type === KColumnType.selectSingle) {
        handleClose();
      }
    },
    [onRowChange, row, column.key, column.type, handleClose],
  );

  return (
    <div className={classes.container}>
      <KDropdown
        items={items}
        allowMultiple={column.type === KColumnType.selectMultiple}
        selectedItemIds={value}
        alwaysOpen
        onSelectionChange={handleSelectionChange}
        onClose={handleClose}
        withEmptyRow={props.column.preventEmpty ?? true}
        withSearchBar
      />
    </div>
  );
};

export { SelectEditor };
