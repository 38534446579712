import { createAction } from "@reduxjs/toolkit";

import {
  PollingDescriptor,
  PoolingData,
} from "@kraaft/shared/core/modules/polling/pollingState";

export type StartPollingPayload = PollingDescriptor & { delay: number };
export const startPolling = createAction(
  "@polling/startPolling",
  (payload: StartPollingPayload) => ({
    payload: {
      ...payload,
      subscriberId: `${Date.now()} ${Math.random().toString()}`,
    },
  }),
);

export type StopPollingPayload = PollingDescriptor & { subscriberId: string };
export const stopPolling = createAction<StopPollingPayload>(
  "@polling/stopPolling",
);

export type UpdatePollingPayload = PollingDescriptor & { data: PoolingData };
export const updatePolling = createAction<UpdatePollingPayload>(
  "@polling/updatePolling",
);
