import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { UserPoolActions } from "@kraaft/shared/core/modules/userPool/userPool.actions";

export function useSubscribedToUserPool(poolId: string | undefined) {
  const dispatch = useDispatch();
  const userId = useSelector(selectCurrentUserId);

  useEffect(() => {
    if (!poolId || !userId) {
      return;
    }
    dispatch(UserPoolActions.subscribeToUserPool({ poolId, userId }));
    return () => {
      dispatch(UserPoolActions.unsubscribeFromUserPool({ poolId, userId }));
    };
  }, [dispatch, poolId, userId]);
}
