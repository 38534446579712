import {
  call,
  put,
  select,
  spawn,
  takeEvery,
  takeLatest,
} from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { fileUpload } from "@kraaft/shared/core/modules/file/fileUploader";
import { ReportTemplateOptimisticSaga } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.optimistic";
import { selectTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.selectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

import { subscribeToReportTemplatesSaga } from "./sagas/subscribeToReportTemplate";
import * as actions from "./reportTemplate.actions";

export function* reportTemplateSagas() {
  yield* spawn(subscribeToReportTemplatesSaga);
  yield* spawn(ReportTemplateOptimisticSaga);

  yield* takeEvery(
    actions.ReportTemplateActions.addReportTemplate,
    addReportTemplateSaga,
  );
  yield* takeLatest(
    actions.ReportTemplateActions.editReportTemplateFile,
    editReportTemplateFileSaga,
  );
}

function* addReportTemplateSaga(
  action: ReturnType<typeof actions.ReportTemplateActions.addReportTemplate>,
) {
  const { payload, meta } = action;
  const { poolId, schemaId, name, file, forceAdd } = payload;

  try {
    const storagePathPayload = {
      poolId: poolId,
      filename: file.filename,
    };
    const uploadPath = yield* call(
      Api.generateReportTemplateUploadPath,
      storagePathPayload,
    );

    yield* call(() =>
      fileUpload.upload({
        file,
        storagePath: uploadPath.storagePath,
        uploadUrl: uploadPath.uploadUrl,
      }),
    );

    const templatePayload = {
      poolId,
      schemaId,
      name,
      storagePath: uploadPath.storagePath,
      forceAdd,
    };

    const response = yield* call(Api.addReportTemplate, templatePayload);

    if (response.success) {
      yield* put(showSuccess({ title: i18n.t("templateAddSuccess") }));
      yield* put(actions.ReportTemplateActions.addReportTemplateSuccess(meta));
    } else {
      let errorMessage = "";
      if (response.unknownTags !== undefined) {
        const unknownTags = response.unknownTags?.join(", ");

        errorMessage = i18n.t("templateAddFailureUnknownTags", { unknownTags });
      }

      const error = new Error(errorMessage);
      throw error;
    }
  } catch (error) {
    yield* put(showError({ title: i18n.t("templateAddFailure") }));
    yield* put(
      actions.ReportTemplateActions.addReportTemplateFailure(error, meta),
    );
  }
}

function* editReportTemplateFileSaga(
  action: ReturnType<
    typeof actions.ReportTemplateActions.editReportTemplateFile
  >,
) {
  const { payload, meta } = action;
  const { reportTemplateId, file, forceAdd } = payload;

  const reportTemplate = yield* select(selectTemplate(reportTemplateId));

  if (reportTemplate === undefined) {
    return;
  }

  try {
    const storagePathPayload = {
      poolId: reportTemplate.poolId,
      filename: file.filename,
    };
    const uploadPath = yield* call(
      Api.generateReportTemplateUploadPath,
      storagePathPayload,
    );

    yield* call(() =>
      fileUpload.upload({
        file,
        storagePath: uploadPath.storagePath,
        uploadUrl: uploadPath.uploadUrl,
      }),
    );

    const templatePayload = {
      reportTemplateId,
      storagePath: uploadPath.storagePath,
      forceAdd,
    };

    const response = yield* call(
      Api.editCustomReportTemplateFile,
      templatePayload,
    );

    if (response.success) {
      yield* put(showSuccess({ title: i18n.t("templateAddSuccess") }));
      yield* put(actions.ReportTemplateActions.addReportTemplateSuccess(meta));
    } else {
      let errorMessage = "";
      if (response.unknownTags !== undefined) {
        const unknownTags = response.unknownTags?.join(", ");

        errorMessage = i18n.t("templateAddFailureUnknownTags", { unknownTags });
      }

      const error = new Error(errorMessage);
      throw error;
    }
  } catch (error) {
    yield* put(showError({ title: i18n.t("templateAddFailure") }));
    yield* put(
      actions.ReportTemplateActions.addReportTemplateFailure(error, meta),
    );
  }
}
