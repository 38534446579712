import { IconButton } from "@mui/material";

import { Button } from "@kraaft/ui";

interface Props {
  onClick?: React.ComponentProps<typeof IconButton>["onClick"];
}

const ReplyButton = ({ onClick }: Props) => {
  return (
    <Button
      icon="corner-down-left"
      onPress={onClick}
      size="SMALL"
      variant="TERTIARY"
    />
  );
};

export { ReplyButton };
