import { memoize } from "lodash";
import { createSelector } from "reselect";

import { RootState } from "@kraaft/shared/core/store";

export const selectUserPoolState = ({ userPool }: RootState) => userPool;

export const selectUserPoolNotificationFilter = memoize((poolId: string) =>
  createSelector(
    selectUserPoolState,
    (state) => state[poolId]?.notificationFilter ?? "all",
  ),
);
