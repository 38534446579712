import { useTranslation } from "react-i18next";

import { EditSchemaVisibilityForRoom } from "@kraaft/shared/components/roomSchemaVisibility/editSchemaVisibilityForRoom";
import { DetailsRoute } from "@kraaft/web/src/components/detailsRoute";
import { detailsQuery } from "@kraaft/web/src/views/app/appRouter/routes";

const EditSchemaVisibilityForRoomRoute = ({
  roomId,
  onBackButtonPress,
  isStickyPanel,
}: {
  roomId: string;
  onBackButtonPress: () => void;
  isStickyPanel?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <DetailsRoute
      path={detailsQuery.roomSchemaVisibility}
      title={t("conversationFolder.schemas.conversationSchemas")}
      onBackButtonPress={onBackButtonPress}
      isStickyPanel={isStickyPanel}
    >
      <EditSchemaVisibilityForRoom
        roomId={roomId}
        source="folder_tab"
        showModularFolderCount
      />
    </DetailsRoute>
  );
};

export { EditSchemaVisibilityForRoomRoute };
