import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectLoader } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { MiniMedia } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";

import {
  DOWNLOAD_DIRECTORY_LIST_LOADER_ID,
  REMOVE_DIRECTORY_LIST_LOADER_ID,
} from "./types";

interface Props {
  roomId: string;
  medias: MiniMedia[];
}

const useDirectoryListContext = (props: Props) => {
  const { roomId, medias } = props;

  const loaderRemove = useSelector(
    selectLoader(REMOVE_DIRECTORY_LIST_LOADER_ID),
  );
  const loaderDownload = useSelector(
    selectLoader(DOWNLOAD_DIRECTORY_LIST_LOADER_ID),
  );

  const isFileCardDisabled = useMemo(
    () =>
      loaderDownload?.status === LoaderStatus.LOADING ||
      loaderRemove?.status === LoaderStatus.LOADING,
    [loaderDownload?.status, loaderRemove?.status],
  );
  const { isSelecting } = useMediaSelectionContext({
    type: "directory",
    medias,
    roomId,
  });

  return { isFileCardDisabled, isSelecting };
};

export { useDirectoryListContext };
