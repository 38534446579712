import { ForgotPasswordEmailSent } from "@kraaft/shared/components/auth/forgotPasswordEmailSent";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { Box } from "@kraaft/web/src/components/box";
import { SplitScreenWrapper } from "@kraaft/web/src/components/splitScreenWrapper";
import { SplitScreenBackButton } from "@kraaft/web/src/components/splitScreenWrapper/splitScreenBackButton";

const ForgotPasswordEmailSentWeb = () => {
  const navigationService = useNavigationService();

  return (
    <SplitScreenWrapper>
      <Box mb="S16">
        <SplitScreenBackButton onClick={() => navigationService.goBack()} />
      </Box>
      <ForgotPasswordEmailSent />
    </SplitScreenWrapper>
  );
};

export { ForgotPasswordEmailSentWeb };
