import { memo, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import isEqual from "fast-deep-equal";

import { AlertDialog } from "@kraaft/shared/components/alertDialog";
import { CenterMediaMarker } from "@kraaft/shared/components/geolocation/centerMediaMarker";
import { LockedAnswersBanner } from "@kraaft/shared/components/modular/details/lockedAnswersBanner";
import {
  FooterPlaceLocation,
  FooterPlaceLocationProps,
} from "@kraaft/shared/components/placeSelection/footerPlaceLocation";
import { NoLocationReason } from "@kraaft/shared/components/placeSelection/footerPlaceLocation/noLocationReason";
import { MapDisplayer } from "@kraaft/shared/components/placeSelection/mapDisplayer";
import { MapDisplayerHandle } from "@kraaft/shared/components/placeSelection/mapDisplayer/mapDisplayerProps";
import { MapDisplayerCenteredOnCurrentLocation } from "@kraaft/shared/components/placeSelection/mapDisplayerCenteredOnCurrentLocation";
import { PlaceSearchBar } from "@kraaft/shared/components/placeSelection/placeSearchBar";
import { PlaceSelectionHeader } from "@kraaft/shared/components/placeSelection/placeSelectionHeader";
import { SectionLockInfo } from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { GeoLocation } from "@kraaft/shared/core/types";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { IconName, Spacing } from "@kraaft/ui";

import { styles } from "./placeSelection.styles";

export type PlaceSelectionProps = Pick<FooterPlaceLocationProps, "context"> & {
  centerOnCurrentLocation?: boolean;
  defaultValue?: GeoLocation | undefined;
  thumbnailUrl?: string;
  sendButtonLabel?: string;
  title: string;
  editTitle?: string;
  closeIcon?: IconName;
  isEditable: boolean;
  isOnlyEditable?: boolean;
  onClose?: () => void;
  onSubmit?: (address: GeoLocation | undefined) => void;
  sectionLockInfo?: SectionLockInfo;
};

const PlaceSelection_ = ({
  context,
  centerOnCurrentLocation,
  defaultValue,
  thumbnailUrl,
  sendButtonLabel,
  title,
  editTitle,
  closeIcon = "chevron-left",
  isEditable,
  isOnlyEditable,
  onClose,
  onSubmit,
  sectionLockInfo,
}: PlaceSelectionProps) => {
  const { t } = useTranslation();

  const [isEditing, startEditing, stopEditing] = useBooleanState(
    isOnlyEditable || (isEditable && !defaultValue),
  );
  const [location, setLocation] = useState<GeoLocation | undefined>(
    defaultValue,
  );
  const mapRef = useRef<MapDisplayerHandle>(null);

  const hasExistingLocation = defaultValue !== undefined;

  const handleSubmit = useCallback(async () => {
    if (location === undefined) {
      AlertDialog.alert(
        t("addressNotFoundTitle"),
        t("addressNotFoundMessage"),
        [
          {
            text: t("cancel"),
            style: "cancel",
          },
        ],
      );
      return;
    }
    onSubmit?.(location);
    onClose?.();
  }, [location, onSubmit, onClose, t]);

  const handleStartEditing = useCallback(() => {
    setLocation(defaultValue);
    startEditing();
  }, [defaultValue, startEditing]);

  const handleCancelEditing = useCallback(() => {
    setLocation(defaultValue);
    stopEditing();
    if (!hasExistingLocation) {
      onClose?.();
    }
  }, [defaultValue, hasExistingLocation, onClose, stopEditing]);

  const handleDelete = useCallback(() => {
    AlertDialog.alert(
      t("placeSelection.deleteLocationTitle"),
      t("placeSelection.deleteLocationMessage"),
      [
        {
          style: "destructive",
          text: t("confirm"),
          onPress: () => {
            onSubmit?.(undefined);
            onClose?.();
          },
        },
        { style: "cancel", text: t("cancel") },
      ],
    );
  }, [onClose, onSubmit, t]);

  const changeLocation = useCallback((newLocation: GeoLocation | undefined) => {
    setLocation(newLocation);
    if (newLocation) {
      mapRef.current?.focusOnLocation(newLocation);
    }
  }, []);

  const [noLocationReason, setNoLocationReason] = useState<NoLocationReason>(
    centerOnCurrentLocation
      ? "loading-current-location"
      : "loading-target-location",
  );

  const handleSetLocation = useCallback(
    (newLocation: GeoLocation | undefined) => {
      setLocation(newLocation);
      if (newLocation === undefined) {
        setNoLocationReason("blank");
      } else {
        setNoLocationReason("invalid-location");
      }
    },
    [],
  );

  const MapDisplayerComponent = centerOnCurrentLocation
    ? MapDisplayerCenteredOnCurrentLocation
    : MapDisplayer;

  return (
    <>
      <PlaceSelectionHeader
        title={title}
        editTitle={editTitle}
        closeIcon={closeIcon}
        hasExistingLocation={hasExistingLocation}
        isEditing={isEditing}
        isOnlyEditable={isOnlyEditable}
        onCancelEditing={handleCancelEditing}
        onClose={onClose}
      />
      <View style={styles.mapContainer}>
        {sectionLockInfo?.isLocked && (
          <LockedAnswersBanner
            lockedBy={sectionLockInfo?.lockedBy}
            lockedAt={sectionLockInfo?.lockedAt}
            mode="singular"
          />
        )}
        {isEditing && (
          <PlaceSearchBar
            extraPadding={Spacing.S8}
            setLocation={changeLocation}
          />
        )}
        <View style={styles.map}>
          <MapDisplayerComponent
            ref={mapRef}
            location={location}
            isEditing={isEditing}
            setLocation={handleSetLocation}
            centerMarker={
              thumbnailUrl && <CenterMediaMarker thumbnailUrl={thumbnailUrl} />
            }
          />
        </View>
        <FooterPlaceLocation
          noLocationReason={noLocationReason}
          isEditing={isEditing}
          location={location}
          context={context}
          submitEditionText={sendButtonLabel ?? ""}
          onStartEdition={handleStartEditing}
          onSubmitEdition={handleSubmit}
          isEditable={!isOnlyEditable && isEditable}
          isDeletable={hasExistingLocation}
          onDelete={handleDelete}
        />
      </View>
    </>
  );
};

export const PlaceSelection = memo(PlaceSelection_, isEqual);
