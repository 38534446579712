import { call } from "typed-redux-saga/macro";

import { MapOverlayStateActions } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.actions";
import {
  selectMapOverlays,
  selectOptimisticState,
} from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.selectors";
import { MapOverlay } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.state";
import { Api } from "@kraaft/shared/core/services/api";
import {
  createOperation,
  createOptimisticReduxBundle,
} from "@kraaft/shared/core/utils/optimistic/createReduxBundle";

export const {
  actions: MapOverlayOptimisticActions,
  delaySnapshot: MapOverlayDelaySnapshot,
  operations: MapOverlayOptimisticOperations,
  reducer: MapOverlayOptimisticReducer,
  saga: MapOverlayOptimisticSaga,
  selectors: MapOverlayOptimisticSelector,
} = createOptimisticReduxBundle(
  "MapOverlay",
  [
    createOperation<MapOverlay, "delete", unknown>({
      type: "delete",
      optimistic: () => {
        return null;
      },
      call: function* ({ payload }) {
        const { updatedAt } = yield* call(Api.deleteMapOverlay, {
          mapOverlayId: payload.targetId,
        });

        return updatedAt;
      },
    }),
  ],
  (mapOverlay) => mapOverlay.id,
  MapOverlayStateActions.set,
  selectOptimisticState,
  selectMapOverlays,
);
