import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useRoomPinning } from "@kraaft/shared/core/modules/room/hooks/roomPinning.hook";
import { Button } from "@kraaft/ui";

interface PinningSectionProps {
  roomId: string;
  setDisplayed?: () => void;
}

export const PinningSection = ({
  roomId,
  setDisplayed,
}: PinningSectionProps) => {
  const { t } = useTranslation();
  const { pin, isPinned, isPinnable, unpin } = useRoomPinning(roomId);

  useEffect(() => {
    if (isPinnable) {
      setDisplayed?.();
    }
  }, [isPinnable, setDisplayed]);

  if (!isPinnable) {
    return null;
  }

  return isPinned ? (
    <Button
      variant="QUATERNARY"
      size="SMALL"
      alignContent="left"
      accessibilityLabel={t("roomPinning.pin")}
      text={t("roomPinning.unpinRoom")}
      icon="pin-02"
      onPress={unpin}
    />
  ) : (
    <Button
      variant="QUATERNARY"
      size="SMALL"
      alignContent="left"
      accessibilityLabel={t("roomPinning.unpin")}
      text={t("roomPinning.pinRoom")}
      icon="pin-02"
      onPress={pin}
    />
  );
};
