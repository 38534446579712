import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  rowGroupContainer: {
    flexGrow: 1,
  },
  rowGroupContainerDisabled: {
    opacity: 0.3,
  },
});
