import { useTranslation } from "react-i18next";
import { Pressable } from "react-native";
import { useSelector } from "react-redux";

import { RoomCardContainer } from "@kraaft/shared/components/roomCard/roomCardContainer";
import { useRoomDescription } from "@kraaft/shared/components/roomCard/roomCardUtils";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { selectUsers } from "@kraaft/shared/core/modules/user/userSelectors";

import { styles } from "./simpleRoomCardForMaps.styles";

export interface SimpleRoomCardProps {
  roomCard: AnyRoomCard;
  onPress?: () => void;
}

const SimpleRoomCard = ({ roomCard, onPress }: SimpleRoomCardProps) => {
  const { t } = useTranslation();

  const users = useSelector(selectUsers);
  const pillColumns = useSelector(
    selectRoomSchema(roomCard.poolId),
  )?.roomCardDisplayColumns;

  const description = useRoomDescription(
    roomCard,
    users[roomCard.roomCreatedBy],
    roomCard.lastMessageInfo && users[roomCard.lastMessageInfo.senderId],
  );

  return (
    <Pressable
      accessibilityLabel={t("openRoom")}
      onPress={onPress}
      style={styles.cardContainer}
    >
      <RoomCardContainer
        roomCard={roomCard}
        description={description}
        pillColumns={pillColumns}
        onPress={onPress}
        isActive={false}
      />
    </Pressable>
  );
};

export { SimpleRoomCard };
