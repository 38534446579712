import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";

export function useAlert() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMemo(
    () => ({
      alertError: (error: Error, context = "") => {
        console.log(`Error ${context}:`, error);
        dispatch(
          showError({ title: t("errorServer"), message: error.message }),
        );
      },
      alertSuccess: ({
        message = "",
        title = "",
      }: {
        message?: string;
        title?: string;
      }) => {
        dispatch(showSuccess({ title, message }));
      },
    }),
    [dispatch, t],
  );
}
