import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { DeepPartial, Dictionary } from "ts-essentials";

import { OpenCheckboxGuard } from "@kraaft/shared/components/modularFolderList/useCheckboxGuard";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";

interface UseModularFolderCheckboxPress {
  currentUserId?: string;
  folders?: Dictionary<ModularFolder>;
  highlightedCheckboxColumnKey?: string;
  openCheckboxGuard: OpenCheckboxGuard;
  alterRecord: (key: string, value: DeepPartial<ModularFolder>) => void;
  revertRecordChanges: (key: string) => void;
}

export const useModularFolderCheckboxPress = ({
  currentUserId,
  folders,
  highlightedCheckboxColumnKey,
  openCheckboxGuard,
  alterRecord,
  revertRecordChanges,
}: UseModularFolderCheckboxPress) => {
  const dispatch = useDispatch();

  return useCallback(
    (
      modularFolderId: string,
      newValue: boolean,
      shouldAlterRecord: boolean,
    ) => {
      if (!folders || !highlightedCheckboxColumnKey) {
        return;
      }

      const hasValueChanged =
        folders[modularFolderId]?.properties[highlightedCheckboxColumnKey]
          ?.value !== newValue;

      if (!hasValueChanged) {
        return;
      }

      if (shouldAlterRecord) {
        alterRecord(modularFolderId, {
          properties: {
            [highlightedCheckboxColumnKey]: {
              columnType: KColumnType.checkbox,
              value: newValue,
              updatedAt: new Date(),
              updatedBy: currentUserId,
            },
          },
        });
      }

      function resetChanges() {
        if (shouldAlterRecord) {
          revertRecordChanges(modularFolderId);
        }
      }

      openCheckboxGuard(modularFolderId, {
        onConfirm: () => {
          resetChanges();
          dispatch(
            OfflineModularFolderActions.edit({
              editions: [
                {
                  id: modularFolderId,
                  properties: [
                    [
                      highlightedCheckboxColumnKey,
                      {
                        columnType: KColumnType.checkbox,
                        value: newValue,
                        updatedAt: new Date(),
                        updatedBy: currentUserId,
                      },
                    ],
                  ],
                },
              ],
            }),
          );
        },
        onCancel: () => {
          resetChanges();
        },
      });
    },
    [
      folders,
      alterRecord,
      currentUserId,
      dispatch,
      highlightedCheckboxColumnKey,
      openCheckboxGuard,
      revertRecordChanges,
    ],
  );
};
