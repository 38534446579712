import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";

import { Avatar, AvatarProps } from "@kraaft/shared/components/avatar";
import { ColorStyle, Text } from "@kraaft/ui";

import { AvatarContent } from "./avatarList.types";

import { styles } from "./avatarList.styles";

interface Props {
  content: AvatarContent;
  onPress?: (user: AvatarContent) => void;
  isRemovable?: boolean;
  isCurrentUser?: boolean;
  displayUserThumbnailOrInitials?: boolean;
}

const AvatarListItem = (props: Props) => {
  const {
    content,
    onPress,
    isRemovable,
    displayUserThumbnailOrInitials,
    isCurrentUser,
  } = props;
  const { t } = useTranslation();

  const isClickable = onPress !== undefined;

  const handlePress = useCallback(() => {
    onPress?.(content);
  }, [onPress, content]);

  const userLabel = isCurrentUser
    ? `${content.displayName} (${t("you")})`
    : content.displayName;

  const avatarProps = useMemo<AvatarProps>(() => {
    const _avatarProps: AvatarProps = {
      ...(displayUserThumbnailOrInitials
        ? content.thumbnailUri
          ? {
              type: "image",
              source: { uri: content.thumbnailUri },
            }
          : {
              type: "initials",
              initials: content.initials ?? content.displayName.slice(0, 2),
              color: ColorStyle.FONT_HIGH_EMPHASIS,
              backgroundColor: ColorStyle.BACKGROUND_STANDARD,
            }
        : {
            type: "icon",
            name: "user-01",
            color: ColorStyle.FONT_HIGH_EMPHASIS,
            backgroundColor: ColorStyle.BACKGROUND_STANDARD,
          }),
      ...(isClickable && isRemovable
        ? {
            topIndicatorIconName: "x-close",
            topIndicatorIconColor: ColorStyle.FONT_HIGH_EMPHASIS,
            topIndicatorBackgroundColor: ColorStyle.BACKGROUND_STANDARD,
          }
        : {}),
    };

    return _avatarProps;
  }, [
    displayUserThumbnailOrInitials,
    content.thumbnailUri,
    content.initials,
    content.displayName,
    isClickable,
    isRemovable,
  ]);

  return (
    <TouchableOpacity
      accessibilityLabel={userLabel}
      onPress={isRemovable ? handlePress : undefined}
      style={styles.itemContainer}
    >
      <Avatar size="medium" {...avatarProps} />
      <View style={styles.nameContainer}>
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={styles.name}
          weight="bold"
        >
          {userLabel}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export { AvatarListItem };
