import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFoldersMap } from "@kraaft/web/src/components/modularFoldersMap";

import { useFilteredModularFoldersForRoom } from "./modularFoldersMapViewUtils";

interface Props {
  roomId: string;
  schema: KFolderSchema;
  schemaViewId?: string;
}

const ModularFoldersMapViewForRoom = ({
  schema,
  roomId,
  schemaViewId,
}: Props) => {
  const { isLoaded, filteredFolders } = useFilteredModularFoldersForRoom({
    roomId,
    schema,
    schemaViewId,
  });

  return (
    <ModularFoldersMap
      isLoaded={isLoaded}
      filteredFolders={filteredFolders}
      schema={schema}
    />
  );
};

export { ModularFoldersMapViewForRoom };
