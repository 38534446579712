import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    flex: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    cursor: "pointer",
  },
});
