import { useEffect, useMemo } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { DraggableMessageProps } from "@kraaft/shared/components/draggableMessage/types";
import { isIE } from "@kraaft/shared/core/utils";

function getStyle(isDragging: boolean): React.CSSProperties {
  return {
    cursor: "grab",
    opacity: isDragging ? 0.6 : 1,
  };
}

const DraggableMessage = ({
  children,
  messageType,
  messageId,
  renderText,
  style,
  id,
}: DraggableMessageProps) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: messageType,
    item: { type: messageType, messageId, renderText },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const containerStyle = useMemo(
    () => ({ ...getStyle(isDragging), ...style }),
    [isDragging, style],
  );

  useEffect(() => {
    if (!isIE()) {
      preview(getEmptyImage(), {
        captureDraggingState: true,
      });
    }
  }, [preview]);

  return (
    <div id={id} ref={drag} style={containerStyle}>
      {children}
    </div>
  );
};

export { DraggableMessage };
