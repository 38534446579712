import { TouchableOpacity } from "react-native";

import { styles } from "./urlLink.styles";

interface Props {
  text: string;
  url: string;
  style?: React.CSSProperties;
}

const UrlLink = (props: Props) => {
  const { text, url, style } = props;

  return (
    <TouchableOpacity accessibilityLabel={text}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={url}
        style={{ ...styles.link, ...style }}
      >
        {text}
      </a>
    </TouchableOpacity>
  );
};

export { UrlLink };
