import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import {
  Color,
  ColorStyle,
  Icon,
  IconName,
  Radius,
  Spacing,
  Text,
} from "@kraaft/ui";
import { BaseNavigationElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/baseNavigationElement";
import { CollapsibleNavigationBarElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/collapsibleNavigationBarElement";

export type ButtonElementProps = {
  id?: string;
  icon: IconName;
  text: string;
  onPress: () => void;
  isSelected?: boolean;
  withChevron?: boolean;
};

const ButtonElement = React.forwardRef<HTMLDivElement, ButtonElementProps>(
  ({ id, icon, text, onPress, isSelected, withChevron }, ref) => {
    const classes = useStyles();
    const fontColor = isSelected ? Color.WHITE : ColorStyle.FONT_HIGH_EMPHASIS;

    return (
      <div
        ref={ref}
        id={id}
        className={clsx(
          classes.container,
          isSelected && classes.selectedContainer,
        )}
        onClick={onPress}
      >
        <BaseNavigationElement>
          <Icon name={icon} color={fontColor} />
        </BaseNavigationElement>
        <CollapsibleNavigationBarElement>
          <div className={classes.collapsible}>
            <Text size="BODY" color={fontColor} numberOfLines={1}>
              {text}
            </Text>
            {withChevron ? (
              <Icon name="chevron-right" color={fontColor} size="SMALL" />
            ) : null}
          </div>
        </CollapsibleNavigationBarElement>
      </div>
    );
  },
);

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",

    width: "100%",
    padding: `${Spacing.S4}px ${Spacing.NONE}px`,
    backgroundColor: Color.TRANSPARENT,
    borderRadius: Radius.SMALL,
    cursor: "pointer",

    "&:hover": {
      backgroundColor: Color.BLUE_LAVENDER,
    },
  },
  selectedContainer: {
    backgroundColor: ColorStyle.ACTION_CTA,
    "&:hover": {
      backgroundColor: ColorStyle.ACTION_CTA_HOVERED,
    },
  },
  collapsible: {
    marginLeft: Spacing.S8,
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    gap: Spacing.S8,
    paddingRight: Spacing.S8,
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export { ButtonElement };
