import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { Color, Preloader, Text } from "@kraaft/ui";

import { styles } from "./roomList.styles";

export const LoadingFooter = () => (
  <View style={styles.loadingFooter}>
    <Preloader
      nativeID="ide2e-tickets-loading"
      color={Color.BLUE_AZURE}
      transparent
      absoluteFill
    />
  </View>
);

export const LoadMoreErrorFooter = () => {
  const { t } = useTranslation();
  return (
    <Text size="BODY" color="FONT_LOW_EMPHASIS" style={styles.textFooter}>
      {t("loadMoreError")}
    </Text>
  );
};

export const NoResultFooter = () => {
  const { t } = useTranslation();
  return (
    <Text size="BODY" color="FONT_LOW_EMPHASIS" style={styles.textFooter}>
      {t("roomList.noResult")}
    </Text>
  );
};
