import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DialogContentText } from "@mui/material";
import clsx from "clsx";

import { KDialog } from "@kraaft/shared/components/kDialog";
import { KDialogConfig } from "@kraaft/shared/components/kDialog/kDialogProps";
import { CustomReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";

import { useStyles } from "./removeReportTemplateDialog.styles";

type RemoveReportTemplateDialogProps = {
  reportTemplate: CustomReportTemplate;
  open: boolean;
  onClose: () => void;
  onRemove?: (reportTemplateId: string) => void;
};
const RemoveReportTemplateDialog = ({
  open,
  onClose,
  reportTemplate,
  onRemove,
}: RemoveReportTemplateDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleRemoveTemplate = useCallback(() => {
    onRemove?.(reportTemplate.id);
    onClose();
  }, [onRemove, onClose, reportTemplate.id]);

  const content = useMemo(
    () => (
      <div className={classes.container}>
        <DialogContentText
          classes={{ root: clsx(classes.text, classes.spacer) }}
        >
          {t("reportTemplate.removeDialogSentence1")}
        </DialogContentText>
        <DialogContentText
          classes={{ root: clsx(classes.text, classes.noMargin) }}
        >
          {t("reportTemplate.removeDialogSentence2")}
        </DialogContentText>
      </div>
    ),
    [classes.container, classes.noMargin, classes.spacer, classes.text, t],
  );

  const dialog: KDialogConfig = {
    title: t("reportTemplate.removeDialogTitle", {
      name: reportTemplate.name,
    }),
    content,
    actions: [
      {
        id: "ide2e-template-remove",
        accessibilityLabel: t("remove"),
        text: t("remove"),
        onPress: handleRemoveTemplate,
        destructive: true,
        autoFocus: true,
      },
      {
        accessibilityLabel: t("cancel"),
        text: t("cancel"),
        onPress: onClose,
      },
    ],
  };

  return <KDialog open={open} onClose={onClose} {...dialog} />;
};

export { RemoveReportTemplateDialog };
