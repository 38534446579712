import { IconAsText, Text } from "@kraaft/ui";

interface DifferenceTextProps {
  children: string;
}

export const DifferenceText = ({ children }: DifferenceTextProps) => {
  return children.split("\n").map((element, index, array) => {
    const isNotLast = index < array.length - 1;

    return (
      <Text key={index.toString()} size="BODY" breakWords>
        {element}
        {isNotLast && (
          <IconAsText iconName="corner-down-left" color="GREY_TAUPE" />
        )}
      </Text>
    );
  });
};
