import { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";

import {
  ImagePreviewSquare,
  ImagePreviewSquareProps,
} from "@kraaft/shared/components/imagesPreview/imagePreviewSquare";
import { alpha } from "@kraaft/shared/core/utils/colorsUtils";
import { Color, Text } from "@kraaft/ui";

type MorePreviewSquareProps = ImagePreviewSquareProps & {
  extraCount: number;
};

const MorePreviewSquare = ({
  extraCount,
  preferredSize,
  ...imagePreviewSquareProps
}: PropsWithChildren<MorePreviewSquareProps>) => {
  return (
    <ImagePreviewSquare
      accessibilityLabel={imagePreviewSquareProps.accessibilityLabel}
      onPress={imagePreviewSquareProps.onPress}
      downloadUrl={imagePreviewSquareProps.downloadUrl}
      resizeMode={imagePreviewSquareProps.resizeMode}
      preferredSize={preferredSize}
      backgroundColor={imagePreviewSquareProps.backgroundColor}
    >
      {extraCount ? (
        <View style={styles.overlayContainer}>
          <Text
            color="WHITE"
            size="BODY"
            selectable={false}
          >{`+${extraCount}`}</Text>
        </View>
      ) : null}
    </ImagePreviewSquare>
  );
};

const styles = StyleSheet.create({
  overlayContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: alpha(Color.BLACK, 0.25),
  },
});

export { MorePreviewSquare };
