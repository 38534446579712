import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import orderBy from "lodash/orderBy";

import { sanitizeColumnType } from "@kraaft/shared/core/generated/__generated/builtinsConditions";
import {
  CompositeCondition,
  Condition,
  CoupleRecordCondition,
  MetadataCondition,
  StandaloneRecordCondition,
} from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Button } from "@kraaft/ui";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { useHandleChangeSingleSelectHelper } from "@kraaft/web/src/components/dropdown/kDropdownUtils";
import { FilterOperator } from "@kraaft/web/src/components/filterMenu/filterMenuTypes";
import {
  getFilterOptions,
  isColumnAvailableFilter,
  isConditionWithColumnKey,
  isConditionWithPredicateValue,
  transferPredicateValue,
} from "@kraaft/web/src/components/filterMenu/filterUtils";
import { PredicateValueInputFactory } from "@kraaft/web/src/components/filterMenu/predicateValueInputFactory";

import { useStyles } from "./filterItem.styles";

const ROOM_FILTER_ID = "$_room_column_$";

export interface FilterItemProps {
  index: number;
  condition: Condition;
  operator: FilterOperator;
  columns: { [key: string]: { path: KSchemaSection[]; column: KSchemaColumn } };
  setOperator: (operator: FilterOperator) => void;
  setCondition: (condition: Condition, index: number) => void;
  deleteItem: (index: number) => void;
  disabledColumnTypes?: KColumnType[];
}

const FilterItem = (props: FilterItemProps): JSX.Element => {
  const {
    condition,
    index,
    columns,
    operator,
    setCondition,
    deleteItem,
    setOperator,
    disabledColumnTypes,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const getInitialValueRenderer = useCallback(() => {
    if (condition.type === "couple-record") {
      const element = columns[condition.columnKey];
      if (element) {
        return sanitizeColumnType(element.column.type);
      }
    }
    return undefined;
  }, [columns, condition]);

  const [valueColumnType, setValueColumnType] = useState<
    KColumnType | undefined
  >(getInitialValueRenderer());

  const [operatorOptions, selectedOperatorOptionsIds] = useMemo(() => {
    const options: FilteredListItem[] = [
      { value: "and", label: t("andFilter") },
      { value: "or", label: t("orFilter") },
    ];
    const selected = options
      .filter((option) => option.value === operator)
      .map((option) => option.value);

    return [options, selected];
  }, [operator, t]);

  const handleOperatorChange = useHandleChangeSingleSelectHelper(
    (newOperator: FilterOperator | undefined) => {
      setOperator(newOperator ?? "and");
    },
    [setOperator],
  );

  const [columnKeyOptions, selectedColumnKeyOptionsIds] = useMemo(() => {
    const options: FilteredListItem[] = [];

    if (
      !disabledColumnTypes?.some(
        (e) => e === KColumnType.roomMembers || e === KColumnType.roomName,
      )
    ) {
      options.push({ value: "rooms", label: t("filterTicket") });
    }

    options.push(
      ...orderBy(
        Object.values(columns).filter(({ column }) =>
          isColumnAvailableFilter(column),
        ),
        ({ path, column }) =>
          KSchemaUtils.getDisplayOrderFromPathAndColmun(path, column),
      )
        .filter(({ column }) => !disabledColumnTypes?.includes(column.type))
        .map(({ path, column }) => ({
          value: column.key,
          label: KSchemaUtils.getStringPath(path, column),
        })),
    );

    function getSelected() {
      if (
        condition.type === "couple-record" &&
        condition.columnKey === "room"
      ) {
        return [ROOM_FILTER_ID];
      }
      if (isConditionWithColumnKey(condition) && condition.columnKey) {
        return [condition.columnKey.toString()];
      }
      if (condition.type === "metadata") {
        return ["rooms"];
      }
      return undefined;
    }

    return [options, getSelected()];
  }, [columns, condition, disabledColumnTypes, t]);

  const handleColumnKeyChange = useHandleChangeSingleSelectHelper(
    (newColumnKey: string | undefined) => {
      if (condition.type === "composite") {
        return;
      }

      let _condition: Exclude<Condition, CompositeCondition> | undefined;

      if (newColumnKey === undefined) {
        _condition = { type: "draft" };
      } else if (newColumnKey === "rooms") {
        _condition = { type: "metadata", predicate: "isInRoom" };
      } else {
        const element = columns[newColumnKey];

        if (element) {
          _condition = { type: "draft", columnKey: element.column.key };
        }
      }
      if (_condition) {
        setCondition(_condition, index);
      }
    },
    [columns, condition.type, index, setCondition],
  );

  const [
    predicateOptions,
    selectedPredicateOptionsIds,
    conditionKeyForPredicate,
  ] = useMemo(() => {
    const getOptions = () => {
      if (isConditionWithColumnKey(condition) && condition.columnKey) {
        const column = columns[condition.columnKey];
        if (!column) {
          return { _conditionKeyForPredicate: {}, filterOptions: [] };
        }
        const {
          conditionKeyForPredicate: _conditionKeyForPredicate,
          filterOptions,
        } = getFilterOptions(columns[condition.columnKey]?.column);

        return {
          _conditionKeyForPredicate,
          filterOptions,
        };
      }
      if (condition.type === "metadata") {
        return {
          _conditionKeyForPredicate: {},
          filterOptions: [{ value: "isInRoom", label: t("is") }],
        };
      }
      return { _conditionKeyForPredicate: {}, filterOptions: [] };
    };

    const getSelected = () => {
      if (condition.type === "metadata") {
        return ["isInRoom"];
      }
      if (
        condition.type === "couple-record" &&
        condition.columnKey === "room"
      ) {
        return ["is"];
      }
      if (
        condition.type === "couple-record" ||
        condition.type === "standalone-record"
      ) {
        return [condition.predicate];
      }

      return undefined;
    };

    const { _conditionKeyForPredicate, filterOptions } = getOptions();

    return [filterOptions, getSelected(), _conditionKeyForPredicate];
  }, [columns, condition, t]);

  const handlePredicateChange = useHandleChangeSingleSelectHelper(
    (newPredicate: string | undefined) => {
      if (newPredicate === undefined || condition.type === "composite") {
        return;
      }

      if (condition.type === "metadata") {
        const metadataCondition: MetadataCondition = {
          ...condition,
          predicate: newPredicate,
        };
        setCondition(metadataCondition, index);
        setValueColumnType(undefined);
        return;
      }
      if (isConditionWithColumnKey(condition) && condition.columnKey) {
        const conditionKey = conditionKeyForPredicate[newPredicate];

        if (conditionKey === "standalone") {
          const standaloneRecordFilter: StandaloneRecordCondition = {
            type: "standalone-record",
            columnKey: condition.columnKey,
            predicate: newPredicate,
          };
          setCondition(standaloneRecordFilter, index);
        } else if (conditionKey !== undefined) {
          const coupleRecordFilter: CoupleRecordCondition = {
            type: "couple-record",
            columnKey: condition.columnKey,
            predicate: newPredicate,
            value: transferPredicateValue(
              condition,
              columns[condition.columnKey]?.column,
              conditionKey,
              newPredicate,
            ),
          };
          setCondition(coupleRecordFilter, index);
          setValueColumnType(conditionKey);
        }
      }
    },
    [columns, condition, conditionKeyForPredicate, index, setCondition],
  );

  const setConditionValue = useCallback(
    (_condition: Condition) => {
      setCondition(_condition, index);
    },
    [index, setCondition],
  );

  const columnsWithoutPath = useMemo(
    () =>
      Object.values(columns).reduce<Record<string, KSchemaColumn>>(
        (acc, curr) => {
          acc[curr.column.key] = curr.column;
          return acc;
        },
        {},
      ),
    [columns],
  );

  return (
    <div className={classes.root}>
      <div className={classes.operator}>
        {index === 1 ? (
          /* Operator Selector */
          <KDropdown
            variant="dark"
            items={operatorOptions}
            selectedItemIds={selectedOperatorOptionsIds}
            onSelectionChange={handleOperatorChange}
            placeholder={t("filterOption")}
            fullWidth
          />
        ) : (
          /* Operator Label */
          <span className={classes.operatorText}>
            {index === 0
              ? t("filterWhere")
              : t(operator === "and" ? "andFilter" : "orFilter")}
          </span>
        )}
      </div>

      {/* ColumnKey Selector */}
      <div className={classes.part}>
        <KDropdown
          variant="dark"
          items={columnKeyOptions}
          selectedItemIds={selectedColumnKeyOptionsIds}
          onSelectionChange={handleColumnKeyChange}
          placeholder={t("filterOption")}
          withSearchBar
        />
      </div>

      {/* Predicate Selector */}
      <div className={classes.part}>
        {(isConditionWithColumnKey(condition) && condition.columnKey) ||
        condition.type === "metadata" ? (
          <KDropdown
            variant="dark"
            items={predicateOptions}
            selectedItemIds={selectedPredicateOptionsIds}
            onSelectionChange={handlePredicateChange}
            placeholder={t("filterOption")}
            withSearchBar
          />
        ) : null}
      </div>

      {/* Predicate Value Selector */}
      <div className={classes.part}>
        {(isConditionWithPredicateValue(condition) &&
          condition.value?.columnType) ||
        condition.type === "metadata" ? (
          <PredicateValueInputFactory
            condition={condition}
            columns={columnsWithoutPath}
            setCondition={setConditionValue}
          />
        ) : null}
      </div>

      <Button
        variant="QUATERNARY"
        onPress={() => {
          deleteItem(index);
        }}
        icon="x-close"
        iconSize="SMALL"
      />
    </div>
  );
};

export { FilterItem };
