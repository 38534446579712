import { EventChannel, eventChannel } from "redux-saga";
import { put, select, takeEvery } from "typed-redux-saga/macro";

import {
  FirestoreModularFolderHistoryEntry,
  firestoreSubscribeToMoldularFolderHistory,
  normalizeModularFolderHistoryEntries,
} from "@kraaft/shared/core/modules/modularFolderHistory/firestore/firestore.modularFolderHistory";
import {
  ModularFolderHistoryActions,
  ModularFolderHistoryStateActions,
} from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.actions";
import { selectSchemaElementsWithPath } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToModularFolderHistories() {
  yield takeCountedDeep(
    ModularFolderHistoryActions.subscribe,
    ModularFolderHistoryActions.unsubscribe,
    subscribeToModularFolderHistory,
    unsubscribeFromModularFolderHistory,
    (action) => action.payload.modularFolderId,
  );
}

type EventChannelData = { data: FirestoreModularFolderHistoryEntry[] };
type Meta = EventChannel<EventChannelData> | undefined;

function createChannel(modularFolderId: string) {
  return eventChannel<EventChannelData>((emit) => {
    return firestoreSubscribeToMoldularFolderHistory(modularFolderId, (data) =>
      emit({ data }),
    );
  });
}

function* receiveModularFolderHistoryEntries(
  schemaId: string,
  modularFolderId: string,
  firestoreModularFolderHistoryEntries: FirestoreModularFolderHistoryEntry[],
) {
  const schemaElementWithPaths = yield* select(
    selectSchemaElementsWithPath(schemaId),
  );

  const modularFolderHistoryEntries = normalizeModularFolderHistoryEntries(
    firestoreModularFolderHistoryEntries,
    schemaElementWithPaths,
  );

  yield* put(
    ModularFolderHistoryStateActions.set({
      modularFolderId,
      modularFolderHistoryEntries,
      schemaElementWithPaths,
    }),
  );
}

function* subscribeToModularFolderHistory(
  registerMeta: (meta: Meta) => void,
  action: ReturnType<typeof ModularFolderHistoryActions.subscribe>,
) {
  yield* put(
    ModularFolderHistoryStateActions.setLoading({
      modularFolderId: action.payload.modularFolderId,
    }),
  );
  const channel = createChannel(action.payload.modularFolderId);
  registerMeta(channel);
  yield* takeEvery(channel, ({ data }) =>
    receiveModularFolderHistoryEntries(
      action.payload.schemaId,
      action.payload.modularFolderId,
      data,
    ),
  );
}

function* unsubscribeFromModularFolderHistory(meta: Meta) {
  meta?.close();
}
