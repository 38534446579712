import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchema,
  KSchemaColumn,
  KSchemaIcon,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { AutoNumberingMode } from "@kraaft/shared/core/modules/schema/schema.actions";
import { getSchemaTitleColumn } from "@kraaft/shared/core/modules/schema/schema.utils";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { FontSize, Text } from "@kraaft/ui";
import { ModularFolderCardConfigurator } from "@kraaft/web/src/components/schemaBuilder/tabs/editMetadata/modularFolderCardConfigurator/modularFolderCardConfigurator";
import { RoomPills } from "@kraaft/web/src/components/schemaBuilder/tabs/editMetadata/roomPills";
import { SchemaNameInput } from "@kraaft/web/src/components/schemaBuilder/tabs/editMetadata/schemaNameInput/schemaNameInput";
import { SchemaNamingStrategy } from "@kraaft/web/src/components/schemaBuilder/tabs/editMetadata/schemaNamingStrategy";
import { AutoNumberDialog } from "@kraaft/web/src/components/schemaBuilder/tabs/editMetadata/schemaNamingStrategy/autoNumberDialog";

import { useStyles } from "./editMetadata.styles";

interface EditMetadataProps {
  schema: KSchema;
  onChangeName: (name: string) => void;
  onChangeIcon: (icon: KSchemaIcon) => void;
  onChangeAutonumberMode: (autoNumberingMode: AutoNumberingMode) => void;
  onChangeHighlightedCheckbox: (columnKey: string | undefined) => void;
  skipConfirmation?: boolean;
}

function extractAutonumberMode(
  titleColumn:
    | KSchemaColumn<KColumnType.shortText>
    | KSchemaColumn<KColumnType.automatedAutoIncrement>,
): AutoNumberingMode {
  return titleColumn.type === KColumnType.automatedAutoIncrement
    ? {
        mode: "autonumber",
        prefix: titleColumn.prefix,
      }
    : { mode: "manual" };
}

const EditMetadata = ({
  schema,
  onChangeName,
  onChangeIcon,
  onChangeAutonumberMode,
  onChangeHighlightedCheckbox,
  skipConfirmation,
}: EditMetadataProps) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const titleColumn = getSchemaTitleColumn(schema);
  const [newAutonumber, setNewAutonumber] = useState<AutoNumberingMode>(
    extractAutonumberMode(titleColumn),
  );
  const [open, openDialog, closeDialog] = useBooleanState();

  const handleChangeAutonumberMode = useCallback(
    (newMode: AutoNumberingMode) => {
      setNewAutonumber(newMode);
      if (skipConfirmation) {
        onChangeAutonumberMode(newMode);
      } else if (newMode.mode === "manual") {
        onChangeAutonumberMode(newMode);
      } else {
        openDialog();
      }
    },
    [skipConfirmation, onChangeAutonumberMode, openDialog],
  );

  const onSave = useCallback(() => {
    onChangeAutonumberMode(newAutonumber);
  }, [onChangeAutonumberMode, newAutonumber]);

  const onCancel = useCallback(() => {
    setNewAutonumber(extractAutonumberMode(titleColumn));
    closeDialog();
  }, [closeDialog, titleColumn]);

  const canEditIconOrName =
    schema.collection === "folder" && schema.type !== "checkbox";

  const [intermediateName, setIntermediateName] = useState<string>(schema.name);
  const isFocusedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!isFocusedRef.current) {
      setIntermediateName(schema.name);
    }
  }, [schema.name, setIntermediateName]);

  const handleOnFocus = useCallback(() => {
    isFocusedRef.current = true;
  }, []);

  const handleOnBlur = useCallback(() => {
    isFocusedRef.current = false;
    onChangeName?.(intermediateName);
  }, [intermediateName, onChangeName]);

  return (
    <>
      <div className={styles.root}>
        {schema.collection === "folder" && (
          <>
            <div className={styles.section}>
              <Text size={FontSize.H2} weight="bold">
                {t("formBuilder.infos.schemaName")}
              </Text>
              <SchemaNameInput
                editNameDisabled={!canEditIconOrName}
                name={intermediateName}
                onNameChange={setIntermediateName}
                editIconDisabled={!canEditIconOrName}
                icon={schema.collection === "folder" ? schema.icon : undefined}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onIconChange={
                  schema.collection === "folder" ? onChangeIcon : undefined
                }
              />
            </div>
            <div className={styles.section}>
              <Text size="H2" weight="bold">
                {t("formBuilder.infos.displayOptions")}
              </Text>
              <div className={styles.marginBottom}>
                <ModularFolderCardConfigurator
                  schema={schema}
                  onChangeHighlightedCheckbox={onChangeHighlightedCheckbox}
                />
              </div>
            </div>
            <div className={styles.section}>
              <Text size="H2" weight="bold">
                {t("formBuilder.infos.foldersTitle")}
              </Text>
              <div className={styles.marginBottom}>
                <SchemaNamingStrategy
                  autoNumberingMode={newAutonumber}
                  setAutoNumberingMode={handleChangeAutonumberMode}
                />
              </div>
            </div>
          </>
        )}
        {schema.collection === "room" && (
          <div className={styles.section}>
            <Text size="H1">{t("formBuilder.infos.customizeDisplay")}</Text>
            <RoomPills roomSchema={schema} />
          </div>
        )}
      </div>

      {newAutonumber.mode === "autonumber" && (
        <AutoNumberDialog
          schemaId={schema.id}
          prefix={newAutonumber.prefix}
          open={open}
          onSave={onSave}
          onCancel={onCancel}
          onClose={closeDialog}
        />
      )}
    </>
  );
};

export { EditMetadata };
