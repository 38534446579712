import { useMemo } from "react";
import { StyleSheet } from "react-native";

import {
  DifferenceBackground,
  DifferenceBackgroundProps,
} from "@kraaft/shared/components/modularFolders/modularFolderHistory/recordPropertyDifferenceRenderer/differenceBackground";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularRecordValueOfType } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { Image, Radius, Spacing } from "@kraaft/ui";

interface SignatureDifferenceProps extends DifferenceBackgroundProps {
  value: ModularRecordValueOfType<KColumnType.signature>;
}

export const SignatureDifference = ({
  value,
  method,
}: SignatureDifferenceProps) => {
  const content = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    return (
      <DifferenceBackground method={method} withBorderRadius>
        <Image
          preferredSize="small"
          style={styles.image}
          resizeMode="contain"
          source={value.original.downloadUrl}
        />
      </DifferenceBackground>
    );
  }, [method, value]);

  return content !== undefined ? content : null;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    gap: Spacing.S4,
  },

  image: {
    borderRadius: Radius.SMALL,
    height: 100,
    aspectRatio: 1,
  },
});
