const getSegmentAnalyticsClient = (): typeof window.analytics | undefined =>
  window.analytics;

type JsonValue =
  | boolean
  | number
  | string
  | null
  | JsonList
  | JsonMap
  | undefined;

type JsonList = Array<JsonValue>;

export interface JsonMap {
  [key: string]: JsonValue;
}

class SegmentAnalytics {
  private context: { groupId: string } | undefined;

  async setContext(context: { groupId: string } | undefined): Promise<void> {
    this.context = context;
  }

  async identify(userId: string, traits?: JsonMap): Promise<void> {
    getSegmentAnalyticsClient()?.identify(userId, traits);
  }

  async track(eventName: string, eventData?: JsonMap): Promise<void> {
    const options: SegmentAnalytics.SegmentOpts = {};
    if (this.context) {
      options.context = this.context;
    }

    getSegmentAnalyticsClient()?.track(eventName, eventData, options);
  }
}

export const segmentAnalytics = new SegmentAnalytics();
