import { createAction } from "@reduxjs/toolkit";

interface AlertActionProps {
  title: string;
  message?: string;
  isPersistent?: boolean;
}

interface PreparedAlertAction {
  payload: {
    title: string;
    message: string | undefined;
    isPersistent: boolean;
  };
}

function prepareAction(payload: AlertActionProps): PreparedAlertAction {
  return {
    payload: {
      title: payload.title,
      message: payload.message,
      isPersistent: payload.isPersistent ?? false,
    },
  };
}

export const showInfo = createAction("@alert/INFO_SHOW", prepareAction);

export const showWarn = createAction("@alert/WARN_SHOW", prepareAction);

export const showError = createAction("@alert/ERROR_SHOW", prepareAction);

export const showSuccess = createAction("@alert/SUCCESS_SHOW", prepareAction);

export const hideAlert = createAction("@alert/ALERT_HIDE");
