import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { SchemaTemplateActions } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateActions";
import { selectSchemaTemplates } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateSelectors";

export function useRealtimeSchemaTemplates() {
  const dispatch = useDispatch();
  const poolId = useSelector(selectCurrentPoolId);

  useEffect(() => {
    if (!poolId) {
      return;
    }
    dispatch(SchemaTemplateActions.subscribe({ poolId }));
    return () => {
      dispatch(SchemaTemplateActions.unsubscribe({ poolId }));
    };
  }, [dispatch, poolId]);

  return useSelector(selectSchemaTemplates);
}
