import { useCallback, useRef } from "react";
import { Dimensions } from "react-native";

import { OnScrollInfo } from "@kraaft/shared/components/bidirectionalMessageList";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import {
  LinkedList,
  LinkedListElement,
} from "@kraaft/shared/core/utils/useBidirectional/createLinkedLists";
import { LinkedListHelpers } from "@kraaft/shared/core/utils/useBidirectional/linkedList";

const SCROLL_TRIGGER_THRESHOLD = Dimensions.get("window").height;

export function detectEdgeFromScrollInfo({
  contentHeight,
  scroll,
  scrollHeight,
}: OnScrollInfo) {
  const top = scroll;
  const bottom = scrollHeight - scroll - contentHeight;

  const isBeforeBorder = top < SCROLL_TRIGGER_THRESHOLD;
  const isAfterBorder = bottom < SCROLL_TRIGGER_THRESHOLD;

  return { isBeforeBorder, isAfterBorder, top, bottom };
}

export function useScrollEdgeDetection(
  linkedList: LinkedList,
  anchor: LinkedListElement | undefined,
  loadBefore: () => Promise<void>,
  loadAfter: () => Promise<void>,
) {
  const earlierItemIdRequested = useRef<string | undefined>(undefined);
  const latestItemIdRequested = useRef<string | undefined>(undefined);

  const lastY = useRef(0);
  const onScroll = useCallbackRealtime(
    async (
      [_linkedList, _anchor, _loadAfter, _loadBefore],
      scrollInfo: OnScrollInfo,
    ) => {
      const { scroll } = scrollInfo;
      const { isBeforeBorder, isAfterBorder } =
        detectEdgeFromScrollInfo(scrollInfo);

      const earliestItem =
        _anchor && LinkedListHelpers.getEarliestAnchored(_linkedList, _anchor);
      const latestItem =
        _anchor && LinkedListHelpers.getLatestAnchored(_linkedList, _anchor);

      const direction = scroll - lastY.current < 0 ? "before" : "after";
      lastY.current = scroll;

      if (
        isBeforeBorder &&
        direction === "before" &&
        earlierItemIdRequested.current !== earliestItem?.itemId
      ) {
        earlierItemIdRequested.current = earliestItem?.itemId;
        await _loadBefore().catch(console.error);
      } else if (
        isAfterBorder &&
        direction === "after" &&
        latestItemIdRequested.current !== latestItem?.itemId
      ) {
        latestItemIdRequested.current = latestItem?.itemId;
        await _loadAfter().catch(console.error);
      }
    },
    [],
    [linkedList, anchor, loadAfter, loadBefore],
  );

  const resetLoadLocks = useCallback(() => {
    earlierItemIdRequested.current = undefined;
    latestItemIdRequested.current = undefined;
  }, []);

  return { onScroll, resetLoadLocks };
}
