import { StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import {
  ColorStyle,
  FontSize,
  PixelSize,
  Radius,
  Spacing,
  webOnlyStyle,
} from "@kraaft/ui";

export const styles = StyleSheet.create({
  touchableItem: {
    ...(!isNative() ? { flexDirection: "row" } : {}),
    width: "100%",
    height: PixelSize.S40,
  },
  group: {
    width: 0, // We need to have a width here (even if it's 0) on the web
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: PixelSize.S40,
    paddingHorizontal: 12,
    borderRadius: Radius.SMALL,
    ...(isNative() ? { width: "100%" } : {}),
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: "row",
  },
  title: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,

    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "left",
    ...webOnlyStyle({ wordBreak: "break-all" }),
  },
  description: {
    flexShrink: 1,
    maxWidth: "50%",

    ...webOnlyStyle({ wordBreak: "break-all" }),
    paddingRight: 3,
    paddingLeft: Spacing.S16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  bracket: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  titleText: {
    paddingLeft: 8,
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    flexGrow: 1,
    flexBasis: 0,
  },
  descriptionText: {
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
});
