import { NetworkState } from "@kraaft/shared/core/modules/app/appState";
import { Network } from "@kraaft/shared/core/modules/network/network";
import { NoopListeners } from "@kraaft/shared/core/utils/listeners";

export class AlwaysOnNetwork implements Network {
  async getState() {
    return {
      isInternetReachable: true,
      networkType: "non-cellular" as const,
    };
  }

  public onStateChange = new NoopListeners<(state: NetworkState) => void>();
}
