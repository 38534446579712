import { forwardRef, ReactElement, Ref, useMemo } from "react";
import { FlatList, FlatListProps } from "react-native";
import { flatten, isEmpty } from "lodash/fp";

import {
  EmptyState,
  EmptyStateProps,
} from "@kraaft/shared/components/emptyState";

import { styles } from "./styles";

export type FlatListWithEmptyStateProps<ItemT> = Omit<
  FlatListProps<ItemT>,
  "ListEmptyComponent"
> & {
  type: EmptyStateProps["type"];
  // should the empty state be displayed on empty data
  isEmptyStateEnabled?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FlatListWithEmptyState_ = <ItemT = any>(
  {
    type,
    contentContainerStyle,
    isEmptyStateEnabled = true,
    scrollEnabled = true,
    ...props
  }: FlatListWithEmptyStateProps<ItemT>,
  ref: Ref<FlatList<ItemT>>,
) => {
  const { data } = props;

  const isEmptyStateVisible = isEmptyStateEnabled && isEmpty(data);

  const _contentContainerStyle = useMemo(
    () =>
      flatten([
        contentContainerStyle,
        isEmptyStateVisible && styles.emptyContent,
      ]),
    [contentContainerStyle, isEmptyStateVisible],
  );

  return (
    <FlatList<ItemT>
      {...props}
      ref={ref}
      contentContainerStyle={_contentContainerStyle}
      scrollEnabled={isEmptyStateVisible ? false : scrollEnabled}
      ListEmptyComponent={
        isEmptyStateEnabled ? <EmptyState type={type} /> : null
      }
    />
  );
};

export const FlatListWithEmptyState = forwardRef(FlatListWithEmptyState_) as <
  ItemT,
>(
  props: FlatListWithEmptyStateProps<ItemT> & { ref?: Ref<FlatList<ItemT>> },
) => ReactElement;
