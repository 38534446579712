import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { useStartEditingMessageText } from "@kraaft/shared/components/messageTextEditor";
import { getEditMessageMenuLabel } from "@kraaft/shared/components/messageTextEditor/messageTextEditorUtils";
import { utils as messageUtils } from "@kraaft/shared/core/modules/message";
import {
  ImageMessage,
  Message,
  VideoMessage,
} from "@kraaft/shared/core/modules/message/messageState";
import { selectCurrentContextAtLeastAdmin } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useNetwork } from "@kraaft/shared/core/utils/useNetwork";

export function useEditCaptionMenuItems(roomId: string, message: Message) {
  const { t } = useTranslation();
  const { ensureOnline } = useNetwork();

  const startEditingMessageText = useStartEditingMessageText();
  const canEditCaption = useCanEditCaption(message);

  return useMemo(() => {
    const items: ActionSheetItem[] = [];

    if (canEditCaption) {
      items.push({
        label: getEditMessageMenuLabel(t, message),
        onPress: ensureOnline(() => {
          trackEvent({
            eventName: "Click Message Action Sheet Option",
            room_id: roomId,
            action: "edit caption",
          });

          startEditingMessageText(message);
        }),
        icon: "edit-02",
      });
    }
    return items;
  }, [
    canEditCaption,
    ensureOnline,
    message,
    roomId,
    startEditingMessageText,
    t,
  ]);
}

export function useCanEditCaption(
  message: Message,
): message is ImageMessage | VideoMessage {
  const isAtLeastAdmin = useSelector(selectCurrentContextAtLeastAdmin);

  const canEditCaption =
    (message.type === "image" || message.type === "video") &&
    messageUtils.isPersisted(message) &&
    (!message.isReply || isAtLeastAdmin);
  return canEditCaption;
}
